import {
  Button, ComboSelect, ComboSelectAdditionalOption,
  ContactIcon,
  Form,
  FormField,
  FormRefHandle,
  FormWatch,
  LoadingIndicator,
  LocationIcon,
  Modal,
  OrganizationIcon,
  SlideOver,
  SlideOverOnCloseProps,
  TextInput,
  UploadBadge,
} from '@client/shared/toolkit';
import {
  apiBase,
  ApiTagTypes,
  CompanyAssignmentReadModel,
  CompanyBranchReadModel,
  CompanyReadModel,
  PersonBranchAssignmentReadModel,
  useApiDeleteTenantProjectInviteMutation,
  useApiPostInviteTenantMutation,
  useApiPostReinviteTenantMutation,
} from '@client/shared/api';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  getProjectThumbnailByCoverId,
  getTenantTrustBadgeByStatus, getTenantTrustBadgeLabelByStatus,
  TenantTrustInviteModal,
  TenantTrustToggle
} from '@client/project/shared';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import { useDispatch } from 'react-redux';
import { InviteDeleteModal } from '../trustedTenants';
import { settings } from '@client/shared/store';
import { ContactDetailsSlideOver } from './ContactDetailsSlideOver';

const CompanyProjectValidationSchema = yup.object({
  contactPartner: yup.string().nullable().optional(),
  branch: yup.string().nullable().optional(),
  employees: yup.mixed().optional(),
  uploadActive: yup.boolean().optional(),
});

type CompanyProjectValidationValues = InferType<typeof CompanyProjectValidationSchema>;

interface PartnerCompaniesProjectEditSlideOverProps extends SlideOverOnCloseProps {
  isOpen: boolean;
  resetProject: () => void;
  company?: CompanyReadModel;
  project: CompanyAssignmentReadModel | null;
  branch?: CompanyBranchReadModel | null;
}

export const PartnerCompaniesProjectEditSlideOver = (props: PartnerCompaniesProjectEditSlideOverProps) => {
  const { isOpen, onClose, resetProject, project, branch, company } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDevMode = settings.devMode;

  const formRef = useRef<FormRefHandle<CompanyProjectValidationValues>>();
  const [inviteTrustedTenant, { isLoading: isInviting }] = useApiPostInviteTenantMutation();
  // const [setEnabled, { isLoading: isEnabling }] = useApiPostInviteSetEnabledMutation();
  const [disableTenantTrust, { isLoading: isDisabling }] = useApiDeleteTenantProjectInviteMutation();
  const [reInvite, { isLoading: isReInviting }] = useApiPostReinviteTenantMutation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedContactPartner, setSelectedContactPartner] = useState<PersonBranchAssignmentReadModel | null>(null);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isUploadActive, setIsUploadActive] = useState(false);

  const [showEditContact, setShowEditContact] = useState<boolean>(false);
  const [newContact, setNewContact] = useState('');

  // TODO only for testing on dev
  const [testInvitationUrl, setTestInvitationUrl] = useState('')

  const projectImage = useMemo(() => {
    if (project) {
      return getProjectThumbnailByCoverId(
        project.project.projectId,
        project.project.name,
        'Small',
        project.project.projectCoverId
      );
    }
    return '';
  }, [project]);

  /* const handleSetEnabled = async (id: string, enabled: boolean) => {
    try {
      await safeMutation(
        setEnabled,
        {
          body: {
            inviteId: id,
            enabled: enabled,
          },
        },
        isEnabling,
      );
      if (company) {
        // @ts-expect-error need to invalidate only that company
        dispatch(apiBase.util.invalidateTags([{ type: 'Company', id: company.id }]));
      }
      setIsInviteModalOpen(false);
    } catch (e) {
      console.log(e);
    }
  }; */

  const handleReinvite = async (id: string) => {
    try {
      await safeMutation(
        reInvite,
        {
          inviteId: id,
        },
        isReInviting,
      );
      setIsInviteModalOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const disableInvitation = async () => {
    if (project?.tenantTrust?.trustId) {
      try {
        await safeMutation(
          disableTenantTrust,
          {
            body: {
              trustId: project.tenantTrust.trustId,
              projectId: project.project.projectId,
            },
          },
          isDisabling,
        );
        if (company) {
          // @ts-expect-error need to invalidate only that company
          dispatch(apiBase.util.invalidateTags([{ type: ApiTagTypes.Company, id: company.id }]));
        }
        setIsInviteModalOpen(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleSubmit = async (data: CompanyProjectValidationValues) => {
    setTestInvitationUrl('')
    if (project?.tenantTrust?.trustId) {
      if (isUploadActive) {
        // resend invitation
        await handleReinvite(project.tenantTrust.trustId);
      } else {
        await disableInvitation()
      }
      // disable / enable existing accepted trust
      // await handleSetEnabled(project.tenantTrust.trustId, isUploadActive);
    } else if (company && branch && data.contactPartner && selectedContactPartner?.personEmail) {
      try {
        const response = await inviteTrustedTenant({
          body: {
            companyId: company.id,
            companyBranchId: branch.id,
            companyPersonId: data.contactPartner,
            projectId: project?.project.projectId ?? '',
          },
        }).unwrap();
        if (company) {
          // @ts-expect-error need to invalidate only that company
          dispatch(apiBase.util.invalidateTags([{ type: ApiTagTypes.Company, id: company.id }]));
          setSelectedContactPartner(null);
        }
        if (isDevMode && response.inviteUrl) {
          setTestInvitationUrl(response.inviteUrl)
        }
        setIsInviteModalOpen(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const employeeOptions = useMemo(() => {
    if (branch?.persons?.length) {
      return branch.persons.map((person) => {
        return {
          label: `${person.personName}${person.personEmail ? ' - ' + person.personEmail : ''}`,
          value: person.personId,
        };
      });
    }
    return [];
  }, [branch?.persons]);

  useEffect(() => {
    if (branch?.persons && newContact && branch?.persons.find((person) => person.personId === newContact)) {
      formRef.current?.setValue('contactPartner', newContact);
    }
  }, [branch?.persons, newContact]);

  const defaultValues = {
    contactPartner:
      project?.tenantTrust?.trustState === 'Accepted'
        ? null
        : branch?.persons?.length
          ? branch.persons[0].personId
          : null,
    branch: branch?.id ?? '',
    employees: [],
    uploadActive: project?.tenantTrust?.trustIsActive,
  };

  return (
    <SlideOver isOpen={isOpen} onClose={() => onClose(false)} onAfterLeave={resetProject}>
      {(isInviting || isDisabling || isReInviting) && (
        <LoadingIndicator text={t('app.tenantTrustLoadingIndicator')} mode="overlay-window" />
      )}
      <Form<CompanyProjectValidationValues>
        onSubmit={handleSubmit}
        validationSchema={CompanyProjectValidationSchema}
        defaultValues={defaultValues}
        className="flex flex-col flex-grow min-h-0"
        ref={formRef}
      >
        <FormWatch
          fieldNames={['contactPartner']}
          onChange={({ contactPartner }) => {
            if (!project?.tenantTrust) {
              const foundPerson = branch?.persons?.find((person) => person.personId === contactPartner);
              if (foundPerson) {
                setSelectedContactPartner(foundPerson);
              }
              if (!foundPerson?.personEmail) {
                formRef.current?.setValue('uploadActive', false);
                setIsUploadActive(false);
              }
            }
          }}
        >
          {() => (
            <SlideOver.Content>
              <div className="w-full h-1/3">
                {projectImage && (
                  <img
                    src={projectImage}
                    alt={project?.project.name}
                    width={950}
                    height={780}
                    className="w-full h-full object-cover"
                  />
                )}
                {project?.tenantTrust && (
                  <UploadBadge
                    variant={getTenantTrustBadgeByStatus(
                      project.tenantTrust.trustState,
                      project.tenantTrust.trustIsActive,
                    )}
                    className="absolute top-4 right-4"
                    tooltip={getTenantTrustBadgeLabelByStatus(
                      project.tenantTrust.trustState,
                      project.tenantTrust.trustIsActive,
                    )}
                  />
                )}
              </div>
              <div className="p-8">
                <TextInput
                  disabled
                  label={t('app.project')}
                  value={project?.project.name ?? ''}
                  icon={<LocationIcon />}
                />
                {branch && (
                  <TextInput
                    className="mt-4"
                    disabled
                    label={t('common.address')}
                    value={branch.name ?? ''}
                    icon={<OrganizationIcon />}
                  />
                )}
                {project?.tenantTrust ? (
                  <TextInput
                    disabled
                    label={t('app.contactPerson')}
                    value={`${project.tenantTrust.personName} - ${project.tenantTrust.personEmail}`}
                    icon={<LocationIcon />}
                    // iconRight={<TrashIcon className="w-5 ml-auto" />}
                    // onRightIconClick={() => setIsDeleteModalOpen(true)}
                  />
                ) : (
                  <FormField name="contactPartner">
                    {(control) => (
                      <ComboSelect
                        label={t('app.contactPerson')}
                        icon={<ContactIcon />}
                        options={employeeOptions}
                        {...control}
                        additionalOptionOnClick={() => setShowEditContact(true)}
                        additionalOption={<ComboSelectAdditionalOption label={t('app.companyContactAdd')} />}
                      />
                    )}
                  </FormField>
                )}
                {project && branch && company && (
                  <TenantTrustToggle
                    company={company}
                    project={project.project.name}
                    contact={selectedContactPartner}
                    handleOnChange={(value) => {
                      setIsUploadActive(value);
                      if (value || (!value && project?.tenantTrust?.trustState === 'Accepted')) {
                        setIsInviteModalOpen(true);
                      } else {
                        disableInvitation();
                      }
                    }}
                    branch={branch}
                    disabled={!project.tenantTrust && !selectedContactPartner?.personEmail}
                    errorMessage={
                      !project.tenantTrust && selectedContactPartner && !selectedContactPartner?.personEmail
                        ? t('app.masterDataTenantTrustNeedsEmail')
                        : undefined
                    }
                  />
                )}
                {testInvitationUrl && isDevMode && (
                  <div className="italic text-sm mt-2">
                    <span className="font-bold">Invitation URL:</span>
                    <br />
                    <a href={testInvitationUrl}>
                      {testInvitationUrl}
                    </a>
                  </div>
                )}
                {/*
                <FormField name="employees">
                  {(control) => (
                    <BaseSelect
                      className="hidden"
                      label={t('app.projectParticipants')}
                      options={employeeOptions}
                      {...control}
                    />
                  )}
                </FormField>
                <SlideOverTitle title={t('app.projectParticipants')} />
                <SlideOverList
                  clickable={false}
                  className="gap-1 mt-0"
                  items={[]}
                  noItemsMessage={t('app.companiesNoEmployeesMessage')}
                  rounded={false}
                  showShadow={false}
                  margin={false}
                />
                */}
              </div>
            </SlideOver.Content>
          )}
        </FormWatch>
        <SlideOver.Controls>
          <div className="w-full flex justify-end">
            <Button variant="secondary" onClick={() => onClose(false)}>
              {t('common.close')}
            </Button>
            {/*
            <Button variant="primary" onClick={() => setIsInviteModalOpen(true)}>
              {t('common.save')}
            </Button>
            */}
          </div>
        </SlideOver.Controls>
      </Form>
      <TenantTrustInviteModal
        descriptionData={{
          company: company?.name ?? '',
          project: project?.project.name ?? '',
          invitee: selectedContactPartner?.personName ?? '',
        }}
        isOpen={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
        onSubmit={() => formRef.current?.submitForm()}
        activate={isUploadActive}
      />
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <InviteDeleteModal
          inviteId={project?.tenantTrust?.trustId}
          onClose={(deleted) => {
            setIsDeleteModalOpen(false);
            if (deleted && company?.id) {
              // @ts-expect-error need to invalidate only that company
              dispatch(apiBase.util.invalidateTags([{ type: ApiTagTypes.Company, id: company.id }]));
            }
            formRef.current?.setValue('uploadActive', false);
            setIsUploadActive(false);
          }}
        />
      </Modal>
      <SlideOver
        isOpen={showEditContact}
        onClose={() => setShowEditContact(false)}
      >
        <ContactDetailsSlideOver
          isAdd
          company={company}
          branch={branch}
          onClose={(newContact) => {
            setNewContact(newContact ?? '');
            setShowEditContact(false);
          }}
        />
      </SlideOver>
    </SlideOver>
  );
};
