import {
  CalculationModelMilestoneReadModel,
  useApiPostCreateCalculationModelMilestonesMutation,
  useApiPostUpdateCalculationModelMilestonesMutation
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { formatDateOnly, safeMutation } from '@client/shared/utilities';
import {
  MilestoneFormValidationSchema,
  MilestoneFormValidationValues
} from './MilestoneFormValidationSchema';
import {
  BooleanInput,
  ContextMenu,
  ContextMenuItem,
  DatePicker,
  Form,
  FormField,
  FormRefHandle,
  Modal,
  TextInput,
  TrashIcon
} from '@client/shared/toolkit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { DeleteCalculationModelMilestoneModal } from './DeleteCalculationModelMilestoneModal';

interface EditCalculationModelMilestoneProps {
  item: CalculationModelMilestoneReadModel,
  updateItem: (updated: CalculationModelMilestoneReadModel) => void
  onItemDeleted: () => void
  readOnly?: boolean
  setIsLoading: (isLoading: boolean) => void
}

export const EditCalculationModelMilestone = ({
  item,
  onItemDeleted,
  readOnly = false,
  setIsLoading,
  updateItem
}: EditCalculationModelMilestoneProps) => {
  const { t } = useTranslation();
  const formRef = useRef<FormRefHandle<MilestoneFormValidationValues>>();
  const loadedProjectId = useLoadedProjectId() ?? 'unset';
  const loadedCalculationModelId = useLoadedVariantId() ?? 'unset';
  const canDelete = useValidateProjectPermission(['PROJECT_DELETE'], loadedProjectId);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [postUpdate, { isLoading: isUpdating }] = useApiPostUpdateCalculationModelMilestonesMutation();
  const [postCreate, { isLoading: isCreating }] = useApiPostCreateCalculationModelMilestonesMutation();
  const defaultFormValues = useMemo(() => {
    return {
      code: item?.code,
      name: item?.name,
      milestoneDate: item?.date
        ? new Date(item?.date)
        : new Date(),
      isAchieved: item?.isAchieved
    }
  }, [item])
  const handleMilestoneSubmit = (data : MilestoneFormValidationValues) => {
    const updatedItem = {...item, ...{
        code: data.code,
        name: data.name,
        date: formatDateOnly(data.milestoneDate)
      }}
    submitData(updatedItem)
  };

  const submitData = async (data: CalculationModelMilestoneReadModel) => {
    // new milestone
    if (!data.id){
      try {
        await safeMutation(
          postCreate,
          {
            projectId: loadedProjectId,
            calculationModelId: loadedCalculationModelId,
            body: {
              code : data.code,
              name : data.name,
              order : data.order,
              date : data.date,
              isAchieved : data.isAchieved
            },
          },
          isCreating
        );
      } catch (e) {
        console.log(e);
      }
    } else {
      // update milestone
      try {
        await safeMutation(
          postUpdate,
          {
            projectId: loadedProjectId,
            calculationModelId: loadedCalculationModelId,
            milestoneId: data.id,
            body: {
              code : data.code,
              name : data.name,
              order : data.order ?? 0,
              date : data.date,
              isAchieved : data.isAchieved
            },
          },
          isUpdating
        );
      } catch (e) {
        console.log(e);
      }
    }
  }

  const saveItem = useCallback(() => {
    if (formRef?.current) {
      formRef.current.submitForm()
    }
  }, [])

  const contextItems: ContextMenuItem[] = useMemo(() => [
    {
      label: t('common.delete'),
      subtitle: t('common.deleteElement'),
      icon: <TrashIcon />,
      onClick: () => {
        if (item.id) {
          setShowDelete(true)
        } else {
          onItemDeleted()
        }
      }
    },
  ], [t, item.id, onItemDeleted, setShowDelete])

  useEffect(() => {
    if (isUpdating || isCreating) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdating, isCreating]);


  return (
    <Form<MilestoneFormValidationValues>
      onSubmit={handleMilestoneSubmit}
      validationSchema={MilestoneFormValidationSchema}
      defaultValues={defaultFormValues}
      className="flex border-b divide-x"
      ref={formRef}
      key={`form-milestone-${item.id}-${item.order}`}
    >
      <FormField name="code">
        {(control) => (
          <TextInput
            className="w-[76px] flex-none"
            disabled={readOnly}
            label={t('projectCalculate.deliveryPhaseLabelId')}
            maxLength={5}
            {...control}
            helperText=""
            showValidation={!control.isValidationValid}
            onBlur={() => {
              if (control.value !== item.code) {
                saveItem()
              }
            }}
          />
        )}
      </FormField>
      <FormField name="name">
        {(control) => (
          <TextInput
            className="w-auto flex-1"
            disabled={readOnly}
            label={t('common.description')}
            {...control}
            helperText=""
            showValidation={!control.isValidationValid}
            onBlur={() => {
              if (control.value !== item.name) {
                saveItem()
              }
            }}
          />
        )}
      </FormField>
      <FormField name="milestoneDate">
        {(control) => (
          <DatePicker
            className="w-[160px] flex-none overflow-hidden"
            disabled={readOnly}
            label={t('common.date')}
            {...control}
            helperText=""
            showValidation={!control.isValidationValid}
            onChange={(value) => {
              if (value) {
                // somehow we need to do it like this, and not use onBlur as the value is incorrect there
                const updatedItem = {...item}
                updatedItem.date = formatDateOnly(value)
                updateItem(updatedItem)
                submitData(updatedItem)
              }
            }}
            /*
            onBlur={() => {
              console.log(control.value, 'control.value')
            }}
            */
          />
        )}
      </FormField>
      <BooleanInput
        label=""
        value={item.isAchieved}
        // somehow we need to do it like this, and not use onBlur as the value is incorrect there
        onChange={(value) => {
          const updatedItem = {...item}
          updatedItem.isAchieved = value
          updateItem(updatedItem)
          submitData(updatedItem)
        }}
        variant="checkbox-left"
      />
      {canDelete && (
        <div className="bg-white flex items-center justify-center text-gray-400 hover:text-gray-600 px-1">
          <ContextMenu items={contextItems} />
        </div>
      )}
      <Modal isOpen={showDelete} onClose={() => setShowDelete(false)}>
        <DeleteCalculationModelMilestoneModal
          milestone={item}
          onClose={() => setShowDelete(false)}
        />
      </Modal>
    </Form>
  );
};
