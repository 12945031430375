import { useMemo } from 'react';
import { SlideOver, Button, SlideOverOnCloseProps } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { formatDate, formatNumber, formatPercentage } from '@client/shared/utilities';
import { TimeLineElementsProps } from './TimeLineBody';
import { FinanceTimeLineElement } from '@client/shared/api';
import {useLoadedProjectValueTypeIsNet, useLoadedVariant } from '@client/project/store';
import { FormattedCurrency } from '@client/project/shared';

interface TimeLineElementSlideOverProps extends SlideOverOnCloseProps {
  element?: TimeLineElementsProps;
}

export const TimeLineElementSlideOver = ({ element, onClose }: TimeLineElementSlideOverProps) => {
  const { t } = useTranslation();
  const { data: loadedVariant } = useLoadedVariant();
  const isNet = useLoadedProjectValueTypeIsNet()

  const length = element?.financeTimeline?.elements ? element?.financeTimeline?.elements.length : 0;
  const statusDate = loadedVariant?.calculationModel?.modelMetadata?.cashFlowSettings?.statusMonth;
  const plannedToDate = element?.financeTimeline?.plannedToDate;
  const paymentToDate = isNet ? element?.financeTimeline?.paymentToDateNet : element?.financeTimeline?.paymentToDateGross;
  const totalPlanned = element?.financeTimeline?.totalPlanned;
  const totalPayment = isNet ? element?.financeTimeline?.totalPaymentNet : element?.financeTimeline?.totalPaymentGross;


  const getDay = (date: string) => {
    if (!date) return undefined;
    return date.split('-')[2].split('T')[0];
  };

  const getPaymentTime = (element: FinanceTimeLineElement) => {
    if (!element.datedPayments) return t('projectCalculateTransactionplanOpen');
    const planDate = new Date(element.plan?.date ?? '');
    const paymentDate = new Date(element.datedPayments?.date ?? '');
    const diff = (planDate.getTime() - paymentDate.getTime()) / (1000 * 60 * 60 * 24);

    if (diff > 1) {
      return `${formatNumber(Math.abs(diff), { maxDigits: 0 })} ${t('projectCalculateTransactionplanDaysEarly')}`;
    } else if (diff < -1) {
      return `${formatNumber(Math.abs(diff), { maxDigits: 0 })} ${t('projectCalculateTransactionplanDaysLate')}`;
    } else if (diff === 0 || diff < 1) {
      return t('projectCalculateTransactionplanOnTime');
    }
  };

  const getPercentage = (value1: number, value2: number) => {
    return (value2 - value1) / value1;
  };

  const closestElementToStatusDate = useMemo(() => {
    const elements = element?.financeTimeline?.elements;
    const date = new Date(statusDate ?? '');

    if (!elements || !statusDate) return;

    const middleOfMonth = new Date(date.getFullYear(), date.getMonth(), 15);
    let closestElement = elements[0];
    let closestDiff = Math.abs(
      middleOfMonth.getTime() - new Date(elements[0].plan?.date ?? elements[0].date).getTime()
    );
    elements.forEach((el) => {
      const diff = Math.abs(middleOfMonth.getTime() - new Date(el.plan?.date ?? el.date).getTime());

      if (diff < closestDiff) {
        closestDiff = diff;
        closestElement = el;
      }
    });

    return closestElement;
  }, [element, statusDate]);

  const calculatePlannedELement = useMemo(() => {
    const percentage = plannedToDate ?? 0;
    const plannedValue = percentage * (totalPlanned ?? 0);
    const elements = element?.financeTimeline?.elements;

    if (!elements || elements.length === 0) return null;

    let result = elements[0];
    let minDifference = Math.abs(plannedValue - (elements[0].plan?.value ?? 0));
    let sum = 0;

    elements.forEach((el) => {
      sum += el.plan?.value ?? 0;
      const difference = Math.abs(plannedValue - sum);
      if (difference < minDifference) {
        minDifference = difference;
        result = el;
      }
    });

    return result;
  }, [plannedToDate, element, totalPlanned]);

  const calculatePaymentELement = useMemo(() => {
    const percentage = paymentToDate ?? 0;
    const paymentValue = percentage * (totalPayment ?? 0);
    const elements = element?.financeTimeline?.elements;

    if (!elements || elements.length === 0) return null;

    let result = elements[0];
    let minDifference = Math.abs(paymentValue - ((isNet ? result.datedPayments?.paymentValueSumNet : result.datedPayments?.paymentValueSumGross) ?? 0));
    let sum = 0;

    elements.forEach((el) => {
      sum += (isNet ? el.datedPayments?.paymentValueSumNet : el.datedPayments?.paymentValueSumGross) ?? 0;
      const difference = Math.abs(paymentValue - sum);
      if (difference < minDifference) {
        minDifference = difference;
        result = el;
      }
    });

    return result;
  }, [paymentToDate, totalPayment, element?.financeTimeline?.elements, isNet]);

  return (
    <>
      <SlideOver.Header
        onClose={onClose}
        title={element?.description ?? ''}
        subTitle={t('projectCalculateTransactionplan')}
        backgroundClassName="bg-sky-900 w-full text-white p-8 "
      />
      <SlideOver.Content>
        <div className="mt-8 mx-8">
          {element?.financeTimeline && (
            <>
              <div className="mb-1 pl-2 pr-4 flex text-gray-800 text-xs text-center">
                <div className="w-2/12">{t('projectCalculateTransactionplanPaydate')}</div>
                <div className="w-2/12 text-left pl-2">{t('projectCalculateTransactionplanStatus')}</div>
                <div className="w-4/12 text-end">{t('projectCalculateTransactionplanPlannedFixed')}</div>
                <div className="w-4/12 text-end">{t('projectCalculate.ist')}</div>
              </div>
              <div className="mb-2 pl-2 pr-4 bg-white flex flex-col text-gray-800 text-sm text-center shadow-sm rounded ">
                {element?.financeTimeline.elements.map((el, i) => {
                  const paymentSum = isNet ? el.datedPayments?.cashflowValueSumNet : el.datedPayments?.cashflowValueSumGross;
                  return (
                    <div
                      className={classNames('flex h-[60px]', {
                        'border-b': i !== length - 1,
                      })}
                      key={i}
                    >
                      <div className="w-2/12 font-black relative flex items-center justify-center flex-col">
                        <div className="text-lg">{getDay(el.plan?.date ?? el.date ?? '')}</div>
                        <div className="text-xs -mt-2">
                          {formatDate(el.plan?.date ?? el.date, {
                            includeDay: false,
                            shortMonth: true,
                            shortYear: true,
                          })}
                        </div>

                        {statusDate && i !== length - 1 && closestElementToStatusDate === el && (
                          <div className="absolute bg-yellow-400 text-white text-xxs rounded-full px-2 py-[0.10rem] mt-[60px]">
                            {t('projectCalculateTransactionplanStatusDate')}
                          </div>
                        )}
                      </div>
                      <div className="w-1/12 pl-4 flex items-center justify-center relative h-full">
                        {length > 1 && (
                          <>
                            {i !== 0 && (
                              <div
                                className={classNames('absolute t-0 w-2 z-0 h-1/2 -mt-[30px]', {
                                  'bg-green-200': el.datedPayments,
                                  'bg-gray-200': !el.datedPayments,
                                })}
                              />
                            )}
                            {i !== length - 1 && (
                              <div
                                className={classNames('absolute t-0 w-2 z-0 h-1/2 mt-[30px]', {
                                  'bg-green-200':
                                    el.datedPayments && element?.financeTimeline?.elements[i + 1].datedPayments,
                                  'bg-gray-200':
                                    !el.datedPayments || !element?.financeTimeline?.elements[i + 1].datedPayments,
                                })}
                              />
                            )}
                          </>
                        )}
                        <div
                          className={classNames(
                            'rounded-full w-4 h-4 z-10 border-2 bg-white flex items-center justify-center',
                            {
                              'border-green-500': el.datedPayments,
                              'border-gray-500': !el.datedPayments,
                            }
                          )}
                        >
                          {el.datedPayments && (
                            <svg
                              className="w-4 h-4 text-green-500"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                          )}
                        </div>
                      </div>
                      <div className="w-1/12">
                        {plannedToDate !== undefined &&
                          (statusDate ? closestElementToStatusDate === el : calculatePlannedELement === el) &&
                          calculatePlannedELement !== calculatePaymentELement && (
                            <div
                              className={classNames('flex items-end h-full', {
                                'text-green-500': paymentToDate !== undefined && plannedToDate >= paymentToDate,
                                'text-red-500':
                                  (paymentToDate !== undefined && plannedToDate > paymentToDate) ||
                                  paymentToDate === undefined,
                              })}
                            >
                              <span className="font-bold text-xs">{t('projectCalculateTransactionplanShould')}</span>
                              <span className="font-extrabold italic text-lg leading-tight tracking-tighter">
                                {formatPercentage(plannedToDate, { maxDigits: 0 })}
                              </span>
                            </div>
                          )}

                        {paymentToDate !== undefined && calculatePaymentELement === el && (
                          <div
                            className={classNames('flex items-end h-full', {
                              'text-green-700': plannedToDate !== undefined && plannedToDate < paymentToDate,
                              'text-red-700':
                                (plannedToDate !== undefined && plannedToDate >= paymentToDate) ||
                                plannedToDate === undefined,
                            })}
                          >
                            <span className="font-bold text-xs">{t('projectCalculate.financeTimelineActual')}</span>
                            <span className="font-extrabold italic text-lg leading-tight tracking-tighter">
                              {formatPercentage(paymentToDate, { maxDigits: 0 })}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="w-4/12 font-bold text-slate-500 flex items-center justify-center flex-col">
                        <div className="flex flex-row justify-end w-full"><FormattedCurrency amount={el.plan?.value} /></div>
                        <div className="flex flex-row justify-end w-full">
                          <div className="text-xxs">
                            {el.plan?.date &&
                              new Date(statusDate ?? '') > new Date(el.plan?.date) &&
                              t('projectCalculateTransactionplanFixed')}
                            {el.plan?.date &&
                              new Date(statusDate ?? '') < new Date(el.plan?.date) &&
                              t('projectCalculateTransactionplanPlanned')}
                            {!el.plan?.date && t('projectCalculateTransactionplanNoPlan')}
                          </div>
                        </div>
                      </div>

                      <div className="w-4/12 font-bold flex items-center justify-center flex-col">
                        <div className="flex flex-row justify-end w-full">
                          <FormattedCurrency amount={paymentSum} />
                        </div>
                        <div className="flex flex-row justify-end w-full text-xxs text-slate-500">
                          {el.plan &&
                            paymentSum &&
                            paymentSum !== el.plan?.value && (
                              <>
                                <span
                                  className={classNames({
                                    'text-red-500': paymentSum < el.plan?.value,
                                    'text-green-500': paymentSum > el.plan?.value,
                                  })}
                                >
                                  {paymentSum > el.plan?.value && '+'}
                                  {formatPercentage(getPercentage(el.plan?.value, paymentSum), {
                                    maxDigits: 0,
                                  })}
                                </span>
                                <span className="mx-1">&bull;</span>
                              </>
                            )}
                          <span>{getPaymentTime(el)}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="w-full flex flex-col text-right p-2 mb-10">
                <div className="font-bold text-2xl"><FormattedCurrency amount={isNet ? element.financeTimeline.totalPaymentNet : element.financeTimeline.totalPaymentGross} /></div>
              </div>
            </>
          )}
        </div>
      </SlideOver.Content>
      <SlideOver.Controls>
        <div className={classNames('w-full flex justify-end')}>
          <div className="flex">
            <Button variant="primary" onClick={() => onClose(false)} className="mr-2">
              {t('common.close')}
            </Button>
          </div>
        </div>
      </SlideOver.Controls>
    </>
  );
};
