import { useLoadedProjectId } from "@client/project/store";
import { 
    useApiGetAuditLogsByKeyQuery, 
    AuditLogTarget, 
    AuditLogReadModel, 
    useApiGetAuditLogsByTargetQuery 
} from "@client/shared/api";
import { LoadingIndicator } from "@client/shared/toolkit";
import { AuditLogListItem } from "./AuditLogListItem";
import { t } from "i18next";
import { useEffect, useState } from "react";

interface AuditLogProps {
    id? : string;
    targetType : AuditLogTarget
}

type AuditLogDateGroup = {
    date: string;
    auditLogEntries: AuditLogReadModel[];
}

export const AuditLog = ({ id, targetType }: AuditLogProps) => {

    const [groupedLogEntries, setGroupedLogEntries] = useState<AuditLogDateGroup[]>([]);

    const loadedProjectId = useLoadedProjectId();
    const { data : dataByKey, isLoading : isLoadingDataByKey} = useApiGetAuditLogsByKeyQuery({
        projectId : loadedProjectId ?? '',
        target : targetType,
        key : id ?? ''
    }, {
        skip : !loadedProjectId || !id
    })

    const { data : dataByTargetType, isLoading : isLoadingDataByTargetType} = useApiGetAuditLogsByTargetQuery({
        projectId : loadedProjectId ?? '',
        target : targetType
    }, {
        skip : !loadedProjectId || !!id
    })    

    useEffect(() => {
        const source = id ? dataByKey : dataByTargetType;
        const groups : AuditLogDateGroup[] = [];
        
        source?.forEach((entry) =>{
            const date = new Date(entry.dateTime).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric", weekday: "long"});
            const existingGroup = groups.find(x => x.date === date);

            if (existingGroup){
                existingGroup.auditLogEntries.push(entry);
            } else {
                groups.push({ date : date, auditLogEntries : [ entry ] });
            }            
        });

        setGroupedLogEntries(groups);
    }, [id, dataByKey, dataByTargetType]);

    return (
        <>
            {(isLoadingDataByKey || isLoadingDataByTargetType) ? (
                <LoadingIndicator text={t('auditLog.loadingHistory')}/>
            ) : (
                <>
                    {groupedLogEntries.map((group) => (
                        <div key={group.date} className="flex flex-col w-full">
                            <div className="flex flex-row text-slate-500 items-center justify-between">
                                <HorizontalLine/>
                                <div className="font-bold mx-3 text-nowrap">{group.date}</div>
                                <HorizontalLine/>
                            </div>
                            {group.auditLogEntries.map((auditLogEntry, index) => (
                                <AuditLogListItem
                                    key={index}
                                    auditLogEntry={auditLogEntry}
                                />
                            ))}
                        </div>
                    ))}
                </>
            )}
        </>
    );
}

export const HorizontalLine = () => {
    return <hr className="w-full" style={{ borderColor: 'gray', height: 1 }}/>
};