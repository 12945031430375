import { getAssignedSpaceOrAmount, getUsedRentalSpaceInDeal } from '../DealCalculation';
import { formatUnit, safeMutation } from '@client/shared/utilities';
import {
  DealReadModel,
  CalculationTarget,
  ContractItemCategory,
  DealContractItemReadModel,
  useApiPostCreateRentDealContractItemMutation,
  useApiPostUpdateRentDealContractItemMutation,
  useApiPostCreateSellDealContractItemMutation,
  useApiPostUpdateSellDealContractItemMutation
} from '@client/shared/api';
import classNames from 'classnames';
import { useState, useRef } from 'react';
import {
  Button,
  ResumeIcon,
  Form,
  TextInput,
  BaseSelect,
  NumberInput,
  FormField,
  FormWatch,
  Modal,
  SlideOverOnCloseProps,
  SlideOver,
  CashIcon, TagWindowIcon,
  PaidParkingDottedIcon, ExpandIcon,
  LoadingIndicator,
} from '@client/shared/toolkit';
import { getCalculationTargetValueLabel, getCalculationTargetFactorLabel } from '../../utils';
import { DealContractItemDeleteModal } from './DealContractItemDeleteModal';
import { useTranslation } from 'react-i18next';
import {
  DealContractItemFormValidationSchema,
  DealContractItemFormValidationValues,
} from './DealContractItemFormValidationSchema';
import { useLoadedProjectId, useLoadedProjectUnitSystemSymbol } from '@client/project/store';
import { useCanDeleteDeal, useCanWriteDeal } from '../../hooks';
import { ProjectTaxPickerInput } from '@client/project/shared';

export const getCalculationTargetIcon = (calculation: CalculationTarget) => {
  switch (calculation) {
    case 'Amount':
      return <PaidParkingDottedIcon />
    case 'RentalSpace':
    case 'CommonSpace':
      return <ExpandIcon />
    default:
      return <CashIcon />
  }
};

interface DealContractItemSlideOverProps extends SlideOverOnCloseProps {
  deal: DealReadModel;
  isAddMode: boolean;
  dealId: string;
  category: ContractItemCategory;
  contractItem?: DealContractItemReadModel;
  isReadOnly: boolean;
}

export const DealContractItemSlideOver = ({
  deal,
  category,
  dealId,
  isAddMode,
  contractItem,
  onClose,
  isReadOnly,
}: DealContractItemSlideOverProps) => {
  const { t } = useTranslation();

  const unitSystem = useLoadedProjectUnitSystemSymbol();
  const submitRef = useRef<HTMLButtonElement>(null);

  const availableCalculationTargets: { label: string; value: CalculationTarget }[] = [
    { label: getCalculationTargetValueLabel('RentalSpace'), value: 'RentalSpace' },
    { label: getCalculationTargetValueLabel('CommonSpace'), value: 'CommonSpace' },
    { label: getCalculationTargetValueLabel('Amount'), value: 'Amount' },
    { label: getCalculationTargetValueLabel('Value'), value: 'Value' },
  ];

  const projectId = useLoadedProjectId();
  const readOnly = !useCanWriteDeal(deal.type) || isReadOnly;
  const canDelete = useCanDeleteDeal(deal.type);

  const [createRentContractItem, { isLoading: isCreatingRent }] = useApiPostCreateRentDealContractItemMutation();
  const [updateRentContractItem, { isLoading: isUpdatingRent }] = useApiPostUpdateRentDealContractItemMutation();
  const [createSellContractItem, { isLoading: isCreatingSell }] = useApiPostCreateSellDealContractItemMutation();
  const [updateSellContractItem, { isLoading: isUpdatingSell }] = useApiPostUpdateSellDealContractItemMutation();

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  const getDealRemainingSpace = (
    deal: DealReadModel,
    calculation: CalculationTarget,
    category: ContractItemCategory,
    value: number | undefined,
    contractId: string | undefined
  ) => {
    const availableRentalSpace = getAssignedSpaceOrAmount(deal.costItems, calculation);
    let usedSpace = getUsedRentalSpaceInDeal(deal.contractItems, category, calculation, contractId);

    if (value && value >= 0) usedSpace += value;

    return availableRentalSpace - usedSpace;
  };

  const handleSubmit = async (data: DealContractItemFormValidationValues) => {
    switch (deal.type) {
      case 'Rent':
        handleRentSubmit(data);
        break;
      case 'Sell':
        handleSellSubmit(data);
        break;
    }
  };

  const handleSellSubmit = async (data: DealContractItemFormValidationValues) => {
    try {
      if (isAddMode) {
        await safeMutation(
          createSellContractItem,
          {
            projectId: projectId ?? 'unset',
            dealId: dealId,
            body: {
              name: data.name,
              category: category,
              calculation: data.calculation as CalculationTarget,
              factor: data.calculation === 'Value' ? 1 : data.factor ?? 0,
              value: data.value ?? 0,
              vat: data.vat ?? 0,
            },
          },
          isCreatingSell
        );
      } else {
        await safeMutation(
          updateSellContractItem,
          {
            projectId: projectId ?? 'unset',
            dealId: dealId,
            contractItemId: contractItem?.id ?? '',
            body: {
              name: data.name,
              category: category,
              calculation: data.calculation as CalculationTarget,
              factor: data.calculation === 'Value' ? 1 : data.factor ?? 0,
              value: data.value ?? 0,
              vat: data.vat ?? 0,
            },
          },
          isUpdatingSell
        );
      }

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRentSubmit = async (data: DealContractItemFormValidationValues) => {
    try {
      if (isAddMode) {
        await safeMutation(
          createRentContractItem,
          {
            projectId: projectId ?? 'unset',
            dealId: dealId,
            body: {
              name: data.name,
              category: category,
              calculation: data.calculation as CalculationTarget,
              factor: data.calculation === 'Value' ? 1 : data.factor ?? 0,
              value: data.value ?? 0,
              vat: data.vat ?? 0,
            },
          },
          isCreatingRent
        );
      } else {
        await safeMutation(
          updateRentContractItem,
          {
            projectId: projectId ?? 'unset',
            dealId: dealId,
            contractItemId: contractItem?.id ?? '',
            body: {
              name: data.name,
              category: category,
              calculation: data.calculation as CalculationTarget,
              factor: data.calculation === 'Value' ? 1 : data.factor ?? 0,
              value: data.value ?? 0,
              vat: data.vat ?? 0,
            },
          },
          isUpdatingRent
        );
      }

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const restSpace = getDealRemainingSpace(
    deal,
    contractItem?.calculation ?? 'RentalSpace',
    contractItem?.category ?? 'Rent',
    contractItem?.value.value ?? 0,
    contractItem?.id
  );

  const defaultFormValues = {
    name: contractItem?.name ?? '',
    calculation: contractItem?.calculation ?? 'RentalSpace',
    factor: contractItem?.factor.value?? 0,
    vat: contractItem?.vat,
    value: contractItem?.value.value ?? restSpace,
  };

  return (
    <>
      <SlideOver.Header
        title={t('projectRent.dealContractItemEditTitle')}
        backgroundClassName="bg-sky-900"
        onClose={onClose}
      />

      <Form<DealContractItemFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={DealContractItemFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
      >
        <SlideOver.Content>
        {(isCreatingRent || isUpdatingRent || isCreatingSell || isUpdatingSell)  && <LoadingIndicator text={t('projectRent.dealContractItemSaving') ?? ''} mode="overlay" />}
          <div className="overflow-y-auto flex-grow">
            <div className="m-8 bg-white">
              <div className="divide-gray-100 divide-y">
                <FormField name="name">
                  {(control) => (
                    <TextInput
                      disabled={readOnly}
                      label={t('projectRent.dealContractItemLabelName')}
                      icon={<TagWindowIcon />}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="calculation">
                  {(control) => (
                    <BaseSelect
                      disabled={readOnly}
                      label={t('projectRent.dealContractItemLabelTargetValue')}
                      options={availableCalculationTargets}
                      icon={<ResumeIcon />}
                      {...control}
                    />
                  )}
                </FormField>
                <FormWatch<DealContractItemFormValidationValues> fieldNames={['calculation']}>
                  {({ calculation }) => (
                    <>
                      <div className="flex">
                        <FormField name="value">
                          {(control) => (
                            <NumberInput
                              disabled={readOnly}
                              className="w-10/12"
                              label={getCalculationTargetValueLabel(calculation as CalculationTarget)}
                              icon={getCalculationTargetIcon(calculation as CalculationTarget)}
                              {...control}
                            />
                          )}
                        </FormField>
                        {calculation !== 'Value' && (
                          <div className="w-2/12 pt-3 mt-[3px] top-0 text-gray-600 truncate pr-4 text-right">
                            <FormWatch<DealContractItemFormValidationValues> fieldNames={['value']}>
                              {({ value }) => (
                                <>
                                  {getDealRemainingSpace(
                                    deal,
                                    calculation as CalculationTarget,
                                    category,
                                    value,
                                    contractItem?.id
                                  ) === 0 && (
                                    <div className="text-sm text-gray-600 text-right">
                                      <div className="text-xs">+/- 0</div>
                                      Rest
                                    </div>
                                  )}

                                  {getDealRemainingSpace(
                                    deal,
                                    calculation as CalculationTarget,
                                    category,
                                    value,
                                    contractItem?.id
                                  ) > 0 && (
                                    <div className="text-sm text-gray-600 text-right">
                                      <div className="text-xs text-green-800">
                                        +{' '}
                                        {formatUnit(
                                          getDealRemainingSpace(
                                            deal,
                                            calculation as CalculationTarget,
                                            category,
                                            value,
                                            contractItem?.id
                                          ),
                                          calculation && calculation !== 'RentalSpace' && calculation !== 'CommonSpace'
                                            ? t('projectRent.dealContractItemPieces')
                                            : unitSystem
                                        )}
                                      </div>
                                      Rest
                                    </div>
                                  )}

                                  {getDealRemainingSpace(
                                    deal,
                                    calculation as CalculationTarget,
                                    category,
                                    value,
                                    contractItem?.id
                                  ) < 0 && (
                                    <div className="text-sm text-gray-600 text-right">
                                      <div className="text-xs text-red-800">
                                        -{' '}
                                        {formatUnit(
                                          getDealRemainingSpace(
                                            deal,
                                            calculation as CalculationTarget,
                                            category,
                                            value,
                                            contractItem?.id
                                          ),
                                          calculation && calculation !== 'RentalSpace' && calculation !== 'CommonSpace'
                                            ? t('projectRent.dealContractItemPieces')
                                            : unitSystem,
                                          { signDisplay: 'never' }
                                        )}
                                      </div>
                                      Rest
                                    </div>
                                  )}
                                </>
                              )}
                            </FormWatch>
                          </div>
                        )}
                      </div>

                      {calculation !== 'Value' && (
                        <FormField name="factor">
                          {(control) => (
                            <NumberInput
                              disabled={readOnly}
                              label={getCalculationTargetFactorLabel(calculation as CalculationTarget, unitSystem)}
                              icon={<CashIcon />}
                              {...control}
                            />
                          )}
                        </FormField>
                      )}
                    </>
                  )}
                </FormWatch>
                <FormField name="vat">
                  {(control) => (
                    <ProjectTaxPickerInput
                      disabled={readOnly}
                      {...control}
                    />
                  )}
                </FormField>
              </div>
            </div>
          </div>
        </SlideOver.Content>

        <SlideOver.Controls>
          <div className={classNames('flex-grow flex', contractItem ? 'justify-between' : 'justify-end')}>
            {!isAddMode && canDelete && !isReadOnly && (
              <Button variant="warning" onClick={() => setIsOpenDeleteModal(true)}>
                {t('common.delete')}
              </Button>
            )}
            <div className="flex">
              <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
                {t('common.cancel')}
              </Button>
              {!readOnly && (
                <Button variant="primary" formSubmit={true} innerRef={submitRef}>
                  {isAddMode ? t('common.add') : t('common.save')}
                </Button>
              )}
            </div>
          </div>
        </SlideOver.Controls>
      </Form>

      {!isAddMode && contractItem && (
        <Modal isOpen={isOpenDeleteModal} onClose={() => setIsOpenDeleteModal(false)} variant="small">
          <DealContractItemDeleteModal
            onClose={() => setIsOpenDeleteModal(false)}
            item={contractItem}
            dealId={dealId}
            type={deal.type}
          />
        </Modal>
      )}
    </>
  );
};
