import React, { cloneElement, ReactElement, ReactNode } from 'react';
import cn from 'classnames';

export interface CardsListProps {
  className?: string
  cards: ReactNode[]
}
export const CardsList = (props: CardsListProps) => {
  const {
    className,
    cards
  } = props
  if (!cards.length) return null
  return (
    <div className={cn('flex flex-wrap gap-8', className)}>
      {cards.map((card, i) => {
        return cloneElement(card as ReactElement, { key: `cards-list-${i}`} )
      })}
    </div>
  )
}
