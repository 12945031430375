import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface FormFieldProps {
  name: string;
  children: ({
    onChange,
    value,
    helperText,
    isValidationValid,
    showValidation,
    className,
  }: {
    /* FP: This explicit any is necessary because of the typing in the library */
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    onChange: (...event: any[]) => void;
    /* FP: This explicit any is necessary because watched components can return anything on their onChange function */
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    value: any;
    helperText?: string;
    isValidationValid?: boolean;
    showValidation?: boolean;
    className?: string;
  }) => React.ReactElement;
}

export const FormField = ({ children, name }: FormFieldProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState, formState }) =>
        children({
          onChange: field.onChange,
          value: field.value,
          helperText:
            formState.isSubmitted && fieldState.error && fieldState.error.message
              ? t(fieldState.error.message)
              : undefined,
          isValidationValid: !fieldState.invalid,
          showValidation: formState.isSubmitted,
        })
      }
    />
  );
};
