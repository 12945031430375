import { Routes, Route, Navigate } from 'react-router-dom';
import { ProtectedRoute, ROUTES_CONFIG } from '@client/shared/permissions';
import { ProjectingRoute } from '@client/portfolio/projecting';
import { PropertyRoute } from '@client/portfolio/property';
import { PortfolioTasksRoute } from '@client/portfolio/tasks';

export const PortfolioRoutes = () => {
  return (
    <Routes>
      <Route
        path={`${ROUTES_CONFIG.PORTFOLIO_PROJECTING.name}/*`}
        element={
          <ProtectedRoute routeConfig={ROUTES_CONFIG.PORTFOLIO_PROJECTING}>
            <ProjectingRoute />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${ROUTES_CONFIG.PORTFOLIO_PROPERTY.name}/*`}
        element={
          <ProtectedRoute routeConfig={ROUTES_CONFIG.PORTFOLIO_PROPERTY}>
            <PropertyRoute />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${ROUTES_CONFIG.PORTFOLIO_PROPERTY.name}/*`}
        element={
          <ProtectedRoute routeConfig={ROUTES_CONFIG.PORTFOLIO_PROPERTY}>
            <PropertyRoute />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${ROUTES_CONFIG.PORTFOLIO_TASKS.name}/*`}
        element={
          <ProtectedRoute routeConfig={ROUTES_CONFIG.PORTFOLIO_TASKS}>
            <PortfolioTasksRoute />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to={ROUTES_CONFIG.PORTFOLIO_PROPERTY.name} replace />} />
    </Routes>
  );
};
