import cn from 'classnames';
import { HeaderButton } from './Buttons';

interface ToggleSliderProps {
  left: boolean;
  onClick: () => void;
  headerLabel?: string;
  labelLeft: string;
  labelRight: string;
  disabled?: boolean
}

export const ToggleSlider = ({ left, onClick, headerLabel, labelLeft, labelRight, disabled = false }: ToggleSliderProps) => {
  return (
    <HeaderButton
      title={headerLabel}
      showToggle={false}
      >
      <div className="w-fit flex flex-row items-center h-4 overlow-visible cursor-default">
        <div
          className={`text-left font-semibold text-sm leading-tight whitespace-nowrap h-4 my-auto ${
            left ? 'text-gray-400' : 'text-gray-300'
          }`}
        >
          {labelLeft}
        </div>
        <div className={cn('w-8 h-4 mx-2 bg-gray-400 rounded-full relative', disabled ? 'cursor-not-allowed' : 'cursor-pointer')} onClick={disabled ? undefined : onClick}>
          <div
            className={`absolute tight-0 top-0 h-full w-4 bg-white rounded-full shadow-md transition-transform ${
              left ? '' : 'translate-x-full'
            }`}
          />
        </div>
        <div
          className={`text-left font-semibold text-sm leading-tight whitespace-nowrap h-4 my-auto ${
            !left ? 'text-gray-400' : 'text-gray-300'
          }`}
        >
          {labelRight}
        </div>
      </div>
    </HeaderButton>
  );
};
