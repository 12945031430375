import { ShortCommitmentReadModel, useApiDeleteCommitmentMutation } from '@client/shared/api';
import { HintBox, ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';

interface CommitmentDeleteModalProps extends ModalOnCloseProps {
  commitment: ShortCommitmentReadModel;
}

export const CommitmentDeleteModal = ({ commitment, onClose }: CommitmentDeleteModalProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const [postDeleteCommitment, { isLoading }] = useApiDeleteCommitmentMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    if (commitment?.id && loadedProjectId && loadedVariantId) {
      try {
        await safeMutation(
          postDeleteCommitment,
          {
            commitmentId: commitment.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
          },
          isLoading,
        );
        onClose(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <ConfirmModal
      title={t('projectCommitment.deleteTitle')}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      {isLoading && <LoadingIndicator text={t('common.deleteLoading') ?? ''} mode="overlay" />}
      <HintBox hintType="warning">{t('projectCommitment.deleteHint')}</HintBox>
    </ConfirmModal>
  );
};
