import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SetProjectPayload = { projectId: string | undefined; variantId: string | undefined };

export interface ProjectState {
  loadedProjectId?: string;
  loadedVariantId?: string;
}

const initialState: ProjectState = {};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectId: (state, action: PayloadAction<SetProjectPayload | undefined>) => {
      // if project id changes, remove variant information
      state.loadedProjectId = action.payload?.projectId;
      state.loadedVariantId = action.payload?.variantId;
    },
    setVariantId: (state, action: PayloadAction<string>) => {
      state.loadedVariantId = action.payload;
    },
  },
});

export const { setProjectId, setVariantId } = projectSlice.actions;
