import { useState, useLayoutEffect } from 'react';

interface ScrollPosition {
  x: number;
  y: number;
}

/**
 * useScrollPosition is a custom hook that returns the x and y scroll position of the element passed in the containerRef.
 * It uses the requestAnimationFrame API to throttle scroll events and avoid performance issues.
 * @param containerRef A ref of the element to track the scroll position of.
 * @returns The x and y scroll position of the element.
 */
export const useScrollPosition = (containerRef: React.RefObject<HTMLElement>): ScrollPosition => {
  const [position, setScrollPosition] = useState<ScrollPosition>({ x: 0, y: 0 });

  useLayoutEffect(() => {
    const element = containerRef?.current;

    const handleScroll = () => {
      // Use requestAnimationFrame to limit the number of times this function is called
      window.requestAnimationFrame(() => {
        if (element) {
          setScrollPosition({ x: element.scrollLeft, y: element.scrollTop });
        }
      });
    };

    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerRef]);

  return position;
};
