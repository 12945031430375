import { UserDefinedFieldDefinitionGroup } from './CustomFields';
import { UserDefinedFieldDefinitionReadModel } from '@client/shared/api';
import { ContextMenu, Highlighted, TrashIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { getCustomFieldTypeIcon, getCustomFieldTypeSubtitle } from '@client/project/shared';

interface CustomFieldsListGroupProps {
  group: UserDefinedFieldDefinitionGroup;
  handleOnClickItem: (item: UserDefinedFieldDefinitionReadModel) => void
  handleOnDeleteItem: (item: UserDefinedFieldDefinitionReadModel) => void
  searchText?: string
}

export const CustomFieldsListGroup = (props: CustomFieldsListGroupProps) => {
  const {
    group,
    handleOnClickItem,
    handleOnDeleteItem,
    searchText
  } = props

  return (
    <div>
      <div className="flex gap-4 items-center bg-gray-100 w-full py-3 px-5">
        <div className="w-8">{group.icon}</div>
        <span className="font-bold">{group.title}</span>
      </div>
      <ul>
        {group.items.map((field, i) => (
          <CustomFieldsListGroupItem
            key={`custom-field-${group.title}-${field.name}-${i}`}
            field={field}
            handleOnClickItem={handleOnClickItem}
            handleOnDeleteItem={handleOnDeleteItem}
            searchText={searchText}
          />
        ))}
      </ul>
    </div>
  );
};


type CustomFieldsListGroupItemProps = {
  field: UserDefinedFieldDefinitionReadModel
  handleOnClickItem: (item: UserDefinedFieldDefinitionReadModel) => void
  handleOnDeleteItem: (item: UserDefinedFieldDefinitionReadModel) => void
  searchText?: string
}
export const CustomFieldsListGroupItem = (props: CustomFieldsListGroupItemProps) => {
  const { t } = useTranslation()
  const {
    field,
    handleOnClickItem,
    handleOnDeleteItem,
    searchText
  } = props

  const contextMenuItems = useMemo(() => {
    return [
      {
        label: t('common.delete'),
        subtitle: t('common.deleteElement'),
        icon: <TrashIcon />,
        onClick: () => {
          handleOnDeleteItem(field)
        },
      },
    ]
  }, [t, handleOnDeleteItem, field])

  const icon = useMemo(() => {
    const IconComponent = getCustomFieldTypeIcon(field.fieldType)
    return <IconComponent className="w-6" />
  }, [field.fieldType])

  return (
    <li className="flex w-full border-b border-gray-100 hover:bg-slate-100 cursor-pointer items-center py-3 px-5 gap-8 leading-6 justify-between">
      <div
        className="flex-1 flex items-center gap-8"
        onClick={() => handleOnClickItem(field)}
      >
        <div className="flex gap-4 items-center w-8/12">
          <div className="w-8 flex items-center justify-center flex-none">
            {icon}
          </div>
          <div className="flex flex-col">
            <span className="font-bold">{searchText ? <Highlighted text={field.name} highlight={searchText} /> : field.name}</span>
            <span className="text-slate-400">{getCustomFieldTypeSubtitle(field)}</span>
          </div>
        </div>
        <div className="text-slate-400 w-2/12">{searchText ? <Highlighted text={field.elementType} highlight={searchText} /> : field.elementType} {field.calculateElementType}</div>
        <div className="text-slate-400 w-2/12">{field.assignedProjects && field.assignedProjects?.length > 0 ? field.assignedProjects?.length : t('app.settingsCustomFieldAllProjects')}
        </div>
      </div>
      <div className="text-gray-400 hover:text-gray-600 px-1 w-6">
        <ContextMenu items={contextMenuItems} />
      </div>
    </li>
  )
}
