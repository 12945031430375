import React from 'react';
import { BadgeProps } from './Badges';
import { recursivelyApplyClassName } from '@client/shared/utilities';

export const BadgeGroup = ({
  children,
}: {
  children: React.ReactElement<BadgeProps>[] | React.ReactElement<BadgeProps>;
}) => {
  if (!Array.isArray(children)) {
    children = [children];
  }

  if (children.length === 0) {
    return null;
  }

  return (
    <div className="flex divide-x">
      {recursivelyApplyClassName(
        children,
        'rounded-none first:rounded-r-none first:rounded-l-full last:rounded-l-none last:rounded-r-full only:rounded-full'
      )}
    </div>
  );
};
