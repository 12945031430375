import { PlotReadModel, useApiDeletePlotMutation } from '@client/shared/api';
import { ConfirmModal, HintBox, LoadingIndicator, ModalOnCloseProps } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';

interface PlotDeleteModalProps extends ModalOnCloseProps {
  item: PlotReadModel;
}

export const PlotDeleteModal = ({ item, onClose }: PlotDeleteModalProps) => {
  const { t } = useTranslation();

  const loadedVariantId = useLoadedVariantId();
  const projectId = useLoadedProjectId();

  const [deletePlotPiece, { isLoading }] = useApiDeletePlotMutation();

  const handleDelete = async () => {
    if (item == null || loadedVariantId == null) return;

    try {
      await safeMutation(
        deletePlotPiece,
        {
          projectId: projectId ?? 'unset',
          calculationModelId: loadedVariantId,
          plotId: item.plotId,
          shouldDeleteChildren: false,
        },
        isLoading
      );

      onClose(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnClose = () => {
    onClose(false);
  };

  return (
    <ConfirmModal
      title={t('projectTaxonomy.deletePlotElementTitle', {
        element: `${item.plotName}`,
      })}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isLoading}
    >
      {isLoading && <LoadingIndicator text={t('projectTaxonomy.deletingTaxonomyLoadingIndicator')} mode="overlay-window" />}
      <p>{t('projectTaxonomy.plotConfirmDelete')}</p>
      <HintBox hintType="warning">{t('projectTaxonomy.deletePlotElementHint')}</HintBox>
    </ConfirmModal>
  );
};
