import { TaxonomyReadModel } from '@client/shared/api';
import { PlanCostsIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

interface RentalPeriodCardProps {
  item?: TaxonomyReadModel | undefined;
}

export const RentalPeriodCard = ({ item }: RentalPeriodCardProps) => {
  const { t } = useTranslation();

  return (
    <div className="rounded shadow p-4 w-full h-full flex flex-row bg-white ">
      <div className="w-full flex flex-row">
        <div className="flex justify-center items-center mr-4">
          <PlanCostsIcon className="h-8 w-8" />
        </div>
        <div className="w-full">
          <div className="text-xxs font-bold text-slate-500">{t('projectTaxonomy.dashboardRentalPeriod')}</div>
          <div className="h-7 text-base font-extrabold">-</div>
          <div className="text-xxs text-slate-500">-</div>
        </div>
      </div>
      <div className="w-2/6 text-right justify-end">
        <div className="text-xxs font-bold text-slate-500">-</div>
        <div className="h-7 text-xs font-extrabold flex items-end justify-end">-</div>
      </div>
    </div>
  );
};
