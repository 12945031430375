import {
  DecoratedCard,
  Collapsible,
  useComponentDimensions,
  LoadingIndicator,
  DocumentCustomIcon,
  Highlighted,
  LevelToggle,
} from '@client/shared/toolkit';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  useExpandedReportingIds,
  setExpandedVariationReportIds,
  useLoadedProjectId,
  useLoadedVariantId,
} from '@client/project/store';
import { ToggleButton } from '@client/shared/toolkit';
import { useDispatch } from 'react-redux';
import { ReportVariationSearchFilter } from '.';
import {
  ContractTitleReportReadModel,
  ContractTitleState,
  ContractWithContractorReportReadModel,
  useApiGetContractWithContractorReportQuery,
} from '@client/shared/api';
import { ReportingContext } from '..';
import { t } from 'i18next';

const getContractTitleStateTranslation = (state: ContractTitleState) => {
  switch (state) {
    case 'Expected':
      return t('projectContract.contractTitleStateExpected');
    case 'Optional':
      return t('projectContract.contractTitleStateOptional');
    case 'Announced':
      return t('projectContract.contractTitleStateAnnounced');
    case 'Budgeted':
      return t('projectContract.contractTitleStateBudgeted');
    case 'Received':
      return t('projectContract.contractTitleStateReceived');
    case 'Approved':
      return t('projectContract.contractTitleStateApproved');
    case 'Commissioned':
      return t('projectContract.contractTitleStateCommissioned');
    case 'Rejected':
      return t('projectContract.contractTitleStateRejected');
    case 'Canceled':
      return t('projectContract.contractTitleStateCanceled');
    case 'Reserve':
      return t('projectContract.contractTitleStateReserve');
    case 'None':
      return t('projectContract.contractTitleStateNone');
    default:
      return state;
  }
};

export const ReportVariation = () => {
  const dispatch = useDispatch();

  const { Variation: expandedElements } = useExpandedReportingIds();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const wrapper = useRef<HTMLDivElement>(null);
  const dimensions = useComponentDimensions(wrapper);

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [searchFilter, setSearchFilter] = useState<{ contract: string[]; contractor: string[]; state: string[] }>({
    contract: [],
    contractor: [],
    state: [],
  });

  const { data, isFetching } = useApiGetContractWithContractorReportQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId,
      refetchOnMountOrArgChange: true,
    },
  );

  const { setExportFilterConfig } = useContext(ReportingContext);

  useEffect(() => {
    setExportFilterConfig([
      {
        componentName: 'Contracts',
        filterMode: 'Or',
        filters: searchFilter.contract
          .map((contract) => ({ column: 'contractId', value1: contract }))
          .concat(searchFilter.contractor.map((contractor) => ({ column: 'contractorId', value1: contractor }))),
      },
      {
        componentName: 'Titles',
        filterMode: 'Or',
        filters: searchFilter.state
          .map((state) => ({ column: 'state', value1: state }))
          .concat(searchFilter.contract.map((contract) => ({ column: 'contractId', value1: contract })))
          .concat(searchFilter.contractor.map((contractor) => ({ column: 'contractorId', value1: contractor }))),
      },
    ]);
  }, [searchFilter, setExportFilterConfig]);

  const handleOnCollapse = (level: number) => {
    const expanded = level === 1 ? [] : data?.map((contract) => contract.contractId) ?? [];
    dispatch(setExpandedVariationReportIds(expanded));
  };

  const filteredData = useMemo(() => {
    if (!data) return [];
    return data
      .filter(
        (contract) =>
          (!searchValue ||
            contract.contractCode.toLowerCase().includes(searchValue.toLowerCase()) ||
            contract.contractName.toLowerCase().includes(searchValue.toLowerCase()) ||
            contract.contractorName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            contract.contractTitles.some(
              (title) =>
                title.code.toLowerCase().includes(searchValue.toLowerCase()) ||
                title.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                getContractTitleStateTranslation(title.state).toLowerCase().includes(searchValue.toLowerCase()),
            )) &&
          (!searchFilter.contract.length || searchFilter.contract.includes(contract.contractId)) &&
          (!searchFilter.contractor.length || searchFilter.contractor.includes(contract.contractorId ?? '')) &&
          (!searchFilter.state.length ||
            contract.contractTitles.some((title) => searchFilter.state.includes(title.state))),
      )
      .map((contract) => ({
        ...contract,
        contractTitles: contract.contractTitles.filter(
          (title) =>
            (!searchValue ||
              title.code.toLowerCase().includes(searchValue.toLowerCase()) ||
              title.name.toLowerCase().includes(searchValue.toLowerCase()) ||
              getContractTitleStateTranslation(title.state).toLowerCase().includes(searchValue.toLowerCase())) &&
            (!searchFilter.state.length || searchFilter.state.includes(title.state)),
        ),
      }));
  }, [data, searchFilter, searchValue]);

  const columnsLeft = [
    {
      value: 'contract',
      label: t('reporting.tableContract'),
    },
    {
      value: 'state',
      label: t('reporting.tableState'),
    },
    {
      value: 'title',
      label: t('reporting.tableTitle'),
    },
    {
      value: 'branch',
      label: t('reporting.tableBranch'),
    },
  ];

  const columnsRight = useMemo(() => {
    return [
      {
        value: 'claim',
        label: t('reporting.tableClaim'),
        totalNo: filteredData.reduce((acc, contract) => acc + contract.contractTitles.length, 0) ?? 0,
        total: filteredData.reduce((acc, contract) => acc + contract.offerNet, 0) ?? 0,
      },
      {
        value: 'expected',
        label: t('reporting.tableExpected'),
        totalNo:
          filteredData.reduce(
            (acc, contract) => acc + contract.contractTitles.filter((title) => title.state === 'Expected').length,
            0,
          ) ?? 0,
        total: filteredData.reduce(
          (acc, contract) =>
            acc +
            contract.contractTitles.reduce(
              (acc, title) => acc + (title.state === 'Expected' ? title.forecastValueNet : 0),
              0,
            ),
          0,
        ),
      },
      {
        value: 'announced',
        label: t('reporting.tableAnnounced'),
        totalNo:
          filteredData.reduce(
            (acc, contract) => acc + contract.contractTitles.filter((title) => title.state === 'Announced').length,
            0,
          ) ?? 0,
        total: filteredData.reduce(
          (acc, contract) =>
            acc +
            contract.contractTitles.reduce(
              (acc, title) => acc + (title.state === 'Announced' ? title.forecastValueNet : 0),
              0,
            ),
          0,
        ),
      },
      {
        value: 'submitted',
        label: t('reporting.tableSubmitted'),
        totalNo:
          filteredData.reduce(
            (acc, contract) => acc + contract.contractTitles.filter((title) => title.state === 'Received').length,
            0,
          ) ?? 0,
        total: filteredData.reduce(
          (acc, contract) =>
            acc +
            contract.contractTitles.reduce(
              (acc, title) => acc + (title.state === 'Received' ? title.contractValueNet : 0),
              0,
            ),
          0,
        ),
      },
      {
        value: 'approved',
        label: t('reporting.tableChecked'),
        totalNo:
          filteredData.reduce(
            (acc, contract) => acc + contract.contractTitles.filter((title) => title.state === 'Approved').length,
            0,
          ) ?? 0,
        total: filteredData.reduce(
          (acc, contract) =>
            acc +
            contract.contractTitles.reduce(
              (acc, title) => acc + (title.state === 'Approved' ? title.contractValueNet : 0),
              0,
            ),
          0,
        ),
      },
      {
        value: 'commissioned',
        label: t('reporting.tableCommissioned'),
        totalNo:
          filteredData.reduce(
            (acc, contract) => acc + contract.contractTitles.filter((title) => title.state === 'Commissioned').length,
            0,
          ) ?? 0,
        total: filteredData.reduce(
          (acc, contract) =>
            acc +
            contract.contractTitles.reduce(
              (acc, title) => acc + (title.state === 'Commissioned' ? title.contractValueNet : 0),
              0,
            ),
          0,
        ),
      },
      {
        value: 'rejected',
        label: t('reporting.tableRejected'),
        total: filteredData.reduce(
          (acc, contract) => acc + contract.contractTitles.filter((title) => title.state === 'Rejected').length,
          0,
        ),
      },
      {
        value: 'canceled',
        label: t('reporting.tableCancelled'),
        total: filteredData.reduce(
          (acc, contract) => acc + contract.contractTitles.filter((title) => title.state === 'Canceled').length,
          0,
        ),
      },
      {
        value: 'forecast',
        label: t('reporting.tableForecast'),
        total: filteredData.reduce((acc, contract) => acc + contract.forecastValueNet, 0),
      },
      {
        value: 'claimRejected',
        label: t('reporting.tableClaimRejected'),
        total: filteredData.reduce((acc, contract) => acc + contract.offerNet - contract.forecastValueNet, 0),
      },
    ];
  }, [filteredData]);

  return (
    <div className="min-w-[1383px] flex gap-2">
      {isFetching && <LoadingIndicator text={t('reporting.loadingReport')} mode="overlay" />}
      <DecoratedCard className="w-4/12 min-w-[500px]">
        <DecoratedCard.Header showActionButton={false}>
          <div className="h-32 flex justify-start items-center w-full truncate">
            {t('reporting.reportVariation.fullTitle')}
            <div className="pdf-export-hidden">
              <LevelToggle handleOnCollapse={handleOnCollapse} showLevels={[1, 2]} />
            </div>
          </div>
        </DecoratedCard.Header>
        <DecoratedCard.Content className="w-full h-full flex relative">
          <div className="relative w-full" ref={wrapper}>
            <div
              className="w-2 my-4 rounded -ml-1 left-0 absolute bg-sky-800"
              style={{ height: (dimensions.height ?? 15) - 32 }}
            />
            <div className="ml-4 mt-5 text-[22px] font-bold flex pl-7 text-sky-700">
              {t('reporting.reportVariation.description')}
            </div>
            <div className="h-14 bg-white border-b border-b-slate-300 pb-2 flex pl-9 w-full truncate">
              <div className="w-full h-full flex items-start justify-end">
                {columnsLeft?.map((column, idx) => (
                  <div
                    className={classNames(
                      'h-full flex flex-col justify-end px-1.5 truncate border-r border-slate-300',
                      {
                        'w-2/12 border-r-0 text-[11px] text-slate-500': idx === 0,
                        'w-2/12 text-[11px] text-slate-500 text-right': idx === 1,
                        'w-5/12 font-bold text-[15px]': idx === 2,
                        'w-3/12 font-bold text-[15px] border-r-0': idx === 3,
                      },
                    )}
                    key={`report-detail-column-header-left'}-${idx}`}
                  >
                    {column.label}
                  </div>
                ))}
              </div>
            </div>

            {filteredData.map((element, i) => (
              <VariationRowLeft
                contract={element}
                key={`report-cost-left-${element.contractId}`}
                expandedElements={expandedElements}
                searchValue={searchValue}
              />
            ))}

            <div className="h-14 w-full pl-12 flex items-center text-[18px] font-bold text-sky-700">
              {t('reporting.reportVariation.total')}
            </div>
          </div>
        </DecoratedCard.Content>
      </DecoratedCard>
      <div className="w-8/12 bg-white rounded-md shadow-lg border-gray-200 divide-y flex flex-col min-w-[1000px]">
        <div className="mb-1.5 h-16 flex flex-shrink-0 z-5">
          <div className="flex items-center justify-end relative w-full pdf-export-hidden">
            <ReportVariationSearchFilter
              data={data}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchFilters={searchFilter}
              setSearchFilters={(contract, contractor, state) => {
                setSearchFilter({ contract, contractor, state });
              }}
            />
            {/* <div className="font-bold text-[15px] pr-6">{t('reporting.showBy')}</div> */}
          </div>
        </div>
        <div className="relative w-full" ref={wrapper}>
          <div className="ml-4 mt-5 text-[22px] font-bold text-sky-700">{t('reporting.tableSupplement')}</div>
          <div className="h-14 bg-white border-b border-b-slate-300 pb-2 flex-grow flex items-center justify-end pb">
            {columnsRight.map((column, idx) => (
              <div
                className={classNames(
                  'h-full flex justify-end items-end px-1.5 border-r border-slate-300 gap-1 truncate',
                  {
                    'flex-grow basis-0 min-w-[28px] justify-between': idx < 6,
                    'w-[100px]': idx > 5,
                    'border-r-0': idx === columnsRight.length - 1,
                  },
                )}
                key={`report-detail-column-header-left'}-${idx}`}
              >
                {idx < 6 && <span className="text-[11px] text-slate-500">{t('reporting.variationNo')}</span>}
                <span className="text-right font-bold text-[15px] text-wrap truncate">{column.label}</span>
              </div>
            ))}
          </div>

          {filteredData.map((element) => (
            <VariationRowRight
              contract={element}
              key={`report-cost-rigth-${element.contractId}`}
              expandedElements={expandedElements}
            />
          ))}

          <div className="h-14 bg-white flex items-center hover:bg-slate-50 transition-colors font-bold text-[14px] flex-grow justify-center">
            {columnsRight?.map((column, idx) => (
              <div
                key={idx}
                className={classNames('h-full items-center flex justify-end py-2 text-right px-2', {
                  'flex-grow basis-0 min-w-[28px] justify-between': idx < 6,
                  'w-[100px]': idx > 5,
                })}
              >
                <span>{column.totalNo}</span>
                <span>{column.total}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

interface VariationRowProps {
  contract?: ContractWithContractorReportReadModel;
  title?: ContractTitleReportReadModel;
  expandedElements: string[];
  searchValue?: string;
}

const VariationRowLeft = ({ contract, title, expandedElements, searchValue }: VariationRowProps) => {
  const constractColumns = contract
    ? [
        <span className="px-1.5">
          <Highlighted text={contract.contractCode} highlight={searchValue ?? ''} />
        </span>,
        null,
        <span className="line-clamp-2 px-1.5">
          <Highlighted text={contract.contractName} highlight={searchValue ?? ''} />
        </span>,
        <span className="line-clamp-2 px-1.5">
          <Highlighted text={contract.contractorName ?? ''} highlight={searchValue ?? ''} className="" />
        </span>,
      ]
    : [];

  const titleColumns = title
    ? [
        <span className="flex pl-3 pr-1 gap-1 truncate">
          <DocumentCustomIcon className="h-4 min-w-4" />
          <Highlighted text={title.code} highlight={searchValue ?? ''} />
        </span>,
        <span className="truncate px-1.5 text-right">
          <Highlighted text={getContractTitleStateTranslation(title.state)} highlight={searchValue ?? ''} />
        </span>,
        <span className="line-clamp-2 px-1.5">
          <Highlighted text={title.name} highlight={searchValue ?? ''} />
        </span>,
        null,
      ]
    : [];

  const columns = contract ? constractColumns : titleColumns;

  const dispatch = useDispatch();

  return (
    <>
      <div
        className={classNames('flex items-center pl-1 hover:bg-slate-50 transition-colors', {
          'bg-gray-50 font-bold border-b border-dotted border-slate-300 text-[14px]': !title,
          'bg-white border-b border-slate-300 border-dotted text-[12px]': title,
        })}
      >
        {contract && contract.contractTitles.length > 0 ? (
          <ToggleButton
            open={expandedElements.includes(contract?.contractId)}
            onClick={() =>
              dispatch(
                setExpandedVariationReportIds(
                  expandedElements.includes(contract?.contractId)
                    ? expandedElements.filter((x) => x !== contract?.contractId)
                    : [...expandedElements, contract?.contractId],
                ),
              )
            }
            className=""
          />
        ) : (
          <div className="w-8">&nbsp;</div>
        )}
        <div className="flex w-full h-10">
          {columns?.map((column, idx) => (
            <div
              key={idx}
              className={classNames('h-full flex flex-col justify-center border-r border-dotted border-slate-300', {
                'w-2/12 text-[12px] border-r-0': idx === 0,
                'w-2/12 text-[12px]': idx === 1,
                'w-5/12': idx === 2,
                'w-3/12 border-r-0': idx === 3,
              })}
            >
              {column}
            </div>
          ))}
        </div>
      </div>

      {contract && contract.contractTitles.length > 0 && (
        <Collapsible open={expandedElements.includes(contract?.contractId ?? '')}>
          {contract.contractTitles?.map((title, idx) => (
            <VariationRowLeft key={idx} title={title} expandedElements={expandedElements} searchValue={searchValue} />
          ))}
        </Collapsible>
      )}
    </>
  );
};

const VariationRowRight = ({ contract, title, expandedElements }: VariationRowProps) => {
  const contractColumns = contract
    ? [
        <div className="flex justify-between">
          <span>{contract.contractTitles.length}</span>
          {contract.offerNet}
        </div>,
        <div className="flex justify-between">
          <span>{contract.contractTitles.filter((title) => title.state === 'Expected').length}</span>
          {contract.contractTitles.reduce(
            (acc, title) => acc + (title.state === 'Expected' ? title.forecastValueNet : 0),
            0,
          )}
        </div>,
        <div className="flex justify-between">
          <span>{contract.contractTitles.filter((title) => title.state === 'Announced').length}</span>
          {contract.contractTitles.reduce(
            (acc, title) => acc + (title.state === 'Announced' ? title.forecastValueNet : 0),
            0,
          )}
        </div>,
        <div className="flex justify-between">
          <span>{contract.contractTitles.filter((title) => title.state === 'Received').length}</span>
          {contract.contractTitles.reduce(
            (acc, title) => acc + (title.state === 'Received' ? title.contractValueNet : 0),
            0,
          )}
        </div>,
        <div className="flex justify-between">
          <span>{contract.contractTitles.filter((title) => title.state === 'Approved').length}</span>
          {contract.contractTitles.reduce(
            (acc, title) => acc + (title.state === 'Approved' ? title.contractValueNet : 0),
            0,
          )}
        </div>,
        <div className="flex justify-between">
          <span>{contract.contractTitles.filter((title) => title.state === 'Commissioned').length}</span>
          {contract.contractTitles.reduce(
            (acc, title) => acc + (title.state === 'Commissioned' ? title.contractValueNet : 0),
            0,
          )}
        </div>,
        <>{contract.contractTitles.filter((title) => title.state === 'Rejected').length}</>,
        <>{contract.contractTitles.filter((title) => title.state === 'Canceled').length}</>,
        <>{contract.forecastValueNet}</>,
        <>{contract.offerNet - contract.forecastValueNet}</>,
      ]
    : [];
  const titleColumns = title
    ? [
        <>{title.offerNet}</>,
        <>{title.state === 'Expected' ? title.forecastValueNet : 0}</>,
        <>{title.state === 'Announced' ? title.forecastValueNet : 0}</>,
        <>{title.state === 'Received' ? title.contractValueNet : 0}</>,
        <>{title.state === 'Approved' ? title.contractValueNet : 0}</>,
        <>{title.state === 'Commissioned' ? title.contractValueNet : 0}</>,
        null,
        null,
        <>{title.forecastValueNet}</>,
        <>{title.offerNet - title.forecastValueNet}</>,
      ]
    : [];

  const columns = contract ? contractColumns : titleColumns;

  return (
    <>
      <div
        className={classNames('flex items-center hover:bg-slate-50 transition-colors', {
          'bg-gray-50 font-bold border-b border-dotted border-slate-300 text-[14px]': !title,
          'bg-white border-b border-slate-300 border-dotted text-[12px]': title,
        })}
      >
        <div className="flex flex-grow h-10 justify-center items-center">
          {columns?.map((column, idx) => (
            <div
              key={idx}
              className={classNames(
                'h-full flex flex-col justify-center py-2 border-r border-dotted border-slate-300 text-right px-2',
                {
                  'flex-grow basis-0 min-w-[28px]': idx < 6,
                  'w-[100px]': idx > 5,
                  'border-r-0 min-w-[20px]': idx === columns.length - 1,
                },
              )}
            >
              {column}
            </div>
          ))}
        </div>
      </div>

      {contract && contract.contractTitles.length > 0 && (
        <Collapsible open={expandedElements.includes(contract?.contractId ?? '')}>
          {contract.contractTitles?.map((title, idx) => (
            <VariationRowRight key={idx} title={title} expandedElements={expandedElements} />
          ))}
        </Collapsible>
      )}
    </>
  );
};
