import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, LoadingIndicator, Modal, TearOffCalenderIcon } from '@client/shared/toolkit';
import { useApiGetEuriborRatesQuery } from '@client/shared/api';
import { formatNumber, formatDate, formatNullableDateOnly } from '@client/shared/utilities';
import { DatePicker } from '@client/shared/toolkit';
import classNames from 'classnames';

const EuriborBox = ({ label, value, width }: { label: string; value: string; width: string }) => {
  return (
    <div className={`w-${width}/12 rounded-lg border border-slate-300 py-2 px-4 bg-white `}>
      <div className="text-xs text-slate-500 font-semibold">{label}</div>
      <div className="font-bold">{value}</div>
    </div>
  );
};

interface EuriborRatesModalProps {
  onClose: () => void;
}

export const EuriborRatesModal = ({ onClose }: EuriborRatesModalProps) => {
  const today = new Date();
  const todayPlus20 = new Date();
  todayPlus20.setDate(todayPlus20.getDate() + 20);
  const todayPlus2Months = new Date();
  todayPlus2Months.setMonth(todayPlus2Months.getMonth() + 2);
  const [startDate, setStartDate] = useState<Date | undefined>(today);
  const [endDate, setEndDate] = useState<Date | undefined>(todayPlus20);
  const [startDateQuery, setStartDateQuery] = useState<Date | undefined>(today);
  const [endDateQuery, setEndDateQuery] = useState<Date | undefined>(todayPlus2Months);

  const { t } = useTranslation();
  const { data, isFetching: isLoadingEuriborRates } = useApiGetEuriborRatesQuery(
    {
      from: formatNullableDateOnly(startDateQuery),
      to: formatNullableDateOnly(endDateQuery),
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (startDate && endDate) {
      if (startDate < new Date(endDate.getFullYear(), endDate.getMonth() - 2, endDate.getDate())) {
        setEndDateQuery(startDate);
        setEndDate(startDate);
        setStartDateQuery(startDate);
      } else if (startDate > endDate) {
        setEndDateQuery(startDate);
        setEndDate(startDate);
        setStartDateQuery(startDate);
      } else {
        setStartDateQuery(startDate);
        setEndDateQuery(endDate);
      }
    }
  }, [startDate, endDate]);

  return (
    <>
      <Modal.Header
        title={t('projectCalculate.euriborInterestPlan')}
        description={t('projectCalculate.euriborInterestModelInterest')}
      />
      <Modal.Content className="overflow-y-scroll">
        <>
          {isLoadingEuriborRates && <LoadingIndicator text={t('common.loading')} mode='overlay' />}
          <div className="text-2xl font-bold">{t('projectCalculate.euriborLast')}</div>

          <div className="flex flex-row mt-2">
            <div className="flex flex-row w-full gap-2">
              <EuriborBox label={t('common.date')} value={formatDate(data?.lastConfirmedEuribor?.date)} width="2" />
              <EuriborBox
                label={t('projectCalculate.euriborPeriods.OneMonth')}
                value={formatNumber(data?.lastConfirmedEuribor?.oneMonthEuribor, { maxDigits: 3 }) + ' %'}
                width="2"
              />
              <EuriborBox
                label={t('projectCalculate.euriborPeriods.ThreeMonths')}
                value={formatNumber(data?.lastConfirmedEuribor?.threeMonthsEuribor, { maxDigits: 3 }) + ' %'}
                width="2"
              />
              <EuriborBox
                label={t('projectCalculate.euriborPeriods.SixMonths')}
                value={formatNumber(data?.lastConfirmedEuribor?.sixMonthsEuribor, { maxDigits: 3 }) + ' %'}
                width="2"
              />
              <EuriborBox
                label={t('projectCalculate.euriborPeriods.TwelveMonths')}
                value={formatNumber(data?.lastConfirmedEuribor?.twelveMonthsEuribor, { maxDigits: 3 }) + ' %'}
                width="2"
              />
              {/* <EuriborBox
                label={t('projectCalculate.euriborUserConfirmed')}
                value={t(
                  data?.lastConfirmedEuribor?.userConfirmed
                    ? 'projectCalculate.euriborConfirmedYes'
                    : 'projectCalculate.euriborConfirmedNo',
                )}
                width="2"
              />
              <EuriborBox
                label={t('projectCalculate.euriborConfirmed')}
                value={t(
                  data?.lastConfirmedEuribor?.systemConfirmed
                    ? 'projectCalculate.euriborConfirmedYes'
                    : 'projectCalculate.euriborConfirmedNo',
                )}
                width="1"
              /> */}
            </div>
            <div className="w-12" />
          </div>
          <div>
            <div className="w-11/12 h-[2px] bg-slate-200 my-5" />
          </div>

          <div className="text-2xl font-bold">{t('projectCalculate.euriborProjections')}</div>

          <div className="w-full flex flex-row my-2 gap-2">
            <div className="w-4/12 rounded-lg border border-slate-300 p-1 bg-white">
              <DatePicker
                label={t('projectCalculate.euriborStartDate')}
                icon={<TearOffCalenderIcon />}
                value={startDate}
                onChange={(x) => setStartDate(x ?? undefined)}
              />
            </div>
            <div className="w-4/12 rounded-lg border border-slate-300 p-1 bg-white">
              <DatePicker
                label={t('projectCalculate.euriborEndDate')}
                icon={<TearOffCalenderIcon />}
                value={endDate}
                onChange={(x) => setEndDate(x ?? undefined)}
                className="w-full"
                minDate={startDate}
                maxDate={startDate ? new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 60) : undefined}
              />
            </div>
          </div>

          <div className="mb-5">
            {data?.euriborRates.map((element, i) => (
              <div className="flex flex-row" key={i}>
                <div className={classNames('w-full flex flex-row gap-2 py-1')}>
                  <EuriborBox label={t('common.date')} value={formatDate(element?.date)} width="2" />
                  <EuriborBox
                    label={t('projectCalculate.euriborPeriods.OneMonth')}
                    value={formatNumber(element?.euriborReadModel?.oneMonthEuribor, { maxDigits: 3 }) + ' %'}
                    width="2"
                  />
                  <EuriborBox
                    label={t('projectCalculate.euriborPeriods.ThreeMonths')}
                    value={formatNumber(element?.euriborReadModel?.threeMonthsEuribor, { maxDigits: 3 }) + ' %'}
                    width="2"
                  />
                  <EuriborBox
                    label={t('projectCalculate.euriborPeriods.SixMonths')}
                    value={formatNumber(element?.euriborReadModel?.sixMonthsEuribor, { maxDigits: 3 }) + ' %'}
                    width="2"
                  />
                  <EuriborBox
                    label={t('projectCalculate.euriborPeriods.TwelveMonths')}
                    value={formatNumber(element?.euriborReadModel?.twelveMonthsEuribor, { maxDigits: 3 }) + ' %'}
                    width="2"
                  />
                  {/* <EuriborBox
                        label={t('projectCalculate.euriborUserConfirmed')}
                        value={t(
                          element?.euriborReadModel?.userConfirmed
                            ? 'projectCalculate.euriborConfirmedYes'
                            : 'projectCalculate.euriborConfirmedNo',
                        )}
                        width="2"
                      />
                      <EuriborBox
                        label={t('projectCalculate.euriborConfirmed')}
                        value={t(
                          element?.euriborReadModel?.systemConfirmed
                            ? 'projectCalculate.euriborConfirmedYes'
                            : 'projectCalculate.euriborConfirmedNo',
                        )}
                        width="1"
                      /> */}
                </div>
                <div className="w-12 flex items-center"></div>
              </div>
            ))}
          </div>
        </>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={() => onClose()} className="mr-2" variant="secondary">
          {t('common.close')}
        </Button>
      </Modal.Controls>
    </>
  );
};
