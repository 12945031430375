import { useTranslation } from 'react-i18next';
import {useState, useEffect, useMemo, useCallback, useRef} from 'react';
import {
  Button,
  SlideOverOnCloseProps,
  DatePicker,
  LoadingIndicator,
  BaseSelect,
} from '@client/shared/toolkit';
import { useLoadedProjectId, useLoadedVariant } from '@client/project/store';
import {
  CalculationModelMilestoneReadModel,
  DeliveryPhaseOverviewReadModel,
  TimelineDurationUnit,
  TimelineTypes,
  useApiGetCalculationModelDeliveryPhasesQuery,
  useApiGetCalculationModelMilestonesQuery,
  useApiPostUpdateCalculationModelMetadataMutation
} from '@client/shared/api';
import { useValidateProjectPermission } from '@client/shared/permissions';
import {
  DistributionModal,
  DistributionModalAddButton
} from '@client/project/shared'
import { EditCalculationModelDeliveryPhase } from './EditCalculationModelDeliveryPhase';
import { EditCalculationModelMilestone } from './EditCalculationModelMilestone';
import { formatDateOnly, safeMutation } from '@client/shared/utilities';

export const CalculationModelDeliveryPhasesMilestonesModal = ({ onClose }: SlideOverOnCloseProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId() ?? 'unset';
  const loadedCalculationModel = useLoadedVariant().data?.calculationModel;
  const loadedCalculationModelId = loadedCalculationModel?.modelMetadata.id ?? 'unset';

  const readOnly = !useValidateProjectPermission(['PROJECT_WRITE'], loadedProjectId);

  const [selectedDurationUnit, setSelectedDurationUnit] = useState<TimelineDurationUnit>('Months')

  const [isLoading, setIsLoading] = useState(false)

  const [postUpdateMetadata, { isLoading: isUpdatingMetadata }] = useApiPostUpdateCalculationModelMetadataMutation();

  const { data: deliveryPhasesResponse, isFetching: loadingDeliveryPhases } = useApiGetCalculationModelDeliveryPhasesQuery({
    projectId: loadedProjectId,
    calculationModelId: loadedCalculationModelId,
  });

  const { data: milestonesResponse, isFetching: loadingMilestones } = useApiGetCalculationModelMilestonesQuery({
    projectId: loadedProjectId,
    calculationModelId: loadedCalculationModelId,
  });

  const [calculationModelStartDate, setCalculationModelStartDate] = useState<Date>(new Date());
  const [calculationModelEndDate, setCalculationModelEndDate] = useState<Date>(new Date());

  const [deliveryPhases, setDeliveryPhases] = useState(deliveryPhasesResponse?.deliveryPhases ?? []);
  const [milestones, setMilestones] = useState(milestonesResponse?.milestones ?? []);

  const deliveryPhasesContainerRef = useRef<HTMLDivElement>(null)
  const milestonesContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!deliveryPhasesResponse) {
      return;
    }
    setCalculationModelStartDate(
      deliveryPhasesResponse?.calculationModelStartDate
        ? new Date(deliveryPhasesResponse?.calculationModelStartDate)
        : new Date()
    );
    setCalculationModelEndDate(
      deliveryPhasesResponse?.calculationModelEndDate
        ? new Date(deliveryPhasesResponse?.calculationModelEndDate)
        : new Date()
    );

    const elements = [...deliveryPhasesResponse.deliveryPhases];
    elements.sort((a, b) => a.order - b.order);
    setDeliveryPhases(elements ?? []);
    setMilestones(milestonesResponse?.milestones ?? []);
  }, [deliveryPhasesResponse, milestonesResponse]);

  const handleAddMilestone = useCallback(() => {
    let order = 1
    if (milestones.length) {
      const lastMilestone = milestones.reduce(function(prev, current) {
        return (prev && prev.order > current.order) ? prev : current
      })
      order = lastMilestone.order + 1
    }

    const values = [...milestones]
    const defaultMilestone = {
      id: '',
      code: '',
      name: '',
      date: formatDateOnly(new Date()),
      order: order,
      isAchieved: false
    }
    values.push(defaultMilestone)
    setMilestones(values)
    setTimeout(() => {
      if (milestonesContainerRef.current) {
        milestonesContainerRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        })
      }
    }, 100)
  }, [milestones])

  const handleAddDeliveryPhase = useCallback(() => {
    let order = 1
    if (deliveryPhases.length) {
      const lastDeliveryPhase = deliveryPhases?.reduce(function(prev, current) {
        return (prev && prev.order > current.order) ? prev : current
      })
      order = lastDeliveryPhase.order + 1
    }


    const values = [...deliveryPhases];
    const newDeliveryPhase = {
      id: '',
      code: '',
      name: '',
      timeLine: {
        id: null,
        calculationModelDeliveryPhaseId: null,
        startCalculationModelDeliveryPhaseId: null,
        startOffset: 0,
        startOffsetUnit: null,
        startOffsetPosition: null,
        startFixedStartDate: deliveryPhases[deliveryPhases.length -1]?.timeLine?.effectiveEndDate ?? formatDateOnly(new Date()),
        startElementTimelineId: null,
        startType: 'FixedDates' as TimelineTypes,
        effectiveStartDate: deliveryPhases[deliveryPhases.length -1]?.timeLine?.effectiveEndDate ?? formatDateOnly(new Date()),
        duration: 0,
        durationUnit: 'Days' as TimelineDurationUnit,
        endDate: null,
        endCalculationModelDeliveryPhaseId: null,
        endOffset: 0,
        endOffsetUnit: null,
        endOffsetPosition: null,
        endElementTimelineId: null,
        endType: 'FixedDates' as TimelineTypes,
        effectiveEndDate: deliveryPhases[deliveryPhases.length -1]?.timeLine?.effectiveEndDate ?? formatDateOnly(new Date()),
        distribution: null,
      },
      order: order
    }
    values.push(newDeliveryPhase)
    setDeliveryPhases(values);
    setTimeout(() => {
      if (deliveryPhasesContainerRef.current) {
        deliveryPhasesContainerRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        })
      }
    }, 100)
  }, [deliveryPhases])

  const durationUnitOptions = useMemo(() => {
    return [
      {
        label: t('projectCalculate.timelineUnitDays'),
        value: 'Days',
      },
      {
        label: t('projectCalculate.timelineUnitWeeks'),
        value: 'Weeks',
      },
      {
        label: t('projectCalculate.timelineUnitMonths'),
        value: 'Months',
      }
      /* {
        label: t('projectCalculate.timelineUnitYears'),
        value: 'Years',
      }, */
    ];
  }, [t])

  const updateDeliveryPhasesAtIndex = useCallback((
    updated: Partial<DeliveryPhaseOverviewReadModel>,
    itemIndex: number
  ): DeliveryPhaseOverviewReadModel[] => {
    return deliveryPhases.map((item, index) => {
      if (index === itemIndex) {
        return {
          ...item,
          ...updated,
        };
      }
      return item;
    });
  }, [deliveryPhases])

  const updateMilestonesAtIndex = useCallback((
    updated: Partial<DeliveryPhaseOverviewReadModel>,
    itemIndex: number
  ): CalculationModelMilestoneReadModel[] => {
    return milestones.map((item, index) => {
      if (index === itemIndex) {
        return {
          ...item,
          ...updated,
        };
      }
      return item;
    });
  }, [milestones])

  const handleCalculationDurationSubmit = async (start: Date, end: Date) => {
    try {
      await safeMutation(
        postUpdateMetadata,
        {
          projectId: loadedProjectId,
          calculationModelId: loadedCalculationModelId,
          body: {
            title: loadedCalculationModel?.modelMetadata.name ?? '',
            description: loadedCalculationModel?.modelMetadata.description,
            startDate: formatDateOnly(start),
            endDate: formatDateOnly(end),
          },
        },
        isUpdatingMetadata
      );
    } catch (e) {
      /* left blank */
      console.error(e)
    }
  }

  return (
    <DistributionModal
      title={t('projectCalculate.deliveryPhasesAndMilestonesTitle')}
      description={t('projectCalculate.deliveryPhasesProjectDescription')}
      header={!loadingDeliveryPhases && !loadingMilestones && (
        <div className="px-12 w-full border-b-2 shadow-lg z-10">
          <div className="flex w-full divide-x">
            <DatePicker
              disabled={!calculationModelStartDate || readOnly}
              label={t('projectCalculate.deliveryPhasesProjectStart')}
              value={calculationModelStartDate}
              onChange={(value) => {
                if (value && formatDateOnly(value) !== formatDateOnly(calculationModelStartDate)) {
                  setCalculationModelStartDate(value ?? new Date())
                  handleCalculationDurationSubmit(value, calculationModelEndDate)
                }
              }}
              className="w-2/5"
            />
            <DatePicker
              disabled={!calculationModelEndDate || readOnly}
              label={t('projectCalculate.deliveryPhasesProjectEnd')}
              value={calculationModelEndDate}
              onChange={(value) => {
                if (value && formatDateOnly(value) !== formatDateOnly(calculationModelEndDate)) {
                  setCalculationModelEndDate(value ?? new Date())
                  handleCalculationDurationSubmit(calculationModelStartDate, value)
                }
              }}
              className="w-2/5"
            />
            <BaseSelect
              className="w-1/5"
              label={t('projectCalculate.deliveryPhasesDurationIn')}
              value={selectedDurationUnit ?? 'Months'}
              options={durationUnitOptions}
              onChange={(option) => setSelectedDurationUnit(option as TimelineDurationUnit)}
            />
          </div>
        </div>
      )}
      controls={
        <div className="flex space-x-2 w-full justify-end">
          <Button variant="primary" onClick={() => onClose(false)}>
            {t('common.close')}
          </Button>
        </div>
      }
    >
      {(loadingDeliveryPhases || loadingMilestones || isUpdatingMetadata) ? (
          <LoadingIndicator text={t('projectCalculate.deliveryPhasesAndMilestonesLoadingIndicator')} />
      ) : (
        <>
          {isLoading && (
            <LoadingIndicator
              text={t('projectCalculate.deliveryPhasesAndMilestonesLoadingIndicator')}
              mode="overlay-window"
            />
          )}
          <div className="flex w-full justify-start text-[22px] my-2.5 font-bold text-gray-900">
            {t('projectCalculate.deliveryPhasesProjectTitle')}
          </div>
          <div className="flex-col" ref={deliveryPhasesContainerRef}>
            {deliveryPhases.map((deliveryPhase, idx) => (
              <EditCalculationModelDeliveryPhase
                readOnly={readOnly}
                key={idx}
                item={deliveryPhase}
                updateItem={(updated) => {
                  const values = updateDeliveryPhasesAtIndex(updated, idx)
                  setDeliveryPhases(values);
                }}
                variantId={loadedCalculationModelId}
                calculationModelStartDate={calculationModelStartDate}
                onItemDeleted={() => {
                  const values = [...deliveryPhases];
                  values.splice(idx, 1);
                  setDeliveryPhases(values);
                }}
                setIsLoading={setIsLoading}
                selectedDurationUnit={selectedDurationUnit}
              />
            ))}
            {!readOnly && <DistributionModalAddButton onClick={handleAddDeliveryPhase} />}
          </div>

          <div className="flex w-full justify-start text-[22px] font-bold text-gray-900 my-2.5">
            {t('projectCalculate.milestonesTitle')}
          </div>
          <div className="flex-col" ref={milestonesContainerRef}>
            {milestones.map((milestone, idx) => (
              <EditCalculationModelMilestone
                key={idx}
                readOnly={readOnly}
                item={milestone}
                updateItem={(updated) => {
                  const values = updateMilestonesAtIndex(updated, idx)
                  setMilestones(values);
                }}
                onItemDeleted={() => {
                  const values = [...milestones];
                  values.splice(idx, 1);
                  setMilestones(values);
                }}
                setIsLoading={setIsLoading}
              />
            ))}
            {!readOnly && <DistributionModalAddButton onClick={handleAddMilestone} />}
          </div>
        </>
      )}
    </DistributionModal>
  )
}
