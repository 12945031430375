import {
  useDocumentTitle,
  TitleBar, SearchInput
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { RequirementsContainer } from '../components';
import { useLoadedPortfolio } from '@client/portfolio/store';
import { useState } from 'react';

export const ProjectingRoute = () => {
  const { t } = useTranslation();

  useDocumentTitle({ title: t('portfolio.menu.projecting') });

  const { data } = useLoadedPortfolio();
  const [searchValue, setSearchValue] = useState('');

  return (
    <>
      <TitleBar
        title={data?.portfolio.name ?? t('portfolio.menu.projecting')}
        subTitle={t('portfolio.projecting.routeSubTitle')}
      >
        <SearchInput
          value={searchValue}
          handleSearch={(val) => setSearchValue(val)}
          className="max-w-[428px] text-gray-400 bg-gray-100"
          bgColor="bg-gray-100"
          size='small'
        />
      </TitleBar>
      <div className="flex-grow flex flex-col overflow-hidden">
        <div className="px-4 py-8 md:px-8 flex flex-col flex-grow overflow-y-auto">
          <RequirementsContainer searchValue={searchValue} />
        </div>
      </div>
    </>
  );
};
