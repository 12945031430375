import React, { CSSProperties, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { ReportCostBreakdownContext } from './ReportCostBreakdownContext';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useExpandedReportingIds } from '@client/project/store';
import { CashFlowReportReadModel } from '@client/shared/api';
import { FormattedCurrency } from '@client/project/shared';
import classNames from 'classnames';
import { formatPercentage } from '@client/shared/utilities';

export const ReportCostBreakdownTableYears = () => {
  const { t } = useTranslation();
  const { elements, metadata } = useContext(ReportCostBreakdownContext);

  const [expandedYears, setExpandedYears] = useState<number[]>([]);
  const years = useMemo(() => {
    if (metadata?.start && metadata?.end) {
      const startYear = new Date(metadata.start).getFullYear();
      const endYear = new Date(metadata.end).getFullYear();
      const yearsArray = [...Array(endYear - startYear + 1)].map((_, i) => startYear + i);
      setExpandedYears(yearsArray);
      return yearsArray;
    }
    return [];
  }, [metadata]);

  const { CostBreakdown: expandedElements } = useExpandedReportingIds();

  const minWidth = useMemo(() => {
    return 4 * expandedYears.length * 117 + (years.length - expandedYears.length) * 47;
  }, [expandedYears.length, years.length]);

  const toggleYear = (year: number) => {
    const copyOfYears = [...expandedYears];
    const indexOf = copyOfYears.indexOf(year);

    if (indexOf > -1) {
      copyOfYears.splice(indexOf, 1);
    } else {
      copyOfYears.push(year);
    }
    setExpandedYears(copyOfYears.sort());
  };

  return (
    <div className="w-full flex flex-col pr-5">
      <div className="h-8 font-bold text-[15px] text-center">{t('reporting.reportCostBreakdown.invoicesCalendar')}</div>
      <div className="overflow-x-auto">
        <div
          className={cn(
            'flex',
            // expandedYears.length > 12 ? `grid-cols-[${expandedYears.length}]` : `grid-cols-${expandedYears.length}`,
          )}
          style={{ minWidth: minWidth }}
        >
          {years.map((year, i) => {
            return (
              <ReportCostBreakdownQuarterCell
                key={`year-${year}`}
                header
                styles={{ paddingTop: !expandedYears.includes(year) ? 24 : undefined }}
                className={cn({
                  'pdf-export-hidden text-gray-400': !expandedYears.includes(year),
                  'flex-1': expandedYears.includes(year) && expandedYears.length <= 2
                })}
              >
                <div className="h-5 text-center">
                  {years.length > 1 && (
                    <button
                      className="pdf-export-hidden absolute top-0 left-0 bg-gray-100 center flex justify-center items-center z-50 rounded hover:bg-gray-200 transition-colors duration-300"
                      onClick={() => toggleYear(year)}
                    >
                      {!expandedYears.includes(year) ? (
                        <PlusIcon className="w-4 h-4" />
                      ) : (
                        <MinusIcon className="w-4 h-4" />
                      )}
                    </button>
                  )}
                  {year}
                </div>
                {expandedYears.includes(year) && (
                  <div className="h-6">
                    <div className="grid grid-cols-4">
                      {[...Array(4)].map((_e, i) => {
                        return (
                          <ReportCostBreakdownQuarterCell
                            className="flex-1 pr-2"
                            key={`header-quarter-${year}-${i}`}
                            header
                            styles={{ minWidth: 117 }}
                          >
                            {`Q${i + 1}/${year.toString().slice(-2)}`}
                          </ReportCostBreakdownQuarterCell>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className="h-1 border-slate-300 border-b" />
                {elements.map((element) => {
                  return (
                    <ReportCostBreakdownValueCell
                      expandedYears={expandedYears}
                      year={year}
                      element={element}
                      expandedElements={expandedElements}
                      key={`value-cell-${element.uid}`}
                      last={element === elements[elements.length - 1]}
                    />
                  );
                })}
                <div>
                  <div className="grid grid-cols-4 pb-2">
                    {[...Array(4)].map((_e, i) => {
                      const quarter = metadata?.summary.quarters.find(
                        (quarter) => quarter.year === year && quarter.quarter === i + 1,
                      );
                      return (
                        <ReportCostBreakdownQuarterCell
                          className="flex-1"
                          key={`footer-quarter-${year}-${i}`}
                          footer
                          styles={{ minWidth: expandedYears.includes(year) ? 117 : 0 }}
                        >
                          <div className="flex-grow">
                            <div className="flex items-center justify-end pl-2 h-11 text-[16px] font-bold truncate border-b border-slate-200 border-dotted">
                              {expandedYears.includes(year) && (
                                <div className="flex flex-col">
                                  <div className="h-3 -mb-1" />
                                  <FormattedCurrency amount={quarter?.planned} />
                                  <div className="h-3 -mt-1">
                                    {quarter?.planned && quarter?.payed && (
                                      <DifferenceElement payed={quarter?.payed} planned={quarter?.planned} />
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="flex items-center justify-end pl-2 h-9 text-[14px] truncate border-b border-slate-200 border-dotted">
                              {expandedYears.includes(year) && <FormattedCurrency amount={undefined} />}
                            </div>
                            <div className="flex items-center justify-end pl-2 h-9 text-[14px] truncate border-b border-slate-200 border-dotted">
                              {expandedYears.includes(year) && <FormattedCurrency amount={undefined} />}
                            </div>
                            <div className="flex items-center justify-end pl-2 h-11 text-[16px] font-bold truncate border-b border-slate-200 border-dotted">
                              {expandedYears.includes(year) && <FormattedCurrency amount={undefined} />}
                            </div>
                            <div className="flex items-center justify-end pl-2 h-11 text-[16px] font-bold truncate">
                              {expandedYears.includes(year) && <FormattedCurrency amount={undefined} />}
                            </div>
                          </div>
                        </ReportCostBreakdownQuarterCell>
                      );
                    })}
                  </div>
                </div>
              </ReportCostBreakdownQuarterCell>
            );
          })}
        </div>
      </div>
    </div>
  );
};

interface ReportCostBreakdownQuarterProps extends PropsWithChildren {
  header?: boolean;
  footer?: boolean;
  active?: boolean;
  styles?: CSSProperties;
  className?: string;
}

export const ReportCostBreakdownQuarterCell = (props: ReportCostBreakdownQuarterProps) => {
  const { header = false, footer = false, children, active = true, styles, className } = props;
  return (
    <div
      className={cn(
        'text-right text-[13px] relative',
        {
          'font-bold text-[15px] [&:not(:last-child)]:border-r border-slate-300': header,
          'text-[14px] font-semibold border-b border-slate-300': !header && !footer,
          'text-[14px] font-semibold pr-2': footer,
          'text-slate-300': !active,
        },
        className,
      )}
      style={styles}
    >
      {children}
    </div>
  );
};

const calculateDifference = (payed: number | undefined | null, planned: number) => {
  if (!planned) return 0;
  if (!payed) return 0;
  const difference = payed - planned;
  const percentageDifference = difference / planned;
  return percentageDifference;
};

const DifferenceElement = ({ payed, planned }: { payed: number | undefined | null; planned: number }) => {
  const difference = calculateDifference(payed, planned);
  return (
    !!payed &&
    difference !== 0 && (
      <span
        className={classNames('flex justify-end items-end text-xxs', {
          'text-red-700': difference > 0,
          'text-green-700': difference < 0,
        })}
      >
        {difference < 0 ? '' : '+'}
        {formatPercentage(difference, {
          maxDigits: difference > -0.99 ? 0 : 2,
        })}
      </span>
    )
  );
};

interface ReportCostBreakdownValueCellProps {
  expandedYears: number[];
  year: number;
  element: CashFlowReportReadModel;
  expandedElements: string[];
  level?: number;
  last: boolean;
}

export const ReportCostBreakdownValueCell = ({
  expandedYears,
  year,
  element,
  expandedElements,
  level = 0,
  last = false,
}: ReportCostBreakdownValueCellProps) => {
  const expandedYear = expandedYears.includes(year);

  return (
    <div>
      <div
        className={classNames('hover:bg-slate-50 transition-colors flex', {
          'bg-gray-50 font-semibold text-[14px]': level === 0,
          'bg-white text-[12px]': level > 0,
        })}
      >
        {(expandedYear ? [...Array(4)] : [...Array(1)]).map((_e, i) => {
          const quarter = element.values?.quarters.find((value) => value.year === year && value.quarter === i + 1);
          const catalogOrCostElement = element.itemType === 'Catalog' || element.itemType === 'CostElement';
          return (
            <ReportCostBreakdownQuarterCell
              className={classNames('flex-1 pr-2', {
                'border-dotted': level > 0,
              })}
              key={`${element.uid}-quarter-${year}-${i}`}
              styles={{ minWidth: expandedYear ? 117 : 50 }}
            >
              <div
                className={classNames('h-10 flex items-center justify-end', {
                  'text-[14px]': level === 0,
                  'font-normal text-[12px]': level > 0,
                })}
              >
                {expandedYear && (quarter?.planned || quarter?.payed) && (
                  <div className="flex flex-col">
                    <div className="h-3 -mb-1" />
                    <span className={classNames({ italic: !catalogOrCostElement })}>
                      <FormattedCurrency amount={catalogOrCostElement ? quarter?.planned : quarter?.payed} />
                    </span>
                    <div className="h-3 -mt-1">
                      {catalogOrCostElement && quarter?.planned && (
                        <DifferenceElement payed={quarter?.payed} planned={quarter?.planned} />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </ReportCostBreakdownQuarterCell>
          );
        })}
      </div>

      {expandedElements.includes(element.uid) &&
        element.children.map((child) => (
          <ReportCostBreakdownValueCell
            expandedYears={expandedYears}
            year={year}
            element={child}
            expandedElements={expandedElements}
            level={level + 1}
            key={`value-cell-${child.uid}`}
            last={child === element.children[element.children.length - 1]}
          />
        ))}
    </div>
  );
};
