import { ReactElement, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import {
  TaxonomyBuildingComplexIcon,
  TaxonomyFloorIcon,
  SkyscrapersDottedIcon,
  TaxonomyWingIcon,
  TaxonomyQuarterIcon,
  FloorPlanDottedIcon,
  TaxonomyUnitIcon,
  TaxonomyAreaCustomIcon,
  TaxonomyParkingIcon, InteriorDottedIcon
} from '@client/shared/toolkit';
import { Arrow, RenderNode, SizeElement, Tree, TreeNodeProps } from './Tree';
import { TaxonomyReadModel } from '@client/shared/api';
import { getSizes } from '../hooks';
import { FlattenedTaxonomyItem } from '@client/project/shared';

interface TaxonomyNameProps {
  name: string;
  icon: ReactElement;
  headerClass?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const TaxonomyName = (props: TaxonomyNameProps) => {
  return (
    <div
      className={classNames('bg-white w-full flex flex-row items-center p-1 gap-x-2', {
        'cursor-pointer': !props.disabled,
      })}
      onClick={props.onClick}
    >
      <div className="w-5 h-full">{props.icon}</div>
      <div className={classNames('text-base', props.headerClass ?? '')}>{props.name}</div>
    </div>
  );
};

type RenderTaxonomyProps = TreeNodeProps & {
  taxonomy: TaxonomyReadModel;
  taxonomyTree: FlattenedTaxonomyItem<TaxonomyReadModel>[] | undefined;
  onSelectBuilding: (token: string) => void;
  disabled?: boolean;
};

export const RenderTaxonomy = (props: RenderTaxonomyProps) => {
  const getIcon = useCallback(() => {
    switch (props.taxonomy.itemType) {
      case 'Building':
        return <SkyscrapersDottedIcon className="w-full" />;
      case 'BuildingComplex':
        return <TaxonomyBuildingComplexIcon className="w-full" />;
      case 'Floor':
        return <TaxonomyFloorIcon className="w-full" />;
      case 'Zone':
        return <InteriorDottedIcon className="w-full" />;
      case 'Room':
        return <FloorPlanDottedIcon className="w-full" />;
      case 'Parking':
        return <TaxonomyParkingIcon className="w-full" />;
      case 'Project':
        return <SkyscrapersDottedIcon className="w-full" />;
      case 'Undefined':
      case 'Area':
        return <TaxonomyAreaCustomIcon className="w-full" />;
      case 'Quarter':
        return <TaxonomyQuarterIcon className="w-full" />;
      case 'Wing':
        return <TaxonomyWingIcon className="w-full" />;
      case 'Unit':
        return <TaxonomyUnitIcon className="w-full" />;
      case 'Sector':
      case 'Bed':
      case 'Other':
      default:
        return <SkyscrapersDottedIcon className="w-full" />;
    }
  }, [props.taxonomy.itemType]);

  const onSelectBuildingSize = useCallback(
    (buildingName: string, size: string) => {
      const elementsWithThisName = props.taxonomyTree?.filter((t) => t.taxonomyItem.itemName === buildingName);
      const elementIndex = elementsWithThisName?.findIndex((t) => t.taxonomyItem.itemId === props.taxonomy.itemId);
      const duplicateNameDifferentiation =
        (elementsWithThisName?.length ?? 0) <= 1 ? '' : `[${1 + (elementIndex ?? 0)}]`;

      props.onSelectBuilding(`'${buildingName}'${duplicateNameDifferentiation}.${size}`);
    },
    [props],
  );

  const sizes = useMemo(() => {
    return getSizes('building');
  }, []);

  return (
    <>
      <RenderNode>
        <Arrow expanded={props.expanded ?? false} onClick={props.toggleExpansion} />
        <TaxonomyName
          name={props.taxonomy.itemName}
          icon={getIcon()}
          headerClass={['Project', 'Building', 'BuildingComplex'].includes(props.taxonomy.itemType) ? 'font-bold' : ''}
          disabled={props.disabled}
          onClick={props.toggleExpansion}
        />
      </RenderNode>
      {props.expanded && (
        <>
          <div className="ml-5">
            {sizes.map((size) => (
              <SizeElement
                key={size.label}
                size={size}
                onClick={() => onSelectBuildingSize(props.taxonomy.itemName, size.label)}
                disabled={props.disabled}
              />
            ))}
          </div>
          <div className="">
            {props.taxonomy.children.map((c) => (
              <Tree
                key={c.itemId}
                nodeData={c}
                renderNode={(taxonomy, isExpanded, toggleExpanded) => (
                  <RenderTaxonomy
                    expanded={isExpanded}
                    taxonomy={taxonomy}
                    taxonomyTree={props.taxonomyTree}
                    onSelectBuilding={!props.disabled ? props.onSelectBuilding : () => null}
                    toggleExpansion={toggleExpanded}
                    disabled={props.disabled}
                  />
                )}
                className="ml-5"
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};
