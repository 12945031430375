import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import { 
  ContactButtons, 
  ContractIcon, 
  Highlighted, 
  ListItem, 
  SortDownIcon, 
  UploadBadge 
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { 
  ShortCommitmentReadModel, 
  ShortContractReadModel 
} from '@client/shared/api';
import {
  FormattedCurrency,
  getTenantTrustBadgeByStatus,
  getTenantTrustBadgeLabelByStatus,
} from '@client/project/shared';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

export interface ContractListRowProps extends PropsWithChildren {
  className?: string;
  col1?: ReactNode | string | null;
  col2?: ReactNode | string | null;
  col3?: ReactNode | string | null;
  col4?: ReactNode | string | null;
  col5?: ReactNode | string | null;
  col6?: ReactNode | string | null;
  col7?: ReactNode | string | null;
  col8?: ReactNode | string | null;
  childItems?: boolean;
  child?: boolean;
}

export const ContractListRow = (props: ContractListRowProps) => {
  const {
    className,
    col1,
    col2,
    col3,
    col4,
    col5,
    col6,
    col7,
    col8,
    children,
    childItems = false,
    child = false,
  } = props;

  return (
    <div className={cn('flex flex-wrap w-full gap-y-3.5 md:gap-0 justify-between', className)}>
      <div
        className={cn('w-full md:w-3/12  flex-none flex gap-2 justify-between items-center md:pr-3', {
          'pl-8': childItems,
          'pl-0 -mr-3': child,
        })}
      >
        <div className="hidden w-8 md:flex items-center flex-col flex-none">{col1}</div>
        <div className={cn('flex-1', { 'pr-8': child })}>{col2}</div>
      </div>
      <div className={cn("w-full md:flex-1 grid gap-3 md:justify-between md:pl-3 whitespace-nowrap grid-cols-2 md:grid-cols-4 xl:grid-cols-6",{
        '-ml-3': child,
      })}>
        <div className="hidden xl:flex justify-end">{col3}</div>
        <div className="hidden xl:flex justify-end">{col4}</div>
        {col5}
        {col6}
        {col7}
        {col8}
      </div>
      {children}
    </div>
  );
};

export interface ContractListItemProps extends PropsWithChildren {
  contract?: ShortContractReadModel;
  commitment?: ShortCommitmentReadModel;
  showNet?: boolean;
  searchValue?: string;
  child?: boolean;
}

export const ContractListItem = (props: ContractListItemProps) => {
  const { t } = useTranslation();
  const { contract, commitment, children, searchValue = '', child = false, showNet = true } = props;

  const contractorTenantTrustBadge = useMemo(() => {
    if (contract?.isTrustedContractor && contract.contractorTrustState) {
      return (
        <UploadBadge
          className="flex-none"
          variant={getTenantTrustBadgeByStatus(
            contract.contractorTrustState,
            contract.isContractorTrustActive,
          )}
          tooltip={getTenantTrustBadgeLabelByStatus(
            contract.contractorTrustState,
            contract.isContractorTrustActive,
          )}
          size="small"
        />
      );
    }
    return null;
  }, [contract]);

  const clientTenantTrustBadge = useMemo(() => {
    if (contract?.isTrustedClient && contract.clientTrustState) {
      return (
        <UploadBadge
          className="flex-none"
          variant={getTenantTrustBadgeByStatus(
            contract.clientTrustState,
            contract.isContractorTrustActive,
          )}
          tooltip={getTenantTrustBadgeLabelByStatus(
            contract.clientTrustState,
            contract.isContractorTrustActive,
          )}
          size="small"
        />
      );
    }
    return null;
  }, [contract]);

  return (
    <ContractListRow
      childItems={commitment?.contracts && commitment?.contracts?.length > 0}
      child={child}
      col1={<ContractIcon className="w-5" />}
      col2={
        <div className="flex flex-row items-center">
          {contract && contract.isPxContract && <ArrowPathIcon className="w-6 h-6 mr-3 text-sky-900 flex-none" />}
          <div className="flex flex-col font-bold text-[15px]">
            <span className="whitespace-normal leading-tight">
              {contract && contract.name ? <Highlighted text={contract.name} highlight={searchValue} /> : ''}
              {commitment && commitment.name ? <Highlighted text={commitment.name} highlight={searchValue} /> : ''}
            </span>
            <span className="text-[12px] text-gray-500 leading-none">
              {contract && contract.code ? <Highlighted text={contract.code} highlight={searchValue} /> : ''}
              {commitment && commitment.code ? <Highlighted text={commitment.code} highlight={searchValue} /> : ''}
            </span>
          </div>
        </div>
      }
      col3={
        <div className="flex flex-wrap items-center justify-end">
          {contract && (contract.contractorEmail || contract.contractorPhone) && (
            <div className="hidden lg:flex gap-2 h-8 mr-2 lg:mr-3.5 my-2">
              {contract && (contract.contractorEmail || contract.contractorPhone) && (
                <ContactButtons phone={contract.contractorPhone} email={contract.contractorEmail} />
              )}
            </div>
          )}
          <div className="whitespace-normal flex gap-2 items-center justify-end text-right">
            {contract && contract.contractorName ? (
              <Highlighted
                text={
                  contract.contractorCompanyName
                    ? `${contract.contractorCompanyName} - ${contract.contractorName}`
                    : contract.contractorName
                }
                highlight={searchValue}
              />
            ) : (
              ''
            )}
            {contractorTenantTrustBadge}
          </div>
        </div>
      }
      col4={
        <div className="flex flex-wrap items-center justify-end">
          {contract && (contract.clientEmail || contract.clientPhone) && (
            <div className="hidden lg:flex gap-2 h-8 mr-2 lg:mr-3.5 my-2">
              {contract && (contract.clientEmail || contract.clientPhone) && (
                <ContactButtons phone={contract.clientPhone} email={contract.clientEmail} />
              )}
            </div>
          )}
          <div className="whitespace-normal flex gap-2 items-center justify-end text-right">
            {contract && contract.clientName ? (
              <Highlighted
                text={
                  contract.clientCompanyName
                    ? `${contract.clientCompanyName} - ${contract.clientName}`
                    : contract.clientName
                }
                highlight={searchValue}
              />
            ) : (
              ''
            )}
            {clientTenantTrustBadge}
          </div>
        </div>
      }
      col5={
        <>
          <span className="leading-tight md:hidden text-[13px] text-gray-400 whitespace-break-spaces">
            {t('projectContract.budget')}
          </span>
          <div className="flex flex-col text-[15px] leading-tight text-right justify-center">
            {showNet ? (
              <FormattedCurrency amount={contract ? contract.budgetNet : commitment?.budgetNet} />
            ) : (
              <FormattedCurrency amount={contract ? contract.budgetGross : commitment?.budgetGross} />
            )}
            <span className="text-[10px] text-gray-500">
              {showNet ? t('projectControl.netAmount') : t('projectControl.grossAmount')}
            </span>
          </div>
        </>
      }
      col6={
        <>
          <span className="leading-tight md:hidden text-[13px] text-gray-400 whitespace-break-spaces">
            {t('projectContract.totalContract')}
          </span>
          <div className="flex flex-col text-[15px] leading-tight text-right justify-center">
            {showNet ? (
              <FormattedCurrency amount={contract ? contract.contractValueNet : commitment?.contractValueNet} />
            ) : (
              <FormattedCurrency amount={contract ? contract.contractValueGross : commitment?.contractValueGross} />
            )}
            <span className="text-[10px] text-gray-500">
              {showNet ? t('projectControl.netAmount') : t('projectControl.grossAmount')}
            </span>
          </div>
        </>
      }
      col7={
        <>
          <span className="leading-tight md:hidden text-[13px] text-gray-400 whitespace-break-spaces">
            {t('projectContract.forecast')}
          </span>
          <div className="flex flex-col text-[15px] leading-tight text-right justify-center">
            {showNet ? (
              <FormattedCurrency amount={contract ? contract.forecastValueNet : commitment?.forecastValueNet} />
            ) : (
              <FormattedCurrency amount={contract ? contract.forecastValueGross : commitment?.forecastValueGross} />
            )}
            <span className="text-[10px] text-gray-500">
              {showNet ? t('projectControl.netAmount') : t('projectControl.grossAmount')}
            </span>
          </div>
        </>
      }
      col8={
        <>
          <span className="leading-tight md:hidden text-[13px] text-gray-400 whitespace-break-spaces">
            {t('projectContract.invoice')}
          </span>
          <div className="flex flex-col text-[15px] leading-tight text-right justify-center">
            {showNet ? (
              <FormattedCurrency amount={contract ? contract.invoiceValueNet : commitment?.invoiceValueNet} />
            ) : (
              <FormattedCurrency amount={contract ? contract.invoiceValueGross : commitment?.invoiceValueGross} />
            )}
            <span className="text-[10px] text-gray-500">
              {showNet ? t('projectControl.netAmount') : t('projectControl.grossAmount')}
            </span>
          </div>
        </>
      }
    >
      {children}
    </ContractListRow>
  );
};

export interface ContractListItemSumProps extends PropsWithChildren {
  budget: number;
  total: number;
  forecast: number;
  invoice: number;
}

export const ContractListItemSum = (props: ContractListItemSumProps) => {
  const { t } = useTranslation();
  const { budget, forecast, invoice, total } = props;
  return (
    <ListItem bgColor="" shadow={false} additionalContent={<div className="w-0 md:w-[56px] h-full" />}>
      <ContractListRow
        col5={
          <>
            <span className="leading-tight md:hidden text-[15px] font-bold  text-gray-400 whitespace-break-spaces">
              {t('projectContract.totalBudget')}
            </span>
            <div className="flex flex-col leading-none text-right">
              <span className="text-xl leading-none font-bold text-gray-400">
                <FormattedCurrency amount={budget} />
              </span>
              <span className="text-[10px] text-gray-400">{t('projectContract.sum')}</span>
            </div>
          </>
        }
        col6={
          <>
            <span className="leading-tight md:hidden text-[15px] font-bold text-green-500 whitespace-break-spaces">
              {t('projectContract.totalContract')}
            </span>
            <div className="flex flex-col leading-none text-right">
              <span className="text-xl leading-none font-bold text-green-500">
                <FormattedCurrency amount={total} />
              </span>
              <span className="text-[10px] text-green-500">{t('projectContract.sum')}</span>
            </div>
          </>
        }
        col7={
          <>
            <span className="leading-tight md:hidden text-[15px] font-bold text-green-500 whitespace-break-spaces">
              {t('projectContract.totalForecast')}
            </span>
            <div className="flex flex-col leading-none text-right">
              <span className="text-xl leading-none font-bold text-green-500">
                <FormattedCurrency amount={forecast} />
              </span>
              <span className="text-[10px] text-green-500">{t('projectContract.sum')}</span>
            </div>
          </>
        }
        col8={
          <>
            <span className="leading-tight md:hidden text-[15px] font-bold text-red-500 whitespace-break-spaces">
              {t('projectContract.totalInvoice')}
            </span>
            <div className="flex flex-col leading-none text-right text-gray-500">
              <span className="text-xl leading-none font-bold text-red-500">
                <FormattedCurrency amount={invoice} />
              </span>
              <span className="text-[10px] text-red-500">{t('projectContract.sum')}</span>
            </div>
          </>
        }
      />
    </ListItem>
  );
};

export interface ContractListSortHeaderProps extends PropsWithChildren {
  onHandleSort: (index: number) => void;
  sortValues: (boolean | null)[];
}

export const ContractListSortHeader = (props: ContractListSortHeaderProps) => {
  const { t } = useTranslation();
  const { children, onHandleSort, sortValues } = props;

  return (
    <ListItem
      borderColor="bg-transparent"
      bgColor=""
      shadow={false}
      padding={false}
      additionalContent={<div className="w-[56px] h-full" />}
      as="div"
      className="hidden md:flex"
    >
      <ContractListRow
        childItems={false}
        col2={
          <ContractListSortHeaderItem
            label={t('projectContract.contract')}
            onClick={() => onHandleSort(1)}
            asc={sortValues[1]}
            alignRight={false}
            className="opacity-0 md:opacity-100"
          />
        }
        col3={
          <ContractListSortHeaderItem
            label={t('projectContract.contractor')}
            onClick={() => onHandleSort(2)}
            asc={sortValues[2]}
            alignRight={false}
          />
        }
        col4={
          <ContractListSortHeaderItem
            label={t('projectContract.contractClient')}
            onClick={() => onHandleSort(4)}
            asc={sortValues[4]}
            alignRight
          />
        }
        col5={
          <ContractListSortHeaderItem
            label={t('projectContract.budget')}
            onClick={() => onHandleSort(5)}
            asc={sortValues[5]}
            alignRight
          />
        }
        col6={
          <ContractListSortHeaderItem
            label={t('projectContract.totalContract')}
            onClick={() => onHandleSort(6)}
            asc={sortValues[6]}
            alignRight
          />
        }
        col7={
          <ContractListSortHeaderItem
            label={t('projectContract.forecast')}
            onClick={() => onHandleSort(7)}
            asc={sortValues[7]}
            alignRight
          />
        }
        col8={
          <ContractListSortHeaderItem
            label={t('projectContract.invoice')}
            onClick={() => onHandleSort(8)}
            asc={sortValues[8]}
            alignRight
          />
        }
      >
        {children}
      </ContractListRow>
    </ListItem>
  );
};

export interface ContractListSortHeaderItemProps {
  className?: string;
  onClick: () => void;
  label: string;
  asc: boolean | null;
  alignRight?: boolean;
}

export const ContractListSortHeaderItem = (props: ContractListSortHeaderItemProps) => {
  const { className, onClick, label, asc, alignRight = true } = props;
  return (
    <span
      className={cn(
        'flex gap-2.5 items-center cursor-pointer text-gray-500',
        alignRight ? 'justify-end' : '',
        className,
      )}
      onClick={onClick}
    >
      {label}
      <SortDownIcon
        className={cn('flex-none w-7', {
          'rotate-180': asc || asc === null,
          'opacity-30': asc === null,
        })}
      />
    </span>
  );
};
