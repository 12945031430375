import { PaymentMethod } from '@client/shared/api';
import { i18n } from '@client/shared/utilities';

export const getPaymentMethodValueLabel = (paymentMethod: PaymentMethod) => {
  switch (paymentMethod) {
    case 'OneTime':
      return i18n.t('projectRent.paymentMethod.OneTime');
    case 'PaymentPlan':
      return i18n.t('projectRent.paymentMethod.PaymentPlan');
    case 'None':
      return i18n.t('projectRent.paymentMethod.None');
    default:
      return i18n.t('projectRent.paymentMethod.OneTime');
  }
};
