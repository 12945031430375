import { useApiPostUpdateTenantBillingAddressMutation } from '@client/shared/api';
import { useUi } from '@client/shared/store';
import {
  AddressFormFields,
  Button, EmailIcon,
  Form,
  FormField, LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
  TextInput
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useTranslation } from 'react-i18next';
import React, { useRef } from 'react';

const AddressFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  email: yup.string().required('validation.required'),
  street: yup.string().required('validation.required'),
  number: yup.string(),
  suffix: yup.string(),
  postalCode: yup.string().required('validation.required'),
  city: yup.string().required('validation.required'),
  state: yup.string(),
  country: yup.string().required('validation.required'),
});

type AddressFormValidationValues = InferType<typeof AddressFormValidationSchema>;

export const AddressEditSlideOver = ({ onClose }: SlideOverOnCloseProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const ui = useUi();
  const address = ui.appUser.tenant?.billingAddress;
  const [postChangeAddress, { isLoading }] = useApiPostUpdateTenantBillingAddressMutation();

  const handleSubmit = async (data: AddressFormValidationValues) => {
    try {
      await safeMutation(
        postChangeAddress,
        {
          body: {
            name: data.name,
            city: data.city,
            country: data.country,
            postalCode: data.postalCode,
            email: data.email,
            street: data.street,
            number: data.number,
            state: data.state,
            suffix: data.suffix,
          },
        },
        isLoading
      );

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const defaultValues = {
    name: address?.name ?? '',
    email: address?.email ?? '',
    street: address?.street ?? '',
    number: address?.number ?? '',
    suffix: address?.suffix ?? '',
    postalCode: address?.postalCode ?? '',
    city: address?.city ?? '',
    state: address?.state ?? '',
    country: address?.country ?? '',
  };

  return (
    <Form<AddressFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={AddressFormValidationSchema}
      defaultValues={defaultValues}
      className="flex flex-col flex-grow min-h-0"
    >
      {isLoading && <LoadingIndicator mode="overlay-window" text={t('app.settingsTenantSavingLoadingIndicator')} />}
      <SlideOver.Header
        title={t('app.settingsBillingAddress')}
        subTitle={''}
        backgroundClassName="bg-blue-900"
        onClose={onClose}
      />
      <SlideOver.Content className="p-8">
        <div>
          <FormField name="name">{(control) => <TextInput label={t('common.name')} {...control} />}</FormField>
          <FormField name="email">{(control) => <TextInput icon={<EmailIcon />} label={t('common.email')} {...control} />}</FormField>
        </div>
        <div className="mt-4">
          <AddressFormFields fields={['street', 'number', 'suffix', 'postalCode', 'city', 'state', 'country']} />
        </div>
      </SlideOver.Content>

      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" formSubmit={true} innerRef={submitRef}>
          {t('common.save')}
        </Button>
      </SlideOver.Controls>
    </Form>
  );
};
