import { HintBox } from "@client/shared/toolkit";

interface DisplayErrorProps {
    translatedErrors: string[]
}

const DisplayErrors = ({ translatedErrors }: DisplayErrorProps) => {
    if (!translatedErrors || translatedErrors.length === 0) return null;
    return (
        <HintBox hintType="warning">
            {translatedErrors.join(`\n`)}
        </HintBox>
    );
}

export default DisplayErrors;