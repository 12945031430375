import { useNavigate, useParams } from 'react-router-dom';
import { LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import React, { useEffect, useState } from 'react';
import { CompanyReadModel, useApiGetTenantCompanyQuery } from '@client/shared/api';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useTranslation } from 'react-i18next';
import { PartnerCompanyDetailsSlideOver } from './PartnerCompanyDetailsSlideOver';

export const PartnerCompanyView = () => {
  const { companyId } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isOpenSlideOver, setIsOpenSlideOver] = useState(false);
  const [company, setCompany] = useState<CompanyReadModel | undefined>(undefined);

  if (!companyId) {
    navigate(ROUTES_CONFIG.SETTINGS.name);
  }

  const {
    data: loadedCompany,
    isFetching: isLoadingCompany,
    isError,
  } = useApiGetTenantCompanyQuery(
    {
      companyId: companyId ?? '',
    },
    {
      skip: !companyId,
    },
  );

  useEffect(() => {
    if (typeof loadedCompany !== 'undefined' && loadedCompany !== null) {
      setCompany(loadedCompany);
      setIsOpenSlideOver(true)
    }
    if (isError) {
      navigate(ROUTES_CONFIG.SETTINGS_PARTNER_COMPANIES.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedCompany, isError]);

  return (
    <SlideOver
      isOpen={isOpenSlideOver}
      variant="2xl"
      onClose={() => setIsOpenSlideOver(false)}
      onAfterLeave={() => {
        navigate(ROUTES_CONFIG.SETTINGS_PARTNER_COMPANIES.name);
      }}
    >
      {isLoadingCompany && (
        <LoadingIndicator text={t('app.settingsCompanyLoading')} mode="overlay" />
      )}
      <PartnerCompanyDetailsSlideOver
        company={company}
        onClose={() => setIsOpenSlideOver(false)}
      />
    </SlideOver>
  );
};
