import { HintBox, ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { useApiPostLockFinancingElementMutation, useApiPostUnlockFinancingElementMutation } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId } from '@client/project/store';

interface FinancingElementChangeLockProps extends ModalOnCloseProps {
  elementId?: string;
  variantId?: string;
  lock: boolean;
}

export const FinancingElementChangeLockModal = ({
  elementId,
  onClose,
  variantId,
  lock,
}: FinancingElementChangeLockProps) => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();

  const [lockCall, {isLoading: isLoadingLock}] = useApiPostLockFinancingElementMutation();
  const [unlockCall, {isLoading: isLoadingUnlock}] = useApiPostUnlockFinancingElementMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleLock = async () => {
    if (elementId && variantId) {
      try {
        await lockCall({
          id: elementId,
          calculationModelId: variantId,
          projectId: projectId ?? 'unset',
        });

        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  const handleUnlock = async () => {
    if (elementId && variantId) {
      try {
        await unlockCall({
          id: elementId,
          calculationModelId: variantId,
          projectId: projectId ?? 'unset',
        });

        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  return (
    <ConfirmModal
      title={lock ? t('projectCalculate.financingElementLockTitle') : t('projectCalculate.financingElementUnlockTitle')}
      onConfirm={lock ? handleLock : handleUnlock}
      onClose={handleOnClose}
      confirmVariant="text"
      confirmText={
        lock
          ? t('projectCalculate.rowMenu.lockFinancingElementTitle')
          : t('projectCalculate.rowMenu.unlockFinancingElementTitle')
      }
    >
      {(isLoadingLock || isLoadingUnlock) && <LoadingIndicator text={t('common.loading')} mode='overlay' />}
      <HintBox hintType="warning">
        {lock ? t('projectCalculate.financingElementLockHint') : t('projectCalculate.financingElementUnlockHint')}
      </HintBox>
    </ConfirmModal>
  );
};
