import { t } from 'i18next';

export const translateTaskName = (taskName: string) => {
  switch (taskName) {
    case 'ReviewInvoiceTask':
      return t('projectTasks.reviewInvoiceTask');
    case 'ApproveInvoiceTask':
      return t('projectTasks.approveInvoiceTask');
    case 'ApproveInvoiceAction':
        return t('projectTasks.approveInvoiceAction');
    default:
      return taskName;
  }
}