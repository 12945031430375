import {
  DealContactReadModel,
  useApiPostCreateRentDealContactMutation,
  useApiPostUpdateRentDealContactMutation,
  useApiPostCreateSellDealContactMutation,
  useApiPostUpdateSellDealContactMutation,
  DealType,
} from '@client/shared/api';
import classNames from 'classnames';
import { useState, useRef } from 'react';
import {
  Button,
  ResumeIcon,
  Form,
  TextInput,
  FormField,
  Modal,
  SlideOverOnCloseProps,
  SlideOver, TagWindowIcon, PhoneIcon, AtSignIcon,
  LoadingIndicator
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { DealContactDeleteModal } from './DealContactDeleteModal';
import { useTranslation } from 'react-i18next';
import { DealContactFormValidationValues, DealContactFormValidationSchema } from './DealContactFormValidationSchema';
import { useLoadedProjectId } from '@client/project/store';
import { useCanDeleteDeal, useCanWriteDeal } from '../../hooks';

interface DealContactSlideOverProps extends SlideOverOnCloseProps {
  isAddMode: boolean;
  dealId: string;
  type: DealType;
  contact?: DealContactReadModel;
}

export const DealContactSlideOver = ({ dealId, type, isAddMode, contact, onClose }: DealContactSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const projectId = useLoadedProjectId();
  const readOnly = !useCanWriteDeal(type);
  const canDelete = useCanDeleteDeal(type);

  const [createRentContact, { isLoading: isCreatingRent }] = useApiPostCreateRentDealContactMutation();
  const [updateRentContact, { isLoading: isUpdatingRent }] = useApiPostUpdateRentDealContactMutation();
  const [createSellContact, { isLoading: isCreatingSell }] = useApiPostCreateSellDealContactMutation();
  const [updateSellContact, { isLoading: isUpdatingSell }] = useApiPostUpdateSellDealContactMutation();

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  const handleSubmit = async (data: DealContactFormValidationValues) => {
    switch (type) {
      case 'Rent':
        handleRentSubmit(data);
        break;
      case 'Sell':
        handleSellSubmit(data);
        break;
    }
  };

  const handleSellSubmit = async (data: DealContactFormValidationValues) => {
    try {
      if (isAddMode) {
        await safeMutation(
          createSellContact,
          {
            projectId: projectId ?? 'unset',
            dealId: dealId,
            body: {
              name: data.name,
              role: data.role,
              company: data.company,
              email: data.email,
              mobile: data.mobile,
            },
          },
          isCreatingSell
        );
      } else {
        await safeMutation(
          updateSellContact,
          {
            projectId: projectId ?? 'unset',
            dealId: dealId,
            contactId: contact?.id ?? '',
            body: {
              name: data.name,
              role: data.role,
              company: data.company,
              email: data.email,
              mobile: data.mobile,
            },
          },
          isUpdatingSell
        );
      }

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRentSubmit = async (data: DealContactFormValidationValues) => {
    try {
      if (isAddMode) {
        await safeMutation(
          createRentContact,
          {
            projectId: projectId ?? 'unset',
            dealId: dealId,
            body: {
              name: data.name,
              role: data.role,
              company: data.company,
              email: data.email,
              mobile: data.mobile,
            },
          },
          isCreatingRent
        );
      } else {
        await safeMutation(
          updateRentContact,
          {
            projectId: projectId ?? 'unset',
            dealId: dealId,
            contactId: contact?.id ?? '',
            body: {
              name: data.name,
              role: data.role,
              company: data.company,
              email: data.email,
              mobile: data.mobile,
            },
          },
          isUpdatingRent
        );
      }

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const defaultFormValues = {
    name: contact?.name ?? '',
    company: contact?.company ?? '',
    role: contact?.role ?? '',
    email: contact?.email ?? '',
    mobile: contact?.mobile ?? '',
  };

  return (
    <>
      <SlideOver.Header
        title={t('projectRent.dealContactEditTitle')}
        backgroundClassName="bg-sky-900"
        onClose={() => onClose(false)}
      />

      <Form<DealContactFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={DealContactFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
      >
        <SlideOver.Content>
          {(isCreatingRent || isUpdatingRent || isCreatingSell || isUpdatingSell)  && <LoadingIndicator text={t('projectRent.dealContractSaving') ?? ''} mode="overlay" />}
          <div className="m-8 bg-white">
            <div className="divide-gray-100 divide-y">
              <FormField name="name">
                {(control) => (
                  <TextInput
                    disabled={readOnly}
                    label={t('projectRent.dealContactLabelCompany')}
                    icon={<TagWindowIcon />}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="company">
                {(control) => (
                  <TextInput
                    disabled={readOnly}
                    label={t('projectRent.dealContactLabelContactPerson')}
                    icon={<TagWindowIcon />}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="role">
                {(control) => (
                  <TextInput
                    disabled={readOnly}
                    label={t('projectRent.dealContactLabelRole')}
                    icon={<ResumeIcon />}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="email">
                {(control) => (
                  <TextInput
                    disabled={readOnly}
                    label={t('common.email')}
                    icon={<AtSignIcon className="w-6 h-6" />}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="mobile">
                {(control) => (
                  <TextInput
                    disabled={readOnly}
                    label={t('common.phoneNumber')}
                    icon={<PhoneIcon />}
                    {...control}
                  />
                )}
              </FormField>
            </div>
          </div>
        </SlideOver.Content>

        <SlideOver.Controls>
          <div className={classNames('flex-grow flex', contact ? 'justify-between' : 'justify-end')}>
            {!isAddMode && canDelete && (
              <Button variant="warning" onClick={() => setIsOpenDeleteModal(true)}>
                {t('common.delete')}
              </Button>
            )}
            <div className="flex">
              <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
                {t('common.cancel')}
              </Button>
              {!readOnly && (
                <Button variant="primary" formSubmit={true} innerRef={submitRef}>
                  {isAddMode ? t('common.add') : t('common.save')}
                </Button>
              )}
            </div>
          </div>
        </SlideOver.Controls>
      </Form>

      {!isAddMode && contact && (
        <Modal isOpen={isOpenDeleteModal} onClose={() => setIsOpenDeleteModal(false)} variant="small">
          <DealContactDeleteModal
            onClose={() => setIsOpenDeleteModal(false)}
            contact={contact}
            dealId={dealId}
            type={type}
          />
        </Modal>
      )}
    </>
  );
};
