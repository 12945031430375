import { DecoratedElement } from '../hooks';

/**
 * This helper function adds values to an array if they do not already exist in the array.
 * @param array The array to add values to.
 * @param values The values to add to the array.
 */
const addValuesToArray = (array: string[], values: string[]) => {
  values.forEach((value) => {
    const index = array.indexOf(value);

    if (index === -1) {
      array.push(value);
    }
  });
};

/**
 * This helper function removes values from an array if they do exist in the array.
 * @param array The array to remove values from.
 * @param values The values to remove from the array.
 */
const removeValuesFromArray = (array: string[], values: string[]) => {
  values.forEach((value) => {
    const index = array.indexOf(value);

    if (index > -1) {
      array.splice(index, 1);
    }
  });
};

/**
 * This function adds or removes a decorated element from the expandedIds array.
 * @param expandedIds - The expandedIds array to be updated.
 * @param element - The decorated element to be added or removed.
 * @param mode - The mode in which the operation will be performed.
 * @returns A new expandedIds array with the updated elements.
 */

export const recursivelyAddOrRemoveIds = <T>(
  expandedIds: string[],
  element: DecoratedElement<T>,
  mode: 'remove' | 'add'
) => {
  if (mode === 'remove') {
    removeValuesFromArray(expandedIds, [element.categoryId]);

    // Recurse through the elements if the decorated element has children
    if (element.level < 3) {
      element.children.forEach((element) => recursivelyAddOrRemoveIds(expandedIds, element, 'remove'));
    }
  }

  if (mode === 'add') {
    addValuesToArray(expandedIds, [element.categoryId]);
  }

  return [...expandedIds];
};

export const addOrRemoveDecoratedElementFromExpandedIds = <T>(
  expandedIds: string[],
  element: DecoratedElement<T>,
  mode: 'remove' | 'add'
) => {

  const clonedIds = [...expandedIds];
  

  return recursivelyAddOrRemoveIds(clonedIds, element, mode);
};
