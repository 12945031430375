import { EarningsElementData } from '@client/shared/api';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import cn from 'classnames';
import { LabeledValue, ToggleButton } from '@client/shared/toolkit';
import { ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatNumber, partitionArray } from '@client/shared/utilities';

interface EarningsElementImportRecordProps {
  element: EarningsElementData;
  level?: number;
}

export const EarningsElementImportRecord = ({ element, level = 0 }: EarningsElementImportRecordProps) => {
  const { t } = useTranslation();

  const allUserDefinedFieldKeys = Object.keys(element.data.userDefinedFields);
  const userDefinedFieldKeySegments = partitionArray(allUserDefinedFieldKeys, 3);

  return (
    <Disclosure as="div" className="w-full" defaultOpen={false}>
      {({ open }) => (
        <>
          <DisclosureButton as="div">
            <div
              className={cn('flex items-center h-[38px] hover:text-black hover:bg-gray-50 relative', {
                'border-b font-bold text-sm text-gray-900': level === 0,
                'text-xs text-gray-500': level > 0,
              })}
            >
              <ToggleButton className="absolute z-[5] -left-6 top-0 h-full" open={open} />
              <div className="pl-2 w-full h-full flex flex-row cursor-pointer items-center">
                <div className="flex flex-grow" style={level > 1 ? { marginLeft: `${level * 0.6}rem` } : undefined}>
                  {element.data.name}
                </div>
                {element.errors && element.errors.length > 0 && (
                  <div className="mr-2 flex justify-end text-red-600">
                    <ExclamationCircleIcon className="w-6" />
                  </div>
                )}
              </div>
            </div>
          </DisclosureButton>
          <DisclosurePanel>
            <div className="text-xs mb-2 ml-2">
              <div className="text-center mb-2">
                {element.errors.map((err, index) => (
                  <div key={index} className="text-red-500">
                    {t(err)}
                  </div>
                ))}
              </div>
              <div className="flex flex-row">
                <div className="w-1/3">
                  <LabeledValue value={element.data.name ?? '-'}>{t('common.name')}</LabeledValue>
                </div>
                <div className="w-1/3">
                  <LabeledValue value={element.data.catalogElement ?? '-'}>{t('projectCalculate.earningsCatalogElement')}</LabeledValue>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/3">
                  <LabeledValue value={element.data.code ?? '-'}>{t('common.code')}</LabeledValue>
                </div>
                <div className="w-1/3">
                  <LabeledValue value={element.data.type ?? '-'}>{t('projectCalculate.earningsType')}</LabeledValue>
                </div>                
              </div>              
              <div className="flex flex-row">
                <div className="w-1/3">
                  <LabeledValue value={element.data.unit ?? '-'}>{t('projectCalculate.formulaLabelUnit')}</LabeledValue>
                </div>
                <div className="w-1/3">
                  <LabeledValue value={formatNumber(element.data.amount, {maxDigits : 2, minDigits : 2}) ?? '-'}>{t('projectCalculate.formulaLabelAmount')}</LabeledValue>
                </div>
                <div className="w-1/3">
                  <LabeledValue value={formatCurrency(element.data.unitPrice, {maxDigits : 2, minDigits : 2}) ?? '-'}>{t('projectCalculate.formulaLabelUnitPrice')}</LabeledValue>
                </div>
              </div>
              {allUserDefinedFieldKeys.length > 0 && <div className="my-1">{t('common.userDefinedFields')}</div>}
              {userDefinedFieldKeySegments.map((keys, segmentIndex) => (
                <div key={segmentIndex} className="flex flex-row">
                  {keys.map((key, userDefinedFieldIndex) =>(
                    <div key={`earnings-element-user-defined-field${userDefinedFieldIndex}`} className="w-1/3">
                      <LabeledValue value={element.data.userDefinedFields[key] ?? '-'}>{key}</LabeledValue>
                    </div>
                  ))}
                </div>
              ))}              
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
};
