import { StreetViewIcon } from '../../icons'
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from '../FormField';
import { TextInput } from '../TextInput';
import { BaseSelect } from '../BaseSelect';
import { SlideOverTitle } from '../SlideOverTitle';

type ContactFormField = 'gender' | 'title' | 'firstName' | 'lastName' | 'function'
interface ContactFormFieldsProps {
  fields?: ContactFormField[]
  showTitle?: boolean
  title?: string
  marginTop?: boolean
}
export const ContactFormFields = (props: ContactFormFieldsProps) => {
  const {
    fields = ['gender', 'title', 'firstName', 'lastName', 'function'],
    title,
    showTitle = true,
    marginTop = false
  } = props
  const { t } = useTranslation()
  const genderOptions = [
    { label: t('common.male'), value: 'M' },
    { label: t('common.female'), value: 'F' },
    { label: '-', value: 'D' },
  ];
  return (
    <>
      {showTitle && (
        <SlideOverTitle title={title ? title : t('app.masterDataGeneral')} marginTop={marginTop} />
      )}
      {fields.includes('gender') && (
        <FormField name="gender">
          {(control) => <BaseSelect options={genderOptions} label={t('common.salutation')} {...control} />}
        </FormField>
      )}
      {fields.includes('title') && (
        <FormField name="title">
          {(control) => <TextInput label={t('app.companyContactTitle')} {...control} />}
        </FormField>
      )}
      {fields.includes('firstName') && (
        <FormField name="firstName">
          {(control) => <TextInput label={t('common.firstName')} {...control} />}
        </FormField>
      )}
      {fields.includes('lastName') && (
        <FormField name="lastName">{(control) => <TextInput label={t('common.lastName')} {...control} />}</FormField>
      )}
      {fields.includes('function') && (
        <FormField name="function">{(control) => <TextInput icon={<StreetViewIcon />} label={t('auth.role')} {...control} />}</FormField>
      )}
    </>
  )
}
