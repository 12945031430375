import { AuthRoleAssignmentReadModel, AuthRoleReadModel, useApiGetAuthRolesQuery } from '@client/shared/api';
import { Button, LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import { ShieldCheckIcon } from '@heroicons/react/24/outline';
import { TranslateRoleName } from './RoleTranslation';

interface RoleAddSlideOverProps {
  assignedRoles: AuthRoleAssignmentReadModel[];
  onClose: (newRoles?: AuthRoleAssignmentReadModel[]) => void;
  slideOverBackground: string;
}

export const RoleAddSlideOver = ({ assignedRoles, onClose, slideOverBackground }: RoleAddSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const { data: roles, isFetching } = useApiGetAuthRolesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [selectableRoles, setSelectableRoleList] = useState<AuthRoleAssignmentReadModel[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<AuthRoleAssignmentReadModel[]>([]);

  const toAssignment = (role: AuthRoleReadModel): AuthRoleAssignmentReadModel => ({
    roleId: role.id,
    name: role.name,
    isSystemRole: role.isSystemRole,
    systemRoleType: role.systemRoleType,
    scope: role.scope,
    permissions: role.permissions,
    assignments: [{ id: '', role: { id: role.id, name: role.name } }],
  });

  useEffect(() => {
    if (!roles) {
      setSelectableRoleList([]);
    } else {
      setSelectableRoleList(
        roles.filter((x) => !assignedRoles.find((y) => y.roleId === x.id)).map(toAssignment)
      );
    }
  }, [assignedRoles, roles]);

  const handleChange = (role: AuthRoleAssignmentReadModel) => {
    if (selectedRoles.includes(role)) {
      const newSelection = selectedRoles.filter((x) => x.roleId !== role.roleId);
      setSelectedRoles(newSelection);
    } else {
      const newSelection = selectedRoles.concat(role);
      setSelectedRoles(newSelection);
    }
  };

  return (
    <>
      <SlideOver.Header
        title={t('auth.roleAdd')}
        backgroundClassName={slideOverBackground}
        onClose={() => onClose(undefined)}
      />
      <SlideOver.Content className="p-8">
        {isFetching === true ? (
          <LoadingIndicator text={t('auth.roleLoadingIndicator') ?? ''} />
        ) : (
          <div className="divide-y">
            {selectableRoles.map((role, index) => (
              <label className="flex items-center bg-white p-3 cursor-pointer" key={index}>
                <input
                  type="checkbox"
                  checked={selectedRoles.includes(role)}
                  onChange={() => handleChange(role)}
                  autoFocus={false}
                />
                <ShieldCheckIcon className="w-6 mx-2"/>
                {TranslateRoleName(role.name, role.systemRoleType)}
              </label>
            ))}
          </div>
        )}
      </SlideOver.Content>
      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(undefined)}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={() => onClose(selectedRoles)} innerRef={submitRef}>
          {t('common.add')}
        </Button>
      </SlideOver.Controls>
    </>
  );
};
