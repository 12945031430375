import { DealReadModel, TaxonomyCostItemsReadModel } from '@client/shared/api';
import { DealTypeGroup } from './DealTypeGroup';
import { useLoadedProjectId, useReadOnly } from '@client/project/store';
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';

interface ItemDetailsProps {
  item: TaxonomyCostItemsReadModel;
}

export const ItemDetails = ({ item: { attachedContracts } }: ItemDetailsProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const projectId = useLoadedProjectId()
  const isReadOnly = useReadOnly();

  const handleSelect = (item: DealReadModel) => {
    if (item.type === 'Rent') {
      navigate({
        pathname: ROUTES_CONFIG.PROJECT_RENT_DEAL_VIEW.path.replace(':id', projectId ?? '').replace(':dealId', item.id),
        search: createSearchParams(searchParams).toString()
      })
    } else {
      navigate({
        pathname: ROUTES_CONFIG.PROJECT_SELL_DEAL_VIEW.path.replace(':id', projectId ?? '').replace(':dealId', item.id),
        search: createSearchParams(searchParams).toString()
      })
    }
  }

  return (
    <div className="flex flex-col p-8">
      <div className="space-y-4">
        <DealTypeGroup type="Rent" items={attachedContracts} onSelect={handleSelect} isReadOnly={isReadOnly} />
        <DealTypeGroup type="Sell" items={attachedContracts} onSelect={handleSelect} isReadOnly={isReadOnly}/>
      </div>
    </div>
  );
};
