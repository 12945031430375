import { CoverImageSize, ShortProjectGroupReadModel, ShortProjectReadModel, TrustedProjectReadModel } from '@client/shared/api';

export const PROJECT_THUMBNAIL_PLACEHOLDER_PATH = '/assets/project-placeholder/';
export const PROJECT_THUMBNAIL_PLACEHOLDER_EMPTY = `${PROJECT_THUMBNAIL_PLACEHOLDER_PATH}project-placeholder-empty.svg`;
export const GET_PROJECT_COVER_ID_ENDPOINT = '/api/projects/:projectId/cover/:coverId/:size';
export const GET_PROJECT_GROUP_COVER_ID_ENDPOINT = '/api/projectgroups/:projectId/cover/:coverId/:size';
export const GET_CROSS_TENANT_PROJECT_COVER_ENDPOINT = '/api/cross-tenant/:tenantId/projects/:projectId/cover/:coverId';
export const GET_CROSS_TENANT_AVATAR_ENDPOINT = '/api/cross-tenant/:tenantId/avatar';

// this randomly takes a random stock photo image from the assets folder if no cover picture is set
export const getRandomProjectThumbnail = (name: string) => {
  const number = (name.length % 6) + 1;
  return `${PROJECT_THUMBNAIL_PLACEHOLDER_PATH}project-placeholder-${number}.jpg`;
};

export const getProjectThumbnailPath = (name: string, size: CoverImageSize, projectId?: string, coverPictureId?: string | null, group = false) => {
  if (group) {
    return projectId && coverPictureId
      ? GET_PROJECT_GROUP_COVER_ID_ENDPOINT.replace(':projectId', projectId).replace(':coverId', coverPictureId).replace(':size', size)
      : getRandomProjectThumbnail(name);
  }
  return projectId && coverPictureId && size
    ? GET_PROJECT_COVER_ID_ENDPOINT.replace(':projectId', projectId).replace(':coverId', coverPictureId).replace(':size', size)
    : getRandomProjectThumbnail(name);
};

export const getProjectThumbnail = (size: CoverImageSize, project?: ShortProjectGroupReadModel | ShortProjectReadModel, group = false) => {
  if (project == null) return PROJECT_THUMBNAIL_PLACEHOLDER_EMPTY;

  return getProjectThumbnailPath(project.name, size, project.id, project.coverPictureId, group);
};

export const getProjectThumbnailByCoverId = (
  projectId: string,
  name: string,
  size: CoverImageSize,
  coverId?: string | null,
  group = false,
) => {
  return getProjectThumbnailPath(name, size, projectId, coverId, group);
};

export const getCrossTenantProjectThumbnail = (project?: TrustedProjectReadModel) => {
  if (project == null) return PROJECT_THUMBNAIL_PLACEHOLDER_EMPTY;

  return project.coverPictureId
    ? GET_CROSS_TENANT_PROJECT_COVER_ENDPOINT.replace(':tenantId', project.tenantId)
        .replace(':projectId', project.id)
        .replace(':coverId', project.coverPictureId)
    : getRandomProjectThumbnail(project.name);
};

export const getCrossTenantAvatar = (tenantId: string) => {
  return GET_CROSS_TENANT_AVATAR_ENDPOINT.replace(':tenantId', tenantId);
};
