import { useTranslation } from 'react-i18next';
import { useApiPostUpdatePasswordMutation } from '@client/shared/api';
import { Button, Modal, TextInput, Form, FormField, ModalOnCloseProps, LoadingIndicator } from '@client/shared/toolkit';
import {
  ChangePasswordFormValidationSchema,
  ChangePasswordFormValidationValues,
} from './ChangePasswordFormValidationSchema';
import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';

export const ChangePasswordModal = ({ onClose }: ModalOnCloseProps) => {
  const { t } = useTranslation();

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const [postChangePassword, { isLoading }] = useApiPostUpdatePasswordMutation();

  const handleSubmit = async (data: ChangePasswordFormValidationValues) => {
    try {
      await postChangePassword({
        body: {
          currentPassword: data.currentPassword,
          newPassword: data.newPassword,
        },
      }).unwrap();

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const defaultFormValues = { currentPassword: '', newPassword: '', newPasswordConfirmation: '' };

  return (
    <Form<ChangePasswordFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={ChangePasswordFormValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
    >
      {isLoading && <LoadingIndicator text={t('common.updating')} mode="overlay-window" />}
      <Modal.Header title={t('app.loginSecurityChangePassword')} />
      <Modal.Content className="flex flex-col divide-gray-100 divide-y">
        <FormField name="currentPassword">
          {(control) => (
            <div className="flex justify-between items-center bg-white" onBlur={() => setShowCurrentPassword(false)}>
              <TextInput
                label={t('app.loginSecurityCurrentPassword')}
                inputType={showCurrentPassword ? 'text' : 'password'}
                autoComplete="current-password"
                helperTextTruncate={false}
                className="w-full max-w-[calc(100%-35px)] flex-1"
                {...control}
              />
              <div
                className="w-5 cursor-pointer mx-2 flex-none"
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                data-cy="button-show-password"
              >
                {showCurrentPassword ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
              </div>
            </div>
          )}
        </FormField>
        <FormField name="newPassword">
          {(control) => (
            <div className="flex justify-between items-center bg-white" onBlur={() => setShowPassword(false)}>
              <TextInput
                label={t('app.loginSecurityNewPassword')}
                inputType={showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                className="w-full max-w-[calc(100%-35px)] flex-1"
                {...control}
              />
              <div
                className="w-5 cursor-pointer mx-2 flex-none"
                onClick={() => setShowPassword(!showPassword)}
                data-cy="button-show-password"
              >
                {showPassword ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
              </div>
            </div>
          )}
        </FormField>
        <FormField name="newPasswordConfirmation">
          {(control) => (
            <div
              className="flex justify-between items-center bg-white"
              onBlur={() => setShowPasswordConfirmation(false)}
            >
              <TextInput
                label={t('app.loginSecurityConfirmPassword')}
                inputType={showPasswordConfirmation ? 'text' : 'password'}
                autoComplete="new-password"
                className="w-full max-w-[calc(100%-35px)] flex-1"
                {...control}
              />
              <div
                className="w-5 cursor-pointer mx-2 flex-none"
                onClick={() => setShowPassword(!showPasswordConfirmation)}
                data-cy="button-show-password"
              >
                {showPasswordConfirmation ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
              </div>
            </div>
          )}
        </FormField>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" disabled={isLoading} formSubmit={true}>
          {t('common.save')}
        </Button>
      </Modal.Controls>
    </Form>
  );
};
