import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { SearchIcon } from '../icons';

export interface SearchInputProps {
  className?: string;
  showIcon?: boolean;
  placeholder?: string;
  handleSearch: (searchValue: string) => void;
  disabled?: boolean;
  variant?: 'light' | 'transparent';
  size?: 'small' | 'medium';
  value?: string;
  bgColor?: string;
}

export const SearchInput = (props: SearchInputProps) => {
  const {
    className,
    showIcon = true,
    placeholder,
    handleSearch,
    disabled = false,
    variant = 'light',
    size = 'medium',
    value,
    bgColor = 'bg-white',
  } = props;
  const { t } = useTranslation();

  const variantStyles = useMemo(() => {
    return variant !== 'transparent' ? `rounded-full text-gray-600 ${size === 'medium' ? 'px-5 h-12': 'px-4 h-10'}` : '';
  }, [variant, size]);

  return (
    <div className={cn('flex gap-2 items-center', variantStyles, className, bgColor)}>
      {showIcon && <SearchIcon className='h-5 w-5' />}
      <input
        value={value}
        disabled={disabled}
        type="search"
        placeholder={placeholder ? placeholder : t('common.search')}
        className="h-full w-full text-sm focus:outline-none border-none bg-transparent"
        onChange={(e) => handleSearch(e.target.value)}
      />
    </div>
  );
};
