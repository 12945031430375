import { useApiPostCreateCalculationModelSnapshotMutation, CalculationModelMetadata } from '@client/shared/api';
import React, { useState } from 'react';
import {
  TextInput,
  Modal,
  Button,
  HintBox,
  EditNodeIcon,
  ModalOnCloseProps,
  LoadingIndicator
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

interface VariantCreateSnapshotModalProps extends ModalOnCloseProps {
  variant: CalculationModelMetadata;
}

export const VariantCreateSnapshotModal = ({ onClose, variant }: VariantCreateSnapshotModalProps) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState('');
  const [call, { isLoading }] = useApiPostCreateCalculationModelSnapshotMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleOnCreateSnapshot = async () => {
    try {
      await safeMutation(
        call,
        {
          projectId: variant.projectId,
          calculationModelId: variant.id,
          body: {
            comment: title,
          },
        },
        isLoading
      );

      onClose(true);
    } catch {
      /* left blank */
    }
  };

  return (
    <>
      {isLoading && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
      <Modal.Header title={t('projectVariants.variantCreateSnapshotTitle')} />
      <Modal.Content className="space-y-6">
        <TextInput
          label={t('projectVariants.variantCreateSnapshotLabelName')}
          value={title}
          onChange={(value) => setTitle(value)}
          icon={<EditNodeIcon />}
        />
        <HintBox>{t('projectVariants.variantCreateSnapshotHint')}</HintBox>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={handleOnClose} className="mr-2" variant="secondary">
          {t('common.cancel')}
        </Button>
        <Button onClick={handleOnCreateSnapshot} disabled={title === ''}>
          {t('projectVariants.variantCreateSnapshotTitle')}
        </Button>
      </Modal.Controls>
    </>
  );
};
