import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Collapsible } from '@client/shared/toolkit';
import { ToggleButton } from '@client/shared/toolkit';
import { useDispatch } from 'react-redux';
import { setExpandedBudgetReportIds, setExpandedComparisonReportIds, setExpandedForecastReportIds } from '@client/project/store';

export type ColumnRowStyle = (index: number) => Record<string, unknown>;

interface ReportCollapsingRowProps<T> extends PropsWithChildren {
  rowData?: T & { rowIdentifier: string; children?: T[] | null };
  level?: number;
  columns?: React.ReactElement[];
  columnRowStyle?: ColumnRowStyle;
  // FP: Using any here because different components are passed in as props
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  RowComponent: any;
  expandedElements: string[];
  report: 'Forecast' | 'Budget' | 'Comparison';
}

export const ReportCollapsingRow = <T extends object>({
  children,
  columns,
  level = 0,
  rowData,
  columnRowStyle,
  RowComponent,
  expandedElements,
  report,
}: ReportCollapsingRowProps<T>) => {
  const dispatch = useDispatch();

  const dispatchElements = () => {
    if (rowData) {
      const elements = expandedElements.includes(rowData?.rowIdentifier)
        ? expandedElements.filter((x) => x !== rowData?.rowIdentifier)
        : [...expandedElements, rowData?.rowIdentifier];

      switch (report) {
        case 'Forecast':
          dispatch(setExpandedForecastReportIds(elements));
          break;
        case 'Budget':
          dispatch(setExpandedBudgetReportIds(elements));
          break;
        case 'Comparison':
          dispatch(setExpandedComparisonReportIds(elements));
          break;
        default:
          break;
      }
    }
  };
  return (
    <>
      <div
        className={classNames('flex items-center text-[13px] px-4 py-2 hover:bg-slate-50 transition-colors', {
          'bg-gray-50 font-bold border-b border-slate-300': level === 0,
          'bg-white border-b border-slate-300 border-dotted': level > 0,
        })}
      >
        {rowData && (children || (rowData?.children && rowData.children.length > 0)) ? (
          <ToggleButton open={expandedElements.includes(rowData?.rowIdentifier)} onClick={dispatchElements} />
        ) : (
          <div className="w-8">&nbsp;</div>
        )}
        <div className="flex flex-grow h-full justify-center items-center">
          {columns?.map((column, index) => (
            <div
              key={index}
              className={classNames(
                'h-full flex flex-col justify-center',
                columnRowStyle
                  ? columnRowStyle(index)
                  : {
                      'w-20 text-right px-2': index === 0,
                      'w-[422px]': index === 1,
                      'flex-grow': index === 2,
                      'w-48 text-right px-4': index > 2,
                      'pl-8': level > 1,
                      '-mr-8': level > 1 && index === 1,
                    },
              )}
            >
              {column}
            </div>
          ))}
        </div>
      </div>
      {rowData && children && (
        <Collapsible open={expandedElements.includes(rowData?.rowIdentifier)}>{children}</Collapsible>
      )}
      {rowData && rowData?.children && rowData.children.length > 0 && (
        <Collapsible open={expandedElements.includes(rowData?.rowIdentifier)}>
          {rowData.children.map((child, index) => (
            <RowComponent
              key={index}
              rowData={child}
              level={level + 1}
              expandedElements={expandedElements}
              report={report}
            />
          ))}
        </Collapsible>
      )}
    </>
  );
};
