import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {
  Button,
  Form,
  FormDottedIcon,
  FormField, LoadingIndicator,
  Modal,
  TaskCompletedDottedIcon,
  TextInput,
  Wizard,
  WizardSlideContent,
  WizardSlideHeader,
} from '@client/shared/toolkit';
import {
  RequirementCreateFormValidationSchema,
  RequirementCreateFormValidationValues,
} from './RequirementFormValidationSchemas';
import {
  RequirementReadModel,
  useApiPostUpdateClusterItemRequirementStatusMutation
} from '@client/shared/api';
import { ClusterItemSelect } from './ClusterItemSelect';
import { useLoadedPortfolioId } from '@client/portfolio/store';
import { safeMutation } from '@client/shared/utilities';

export const RequirementSubmitWizard = ({ onClose, requirement }: { onClose: () => void, requirement: RequirementReadModel }) => {
  const { t } = useTranslation();
  const loadedPortfolioId = useLoadedPortfolioId();
  const [currentStep, setCurrentStep] = useState(0);

  const [updateState, { isLoading }] = useApiPostUpdateClusterItemRequirementStatusMutation();

  const handleSubmit = async (data: RequirementCreateFormValidationValues) => {
    if (loadedPortfolioId) {
      try {
        await safeMutation(
          updateState,
          {
            portfolioId: loadedPortfolioId,
            clusterItemId: requirement.clusterItem.id,
            requirementId: requirement.id,
            body: {
              name: data.name,
              clusterItemId: data.clusterItemId,
              state: 'Submitted'
            }
          },
          isLoading
        );
        setCurrentStep(currentStep + 1);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const defaultFormValues = {
    name: requirement.name,
    code: requirement.code,
    clusterItemId: requirement.clusterItem.id
  };

  return (
    <Form<RequirementCreateFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={RequirementCreateFormValidationSchema}
      defaultValues={defaultFormValues}
      className="h-full"
    >
      {isLoading && <LoadingIndicator text={t('portfolio.projecting.submitNotificationWizard.loadingIndicator')} mode="overlay" />}
      <Wizard currentStep={currentStep} onSlideChange={(step) => setCurrentStep(step)}>
        <Wizard.Slides>
          <Wizard.Slide>
            <WizardSlideHeader
              icon={<FormDottedIcon />}
              title={t('portfolio.projecting.submitNotificationWizard.title')}
            />
            <WizardSlideContent>
              <div className="flex gap-0.5">
                <FormField name="name">
                  {(control) => (
                    <TextInput
                      label={t('portfolio.projecting.requirementEdit.title')}
                      {...control}
                      className="flex-1"
                      helperTextClassName="bg-transparent"
                    />
                  )}
                </FormField>
                <FormField name="code">
                  {(control) => (
                    <TextInput
                      label={t('portfolio.projecting.requirementEdit.number')}
                      {...control}
                      disabled
                      className="w-[150px] flex-none"
                      helperTextClassName="bg-transparent"
                    />
                  )}
                </FormField>
              </div>
              <ClusterItemSelect disabled />
            </WizardSlideContent>
          </Wizard.Slide>
          <Wizard.Slide>
            <WizardSlideContent>
              <WizardSlideHeader
                icon={<TaskCompletedDottedIcon />}
                title={t('portfolio.projecting.submitNotificationWizard.successfullyCreated')}
                subTitle={t('portfolio.projecting.submitNotificationWizard.successfullyCreatedSubTitle')}
                fullHeight
              />
            </WizardSlideContent>
          </Wizard.Slide>
        </Wizard.Slides>
        <Wizard.Navigation>
          {({ isFirst, isLast }) => (
            <Modal.Controls>
              {(() => {
                if (isFirst) {
                  return (
                    <>
                      <Button variant="text" onClick={onClose}>
                        {t('common.cancel')}
                      </Button>
                      <Button variant="primary" formSubmit>
                        {t('portfolio.projecting.requirementSlideOver.submit')}
                      </Button>
                    </>
                  );
                } else if (isLast) {
                  return (
                    <Button variant="primary" onClick={onClose}>
                      {t('portfolio.projecting.submitNotificationWizard.gotIt')}
                    </Button>
                  );
                }
              })()}
            </Modal.Controls>
          )}
        </Wizard.Navigation>
      </Wizard>
    </Form>
  );
};
