import { useLoadedProjectId } from '@client/project/store';
import { useApiGetFinancingPurposeOverviewQuery } from '@client/shared/api';
import { Button, LoadingIndicator, Modal } from '@client/shared/toolkit';
import { formatDate } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { Fragment, PropsWithChildren } from 'react';
import cn from 'classnames';
import { FormattedCurrency } from '@client/project/shared';

interface FinancingPurposeOverviewProps {
  onClose: () => void;
  financingPurposeId?: string;
  financingElementId?: string;
  variantId: string;
}

export const FinancingPurposeOverviewModal = ({
  financingPurposeId,
  financingElementId,
  variantId,
  onClose,
}: FinancingPurposeOverviewProps) => {
  const { t } = useTranslation();
  const projectId = useLoadedProjectId();
  const { data: purposeOverview, isFetching: isLoadingPurposeOverview } = useApiGetFinancingPurposeOverviewQuery({
    projectId: projectId ?? 'unset',
    calculationModelId: variantId ?? 'unset',
    financingPurposeId: financingPurposeId === '' ? undefined : financingPurposeId,
    financingElementId: financingElementId === '' ? undefined : financingElementId,
  });
  return (
    <>
      <Modal.Header title={t('projectCalculate.financingPurposeModel.overviewTitle')} />
      <Modal.Content>
        {isLoadingPurposeOverview ? (
          <LoadingIndicator text={t('projectCalculate.financingPurposeModel.LoadingIndicator')} />
        ) : (
          <div className="w-full overflow-y-auto">
            <table className="w-full text-gray-800 border-separate border-spacing-0">
              {purposeOverview?.overviewResult?.dates && purposeOverview?.overviewResult.dates.length > 0 && (
                <thead className="font-medium">
                  <tr>
                    <FinancingPurposeOverviewModalTableHeaderCell className="font-medium" />
                    {purposeOverview?.overviewResult.dates.map((date) => (
                      <Fragment key={`financing-purpose-date-${date}`}>
                        <FinancingPurposeOverviewModalTableBodyCell className="">
                          {formatDate(date, { includeDay: false })}
                        </FinancingPurposeOverviewModalTableBodyCell>
                      </Fragment>
                    ))}
                  </tr>
                </thead>
              )}
              <tbody>
                {purposeOverview?.overviewResult.financingSegments.map((financingSegment, rIdx) => {
                  return (
                    <tr key={`financing-purpose-segment-${rIdx}`}>
                      <FinancingPurposeOverviewModalTableHeaderCell
                        className={cn('font-medium', {
                          'border-t-[5px]': rIdx === 0,
                        })}
                      >
                        {financingSegment.name}
                      </FinancingPurposeOverviewModalTableHeaderCell>
                      {financingSegment.amounts.map((amount, cIdx) => (
                        <Fragment key={`financing-purpose-amounts-${cIdx}`}>
                          <FinancingPurposeOverviewModalTableBodyCell
                            className={cn({
                              'border-t-[5px]': rIdx === 0,
                            })}
                          >
                            <FormattedCurrency amount={amount} />
                          </FinancingPurposeOverviewModalTableBodyCell>
                        </Fragment>
                      ))}
                    </tr>
                  );
                })}
                <tr>
                  <FinancingPurposeOverviewModalTableHeaderCell className="border-t-[5px]">
                    {t('projectCalculate.financingPurposeModel.totalCosts')}
                  </FinancingPurposeOverviewModalTableHeaderCell>
                  {purposeOverview?.overviewResult.totalCosts.map((cost, i) => (
                    <Fragment key={`financing-purpose-total-cost-${i}`}>
                      <FinancingPurposeOverviewModalTableBodyCell bold className="border-t-[5px]">
                        <FormattedCurrency amount={cost} />
                      </FinancingPurposeOverviewModalTableBodyCell>
                    </Fragment>
                  ))}
                </tr>
                <tr>
                  <FinancingPurposeOverviewModalTableHeaderCell>
                    {t('projectCalculate.financingPurposeModel.totalFinancing')}
                  </FinancingPurposeOverviewModalTableHeaderCell>
                  {purposeOverview?.costsFinancingOverviewItems.map((purpose, i) => (
                    <Fragment key={`financing-purpose-overview-item-total-${i}`}>
                      <FinancingPurposeOverviewModalTableBodyCell bold>
                        <FormattedCurrency amount={purpose.totalFinancing} />
                      </FinancingPurposeOverviewModalTableBodyCell>
                    </Fragment>
                  ))}
                </tr>
                <tr>
                  <FinancingPurposeOverviewModalTableHeaderCell className="border-t-[5px]">
                    {t('projectCalculate.financingPurposeModel.balance')}
                  </FinancingPurposeOverviewModalTableHeaderCell>
                  {purposeOverview?.costsFinancingOverviewItems.map((purpose, i) => (
                    <Fragment key={`financing-purpose-overview-item-balance-${i}`}>
                      <FinancingPurposeOverviewModalTableBodyCell
                        bold
                        className={cn('border-t-[5px]', {
                          'text-red-800': purpose.balance < 0,
                        })}
                      >
                        <FormattedCurrency amount={purpose.balance} />
                      </FinancingPurposeOverviewModalTableBodyCell>
                    </Fragment>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={() => onClose()} className="mr-2" variant="primary">
          {t('common.close')}
        </Button>
      </Modal.Controls>
    </>
  );
};

export interface FinancingPurposeOverviewModalTableHeaderCellProps extends PropsWithChildren {
  className?: string;
  bold?: boolean;
}
export const FinancingPurposeOverviewModalTableHeaderCell = (
  props: FinancingPurposeOverviewModalTableHeaderCellProps
) => {
  const { children, className } = props;
  return (
    <th
      className={cn(
        'sticky -left-px p-3 border-b-2 border-gray-100 bg-white whitespace-nowrap',
        'after:content-[""] after:absolute after:top-0 after:-right-px after:bg-gray-100 after:w-0.5 after:h-full',
        className
      )}
      scope="col"
    >
      {children}
    </th>
  );
};

export const FinancingPurposeOverviewModalTableBodyCell = (
  props: FinancingPurposeOverviewModalTableHeaderCellProps
) => {
  const { children, className, bold } = props;
  return (
    <td
      className={cn(
        'text-right pl-8 pr-3 py-3 border-r-2 border-b-2 border-gray-100 bg-white whitespace-nowrap',
        className,
        {
          'font-medium': bold,
        }
      )}
    >
      {children}
    </td>
  );
};
