import { Radio, RadioGroup, Popover, Portal, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useState, useId, useEffect, useCallback, useRef } from 'react';
import { usePopper } from 'react-popper';
import { useTranslation } from 'react-i18next';
import { FormHelperText, NumberInput } from '.';
import cn from 'classnames';

type TaxCategoryOptionsType = 'no-tax' | 'null' | 'project-tax' | 'custom-tax';

export interface TaxPickerInputProps {
  label?: string;
  icon?: React.ReactNode;
  value?: number | null;
  projectTaxRate?: number | null;
  onChange: (value: number | undefined | null) => void;
  placeHolder?: string;
  className?: string;
  disabled?: boolean;
  showValidation?: boolean;
  isValidationValid?: boolean;
  helperText?: string;
  isNullable?: boolean;
  nullLabel?: string;
  handlePopoverVisibility?: (isOpen: boolean) => void
}

export const TaxPickerInput = ({
  icon,
  label,
  value,
  projectTaxRate,
  onChange,
  className,
  showValidation,
  isValidationValid,
  helperText,
  disabled,
  isNullable = false,
  nullLabel = '',
  handlePopoverVisibility
}: TaxPickerInputProps) => {
  const inputId = useId();
  const { t } = useTranslation();

  const numberInputRef = useRef<HTMLDivElement>(null);

  // somehow value does not get updated if it's null or undefined, so we store the correct value here
  const [updatedValue, setUpdatedValue] = useState(value);
  const [taxCategory, setTaxCategory] = useState<TaxCategoryOptionsType>('custom-tax');
  const [targetElement, setTargetElement] = React.useState<HTMLDivElement | null>(null);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
          altBoundary: true,
        },
      },
    ],
  });

  useEffect(() => {
    if (handlePopoverVisibility) {
      handlePopoverVisibility(!!popperElement)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperElement]);

  const handleTaxCategoryChange = useCallback(
    (category: TaxCategoryOptionsType) => {
      let tax: number | null = null;
      switch (category) {
        case 'null':
          tax = null;
          break;
        case 'no-tax':
          tax = 0;
          break;
        case 'project-tax':
          tax = projectTaxRate ?? null;
          break;
        case 'custom-tax':
          tax = 1;
          break;
      }
      onChange(tax);
    },
    [onChange, projectTaxRate],
  );

  useEffect(() => {
    if (taxCategory === 'custom-tax') {
      const numberInput = numberInputRef.current?.querySelector('input');
      if (numberInput) {
        numberInput.focus();
      }
    }
  }, [taxCategory]);

  useEffect(() => {
    let cat: TaxCategoryOptionsType = 'custom-tax';

    // value is null / undefined => set a default value
    if (value === null || typeof value === 'undefined') {
      if (isNullable) {
        cat = 'null';
      } else if (projectTaxRate) {
        // project tax rate as default value
        cat = 'project-tax';
        setUpdatedValue(projectTaxRate);
        handleTaxCategoryChange('project-tax');
      } else {
        // 0 as default value
        cat = 'no-tax';
        setUpdatedValue(0);
        handleTaxCategoryChange('no-tax');
      }
    } else {
      // there is a value
      if (value === projectTaxRate) {
        // same as project tax rate
        cat = 'project-tax';
      } else if (value === 0) {
        // same as 0
        cat = 'no-tax';
      }
    }
    setTaxCategory(cat);
  }, [value, projectTaxRate, isNullable, handleTaxCategoryChange]);

  useEffect(() => {
    if (value !== updatedValue) {
      setUpdatedValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleCustomTaxChange = useCallback(
    (val: number | null) => {
      if (val === null && !isNullable) {
        if (projectTaxRate) {
          handleTaxCategoryChange('project-tax');
        } else {
          handleTaxCategoryChange('no-tax');
        }
      } else {
        onChange(val);
      }
    },
    [handleTaxCategoryChange, onChange, isNullable, projectTaxRate],
  );

  const listOptionsWidth = targetElement?.scrollWidth;

  return (
    <div className={className}>
      <div className={cn('w-full relative h-14 bg-white', { 'text-gray-500 cursor-not-allowed': disabled })}>
        <Popover as="div">
          <>
            <div ref={setTargetElement}>
              <PopoverButton
                as="div"
                ref={setReferenceElement}
                className={classNames('w-full relative h-14 px-3 flex flex-row bg-white outline-none peer', className, {
                  'shadow-[inset_0px_0px_0px_1px] shadow-red-500':
                    showValidation && isValidationValid != null && !isValidationValid,
                  'shadow-[inset_0px_0px_0px_1px] shadow-green-500': showValidation && isValidationValid,
                  'text-gray-500 cursor-not-allowed': disabled,
                })}
                disabled={disabled}
              >
                {icon && (
                  <div className="flex items-center h-full">
                    <div className="h-5 w-5 flex items-center justify-center">{icon}</div>
                  </div>
                )}
                <div
                  className={classNames('relative flex-grow min-w-0', {
                    'ml-2': icon,
                    'mx-1': !icon,
                  })}
                >
                  <div
                    className={classNames(
                      'fake-mt block pt-5 w-full text-lg appearance-none focus:outline-none bg-transparent font-medium text-left truncate',
                      {
                        'text-gray-800': !disabled,
                        'text-gray-500 cursor-not-allowed': disabled,
                      },
                    )}
                  >
                    {updatedValue ?? nullLabel}
                  </div>
                  <label
                    htmlFor={inputId}
                    className={classNames(
                      'absolute top-0 left-0 right-0 duration-200 origin-0 select-none transform truncate text-left pt-5 ',
                      {
                        'text-lg -translate-y-1':
                          (typeof updatedValue === 'undefined' || updatedValue === null) && !nullLabel,
                        'text-xs -translate-y-3':
                          (typeof updatedValue !== 'undefined' && updatedValue !== null) || nullLabel,
                        'text-gray-600': !disabled,
                        'text-gray-500 cursor-not-allowed': disabled,
                      },
                    )}
                  >
                    {label}
                  </label>
                </div>
                <div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronDownIcon className="w-5 h-5 text-gray-800" />
                  </div>
                </div>
                {popperElement && (
                  <div
                    className={classNames(
                      'absolute bottom-0 h-0.5 bg-black left-0 right-0 duration-200 transition-opacity peer-focus:opacity-100',
                    )}
                  />
                )}
              </PopoverButton>
            </div>
            <Portal>
              <PopoverPanel
                ref={setPopperElement}
                style={{ ...styles.popper, width: listOptionsWidth }}
                {...attributes.popper}
                className="z-20"
              >
                <div className="rounded rounded-tr-none bg-slate-100  w-full z-20 shadow relative">
                  <RadioGroup value={taxCategory} onChange={handleTaxCategoryChange} name="time-options">
                    {isNullable && (
                      <Radio value="null" as={Fragment} key="null-tax-radio" disabled={disabled}>
                        {({ checked }) => (
                          <div className="bg-white border-b flex pl-4 py-4">
                            <div className="w-full">{nullLabel ?? t('app.EmptyTaxRate')}</div>
                            <div className="flex flex-col items-center justify-center pr-3">
                              <CustomRadioCircle checked={checked} />
                            </div>
                          </div>
                        )}
                      </Radio>
                    )}

                    <Radio value="no-tax" as={Fragment} key="no-tax-radio" disabled={disabled}>
                      {({ checked }) => (
                        <div className="bg-white border-b flex pl-4 py-4">
                          <div className="w-full">{t('app.NoTaxRate', { tax: '0%' })}</div>
                          <div className="flex flex-col items-center justify-center pr-3">
                            <CustomRadioCircle checked={checked} />
                          </div>
                        </div>
                      )}
                    </Radio>

                    {typeof projectTaxRate !== 'undefined' && (
                      <Radio value="project-tax" as={Fragment} key="project-tax-radio" disabled={disabled}>
                        {({ checked }) => (
                          <div className="bg-white border-b flex pl-4 py-4">
                            <div className="w-full">{t('app.ProjectTaxRate', { tax: `${projectTaxRate}%` })}</div>
                            <div className="flex flex-col items-center justify-center pr-3">
                              <CustomRadioCircle checked={checked} />
                            </div>
                          </div>
                        )}
                      </Radio>
                    )}

                    <Radio value="custom-tax" as={Fragment} key="custom-tax-radio" disabled={disabled}>
                      {({ checked }) => (
                        <div className="bg-white border-b flex" ref={numberInputRef}>
                          <NumberInput
                            label={t('app.IndividualTaxRate')}
                            value={taxCategory === 'custom-tax' ? value : undefined}
                            onChange={handleCustomTaxChange}
                            className={cn('w-full', { 'pointer-events-none': !checked })}
                            disabled={disabled}
                          />
                          <div className="flex flex-col items-center justify-center pr-3">
                            <CustomRadioCircle checked={checked} />
                          </div>
                        </div>
                      )}
                    </Radio>
                  </RadioGroup>
                </div>
              </PopoverPanel>
            </Portal>
          </>
        </Popover>
      </div>
      {helperText && (
        <div className="w-full bg-white">
          <FormHelperText text={helperText} error={!isValidationValid} />
        </div>
      )}
    </div>
  );
};

export const CustomRadioCircle = ({ ...props }) => (
  <div className="h-4 w-4 border-2 rounded-full border-neutral-500 focus:ring-2 focus:ring-blue-300 flex items-center justify-center">
    <div className={classNames('w-2 h-2 rounded-full', { 'bg-black': props.checked })}></div>
  </div>
);
