import { Button } from '.';
import { Level1Icon, Level2Icon, Level3Icon } from '..';
import cn from 'classnames';

interface LevelToggleProps {
  handleOnCollapse: (level: number) => void;
  showLevels?: number[]
  className?: string;
  variant?: 'primary' | 'secondary' | 'custom';
  buttonClassName?: string
}

export const LevelToggle = ({
  handleOnCollapse,
  showLevels = [1, 2, 3],
  className,
  variant = 'secondary',
  buttonClassName
}: LevelToggleProps) => {
  if (!showLevels.length) return null
  return (
    <div className={cn('flex', className ? className : 'mx-4 space-x-1 h-8')}>
      {showLevels.includes(1) && (
        <Button
          variant={variant}
          onClick={() => handleOnCollapse(1)}
          className={cn('flex-none', buttonClassName)}
          hasPadding={variant !== 'custom'}
        >
          <Level1Icon className="w-4 h-4" />
        </Button>
      )}
      {showLevels.includes(2) && (
        <Button
          variant={variant}
          onClick={() => handleOnCollapse(2)}
          className={cn('flex-none', buttonClassName)}
          hasPadding={variant !== 'custom'}
        >
          <Level2Icon className="w-4 h-4" />
        </Button>
      )}
      {showLevels.includes(3) && (
        <Button
          variant={variant}
          onClick={() => handleOnCollapse(3)}
          className={cn('flex-none', buttonClassName)}
          hasPadding={variant !== 'custom'}
        >
          <Level3Icon className="w-4 h-4" />
        </Button>
      )}
    </div>
  );
};
