import { FormatNumberOptions, formatNumber } from './formatNumber';

export interface FormatUnitOptions extends FormatNumberOptions {
  prefix?: boolean;
}

/**
 * Formats a number to display in the user's locale with a prefixed or postfixed unit (e.g. 1.23 GB).
 * @param amount The number to format.
 * @param unit The unit to append to the number.
 * @param options formatting options.
 * @param options.maxDigits The maximum number of digits to display after the decimal point.
 * @param options.placeHolder The placeholder to use if the amount is null or undefined. Defaults to '-'.
 * @param options.signDisplay The sign display style to use. Defaults to 'auto'.
 * @param options.locale The locale to use. Defaults to the user's browser locale.
 * @param options.prefix Whether to prefix the unit to the number. Defaults to false.
 * @returns {string} A string representing the formatted number.
 */
export const formatUnit = (
  amount: number | null | undefined,
  unit: string,
  { prefix = false, ...options }: FormatUnitOptions = {}
): string => {
  if (prefix) {
    return `${unit} ${formatNumber(amount, options)}`;
  }

  return `${formatNumber(amount, options)} ${unit}`;
};
