import { apiBase } from '@client/shared/api';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { settings } from '@client/shared/store';
import { ROUTES_CONFIG } from '@client/shared/permissions';

export const PreviewBanner = () => {
  const dispatch = useDispatch();
  const isDevMode = settings.devMode;

  const resetApi = () => {
    dispatch(apiBase.util.resetApiState());
  };

  return (
    <>
      {isDevMode && (
        <div className="bg-red-400 flex justify-center items-center space-x-8 text-white z-20">
          <div className="flex space-x-4 p-1">
            <NavLink to={ROUTES_CONFIG.DEVELOPMENT.path}>DEV</NavLink>
            <button onClick={resetApi}>RESET API</button>
          </div>
        </div>
      )}
    </>
  );
};
