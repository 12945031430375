import {
  AuthAssignmentReadModel,
  AuthGroupReadModel,
  AuthRoleAssignmentReadModel,
  AuthSubjectReadModel,
  RoleAssignmentPayload,
  useApiPostUpdateAuthSubjectMutation,
} from '@client/shared/api';
import {
  Button,
  HintBox,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
  UsersCheckCustomIcon
} from '@client/shared/toolkit';
import { isEmpty, safeMutation } from '@client/shared/utilities';
import { CheckBadgeIcon, RectangleGroupIcon, ShieldCheckIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import { RoleList, RoleImpactEditSlideOver } from '../roles';
import { EffectivePermissionList } from '../permissions';
import { GroupList } from '../groups';
import { LicenseList } from '../../licenses';
import { EffectiveCatalogList } from '../templates';

type SubArea = 'Groups' | 'Roles' | 'Permissions' | 'CatalogPermissions' | 'Licenses';

interface UserDetailsSlideOverProps extends SlideOverOnCloseProps {
  user: AuthSubjectReadModel;
}

export const UserDetailsSlideOver = ({
  onClose,
  user: { effectiveCatalogs, effectivePermissions, groups: assigned, roles, tenantUserId, name, licenses },
}: UserDetailsSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const [subarea, setSubarea] = useState<SubArea>('Groups');

  const [showEditRole, setShowEditRole] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<AuthRoleAssignmentReadModel>();

  const [assignedRoles, setAssignedRoles] = useState<AuthRoleAssignmentReadModel[]>(roles.assigned);
  const [newRoles, setNewRoles] = useState<AuthRoleAssignmentReadModel[]>([]);
  const [deletedRoles, setDeletedRoles] = useState<AuthRoleAssignmentReadModel[]>([]);
  const [newGroups, setNewGroups] = useState<AuthGroupReadModel[]>([]);
  const [deletedGroups, setDeletedGroups] = useState<AuthGroupReadModel[]>([]);

  const [postUpdate, { isLoading }] = useApiPostUpdateAuthSubjectMutation();

  const handleRoleUpdate = (role: AuthRoleAssignmentReadModel) => {
    let index: number;
    let updatedRoleList: AuthRoleAssignmentReadModel[];
    let currentRole = assignedRoles.find((x) => x.roleId === role.roleId);

    if (currentRole) {
      index = assignedRoles.indexOf(currentRole);
      updatedRoleList = assignedRoles.slice();
      updatedRoleList.splice(index, 1, role);
      setAssignedRoles(updatedRoleList);
    }

    currentRole = newRoles.find((x) => x.roleId === role.roleId);
    if (currentRole) {
      index = newRoles.indexOf(currentRole);
      updatedRoleList = newRoles.slice();
      updatedRoleList.splice(index, 1, role);
      setNewRoles(updatedRoleList);
    }
  };

  const toPayload = (assignment: AuthAssignmentReadModel): RoleAssignmentPayload => ({
    roleId: assignment.role.id,
    projectId: assignment.project?.id,
  });

  const getNewRoleAssignments = (): RoleAssignmentPayload[] => {
    let assignments: RoleAssignmentPayload[] = [];

    newRoles.forEach((x) => {
      assignments = assignments.concat(x.assignments.map(toPayload));
    });

    assignedRoles.forEach((x) => {
      assignments = assignments.concat(x.assignments.filter(y => isEmpty(y.id)).map(toPayload));
    })

    return assignments;
  };

  const getDeletedRoleAssignments = (): string[] => {
    let assignments: string[] = [];

    deletedRoles.forEach((x) => {
      assignments = assignments.concat(x.assignments.map((y) => y.id));
    });

    assignedRoles.forEach((x) => {
      const originalRole = roles.assigned.find(y => y.roleId === x.roleId);
      if (originalRole) {
        assignments = assignments.concat(originalRole.assignments
            .filter(y => !x.assignments.some(z => z.id === y.id))
            .map(y => y.id));
      }
    });

    return assignments;
  };

  const handleSubmit = async () => {
    try {
      await safeMutation(
        postUpdate,
        {
          subjectId: tenantUserId,
          body: {
            addedGroups: newGroups.map((x) => x.id),
            deletedGroups: deletedGroups.map((x) => x.id),
            addedRoleAssignments: getNewRoleAssignments(),
            deletedRoleAssignments: getDeletedRoleAssignments(),
          },
        },
        isLoading
      );

      onClose(true);
    } catch {
      /* left blank */
    }
  };

  return (
    <>
      {isLoading && <LoadingIndicator mode="overlay-window" text={t('common.updating')} />}
      <SlideOver.Header
        title={name ?? ''}
        subTitle={t('auth.teamsAndPermissions')}
        backgroundClassName="bg-blue-900"
        onClose={onClose}
      />
      <SlideOver.Content className="p-8">
        <div>
          <div className="flex flex-row flex-wrap pt-2 mb-1">
            <button
              type="button"
              className={classNames('px-4 pb-2 text text-gray-900 block ', {
                'font-bold border-b border-slate-200': subarea === 'Groups',
              })}
              onClick={() => setSubarea('Groups')}
            >
              <div className="flex felx-row items-center">
                <UserGroupIcon className="w-5 h-5 mr-2" />
                {t('auth.groupPageTitle')}
              </div>
            </button>
            <button
              type="button"
              className={classNames('px-4 pb-2 text text-gray-900 block', {
                'font-bold border-b border-slate-200': subarea === 'Roles',
              })}
              onClick={() => setSubarea('Roles')}
            >
              <div className="flex felx-row items-center">
                <ShieldCheckIcon className="w-5 h-5 mr-2" />
                {t('auth.roles')}
              </div>
            </button>
            <button
              type="button"
              className={classNames('px-4 pb-2 text text-gray-900 block', {
                'font-bold border-b border-slate-200': subarea === 'Permissions',
              })}
              onClick={() => setSubarea('Permissions')}
            >
              <div className="flex felx-row items-center">
                <UsersCheckCustomIcon className="w-5 h-5 mr-2" />
                {t('auth.permissions')}
              </div>
            </button>
            <button
                type="button"
                className={classNames('px-4 pb-2 text text-gray-900 block', {
                  'font-bold border-b border-slate-200': subarea === 'CatalogPermissions',
                })}
                onClick={() => setSubarea('CatalogPermissions')}
              >
                <div className="flex felx-row items-center">
                  <RectangleGroupIcon className="w-5 h-5 mr-2" />
                  {t('auth.catalogPermissions')}
                </div>
            </button>
            <button
              type="button"
              className={classNames('px-4 pb-2 text text-gray-900 block', {
                'font-bold border-b border-slate-200': subarea === 'Licenses',
              })}
              onClick={() => setSubarea('Licenses')}
            >
              <div className="flex flex-row items-center">
                <CheckBadgeIcon className="h-5 w-5 mr-2" />
                {t('license.licenses')}
              </div>
            </button>
          </div>
          <div className="bg-white">
            {subarea === 'Groups' && (
              <GroupList
                assignedGroups={assigned}
                newGroups={newGroups}
                deletedGroups={deletedGroups}
                groupSelectionChanged={(a, r) => {
                  setNewGroups(a);
                  setDeletedGroups(r);
                }}
              />
            )}
            {subarea === 'Roles' && (
              <RoleList
                embeddedSlideOverBackground="bg-blue-900"
                assignedRoles={assignedRoles}
                newRoles={newRoles}
                deletedRoles={deletedRoles}
                onEditRoleImpact={(role) => {
                  setSelectedRole(role);
                  setShowEditRole(true);
                }}
                onRoleSelectionChanged={(add, rem) => {
                  setNewRoles(add);
                  setDeletedRoles(rem);
                }}
              />
            )}
            {subarea === 'Permissions' && (
              <EffectivePermissionList
                showGroupOrigin={true}
                tenantPermissions={effectivePermissions.tenantPermissions ?? []}
                projectPermissions={effectivePermissions.projectPermissions ?? []}
              >
                {(newRoles.length > 0 || deletedRoles.length > 0) && (
                  <HintBox hintType="info" title={t('licence.saveFirstToSeePermissionsMessage')} />
                )}
              </EffectivePermissionList>
            )}
            {subarea === 'CatalogPermissions' && (
              <EffectiveCatalogList
                effectiveTenantCatalogTemplates={effectiveCatalogs.filter(x => !x.project) ?? []}
                effectiveProjectCatalogTemplates={effectiveCatalogs.filter(x => x.project) ?? []}
              >
                {(newRoles.length > 0 || deletedRoles.length > 0) && (
                  <HintBox hintType="info" title={t('licence.saveFirstToSeePermissionsMessage')} />
                )}
              </EffectiveCatalogList>
            )}
            {subarea === 'Licenses' && <LicenseList licenses={licenses} />}
          </div>
        </div>
      </SlideOver.Content>

      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={handleSubmit} innerRef={submitRef}>
          {t('common.save')}
        </Button>
      </SlideOver.Controls>

      {selectedRole && (
        <SlideOver
          isOpen={showEditRole}
          onClose={() => {
            setShowEditRole(false);
            setSelectedRole(undefined);
          }}
        >
          <RoleImpactEditSlideOver
            roleAssignment={selectedRole}
            onClose={(role) => {
              if (role) {
                handleRoleUpdate(role);
              }

              setShowEditRole(false);
              setSelectedRole(undefined);
            }}
          />
        </SlideOver>
      )}
    </>
  );
};
