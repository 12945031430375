import classNames from 'classnames';
import React, { useId } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { Listbox, Transition, Portal, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { isEmpty } from '@client/shared/utilities';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { FormHelperText } from './FormHelperText';
import { BaseSelectOption } from './BaseSelect';
import { useTranslation } from 'react-i18next';

interface MultiSelectProps {
  label: string;
  value: string[];
  options: BaseSelectOption[];
  onChange: (selected: string[]) => void;
  icon?: React.ReactNode;
  placeHolder?: string;
  className?: string;
  disabled?: boolean;
  showValidation?: boolean;
  isValidationValid?: boolean;
  helperText?: string;
  nullable?: boolean;
}

export const MultiSelect = ({
  className,
  disabled,
  helperText,
  icon,
  isValidationValid,
  label,
  onChange,
  options,
  placeHolder,
  showValidation,
  value,
  nullable,
}: MultiSelectProps) => {
  const { t } = useTranslation();
  const inputId = useId();
  const selectedOptions = options.filter((option) => value.includes(option.value));
  const popperElRef = React.useRef(null);
  const [targetElement, setTargetElement] = React.useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  const listOptionsWidth = targetElement?.scrollWidth;

  return (
    <div className={className}>
      <div className="w-full relative h-14 bg-white">
        <Listbox value={value} onChange={(item) => onChange(item)} disabled={disabled} as="div" multiple={true}>
          {({ open }) => (
            <>
              <div ref={setTargetElement}>
                <ListboxButton
                  className={classNames('w-full relative h-14 px-3 flex flex-row bg-white outline-none peer', {
                    'shadow-[inset_0px_0px_0px_1px] shadow-red-500':
                      showValidation && isValidationValid != null && !isValidationValid,
                    'shadow-[inset_0px_0px_0px_1px] shadow-green-500': showValidation && isValidationValid,
                  })}
                >
                  {icon && (
                    <div className="flex items-center h-full">
                      <div className="h-5 w-5 flex items-center justify-center">{icon}</div>
                    </div>
                  )}
                  <div
                    className={classNames('relative flex-grow truncate', {
                      'ml-2': icon,
                      'mx-1': !icon,
                      'flex items-center': !label
                    })}
                  >
                    <div
                      className={classNames(
                        'fake-mt block w-full text-lg appearance-none focus:outline-none bg-transparent font-medium text-left truncate pr-4',
                        {
                          'text-gray-800': !disabled,
                          'text-gray-500 cursor-not-allowed': disabled,
                          'pt-5': label
                        },
                      )}
                    >
                      {selectedOptions.map((option) => option.label).join(', ') ?? <>&nbsp;</>}
                    </div>
                    {label && (
                      <label
                        htmlFor={inputId}
                        className={classNames(
                          'absolute top-0 left-0 right-0 text-lg duration-200 origin-0 text-gray-600 select-none transform truncate pr-4 text-left',
                          {
                            'pt-3 mt-[3px]': isEmpty(value) && isEmpty(placeHolder),
                            'pt-5 -mt-px text-xs -translate-y-3': !isEmpty(value) || !isEmpty(placeHolder),
                          },
                        )}
                      >
                        {label}
                      </label>
                    )}
                  </div>
                  <div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronDownIcon className="w-5 h-5 text-gray-800" />
                    </div>
                  </div>
                </ListboxButton>
                <div
                  className={classNames(
                    'absolute bottom-0 h-0.5 bg-black left-0 right-0 duration-200 transition-opacity peer-focus:opacity-100',
                    {
                      'opacity-0': !open,
                    },
                  )}
                ></div>
              </div>

              <Portal>
                <div
                  ref={popperElRef}
                  style={{ ...styles.popper, width: listOptionsWidth }}
                  {...attributes.popper}
                  className="z-50"
                >
                  <Transition
                    show={open}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    beforeEnter={() => setPopperElement(popperElRef.current)}
                    afterLeave={() => setPopperElement(null)}
                  >
                    <ListboxOptions className="origin-top-right bg-white border border-t-0 border-gray-200 divide-y divide-gray-100 rounded-bl-lg rounded-br-lg shadow-lg outline-none max-h-72 overflow-y-scroll">
                      {nullable && !!selectedOptions.length && (
                        <div
                          className="select-none relative py-2 px-3 text-sm cursor-pointer text-center text-gray-900 hover:bg-sky-100 truncate "
                          title={t('app.clearSelection')}
                          onClick={() => onChange([])}
                        >
                          {t('app.clearSelection')}
                        </div>
                      )}

                      {options.map((option, index) => (
                        <ListboxOption
                          key={index}
                          className={({ focus }) =>
                            classNames(
                              'select-none relative py-3 px-3 text-lg',
                              { 'cursor-pointer text-gray-900': !focus && !option.disabled },
                              { 'cursor-pointer text-gray-900 bg-sky-100': focus && !option.disabled },
                              { 'cursor-not-allowed text-gray-400 bg-gray-100/50': option.disabled },
                            )
                          }
                          value={option.value}
                          disabled={option.disabled}
                        >
                          {({ selected }) => (
                            <>
                              <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                {option.label}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-primary">
                                  <CheckIcon className="w-5 h-5" />
                                </span>
                              ) : undefined}
                            </>
                          )}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </Transition>
                </div>
              </Portal>
            </>
          )}
        </Listbox>
      </div>
      {helperText && (
        <div className="w-full bg-white">
          <FormHelperText text={helperText} error={!isValidationValid} />
        </div>
      )}
    </div>
  );
};
