import {
  DatevProbisMappingReadModel,
  useApiDeleteProjectDatevProbisMappingMutation,
  useApiDeleteTenantDatevProbisMappingMutation,
} from '@client/shared/api';
import { HintBox, ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

interface DatevMappingDeleteModalProps extends ModalOnCloseProps {
  apiAccessId?: string;
  item?: DatevProbisMappingReadModel | null;
}

export const DatevMappingDeleteModal = ({ item, apiAccessId, onClose }: DatevMappingDeleteModalProps) => {
  const { t } = useTranslation();

  const [deleteTenantMapping, { isLoading: isDeletingTenantMapping }] = useApiDeleteTenantDatevProbisMappingMutation();
  const [deleteProjectMapping, { isLoading: isDeletingProjectMapping }] =
    useApiDeleteProjectDatevProbisMappingMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    try {
      if (!item?.projectId) {
        await safeMutation(
          deleteTenantMapping,
          {
            apiAccessId: apiAccessId,
            mappingId: item?.id ?? '',
            datevApiAccessId: item?.apiAccessId ?? '',
          },
          isDeletingTenantMapping,
        );
      } else {

        await safeMutation(
          deleteProjectMapping,
          {
            projectId: item.projectId ?? '',
            mappingId: item?.id ?? '',
          },
          isDeletingProjectMapping,
        );
      }
      handleOnClose();
    } catch {
      /* left blank */
    }
  };

  return (
    <ConfirmModal
      title={t('app.settingsApiDatevConnectionDeleteItem', { name: item?.name ?? '' })}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      {isDeletingTenantMapping ||
        (isDeletingProjectMapping && <LoadingIndicator text={t('common.deleteLoading') ?? ''} />)}
      <HintBox hintType="warning">{t('app.settingsApiDatevConnectionDeleteItemWarning')}</HintBox>
    </ConfirmModal>
  );
};
