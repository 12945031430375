import { Button, ErrorCustomIcon, Modal, UploadPortalInviteIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

interface TenantTrustInviteModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  descriptionData: {
    company: string
    invitee?: string
    project?: string
  }
  activate: boolean
}
export const TenantTrustInviteModal = (props: TenantTrustInviteModalProps) => {
  const {
    isOpen,
    descriptionData,
    onClose,
    onSubmit,
    activate
  } = props
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header
        icon={activate ? <UploadPortalInviteIcon /> : <ErrorCustomIcon />}
        title={activate ? t('uploadPortal.sendInviteTitle') : t('uploadPortal.deactivateTrustTitle')}
        description={activate ? t('uploadPortal.sendInviteSubtitle', descriptionData) : t('uploadPortal.deactivateTrustSubtitle', descriptionData)}
      />
      <Modal.Controls className="bg-white">
        <Button
          variant="secondary"
          className="mr-2"
          onClick={onClose}
        >
          {t('common.cancel')}
        </Button>
        <Button
          variant="primary"
          className="mr-2"
          onClick={onSubmit}
        >
          {activate ? t('app.inviteSendInvitation') : t('common.next')}
        </Button>
      </Modal.Controls>
    </Modal>
  )
}
