import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { DeliveryPhaseOverviewReadModel, useApiDeleteCalculationModelDeliveryPhasesMutation } from '@client/shared/api';
import { ModalOnCloseProps, ConfirmModal } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation, Trans } from 'react-i18next';

interface DeleteCalculationModelDeliveryPhaseModalProps extends ModalOnCloseProps {
  deliveryPhase: DeliveryPhaseOverviewReadModel | undefined;
}

export const DeleteCalculationModelDeliveryPhaseModal = ({ deliveryPhase, onClose }: DeleteCalculationModelDeliveryPhaseModalProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId() ?? 'unset';
  const loadedCalculationModelId = useLoadedVariantId() ?? 'unset';
  const [deleteDeliveryPhase, { isLoading: isDeleting }] = useApiDeleteCalculationModelDeliveryPhasesMutation();

  const handleDelete = async () => {
    try {
    await safeMutation(
        deleteDeliveryPhase,
        {
            projectId: loadedProjectId,
            calculationModelId: loadedCalculationModelId,
            deliveryPhaseId: deliveryPhase?.id ?? 'unset'
        },
        isDeleting
    );

    onClose(true);
    } catch {
        /* left blank */
    }
  };

  return (
    <ConfirmModal
      title={`${t('common.delete')} ${deliveryPhase?.name ?? ''}`}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      <Trans i18nKey="projectCalculate.deliveryPhaseDeleteConfirmation" values={{ entity: `${deliveryPhase?.name ?? ''}` }}>
        <div className="block">
          Do you want to delete
          <span className="font-bold">DeliveryPhase</span>?<span className="font-bold">DeliveryPhase</span>?
        </div>
        <div className="inline-block pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
