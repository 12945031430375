import { useLoadedProjectId, useLoadedVariantId, useToggledCostFilters } from '@client/project/store';
import {
  Assignment,
  CatalogElementRestBudget,
  CostElementRestBudget,
  useApiGetCalculationModelRestBudgetMutation,
} from '@client/shared/api';
import { Button, CheckBox, TextHighlighter, TriangleIcon } from '@client/shared/toolkit';
import { formatLargeNumber, formatNumber, safeMutation } from '@client/shared/utilities';
import classNames from 'classnames';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { UserDefinedFieldsSearch } from '../UserDefinedFields';


interface BudgetAssignmentSelectProps {
  assignments: Assignment[];
  updateCostElements: (workItems: Assignment[]) => void;
  showBudget?: boolean;
  showFx?: boolean;
  showVat?: boolean;
  showControls?: boolean;
  onlyWithCostElementId?: boolean;
  onClose: () => void;
}

export const BudgetAssignmentSelect = ({
  assignments,
  updateCostElements,
  showBudget,
  showFx,
  showVat,
  showControls,
  onlyWithCostElementId,
  onClose,
}: BudgetAssignmentSelectProps) => {
  const [workAssignments, setWorkAssignments] = useState<Assignment[]>(assignments);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const toggledCostFilters = useToggledCostFilters();

  const loadedVariantId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();

  const [postGetRestBudget, { isLoading }] = useApiGetCalculationModelRestBudgetMutation();
  const [restBudgetElements, setRestBudgetElements] = useState<CatalogElementRestBudget[]>([]);
  useEffect(() => {
    const getRestBudget = async () => {
      const resp = await safeMutation(
        postGetRestBudget,
        {
          projectId: loadedProjectId ?? '',
          calculationModelId: loadedVariantId ?? '',
          body: workAssignments,
        },
        isLoading,
      );

      setRestBudgetElements(resp?.payload.catalogElements ?? []);
    };

    try {
      getRestBudget();
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workAssignments]);

  const addOrRemoveExpandedId = (elementId: string) => {
    if (expandedIds.includes(elementId)) {
      setExpandedIds((prev) => prev.filter((x) => x !== elementId));
    } else {
      setExpandedIds((prev) => [...prev, elementId]);
    }
  };

  const searchCostElements = (
    costElements: (CostElementRestBudget | null | undefined)[],
    searchValue: string,
  ): string[] => {
    if (!costElements) return [];
    else {
      const costElementIds: string[] = [];
      costElements.forEach((costElement) => {
        if (!costElement) return;
        if (
          toggledCostFilters.length > 0
            ? searchResults.length > 0 && searchResults.includes(costElement.id)
            : costElement.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
              (showVat && costElement.values.vat && costElement.values.vat.toString().includes(searchValue))
        ) {
          costElementIds.push(costElement.id);
        }
      });
      return costElementIds;
    }
  };

  const searchCostGroupElements = (restBudgetElements: CatalogElementRestBudget[], searchValue: string): string[] => {
    if (!restBudgetElements) return [];
    else {
      const costElementIds: string[] = [];

      restBudgetElements.forEach((restElement) => {
        if (
          toggledCostFilters.length > 0
            ? searchResults.length > 0 && restElement.costElementId && searchResults.includes(restElement.costElementId)
            : restElement.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
              restElement.code?.toLowerCase().includes(searchValue.toLowerCase()) ||
              (showVat && restElement.values.vat && restElement.values.vat.toString().includes(searchValue))
        ) {
          costElementIds.push(restElement.costElementId ?? '');
        }
        if (restElement.children.length > 0) {
          const childCostElementIds = searchCostGroupElements(restElement.children, searchValue);
          if (childCostElementIds.length > 0) {
            costElementIds.push(restElement.costElementId ?? '');
          }
          costElementIds.push(...childCostElementIds);
        }
        if (restElement.costElements.length > 0) {
          const childCostElementIds = searchCostElements(restElement.costElements, searchValue);
          if (childCostElementIds.length > 0) {
            costElementIds.push(restElement.costElementId ?? '');
          }
          costElementIds.push(...childCostElementIds);
        }
      });
      return costElementIds.filter((x) => x !== '');
    }
  };

  const matchingCostElements = useMemo(() => {
    if (searchValue.length === 0) return expandedIds;

    const catalogElements = restBudgetElements ?? [];
    return searchCostGroupElements(catalogElements, searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, restBudgetElements]);

  useEffect(() => {
    if (searchValue.length > 0) setExpandedIds(matchingCostElements);
  }, [matchingCostElements, searchValue]);

  const handleSelectionChange = (elementId: string, restBudget: number, selected: boolean) => {
    if (selected) {
      handleSelected(elementId, restBudget);
    } else {
      handleDeselected(elementId);
    }
  };

  const handleSelected = (elementId: string, restBudget: number) => {
    const foundAssignment = workAssignments.find((x) => x.costElementId === elementId);
    const newAsgn: Assignment = {
      id: foundAssignment ? foundAssignment.id : '00000000-0000-0000-0000-000000000000',
      costElementId: elementId,
      budget: restBudget,
      state: foundAssignment ? 'Updated' : 'Added',
    };
    setWorkAssignments((prev) => [...prev.filter((x) => x.costElementId !== elementId), newAsgn]);
  };

  const handleDeselected = (elementId: string) => {
    const justAdded = workAssignments.find((x) => x.costElementId === elementId && x.state === 'Added');
    if (justAdded) {
      setWorkAssignments((prev) => prev.filter((x) => x.costElementId !== elementId));
    } else {
      setWorkAssignments((prev) =>
        prev.map((x) => {
          if (x.costElementId === elementId) {
            return { ...x, state: 'Deleted' };
          }
          return x;
        }),
      );
    }
  };

  const renderCostElement = (costElement: CostElementRestBudget, lastIdx: boolean) => {
    const restBudget = showBudget ? costElement.values.restBudget : 0;
    const budgetingDisabled = !costElement.budgetingAllowed;

    return (
      <div className="w-full flex">
        <div className="w-4 mr-2" />
        <div className="bg-white w-full p-2 flex items-center border-t">
          <div className="w-1/6" />
          <div className="w-5/6 flex justify-between items-center">
            <div className="pl-2 flex items-center ">
              <span className="w-2 mr-2 flex justify-center items-center">
                <div className="relative w-2 flex-none">
                  {lastIdx ? (
                    <div className="w-px h-5 absolute ml-1 -mt-5 bg-slate-400 z-0" />
                  ) : (
                    <div className="w-px h-10 absolute ml-1 -mt-5 bg-slate-400 z-0" />
                  )}
                  <div className="w-[0.5625rem] h-[0.5625rem] absolute -mt-1 rounded-full bg-white shadow border-2 border-slate-400 z-[5]" />
                </div>
              </span>
              <span className="w-72 truncate text-ellipsis text-slate-600 h-full">
                <TextHighlighter
                  text={costElement.name ? costElement.name : t('projectCalculate.unnamedElement')}
                  highlighted={searchValue
                    .split(',')
                    .filter((x) => x !== '')
                    .map((x) => x.trim())}
                />
                {showVat && costElement.values.vat && (
                  <TextHighlighter
                    text={` (${costElement.values.vat}%)`}
                    highlighted={searchValue
                      .split(',')
                      .filter((x) => x !== '')
                      .map((x) => x.trim())}
                  />
                )}
                {showFx && costElement.values.hasFormula && (
                  <sup className="font-normal italic text-slate-600"> &nbsp;fx</sup>
                )}
              </span>
            </div>

            <div className="flex">
              {showBudget && !costElement.parentHasBudget && !costElement.parentHasBoundary && (
                <div
                  className={classNames(
                    'rounded-full px-2 text-sm text-white font-bold mr-2',
                    { 'bg-slate-200': budgetingDisabled },
                    { 'bg-slate-400': !budgetingDisabled },
                  )}
                >
                  {restBudget.toString().length > 6 ? formatLargeNumber(restBudget) : formatNumber(restBudget)}
                </div>
              )}
              {!costElement.parentHasBudget && !costElement.parentHasBoundary && (
                <CheckBox
                  disabled={budgetingDisabled}
                  checked={workAssignments.some((x) => x.costElementId === costElement.id && x.state !== 'Deleted')}
                  onChange={(value) => {
                    handleSelectionChange(costElement.id, costElement.values.restBudget, value);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCostGroupElement = (costGroupElement: CatalogElementRestBudget, level: number) => {
    if (onlyWithCostElementId && !costGroupElement.costElementId) return null;
    const elementId = costGroupElement.costElementId;
    const restBudget = showBudget ? costGroupElement.values.restBudget : 0;

    const isExpanded = expandedIds.includes(elementId);

    const budgetingDisabled = !costGroupElement.budgetingAllowed;

    return (
      <div>
        <div className="flex items-center divide-y">
          {costGroupElement.children.length > 0 || costGroupElement.costElements.length > 0 ? (
            <div
              className="flex items-center justify-center hover:bg-gray-100 cursor-pointer mr-2 w-4 h-4"
              onClick={() => addOrRemoveExpandedId(elementId)}
            >
              {isExpanded ? (
                <TriangleIcon className="w-3 h-3 text-gray-500 rotate-90" />
              ) : (
                <TriangleIcon className="w-3 h-3 text-gray-500" />
              )}
            </div>
          ) : (
            <div className="mr-2 w-4 h-4" />
          )}
          <div
            className={classNames('bg-white w-full p-2 flex items-center truncate text-ellipsis', {
              'font-bold': level === 0,
            })}
          >
            <span className="w-1/6 truncate">
              <TextHighlighter
                text={costGroupElement.code ?? ''}
                highlighted={searchValue
                  .split(',')
                  .filter((x) => x !== '')
                  .map((x) => x.trim())}
              />
            </span>

            <div className="pl-2 w-5/6 flex justify-between items-center">
              <span className="w-2 mr-2 flex justify-center items-center">
                <div
                  className={classNames('relative w-2 flex-none', {
                    invisible:
                      !isExpanded ||
                      costGroupElement.costElements.length === 0 ||
                      (searchValue !== '' &&
                        !costGroupElement.costElements.some((x) => matchingCostElements.includes(x.id ?? ''))),
                  })}
                >
                  <div className="w-px h-8 absolute ml-1 bg-slate-400 z-0" />
                  <div className="w-[0.5625rem] h-[0.5625rem] p-px absolute -mt-1 rounded-full bg-white shadow border-2 border-slate-400 z-[5]" />
                </div>
              </span>
              <span className="truncate w-72 text-ellipsis ">
                <TextHighlighter
                  text={costGroupElement.name ? costGroupElement.name : t('projectCalculate.unnamedElement')}
                  highlighted={searchValue
                    .split(',')
                    .filter((x) => x !== '')
                    .map((x) => x.trim())}
                />
                {showVat && costGroupElement.values.vat && (
                  <TextHighlighter
                    text={` (${costGroupElement.values.vat}%)`}
                    highlighted={searchValue
                      .split(',')
                      .filter((x) => x !== '')
                      .map((x) => x.trim())}
                  />
                )}

                {showFx && costGroupElement.values.hasFormula && (
                  <sup className="font-normal italic text-slate-600"> &nbsp;fx</sup>
                )}
              </span>
              <div className="flex-grow flex justify-end">
                {costGroupElement.createsBoundary && <div className="text-red-700 text-sm font-bold pr-2">(+/-)</div>}
                {showBudget && !costGroupElement.parentHasBudget && !costGroupElement.parentHasBoundary && (
                  <div
                    className={classNames(
                      'rounded-full px-2 text-sm text-white font-bold mr-2',
                      { 'bg-slate-200': budgetingDisabled },
                      { 'bg-slate-400': !budgetingDisabled },
                    )}
                  >
                    {restBudget.toString().length > 6 ? formatLargeNumber(restBudget) : formatNumber(restBudget)}
                  </div>
                )}
                {!costGroupElement.parentHasBudget &&
                  !costGroupElement.childrenHaveBudget &&
                  !costGroupElement.parentHasBoundary && (
                    <CheckBox
                      disabled={budgetingDisabled}
                      checked={workAssignments.some(
                        (x) => x.costElementId === costGroupElement.costElementId && x.state !== 'Deleted',
                      )}
                      onChange={(value) => {
                        handleSelectionChange(
                          costGroupElement.costElementId,
                          costGroupElement.values.restBudget,
                          value,
                        );
                      }}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
        {isExpanded && (searchValue !== '' ? matchingCostElements.includes(elementId) : true) && (
          <>
            {costGroupElement.costElements.length > 0 &&
              costGroupElement.costElements
                .filter((costElement) => (searchValue !== '' ? matchingCostElements.includes(costElement.id) : true))
                .map((costElement, i) => (
                  <div key={`costElement-${i}`}>
                    {renderCostElement(
                      costElement,
                      i + 1 ===
                        costGroupElement.costElements.filter((x) =>
                          searchValue !== '' ? matchingCostElements.includes(x.id ?? '') : true,
                        ).length,
                    )}
                  </div>
                ))}
            {costGroupElement.children.length > 0 &&
              costGroupElement.children
                .filter((costElement) =>
                  searchValue !== '' ? matchingCostElements.includes(costElement.costElementId) : true,
                )
                .map((costElement, i) => (
                  <div key={`costGroupElement-${costElement.costElementId}-${i}`}>
                    {renderCostGroupElement(costElement, level + 1)}
                  </div>
                ))}
          </>
        )}
      </div>
    );
  };

  return (
    <div
      className="flex flex-col h-full justify-between w-full"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          updateCostElements(workAssignments);
          onClose();
        }
      }}
      tabIndex={0}
    >
      <div className="w-full pl-6 mb-2">
        <UserDefinedFieldsSearch
          searchValue={searchValue}
          updateSearchResults={(result: string[]) => {
            setSearchResults(result);
          }}
          handleSearchValueUpdate={(val) => {
            setSearchValue(val);
          }}
          udfElementTypes={['Cost']}
          searchDisabled={!loadedVariantId || !loadedProjectId}
          filterStore="Cost"
        />
      </div>
      <div className="overflow-y-auto h-full">
        {restBudgetElements &&
          restBudgetElements
            .filter((x) => x.catalogElementId !== '00000000-0000-0000-0000-000000000000')
            .map((costElement, i) => {
              if (searchValue !== '' ? matchingCostElements.includes(costElement.costElementId) : true)
                return (
                  <div key={`costGroupElement-${costElement.costElementId}-${i}`}>
                    {renderCostGroupElement(costElement, 0)}
                  </div>
                );
              else return null;
            })}
      </div>

      {showControls && (
        <div className="flex justify-between items-center h-18 px-5 pt-2 border-t">
          <div></div>
          <div className="flex">
            <Button variant="secondary" className="mr-5" onClick={onClose}>
              {t('common.cancel')}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                updateCostElements(workAssignments);
                onClose();
              }}
            >
              {t('common.select')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
