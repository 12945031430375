import {PriceHubbleProjectValuation} from '@client/shared/api';
import {useTranslation} from 'react-i18next';
import {useLoadedVariant} from '@client/project/store';
import {Fragment} from 'react';
import {formatDate} from '@client/shared/utilities';
import {PriceHubbleLineChart} from '.';
import {Gauge} from '@client/shared/toolkit';
import { FormattedCurrency } from '../FormattedCurrency';

interface PriceHubbleProps {
  priceHubbledata: PriceHubbleProjectValuation;
  handleProjectValuation: () => void;
}

export const PriceHubble = ({ priceHubbledata, handleProjectValuation }: PriceHubbleProps) => {
  const { t } = useTranslation();
  const { data: variantData } = useLoadedVariant();
  const activeVariant = variantData?.calculationModel.modelMetadata;

  const scores = Object.entries(priceHubbledata?.score || {});

  const priceHubbleAvgRent = priceHubbledata?.avgSaleUnitPrice.value ?? 0;
  const projectAvgRent = activeVariant?.averageRentUnitPrice.value ?? 0;
  const isPhAvgBigger = priceHubbleAvgRent > projectAvgRent;
  const biggerAvgNumberPlus30Percent = !projectAvgRent
    ? priceHubbleAvgRent * 2
    : (isPhAvgBigger ? priceHubbleAvgRent : projectAvgRent) * 1.3;
  const priceHubbleAvgPercentage = Math.round((priceHubbleAvgRent / biggerAvgNumberPlus30Percent) * 100);
  const projectAvgPercentage = Math.round((projectAvgRent / biggerAvgNumberPlus30Percent) * 100);

  const scoreDict: { [key: string]: { label: string } } = {
    catering: {
      label: 'projectTaxonomy.dashboardMarketPricesCatering',
    },
    family: {
      label: 'projectTaxonomy.dashboardMarketPricesFamily',
    },
    health: {
      label: 'projectTaxonomy.dashboardMarketPricesHealth',
    },
    leisure: {
      label: 'projectTaxonomy.dashboardMarketPricesLeisure',
    },
    nuisance: {
      label: 'projectTaxonomy.dashboardMarketPricesNuisance',
    },
    shopping: {
      label: 'projectTaxonomy.dashboardMarketPricesShopping',
    },
    view: {
      label: 'projectTaxonomy.dashboardMarketPricesView',
    },
  };

  const getStrokeColor = (score: number) => {
    const value = Math.floor(score / 20); // scale the value in 1 - 5
    if (value < 3) return 'stroke-red-500';
    if (value < 4) return 'stroke-yellow-500';
    if (value <= 5) return 'stroke-green-500';
    return 'stroke-gray-500';
  };

  return (
    <div className="p-5 bg-slate-800 text-white text-xs">
      <div className="mb-5 flex flex-wrap gap-5">
        {scores.map(
          ([key, value]) =>
            value && (
              <Fragment key={key}>
                <Gauge
                  width="w-1/4 max-w-[56px]"
                  value={value}
                  label={t(scoreDict[key].label)}
                  strokeColor={getStrokeColor(value)}
                />
              </Fragment>
            )
        )}
      </div>

      <div className="w-full mb-5 border-b border-dotted border-white" />

      {!!priceHubbleAvgPercentage && (
        <div className="my-2 w-full flex flex-row items-center">
          <div className="flex justify-center items-center mr-4 h-5 w-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="22.998" height="21.9" viewBox="0 0 22.998 21.9">
              <path
                id="icons8-mortgage"
                d="M12,2.1,1,12H4v9h8.295A6.991,6.991,0,0,1,12,19H6V10.191l6-5.4,6,5.4V12h5ZM19,14a5,5,0,1,0,5,5A5,5,0,0,0,19,14Zm0,2a3.149,3.149,0,0,1,2.382,1.175A2.778,2.778,0,0,1,22,19a3,3,0,1,1-3-3Zm.408,1-1.9.777v1.037l.793-.307V21h1.359V17Z"
                transform="translate(-1.002 -2.1)"
                fill="white"
              />
            </svg>
          </div>
          <div className="w-full">
            <div className={`text-xxs ml-[${priceHubbleAvgPercentage}%]`}>
              <span className="font-extrabold text-xs"><FormattedCurrency amount={priceHubbleAvgRent} /></span>
              <span className="text-xs"> {t('projectTaxonomy.dashboardMarketPriceInLocation')}</span>
            </div>

            <div className="relative my-1 w-full bg-sky-700 h-[4px]">
              {priceHubbleAvgRent && (
                <div
                  className={`absolute w-0 h-0
                    border-l-[2.5px] border-l-transparent
                    border-t-[4px] border-t-white
                    border-r-[2.5px] border-r-transparent
                    ml-[${priceHubbleAvgPercentage}%]`}
                />
              )}

              {projectAvgRent && (
                <div
                  className={`absolute w-0 h-0
                    border-l-[2.5px] border-l-transparent
                    border-b-[4px] border-b-white
                    border-r-[2.5px] border-r-transparent
                    ml-[${projectAvgPercentage}%]`}
                />
              )}
            </div>
            {!!projectAvgRent && (
              <div className={`text-xxs ml-[${projectAvgPercentage}%]`}>
                <span className="font-extrabold"><FormattedCurrency amount={projectAvgRent} /></span>
                <span> {t('projectTaxonomy.dashboardMarketPriceProject')}</span>
              </div>
            )}
          </div>
        </div>
      )}

      <PriceHubbleLineChart
        data={priceHubbledata.rentTimeline.slice(-10)}
        increase={priceHubbledata.lastYearRentIncrease.value ?? 0}
        label={'projectTaxonomy.dashboardMarketPriceDevelopmentYear'}
      />

      <div className="w-full my-2 border-b border-dotted border-white" />

      <PriceHubbleLineChart
        data={priceHubbledata.sellTimeline.slice(-10)}
        increase={priceHubbledata.lastYearSaleIncrease.value ?? 0}
        label={'projectTaxonomy.dashboardSalePriceDevelopmentYear'}
      />

      <div className="mt-2 w-full flex justify-end text-xxs text-slate-400">
        <span className="text-right text-xs">{formatDate(priceHubbledata.date)}</span>
      </div>
    </div>
  );
};
