import { useApiPostUpdateCalculationModelMetadataMutation, CalculationModelMetadata } from '@client/shared/api';
import { useState } from 'react';
import {
  TextInput,
  Modal,
  Button,
  HintBox,
  EditNodeIcon,
  ModalOnCloseProps,
  LoadingIndicator
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

interface VariantUpdateTitleModalProps extends ModalOnCloseProps {
  variant: CalculationModelMetadata;
}

export const VariantUpdateTitleModal = ({ variant, onClose }: VariantUpdateTitleModalProps) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState(variant.name);
  const [call, { isLoading }] = useApiPostUpdateCalculationModelMetadataMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleOnUpdate = async () => {
    try {
      await safeMutation(
        call,
        {
          projectId: variant.projectId,
          calculationModelId: variant.id,
          body: {
            title: title,
            startDate: variant?.calculationModelStartDate ?? '',
            endDate: variant?.calculationModelEndDate ?? ''
          },
        },
        isLoading
      );

      onClose(true);
    } catch {
      /* left blank */
    }
  };

  return (
    <>
      {isLoading && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
      <Modal.Header title={t('projectVariants.variantUpdateTitle')} />
      <Modal.Content className="space-y-6">
        <TextInput
          label={t('projectVariants.variantUpdateLabelName')}
          value={title}
          onChange={(value) => setTitle(value)}
          icon={<EditNodeIcon />}
        />
        <HintBox className="mt-4">{t('projectVariants.variantUpdateHint')}</HintBox>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={handleOnClose} className="mr-2" variant="secondary">
          {t('common.cancel')}
        </Button>
        <Button onClick={handleOnUpdate} disabled={title === ''}>
          {t('projectVariants.variantUpdateAction')}
        </Button>
      </Modal.Controls>
    </>
  );
};
