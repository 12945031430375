import React from 'react';

/**
 * Recursively apply a className to an array of React elements.
 * @param children An array of React elements.
 * @param className The className to apply.
 * @param level The level to apply the className. Defaults to 0.
 * @returns The array of React elements with the className applied.
 */
export const recursivelyApplyClassName = (
  children: React.ReactElement[] | React.ReactElement,
  className: string,
  level = 0
): React.ReactElement[] =>
  React.Children.map(children, (child) => {
    // If the child is not a React element, return it as-is
    if (!React.isValidElement(child)) {
      return child;
    }

    // If the child has children and is the root element, recursively apply the className
    if ((child as React.ReactElement).props.children && level === 0) {
      const props = { children: recursivelyApplyClassName((child as React.ReactElement).props.children, className, 1) };
      child = React.cloneElement(child, props);
    }

    // Clone the child element and apply the className
    return React.cloneElement(child, {
      className: child.props.className ? `${child.props.className} ${className}` : className,
    });
  });
