import cn from 'classnames';

interface ToggleSwitchProps {
  id?: string;
  checked?: boolean;
  className?: string;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  offLabel?: string
  onLabel?: string
  name?: string
  variant?: 'light' | 'medium'
}

export const ToggleSwitch = ({
  id,
  checked = true,
  className,
  disabled,
  onChange,
  offLabel,
  onLabel,
  name,
  variant = 'light'
}: ToggleSwitchProps) => {
  return (
    <div className={className ? className : 'inline-flex'}>
      <label
        className={cn(
          'h-full overflow-visible p-1 w-auto rounded-full relative',
          {
            'cursor-pointer': !disabled,
            'bg-white': variant === 'light',
            'bg-gray-100': variant === 'medium'
          }
        )}
      >
        <input
          id={id}
          name={name}
          type="checkbox"
          checked={checked}
          className="hidden"
          onChange={disabled ? undefined : () => onChange(!checked)}
        />
        <span className={cn(
          'absolute top-0 left-0.5 z-0 inline-block px-7',
          'w-1/2 h-auto bg-gray-400 transition-all rounded-full',
          'grid grid-cols-2 items-center justify-center',
          'top-0.5 bottom-0.5'
        )} style={{
          transform: checked ? 'translate(calc(100% - 4px), 0)' : 'translate(0, 0)'
        }} />
        <div className="grid grid-cols-2 items-center z-10 relative py-1 text-center text-[15px] font-bold">
          <span
            className={cn('px-7 transition-colors duration-300', {
            'text-white': !checked
            })}
          >
            {offLabel}
          </span>
          <span
            className={cn('px-7 transition-colors duration-300', {
              'text-white': checked
            })}
          >
            {onLabel}
          </span>
        </div>
      </label>
    </div>
  );
};
