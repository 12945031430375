import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiDeleteDefaultValuesApiArg,
  ApiDeleteDefaultValuesApiResponse,
  ApiGetDefaultValuesApiArg,
  ApiGetDefaultValuesApiResponse,
  ApiPostCreateDefaultValuesApiArg,
  ApiPostCreateDefaultValuesApiResponse,
  ApiPostUpdateDefaultValuesApiArg,
  ApiPostUpdateDefaultValuesApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiDefaultValuesEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetDefaultValues: {
    providesTags: (
      _result: ApiGetDefaultValuesApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiGetDefaultValuesApiArg,
    ) => [{ type: ApiTagTypes.DefaultValues, id: 'LIST' }],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateDefaultValues: {
    invalidatesTags: (
      _result: ApiPostCreateDefaultValuesApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostCreateDefaultValuesApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.DefaultValues, id: 'LIST' }] : []),
  },
  apiPostUpdateDefaultValues: {
    invalidatesTags: (
      _result: ApiPostUpdateDefaultValuesApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostUpdateDefaultValuesApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.DefaultValues, id: 'LIST' }] : []),
  },
  apiDeleteDefaultValues: {
    invalidatesTags: (
      _result: ApiDeleteDefaultValuesApiResponse,
      error: FetchBaseQueryError,
      _args: ApiDeleteDefaultValuesApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.DefaultValues, id: 'LIST' }] : []),
  },
};
