import React, { PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';
import { Button } from './Button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
export interface BgImageCardProps extends PropsWithChildren {
  className?: string
  thumbnail?: string
  thumbnailAlt?: string
  passDataCy?: string
  title?: string | ReactNode
  subTitle?: string | ReactNode
  preTitle?: string
  onClick?: () => void
  linkText?: string
  textTheme?: 'light' | 'dark' | 'custom'
  size?: 'default' | 'custom'
  icon?: ReactNode
  gradientOverlay?: boolean
}
export const BgImageCard = (props: BgImageCardProps) => {
  const {
    className,
    title,
    subTitle,
    preTitle,
    thumbnail,
    thumbnailAlt,
    size = 'default',
    textTheme = 'light',
    icon,
    gradientOverlay = true,
    linkText,
    onClick
  } = props

  const sizeClass = size === 'default' ? 'w-[241px] h-[342px]' : ''
  const textClass = textTheme === 'light' ? 'text-white' : ''

  return (
    <div
      className={cn(
        'relative bg-slate-200 rounded overflow-hidden drop-shadow',
        sizeClass,
        textClass,
        {
          'cursor-pointer': onClick
        },
        className
      )}
      onClick={onClick
    }>
      {thumbnail && (
        <div className="absolute top-0 left-0 z-0 w-full h-full">
          <LazyLoadImage className="h-full w-full object-cover" src={thumbnail} alt={thumbnailAlt} />
        </div>
      )}
      {icon && (
        <div className="absolute top-2.5 right-2.5 z-20">
          {icon}
        </div>
      )}
      {gradientOverlay && (
        <div className="absolute z-1 bg-gradient-to-b from-transparent to-black h-4/5 w-full opacity-40 bottom-0 left-0 pointer-events-none" />
      )}
      <div className="absolute z-10 top-0 left-0 h-full w-full flex flex-col justify-end items-center p-3 gap-[25px]">
        {(title || subTitle) && (
          <div className="text-center">
            {preTitle && <span className="text-[13px] leading-[22px] font-medium truncate">{preTitle}</span>}
            {title && <h4 className="text-[32px] font-bold max-h-[150px] overflow-hidden truncate whitespace-normal">{title}</h4>}
            {subTitle && <span className="text-[13px] leading-[22px] font-bold truncate">{subTitle}</span>}
          </div>
        )}
        {linkText && onClick && (
          <Button variant="primary" onClick={onClick}>{linkText}</Button>
        )}
      </div>
    </div>
  )
};
