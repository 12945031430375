import { cloneElement, ReactElement, ReactNode } from 'react';
import { EmailFilledIcon, PhoneIcon } from '../icons';
import { useTranslation } from 'react-i18next';

export type ContactInfosProps = {
  className?: string
  email?: string
  phone?: string
}
export const ContactInfos = (props: ContactInfosProps) => {
  const {
    email,
    phone,
    className
  } = props
  const { t } = useTranslation()

  if (!email && !phone) return null
  return (
    <ul className={className}>
      {email && <li className="w-full"><ContactInfo icon={<EmailFilledIcon />} label={`${t('common.email')}:`} value={email} /></li>}
      {phone && <li className="w-full"><ContactInfo icon={<PhoneIcon />} label={`${t('common.phone')}:`} value={phone} /></li>}
    </ul>
  )
}

export type ContactInfoProps = {
  icon?: ReactNode
  value?: string
  label?: string
}
export const ContactInfo = (props: ContactInfoProps) => {
  const {
    icon,
    value,
    label
  } = props
  return (
    <div className="flex py-2 px-3 gap-2 items-center w-full">
      {icon && (cloneElement(icon as ReactElement, { className: 'flex-none w-5' }))}
      <div className="flex flex-col w-[calc(100%_-_1.25rem)]">
        {label && (<span className="text-xs leading-4 text-gray-500">{label}</span>)}
        {value && (<span className="font-bold text-lg leading-6 truncate">{value}</span>)}
      </div>
    </div>
  )
}
