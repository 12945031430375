import {
  CalculationRuleGroupReadModel,
  CalculationRuleReadModel,
  CalculationRuleType,
  InvoiceCalculationSchemeReadModel,
  InvoiceCalculationSchemeType,
  InvoiceType,
  MoveDirection,
  useApiPostMoveProjectCalculationRuleGroupMutation,
  useApiPostMoveProjectCalculationRuleMutation,
  useApiPostMoveTenantCalculationRuleGroupMutation,
  useApiPostMoveTenantCalculationRuleMutation,
  useApiPostUpdateProjectInvoiceCalculationSchemeMutation,
  useApiPostUpdateTenantInvoiceCalculationSchemeMutation,
  useApiGetInvoiceCalculationSampleQuery,
  useApiGetTenantReportsQuery,
  useApiGetSystemReportsQuery
} from '@client/shared/api';
import {
  IcsNewFormValidationSchema,
  IcsNewFormValidationValues,
  IcsType,
  InvoiceCoverSheetDeleteModal,
  IcsDeductionGroupSection,
  InvoiceCoverSheetExampleInvoice
} from '.';
import {
  BaseSelect, BaseSelectOption,
  BooleanInput,
  Button,
  Form,
  FormField,
  FormRefHandle, FormWatch, LoadingIndicator,
  RenameIcon,
  SlideOver,
  SlideOverList,
  TagWindowIcon,
  TextInput,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useRef, useState } from 'react';
import { safeMutation } from '@client/shared/utilities';
import { IcsGroupCreateSlideOver } from './IcsGroupCreateSlideOver';
import { IcsDeductionCreateSlideOver } from './IcsDeductionCreateSlideOver';
import { IcsGroupDeleteModal } from './IcsGroupDeleteModal';
import { IcsDeductionDeleteModal } from './IcsDeductionDeleteModal';
import { getIcsVatRenderedInfo } from '../../utils';

export const excludeDeleteDeductionByType: Record<InvoiceCalculationSchemeType, CalculationRuleType[]> = {
  Cumulated: ['Vat', 'PartialPayment', 'Repayment', 'CashDiscount'],
  Single: ['Vat', 'CashDiscount'],
  AdvancePayment: ['Vat']
}

type InvoiceCoverSheetEditSlideOverProps = {
  sheet: InvoiceCalculationSchemeReadModel | null;
  isOpen: boolean;
  type: IcsType;
  onClose: () => void;
  onAfterLeave: () => void;
  projectId?: string;
  setIsChildSlideOverOpen: (open: boolean) => void;
};
export const InvoiceCoverSheetEditSlideOver = (props: InvoiceCoverSheetEditSlideOverProps) => {
  const { sheet, isOpen, onClose, onAfterLeave, type, projectId, setIsChildSlideOverOpen } = props;
  const { t } = useTranslation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openSlideOver, setOpenSlideOver] = useState<'createGroup' | 'deleteGroup' | 'editGroup' | 'addDeduction' | 'deleteDeduction' | null>(null);

  const [selectedGroup, setSelectedGroup] = useState<CalculationRuleGroupReadModel | null>(null)
  const [selectedCalculationRule, setSelectedCalculationRule] = useState<CalculationRuleReadModel | null>(null)

  const [selectedExampleInvoiceType, setSelectedExampleInvoiceType] = useState<InvoiceType>('Partial');

  const formRef = useRef<FormRefHandle<IcsNewFormValidationValues>>();

  const [updateTenantIcs, { isLoading: isUpdatingTenantIcs }] =
    useApiPostUpdateTenantInvoiceCalculationSchemeMutation();
  const [updateProjectIcs, { isLoading: isUpdatingProjectIcs }] =
    useApiPostUpdateProjectInvoiceCalculationSchemeMutation();

  const [moveTenantIcsGroup, { isLoading: isMovingTenantIcsGroup}] =
    useApiPostMoveTenantCalculationRuleGroupMutation();
  const [moveProjectIcsGroup, { isLoading: isMovingProjectIcsGroup}] =
    useApiPostMoveProjectCalculationRuleGroupMutation();

  const [moveTenantDeduction, { isLoading: isMovingTenantDeduction}] =
    useApiPostMoveTenantCalculationRuleMutation();
  const [moveProjectDeduction, { isLoading: isMovingProjectDeduction}] =
    useApiPostMoveProjectCalculationRuleMutation();

  const { data: exampleInvoiceData, isFetching: isLoadingExampleInvoiceData } = useApiGetInvoiceCalculationSampleQuery(
    { invoiceCalculationSchemeId: sheet?.invoiceCalculationSchemeId ?? '' },
    { skip: !sheet?.invoiceCalculationSchemeId },
  );

  const { data: tenantReportsData, isFetching: isFetchingTenantReports } = useApiGetTenantReportsQuery({
    type: 'InvoiceCoverSheet'
  });
  const { data: systemReportsData, isFetching: isFetchingSystemReports } = useApiGetSystemReportsQuery({
    type: 'InvoiceCoverSheet'
  });

  const handleSubmit = async (data: IcsNewFormValidationValues) => {
    if (sheet) {
      if (type === 'tenant') {
        try {
          await safeMutation(
            updateTenantIcs,
            {
              invoiceCalculationSchemeId: sheet.invoiceCalculationSchemeId,
              body: {
                isDefault: data.isDefault,
                name: data.name,
                description: data.description,
                type: data.type,
                invoiceCoverSheetReportId: data.icsReport
              },
            },
            isUpdatingTenantIcs,
          );
          onClose();
        } catch (e) {
          console.log(e);
        }
      } else if (projectId) {
        try {
          await safeMutation(
            updateProjectIcs,
            {
              invoiceCalculationSchemeId: sheet.invoiceCalculationSchemeId,
              projectId: projectId,
              body: {
                isDefault: data.isDefault,
                name: data.name,
                description: data.description,
                type: data.type,
                invoiceCoverSheetReportId: data.icsReport
              },
            },
            isUpdatingProjectIcs,
          );
          onClose();
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const handleGroupMove = async (group: CalculationRuleGroupReadModel, direction: MoveDirection) => {
    if (sheet) {
      if (type === 'tenant') {
        try {
          await safeMutation(
            moveTenantIcsGroup,
            {
              invoiceCalculationSchemeId: sheet?.invoiceCalculationSchemeId,
              calculationRuleGroupId: group.calculationRuleGroupId,
              body: {
                direction: direction
              },
            },
            isMovingTenantIcsGroup,
          );
        } catch (e) {
          console.log(e);
        }
      } else if (projectId) {
        try {
          await safeMutation(
            moveProjectIcsGroup,
            {
              projectId: projectId,
              invoiceCalculationSchemeId: sheet?.invoiceCalculationSchemeId,
              calculationRuleGroupId: group.calculationRuleGroupId,
              body: {
                direction: direction
              },
            },
            isMovingProjectIcsGroup,
          );
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  const handleDeductionMove = async (item: CalculationRuleReadModel, group: CalculationRuleGroupReadModel, direction: MoveDirection) => {
    if (sheet) {
      if (type === 'tenant') {
        try {
          await safeMutation(
            moveTenantDeduction,
            {
              invoiceCalculationSchemeId: sheet.invoiceCalculationSchemeId,
              calculationRuleGroupId: group.calculationRuleGroupId,
              calculationRuleId: item.calculationRuleId,
              body: {
                direction: direction
              },
            },
            isMovingTenantDeduction,
          );
        } catch (e) {
          console.log(e);
        }
      } else if (projectId) {
        try {
          await safeMutation(
            moveProjectDeduction,
            {
              projectId: projectId,
              invoiceCalculationSchemeId: sheet.invoiceCalculationSchemeId,
              calculationRuleGroupId: group.calculationRuleGroupId,
              calculationRuleId: item.calculationRuleId,
              body: {
                direction: direction
              },
            },
            isMovingProjectDeduction,
          );
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  const deductionGroups = useMemo(() => {
    return sheet?.calculationRuleGroups;
  }, [sheet?.calculationRuleGroups]);

  const selectedExampleInvoice = useMemo(() => {
    if (sheet?.type === 'Cumulated') {
      return exampleInvoiceData?.calculationSample.invoices.find((invoice) => invoice.type === selectedExampleInvoiceType);
    }
    return exampleInvoiceData?.calculationSample.invoices.length ? exampleInvoiceData?.calculationSample.invoices[0] : undefined;
  }, [sheet?.type, exampleInvoiceData?.calculationSample.invoices, selectedExampleInvoiceType])

  const vatRenderedInfo = useMemo(() => {
    return getIcsVatRenderedInfo(deductionGroups);
  }, [deductionGroups])

  const icsReportOptions = useMemo(() => {
    const options: BaseSelectOption[] = [];
    if (systemReportsData?.length) {
      options.push({
        label: t('ics.systemReports'),
        value: 'system-reports',
        disabled: true,
        options: systemReportsData.map((r) => {
          return {
            label: r.metaData.name,
            value: r.metaData.reportId
          }
        })
      });
    }
    if (tenantReportsData?.length) {
      options.push({
        label: t('ics.tenantReports'),
        value: 'tenant-reports',
        disabled: true,
        options: tenantReportsData.map((r) => {
          return {
            label: r.metaData.name,
            value: r.metaData.reportId
          }
        })
      });
    }
    return options;
  }, [tenantReportsData, systemReportsData, t])

  if (!sheet) {
    return null
  }

  const defaultFormValues = {
    isDefault: sheet?.isDefault ?? false,
    name: sheet?.name ?? '',
    description: sheet?.description ?? '',
    type: sheet?.type ?? ('Cumulated' as InvoiceCalculationSchemeType),
    icsReport: sheet?.invoiceCoverSheetReport?.metaData?.reportId ?? null
  }

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} onAfterLeave={onAfterLeave} variant="large">
      {(isUpdatingTenantIcs ||
        isUpdatingProjectIcs ||
        isMovingTenantIcsGroup ||
        isMovingProjectIcsGroup ||
        isMovingTenantDeduction ||
        isMovingProjectDeduction ||
        isLoadingExampleInvoiceData) && (
        <LoadingIndicator text={t('app.settingsSavingInvoiceCoverSheet')} mode="overlay" />
      )}
      <Form<IcsNewFormValidationValues>
        validationSchema={IcsNewFormValidationSchema}
        defaultValues={defaultFormValues}
        className="flex flex-col w-full h-full"
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <FormWatch fieldNames={['isDefault', 'name', 'description', 'icsReport']}>
          {({ isDefault, name, description, icsReport }) => (
            <>
              <SlideOver.Header
                title={sheet?.name ?? ''}
                subTitle={t('ics.editInvoiceCoverSheet')}
                backgroundClassName="bg-slate-900"
                onClose={onClose}
              />
              <SlideOver.Content className="p-8">
                <div className="flex flex-col gap-0.5">
                  <FormField name="isDefault">
                    {(control) => <BooleanInput label={t('app.settingsInvoiceCoverSheetDefaultFlag')} {...control} />}
                  </FormField>
                  <FormField name="name">
                    {(control) => (
                      <TextInput label={t('app.settingsInvoiceCoverSheetName')} icon={<RenameIcon />} {...control} />
                    )}
                  </FormField>
                  <FormField name="description">
                    {(control) => (
                      <TextInput
                        label={t('app.settingsInvoiceCoverSheetDescription')}
                        icon={<TagWindowIcon />}
                        inputType="textarea"
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="icsReport">
                    {(control) => (
                      <BaseSelect
                        nullable
                        label={t('ics.selectReport')}
                        icon={<TagWindowIcon />}
                        options={icsReportOptions}
                        disabled={isFetchingTenantReports || isFetchingSystemReports}
                        {...control}
                      >
                        {(isFetchingTenantReports || isFetchingSystemReports) && <LoadingIndicator />}
                      </BaseSelect>
                    )}
                  </FormField>
                </div>

                <div>
                  {sheet && exampleInvoiceData && (
                    <InvoiceCoverSheetExampleInvoice
                      sheet={sheet}
                      selectedType={selectedExampleInvoiceType}
                      setSelectedType={setSelectedExampleInvoiceType}
                      exampleInvoiceData={exampleInvoiceData}
                      selectedExampleInvoice={selectedExampleInvoice}
                    />
                  )}

                  {deductionGroups &&
                    deductionGroups.length > 0 &&
                    deductionGroups.map((group, groupIndex) => (
                      <IcsDeductionGroupSection
                        key={`deduction-group-${group.calculationRuleGroupId}-${group.name}`}
                        deductionGroup={group}
                        onAdd={() => {
                          setIsChildSlideOverOpen(true);
                          setOpenSlideOver('createGroup');
                        }}
                        onDelete={() => {
                          setSelectedGroup(group);
                          setIsChildSlideOverOpen(true);
                          setOpenSlideOver('deleteGroup');
                        }}
                        onEdit={() => {
                          setSelectedGroup(group);
                          setIsChildSlideOverOpen(true);
                          setOpenSlideOver('editGroup');
                        }}
                        onAddItem={() => {
                          setSelectedCalculationRule(null);
                          setSelectedGroup(group);
                          setIsChildSlideOverOpen(true);
                          setOpenSlideOver('addDeduction');
                        }}
                        onEditItem={(item) => {
                          setSelectedGroup(group);
                          setSelectedCalculationRule(item);
                          setIsChildSlideOverOpen(true);
                          setOpenSlideOver('addDeduction');
                        }}
                        onDeleteItem={(item) => {
                          setSelectedGroup(group);
                          setSelectedCalculationRule(item);
                          setIsChildSlideOverOpen(true);
                          setOpenSlideOver('deleteDeduction');
                        }}
                        onChangeOrderNumber={(group, direction) => {
                          handleGroupMove(group, direction);
                        }}
                        onChangeOrderNumberItem={(item, direction) => {
                          handleDeductionMove(item, group, direction);
                        }}
                        sheet={sheet}
                        selectedExampleInvoiceType={selectedExampleInvoiceType}
                        selectedExampleInvoice={selectedExampleInvoice}
                        exampleInvoiceData={exampleInvoiceData}
                        vatRendered={groupIndex > vatRenderedInfo.groupIndex}
                        vatRenderedRuleIndex={vatRenderedInfo.ruleIndex}
                        isVatGroup={groupIndex === vatRenderedInfo.groupIndex}
                      />
                    ))}

                  <SlideOverList
                    className="mt-8"
                    clickable
                    items={[]}
                    noItemsMessage={
                      <div className="border-b border-b-primary pl-4 pb-2 relative after:content-[''] after:w-full after:h-px after:bg-primary after:absolute after:-bottom-1 after:left-0 text-xs text-gray-500">
                        {t('ics.createNewIcsGroup')}
                      </div>
                    }
                    showCustomNoItems
                    onAdd={() => {
                      setSelectedGroup(null);
                      setIsChildSlideOverOpen(true);
                      setOpenSlideOver('createGroup');
                    }}
                  />
                </div>
              </SlideOver.Content>
              <SlideOver.Controls className="flex justify-between">
                <Button
                  variant="danger"
                  onClick={() => {
                    setIsChildSlideOverOpen(true);
                    setIsDeleteModalOpen(true);
                  }}
                >
                  {t('common.delete')}
                </Button>
                <div>
                  <Button variant="secondary" className="mr-2" onClick={onClose}>
                    {t('common.cancel')}
                  </Button>
                  <Button
                    variant="primary"
                    formSubmit
                    disabled={
                      !(
                        isDefault !== defaultFormValues.isDefault ||
                        name !== defaultFormValues.name ||
                        description !== defaultFormValues.description ||
                        icsReport !== defaultFormValues.icsReport
                      )
                    }
                  >
                    {t('common.save')}
                  </Button>
                </div>
              </SlideOver.Controls>
            </>
          )}
        </FormWatch>
      </Form>
      {/* DELETE MODAL */}
      <InvoiceCoverSheetDeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsChildSlideOverOpen(false);
          setIsDeleteModalOpen(false);
        }}
        sheet={sheet}
        type={type}
        onAfterLeave={() => {
          onClose();
        }}
        projectId={projectId}
      />

      {/* DELETE GROUP MODAL */}
      <IcsGroupDeleteModal
        isOpen={openSlideOver === 'deleteGroup'}
        onClose={() => {
          setOpenSlideOver(null);
          setIsChildSlideOverOpen(false);
        }}
        group={selectedGroup}
        type={type}
        invoiceCalculationSchemeId={sheet?.invoiceCalculationSchemeId ?? ''}
        projectId={projectId}
      />

      {/* CREATE GROUP SLIDE OVER  */}
      <IcsGroupCreateSlideOver
        isOpen={openSlideOver === 'createGroup' || openSlideOver === 'editGroup'}
        onClose={() => {
          setOpenSlideOver(null);
        }}
        onAfterLeave={() => {
          setSelectedGroup(null);
          setIsChildSlideOverOpen(false);
        }}
        type={type}
        sheet={sheet}
        group={selectedGroup}
        projectId={projectId}
      />

      {/* CREATE / EDIT DEDUCTION SLIDE OVER  */}
      <IcsDeductionCreateSlideOver
        isOpen={openSlideOver === 'addDeduction'}
        onClose={() => {
          setOpenSlideOver(null);
        }}
        onAfterLeave={() => {
          setSelectedCalculationRule(null);
          setSelectedGroup(null);
          setIsChildSlideOverOpen(false);
        }}
        type={type}
        sheet={sheet}
        group={selectedGroup}
        calculationRule={selectedCalculationRule}
        projectId={projectId}
      />

      {/* DELETE DEDUCTION MODAL */}
      <IcsDeductionDeleteModal
        isOpen={openSlideOver === 'deleteDeduction'}
        onClose={() => {
          setOpenSlideOver(null);
          setIsChildSlideOverOpen(false);
        }}
        group={selectedGroup}
        type={type}
        invoiceCalculationSchemeId={sheet?.invoiceCalculationSchemeId ?? ''}
        calculationRule={selectedCalculationRule}
        projectId={projectId}
      />
    </SlideOver>
  );
};
