import React, { ReactNode, useMemo, useState } from 'react';
import {CheckBox, ContextMenuItem} from '.';
import { ProjectCard, ProjectCardProps } from './ProjectCard';
import { SkyscrapersDottedIcon } from '..';
export interface DashboardCardProps extends ProjectCardProps {
  showStack?: boolean
  showCheckbox?: boolean
  isSelected?: boolean
  toggleSelect?: () => void
  contextItems?: ContextMenuItem[]
  isToggleable?: boolean
  icons?: ReactNode[]
}
export const DashboardCard = (props: DashboardCardProps) => {
  const {
    showCheckbox = false,
    isSelected = false,
    toggleSelect,
    isToggleable = true,
    icons,
    ...projectCardProps
  } = props
  const [isHovered, setIsHovered] = useState(false);

  const overlayInfo =  useMemo(() => {
    if (isToggleable && (showCheckbox || isHovered || isSelected) && toggleSelect) {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <CheckBox checked={isSelected} onChange={toggleSelect} className="cursor-pointer" />
        </div>
      )
    }
  }, [isToggleable, showCheckbox, isHovered, isSelected, toggleSelect])

  return (
    <ProjectCard
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      overlayImages={icons ? icons : [<SkyscrapersDottedIcon className="h-7"/>]}
      overlayInfo={overlayInfo}
      {...projectCardProps}
    />
  )
};
