import { DecoratedCard, SelectedCustomIcon } from '@client/shared/toolkit';
import { TaxonomyReadModel, PlotReadModel } from '@client/shared/api';
import { formatUnit } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

function getTaxonomyUsageTypePercentages(data: TaxonomyReadModel) {
  const totalSize = data.sizes?.bgf?.value || 0;
  const usageTypes: { [key: string]: number } = {};
  const percentages: { [key: string]: number } = {};

  data.children.forEach((child) => {
    const usageType = child.itemUsageType;
    const size = child.sizes?.bgf?.value || 0;

    if (usageType && usageType !== 'Undefined' && size > 0) {
      usageTypes[usageType] = (usageTypes[usageType] ?? 0) + size;
    }
  });

  for (const usageType in usageTypes) {
    percentages[usageType] = (usageTypes[usageType] / totalSize) * 100;
  }
  return percentages;
}

function getPlotUsageTypePercentages(data: PlotReadModel) {
  const totalSize = data.totalPlotSize || 0;
  const usageTypes: { [key: string]: number } = {};
  const percentages: { [key: string]: number } = {};

  data.plots.forEach((child) => {
    const usageType = child.usageType;
    const size = child.totalPlotSize || 0;

    if (usageType && usageType !== 'Undefined' && !!size.value) {
      usageTypes[usageType] = (usageTypes[usageType] ?? 0) + (size.value ?? 0);
    }
  });

  for (const usageType in usageTypes) {
    percentages[usageType] = (usageTypes[usageType] / (totalSize.value ?? 0)) * 100;
  }
  return percentages;
}

interface UsageTypeCardProps {
  item?: TaxonomyReadModel | undefined;
  plot?: PlotReadModel | undefined;
  setIsEditPlotAreaSlideOverOpen?: (value: boolean) => void;
  setSelectedField?: (field: string) => void;
  setIsBuildingItemSlideOverOpen?: (value: boolean) => void;
}

export const UsageTypeCard = ({
  item,
  plot,
  setSelectedField,
  setIsBuildingItemSlideOverOpen,
  setIsEditPlotAreaSlideOverOpen,
}: UsageTypeCardProps) => {
  const { t } = useTranslation();

  let taxonomyUsageTypes: { [key: string]: number } = {};
  if (item) {
    taxonomyUsageTypes = getTaxonomyUsageTypePercentages(item);
  }

  let plotUsageTypes: { [key: string]: number } = {};
  if (plot) {
    plotUsageTypes = getPlotUsageTypePercentages(plot);
  }

  return (
    <DecoratedCard>
      <DecoratedCard.Header
        showActionButton={false}
        // icon={<PencilIcon />}
        // onActionClick={() => {
        //   if (setIsBuildingItemSlideOverOpen && setSelectedField) {
        //     setSelectedField('usage');
        //     setIsBuildingItemSlideOverOpen(true);
        //   } else if (setIsEditPlotAreaSlideOverOpen) {
        //     setIsEditPlotAreaSlideOverOpen(true);
        //   }
        // }}
      >
        <div className="truncate">{t(`projectTaxonomy.building.usage`)}</div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="p-5 w-full h-full grid grid-cols-1 bg-white gap-3">
        {item && (
          <>
            {Object.keys(taxonomyUsageTypes).map((usageType) => (
              <div key={usageType} className="grid grid-cols-8 items-center">
                <SelectedCustomIcon className="w-3 h-3" />
                <div className="col-span-7">
                  <div className="text-base font-bold text-gray-900">
                    {formatUnit(taxonomyUsageTypes[usageType], '% ')}
                    {t(`projectTaxonomy.usageType.${usageType}`)}
                  </div>
                  <div className="text-xs text-gray-500">{t('projectTaxonomy.building.usageType')}</div>
                </div>
              </div>
            ))}
            {Object.keys(taxonomyUsageTypes).length === 0 && (
              <div className="flex flex-col items-center">
                <div className="text-base font-bold text-gray-900">
                  {t(`projectTaxonomy.usageType.${item?.itemUsageType}`)}
                </div>
                <div className="text-xs text-gray-500">{t('projectTaxonomy.building.usageType')}</div>
              </div>
            )}
          </>
        )}
        {plot && (
          <>
            {Object.keys(plotUsageTypes).map((usageType) => (
              <div key={usageType} className="grid grid-cols-8 items-center">
                <SelectedCustomIcon className="w-3 h-3" />
                <div className="col-span-7">
                  <div className="text-base font-bold text-gray-900">
                    {formatUnit(plotUsageTypes[usageType], '% ')}
                    {t(`projectTaxonomy.usageType.${usageType}`)}
                  </div>
                  <div className="text-xs text-gray-500">{t('projectTaxonomy.building.usageType')}</div>
                </div>
              </div>
            ))}
            {Object.keys(plotUsageTypes).length === 0 && plot.usageType !== 'Undefined' && (
              <div className="flex flex-col items-center">
                <div className="text-base font-bold text-gray-900">
                  {t(`projectTaxonomy.usageType.${plot?.usageType}`)}
                </div>
                <div className="text-xs text-gray-500">{t('projectTaxonomy.building.usageType')}</div>
              </div>
            )}
          </>
        )}
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};
