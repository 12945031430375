import {
  ApiDeletePlotApiArg,
  ApiDeletePlotApiResponse,
  ApiDeletePlotItemFileApiArg,
  ApiDeletePlotItemFileApiResponse,
  ApiGetPlotApiArg,
  ApiGetPlotApiResponse,
  ApiGetPlotsByCalculationModelApiArg,
  ApiGetPlotsByCalculationModelApiResponse,
  ApiPostCreatePlotApiArg,
  ApiPostCreatePlotApiResponse,
  ApiPostUpdatePlotApiArg,
  ApiPostUpdatePlotApiResponse,
  ApiPostUploadPlotFileApiArg,
  ApiPostUploadPlotFileApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiPlotEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetPlot: {
    providesTags: (_result: ApiGetPlotApiResponse, _error: FetchBaseQueryError, args: ApiGetPlotApiArg) => [
      { type: ApiTagTypes.Plot, id: args.plotId },
      { type: ApiTagTypes.Plots, id: args.calculationModelId },
    ],
  },
  apiGetPlotsByCalculationModel: {
    providesTags: (
      result: ApiGetPlotsByCalculationModelApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetPlotsByCalculationModelApiArg,
    ) => [
      { type: ApiTagTypes.Plot, id: result?.plotId },
      ...(result?.plots?.map((dto): { type: ApiTagTypes.Plot; id: string } => ({
        type: ApiTagTypes.Plot,
        id: dto.plotId,
      })) ?? []),
      { type: ApiTagTypes.Plots, id: args.calculationModelId },
    ],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreatePlot: {
    invalidatesTags: (
      _result: ApiPostCreatePlotApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreatePlotApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Plot, id: args.body.parentId ?? '' },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.Plots, id: args.calculationModelId }
        ]
        : [],
  },
  apiPostUpdatePlot: {
    invalidatesTags: (
      _result: ApiPostUpdatePlotApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdatePlotApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Plot, id: args.plotId },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.Plots, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.RisksCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.EarningsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            // TODO only invalidate the elements that have a reference in formula to the plot
            { type: ApiTagTypes.FormulaElements, id: args.calculationModelId },
          ]
        : [],
  },
  apiDeletePlot: {
    invalidatesTags: (_result: ApiDeletePlotApiResponse, error: FetchBaseQueryError, args: ApiDeletePlotApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.Plot, id: args.plotId },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.Plots, id: args.calculationModelId },
            // TODO only invalidate the elements that have a reference in formula to the plot
            { type: ApiTagTypes.FormulaElements, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostUploadPlotFile: {
    invalidatesTags: (
      _result: ApiPostUploadPlotFileApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUploadPlotFileApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Plot, id: args.plotItemId },
            {
              type: ApiTagTypes.Taxonomy,
              id: args.projectId,
            },
            { type: ApiTagTypes.Plots, id: args.calculationModelId },
          ]
        : [],
  },
  apiDeletePlotItemFile: {
    invalidatesTags: (
      _result: ApiDeletePlotItemFileApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeletePlotItemFileApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Plot, id: args.plotItemId },
            {
              type: ApiTagTypes.Taxonomy,
              id: args.projectId,
            },
            { type: ApiTagTypes.Plots, id: args.calculationModelId },
          ]
        : [],
  },
};
