import cn from 'classnames';
import { CheckmarkFilledIcon } from '../icons';

export interface SlideOverStepsProps {
  steps: string[]
  currentStep: number
  handleOnStepChange: (step: number) => void
  bgColor?: string
}

export const SlideOverSteps = ({ steps, currentStep, handleOnStepChange, bgColor }: SlideOverStepsProps) => {

  return (
    <div className={cn('w-full pt-2 px-8', bgColor)}>
      <div className="inline-flex text-white border-x border-x-white">
        {steps.map((step, i) => (
          <button
            key={`slide-over-step-${step}-${i}`}
            type="button"
            className={cn('border-b-4 pb-2 px-4 flex flex-col justify-center items-center gap-0.5 relative min-w-[88px] whitespace-nowrap outline-none', {
              'border-transparent': currentStep !== i,
              'border-white': currentStep === i
            })}
            onClick={() => handleOnStepChange(i)}
          >
            {step}
            {currentStep === i ? <CheckmarkFilledIcon className="w-5 h-5" /> : <div className="w-4 h-4 rounded-full border-2 border-white" />}
            {i !== steps.length - 1 && (
              <div className="absolute w-[50%] top-[65%] left-1/2 h-[2px] bg-white translate-x-[25px]"></div>
            )}
          </button>
        ))}
      </div>
    </div>
  );
};
