import { AdjustmentType } from '@client/shared/api';
import { i18n } from '@client/shared/utilities';

export const getAdjustmentTypeValueLabel = (adjustmentType: AdjustmentType) => {
  switch (adjustmentType) {
    case 'ConsumerPriceIndex':
      return i18n.t('projectRent.adjustmentType.ConsumerPriceIndex');
    case 'Fix':
      return i18n.t('projectRent.adjustmentType.Fix');
    case 'None':
      return i18n.t('projectRent.adjustmentType.None');
    default:
      return i18n.t('projectRent.adjustmentType.Fix');
  }
};
