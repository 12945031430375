import { FinancingElementShortReadModel, FinancingGroupReadModel } from '@client/shared/api';
import { useMemo } from 'react';
import { DecoratedElement } from './useCosts';
import {filterElementsByCodes, filterElementsBySearchValue} from '../utils';

export type FinancingElement = {
  key: string;
  group?: FinancingGroupReadModel;
  financingElement?: FinancingElementShortReadModel;
  type: 'group' | 'element' | 'summary';
};

function decorateChildElement(
  financingElements: FinancingElementShortReadModel[],
  level = 0,
  parent?: DecoratedElement<FinancingElement>
): DecoratedElement<FinancingElement>[] {
  return financingElements.map((element) => {
    const decoratedElement: DecoratedElement<FinancingElement> = {
      element: {
        key: element.id,
        type: 'element',
        financingElement: element,
      },
      categoryId: element.code ?? element.id,
      level,
      parent,
      children: [],
    };
    return decoratedElement;
  });
}

function decorateElements(
  elements?: FinancingGroupReadModel[],
  level = 0,
  parent?: DecoratedElement<FinancingElement>
): DecoratedElement<FinancingElement>[] {
  if (elements === undefined || elements.length === 0) return [];

  return elements.map((element, index) => {
    const groupId = `${element.code}-${index}`;

    const decorated: DecoratedElement<FinancingElement> = {
      element: {
        key: groupId,
        group: element,
        type: 'group',
      },
      categoryId: groupId,
      children: [],
      level: level,
      disableClick: true,
      parent,
    };

    const financingElements = element.financingElements;
    decorated.children = decorateChildElement(financingElements ?? [], level + 1, decorated);

    const childrenGroups = element.groups ?? [];
    const decoratedGroups = decorateElements(childrenGroups, level + 1, decorated);
    decorated.children.push(...decoratedGroups);

    return decorated;
  });
}

export const useFinancing = (financing: FinancingGroupReadModel[], filteredCodes?: string[], searchValue?: string) => {
  return useMemo(() => {
    // decorate all elements while preserving all items and structure
    const decoratedElements = decorateElements(financing);

    // filter out elements
    let filteredElements = filterElementsByCodes<FinancingElement>(decoratedElements, filteredCodes);

    if (searchValue) {
      filteredElements = filterElementsBySearchValue(filteredElements, searchValue)
    }

    return filteredElements;
  }, [filteredCodes, financing, searchValue]);
};
