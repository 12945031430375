import {
  ShortProjectGroupReadModel,
  useApiDeleteProjectsFromGroupMutation,
  useApiPostGroupProjectsMutation,
} from '@client/shared/api';
import {
  BaseSelect,
  BaseSelectOption,
  Button,
  LoadingIndicator,
  Modal,
  ModalOnCloseProps
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { safeMutation } from '@client/shared/utilities';

interface ProjectMoveModalProps extends ModalOnCloseProps {
  selectedProjectIds: string[];
  multiProjects?: ShortProjectGroupReadModel[];
  groupId?: string | null;
}

export const ProjectMoveModal = ({ selectedProjectIds, multiProjects = [], groupId, onClose }: ProjectMoveModalProps) => {
  const { t } = useTranslation();

  const [newParent, setNewParent] = useState('none');

  const [moveProject, { isLoading: isMoving }] = useApiPostGroupProjectsMutation();
  const [deleteProject, { isLoading: isDeleting }] = useApiDeleteProjectsFromGroupMutation();

  const handleOnMove = async () => {
    if (newParent === 'none') {
      if (groupId) {
        try {
          await safeMutation(
            deleteProject,
            {
              body: {
                groupId: groupId,
                projectIds: selectedProjectIds,
              },
            },
            isDeleting,
          );
          onClose(true);
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      try {
        await safeMutation(
          moveProject,
          {
            body: {
              groupId: newParent,
              projectIds: selectedProjectIds,
            },
          },
          isMoving,
        );
        onClose(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const groupOptions: BaseSelectOption[] = useMemo(() => {
    const options: BaseSelectOption[] = [];

    options.push({
      label: t('project.moveProjectNoGroup'),
      value: 'none',
    });

    multiProjects.forEach((group) => {
      options.push({
        label: group.name,
        value: group.id,
      });
    });
    return options;
  }, [multiProjects, t]);

  if (selectedProjectIds.length === 0) return null;
  return (
    <>
      {(isMoving || isDeleting) && <LoadingIndicator text={t('project.updatingProjectLoadingIndicator')} mode="overlay" />}
      <Modal.Header title={t('project.moveProjectTitle')} description={t('project.moveProjectDescription')} />
      <Modal.Content className="text-center">
        <BaseSelect
          label={t('project.projectGroup')}
          value={newParent}
          options={groupOptions}
          onChange={setNewParent}
        />
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="text" onClick={() => onClose(false)} className="mr-2">
          {t('common.cancel')}
        </Button>
        <Button variant="primary" disabled={isMoving || isDeleting} onClick={handleOnMove}>
          {t('common.save')}
        </Button>
      </Modal.Controls>
    </>
  );
};
