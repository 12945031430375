import {
  AreasCard,
  BriCard,
  CostsCard, DescriptionCard,
  HeaderInfo, ImageUploadModal, MarketRentCard,
  PlotsCard,
  UsageTypeCard
} from './dashboard';
import {
  PlotElementSlideOver,
  PlotHeaderSlideOver,
} from './plots'
import { TaxonomyPlotDashboardProps } from '.'
import React, { useState } from 'react';
import { Button, Modal, PencilIcon, SlideOver } from '@client/shared/toolkit';
import { useLoadedProjectId } from '@client/project/store';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const TaxonomyPlotData = (props: TaxonomyPlotDashboardProps) => {
  const {
    plot,
    isReadOnly,
    canDelete,
  } = props
  const loadedProjectId = useLoadedProjectId();

  const [isEditPlotAreaSlideOverOpen, setIsEditPlotAreaSlideOverOpen] = useState(false);
  const [isEditPlotHeaderSlideOverOpen, setIsEditPlotHeaderSlideOverOpen] = useState(false);
  const [isImageUploadModalOpen, setIsImageUploadModalOpen] = useState(false);

  const thumbnail = plot?.files?.find((file) => file.fileType === 'Thumbnail')
    ? `/api/projects/${loadedProjectId}/calculation-models/${plot?.calculationModelId}/plots/${plot?.plotId}/file/${plot?.files?.find(
      (file) => file.fileType === 'Thumbnail',
    )?.id}`
    : '/assets/project-placeholder/project-placeholder-1.jpg';
  const showforItemTypes = (itemTypes: (string | undefined)[]) => {
    return itemTypes.includes(plot?.type);
  };

  const TopRowComponents = [
    {
      component: <CostsCard plot={plot} />,
      show: showforItemTypes(['Plot', 'Infrastructure', 'SpecialItem']) && !plot?.isProjectPlot,
    },
    {
      component: <BriCard plot={plot} />,
      show: showforItemTypes(['Plot', 'Infrastructure', 'SpecialItem']) && !plot?.isProjectPlot,
    },
    {
      component: <MarketRentCard plot={plot} />,
      show: showforItemTypes(['Plot', 'Infrastructure', 'SpecialItem']) && !plot?.isProjectPlot,
    },
  ];

  const leftColumnComponents = [
    {
      component: <PlotsCard plot={plot} />,
      show: plot?.isProjectPlot,
    },
    {
      component: <DescriptionCard plot={plot} setIsEditPlotAreaSlideOverOpen={setIsEditPlotAreaSlideOverOpen} />,
      show: showforItemTypes(['Plot', 'Infrastructure', 'SpecialItem']) && !plot?.isProjectPlot,
    },
    // {
    //   component: <RentersCard item={plot} />,
    //   show: false,
    // },
    // {
    //   component: <RentersContactsCard item={plot} />,
    //   show: false,
    // }
  ];

  const rightColumnComponents = [
    {
      component: (
        <AreasCard
          plot={plot}
          setIsEditPlotAreaSlideOverOpen={
            plot?.isProjectPlot ? setIsEditPlotHeaderSlideOverOpen : setIsEditPlotAreaSlideOverOpen
          }
        />
      ),
      show: showforItemTypes(['Plot', 'Infrastructure', 'SpecialItem']) || plot?.isProjectPlot,
    },
    {
      component: <UsageTypeCard plot={plot} setIsEditPlotAreaSlideOverOpen={setIsEditPlotAreaSlideOverOpen} />,
      show: showforItemTypes(['Plot', 'Infrastructure', 'SpecialItem']) && !plot?.isProjectPlot,
    },
  ];

  return (
    <div className="flex-grow overflow-y-auto">
      <div className="flex-shrink flex flex-col space-y-3">
        <div className="w-full flex flex-col flex-grow flex-shrink z-0 relative">
          <div className="px-2 pb-2 w-full overflow-hidden h-[250px] relative">
            <LazyLoadImage src={thumbnail} alt="Background" className="w-full h-full object-cover" />
            <Button
              className="absolute top-0 right-0 m-6 aspect-square shadow-lg h-14 w-14 bg-white hover:bg-slate-100"
              variant="custom"
              onClick={() => setIsImageUploadModalOpen(true)}
            >
              <PencilIcon />
            </Button>
            <HeaderInfo plot={plot} />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 p-6 gap-6">
        {TopRowComponents.filter((element) => element.show).length > 0 && (
          <div className="col-span-3 grid grid-cols-3 gap-6">
            {TopRowComponents.filter((element) => element.show).map((element, i) => {
              return <div key={i}>{element.component}</div>;
            })}
          </div>
        )}

        <div className="col-span-2 grid grid-cols-1 gap-6 h-min">
          {leftColumnComponents
            .filter((element) => element.show)
            .map((element, i) => {
              return <div key={i}>{element.component}</div>;
            })}
        </div>
        <div className="col-span-1 grid grid-cols-1 gap-6 h-min">
          {rightColumnComponents
            .filter((element) => element.show)
            .map((element, i) => {
              return <div key={i}>{element.component}</div>;
            })}
        </div>
      </div>
      <SlideOver isOpen={isEditPlotAreaSlideOverOpen} onClose={() => setIsEditPlotAreaSlideOverOpen(false)}>
        <PlotElementSlideOver
          item={plot}
          isReadOnly={isReadOnly}
          canDelete={canDelete}
          onClose={() => setIsEditPlotAreaSlideOverOpen(false)}
        />
      </SlideOver>
      <SlideOver isOpen={isEditPlotHeaderSlideOverOpen} onClose={() => setIsEditPlotHeaderSlideOverOpen(false)}>
        <PlotHeaderSlideOver
          item={plot}
          isReadOnly={isReadOnly}
          onClose={() => setIsEditPlotHeaderSlideOverOpen(false)}
        />
      </SlideOver>
      <Modal isOpen={isImageUploadModalOpen} onClose={() => setIsImageUploadModalOpen(false)} zIndex="z-50">
        <ImageUploadModal plot={plot} onClose={() => setIsImageUploadModalOpen(false)} />
      </Modal>
    </div>
  )
}
