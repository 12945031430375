import { i18n } from '@client/shared/utilities';

export const getRiskCatalogTranslation = (title: string) => {
  switch (title) {
    case 'Externe Risiken':
      return i18n.t('templates.riskCatalogExternalRisksTitle');
    case 'Interne Risiken':
      return i18n.t('templates.riskCatalogInternalRisksTitle');
    case 'Interne Risk': // TODO there is that wrong name in qa and production database
      return i18n.t('templates.riskCatalogInternalRisksTitle');
    case 'Inflation':
      return i18n.t('templates.riskCatalogInflationTitle');
    case 'Nachbarschaftlich':
      return i18n.t('templates.riskCatalogNeighbourlyTitle');
    case 'Rechtlich':
      return i18n.t('templates.riskCatalogLegallyTitle');
    case 'Genehmigung':
      return i18n.t('templates.riskCatalogPermissionTitle');
    case 'Neue Gesetze':
      return i18n.t('templates.riskCatalogNewLawsTitle');
    case 'Insolvenz':
      return i18n.t('templates.riskCatalogInsolvencyTitle');
    case 'Wetterbedingt':
      return i18n.t('templates.riskCatalogWeatherDependentTitle');
    case 'Sonstiges':
      return i18n.t('templates.riskCatalogOtherTitle');
  }

  return title;
};
