import classNames from 'classnames';

export type BadgeVariants = 'success' | 'info' | 'danger' | 'primary' | 'secondary' | 'lightSuccess' | 'lightInfo' | 'custom';

export interface BadgeProps {
  text: string;
  className?: string;
  variant?: BadgeVariants;
  side?: 'left' | 'right';
}

const variantStyles = {
  success: 'text-white bg-green-600',
  info: 'text-white bg-sky-600',
  danger: 'text-white bg-red-600',
  secondary: 'text-white bg-gray-600',
  primary: 'text-white bg-primary',
  lightSuccess: 'text-white bg-green-400',
  lightInfo: 'text-white bg-sky-400',
  custom: ''
};

export const Badge = ({ text, className, variant = 'primary' }: BadgeProps) => (
  <span
    className={classNames(
      'inline-block items-center px-2 text-xs font-semibold leading-5 rounded-full truncate',
      variantStyles[variant],
      className
    )}
  >
    {text}
  </span>
);
