import { DealType, useApiPostCreateRentDealDocumentMutation, useApiPostCreateSellDealDocumentMutation } from '@client/shared/api';
import { useState } from 'react';
import { Button, FileInput, LoadingIndicator, Modal } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId } from '@client/project/store';
import { useCanWriteDeal } from '../../hooks/useDealPermissions';

interface DealDocumentUploadProps {
  dealId: string;
  type: DealType;
  onDone?: () => void;
}

export const DealDocumentUploadModal = ({ dealId, type, onDone }: DealDocumentUploadProps) => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();

  const readOnly = !useCanWriteDeal(type);
  const [files, setFiles] = useState<FileList | null>(null);

  const [postRentDocuments, { isLoading : isLoadingRentDocuments }] = useApiPostCreateRentDealDocumentMutation();
  const [postSellDocuments, { isLoading : isLoadingSellDocuments }] = useApiPostCreateSellDealDocumentMutation();


  const handleSaveDocuments = async () => {
    if (files && files.length > 0) {
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }

      switch (type){
        case 'Rent':
          try {
            await safeMutation(
              postRentDocuments,
              {
                projectId: projectId ?? 'unset',
                dealId: dealId,
                body: formData as unknown as { files: Blob[] },
              },
              isLoadingRentDocuments
            );

            setFiles(null);
            handleClose();
          } catch (e) {
            console.log(e, 'error');
          }
          break;
        case 'Sell':
          try {
            await safeMutation(
              postSellDocuments,
              {
                projectId: projectId ?? 'unset',
                dealId: dealId,
                body: formData as unknown as { files: Blob[] },
              },
              isLoadingSellDocuments
            );

            setFiles(null);
            handleClose();
          } catch (e) {
            console.log(e, 'error');
          }
          break;
      }
    }
  };

  const handleFileChange = (fileList: FileList | null) => {
    setFiles(fileList);
  };

  const handleClose = () => {
    if (onDone) onDone();
  };

  return (
    <>
      <Modal.Header title={t('projectRent.dealDocumentActionUpload')}></Modal.Header>
      <Modal.Content className="w-full pt-6 px-12">
        {(isLoadingRentDocuments || isLoadingSellDocuments) && <LoadingIndicator text={t('projectRent.documentUploading') ?? ''} mode="overlay" />}
        <div className="bg-white p-3 rounded-sm">
          <FileInput
            acceptedFileTypes={[]}
            multiple={true}
            onChange={(files) => handleFileChange(files)}
          />
        </div>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" className="mr-2" onClick={handleClose}>
          {t('common.cancel')}
        </Button>
        {!readOnly &&
          <Button onClick={handleSaveDocuments} disabled={!files || files.length === 0}>
            {t('common.upload')}
          </Button>
        }
      </Modal.Controls>
    </>
  );
};
