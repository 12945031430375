import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DealDetailsSlideOver } from '.';
import { useGetProjectDeals } from '../hooks';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useEffect, useState } from 'react';
import { DealReadModel, DealType } from '@client/shared/api';
import { LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import { useLoadedProjectId } from '@client/project/store';

export const DealView = ({setIsDealSlideOverOpen, type}: {setIsDealSlideOverOpen: (open: boolean) => void, type: DealType}) => {
  const { dealId } = useParams();
  const navigate = useNavigate()
  const { t } = useTranslation();
  const { data: response, isFetching, isError } = useGetProjectDeals(type);
  const [selectedDeal, setSelectedDeal] = useState<DealReadModel | undefined>(undefined)
  const [isOpen, setIsOpen] = useState(false)
  const [searchParams] = useSearchParams();
  const projectId = useLoadedProjectId()

  const fallBackUrl = type === 'Rent' ? ROUTES_CONFIG.PROJECT_RENT.path.replace(':id', projectId ?? '') : ROUTES_CONFIG.PROJECT_SELL.path.replace(':id', projectId ?? '')

  if (!dealId) {
    navigate({
      pathname: fallBackUrl,
      search: createSearchParams(searchParams).toString()
    })
  }

  useEffect(() => {
    if (response?.deals) {
      const deals = response.deals
      const foundDeal = deals.find((x) => x.id === dealId);
      if (!foundDeal) {
        navigate({
          pathname: fallBackUrl,
          search: createSearchParams(searchParams).toString()
        })
      }
      setSelectedDeal(foundDeal)
      setIsOpen(true)
      setIsDealSlideOverOpen(true)
    }
    if (isError) {
      navigate({
        pathname: fallBackUrl,
        search: createSearchParams(searchParams).toString()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, isError]);


  return (
    <SlideOver
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      variant="x-large"
      onAfterLeave={() => {
        navigate({
          pathname: fallBackUrl,
          search: createSearchParams(searchParams).toString()
        })
        setIsDealSlideOverOpen(false)
      }}
    >
      {isFetching && <LoadingIndicator mode="overlay" text={t('projectRent.loadingDealDetails')} />}
      <DealDetailsSlideOver
        deal={selectedDeal}
        onClose={() => setIsOpen(false)}
      />
    </SlideOver>
  )
}
