export const getMinDate = (date: string | null | undefined) => {
  if (date) {
    const minDate = new Date(date);
    minDate.setHours(0, 0, 0, 0);
    return minDate;
  }
  return undefined;
};

export const getMinDateError = (date: string | null | undefined, minDate: string | null | undefined) => {
  return date && minDate && new Date(date).setHours(0, 0, 0, 0) < new Date(minDate).setHours(0, 0, 0, 0);
};
