import { TenantCatalogReadModel, TenantCatalogType } from '@client/shared/api';
import {
  Badge,
  Modal,
  ContextMenuItem,
  ContextMenu,
  SlideOver,
  Highlighted, PencilIcon, TrashIcon
} from '@client/shared/toolkit';
import {
  Bars3CenterLeftIcon,
  InformationCircleIcon,
  BuildingOfficeIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { CatalogDeleteModal } from './CatalogDeleteModal';
import React, { useState } from 'react';
import { CatalogSlideOver } from './CatalogSlideOver';
import { CatalogEditSlideOver } from './CatalogEditSlideOver';

interface CatalogRowProps {
  catalog: TenantCatalogReadModel;
  searchText?: string | null
}

export const CatalogRow = ({ catalog, searchText }: CatalogRowProps) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const getTranslatedType = (type: TenantCatalogType): string => {
    switch (type) {
      case 'Costs':
        return t('projectCalculate.calculateCostsTitle');
      case 'Earnings':
        return t('projectVariants.labelEarnings');
      case 'Risks':
        return t('projectCalculate.calculateRiskTitle');
      case 'Finance':
        return t('projectCalculate.calculateFinancingTitle');
    }
  };

  const contextItems: ContextMenuItem[] = [
    {
      label: t('app.ConnectShowDetails'),
      subtitle: '',
      icon: <InformationCircleIcon />,
      onClick: () => setShowDetails(true),
      stopPropagation: true,
    },
    {
      label: t('common.edit'),
      subtitle: '',
      icon: <PencilIcon />,
      onClick: () => setShowEdit(true),
      isDisabled: !catalog.isTenantCatalog,
    },
    {
      label: t('common.delete'),
      subtitle: '',
      icon: <TrashIcon />,
      onClick: () => setShowDelete(true),
      isDisabled: catalog.projectCount !== 0 || !catalog.isTenantCatalog,
    },
  ];

  const catalogIcon = () => {
    return catalog.isTenantCatalog
      ? <BuildingOfficeIcon className="w-6" />
      : <Bars3CenterLeftIcon className="w-6 " />
  }

  return (
    <div>
      <div className="w-full flex flex-row py-2 text-gray-500 hover:bg-blue-100 items-center">
        <div className="flex mx-3">{catalogIcon()}</div>

        <div className="w-7/12 font-bold text-base text-gray-900 cursor-pointer" onClick={() => setShowDetails(true)}>
          {searchText ? (
            <Highlighted text={catalog.name} highlight={searchText.trim()} />
          ) : catalog.name}
        </div>

        <div className="w-2/12 text-gray-500 flex flex-row">
          <div>{getTranslatedType(catalog.type)}</div>
        </div>

        <div className="w-2/12 flex items-center">
          <Badge text={String(catalog.projectCount)} variant="secondary" />
        </div>
        <div className="w-1/12">
          <div className="flex flex-row justify-end">
            <ContextMenu items={contextItems} className="w-6 h-6" />
          </div>
        </div>
      </div>
      <Modal isOpen={showDelete} onClose={() => setShowDelete(false)}>
        <CatalogDeleteModal catalog={catalog} onClose={() => setShowDelete(false)} />
      </Modal>
      <SlideOver isOpen={showDetails} onClose={() => setShowDetails(false)} variant="2xl">
        <CatalogSlideOver catalog={catalog} onClose={() => setShowDetails(false)} />
      </SlideOver>
      <SlideOver isOpen={showEdit} onClose={() => setShowEdit(false)} variant="default">
        <CatalogEditSlideOver catalog={catalog} onClose={() => setShowEdit(false)} />
      </SlideOver>
    </div>
  );
};
