import { BaseSelectOption, ComboSelect, ContextMenu, TextInput, TrashIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

interface DocuwareConditionListItemProps {
  id: number;
  options: BaseSelectOption[];
  onUpdate: (id: number, selected: string, query: string) => void;
  onDelete: (id: number) => void;
  selected?: string;
  query?: string;
}

export const DocuwareConditionListItem = ({
  id,
  options,
  onUpdate,
  onDelete,
  selected,
  query,
}: DocuwareConditionListItemProps) => {
  const { t } = useTranslation();

  const contextMenuItems = [
    {
      label: t('common.delete'),
      subtitle: t('app.settingsApiDocuwareFieldsDelete'),
      icon: <TrashIcon />,
      onClick: () => {
        onDelete(id);
      },
    },
  ];

  return (
    <div className="flex bg-white divide-x w-full items-center">
      <div className="w-6/12">
        <ComboSelect
          className="w-full"
          label={t('app.settingsApiDocuwareFields')}
          options={options ?? []}
          value={selected ?? ''}
          onChange={(value) => {
            onUpdate(id, value ?? '', query ?? '');
          }}
        />
      </div>
      <div className="w-5/12">
        <TextInput
          className="w-full"
          label={t('app.settingsApiDocuwareFieldValue')}
          value={query ?? ''}
          onChange={(value) => {
            onUpdate(id, selected ?? '', value);
          }}
        />
      </div>
      <div className="w-1/12 flex justify-center">
        <ContextMenu items={contextMenuItems} />
      </div>
    </div>
  );
};
