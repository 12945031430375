import { BooleanInput, FormField, InfoText, SlideOverTitle } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { TenantTrustInviteModal } from './TenantTrustInviteModal';
import {
  CompanyBranchReadModel,
  CompanyReadModel, PersonBranchAssignmentReadModel,
  useApiPostInviteTenantMutation
} from '@client/shared/api';

interface TenantTrustToggleProps {
  className?: string
  fieldName?: string
  handleOnChange?: (val: boolean) => void
  submitOnChange?: boolean
  showInfoText?: boolean
  project: string
  company?: CompanyReadModel
  contact?:  PersonBranchAssignmentReadModel | null
  branch?: CompanyBranchReadModel | null
  disabled?: boolean
  errorMessage?: string
}
export const TenantTrustToggle = (props: TenantTrustToggleProps) => {
  const {
    className,
    fieldName = 'uploadActive',
    handleOnChange,
    submitOnChange = false,
    showInfoText = true,
    project,
    contact,
    company,
    branch,
    disabled = false,
    errorMessage
  } = props
  const { t } = useTranslation()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activate, setActivate] = useState(false)

  const [inviteTrustedTenant] = useApiPostInviteTenantMutation();

  const handleSubmit = async () => {
    if (contact && project && company && branch && !disabled) {
      try {
        await inviteTrustedTenant({
          body: {
            companyId: company.id,
            companyBranchId: branch.id,
            companyPersonId: contact.id,
            projectId: project
          },
        }).unwrap();
        setIsModalOpen(false)
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div className={className}>
      <SlideOverTitle title={t('uploadPortal.title')} />
      <FormField name={fieldName}>
        {(control) => (
          <BooleanInput
            disabled={disabled}
            label={control.value ? t('app.active') : t('app.inactive')}
            variant="switch"
            subLabel={t('app.settingsColStatus')}
            {...control}
            onChange={(val) => {
              if (submitOnChange) {
                setActivate(val)
                setIsModalOpen(true)
              } else {
                control.onChange(val)
                if (handleOnChange) {
                  handleOnChange(val)
                }
              }
            }}
            isValidationValid={control.isValidationValid && !errorMessage}
            showValidation={control.showValidation || !!errorMessage}
            helperText={errorMessage ? errorMessage : control.helperText}
            helperTextTruncate={false}
          />
        )}
      </FormField>
      {showInfoText && (
        <InfoText className="mt-2">
          {t('uploadPortal.activateUploadPortalInfoText')}
        </InfoText>
      )}
      <TenantTrustInviteModal
        descriptionData={{
          company: company?.name ?? '',
          project: project ?? '',
          invitee: contact?.personName ?? ''
        }}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSubmit}
        activate={activate}
      />
    </div>
  )
}
