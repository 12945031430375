import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UiState } from './uiSlice';

type RootState = {
  ui: UiState;
};

export const useUi = () => {
  const ui = useSelector<RootState, UiState>((x) => x.ui);
  return useMemo(() => ui, [ui]);
};
