import {
  ApiDeleteAuthGroupApiResponse,
  ApiDeleteAuthRoleApiResponse,
  ApiDeleteTenantAvatarApiResponse,
  ApiPostCopyAuthRoleApiResponse,
  ApiPostCreateAuthGroupApiResponse,
  ApiPostCreateAuthRoleApiResponse,
  ApiPostCreateTenantAvatarApiResponse,
  ApiPostCreateUserInvitationApiResponse,
  ApiPostUpdateAuthGroupApiResponse,
  ApiPostUpdateAuthRoleApiResponse,
  ApiPostUpdateAuthSubjectMembershipApiResponse,
  ApiPostUpdateLicenseGrantApiArg,
  ApiPostUpdateLicenseGrantApiResponse,
  ApiPostUpdateTenantOwnerApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiSettingsEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetAuthGroups: {
    providesTags: [{ type: ApiTagTypes.AuthGroups, id: 'LIST' }],
  },
  apiGetAuthRoles: {
    providesTags: [{ type: ApiTagTypes.AuthRoles, id: 'LIST' }],
  },
  apiGetAuthSubjects: {
    providesTags: [{ type: ApiTagTypes.AuthSubjects, id: 'LIST' }],
  },
  apiGetLicenseGrants: {
    providesTags: [{ type: ApiTagTypes.LicenseGrants, id: 'LIST' }],
  },
  apiGetUserTenants: {
    providesTags: [{ type: ApiTagTypes.Tenants, id: 'LIST' }],
  },
  /*------------------ INVALIDATE TAGS --------------------*/
  apiDeleteAuthGroup: {
    invalidatesTags: (_result: ApiDeleteAuthGroupApiResponse, error: FetchBaseQueryError) => {
      return error == null
        ? [
            { type: ApiTagTypes.AuthGroups, id: 'LIST' },
            { type: ApiTagTypes.AuthRoles, id: 'LIST' },
            { type: ApiTagTypes.AuthSubjects, id: 'LIST' },
          ]
        : [];
    },
  },
  apiPostUpdateAuthGroup: {
    invalidatesTags: (_result: ApiPostUpdateAuthGroupApiResponse, error: FetchBaseQueryError) => {
      return error == null
        ? [
            { type: ApiTagTypes.AuthGroups, id: 'LIST' },
            { type: ApiTagTypes.AuthRoles, id: 'LIST' },
            { type: ApiTagTypes.AuthSubjects, id: 'LIST' },
          ]
        : [];
    },
  },
  apiPostCreateAuthGroup: {
    invalidatesTags: (result: ApiPostCreateAuthGroupApiResponse, error: FetchBaseQueryError) => {
      return error == null
        ? [
            { type: ApiTagTypes.AuthGroups, id: 'LIST' },
            { type: ApiTagTypes.AuthRoles, id: 'LIST' },
            { type: ApiTagTypes.AuthSubjects, id: 'LIST' },
          ]
        : [];
    },
  },
  apiPostCreateAuthRole: {
    invalidatesTags: (_result: ApiPostCreateAuthRoleApiResponse, error: FetchBaseQueryError) =>
      error == null ? [{ type: ApiTagTypes.AuthRoles, id: 'LIST' }] : [],
  },
  apiPostCopyAuthRole: {
    invalidatesTags: (_result: ApiPostCopyAuthRoleApiResponse, error: FetchBaseQueryError) =>
      error == null ? [{ type: ApiTagTypes.AuthRoles, id: 'LIST' }] : [],
  },
  apiDeleteAuthRole: {
    invalidatesTags: (_result: ApiDeleteAuthRoleApiResponse, error: FetchBaseQueryError) =>
      error == null
        ? [
            { type: ApiTagTypes.AuthRoles, id: 'LIST' },
            { type: ApiTagTypes.AuthGroups, id: 'LIST' },
            { type: ApiTagTypes.AuthSubjects, id: 'LIST' },
          ]
        : [],
  },
  apiPostUpdateAuthRole: {
    invalidatesTags: (_result: ApiPostUpdateAuthRoleApiResponse, error: FetchBaseQueryError) =>
      error == null
        ? [
            { type: ApiTagTypes.AuthRoles, id: 'LIST' },
            { type: ApiTagTypes.AuthGroups, id: 'LIST' },
            { type: ApiTagTypes.AuthSubjects, id: 'LIST' },
          ]
        : [],
  },
  apiPostUpdateAuthSubject: {
    invalidatesTags: (_result: ApiPostUpdateAuthSubjectMembershipApiResponse, error: FetchBaseQueryError) =>
      error == null
        ? [
            { type: ApiTagTypes.AuthRoles, id: 'LIST' },
            { type: ApiTagTypes.AuthGroups, id: 'LIST' },
            { type: ApiTagTypes.AuthSubjects, id: 'LIST' },
          ]
        : [],
  },
  apiPostUpdateAuthSubjectMembership: {
    invalidatesTags: (_result: ApiPostUpdateAuthSubjectMembershipApiResponse, error: FetchBaseQueryError) =>
      error == null
        ? [
            { type: ApiTagTypes.AuthRoles, id: 'LIST' },
            { type: ApiTagTypes.AuthGroups, id: 'LIST' },
            { type: ApiTagTypes.AuthSubjects, id: 'LIST' },
            { type: ApiTagTypes.LicenseGrants, id: 'LIST' },
          ]
        : [],
  },
  apiPostCreateUserInvitation: {
    invalidatesTags: (_result: ApiPostCreateUserInvitationApiResponse, error: FetchBaseQueryError) =>
      error == null
        ? [
            {
              type: ApiTagTypes.AuthSubjects,
              id: 'LIST',
            },
          ]
        : [],
  },
  apiPostUpdateLicenseGrant: {
    invalidatesTags: (
      _result: ApiPostUpdateLicenseGrantApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateLicenseGrantApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.AuthSubjects, id: 'LIST' },
            { type: ApiTagTypes.LicenseGrants, id: 'LIST' },
          ]
        : [],
  },
  apiPostCreateTenantAvatar: {
    invalidatesTags: (_result: ApiPostCreateTenantAvatarApiResponse, error: FetchBaseQueryError) =>
      error == null
        ? [
            {
              type: ApiTagTypes.Tenants,
              id: 'LIST',
            },
          {
            type: ApiTagTypes.Tenants,
            id: 'LIST',
          },
          { type: ApiTagTypes.InvoiceReport }
          ]
        : [],
  },
  apiDeleteTenantAvatar: {
    invalidatesTags: (_result: ApiDeleteTenantAvatarApiResponse, error: FetchBaseQueryError) =>
      error == null
        ? [
            {
              type: ApiTagTypes.Tenants,
              id: 'LIST',
            },
            { type: ApiTagTypes.InvoiceReport }
          ]
        : [],
  },
  apiPostUpdateTenantOwner: {
    invalidatesTags: (_result: ApiPostUpdateTenantOwnerApiResponse, error: FetchBaseQueryError) =>
      error == null
        ? [
            {
              type: ApiTagTypes.AuthSubjects,
              id: 'LIST',
            },
          ]
        : [],
  },
};
