import {
  DistributionFrequency,
  DistributionReadModel,
  DistributionType,
  ElementTimelineReadModel
} from '@client/shared/api';
import { TimeLineElementInput, TimeInput } from './TimeLineElementInput';
import { DateFromIcon } from '@client/shared/toolkit';
import { TimeLineFrequencySelect } from './TimeLineFrequencySelect';
import { useTranslation } from 'react-i18next';
import {TimeLineDistributionSelect} from "./TimeLineDistributionSelect";
import cn from "classnames";
import { useEffect, useMemo } from "react";
import { addDays } from 'date-fns';
import { formatDateOnly } from '@client/shared/utilities';

interface TimeLineMoneyDistributionHeaderProps {
  className?: string
  timing?: ElementTimelineReadModel | null;
  variantId?: string;
  hideEnd?: boolean;
  hideDistribution?: boolean;
  startLabel?: string;
  endLabel?: string;
  disabled?: boolean;
  disabledDistributionTypes?: DistributionType[];
  onClear?: () => void;
  handleOnChange: (data: {
    startElement?: TimeInput;
    endElement?: TimeInput;
    elementDistribution?: DistributionReadModel;
  }) => void
  timeLineStart?: TimeInput
  timeLineEnd?: TimeInput
}

export const TimeLineMoneyDistributionHeader = (props: TimeLineMoneyDistributionHeaderProps) => {
  const { t } = useTranslation();
  const {
    className,
    endLabel = t('projectCalculate.deliveryPhaseLabelEndDate'),
    hideEnd = false,
    startLabel = t('projectCalculate.deliveryPhaseLabelStartDate'),
    timing,
    variantId,
    disabled,
    disabledDistributionTypes,
    handleOnChange,
    timeLineStart,
    timeLineEnd
  } = props

  const isGrainSelectDisabled = useMemo(() => {
    return !timing ||
      (timing && (!timing.distribution?.type || timing.distribution?.type === 'None' || timing.distribution?.type === 'Manual')) ||
      !timing.effectiveEndDate || !timing.effectiveStartDate;
  }, [timing])

  const disabledGrainOptions: DistributionFrequency[] = useMemo(() => {
    if (timing && timing.distribution?.type !== 'Manual') {
      return ['None']
    }
    return []
  }, [timing])

  const isDistributionSelectDisabled = useMemo(() => {
    return !timing?.effectiveStartDate || !timing?.effectiveEndDate || disabled
  }, [disabled, timing?.effectiveStartDate, timing?.effectiveEndDate])

  const minEndDate = useMemo(() => {
    // TODO check if start cannot be equal end date
    // return timeLineStart?.effectiveDate ? new Date(timeLineStart?.effectiveDate) : undefined;
    return timeLineStart?.effectiveDate ? addDays(new Date(timeLineStart?.effectiveDate), 1) : undefined;
  }, [timeLineStart?.effectiveDate])

  useEffect(() => {
    // TODO check if start cannot be equal end date
    /* if (timeLineStart?.effectiveDate && timeLineEnd?.effectiveDate) {
      const end = new Date(timeLineEnd.effectiveDate)
      const start = new Date(timeLineStart.effectiveDate)
      // end date needs to be larger than start date
      if (end < start) {
        handleOnChange({ endElement: timeLineStart })
      }
    } */
    if (timeLineStart?.effectiveDate && timeLineEnd?.effectiveDate) {
      const end = new Date(timeLineEnd.effectiveDate);
      const start = new Date(timeLineStart.effectiveDate);
      // end date needs to be larger than start date
      if (end <= start) {
        const endTl = {
          variantDeliveryPhaseId: null,
          variantMileStoneId: null,
          offset: null,
          offsetPosition: null,
          offsetUnit: null,
          elementTimelineId: null,
          fixedDate: formatDateOnly(addDays(start, 1)),
          type: 'FixedDates',
          effectiveDate: formatDateOnly(addDays(start, 1)),
        } as TimeInput;
        handleOnChange({ endElement: endTl })
      }
    }
  }, [timeLineStart, timeLineEnd, handleOnChange]);

  if (!variantId) return null
  return (
      <div className={cn('flex divide-x flex-row pb-3', className)}>
        <TimeLineElementInput
          className={hideEnd ? 'w-2/4 flex-none' : 'w-1/4 flex-none'}
          timeLineData={timeLineStart}
          label={startLabel}
          variantId={variantId}
          disabled={disabled}
          onChange={(data) => {
            if (hideEnd) {
              handleOnChange({
                startElement: data,
                endElement: { ...data, type: 'FixedDates', fixedDate: data.effectiveDate },
              });
            } else {
              handleOnChange({ startElement: data });
            }
          }}
          icon={<DateFromIcon />}
          inModal={true}
        />
        {!hideEnd && (
          <TimeLineElementInput
            className="w-1/4 flex-none"
            timeLineData={timeLineEnd}
            label={endLabel}
            variantId={variantId}
            showDuration={!!timeLineStart} // if there is no start, yet, you cannot set the duration
            disabled={disabled}
            onChange={(data) => handleOnChange({ endElement: data })}
            icon={<DateFromIcon className='scale-x-[-1]' />}
            minDate={minEndDate}
            inModal={true}
            type="end"
          />
        )}
        <TimeLineDistributionSelect
          className="w-1/4 flex-none"
          timeline={timing}
          disabledTypes={disabledDistributionTypes}
          disabled={isDistributionSelectDisabled}
          onChange={(data) => handleOnChange({ elementDistribution: data })}
        />
        <TimeLineFrequencySelect
          className="w-1/4 flex-none"
          disabledTypes={disabledGrainOptions}
          disabled={isGrainSelectDisabled}
          timeline={timing}
          onChange={(data) => handleOnChange({ elementDistribution: data })}
        />
      </div>
  );
};
