import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CalculationModelDeliveryPhaseReadModel,
  CalculationModelMilestoneReadModel
}
from '@client/shared/api';
import { AddButton, Modal } from '@client/shared/toolkit';
import { TimeLineHeaderYear } from './TimeLineHeaderYear';
import { getMilestonesForYear, getPhasesForYear, getPhasesWithPositions } from './TimeLineDataContext';
import { CalculationModelDeliveryPhasesMilestonesModal } from "../DeliveryPhase";
import { formatDateOnly } from '@client/shared/utilities';

export enum TimeLineView {
  FINANCE = 'finance',
  TIMELINE= 'timeline'
}

interface TimeLineHeaderProps {
  className?: string;
  phases?: CalculationModelDeliveryPhaseReadModelExt[];
  monthsGroupedByYear?: Date[][]
  milestones?: CalculationModelMilestoneReadModel[];
  view: TimeLineView
  timeLineHeaderRef: React.RefObject<HTMLElement>
  dueDate?: string | null
}

export interface CalculationModelDeliveryPhaseReadModelExt extends CalculationModelDeliveryPhaseReadModel {
  startDate?: Date
  endDate?: Date
  position?: number
  yearEnd?: Date
  yearStart?: Date
}

export const TimeLineHeader = ({
  className,
  phases = [
    {
      id: '1',
      code: ' ',
      name: ' ',
      order: 1,
      timeLine: {
        id: '1',
        duration: 1095,
        effectiveStartDate: formatDateOnly(new Date()),
        effectiveEndDate: formatDateOnly(new Date(
          new Date().getFullYear() + 3,
          new Date().getMonth(),
          new Date().getDate()
        )),
        startType: 'FixedDates',
        endType: 'FixedDates',
      },
    },
  ],
  milestones = [],
  view,
  monthsGroupedByYear = [],
  timeLineHeaderRef,
  dueDate
}: TimeLineHeaderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scrollPos, setScrollPos] = useState(0)
  const handleModalClose = () => {
    setIsModalOpen(false);
  }
  const milestonesPerYear = useCallback((year: number) => {
    return getMilestonesForYear(year, milestones)
  }, [milestones])

  const phasesWithPositions = useMemo(() => {
    return getPhasesWithPositions(phases)
  }, [phases])

  const phasesPerYear = useCallback((year: number) => {
    return getPhasesForYear(year, phasesWithPositions)
  }, [phasesWithPositions])

  useEffect(() => {
    if (timeLineHeaderRef?.current) {
      const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          if (mutation.type === "attributes" && mutation.attributeName === 'style') {
            if (timeLineHeaderRef.current) {
              const style = window.getComputedStyle(timeLineHeaderRef.current)
              const matrix = new WebKitCSSMatrix(style.transform)
              setScrollPos(matrix.m41)
            }
          }
        });
      });
      observer.observe(timeLineHeaderRef.current, {
        attributes: true
      })
    }
  }, [timeLineHeaderRef])
  return (
    <>
      <div
        className={classNames('relative min-w-full flex flex-col justify-end h-full', className)}
        onClick={() => setIsModalOpen(true)}
      >
        {!monthsGroupedByYear?.length && (
          <div className="p-4">
            <AddButton onClick={() => setIsModalOpen(true)} />
          </div>
        )}
        <div className="flex text-gray-400 cursor-pointer" style={{ maxWidth: 'calc(100% - 32px)'}}>
          {/* Year Timeline */}
          {monthsGroupedByYear.map((months, index) => (
            <TimeLineHeaderYear
                key={`timeline-header-year-${index}`}
                months={months}
                view={view}
                showMilestones={milestones.length > 0}
                milestones={months.length ? milestonesPerYear(months[0].getFullYear()) : []}
                phases={phases.length ? phasesPerYear(months[0].getFullYear()) : []}
                scrollPos={scrollPos}
                dueDate={dueDate}
            />
          ))}
        </div>
      </div>
      {/*
      <SlideOver isOpen={isModalOpen} variant="custom" onClose={() => handleModalClose()}>
        <CalculationModelDeliveryPhasesMilestonesSlideOver onClose={handleModalClose} />
      </SlideOver>
      */}
      <Modal isOpen={isModalOpen} onClose={handleModalClose} variant="max">
        <CalculationModelDeliveryPhasesMilestonesModal onClose={handleModalClose} />
      </Modal>
    </>
  );
};
