import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import cn from 'classnames';

interface WizardSlideHeaderProps extends PropsWithChildren {
  icon?: ReactNode
  title?: string
  subTitle?: string
  fullHeight?: boolean
}
export const WizardSlideHeader = (props: WizardSlideHeaderProps) => {
  const {
    icon,
    title,
    subTitle,
    children,
    fullHeight = false
  } = props
  return (
    <div className={cn('w-full flex items-center flex-col px-12', fullHeight ? 'justify-center h-full' : 'py-6')}>
      {icon && (
        <div className="h-36 w-36 mb-8">
          {React.cloneElement(icon as ReactElement, { className: 'w-full h-full' })}
        </div>
      )}
      {(title || subTitle) && (
        <div className="text-center">
          {title && (
            <h1 className="text-3xl font-bold text-gray-900 text-center">
              {title}
            </h1>
          )}
          {subTitle && (
            <p className="pt-3 text-sm text-gray-500">{subTitle}</p>
          )}
        </div>
      )}
      {children}
    </div>
  )
}
