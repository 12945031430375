import { useLoadedProjectId } from '@client/project/store';
import { DealCostItemReadModel, DealReadModel, useApiDeleteRentDealCostItemMutation, useApiDeleteSellDealCostItemMutation } from '@client/shared/api';
import { ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { Trans, useTranslation } from 'react-i18next';

interface DealCostItemDeleteModalProps extends ModalOnCloseProps {
  deal: DealReadModel;
  costItem: DealCostItemReadModel;
}

export const DealCostItemDeleteModal = ({ deal, costItem, onClose }: DealCostItemDeleteModalProps) => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();
  const [deleteRentCostItem, { isLoading : isLoadingRent }] = useApiDeleteRentDealCostItemMutation();
  const [deleteSellCostItem, { isLoading : isLoadingSell }] = useApiDeleteSellDealCostItemMutation();

  const handleDelete = async () => {
    switch (deal.type){
      case 'Rent':
        try {
          await safeMutation(
            deleteRentCostItem,
            {
              projectId: projectId ?? 'unset',
              dealId: deal.id ?? '',
              costItemId: costItem.id,
            },
            isLoadingRent
          );
    
          onClose(true);
        } catch {
          /* left blank */
        }        
        break;
      case 'Sell':
        try {
          await safeMutation(
            deleteSellCostItem,
            {
              projectId: projectId ?? 'unset',
              dealId: deal.id ?? '',
              costItemId: costItem.id,
            },
            isLoadingSell
          );
    
          onClose(true);
        } catch {
          /* left blank */
        }        
        break;        
    }
    
  };

  return (
    <ConfirmModal
      title={`${t('common.delete')} ${costItem.taxonomyItem?.itemName} - ${costItem.taxonomyItem?.itemType}`}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      {(isLoadingRent || isLoadingSell) && <LoadingIndicator text={t('projectRent.costItemDeleteLoading') ?? ''} mode="overlay" />}
      <Trans
        i18nKey="projectRent.dealCostItemDeleteConfirmation"
        values={{ entity: `${costItem.taxonomyItem?.itemName} - ${costItem.taxonomyItem?.itemType}` }}
      >
        <div>
          Möchten Sie die Zuordnung <span className="font-bold">Entity</span> wirklich löschen?
        </div>
        <div className="pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
