import { useEffect } from 'react';

const generateScript = (id?: string) => {
  const script = `
 (function (c, l, a, r, i, t, y) {
   c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
   t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
   y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
 })(window, document, "clarity", "script", "${id}");
 `;

  return script;
};

export const useClarity = (id?: string) => {
  useEffect(() => {
    if (id == null || id === '') return;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = generateScript(id);

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [id]);
};
