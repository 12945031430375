import {
  DealReadModel,
  DealCostItemReadModel,
  DealContractItemReadModel,
  ContractItemCategory,
  CalculationTarget
} from '@client/shared/api';

export const getSumOfParkingSpace = (costItems: DealCostItemReadModel[]): number => {
  return costItems
    .filter((x) => x.conflictingSold.length === 0 && x.conflictingRented.length === 0)
    .reduce((x, y) => (x = x + (y.partParkingAmount ?? 0)), 0);
};

export const getSumOfCommonAndRentalSpace = (costItems: DealCostItemReadModel[]): number => {
  return costItems
    .filter((x) => x.conflictingSold.length === 0 && x.conflictingRented.length === 0)
    .reduce((x, y) => (x = x + (y.partRentalSpace.value ?? 0) + (y.partCommonSpace.value ?? 0)), 0);
};

export const getNumberOfRooms = (costItems: DealCostItemReadModel[]): number => {
  return costItems?.reduce((x, y) => (x = x + (y.taxonomyItem?.sizes.floorAreaCount ?? 0)), 0);
};

export const getDealContractItemsSum = (deal: DealReadModel): number => {
  return (
    deal?.contractItems?.reduce(
      (x, y) => (x = x + (y.calculation === 'Value' ? y.value.value ?? 0 : (y.value.value ?? 0) * (y.factor.value ?? 0))  * (1 + y.vat / 100)),
      0
    ) ?? 0
  );
};

export const getContractItemsSumWithoutVat = (contractItems: DealContractItemReadModel[]): number => {
  return contractItems.reduce((x, y) => (x = x + (y.calculation === 'Value' ? y.value.value ?? 0 : (y.value.value ?? 0) * (y.factor.value ?? 0))), 0) ?? 0;
};

export const getVatValue = (deal: DealReadModel): number => {
  return getDealContractItemsSum(deal) - getContractItemsSumWithoutVat(deal.contractItems);
};

export const getDealVat = (deal: DealReadModel): number => {
  const sumWithVat = getDealContractItemsSum(deal);
  const sumWithoutVat = getContractItemsSumWithoutVat(deal.contractItems);

  return sumWithVat === 0 ? 0 : (sumWithVat - sumWithoutVat) / sumWithoutVat;
};

export const getContractItemsColdRentSumWithoutVat = (contractItems: DealContractItemReadModel[]): number => {
  return (
    contractItems
      .filter((x) => x.category === 'Rent')
      .reduce((x, y) => (x = x + (y.calculation === 'Value' ? y.value.value ?? 0 : (y.value.value ?? 0) * (y.factor.value ?? 0))), 0) ?? 0
  );
};

export const getAssignedSpaceOrAmount = (
  costItems: DealCostItemReadModel[],
  calculation: CalculationTarget
): number => {
  switch (calculation) {
    case 'RentalSpace':
      return costItems
        .filter(
          (x) =>
            x.conflictingSold.length === 0 && x.conflictingRented.length === 0 && x.taxonomyItem?.itemType !== 'Parking'
        )
        .reduce((x, y) => (x = x + (y.partRentalSpace.value ?? 0)), 0);
    case 'CommonSpace':
      return costItems
        .filter(
          (x) =>
            x.conflictingSold.length === 0 && x.conflictingRented.length === 0 && x.taxonomyItem?.itemType !== 'Parking'
        )
        .reduce((x, y) => (x = x + (y.partCommonSpace.value ?? 0)), 0);
    case 'Amount':
      return costItems
        .filter(
          (x) =>
            x.conflictingSold.length === 0 && x.conflictingRented.length === 0 && x.taxonomyItem?.itemType === 'Parking'
        )
        .reduce((x, y) => (x = x + y.partParkingAmount), 0);
    default:
      return 0;
  }
};

export const getUsedRentalSpaceInDeal = (
  contractItems: DealContractItemReadModel[],
  category: ContractItemCategory,
  calculation: CalculationTarget,
  dealContractItemId: string | undefined
): number => {
  if (category !== 'Rent' && category !== 'Sell') return 0;

  if (dealContractItemId) {
    return contractItems
      .filter((x) => x.category === category && x.calculation === calculation && x.id !== dealContractItemId)
      .reduce((x, y) => (x = x + (y.value.value ?? 0)), 0);
  }

  return contractItems
    .filter((x) => x.category === category && x.calculation === calculation)
    .reduce((x, y) => (x = x + (y.value.value ?? 0)), 0);
};
