import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ProjectState } from './projectSlice';

type RootState = {
  project: ProjectState;
};

export const useProjectState = () => {
  const project = useSelector<RootState, ProjectState>((x) => x.project);
  return useMemo(() => project, [project]);
};
