import {
  CalculationModelCostsQueryResponse,
  CatalogElementRestBudget,
  CostCatalogElementDto,
  useApiGetCalculationModelCostsQuery,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useMemo } from 'react';

export const flattenCostElements = (costElements: CostCatalogElementDto[]): CostCatalogElementDto[] => {
  if (!costElements) return [];
  else {
    return costElements
      .flatMap((costElement) => {
        return [costElement, ...flattenCostElements(costElement.children)];
      })
      .filter((x) => x != null && x.description !== 'unassigned');
  }
}

export const useFlattenCostElements = (data?: CalculationModelCostsQueryResponse, skip = false): CostCatalogElementDto[] => {
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: costData } = useApiGetCalculationModelCostsQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: !loadedProjectId || !loadedVariantId || !!data || skip },
  )

  return useMemo(() => {
    if (data) {
      return data.payload.catalogElements ? flattenCostElements(data.payload.catalogElements) : []
    } else if (costData?.payload.catalogElements) {
      return flattenCostElements(costData?.payload.catalogElements)
    }
    return []
  }, [costData?.payload.catalogElements, data])
}

export const flattenCostElementRestBudgets = (costElements: CatalogElementRestBudget[]): CatalogElementRestBudget[] => {
  if (!costElements) return [];
  else {
    return costElements
      .flatMap((costElement) => {
        return [costElement, ...flattenCostElementRestBudgets(costElement.children)];
      })
      .filter((x) => x != null && x.name !== 'unassigned');
  }
}

export const useFlattenCostElementRestBudgets = (costElements: CatalogElementRestBudget[]): CatalogElementRestBudget[] => {
  return useMemo(() => {
    if (costElements.length > 0) {
      return flattenCostElementRestBudgets(costElements);
    }
    return []
  }, [costElements])
}
