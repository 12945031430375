import {
    DefaultValuesReadModel,
    useApiPostCreateDefaultValuesMutation,
    useApiPostUpdateDefaultValuesMutation,
  } from '@client/shared/api';
  import classNames from 'classnames';
  import { useState, useRef } from 'react';
  import {
  Button,
  SlideOver,
  Form,
  FormField,
  Modal,
  SlideOverOnCloseProps,
  NumberInput, TagWindowIcon, LoadingIndicator,
} from '@client/shared/toolkit';
  import { useTranslation } from 'react-i18next';
import { DefaultValuesFormValidationSchema, DefaultValuesFormValidationValues } from './DefaultValuesFormValidationSchema';
import { DefaultValuesDeleteModal } from './DefaultValuesDeleteModal';
import { safeMutation } from '@client/shared/utilities';

  interface DefaultValuesSlideOverProps extends SlideOverOnCloseProps {
    defaultValues?: DefaultValuesReadModel;
  }

  export const DefaultValuesSlideOver = ({ defaultValues, onClose }: DefaultValuesSlideOverProps) => {
    const { t } = useTranslation();
    const submitRef = useRef<HTMLButtonElement>(null);

    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    const [createDefaultValues, { isLoading: isCreating }] = useApiPostCreateDefaultValuesMutation();
    const [updateDefaultValues, { isLoading: isUpdating }] = useApiPostUpdateDefaultValuesMutation();

    const handleSubmit = async (data: DefaultValuesFormValidationValues) => {
      try {
        if (!defaultValues) {
          await safeMutation(
            createDefaultValues,
            {
                body: {
                    dueDateDeadline : data.dueDateDeadline,
                    cashDiscountDeadline : data.cashDiscountDeadline
                },
            },
            isCreating
          );
        } else {
          await safeMutation(
            updateDefaultValues,
            {
              defaultValuesId : defaultValues.id,
              body: {
                dueDateDeadline : data.dueDateDeadline,
                cashDiscountDeadline : data.cashDiscountDeadline
              },
            },
            isUpdating
          );
        }

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    };

    const defaultFormValues = {
      cashDiscountDeadline: defaultValues?.cashDiscountDeadline ?? undefined,
      dueDateDeadline: defaultValues?.dueDateDeadline ?? undefined,
    };

    return (
      <>
        {(isCreating || isUpdating) && (
          <LoadingIndicator text={t('common.loading')} mode="overlay" />
        )}
        <SlideOver.Header
          onClose={() => onClose(false)}
          title={t('app.masterDataDefaultValues.title')}
          backgroundClassName="bg-sky-900"
        />

        <Form<DefaultValuesFormValidationValues>
          onSubmit={handleSubmit}
          validationSchema={DefaultValuesFormValidationSchema}
          defaultValues={defaultFormValues}
          className="w-full flex flex-col justify-between h-full"
        >
          <SlideOver.Content>
            <div className="m-8 bg-white">
              <div className="divide-gray-100 divide-y">
                <FormField name="dueDateDeadline">
                  {(control) => (
                    <NumberInput
                      label={t('app.masterDataDefaultValues.dueDateDeadline')}
                      icon={<TagWindowIcon />}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="cashDiscountDeadline">
                  {(control) => (
                    <NumberInput
                      label={t('app.masterDataDefaultValues.cashDiscountDeadline')}
                      icon={<TagWindowIcon />}
                      {...control}
                    />
                  )}
                </FormField>
              </div>
            </div>
          </SlideOver.Content>
          <SlideOver.Controls>
            <div className={classNames('flex w-full', defaultValues ? 'justify-between' : 'justify-end')}>
                {defaultValues && (
                    <Button variant="warning" onClick={() => setIsOpenDeleteModal(true)}>
                        {t('common.delete')}
                    </Button>
                )}
                <div className="flex">
                    <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="primary" formSubmit={true} innerRef={submitRef}>
                        {t('common.save')}
                    </Button>
                </div>
            </div>
          </SlideOver.Controls>
        </Form>

        {defaultValues && (
          <Modal isOpen={isOpenDeleteModal} onClose={() => {setIsOpenDeleteModal(false); onClose(true);}} variant="small">
            <DefaultValuesDeleteModal defaultValues={defaultValues} onClose={() => {setIsOpenDeleteModal(false); onClose(true);}} />
          </Modal>
        )}
      </>
    );
  };
