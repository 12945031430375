import { useTranslation } from 'react-i18next';
import { Dispatch, RefObject, SetStateAction, useMemo } from 'react';
import {
  BaseSelect,
  BaseSelectOption,
  FormField,
  FormRefHandle,
  LinkIcon,
  NumberInput,
  SalePriceTagIcon,
} from '@client/shared/toolkit';
import { InvoiceCalculationSchemeReadModel } from '@client/shared/api';
import { IcsDeductionNewFormValidationValues } from './IcsNewFormValidationSchema';

export interface IcsDeductionValueFormFieldProps {
  getReferenceTypeByReferenceId: (id: string) => string;
  calculationRuleReferenceOptions: BaseSelectOption[];
  calculationRuleGroupReferenceOptions: BaseSelectOption[];
  sheet: InvoiceCalculationSchemeReadModel | null;
  setCustomAmount: Dispatch<SetStateAction<number | null | undefined>>;
  customAmount: number | undefined | null;
  allowCustomAmount?: boolean;
  formRef?: RefObject<FormRefHandle<IcsDeductionNewFormValidationValues>>;
  selectedValue?: string | null;
  label?: string;
  name?:
    | 'selectedValue'
    | 'selectedPartialInvoiceValue'
    | 'selectedPartialFinalInvoiceValue'
    | 'selectedFinalInvoiceValue';
  referenceTypeFormName?:
    | 'referenceType'
    | 'partialInvoiceReferenceType'
    | 'partialFinalInvoiceReferenceType'
    | 'finalInvoiceReferenceType';
  calculationRuleReferenceFormName?:
    | 'calculationRuleReference'
    | 'partialInvoiceCalculationRuleReference'
    | 'partialFinalInvoiceCalculationRuleReference'
    | 'finalInvoiceCalculationRuleReference';
  calculationRuleGroupReferenceFormName?:
    | 'calculationRuleGroupReference'
    | 'partialInvoiceCalculationRuleGroupReference'
    | 'partialFinalInvoiceCalculationRuleGroupReference'
    | 'finalInvoiceCalculationRuleGroupReference';
}

export const IcsDeductionValueFormField = (props: IcsDeductionValueFormFieldProps) => {
  const {
    name = 'selectedValue',
    selectedValue,
    label,
    getReferenceTypeByReferenceId,
    calculationRuleReferenceOptions,
    calculationRuleGroupReferenceOptions,
    sheet,
    setCustomAmount,
    customAmount,
    allowCustomAmount= true,
    formRef,
    referenceTypeFormName = 'referenceType',
    calculationRuleReferenceFormName = 'calculationRuleReference',
    calculationRuleGroupReferenceFormName = 'calculationRuleGroupReference'
  } = props
  const { t } = useTranslation()
  const selectedValueOptions = useMemo(() => {
    const options: BaseSelectOption[] = [];

    // only available for multiplication and cash discount
    if (allowCustomAmount) {
      options.push({
        label: t('ics.referenceTypeNone'),
        value: 'None',
        disabled: true,
        customLabel: customAmount?.toString(),
        children: (
          <div className="ml-6">
            <NumberInput
              label={t('ics.deductionValueAmount')}
              value={customAmount}
              onChange={(value) => {
                if (value) {
                  // reset the references and set reference type to 'None'
                  formRef?.current?.setValue('selectedValue', 'None');
                  formRef?.current?.setValue('referenceType', 'None');
                  formRef?.current?.setValue('calculationRuleReference', null);
                  formRef?.current?.setValue('calculationRuleGroupReference', null);

                  formRef?.current?.setValue('selectedPartialInvoiceValue', 'None');
                  formRef?.current?.setValue('partialInvoiceReferenceType', 'None');
                  formRef?.current?.setValue('partialInvoiceCalculationRuleReference', null);
                  formRef?.current?.setValue('partialInvoiceCalculationRuleGroupReference', null);

                  formRef?.current?.setValue('selectedPartialFinalInvoiceValue', 'None');
                  formRef?.current?.setValue('partialFinalInvoiceReferenceType', 'None');
                  formRef?.current?.setValue('partialFinalInvoiceCalculationRuleReference', null);
                  formRef?.current?.setValue('partialFinalInvoiceCalculationRuleGroupReference', null);

                  formRef?.current?.setValue('selectedFinalInvoiceValue', 'None');
                  formRef?.current?.setValue('finalInvoiceReferenceType', 'None');
                  formRef?.current?.setValue('finalInvoiceCalculationRuleReference', null);
                  formRef?.current?.setValue('finalInvoiceCalculationRuleGroupReference', null);
                }
                setCustomAmount(value);
              }}
              decimalScale={2}
            />
          </div>
        ),
      })
    }

    options.push(
      {
        label: t('ics.referenceTypeInvoiceValue'),
        value: 'InvoiceValue',
      },
      {
        label: t('ics.referenceTypeInvoiceValueNet'),
        value: 'InvoiceValueNet',
      },
      {
        label: t('ics.referenceTypeInvoiceValueGross'),
        value: 'InvoiceValueGross',
      },
      {
        label: t('ics.referenceTypeMainContractValue'),
        value: 'MainContractValue',
      },
      {
        label: t('ics.referenceTypeMainContractValueNet'),
        value: 'MainContractValueNet',
      },
      {
        label: t('ics.referenceTypeMainContractValueGross'),
        value: 'MainContractValueGross',
      },
      {
        label: t('ics.referenceTypeSupplementValue'),
        value: 'SupplementValue',
      },
      {
        label: t('ics.referenceTypeSupplementValueNet'),
        value: 'SupplementValueNet',
      },
      {
        label: t('ics.referenceTypeSupplementValueGross'),
        value: 'SupplementValueGross',
      },
      {
        label: t('ics.referenceTypeContractValue'),
        value: 'ContractValue',
      },
      {
        label: t('ics.referenceTypeContractValueNet'),
        value: 'ContractValueNet',
      },
      {
        label: t('ics.referenceTypeContractValueGross'),
        value: 'ContractValueGross',
      },);

    // only available for cumulated ics
    if (sheet?.type === 'Cumulated') {
      options.push(
      {
        label: t('ics.referenceTypePartialPayment'),
        value: 'PartialPayment',
      },
      {
        label: t('ics.referenceTypePartialPaymentNet'),
        value: 'PartialPaymentNet',
      },
      {
        label: t('ics.referenceTypePartialPaymentGross'),
        value: 'PartialPaymentGross',
      });
    }

    // only if group reference options are available
    if (calculationRuleGroupReferenceOptions.length) {
      options.push({
        label: t('ics.referenceTypeDeductionGroup'),
        value: 'CalculationRuleGroup',
        disabled: true,
        options: calculationRuleGroupReferenceOptions,
      });
    }
    // only if calculationRule reference options are available
    if (calculationRuleReferenceOptions.length > 0) {
      options.push({
        label: t('ics.referenceTypeDeduction'),
        value: 'CalculationRule',
        disabled: true,
        options: calculationRuleReferenceOptions,
      });
    }

    return options;
  }, [
    t,
    customAmount,
    formRef,
    setCustomAmount,
    sheet?.type,
    calculationRuleGroupReferenceOptions,
    calculationRuleReferenceOptions,
    allowCustomAmount
  ]);

  return (
    <FormField name={name ?? 'selectedValue'}>
      {(control) => (
        <BaseSelect
          label={label ?? t('ics.deductionValueAmount')}
          options={selectedValueOptions}
          icon={selectedValue === 'None' ? <SalePriceTagIcon /> : <LinkIcon />}
          {...control}
          onChange={(value) => {
            const refType = getReferenceTypeByReferenceId(value);
            // reset the custom value
            setCustomAmount(null);
            if (refType === 'CalculationRule' || refType === 'CalculationRuleGroup') {
              // for calculationRule group and calculationRule references, we only have an ID, so we need
              // to check which ID was set to update the correct reference type
              const refType = getReferenceTypeByReferenceId(value);
              formRef?.current?.setValue(referenceTypeFormName, refType);
              if (refType === 'CalculationRuleGroup') {
                formRef?.current?.setValue(calculationRuleReferenceFormName, null);
                formRef?.current?.setValue(calculationRuleGroupReferenceFormName, value);
              } else {
                formRef?.current?.setValue(calculationRuleReferenceFormName, value);
                formRef?.current?.setValue(calculationRuleGroupReferenceFormName, null);
              }
            } else {
              formRef?.current?.setValue(referenceTypeFormName, value);
              formRef?.current?.setValue(calculationRuleReferenceFormName, null);
              formRef?.current?.setValue(calculationRuleGroupReferenceFormName, null);
            }
            control.onChange(value);
          }}
        />
      )}
    </FormField>
  );
}
