import { DecoratedCard } from '@client/shared/toolkit';
import { PlotReadModel } from '@client/shared/api';
import { formatUnit } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

interface PlotsCardProps {
  plot?: PlotReadModel | undefined;
}

export const PlotsCard = ({ plot }: PlotsCardProps) => {
  const { t } = useTranslation();

  return (
    <DecoratedCard>
      <DecoratedCard.Header showActionButton={false}>
        <div className="truncate">{t('projectTaxonomy.plotDetailsPlotsTitle')}</div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="p-3 w-full h-full flex items-center justify-center bg-white">
        <div className="space-y-2 flex flex-col w-full">
          {plot?.plots.map((plot) => (
            <div key={plot.plotId} className="flex border rounded-md bg-white p-3">
              <div>
                <div className="text-base font-bold text-gray-900">{plot.plotName}</div>
                <div className="text-sm text-gray-500">{formatUnit(plot.totalPlotSize.value, plot.totalPlotSize.unit)}</div>
              </div>
            </div>
          ))}
        </div>
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};
