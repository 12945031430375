import { LicenseGrantReadModel } from '@client/shared/api';
import { KeyIcon } from '@heroicons/react/24/outline';

interface IProps {
  licenses: LicenseGrantReadModel[];
}

export const LicenseList = (props: IProps) => {
  const { licenses } = props;

  return (
    <div className="p-4">
      <div className="border-t border-slate-300">
        {licenses
          .filter((x) => x.isActive === true)
          .map((x, idx) => (
            <div key={idx} className="flex flex-row border-b border-slate-300 pb-2 pt-2">
              <div className="flex items-center">
                <KeyIcon className="w-6 mr-3" />
              </div>
              <div>
                <div>{x.name}</div>
                <div className="text-sm text-gray-500">{x.description}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
