import { Document, Page, pdfjs, Thumbnail } from 'react-pdf';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DocumentViewerPreviewHeader, DocumentViewerPreviewHeaderMenuOptions } from './DocumentViewerPreviewHeader';
import { DocumentCallback, OnItemClickArgs, PageCallback } from 'react-pdf/src/shared/types';
import cn from 'classnames';
import { FileInput } from '../FileInput';
import { HintBox } from '../HintBox';
import { useTranslation } from 'react-i18next';
import { DragScrollContainerTranslate } from '../DragScrollContainerTranslate';
import { Tooltip } from '../Tooltip';
import { DocumentPositionBoundingBoxReadModel } from './DocumentViewer';

export interface PDFPreviewProps {
  title?: string;
  pdfFile?: string | null;
  file?: File | null;
  thumbnailWidth?: number;
  maxThumbnailsRendering?: number;
  showThumbnails?: boolean;
  handleFileChange?: (fileList: FileList | null) => void;
  boxes?: (DocumentPositionBoundingBoxReadModel | null)[];
  name: string;
  width?: number;
  height?: number;
  uploadDescription?: string;
  customMenu?: ReactNode;
  additionalMenu?: ReactNode;
  menuOptions?: DocumentViewerPreviewHeaderMenuOptions;
  hoveredBox?: number | null;
  setHoveredBox?: (box: number | null) => void;
  fileLoaded?: boolean
  imageHeight?: number
  imageWidth?: number
  allBoundingBoxes?: {
    boxes: number[][][]
    pages: number[]
    texts: string[][]
  } | null
  showAllBoxes?: boolean
  blob?: Blob | null
  initialZoomLevel?: number
}

export const DocumentViewerPreview = (props: PDFPreviewProps) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
  const { t } = useTranslation()
  const {
    title,
    pdfFile,
    // file,
    showThumbnails = true,
    thumbnailWidth = 60,
    maxThumbnailsRendering = 15,
    handleFileChange,
    boxes = [],
    name,
    // width,
    // height,
    uploadDescription,
    customMenu,
    additionalMenu,
    menuOptions,
    hoveredBox,
    setHoveredBox,
    fileLoaded = false,
    imageHeight,
    allBoundingBoxes,
    showAllBoxes = false,
    blob,
    initialZoomLevel = 1.0
  } = props;

  const [numPages, setNumPages] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(1.0);
  const [zoomFactor, setZoomFactor] = useState(1.0);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [enteredValues] = useState(Array(100).fill(''));
  const [pageHeight, setPageHeight] = useState(0);

  const [allBoxes, setAllBoxes] = useState<number[][][]>([])
  const [allBoxesPages, setAllBoxesPages] = useState<number[]>([])
  const [allBoxesTexts, setAllBoxesTexts] = useState<string[][]>([])

  const [maxRendering, setMaxRendering] = useState(maxThumbnailsRendering)

  const containerRef = useRef<HTMLDivElement>(null)

  const onDocumentLoadSuccess = (document: DocumentCallback) => {
    setNumPages(document.numPages);
    setRotationAngle(0);
    setZoomLevel(initialZoomLevel);
  };

  const onPageLoadSuccess = (page: PageCallback) => {
    let adjustedHeight = page.height;
    let adjustedWidth = page.width;

    // Check if the page is in landscape mode
    if (adjustedHeight < adjustedWidth) {
      // Swap the height and width values
      [adjustedHeight, adjustedWidth] = [adjustedWidth, adjustedHeight];
      setTimeout(() => {
        setRotationAngle(270);
      }, 100);
    }
    setPageHeight(adjustedHeight)
    if (imageHeight) {
      const newZoomFactor = imageHeight / adjustedHeight;
      setZoomFactor(newZoomFactor)
    }
  };

  const rotate = () => {
    setRotationAngle((prev) => (prev + 90) % 360);
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      // setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        // setIsFullScreen(false);
      }
    }
  };

  const pages: number[] = useMemo(() => {
    return numPages > 0 ? Array.from({ length: numPages }) : [];
  }, [numPages]);

  const onItemClick = useCallback((args: OnItemClickArgs) => {
    setSelectedPage(args.pageNumber);
  }, [])

  useEffect(() => {
    if (pageHeight && imageHeight && boxes.length) {
      const newZoomFactor = imageHeight / pageHeight;
      setZoomFactor(newZoomFactor)
    }
  }, [boxes, pageHeight, imageHeight]);

  useEffect(() => {
    if (allBoundingBoxes) {
      setAllBoxes(Object.values(allBoundingBoxes.boxes))
      setAllBoxesPages(Object.values(allBoundingBoxes.pages))
      setAllBoxesTexts(Object.values(allBoundingBoxes.texts))
    }
  }, [allBoundingBoxes]);

  return (
    <div className="rounded-md w-full shadow">
      <DocumentViewerPreviewHeader
        title={title}
        zoomOut={() => setZoomLevel((prev) => Math.max(prev - 0.1, 0.5))}
        zoomIn={() => setZoomLevel((prev) => prev + 0.1)}
        goToPreviousPage={() => setSelectedPage((prev) => Math.max(1, prev - 1))}
        goToNextPage={() => setSelectedPage((prev) => Math.min(numPages, prev + 1))}
        rotate={rotate}
        toggleFullscreen={toggleFullscreen}
        selectedPage={selectedPage}
        numPages={numPages}
        pdfFile={pdfFile ?? ''}
        zoomLevel={zoomLevel}
        name={name}
        customMenu={customMenu}
        additionalMenu={additionalMenu}
        menuOptions={menuOptions}
        blob={blob}
      />
      {!pdfFile && !fileLoaded && handleFileChange && (
        <FileInput
          acceptedFileTypes={['application/pdf']}
          multiple={false}
          selectedFiles={null}
          onChange={handleFileChange}
          uploadDescription={uploadDescription}
        />
      )}
      {fileLoaded && !pdfFile && (
        <HintBox className="mt-4">{t('projectControl.documentViewerCouldNotLoadFileMessage')}</HintBox>
      )}
      {pdfFile && (
        <div className="w-full">
          <Document
            file={pdfFile}
            onLoadSuccess={onDocumentLoadSuccess}
            className="w-full flex gap-5 p-8 overflow-hidden"
            onItemClick={onItemClick}
          >
            {showThumbnails && pages.length > 0 && (
              <div className="flex flex-col gap-2 overflow-y-auto max-h-[65vh] flex-none w-[90px] pr-2 items-center">
                {/* Load thumbnails not all at once */}
                {pages.map((_element, index) => {
                  if (index <= maxRendering) {
                    return (
                      <Thumbnail
                        key={`pdf-upload-thumbnail-${index}`}
                        pageNumber={index + 1}
                        width={thumbnailWidth}
                        className={cn('block border-[3px]', {
                          'border-slate-500': selectedPage === index + 1,
                          'border-transparent': selectedPage !== index + 1,
                        })}
                        onRenderSuccess={() => {
                          if (maxRendering !== numPages) {
                            setMaxRendering(numPages)
                          }
                        }}
                      />
                    );
                  }
                  return null
                })}
              </div>
            )}
            <div key={`page_${selectedPage}`} className="relative overflow-hidden">
              <DragScrollContainerTranslate containerRef={containerRef} paddingRight={0}>
                <Page
                  pageNumber={selectedPage}
                  scale={zoomLevel}
                  rotate={rotationAngle}
                  renderTextLayer={false}
                  onLoadSuccess={onPageLoadSuccess}
                  className="shadow-lg"
                  // canvasBackground="transparent"
                >
                  <div className="absolute top-0 left-0 w-full h-full">
                    {boxes &&
                      boxes.map((box, boxIndex) => {
                        if (box === null || box.page !== selectedPage) return null;
                        return (
                          <div
                            className={cn(
                              'document-bounding-box absolute border-none cursor-pointer text-white pointer-events-auto z-10 transition-colors duration-300',
                              {
                                'bg-transparent': hoveredBox !== boxIndex,
                                'bg-red-500/40': hoveredBox === boxIndex,
                              }
                            )}
                            key={boxIndex}
                            style={{
                              left: box.left / zoomFactor,
                              top: box.top / zoomFactor,
                              width: box.width / zoomFactor,
                              height: box.height / zoomFactor,
                            }}
                            onMouseEnter={() => setHoveredBox ? setHoveredBox(boxIndex) : null}
                            onMouseLeave={() =>  setHoveredBox ? setHoveredBox(null): null}
                          >
                            {enteredValues &&
                              enteredValues.map((value, index) => {
                                if (index === boxIndex && value !== '') {
                                  return (
                                    <p
                                      key={`entered-value-box-${index}`}
                                      className="inline-block overwrittenText font-bold bg-red-400 text-right"
                                    >
                                      {value}
                                    </p>
                                  );
                                }
                                return null;
                              })}
                          </div>
                        );
                      })}
                    </div>
                    <div className={cn('absolute top-0 left-0 w-full h-full',
                      {
                        'pointer-events-auto': showAllBoxes,
                        'pointer-events-none': !showAllBoxes
                      }
                    )}>
                      {showAllBoxes && allBoxes.length > 0 && allBoxes.map((boxes, i) => {
                        if (allBoxesPages[i] && allBoxesPages[i] === selectedPage) {
                          return boxes.map((box, j) => {
                            return (
                              <Tooltip
                                key={`bounding-box-${i}-${j}`}
                                placement="bottom"
                                label={
                                  <div
                                    data-box={`bounding-box-${i}-${j}`}
                                    className={cn(
                                      'document-bounding-box absolute border-none cursor-pointer text-white pointer-events-auto z-10 transition-colors duration-300 bg-red-500/40'
                                    )}
                                    key={`bounding-box-${i}-${j}`}
                                    style={{
                                      left: box[0] / zoomFactor,
                                      top: box[1] / zoomFactor,
                                      width: box[2] / zoomFactor,
                                      height: box[3] / zoomFactor,
                                    }}
                                  />
                                }
                              >
                                <div className="flex flex-col text-sm text-center text-gray-600 p-2 pb-1">{allBoxesTexts[i][j]}</div>
                              </Tooltip>
                            )
                          })
                        }
                        return null
                      })}
                  </div>
                </Page>
              </DragScrollContainerTranslate>
            </div>
          </Document>
        </div>
      )}
    </div>
  );
};
