import { LicenseAssigneeReadModel } from '@client/shared/api';
import { ArrowUturnLeftIcon, KeyIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { TrashIcon } from '@client/shared/toolkit';

interface IProps {
  seat: LicenseAssigneeReadModel;
  freeSeats: boolean;
  state: SeatState;
  onRemove: (seat: LicenseAssigneeReadModel) => void;
}

export type SeatState = 'Present' | 'Added' | 'Removed';

export const LicenseSeat = (props: IProps) => {
  const { seat, freeSeats, state, onRemove } = props;

  return (
    <div className="flex flex-row border-b border-slate-300 pb-2 pt-2 w-full gap-3">
      <div className="flex items-center flex-none">
        <KeyIcon
          className={classNames(
            'w-6',
            { 'text-green-600': state === 'Added' },
            { 'text-red-600': state === 'Removed' }
          )}
        />
      </div>
      <div className="w-8/12 flex-1">
        <div className="truncate">{seat.fullname}</div>
        <div className="text-sm text-gray-400 truncate">{seat.mail}</div>
      </div>
      <div className="flex justify-end text-gray-500 hover:text-black flex-none">
        {state === 'Present' && <TrashIcon className="w-6" onClick={() => onRemove(seat)} />}
        {state === 'Added' && <ArrowUturnLeftIcon className="w-6" onClick={() => onRemove(seat)} />}
        {state === 'Removed' && freeSeats && <ArrowUturnLeftIcon className="w-6" onClick={() => onRemove(seat)} />}
      </div>
    </div>
  );
};
