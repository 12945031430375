import { useMemo } from 'react';
import { ContactListItem } from './ContactListItem'
import cn from 'classnames';
import { HierarchyListProps } from '../HierarchyListProps';

export interface ContactListProps extends HierarchyListProps {
  theme?: 'contact' | 'portfolio';
}

export const ContactList = (props: ContactListProps) => {
  const {
    className,
    listItems = [],
    theme = 'contact',
    level = 0,
    customColor,
    defaultOpen,
    onToggle
  } = props

  const borderColor = theme === 'portfolio' ? 'border-[#164870]' : 'border-slate-600';

  const collapsibleList = useMemo(() => {
    if (listItems.length > 0) {
      return (
        <div className="flex flex-col gap-0.5">
          {listItems.map((listItem, i) => (
            <ContactListItem
              key={`contact-list-item-${i}`}
              theme={theme}
              level={level}
              customColor={customColor}
              defaultOpen={defaultOpen}
              onToggle={onToggle}
              {...listItem}
            />
          ))}
        </div>
      )
    }
    return null
  }, [listItems, theme, level, customColor, defaultOpen, onToggle])

  if (level === 0 && listItems.length) {
    return (
      <div className={cn('relative w-full', className)}>
        <div className={cn('w-[calc(100%_-28px)] rounded absolute left-7 top-0 h-full shadow-lg border-l-[6px] z-0 pointer-events-none bg-slate-200', borderColor)} />
        <div className="w-full flex flex-col gap-0.5 relative z-5 overflow-hidden">
          {collapsibleList}
        </div>
      </div>
    )
  }

  return collapsibleList
}
