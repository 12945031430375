import * as yup from 'yup';
import { InferType } from 'yup';

export const CopyRoleFormValidationSchema = yup.object({
  role: yup.string().required('validation.required'),
  name: yup.string().required('validation.required'),
  comment: yup.string().nullable(),
});

export type CopyRoleFormValidationValues = InferType<typeof CopyRoleFormValidationSchema>;
