import { BaseSelect, Button, LoadingIndicator, Modal, ModalOnCloseProps } from '@client/shared/toolkit';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useApiChangeTenantDatevClientMutation,
  useApiCreateDatevAuthenticationUrlQuery,
  useApiGetDatevClientsQuery,
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { DatevAuthState, updateDatevAuthData } from '@client/project/store';

interface DatevClientUpdateModalProps extends ModalOnCloseProps {
  apiAccessId?: string;
  datevClientId?: string;
  isOpen: boolean;
}

export const DatevClientUpdateModal = ({ apiAccessId, datevClientId, onClose }: DatevClientUpdateModalProps) => {
  const { t } = useTranslation();
  const [selectedClient, setSelectedClient] = useState<string>(datevClientId ?? '');
  const [selectedDatevClientId, setSelectedDatevClientId] = useState<string>('');

  const [updateTenantClient, { isLoading: isUpdatingTenantClient }] = useApiChangeTenantDatevClientMutation();

  const { data: tenantClients, isFetching } = useApiGetDatevClientsQuery(
    {
      datevApiAccessId: apiAccessId ?? '',
    },
    {
      skip: !apiAccessId,
    },
  );

  const activeClientsId = useMemo(() => {
    return tenantClients?.clients.filter((client) => client.hasAccessAvailable)?.map((m) => m.datevClientId);
  }, [tenantClients?.clients]);

  const { data: createDatevAuthUrlResponse, isFetching: isFetchingDatevAuthUrl } =
    useApiCreateDatevAuthenticationUrlQuery(
      {
        datevClientId: selectedDatevClientId,
      },
      {
        skip:
          selectedClient === datevClientId ||
          (!!selectedDatevClientId && !!activeClientsId?.length && activeClientsId.includes(selectedDatevClientId)),
        refetchOnMountOrArgChange: true,
      },
    );

  const handleOnClose = () => {
    onClose(false);
  };

  const handleSubmit = async () => {
    if (createDatevAuthUrlResponse?.url && selectedClient && selectedDatevClientId) {
      updateDatevAuthData({
        datevClientId: selectedClient,
        datevUpdateData: {
          apiAccessId: apiAccessId,
        },
      } as DatevAuthState);
      window.open(createDatevAuthUrlResponse.url, '_self');
    } else {
      try {
        await safeMutation(
          updateTenantClient,
          {
            datevClientId: selectedClient,
            datevApiAccessId: apiAccessId ?? '',
          },
          isUpdatingTenantClient,
        );

        handleOnClose();
      } catch {
        /* left blank */
      }
    }
  };

  const clientOptions = useMemo(() => {
    return (
      tenantClients?.clients
        .map((client) => {
          return {
            label: client.name,
            value: client.id,
          };
        })
        .sort((a, b) =>
          a.label.toLowerCase() < b.label.toLowerCase() ? -1 : a.label.toLowerCase() > b.label.toLowerCase() ? 1 : 0,
        ) ?? []
    );
  }, [tenantClients]);

  return (
    <>
      <Modal.Header
        title={t('app.settingsApiDatevChooseClient')}
        className="mb-0"
        description={t('app.settingsApiDatevChooseClientDescription')}
      />
      <Modal.Content className="flex items-center justify-center !flex-none">
        {(isFetching || isFetchingDatevAuthUrl) && (
          <LoadingIndicator text={t('app.settingsApiDatevLoadingClients') ?? ''} mode="overlay" />
        )}

        {clientOptions.length === 0 ? (
          t('app.settingsApiDatevNoClientsAvailable')
        ) : (
          <BaseSelect
            className="w-full"
            label={t('app.settingsApiDatevClient')}
            options={clientOptions}
            value={selectedClient}
            onChange={(value) => {
              setSelectedClient(value);
              let foundClientDatevId = '';

              const foundClient = tenantClients?.clients.find((client) => client.id === value);
              foundClientDatevId = foundClient?.datevClientId ?? '';

              setSelectedDatevClientId(foundClientDatevId);
            }}
          />
        )}
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={handleOnClose} variant="secondary">
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={selectedClient === datevClientId}>
          {!!activeClientsId?.length && activeClientsId.includes(selectedDatevClientId)
            ? t('common.save')
            : t('common.next')}
        </Button>
      </Modal.Controls>
    </>
  );
};
