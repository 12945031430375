import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiDeleteTenantCalculationRuleApiArg,
  ApiDeleteTenantCalculationRuleApiResponse,
  ApiDeleteTenantCalculationRuleGroupApiArg,
  ApiDeleteTenantCalculationRuleGroupApiResponse,
  ApiDeleteTenantInvoiceCalculationSchemeApiArg,
  ApiDeleteTenantInvoiceCalculationSchemeApiResponse,
  ApiGetInvoiceCalculationSampleApiArg,
  ApiGetInvoiceCalculationSampleApiResponse,
  ApiPostCopyTenantInvoiceCalculationSchemeApiArg,
  ApiPostCopyTenantInvoiceCalculationSchemeApiResponse,
  ApiPostCopyTenantInvoiceCalculationSchemeToProjectApiArg,
  ApiPostCopyTenantInvoiceCalculationSchemeToProjectApiResponse,
  ApiPostCreateTenantCalculationRuleApiArg,
  ApiPostCreateTenantCalculationRuleApiResponse,
  ApiPostCreateTenantCalculationRuleGroupApiArg,
  ApiPostCreateTenantCalculationRuleGroupApiResponse,
  ApiPostCreateTenantInvoiceCalculationSchemeApiArg,
  ApiPostCreateTenantInvoiceCalculationSchemeApiResponse,
  ApiPostMoveTenantCalculationRuleApiArg,
  ApiPostMoveTenantCalculationRuleApiResponse,
  ApiPostMoveTenantCalculationRuleGroupApiArg,
  ApiPostMoveTenantCalculationRuleGroupApiResponse,
  ApiPostUpdateTenantCalculationRuleApiArg,
  ApiPostUpdateTenantCalculationRuleApiResponse,
  ApiPostUpdateTenantCalculationRuleGroupApiArg,
  ApiPostUpdateTenantCalculationRuleGroupApiResponse,
  ApiPostUpdateTenantInvoiceCalculationSchemeApiArg,
  ApiPostUpdateTenantInvoiceCalculationSchemeApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiInvoiceCalculationSchemeEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetTenantInvoiceCalculationSchemes: {
    providesTags: [{ type: ApiTagTypes.TenantInvoiceCoverSheets, id: 'LIST' }],
  },
  apiGetInvoiceCalculationSample: {
    providesTags: (
      _result: ApiGetInvoiceCalculationSampleApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetInvoiceCalculationSampleApiArg,
    ) => [{ type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId }],
  },
  /*------------------ INVALIDATE TAGS --------------------*/
  apiPostCreateTenantInvoiceCalculationScheme: {
    invalidatesTags: (
      _result: ApiPostCreateTenantInvoiceCalculationSchemeApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostCreateTenantInvoiceCalculationSchemeApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.TenantInvoiceCoverSheets, id: 'LIST' }] : []),
  },
  apiDeleteTenantInvoiceCalculationScheme: {
    invalidatesTags: (
      _result: ApiDeleteTenantInvoiceCalculationSchemeApiResponse,
      error: FetchBaseQueryError,
      _args: ApiDeleteTenantInvoiceCalculationSchemeApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.TenantInvoiceCoverSheets, id: 'LIST' }] : []),
  },
  apiPostUpdateTenantInvoiceCalculationScheme: {
    invalidatesTags: (
      _result: ApiPostUpdateTenantInvoiceCalculationSchemeApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostUpdateTenantInvoiceCalculationSchemeApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.TenantInvoiceCoverSheets, id: 'LIST' }] : []),
  },
  apiPostCreateTenantCalculationRuleGroup: {
    invalidatesTags: (
      _result: ApiPostCreateTenantCalculationRuleGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateTenantCalculationRuleGroupApiArg,
    ) => (error == null ? [
      { type: ApiTagTypes.TenantInvoiceCoverSheets, id: 'LIST' },
      { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId }
    ] : []),
  },
  apiPostUpdateTenantCalculationRuleGroup: {
    invalidatesTags: (
      _result: ApiPostUpdateTenantCalculationRuleGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateTenantCalculationRuleGroupApiArg,
    ) => (error == null ? [
      { type: ApiTagTypes.TenantInvoiceCoverSheets, id: 'LIST' },
      { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId }
    ] : []),
  },
  apiDeleteTenantCalculationRuleGroup: {
    invalidatesTags: (
      _result: ApiDeleteTenantCalculationRuleGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteTenantCalculationRuleGroupApiArg,
    ) => (error == null ? [
      { type: ApiTagTypes.TenantInvoiceCoverSheets, id: 'LIST' },
      { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId }
    ] : []),
  },
  apiPostCreateTenantCalculationRule: {
    invalidatesTags: (
      _result: ApiPostCreateTenantCalculationRuleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateTenantCalculationRuleApiArg,
    ) => (error == null ? [
      { type: ApiTagTypes.TenantInvoiceCoverSheets, id: 'LIST' },
      { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId }
    ] : []),
  },
  apiPostUpdateTenantCalculationRule: {
    invalidatesTags: (
      _result: ApiPostUpdateTenantCalculationRuleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateTenantCalculationRuleApiArg,
    ) => (error == null ? [
      { type: ApiTagTypes.TenantInvoiceCoverSheets, id: 'LIST' },
      { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId }
    ] : []),
  },
  apiDeleteTenantCalculationRule: {
    invalidatesTags: (
      _result: ApiDeleteTenantCalculationRuleApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteTenantCalculationRuleApiArg,
    ) => (error == null ? [
      { type: ApiTagTypes.TenantInvoiceCoverSheets, id: 'LIST' },
      { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId }
    ] : []),
  },
  apiPostCopyTenantInvoiceCalculationScheme: {
    invalidatesTags: (
      _result: ApiPostCopyTenantInvoiceCalculationSchemeApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostCopyTenantInvoiceCalculationSchemeApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.TenantInvoiceCoverSheets, id: 'LIST' }] : []),
  },
  apiPostCopyTenantInvoiceCalculationSchemeToProject: {
    invalidatesTags: (
      _result: ApiPostCopyTenantInvoiceCalculationSchemeToProjectApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostCopyTenantInvoiceCalculationSchemeToProjectApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.TenantInvoiceCoverSheets, id: 'LIST' },
            { type: ApiTagTypes.ProjectInvoiceCoverSheets, id: 'LIST' },
          ]
        : [],
  },
  apiPostMoveTenantCalculationRuleGroup: {
    invalidatesTags: (
      _result: ApiPostMoveTenantCalculationRuleGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostMoveTenantCalculationRuleGroupApiArg,
    ) => (error == null ? [
      { type: ApiTagTypes.TenantInvoiceCoverSheets, id: 'LIST' },
      { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId }
    ] : []),
  },
  apiPostMoveTenantCalculationRule: {
    invalidatesTags: (
      _result: ApiPostMoveTenantCalculationRuleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostMoveTenantCalculationRuleApiArg,
    ) => (error == null ? [
      { type: ApiTagTypes.TenantInvoiceCoverSheets, id: 'LIST' },
      { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId }
    ] : []),
  },
};
