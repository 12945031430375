import { LoadingIndicator, Modal } from '@client/shared/toolkit';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CrossTenantContractReadModel,
  TrustedProjectReadModel,
  useApiGetCrossTenantContractsQuery,
} from '@client/shared/api';
import { getCrossTenantAvatar, UploadWizard } from '@client/project/shared';
import { getInitials } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

export const ProjectUpload = () => {
  const { tenantId, projectId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isOpenSlideOver, setIsOpenSlideOver] = useState(true); // TODO false
  const [project, setProject] = useState<TrustedProjectReadModel | undefined>(undefined);
  const [contracts, setContracts] = useState<CrossTenantContractReadModel[]>([]);
  const [modalSize, setModalSize] = useState<string | undefined>(undefined);

  const {
    data: loadedProject,
    isFetching,
    isError,
  } = useApiGetCrossTenantContractsQuery(
    {
      crossTenantProjectId: projectId ?? '',
      crossTenantId: tenantId ?? '',
    },
    {
      skip: tenantId === null || projectId === null,
      refetchOnMountOrArgChange: true
    },
  );

  useEffect(() => {
    if (typeof loadedProject !== 'undefined' && loadedProject !== null) {
      setProject(loadedProject.project);
      setContracts(loadedProject.contracts);
      setIsOpenSlideOver(true);
    }
    if (isError) {
      navigate(ROUTES_CONFIG.PROJECTS.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedProject, isError]);

  const logo = useMemo(() => {
    if (!project) {
      return null
    }
    let logo = '';
    if (project?.hasAvatarImage) {
      logo = getCrossTenantAvatar(project?.tenantId);
    }

    return (
      <div className="absolute top-0 -translate-y-1/2 left-0 w-[260px] z-10 flex flex-col items-center gap-2">
        {logo ? (
          <img src={logo} className="bg-white rounded-md overflow-hidden w-[100px] h-[100px] object-cover" alt="Logo Icon" />
        ) : (
          <div className="w-[100px] h-[100px] rounded p-2 text-lg font-medium leading-none overflow-hidden bg-white flex items-center justify-center">
            {getInitials(project?.tenantName)}
          </div>
        )}
        {/*<span className="text-white font-light text-[13px]">{project?.tenantName}</span> */}
      </div>
    );
  }, [project]);

  if (!tenantId || !projectId) {
    navigate(ROUTES_CONFIG.PROJECTS.name);
  }

  return (
    <Modal
      isOpen={isOpenSlideOver}
      onClose={() => setIsOpenSlideOver(false)}
      onAfterLeave={() => {
        navigate(ROUTES_CONFIG.PROJECTS.name);
      }}
      variant={modalSize ? 'custom' : 'default'}
      className={modalSize ? 'w-[80vw] max-w-[1492px] h-[80vh]' : ''}
      outerContent={logo}
    >
      {isFetching && <LoadingIndicator text={t('project.projectLoadingIndicator')} mode="overlay" />}
      {project?.projectId && (
        <UploadWizard
          contracts={contracts}
          project={project}
          loadedProjectId={project?.projectId}
          onClose={() => setIsOpenSlideOver(false)}
          setModalSize={setModalSize}
        />
      )}
    </Modal>
  );
};
