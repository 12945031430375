import { DealType, TaxonomyCostItemsReadModel } from '@client/shared/api';
import { useState } from 'react';
import { LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import { useGetVariantTaxonomyCostItems } from '../../hooks';
import { ItemDetails } from './ItemDetails';
import { TaxonomyRootItem } from './TaxonomyRootItem';
import { useTranslation } from 'react-i18next';

const flattenTree = (item?: TaxonomyCostItemsReadModel): TaxonomyCostItemsReadModel[] => {
  const result: TaxonomyCostItemsReadModel[] = [];

  if (!item) return result;

  result.push(item);
  item.childItems.forEach((element) => {
    result.push(...flattenTree(element));
  });

  return result;
};

export const DealObjects = ({isDealSlideOverOpen, type}: {isDealSlideOverOpen: boolean, type: DealType}) => {
  const { t } = useTranslation();

  const { data: taxonomyRoot, isFetching, isSuccess } = useGetVariantTaxonomyCostItems();
  const [selectedTreeItemId, setSelectedTreeItemId] = useState<string | undefined>();
  const [slideOverOpen, setIsSlideOverOpen] = useState<boolean>(false);

  const flatElements = flattenTree(taxonomyRoot);
  const selectedTreeItem = flatElements.find((x) => x.taxonomyItem.itemId === selectedTreeItemId);

  const setSelection = (item: TaxonomyCostItemsReadModel) => {
    setSelectedTreeItemId(item.taxonomyItem.itemId);
    setIsSlideOverOpen(true);
  };

  const closeSlideOver = () => {
    if (!isDealSlideOverOpen) {
      setIsSlideOverOpen(false);
    }
  };

  return (
    <div>
      {isFetching === true && <LoadingIndicator text={t('projectRent.dealObjectLoadingBuildings')} />}
      {taxonomyRoot != null && isSuccess === true && (
        <TaxonomyRootItem rootElement={taxonomyRoot} selectedItem={selectedTreeItem} doSelect={setSelection} />
      )}
      <SlideOver isOpen={slideOverOpen} onClose={closeSlideOver} variant="large" confirmBeforeClose={false}>
        <SlideOver.Header
          onClose={closeSlideOver}
          subTitle={t('projectRent.dealObjectSubTitle')}
          title={selectedTreeItem?.taxonomyItem.itemName ?? ''}
          backgroundClassName="bg-sky-900"
        />
        <SlideOver.Content>{selectedTreeItem && <ItemDetails item={selectedTreeItem} />}</SlideOver.Content>
      </SlideOver>
    </div>
  );
};
