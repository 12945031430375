import { useDocumentTitle, TitleBar } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { PropertyContainer } from '../components';
import { useLoadedPortfolio } from '@client/portfolio/store';

export const PropertyRoute = () => {
  const { t } = useTranslation();

  useDocumentTitle({ title: t('portfolio.menu.property') });

  const { data } = useLoadedPortfolio();

  return (
    <>
      <TitleBar
        title={data?.portfolio.name ?? t('portfolio.menu.property')}
        subTitle={t('portfolio.property.routeSubTitle')}
      />
      <div className="flex-grow flex flex-col overflow-hidden">
        <div className="px-4 py-8 md:px-8 flex flex-col flex-grow overflow-y-auto">
          <PropertyContainer />
        </div>
      </div>
    </>
  );
};
