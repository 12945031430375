import { DecoratedCard } from '@client/shared/toolkit';
import { ProjectPayload } from '@client/shared/api';
import { useTranslation } from 'react-i18next';

interface ProjectDataCardProps {
  project?: ProjectPayload | undefined;
}

export const ProjectDataCard = ({ project }: ProjectDataCardProps) => {
  const { t } = useTranslation();

  return (
    <DecoratedCard>
      <DecoratedCard.Header showActionButton={false}>
        <div className="truncate">{t('projectTaxonomy.dashboardProjectData')}</div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="p-5 w-full h-full grid grid-cols-4 bg-white gap-3">
        <div className="col-span-2">
          <div className="m-1">
            <div className="h-full text-xl font-bold text-gray-900">{project?.name}</div>
            <div className="h-5 text-xs text-gray-500">{t('projectTaxonomy.dashboardProjectName')}</div>
          </div>
          {(project?.street || project?.number || project?.postalCode || project?.city) && (
            <div className="m-1">
              <div className="h-full text-sm font-bold text-gray-900">

                {(project?.street || project?.number) ?? ('' && ',')} {project?.postalCode} {project?.city}
              </div>
              <div className="h-5 text-xs text-gray-500">{t('projectTaxonomy.dashboardProjectAddress')}</div>
            </div>
          )}
        </div>
        <div className="col-span-1">
          <div className="m-1">
            <div className="h-7 text-xl font-bold text-gray-900">{project?.projectId}</div>
            <div className="h-5 text-xs text-gray-500">{t('projectTaxonomy.dashboardProjectNr')}</div>
          </div>

          {/* <div className="m-1">
            <div className="h-full text-sm font-bold text-gray-900">
              
            </div>
            <div className="h-5 text-xs text-gray-500">{t('projectTaxonomy.dashboardProjectSap')}</div>
          </div> */}
        </div>
        <div className="col-span-1 h-full">
          {/* <div className="m-1">
            <div className="h-7 text-xl font-bold text-gray-900"></div>
            <div className="h-5 text-xs text-gray-500">{t('projectTaxonomy.dashboardProjectType')}</div>
          </div> */}
        </div>
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};
