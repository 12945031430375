import * as yup from 'yup';
import { InferType } from 'yup';

export const RegisterTrustedTenantFormValidationSchema = yup.object({
  email: yup.string().email('validation.email').required('validation.required'),
  company: yup.string().required('validation.required'),
  firstName: yup.string().required('validation.required'),
  lastName: yup.string().required('validation.required'),
  password: yup.string().required('validation.required').min(8, 'validation.passwordLength').matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{8,}$/,
    'validation.passwordStrength'),
  passwordConfirmation: yup.string().test('passwords-match', 'validation.passwordMatch', function (value) {
    return this.parent.password === value;
  }),
});

export type RegisterTrustedTenantFormValidationValues = InferType<typeof RegisterTrustedTenantFormValidationSchema>;


export const AcceptTrustedTenantFormValidationSchema = yup.object({
  email: yup.string().email('validation.email').required('validation.required'),
  tenant: yup.string().required('validation.required')
});

export type AcceptTrustedTenantFormValidationValues = InferType<typeof AcceptTrustedTenantFormValidationSchema>;
