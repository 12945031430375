import { TrustState } from '@client/shared/api';
import { i18n } from '@client/shared/utilities';

export const getTenantTrustBadgeByStatus = (status: TrustState, active: boolean) => {
  if (!active) {
    return 'disabled';
  }
  switch (status) {
    case 'Invited':
      return 'inactive';
    case 'Accepted':
      return 'active';
    case 'Expired':
      return 'error';
  }
};

export const getTenantTrustBadgeLabelByStatus = (status: TrustState, active: boolean) => {
  if (!active) {
    return i18n.t('app.tenantTrustStatusInactive');
  }
  switch (status) {
    case 'Invited':
      return i18n.t('app.tenantTrustStatusInvited');
    case 'Accepted':
      return i18n.t('app.tenantTrustStatusAccepted');
    case 'Expired':
      return i18n.t('app.tenantTrustStatusExpired');
  }
};
