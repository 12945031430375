import { AuthSubjectReadModel } from '@client/shared/api';
import { SettingsAddButton, SlideOver } from '@client/shared/toolkit';
import { useState } from 'react';
import { UserAddSlideOver } from './UserAddSlideOver';
import { RenderUser, UserState } from './RenderUser';
import { useTranslation } from 'react-i18next';

interface UserListProps {
  assignedUsers: AuthSubjectReadModel[];
  newUsers: AuthSubjectReadModel[];
  deletedUsers: AuthSubjectReadModel[];
  userSelectionChanged: (newUsers: AuthSubjectReadModel[], deletedUsers: AuthSubjectReadModel[]) => void;
}

export const UserList = ({ newUsers, assignedUsers, deletedUsers, userSelectionChanged }: UserListProps) => {
  const { t } = useTranslation();

  const [showAddUser, setShowAddUser] = useState<boolean>(false);

  const getUserState = (user: AuthSubjectReadModel): UserState => {
    if (deletedUsers.includes(user)) {
      return 'Deleted';
    }
    if (!assignedUsers.includes(user)) {
      return 'New';
    }

    return 'Present';
  };

  const handleRemoveUser = (user: AuthSubjectReadModel) => {
    const state = getUserState(user);
    if (state === 'Present') {
      const newRemoved = deletedUsers.concat(user);
      userSelectionChanged(newUsers, newRemoved);
    }
    if (state === 'New') {
      const newAdded = newUsers.filter((x) => x.userId !== user.userId);
      userSelectionChanged(newAdded, deletedUsers);
    }
    if (state === 'Deleted') {
      const newRemoved = deletedUsers.filter((x) => x.userId !== user.userId);
      userSelectionChanged(newUsers, newRemoved);
    }
  };

  const handleAddUsers = (users: AuthSubjectReadModel[]) => {
    const allAdded = newUsers.concat(users);

    userSelectionChanged(allAdded, deletedUsers);
  };

  return (
    <div className="flex flex-col w-full ">
      <div className="flex flex-col w-full">
        {assignedUsers.map((user, idx) => (
          <RenderUser key={idx} user={user} allowRemove={true} state={getUserState(user)} onRemove={handleRemoveUser} />
        ))}
        {newUsers.map((user, idx) => (
          <RenderUser key={idx} user={user} allowRemove={true} state="New" onRemove={handleRemoveUser} />
        ))}
        {newUsers.length === 0 && assignedUsers.length === 0 && (
          <div className="flex my-4 items-center justify-center w-full">
            <div className="text-gray-500">{t('auth.noUsersAdded')}</div>
          </div>
        )}
      </div>

      <SettingsAddButton className="mr-2 mb-2" onClick={() => setShowAddUser(true)} />

      <SlideOver isOpen={showAddUser} onClose={() => setShowAddUser(false)}>
        <UserAddSlideOver
          assignedUsers={assignedUsers.concat(newUsers)}
          onClose={(newUsers) => {
            if (newUsers) {
              handleAddUsers(newUsers);
            }

            setShowAddUser(false);
          }}
        />
      </SlideOver>
    </div>
  );
};
