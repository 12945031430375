import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { TaxonomyReadModel, useApiDeleteTaxonomyMutation } from '@client/shared/api';
import { Button, LoadingIndicator, Modal, ModalOnCloseProps } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface BuildingItemDeleteModalProps extends ModalOnCloseProps {
  item: TaxonomyReadModel;
}

export const BuildingItemDeleteModal = ({ item, onClose }: BuildingItemDeleteModalProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();

  const loadedVariantId = useLoadedVariantId();
  const [deleteChildren, setDeleteChildren] = useState<boolean>(false);
  const [deleteTaxonomyItem, { isLoading }] = useApiDeleteTaxonomyMutation();

  const handleOnChange = () => {
    setDeleteChildren(!deleteChildren);
  };

  useEffect(() => {
    if (item.itemType === 'Project') {
      setDeleteChildren(true);
    }
  }, [item]);

  const handleDelete = async (item: TaxonomyReadModel) => {
    if (loadedVariantId == null) return;

    try {
      await safeMutation(
        deleteTaxonomyItem,
        {
          taxonomyItemId: item.itemId,
          calculationModelId: loadedVariantId,
          projectId: loadedProjectId ?? 'unset',
          shouldDeleteChildren: deleteChildren,
        },
        isLoading
      );

      onClose(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoading && <LoadingIndicator text={t('projectTaxonomy.deletingTaxonomyLoadingIndicator')} mode="overlay-window" />}
      <Modal.Header
        title={t('projectTaxonomy.deleteBuildingElementTitle', {
          element: `${item.itemName} ${item.itemType}`,
        })}
      />
      <Modal.Content>
        <div className="text-sm text-gray-500 my-6 mt-4">
          <Trans
            i18nKey="projectTaxonomy.deleteBuildingElementQuestion"
            values={{ name: item.itemName, type: item.itemType }}
          >
            Are you sure you want to delete <span className="font-bold">name</span> type?
          </Trans>
          <p className="pt-2">{t('projectTaxonomy.deleteBuildingElementHint')}</p>
          {item.children != null && item.children.length > 0 && (
            <div className="mt-6 bg-slate-100 -mx-6 px-6 py-2 -mb-10">
              <div className="grid grid-rows-22 grid-cols-12 grid-flow-col">
                <div className="row-span-2 col-span-1 place-content-center mt-auto mb-auto">
                  {item.itemType === 'Project' && (
                    <input
                      id="delete-building-item-children-checkbox-project"
                      type="checkbox"
                      checked={deleteChildren}
                      autoFocus={false}
                      disabled={true}
                    />
                  )}
                  {item.itemType !== 'Project' && (
                    <input
                      id="delete-building-item-children-checkbox"
                      type="checkbox"
                      checked={deleteChildren}
                      onChange={() => handleOnChange()}
                      autoFocus={false}
                    />
                  )}
                </div>
                <div className="col-span-11 row-span-1">
                  {t('projectTaxonomy.deleteBuildingElementChildrenHint', { count: item.children.length })}
                </div>
                <div className="col-span-11 row-span-1 flex">
                  {item.children.map((child, index) => (
                    <div key={index}>
                      <span className="font-bold">{child.itemName}</span> {child.itemType}
                      {index !== item.children.length - 1 && <span>,&nbsp;</span>}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
          {t('common.cancel')}
        </Button>
        <Button variant="danger" onClick={() => handleDelete(item)} disabled={isLoading}>
          {!deleteChildren && <span>{t('projectTaxonomy.deleteBuildingElementAction')}</span>}
          {deleteChildren && (
            <span>{t('projectTaxonomy.deleteBuildingElementAction', { count: item.children.length })}</span>
          )}
        </Button>
      </Modal.Controls>
    </>
  );
};
