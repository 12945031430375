import {
  ContractTitleReadModel,
  useApiDeleteContractTitleMutation
} from '@client/shared/api';
import { HintBox, ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';

interface ContractTitleDeleteModalProps extends ModalOnCloseProps {
  title: ContractTitleReadModel;
}

export const ContractTitleDeleteModal = ({ title, onClose }: ContractTitleDeleteModalProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const [deleteContractTitle, { isLoading }] = useApiDeleteContractTitleMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    try {
      await safeMutation(
        deleteContractTitle,
        {
          contractId: title.contractId,
          contractTitleId: title.id,
          projectId: loadedProjectId ?? '',
          calculationModelId: loadedVariantId ?? ''
        },
        isLoading
      );
      onClose(true);
    } catch (e) {
      console.error(e)
    }
  };

  return (
    <ConfirmModal
      title={t('projectContract.deleteContractTitle')}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >      
      {isLoading && <LoadingIndicator text={t('projectContract.deleteContractTitleLoading') ?? ''} mode="overlay" />}
      <HintBox hintType="warning">{t('projectContract.deleteContractTitleHint')}</HintBox>
    </ConfirmModal>
  );
};
