import { DecoratedElement } from '../hooks';

export const filterDecoratedElementsByLevel = <T>(
  level: number,
  decoratedElements: DecoratedElement<T>[]
): string[] => {
  let ids: string[] = [];
  if (level === 2) {
    ids = decoratedElements.filter((x) => x.level === 0).map((x) => x.categoryId);
  } else if (level === 3) {
    ids = decoratedElements.filter((x) => x.level <= 2).map((x) => x.categoryId);
  }

  return ids;
};
