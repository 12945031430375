import { PlotReadModel } from '@client/shared/api';
import { PlanCostsIcon } from '@client/shared/toolkit';
import { formatUnit } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

interface BriCardProps {
  plot?: PlotReadModel | undefined;
}

export const BriCard = ({ plot }: BriCardProps) => {
  const { t } = useTranslation();

  return (
    <div className="rounded shadow p-4 w-full h-full flex flex-row bg-white ">
      <div className="w-full flex flex-row">
        <div className="flex justify-center items-center mr-4">
          <PlanCostsIcon className="h-8 w-8" />
        </div>
        <div className="w-full">
          <div className="text-xxs font-bold text-slate-500">{t('projectTaxonomy.bri')}</div>
          <div className="h-7 text-base font-extrabold">
            {plot?.grossVolume && formatUnit(plot?.grossVolume.value, plot?.grossVolume.unit)}
          </div>
        </div>
      </div>
      {/* <div className="w-2/6 text-right justify-end">
        <div className="text-xxs font-bold text-slate-500">{t('projectTaxonomy.dashboardDeviation')}</div>
        <div className="h-7 text-xs font-extrabold flex items-end justify-end">-</div>
        <div className="text-xxs text-slate-500">
          <span className="text-green-400">-</span>
        </div>
      </div> */}
    </div>
  );
};
