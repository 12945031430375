import {
  BaseSelect,
  Button,
  CheckBox,
  Form,
  FormField,
  FormRefHandle,
  FormWatch,
  LoadingIndicator,
  NumberInput,
  SearchInput,
  SlideOver,
  SlideOverList,
  SlideOverOnCloseProps,
  FuturesIcon,
  GoogleHomeIcon,
  ElectricityIcon,
  StatesIcon,
  GasIndustryIcon,
  AddButton,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { InferType } from 'yup';
import { RestructuredCunsumptionElement, ReconstructedConsumptionUsage } from './';
import { formatUnit } from '@client/shared/utilities';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';
import {
  RegionEmissionFactorsReadModel,
  TaxonomyReadModel,
  UtilityType,
  useApiGetTaxonomyQuery,
  TaxonomyType,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { FlattenedTaxonomyItem, flattenTaxonomyTree, getTaxonomyIcon } from '@client/project/shared';
import { TrashIcon } from '@heroicons/react/24/outline';

export const RegulatoryElementUtilityFormValidationSchema = yup.object({
  utilityType: yup.string().required('validation.required'),
  propertyType: yup.string().required('validation.required'),
});

type RegulatoryElementUtilityFormValidationValues = InferType<typeof RegulatoryElementUtilityFormValidationSchema>;

interface RegulatoryElementUtilitySlideOverProps extends SlideOverOnCloseProps {
  element?: RestructuredCunsumptionElement;
  index: number;
  disabled: boolean;
  addElement: (element: RestructuredCunsumptionElement) => void;
  updateElement: (element: RestructuredCunsumptionElement, index: number) => void;
  removeElement: (index: number) => void;
  regionEmissionFactors?: RegionEmissionFactorsReadModel;
}

export const RegulatoryElementUtilitySlideOver = ({
  element,
  index,
  disabled,
  addElement,
  updateElement,
  removeElement,
  regionEmissionFactors,
  onClose,
}: RegulatoryElementUtilitySlideOverProps) => {
  const { t } = useTranslation();

  const isEditing = element != null;

  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<FormRefHandle<RegulatoryElementUtilityFormValidationValues>>();
  const [consumptionUsages, setConsumptionUsages] = useState<ReconstructedConsumptionUsage[]>([]);
  const [taxonomyElements, setTaxonomyElements] = useState<TaxonomyReadModel[]>([]);
  const [taxonomyTree, setTaxonomyTree] = useState<FlattenedTaxonomyItem<TaxonomyReadModel>[]>([]);
  const [searchText, setSearchText] = useState('');
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'top-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['right-start', 'bottom'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: buildingsData, isSuccess, isFetching } = useApiGetTaxonomyQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: loadedVariantId == null },
  );

  useEffect(() => {
    if (element) {
      setConsumptionUsages(JSON.parse(JSON.stringify(element.consumptionUsages)));
    } else {
      setConsumptionUsages([
        {
          year: null,
          usage: {
            value: null,
            unit: utilityTypes.find((item) => item.value === formRef.current?.getValues().utilityType)?.unit ?? '',
          },
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  useEffect(() => {
    if (isSuccess && buildingsData) {
      setTaxonomyTree(flattenTaxonomyTree(buildingsData));
    }
  }, [buildingsData, isSuccess]);

  useEffect(() => {
    if (taxonomyTree.length > 0 && element && element.taxonomies.length > 0) {
      const elements = taxonomyTree.filter((item) => element.taxonomies.includes(item.taxonomyItem.itemId));

      setTaxonomyElements(elements.map((item) => item.taxonomyItem));
    }
  }, [taxonomyTree, element]);

  const handleSubmit = async (data: RegulatoryElementUtilityFormValidationValues) => {
    if (data) {
      if (element) {
        updateElement(
          {
            ...element,
            utilityType: data.utilityType as UtilityType,
            propertyType: {
              propertyType: data.propertyType,
            },
            consumptionUsages: consumptionUsages,
            taxonomies: taxonomyElements.map((item) => item.itemId),
          },
          index,
        );
      } else {
        addElement({
          consumption: {
            value: null,
            unit: utilityTypes.find((item) => item.value === formRef.current?.getValues().utilityType)?.unit ?? '',
          },
          utilityType: data.utilityType as UtilityType,
          propertyType: {
            propertyType: data.propertyType,
          },
          consumptionUsages: consumptionUsages,
          taxonomies: taxonomyElements.map((item) => item.itemId),
        });
      }
      onClose(false);
    }
  };

  const checkForDuplicateAndInvalidYears = () => {
    const years = consumptionUsages.map((usage) => usage.year);
    const uniqueYears = [...new Set(years)];
    const hasDuplicateYears = years.length !== uniqueYears.length || years.includes(null);
    const hasInvalidYears = years.filter((year) => year?.toString().length !== 4).length > 0;
    return hasDuplicateYears || hasInvalidYears;
  };

  const utilityTypes: {
    unit: string;
    value: UtilityType;
    label: string;
    icon: React.ReactNode;
  }[] = [
    {
      unit: 'kWh',
      value: 'Electricity',
      label: t('projectCalculate.localLaw.utilityElectricity'),
      icon: <ElectricityIcon className="w-8 h-8" />,
    },
    {
      unit: 'ft³',
      value: 'NaturalGas',
      label: t('projectCalculate.localLaw.utilityGas'),
      icon: <GasIndustryIcon className="w-8 h-8" />,
    },
    {
      unit: 'gal',
      value: 'Propane',
      label: t('projectCalculate.localLaw.utilityPropane'),
      icon: <GasIndustryIcon className="w-8 h-8" />,
    },
    {
      unit: 'gal',
      value: 'FuelOilNo2',
      label: t('projectCalculate.localLaw.utilityOilNo2'),
      icon: <GasIndustryIcon className="w-8 h-8" />,
    },
    {
      unit: 'gal',
      value: 'FuelOilNo4',
      label: t('projectCalculate.localLaw.utilityOilNo4'),
      icon: <GasIndustryIcon className="w-8 h-8" />,
    },
    {
      unit: 'lb',
      value: 'DistrictSteam',
      label: t('projectCalculate.localLaw.utilitySteam'),
      icon: <GasIndustryIcon className="w-8 h-8" />,
    },
  ];

  const defaultValues = {
    utilityType: element?.utilityType ?? '',
    propertyType: element?.propertyType.propertyType ?? '',
  };

  const getBuildingTypeIcon = useCallback((type: TaxonomyType) => {
    const IconComponent = getTaxonomyIcon(type);
    return <IconComponent className="w-8 h-8 opacity-80" />;
  }, []);

  return (
    <>
      <SlideOver.Header
        onClose={onClose}
        title={
          utilityTypes.find((utilityType) => utilityType.value === formRef.current?.getValues().utilityType)?.label ??
          t('projectCalculate.localLaw.newElement')
        }
        subTitle={t('projectCalculate.localLaw.utilityElement')}
        backgroundClassName="bg-gray-700"
      />
      <Form<RegulatoryElementUtilityFormValidationValues>
        defaultValues={defaultValues}
        ref={formRef}
        className="w-full flex flex-col flex-grow min-h-0"
        onSubmit={handleSubmit}
        validationSchema={RegulatoryElementUtilityFormValidationSchema}
      >
        <SlideOver.Content className="p-8 overflow-y-scroll">
          <div className="flex flex-grow flex-col">
            <div className="mb-5">
              <FormField name="utilityType">
                {(control) => (
                  <BaseSelect
                    label={t('projectCalculate.localLaw.utilityType')}
                    icon={<StatesIcon />}
                    options={utilityTypes.map((item) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                    {...control}
                  />
                )}
              </FormField>
              <FormWatch<RegulatoryElementUtilityFormValidationValues> fieldNames={['utilityType']}>
                {({ utilityType }) => (
                  <div className="mt-8">
                    {utilityType !== '' && (
                      <>
                        {consumptionUsages.map((item: ReconstructedConsumptionUsage, index: number) => (
                          <div key={index} className="w-full flex items-center bg-white">
                            <NumberInput
                              label={t('projectCalculate.localLaw.year')}
                              value={item.year}
                              maxLength={4}
                              onChange={(value) => {
                                const newConsumptionUsages = [...consumptionUsages];
                                newConsumptionUsages[index].year = value;
                                setConsumptionUsages(newConsumptionUsages);
                              }}
                              icon={<FuturesIcon />}
                              disabled={disabled}
                              formatGroup={false}
                              allowNegative={false}
                              showValidation={
                                !(consumptionUsages.filter((usage) => usage.year === item.year).length < 2) ||
                                (item.year?.toString().length !== 4 && item.year !== null)
                              }
                              isValidationValid={
                                consumptionUsages.filter((usage) => usage.year === item.year).length < 2 &&
                                (item.year?.toString().length === 4 || item.year === null)
                              }
                            />

                            <NumberInput
                              label={`${t('projectCalculate.localLaw.consumption')} (${
                                utilityTypes.find((item) => item.value === utilityType)?.unit ?? ' '
                              })`}
                              value={item.usage.value}
                              onChange={(value) => {
                                const newConsumptionUsages = [...consumptionUsages];
                                newConsumptionUsages[index].usage.value = value;
                                setConsumptionUsages(newConsumptionUsages);
                              }}
                              disabled={disabled}
                            />
                            <div className="h-6 w-6 mr-2 flex justify-center">
                              {consumptionUsages.length === 1 && consumptionUsages[0].year === null ? undefined : (
                                <TrashIcon
                                  className="h-6 w-6 hover:opacity-60 transition-transform duration-200 cursor-pointer"
                                  onClick={() => {
                                    const newConsumptionUsages = [...consumptionUsages];
                                    newConsumptionUsages.splice(index, 1);
                                    if (newConsumptionUsages.length === 0) {
                                      newConsumptionUsages.push({
                                        year: null,
                                        usage: {
                                          value: null,
                                          unit: utilityTypes.find((item) => item.value === utilityType)?.unit ?? '',
                                        },
                                      });
                                    }
                                    setConsumptionUsages(newConsumptionUsages);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        ))}
                        {!disabled && (
                          <div className="flex w-full justify-end items-center z-50 relative">
                            <AddButton
                              onClick={() =>
                                setConsumptionUsages([
                                  ...consumptionUsages,
                                  {
                                    year: null,
                                    usage: {
                                      value: null,
                                      unit: 'kWh',
                                    },
                                  },
                                ])
                              }
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </FormWatch>
              <div className="mt-6">
                <FormField name="propertyType">
                  {(control) => (
                    <BaseSelect
                      label={t('projectCalculate.localLaw.propertyType')}
                      icon={<GoogleHomeIcon />}
                      options={
                        regionEmissionFactors?.regionEmissionFactors.map((item) => ({
                          value: item.propertyType,
                          label: t('projectCalculate.localLaw.property' + item.propertyType),
                        })) ?? []
                      }
                      {...control}
                      disabled={disabled}
                    />
                  )}
                </FormField>
              </div>
            </div>
            {(!isSuccess || isFetching) ? (
              <LoadingIndicator text={t('projectCalculate.localLaw.loadingTaxonomyElements')} />
            ) : (
              <SlideOverList
                items={taxonomyElements.map((item) => ({
                  id: item.itemId,
                  leftTop: item.itemType,
                  leftCenter: item.itemName,
                  rightCenter: formatUnit(item.sizes.rentalSpace.value, item.sizes.rentalSpace.unit),
                  icon: getBuildingTypeIcon(item.itemType),
                  optionalNode: (
                    <TrashIcon
                      className="h-6 mr-2 hover:opacity-60 transition-transform duration-200 cursor-pointer"
                      onClick={() => {
                        const newTaxonomyElements = [...taxonomyElements];
                        newTaxonomyElements.splice(
                          newTaxonomyElements.findIndex((element) => element.itemId === item.itemId),
                          1,
                        );
                        setTaxonomyElements(newTaxonomyElements);
                      }}
                    />
                  ),
                }))}
                noItemsMessage={t('projectCalculate.localLaw.assignTaxonomyElements')}
                clickable={false}
                disabled={disabled}
                customAddButton={
                  !disabled && (
                    <Popover className="h-full w-full">
                      <>
                        <div ref={setTargetElement} className="w-full h-full">
                          <PopoverButton className="flex w-full justify-end items-center z-50 outline-0">
                            <div className="-mt-4">
                              <AddButton />
                            </div>
                          </PopoverButton>
                        </div>
                        <PopoverPanel
                          portal
                          ref={setPopperElement}
                          style={{ ...styles.popper }}
                          {...attributes.popper}
                          className="z-20 bg-white outline-none shadow h-80 w-96 overflow-y-auto"
                        >
                          {
                            <ul className="flex flex-col">
                              <SearchInput
                                value={searchText}
                                handleSearch={setSearchText}
                                className=" text-gray-400 bg-gray-100 m-2"
                                bgColor="bg-gray-100"
                              />
                              {taxonomyTree
                                .filter((item) =>
                                  item.taxonomyItem.itemName.toLowerCase().includes(searchText?.toLowerCase() ?? ''),
                                )
                                .map((item) => (
                                  <li key={item.taxonomyItem.itemId}>
                                    <div
                                      className={classNames(
                                        'w-full flex flex-row items-center justify-between px-4 py-2 text-slate-500 hover:opacity-70 cursor-pointer',
                                        {
                                          'bg-slate-100': item.depth === 0,
                                          'bg-slate-50': item.depth === 1,
                                          'bg-white text-zinc-600': item.depth > 1,
                                        },
                                      )}
                                      onClick={() => {
                                        const newTaxonomyElements = [...taxonomyElements];
                                        if (
                                          newTaxonomyElements.find(
                                            (element) => element.itemId === item.taxonomyItem.itemId,
                                          )
                                        ) {
                                          newTaxonomyElements.splice(
                                            newTaxonomyElements.findIndex(
                                              (element) => element.itemId === item.taxonomyItem.itemId,
                                            ),
                                            1,
                                          );
                                          setTaxonomyElements(newTaxonomyElements);
                                        } else {
                                          newTaxonomyElements.push(item.taxonomyItem);
                                          setTaxonomyElements(newTaxonomyElements);
                                        }
                                      }}
                                    >
                                      <div className="flex">
                                        <div style={{ paddingRight: 3 * item.depth + 'px' }} />
                                        <div className="pr-4">{getBuildingTypeIcon(item.taxonomyItem.itemType)}</div>
                                        <div className="flex flex-col">
                                          <div className="text-medium font-medium text-gray-900">
                                            {item.taxonomyItem.itemName}
                                          </div>
                                          <div className="text-xs font-medium text-gray-700">
                                            {item.taxonomyItem.itemType}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex">
                                        <div className="font-xs font-medium text-gray-700 pr-4">
                                          {formatUnit(
                                            item.taxonomyItem.sizes.rentalSpace.value,
                                            item.taxonomyItem.sizes.rentalSpace.unit,
                                          )}
                                        </div>

                                        <CheckBox
                                          checked={
                                            !!taxonomyElements.find(
                                              (element) => element.itemId === item.taxonomyItem.itemId,
                                            )
                                          }
                                          onChange={() => {
                                            const newTaxonomyElements = [...taxonomyElements];
                                            if (
                                              newTaxonomyElements.find(
                                                (element) => element.itemId === item.taxonomyItem.itemId,
                                              )
                                            ) {
                                              newTaxonomyElements.splice(
                                                newTaxonomyElements.findIndex(
                                                  (element) => element.itemId === item.taxonomyItem.itemId,
                                                ),
                                                1,
                                              );
                                              setTaxonomyElements(newTaxonomyElements);
                                            } else {
                                              newTaxonomyElements.push(item.taxonomyItem);
                                              setTaxonomyElements(newTaxonomyElements);
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          }
                        </PopoverPanel>
                      </>
                    </Popover>
                  )
                }
              />
            )}
          </div>
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className={classNames('w-full flex justify-end', { 'justify-between': isEditing && !disabled })}>
            {isEditing && !disabled && (
              <Button
                variant="warning"
                onClick={() => {
                  removeElement(index);
                  onClose(false);
                }}
              >
                {t('common.delete')}
              </Button>
            )}

            <div className="flex">
              <Button
                variant="secondary"
                className="mr-2"
                onClick={() => {
                  onClose(false);
                }}
              >
                {t('common.close')}
              </Button>
              <Button
                variant="primary"
                className="mr-2"
                formSubmit={true}
                innerRef={submitRef}
                disabled={
                  disabled ||
                  taxonomyElements.length === 0 ||
                  consumptionUsages.length === 0 ||
                  checkForDuplicateAndInvalidYears()
                }
              >
                {t(isEditing ? 'common.update' : 'common.add')}
              </Button>
            </div>
          </div>
        </SlideOver.Controls>
      </Form>
    </>
  );
};
