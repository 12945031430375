import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  Filler,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { EmissionPeriod, useApiGetEmissionYearlyDistributionsQuery } from '@client/shared/api';
import { formatNumber } from '@client/shared/utilities';
import { DecoratedCard, ListTitle, LoadingIndicator } from '@client/shared/toolkit';
import { FormattedCurrency } from '@client/project/shared';

export type CarbonEmissionsChartProps = {
  emissions?: number[]
  thresholds?: number[]
  penalties?: number[]
  excessEmissions?: number[]
}

export const CarbonEmissionsChart = (props: CarbonEmissionsChartProps) => {
  ChartJS.register(
    Filler,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    Title
  );
  /*
  const {
    //emissions = [14200, 8918, 8918, 8918, 8918], // Emissions (tC02e/yr)
    thresholds = [18135, 6566, 2089, 1540, 0], // Emissions Threshold (tCO2e/yr)
    // excessEmissions = [0, 2325, 4828, 7377, 8918],
    penalties = [0, 630367, 1293696, 1977102, 2389953]
  } = props */
  const projectId = useLoadedProjectId();
  const calculationModelId = useLoadedVariantId();

  const { t} = useTranslation()

  const { data: emissionsData, isFetching } = useApiGetEmissionYearlyDistributionsQuery(
    {
      projectId: projectId ?? '',
      calculationModelId: calculationModelId ?? ''
    }  ,
    { skip: !projectId || !calculationModelId }
  )

  const [periods, setPeriods] = useState<EmissionPeriod[]>([])
  const [emissions, setEmissions] = useState<number[]>([])
  const [thresholds, setThresholds] = useState<number[]>([])
  const [penalties, setPenalties] = useState<number[]>([])

  useEffect(() => {
    if (emissionsData) {
      const emissionPeriods = emissionsData.emissionYearlyDistributions.emissionPeriods
      setPeriods(emissionPeriods)

      const periodLabels: string[] = []
      const periodLineLabels: string[] = []
      const periodEmissions: number[] = []
      const periodThresholds: number[] = []
      const periodPenalties: number[] = []
      emissionPeriods.forEach((period) => {
        const periodLabel = `${period.startYear} - ${period.endYear > 2050 ? '' : period.endYear}`
        periodLabels.push(periodLabel)
        periodLineLabels.push(periodLabel)
        periodEmissions.push(period.emissionPerYearOverview.emissionPerYear.value ?? 0)
        periodThresholds.push(period.emissionPerYearOverview.emissionThreshold.value ?? 0)
        periodPenalties.push(period.emissionPerYearOverview.penaltyCost.value ?? 0)
      })
      periodLineLabels.push('0')
      setLabels(periodLabels)
      setLineLabels(periodLineLabels)
      setEmissions(periodEmissions)
      setThresholds(periodThresholds)
      setPenalties(periodPenalties)
    }
  }, [emissionsData]);

  const [labels, setLabels] = useState<string[]>([]);
  // ['2024 - 2029', '2030 - 2034', '2035 - 2039', '2040 - 2049', '2050 -']
  // we need to fake one more column, as offset not working with the line
  const [lineLabels, setLineLabels] = useState<string[]>(['2024-2029', '20230-2034', '2035-2039', '2040-2049', '2050-', '0']);

  // we add one the last threshold to make the length of the line the same as the bars
  const carbonThresholds = useMemo(() => {
    return [...thresholds, thresholds[thresholds.length - 1]]
  }, [thresholds])

  // Emissions (tC02e/yr) <= Threshold
  const carbonBelowThreshold = useMemo(() => {
    const carbonBelow: number[] = []
    emissions.forEach((emission, i) => {
      if (emission <= thresholds[i]) {
        carbonBelow.push(emission)
      } else {
        carbonBelow.push(thresholds[i])
      }
    })
    return carbonBelow
  }, [emissions, thresholds])

  // Emissions (tC02e/yr) > Threshold
  const carbonAboveThreshold = useMemo(() => {
    const carbonAbove: number[] = []
    emissions.forEach((emission, i) => {
      if (emission <= thresholds[i]) {
        carbonAbove.push(0)
      } else {
        carbonAbove.push(emission - thresholds[i])
      }
    })
    return carbonAbove
  }, [emissions, thresholds])

  // we could add additional info here to the tooltip
  /* const footer = (tooltipItems: any) => {
    let sum = 0;

    tooltipItems.forEach(function(tooltipItem: any) {
      sum += tooltipItem.parsed.y;
    });
    return 'Sum: ' + sum;
  }; */

  const options = useMemo(() => {
    return {
      plugins: {
        title: {
          display: true,
          text: t('projectTaxonomy.annualCarbonThresholdSummaryTitle')
        },
        /* tooltip: {
          callbacks: {
            footer: footer,
          }
        }*/
      },
      responsive: true,
      interaction: {
        mode: 'index' as const,
        intersect: true,
      },
      scales: {
        // offset not working with the line, so we create a fake x axis
        x2: {
          beginAtZero: true,
          offset: false,
          stacked: true,
          display: false,
          labels: lineLabels
        },
        x: {
          beginAtZero: true,
          offset: true,
          type: 'category' as const,
          stacked: true
        },
        y: {
          stacked: true,
          beginAtZero: true,
        }
      },
      layout: {
        padding: {
          top: 50,
          left: 50,
          bottom: 0
        }
      }
    }
  }, [lineLabels, t]);

  const data = useMemo(() => {
    return {
      labels,
      datasets: [
        {
          label: t('projectTaxonomy.emissionsThreshold'),
          data: carbonThresholds,
          backgroundColor: 'transparent',
          stack: '1',
          fill: false,
          borderWidth: 3,
          borderColor: '#EF4444',
          pointStyle: false as const, // hides the dots
          // stepped: true, // 'middle' as const // makes the lines like steps
          type: 'line' as const,
          stepped: 'before' as const,
          categoryPercentage: 1,
          barPercentage: 1,
          xAxisID: 'x2'
        },
        {
          label: t('projectTaxonomy.CarbonBelowThreshold'),
          data: carbonBelowThreshold,
          backgroundColor: 'rgb(178, 219, 182)',
          stack: '1',
          fill: true,
          stepped: true,
          pointStyle: false as const,
          type: 'bar' as const,
          categoryPercentage: 1,
          barPercentage: 0.99,
          xAxisID: 'x'
        },
        {
          label: t('projectTaxonomy.CarbonOverThreshold'),
          data: carbonAboveThreshold, // emissions
          backgroundColor: 'rgb(226, 232, 240)',
          stack: '1',
          fill: true,
          stepped: true,
          pointStyle: false as const,
          type: 'bar' as const,
          categoryPercentage: 1,
          barPercentage: 0.99,
          xAxisID: 'x',
        },
        /* {
          label: t('projectTaxonomy.emissions'),
          data: emissions,
          backgroundColor: 'transparent',
          stack: '1',
          fill: true,
          stepped: true,
          pointStyle: false as const,
          type: 'bar' as const,
          categoryPercentage: 1,
          barPercentage: 1
        } */
      ],
    }
  }, [labels, carbonThresholds, carbonAboveThreshold, t, carbonBelowThreshold]);

  if (!periods.length) return null

  return (
    <DecoratedCard>
      <DecoratedCard.Content>
        {isFetching && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
        <ListTitle
          title={t('projectTaxonomy.annualCarbonThresholdSummaryTitle')}
          color="bg-cyan-700"
        />
        <div className="mb-10 bg-white pt-5 pl-6 pr-4">
          {/* TODO tidy up! */}
          <div className="w-full flex">
            <div className="w-[12%] max-w-[85px] flex-none border-b"></div>
            <div className="grid grid-cols-5 flex-1 divide-x">
              {labels.map((label) => (
                <div
                  key={`carbon-emissions-chart-label-${label}`}
                  className="grid-cols-1 text-center border-b font-bold p-3"
                >
                  {label}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full flex">
            <div className="w-[12%] max-w-[85px] flex-none flex flex-col gap-1 leading-none text-right p-2 border-b">
              <span className="text-[13px] truncate font-bold">{t('projectTaxonomy.emissions')}</span>
              <span className="text-[10px]">(tC02e/yr)</span>
            </div>
            <div className="grid grid-cols-5 flex-1 divide-x">
              {emissions.map((emission, i) => (
                <div
                  key={`carbon-emissions-chart-emission-${i}`}
                  className="grid-cols-1 text-center border-b p-2"
                >
                  {formatNumber(emission)}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full flex">
            <div className="w-[12%] max-w-[85px] flex-none flex flex-col gap-1 leading-none text-right p-2 border-b">
              <span className="text-[13px] truncate font-bold">{t('projectTaxonomy.emissionsThreshold')}</span>
              <span className="text-[10px]">(tC02e/yr)</span>
            </div>
            <div className="grid grid-cols-5 flex-1 divide-x">
              {thresholds.map((threshold, i) => (
                <div
                  key={`carbon-emissions-chart-threshold-${i}`}
                  className="grid-cols-1 text-center border-b p-2"
                >
                  {formatNumber(threshold)}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full flex">
            <div className="w-[12%] max-w-[85px] flex-none flex flex-col gap-1 leading-none text-right p-2">
              <span className="text-[13px] truncate font-bold">{t('projectTaxonomy.emissionsEstimatedPenalty')}</span>
              <span className="text-[10px]">(tC02e/yr)</span>
            </div>
            <div className="grid grid-cols-5 flex-1 divide-x">
              {penalties.map((penalty, i) => (
                <div
                  key={`carbon-emissions-chart-penalty-${i}`}
                  className="grid-cols-1 text-center p-2 font-medium"
                >
                  <FormattedCurrency amount={penalty} />
                </div>
              ))}
            </div>
          </div>
          {periods.length > 0 && (
            <Chart
              options={options}
              type="line"
              data={data}
            />
          )}
        </div>
      </DecoratedCard.Content>
    </DecoratedCard>
  )
}
