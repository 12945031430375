import { getLanguageAndLocale } from './i18n';

/**
 * This function returns an object with the position of the day, month, and year in a date format string.
 * @param locale The locale to use. Defaults to the user's browser locale.
 * @returns An object with the position of the day, month, and year in a date format string.
 */
export const getLocaleDateFormat = (locale: string = getLanguageAndLocale().locale) => {
  const date = new Date(1988, 2, 29);

  const dateOrder = Intl.DateTimeFormat(locale)
    .formatToParts(date)
    .filter((part) => part.type !== 'literal')
    .map((part) => part.type);

  return {
    year: dateOrder.indexOf('year'),
    month: dateOrder.indexOf('month'),
    day: dateOrder.indexOf('day'),
  };
};
