import {
  Button,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
  SlideOverTabOptions,
  SlideOverWithTabs,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import {
  DocuWareConditionPayload,
  DocuWareProjectMappingPayload,
  useApiGetDocuWareAccessQuery,
  useApiPostUpdateDocuWareAccessMutation,
  useApiRemoveApiAccessMutation,
} from '@client/shared/api';
import { DocuwareClientSlideOverMasterDataTab } from './DocuwareClientSlideOverMasterDataTab';
import { DocuwareClientSlideOverConditionsTab } from './DocuwareClientSlideOverConditionsTab';
import { DocuwareClientSlideOverProjectMappingsTab } from './DocuwareClientSlideOverProjectMappingsTab';
import { DocuwareClientDeleteModal } from '.';
import { useEffect, useMemo, useState } from 'react';
import { safeMutation } from '@client/shared/utilities';

interface DocuwareClientSlideOverProps extends SlideOverOnCloseProps {
  apiId: string;
  apiAccessId: string;
  isOpen: boolean;
}

export const DocuwareClientSlideOver = ({ isOpen, onClose, apiAccessId, apiId }: DocuwareClientSlideOverProps) => {
  const { t } = useTranslation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // const [currentTab, setCurrentTab] = useState(0);

  const [updateDocuWare, { isLoading: isUpdating }] = useApiPostUpdateDocuWareAccessMutation();
  const [removeAcessItem, { isLoading: isRemoving, isSuccess: wasRemoved }] = useApiRemoveApiAccessMutation();

  const { data, isFetching } = useApiGetDocuWareAccessQuery(
    {
      accessId: apiAccessId,
    },
    {
      skip: !apiAccessId || isRemoving || wasRemoved,
    },
  );

  const [conditionsPayload, setConditionsPayload] = useState<DocuWareConditionPayload[]>([]);
  const [projectMappingsPayload, setProjectMappingsPayload] = useState<DocuWareProjectMappingPayload[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);

  useEffect(() => {
    setConditionsPayload(data?.docuWareAccessConfiguration?.conditions as DocuWareConditionPayload[] ?? []);
    setProjectMappingsPayload(data?.docuWareAccessConfiguration?.projectMappings as DocuWareProjectMappingPayload[] ?? []);
    setSelectedGroupId(data?.docuWareAccessConfiguration?.importNotificationGroupId ?? null);
  }, [data]);

  const tabOptions: SlideOverTabOptions[] = [
    {
      header: t('app.settingsApiDocuwareMasterData'),
      name: 'masterData',
      panel: (
        <div className="w-full h-full">
          {isFetching ? (
            <LoadingIndicator text={t('app.settingsApiDocuwareLoadingClient') ?? ''} mode="overlay" />
          ) : (
            <DocuwareClientSlideOverMasterDataTab readModel={data} updateGroupId={setSelectedGroupId} />
          )}
        </div>
      ),
    },

    {
      header: t('app.settingsApiDocuwareConditions'),
      name: 'connections',
      panel: (
        <DocuwareClientSlideOverConditionsTab
          apiId={apiId}
          apiAccessId={apiAccessId}
          readModel={data}
          updateConditions={(payload) => setConditionsPayload(payload)}
        />
      ),
    },
    {
      header: t('app.settingsApiDocuwareProjectMappings'),
      name: 'projects',
      panel: (
        <DocuwareClientSlideOverProjectMappingsTab
          readModel={data}
          updateProjectMappings={(payload) => setProjectMappingsPayload(payload)}
        />
      ),
    },
  ];

  const handleDelete = async () => {
    try {
      await safeMutation(
        removeAcessItem,
        {
          apiAccessId: apiAccessId,
        },
        isRemoving,
      );

      onClose(false);
    } catch (e) {
      console.log(e);
    }
    setIsDeleting(true);
  };

  const onUpdate = async () => {
    try {
      await safeMutation(
        updateDocuWare,
        {
          apiId: apiId,
          accessId: apiAccessId,
          body: {
            conditions: conditionsPayload,
            projectMappings: projectMappingsPayload,
            importNotificationGroupId: selectedGroupId
          },
        },
        isUpdating,
      );
    } catch (e) {
      console.log(e);
    }
  };

  const disableUpdate = useMemo(() => {
    return !(
      projectMappingsPayload.every((mapping) => mapping.probisProjectId !== '' && mapping.docuWareProject !== '') &&
      conditionsPayload.every((condition) => condition.label !== '' && condition.value !== '')
    );
  }, [conditionsPayload, projectMappingsPayload]);

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} variant="2xl">
      {isUpdating && <LoadingIndicator text={t('common.updating')} mode="overlay" />}
      <SlideOverWithTabs
        tabOptions={tabOptions}
        title={t('app.settingsApiDocuwareConnection')}
        onClose={onClose}
        // onChange={(tab: number) => setCurrentTab(tab)}
      >
        <SlideOver.Controls className="w-full flex justify-between">
          <div className="flex ">
            <Button
              variant="danger"
              className="mr-2"
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
            >
              {t('common.delete')}
            </Button>
          </div>
          <div className="flex">
            <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
              {t('common.close')}
            </Button>

            <Button variant="primary" onClick={onUpdate} disabled={disableUpdate}>
              {t('common.update')}
            </Button>

          </div>
        </SlideOver.Controls>
      </SlideOverWithTabs>
      <DocuwareClientDeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        handleDelete={handleDelete}
        clientName={data?.userName ?? ''}
        isDeleting={isDeleting}
      />
    </SlideOver>
  );
};
