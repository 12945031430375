import { ContextMenu, ContextMenuItem } from '../ContextMenu';
import { Button } from '../Button';
import React from 'react';
import cn from 'classnames';
import { PlusIcon } from '../../icons';

interface FloatingActionButtonProps {
  onClick?: () => void;
  icon?: React.ReactNode;
  menuItems?: ContextMenuItem[];
  position?: string
}

export const FloatingActionButton = ({ icon, menuItems = [], onClick, position = 'fixed right-10 bottom-10'}: FloatingActionButtonProps) => (
  <div className={cn(position, 'z-10')}>
    <div onClick={onClick}>
      {menuItems.length > 0 ? (
        <ContextMenu
          items={menuItems}
          button={
            <Button variant="primary" className="aspect-square shadow-lg h-14 w-14 flex items-center justify-center">
              {icon ?? <PlusIcon className="w-4 h-4 text-white" />}
            </Button>
          }
        />
      ) : (
        <Button variant="primary" className="aspect-square shadow-lg h-14 w-14 flex items-center justify-center">
          {icon ?? <PlusIcon className="w-4 h-4 text-white" />}
        </Button>
      )}
    </div>
  </div>
);
