import {
  api,
  LicenseAssigneeReadModel,
  LicenseGrantReadModel,
  useApiPostUpdateLicenseGrantMutation
} from '@client/shared/api';
import { Button, LoadingIndicator, SlideOver, SlideOverOnCloseProps } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useState, useRef } from 'react';
import { LicenseSeatList } from './LicenseSeatList';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface LicenseDetailSlideOverProps extends SlideOverOnCloseProps {
  license: LicenseGrantReadModel;
}

type Subarea = 'Info' | 'Users';

export const LicenseDetailSlideOver = ({ license, onClose }: LicenseDetailSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const [subarea, setSubarea] = useState<Subarea>('Users');
  const [assignedSeats] = useState<LicenseAssigneeReadModel[]>(license.assignees);
  const [addedSeats, setAddedSeats] = useState<LicenseAssigneeReadModel[]>([]);
  const [removedSeats, setRemovedSeats] = useState<LicenseAssigneeReadModel[]>([]);

  const [postUpdate, { isLoading }] = useApiPostUpdateLicenseGrantMutation();
  const dispatch = useDispatch();

  const getAssignedSeats = (): string[] => {
    const excludeRemoved = assignedSeats.filter((x) => !removedSeats.includes(x));
    const includeAdded = excludeRemoved.concat(addedSeats);

    return includeAdded.map((x) => x.tenantUserId);
  };

  const handleSubmit = async () => {
    try {
      await safeMutation(
        postUpdate,
        {
          licenseGrantId: license.id,
          body: {
            assignees: getAssignedSeats(),
          },
        },
        isLoading
      );
      // @ts-expect-error need to check session again
      dispatch(api.endpoints.apiPostCheckSession.initiate());

      onClose(true);
    } catch {
      /* left blank */
    }
  };

  const handleSeatChange = (added: LicenseAssigneeReadModel[], removed: LicenseAssigneeReadModel[]) => {
    setAddedSeats(added);
    setRemovedSeats(removed);
  };

  return (
    <>
      {isLoading && (
        <LoadingIndicator text={t('common.loading')} mode="overlay" />
      )}
      <SlideOver.Header
        title={license.name ?? ''}
        subTitle={t('license.license')}
        backgroundClassName="bg-blue-900"
        onClose={onClose}
      />
      <SlideOver.Content className="p-8">
        <div>
          <div className="flex flex-row flex-wrap pt-2 mb-1">
            {/* <button
              type="button"
              className={classNames('px-4 pb-2 text text-gray-900 block ', {
                'font-bold border-b border-slate-200': subarea === 'Info',
              })}
              onClick={() => setSubarea('Info')}
            >
              <InformationCircleIcon className="w-5 h-5 mr-2 inline-block" />
              {t('#Info')}
            </button> */}
            <button
              type="button"
              className={classNames('px-4 pb-2 text text-gray-900 block ', {
                'font-bold border-b border-slate-200': subarea === 'Users',
              })}
              onClick={() => setSubarea('Users')}
            >
              <UserGroupIcon className="w-5 h-5 mr-2 inline-block" />
              {t('license.seats')}
            </button>
          </div>
          {subarea === 'Users' && (
            <LicenseSeatList
              seats={license.seats}
              present={assignedSeats}
              added={addedSeats}
              removed={removedSeats}
              seatsChanged={handleSeatChange}
            />
          )}
        </div>
      </SlideOver.Content>

      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={handleSubmit} innerRef={submitRef}>
          {t('common.save')}
        </Button>
      </SlideOver.Controls>
    </>
  );
};
