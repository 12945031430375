import { useCallback, useEffect, useRef } from 'react';

interface TitleProps {
  title: string;
  setOldTitleOnUnmount?: boolean;
}

interface TitleResponse {
  title: string;
  setTitle: (newTitle: string) => void;
}

/**
 * useDocumentTitle is a custom hook that can be used to set the document title on any component.
 * @param {string} title The title to be set on the document
 * @param {boolean} setOldTitleOnUnmount If true, the old document title will be restored on unmount
 * @returns {object} The title and a function to set the title
 */
export const useDocumentTitle: (props: TitleProps) => TitleResponse = (
  { setOldTitleOnUnmount, title } = { title: '', setOldTitleOnUnmount: false }
) => {
  const initialTitle = useRef<string>();

  const setTitle = useCallback((title: string) => {
    document.title = `${title} [PROBIS]`;
  }, []);

  useEffect(() => {
    initialTitle.current = document.title;
  }, []);

  useEffect(() => {
    if (title) {
      setTitle(title);
    }
    return () => {
      if (setOldTitleOnUnmount && initialTitle.current) {
        setTitle(initialTitle.current);
      }
    };
  }, [setTitle, title, setOldTitleOnUnmount]);

  return {
    title,
    setTitle,
  };
};
