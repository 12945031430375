import {
  BaseSelect, BaseSelectOption,
  BillCustomIcon,
  BooleanInput,
  Button,
  Form,
  FormField,
  FormRefHandle, LoadingIndicator,
  Modal,
  RenameIcon,
  TextInput,
  Wizard,
  WizardSlideContent,
  WizardSlideHeader,
} from '@client/shared/toolkit';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  IcsNewFormValidationSchema,
  IcsNewFormValidationValues
} from './IcsNewFormValidationSchema';
import React, { useMemo, useRef, useState } from 'react';
import {
  InvoiceCalculationSchemeType,
  useApiPostCreateTenantInvoiceCalculationSchemeMutation,
  useApiPostCreateProjectInvoiceCalculationSchemeMutation
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { IcsType } from '.';

type InvoiceCoverSheetCreateWizardProps = {
  isOpen: boolean;
  projectId?: string;
  onClose: () => void;
  type: IcsType;
};
export const InvoiceCoverSheetCreateWizard = (props: InvoiceCoverSheetCreateWizardProps) => {
  const { isOpen, onClose, type, projectId } = props;
  const { t } = useTranslation();

  const formRef = useRef<FormRefHandle<IcsNewFormValidationValues>>();

  const [currentStep] = useState(0);

  const [createTenantIcs, { isLoading: isCreatingTenantIcs }] =
    useApiPostCreateTenantInvoiceCalculationSchemeMutation();
  const [createProjectIcs, { isLoading: isCreatingProjectIcs }] =
    useApiPostCreateProjectInvoiceCalculationSchemeMutation();

  const handleSubmit = async (data: IcsNewFormValidationValues) => {
    if (type === 'tenant') {
      try {
        await safeMutation(
          createTenantIcs,
          {
            body: {
              isDefault: data.isDefault,              
              name: data.name,
              type: data.type,
            },
          },
          isCreatingTenantIcs,
        );

        onClose();
      } catch (e) {
        console.log(e);
      }
    } else if (projectId) {
      try {
        await safeMutation(
          createProjectIcs,
          {
            projectId: projectId,
            body: {
              isDefault: data.isDefault,
              name: data.name,
              type: data.type,
            },
          },
          isCreatingProjectIcs,
        );

        onClose();
      } catch (e) {
        console.log(e);
      }
    }
  }

  const typeOptions: BaseSelectOption[] = useMemo(() => {
    return [
      {
        label: t('app.settingsIcsTypeCumulated'),
        value: 'Cumulated'
      },
      {
        label: t('app.settingsIcsTypeNotCumulated'),
        value: 'Single'
      },
      {
        label: t('app.settingsIcsTypeAdvancePayment'),
        value: 'AdvancePayment'
      }
    ]
  }, [t])

  const defaultFormValues = {
    isDefault: false,
    name: '',
    type: 'Cumulated' as InvoiceCalculationSchemeType,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {(isCreatingTenantIcs || isCreatingProjectIcs) && (
        <LoadingIndicator text={t('app.settingsLoadingInvoiceCoverSheets')} mode="overlay-window" />
      )}
      <Form<IcsNewFormValidationValues>
        validationSchema={IcsNewFormValidationSchema}
        defaultValues={defaultFormValues}
        className="h-full"
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <Wizard
          currentStep={currentStep}
          className="w-[740px] h-[560px]"
        >
          <Wizard.Slides>
            <Wizard.Slide>
              <WizardSlideHeader icon={<BillCustomIcon />} title={t('app.settingsNewInvoiceCoverSheetWizardTitle')} />
              <WizardSlideContent>
                <FormField name="isDefault">
                  {(control) => (
                    <BooleanInput
                      label={t('app.settingsInvoiceCoverSheetDefaultFlag')}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="name">
                  {(control) =>
                    <TextInput
                      label={t('app.settingsInvoiceCoverSheetName')}
                      icon={<RenameIcon />}
                      {...control}
                    />
                  }
                </FormField>
                <FormField name="type">
                  {(control) => (
                    <BaseSelect
                      label={t('app.settingsInvoiceCoverSheetType')}
                      options={typeOptions}
                      {...control}
                    />
                  )}
                </FormField>
              </WizardSlideContent>
            </Wizard.Slide>
          </Wizard.Slides>
          <Wizard.Navigation>
            {() => (
              <Modal.Controls
                className={classNames({
                  'bg-slate-100': currentStep === 0,
                  'bg-white': currentStep !== 0,
                })}
              >
                {(() => {
                  return (
                    <>
                      <Button variant="text" onClick={onClose}>
                        {t('common.cancel')}
                      </Button>
                      <Button variant="primary" formSubmit>
                        {t('common.create')}
                      </Button>
                    </>
                  )
                })()}
              </Modal.Controls>
            )}
          </Wizard.Navigation>
        </Wizard>
      </Form>
    </Modal>
  );
};
