import { getLanguageAndLocale } from './i18n';

export interface FormatNumberOptions {
  maxDigits?: number;
  minDigits?: number;
  placeHolder?: string;
  signDisplay?: 'auto' | 'always' | 'never' | 'exceptZero';
  locale?: string;
}

/**
 * Formats a number to display in the user's locale.
 * @param amount The number to format.
 * @param options formatting options.
 * @param options.maxDigits The maximum number of digits to display after the decimal point.
 * @param options.minDigits The minimum number of digits to display after the decimal point.
 * @param options.placeHolder The placeholder to use if the amount is null or undefined. Defaults to '-'.
 * @param options.signDisplay The sign display style to use. Defaults to 'auto'.
 * @param options.locale The locale to use. Defaults to the user's browser locale.
 * @returns {string} A string representing the formatted number.
 */
export const formatNumber = (
  amount: number | null | undefined,
  {
    maxDigits = 2,
    minDigits = 0,
    signDisplay = 'auto',
    locale = getLanguageAndLocale().locale,
    placeHolder = '-',
  }: FormatNumberOptions = {}
) => {
  if (amount == null) {
    return placeHolder;
  }

  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: maxDigits,
    minimumFractionDigits: minDigits,
    signDisplay: signDisplay,
  }).format(amount);
};
