import { useTranslation } from 'react-i18next';
import {
  ApiAccessReadModel,
  useApiGetAuthGroupsQuery,
  useApiPostStartDocuWareProcessMutation,
} from '@client/shared/api';
import { BaseSelectOption, Button, ComboSelect } from '@client/shared/toolkit';
import { useEffect, useMemo, useState } from 'react';

interface DocuwareClientSlideOverMasterDataTabProps {
  readModel: ApiAccessReadModel | null | undefined;
  updateGroupId: (selected: string | null) => void;
}

export const DocuwareClientSlideOverMasterDataTab = ({
  readModel,
  updateGroupId,
}: DocuwareClientSlideOverMasterDataTabProps) => {
  const { t } = useTranslation();

  const [docuwareImport] = useApiPostStartDocuWareProcessMutation();
  const { data } = useApiGetAuthGroupsQuery();

  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);

  useEffect(() => {
    setSelectedGroup(readModel?.docuWareAccessConfiguration?.importNotificationGroupId ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readModel?.docuWareAccessConfiguration?.importNotificationGroupId]);


  const options = useMemo(() => {
    return (
      (data?.map((group) => {
        return {
          label: group.name,
          value: group.id,
        };
      }) as BaseSelectOption[]) ?? []
    ).sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  }, [data]);

  const startImport = async () => {
    try {
      await docuwareImport().unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  const updateGroup = (selected: string | null) => {
    updateGroupId(selected);
    setSelectedGroup(selected);
  }

  return (
    <div>
      {readModel ? (
        <div className="text-sm flex flex-col gap-3">
          <div className="flex">
            <span className="w-1/2">{t('app.ConnectApiUrl')}</span>
            <span className="w-1/2 font-bold">{readModel?.apiUrl}</span>
          </div>
          <div className="flex">
            <span className="w-1/2">{t('app.ConnectUserName')}</span>
            <span className="w-1/2 font-bold">{readModel?.userName}</span>
          </div>
          <div className="flex">
            <span className="w-1/2">{t('app.settingsApiDocuwareProjectIdentifier')}</span>
            <span className="w-1/2 font-bold">{readModel?.docuWareAccessConfiguration?.projectIdentifier}</span>
          </div>
          <div className="flex">
            <span className="w-1/2">{t('app.settingsApiDocuwareDialog')}</span>
            <span className="w-1/2 font-bold">{readModel?.docuWareAccessConfiguration?.dialogLabel}</span>
          </div>

          <div className="flex mt-4">
            <span className="w-1/2">Notify users upon import</span>
            <ComboSelect
              className="w-1/2"
              label="Probis Groups"
              value={selectedGroup}
              options={options}
              onChange={updateGroup} />
          </div>
          <Button variant="primary" className="mt-2" onClick={startImport}>
            {t('app.settingsApiDocuwareStartImport')}
          </Button>
        </div>
      ) : (
        t('app.settingsApiDatevNoMasterDataAvailable')
      )}
    </div>
  );
};
