import { t } from "i18next";

export const getInvoiceStateLabel = (titleStatus : string) => {
    switch (titleStatus){
        case 'Pending':
            return t('projectControl.statePending');
        case 'Audited':
            return t('projectControl.stateAudited');
        case 'Approved':
            return t('projectControl.stateApproved');
        case 'Paid':
            return t('projectControl.statePaid');
        default:
            return '';
    }
}
