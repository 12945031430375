import { ContractDocumentReadModel, useApiPostCreateContractDocumentsMutation } from '@client/shared/api';
import { FileInput, Button, DecoratedCard, ListTitle, LoadingIndicator } from '@client/shared/toolkit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractDocumentRow } from './ContractDocumentRow';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';

interface ContractDocumentsProps {
  contractId: string;
  documents: ContractDocumentReadModel[];
  canWrite: boolean;
  canDelete: boolean;
}

export const ContractDocuments = ({ contractId, documents, canWrite, canDelete }: ContractDocumentsProps) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileList | null>(null);
  const [isFileError, setIsFileError] = useState(false);

  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();
  const [postContractDocuments, { isLoading: isLoadingContractDocuments }] = useApiPostCreateContractDocumentsMutation();

  const handleFileChange = (fileList: FileList | null) => {
    setFiles(fileList?.length ? fileList : null);
  };
  const handleSaveDocuments = async () => {
    if (files && files.length > 0) {
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }

      try {
        await safeMutation(
            postContractDocuments,
          {
            projectId: loadedProjectId ?? 'unset',
            calculationModelId: loadedCalculationModelId ?? 'unset',
            contractId: contractId,
            body: formData as unknown as { files: Blob[] },
          },
          isLoadingContractDocuments
        );

        setFiles(null);
      } catch (e) {
        console.log(e, 'error');
      }
    }
  };

  return (
    <>
      {isLoadingContractDocuments && <LoadingIndicator text={t('common.uploading') ?? ''} mode="overlay" />}
      <DecoratedCard shadowVariant="normal">
        <DecoratedCard.Content>
          <ListTitle title={t('projectCalculate.addElementDocument')} />
          <div className="bg-white p-3 rounded-sm">
            <FileInput
              acceptedFileTypes={[]}
              multiple={true}
              selectedFiles={files}
              onChange={(files) => handleFileChange(files)}
              setError={setIsFileError}
            />
          </div>

          <div className="flex justify-end mt-4 p-3">
            <Button variant="primary" onClick={handleSaveDocuments} disabled={files === null || isFileError}>
              {t('common.upload')}
            </Button>
          </div>
        </DecoratedCard.Content>
      </DecoratedCard>

      <DecoratedCard className="mt-4" shadowVariant="normal">
        <DecoratedCard.Content className="mb-2">
          <ListTitle title={t('projectCalculate.elementDocument')} />
          {documents.map((document) => (
            <ContractDocumentRow
              contractId={contractId}
              contractDocument={document}
              key={document.id}
              canDelete={canDelete}
            />
          ))}
          {!documents.length && (
            <div className="flex flex-col items-center justify-center p-5 bg-white text-gray-500">
              {t('projectCalculate.elementDocumentsEmpty')}
            </div>
          )}
        </DecoratedCard.Content>
      </DecoratedCard>
    </>
  );
};
