import { Modal, Button, ModalOnCloseProps } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  ElementUserDefinedFieldDefinitionReadModel,
} from '@client/shared/api';
import {
  SelectUserDefinedFieldsList
} from '.';

interface SelectUserDefinedFieldsModalProps extends ModalOnCloseProps {
  onChange: (fields: ElementUserDefinedFieldDefinitionReadModel[]) => void;
  isReadOnly?: boolean;
  fields: ElementUserDefinedFieldDefinitionReadModel[] | null
}

export const SelectUserDefinedFieldsModal = (props: SelectUserDefinedFieldsModalProps) => {
  const { t } = useTranslation();
  const {
    onClose,
    onChange,
    isReadOnly,
    fields
  } = props

  const [selectedCustomFields, setSelectedCustomFields] = useState<ElementUserDefinedFieldDefinitionReadModel[] | null>(null);

  useEffect(() => {
    if (fields != null) {
      setSelectedCustomFields(fields)
    }
  }, [fields]);

  const handleOnCancel = () => {
    onClose(false);
  };

  const handleSave = async () => {
    if (selectedCustomFields) {
      onChange(selectedCustomFields);
      onClose(true);
    }
  };

  return (
    <>
      <Modal.Header title={t('extensionElements.title')} description={t('extensionElements.description')} />
      <Modal.Content className="pb-3 divide-y">
        <SelectUserDefinedFieldsList
          updateSelectedCustomFields={setSelectedCustomFields}
          selectedCustomFields={selectedCustomFields}
        />
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={handleOnCancel} variant="secondary">
          {t('common.cancel')}
        </Button>
        {!isReadOnly && <Button onClick={handleSave}>{t('common.save')}</Button>}
      </Modal.Controls>
    </>
  );
};
