import {
  ApiDeleteTaxonomyApiArg,
  ApiDeleteTaxonomyApiResponse,
  ApiDeleteTaxonomyItemFileApiArg,
  ApiDeleteTaxonomyItemFileApiResponse,
  ApiGetTaxonomyApiArg,
  ApiGetTaxonomyApiResponse,
  ApiGetTaxonomyWithCostItemsApiArg,
  ApiGetTaxonomyWithCostItemsApiResponse,
  ApiPostCreateTaxonomyApiArg,
  ApiPostCreateTaxonomyApiResponse,
  ApiPostDuplicateTaxonomyApiArg,
  ApiPostDuplicateTaxonomyApiResponse,
  ApiPostInitializeTaxonomyApiArg,
  ApiPostInitializeTaxonomyApiResponse,
  ApiPostMoveTaxonomyApiArg,
  ApiPostMoveTaxonomyApiResponse,
  ApiPostUpdateTaxonomyApiArg,
  ApiPostUpdateTaxonomyApiResponse,
  ApiPostUploadTaxonomyFileApiArg,
  ApiPostUploadTaxonomyFileApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiTaxonomyEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetTaxonomyWithCostItems: {
    providesTags: (
      _result: ApiGetTaxonomyWithCostItemsApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetTaxonomyWithCostItemsApiArg,
    ) => [{ type: ApiTagTypes.TaxonomyCostItems, id: args.projectId }],
  },
  apiGetTaxonomy: {
    providesTags: (result: ApiGetTaxonomyApiResponse, _error: FetchBaseQueryError, args: ApiGetTaxonomyApiArg) => [
      { type: ApiTagTypes.TaxonomyItem, id: result?.itemId },
      ...(result?.children?.map((dto): { type: ApiTagTypes.TaxonomyItem; id: string } => ({
        type: ApiTagTypes.TaxonomyItem,
        id: dto.itemId,
      })) ?? []),
      { type: ApiTagTypes.Taxonomy, id: args.projectId },
    ],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateTaxonomy: {
    invalidatesTags: (
      _result: ApiPostCreateTaxonomyApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateTaxonomyApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Taxonomy, id: args.projectId },
            { type: ApiTagTypes.TaxonomyItem, id: args.body.parentId ?? args.body.childId ?? '' },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Project, id: args.projectId },
          ]
        : [],
  },
  apiDeleteTaxonomy: {
    invalidatesTags: (
      _result: ApiDeleteTaxonomyApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteTaxonomyApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Taxonomy, id: args.projectId },
            { type: ApiTagTypes.TaxonomyItem, id: args.taxonomyItemId },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Project, id: args.projectId },
            // TODO only invalidate the elements that have a reference in formula to the plot
            { type: ApiTagTypes.FormulaElements, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostInitializeTaxonomy: {
    invalidatesTags: (
      _result: ApiPostInitializeTaxonomyApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostInitializeTaxonomyApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId },
          ]
        : [],
  },
  apiPostUpdateTaxonomy: {
    invalidatesTags: (
      _result: ApiPostUpdateTaxonomyApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateTaxonomyApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.TaxonomyItem, id: args.taxonomyItemId },
            { type: ApiTagTypes.UserDefinedFields, id: args.taxonomyItemId },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.RisksCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.EarningsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId },
            // TODO only invalidate the elements that have a reference in formula to the plot
            { type: ApiTagTypes.FormulaElements, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostUploadTaxonomyFile: {
    invalidatesTags: (
      _result: ApiPostUploadTaxonomyFileApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUploadTaxonomyFileApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.TaxonomyItem, id: args.taxonomyItemId },
            {
              type: ApiTagTypes.Taxonomy,
              id: args.projectId,
            },
          ]
        : [],
  },
  apiDeleteTaxonomyItemFile: {
    invalidatesTags: (
      _result: ApiDeleteTaxonomyItemFileApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteTaxonomyItemFileApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.TaxonomyItem, id: args.taxonomyItemId },
            {
              type: ApiTagTypes.Taxonomy,
              id: args.projectId,
            },
          ]
        : [],
  },
  apiPostDuplicateTaxonomy: {
    invalidatesTags: (
      _result: ApiPostDuplicateTaxonomyApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostDuplicateTaxonomyApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.TaxonomyItem, id: args.taxonomyItemId },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId },
            // TODO only invalidate the elements that have a reference in formula to the taxonomy
            { type: ApiTagTypes.FormulaElements, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostMoveTaxonomy: {
    invalidatesTags: (
      _result: ApiPostMoveTaxonomyApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostMoveTaxonomyApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.TaxonomyItem, id: args.taxonomyItemId },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId },
            // TODO only invalidate the elements that have a reference in formula to the taxonomy
            { type: ApiTagTypes.FormulaElements, id: args.calculationModelId },
          ]
        : [],
  },
};
