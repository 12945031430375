import { FinancingElementReadModel, FinancingTransactionReadModel } from '@client/shared/api';
import { SlideOver, SlideOverOnCloseProps } from '@client/shared/toolkit';
import { isAfter, isBefore, isWithinInterval } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FinancingTransactionsRow } from './FinancingTransactionsRow';

interface FinancingTransactionsSlideOverProps extends SlideOverOnCloseProps {
  element?: FinancingElementReadModel;
}

export const FinancingTransactionsSlideOver = ({ element, onClose }: FinancingTransactionsSlideOverProps) => {
  const { t } = useTranslation();

  const dateMarkIndex = useMemo(() => {
    if (!element?.transactions) {
      return -1;
    }

    if (element.transactions.length === 0) {
      return -1;
    }

    const today = new Date();

    const firstElementDate = new Date(element.transactions[0].paymentOn);

    // no need to show mark if first transaction have not yet started
    if (isBefore(today, firstElementDate)) {
      return -1;
    }

    const lastElementDate = new Date(element.transactions[element.transactions.length - 1].paymentOn);

    // no need to show mark if last transactions have passed
    if (isAfter(today, lastElementDate)) {
      return -1;
    }

    type TransactionByIndex = {
      index: number;
      transaction: FinancingTransactionReadModel;
    };

    const transactionsInRange = element.transactions
      .map<TransactionByIndex>((transaction, index) => {
        return {
          index,
          transaction,
        } as TransactionByIndex;
      })
      .filter((transaction, index, array) => {
        // current transaction date is compared to next transaction date so the last element is ignored
        if (index === array.length - 1) {
          return false;
        }
        const start = new Date(transaction.transaction.paymentOn);
        const end = new Date(array[index + 1].transaction.paymentOn);

        if (isWithinInterval(today, { start, end })) {
          return true;
        }

        return false;
      });

    return transactionsInRange[transactionsInRange.length - 1].index;
  }, [element]);

  return (
    <>
      <SlideOver.Header
        title={element?.title ?? 'N/A'}
        subTitle={t('projectCalculate.transactionsTitle')}
        backgroundClassName="bg-gray-700"
        onClose={() => onClose(false)}
      />
      <SlideOver.Content className="p-8">
        <div className="flex flex-row text-end text-slate-600 text-[13px] px-8 pb-3">
          <div className="w-4/12 text-start">{t('projectCalculate.transactionsHeader.Deadline')}</div>
          <div className="w-2/12">{t('projectCalculate.transactionsHeader.StartBalance')}</div>
          <div className="w-2/12">{t('projectCalculate.transactionsHeader.InterestRate')}</div>
          <div className="w-2/12">{t('projectCalculate.transactionsHeader.EndBalance')}</div>
          <div className="w-2/12">{t('projectCalculate.transactionsHeader.TotalAmount')}</div>
        </div>
        {element?.transactions ? (
          <div className="divide-y-2 divide-slate-50">
            {element.transactions?.map((transaction, idx: number) => (
              <FinancingTransactionsRow
                key={idx}
                applyOpacity={dateMarkIndex > -1 ? dateMarkIndex < idx : false}
                showDateMark={dateMarkIndex === idx}
                transaction={transaction}
                fixedInterestRate={element.interestRateReadModel?.interestRateType === 'Fixed'}
              />
            ))}
          </div>
        ) : null}
      </SlideOver.Content>
    </>
  );
};
