import { CostCatalogElement, DecoratedElement } from '../hooks';
import { formatCurrency, i18n } from '@client/shared/utilities';
import { CostElementGroupValues, SearchFilter, SearchFilterType } from '@client/shared/api';

export const FINANCE_COST_FILTERS = ['forecast-gtoe-budget', 'diff-gtoe-1000', 'diff-gtoe-5000', 'diff-gtoe-10000', 'diff-stoe-1000', 'diff-stoe-5000'];
/*
  gt: greater than
  gtoe: greater than or equal
  stoe: smaller than or equal
 */
export type CostFilterComparisonType = 'gt' | 'gtoe' | 'stoe'

export type CostsFilterOption = Omit<SearchFilter, 'type'> & {
  label: string;
  type: SearchFilterType | 'FinanceCost'
}

export function getCostsFilters (currency: string): CostsFilterOption[] {
  const formatCurrencyOptions = { currency: currency}
  return [
    {
      id: 'forecast-gtoe-budget',
      label: `${i18n.t('projectCalculate.headerTitleForecast')} > ${i18n.t('projectCalculate.financeTimeline.budget')}`,
      type: 'FinanceCost'
    },
    {
      id: 'diff-gtoe-1000',
      label: `${i18n.t('projectCalculate.difference')} ≥ ${formatCurrency(1000, formatCurrencyOptions)}`,
      type: 'FinanceCost'
    },
    {
      id: 'diff-gtoe-5000',
      label: `${i18n.t('projectCalculate.difference')} ≥ ${formatCurrency(5000, formatCurrencyOptions)}`,
      type: 'FinanceCost'
    },
    {
      id: 'diff-gtoe-10000',
      label: `${i18n.t('projectCalculate.difference')} ≥ ${formatCurrency(10000, formatCurrencyOptions)}`,
      type: 'FinanceCost'
    },
    {
      id: 'diff-stoe-1000',
      label: `${i18n.t('projectCalculate.difference')} ≤ ${formatCurrency(1000, formatCurrencyOptions)}`,
      type: 'FinanceCost'
    },
    {
      id: 'diff-stoe-5000',
      label: `${i18n.t('projectCalculate.difference')} ≤ ${formatCurrency(5000, formatCurrencyOptions)}`,
      type: 'FinanceCost'
    }
  ]
}

export const getDisplayAndChangeValue = (props: CostElementGroupValues) => {
  let displayValue: number | undefined | null;
  let changeValue: number | undefined | null;

  if (props.calculationState === 'JustTotal') {
    displayValue = props.total;
    changeValue = props.forecastValue && props.total && props.total !== 0 ? props.forecastValue - props.total : undefined;
  } else if (props.calculationState === 'JustCalculated') {
    displayValue = props.calculatedValue;
    changeValue =
      props.forecastValue && props.calculatedValue && props.calculatedValue !== 0
        ? props.forecastValue - props.calculatedValue
        : undefined;
  } else {
    displayValue = props.effectiveValue;
    changeValue =
      props.forecastValue && props.effectiveValue && props.effectiveValue !== 0
        ? props.forecastValue - props.effectiveValue
        : undefined;
  }

  return {
    displayValue: displayValue,
    changeValue: changeValue
  }
}

export function filterElementsByCostsFilter(elements: DecoratedElement<CostCatalogElement>[], costsFilter: CostsFilterOption[]): DecoratedElement<CostCatalogElement>[] {
  if (!costsFilter.length) return elements
  return elements.filter((element) => {
    let match = false
    const forecast = element.element?.group?.modelValues.forecastValue
    if (element.element.group?.modelValues && forecast) {
      const { displayValue, changeValue } = getDisplayAndChangeValue(element.element.group.modelValues)
      if (displayValue) {
        costsFilter.forEach((costsFilterOption) => {
          if ((costsFilterOption.id === 'forecast-gtoe-budget' && forecast >= displayValue) ||
            (changeValue && costsFilterOption.id === 'diff-gtoe-1000' && changeValue >= 1000) ||
            (changeValue && costsFilterOption.id === 'diff-gtoe-5000' && changeValue >= 5000) ||
            (changeValue && costsFilterOption.id === 'diff-gtoe-10000' && changeValue >= 10000) ||
            (changeValue && costsFilterOption.id === 'diff-stoe-1000' && changeValue <= 1000) ||
            (changeValue && costsFilterOption.id === 'diff-stoe-5000' && changeValue <= 5000)
          ) {
            match = true
          }
        })
      }
    }
    return match
  });
}
