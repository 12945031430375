import { TaxonomyCostItemsReadModel } from '@client/shared/api';
import { TaxonomyTreeItem } from './TaxonomyTreeItem';
import { useTranslation } from 'react-i18next';
import { SkyscrapersDottedIcon } from '@client/shared/toolkit';

interface TaxonomyRootItemProps {
  rootElement: TaxonomyCostItemsReadModel;
  selectedItem?: TaxonomyCostItemsReadModel;
  doSelect: (item: TaxonomyCostItemsReadModel) => void;
}

export const TaxonomyRootItem = ({
  doSelect,
  rootElement: { attachedContracts, childItems, taxonomyItem: rootTaxonomy },
  selectedItem,
}: TaxonomyRootItemProps) => {
  const { t } = useTranslation();

  const setIsBuildingItemModalOpen = (open: boolean) => {
    console.log('blub');
  };

  return (
    <div className="bg-sky-900 shadow-lg rounded">
      <div className="text-gray-100 p-6">
        <div className="relative flex">
          <div className="px-6" onClick={() => setIsBuildingItemModalOpen(true)}>
            <SkyscrapersDottedIcon className="w-10 cursor-pointer" />
          </div>
          <div className="pl-0 w-3/12 flex flex-row cursor-pointer" onClick={() => setIsBuildingItemModalOpen(true)}>
            <div>
              <p className="font-bold">{rootTaxonomy.itemName}</p>
              <p className="text-sm font-extralight">{rootTaxonomy.itemType}</p>
            </div>
          </div>
          <div
            className="w-5/12 flex flex-row cursor-pointer text-gray-100/50"
            onClick={() => setIsBuildingItemModalOpen(true)}
          >
            {attachedContracts && attachedContracts.length > 0 && (
              <div>
                <div>
                  <p className="font-bol text-right">{attachedContracts.length}</p>
                  <p className="text-sm font-extralight">
                    {t('projectRent.taxonomyTreeItemContract', { count: attachedContracts.length })}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col ml-2 text-sky-900/60 font-semibold divide-white divide-y-2">
        {childItems != null &&
          childItems.length > 0 &&
          childItems.map((c) => (
            <TaxonomyTreeItem
              item={c}
              depth={0}
              key={c.taxonomyItem.itemId}
              selectedItem={selectedItem}
              doSelect={doSelect}
            />
          ))}
      </div>
    </div>
  );
};
