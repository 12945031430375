import { RefObject, useState } from 'react';

interface DragScrollOptions {
  horizontal?: boolean;
  vertical?: boolean;
}

/**
 * @description This hook allows scrolling a div element by dragging it with the mouse.
 * @param ref - The ref of the div element that will be scrolled.
 * @param horizontal - Whether to allow horizontal scrolling.
 * @param vertical - Whether to allow vertical scrolling.
 * @returns An object containing the onMouseDown event handler function and a boolean indicating whether the user is dragging the element.
 */
export const useDragScroll = (
  ref: RefObject<HTMLElement>,
  { horizontal, vertical }: DragScrollOptions = { horizontal: true, vertical: true }
) => {
  // Keep track of the initial position of the element and the mouse, we are not using state to avoid unnecessary re-renders and let the browser handle it natively
  let initialPosition = { scrollTop: 0, scrollLeft: 0, mouseX: 0, mouseY: 0 };
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseMove = ({ clientX, clientY }: MouseEvent) => {
    if (ref.current) {
      // The mouse has moved since the last event, calculate the distance it has moved
      const dx = clientX - initialPosition.mouseX;
      const dy = clientY - initialPosition.mouseY;

      // Move the element by the same distance as the mouse
      if (vertical) {
        ref.current.scrollTop = initialPosition.scrollTop - dy;
      }
      if (horizontal) {
        ref.current.scrollLeft = initialPosition.scrollLeft - dx;
      }
    }
  };

  const handleMouseUp = () => {
    // Remove the event listeners
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);

    setIsDragging(false);
  };

  const onMouseDown = ({ clientX, clientY }: React.MouseEvent) => {
    if (ref.current) {
      // Store the initial position of the element and the mouse
      initialPosition = {
        scrollLeft: ref.current.scrollLeft,
        scrollTop: ref.current.scrollTop,
        mouseX: clientX,
        mouseY: clientY,
      };

      // Add the event listeners
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);

      setIsDragging(true);
    }
  };

  return { onMouseDown, isDragging };
};
