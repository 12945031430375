import { Collapsible } from '@client/shared/toolkit';
import { CalculateColumns, TimeLineView } from '.';
import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import { CalculateTotal } from './CalculateTotal';
import cn from 'classnames';

export interface CalculateSectionProps extends PropsWithChildren {
  catalogHasRestrictions: boolean;
  view: TimeLineView;
  open: boolean;
  bgColor?: string;
  totalColor?: string;
  totalText?: string;
  totalCost?: number | null;
  totalSectionTimeline?: ReactNode;
  totalSectionFinance?: ReactNode;
  hasBarChart?: boolean;
  height?: number
}

export const CalculateSection = (props: CalculateSectionProps) => {
  const {
    catalogHasRestrictions,
    totalCost,
    view,
    open,
    bgColor = 'bg-costs',
    children,
    totalText,
    totalColor = 'text-costs',
    totalSectionTimeline,
    totalSectionFinance,
    hasBarChart = false,
    height
  } = props
  const total = useMemo(() => {
    if (!totalText) return
    return (
      <CalculateTotal
        catalogHasRestrictions={catalogHasRestrictions}
        totalText={totalText}
        colorClass={totalColor}
        totalCost={totalCost}
      />
    )
  }, [catalogHasRestrictions, totalText, totalColor, totalCost])
  return (
    <Collapsible disableAnimation={true} open={open}>
      <div className="w-full flex flex-col">
        <div className="w-full flex bg-white shadow rounded-tl rounded-bl">
          <div className={cn('w-2 h-auto rounded-tl-md rounded-bl-md flex-none', bgColor)} />
          <div className={cn('w-full relative', {
            'mb-28': view === TimeLineView.TIMELINE && hasBarChart,
            'pb-3': view === TimeLineView.TIMELINE && !hasBarChart || view === TimeLineView.FINANCE
          })}>
            {/* vertical borders */}
            <div className="vertical-borders flex w-full h-full absolute top-0 left-0 pointer-events-none">
              <CalculateColumns
                view={view}
                borders
              />
            </div>
            {/* rows with columns with data */}
            <div className="flex w-full flex-col text-primary relative" style={{ height: height }}>
              {children}
            </div>
          </div>
        </div>
        {/* for financing we need multiple totals for timeline view */}
        {view === TimeLineView.TIMELINE && (totalSectionTimeline ? totalSectionTimeline : total)}
        {view === TimeLineView.FINANCE && (totalSectionFinance ? totalSectionFinance : (
          <div className="flex pl-2">
            <CalculateColumns
              view={view}
              currentColumnContent={total}
              isTotal
            />
          </div>
        ))}
      </div>
    </Collapsible>
  )
}
