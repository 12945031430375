import { ContractReadModel, InvoiceReadModel } from '@client/shared/api';
import {
  AddressCard, CardActionButton,
  DocumentViewerFileDataGroup,
  DocumentViewerFileDataSet,
  PaidBillDottedIcon, PencilIcon,
  SlideOverTitle
} from '@client/shared/toolkit';
import { FormattedCurrency } from '../../FormattedCurrency';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId } from '@client/project/store';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { InvoiceContractTitlesList } from './InvoiceContractTitlesList';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import cn from 'classnames';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

interface InvoiceContractProps {
  invoice: InvoiceReadModel;
  contract?: ContractReadModel;
  view?: 'contract' | 'invoice'; // if invoice slide over was opened from control or from contract view
}

export const InvoiceContract = (props: InvoiceContractProps) => {
  const { invoice, view = 'invoice', contract } = props;

  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const navigate = useNavigate();

  const openContractView = (e: React.MouseEvent) => {
    if (invoice.contractId && view === 'invoice') {
      e.preventDefault()
      e.stopPropagation()
      navigate(
        ROUTES_CONFIG.PROJECT_CONTRACT_VIEW.path
          .replace(':id', loadedProjectId ?? '')
          .replace(':contractId', invoice.contractId),
      );
    }
  };

  if (!contract) return null;

  return (
    <>
      <AddressCard
        address={contract?.contractor?.address ?? undefined}
        companyName={contract?.contractor?.companyName}
        name={contract?.contractor?.name}
        logo={
          contract?.contractor?.logoImageId
            ? `/api/companies/${contract?.contractor?.companyId}/logo/${contract?.contractor?.logoImageId}`
            : undefined
        }
        label={t('projectContract.contractor')}
        centered
      />
      <AddressCard
        address={contract?.client?.address ?? undefined}
        companyName={contract?.client?.companyName}
        name={contract?.client?.name}
        logo={
          contract?.client?.logoImageId
            ? `/api/companies/${contract?.client?.companyId}/logo/${contract?.client?.logoImageId}`
            : undefined
        }
        label={t('projectContract.contractClient')}
        centered
      />
      <DocumentViewerFileDataGroup divider={false} className="relative">
        <Disclosure as="div" className="flex flex-col divide-y-2" defaultOpen>
          {({ open }) => (
            <>
              <DisclosureButton as="div">
                <div className={cn('flex gap-2 cursor-pointer', open ? 'pb-2' : '')}>
                  <PaidBillDottedIcon className="w-9 flex-none" />
                  <SlideOverTitle
                    marginTop={false}
                    title={t('projectContract.contract')}
                    className="mt-4 flex-1 flex hover:text-gray-800 duration-300 transition-colors relative"
                  >
                    <div className={classNames('flex-1 flex items-center', invoice.contractId && !contract?.isPxContract && view === 'invoice' ? 'justify-between' : 'justify-end')}>
                      {invoice.contractId && !contract?.isPxContract && view === 'invoice' && (
                        <CardActionButton icon={<PencilIcon />} onClick={openContractView} className="ml-4 flex-none" />
                      )}
                      <ChevronDownIcon className={classNames(
                        'transition-transform will-change-transform duration-100 transform -rotate-90 h5 w-5',
                        {
                          'rotate-0': open
                        }
                      )} />
                    </div>
                  </SlideOverTitle>
                </div>
              </DisclosureButton>
              <DisclosurePanel>
                <div className="flex flex-col divide-y-2">
                  {/* CONTRACT DATA */}
                  <div className="py-4">
                    <DocumentViewerFileDataSet
                      label={t('projectContract.contract')}
                      title={contract?.name}
                      text={contract?.description}
                    />
                  </div>
                  <div className="w-full py-4">
                    <DocumentViewerFileDataSet label={t('projectContract.totalContract')} className="pr-2">
                      <div className="grid grid-cols-2 divide-x-2">
                        <div className="flex flex-col">
                          <span className="text-lg font-bold">
                            <FormattedCurrency amount={contract?.contractValueGross} />
                          </span>
                          <span className="text-[11px] text-gray-500">{t('projectContract.orderVolume')}</span>
                        </div>
                        <div className="flex flex-col pl-4 text-right">
                          <span className="text-lg font-bold">-</span>
                          <span className="text-[11px] text-gray-500 whitespace-nowrap truncate">
                            {t('projectContract.approvalsIncludingSupplements')}
                          </span>
                        </div>
                      </div>
                    </DocumentViewerFileDataSet>
                  </div>
                  {/* CONTRACT TITLES */}
                  <Disclosure as="div" className="divide-y-2 flex flex-col" defaultOpen>
                    {({ open: childOpen }) => (
                      <>
                        <DisclosureButton>
                          <SlideOverTitle
                            title={t('projectContract.contractPositions')}
                            className="flex-1 flex justify-between hover:text-gray-800 duration-300 transition-colors cursor-pointer"
                          >
                            <ChevronDownIcon
                              className={classNames(
                                'transition-transform will-change-transform duration-100 transform -rotate-90 h5 w-5 flex-none',
                                {
                                  'rotate-0': childOpen,
                                },
                              )}
                            />
                          </SlideOverTitle>
                        </DisclosureButton>
                        <DisclosurePanel>
                          {/* CONTRACT TITLES */}
                          {contract.titles.length > 0 ? (
                            <InvoiceContractTitlesList invoice={invoice} contract={contract} />
                          ) : (
                            <div className="italic pt-4">{t('projectContract.noContractTitles')}</div>
                          )}
                        </DisclosurePanel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      </DocumentViewerFileDataGroup>
    </>
  );
};
