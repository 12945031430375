import {
  AuthGroupReadModel,
  AuthSubjectReadModel,
  useApiPostUpdateAuthGroupMutation,
  useApiPostCreateAuthGroupMutation,
  AuthRoleAssignmentReadModel,
  RoleAssignmentPayload,
  AuthAssignmentReadModel,
} from '@client/shared/api';
import { RectangleGroupIcon, ShieldCheckIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import {
  Button,
  Form,
  FormField,
  SlideOver,
  TextInput,
  Modal,
  SlideOverOnCloseProps, TagWindowIcon, UsersCheckCustomIcon, HintBox, LoadingIndicator,
} from '@client/shared/toolkit';
import { isEmpty, safeMutation } from '@client/shared/utilities';
import { EffectivePermissionList } from '../permissions';
import { UserList } from '../users';
import { RoleImpactEditSlideOver, RoleList } from '../roles';
import { GroupFormValidationSchema, GroupFormValidationValues } from './GroupFormValidationSchema';
import { GroupDeleteModal } from './GroupDeleteModal';
import { EffectiveCatalogList } from '../templates';

interface GroupDetailsSlideOverProps extends SlideOverOnCloseProps {
  isAddMode: boolean;
  group: AuthGroupReadModel | undefined;
}

type Subarea = 'Users' | 'Roles' | 'Permissions' | 'CatalogPermissions';

export const GroupDetailsSlideOver = ({ group, isAddMode, onClose }: GroupDetailsSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const [postUpdate, { isLoading: isUpdating }] = useApiPostUpdateAuthGroupMutation();
  const [postCreate, { isLoading: isCreating }] = useApiPostCreateAuthGroupMutation();

  const [subarea, setSubarea] = useState<Subarea>('Users');
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const [showEditRole, setShowEditRole] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<AuthRoleAssignmentReadModel>();

  const [assignedRoles, setAssignedRoles] = useState<AuthRoleAssignmentReadModel[]>(group?.roles.assigned ?? []);
  const [newRoles, setNewRoles] = useState<AuthRoleAssignmentReadModel[]>([]);
  const [deletedRoles, setDeletedRoles] = useState<AuthRoleAssignmentReadModel[]>([]);

  const [newUsers, setNewUsers] = useState<AuthSubjectReadModel[]>([]);
  const [deletedUsers, setDeletedUsers] = useState<AuthSubjectReadModel[]>([]);

  const handleRoleUpdate = (role: AuthRoleAssignmentReadModel) => {

    const assignedRole = assignedRoles.find((x) => x.roleId === role.roleId);
    if (assignedRole) {
      const index = assignedRoles.indexOf(assignedRole);
      const updatedRoleList = assignedRoles.slice();
      updatedRoleList.splice(index, 1, role);
      setAssignedRoles(updatedRoleList);
    }

    const newRole = newRoles.find((x) => x.roleId === role.roleId);
    if (newRole) {
      const index = newRoles.indexOf(newRole);
      const updatedRoleList = newRoles.slice();
      updatedRoleList.splice(index, 1, role);
      setNewRoles(updatedRoleList);
    }
  };

  const toPayload = (assignment: AuthAssignmentReadModel): RoleAssignmentPayload => ({
    roleId: assignment.role.id,
    projectId: assignment.project?.id,
  });

  const getNewRoleAssignments = (): RoleAssignmentPayload[] => {
    let assignments: RoleAssignmentPayload[] = [];

    newRoles.forEach((x) => {
      assignments = assignments.concat(x.assignments.map(toPayload));
    });

    assignedRoles.forEach((x) => {
      assignments = assignments.concat(x.assignments.filter(y => isEmpty(y.id)).map(toPayload));
    })

    return assignments;
  };

  const getDeletedRoleAssignments = (): string[] => {
    let assignments: string[] = [];

    deletedRoles.forEach((x) => {
      assignments = assignments.concat(x.assignments.map((y) => y.id))
    });

    assignedRoles.forEach((x) => {
      const originalRole = group?.roles.assigned.find(y => y.roleId === x.roleId);
      if (originalRole) {
        assignments = assignments.concat(originalRole.assignments
            .filter(y => !x.assignments.some(z => z.id === y.id))
            .map(y => y.id));
      }
    });

    return assignments;
  };

  const getAssignedUsers = (): string[] => {
    const assigned = group?.subjects ?? [];
    const excludeRemoved = assigned.filter((x) => !deletedUsers.includes(x));
    const includeAdded = excludeRemoved.concat(newUsers);

    return includeAdded.map((x) => x.tenantUserId);
  };

  const defaultFormValues = {
    name: group?.name ?? '',
    comment: group?.comment ?? ''
  };

  const handleGroupSubmit = async (data: GroupFormValidationValues) => {
    if (isAddMode) {
      try {
        await safeMutation(
          postCreate,
          {
            body: {
              name: data.name,
              comment: data.comment,
              assignedUsers: getAssignedUsers(),
              roleAssignments: getNewRoleAssignments(),
            },
          },
          isCreating
        );

        onClose(true);
      } catch {
        /* left blank */
      }
    } else {
      try {
        await safeMutation(
          postUpdate,
          {
            groupId: group?.id ?? '',
            body: {
              name: data.name,
              comment: data.comment,
              addedUsers: newUsers.map((x) => x.tenantUserId),
              deletedUsers: deletedUsers.map((x) => x.tenantUserId),
              addedRoleAssignments: getNewRoleAssignments(),
              deletedRoleAssignments: getDeletedRoleAssignments(),
            },
          },
          isUpdating
        );

        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  return (
    <>
      <Form<GroupFormValidationValues>
        onSubmit={handleGroupSubmit}
        validationSchema={GroupFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
      >
        {(isUpdating || isCreating) && (
          <LoadingIndicator text={isUpdating ? t('common.updating') : t('common.loading')} mode="overlay" />
        )}
        <SlideOver.Header
          title={group?.name ?? t('auth.groupNewGroupTitle')}
          backgroundClassName="bg-gray-600"
          onClose={() => onClose(false)}
        />
        <SlideOver.Content>
          <div className="m-8 bg-white">
            <div className="divide-gray-100 divide-y">
              <FormField name="name">
                {(control) => (
                  <TextInput label={t('common.name')} icon={<TagWindowIcon />} {...control} />
                )}
              </FormField>
              <FormField name="comment">
                {(control) => (
                  <TextInput label={t('auth.comment')} icon={<TagWindowIcon />} {...control} />
                )}
              </FormField>
            </div>
          </div>

          <div className="flex flex-grow flex-col px-8">
            <div className="flex flex-row mt-2">
              <button
                type="button"
                className={classNames('px-4 pb-2 text text-gray-900 block ', {
                  'font-bold border-b border-slate-200': subarea === 'Users',
                })}
                onClick={() => setSubarea('Users')}
              >
                <div className="flex felx-row items-center">
                  <UserGroupIcon className="w-5 h-5 mr-2" />
                  {t('auth.subjects')}
                </div>
              </button>
              <button
                type="button"
                className={classNames('px-4 pb-2 text text-gray-900 block', {
                  'font-bold border-b border-slate-200': subarea === 'Roles',
                })}
                onClick={() => setSubarea('Roles')}
              >
                <div className="flex felx-row items-center">
                  <ShieldCheckIcon className="w-5 h-5 mr-2" />
                  {t('auth.roles')}
                </div>
              </button>
              <button
                type="button"
                className={classNames('px-4 pb-2 text text-gray-900 block', {
                  'font-bold border-b border-slate-200': subarea === 'Permissions',
                })}
                onClick={() => setSubarea('Permissions')}
              >
                <div className="flex felx-row items-center">
                  <UsersCheckCustomIcon className="w-5 h-5 mr-2" />
                  {t('auth.permissions')}
                </div>
              </button>
              <button
                type="button"
                className={classNames('px-4 pb-2 text text-gray-900 block', {
                  'font-bold border-b border-slate-200': subarea === 'CatalogPermissions',
                })}
                onClick={() => setSubarea('CatalogPermissions')}
              >
                <div className="flex felx-row items-center">
                  <RectangleGroupIcon className="w-5 h-5 mr-2" />
                  {t('auth.catalogPermissions')}
                </div>
              </button>
            </div>
            <div className="bg-white">
              {subarea === 'Users' && (
                <UserList
                  assignedUsers={group?.subjects ?? []}
                  newUsers={newUsers}
                  deletedUsers={deletedUsers}
                  userSelectionChanged={(add, rem) => {
                    setNewUsers(add);
                    setDeletedUsers(rem);
                  }}
                />
              )}
              {subarea === 'Roles' && (
                <RoleList
                  embeddedSlideOverBackground="bg-gray-600"
                  assignedRoles={assignedRoles}
                  newRoles={newRoles}
                  deletedRoles={deletedRoles}
                  onEditRoleImpact={(role) => {
                    setSelectedRole(role);
                    setShowEditRole(true);
                  }}
                  onRoleSelectionChanged={(add, rem) => {
                    setNewRoles(add);
                    setDeletedRoles(rem);
                  }}
                />
              )}
              {subarea === 'Permissions' && (
                <EffectivePermissionList
                  showGroupOrigin={false}
                  tenantPermissions={group?.effectivePermissions.tenantPermissions ?? []}
                  projectPermissions={group?.effectivePermissions.projectPermissions ?? []}
                >
                  {(newRoles.length > 0 || deletedRoles.length > 0) && (
                    <HintBox hintType="info" title={t('licence.saveFirstToSeePermissionsMessage')} />
                  )}
                </EffectivePermissionList>
              )}
              {subarea === 'CatalogPermissions' && (
                <EffectiveCatalogList
                  effectiveTenantCatalogTemplates={group?.effectiveCatalogs.filter(x => !x.project) ?? []}
                  effectiveProjectCatalogTemplates={group?.effectiveCatalogs.filter(x => x.project) ?? []}
                >
                  {(newRoles.length > 0 || deletedRoles.length > 0) && (
                    <HintBox hintType="info" title={t('licence.saveFirstToSeePermissionsMessage')} />
                  )}
                </EffectiveCatalogList>
              )}
            </div>
          </div>
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className="flex flex-grow">
            {!isAddMode && (
              <Button variant="warning" onClick={() => setShowDelete(true)}>
                {t('common.delete')}
              </Button>
            )}
          </div>
          <div className="flex justify-end">
            <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
              {t('common.cancel')}
            </Button>
            <Button variant="primary" formSubmit={true} innerRef={submitRef}>
              {t('common.save')}
            </Button>
          </div>
        </SlideOver.Controls>
      </Form>

      <Modal isOpen={showDelete} onClose={onClose} variant="small">
        <GroupDeleteModal
          group={group}
          onClose={(success: boolean) => {
            setShowDelete(false);

            if (success) {
              onClose(true);
            }
          }}
        />
      </Modal>

      {selectedRole && (
        <SlideOver
          isOpen={showEditRole}
          onClose={() => {
            setShowEditRole(false);
            setSelectedRole(undefined);
          }}
        >
          <RoleImpactEditSlideOver
            roleAssignment={selectedRole}
            onClose={(role) => {
              if (role) {
                handleRoleUpdate(role);
              }

              setShowEditRole(false);
              setSelectedRole(undefined);
            }}
          />
        </SlideOver>
      )}
    </>
  );
};
