import {
  ApiAddDatevApiAccessApiArg,
  ApiAddDatevApiAccessApiResponse,
  ApiAddProjectDatevProbisMappingApiArg,
  ApiAddProjectDatevProbisMappingApiResponse,
  ApiAddTenantDatevProbisMappingApiArg,
  ApiAddTenantDatevProbisMappingApiResponse,
  ApiChangeProjectDatevClientApiArg,
  ApiChangeProjectDatevClientApiResponse,
  ApiChangeTenantDatevClientApiArg,
  ApiChangeTenantDatevClientApiResponse,
  ApiDatevClientTokenRevokeApiArg,
  ApiDatevClientTokenRevokeApiResponse,
  ApiDeleteDatevApiAccessApiArg,
  ApiDeleteDatevApiAccessApiResponse,
  ApiDeleteProjectDatevProbisMappingApiArg,
  ApiDeleteProjectDatevProbisMappingApiResponse,
  ApiDeleteTenantDatevProbisMappingApiArg,
  ApiDeleteTenantDatevProbisMappingApiResponse,
  ApiPostActiveApiAccessApiArg,
  ApiPostActiveApiAccessApiResponse,
  ApiPostAddDocuWareAccessApiArg,
  ApiPostAddDocuWareAccessApiResponse,
  ApiPostApiAccessApiArg,
  ApiPostApiAccessApiResponse,
  ApiPostImportTenantDatevActiveApiMappingApiArg,
  ApiPostImportTenantDatevActiveApiMappingApiResponse,
  ApiPostStartDocuWareProcessApiArg,
  ApiPostStartDocuWareProcessApiResponse,
  ApiPostUpdateDocuWareAccessApiArg,
  ApiPostUpdateDocuWareAccessApiResponse,
  ApiRemoveApiAccessApiArg,
  ApiRemoveApiAccessApiResponse,
  ApiUpdateApiAccessApiArg,
  ApiUpdateApiAccessApiResponse,
  ApiUpdateDatevAccountingMonthApiArg,
  ApiUpdateDatevAccountingMonthApiResponse,
  ApiUpdateDatevApiAccessApiArg,
  ApiUpdateDatevApiAccessApiResponse,
  ApiUpdateProjectDatevProbisMappingApiArg,
  ApiUpdateProjectDatevProbisMappingApiResponse,
  ApiUpdateTenantDatevProbisMappingApiArg,
  ApiUpdateTenantDatevProbisMappingApiResponse
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiExternalApiEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetExternalApis: {
    providesTags: [ApiTagTypes.ExternalApis],
  },
  apiGetDatevClient: {
    providesTags: [ApiTagTypes.ExternalApis, ApiTagTypes.DatevApi],
  },
  apiGetDatevApiAccess: {
    providesTags: [ApiTagTypes.ExternalApis],
  },
  apiGetDatevActiveClients: {
    providesTags: [ApiTagTypes.ExternalApis, ApiTagTypes.DatevApi],
  },
  apiGetProjectDatevConnection: {
    providesTags: [ApiTagTypes.ExternalApis],
  },
  apiGetDocuWareAccess: {
    providesTags: [ApiTagTypes.ExternalApis],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostApiAccess: {
    invalidatesTags: (
      _result: ApiPostApiAccessApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostApiAccessApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiRemoveApiAccess: {
    invalidatesTags: (
      _result: ApiRemoveApiAccessApiResponse,
      error: FetchBaseQueryError,
      _args: ApiRemoveApiAccessApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiUpdateApiAccess: {
    invalidatesTags: (
      _result: ApiUpdateApiAccessApiResponse,
      error: FetchBaseQueryError,
      _args: ApiUpdateApiAccessApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiPostActiveApiAccess: {
    invalidatesTags: (
      _result: ApiPostActiveApiAccessApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostActiveApiAccessApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiAddDatevApiAccess: {
    invalidatesTags: (
      _result: ApiAddDatevApiAccessApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAddDatevApiAccessApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiUpdateDatevApiAccess: {
    invalidatesTags: (
      _result: ApiUpdateDatevApiAccessApiResponse,
      error: FetchBaseQueryError,
      _args: ApiUpdateDatevApiAccessApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiDeleteDatevApiAccess: {
    invalidatesTags: (
      _result: ApiDeleteDatevApiAccessApiResponse,
      error: FetchBaseQueryError,
      _args: ApiDeleteDatevApiAccessApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiAddTenantDatevProbisMapping: {
    invalidatesTags: (
      _result: ApiAddTenantDatevProbisMappingApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAddTenantDatevProbisMappingApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiUpdateTenantDatevProbisMapping: {
    invalidatesTags: (
      _result: ApiUpdateTenantDatevProbisMappingApiResponse,
      error: FetchBaseQueryError,
      _args: ApiUpdateTenantDatevProbisMappingApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiDeleteTenantDatevProbisMapping: {
    invalidatesTags: (
      _result: ApiDeleteTenantDatevProbisMappingApiResponse,
      error: FetchBaseQueryError,
      _args: ApiDeleteTenantDatevProbisMappingApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiAddProjectDatevProbisMapping: {
    invalidatesTags: (
      _result: ApiAddProjectDatevProbisMappingApiResponse,
      error: FetchBaseQueryError,
      _args: ApiAddProjectDatevProbisMappingApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiUpdateProjectDatevProbisMapping: {
    invalidatesTags: (
      _result: ApiUpdateProjectDatevProbisMappingApiResponse,
      error: FetchBaseQueryError,
      _args: ApiUpdateProjectDatevProbisMappingApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiDeleteProjectDatevProbisMapping: {
    invalidatesTags: (
      _result: ApiDeleteProjectDatevProbisMappingApiResponse,
      error: FetchBaseQueryError,
      _args: ApiDeleteProjectDatevProbisMappingApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiDatevClientTokenRevoke: {
    invalidatesTags: (
      _result: ApiDatevClientTokenRevokeApiResponse,
      error: FetchBaseQueryError,
      _args: ApiDatevClientTokenRevokeApiArg,
    ) => (error == null ? [ApiTagTypes.DatevApi] : []),
  },
  apiChangeProjectDatevClient: {
    invalidatesTags: (
      _result: ApiChangeProjectDatevClientApiResponse,
      error: FetchBaseQueryError,
      _args: ApiChangeProjectDatevClientApiArg,
    ) => (error == null ? [ApiTagTypes.DatevApi] : []),
  },
  apiPostAddDocuWareAccess: {
    invalidatesTags: (
      _result: ApiPostAddDocuWareAccessApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostAddDocuWareAccessApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiPostUpdateDocuWareAccess: {
    invalidatesTags: (
      _result: ApiPostUpdateDocuWareAccessApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostUpdateDocuWareAccessApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiPostStartDocuWareProcess: {
    invalidatesTags: (
      _result: ApiPostStartDocuWareProcessApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostStartDocuWareProcessApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiPostImportTenantDatevActiveApiMapping: {
    invalidatesTags: (
      _result: ApiPostImportTenantDatevActiveApiMappingApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostImportTenantDatevActiveApiMappingApiArg,
    ) => (error == null ? [ApiTagTypes.ExternalApis] : []),
  },
  apiUpdateDatevAccountingMonth: {
    invalidatesTags: (
      _result: ApiUpdateDatevAccountingMonthApiResponse,
      error: FetchBaseQueryError,
      _args: ApiUpdateDatevAccountingMonthApiArg,
    ) => (error == null ? [ApiTagTypes.DatevApi] : []),
  },
  apiChangeTenantDatevClient: {
    invalidatesTags: (
      _result: ApiChangeTenantDatevClientApiResponse,
      error: FetchBaseQueryError,
      _args: ApiChangeTenantDatevClientApiArg,
    ) => (error == null ? [ApiTagTypes.DatevApi] : []),
  },
};
