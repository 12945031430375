import { useTranslation } from 'react-i18next';
import React, { useRef, useState } from 'react';
import {
  Button,
  Form,
  FormField,
  FormWatch,
  Modal,
  TaskCompletedDottedIcon,
  TextInput,
  Wizard,
  WizardSlideContent,
  WizardSlideHeader,
} from '@client/shared/toolkit';
import {
  RequirementRejectFormValidationSchema,
  RequirementRejectFormValidationValues,
} from './RequirementFormValidationSchemas';
import { ClusterItemSelect } from './ClusterItemSelect';
import { RequirementReadModel, useApiPostUpdateClusterItemRequirementStatusMutation } from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { useLoadedPortfolioId } from '@client/portfolio/store';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useNavigate } from 'react-router-dom';

export const RequirementRejectWizard = ({
  onClose,
  requirement,
  setClusterItemChanged,
}: {
  onClose: () => void;
  requirement: RequirementReadModel;
  setClusterItemChanged: (changed: boolean) => void;
}) => {
  const { t } = useTranslation();
  const loadedPortfolioId = useLoadedPortfolioId();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [updateState, { isLoading }] = useApiPostUpdateClusterItemRequirementStatusMutation();

  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = async (data: RequirementRejectFormValidationValues) => {
    if (loadedPortfolioId) {
      try {
        await safeMutation(
          updateState,
          {
            portfolioId: loadedPortfolioId,
            clusterItemId: requirement.clusterItem.id,
            requirementId: requirement.id,
            body: {
              name: data.name,
              clusterItemId: data.clusterItemId,
              state: 'Rejected',
              comment: data.reason,
              reportedById: data.reportedById,
              reportedByDeputyId: data.reportedByDeputyId
            },
          },
          isLoading,
        );
        if (data.clusterChanged) {
          navigate(
            ROUTES_CONFIG.PORTFOLIO_PROJECTING_REQUIREMENT_VIEW.path
              .replace(':id', loadedPortfolioId ?? '')
              .replace(':clusterItemId', data.clusterItemId)
              .replace(':requirementId', requirement.id),
          );
        }
        setCurrentStep(currentStep + 1);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const defaultFormValues = {
    name: requirement.name,
    code: requirement.code,
    clusterItemId: requirement.clusterItem.id,
    reason: '',
    clusterChanged: false,
    reportedById: requirement.reportedById ?? '',
    reportedByDeputyId: requirement.reportedByDeputyId ?? ''
  };

  return (
    <Form<RequirementRejectFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={RequirementRejectFormValidationSchema}
      defaultValues={defaultFormValues}
      className="h-full"
      ref={formRef}
    >
      <FormWatch fieldNames={['clusterChanged']}>
        {({ clusterChanged }) => (
          <Wizard currentStep={currentStep} onSlideChange={(step) => setCurrentStep(step)}>
            <Wizard.Slides>
              <Wizard.Slide>
                <WizardSlideHeader title={t('portfolio.projecting.rejectWizard.title')} />
                <WizardSlideContent>
                  <div className="flex gap-0.5">
                    <FormField name="name">
                      {(control) => (
                        <TextInput
                          label={t('portfolio.projecting.requirementEdit.title')}
                          {...control}
                          className="flex-1"
                          helperTextClassName="bg-transparent"
                        />
                      )}
                    </FormField>
                    <FormField name="code">
                      {(control) => (
                        <TextInput
                          label={t('portfolio.projecting.requirementEdit.number')}
                          {...control}
                          disabled
                          className="w-[150px] flex-none"
                          helperTextClassName="bg-transparent"
                        />
                      )}
                    </FormField>
                  </div>
                  <ClusterItemSelect
                    formRef={formRef}
                    requirement={requirement}
                    onChange={(value) => {
                      formRef.current?.setValue('clusterChanged', value !== defaultFormValues.clusterItemId);
                      setClusterItemChanged(value !== defaultFormValues.clusterItemId);
                    }}
                    clusterChanged={clusterChanged}
                  />
                  <FormField name="reason">
                    {(control) => (
                      <TextInput
                        label={t('portfolio.projecting.requirementEdit.reason')}
                        inputType="textarea"
                        {...control}
                        helperTextClassName="bg-transparent"
                      />
                    )}
                  </FormField>
                </WizardSlideContent>
              </Wizard.Slide>
              <Wizard.Slide>
                <WizardSlideContent>
                  <WizardSlideHeader
                    icon={<TaskCompletedDottedIcon />}
                    title={t('portfolio.projecting.rejectWizard.successfullyRejected')}
                    subTitle={t('portfolio.projecting.rejectWizard.successfullyRejectedSubtitle')}
                    fullHeight
                  />
                </WizardSlideContent>
              </Wizard.Slide>
            </Wizard.Slides>
            <Wizard.Navigation>
              {({ isFirst, isLast }) => (
                <Modal.Controls>
                  {(() => {
                    if (isFirst) {
                      return (
                        <>
                          <Button variant="text" onClick={onClose}>
                            {t('common.cancel')}
                          </Button>
                          <Button variant="danger" formSubmit>
                            {t('portfolio.projecting.rejectWizard.reject')}
                          </Button>
                        </>
                      );
                    } else if (isLast) {
                      return (
                        <Button variant="primary" onClick={onClose}>
                          {t('portfolio.projecting.submitNotificationWizard.gotIt')}
                        </Button>
                      );
                    }
                  })()}
                </Modal.Controls>
              )}
            </Wizard.Navigation>
          </Wizard>
        )}
      </FormWatch>
    </Form>
  );
};
