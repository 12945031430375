import {
  AuthRoleReadModel,
  ProbisPermission,
  PermissionScope,
  useApiGetAuthPermissionDefinitionsQuery,
  useApiPostCreateAuthRoleMutation,
  useApiPostUpdateAuthRoleMutation,
  AuthPermissionDefinitionReadModel,
  CreateRoleCatalogTemplatePayload,
  CreateCatalogElementPermissionPayload,
  UpdateCatalogElementPermissionPayload,
  UpdateRoleCatalogTemplatePayload,
} from '@client/shared/api';
import { ArrowsPointingInIcon, RectangleGroupIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import {
  Button,
  Form,
  FormField,
  SlideOver,
  TextInput,
  Modal,
  SlideOverOnCloseProps,
  LoadingIndicator,
  BaseSelect,
  TagWindowIcon,
  UsersCheckCustomIcon, SettingsAddButton,
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { PermissionList } from '../permissions';
import { RoleFormValidationSchema, RoleFormValidationValues } from './RoleFormValidationSchema';
import { RoleDeleteModal } from './RoleDeleteModal';
import { TranslateRoleComment, TranslateRoleName } from './RoleTranslation';
import { getPermissionCategories } from '../permissions';
import {
  CatalogTemplateAddSlideOver,
  CatalogTemplateList,
  TemplateElementStatus,
  TemplateStatus,
  toTemplateStatus,
} from '../templates';

interface RoleDetailsSlideOverProps extends SlideOverOnCloseProps {
  isAddMode: boolean;
  role: AuthRoleReadModel | undefined;
}

type Subarea = 'Permissions' | 'Templates';

export const RoleDetailsSlideOver = ({ role, isAddMode, onClose }: RoleDetailsSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const scopeOptions: { label: string; value: PermissionScope }[] = [
    { label: t('auth.TenantPermissionScope'), value: 'Tenant' },
    { label: t('auth.ProjectPermissionScope'), value: 'Project' },
  ];

  const { data: permissionData, isFetching } = useApiGetAuthPermissionDefinitionsQuery();
  const [scope, setScope] = useState<PermissionScope>(role?.scope ?? 'Project');

  const [postUpdate, { isLoading: isUpdating }] = useApiPostUpdateAuthRoleMutation();
  const [postCreate, { isLoading: isCreating }] = useApiPostCreateAuthRoleMutation();

  const [subarea, setSubarea] = useState<Subarea>('Permissions');
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const [permissionDefinitions, setPermissionDefinitions] = useState<AuthPermissionDefinitionReadModel[]>([]);
  const [newPermissions, setNewPermissions] = useState<ProbisPermission[]>([]);
  const [deletedPermissions, setDeletedPermissions] = useState<ProbisPermission[]>([]);

  const [showAddTemplate, setShowAddTemplate] = useState<boolean>(false);

  const [assignedTemplates, setAssignedTemplates] = useState<TemplateStatus[]>([]);
  const [addedTemplates, setAddedTemplates] = useState<TemplateStatus[]>([]);
  const [deletedTemplates, setDeletedTemplates] = useState<TemplateStatus[]>([]);

  useEffect(() => {
    setPermissionDefinitions(permissionData?.filter((x) => x.scope === scope) ?? []);
    setAssignedTemplates(role?.catalogTemplates.map(toTemplateStatus) ?? []);
  }, [permissionData, scope, role]);

  const handleScopeChange = (newScope: PermissionScope) => {
    setScope(newScope);

    const wrongScopedPermissions = role?.permissions.filter((x) => x.scope !== newScope).map((y) => y.name) ?? [];
    handlePermissionsChanged([], wrongScopedPermissions);

    if (newScope === 'Tenant'){
      setDeletedTemplates(deletedTemplates.concat(assignedTemplates));
      setAddedTemplates([]);
      setAssignedTemplates([]);

      setSubarea('Permissions')
    } else {
      setAssignedTemplates(deletedTemplates);
      setDeletedTemplates([]);
    }
  };

  const defaultFormValues = {
    name: TranslateRoleName(role?.name ?? '', role?.systemRoleType ?? 'None'),
    comment: TranslateRoleComment(role?.comment ?? '', role?.systemRoleType ?? 'None'),
  };

  const handlePermissionsChanged = (
    newNewPermissions: ProbisPermission[],
    newDeletedPermissions: ProbisPermission[]
  ) => {
    setNewPermissions(newNewPermissions);
    setDeletedPermissions(newDeletedPermissions);
  };

  const handleAddTemplate = (newTemplates? : TemplateStatus[]) => {
    if (newTemplates){
      const templates = addedTemplates?.concat(newTemplates) ?? newTemplates;
      setAddedTemplates(templates)
    }
    setShowAddTemplate(!showAddTemplate);
  }

  const handleDeleteTemplate = (deletedTemplate : TemplateStatus) => {
    if (addedTemplates.includes(deletedTemplate)){
      setAddedTemplates(addedTemplates.filter(x => x.template.catalogTemplate.catalogTemplateId !== deletedTemplate.template.catalogTemplate.catalogTemplateId));
    } else if (assignedTemplates?.includes(deletedTemplate)){
      setAssignedTemplates(assignedTemplates.filter(x => x.template.id !== deletedTemplate.template.id));

      const newDeletedTemplates = deletedTemplates.slice();
      newDeletedTemplates.push(deletedTemplate);
      setDeletedTemplates(newDeletedTemplates);
    }
  }

  const handleUndoDeleteTemplate = (template : TemplateStatus) => {
    setDeletedTemplates(deletedTemplates.filter(x => x.template.id !== template.template.id));

    const newAssignedTemplates = assignedTemplates.slice();
    newAssignedTemplates.push(template);
    setAssignedTemplates(newAssignedTemplates);
  }

  const handleChangeElement = (template : TemplateStatus) => {

    const currentAddedTemplate = addedTemplates.find(x => x.template.catalogTemplate.catalogTemplateId === template.template.catalogTemplate.catalogTemplateId);
    if (currentAddedTemplate){
      const index = addedTemplates.indexOf(currentAddedTemplate);

      const newAddedTemplates = addedTemplates.slice();
      newAddedTemplates.splice(index, 1, template);
      setAddedTemplates(newAddedTemplates);
    }

    const currentAssignedTemplate = assignedTemplates.find(x => x.template.id === template.template.id);
    if (currentAssignedTemplate){
      const index = assignedTemplates.indexOf(currentAssignedTemplate);

      const newAssignedTemplates = assignedTemplates.slice();
      newAssignedTemplates.splice(index, 1, template);
      setAssignedTemplates(newAssignedTemplates);
    }
  }

  const toUpdateRoleCatalogTemplatePayload = (template: TemplateStatus) : UpdateRoleCatalogTemplatePayload => {
    return {
      catalogTemplateId: template.template.id,
      added: template.elements.filter(x => x.elementState === 'new').map(toCreateCatalogElementPermission),
      updated: template.elements.filter(x => x.elementState === 'changed').map(toUpdateCatalogElementPermission),
      deleted: template.elements.filter(x => x.elementState === 'deleted').map(x => x.element.id)
    }
  }

  const toUpdateCatalogElementPermission = (templateElement: TemplateElementStatus) : UpdateCatalogElementPermissionPayload => {
    return {
      catalogElementPermissionId: templateElement.element.id,
      canReadPermission : templateElement.element.canReadElement,
      canWritePermission : templateElement.element.canWriteElement
    }
  }

  const toCreateRoleCatalogTemplatePayload = (template: TemplateStatus) : CreateRoleCatalogTemplatePayload => {
    return {
      catalogTemplateId: template.template.catalogTemplate.catalogTemplateId,
      added: template.elements.map(toCreateCatalogElementPermission)
    };
  }

  const toCreateCatalogElementPermission = (templateElement: TemplateElementStatus) : CreateCatalogElementPermissionPayload => {
    return {
      costCatalogElementId: templateElement.element.costCatalogElement?.id,
      riskCatalogElementId: templateElement.element.riskCatalogElement?.id,
      earningsCatalogElementId: templateElement.element.earningsCatalogElement?.id,
      financingCatalogElementId: templateElement.element.financingCatalogElement?.id,
      canReadPermission : templateElement.element.canReadElement,
      canWritePermission : templateElement.element.canWriteElement,
    }
  }

  const handleRoleSubmit = async (data: RoleFormValidationValues) => {
    if (isAddMode) {
      try {
        await safeMutation(
          postCreate,
          {
            body: {
              name: data.name,
              comment: data.comment,
              scope: scope,
              addedPermissions: newPermissions,
              addedTemplates: addedTemplates.map(toCreateRoleCatalogTemplatePayload)
            },
          },
          isCreating
        );

        onClose(true);
      } catch {
        /* left blank */
      }
    } else {
      try {
        await safeMutation(
          postUpdate,
          {
            roleId: role?.id ?? '',
            body: {
              name: data.name,
              comment: data.comment,
              scope: scope,
              addedPermissions: newPermissions,
              deletedPermissions: deletedPermissions,
              addedTemplates: addedTemplates.map(toCreateRoleCatalogTemplatePayload),
              updatedTemplates: assignedTemplates.filter(x => x.elements.some(y => y.elementState !== 'unchanged')).map(toUpdateRoleCatalogTemplatePayload),
              deletedTemplates: deletedTemplates.map(x => x.template.id)
            },
          },
          isUpdating
        );

        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  return (
    <>
      <Form<RoleFormValidationValues>
        onSubmit={handleRoleSubmit}
        validationSchema={RoleFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
      >
        <SlideOver.Header
          title={role?.name ?? t('auth.roleNewRoleTitle')}
          backgroundClassName="bg-gray-600"
          onClose={() => onClose(false)}
        />

        <SlideOver.Content>
          {isFetching === true ? (
            <div className="m-2">
              <LoadingIndicator text={t('auth.permissionsLoadingIndicator') ?? ''} />
            </div>
          ) : (
            <>
              <div className="m-8 bg-white">
                <div className="divide-gray-100 divide-y">
                  <FormField name="scope">
                    {() => (
                      <BaseSelect
                        disabled={role?.isSystemRole}
                        label={t('auth.scope')}
                        options={scopeOptions}
                        value={scope}
                        icon={<ArrowsPointingInIcon className="w-5 h-5" />}
                        onChange={(value) => handleScopeChange(value as PermissionScope)}
                      />
                    )}
                  </FormField>
                  <FormField name="name">
                    {(control) => (
                      <TextInput
                        disabled={role?.isSystemRole}
                        label={t('common.name')}
                        icon={<TagWindowIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="comment">
                    {(control) => (
                      <TextInput
                        disabled={role?.isSystemRole}
                        label={t('auth.comment')}
                        icon={<TagWindowIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                </div>
              </div>

              <div className="flex flex-col px-8">
                <div className="flex flex-row mt-2">
                  <button
                    type="button"
                    className={classNames('px-4 pb-2 text text-gray-900 block', {
                      'font-bold border-b border-slate-200': subarea === 'Permissions',
                    })}
                    onClick={() => setSubarea('Permissions')}
                  >
                    <div className="flex flex-row items-center">
                      <UsersCheckCustomIcon className="w-5 h-5 mr-2" />
                      {t('auth.permissions')}
                    </div>
                  </button>
                  {scope === 'Project' &&
                    <button
                      type="button"
                      className={classNames('px-4 pb-2 text text-gray-900 block', {
                        'font-bold border-b border-slate-200': subarea === 'Templates',
                      })}
                      onClick={() => setSubarea('Templates')}
                    >
                      <div className="flex flex-row items-center">
                        <RectangleGroupIcon className="w-5 h-5 mr-2"/>
                        {t('auth.templatesPageTitle')}
                      </div>
                    </button>
                  }
                </div>

                {subarea === 'Permissions' && (
                  <PermissionList
                    allowEditing={!role?.isSystemRole}
                    permissionDefinitions={permissionDefinitions}
                    permissionCategories={getPermissionCategories(scope)}
                    grantedPermissions={role?.permissions.map((x) => x.name) ?? []}
                    newPermissions={newPermissions}
                    deletedPermissions={deletedPermissions}
                    selectedPermissionsChanged={handlePermissionsChanged}
                  />
                )}
                {scope === 'Project' && subarea === 'Templates' && (
                  <>
                    <CatalogTemplateList
                      assignedCatalogTemplates={assignedTemplates}
                      newCatalogTemplates={addedTemplates}
                      deletedCatalogTemplates={deletedTemplates}
                      handleDeleteTemplate={handleDeleteTemplate}
                      handleUndoDeleteTemplate={handleUndoDeleteTemplate}
                      handleChangeElement={handleChangeElement}
                    />
                    {!role?.isSystemRole && <SettingsAddButton onClick={() => setShowAddTemplate(true)} />}
                  </>
                )}
              </div>
            </>
          )}
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className="flex flex-grow flex-row justify-start">
            {!isAddMode && !role?.isSystemRole && (
              <Button variant="warning" onClick={() => setShowDelete(true)}>
                {t('common.delete')}
              </Button>
            )}
          </div>
          <div className="flex justify-end">
            <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
              {t('common.cancel')}
            </Button>
            {!role?.isSystemRole && (
              <Button variant="primary" formSubmit={true} innerRef={submitRef}>
                {t('common.save')}
              </Button>
            )}
          </div>
        </SlideOver.Controls>
      </Form>

      <Modal isOpen={showDelete} onClose={onClose} variant="small">
        <RoleDeleteModal
          role={role}
          onClose={(success: boolean) => {
            setShowDelete(false);

            if (success) {
              onClose(true);
            }
          }}
        />
      </Modal>

      <SlideOver
          isOpen={showAddTemplate}
          onClose={() => setShowAddTemplate(!showAddTemplate)}
        >
          <CatalogTemplateAddSlideOver
            roleId={role?.id ?? ''}
            assignedCatalogTemplates={role?.catalogTemplates ?? []}
            onClose={handleAddTemplate}
          />
        </SlideOver>
    </>
  );
};
