import classNames from 'classnames';
import React from 'react';

interface FlipSwitchProps {
  id?: string;
  checked?: boolean;
  className?: string;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  name?: string;
  autoComplete?: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const FlipSwitch = ({
  autoComplete,
  id,
  checked = false,
  className,
  name,
  disabled,
  onFocus,
  onChange,
  onBlur,
}: FlipSwitchProps) => {
  return (
    <div className="h-6 w-9">
      <input
        id={id}
        type="checkbox"
        className={classNames(
          'translate-y-1 appearance-none w-9 focus:outline-none checked:bg-cyan-700/50 h-[14px] bg-gray-800/50 rounded-full before:inline-block before:rounded-full before:bg-white checked:before:bg-cyan-700 before:h-5 before:w-5 before:-translate-y-[3px] checked:before:translate-x-[16px] before:shadow-md transition-all before:transition-all duration-300',
          {
            'before:bg-gray-200 cursor-not-allowed': disabled,
          },
          className
        )}
        checked={checked}
        disabled={disabled}
        autoComplete={autoComplete}
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={() => onChange(!checked)}
      />
    </div>
  );
};
