import { DecoratedCard, ProjectInfoCardMapContent } from '@client/shared/toolkit';
import { ProjectPayload } from '@client/shared/api';
import { useTranslation } from 'react-i18next';

interface LocationCardProps {
  project?: ProjectPayload | undefined;
}

export const LocationCard = ({ project }: LocationCardProps) => {
  const { t } = useTranslation();

  return (
    <DecoratedCard>
      <DecoratedCard.Header showActionButton={false}>
        <div className="truncate">{t('projectTaxonomy.Location')}</div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="h-[500px] w-full flex justify-center projects-center">
        <ProjectInfoCardMapContent latitude={project?.latitude} longitude={project?.longitude} />
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};
