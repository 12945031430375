import { XMarkIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { Button } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

export const PasswordResetSuccessfulView = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <>
      <div
        className="flex cursor-pointer"
        onClick={() => {
          navigate('/auth/login');
        }}
      >
        <XMarkIcon className="absolute -mt-6 ml-[23rem] w-8 h-8" fill="#6B7280" />
      </div>
      <div className="flex flex-row items-center mt-3 mb-6">
        <h1 className="text-4xl font-semibold text-primary">{t('auth.passwordResetPasswordChanged')}</h1>
      </div>
      <div className="text-gray-500">{t('auth.passwordResetPasswordChangedDescription')}</div>

      <div className="pt-48 flex place-content-end">
        <Button
          variant="primary"
          onClick={() => {
            navigate('/auth/login');
          }}
        >
          {t('auth.passwordResetPasswordChangedAction')}
        </Button>
      </div>
    </>
  );
};
