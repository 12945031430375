import classNames from 'classnames';
import React, { useId, useState, useEffect, useRef, useMemo } from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { isEmpty, getLocaleDateSeparator, getLocaleDateFormat, formatDateOnly } from '@client/shared/utilities';
import { ChevronLeftIcon, ChevronRightIcon, CalendarDaysIcon } from '@heroicons/react/20/solid';
import { FormHelperText } from './FormHelperText';
import { useLilius, Day } from 'use-lilius';
import {
  addDays,
  endOfMonth,
  format,
  getDate,
  getMonth,
  getYear,
  isToday,
  isValid,
  lastDayOfMonth,
  nextMonday,
  parse,
  startOfMonth,
  isEqual,
  isAfter,
  isBefore,
} from 'date-fns';
import { Button } from './Button';

interface DatePickerProps {
  label: string;
  placeHolder?: string;
  value?: Date | string | null;
  onChange: (value?: Date | null) => void;
  icon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  name?: string;
  inputClassName?: string;
  disablePortal?: boolean;
  showQuickButtons?: boolean;
  showJumpToToday?: boolean;
  showValidation?: boolean;
  isValidationValid?: boolean;
  helperText?: string;
  required?: boolean;
  onBlur?: () => void;
  onTogglePopover?: () => void;
  handlePopoverVisibility?: (isOpen: boolean) => void;
  size?: 'default' | 'small';
  customZIndex?: string;
}

const localeSettings: {
  separator: string | undefined;
  separatorRegex: RegExp | undefined;
  positionDay: number;
  positionMonth: number;
  positionYear: number;
} = {
  separator: undefined,
  separatorRegex: undefined,
  positionDay: 0,
  positionMonth: 1,
  positionYear: 2,
};

const allowedSeperators = '/.-';

export const DatePicker = ({
  onChange,
  value,
  label,
  icon,
  className,
  disabled,
  minDate,
  maxDate,
  name,
  inputClassName,
  placeHolder,
  showQuickButtons = false,
  showJumpToToday = false,
  showValidation,
  isValidationValid,
  disablePortal = false,
  helperText,
  required = false,
  onBlur,
  onTogglePopover,
  handlePopoverVisibility,
  size = 'default',
  customZIndex,
}: DatePickerProps) => {
  const inputId = useId();
  const inputRef = useRef<HTMLInputElement>(null);

  // causing issues, solved with setting focus when date is selected
  /* const focus = () => {
    inputRef.current?.focus();
  }; */

  if (localeSettings.separator === undefined) {
    localeSettings.separator = getLocaleDateSeparator() ?? '/';
    // FP: This regex definition looks a bit weird, but it should prevent regex injection by limiting the separator to a single character and adding an escape character.
    localeSettings.separatorRegex = new RegExp(`[^\\d\\${allowedSeperators}]+`, 'g');

    const positions = getLocaleDateFormat();

    localeSettings.positionDay = positions.day;
    localeSettings.positionMonth = positions.month;
    localeSettings.positionYear = positions.year;
  }

  localeSettings.separator = getLocaleDateSeparator();
  placeHolder =
    localeSettings.separator === '.'
      ? `tt${localeSettings.separator}mm${localeSettings.separator}jjjj`
      : `mm${localeSettings.separator}dd${localeSettings.separator}yyyy`;

  const inDate = useMemo(() => (value ? (value instanceof Date ? value : new Date(value)) : undefined), [value]);

  const {
    calendar,
    clearSelected,
    clearTime,
    inRange,
    isSelected,
    select,
    selected,
    setViewing,
    toggle,
    viewing,
    viewNextMonth,
    viewPreviousMonth,
    viewToday,
  } = useLilius({ weekStartsOn: Day.MONDAY, selected: inDate ? [inDate] : [] });

  // Extend inRange function from hook to support min and max dates
  const inRangeMinMax = (date: Date, min?: Date, max?: Date) => {
    if (!min && !max) {
      return true;
    }

    if (min && max) {
      return (isEqual(date, min) || isAfter(date, min)) && (isEqual(date, max) || isBefore(date, max));
    }

    if (min && !max) {
      return isEqual(date, min) || isAfter(date, min);
    }

    if (!min && max) {
      return isEqual(date, max) || isBefore(date, max);
    }

    return false;
  };

  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(inDate ? format(inDate, 'P') : '');
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  const canGoBack = minDate ? isAfter(startOfMonth(viewing), minDate) : true;
  const canGoForward = maxDate ? isBefore(endOfMonth(viewing), maxDate) : true;

  const handleFocusChange = (focus: boolean) => {
    setIsFocused(focus);
  };

  const handleDateChange = (date: Date | null) => {
    onChange(date);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    // Only accept digits and date separators as input
    if (event.key === 'Enter') {
      parseAndSetValue();
    }
  };

  const handleInputOnChange = (input: string) => {
    if (localeSettings.separatorRegex) {
      input = input.trim().replace(localeSettings.separatorRegex, '');
    }

    if (localeSettings.separator) {
      input = input.replace(new RegExp(`[^\\d${localeSettings.separator}]`, 'g'), '');

      if (input.length > 2 && !input.includes(localeSettings.separator, 1)) {
        input = input.slice(0, 2) + localeSettings.separator + input.slice(2);
      }

      if (input.length > 5 && !input.includes(localeSettings.separator, 3)) {
        input = input.slice(0, 5) + localeSettings.separator + input.slice(5);
      }
    }

    setInputValue(input);
  };

  // OnBlur of the input, we need to parse and set the date
  // Additionally, we need to make sure the input value is valid
  const parseAndSetValue = () => {
    handleFocusChange(false);

    if (inputValue === '') {
      clearSelected();

      handleDateChange(null);

      return;
    }

    const parts = inputValue.split(new RegExp(`[${allowedSeperators}]`, 'g'));
    const partsAsNumber = parts.map((p) => parseInt(p, 10));

    const dayFirst = localeSettings.positionDay < localeSettings.positionMonth ? 1 : 2;
    const monthFirst = localeSettings.positionDay < localeSettings.positionMonth ? 2 : 1;

    // Make sure the month is within the valid range of months (1 - 12).
    // If no month is given, default to the one we're looking at.
    if (parts.length < monthFirst) {
      // add 1 to the month because getMonth returns 0-11
      parts[localeSettings.positionMonth] = `${getMonth(viewing) + 1}`;
    } else if (partsAsNumber[localeSettings.positionMonth] < 1) {
      parts[localeSettings.positionMonth] = '1';
    } else if (partsAsNumber[localeSettings.positionMonth] > 12) {
      parts[localeSettings.positionMonth] = '12';
    }

    // If no year is given, default to the one we're looking at.
    // If the user passes in 2 digits, append them to the first 2 digits of the year we're looking at.
    if (parts.length < 3) {
      parts[localeSettings.positionYear] = `${getYear(viewing)}`;
    } else if (partsAsNumber[localeSettings.positionYear] > 9 && partsAsNumber[localeSettings.positionYear] < 100) {
      parts[localeSettings.positionYear] = `${
        Math.round(getYear(viewing) / 1000) * 1000 + partsAsNumber[localeSettings.positionYear]
      }`;
    }

    // month and year are now correct, so we can now get the last day of the month for the correct month of the year
    const firstDateOfMonthParts = [...parts];
    firstDateOfMonthParts[localeSettings.positionDay] = '1'; // we just use the 1. of the month here to get the last day of the month
    const parsedFirstDateOfMonth = parse(firstDateOfMonthParts.join(localeSettings.separator ?? '/'), 'P', new Date());

    // Make sure the day is within the valid range of days (1 - last day of the given month).
    // If no day is given, default to the first day.
    if (parts.length < dayFirst) {
      parts[localeSettings.positionDay] = '1';
    } else if (partsAsNumber[localeSettings.positionDay] < 1) {
      parts[localeSettings.positionDay] = '1';
    } else if (partsAsNumber[localeSettings.positionDay] > getDate(lastDayOfMonth(parsedFirstDateOfMonth))) {
      parts[localeSettings.positionDay] = `${getDate(lastDayOfMonth(parsedFirstDateOfMonth))}`;
    }

    let parsed = parse(parts.join(localeSettings.separator ?? '/'), 'P', new Date());

    if (isValid(parsed)) {
      // Ensure the parsed date is within the min and max dates
      if (minDate && isBefore(parsed, minDate)) {
        parsed = minDate;
      } else if (maxDate && isAfter(parsed, maxDate)) {
        parsed = maxDate;
      }

      select(parsed, true);

      handleDateChange(parsed);
    } else {
      setInputValue('');
    }
  };

  useEffect(() => {
    setInputValue(inDate ? format(inDate, 'P') : '');
  }, [inDate]);

  // On selection change, we want to update the input field and the currently viewed month
  useEffect(() => {
    setInputValue(selected.length > 0 ? format(selected[0], 'P') : '');
    setViewing(selected.length > 0 ? selected[0] : new Date());
  }, [selected, setViewing]);

  const handleTogglePopover = () => {
    if (onTogglePopover) {
      onTogglePopover();
    }
  };

  useEffect(() => {
    if (handlePopoverVisibility) {
      handlePopoverVisibility(!!popperElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperElement]);

  return (
    <div className={className}>
      <div className="w-full relative h-14 bg-white">
        <Popover onBlur={onBlur}>
          {({ open }) => (
            <>
              <div ref={setTargetElement}>
                <div
                  className={classNames('w-full relative h-14 flex flex-row bg-white outline-none peer', className, {
                    'shadow-[inset_0px_0px_0px_1px] shadow-red-500':
                      showValidation && isValidationValid != null && !isValidationValid,
                    'shadow-[inset_0px_0px_0px_1px] shadow-green-500': showValidation && isValidationValid,
                  })}
                >
                  <div
                    className={classNames('w-full flex', {
                      'px-3': size === 'default',
                      'px-1': size === 'small',
                    })}
                  >
                    {icon && (
                      <div className="flex items-center h-full">
                        <div className="h-5 w-5 flex items-center justify-center">{icon}</div>
                      </div>
                    )}
                    <div
                      className={classNames('relative flex-grow', {
                        'ml-2': icon,
                        'mx-1': !icon,
                        'flex items-center': !label
                      })}
                    >
                      <input
                        id={inputId}
                        className={classNames(
                          'fake-mt block w-full appearance-none focus:outline-none bg-transparent font-medium',
                          {
                            'text-gray-800': !disabled,
                            'text-gray-500 cursor-not-allowed': disabled,
                            'opacity-0': placeHolder && isEmpty(value) && !isFocused,
                            'text-lg': size === 'default',
                            'pt-5': label
                          },
                          inputClassName,
                        )}
                        value={inputValue}
                        type="text"
                        placeholder={placeHolder}
                        disabled={disabled}
                        autoComplete="off"
                        name={name}
                        onChange={(event) => handleInputOnChange(event.target.value)}
                        onFocus={() => handleFocusChange(true)}
                        onBlur={parseAndSetValue}
                        onKeyDown={handleOnKeyDown}
                        ref={inputRef}
                        required={required}
                        maxLength={10}
                      />
                      {label && (
                        <label
                          htmlFor={inputId}
                          className={classNames(
                            'absolute top-0 left-0 right-0 text-lg duration-200 origin-0 text-gray-600 select-none transform truncate pr-4 text-left',
                            {
                              'pt-3 mt-[3px]': isEmpty(value) && !isFocused,
                              'pt-5 -mt-px text-xs -translate-y-3': !isEmpty(value) || isFocused,
                            },
                          )}
                        >
                          {label}
                        </label>
                      )}
                    </div>
                    <PopoverButton
                      className={classNames('flex items-center pl-1 z-20', {
                        'text-gray-800 cursor-pointer': !disabled,
                        'text-gray-500 cursor-not-allowed': disabled,
                        'pr-3 -mr-3': size === 'default',
                        'pr-1': size === 'small',
                      })}
                      disabled={disabled}
                    >
                      <CalendarDaysIcon className="w-5 h-5" onClick={handleTogglePopover} />
                    </PopoverButton>
                  </div>
                </div>
                <div
                  className={classNames(
                    'absolute bottom-0 h-0.5 bg-black left-0 right-0 duration-200 transition-opacity peer-focus:opacity-100',
                    {
                      'opacity-0': !open,
                    },
                  )}
                />
              </div>
              <PopoverPanel
                portal={disablePortal === false}
                as="div"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className={classNames(
                  'date-picker-portal-wrapper min-w-[300px] w-1/4 flex items-center justify-center bg-white border border-gray-200 shadow-lg outline-none border-t-0 rounded-bl-lg rounded-br-lg',
                  customZIndex ?? 'z-20',
                )}
              >
                {({ close }) => (
                  <div className="flex flex-col h-96 w-full">
                    {showQuickButtons && (
                      <div className="flex items-center justify-center p-3 border-b space-x-3">
                        <Button onClick={() => select(clearTime(new Date()), true)}>Today</Button>
                        <Button onClick={() => select(addDays(clearTime(new Date()), 1), true)}>Tomorrow</Button>
                        <Button onClick={() => select(nextMonday(clearTime(new Date())), true)}>Next Monday</Button>
                      </div>
                    )}

                    <div className="p-3 flex flex-row items-center justify-between">
                      <Button
                        aria-label="Previous Month"
                        variant="secondary"
                        className="aspect-square"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          viewPreviousMonth();
                        }}
                        disabled={!canGoBack}
                      >
                        <ChevronLeftIcon className="w-5 h-5 text-black" />
                      </Button>

                      <div className="font-bold text-lg text-center">{format(viewing, 'MMMM yyyy')}</div>

                      <Button
                        aria-label="Next Month"
                        variant="secondary"
                        className="aspect-square"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          viewNextMonth();
                        }}
                        disabled={!canGoForward}
                      >
                        <ChevronRightIcon className="w-5 h-5 text-gray-800" />
                      </Button>
                    </div>

                    <div className="p-3 pt-0">
                      <div className="flex w-full justify-between text-sm text-gray-500">
                        {calendar[0][0].map((day) => (
                          <div key={`${day}`} className="w-[14%] text-center h-9">
                            {format(day, 'EEE')}
                          </div>
                        ))}
                      </div>

                      {calendar[0].map((week) => (
                        <div key={`week-${week[0]}`} className="flex justify-between">
                          {week.map((day) => {
                            const dayIsSelectable = inRangeMinMax(day, minDate, maxDate);

                            return (
                              <button
                                key={`${day}`}
                                onClick={() => {
                                  toggle(day, true);
                                  // set the input value...
                                  setInputValue(format(day, 'P'));
                                  // ...and set to focus, otherwise input field stays "empty" (opacity)
                                  setIsFocused(true);
                                  if (value && day && formatDateOnly(new Date(value)) === formatDateOnly(day)) {
                                    handleDateChange(null);
                                  } else {
                                    handleDateChange(day);
                                  }
                                  close();
                                }}
                                className={classNames(
                                  'w-[10%] text-center h-9 rounded-md flex items-center justify-center my-1',
                                  {
                                    'text-gray-400': !inRange(day, startOfMonth(viewing), endOfMonth(viewing)),
                                    'border border-blue-500 text-blue-500': isToday(day),
                                    'bg-blue-500 text-white': isSelected(day),
                                    'hover:bg-blue-100 hover:text-blue-500': dayIsSelectable,
                                    'text-gray-400 bg-gray-200': !dayIsSelectable,
                                  },
                                )}
                                disabled={!dayIsSelectable}
                              >
                                {format(day, 'dd')}
                              </button>
                            );
                          })}
                        </div>
                      ))}
                    </div>

                    {showJumpToToday && (
                      <div className="flex items-center justify-center p-3 border-t">
                        <Button onClick={viewToday}>Jump to Today</Button>
                      </div>
                    )}
                  </div>
                )}
              </PopoverPanel>
            </>
          )}
        </Popover>
      </div>
      {helperText && (
        <div className="w-full bg-white">
          <FormHelperText text={helperText} error={!isValidationValid} />
        </div>
      )}
    </div>
  );
};
