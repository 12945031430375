import { useSearchParams } from 'react-router-dom';

// for some reason typescript does not correctly infer the types when returning
// from the function below.
// As a workaround I'm giving exact types as a return type
type Dictionary = { [k: string]: string | undefined };
type SetSearch = ReturnType<typeof useSearchParams>[1];

/**
 * Get query parameters as plain javascript object
 * @returns Javascript object with search/query parameters
 */
export const useCustomSearchParams = (): [Dictionary, SetSearch] => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = Object.fromEntries(new URLSearchParams(search));

  return [searchAsObject, setSearch];
};
