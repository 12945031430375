import React, { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SlideOverOnCloseProps,
  SlideOverWithTabs,
  SlideOverTabOptions
} from '@client/shared/toolkit';
import { TaxonomyReadModel } from '@client/shared/api';
import { useLoadedProject } from '@client/project/store';
import { getBuildingLabel } from '../utils';
import { getTaxonomyIcon } from '@client/project/shared';
import { TaxonomyBuildingData } from './TaxonomyBuildingData';
import { TaxonomyDocuments } from './TaxonomyDocuments';

export interface TaxonomyDashboardProps extends SlideOverOnCloseProps, PropsWithChildren {
  item?: TaxonomyReadModel | undefined;
  headerMenu?: React.ReactNode;
  isReadOnly: boolean;
}

export const TaxonomyDashboard = (props: TaxonomyDashboardProps) => {
  const { item, onClose } = props
  const { t } = useTranslation()
  const loadedProject = useLoadedProject();

  const address = `${loadedProject?.data?.project.payload.street ?? ''}  ${
    loadedProject?.data?.project.payload.number ?? ''
  }`;

  const icon = useMemo(() => {
    const IconComponent = getTaxonomyIcon(item?.itemType ?? 'Building')
    return <IconComponent className="w-8 h-8" />
  }, [item?.itemType])

  const tabOptions: SlideOverTabOptions[] = [
    {
      header: t('projectTaxonomy.taxonomyType.Building'),
      name: 'building',
      panel:
        <TaxonomyBuildingData
          {...props}
        />
    },
    {
      header: t('common.documents'),
      name: 'documents',
      panel:
        <TaxonomyDocuments
          {...props}
        />
    }
  ]

  return (
    <SlideOverWithTabs
      hasPadding={false}
      icon={icon}
      preTitle={t(getBuildingLabel(item))}
      title={item?.itemName}
      subtitle={item?.parent?.itemName ? item.parent.itemName : address}
      tabOptions={tabOptions}
      onClose={onClose}
    />
  );
};
