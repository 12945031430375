import * as yup from 'yup';
import { CashFlowReferenceDate, CashFlowReferenceValue } from '@client/shared/api';
import { InferType } from 'yup';

export const CashFlowSettingsValidationSchema = yup.object({
  date: yup.date().optional().nullable(),
  dateRef: yup.mixed<CashFlowReferenceDate>().oneOf(['ReceivedDate', 'InvoiceDate', 'ApprovalDate', 'PaymentDate']).optional().nullable(),
  valueRef: yup.mixed<CashFlowReferenceValue>().oneOf(['Invoice', 'Payment', 'AbsolutePayment']).optional().nullable(),
  overridePlan: yup.bool().optional().nullable(),
});

export type CashFlowSettingsValidationValues = InferType<typeof CashFlowSettingsValidationSchema>;
