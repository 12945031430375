import { useMemo } from 'react';
import { HierarchyListProps } from '../HierarchyListProps';
import cn from 'classnames';
import { ClusterListItem } from './ClusterListItem';

export interface ClusterListProps extends HierarchyListProps {
  theme?: 'cluster' | 'requirement'
}

export const ClusterList = (props: ClusterListProps) => {
  const {
    className,
    listItems = [],
    level = 0,
    customColor,
    defaultOpen,
    onToggle,
    theme = 'cluster'
  } = props;

  const borderColor = 'border-sky-900';

  const collapsibleList = useMemo(() => {
    if (listItems.length > 0) {
      return (
        <div className={cn('flex flex-col', className)}>
          {listItems.map((listItem, i) => (
            <ClusterListItem
              key={`cluster-list-item-${i}`}
              level={level}
              theme={theme}
              customColor={customColor}
              defaultOpen={defaultOpen}
              onToggle={onToggle}
              {...listItem}
            />
          ))}
        </div>
      )
    }
    return null
  }, [className, listItems, level, theme, customColor, defaultOpen, onToggle])

  if (level === 0 && listItems.length) {
    return (
      <div className={cn('relative w-full', className)}>
        <div
          className={cn('w-[calc(100%_-28px)] rounded absolute left-7 top-0 h-full shadow-lg border-l-[6px] z-0 pointer-events-none bg-sky-900', borderColor)}/>
        <div className="w-full flex flex-col relative z-5 overflow-hidden rounded-tr">
          {collapsibleList}
        </div>
      </div>
    )
  }

  return collapsibleList
}
