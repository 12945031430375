/**
 * Gets a text color class based on a hex color.
 * Based on https://stackoverflow.com/a/3943023/112731
 * @param {string} hex - The hex color.
 * @param {Object} options - Options.
 * @param {string} options.classNameDark - The dark class name. Defaults to 'text-black'.
 * @param {string} options.classNameLight - The light class name. Defaults to 'text-white'.
 * @returns {string} The text color class.
 */
export const getTextColorClass = (
  hex: string,
  { classNameDark = 'text-black', classNameLight = 'text-white' } = {}
) => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }

  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  if (hex.length !== 6) {
    return classNameDark;
  }

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? classNameDark : classNameLight;
};
