import { DealReadModel } from '@client/shared/api';
import { useState } from 'react';
import { SlideOver, AddButton, SlideOverTitle } from '@client/shared/toolkit';
import { DealContactCard } from './DealContactCard';
import { DealContactSlideOver } from './DealContactSlideOver';
import { useTranslation } from 'react-i18next';
import { useCanWriteDeal } from '../../hooks';

interface DealContactsProps {
  deal: DealReadModel;
  isReadOnly:boolean;
}

export const DealContacts = ({ deal: { contacts, id, type}, isReadOnly }: DealContactsProps) => {
  const { t } = useTranslation();

  const readOnly = !useCanWriteDeal(type) || isReadOnly;

  const [showAddForm, setShowAddForm] = useState<boolean>(false);

  return (
    <div>
      <SlideOverTitle title={t('projectRent.dealContactSectionTitleContractualPartner')} />
      <div className="flex flex-wrap">
        {contacts.map((x) => (
          <div key={x.id} className="mr-5 mb-2">
            <DealContactCard contact={x} dealId={id} type={type} isReadOnly={isReadOnly}/>
          </div>
        ))}
        <div className="flex items-center">
          {!readOnly && <AddButton onClick={() => setShowAddForm(true)} />}
        </div>
      </div>

      <SlideOver isOpen={showAddForm} onClose={() => setShowAddForm(false)}>
        <DealContactSlideOver isAddMode={true} dealId={id} type={type} onClose={() => setShowAddForm(false)} />
      </SlideOver>
    </div>
  );
};
