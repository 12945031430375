import { DealContactReadModel, DealType } from '@client/shared/api';
import { useState } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { SlideOver, Badge, PhoneIcon, AtSignIcon } from '@client/shared/toolkit';
import { DealContactSlideOver } from './DealContactSlideOver';
import { useTranslation } from 'react-i18next';
import { getInitials } from '@client/shared/utilities';

interface DealContactCardProps {
  dealId: string;
  type : DealType;
  contact: DealContactReadModel;
  isReadOnly:boolean;
}

export const DealContactCard = ({ contact, dealId, type, isReadOnly }: DealContactCardProps) => {
  const { t } = useTranslation();

  const [showEditForm, setShowEditForm] = useState<boolean>(false);

  return (
    <div className="bg-white shadow-md rounded-md p-4">
      <div className="flex">
        <div className="w-14">
          <div className="flex items-center justify-center bg-slate-200 text-slate-600 text-2xl font-bold rounded-full h-14 w-14">
            {getInitials(contact.name)}
          </div>
        </div>

        <div className="ml-2 w-48">
          <div className="font-bold text-xl">{contact.company}</div>
          <div>{contact.name}</div>
          {contact.role && <Badge variant="secondary" text={contact.role} />}
        </div>

        {!isReadOnly && (
          <div className="text-gray-500 hover:text-gray-800">
            <button onClick={() => setShowEditForm(true)}>
              <EllipsisVerticalIcon className="w-6 h-6" />
            </button>
          </div>
        )}
      </div>

      <div className="flex mt-2">
        <div className="flex w-14 items-center justify-end">
          <AtSignIcon className="w-6 h-6" />
        </div>
        <div className="ml-2">
          <div className="text-xs">{t('common.email')}</div>
          <div className="font-semibold">{contact.email}</div>
        </div>
      </div>

      <div className="flex">
        <div className="flex w-14 items-center justify-end">
          <PhoneIcon className="w-6 h-6" />
        </div>
        <div className="ml-2">
          <div className="text-xs">{t('common.phoneNumber')}</div>
          <div className="font-semibold">{contact.mobile}</div>
        </div>
      </div>

      <SlideOver isOpen={showEditForm} onClose={() => setShowEditForm(false)}>
        <DealContactSlideOver
          isAddMode={false}
          dealId={dealId}
          type={type}
          contact={contact}
          onClose={() => setShowEditForm(false)}
        />
      </SlideOver>
    </div>
  );
};
