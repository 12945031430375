import { ModalOnCloseProps, ConfirmModal } from '@client/shared/toolkit';
import { Trans, useTranslation } from 'react-i18next';

interface UserDeleteModalProps extends ModalOnCloseProps {
  name: string;
}

export const UserDeleteModal = ({ name, onClose }: UserDeleteModalProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmModal
      title={`${t('common.delete')} ${name}`}
      onConfirm={() => onClose(true)}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      <Trans i18nKey="auth.groupDeleteConfirmation" values={{ entity: `${name}` }}>
        <div className="block">
          Do you want to delete
          <span className="font-bold">Role</span>?<span className="font-bold">Group</span>?
        </div>
        <div className="inline-block pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
