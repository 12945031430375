import cn from 'classnames';
import React, { Fragment, PropsWithChildren, ReactNode, useMemo } from 'react';
import {
  DashboardWidgetAssets,
  DashboardWidgetMaBVRate,
  DashboardWidgetProjectMetadata,
  DashboardWidgetCashFlowChart,
  DashboardWidgetIsShouldChart,
  DashboardWidgetProjectCustomFields,
  DashboardWidgetProjectMilestones,
  DashboardWidgetMixed,
} from '../DashboardWidgets';
import { ProjectDashboardCustomDataType } from '@client/shared/api';

export enum DashboardWidgetType {
  'ProjectCustomFields',
  'ProjectMetaDataAddress',
  'ProjectMetaDataGrz',
  'ProjectMetaDataCustom',
  'MaBVRate',
  'Assets',
  'CashFlowChart',
  'IsShouldChart',
  'ProjectMilestones',
  'Mixed',
}

export interface DashboardColProps {
  widgets: DashboardWidgetProps[];
  colSpan?: string;
  columns?: string;
  children?: DashboardColProps[];
  index?: number;
  className?: string;
}

export interface DashboardCustomConfig {
  cols: DashboardColProps[];
  grid?: string;
}

export type DashboardWidgetDataFeedConfig = {
  name: string;
  icon: ReactNode;
  title?: string;
  size?: 'large' | 'default';
  variant?: 'feed' | 'other';
  hideIfEmpty?: boolean;
  type?: ProjectDashboardCustomDataType;
};

export type DashboardWidgetVariant = 'card' | 'child';

export interface DashboardWidgetProps extends PropsWithChildren {
  type: DashboardWidgetType;
  title?: string;
  col?: number;
  order?: number;
  colSpan?: string;
  config?: DashboardWidgetDataFeedConfig[];
  widgets?: DashboardWidgetProps[];
  variant?: string;
  icon?: ReactNode | string;
}

export interface DashboardCustomProps {
  config: DashboardCustomConfig;
}

export const DashboardCustom = (props: DashboardCustomProps) => {
  const { config } = props;

  const gridCols = useMemo(() => {
    return config.grid ? config.grid : `grid-cols-${config.cols.length}`;
  }, [config.grid, config.cols.length]);

  return (
    <div className={cn('mb-4 grid gap-[15px] content-start', gridCols)}>
      {config.cols.map((col, index) => (
        <DashboardCustomCol {...col} key={`dashboard-col-${index}`} index={index} />
      ))}
    </div>
  );
};

export const getWidget = (widget: DashboardWidgetProps, variant: 'card' | 'child' = 'card') => {
  switch (widget.type) {
    case DashboardWidgetType.ProjectCustomFields:
      return (
        <DashboardWidgetProjectCustomFields
          config={widget.config}
          variant={variant}
          title={widget.title}
          icon={widget.icon}
        >
          {widget.widgets?.length && (
            <DashboardWidgetMixed widgets={widget.widgets} variant="child">
              {widget.children}
            </DashboardWidgetMixed>
          )}
        </DashboardWidgetProjectCustomFields>
      );
    case DashboardWidgetType.ProjectMetaDataAddress:
      return <DashboardWidgetProjectMetadata type="address" />;
    case DashboardWidgetType.ProjectMetaDataCustom:
      return (
        <DashboardWidgetProjectMetadata type="custom" variant={variant} config={widget.config? widget.config[0] : undefined}>
          {widget.widgets?.length && (
            <DashboardWidgetMixed widgets={widget.widgets} variant="child">
              {widget.children}
            </DashboardWidgetMixed>
          )}
        </DashboardWidgetProjectMetadata>
      );
    case DashboardWidgetType.ProjectMetaDataGrz:
      return (
        <DashboardWidgetProjectMetadata type="grz" variant={variant}>
          {widget.widgets?.length && (
            <DashboardWidgetMixed widgets={widget.widgets} variant="child">
              {widget.children}
            </DashboardWidgetMixed>
          )}
        </DashboardWidgetProjectMetadata>
      );
    case DashboardWidgetType.MaBVRate:
      return <DashboardWidgetMaBVRate />;
    case DashboardWidgetType.Assets:
      return <DashboardWidgetAssets />;
    case DashboardWidgetType.CashFlowChart:
      return <DashboardWidgetCashFlowChart />;
    case DashboardWidgetType.IsShouldChart:
      return <DashboardWidgetIsShouldChart />;
    case DashboardWidgetType.ProjectMilestones:
      return (
        <DashboardWidgetProjectMilestones config={widget.config} variant={variant}>
          {widget.widgets?.length && (
            <DashboardWidgetMixed widgets={widget.widgets} variant="child">
              {widget.children}
            </DashboardWidgetMixed>
          )}
        </DashboardWidgetProjectMilestones>
      );
    case DashboardWidgetType.Mixed:
      return <DashboardWidgetMixed widgets={widget.widgets ?? []}>{widget.children}</DashboardWidgetMixed>;
    default:
      return null;
  }
};

export const DashboardCustomCol = (props: DashboardColProps) => {
  const { columns, widgets, children = [], index = 0, className } = props;

  return (
    <div className={cn('relative break-inside-avoid-column', columns, 'gap-[15px]', className)}>
      {widgets.map((widget, widgetIndex) => {
        return (
          <Fragment key={`dashboard-col-${index}-widget-${widgetIndex}`}>
            <div className={cn(widget.colSpan ? widget.colSpan : 'col-span-full', '[&:not(:first-child)]:mt-[15px]')}>
              {getWidget(widget)}
            </div>
            {children.length > 0 &&
              children.map((colChild, colChildIndex) => (
                <DashboardCustomCol
                  {...colChild}
                  key={`dashboard-col-${index}-widget-${widgetIndex}-child-${colChildIndex}`}
                  className="mt-[15px]"
                />
              ))}
          </Fragment>
        );
      })}
    </div>
  );
};
