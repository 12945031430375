import { UnitSystemState } from './unitSystemSlice';
import { useSelector } from 'react-redux';
import { useLoadedProject } from './';

type RootState = {
  unitSystem: UnitSystemState;
};

export const useUnitSystem = () => {
  const loadedProject = useLoadedProject();

  const unitSystemProject = loadedProject?.data?.project.payload.unitSystem;
  const projectId = loadedProject?.data?.project.payload.id ?? '';

  const unitSystemState = useSelector((state: RootState) => state.unitSystem).unitSystem.find((unitSystem) => unitSystem.id === projectId);

  return unitSystemState?.unitSystem ?? unitSystemProject ?? 'Metric';
};
