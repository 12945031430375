import { TitleBar, useDocumentTitle } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useLoadedProject } from '@client/project/store';
import { UploadWizard } from '@client/project/shared';

export const UploadRoute = () => {
  const { t } = useTranslation();
  const { data: loadedProject } = useLoadedProject();

  useDocumentTitle({ title: t('projectUpload.routeTitle') });

  return (
    <>
      <TitleBar
        title={loadedProject?.project?.payload?.name ?? t('projectUpload.routeTitle')}
        subTitle={t('projectUpload.routeSubTitle')}
      />
      <div className="flex-grow flex flex-col overflow-hidden">
        <div className="px-4 py-8 md:px-8 flex flex-col flex-grow overflow-y-auto">
          {loadedProject?.project?.payload?.projectId && (
            <UploadWizard loadedProjectId={loadedProject.project.payload.projectId } />
          )}
        </div>
      </div>
    </>
  )
}

