import { useApiPostUpdateUserEmailMutation } from '@client/shared/api';
import {
  Button,
  SlideOver,
  SlideOverOnCloseProps,
  TextInput,
  Form,
  FormField,
  LoadingIndicator
} from '@client/shared/toolkit';
import { ChangeEmailFormValidationSchema, ChangeEmailFormValidationValues } from './ChangeEmailFormValidationSchema';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

export const ChangeEmailSlideOver = ({ onClose }: SlideOverOnCloseProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const [postChangeMail, { isLoading }] = useApiPostUpdateUserEmailMutation();

  const defaultFormValues = { password: '', newEmail: '' };

  const handleSubmit = async (data: ChangeEmailFormValidationValues) => {
    try {
      await postChangeMail({
        body: {
          newEmail: data.newEmail,
          password: data.password,
        },
      }).unwrap();

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Form<ChangeEmailFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={ChangeEmailFormValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
    >
      {isLoading && <LoadingIndicator text={t('common.updating')} mode="overlay-window" />}
      <SlideOver.Header
        title={t('app.loginSecurityEditMail')}
        subTitle={''}
        backgroundClassName="bg-blue-900"
        onClose={onClose}
      />
      <SlideOver.Content className="p-8">
        <FormField name="newEmail">
          {(control) => <TextInput label={t('app.loginSecurityNewEmail')} autoComplete="new-email" {...control} />}
        </FormField>
        <FormField name="password">
          {(control) => (
            <TextInput
              label={t('app.loginSecurityCurrentPassword')}
              inputType="password"
              autoComplete="current-password"
              {...control}
            />
          )}
        </FormField>
      </SlideOver.Content>

      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.cancel')}
        </Button>
        <Button disabled={isLoading} formSubmit={true} innerRef={submitRef}>
          {t('common.save')}
        </Button>
      </SlideOver.Controls>
    </Form>
  )
};
