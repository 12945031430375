import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { ApiAccessReadModel, DocuWareConditionPayload, useApiGetFetchFieldsQuery } from '@client/shared/api';
import { List, AddButton, BaseSelectOption, LoadingIndicator } from '@client/shared/toolkit';
import { DocuwareConditionListItem } from './DocuwareConditionListItem';

interface DocuwareClientSlideOverConditionsTabProps {
  apiId: string;
  apiAccessId: string;
  readModel: ApiAccessReadModel | null | undefined;
  updateConditions: (conditions: DocuWareConditionPayload[]) => void;
}

export const DocuwareClientSlideOverConditionsTab = ({
  apiId,
  apiAccessId,
  readModel,
  updateConditions,
}: DocuwareClientSlideOverConditionsTabProps) => {
  const { t } = useTranslation();

  const { data, isFetching } = useApiGetFetchFieldsQuery(
    {
      accessId: apiAccessId,
    },
    {
      skip: !apiAccessId,
    },
  );

  const [queries, setQueries] = useState<DocuWareConditionPayload[]>([]);
  const [approved, setApproved] = useState<DocuWareConditionPayload[]>([]);
  const [rejected, setRejected] = useState<DocuWareConditionPayload[]>([]);

  const options = useMemo(() => {
    return (data?.fields?.map((field) => {
      return {
        label: field.displayName,
        value: field.label,
      };
    }) ?? []) as BaseSelectOption[];
  }, [data?.fields]);

  useEffect(() => {
    const conditionsPayload =
      readModel?.docuWareAccessConfiguration?.conditions.map((item) => {
        return {
          type: item.type,
          displayName: data?.fields?.find((field) => field.label === item.label)?.displayName ?? '',
          label: item.label,
          value: item.value,
        };
      }) ?? [];

    setQueries([...conditionsPayload.filter((item) => item.type === 'Query')]);
    setApproved([...conditionsPayload.filter((item) => item.type === 'Approved')]);
    setRejected([...conditionsPayload.filter((item) => item.type === 'Rejected')]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, readModel?.docuWareAccessConfiguration?.conditions]);

  useEffect(() => {
    const fields = data?.fields;
    const conditions = [...queries, ...approved, ...rejected];
    const conditionsPayload = conditions.map((item) => {
      return {
        type: item.type,
        displayName: fields?.find((field) => field.label === item.label)?.displayName ?? '',
        label: item.label,
        value: item.value,
      };
    });

    updateConditions(conditionsPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approved, queries, rejected]);

  const addListItem = (type: 'Query' | 'Approved' | 'Rejected') => {
    if (type === 'Query') {
      setQueries([...queries, { type: type, displayName: '', label: '', value: '' }]);
    } else if (type === 'Approved') {
      setApproved([...approved, { type: type, displayName: '', label: '', value: '' }]);
    } else if (type === 'Rejected') {
      setRejected([...rejected, { type: type, displayName: '', label: '', value: '' }]);
    }
  };

  return (
    <>
      {isFetching && <LoadingIndicator text={t('common.loading')} mode="overlay" />}
      <>
        {readModel ? (
          <div className="flex flex-col gap-3">
            <List
              className="mt-10"
              listTitle={t('app.settingsApiDocuwareQuery')}
              items={queries.map((item, index) => {
                return {
                  id: `query-${index}`,
                  children: (
                    <DocuwareConditionListItem
                      id={index}
                      options={options}
                      selected={item.label}
                      query={item.value}
                      onUpdate={(id, selected, query) => {
                        const updatedQueries = [...queries];
                        updatedQueries[index] = {
                          type: 'Query',
                          displayName: '',
                          label: selected,
                          value: query ?? '',
                        };
                        setQueries(updatedQueries);
                      }}
                      onDelete={(id) => {
                        const updatedQueries = [...queries];
                        updatedQueries.splice(id, 1);
                        setQueries(updatedQueries);
                      }}
                    />
                  ),
                };
              })}
              showPagination={false}
            />
            <div className="w-full justify-end">
              <AddButton
                className="float-right"
                onClick={() => {
                  addListItem('Query');
                }}
              />
            </div>
            <List
              className="mt-10"
              listTitle={t('app.settingsApiDocuwareApproved')}
              items={approved.map((item, index) => {
                return {
                  id: `approved-${index}`,
                  children: (
                    <DocuwareConditionListItem
                      id={index}
                      options={options.filter((option) =>
                        option.value === item.label
                          ? true
                          : !approved.some((condition) => condition.label === option.value) &&
                            option.value !== item.label,
                      )}
                      selected={item.label}
                      query={item.value}
                      onUpdate={(id, selected, query) => {
                        const updatedApproved = [...approved];
                        updatedApproved[index] = {
                          type: 'Approved',
                          displayName: '',
                          label: selected,
                          value: query ?? '',
                        };
                        setApproved(updatedApproved);
                      }}
                      onDelete={(id) => {
                        const updatedApproved = [...approved];
                        updatedApproved.splice(id, 1);
                        setApproved(updatedApproved);
                      }}
                    />
                  ),
                };
              })}
              showPagination={false}
            />
            <div className="w-full justify-end">
              <AddButton
                className="float-right"
                onClick={() => {
                  addListItem('Approved');
                }}
              />
            </div>
            <List
              className="mt-10"
              listTitle={t('app.settingsApiDocuwareRejected')}
              items={rejected.map((item, index) => {
                return {
                  id: `rejected-${index}`,
                  children: (
                    <DocuwareConditionListItem
                      id={index}
                      options={options.filter((option) =>
                        option.value === item.label
                          ? true
                          : !rejected.some((condition) => condition.label === option.value) &&
                            option.value !== item.label,
                      )}
                      selected={item.label}
                      query={item.value}
                      onUpdate={(id, selected, query) => {
                        const updatedRejected = [...rejected];
                        updatedRejected[index] = {
                          type: 'Rejected',
                          displayName: '',
                          label: selected,
                          value: query ?? '',
                        };
                        setRejected(updatedRejected);
                      }}
                      onDelete={(id) => {
                        const updatedRejected = [...rejected];
                        updatedRejected.splice(id, 1);
                        setRejected(updatedRejected);
                      }}
                    />
                  ),
                };
              })}
              showPagination={false}
            />
            <div className="w-full justify-end">
              <AddButton
                className="float-right"
                onClick={() => {
                  addListItem('Rejected');
                }}
              />
            </div>
          </div>
        ) : (
          t('app.settingsApiDocuwareNoMasterDataAvailable')
        )}
      </>
    </>
  );
};
