import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FilterState } from './filterSlice';

type RootState = {
  filters: FilterState;
};

export const useFilterState = () => {
  const filtersList = useSelector<RootState, FilterState>((x) => x.filters);
  return useMemo(() => filtersList, [filtersList]);
};

export const useToggledCostFilters = () => {
  return useFilterState().Cost;
};

export const useToggledProjectFilters = () => {
  return useFilterState().Project;
};

export const useToggledTaxonomyFilters = () => {
  return useFilterState().Taxonomy;
};

export const useToggledContractFilters = () => {
  return useFilterState().Contract;
};

export const useToggledContractTitleFilters = () => {
  return useFilterState().ContractTitle;
};

export const useToggledInvoiceFilters = () => {
  return useFilterState().Invoice;
};

