import { useEffect, useState } from 'react';
import { PermissionCategoryDefinition, ProbisPermissionStatus, getNewStateWithSelectAll } from './PermissionUtilities';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { PermissionGroupRow } from './PermissionGroupRow';
import { CheckBox } from '@client/shared/toolkit';
import { ProbisPermission } from '@client/shared/api';

interface PermissionCategoryRowProps {
  permissionCategory: PermissionCategoryDefinition,
  permissionStatuses: ProbisPermissionStatus[] | undefined;
  allowEditing: boolean;
  changeState: (newStatus: ProbisPermissionStatus[]) => void;
}

export const PermissionCategoryRow = ({ allowEditing, changeState, permissionStatuses, permissionCategory }: PermissionCategoryRowProps) => {
    const [expanded, setExpanded] = useState<boolean>(true);

    const [allReadPermissionsSelected, setAllReadPermissionsSelected] = useState<boolean>(false);
    const [allWritePermissionsSelected, setAllWritePermissionsSelected] = useState<boolean>(false);
    const [allDeletePermissionsSelected, setAllDeletePermissionsSelected] = useState<boolean>(false);
    const [allExecutePermissionsSelected, setAllExecutePermissionsSelected] = useState<boolean>(false);

    const allReadPermissionsVisible = permissionCategory.groups.some(x => x.readPermission);
    const allWritePermissionsVisible = permissionCategory.groups.some(x => x.writePermission);
    const allDeletePermissionsVisible = permissionCategory.groups.some(x => x.deletePermission);
    const allExecutePermissionsVisible = permissionCategory.groups.some(x => x.executePermission);

    useEffect(() =>{
        setAllReadPermissionsSelected(permissionCategory.groups
            .every(x => permissionStatuses?.some(y => !x.readPermission || (y.permission === x.readPermission && (y.state === 'Granted' || y.state === 'New')))));
        setAllWritePermissionsSelected(permissionCategory.groups
            .every(x => permissionStatuses?.some(y => !x.writePermission || (y.permission === x.writePermission && (y.state === 'Granted' || y.state === 'New')))));
        setAllDeletePermissionsSelected(permissionCategory.groups
            .every(x => permissionStatuses?.some(y => !x.deletePermission || (y.permission === x.deletePermission && (y.state === 'Granted' || y.state === 'New')))));
        setAllExecutePermissionsSelected(permissionCategory.groups
            .every(x => permissionStatuses?.some(y => !x.executePermission || (y.permission === x.executePermission && (y.state === 'Granted' || y.state === 'New')))));
    }, [permissionStatuses, permissionCategory])

    const handleAllReadPermissionsChanged = () => {
        const readPermisisons = permissionCategory.groups
            .filter(x => x.readPermission)
            .map(x => x.readPermission);

        const allSelected = !allReadPermissionsSelected;
        const newStatuses = getChangedPermissionStatuses(allSelected, readPermisisons);

        changeState(newStatuses);
        setAllReadPermissionsSelected(allSelected);
    }

    const handleAllWritePermissionsChanged = () => {
        const writePermisisons = permissionCategory.groups
            .filter(x => x.writePermission)
            .map(x => x.writePermission);

        const allSelected = !allWritePermissionsSelected;
        const newStatuses = getChangedPermissionStatuses(allSelected, writePermisisons);

        changeState(newStatuses);
        setAllWritePermissionsSelected(allSelected);
    }

    const handleAllDeletePermissionsChanged = () => {
        const deletePermisisons = permissionCategory.groups
            .filter(x => x.deletePermission)
            .map(x => x.deletePermission);

        const allSelected = !allDeletePermissionsSelected;
        const newStatuses = getChangedPermissionStatuses(allSelected, deletePermisisons);

        changeState(newStatuses);
        setAllWritePermissionsSelected(allSelected);
    }

    const handleAllExecutePermissionsChanged = () => {
        const executePermisisons = permissionCategory.groups
            .filter(x => x.executePermission)
            .map(x => x.executePermission);

        const allSelected = !allExecutePermissionsSelected;
        const newStatuses = getChangedPermissionStatuses(allSelected, executePermisisons);

        changeState(newStatuses);
        setAllWritePermissionsSelected(allSelected);
    }    

    const getChangedPermissionStatuses = (allSelected : boolean, effectedPermissions : (ProbisPermission | undefined)[]) : ProbisPermissionStatus[] => {
        const changedStatuses = permissionStatuses
            ?.filter(x => effectedPermissions.includes(x.permission))
            .filter(y => y.state !== getNewStateWithSelectAll(allSelected, y.state));

        return changedStatuses?.map(x => getNewPermissionStatus(allSelected, x)) ?? [];
    } 

    const getNewPermissionStatus = (allSelected : boolean, permissionStatus : ProbisPermissionStatus) : ProbisPermissionStatus => {
        return {
          permission: permissionStatus.permission,
          state: getNewStateWithSelectAll(allSelected, permissionStatus.state),
        };
      }

    return (
        <div className='flex flex-col bg-white'>
            <div className='flex flex-row cursor-pointer py-4'>
                <div className='flex flex-row items-center w-8/12' onClick={() => setExpanded(!expanded)}>
                    <div className='mx-2'>
                        {expanded &&
                            <ChevronDownIcon className='w-4 h-4' />
                        }
                        {!expanded &&
                            <ChevronRightIcon className='w-4 h-4' />
                        }
                    </div>
                    {permissionCategory.category}
                </div>

                <div className='flex items-center justify-center w-1/12'>
                    {allReadPermissionsVisible &&
                        <CheckBox 
                            checked={allReadPermissionsSelected}
                            onChange={handleAllReadPermissionsChanged}/>
                    }
                </div>
                <div className='flex items-center justify-center w-1/12'>
                    {allWritePermissionsVisible &&
                        <CheckBox 
                            checked={allWritePermissionsSelected}
                            onChange={handleAllWritePermissionsChanged}/>
                    }
                </div>
                <div className='flex items-center justify-center w-1/12'>
                    {allDeletePermissionsVisible &&
                        <CheckBox 
                            checked={allDeletePermissionsSelected}
                            onChange={handleAllDeletePermissionsChanged}/>
                    }
                </div>
                <div className='flex items-center justify-center w-1/12'>
                    {allExecutePermissionsVisible &&
                        <CheckBox 
                            checked={allExecutePermissionsSelected}
                            onChange={handleAllExecutePermissionsChanged}/>
                    }
                </div>
            </div>
            <div className='border-b-2 border-sky-800'/>
            {expanded &&
                <>
                    {permissionCategory.groups.map((group, index) => (
                        <div key={index}>
                            <PermissionGroupRow 
                                allowEditing={allowEditing} 
                                changeState={changeState} 
                                permissionStatuses={permissionStatuses}
                                permissionGroup={group}/>
                        </div>
                    ))}
                </>
            }
        </div>
    );
}