import { useApiDeleteUserAvatarMutation } from '@client/shared/api';
import {
  ConfirmModal, HintBox,
  LoadingIndicator,
  ModalOnCloseProps
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';

export const RemoveAvatarModal = ({ onClose }: ModalOnCloseProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const [deleteAvatar, { isLoading }] = useApiDeleteUserAvatarMutation();

  const handleDelete = async () => {
    try {
      await safeMutation(deleteAvatar, undefined, isLoading);

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    submitRef.current?.focus()
  }, [submitRef]);

  return (
    <ConfirmModal
      title={t('common.delete')}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isLoading}
    >
      {isLoading && (
        <LoadingIndicator text={t('app.tenantRemoveImageLoadingIndicator')} mode="overlay" />
      )}
      <HintBox hintType="warning">{t('app.userRemoveAvatar')}</HintBox>
    </ConfirmModal>
  );
};
