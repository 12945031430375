import * as yup from 'yup';
import { InferType } from 'yup';

export const CompanyAddFormValidationSchema = yup.object({
  company: yup.string().required('validation.required'),
  code: yup.string().optional(),
  branch: yup.string().optional(),
  iban: yup.string().optional(),
  swift: yup.string().optional(),
  phone: yup.string().optional(),
  email: yup.string().email('validation.email').optional(),
  street: yup.string().optional(),
  number: yup.string().optional(),
  postalCode: yup.string().optional(),
  city: yup.string().optional(),
  country: yup.string().optional(),
});

export type CompanyAddFormValidationValues = InferType<typeof CompanyAddFormValidationSchema>;
