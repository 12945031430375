import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, ReactNode } from 'react';

interface TabProps {
  header: string | ReactNode;
  panel: ReactNode;
}

interface TabsProps {
  disabled?: boolean;
  selectedIndex: number;
  options: TabProps[];
  tabChange: (arg: number) => void;
  label?: string;
  hideHeader?: boolean;
}

export const Tabs = (props: TabsProps) => {
  const headers = props.options.map((t) => t.header);
  const panels = props.options.map((t) => t.panel);

  return (
    <div>
      <TabGroup selectedIndex={props.selectedIndex} onChange={(x) => props.tabChange(x)} as={Fragment} manual>
        <div className="flex">
          {props.label && <div className="grow text-gray-600 text-base font-semibold pl-3 pt-1">{props.label}</div>}
          <TabList className={classNames('text-xs font-normal p-[3px] pr-3', {
            'hidden': props.hideHeader // without header, the tabs don't work
          })}>
            {headers.map((header, i) => (
              <Tab key={`header-${i}`} as={Fragment}>
                {({ selected }) => (
                  <button
                    disabled={props.disabled}
                    className={classNames('text-gray-600  font-semibold px-2 pt-1', selected ? 'underline' : '')}
                  >
                    {header}
                  </button>
                )}
              </Tab>
            ))}
          </TabList>
        </div>
        <TabPanels>
          {panels.map((panel, index) => (
            <TabPanel key={index} className="outline-none">{panel}</TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
    </div>
  );
};
