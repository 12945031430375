import { useEffect, useState } from 'react';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

interface GeoLookupResult {
  lat: number;
  lng: number;
}

const provider = new OpenStreetMapProvider();

/**
 * This is a hook to look up a geo coordinate from a given street, number, city and postal code.
 * The hook uses the openstreetmap API to perform the lookup
 * @param address The address to lookup.
 * @returns State of api call, the geo coordinate and the error state if any error occurred during the lookup.
 */
export function useGeoLookup(address: { street: string; number?: string | null; city: string; postalCode: string }) {
  const [geo, setGeo] = useState<GeoLookupResult | undefined>(undefined);
  const [requestCount, setRequestCount] = useState<number>(0);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (geo != null || requestCount > 0 || error != null) {
      return;
    } else {
      const query = `${address.street} ${address.number}, ${address.postalCode} ${address.city}`;

      provider
        .search({ query })
        .then((results) => {
          if (results.length === 0) {
            setIsFetching(false);
            setError('not-found');
            setRequestCount(0);
            setGeo(undefined);

            return;
          }

          const result = results[0];

          setGeo({ lat: result.y, lng: result.x });
          setIsFetching(false);
          setError(undefined);
          setRequestCount(0);
        })
        .catch((err) => {
          setGeo(undefined);
          setIsFetching(false);
          setError('not-found');
          setRequestCount(0);
        });

      setIsFetching(true);
      setRequestCount(requestCount + 1);
    }
  }, [address, geo, requestCount, error]);

  return { geo, error, isFetching };
}
