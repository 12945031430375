import { i18n } from "./i18n";

export const getUnitSystemLabel = (unitSystem: string): string => {
    switch (unitSystem) {
      case 'Metric':
        return i18n.t('project.unitSystemMetricLabel');
      case 'Imperial':
        return i18n.t('project.unitSystemImperialLabel');
      default:
        return unitSystem;
    }
  };