import { ShortProjectGroupReadModel, useApiDeleteProjectGroupMutation } from '@client/shared/api';
import { HintBox, ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

interface MultiProjectDeleteModalProps extends ModalOnCloseProps {
  project: ShortProjectGroupReadModel;
}

export const MultiProjectDeleteModal = ({ project, onClose }: MultiProjectDeleteModalProps) => {
  const { t } = useTranslation();

  const [call, { isLoading }] = useApiDeleteProjectGroupMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    try {
      await safeMutation(
        call,
        {groupId: project.id},
        isLoading
      );
      onClose(true);
    } catch {
      /* left blank */
    }
  };

  return (
    <ConfirmModal
      title={t('app.deleteMultiProjectTitle', { name: project.name })}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      {isLoading && <LoadingIndicator text={t('app.deleteMultiProjectLoading') ?? ''} />}
      <HintBox hintType="warning">{t('app.deleteMultiProjectHint')}</HintBox>
    </ConfirmModal>
  );
};
