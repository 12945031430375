import {
  api,
  ApiPostCreateTaxonomyApiResponse,
  ApiDeleteTaxonomyApiResponse,
  ApiPostInitializeTaxonomyApiResponse,
  ApiPostUpdateTaxonomyApiResponse,
  ApiPostCreatePlotApiResponse,
  ApiPostUpdatePlotApiResponse,
  ApiDeletePlotApiResponse,
} from '@client/shared/api';
import { fork, put, takeEvery } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import { PayloadAction } from '@reduxjs/toolkit';
import { i18n } from '@client/shared/utilities';
import { setExpandedTaxonomyIds, TOGGLED_EXPANDED_IDS } from '@client/project/store';

function* onPlotChange() {
  yield takeEvery(
    [api.endpoints.apiPostCreatePlot.matchFulfilled],
    function* (_: PayloadAction<ApiPostCreatePlotApiResponse>) {
      toast.success(i18n.t('projectTaxonomy.notificationNewPlotPieceCreated'));
      yield;
    }
  );

  yield takeEvery(
    [api.endpoints.apiPostUpdatePlot.matchFulfilled],
    function* (_: PayloadAction<ApiPostUpdatePlotApiResponse>) {
      toast.success(i18n.t('projectTaxonomy.notificationPlotPieceChanged'));
      yield;
    }
  );

  yield takeEvery([api.endpoints.apiDeletePlot.matchFulfilled], function* (_: PayloadAction<ApiDeletePlotApiResponse>) {
    toast.success(i18n.t('projectTaxonomy.notificationPlotPieceRemoved'));
    yield;
  });
}

function* onBuildingTaxonomyChange() {
  yield takeEvery(
    [api.endpoints.apiPostUpdateTaxonomy.matchFulfilled],
    function* (_: PayloadAction<ApiPostUpdateTaxonomyApiResponse>) {
      toast.success(i18n.t('projectTaxonomy.notificationBuildingItemUpdated'));
      yield;
    }
  );

  yield takeEvery(
    [api.endpoints.apiPostCreateTaxonomy.matchFulfilled],
    function* (_: PayloadAction<ApiPostCreateTaxonomyApiResponse>) {
      toast.success(i18n.t('projectTaxonomy.notificationBuildingItemCreated'));
      yield;
    }
  );

  yield takeEvery(
    [api.endpoints.apiPostInitializeTaxonomy.matchFulfilled],
    function* (_: PayloadAction<ApiPostInitializeTaxonomyApiResponse>) {
      toast.success(i18n.t('projectTaxonomy.notificationProjectItemCreated'));
      yield;
    }
  );

  yield takeEvery(
    [api.endpoints.apiDeleteTaxonomy.matchFulfilled],
    function* (_: PayloadAction<ApiDeleteTaxonomyApiResponse>) {
      toast.success(i18n.t('projectTaxonomy.notificationTaxonomyItemRemoved'));
      yield;
    }
  );
}

function* loadTaxonomyExpandedIds() {
  const serializedExpandedIds = localStorage.getItem(TOGGLED_EXPANDED_IDS);
  if (serializedExpandedIds) {
    const preselectedExpandedIds = JSON.parse(serializedExpandedIds)
    if (preselectedExpandedIds.Taxonomy && typeof preselectedExpandedIds.Taxonomy !== 'undefined') {
      yield put(setExpandedTaxonomyIds(preselectedExpandedIds.Taxonomy))
    }
  }
}

export const taxonomySaga = function* () {
  yield fork(onPlotChange);
  yield fork(onBuildingTaxonomyChange);
  yield fork(loadTaxonomyExpandedIds);
};
