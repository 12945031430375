import * as yup from 'yup';
import {InferType} from 'yup';

export const CreateCatalogElementFormValidationSchema = yup.object({
  codeMask: yup.string().optional().nullable(),
  code: yup.string().nullable()
    .when('codeMask', {
      is: (codeMask: string) => (codeMask === '' || codeMask === null),
      then: (schema) => schema.required('validation.required'),
      otherwise: (schema) => schema.optional().nullable()
    }),
  name: yup.string().required('validation.required'),
});
export type CreateCatalogElementFormValidationValues = InferType<typeof CreateCatalogElementFormValidationSchema>;