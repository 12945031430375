import { FinancingElementFeeReadModel, FinancingFeePayload, useApiGetCatalogQuery } from '@client/shared/api';
import { AddButton, BaseSelectOption, Button, LoadingIndicator, Modal } from '@client/shared/toolkit';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FinancingFeeElement } from './FinancingFeeElement';
import { formatDateOnly } from '@client/shared/utilities';

interface FinancingFeesModalProps {
  onClose: (fees?: FinancingFeePayload[]) => void;
  fees?: FinancingFeePayload[];
  totalFinancingAmount?: number;
  catalogId?: string;
  disabled?: boolean;
}

export const FinancingFeesModal = ({ onClose, fees = [], totalFinancingAmount = 0, catalogId, disabled }: FinancingFeesModalProps) => {
  const { t } = useTranslation();

  const { data: catalog, isFetching } = useApiGetCatalogQuery({ id: catalogId ?? '' });

  const [cachedFees, setCachedFees] = useState(fees);

  const addNewElement = () => {
    setCachedFees([
      ...cachedFees,
      {
        date: formatDateOnly(new Date()),
        amount: 0,
        description: '',
      },
    ]);
  };

  const catalogOptions: BaseSelectOption[] = useMemo(() => {
    const options =
      catalog?.costCatalog?.elements.map(({ code, description, id }) => {
        return {
          label: `${code} ${description}` ?? t('projectCalculate.costElementNoLabel'),
          value: id ?? 'no-id',
        };
      }) ?? [];

    return options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }, [catalog?.costCatalog, t]);

  return (
    <>
      <Modal.Header
        title={t('projectCalculate.financingFeeModalTitle')}
        description={t('projectCalculate.financingFeeModalDescription')}
      />
      <Modal.Content>
        {isFetching && <LoadingIndicator mode="overlay" />}
        <div className="overflow-auto">
          <div className="divide-y">
            {cachedFees.map((fee, index) => (
              <FinancingFeeElement
                key={`fee-${fee.id}-${index}`}
                index={index}
                item={fee}
                totalFinancingAmount={totalFinancingAmount}
                catalogOptions={catalogOptions}
                onItemChange={(item: FinancingElementFeeReadModel, index: number) => {
                  const result = cachedFees.map((c, i) => {
                    if (i === index) {
                      c = item;
                    }
                    return c;
                  });
                  setCachedFees(result);
                }}
                onItemDeleted={(item: FinancingElementFeeReadModel) => {
                  setCachedFees(cachedFees.filter((x) => x !== item));
                }}
                disabled={disabled}
              />
            ))}
          </div>

          <div className="sticky bottom-0 flex-row-reverse flex mr-2">
            {!disabled && (
              <AddButton onClick={addNewElement} scale={false} />
            )}
          </div>
        </div>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={() => onClose(undefined)} className="mr-2" variant="secondary">
          {t('common.cancel')}
        </Button>
        {!disabled && <Button onClick={() => onClose(cachedFees)}>{t('common.save')}</Button>}
      </Modal.Controls>
    </>
  );
};
