import cn from 'classnames';
import { ReactNode } from 'react';

export const SlideOverTitle = ({
  title,
  marginTop = true,
  className,
  onClick,
  children
}: {
  title: string;
  marginTop?: boolean;
  className?: string;
  onClick?: () => void
  children?: ReactNode
}) => {
  return (
    <div
      className={cn(
        'mb-2 text-xl text-gray-500 font-light',
        {
          'mt-6': marginTop,
          'cursor-pointer hover:text-gray-800 transition-colors duration-300': onClick
        },
        className,
      )}
      onClick={onClick}
    >
      {title}
      {children}
    </div>
  )
}
