import { TitleBar, useDocumentTitle } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { ContractContainer } from '../components';
import { useLoadedProject } from '@client/project/store';
import { useState } from 'react';
import { UserDefinedFieldsSearch } from '@client/project/shared';

export const ContractRoute = () => {
  const { t } = useTranslation();
  const { data: loadedProject } = useLoadedProject();

  useDocumentTitle({ title: t('projectContract.routeTitle') });

  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<string[]>([]);

  return (
    <>
      <TitleBar
        title={loadedProject?.project?.payload?.name ?? t('projectContract.routeTitle')}
        subTitle={t('projectContract.routeSubTitle')}
      >
      <UserDefinedFieldsSearch
        searchValue={searchValue}
        updateSearchResults={(results) => setSearchResults(results)}
        handleSearchValueUpdate={(value) => setSearchValue(value)}
        udfElementTypes={['Contract']}
        filterStore="Contract"
        className="max-w-[428px] text-gray-400 bg-gray-100"
      />
      </TitleBar>
      <div className="flex-grow flex flex-col overflow-hidden">
        <div className="px-4 py-8 md:px-8 flex flex-col flex-grow overflow-y-auto">
          <ContractContainer searchValue={searchValue} searchResults={searchResults} />
        </div>
      </div>
    </>
  );
};
