import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteNavType, ROUTES_CONFIG } from '@client/shared/permissions';
import { ProtectedNavLink } from '@client/shared/permissions';
import { useUi } from '@client/shared/store';

export type MainNavigationMenuProps = {
  className?: string;
};

export const MainNavigationMenu = (props: MainNavigationMenuProps) => {
  const { t } = useTranslation();
  const ui = useUi();
  const navigationItems: RouteNavType[] = useMemo(() => {
    return [
      {
        label: 'app.menuProjects',
        routeConfig: ROUTES_CONFIG.PROJECTS,
      },
      {
        label: 'app.menuCompany',
        routeConfig: ROUTES_CONFIG.COMPANY,
      },
      {
        label: 'app.menuPortfolio',
        routeConfig: ROUTES_CONFIG.PORTFOLIOS,
        // TODO only temporarily: not disabled on dev and on qa / prod for fraport tenant
        disabled:
          ui.appUser.tenant?.tenantId !== '5681feb0-df80-443b-9a2b-6d48989a4e1b' &&
          ui.appUser.tenant?.tenantId !== 'f72e23cc-57c1-40ee-88ec-c6ede169ae29' &&
          ui.appUser.tenant?.tenantId !== '1cdbbca8-96a8-4f8c-94b5-9c2a8035c9bf',
      },
      /* {
        label: 'app.menuContacts',
        routeConfig: ROUTES_CONFIG.CONTACTS
      }, */
      {
        label: 'app.menuHelp',
        routeConfig: ROUTES_CONFIG.HELP,
      },
      {
        label: 'app.menuSettings',
        routeConfig: ROUTES_CONFIG.SETTINGS,
      },
    ];
  }, [ui.appUser.tenant?.tenantId]);
  return (
    <ul className={props.className}>
      {navigationItems.map((item) => (
        <ProtectedNavLink
          routeConfig={item.routeConfig}
          passDataCy={`navigate-${item.routeConfig.name}`}
          disabled={item.disabled}
          className="cursor-pointer inline-block text-gray-300 hover:text-white hover:border-gray-300 my-6 menuLg:my-0 menuLg:py-6 menuLg:px-4 text-lg font-light text-center border-b-2 transform-colors duration-200"
          activeClassName="text-white border-gray-300"
          notActiveClassName="border-transparent"
          disabledClassName="cursor-not-allowed inline-block text-gray-600 my-6 menuLg:my-0 menuLg:py-6 menuLg:px-4 text-lg text-center font-light border-transparent border-b-2 transform-colors duration-200"
          key={`main-nav-link-${item.routeConfig.name}`}
          as="li"
        >
          {t(item.label)}
        </ProtectedNavLink>
      ))}
    </ul>
  );
};
