import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  SlideOver,
  SlideOverOnCloseProps,
  SlideOverTabs,
  Tabs
} from '.';
import { DialogTitle } from '@headlessui/react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';

export type SlideOverTabOptions =  {
  header: ReactNode | string
  name: string
  panel: JSX.Element | string
  icon?: ReactNode | string
  disabled?: boolean
}

export interface SlideOverWithTabsProps extends SlideOverOnCloseProps {
  tabOptions: SlideOverTabOptions[]
  icon?: string | ReactNode
  preTitle?: string | ReactNode
  title?: string | ReactNode
  subtitle?: string | ReactNode
  children?: ReactNode | undefined
  onChange?: (tabIndex: number) => void
  hasPadding?: boolean
  tabsClassName?: string
}

export const SlideOverWithTabs = (props: SlideOverWithTabsProps) => {
  const {
    onClose,
    tabOptions,
    children,
    icon,
    preTitle,
    title,
    subtitle,
    onChange,
    hasPadding = true,
    tabsClassName
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const [tabName, setTabName] = useState(tabOptions[0]?.name);
  const [selectedTab, setSelectedTab] = useState(0);

  const tabHeaders = useMemo(() => {
    return tabOptions.map((tab) => {
      return {
        header: tab.header,
        name: tab.name,
        icon: tab.icon,
        disabled: tab.disabled ?? false
      };
    });
  }, [tabOptions]);

  const handleTabChange = (tabIdx: number) => {
    setSelectedTab(tabIdx);
    const name = tabOptions[tabIdx].name
    setTabName(name)
    searchParams.set('tab', name);
    setSearchParams(searchParams, { replace: true });
    if (onChange) {
      onChange(tabIdx)
    }
  };

  useEffect(() => {
    const searchParamsName = searchParams.get('tab')
    if (searchParamsName && searchParamsName !== tabName) {
      const foundTabIndex = tabOptions.findIndex((option) => option.name === searchParamsName)
      if (foundTabIndex >= 0) {
        setSelectedTab(foundTabIndex)
        setTabName(searchParamsName)
        if (onChange) {
          onChange(foundTabIndex)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      <DialogTitle className={classNames('w-full relative')}>
        <div className="flex h-[88px] items-center justify-between border-b">
          {(icon || preTitle || title || subtitle) && (
            <div className={cn('w-1/3 flex-none py-4', hasPadding ? 'px-8' : 'px-4')}>
              <div className="flex">
                {icon && (
                  <div className="flex flex-col flex-shrink items-center justify-center pr-3">
                    {icon}
                  </div>
                )}
                {(preTitle || title || subtitle) && (
                  <div className="w-full">
                    {preTitle && (
                      <div className="whitespace-nowrap text-[13px] leading-tight text-gray-500 pb-1">
                        {preTitle}
                      </div>
                    )}
                    {title && <div className="text-2xl leading-tight font-bold truncate">{title}</div>}
                    {subtitle && (
                      <div className="whitespace-nowrap text-[13px] leading-tight text-gray-500 pt-1">
                        {subtitle}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          <SlideOverTabs
            selectedTab={selectedTab}
            tabHeaders={tabHeaders}
            handleTabChange={handleTabChange}
            onClose={() => onClose(false)}
          />
        </div>
      </DialogTitle>

      <SlideOver.Content className={hasPadding ? 'p-8' : undefined}>
        <div className={cn('slide-over-tabs mb-4', tabsClassName)}>
          <Tabs tabChange={handleTabChange} selectedIndex={selectedTab} options={tabOptions} hideHeader />
        </div>
      </SlideOver.Content>

      {children}
    </>
  );
};
