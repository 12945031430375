import * as yup from 'yup';
import { InferType } from 'yup';

export const AccessItemFormValidationSchema = yup.object({
  isActive: yup.boolean().required('validation.required'),
  apiUrl: yup.string().optional(),
  apiKey: yup.string().optional(),
  userName: yup.string().optional(),
  password: yup.string().optional(),
  customConfig: yup.string().optional(),
});

export type AccessItemFormValidationSchema = InferType<typeof AccessItemFormValidationSchema>;
