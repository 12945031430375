import {
  CompanyBranchReadModel,
  CompanyPersonReadModel,
  CompanyReadModel,
  PersonGender,
  useApiPostCreateCompanyPersonMutation,
  useApiPostUpdateCompanyPersonMutation,
  useApiRemoveBranchFormCompanyPersonMutation
} from '@client/shared/api';
import * as yup from 'yup';
import { InferType } from 'yup';
import {
  AddressIcon, BaseSelect, BaseSelectOption,
  Button,
  ContactDetailsFormFields,
  ContactFormFields,
  ContextMenuItem,
  Form, FormField, FormRefHandle, LoadingIndicator, Modal,
  SlideOver,
  SlideOverList,
  SlideOverListItemValueProps,
  SlideOverTitle,
  TrashIcon,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { safeMutation } from '@client/shared/utilities';
import { ContactDeleteModal } from './ContactDeleteModal';

const ContactFormValidationSchema = yup.object({
  gender: yup.mixed<PersonGender>().oneOf(['M', 'F', 'D']).required('validation.required'),
  title: yup.string().optional(),
  firstName: yup.string().required('validation.required'),
  lastName: yup.string().required('validation.required'),
  function: yup.string().optional(),
  email: yup.string().optional().email('validation.email'),
  phone: yup.string().optional(),
  mobile: yup.string().optional(),
  web: yup.string().optional(),
  branchId: yup.string().optional().nullable(),
});

type ContactFormValidationValues = InferType<typeof ContactFormValidationSchema>;

interface ContactDetailsSlideOverProps {
  isAdd: boolean;
  company: CompanyReadModel | undefined;
  contact?: CompanyPersonReadModel | null;
  branch?: CompanyBranchReadModel | null;
  onClose: (newContact?: string) => void;
}

export const ContactDetailsSlideOver = ({ isAdd, contact, company, onClose, branch }: ContactDetailsSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<FormRefHandle<ContactFormValidationValues>>(null);

  const [postCreatePerson, { isLoading: isCreating }] = useApiPostCreateCompanyPersonMutation();
  const [postUpdatePerson, { isLoading: isUpdating }] = useApiPostUpdateCompanyPersonMutation();
  const [removeBranchFromPerson, { isLoading: isRemoving }] = useApiRemoveBranchFormCompanyPersonMutation()

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const defaultValues = useMemo(() => {
    return {
      gender: contact?.gender ?? 'D',
      title: contact?.title ?? '',
      firstName: contact?.firstName ?? '',
      lastName: contact?.lastName ?? '',
      function: contact?.function ?? '',
      email: contact?.email ?? '',
      phone: contact?.phone ?? '',
      mobile: contact?.mobile ?? '',
      web: contact?.web ?? '',
      branchId: contact ? (contact?.branches[0]?.branchId ?? null) : branch?.id ?? null
    }
  }, [contact, branch?.id]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.resetForm(defaultValues);
    }
  }, [defaultValues]);

  const handleSubmit = async (data: ContactFormValidationValues) => {
    if (isAdd) {
        try {
          const newContact = await safeMutation(
            postCreatePerson,
            {
              companyId: company?.id ?? '',
              body: {
                gender: data.gender,
                title: data.title,
                firstName: data.firstName,
                lastName: data.lastName,
                function: data.function,
                email: data.email,
                phone: data.phone,
                mobile: data.mobile,
                web: data.web,
                branchId: branch?.id ?? data.branchId
              }
            },
            isCreating,
          );
          onClose(newContact?.personId);
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          await safeMutation(
            postUpdatePerson,
            {
              companyId: company?.id ?? '',
              companyPersonId: contact?.id ?? '',
              body: {
                gender: data.gender,
                title: data.title,
                firstName: data.firstName,
                lastName: data.lastName,
                function: data.function,
                email: data.email,
                phone: data.phone,
                mobile: data.mobile,
                web: data.web,
                branchId: data.branchId
              }
            },
            isUpdating,
          );
          onClose();
        } catch (e) {
          console.log(e);
        }
      }
  };

  const removeBranchFromContact = useCallback(async (branchId: string) => {
    if (company?.id && contact?.id) {
      try {
        await safeMutation(
          removeBranchFromPerson,
          {
            companyId: company.id,
            companyPersonId: contact.id,
            body: {
              branchId: branchId
            }
          },
          isRemoving,
        );
        formRef?.current?.setValue('branchId', null)
      } catch (e) {
        console.log(e);
      }
    }
  }, [company?.id, contact?.id, removeBranchFromPerson, isRemoving])

  const branchOptions = useMemo(() => {
    const options: BaseSelectOption[] = []
    company?.branches.forEach((branch) => {
      options.push({
        label: branch.name,
        value: branch.id
      })
    })
    return options
  }, [company?.branches])

  const branchesListItems: SlideOverListItemValueProps[] = useMemo(() => {
    if (!contact?.branches.length) return [];
    return contact.branches.map((branch) => {
      const contextMenuItems: ContextMenuItem[] = [
        {
          label: t('app.companyContactRemoveAssignedBranch'),
          subtitle: t('app.companyContactRemoveBranch'),
          icon: <TrashIcon />,
          onClick: () => {
            removeBranchFromContact(branch.branchId)
          }
        },
      ]
      return {
        icon: <AddressIcon className="ml-1 w-5 h-5" />,
        id: branch.id,
        leftTop: t('app.masterDataBranch'),
        leftCenter: branch.branchName,
        contextMenuItems: contextMenuItems,
        borderColor: ''
      }
    });
  }, [contact?.branches, t, removeBranchFromContact]);

  return (
    <>
      {(isCreating || isRemoving || isUpdating) && (
        <LoadingIndicator text={t('app.settingsCompaniesLoading') ?? ''} mode="overlay" />
      )}
      <Form<ContactFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={ContactFormValidationSchema}
        defaultValues={defaultValues}
        className="flex flex-col flex-grow min-h-0"
        ref={formRef}
      >
        <SlideOver.Header
          title={isAdd ? t('app.companyContactAdd') : contact?.fullname ?? ''}
          subTitle={isAdd ? '' : t('app.companyContactEdit')}
          backgroundClassName="bg-slate-800"
          onClose={() => onClose()}
        ></SlideOver.Header>
        <SlideOver.Content className="p-8">
          <ContactFormFields showTitle={false} />
          <ContactDetailsFormFields />

          {contact && contact.branches.length > 0 ? (
            <>
              <SlideOverTitle title={t('app.companyAssignedBranch')} />
              <SlideOverList
                clickable={false}
                className="gap-1 mt-0"
                items={branchesListItems}
                noItemsMessage={t('app.companyNoAssignedBranch')}
                rounded={false}
                showShadow={false}
                margin={false}
                customAddButton={null}
              />
            </>
          ) : (
            <>
              <SlideOverTitle title={t('app.companyAssignedBranch')} />
              <FormField name="branchId">
                {(control) =>
                  <BaseSelect
                    label={t('app.companyAssignedBranch')}
                    options={branchOptions}
                    disabled={!contact && !!branch}
                    nullable
                    {...control}
                  />
                }
              </FormField>
            </>
          )}
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className="flex justify-between w-full">
            {!isAdd && (
              <Button variant="warning" className="mr-2" onClick={() => setIsOpenDeleteModal(true)}>
                {t('common.delete')}
              </Button>
            )}
            <div className="w-full flex justify-end">
              <Button variant="secondary" className="mr-2" onClick={() => onClose()}>
                {t('common.cancel')}
              </Button>
              <Button variant="primary" formSubmit={true} innerRef={submitRef}>
                {t('common.save')}
              </Button>
            </div>
          </div>
        </SlideOver.Controls>
      </Form>
      {/* CONTACT DELETE */}
      <Modal isOpen={isOpenDeleteModal} onClose={() => setIsOpenDeleteModal(false)}>
        <ContactDeleteModal contact={contact ?? undefined} onClose={() => {
          setIsOpenDeleteModal(false);
          onClose();
        }} />
      </Modal>
    </>
  );
};
