import { setExpandedCostBreakdownReportIds, useLoadedProjectId, useLoadedProjectVariants } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { CashFlowReportReadModel, useApiGetCashFlowReportQuery } from '@client/shared/api';
import { DecoratedCard, LevelToggle, LoadingIndicator, useComponentDimensions } from '@client/shared/toolkit';
import { CalculationModelSelect } from '../CalculationModelSelect';
import { ReportCostBreakdownContextProvider } from './ReportCostBreakdownContext';
import { ReportCostBreakdownTable } from './ReportCostBreakdownTable';
import { useDispatch } from 'react-redux';
import { getLevelledElements } from '../../utils';

export interface ReportCashFlowLevelledElement extends CashFlowReportReadModel {
  elementId: string;
  level: number;
  expanded: boolean;
  childLevels: ReportCashFlowLevelledElement[];
}

export type LevelledReportElement = { elementId: string; level: number };

export const ReportCostBreakdown = () => {
  const { data: projectVariants } = useLoadedProjectVariants();
  const { t } = useTranslation();
  const activeVariant = projectVariants.find((x) => x.type === 'Version');
  const activeVariantId = activeVariant?.id;

  const projectId = useLoadedProjectId();
  const [sourceCalculationModelId, setSourceCalculationModelId] = useState<string | undefined>(activeVariantId);

  const { data, isFetching } = useApiGetCashFlowReportQuery(
    {
      projectId: projectId ?? '',
      calculationModelId: sourceCalculationModelId ?? '',
    },
    { skip: sourceCalculationModelId === undefined },
  );

  const wrapper = useRef<HTMLDivElement>(null);
  const dimensions = useComponentDimensions(wrapper);

  const dispatch = useDispatch();

  const levelledElements = useMemo(() => {
    const allElements = [...(data?.data ?? [])];

    return getLevelledElements(allElements, ['children'], 'uid');
  }, [data]);

  const handleOnCollapse = useCallback(
    (level: number) => {
      const elements = levelledElements.filter((x) => x.level <= level - 2);
      const expanded = elements.map((x) => x.elementId);
      dispatch(setExpandedCostBreakdownReportIds(expanded));
    },
    [dispatch, levelledElements],
  );

  return (
    <ReportCostBreakdownContextProvider elements={data?.data ?? []} metadata={data ?? undefined}>
      <DecoratedCard className="min-w-[1383px]">
        <DecoratedCard.Header showActionButton={false}>
          <div className="flex flex-row justify-between items-center w-full">
            <div className="flex">
              <div className="truncate">{t('reporting.reportCostBreakdown.title')}</div>
              <div className="pdf-export-hidden">
                <LevelToggle handleOnCollapse={handleOnCollapse} />
              </div>
            </div>
            <div className="flex items-center">
              <div className="font-bold text-[15px] pr-6">{t('reporting.dataSource')}</div>
              <div className="flex space-x-0.5 items-center">
                <CalculationModelSelect
                  selectedCalculationModelId={sourceCalculationModelId}
                  className="w-52"
                  onChange={(id) => setSourceCalculationModelId(id)}
                />
              </div>
            </div>
          </div>
        </DecoratedCard.Header>
        <DecoratedCard.Content className="w-full h-full flex relative">
          {isFetching && <LoadingIndicator text={t('reporting.loadingReport')} mode="overlay" />}
          <div className="relative w-full py-5" ref={wrapper}>
            <div
              className="w-2 rounded -ml-1 left-0 absolute bg-red-700 mb-4"
              style={{ height: (dimensions.height ?? 15) - 32 }}
            />
            <ReportCostBreakdownTable />
          </div>
        </DecoratedCard.Content>
      </DecoratedCard>
    </ReportCostBreakdownContextProvider>
  );
};
