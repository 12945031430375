import { TFunction } from 'i18next';
import { CalculationModelType } from '@client/shared/api';

export const versionTextFromType = (
  t: TFunction,
  type: CalculationModelType | null | undefined,
  version: string | null | undefined
): string | undefined => {
  if (type === 'Version' || type === 'ArchivedVersion' || type === 'SystemSnapshot' || type === 'UserSnapshot') {
    return t('project.versionNumber', { version });
  }
  if (type === 'Variant' || type === 'ArchivedVariant') {
    return t('project.variantNumber', { version });
  }
};
