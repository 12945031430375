import { PropsWithChildren, ReactNode } from 'react';
import { AddButton, ContextMenu, ContextMenuItem, NumberInput, TextInput } from '.';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export interface SlideOverListItemValueProps extends PropsWithChildren {
  id: string;
  leftTop?: string;
  leftCenter?: string;
  leftBottom?: string;
  rightTop?: string;
  rightCenter?: ReactNode | string;
  rightBottom?: string;
  inputLabel?: string;
  inputValue?: string | number | null;
  inputType?: 'text' | 'number';
  inputDisabled?: boolean;
  onChange?: (value: string | number | null) => void;
  onClick?: () => void;
  contextMenuItems?: ContextMenuItem[];
  optionalNode?: ReactNode;
  className?: string;
  borderColor?: string;
  icon?: ReactNode;
}

interface SlideOverListItemProps extends PropsWithChildren {
  item: SlideOverListItemValueProps;
  showShadow?: boolean;
  showBorder?: boolean;
  margin?: boolean;
  icon?: ReactNode;
  className?: string;
  rounded?: boolean;
  disabled?: boolean;
  clickable?: boolean;
  isInput?: boolean;
}

export const SlideOverListItem = ({
  icon,
  item,
  showShadow,
  showBorder,
  margin,
  rounded,
  disabled,
  clickable,
  isInput,
  children
}: SlideOverListItemProps) => {
  const {
    leftTop,
    leftCenter,
    leftBottom,
    rightTop,
    rightCenter,
    rightBottom,
    inputLabel,
    inputType,
    inputValue,
    inputDisabled,
    onChange,
    onClick,
    contextMenuItems,
    optionalNode,
    className,
    borderColor
  } = item;

  const twoColumns = rightTop || rightCenter || rightBottom;
  const threeRowsLeft = leftTop && leftBottom && leftCenter;
  const twoRowsLeft = (!leftTop || !leftBottom) && leftCenter;
  const threeRowsRight = rightTop && rightBottom && rightCenter;
  const twoRowsRight = (!rightTop || !rightBottom) && rightCenter;
  const heightLeft = threeRowsLeft ? 'h-1/3' : twoRowsLeft ? 'h-1/2' : 'h-full';
  const heightRight = threeRowsRight ? 'h-1/3' : twoRowsRight ? 'h-1/2' : 'h-full';

  return (
    <li
      className={classNames(
        'pr-2 min-h-[2rem] w-full flex items-center bg-white',
        {
          border: showBorder,
          'my-2': margin,
          'hover:shadow-md': !disabled && clickable && !isInput && showShadow,
          'border-l-8': borderColor,
          shadow: showShadow,
          'rounded-md': rounded,
        },
        borderColor,
        className,
      )}
    >
      <div
        className={classNames('w-full h-full flex items-center justify-start', {
          'cursor-pointer': !disabled && clickable && !isInput,
        })}
        onClick={() => (!disabled && clickable && !isInput && onClick ? onClick() : undefined)}
        tabIndex={onClick ? 1 : undefined}
      >
        {(icon || item.icon) && <div className="pl-2">{icon ? icon : item.icon}</div>}
        {isInput && (
          <>
            {inputType === 'text' && typeof inputValue === 'string' && (
              <TextInput
                className="w-full h-full"
                label={inputLabel ?? ''}
                value={inputValue}
                onChange={(value) => {
                  if (onChange) {
                    onChange(value);
                  }
                }}
                disabled={disabled || inputDisabled}
              />
            )}
            {inputType === 'number' && typeof inputValue === 'number' && (
              <NumberInput
                className="w-full h-full"
                label={inputLabel ?? ''}
                value={inputValue}
                onChange={(value) => {
                  if (onChange) {
                    onChange(value);
                  }
                }}
                disabled={disabled || inputDisabled}
              />
            )}
          </>
        )}
        {!isInput && !children ? (
          <>
            <div
              className={classNames('break-normal', {
                'w-full': !twoColumns,
                'w-6/12': twoColumns,
              })}
            >
              <div className="flex flex-col justify-start p-3">
                {leftTop && <div className={classNames(' text-xs text-slate-400', heightLeft)}>{leftTop}</div>}
                {leftCenter && (
                  <div className={classNames('h-1/3 text-base text-slate-800 font-bold', heightLeft)}>{leftCenter}</div>
                )}
                {leftBottom && (
                  <div className={classNames('h-1/3 text-xs text-slate-400', heightLeft)}>{leftBottom}</div>
                )}
              </div>
            </div>
            {twoColumns && (
              <div className="w-6/12 p-3 break-normal">
                {rightTop && (
                  <div className={classNames('h-1/3 text-xs text-slate-400 text-right', heightRight)}>{rightTop}</div>
                )}
                {rightCenter && (
                  <div className={classNames('h-1/3 text-sm text-slate-800 font-bold text-right', heightRight)}>
                    {rightCenter}
                  </div>
                )}
                {rightBottom && (
                  <div className={classNames('h-1/3 text-xs text-slate-400 text-right', heightRight)}>
                    {rightBottom}
                  </div>
                )}
              </div>
            )}
          </>
        ) : children}
      </div>
      <div className="h-full flex">
        {contextMenuItems && <ContextMenu items={contextMenuItems} />}
        {optionalNode}
      </div>
    </li>
  );
};

interface SlideOverListProps extends PropsWithChildren {
  items: SlideOverListItemValueProps[];
  onAdd?: () => void;
  noItemsMessage?: string | ReactNode;
  icon?: ReactNode;
  className?: string;
  showShadow?: boolean;
  showBorder?: boolean;
  margin?: boolean;
  customAddButton?: ReactNode | null;
  rounded?: boolean;
  disabled?: boolean;
  clickable?: boolean;
  isInput?: boolean;
  showCustomNoItems?: boolean
}

export const SlideOverList = ({
  items,
  onAdd,
  noItemsMessage,
  icon,
  className,
  customAddButton,
  children,
  disabled,
  showShadow = true,
  showBorder = true,
  margin = true,
  rounded = true,
  clickable = true,
  isInput = false,
  showCustomNoItems = false
}: SlideOverListProps) => {
  const { t } = useTranslation()
  return (
    <div className={className}>
      <ul>
        {items.map((item: SlideOverListItemValueProps, index: number) => (
          <SlideOverListItem
            item={item}
            icon={icon}
            showShadow={showShadow}
            showBorder={showBorder}
            margin={margin}
            disabled={disabled}
            key={`listItem-` + index}
            rounded={rounded}
            clickable={clickable}
            isInput={isInput}
          >
            {item.children}
          </SlideOverListItem>
        ))}
      </ul>
      {!showCustomNoItems && items.length === 0 && (
        <div className="w-full mt-2 p-5 bg-white">{noItemsMessage ? noItemsMessage : t('common.addElement')}</div>
      )}
      {showCustomNoItems && noItemsMessage}
      {customAddButton || customAddButton === null ? (
        customAddButton
      ) : (
        <div className="flex w-full justify-end items-center z-50 relative">
          <AddButton className={margin ? '-mt-6' : '-mt-4'} onClick={onAdd} />
        </div>
      )}
      {children}
    </div>
  );
};
