import {HandRaisedIcon} from "@heroicons/react/24/outline";
import React from "react";
import cn from "classnames";
import { FormattedCurrency } from '@client/project/shared';

export interface CalculateTotalProps {
  catalogHasRestrictions: boolean
  totalCost?: number | null
  totalText: string
  colorClass?: string
  paddingClass?: string
}


export const CalculateTotal = ({ catalogHasRestrictions, totalCost, totalText, colorClass = 'text-red-500', paddingClass = 'pr-4' }: CalculateTotalProps) => (
  <div className={cn(
    'h-10 w-full text-right mt-4 sm:text-xs lg:text-sm 2xl:text-lg font-bold !leading-tight',
    paddingClass,
    colorClass
  )}>
    <div className="flex flex-col items-end">
      {catalogHasRestrictions ? (
        <HandRaisedIcon className="h-4 w-4" />
      ) : (
        <FormattedCurrency amount={totalCost} />
      )}
      <div className="text-[11px] font-light text-neutral-500">
        {totalText}
      </div>
    </div>
  </div>
);
