import React, { useState } from 'react';
import { SearchFilter, useDocumentTitle } from '@client/shared/toolkit';
import { AppLayout, AppLayoutTitle, AppLayoutMenu, AppLayoutContent } from '../components';
import { useTranslation } from 'react-i18next';
import { PortfoliosContainer } from '@client/main/portfolios';

export const PortfoliosRoute = () => {
  const { t } = useTranslation();

  useDocumentTitle({ title: t('app.menuPortfolio') });

  const [searchText, setSearchText] = useState('');
  const [searchResults] = useState<string[]>([]);

  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false);

  return (
    <AppLayout updateHeaderCollapsed={setIsHeaderCollapsed}>
      <AppLayoutTitle>{t('app.menuPortfolio')}</AppLayoutTitle>
      <AppLayoutMenu>
        <SearchFilter
          handleSearch={setSearchText}
          updateSearchValue={setSearchText}
          searchValue={searchText}
          searchBgColor={isHeaderCollapsed ? 'bg-gray-400 bg-opacity-10' : 'bg-white'}
          className="h-12"
        />
      </AppLayoutMenu>
      <AppLayoutContent>
        <PortfoliosContainer searchText={searchText} searchResults={searchResults} setSearchText={setSearchText} />
      </AppLayoutContent>
    </AppLayout>
  );
};
