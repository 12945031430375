import {
  ApiDeleteFinancingElementApiArg,
  ApiDeleteFinancingElementApiResponse,
  ApiDeleteFinancingElementDocumentApiArg,
  ApiDeleteFinancingElementDocumentApiResponse,
  ApiGetFinancingElementApiArg,
  ApiGetFinancingElementApiResponse,
  ApiGetFinancingElementsMetadataApiArg,
  ApiGetFinancingElementsMetadataApiResponse,
  ApiGetFinancingPurposeOverviewApiArg,
  ApiGetFinancingPurposeOverviewApiResponse,
  ApiPostCreateFinancingElementApiArg,
  ApiPostCreateFinancingElementApiResponse,
  ApiPostCreateFinancingElementDocumentApiArg,
  ApiPostCreateFinancingElementDocumentApiResponse,
  ApiPostLockFinancingElementApiArg,
  ApiPostLockFinancingElementApiResponse,
  ApiPostUnlockFinancingElementApiArg,
  ApiPostUnlockFinancingElementApiResponse,
  ApiPostUpdateFinancingElementApiArg,
  ApiPostUpdateFinancingElementApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiCalculateFinanceEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetFinancingElement: {
    providesTags: (
      _result: ApiGetFinancingElementApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetFinancingElementApiArg,
    ) => [
      { type: ApiTagTypes.FinancingElement, id: args.id },
      {
        type: ApiTagTypes.CalculateElements,
        id: args.calculationModelId,
      },
    ],
  },
  apiGetFinancingPurposeOverview: {
    providesTags: (
      _result: ApiGetFinancingPurposeOverviewApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetFinancingPurposeOverviewApiArg,
    ) => [{ type: ApiTagTypes.FinancingPurposeOverview, id: args.calculationModelId }],
  },
  apiGetFinancingElementsMetadata: {
    providesTags: (
      _result: ApiGetFinancingElementsMetadataApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetFinancingElementsMetadataApiArg,
    ) => [{ type: ApiTagTypes.FinancingElementsMetadata, id: args.calculationModelId }],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiDeleteFinancingElementDocument: {
    invalidatesTags: (
      _result: ApiDeleteFinancingElementDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteFinancingElementDocumentApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.FinancingElement, id: args.financingElementId },
            { type: ApiTagTypes.FinancingElementsMetadata, id: args.calculationModelId },
          ]
        : [],
  },
  apiDeleteFinancingElement: {
    invalidatesTags: (
      _result: ApiDeleteFinancingElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteFinancingElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.FinancingCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.FinancingPurposeOverview, id: args.calculationModelId },
            { type: ApiTagTypes.FinancingElementsMetadata, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostCreateFinancingElementDocument: {
    invalidatesTags: (
      _result: ApiPostCreateFinancingElementDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateFinancingElementDocumentApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.FinancingElement, id: args.financingElementId }] : []),
  },
  apiPostCreateFinancingElement: {
    invalidatesTags: (
      _result: ApiPostCreateFinancingElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateFinancingElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.FinancingElementsMetadata, id: args.calculationModelId },
            { type: ApiTagTypes.FinancingCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.FinancingPurposeOverview, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostUpdateFinancingElement: {
    invalidatesTags: (
      _result: ApiPostUpdateFinancingElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateFinancingElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.FinancingElement, id: args.id },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.FinancingElementsMetadata, id: args.calculationModelId },
            { type: ApiTagTypes.FinancingCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.FinancingPurposeOverview, id: args.calculationModelId }
          ]
        : [],
  },
  apiPostLockFinancingElement: {
    invalidatesTags: (
      _result: ApiPostLockFinancingElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostLockFinancingElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.FinancingElement, id: args.id },
            { type: ApiTagTypes.FinancingCalculationModel, id: args.calculationModelId },
            // TODO check if this is required
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostUnlockFinancingElement: {
    invalidatesTags: (
      _result: ApiPostUnlockFinancingElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUnlockFinancingElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.FinancingElement, id: args.id },
            { type: ApiTagTypes.FinancingCalculationModel, id: args.calculationModelId },
            // TODO check if this is required
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
          ]
        : [],
  },
};
