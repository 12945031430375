import { Button } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { AppLayout, AppLayoutContent, AppLayoutTitle, DevelopmentCommandList } from '../components';
import { useDevModeGuard } from '../hooks';

const delays = [200, 500, 1000, 1500, 2000, 3000, 5000];

export const DevelopmentRoute = () => {
  const { i18n } = useTranslation();

  const activateDelay = (ms: number) => {
    window.location.href = `/?_delay=${ms}`;
  };

  useDevModeGuard();

  return (
    <AppLayout>
      <AppLayoutTitle>Development</AppLayoutTitle>
      <AppLayoutContent>
        <div className="bg-white overflow-y-auto h-full">
          <div className="text-lg font-bold my-2">Query delay</div>
          {delays.map((delay) => (
            <button
              key={delay}
              className="ml-4 p-2 bg-slate-500 text-white rounded-sm"
              onClick={() => activateDelay(delay)}
            >
              {delay}ms
            </button>
          ))}

          <div className="text-lg font-bold my-2">Error handling (sentry)</div>
          <Button
            variant="danger"
            onClick={() => {
              throw new Error('Development');
            }}
          >
            Invoke error
          </Button>

          <div className="text-lg font-bold my-2">Language</div>
          <Button onClick={() => i18n.changeLanguage('de')}>de</Button>
          <Button onClick={() => i18n.changeLanguage('en')}>en</Button>
          <Button onClick={() => i18n.changeLanguage('fr')}>fr</Button>
          <Button onClick={() => i18n.changeLanguage('cimode')}>cimode</Button>

          <DevelopmentCommandList />
        </div>
      </AppLayoutContent>
    </AppLayout>
  );
};
