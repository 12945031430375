import { useApiGetLicenseGrantsQuery } from '@client/shared/api';
import { LoadingIndicator} from '@client/shared/toolkit';
import { useState } from 'react';
import { LicenseRow } from './LicenseRow';
import { useTranslation } from 'react-i18next';
import { SettingsHeader } from '../SettingsHeader';

export const Licenses = () => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState<string | undefined>();

  const { data, isFetching } = useApiGetLicenseGrantsQuery();
  const licenses = data ?? [];

  return (
    <div className="w-full">
      <SettingsHeader searchText={searchText} setSearchText={setSearchText}>
        {t('license.licenses')}
      </SettingsHeader>
      <div className="relative h-full">
        <div className="flex w-full text-xs text-gray-500 px-0 mt-4 mb-2">
          <div className="w-5/12">{t('license.license')}</div>
          <div className="w-3/12">{t('license.runtime')}</div>
          <div className="w-1/12">{t('license.seats')}</div>
          <div className="w-1/12">{t('license.payment')}</div>
          <div className="w-1/12">{t('license.status')}</div>
          <div className="w-1/12">
            <div className="flex flex-row items-end">
              {t('license.price')}
              <div className="text-xs text-slate-300 ml-2">p.M.</div>
            </div>
          </div>
        </div>

        {isFetching === true && <LoadingIndicator text={t('app.settingsUsersLoading') ?? ''} />}

        <div className="border-t border-gray-200">
          {licenses
            .filter((x) => x.name.toLowerCase().includes(searchText?.toLowerCase() ?? ''))
            .map((license, idx) => (
              <div key={idx} className="border-b border-gray-200">
                <LicenseRow license={license} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
