import cn from 'classnames';
import { Button } from '.';
import React, { ReactElement, ReactNode, useMemo, useRef, useState } from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { useComponentDimensions } from '../hooks';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';

export type SlideOverTabHeader = {
  header: string | ReactNode;
  name: string;
  icon?: string | ReactNode
  disabled?: boolean
};
export interface SlideOverTabsProps {
  className?: string;
  tabHeaders: SlideOverTabHeader[],
  handleTabChange: (number: number) => void
  selectedTab: number
  onClose?: () => void
}

export const SlideOverTabs = ({ tabHeaders, handleTabChange, selectedTab, onClose }: SlideOverTabsProps) => {

  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom',
  });

  const menuListWrapperRef = useRef<HTMLDivElement>(null)
  const dimensionsListWrapper = useComponentDimensions(menuListWrapperRef);

  const maxTabItems = useMemo(() => {
    if (dimensionsListWrapper?.width) {
      const closeWidth = onClose ? 40 : 0
      return Math.max(0, Math.floor((dimensionsListWrapper.width - closeWidth) / 110))
    }
    return tabHeaders.length
  }, [dimensionsListWrapper.width, onClose, tabHeaders.length])

  const visibleTabItems = useMemo(() => {
    const items = [...tabHeaders]
    return items.slice(0, maxTabItems)
  }, [tabHeaders, maxTabItems])

  const hiddenTabItems = useMemo(() => {
    const items = [...tabHeaders]
    return items.slice(maxTabItems)
  }, [tabHeaders, maxTabItems])

  return (
    <div className={cn('flex-1 overflow-hidden relative mr-8 h-[88px]', { 'pr-[40px]': onClose })} ref={menuListWrapperRef}>
      {tabHeaders.length > 0 && (
        <ul className="justify-end flex min-w-fit h-full">
          {visibleTabItems.map((tabHeader, i) => (
            <li key={`slide-over-tab-header-${tabHeader.name}-${i}`} className="flex flex-none flex-col h-full justify-center min-w-[88px]">
              <Button
                variant="custom"
                hasPadding={false}
                onClick={() => handleTabChange(i)}
                className={cn('px-6 rounded-none h-full font-normal border-b-4 flex flex-col items-center justify-center text-[15px] pt-2', {
                  'border-transparent': selectedTab !== i,
                  'bg-gray-100 border-b-4 border-primary': selectedTab === i,
                  'text-gray-500': !tabHeader.disabled,
                  'text-slate-300': tabHeader.disabled
                })}
                disabled={tabHeader.disabled ?? false}
                disabledClass="bg-white"
              >
                {tabHeader.icon && (
                  <span>{React.cloneElement(tabHeader.icon as ReactElement, { className: 'w-7 h-7 flex-none'})}</span>
                )}
                <div className="pt-2">{tabHeader.header}</div>
              </Button>
            </li>
          ))}
          {hiddenTabItems.length > 0 && (
            <Popover>
              <div ref={setTargetElement}>
                <PopoverButton>
                  <div className="h-22 px-6 flex gap-2 items-center w-full font-medium hover:bg-secondary/10 transition-colors duration-200 cursor-pointer">
                    <EllipsisVerticalIcon className="w-8" />
                  </div>
                </PopoverButton>
              </div>
              <PopoverPanel
                portal
                ref={setPopperElement}
                style={{ ...styles.popper }}
                {...attributes.popper}
                className="z-20 flex bg-transparent outline-none w-screen pl-10"
              >
                <ul className="flex flex-wrap sm:ml-auto sm:mr-0 sm:justify-end w-full sm:w-auto shadow bg-white">
                  {hiddenTabItems.map((item, j) => (
                    <li key={`slide-over-tab-header-${item.name}-${j}`} className="flex flex-none flex-col h-[87px] min-w-[88px] justify-center">
                      <Button
                        variant="custom"
                        hasPadding={false}
                        onClick={() => handleTabChange(j + visibleTabItems.length)}
                        className={cn('px-6 rounded-none h-full font-normal border-b-4 flex flex-col items-center justify-center text-[15px] pt-2', {
                          'border-transparent': selectedTab !== j + visibleTabItems.length,
                          'bg-gray-100 border-b-4 border-primary': selectedTab === j + visibleTabItems.length,
                          'text-gray-500': !item.disabled,
                          'text-slate-300': item.disabled
                        })}
                        disabled={item.disabled}
                        disabledClass="bg-white"
                      >
                        {item.icon && (
                          <span>{React.cloneElement(item.icon as ReactElement, { className: 'w-7 h-7 flex-none'})}</span>
                        )}
                        <div className="pt-2">{item.header}</div>
                      </Button>
                    </li>
                  ))}
                </ul>
              </PopoverPanel>
            </Popover>
          )}
        </ul>
      )}
      {onClose && (
        <div className="absolute top-1/2 -translate-y-1/2 right-0" onClick={onClose}>
          <XMarkIcon className="w-8 h-8 text-gray-500 hover:text-gray-600 transition-color duration-200 cursor-pointer" />
        </div>
      )}
    </div>
  )
}
