import { DocumentViewerPreview } from './DocumentViewerPreview';
import { ReactNode, useEffect, useState } from 'react';
import { DocumentViewerPreviewHeaderMenuOptions } from './DocumentViewerPreviewHeader';
import { LoadingIndicator } from '../LoadingIndicator';

export type ExtractedFieldValueReadModel = {
  pages: string;
  texts?: string | null;
  value?: string | null;
  edited: boolean;
  boundingBoxes?: string | null;
};
export type InvoiceDocumentShortReadModel = {
  id: string;
  invoiceId?: string | null;
  companyName: ExtractedFieldValueReadModel;
  date: ExtractedFieldValueReadModel;
  invoiceNumber: ExtractedFieldValueReadModel;
  totalGross: ExtractedFieldValueReadModel;
  totalNet: ExtractedFieldValueReadModel;
  vat: ExtractedFieldValueReadModel;
  fileName: string;
  createdAt: string;
};
export type DocumentPositionBoundingBoxReadModel = {
  top: number;
  left: number;
  page: number;
  width: number;
  height: number;
};
export type InvoiceDocumentPositionReadModel = {
  invoiceDocumentId: string;
  value?: string | null;
  overlay: boolean;
  corrected: boolean;
  boundingBox?: DocumentPositionBoundingBoxReadModel | null;
  overlayValue?: string | null;
  originalValue?: number | null;
  correctedValue?: number | null;
};
export type InvoiceDocumentReadModel = {
  id: string;
  projectId: string;
  invoiceId?: string | null;
  companyName: ExtractedFieldValueReadModel;
  date: ExtractedFieldValueReadModel;
  iban: ExtractedFieldValueReadModel;
  invoiceNumber: ExtractedFieldValueReadModel;
  swift: ExtractedFieldValueReadModel;
  totalGross: ExtractedFieldValueReadModel;
  totalNet: ExtractedFieldValueReadModel;
  vat: ExtractedFieldValueReadModel;
  fileName: string;
  fileSize: number;
  createdAt: string;
  positions: InvoiceDocumentPositionReadModel[];
  imageHeight: number;
  imageWidth: number;
};

export interface DocumentViewerProps {
  title?: string
  file?: File | null
  setFile?: (file: File | null) => void
  isLoading?: boolean
  loadingMessage?: string
  uploadDescription?: string
  customMenu?: ReactNode
  additionalMenu?: ReactNode
  menuOptions?: DocumentViewerPreviewHeaderMenuOptions
  boxes?: (DocumentPositionBoundingBoxReadModel | null)[]
  hoveredBox?: number | null
  setHoveredBox?: (box: number | null) => void
  fileLoaded?: boolean
  pdfFile: string | null
  imageWidth?: number
  imageHeight?: number
  allBoundingBoxes?: {
    boxes: number[][][]
    pages: number[]
    texts: string[][]
  } | null
  showAllBoxes?: boolean
  initialZoomLevel?: number
}
export const DocumentViewer = (props: DocumentViewerProps) => {
  const {
    title,
    file,
    setFile,
    isLoading = false,
    loadingMessage = '',
    uploadDescription,
    customMenu,
    additionalMenu,
    menuOptions,
    boxes = [],
    hoveredBox,
    setHoveredBox,
    fileLoaded = false,
    pdfFile,
    imageWidth,
    imageHeight,
    allBoundingBoxes,
    showAllBoxes = false,
    initialZoomLevel
  } = props

  const [currentPdfFile, setCurrentPdfFile] = useState<string | null>(null);
  const [fileName, setFileName] = useState('')

  const handleFileChange = (files: FileList | null) => {
    if (files?.length && setFile) {
      const uploadedFile = files[0]
      setFile(uploadedFile);
    }
  };

  useEffect(() => {
    if (file) {
      const uploadedPDFFile = URL.createObjectURL(file)
      const name = file.name;
      setFileName(name)
      setCurrentPdfFile(uploadedPDFFile);
    }
  }, [file]);

  useEffect(() => {
    if (pdfFile) {
      setCurrentPdfFile(pdfFile);
    }
  }, [pdfFile]);

  return (
    <>
      {isLoading && (
        <LoadingIndicator text={loadingMessage} mode="overlay-window" />
      )}
      <DocumentViewerPreview
        title={title}
        pdfFile={currentPdfFile}
        file={file}
        handleFileChange={handleFileChange}
        boxes={boxes}
        name={fileName}
        width={imageWidth}
        height={imageHeight}
        uploadDescription={uploadDescription}
        customMenu={customMenu}
        additionalMenu={additionalMenu}
        menuOptions={menuOptions}
        hoveredBox={hoveredBox}
        setHoveredBox={setHoveredBox}
        fileLoaded={fileLoaded}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
        allBoundingBoxes={allBoundingBoxes}
        showAllBoxes={showAllBoxes}
        initialZoomLevel={initialZoomLevel}
      />
    </>
  );
}
