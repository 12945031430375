import { PropsWithChildren } from 'react';
import { PortfolioSideNavigation } from './PortfolioSideNavigation';
export const PortfolioLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex h-full flex-col lg:flex-row min-w-full bg-slate-50">
      <PortfolioSideNavigation />
      <div className="flex flex-grow flex-col overflow-x-hidden relative">{children}</div>
    </div>
  );
};
