import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { PropsWithChildren } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface PreviewFeatureProps {
  flip?: boolean;
  image: string;
  title: string;
}

export const PreviewFeature = ({ children, flip = false, image, title }: PropsWithChildren<PreviewFeatureProps>) => {
  const left = (
    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
      <div className="mt-6">
        <div className="flex items-center">
          <span className="h-12 w-12 rounded-md flex items-center justify-center bg-sky-500">
            <CheckBadgeIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
          <h2 className="ml-4 text-3xl font-extrabold tracking-tight text-gray-900">{title}</h2>
        </div>

        <p className="mt-4 text-lg text-gray-500">{children}</p>
      </div>
    </div>
  );

  const right = (
    <div className="mt-12 sm:mt-16 lg:mt-0">
      <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
        <LazyLoadImage
          className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
          alt="Inbox user interface"
          src={image}
        />
      </div>
    </div>
  );

  return (
    <div className="relative bg-white pt-16 pb-32 overflow-hidden">
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          {flip ? right : left}
          {flip ? left : right}
        </div>
      </div>
    </div>
  );
};
