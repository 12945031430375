import { AuthSubjectReadModel, useApiGetPlainAuthSubjectsQuery } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import { Button, LoadingIndicator, SearchIcon, SlideOver } from '@client/shared/toolkit';

interface UserAddSlideOverProps {
  assignedUsers: AuthSubjectReadModel[];
  onClose: (users?: AuthSubjectReadModel[]) => void;
}

export const UserAddSlideOver = ({ assignedUsers, onClose }: UserAddSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const { data: users, isFetching } = useApiGetPlainAuthSubjectsQuery();
  const [filterText, setFilterText] = useState<string>('');

  const [allUsersSelected, setAllUsersSelected] = useState<boolean>(false);
  const [selectableUsers, setSelectableUsers] = useState<AuthSubjectReadModel[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<AuthSubjectReadModel[]>([]);

  useEffect(() => {
    if (!users) {
      setSelectableUsers([]);
    } else {
      const availableUsers = users.filter((x) => (assignedUsers.find((y) => y.userId === x.userId) === undefined) && x.membership === 'Active' );
      setSelectableUsers(availableUsers);
    }
  }, [assignedUsers, users]);

  const handleChange = (user: AuthSubjectReadModel) => {
    if (selectedUsers.includes(user)) {
      const newSelection = selectedUsers.filter((x) => x.userId !== user.userId);
      setSelectedUsers(newSelection);
      setAllUsersSelected(newSelection.length === selectableUsers.length);
    } else {
      const newSelection = selectedUsers.concat(user);
      setSelectedUsers(newSelection);
      setAllUsersSelected(newSelection.length === selectableUsers.length);
    }
  };

  const handleSelectAllChange = () => {
    if (allUsersSelected) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(selectableUsers);
    }

    setAllUsersSelected(!allUsersSelected);
  };

  return (
    <>
      <SlideOver.Header
        title={t('auth.subjectAdd')}
        backgroundClassName="bg-gray-600"
        onClose={() => onClose(undefined)}
      />
      <SlideOver.Content className="p-8">
        {isFetching === true ? (
          <LoadingIndicator text={t('auth.roleLoadingIndicator')} />
        ) : (
          <div className="divide-y">
            <div className="flex flex-row items-center bg-white">
              <div className="p-3 w-full flex items-center">
                <input
                  type="checkbox"
                  checked={allUsersSelected}
                  onChange={() => handleSelectAllChange()}
                  autoFocus={false}
                />
                <span className="ml-2 text-xs italic">
                {t('common.selectAll')} 
                </span>
              </div>
              <div className="relative text-gray-600 m-2">
                <SearchIcon className="absolute left-0 top-0 mt-3.5 ml-3.5 h-5 w-5" />
                <input
                  type="search"
                  placeholder={t('common.search') ?? ''}
                  className="bg-gray-100 h-12 px-5 pl-10 rounded-full text-sm focus:outline-none border-none"
                  onChange={(e) => setFilterText(e.target.value)}
                />
              </div>
            </div>
            {selectableUsers
              .filter((x) => x.name !== ' ' ? x.name.toLowerCase().includes(filterText?.toLowerCase() ?? '') : x.firstName.toLowerCase().includes(filterText?.toLowerCase() ?? ''))
              .map((user, index) => (
                <label className="flex items-center bg-white h-14 p-3 cursor-pointer" key={index}>
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(user)}
                    onChange={() => handleChange(user)}
                    autoFocus={false}
                  />
                  <div className="ml-3 truncate">
                    <div className="truncate">{(user.name !== ' ') ? user.name :  `${user.firstName} ${user.lastName}`}</div>
                    <div className="text-sm text-gray-500 truncate">{user.email}</div>
                  </div>
                </label>
              ))}
          </div>
        )}
      </SlideOver.Content>
      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(undefined)}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={() => onClose(selectedUsers)} innerRef={submitRef}>
          {t('common.add')}
        </Button>
      </SlideOver.Controls>
    </>
  );
};
