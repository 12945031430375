import { AuthRoleAssignmentReadModel } from '@client/shared/api';
import { ArrowUturnLeftIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TranslateRoleName } from './RoleTranslation';
import { TrashIcon } from '@client/shared/toolkit';

interface RoleRowProps {
  state: RoleState;
  role: AuthRoleAssignmentReadModel;
  onDelete: (role: AuthRoleAssignmentReadModel) => void;
  onEdit: (role: AuthRoleAssignmentReadModel) => void;
}

export type RoleState = 'Assigned' | 'New' | 'Deleted';

export const RoleRow = ({ onEdit, onDelete, role, state }: RoleRowProps) => {
  const { t } = useTranslation();

  const handleEdit = () => {
    if (role.scope === 'Tenant') {
      return;
    }

    onEdit(role);
  };

  return (
    <div className="flex flex-col px-4 py-2 hover:bg-gray-100 cursor-pointer overflow-x-hidden">
      <div className="flex items-center">
        <div className="flex w-full items-center" onClick={() => handleEdit()}>
          <div className="h-6 w-6">
            <ShieldCheckIcon
              className={classNames(
                'w-6 h-6',
                { 'text-green-600': state === 'New' },
                { 'text-red-600': state === 'Deleted' }
              )}
            />
          </div>
          <div className="flex flex-col ml-2">
            <div>{TranslateRoleName(role.name, role.systemRoleType)}</div>
            <div className="flex flex-row">
              {role.scope === 'Project' && role.assignments.every((x) => x.project === undefined) && (
                <div className="text-yellow-600">{t('auth.roleAssignmentImpactLevelWarning')}</div>
              )}
              {role.scope === 'Project' && role.assignments.some((x) => x.project !== undefined) && (
                <div className="flex flex-wrap">
                  {role.assignments.map((assignment, index) => (
                    <div key={index} className="flex items-center">
                      <div className="text-sky-600">{assignment.project?.name}</div>
                      {index !== role.assignments.length - 1 && <div className="mx-1">{'|'}</div>}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="text-gray-500 hover:text-black">
          {state === 'Assigned' && <TrashIcon className="w-6" onClick={() => onDelete(role)} />}
          {state !== 'Assigned' && <ArrowUturnLeftIcon className="w-6" onClick={() => onDelete(role)} />}
        </div>
      </div>
    </div>
  );
};
