import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SetPortfolioPayload = { portfolioId: string | undefined; };

export interface PortfolioState {
  loadedPortfolioId?: string;
}

const initialState: PortfolioState = {};

export const portfolioSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setPortfolioId: (state, action: PayloadAction<SetPortfolioPayload | undefined>) => {
      // if project id changes, remove variant information
      state.loadedPortfolioId = action.payload?.portfolioId;
    }
  },
});

export const { setPortfolioId } = portfolioSlice.actions;
