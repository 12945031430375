import React, { useMemo } from 'react';
import { ElementTimelineReadModel, TimelineTypes } from '@client/shared/api';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BaseSelect, DateFromIcon } from '@client/shared/toolkit';
import { TimeLineElementInput } from '@client/project/shared';

interface FinancingGrantTimeLineInputProps {
  variantId?: string;
  timing: ElementTimelineReadModel | null | undefined;
  setGrantDate: (value: ElementTimelineReadModel) => void;
  disabled?: boolean;
}

export const FinancingGrantTimeLineInput = ({
  variantId,
  timing,
  setGrantDate,
  disabled,
}: FinancingGrantTimeLineInputProps) => {
  const { t } = useTranslation();
  const timeLine = useMemo(() => {
    return timing
      ? {
          variantDeliveryPhaseId: timing.startCalculationModelDeliveryPhaseId,
          variantMileStoneId: timing.startCalculationModelMileStoneId,
          offset: timing.startOffset,
          offsetPosition: timing.startOffsetPosition,
          elementTimelineId: timing.startElementTimelineId,
          type: timing.startType,
          duration: timing.duration,
          durationUnit: timing.durationUnit,
          effectiveDate: timing.effectiveStartDate,
          fixedDate: timing.effectiveStartDate,
        }
      : undefined;
  }, [timing]);

  return (
    <div>
      <TimeLineElementInput
        className={classNames('')}
        timeLineData={timeLine}
        label={t('projectCalculate.financingElementLabelGrantDate')}
        variantId={variantId ?? ''}
        showProjectEnd={timeLine?.type === 'ProjectEnd'}
        showVariableDates={timeLine?.type === 'DeliveryPhaseReference'}
        showFixedDates={timeLine?.type === 'FixedDates' || !timing}
        onChange={(data) => {
          if (data) {
            setGrantDate({
              startCalculationModelDeliveryPhaseId: data.variantDeliveryPhaseId,
              startCalculationModelMileStoneId: data.variantMileStoneId,
              startOffset: data.offset,
              startOffsetPosition: data.offsetPosition,
              startElementTimelineId: data.elementTimelineId,
              startType: data.type,
              duration: 0,
              durationUnit: 'Months',
              effectiveStartDate: data.effectiveDate,
              startFixedStartDate: data.fixedDate,
              endCalculationModelDeliveryPhaseId: null,
              endCalculationModelMileStoneId: null,
              endOffset: data.offset,
              endOffsetPosition: data.offsetPosition,
              endElementTimelineId: null,
              endType: 'FixedDates' as TimelineTypes,
              effectiveEndDate: data.effectiveDate,
              endDate: data.fixedDate,
            });
          }
        }}
        icon={<DateFromIcon className='scale-x-[-1]' />}
        disabled={disabled}
        optionalNode={
          <div className="my-3">
            <BaseSelect
              label={t('projectCalculate.timeLineInputPlan')}
              className="w-full"
              value={(timeLine?.type as TimelineTypes) ?? 'FixedDates'}
              onChange={(value) => {
                if (value) {
                  setGrantDate({
                    ...timing,
                    startType: value as TimelineTypes,
                    endType: 'FixedDates' as TimelineTypes,
                  });
                }
              }}
              disabled={disabled}
              options={[
                {
                  value: 'FixedDates',
                  label: t('projectCalculate.timeLineType.FixedDate'),
                },
                { value: 'ProjectEnd', label: t('projectCalculate.paybackTypes.EndOfTheProject') },
                { value: 'DeliveryPhaseReference', label: t('projectCalculate.timeLineType.DeliveryPhaseReference') },
              ]}
            />
          </div>
        }
      />
    </div>
  );
};
