import { CoordinateSystem } from './coordinateSystem';
import { CalculationModelMetadata, CalculationModelType } from '@client/shared/api';
import { Edge, Node, Position } from 'reactflow';

type TNodesAndEdges = {
  nodes: Node<TCalculationModelNode, string>[];
  edges: Edge<TCalculationModelNode>[];
};

type CalculationModelGraphOptions = {
  showUserSnapshots: boolean;
  showArchivedVariants: boolean;
  showArchivedVersions: boolean;
  showSystemSnapshots: boolean;
  showVariants: boolean;
};

const defaultOptions: CalculationModelGraphOptions = {
  showUserSnapshots: false,
  showArchivedVariants: false,
  showArchivedVersions: true,
  showSystemSnapshots: false,
  showVariants: true,
};

export type TCalculationModelNode = {
  calculationModel: CalculationModelMetadata;
  isMainLine: boolean;
  parent?: TCalculationModelNode;
  children: TCalculationModelNode[];
  x_index: number;
  y_index: number;
};

const createHierarchy = (rootNode: TCalculationModelNode, nodes: TCalculationModelNode[]) => {
  rootNode.children = nodes.filter((x) => x.calculationModel.parentId === rootNode.calculationModel.id);
  rootNode.children.forEach((x) => {
    x.parent = rootNode;
    createHierarchy(x, nodes);
  });

  rootNode.isMainLine = rootNode.calculationModel.type === 'Version' || rootNode.children.some((x) => x.isMainLine);
};

const constructGraph = (calculationModels: CalculationModelMetadata[]): TCalculationModelNode[] => {
  // map to the TCalculationModelNode type
  const nodes = calculationModels.map((x) => {
    const node: TCalculationModelNode = {
      calculationModel: x,
      isMainLine: false,
      children: [],
      x_index: 0,
      y_index: 0,
    };
    return node;
  });

  // take the root node
  const rootNode = nodes.find((x) => x.calculationModel.parentId == null);
  if (rootNode == null) throw new Error('Cannot find root calculation model');

  // and fill out the fields in the wrapper (create the hierarchy)
  createHierarchy(rootNode, nodes);

  return nodes;
};

const removeByType = (originalNodes: TCalculationModelNode[], type: CalculationModelType): TCalculationModelNode[] => {
  let nodes = [...originalNodes];

  const nodesToDelete = nodes.filter((x) => x.calculationModel.type === type);

  nodesToDelete.forEach((model) => {
    // find all children
    const children = nodes.filter((x) => x.parent === model);

    // set their parent to their "grandparent"
    children.forEach((x) => (x.parent = model.parent));

    // inject into parent
    if (model.parent != null) {
      model.parent.children.splice(model.parent.children.indexOf(model), 1, ...children);
    }

    // and remove the deleted model from the array (mutate)
    nodes = nodes.filter((x) => x !== model);
  });

  return nodes;
};

const LAYOUT = {
  multiplierX: 250,
  multiplierY: 250,
  offsetY: 100,
};

const createReactflowNodes = (calculationModelNodes: TCalculationModelNode[]): TNodesAndEdges => {
  const nodes: Node<TCalculationModelNode, string>[] = calculationModelNodes.map((x) => ({
    id: x.calculationModel.id,
    data: x,
    type: 'calculationModel',
    draggable: false,
    targetPosition: Position.Left,
    sourcePosition: Position.Right,
    position: {
      x: Math.max(x.x_index * LAYOUT.multiplierX - 125, 0),
      y: x.y_index * LAYOUT.multiplierY,
    },
  }));

  const edges: Edge<TCalculationModelNode>[] = calculationModelNodes
    .filter((x) => x.parent != null)
    .map((x) => ({
      id: x.parent?.calculationModel.id + '-' + x.calculationModel.id,
      source: x.parent?.calculationModel.id ?? '',
      target: x.calculationModel.id,
      type: 'step',
    }));

  // add a "root" node (starting point)
  nodes.push({
    id: 'root',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    data: null!,
    type: 'root',
    targetPosition: Position.Left,
    sourcePosition: Position.Right,
    position: { x: 0, y: 0 },
  });

  // add connection from root node to all children without parent
  calculationModelNodes
    .filter((x) => x.parent == null)
    .forEach((x) => {
      edges.push({
        id: `root-${x.calculationModel.id}`,
        source: 'root',
        target: x.calculationModel.id,
        type: 'step',
      });
    });

  return { nodes, edges };
};

const markXYIndex = (node: TCalculationModelNode, x_index: number, coordinateSystem: CoordinateSystem) => {
  node.x_index = x_index;
  node.y_index = coordinateSystem.getNextPossibleIndex(x_index);
  node.children.forEach((x) => markXYIndex(x, x_index + 1, coordinateSystem));
};

const ensureMainlineIsAlwaysFirstChild = (nodes: TCalculationModelNode[]) => {
  const rootMainlineElementIndex = nodes.findIndex((x) => x.isMainLine && x.parent == null);
  const rootMainlineElement = nodes.splice(rootMainlineElementIndex, 1);
  nodes.unshift(...rootMainlineElement);

  nodes
    .filter((x) => x.children.length > 0)
    .forEach((x) => {
      const mainlineIndex = x.children.findIndex((x) => x.isMainLine);
      if (mainlineIndex > -1) {
        const removed = x.children.splice(mainlineIndex, 1);
        x.children.unshift(...removed);
      }
    });
};

export const constructCalculationModelGraph = (
  calculationModels: CalculationModelMetadata[],
  partialOptions?: Partial<CalculationModelGraphOptions>
): TNodesAndEdges => {
  const options = { ...defaultOptions, ...partialOptions };

  // map the nodes to TVariantNode and create parent/child relationship
  let nodes = constructGraph(calculationModels);

  // conditionally remove certain items
  if (options.showArchivedVariants === false) nodes = removeByType(nodes, 'ArchivedVariant');
  if (options.showArchivedVersions === false) nodes = removeByType(nodes, 'ArchivedVersion');
  if (options.showSystemSnapshots === false) nodes = removeByType(nodes, 'SystemSnapshot');
  if (options.showUserSnapshots === false) nodes = removeByType(nodes, 'UserSnapshot');
  if (options.showVariants === false) nodes = removeByType(nodes, 'Variant');

  // make sure that the mainline is always the first child in .children
  ensureMainlineIsAlwaysFirstChild(nodes);

  // the coordinate systems helps us that each row+col combination can only be used once
  // this prevents that nodes "overlay" each other
  const coordinateSystem = new CoordinateSystem();

  // set row and col in all items
  nodes.filter((x) => x.parent == null).forEach((x) => markXYIndex(x, 1, coordinateSystem));

  // convert to react flow types
  const nodesAndEdges = createReactflowNodes(nodes);

  return nodesAndEdges;
};
