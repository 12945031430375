import {
  ContractReadModel,
  InvoiceReadModel,
  useApiGetContractQuery,
} from '@client/shared/api';
import {
  HintBox,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { InvoiceContract } from './InvoiceContract';
import { InvoiceData } from './InvoiceData';
import { InvoiceUpload } from './InvoiceUpload';
import { InvoiceWorkflows } from './InvoiceWorkflows';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import React, { useEffect, useState } from 'react';

export interface InvoiceTabProps {
  invoice: InvoiceReadModel
  isJobRouter?: boolean
  authorizedToReview: boolean
  contract?: ContractReadModel | null;
  setIsLoading: (isLoading: boolean) => void
}

export const InvoiceTab = (props: InvoiceTabProps) => {
  const {
    invoice,
    isJobRouter,
    authorizedToReview,
    contract,
    setIsLoading
  } = props

  const { t } = useTranslation()

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: loadedContract, isFetching: isLoadingContract } = useApiGetContractQuery({
    contractId: invoice.contractId ?? '',
    projectId: loadedProjectId ?? '',
    calculationModelId: loadedVariantId ?? ''
  }, {
    skip: !loadedProjectId || !loadedVariantId || !invoice.contractId || !!contract
  })

  const [isLoadingDocument, setIsLoadingDocument] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(false)

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(isLoadingDocument || isLoadingContract || isLoadingData)
    }
  }, [isLoadingContract, isLoadingDocument, isLoadingData, setIsLoading]);

  useEffect(() => {
    return () => {
      if (setIsLoading) {
        setIsLoading(false);
      }
    }
  }, [setIsLoading]);

  return (
    <>
      {invoice.isPxInvoice && (
        <HintBox hintType="info" className="mb-4">{t('projectInvoice.warningSynchronizedInvoice')}</HintBox>
      )}
      {invoice.errors && invoice.errors.length > 0 && (
        <HintBox hintType="warning" className="mb-4">
          {t('projectInvoice.workflowErrors')} <br />
          <ul>
            {invoice.errors.map((workflowError, i) => (
              <li className="mt-2" key={`workflow-error-${i}`}>
                {workflowError.activityName}: {workflowError.message}
              </li>
            ))}
          </ul>
        </HintBox>
      )}
      {invoice?.isWaitingForNextTask && (
        <HintBox hintType="info" className="mb-4">
          {t('projectInvoice.workflowIsProcessing')}
        </HintBox>
      )}
      <div className="flex flex-wrap">
        <div className="w-full xl:w-2/3 flex-1 relative">
          <div className="sticky top-0">
            <InvoiceUpload invoice={invoice} isJobRouter={isJobRouter} setIsLoading={setIsLoadingDocument} />
          </div>
        </div>
        <div className="flex-none w-full xl:w-1/3 pt-8 xl:pl-8 xl:pt-0 xl:min-w-[352px] flex flex-col gap-7">
          {/* CONTRACT DATA */}
          {invoice.contractId && (
            <InvoiceContract
              invoice={invoice}
              view={contract ? 'contract' : 'invoice'}
              contract={contract ?? loadedContract}
            />
          )}
          {/* INVOICE DATA */}
          <InvoiceData
            invoice={invoice}
            authorizedToReview={authorizedToReview}
            contract={contract ?? loadedContract}
            setIsLoading={setIsLoadingData}
          />
          {/* WORKFLOWS */}
          <InvoiceWorkflows invoice={invoice} />
        </div>
      </div>
    </>
  );
};
