import { useLoadedProjectId, useLoadedVariantId } from "@client/project/store";
import { InvoiceDocumentReadModel, useApiDeleteInvoiceDocumentMutation } from "@client/shared/api";
import { ConfirmModal, ModalOnCloseProps } from "@client/shared/toolkit";
import { Trans, useTranslation } from "react-i18next";
import { safeMutation } from '@client/shared/utilities';

interface DocumentDeleteModalProps extends ModalOnCloseProps {
  invoiceId: string;
  document: InvoiceDocumentReadModel;
}

export const InvoiceDocumentDeleteModal = ({ invoiceId, document, onClose }: DocumentDeleteModalProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();

  const [deleteDocument, {isLoading}] = useApiDeleteInvoiceDocumentMutation();

  const handleDelete = async () => {
    try {
      await safeMutation(
        deleteDocument,
        {
          projectId: loadedProjectId ?? 'unset',
          calculationModelId: loadedCalculationModelId ?? 'unset',
          invoiceId: invoiceId,
          documentId: document.id
        },
        isLoading
      );

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ConfirmModal
      title={t('projectCalculate.DeleteDocument')}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      <Trans i18nKey="projectCalculate.DocumentDeleteConfirmation" values={{ entity: document.fileName }}>
        <div>
          Möchten Sie die Datei <span className="font-bold">Entity</span> wirklich löschen?
        </div>
        <div className="pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
