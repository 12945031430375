import * as yup from 'yup';
import { InferType } from 'yup';
import { RequirementCategory } from '@client/shared/api';

export const RequirementCreateFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  code: yup.string().required('validation.required'),
  clusterItemId: yup.string().required('validation.required'),
});

export type RequirementCreateFormValidationValues = InferType<typeof RequirementCreateFormValidationSchema>;

export const RequirementReleaseForReviewFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  code: yup.string().required('validation.required'),
  clusterItemId: yup.string().required('validation.required'),
  clusterChanged: yup.boolean(),
  reportedById: yup.string().required('validation.required'),
  reportedByDeputyId: yup.string().required('validation.required'),
  editorId: yup.string().required('validation.required'),
  editorDeputyIds: yup.array()
    .of(yup.string()
      .min(1, 'validation.required')
      .required("validation.required")
    ),
  deadline: yup.string().required('validation.required'),
  comment: yup.string().optional(),
  reason: yup.string().when('clusterChanged', {
    is: (clusterChanged: boolean) => clusterChanged,
    then: (schema) => schema.required('validation.required'),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

export type RequirementReleaseForReviewValidationValues = InferType<
  typeof RequirementReleaseForReviewFormValidationSchema
>;

export const RequirementRejectFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  code: yup.string().required('validation.required'),
  clusterItemId: yup.string().required('validation.required'),
  reason: yup.string().required('validation.required'),
  clusterChanged: yup.boolean(),
  reportedById: yup.string().required('validation.required'),
  reportedByDeputyId: yup.string().required('validation.required'),
});

export type RequirementRejectFormValidationValues = InferType<typeof RequirementRejectFormValidationSchema>;

export const RequirementUpdateFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  code: yup.string().required('validation.required'),
  clusterItemId: yup.string().required('validation.required'),
  category: yup
    .mixed<RequirementCategory>()
    .oneOf([
      'GesetzlicheAnforderung',
      'Mängelbeseitigung',
      'Bestandserhalt',
      'Arbeitsschutz',
      'KapazitiverAusbau',
      'Grenzkapazität',
      'Kapazitätserweiterung',
      'UmsatzwirkungExtern',
      'UmsatzwirkungEntgelte'
    ])
    .optional()
    .nullable(),
  description: yup.string().optional().nullable(),
  reportedByDeputyId: yup.string().optional().nullable()
});

export type RequirementUpdateFormValidationValues = InferType<typeof RequirementUpdateFormValidationSchema>;
