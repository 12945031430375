import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TearOffCalenderIcon,
  Modal,
  ConfirmModal,
  ContextMenu,
  ContextMenuItem,
  LoadingIndicator,
  AddButton, PencilIcon, TrashIcon
} from '@client/shared/toolkit';
import {
  EuriborExtendedReadModel,
  useApiGetEuriborRatesQuery,
  useApiDeleteEuriborRateProjectionMutation,
} from '@client/shared/api';
import { formatNumber, formatDate, safeMutation, formatNullableDateOnly } from '@client/shared/utilities';
import { DatePicker } from '@client/shared/toolkit';
import classNames from 'classnames';
import { EditEuriborRateModal } from './EditEuriborRateModal';

const EuriborBox = ({ label, value, width }: { label: string; value: string; width: string }) => {
  return (
    <div className={`w-${width}/12 rounded-lg border border-slate-300 py-2 px-4`}>
      <div className="text-xs text-slate-500 font-semibold">{label}</div>
      <div className="font-bold">{value}</div>
    </div>
  );
};

export const EuriborRates = () => {
  const today = new Date();
  const [startDate, setStartDate] = useState<Date | undefined>(today);
  const [endDate, setEndDate] = useState<Date | undefined>(today);
  const [startDateQuery, setStartDateQuery] = useState<Date | undefined>(today);
  const [endDateQuery, setEndDateQuery] = useState<Date | undefined>(today);
  const [isEditRateModalOpen, setIsEditRateModalOpen] = useState(false);
  const [isDeleteRateModalOpen, setIsDeleteRateModalOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState<EuriborExtendedReadModel | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { data, isFetching: isLoadingEuriborRates } = useApiGetEuriborRatesQuery(
    {
      from: formatNullableDateOnly(startDateQuery),
      to: formatNullableDateOnly(endDateQuery),
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (startDate && endDate) {
      if (startDate < new Date(endDate.getFullYear(), endDate.getMonth() - 2, endDate.getDate())) {
        setEndDateQuery(startDate);
        setEndDate(startDate);
        setStartDateQuery(startDate);
      } else if (startDate > endDate) {
        setEndDateQuery(startDate);
        setEndDate(startDate);
        setStartDateQuery(startDate);
      } else {
        setStartDateQuery(startDate);
        setEndDateQuery(endDate);
      }
    }
  }, [startDate, endDate]);

  const [deleteElement, { isLoading: isDeleting }] = useApiDeleteEuriborRateProjectionMutation();

  const handleDelete = (id: string) => {
    try {
      safeMutation(
        deleteElement,
        {
          id: id,
        },
        isDeleting,
      );
    } catch (e) {
      console.log(e);
    }
    setIsDeleteRateModalOpen(false);
  };

  const closeDeleteRateModal = () => {
    setIsDeleteRateModalOpen(false);
    setSelectedElement(undefined);
  };

  const closeEditRateModal = () => {
    setIsEditRateModalOpen(false);
    setSelectedElement(undefined);
  };

  const contextItems: ContextMenuItem[] = [
    {
      label: t('common.edit'),
      subtitle: '',
      icon: <PencilIcon />,
      onClick: () => setIsEditRateModalOpen(true),
    },
    {
      label: t('common.delete'),
      subtitle: '',
      isDisabled: !selectedElement?.euriborReadModel,
      icon: <TrashIcon />,
      onClick: () => setIsDeleteRateModalOpen(true),
    },
  ];

  const euriborProjections = data?.euriborRates.filter((element) => !!element.euriborReadModel);

  return (
    <div className="p-4">
      {(isLoading || isLoadingEuriborRates) ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className="font-bold">{t('projectCalculate.euriborCurrent')}</div>
          <div className="font-medium text-slate-600">{t('projectCalculate.euriborCurrentDescription')}</div>
          <div className="flex flex-row mt-2">
            <div className="flex flex-row w-full bg-white gap-2">
              <EuriborBox label={t('common.date')} value={formatDate(data?.lastConfirmedEuribor?.date)} width="2" />
              <EuriborBox
                label={t('projectCalculate.euriborPeriods.OneMonth')}
                value={formatNumber(data?.lastConfirmedEuribor?.oneMonthEuribor, { maxDigits: 3 }) + ' %'}
                width="2"
              />
              <EuriborBox
                label={t('projectCalculate.euriborPeriods.ThreeMonths')}
                value={formatNumber(data?.lastConfirmedEuribor?.threeMonthsEuribor, { maxDigits: 3 }) + ' %'}
                width="2"
              />
              <EuriborBox
                label={t('projectCalculate.euriborPeriods.SixMonths')}
                value={formatNumber(data?.lastConfirmedEuribor?.sixMonthsEuribor, { maxDigits: 3 }) + ' %'}
                width="2"
              />
              <EuriborBox
                label={t('projectCalculate.euriborPeriods.TwelveMonths')}
                value={formatNumber(data?.lastConfirmedEuribor?.twelveMonthsEuribor, { maxDigits: 3 }) + ' %'}
                width="2"
              />
              {/* <EuriborBox
                label={t('projectCalculate.euriborUserConfirmed')}
                value={t(
                  data?.lastConfirmedEuribor?.userConfirmed
                    ? 'projectCalculate.euriborConfirmedYes'
                    : 'projectCalculate.euriborConfirmedNo',
                )}
                width="2"
              />
              <EuriborBox
                label={t('projectCalculate.euriborConfirmed')}
                value={t(
                  data?.lastConfirmedEuribor?.systemConfirmed
                    ? 'projectCalculate.euriborConfirmedYes'
                    : 'projectCalculate.euriborConfirmedNo',
                )}
                width="1"
              /> */}
            </div>
            <div className="w-12" />
          </div>
          <div>
            <div className="w-11/12 h-[2px] bg-slate-200 my-5" />
          </div>

          <div className="font-bold">{t('projectCalculate.euriborProjections')}</div>
          <div className="font-medium text-slate-600">{t('projectCalculate.euriborProjectionsDescription')}</div>

          <div className="w-full flex flex-row my-2 gap-2">
            <div className="w-2/12 border rounded-lg border-slate-300 p-[3px]">
              <DatePicker
                label={t('projectCalculate.euriborStartDate')}
                icon={<TearOffCalenderIcon />}
                value={startDate}
                onChange={(x) => setStartDate(x ?? undefined)}
              />
            </div>
            <div className="w-2/12 border rounded-lg border-slate-300 p-[3px]">
              <DatePicker
                label={t('projectCalculate.euriborEndDate')}
                icon={<TearOffCalenderIcon />}
                value={endDate}
                onChange={(x) => setEndDate(x ?? undefined)}
                minDate={startDate}
                maxDate={startDate ? new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 60) : undefined}
              />
            </div>
          </div>

          <div className="mb-5">
            {euriborProjections && euriborProjections?.length > 0 ? (
              euriborProjections?.map((element, i) => (
                <Fragment key={element?.date}>
                  <div className="flex flex-row">
                    <div className={classNames('w-full flex flex-row gap-2 py-1')}>
                      <EuriborBox label={t('common.date')} value={formatDate(element?.date)} width="2" />
                      <EuriborBox
                        label={t('projectCalculate.euriborPeriods.OneMonth')}
                        value={formatNumber(element?.euriborReadModel?.oneMonthEuribor, { maxDigits: 3 }) + ' %'}
                        width="2"
                      />
                      <EuriborBox
                        label={t('projectCalculate.euriborPeriods.ThreeMonths')}
                        value={formatNumber(element?.euriborReadModel?.threeMonthsEuribor, { maxDigits: 3 }) + ' %'}
                        width="2"
                      />
                      <EuriborBox
                        label={t('projectCalculate.euriborPeriods.SixMonths')}
                        value={formatNumber(element?.euriborReadModel?.sixMonthsEuribor, { maxDigits: 3 }) + ' %'}
                        width="2"
                      />
                      <EuriborBox
                        label={t('projectCalculate.euriborPeriods.TwelveMonths')}
                        value={formatNumber(element?.euriborReadModel?.twelveMonthsEuribor, { maxDigits: 3 }) + ' %'}
                        width="2"
                      />
                      {/* <EuriborBox
                        label={t('projectCalculate.euriborUserConfirmed')}
                        value={t(
                          element?.euriborReadModel?.userConfirmed
                            ? 'projectCalculate.euriborConfirmedYes'
                            : 'projectCalculate.euriborConfirmedNo',
                        )}
                        width="2"
                      />
                      <EuriborBox
                        label={t('projectCalculate.euriborConfirmed')}
                        value={t(
                          element?.euriborReadModel?.systemConfirmed
                            ? 'projectCalculate.euriborConfirmedYes'
                            : 'projectCalculate.euriborConfirmedNo',
                        )}
                        width="1"
                      /> */}
                      <div className="w-2/12 flex items-center">
                        {!element.euriborReadModel?.systemConfirmed && (
                          <div onClick={() => setSelectedElement(element)}>
                            <ContextMenu items={contextItems} className="w-6 h-6" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Fragment>
              ))
            ) : (
              <div className="mt-10 mb-5 w-full flex justify-center">
                <div className="w-full font-medium italic text-slate-600">
                  {t('projectCalculate.euriborNoProjectionsAvailable')}
                </div>
              </div>
            )}

            <AddButton onClick={() => setIsEditRateModalOpen(true)} />
          </div>
        </>
      )}

      <Modal isOpen={isEditRateModalOpen} onClose={closeEditRateModal}>
        <EditEuriborRateModal
          element={selectedElement}
          rates={data?.euriborRates}
          onClose={closeEditRateModal}
          setIsLoading={setIsLoading}
        />
      </Modal>
      <Modal isOpen={isDeleteRateModalOpen} onClose={closeDeleteRateModal} variant="small">
        <ConfirmModal
          title={formatDate(selectedElement?.date ?? '')}
          onConfirm={() => selectedElement && handleDelete(selectedElement?.euriborReadModel?.id ?? '')}
          onClose={closeDeleteRateModal}
          confirmVariant="danger"
          confirmText={t('common.delete')}
        >
          {t('projectCalculate.euriborConfirmDelete')}
        </ConfirmModal>
      </Modal>
    </div>
  );
};
