import { SystemRoleType } from '@client/shared/api';
import { i18n } from '@client/shared/utilities';

export function TranslateRoleName(roleName: string, systemRoleType: SystemRoleType): string {
  switch (systemRoleType) {
    case 'TenantAdmin':
      return i18n.t('role.tenantAdminRoleName');
    case 'ProjectReader':
      return i18n.t('role.projectReaderRoleName');
    case 'ProjectContributer':
      return i18n.t('role.projectContributerRoleName');
    case 'ProjectAdmin':
      return i18n.t('role.projectAdminRoleName');
    default:
      return roleName;
  }
}

export function TranslateRoleComment(roleComment: string | undefined | null, systemRoleType: SystemRoleType): string | undefined | null {
  switch (systemRoleType) {
    case 'TenantAdmin':
      return i18n.t('role.tenantAdminRoleComment');
    case 'ProjectReader':
      return i18n.t('role.projectReaderRoleComment');
    case 'ProjectContributer':
      return i18n.t('role.projectContributerRoleComment');
    case 'ProjectAdmin':
      return i18n.t('role.projectAdminRoleComment');
    default:
      return roleComment;
  }
}
