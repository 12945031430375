import { useState } from 'react';
import { Button, Modal, SlideOver } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { ChangePasswordModal } from './ChangePasswordModal';
import { useNavigate } from 'react-router-dom';
import { useUi } from '@client/shared/store';
import { ChangeEmailSlideOver } from './ChangeEmailSlideOver';
import { SettingsHeader } from '../SettingsHeader';

export const LoginAndSecurity = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ui = useUi();
  const user = ui.appUser;

  const [isPwModalOpen, setIsPwModalOpen] = useState(false);
  const [isMailModalOpen, setIsMailModalOpen] = useState(false);

  return (
    <>
      <div>
        <SettingsHeader>
          {t('app.loginSecurityTitle')}
        </SettingsHeader>
        <div className="border-b pt-2 pb-6 flex">
          <div className="grow">
            <div className="text text-gray-900 mb-3 font-bold">{t('common.email')}</div>
            <div className="text font-bold text-gray-700">{user.email}</div>
          </div>
          <div className="mt-auto">
            <Button onClick={() => setIsMailModalOpen(true)}>{t('common.edit')}</Button>
          </div>
        </div>

        <div className="border-b pt-2 pb-6 flex">
          <div className="grow">
            <div className="text text-gray-900 mb-3 font-bold">{t('common.password')}</div>
            <div className="text-xl font-bold text-gray-700">***********</div>
          </div>
          <div className="mt-auto">
            <Button onClick={() => setIsPwModalOpen(true)}>{t('app.loginSecurityChangePasswordAction')}</Button>
          </div>
        </div>

        <div className="border-b pt-2 pb-6 flex">
          <div className="grow">
            <div className="text text-gray-900 font-bold">{t('auth.security')}</div>
            <div className="text text-gray-900 mb-1">{t('auth.logoutAllDevices')}</div>
            <div className="text-sm text-gray-500 mb-5">{t('auth.logoutAllDevicesHint')}</div>
          </div>
          <div className="mt-auto">
            <Button onClick={() => navigate('/logoutsessions')}>{t('common.logout')}</Button>
          </div>
        </div>
      </div>

      <Modal isOpen={isPwModalOpen} onClose={() => setIsPwModalOpen(false)}>
        <ChangePasswordModal onClose={() => setIsPwModalOpen(false)} />
      </Modal>
      <SlideOver isOpen={isMailModalOpen} onClose={() => setIsMailModalOpen(false)}>
        <ChangeEmailSlideOver onClose={() => setIsMailModalOpen(false)} />
      </SlideOver>
    </>
  );
};
