import * as yup from 'yup';
import { InferType } from 'yup';

export const InvoiceTitleEditFormValidationSchema = yup.object({
  id: yup.string().required('validation.required'),
  code: yup.string().required('validation.required'),
  vat: yup
    .number()
    .typeError('validation.number')
    .test('range', 'validation.rangeHundred', (value) => (value as number) >= 0 && (value as number) <= 100)
    .required('validation.required'),
  invoiceValue: yup.number().required('validation.required'),
  checked: yup.boolean().optional().nullable()
});

export type InvoiceTitleEditFormValidationValues = InferType<typeof InvoiceTitleEditFormValidationSchema>;
