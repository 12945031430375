import { DecoratedElement } from '../hooks';

export const flattenDecoratedElements = <T>(elements: DecoratedElement<T>[] | undefined): DecoratedElement<T>[] => {
  if (elements == null) return [];
  else
    return elements
      .flatMap((element) => {
        return [element, ...flattenDecoratedElements(element.children)];
      })
      .filter((x) => x != null);
};
