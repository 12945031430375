import { DealStatus, DealReadModel } from '@client/shared/api';
import classNames from 'classnames';
import {
  ExclamationTriangleIcon,
  LockOpenIcon,
  LockClosedIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import { getSumOfCommonAndRentalSpace, getNumberOfRooms, getDealContractItemsSum } from '../DealCalculation';
import { useState } from 'react';
import { DealReOpenModal } from './DealReOpenModal';
import { DealEditSlideOver } from './DealEditSlideOver';
import { useTranslation } from 'react-i18next';
import { DocumentCustomIcon, HouseMoneyIcon, Modal, PencilIcon, SlideOver } from '@client/shared/toolkit';
import { humanizeJsonDate, formatUnit } from '@client/shared/utilities';
import { useLoadedProjectId, useLoadedProjectUnitSystemSymbol } from '@client/project/store';
import { useCanReopenDeal } from '../../hooks';
import { FormattedCurrency } from '@client/project/shared';

interface DealRowProps {
  item: DealReadModel;
  onSelect: (deal: DealReadModel) => void;
  isReadOnly:boolean;
}

export const DealRow = ({ item, onSelect, isReadOnly }: DealRowProps) => {
  const { t } = useTranslation();

  const unitSystem = useLoadedProjectUnitSystemSymbol();

  const projectId = useLoadedProjectId();
  const canReopen = useCanReopenDeal(item.type);

  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [showReopenModal, setShowReopenModal] = useState<boolean>(false);

  const updatedAt = humanizeJsonDate(item.updatedAt);

  const assignedRentingSpace = getSumOfCommonAndRentalSpace(item.costItems);
  const numberOfRooms = getNumberOfRooms(item.costItems);
  const rentItemSum = getDealContractItemsSum(item);

  const isLocked = item.status === 'Closed';

  const getBorderColor = (status: DealStatus) => {
    switch (status) {
      case 'Interested':
        return 'border-green-300';
      case 'Draft':
        return 'border-green-500';
      case 'Reserved':
        return 'border-green-700';
      case 'Closed':
        return 'border-green-900';
      default:
        return 'border-red-500';
    }
  };

  const hasContractItemConflict = item.contractItems.filter((x) => x.hasSpaceConflict).length > 0;
  const hasSpaceUsageConflict = item.costItems.filter((x) => x.conflictingSpaceUsage.length > 0).length > 0;
  const hasReservedConflict = item.costItems.filter((x) => x.conflictingReserved.length > 0).length > 0;
  const hasSoldConflict = item.costItems.filter((x) => x.conflictingSold.length > 0).length > 0;
  const hasRentConflict = item.costItems.filter((x) => x.conflictingRented.length > 0).length > 0;
  const blockedConflict = hasSoldConflict || hasRentConflict;

  return (
    <div
      className={classNames(
        'flex w-full bg-white hover:bg-blue-100 shadow-sm cursor-pointer h-14 border-l-8 rounded text-sm',
        getBorderColor(item.status)
      )}
    >
      <div className="flex w-full" onClick={() => onSelect(item)}>
        <div className="w-14 flex justify-center items-center">
          <DocumentCustomIcon className="w-4" />
        </div>

        <div className="w-7/12 flex items-center ml-2">
          <div>
            <div className="truncate">
              <span className="mr-2 font-bold">{item.name}</span>
              <span className="mr-2 ">&bull;</span>
              <span className="mr-2 ">{item.code}</span>
              {updatedAt && (
                <span>
                  <span className="mr-2 ">&bull;</span>
                  <span className="inline-flex items-center rounded-full px-2 text-[10px] text-white bg-gray-400/50">
                    <PencilIcon className="w-3 h-3 mr-1" />
                    {updatedAt}
                  </span>
                </span>
              )}
              {(hasContractItemConflict || hasReservedConflict || hasSpaceUsageConflict) && !blockedConflict && (
                <span className="inline-flex items-center rounded-sm px-2 text-[12px] text-white bg-yellow-400/60 ml-3 align-bottom">
                  <ExclamationTriangleIcon className="w-5 h-5 mr-1" />
                  {t('projectRent.dealConflict')}
                </span>
              )}
              {blockedConflict && (
                <span className="inline-flex items-center rounded-sm px-2 text-[12px] text-white bg-red-600/60 ml-3 align-bottom">
                  <ExclamationTriangleIcon className="w-5 h-5 mr-1" />
                  {t('projectRent.dealConflict')}
                </span>
              )}
            </div>
            <div className="text-gray-400 text-xs font-bold">
              {item.costItems.map((x) => (
                <span key={x.id} className="mr-2 ">
                  {x.taxonomyItem?.itemName}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="w-5/12 flex items-center">
          <div className="w-3/12 text-end"></div>

          <div className="border-l-2 h-6 border-slate-300 m-4" />
          <HouseMoneyIcon className="w-4" />

          <div className="w-3/12 leading-3 text-end">
            <div className="text-sm font-bold">{formatUnit(assignedRentingSpace, unitSystem)}</div>
            <div className="text-[10px] text-gray-400">{t('projectRent.dealArea')}</div>
          </div>

          <div className="w-2/12 leading-3 text-end">
            <div className="text-sm font-bold">{numberOfRooms}</div>
            <div className="text-[10px] text-gray-400">{t('projectRent.dealRoom', { count: numberOfRooms })}</div>
          </div>
          <div className="w-4/12 leading-3 text-end">
            <div className="text-sm font-bold"><FormattedCurrency amount={rentItemSum} /></div>
            <div className="text-[10px] text-gray-400">
              {item.type === 'Rent' && t('projectRent.dealRent')}
              {item.type === 'Sell' && t('projectRent.dealPrice')}
            </div>
          </div>
        </div>
      </div>
      <div className='flex w-14 items-center justify-end mr-3 text-gray-500 hover:text-gray-800'>
        {!isLocked && (
          <button onClick={() => setShowEditForm(true)}>
            <EllipsisVerticalIcon className="w-6 h-6" />
          </button>
        )}
        {isLocked && canReopen && !isReadOnly &&
            <LockButton onClick={() => setShowReopenModal(true)} />
        }
      </div>

      <SlideOver isOpen={showEditForm} onClose={() => setShowEditForm(false)}>
        <DealEditSlideOver
          isReadOnlyVersion={isReadOnly}
          isAddMode={false}
          createType={item.type}
          deal={item}
          projectId={projectId ?? ''}
          onClose={() => setShowEditForm(false)}
        />
      </SlideOver>

      <Modal isOpen={showReopenModal} onClose={() => setShowReopenModal(false)}>
        <DealReOpenModal deal={item} onClose={() => setShowReopenModal(false)} />
      </Modal>
    </div>
  );
};

interface LockButtonProps {
  onClick?: () => void;
}

const LockButton = ({ onClick }: LockButtonProps) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <button onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={onClick}>
      {isHovering && <LockOpenIcon className="w-6 h-6" />}
      {!isHovering && <LockClosedIcon className="w-6 h-6" />}
    </button>
  );
};
