import { useLoadedProject } from '.';

export const useGetCurrencySymbol = (): string => {
  const loadedProject = useLoadedProject();
  return loadedProject?.data?.project.payload.currency.unit ?? '€';
};

export const useGetCurrency = (): string => {
  const loadedProject = useLoadedProject();
  return loadedProject?.data?.project.payload.currency.currency.toUpperCase() ?? 'EUR';
};
