import { DealReadModel } from '@client/shared/api';
import { getNumberOfRooms, getDealContractItemsSum, getSumOfCommonAndRentalSpace } from '../DealCalculation';
import { useTranslation } from 'react-i18next';
import { formatUnit } from '@client/shared/utilities';
import { FormattedCurrency } from '@client/project/shared';
import { useLoadedProjectUnitSystemSymbol } from '@client/project/store';
import { RentContractAgreementIcon } from '@client/shared/toolkit';

interface DealSummaryProps {
  deal: DealReadModel;
}

export const DealSummary = ({ deal }: DealSummaryProps) => {
  const { t } = useTranslation();

  const unitSystem = useLoadedProjectUnitSystemSymbol();

  const totalPercentage = null;

  return (
    <div className="w-full">
      <div className="mt-8">
        {totalPercentage && (
          <div className="text-end">
            <strong className="text-2xl">21%</strong>
            <div className="text-gray-400 text-xs">Vom Gebäude</div>
          </div>
        )}
      </div>
      <div className="flow-root">
        <div className="float-left">
          <div className="flex">
            <RentContractAgreementIcon className="text-cyan-700 mr-4 w-16 -mt-5" />
            <div className="flex-start -mt-3">
              <strong>{deal.code}</strong>
              <div className="text-gray-400 text-xs">{t('projectRent.dealContractId')}</div>

              <div className="inline-flex items-center">
                <span>
                  <div className="text-xl mt-4 font-bold">
                    {formatUnit(getSumOfCommonAndRentalSpace(deal.costItems), unitSystem)}
                  </div>
                  <div className="text-gray-400 text-xs">{t('projectRent.dealPrimaryArea')}</div>
                </span>
                <span className="ml-5 mt-4 border-l-2 h-6 border-slate-300"></span>
                <span className="ml-5 text-center">
                  <div className="text-xl mt-4 ml-2 font-bold">{getNumberOfRooms(deal.costItems)}</div>
                  <div className="text-gray-400 ml-2 text-xs">
                    {t('projectRent.dealRoom', { count: getNumberOfRooms(deal.costItems) })}
                  </div>
                </span>
                <span className="ml-5 mt-4 border-l-2 h-6 border-slate-300"></span>
                <span className="ml-5">
                  <div className="text-xl mt-4 ml-2 font-bold">
                    <FormattedCurrency amount={getDealContractItemsSum(deal)} />
                  </div>
                  <div className="text-gray-400 text-xs ml-2">
                    {deal.type === 'Rent' && t('projectRent.dealRent')}
                    {deal.type === 'Sell' && t('projectRent.dealPrice')}
                  </div>
                </span>
              </div>
            </div>
          </div>
          <h3 className="font-bold text-3xl items-end mt-8">
            {deal.type === 'Rent' && t('projectRent.dealRentContract')}
            {deal.type === 'Sell' && t('projectRent.dealSaleContract')}
          </h3>
        </div>

        <div className="float-right">
          <img className="mt-8 w-72" src="/assets/rent-background.svg" alt="" />
        </div>
      </div>
    </div>
  );
};
