import { DecoratedCard, PencilIcon } from '@client/shared/toolkit';
import { TaxonomyReadModel, TaxonomyType, PlotReadModel } from '@client/shared/api';
import { formatUnit } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useMgfExclusiveTypes } from '../../hooks';

interface AreasCardProps {
  item?: TaxonomyReadModel | undefined;
  plot?: PlotReadModel | undefined;
  setIsEditPlotAreaSlideOverOpen?: (value: boolean) => void;
  setSelectedField?: (field: string) => void;
  setIsBuildingItemSlideOverOpen?: (value: boolean) => void;
}

export const AreasCard = ({
  item,
  plot,
  setSelectedField,
  setIsBuildingItemSlideOverOpen,
  setIsEditPlotAreaSlideOverOpen,
}: AreasCardProps) => {
  const { t } = useTranslation();
  const mgfExclusiveTypes = useMgfExclusiveTypes(item);

  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        if (item && setSelectedField && setIsBuildingItemSlideOverOpen) {
          setSelectedField('areas');
          setIsBuildingItemSlideOverOpen(true);
        } else if (setIsEditPlotAreaSlideOverOpen) {
          setIsEditPlotAreaSlideOverOpen(true);
        }
      }}
    >
      <DecoratedCard>
        <DecoratedCard.Header showActionButton={true} icon={<PencilIcon />}>
          <div className="truncate">{t(`projectTaxonomy.building.areas`)}</div>
        </DecoratedCard.Header>
        <DecoratedCard.Content className="p-5 w-full h-full grid grid-cols-2 bg-white gap-3">
          {item && item?.itemType !== 'Parking' && (
            <>
              {!mgfExclusiveTypes.includes(item?.itemType as TaxonomyType) && (
                <>
                  {!!item?.sizes.effectiveGrossFloorValue && (
                    <div>
                      <div className="text-base font-bold text-gray-900">
                        {formatUnit(
                          item?.sizes.effectiveGrossFloorValue?.value,
                          item?.sizes.effectiveGrossFloorValue?.unit,
                        )}
                      </div>
                      <div className="text-xs text-gray-500">
                        {t('projectTaxonomy.building.effectiveGrossFloorValue')} ({t('projectTaxonomy.buildingBgf')})
                      </div>
                    </div>
                  )}

                  {!!item?.sizes.effectiveNetFloorValue && (
                    <div>
                      <div className="text-base font-bold text-gray-900">
                        {formatUnit(
                          item?.sizes.effectiveNetFloorValue?.value,
                          item?.sizes.effectiveNetFloorValue?.unit,
                        )}
                      </div>
                      <div className="text-xs text-gray-500">
                        {t('projectTaxonomy.building.effectiveNetFloorValue')} ({t('projectTaxonomy.buildingNgf')})
                      </div>
                    </div>
                  )}
                </>
              )}
              {!!item?.sizes.effectiveRentalSpaceValue?.value && (
                <div>
                  <div className="text-base font-bold text-gray-900">
                    {formatUnit(
                      item?.sizes.effectiveRentalSpaceValue?.value,
                      item?.sizes.effectiveRentalSpaceValue?.unit,
                    )}
                  </div>
                  <div className="text-xs text-gray-500">
                    {t('projectTaxonomy.building.effectiveRentalSpaceValue')} ({t('projectTaxonomy.buildingMfg')})
                    {/* - 𝑓𝑥 */}
                  </div>
                </div>
              )}
              {item && item?.itemType === 'Building' && (
                <>
                  {/* <div>
                <div className="text-base font-bold text-gray-900">{formatUnit(item?.sizes.bgf, 'm³')}</div>
                <div className="text-xs text-gray-500">{t('Bruttorauminhalt (BR)')} - 𝑓𝑥</div>
              </div> */}

                  <div className="col-span-2 m-2">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="font-bold text-3xl text-gray-300 col-span-2">Details</div>
                  {!!item?.grossFloorEfficiency?.value && (
                    <div>
                      <div className="text-base font-bold text-gray-900">
                        {formatUnit(item?.grossFloorEfficiency?.value, item?.grossFloorEfficiency?.unit)}
                      </div>
                      <div className="text-xs text-gray-500">
                        {t('projectTaxonomy.buildingMfg')} {t('projectTaxonomy.building.efficiency')}
                      </div>
                    </div>
                  )}
                  {!!item?.netFloorEfficiency?.value && (
                    <div>
                      <div className="text-base font-bold text-gray-900">
                        {formatUnit(item?.netFloorEfficiency?.value, item?.netFloorEfficiency?.unit)}
                      </div>
                      <div className="text-xs text-gray-500">
                        {t('projectTaxonomy.buildingNgf')} {t('projectTaxonomy.building.efficiency')}
                      </div>
                    </div>
                  )}
                  {/* {item?.sizes.constructionFloor && (
                <div>
                  <div className="text-base font-bold text-gray-900">
                    {formatUnit(item?.sizes.constructionFloor, 'm')}
                  </div>
                  <div className="text-xs text-gray-500">{t('Ø-Raumhöhe')}</div>
                </div>
              )}
              {item?.sizes.constructionFloor && (
                <div>
                  <div className="text-base font-bold text-gray-900">
                    {formatUnit(item?.sizes.constructionFloor, 'm²')}
                  </div>
                  <div className="text-xs text-gray-500">{t('Konstruktion Grundfläche (KGF)')}</div>
                </div>
              )}
              {item?.sizes.rentalSpace && (
                <div>
                  <div className="text-base font-bold text-gray-900">{formatUnit(item?.sizes.rentalSpace, 'm²')}</div>
                  <div className="text-xs text-gray-500">{t('Wohnfläche (WoFIV.)')}</div>
                </div>
              )} */}
                  {!!item?.sizes.amount && (
                    <div>
                      <div className="text-base font-bold text-gray-900">
                        {formatUnit(item?.sizes.amount?.value, t('projectTaxonomy.buildingPieces'))}
                      </div>
                      <div className="text-xs text-gray-500">{t('projectTaxonomy.buildingAmount')}</div>
                    </div>
                  )}
                  {!!item?.sizes.other && (
                    <div>
                      <div className="text-base font-bold text-gray-900">
                        {formatUnit(item?.sizes.other?.value, item?.sizes.other?.unit)}
                      </div>
                      <div className="text-xs text-gray-500">{t('projectTaxonomy.building.other')}</div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {item && item?.itemType === 'Parking' && (
            <>
              {!!item?.sizes.rentalSpace && (
                <div>
                  <div className="text-base font-bold text-gray-900">
                    {formatUnit(item?.sizes.rentalSpace.value, item?.sizes.rentalSpace.unit)}
                  </div>
                  <div className="text-xs text-gray-500">{t('projectTaxonomy.parkingLotArea')}</div>
                </div>
              )}
              {!!item?.sizes.amount && (
                <div>
                  <div className="text-base font-bold text-gray-900">{item?.sizes.amount?.value}</div>
                  <div className="text-xs text-gray-500">{t('projectTaxonomy.buildingAmount')}</div>
                </div>
              )}
            </>
          )}
          {plot && (
            <>
              {!!plot?.totalPlotSize && (
                <div>
                  <div className="text-lg font-bold text-gray-900">
                    {formatUnit(plot?.totalPlotSize.value, plot?.totalPlotSize.unit)}
                  </div>
                  <div className="text-sm text-gray-500">{t('projectTaxonomy.plotArea')}</div>
                </div>
              )}
              {!plot?.isProjectPlot && !!plot?.constructionSize && (
                <div>
                  <div className="text-lg font-bold text-gray-900">
                    {formatUnit(plot?.constructionSize.value, plot?.constructionSize.unit)}
                  </div>
                  <div className="text-sm text-gray-500">{t('projectTaxonomy.plotConstructionSize')}</div>
                </div>
              )}
              {!!plot?.isProjectPlot && (
                <>
                  {!!plot?.calculationModelGfz && (
                    <div>
                      <div className="text-lg font-bold text-gray-900">
                        {plot?.calculationModelGfz}
                      </div>
                      <div className="text-sm text-gray-500">{t('projectTaxonomy.plotGfz')}</div>
                    </div>
                  )}
                  {!!plot?.calculationModelGrz && (
                    <div>
                      <div className="text-lg font-bold text-gray-900">{formatUnit(plot?.calculationModelGrz, '')}</div>
                      <div className="text-sm text-gray-500">{t('projectTaxonomy.plotGrz')}</div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {item?.userDefinedFields &&
            item.userDefinedFields.length > 0 &&
            item.userDefinedFields.map((udf) => {
              if (udf) {
                return (
                  <div key={`taxonomy-udf-${udf.id}`}>
                    <div className="text-base font-bold text-gray-900">
                      {udf.text
                        ? udf.text
                        : udf.number
                          ? udf.number
                          : udf.listSelectedItem?.listItemId
                            ? udf.listSelectedItem.label
                            : '-'}
                    </div>
                    <div className="text-xs text-gray-500">{udf.definition.name}</div>
                  </div>
                );
              }
              return null;
            })}
        </DecoratedCard.Content>
      </DecoratedCard>
    </div>
  );
};
