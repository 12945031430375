import { LoadingIndicator, PercentageIcon, TaxPickerInput, TaxPickerInputProps } from '@client/shared/toolkit';
import { useApiGetProjectQuery } from '@client/shared/api';
import { useLoadedProjectId } from '@client/project/store';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const ProjectTaxPickerInput = (props: TaxPickerInputProps) => {
  const { label, icon, projectTaxRate, ...taxPickerInputProps } = props;
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const { data: loadedProject, isFetching } = useApiGetProjectQuery(
    { projectId: loadedProjectId ?? '' },
    { skip: loadedProjectId == null || !!projectTaxRate },
  );

  const vat = useMemo(() => {
    if (typeof projectTaxRate !== 'undefined') {
      return projectTaxRate;
    }
    return loadedProject?.project.payload.vat ?? undefined;
  }, [projectTaxRate, loadedProject?.project.payload.vat]);

  return (
    <>
      {isFetching && <LoadingIndicator text={t('common.loading') ?? ''} mode="overlay" />}
      <TaxPickerInput
        label={label ? label : t('common.vat')}
        icon={icon ? icon : <PercentageIcon className="h-6 w-6" />}
        projectTaxRate={vat}
        {...taxPickerInputProps}
      />
    </>
  );
};
