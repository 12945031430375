import { ProjectReadModel } from '@client/shared/api';
import { TimesheetDottedIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

interface RuntimeCardProps {
  project?: ProjectReadModel | undefined;
}

export const RuntimeCard = ({ project }: RuntimeCardProps) => {
  const { t } = useTranslation();
  const activeVariant = project?.calculationModels.find((x) => x.type === 'Version');
  let duration = 0;
  const startDate = activeVariant?.calculationModelStartDate
    ? new Date(activeVariant.calculationModelStartDate)
    : undefined;
  const endDate = activeVariant?.calculationModelEndDate ? new Date(activeVariant.calculationModelEndDate) : undefined;

  if (startDate && endDate) {
    duration = Math.round(Math.abs(endDate.getTime() - startDate.getTime()) / (30 * 24 * 60 * 60 * 1000));
  }

  return (
    <div className="rounded shadow p-4 w-full h-full flex flex-row bg-white ">
      <div className="w-full flex flex-row">
        <div className="flex justify-center items-center mr-4">
          <TimesheetDottedIcon className="h-8 w-8 text-sky-500" />
        </div>
        <div className="w-full">
          <div className="text-xxs font-bold text-slate-500">{t('project.runtime')}</div>
          <div className="mt flex space-x-2 leading-none">
            <div className="h-7 text-base font-extrabold">{duration}</div>
            <span className="text-xs font-normal flex-shrink min-h-0">{t('common.month').substring(0, 3)}</span>
          </div>
          <div className="text-xxs text-slate-500">
            {activeVariant?.deliveryPhasesCount ?? 0}{' '}
            {t('project.phases', { count: activeVariant?.deliveryPhasesCount ?? 0 })}
          </div>
        </div>
      </div>
      <div className="w-2/6 h-full text-right justify-end flex flex-row items-center">
        <div>
          <div className="py-[2px] text-xxs font-bold text-slate-500">{t('project.start')}</div>
          <div className="py-[2px] text-xs font-extrabold text-end">{startDate ? format(startDate, 'MMMM').substring(0, 3) : '-'}</div>
          <div className="py-[2px] text-xxs font-extrabold text-end">{startDate?.getFullYear()}</div>
        </div>
        <div className="h-1/2 mx-2 w-[2px] bg-slate-200" />
        <div>
          <div className="py-[2px] text-xxs font-bold text-slate-500 text-start">{t('project.end')}</div>
          <div className="py-[2px] text-xs font-extrabold text-start">{endDate ? format(endDate, 'MMMM').substring(0, 3) : '-'}</div>
          <div className="py-[2px] text-xxs font-extrabold text-start">{endDate?.getFullYear()}</div>
        </div>
      </div>
    </div>
  );
};
