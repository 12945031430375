import { EuriborExtendedReadModel } from '@client/shared/api';
import { BaseSelect, BooleanInput, ConfirmModal, LoadingIndicator, NumberInput } from '@client/shared/toolkit';
import { formatDate, safeMutation } from '@client/shared/utilities';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useApiPostCreateEuriborRateProjectionMutation,
  useApiPostUpdateEuriborRateProjectionMutation,
} from '@client/shared/api';

interface EditEuriborRateModalProps {
  element: EuriborExtendedReadModel | undefined;
  rates: EuriborExtendedReadModel[] | undefined;
  onClose: () => void;
  setIsLoading: (value: boolean) => void;
}

export const EditEuriborRateModal = ({ element, rates, onClose, setIsLoading }: EditEuriborRateModalProps) => {
  const [oneMonthEuribor, setOneMonthEuribor] = useState<number | null>(null);
  const [threeMonthsEuribor, setThreeMonthsEuribor] = useState<number | null>(null);
  const [sixMonthsEuribor, setSixMonthsEuribor] = useState<number | null>(null);
  const [twelveMonthsEuribor, setTwelveMonthsEuribor] = useState<number | null>(null);
  const [userConfirmed, setUserConfirmed] = useState<boolean | null>(null);
  const [saved, setSaved] = useState<boolean>(false);

  const [createElement, { isLoading: isCreating }] = useApiPostCreateEuriborRateProjectionMutation();
  const [updateElement, { isLoading: isUpdating }] = useApiPostUpdateEuriborRateProjectionMutation();
  const [selectedDate, setSelectedDate] = useState<string>('');

  const { t } = useTranslation();

  useEffect(() => {
    if (isCreating || isUpdating) setIsLoading(true);
    else setIsLoading(false);
    if (saved && !isCreating && !isUpdating) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreating, isUpdating]);

  useEffect(() => {
    const { oneMonthEuribor, threeMonthsEuribor, sixMonthsEuribor, twelveMonthsEuribor, userConfirmed } =
      element?.euriborReadModel || {};

    if (oneMonthEuribor) setOneMonthEuribor(oneMonthEuribor);
    if (threeMonthsEuribor) setThreeMonthsEuribor(threeMonthsEuribor);
    if (sixMonthsEuribor) setSixMonthsEuribor(sixMonthsEuribor);
    if (twelveMonthsEuribor) setTwelveMonthsEuribor(twelveMonthsEuribor);
    if (userConfirmed) setUserConfirmed(userConfirmed);
  }, [rates, element]);

  const onSafe = () => {
    if (element?.euriborReadModel?.id) handleUpdate();
    else handleCreate();
    setSaved(true);
  };

  const handleCreate = () => {
    try {
      safeMutation(
        createElement,
        {
          body: {
            date: element?.date ?? selectedDate,
            euriborPayload: {
              oneMonthEuribor: oneMonthEuribor ?? 0,
              threeMonthsEuribor: threeMonthsEuribor ?? 0,
              sixMonthsEuribor: sixMonthsEuribor ?? 0,
              twelveMonthsEuribor: twelveMonthsEuribor ?? 0,
              userConfirmed: userConfirmed ?? false,
            },
          },
        },
        isCreating,
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdate = () => {
    try {
      safeMutation(
        updateElement,
        {
          id: element?.euriborReadModel?.id ?? '',
          body: {
            oneMonthEuribor: oneMonthEuribor ?? 0,
            threeMonthsEuribor: threeMonthsEuribor ?? 0,
            sixMonthsEuribor: sixMonthsEuribor ?? 0,
            twelveMonthsEuribor: twelveMonthsEuribor ?? 0,
            userConfirmed: userConfirmed ?? false,
          },
        },
        isUpdating,
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ConfirmModal
      title={element ? formatDate(element?.date) : t('projectCalculate.euriborCreate')}
      onConfirm={onSafe}
      onClose={() => onClose()}
      confirmVariant="primary"
      confirmText={t('common.save')}
    >
      {isCreating || isUpdating ? (
        <LoadingIndicator />
      ) : (
        <div className="h-full flex flex-col items-center">
          {!element ? (
            <>
              <div className="w-full text-center text-sm text-slate-500">
                {t('projectCalculate.euriborDatesWithoutRates')}
              </div>

              <BaseSelect
                options={
                  rates
                    ?.filter((element) => !element.euriborReadModel)
                    .map((x) => ({
                      label: formatDate(x.date),
                      value: x.date,
                    })) ?? []
                }
                label={t('projectCalculate.euriborSelectDate')}
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                className="m-2 w-1/3"
              />
            </>
          ) : (
            <div className="w-full text-center text-slate-800">
              {t('projectCalculate.euriborEditProjection', { date: formatDate(element?.date) })}
            </div>
          )}
          <div className="w-full flex flex-row">
            <NumberInput
              label={t('projectCalculate.euriborPeriods.OneMonth')}
              value={oneMonthEuribor}
              onChange={(x) => setOneMonthEuribor(x)}
              className="m-2 w-full "
            />

            <NumberInput
              label={t('projectCalculate.euriborPeriods.ThreeMonths')}
              value={threeMonthsEuribor}
              onChange={(x) => setThreeMonthsEuribor(x)}
              className="m-2 w-full"
            />
          </div>
          <div className="w-full flex flex-row">
            <NumberInput
              label={t('projectCalculate.euriborPeriods.SixMonths')}
              value={sixMonthsEuribor}
              onChange={(x) => setSixMonthsEuribor(x)}
              className="m-2 w-full"
            />

            <NumberInput
              label={t('projectCalculate.euriborPeriods.TwelveMonths')}
              value={twelveMonthsEuribor}
              onChange={(x) => setTwelveMonthsEuribor(x)}
              className="m-2 w-full"
            />
          </div>
          <div className="w-full flex flex-row">
            <BooleanInput
              label={t('projectCalculate.euriborUserConfirmed')}
              value={userConfirmed}
              onChange={(x) => setUserConfirmed(x)}
              className="m-2 w-full"
              disabled={element?.euriborReadModel?.userConfirmed ?? false}
            />
          </div>
        </div>
      )}
    </ConfirmModal>
  );
};
