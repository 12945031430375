interface InputIconProps {
  src: string;
  className?: string;
}

export const InputIcon = ({ src, className }: InputIconProps) => (
  <div className={className}>
    <img src={src} className="w-full h-full aspect-square" aria-hidden={true} alt="" />
  </div>
);
