import { useTranslation } from 'react-i18next';
import { EuriborRates } from './EuriborRates';
import { SettingsHeader } from '../SettingsHeader';

export const Euribor = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <SettingsHeader>
        {t('app.settingsEuriborRates')}
      </SettingsHeader>
      <EuriborRates />
    </div>
  );
};
