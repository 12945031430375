import { PencilIcon } from '../icons';
import React, { ReactNode, MouseEvent } from 'react';
interface CardActionButtonProps {
  onClick: (e: MouseEvent) => void
  icon?: ReactNode | string
  className?: string
}
export const CardActionButton = (props: CardActionButtonProps) => {
  const {
    onClick,
    icon,
    className = 'absolute top-4 right-4'
  } = props
  return (
    <div className={className}>
      <button
        onClick={onClick}
        className="flex flex-col"
      >
        <div className="w-5 h-5">
          {icon ? icon : <PencilIcon />}
        </div>
      </button>
    </div>
  )
}
