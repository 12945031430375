import cn from 'classnames';
import React, { RefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ErrorIcon, EstimateIcon, FormField, FormRefHandle, NumberInput } from '@client/shared/toolkit';
import { ProjectTaxPickerInput } from '../../ProjectTaxPickerInput';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { useLoadedProjectId } from '@client/project/store';
import { useClickAway } from 'react-use';
import { InvoiceCreateFormValidationValues } from '../InvoiceCreateFormValidationValues';
import { formatNumber } from '@client/shared/utilities';

interface InvoiceDocumentReviewValuesProps {
  net?: number | null;
  claim?: number;
  vat?: number | null;
  formRef: RefObject<FormRefHandle<InvoiceCreateFormValidationValues>>;
  defaultFormValues: InvoiceCreateFormValidationValues;
}

export const InvoiceDocumentReviewValues = (props: InvoiceDocumentReviewValuesProps) => {
  const { claim, net, vat, formRef, defaultFormValues } = props;
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');
  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [allowChangeMode, setAllowChangeMode] = useState(true);

  useClickAway(ref, () => {
    if (buttonRef.current && buttonRef.current.dataset.headlessuiState === 'open' && allowChangeMode) {
      buttonRef.current.click();
    }
  });

  return (
    <Disclosure as="div" ref={ref}>
      {({ open }) => (
        <>
          <DisclosureButton
            as="div"
            className={cn(
              'grid grid-cols-[min-content_1fr] text-[15px] gap-1 hover:bg-gray-100 transition-colors duration-300 cursor-pointer',
              open ? 'mb-4' : '',
            )}
            ref={buttonRef}
          >
            <span className="text-[13px] font-bold">{t('projectControl.invoiceNetto')}</span>
            <span
              className={cn(
                'text-[13px] font-bold text-right whitespace-nowrap',
                claim !== defaultFormValues.claim ? 'text-secondary' : '',
              )}
            >
              {formatNumber(net, { minDigits: 2 }) ?? '-'}
            </span>
            <span className="text-[13px]">{t('projectControl.invoiceVat')}</span>
            <span
              className={cn(
                'text-[13px] text-right whitespace-nowrap',
                vat !== defaultFormValues.vat ? 'text-secondary' : '',
              )}
            >
              {typeof vat !== 'undefined' && vat !== null ? <span>{`${vat}%`}</span> : '-'}
            </span>
            <span className="text-[13px] w-max">{t('projectControl.invoiceVatAmount')}</span>
            <span
              className={cn(
                'text-[13px] text-right whitespace-nowrap',
                vat !== defaultFormValues.vat ? 'text-secondary' : '',
              )}
            >
              {typeof vat !== 'undefined' && vat !== null
                ? formatNumber(Number(((net ?? 0) * vat) / 100), { minDigits: 2 })
                : '-'}
            </span>
            <span className="text-[15px] font-bold whitespace-nowrap truncate">
              {t('projectControl.invoiceBrutto')}
            </span>
            <span
              className={cn(
                'text-[15px] font-bold text-right whitespace-nowrap',
                claim !== defaultFormValues.claim ? 'text-secondary' : '',
              )}
            >
              {claim ? (
                formatNumber(claim, { minDigits: 2 })
              ) : (
                <ErrorIcon className="w-5 text-red-700 inline-flex -mr-1.5" />
              )}
            </span>
          </DisclosureButton>
          <DisclosurePanel className="bg-gray-100 -mx-6 px-6 py-4 shadow-inner -mb-4">
            <FormField name="net">
              {(control) => (
                <NumberInput
                  label={t('projectContract.invoiceNet')}
                  icon={<EstimateIcon className="h-6 w-6" />}
                  disabled={!canWrite}
                  value={net}
                  onChange={(value) => {
                    control.onChange(value);
                    if (typeof value !== 'undefined' && value !== null && typeof vat !== 'undefined' && vat !== null) {
                      const gross = Number((value * (1 + vat / 100)).toFixed(2));
                      formRef.current?.setValue('claim', gross);
                    }
                  }}
                />
              )}
            </FormField>

            <FormField name="vat">
              {(control) => (
                <ProjectTaxPickerInput
                  className="mt-1"
                  disabled={!canWrite}
                  handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                  value={vat ?? 0}
                  onChange={(value) => {
                    control.onChange(value);
                    if (typeof value !== 'undefined' && value !== null) {
                      if (typeof claim !== 'undefined' && claim !== null && claim !== 0) {
                        const netValue = Number((claim / (1 + value / 100)).toFixed(2));
                        formRef.current?.setValue('net', netValue);
                      } else if(typeof net !== 'undefined' && net !== null && net !== 0) {
                        const gross = Number((net * (1 + value / 100)).toFixed(2));
                        formRef.current?.setValue('claim', gross);
                      }
                    }
                  }}
                />
              )}
            </FormField>

            <FormField name="claim">
              {(control) => (
                <NumberInput
                  className="mt-1"
                  label={t('projectControl.invoiceClaim')}
                  icon={<EstimateIcon className="h-6 w-6" />}
                  disabled={!canWrite}
                  value={claim}
                  onChange={(value) => {
                    control.onChange(value);
                    if (typeof value !== 'undefined' && value !== null && typeof vat !== 'undefined' && vat !== null) {
                      const netValue = Number((value / (1 + vat / 100)).toFixed(2));
                      formRef.current?.setValue('net', netValue);
                    }
                  }}
                />
              )}
            </FormField>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
};
