import { settings } from '@client/shared/store';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  ForgotPasswordView,
  LoginView,
  PasswordResetStartedView,
  PasswordResetSuccessfulView,
  ResetPasswordView,
  RegisterView,
  RegisterTrustedTenantView,
} from '../components';

export const LoginRoute = () => {
  const buildId = settings.buildId.slice(0, 7);

  return (
    <div className="flex-grow flex items-center justify-center bg-[url('/assets/bg-login-screen.jpg')] bg-cover relative">
      <div className="bg-black absolute left-0 right-0 top-0 bottom-0 opacity-25" />
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl z-50">
        <div className="flex w-full shadow sm:rounded-xl bg-gray-100 overflow-hidden">
          <div className="w-2/6 min-h-full px-2 bg-[#334155] text-white flex flex-col items-center bg-[url('/assets/login-ornament.svg')] bg-bottom bg-no-repeat bg-contain">
            <div className="flex flex-col flex-grow">
              <img src="/assets/logo-icon.svg" className="-mt-16 absolute" alt="Logo Icon" />
              <img src="/assets/logo-text.svg" className="mt-24" alt="Logo Text" />
            </div>
            <div className="text-sm pb-4">ID: {buildId}</div>
          </div>
          <div className="py-8 px-4 sm:px-10 w-4/6">
            <Routes>
              <Route path="login" element={<LoginView />} />
              <Route path="forgot-password" element={<ForgotPasswordView />} />
              <Route path="reset-password/:token" element={<ResetPasswordView />} />
              <Route path="password-reset-started" element={<PasswordResetStartedView />} />
              <Route path="password-reset-successful" element={<PasswordResetSuccessfulView />} />
              <Route path="register/:token" element={<RegisterView />} />
              <Route path="register-trusted-company/:token" element={<RegisterTrustedTenantView />} />

              <Route path="*" element={<Navigate replace to="login" />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};
