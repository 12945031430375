import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  HintBox,
  Modal,
  ModalOnCloseProps,
  TagWindowIcon,
  TextInput,
  SkyscrapersDottedIcon,
  LoadingIndicator
} from '@client/shared/toolkit';
import { ShortProjectGroupReadModel, ShortProjectReadModel, useApiPostCreateProjectCopyMutation } from '@client/shared/api';
import { useProjects } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';

interface ProjectCopyModalProps extends ModalOnCloseProps {
  selectedProject?: ShortProjectReadModel;
  selectedGroupProject?: ShortProjectGroupReadModel | null
}

export const ProjectCopyModal = ({ onClose, selectedProject }: ProjectCopyModalProps) => {
  const { t } = useTranslation();

  const { projects, groups } = useProjects();
  const [call, { isLoading }] = useApiPostCreateProjectCopyMutation();

  let allProjects = projects ?? [];
  if(groups)
    groups.map((x) => allProjects = allProjects.concat(x.projects))

  const retrievedProject = () => {
    if (selectedProject === null || !selectedProject) {
      return allProjects.length >= 1 ? allProjects.at(0) : null;
    } else {
      return allProjects.find((x) => x === selectedProject);
    }
  };

  const [project, setProject] = useState<ShortProjectReadModel | null | undefined>(retrievedProject);
  const [name, setName] = useState<string>('');
  const [projectId, setProjectId] = useState<string>('');
  const [onlyCurrentVersion, setOnlyCurrentVersion] = useState<boolean>(true);

  useEffect(() => {
    setName(project == null ? '' : t('app.copyCopyOf', { name: project?.name }));
    setProjectId(project == null ? '' : t('app.copyCopyOfProjectId', { projectId: project?.projectId }));
  }, [project, t]);

  const handleCopyProject = async () => {
    if (project && project.id) {
      try {
        await safeMutation(
          call,
          {
            projectId: project.id,
            body: {
              projectId,
              name,
              onlyCurrentVersion,
            },
          },
          isLoading
        );

        onClose(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleOnChange = (id: string, name: string, projectId: string) => {
    setProject(allProjects.find((x) => x.id === id));
    setName(t('app.copyCopyOf', { name }));
    setProjectId(t('app.copyCopyOfProjectId', { projectId }));
  };

  return (
    <>
      {isLoading && <LoadingIndicator text={t('project.projectLoadingIndicator')} mode="overlay" />}
      <Modal.Header title={t('app.copyTitle')} />
      <Modal.Content className="space-y-6">
        <div>
          {selectedProject == null &&
            allProjects.map((currentProject, index) => (
              <div
                key={index}
                className="flex w-full p-2 cursor-pointer hover:bg-blue-100 text-sm items-center border-b"
                onClick={() => handleOnChange(currentProject.id, currentProject.name, currentProject.projectId)}
              >
                <div className="w-1/12 flex flex-col items-start">
                  <div className="bg-sky-900 rounded-full h-8 w-8 flex items-center justify-center">
                    <SkyscrapersDottedIcon className="h-4 text-white" />
                  </div>
                </div>
                <div className="w-10/12 flex flex-col items-start ml-4">
                  <div className="flex flex-col items-start">
                    <div>{currentProject.name}</div>
                    <div>{currentProject.projectId}</div>
                    <div className="text-gray-500">{currentProject.city}</div>
                  </div>
                </div>
                <div className="w-1/12 flex flex-col items-start">
                  <input
                    id={`project-item-${index}`}
                    type="radio"
                    value={currentProject.id}
                    checked={project?.id === currentProject.id}
                    onChange={() => handleOnChange(currentProject.id, currentProject.name, currentProject.projectId)}
                  />
                </div>
              </div>
            ))}
        </div>
        {selectedProject != null && (
          <div className="flex w-full p-2 text-sm items-center border-b">
            <div className="w-1/12 flex flex-col items-start">
              <div className="bg-sky-900 rounded-full h-8 w-8 flex items-center justify-center">
              <SkyscrapersDottedIcon className="h-4 text-white" />
              </div>
            </div>
            <div className="w-5/12 flex flex-col items-start ml-4">
              <div className="flex flex-col items-start">
                <div>{project?.name}</div>
                <div>{project?.projectId}</div>
                <div className="text-gray-500">{project?.city}</div>
              </div>
            </div>
          </div>
        )}
        <div className="border-b">
          <TextInput
            label={t('app.copyCopyName')}
            value={name}
            onChange={setName}
            icon={<TagWindowIcon />}
          />
        </div>
        <div className="border-b">
          <TextInput
            label={t('app.copyCopyProjectId')}
            value={projectId}
            onChange={setProjectId}
            icon={<TagWindowIcon />}
          />
        </div>
        <div className="border-b">
          <label className="flex items-center bg-white h-14 p-3 cursor-pointer">
            <input
              type="checkbox"
              checked={onlyCurrentVersion}
              onChange={() => setOnlyCurrentVersion(!onlyCurrentVersion)}
              autoFocus={false}
            />
            <div className="ml-2 w-full">{t('app.copyCopyProjectOnlyCurrentVersion')}</div>
          </label>
        </div>
        <HintBox hintType="info">{t('app.copyHint')}</HintBox>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={handleCopyProject}>
          {t('app.copyCreateCopy')}
        </Button>
      </Modal.Controls>
    </>
  );
};
