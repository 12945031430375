import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProjectUnitSystem {
  id: string;
  unitSystem: string;
}

export interface UnitSystemState {
  unitSystem: ProjectUnitSystem[];
}

export const initialState: UnitSystemState = {
  unitSystem: [],
};

export const unitSystemSlice = createSlice({
  name: 'unitSystem',
  initialState,
  reducers: {
    setUnitSystem: (state, action: PayloadAction<ProjectUnitSystem>) => {
      state.unitSystem = [
        ...state.unitSystem.filter((unitSystem) => unitSystem.id !== action.payload.id),
        action.payload,
      ];
    },
    setUnitSystems: (state, action) => {
      state.unitSystem = action.payload
    }
  },
});

export const { setUnitSystem, setUnitSystems } = unitSystemSlice.actions;
