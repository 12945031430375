import { Button, Modal, TextInput, Form, FormField, ModalOnCloseProps, LoadingIndicator } from '@client/shared/toolkit';
import { EnvelopeOpenIcon } from '@heroicons/react/24/outline';
import { useApiPostCreateUserInvitationMutation } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { InviteUserFormValidationSchema, InviteUserFormValidationValues } from './InviteUserFormValidationSchema';
import { safeMutation } from '@client/shared/utilities';

export const InviteUserModal = ({ onClose }: ModalOnCloseProps) => {
  const { t } = useTranslation();

  const [inviteUser, {isLoading: isCreating}] = useApiPostCreateUserInvitationMutation();

  const handleSubmit = async (data: InviteUserFormValidationValues) => {
    try {
      await safeMutation(
        inviteUser,
        {
          body: {
            email: data.email,
          },
        },
        isCreating
      );

      onClose(true);
    } catch (error) {
      console.log(error);
    }
  };

  const defaultFormValues = { email: '' };

  return (
    <Form<InviteUserFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={InviteUserFormValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
    >
      {isCreating && (
        <LoadingIndicator text={t('common.loading')} mode="overlay" />
      )}
      <Modal.Header title={t('app.inviteTitle')} />
      <Modal.Content>
        <FormField name="email">
          {(control) => (
            <TextInput label={t('common.email')} icon={<EnvelopeOpenIcon className="w-6 h-6" />} {...control} />
          )}
        </FormField>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
          {t('common.cancel')}
        </Button>
        <Button variant="primary" formSubmit={true}>
          {t('app.inviteSendInvitation')}
        </Button>
      </Modal.Controls>
    </Form>
  );
};
