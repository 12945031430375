import { getLanguageAndLocale } from './i18n';

interface FormatPercentageOptions {
  maxDigits?: number;
  placeHolder?: string;
  locale?: string;
}

/**
 * Format a number as a percentage, with the given number of digits.
 * @param amount The number to format, or null to use the placeholder.
 * @param options formatting options.
 * @param options.maxDigits The maximum number of digits to display after the decimal point.
 * @param options.placeHolder The placeholder to use if the amount is null or undefined. Defaults to '-'.
 * @param options.locale The locale to use. Defaults to the user's browser locale.
 * @returns {string} The formatted percentage.
 */
export const formatPercentage = (
  amount: number | null | undefined,
  { maxDigits = 2, locale = getLanguageAndLocale().locale, placeHolder = '-' }: FormatPercentageOptions = {}
) => {
  if (amount == null) {
    return placeHolder ?? '-';
  }

  return new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: maxDigits,
    minimumFractionDigits: maxDigits,
  }).format(amount);
};
