import { CatalogTemplate } from './CatalogTemplate';
import { EditRowIcon, EyeIcon } from '@client/shared/toolkit';
import { ArrowUturnLeftIcon, RectangleGroupIcon } from '@heroicons/react/24/outline';
import { TemplateStatus } from './CatalogTemplateUtilities';
import { t } from 'i18next';

interface CatalogTemplateProps {
  assignedCatalogTemplates: TemplateStatus[],
  newCatalogTemplates: TemplateStatus[],
  deletedCatalogTemplates: TemplateStatus[],
  handleDeleteTemplate: (deletedTemplate: TemplateStatus) => void;
  handleUndoDeleteTemplate: (template: TemplateStatus) => void;
  handleChangeElement: (template: TemplateStatus) => void;
}

export const CatalogTemplateList = ({
  assignedCatalogTemplates,
  newCatalogTemplates,
  deletedCatalogTemplates,
  handleDeleteTemplate,
  handleUndoDeleteTemplate,
  handleChangeElement
}: CatalogTemplateProps) => {

  return (
    <div className='flex flex-col'>
      {assignedCatalogTemplates.length === 0 && newCatalogTemplates.length === 0 && deletedCatalogTemplates.length === 0 
        ? (
          <div className="my-4 text-center">{t('auth.noTemplatesAssigned')}</div>
        ) : (
          <>
            <div className='flex flex-row items-center py-2 pr-2'>
              <div className='w-10/12'/>
              <div className='flex justify-center w-1/12'>
                <EyeIcon className="w-6 h-6" />
              </div>
              <div className='flex justify-center w-1/12'>
                <EditRowIcon className="w-6 h-6" />
              </div>
            </div>
            {assignedCatalogTemplates?.map((catalogTemplate, index) => (
                <div key={index} className="mb-10">
                    <CatalogTemplate 
                      catalogTemplate={catalogTemplate}
                      handleDeleteTemplate={handleDeleteTemplate}
                      handleChangeElement={handleChangeElement}
                    />
                </div>
            ))}
            {newCatalogTemplates?.map((catalogTemplate, index) => (
                <div key={index} className="mb-10">
                    <CatalogTemplate 
                      catalogTemplate={catalogTemplate}
                      handleDeleteTemplate={handleDeleteTemplate}
                      handleChangeElement={handleChangeElement}
                    />
                </div>
            ))}
            {deletedCatalogTemplates?.map((catalogTemplate, index) => (
                <div key={index} className="mb-10">
                  <div className='flex flex-row bg-gray-200 pl-2 py-4 font-medium text-lg text-black'>
                      <div className='flex flex-row w-11/12'>
                        <RectangleGroupIcon className="w-6 mr-2 text-red-500" />
                        {catalogTemplate.template.catalogTemplate.name}
                      </div>
                      <div className="flex justify-center mr-2 w-1/12 text-gray-500 hover:text-black">
                        <ArrowUturnLeftIcon className="w-6" onClick={() => handleUndoDeleteTemplate(catalogTemplate)} />
                      </div>
                  </div>
                </div>
            ))}
          </>
        )
      }
    </div>
  );
};
