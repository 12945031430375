import { TimelineDurationUnit } from '@client/shared/api';
import { addDays, addWeeks, addYears } from 'date-fns';
import { formatDateOnly } from '@client/shared/utilities';
import { TimeInput, addMonthsToDate } from '@client/project/shared';

const msInDays = 24 * 60 * 60 * 1000;
const msInWeeks = msInDays * 7;
const msInMonths = 2629746000;
const msInYears = 31556952000;

export const calculateStartDate = (
  startElement?: TimeInput | null,
  endElement?: TimeInput | null,
  duration?: number | null,
  durationUnit?: TimelineDurationUnit | null | undefined
) => {
  if (startElement?.fixedDate || startElement?.variantDeliveryPhaseId) {
    return startElement.effectiveDate;
  }

  if (endElement?.effectiveDate && (duration || duration === 0)) {
    return SubtractDuration(endElement.effectiveDate, duration, durationUnit);
  }
};

export const calculateEndDate = (
  startElement?: TimeInput | null,
  endElement?: TimeInput | null,
  duration?: number | null,
  durationUnit?: TimelineDurationUnit | null | undefined
) => {
  if (endElement?.fixedDate || endElement?.variantDeliveryPhaseId) {
    return endElement?.effectiveDate;
  }

  if (startElement?.effectiveDate) {
    return AddDuration(startElement.effectiveDate, duration ?? 0, durationUnit);
  }
};

export const calculateDurationByUnit = (
  durationUnit: TimelineDurationUnit,
  startElement?: TimeInput | null,
  endElement?: TimeInput | null
) => {
  if (!startElement?.effectiveDate || !endElement?.effectiveDate) return undefined;

  return calculateDuration(durationUnit, startElement.effectiveDate, endElement.effectiveDate);
};

export const calculateDuration = (
  durationUnit: TimelineDurationUnit,
  startElement?: string | null,
  endElement?: string | null
) => {
  if (!startElement || !endElement) return undefined;

  const diff = new Date(endElement).getTime() - new Date(startElement).getTime();
  if (diff < 0) {
    return undefined;
  }
  const calculatedTimeSpan = Math.abs(diff);

  switch (durationUnit) {
    case 'Days':
      return Math.ceil(calculatedTimeSpan / msInDays);
    case 'Months':
      return Math.round(calculatedTimeSpan / msInMonths);
    case 'Weeks':
      return Math.round(calculatedTimeSpan / msInWeeks);
    case 'Years':
      return Math.round(calculatedTimeSpan / msInYears);
    default:
      return undefined;
  }
};

export const yearDifferenceWithCeil = (start: Date, end: Date) => {
  const calculatedTimeSpan = Math.abs(end.getTime() - start.getTime());

  return Math.ceil(calculatedTimeSpan / msInYears);
};

const SubtractDuration = (date: string, duration: number, durationUnit: TimelineDurationUnit | null | undefined) => {
  switch (durationUnit) {
    case 'Days':
      return formatDateOnly(addDays(new Date(date), -duration));
    case 'Months':
      return addMonthsToDate(date, -duration);
    case 'Weeks':
      return formatDateOnly(addWeeks(new Date(date), -duration));
    case 'Years':
      return formatDateOnly(addYears(new Date(date), -duration));
    default:
      return formatDateOnly(addDays(new Date(date), -duration));
  }
};

const AddDuration = (dateString: string, duration: number, durationUnit: TimelineDurationUnit | null | undefined) => {
  const date = new Date(dateString);

  switch (durationUnit) {
    case 'Days':
      return formatDateOnly(addDays(date, duration));
    case 'Months':
      return addMonthsToDate(dateString, duration);
    case 'Weeks':
      return formatDateOnly(addWeeks(date, duration));
    case 'Years':
      return formatDateOnly(addYears(date, duration));
    default:
      return formatDateOnly(addDays(date, duration));
  }
};
