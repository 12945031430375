import React, { useState, useEffect, useMemo } from 'react';
import {
  ContextMenuItem,
  ContextMenu,
  LoadingIndicator,
  Modal,
  CustomDragLayer,
  TextHighlighter,
  SkyscrapersDottedIcon,
  PencilIcon,
  TrashIcon,
  LevelToggle,
  AddIcon
} from '@client/shared/toolkit';
import { useApiGetTaxonomyQuery, State, useApiPostMoveTaxonomyMutation, TaxonomyReadModel } from '@client/shared/api';
import { BuildingRow } from './BuildingRow';
import { EmptyBuildingsTable } from './EmptyBuildingsTable';
import { BuildingItemDeleteModal } from './BuildingItemDeleteModal';
import { BuildingItemAddChildModal } from './BuildingItemAddChildModal';
import { useTranslation } from 'react-i18next';
import { formatPercentage, formatUnit, safeMutation, stopPropagation } from '@client/shared/utilities';
import classNames from 'classnames';
import { flattenTaxonomyTree, FlattenedTaxonomyItem, getTaxonomyIcon } from '@client/project/shared';
import { useLoadedProjectId, setExpandedTaxonomyIds, useExpandedTaxonomyIds } from '@client/project/store';
import { getBuildingLabel } from '../../utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';

interface BuildingsTableProps {
  loadedVariantId?: string;
  isReadOnly: boolean;
  canDelete: boolean;
  searchValue?: string;
  searchResults?: string[];
  openDashboard: string
  setIsLoading?: (loading: boolean) => void;
}

export const BuildingsTable = (props: BuildingsTableProps) => {
  const {
    loadedVariantId,
    isReadOnly,
    canDelete,
    searchValue,
    searchResults,
    openDashboard,
    setIsLoading
  } = props;

  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: buildingsData,
    isFetching,
    isSuccess,
  } = useApiGetTaxonomyQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: loadedVariantId == null },
  );

  const expandedTaxonomyIds = useExpandedTaxonomyIds();
  const expandedIds = useMemo(
    () => expandedTaxonomyIds.find((x) => x.project === (loadedProjectId ?? ''))?.ids ?? [],
    [expandedTaxonomyIds, loadedProjectId],
  );

  const [taxonomyTree, setTaxonomyTree] = useState<FlattenedTaxonomyItem<TaxonomyReadModel>[]>(
    flattenTaxonomyTree(buildingsData),
  );

  useEffect(() => {
    if(setIsLoading) {
      setIsLoading(isFetching);
    }
  }, [isFetching, setIsLoading]);

  const filteredTaxonomyTree = useMemo(() => {
    if (searchValue && searchResults) {
      return taxonomyTree.filter((t) => {
        const found = searchResults.includes(t.taxonomyItem.itemId);
        if (!found && t.taxonomyItem.children?.length) {
          const foundChild = t.taxonomyItem.children.filter((child) => searchResults.includes(child.itemId));
          return foundChild.length > 0;
        }
        return found;
      });
    }
    return taxonomyTree;
  }, [taxonomyTree, searchValue, searchResults]);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [hoveredItem, setHoveredItem] = useState<TaxonomyReadModel | null>(null);
  const [draggedItem, setDraggedItem] = useState<TaxonomyReadModel | null>(null);
  const [dndEnabled, setDndEnabled] = useState(true);
  const [mousePosition, setMousePosition] = useState<number | null>(null);

  const hasBuildings = buildingsData != null && isSuccess === true;

  const [moveElement, { isLoading: isMoving }] = useApiPostMoveTaxonomyMutation();

  const handleMove = async (item: TaxonomyReadModel, selectedParentId: string, index: number) => {
    try {
      const isChildless =
        taxonomyTree.find((i) => i.taxonomyItem.itemId === selectedParentId)?.taxonomyItem.children.length === 0;

      await safeMutation(
        moveElement,
        {
          projectId: loadedProjectId ?? '',
          calculationModelId: item?.calculationModelId ?? '',
          taxonomyItemId: item?.itemId,
          newParentId: selectedParentId,
          orderWithinParent: index,
        },
        isMoving,
      );

      if (isChildless) {
        dispatch(
          setExpandedTaxonomyIds([
            ...expandedTaxonomyIds.filter((x) => x.project !== (loadedProjectId ?? '')),
            {
              project: loadedProjectId ?? '',
              ids: [...expandedIds, selectedParentId],
            },
          ]),
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onAddChild = () => {
    setIsOpenAddModal(true);
  };

  const onUpdate = () => {
    navigate(ROUTES_CONFIG.PROJECT_TAXONOMY_VIEW.path.replace(':id', loadedProjectId ?? '').replace(':itemId', buildingsData?.itemId ?? ''))
    setDndEnabled(false);
  };

  const onDelete = () => {
    setDeleteModalOpen(true);
  };

  const resetItems = () => {
    setHoveredItem(null);
    setDraggedItem(null);
    setDndEnabled(false);
    setTimeout(() => setDndEnabled(true), 0);
  };

  const getAllParentIds = (item: TaxonomyReadModel, ids: string[] = []) => {
    const parent = taxonomyTree.find((i) => i.taxonomyItem.children.some((x) => x.itemId === item.itemId));
    if (parent != null) {
      ids.push(parent.taxonomyItem.itemId);
      getAllParentIds(parent.taxonomyItem, ids);
    }
    return ids;
  };

  const moveItem = (dragIndex: number, hoverIndex: number, dropped: boolean, validMove: boolean) => {
    const dragItem = taxonomyTree[dragIndex].taxonomyItem;
    const hoverItem = taxonomyTree[hoverIndex].taxonomyItem;

    if (dragItem.itemId !== hoverItem.itemId) {
      setHoveredItem(hoverItem);
      setDraggedItem(dragItem);
    }

    if (dropped && validMove) {
      const sameParent = dragItem.parent?.itemId === hoverItem.parent?.itemId;
      const hasChildren = hoverItem.children.length > 0;
      const isLower = dragItem.orderWithinParent < hoverItem.orderWithinParent;
      const isExpanded = expandedIds.includes(hoverItem.itemId);

      const hoveredParent = taxonomyTree.find((i) => i.taxonomyItem.children.some((x) => x.itemId === hoverItem.itemId))
        ?.taxonomyItem;

      const parentsParent = taxonomyTree.find((i) =>
        i.taxonomyItem.children.some((x) => x.itemId === hoveredParent?.itemId),
      )?.taxonomyItem;

      const highestOrder =
        Math.max(...(hoveredParent?.children.map((i) => i.orderWithinParent) ?? [0])) === hoverItem.orderWithinParent;

      const moveToParentsParent = mousePosition === 1 && highestOrder && !isExpanded && parentsParent;

      if (mousePosition === 3 || mousePosition === 4 || (hasChildren && isExpanded)) {
        handleMove(dragItem, hoverItem.itemId, 1);
      } else if ((mousePosition === 1 || mousePosition === 2) && hoveredParent) {
        const orderWithinParent = moveToParentsParent
          ? hoveredParent.orderWithinParent + 1
          : sameParent && isLower
            ? hoverItem.orderWithinParent
            : hoverItem.orderWithinParent + 1;
        handleMove(dragItem, moveToParentsParent ? parentsParent.itemId : hoveredParent.itemId, orderWithinParent);
      }

      resetItems();
    } else if (dropped && !validMove) {
      resetItems();
    }
  };

  useEffect(() => {
    setTaxonomyTree(flattenTaxonomyTree(buildingsData));
  }, [buildingsData]);

  useEffect(() => {
    if (
      buildingsData != null &&
      expandedIds.length > 0 &&
      !expandedIds.every((id) => taxonomyTree.map((item) => item.taxonomyItem.itemId).includes(id))
    ) {
      dispatch(
        setExpandedTaxonomyIds([
          ...expandedTaxonomyIds.filter((x) => x.project !== (loadedProjectId ?? '')),
          {
            project: loadedProjectId ?? '',
            ids: expandedIds.filter((id) => taxonomyTree.some((item) => item.taxonomyItem.itemId === id)),
          },
        ]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingsData, taxonomyTree]);

  useEffect(() => {
    if (buildingsData != null && expandedIds.length === 0) {
      dispatch(
        setExpandedTaxonomyIds([
          ...expandedTaxonomyIds.filter((x) => x.project !== (loadedProjectId ?? '')),
          {
            project: loadedProjectId ?? '',
            ids:
              flattenTaxonomyTree(buildingsData)
                .filter((item) => item.taxonomyItem.children.length > 0)
                .map((item) => item.taxonomyItem.itemId) ?? [],
          },
        ]),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingsData, dispatch, expandedIds.length]);

  const handleOnCollapse = (level: number) => {
    dispatch(
      setExpandedTaxonomyIds([
        ...expandedTaxonomyIds.filter((x) => x.project !== (loadedProjectId ?? '')),
        {
          project: loadedProjectId ?? '',
          ids:
            level === 1
              ? taxonomyTree.filter((item) => item.depth === 0).map((item) => item.taxonomyItem.itemId)
              : taxonomyTree.map((item) => item.taxonomyItem.itemId),
        },
      ]),
    );
  };

  const contextItems: ContextMenuItem[] = [
    {
      label: t('projectTaxonomy.buildingAddChildAction'),
      subtitle: t('projectTaxonomy.buildingAddChildActionDescription'),
      icon: <AddIcon />,
      isDisabled: isReadOnly,
      onClick: () => onAddChild(),
    },
    {
      label: t('common.update'),
      subtitle: t('projectTaxonomy.buildingUpdateActionDescription'),
      icon: <PencilIcon />,
      onClick: () => onUpdate(),
    },
    {
      label: t('common.delete'),
      subtitle: t('projectTaxonomy.buildingDeleteActionDescription'),
      icon: <TrashIcon />,
      isDisabled: !canDelete,
      onClick: () => onDelete(),
    },
  ];

  const rentalSpaceEfficiency =
    buildingsData?.rentalSpaceEfficiency != null ? (buildingsData.rentalSpaceEfficiency?.value ?? 0) / 100 : 0;
  const netFloorEfficiency =
    buildingsData?.netFloorEfficiency != null ? (buildingsData.netFloorEfficiency?.value ?? 0) / 100 : 0;
  const grossFloorEfficiency =
    buildingsData?.grossFloorEfficiency != null ? (buildingsData.grossFloorEfficiency?.value ?? 0) / 100 : 0;

  const icon = useMemo(() => {
    const IconComponent = getTaxonomyIcon(draggedItem?.itemType);
    if (IconComponent) {
      return <IconComponent className="w-8 h-8" />;
    }
    return <SkyscrapersDottedIcon className="w-8 h-8" />;
  }, [draggedItem?.itemType]);

  const parentIcon = useMemo(() => {
    if (buildingsData?.itemType) {
      const IconComponent = getTaxonomyIcon(buildingsData?.itemType);
      return <IconComponent className="w-8 h-8 text-white" />;
    }
    return null;
  }, [buildingsData?.itemType]);

  return (
    <>
      {(isFetching === true || isMoving === true) && !setIsLoading && <LoadingIndicator text={t('projectTaxonomy.loadBuilding')} />}

      {!hasBuildings && isFetching === false && isMoving === false && (
        <EmptyBuildingsTable isReadOnly={isReadOnly} canDelete={canDelete} />
      )}

      {hasBuildings && isMoving === false && (
        <>
          <div className="bg-sky-900 shadow-lg rounded">
            <div
              className="text-gray-100 relative flex cursor-pointer h-16 items-center justify-between ml-2 border-l-8 border-transparent"
              onClick={onUpdate}
            >
              <div className="pl-1 flex w-1/3 flex-none pr-2">
                <div className="pr-4 flex-none flex">{parentIcon}</div>
                <div className="flex flex-col flex-grow truncate">
                  <div className="font-bold text-lg leading-none truncate">
                    {searchValue ? (
                      <TextHighlighter
                        text={buildingsData.itemName}
                        highlighted={searchValue
                          .split(',')
                          .filter((x) => x !== '')
                          .map((x) => x.trim())}
                      />
                    ) : (
                      buildingsData.itemName
                    )}
                    {buildingsData.itemCustomerName && <span> &bull; {buildingsData.itemCustomerName}</span>}
                  </div>
                  <div className="text-[15px] leading-tight">
                    {t(`projectTaxonomy.taxonomyType.${buildingsData.itemType}`)}
                  </div>
                </div>
                <div className="h-full flex items-center" onClick={stopPropagation}>
                  <LevelToggle handleOnCollapse={handleOnCollapse} showLevels={[1, 3]} />
                </div>
              </div>
              <div className="flex-none w-2/3 place-content-end flex gap-4 h-full pl-2">
                <div className="flex-none flex flex-col text-right justify-center w-[75px] overflow-hidden">
                  <span className="truncate">{t('projectTaxonomy.buildingAmountHeaderLabel')}</span>
                  <span className="font-light text-[11px] truncate">{t('projectTaxonomy.buildingAmountPieces')}</span>
                </div>
                <div className="flex-1 grid grid-cols-3 gap-6 max-w-[600px]">
                  <HeaderColumn
                    label={t('projectTaxonomy.buildingMfg')}
                    efficiency={rentalSpaceEfficiency}
                    state={buildingsData.sizes?.rentalSpaceState}
                    value={buildingsData.sizes?.rentalSpace?.value}
                    effectiveValue={buildingsData.sizes?.effectiveRentalSpaceValue?.value}
                    calculatedValue={buildingsData.sizes?.calculatedRentalSpace?.value}
                    remainderValue={buildingsData.sizes?.rentalSpaceRestValue?.value}
                    unit={
                      buildingsData.sizes?.rentalSpace?.unit ??
                      buildingsData.sizes?.effectiveRentalSpaceValue?.unit ??
                      buildingsData.sizes?.calculatedRentalSpace?.unit
                    }
                  />

                  <HeaderColumn
                    label={t('projectTaxonomy.buildingNgf')}
                    state={buildingsData.sizes?.netFloorState}
                    value={buildingsData.sizes?.ngf?.value}
                    calculatedValue={buildingsData.sizes?.calculatedNetFloor?.value}
                    effectiveValue={buildingsData.sizes?.effectiveNetFloorValue?.value}
                    remainderValue={buildingsData.sizes?.netFloorRestValue?.value}
                    efficiency={netFloorEfficiency}
                    unit={
                      buildingsData.sizes?.ngf?.unit ??
                      buildingsData.sizes?.effectiveNetFloorValue?.unit ??
                      buildingsData.sizes?.calculatedNetFloor?.unit
                    }
                  />

                  <HeaderColumn
                    label={t('projectTaxonomy.buildingBgf')}
                    state={buildingsData.sizes?.grossFloorState}
                    value={buildingsData.sizes?.bgf?.value}
                    calculatedValue={buildingsData.sizes?.calculatedGrossFloor?.value}
                    effectiveValue={buildingsData.sizes?.effectiveGrossFloorValue?.value}
                    remainderValue={buildingsData.sizes?.grossFloorRestValue?.value}
                    efficiency={grossFloorEfficiency}
                    unit={
                      buildingsData.sizes?.bgf?.unit ??
                      buildingsData.sizes?.effectiveGrossFloorValue?.unit ??
                      buildingsData.sizes?.calculatedGrossFloor?.unit
                    }
                  />
                </div>
                <div className="flex w-[104px] flex-none">
                  <div className="flex justify-center items-center w-8 ml-6" />
                  <div className="px-3 flex items-center text-white justify-center" onClick={stopPropagation}>
                    <ContextMenu items={contextItems} className="w-6 h-6" />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col ml-2 text-sky-900/60 font-semibold">
              {filteredTaxonomyTree.map(
                (item, i) =>
                  getAllParentIds(item.taxonomyItem).every((id) => expandedIds.includes(id)) && (
                    <BuildingRow
                      item={item.taxonomyItem}
                      depth={item.depth}
                      isReadOnly={isReadOnly}
                      canDelete={canDelete}
                      key={item.taxonomyItem.itemId}
                      index={i}
                      moveItem={moveItem}
                      hoveredItem={hoveredItem}
                      draggedItem={draggedItem}
                      dndEnabled={dndEnabled && !searchValue}
                      setDndEnabled={setDndEnabled}
                      droppedOutside={resetItems}
                      mousePosition={mousePosition}
                      setMousePosition={setMousePosition}
                      taxonomyTree={taxonomyTree}
                      searchValue={searchValue}
                      isTaxonomyDashboardOpen={openDashboard === item.taxonomyItem.itemId}
                    />
                  ),
              )}
              {draggedItem && (
                <CustomDragLayer>
                  <div className="flex items-center h-20 bg-slate-50 border-slate-300 border shadow-md p-5">
                    <div className="pr-4">{icon}</div>
                    <div className="text-slate-500 flex flex-col flex-grow">
                      <div className="font-bold text-lg leading-none">{draggedItem?.itemName}</div>
                      <div className="text-[15px] leading-tight font-normal">{t(getBuildingLabel(draggedItem))}</div>
                    </div>
                  </div>
                </CustomDragLayer>
              )}
            </div>
          </div>

          <Modal isOpen={isOpenAddModal} onClose={() => setIsOpenAddModal(false)}>
            <BuildingItemAddChildModal onClose={() => setIsOpenAddModal(false)} parent={buildingsData} />
          </Modal>

          <Modal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
            <BuildingItemDeleteModal onClose={() => setDeleteModalOpen(false)} item={buildingsData} />
          </Modal>
        </>
      )}
    </>
  );
};

interface HeaderColumnProps {
  className?: string;
  state?: State;
  efficiency: number | null;
  label: string;
  value?: number | null;
  calculatedValue?: number | null;
  effectiveValue?: number | null;
  remainderValue?: number | null;
  unit?: string;
}

const HeaderColumn = ({
  className,
  label,
  efficiency,
  state,
  value,
  effectiveValue,
  calculatedValue,
  remainderValue,
  unit = 'm²',
}: HeaderColumnProps) => {
  const { t } = useTranslation();

  const columnKpiNumber = (state?: State) => {
    switch (state) {
      case 'JustValue':
        return { value: formatUnit(value, unit) };
      case 'JustCalculated':
        return { value: formatUnit(calculatedValue, unit), className: 'italic' };
      case 'Equality':
        return { value: formatUnit(effectiveValue, unit), overflow: '+/- 0' };
      case 'Remainder':
        return {
          value: formatUnit(effectiveValue, unit),
          overflow: `+ ${formatUnit(remainderValue, unit)}`,
          overflowClassName: 'text-green-300',
        };
      case 'Overflow':
        return {
          value: formatUnit(effectiveValue, unit),
          overflow: `- ${formatUnit(remainderValue, unit, { signDisplay: 'never' })}`,
          overflowClassName: 'text-red-300',
          className: 'text-red-300',
        };
      default:
        return null;
    }
  };

  const values = columnKpiNumber(state);

  return (
    <div className={classNames('flex flex-col text-right justify-center items-end', className)}>
      <div className={values?.className}>{values?.value}</div>
      <div className="font-light flex flex-wrap flex-row space-x-1 justify-end text-[11px]">
        <div className={values?.overflowClassName}>{values?.overflow}</div>
        {values?.overflow && <div>&bull;</div>}
        <div>{label}</div>
        {efficiency != null && (
          <div className="flex space-x-1">
            <div>&bull;</div>
            <div className="font-medium">
              {t('projectTaxonomy.buildingEfficiencyOf', {
                percentage: formatPercentage(efficiency, { maxDigits: 0 }),
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
