import classNames from 'classnames';
import { CalculateContext, TimeLineView } from ".";
import React, { PropsWithChildren, ReactNode, useContext } from "react";
import cn from "classnames";

export interface CalculateElementRowInformationColumnProps extends PropsWithChildren {
  className?: string;
  level: number
  view: TimeLineView
  handleOnClick?: (evt: React.MouseEvent<HTMLDivElement>) => void
  rightColumn?: ReactNode
  leftColumn?: ReactNode
  contentType?: 'group' | 'row'
  codeWidth?: number
  menu?: ReactNode
  isCollapsed?: boolean
}

/* Information Column for group elements */
export const CalculateElementRowInformationColumn = (props: CalculateElementRowInformationColumnProps) => {
  const {
    level,
    rightColumn,
    leftColumn,
    view,
    handleOnClick,
    contentType = 'group',
    codeWidth = 0,
    menu
  } = props
  const { isListCollapsed } = useContext(CalculateContext)

  return (
    <div
      className={cn('flex w-full', {
        'cursor-pointer': handleOnClick
      })}
      onClick={handleOnClick}
    >
      <div className={view === TimeLineView.TIMELINE && !isListCollapsed ? 'w-2/3 md:w-1/2' : 'w-full flex-1'}>
        <div
          className={classNames(
            'w-full pr-4 flex h-[38px] text-xs text-slate-600',
            {
              'pl-8': (level < 2 && contentType === 'group') || (level <= 2 && contentType === 'row'),
              'pl-[4.375rem]': ((level >= 2 && contentType === 'group') || (level > 2 && contentType === 'row')) && codeWidth < 8,
              'pl-24': ((level >= 2 && contentType === 'group') || (level > 2 && contentType === 'row')) && codeWidth >= 8
            }
          )}

        >
          {leftColumn}
        </div>
      </div>
      {view === TimeLineView.TIMELINE && !isListCollapsed && (
        <div className="w-1/2 pr-6 lg:pr-0">
          <div
            className={classNames(
              'w-full flex h-[38px] pr-4 truncate justify-end text-xs text-slate-600'
            )}
          >
            {rightColumn}
          </div>
        </div>
      )}
      {menu}
    </div>
  )
}
