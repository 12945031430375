import { useTranslation } from 'react-i18next';
import { ProtectedNavLink } from '.';
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import cn from 'classnames';
import { RouteConfig } from '../routes';

interface SideNavigationItemProps extends PropsWithChildren {
  label: string;
  icon: ReactElement;
  path: string;
  passDataCy?: string;
  disabled?: boolean;
  onNavItemClick?: () => void
  routeConfig: RouteConfig
  additionalContent?: ReactNode | string;
  projectId?: string;
}

export const SideNavigationItem = ({ label, passDataCy, icon, path, disabled = false, routeConfig, onNavItemClick, additionalContent, projectId }: SideNavigationItemProps) => {
  const { t } = useTranslation();

  return (
    <ProtectedNavLink
      path={path}
      disabled={disabled}
      routeConfig={routeConfig}
      passDataCy={passDataCy}
      className="relative px-2 lg:px-0 h-16 lg:w-16 flex flex-col justify-center items-center text-xxs font-medium lg:border-r-4 lg:border-l-4 lg:border-l-transparent hover:bg-secondary/10 transition-colors duration-200 border-b-4 lg:border-b truncate"
      activeClassName="border-b-secondary lg:border-b-gray-200 lg:border-r-secondary bg-secondary/10"
      notActiveClassName="border-b-transparent lg:border-b-gray-200 lg:border-r-transparent"
      disabledClassName="h-16 lg:w-16 px-2 flex flex-col justify-center items-center text-xxs font-medium lg:border-r-4 lg:border-l-4 lg:border-l-transparent lg:border-r-transparent cursor-not-allowed text-gray-300 border-b-4 border-b-transparent lg:border-b-gray-200 lg:border-b truncate"
      onClick={onNavItemClick}
      projectId={projectId}
    >
      {icon ? React.cloneElement(icon as ReactElement, { className: cn('w-8 mb-1', icon.props.className) }) : ''}
      <div className="truncate w-full text-center whitespace-normal">{t(label)}</div>
      {additionalContent}
    </ProtectedNavLink>
  )
};
