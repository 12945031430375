import { RequirementState } from '@client/shared/api';
import { BadgeProps } from '@client/shared/toolkit';
import { i18n } from '@client/shared/utilities';

export const getRequirementStatusBadge = (state: RequirementState): BadgeProps | undefined => {
  switch (state) {
    case 'Submitted':
      return  {
        variant: 'success',
        text: i18n.t('portfolio.property.requirementSate.submitted'),
      };
    case 'Rejected':
      return {
        variant: 'danger',
        text: i18n.t('portfolio.property.requirementSate.rejected'),
      };
    case 'Released':
      return  {
        variant: 'success',
        text: i18n.t('portfolio.property.requirementSate.released'),
      };
    default:
      return undefined;
  }
}
