import {
  useApiDeleteCommitmentMutation,
  useApiPostUpdateCommitmentMutation,
  CommitmentReadModel,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef } from 'react';
import { safeMutation } from '@client/shared/utilities';
import {
  Button,
  Form,
  TextInput,
  FormField,
  SlideOver,
  SlideOverOnCloseProps,
  ContractNumberIcon,
  PriceTagIcon,
  FormRefHandle,
  LoadingIndicator,
} from '@client/shared/toolkit';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { CommitmentFormValidationSchema, CommitmentFormValidationValues } from '.';
import classNames from 'classnames';
import { useValidateProjectPermission } from '@client/shared/permissions';

interface CommitmentEditSlideOverProps extends SlideOverOnCloseProps {
  commitment: CommitmentReadModel;
}

export const CommitmentEditSlideOver = ({ onClose, commitment }: CommitmentEditSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<FormRefHandle<CommitmentFormValidationValues>>();
  const [postUpdateCommitment, { isLoading: isUpdating }] = useApiPostUpdateCommitmentMutation();
  const [postDeleteCommitment, { isLoading: isDeleting }] = useApiDeleteCommitmentMutation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const canWrite = useValidateProjectPermission(['CONTRACT_WRITE'], loadedProjectId ?? '');

  const handleSubmit = async (data: CommitmentFormValidationValues) => {
    if (loadedVariantId && loadedProjectId) {
      try {
        await safeMutation(
          postUpdateCommitment,
          {
            commitmentId: commitment.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: {
              code: data.code,
              name: data.name,
              description: data.description,
              addedContracts: [],
              deletedContracts: [],
              addedBudgetAssignments: [],
              updatedBudgetAssignments: [],
              deletedBudgetAssignments: [],
            },
          },
          isUpdating,
        );
        onClose(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleDelete = async () => {
    if (commitment?.id && loadedProjectId && loadedVariantId) {
      try {
        await safeMutation(
          postDeleteCommitment,
          {
            commitmentId: commitment.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
          },
          isDeleting,
        );
        onClose(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const defaultFormValues = useMemo(() => {
    return {
      id: commitment?.id ?? '',
      name: commitment?.name ?? '',
      code: commitment?.code ?? '',
      description: commitment?.description ?? '',
    };
  }, [commitment]);

  useEffect(() => {
    formRef.current?.resetForm(defaultFormValues);
  }, [defaultFormValues]);

  return (
    <>
      <SlideOver.Header title={commitment.name} subTitle={t('projectContract.editCommitment')} onClose={onClose} backgroundClassName='bg-gray-800' />
      <SlideOver.Content
        className="p-8"
        onKeyEnter={() => {
          submitRef.current?.click();
        }}
      >
        {(isUpdating) && <LoadingIndicator text={t('common.loading')} mode="overlay" />}
        <div className="mb-4">
          <Form<CommitmentFormValidationValues>
            onSubmit={handleSubmit}
            validationSchema={CommitmentFormValidationSchema}
            defaultValues={defaultFormValues}
            className="w-full flex flex-col flex-grow min-h-0 divide-y-2 gap-9"
            ref={formRef}
          >
            <div className="bg-white">
              <div className="divide-gray-100 divide-y">
                <FormField name="code">
                  {(control) => (
                    <TextInput
                      label={t('common.code')}
                      icon={<ContractNumberIcon className="h-6 w-6" />}
                      disabled={!canWrite}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="name">
                  {(control) => (
                    <TextInput
                      label={t('common.name')}
                      icon={<PriceTagIcon className="h-6 w-6" />}
                      disabled={!canWrite}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="description">
                  {(control) => (
                    <TextInput
                      label={t('common.description')}
                      inputType="textarea"
                      className="font-normal"
                      disabled={!canWrite}
                      {...control}
                    />
                  )}
                </FormField>
              </div>
            </div>
          </Form>
        </div>
      </SlideOver.Content>

      <SlideOver.Controls
        className={classNames('flex justify-end', {
          'justify-between': canWrite,
        })}
      >
        {canWrite && (
          <Button onClick={handleDelete} className="mr-2" variant="danger">
            {t('common.delete')}
          </Button>
        )}
        <div>
          <Button onClick={() => onClose(false)} className="mr-2" variant="secondary">
            {t('common.cancel')}
          </Button>
          {canWrite && (
            <Button variant="primary" innerRef={submitRef} onClick={() => formRef.current?.submitForm()}>
              {t('common.save')}
            </Button>
          )}
        </div>
      </SlideOver.Controls>
    </>
  );
};
