/**
 * Get the initials of a name.
 * @param name The name.
 * @returns {string} The initials of the name.
 */
export const getInitials = (name?: string) => {
  if (!name) {
    return '';
  }

  const nameSplit = name.trim().split(' ');

  if (nameSplit.length === 1) {
    return nameSplit[0].charAt(0);
  }

  if (nameSplit.length > 1) {
    return nameSplit[0].charAt(0) + nameSplit[nameSplit.length - 1].charAt(0);
  }

  return '';
};
