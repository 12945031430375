import { Button } from '../Button';
import { PencilIcon } from '../../icons'
import React from 'react';
import cn from 'classnames';

interface EditButtonProps {
  onClick?: () => void
  className?: string
}
export const EditButton = (props: EditButtonProps) => {
  return (
    <Button
      variant="primary"
      className={cn('aspect-square shadow-lg h-12 w-12 items-center justify-center flex', props.className)}
      onClick={props.onClick}
      hasPadding={false}
    >
      <div className="w-5 h-5">
        <PencilIcon className="text-white" />
      </div>
    </Button>
  )
}
