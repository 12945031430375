import * as yup from 'yup';
import { InferType } from 'yup';

export const DealCostItemFormValidationSchema = yup.object({
  partCommon: yup.number().typeError('validation.number'),
  partRent: yup.number().typeError('validation.number'),
  partParkingAmount: yup.number().typeError('validation.number'),
});

export type DealCostItemFormValidationValues = InferType<typeof DealCostItemFormValidationSchema>;
