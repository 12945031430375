import React from 'react';
import {ArrowTrendingUpIcon} from "@heroicons/react/24/outline";
import {useTranslation} from "react-i18next";
import { FormattedCurrency } from '@client/project/shared';
import { SlideOverTitle } from '@client/shared/toolkit';

type ExternalForecastProps = {
  forecastValue: number;
}

const ExternalForecast = (props: ExternalForecastProps) => {
  const {t} = useTranslation();

  return (
    <div className="my-2">
      <SlideOverTitle title={t('projectCalculate.headerTitleExternalForecast')} className="px-8" />
      <div className="mx-8 mb-8 bg-white flex p-2">
        <div className="h-5 w-5 flex items-center justify-center">
          <ArrowTrendingUpIcon/>
        </div>
        <div className="ml-2 select-none">
          <span><FormattedCurrency amount={props.forecastValue} options={{ maxDigits: 2 }} /></span>
        </div>
      </div>
    </div>
  )
};

export type {ExternalForecastProps}
export default ExternalForecast;
