import {
  InvoiceTitleReadModel,
  useApiDeleteInvoiceTitleMutation
} from '@client/shared/api';
import { HintBox, ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';

interface InvoiceTitleDeleteModalProps extends ModalOnCloseProps {
  title: InvoiceTitleReadModel;
}

export const InvoiceTitleDeleteModal = ({ title, onClose }: InvoiceTitleDeleteModalProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const [deleteInvoiceTitle, { isLoading }] = useApiDeleteInvoiceTitleMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    try {
      await safeMutation(
        deleteInvoiceTitle,
        {
          invoiceId: title.invoiceId,
          invoiceTitleId: title.id,
          projectId: loadedProjectId ?? '',
          calculationModelId: loadedVariantId ?? ''
        },
        isLoading
      );
      onClose(true);
    } catch (e) {
      console.error(e)
    }
  };

  return (
    <ConfirmModal
      title={t('projectControl.deleteInvoiceTitle')}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      {isLoading && <LoadingIndicator text={t('common.deleteLoading') ?? ''} mode="overlay" />}
      <HintBox hintType="warning">{t('projectControl.deleteInvoiceTitleHint')}</HintBox>
    </ConfirmModal>
  );
};
