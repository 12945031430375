import { FactorValueModel, FormulaElementType, FormulaStrategyValueResponseModel } from '@client/shared/api';
import { NumberInput } from '@client/shared/toolkit';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormulaEditorModal } from './FormulaEditorModal';
import { produce } from 'immer';
import { FormulaBuilder, FormulaBuilderConstructorArgs } from '../utils';
import { FormulaViewer } from './AmountFactorInput';
import { ReactSVG } from 'react-svg';
import { getSizes, useFormulaEditorDataset } from '../hooks';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';

interface UnitFactorInputProps {
  label: string;
  icon: ReactNode;
  factor: FactorValueModel;
  disabled?: boolean;
  onChange: (factor: FactorValueModel) => void;
  showFX?: boolean;
  catalogElementId?: string;
  catalogElementType?: FormulaElementType;
  setIsLoading: (isLoading: boolean) => void;
}

export const UnitFactorInput = ({
  factor,
  icon,
  label,
  onChange,
  disabled,
  showFX = false,
  catalogElementId,
  catalogElementType,
  setIsLoading,
}: UnitFactorInputProps) => {
  const { t } = useTranslation();

  const [showFormulaEditor, setShowFormulaEditor] = useState(false);
  const [showFormulaIcon, setShowFormulaIcon] = useState(false);

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const formulaEditorData = useFormulaEditorDataset({
    projectId: loadedProjectId ?? 'unset',
    calculationModelId: loadedVariantId ?? '',
  });

  const OPTIONS: FormulaBuilderConstructorArgs = useMemo(() => {
    return {
      ...formulaEditorData.values,
      sizes: getSizes(),
    };
  }, [formulaEditorData]);

  useEffect(() => {
    setIsLoading(formulaEditorData.isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formulaEditorData.isLoading]);

  const formulaBuilder = useMemo(() => {
    return new FormulaBuilder(OPTIONS);
  }, [OPTIONS]);

  const visualFormula = formulaBuilder.parseFormula(
    factor?.formulaValue?.expression ?? '',
    factor.formulaValue?.expressionParameters ?? [],
  );

  const toggleFormulaEditor = () => {
    setShowFormulaEditor(!showFormulaEditor);
  };

  const saveFormula = (arg: FormulaStrategyValueResponseModel | null) => {
    onChange(
      produce(factor, (draft) => {
        if (arg === null) {
          draft.formulaValue = arg;
          draft.staticValue = null;
          draft.type = 'Static';
        } else {
          draft.formulaValue = arg;
          draft.staticValue = null;
          draft.type = 'Formula';
        }
      }),
    );
  };

  const focusBlurEventHandlers = useMemo(() => {
    if (!showFX) return {};
    return {
      onFocus: () => setShowFormulaIcon(true),
      onBlur: () => {
        setTimeout(() => setShowFormulaIcon(false), 200);
      },
    };
  }, [showFX]);

  return (
    <div>
      <div className="flex grow text-gray-600 text-base font-semibold pl-3 pt-1">{label}</div>
      <div className="flex divide-x w-full h-14">
        {showFX && factor.formulaValue ? (
          <FormulaViewer
            visualFormula={visualFormula}
            calculatedValue={factor?.formulaValue?.value}
            toggleFormulaEditor={toggleFormulaEditor}
            icon={icon}
          />
        ) : (
          <div className="flex flex-row flex-grow flex-nowrap items-center">
            <NumberInput
              label={t('projectCalculate.formulaLabelStaticValue')}
              icon={icon}
              className="w-full flex-grow"
              value={factor.staticValue?.value ?? undefined}
              disabled={disabled}
              onChange={(v) =>
                onChange({
                  ...factor,
                  staticValue: { value: v, unit: factor.staticValue?.unit ?? 'lumpsum' },
                })
              }
              {...focusBlurEventHandlers}
            />
            {showFX && showFormulaIcon && (
              <div onClick={toggleFormulaEditor}>
                <ReactSVG src="assets/icon-formula.svg" className="formula-icon__highlight w-16 pr-1" />
              </div>
            )}
          </div>
        )}
      </div>

      {showFormulaEditor && (
        <FormulaEditorModal
          defaultFormula={factor.staticValue?.value ? factor.staticValue?.value.toString() : ''}
          saveFormula={saveFormula}
          factor={factor.formulaValue}
          onClose={toggleFormulaEditor}
          catalogElementId={catalogElementId}
          catalogElementType={catalogElementType || 'CostElement'}
          disabled={disabled}
        />
      )}
    </div>
  );
};
