import { useState } from 'react';
import { Button, Modal, SlideOver, UserAvatar } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { ChangeNameModal } from '../ChangeNameModal';
import { UploadAvatarSlideOver } from './UploadAvatarSlideOver';
import { RemoveAvatarModal } from './RemoveAvatarModal';
import { useUi } from '@client/shared/store';
import { LanguageSelector } from './LanguageSelector';
import { SettingsHeader } from '../SettingsHeader';

interface YourAccountProps {
  fullName: string;
  email: string;
}

export const YourAccount = ({ email, fullName }: YourAccountProps) => {
  const { t } = useTranslation();

  const ui = useUi();
  const user = ui.appUser;

  const [nameEditModalOpen, setIsNameEditModalOpen] = useState<boolean>(false);
  const [uploadImageSlideOpen, setUploadImageSlideOpen] = useState<boolean>(false);
  const [removeImageSlideOpen, setRemoveImageSlideOpen] = useState<boolean>(false);

  return (
    <div>
      <SettingsHeader>
        {t('app.settingsYourAccountTitle')}
      </SettingsHeader>
      <div className="border-b flex py-5">
        <div className="grow text-sm">
          <p className="text-gray-600 mb-3">{t('app.settingsYourAccountProfilePicture')}</p>
          <div className="flex">
            <UserAvatar
              userName={user?.fullName ?? ''}
              userId={user?.userId ?? ''}
              lastUpdate={user?.lastUpdate ?? ''}
              hasAvatar={user?.hasAvatar ?? false}
              classNames="w-20 h-20"
            />
          </div>
        </div>
        {user.hasAvatar && (
          <Button onClick={() => setRemoveImageSlideOpen(true)} className="my-auto mr-3">
            {t('common.delete')}
          </Button>
        )}
        <Button onClick={() => setUploadImageSlideOpen(true)} className="my-auto">
          {t('app.editUploadImage')}
        </Button>
      </div>
      <div className="mt-3">
        <div className="border-b flex pb-5">
          <div className="grow text-sm">
            <p className="text-gray-600 mb-3">{t('common.name')}</p>
            <div className="flex">
              <div className="font-bold text-gray-800 grow">{fullName}</div>
            </div>
          </div>
          <Button onClick={() => setIsNameEditModalOpen(true)} className="my-auto">
            {t('common.edit')}
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <div className="border-b flex pb-3">
          <LanguageSelector />
        </div>
      </div>

      <Modal isOpen={nameEditModalOpen} onClose={() => setIsNameEditModalOpen(false)}>
        <ChangeNameModal onClose={() => setIsNameEditModalOpen(false)} />
      </Modal>
      <SlideOver isOpen={uploadImageSlideOpen} onClose={() => setUploadImageSlideOpen(false)}>
        <UploadAvatarSlideOver onClose={() => setUploadImageSlideOpen(false)} />
      </SlideOver>
      <Modal isOpen={removeImageSlideOpen} onClose={() => setRemoveImageSlideOpen(false)}>
        <RemoveAvatarModal onClose={() => setRemoveImageSlideOpen(false)} />
      </Modal>
    </div>
  );
};
