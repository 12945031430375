import { Avatar, Badge, BillIcon, Highlighted } from '@client/shared/toolkit';
import React, { useMemo } from 'react';
import { formatDate, translateTaskName } from '@client/shared/utilities';
import { WorkflowTaskReadModel } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNow } from 'date-fns';
import classNames from 'classnames';

type TasksListItemProps = {
  searchValue: string;
  task: WorkflowTaskReadModel;
};
export const TasksListItem = (props: TasksListItemProps) => {
  const { searchValue, task } = props;
  const { t } = useTranslation();

  const taskUsers = useMemo(() => {
    if (task.completedBy) {
      return [task.completedBy];
    }

    if (task.authorizedUsers?.length) {
      return task.authorizedUsers;
    }
    if (task.authorizedGroups?.length && task.authorizedGroups[0].groupUsers.length) {
      return task.authorizedGroups[0].groupUsers;
    }
    return [];
  }, [task]);

  const translatedTaskName = useMemo(() => {
    return translateTaskName(task.taskName);
  }, [task.taskName]);

  return (
    <div className="flex flex-wrap md:flex-nowrap w-full gap-3.5 justify-between">
      <div className="flex w-full md:w-1/2 2xl:w-2/3 gap-3.5 items-center overflow-hidden">
        <div className="w-8 h-8 rounded-full bg-gray-400 flex items-center justify-center">
          {task.taskEntityReadModel?.entityType === 'Invoice' && (
            <BillIcon className="w-5 hidden md:block text-white" />
          )}
        </div>

        <div className="flex flex-col">
          <div className="text-[15px]">
            <span className="font-bold">
              {searchValue ? <Highlighted text={translatedTaskName} highlight={searchValue} /> : translatedTaskName}
            </span>
            {task.taskEntityReadModel?.name ? (
              searchValue ? (
                <Highlighted text={` • ${task.taskEntityReadModel.name}`} highlight={searchValue} />
              ) : (
                ` • ${task.taskEntityReadModel.name}`
              )
            ) : (
              ''
            )}
          </div>
          <div className="flex flex-wrap text-[11px] text-gray-500 leading-none">
            {task.taskEntityReadModel?.description ? (
              searchValue ? (
                <span className="font-bold">
                  <Highlighted text={task.taskEntityReadModel.description} highlight={searchValue} />
                </span>
              ) : (
                <span className="font-bold">{task.taskEntityReadModel.description}</span>
              )
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="flex w-full md:w-1/2 2xl:w-1/3 items-center md:justify-end overflow-hidden">
        <div className="w-1/3 flex items-center">
          <div className="flex items-center mr-5">
            {taskUsers.map((user, index) => (
              <Avatar
                name={user.userName}
                rounded={true}
                imagePath={user?.hasAvatar ? `/api/user/${user.id}/avatar` : undefined}
                className={classNames('h-10 w-10 -mr-6 bg-slate-400 even:bg-slate-600')}
                key={`${user.id}-${index}`}
              />
            ))}
          </div>

          <div className="w-full truncate ml-3">
            <div className="font-bold truncate">
              {task.authorizedGroups?.length
                ? task.authorizedGroups?.[0]?.groupName ?? taskUsers[0]?.userName
                : taskUsers[0]?.userName}
            </div>
            <div className="text-sm text-gray-500 truncate">
              {task.authorizedGroups?.length ? '' : taskUsers[0].email}
            </div>
          </div>
        </div>
        <div className="w-1/3 flex justify-end items-center pl-3.5">
          {task?.status === 'New' && (
            <Badge text={t('projectTasks.statusNew')} variant="custom" className="bg-green-500 text-white" />
          )}
          {task?.status === 'InProgress' && (
            <Badge text={t('projectTasks.statusInProgress')} variant="custom" className="bg-slate-500 text-white" />
          )}
          {task?.status === 'Accepted' && (
            <Badge text={t('projectTasks.statusAccepted')} variant="custom" className="bg-slate-500 text-white" />
          )}
          {task?.status === 'Rejected' && (
            <Badge text={t('projectTasks.statusRejected')} variant="custom" className="bg-red-500 text-white" />
          )}
        </div>
        <div className="w-1/3 items-end flex flex-col leading-none gap-1 pl-3.5">
          {task.createdAt && (
            <>
              <span className="text-[15px]">{formatDate(task.createdAt)}</span>
              <span className="text-[12px] font-bold text-gray-500">
                {formatDistanceToNow(task.createdAt, { addSuffix: true })}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
