import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiDeleteClusterItemRequirementApiArg,
  ApiDeleteClusterItemRequirementApiResponse,
  ApiDeleteClusterItemRequirementDocumentApiArg,
  ApiDeleteClusterItemRequirementDocumentApiResponse,
  ApiGetClusterItemRequirementApiArg,
  ApiGetClusterItemRequirementApiResponse,
  ApiGetClusterItemsApiArg,
  ApiGetClusterItemsApiResponse,
  ApiGetClusterItemsTreeApiArg,
  ApiGetClusterItemsTreeApiResponse,
  ApiGetPortfolioNextRequirementCodeApiArg,
  ApiGetPortfolioNextRequirementCodeApiResponse,
  ApiGetPortfoliosApiArg,
  ApiGetProjectCompaniesApiResponse,
  ApiPostCreateClusterItemRequirementApiArg,
  ApiPostCreateClusterItemRequirementApiResponse,
  ApiPostCreateClusterItemRequirementDocumentsApiArg,
  ApiPostCreateClusterItemRequirementDocumentsApiResponse,
  ApiPostUpdateClusterItemRequirementApiArg,
  ApiPostUpdateClusterItemRequirementApiResponse,
  ApiPostUpdateClusterItemRequirementStatusApiArg,
  ApiPostUpdateClusterItemRequirementStatusApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiPortfolioEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetPortfolios: {
    providesTags: (
      _result: ApiGetProjectCompaniesApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiGetPortfoliosApiArg,
    ) => [ApiTagTypes.Portfolio],
  },
  apiGetClusterItemsTree: {
    providesTags: (_result: ApiGetClusterItemsTreeApiResponse, _error: FetchBaseQueryError, args: ApiGetClusterItemsTreeApiArg) => [
      { type: ApiTagTypes.Cluster, id: args.portfolioId },
    ],
  },
  apiGetClusterItems: {
    providesTags: (
      _result: ApiGetClusterItemsApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetClusterItemsApiArg,
    ) => [{ type: ApiTagTypes.Cluster, id: args.portfolioId }],
  },
  apiGetClusterItemRequirement: {
    providesTags: (
      _result: ApiGetClusterItemRequirementApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetClusterItemRequirementApiArg,
    ) => [{ type: ApiTagTypes.Requirement, id: args.requirementId }],
  },
  apiGetPortfolioNextRequirementCode: {
    providesTags: (
      _result: ApiGetPortfolioNextRequirementCodeApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetPortfolioNextRequirementCodeApiArg,
    ) => [{ type: ApiTagTypes.Portfolio, id: args.portfolioId }],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateClusterItemRequirement: {
    invalidatesTags: (
      _result: ApiPostCreateClusterItemRequirementApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateClusterItemRequirementApiArg,
    ) => {
      return error == null ? [
        { type: ApiTagTypes.Cluster, id: args.portfolioId },
        { type: ApiTagTypes.Portfolio, id: args.portfolioId }
      ] : [];
    },
  },
  apiPostUpdateClusterItemRequirement: {
    invalidatesTags: (
      _result: ApiPostUpdateClusterItemRequirementApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateClusterItemRequirementApiArg,
    ) => {
      return error == null
        ? [
            { type: ApiTagTypes.Requirement, id: args.requirementId },
            { type: ApiTagTypes.Cluster, id: args.portfolioId },
          ]
        : [];
    },
  },
  apiPostCreateClusterItemRequirementDocuments: {
    invalidatesTags: (
      _result: ApiPostCreateClusterItemRequirementDocumentsApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateClusterItemRequirementDocumentsApiArg,
    ) => {
      return error == null
        ? [
          { type: ApiTagTypes.Requirement, id: args.requirementId }
        ]
        : [];
    },
  },
  apiDeleteClusterItemRequirementDocument: {
    invalidatesTags: (
      _result: ApiDeleteClusterItemRequirementDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteClusterItemRequirementDocumentApiArg,
    ) => {
      return error == null
        ? [
          { type: ApiTagTypes.Requirement, id: args.requirementId }
        ]
        : [];
    },
  },
  apiPostUpdateClusterItemRequirementStatus: {
    invalidatesTags: (
      _result: ApiPostUpdateClusterItemRequirementStatusApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateClusterItemRequirementStatusApiArg,
    ) => {
      return error == null
        ? [
          { type: ApiTagTypes.Requirement, id: args.requirementId },
          { type: ApiTagTypes.Cluster, id: args.portfolioId },
        ]
        : [];
    },
  },
  apiDeleteClusterItemRequirement: {
    invalidatesTags: (
      _result: ApiDeleteClusterItemRequirementApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteClusterItemRequirementApiArg,
    ) => {
      return error == null
        ? [
          { type: ApiTagTypes.Cluster, id: args.portfolioId },
          { type: ApiTagTypes.Portfolio, id: args.portfolioId }
        ]
        : [];
    },
  }
};
