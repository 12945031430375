import classNames from 'classnames';
import {Column, TimeLineView} from ".";
import React, {PropsWithChildren} from "react";

export interface CalculateElementRowFinanceColumnProps extends PropsWithChildren {
  className?: string;
  level: number
  view: TimeLineView
  handleOnClick?: (evt: React.MouseEvent<HTMLDivElement>) => void
  columnType?: Column.CURRENT | Column.OBLIGO | Column.OPTIONAL_COLUMN
}

/* Finance Group Element Column for CURRENT, OBLIGO or OPTIONAL */
export const CalculateElementRowFinanceColumn = (props: CalculateElementRowFinanceColumnProps) => {
  const {
    level,
    children,
    handleOnClick,
    columnType
  } = props

  const textSize = level === 0 ? 'text-[15px]' : level === 1 ? 'text-[13px]' : 'text-[12px]'
  const fontSize = 'font-bold'

  return (
    <div
      className={classNames('w-full pl-4 pr-4 flex transition-colors duration-200 h-[38px]', {
        'cursor-pointer': columnType === Column.CURRENT && handleOnClick
      })}
      onClick={columnType === Column.CURRENT ? handleOnClick : undefined}
    >
      <div
        className={classNames('flex w-full h-full text-right flex-row justify-end items-center text-slate-600', {
          'border-b': level === 0
        }, textSize, fontSize)}
      >
        {children}
      </div>
    </div>
  )
}
