import { DecoratedCard } from '@client/shared/toolkit';
import { PlotReadModel, TaxonomyReadModel } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { PencilIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

interface DescriptionCardProps {
  item?: TaxonomyReadModel | undefined;
  plot?: PlotReadModel | undefined;
  setSelectedField?: (value: string) => void;
  setIsBuildingItemSlideOverOpen?: (value: boolean) => void;
  setIsEditPlotAreaSlideOverOpen?: (value: boolean) => void;
}

export const DescriptionCard = ({
  item,
  plot,
  setSelectedField,
  setIsBuildingItemSlideOverOpen,
  setIsEditPlotAreaSlideOverOpen,
}: DescriptionCardProps) => {
  const { t } = useTranslation();
  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        if (item && setSelectedField && setIsBuildingItemSlideOverOpen) {
          setSelectedField('description');
          setIsBuildingItemSlideOverOpen(true);
        } else if (setIsEditPlotAreaSlideOverOpen) {
          setIsEditPlotAreaSlideOverOpen(true);
        }
      }}
    >
      <DecoratedCard>
        <DecoratedCard.Header
          showActionButton={true}
          icon={<PencilIcon />}
          onActionClick={() => {
            if (item && setSelectedField && setIsBuildingItemSlideOverOpen) {
              setSelectedField('description');
              setIsBuildingItemSlideOverOpen(true);
            } else if (setIsEditPlotAreaSlideOverOpen) {
              setIsEditPlotAreaSlideOverOpen(true);
            }
          }}
        >
          <div className="truncate">{t('projectTaxonomy.Description')}</div>
        </DecoratedCard.Header>
        <DecoratedCard.Content className="p-5 w-full h-full grid grid-cols-3 bg-white gap-6">
          <div
            className={classNames('text-[15px]', {
              'col-span-2': item?.itemType === 'Parking' || plot,
              'col-span-3': item?.itemType !== 'Parking' && !plot,
            })}
          >
            {item && item?.itemType !== 'Parking' && item?.description}
            {item && item?.itemType === 'Parking' && item?.parkingLot?.description}
            {plot && plot?.description}
          </div>
          {item?.itemType === 'Parking' && (
            <div className="col-span-1 flex flex-col gap-6">
              {item?.parkingLot?.number !== '' && (
                <div>
                  <div className="text-base font-bold text-gray-900">{item?.parkingLot?.number}</div>
                  <div className="text-xs text-gray-500">{t('projectTaxonomy.parkingLotNumber')}</div>
                </div>
              )}
              <div>
                <div className="text-base font-bold text-gray-900">{item?.parkingLot?.type}</div>
                <div className="text-xs text-gray-500">{t('projectTaxonomy.parkingLot.type')}</div>
              </div>
            </div>
          )}
          {plot && (
            <div className="col-span-1 flex flex-col gap-6">
              {plot?.parcelNumber && plot?.parcelNumber !== '' && (
                <div>
                  <div className="text-base font-bold text-gray-900">{plot?.parcelNumber}</div>
                  <div className="text-xs text-gray-500">{t('projectTaxonomy.parcelNr')}</div>
                </div>
              )}
              {plot?.street && plot?.street !== '' && (
                <div>
                  <div className="text-base font-bold text-gray-900">{plot?.street}</div>
                  <div className="text-xs text-gray-500">{t('projectTaxonomy.street')}</div>
                </div>
              )}
            </div>
          )}
        </DecoratedCard.Content>
      </DecoratedCard>
    </div>
  );
};
