import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { 
  DocumentReadModel, 
  useApiDeleteCostElementDocumentMutation, 
  useApiDeleteRiskElementDocumentMutation,
  useApiDeleteEarningsElementDocumentMutation, 
  useApiDeleteFinancingElementDocumentMutation,  
} from '@client/shared/api';
import { ModalOnCloseProps, ConfirmModal } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation, Trans } from 'react-i18next';
import { DocumentOwnerType } from './CalculateElementDocuments';

interface CalculateElementDocumentDeleteModalProps extends ModalOnCloseProps {
  ownerId: string;
  type: DocumentOwnerType;
  document: DocumentReadModel;
}

export const CalculateElementDocumentDeleteModal = ({ ownerId, type, document, onClose }: CalculateElementDocumentDeleteModalProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();
  
  const [deleteCostElementDocument, { isLoading : isLoadingCostElementDocument }] = useApiDeleteCostElementDocumentMutation();
  const [deleteRiskElementDocument, { isLoading : isLoadingRiskElementDocument }] = useApiDeleteRiskElementDocumentMutation();
  const [deleteEarningsElementDocument, { isLoading : isLoadingEarningsElementDocument }] = useApiDeleteEarningsElementDocumentMutation();
  const [deleteFinancingElementDocument, { isLoading : isLoadingFinancingElementDocument }] = useApiDeleteFinancingElementDocumentMutation();  

  const handleDelete = async () => {
    switch (type){
      case 'CostElement':
        try {
          await safeMutation(
            deleteCostElementDocument,
            {
              projectId: loadedProjectId ?? 'unset',
              calculationModelId: loadedCalculationModelId ?? 'unset',
              costElementId: ownerId,
              documentId: document.id,
            },
            isLoadingCostElementDocument
          );
    
          onClose(true);
        } catch (e) {
          console.log(e);
        }        
        break;
      case 'RiskElement':
        try {
          await safeMutation(
            deleteRiskElementDocument,
            {
              projectId: loadedProjectId ?? 'unset',
              calculationModelId: loadedCalculationModelId ?? 'unset',
              riskElementId: ownerId,
              documentId: document.id,
            },
            isLoadingRiskElementDocument
          );
    
          onClose(true);
        } catch (e) {
          console.log(e);
        }        
        break;
      case 'EarningsElement':
        try {
          await safeMutation(
            deleteEarningsElementDocument,
            {
              projectId: loadedProjectId ?? 'unset',
              calculationModelId: loadedCalculationModelId ?? 'unset',
              earningsElementId: ownerId,
              documentId: document.id,
            },
            isLoadingEarningsElementDocument
          );
    
          onClose(true);
        } catch (e) {
          console.log(e);
        }        
        break;
      case 'FinancingElement':
        try {
          await safeMutation(
            deleteFinancingElementDocument,
            {
              projectId: loadedProjectId ?? 'unset',
              calculationModelId: loadedCalculationModelId ?? 'unset',
              financingElementId: ownerId,
              documentId: document.id,
            },
            isLoadingFinancingElementDocument
          );
    
          onClose(true);
        } catch (e) {
          console.log(e);
        }        
        break;
    }
  };

  return (
    <ConfirmModal
      title={t('projectCalculate.DeleteDocument')}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      <Trans i18nKey="projectCalculate.DocumentDeleteConfirmation" values={{ entity: document.name }}>
        <div>
          Möchten Sie die Datei <span className="font-bold">Entity</span> wirklich löschen?
        </div>
        <div className="pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
