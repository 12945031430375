import { EarningsType, FinancingType } from '@client/shared/api';
import { BaseSelectOption } from '@client/shared/toolkit';
import { i18n } from '@client/shared/utilities';

export const getTranslatedFinanceType = (type?: FinancingType | null): string => {
  switch (type) {
    case 'OwnCapital':
      return i18n.t('projectCalculate.financingType.OwnCapital');
    case 'Grant':
      return i18n.t('projectCalculate.financingType.Grant');
    case 'Fund':
      return i18n.t('projectCalculate.financingType.Fund');
    case 'Credit':
      return i18n.t('projectCalculate.financingType.Credit');
  }

  return "";
};

export const getTranslatedEarningsType = (type: EarningsType): string => {
    switch (type) {
      case 'SaleRevenue':
        return i18n.t('projectCalculate.earningsTypes.SaleRevenue');
      case 'RentRevenue':
        return i18n.t('projectCalculate.earningsTypes.RentRevenue');
      case 'OperatingRevenue':
        return i18n.t('projectCalculate.earningsTypes.OperatingRevenue');
      case 'ServiceRevenue':
        return i18n.t('projectCalculate.earningsTypes.ServiceRevenue');
    }
  };

export const getTranslatedFinanceOptions = (): BaseSelectOption[] => {
    return [
        {
          label: getTranslatedFinanceType('OwnCapital'),
          value: 'OwnCapital' as FinancingType,
        },
        {
          label: getTranslatedFinanceType('Grant'),
          value: 'Grant' as FinancingType,
        },
        {
          label: getTranslatedFinanceType('Fund'),
          value: 'Fund' as FinancingType,
        },
        {
          label: getTranslatedFinanceType('Credit'),
          value: 'Credit' as FinancingType,
        },
      ];
}

export const getTranslatedEarningOptions = (): BaseSelectOption[] => {
    return [
        {
          label: getTranslatedEarningsType('SaleRevenue'),
          value: 'SaleRevenue',
        },
        {
          label: getTranslatedEarningsType('RentRevenue'),
          value: 'RentRevenue',
        },
        {
          label: getTranslatedEarningsType('OperatingRevenue'),
          value: 'OperatingRevenue',
        },
        {
          label: getTranslatedEarningsType('ServiceRevenue'),
          value: 'ServiceRevenue',
        },
      ];
}
