import { PlusIcon } from '../../icons';
import { MouseEvent } from 'react';
import cn from 'classnames';

const ADD_BUTTON_VARIANT_CLASSES = {
  secondary: 'bg-gray-700 text-white',
  primary: 'bg-sky-700 hover:bg-sky-800 text-white',
  custom: ''
}

export type AddButtonProps = {
  className?: string;
  onClick?: (e: MouseEvent) => void;
  variant?: 'primary' | 'secondary' | 'custom'
  disabled?: boolean
  scale?: boolean
}
export const AddButton = ({
  className,
  onClick,
  variant = 'secondary',
  disabled = false,
  scale = true
}: AddButtonProps) => {

  const variantClasses = ADD_BUTTON_VARIANT_CLASSES[variant]
  return (
    <PlusIcon
      className={cn(
        'w-10 h-10 rounded-full p-3',
        {
          'cursor-pointer': !disabled,
          'hover:scale-110 transition-transform duration-200': !disabled && scale
        },
        variantClasses,
        className,
      )}
      onClick={!disabled ? onClick : undefined}
    />
  );
};
