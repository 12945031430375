import {
  AddButton,
  ContextMenu,
  ContextMenuItem,
  EditListIcon,
  PencilIcon,
  SlideOverSortableList,
  SortingArrowsIcon,
  FilledLockIcon,
  Modal,
  TrashIcon,
} from '@client/shared/toolkit';
import { sortByProperty, SortHeaderType } from '@client/shared/utilities';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatevMappingAddSlideOver, DatevMappingDeleteModal } from '.';
import {
  DatevConnectionResponse,
  DatevProbisMappingReadModel,
} from '@client/shared/api';

interface DatevClientSlideOverMappingTabProps {
  projectId?: string;
  apiAccessId?: string;
  connectionData: DatevConnectionResponse | undefined;
}

export const DatevClientSlideOverMappingTab = ({
  projectId,
  apiAccessId,
  connectionData,
}: DatevClientSlideOverMappingTabProps) => {
  const { t } = useTranslation();

  const [isOpenEditSlideover, setIsOpenEditSlideover] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DatevProbisMappingReadModel | null>(null);

  const defaultSortHeader: SortHeaderType[] = useMemo(() => {
    return [
      {
        asc: true,
        label: t('app.settingsApiDatevConnectionDatevId'),
        type: 'string',
        value: 'datevPropertyName',
      },
      {
        asc: null,
        label: t('app.settingsApiDatevConnectionProbisObjectId'),
        type: 'string',
        value: 'probisPropertyName',
      },
      {
        asc: null,
        label: t('app.settingsApiDatevConnectionCustomField'),
        type: 'string',
        value: 'userDefinedFieldName',
      },
      {
        asc: null,
        label: t('app.settingsApiDatevConnectionDefaultValue'),
        type: 'string',
        value: 'defaultValue',
      },
      // {
      //   asc: null,
      //   label: t('app.settingsApiDatevConnectionRegex'),
      //   type: 'string',
      //   value: 'regex',
      // },
    ];
  }, [t]);

  const [sortHeader, setSortHeader] = useState<SortHeaderType[]>(defaultSortHeader);

  const datevProbisMappings = useMemo(() => {
    return connectionData?.datevProbisMapping ?? [];
  }, [connectionData?.datevProbisMapping]);

  const sortedDatevProbisMappings = useMemo(() => {
    const sortBy = sortHeader.find((item) => item.asc !== null);
    let sortedMappings = [...(datevProbisMappings ?? [])];
    if (sortBy) {
      return (sortedMappings = sortByProperty(sortedMappings, sortBy));
    } else {
      return sortedMappings;
    }
  }, [datevProbisMappings, sortHeader]);

  const mappingTypes = useMemo(
    () => [...new Set(datevProbisMappings.map((mapping) => mapping.type))],
    [datevProbisMappings],
  );

  const handleAdd = () => {
    setSelectedItem(null);
    setIsOpenEditSlideover(true);
  };

  const handleEdit = (item: DatevProbisMappingReadModel) => {
    setSelectedItem(item);
    setIsOpenEditSlideover(true);
  };

  const onHandleSort = useCallback(
    (index: number) => {
      const currentSortValues = [...sortHeader];
      const update = currentSortValues[index];
      update.asc = update.asc === null ? true : !update.asc;
      currentSortValues.forEach((val, i) => {
        if (i !== index) {
          val.asc = null;
        }
      });
      currentSortValues[index] = update;
      setSortHeader(currentSortValues);
    },
    [sortHeader],
  );

  return (
    <div className="relative flex flex-col gap-5">
      {mappingTypes.length > 0 ? (
        mappingTypes.map((mappingType, idx) => (
          <DatevClientSlideOverMappingList
            mappings={sortedDatevProbisMappings.filter((item) => item.type === mappingType)}
            title={mappingType}
            handleEdit={handleEdit}
            sortHeader={idx === 0 ? sortHeader : undefined}
            onHandleSort={idx === 0 ? onHandleSort : undefined}
            key={idx}
            apiAccessId={apiAccessId}
          />
        ))
      ) : (
        <div className="text-center text-gray-400">{t('app.settingsApiDatevNoConnections')}</div>
      )}
      <div className="absolute right-10 -bottom-5">
        <AddButton onClick={handleAdd} />
      </div>
      <DatevMappingAddSlideOver
        isOpen={isOpenEditSlideover}
        onClose={() => setIsOpenEditSlideover(false)}
        item={selectedItem}
        apiAccessId={apiAccessId}
        projectId={projectId}
        connectionData={connectionData}
      />
    </div>
  );
};

interface DatevClientSlideOvermappingListProps extends PropsWithChildren {
  mappings: DatevProbisMappingReadModel[];
  title: string;
  handleEdit: (item: DatevProbisMappingReadModel) => void;
  sortHeader?: SortHeaderType[];
  onHandleSort?: (index: number) => void;
  apiAccessId?: string;
}

const DatevClientSlideOverMappingList = ({
  mappings,
  title,
  handleEdit,
  children,
  sortHeader,
  onHandleSort,
  apiAccessId,
}: DatevClientSlideOvermappingListProps) => {
  const { t } = useTranslation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DatevProbisMappingReadModel | null>(null);

  const listItems = useMemo(() => {
    return mappings.map((item, i) => {
      const contextMenuItems: ContextMenuItem[] = [
        {
          label: t('common.edit'),
          subtitle: t('app.settingsApiDatevConnectionEdit'),
          icon: <PencilIcon />,
          onClick: () => {
            handleEdit(item);
          },
        },
        {
          label: t('common.delete'),
          subtitle: t('app.settingsApiDatevConnectionDelete'),
          icon: <TrashIcon />,
          onClick: () => {
            setSelectedItem(item);
            setIsDeleteModalOpen(true);
          },
          stopPropagation: true,
          isDisabled: item.isMandatoryElement,
        },
      ];

      return {
        id: item.id,
        name: (
          <div className="flex items-center gap-2 h-full">
            {item.name}
            {item.isMandatoryElement && (
              <span className="h-full">
                <FilledLockIcon className="h-2.5" />
              </span>
            )}
          </div>
        ),
        description: item.description,
        // item.defaultValue ? (
        //   <div className="flex items-center gap-2">
        //     <div className="w-2 h-2 bg-green-500 rounded-full" />
        //     <span className="font-bold">{t('app.settingsApiDatevConnectionOn')}</span> |{' '}
        //     {t('app.settingsApiDatevConnectionListening')}
        //   </div>
        // ) : (
        //   <div className="flex items-center gap-2">
        //     <div className="w-2 h-2 bg-red-500 rounded-full" />
        //     {t('app.settingsApiDatevConnectionOff')}
        //   </div>
        // ),

        icon: <SortingArrowsIcon className="rotate-90 h-6" />,
        cols: [
          {
            value: item.datevPropertyName,
            header: t('app.settingsApiDatevConnectionDatevId'),
          },
          {
            value: item.probisPropertyName ?? '',
            header: t('app.settingsApiDatevConnectionProbisObjectId'),
          },
          {
            value: item.userDefinedFieldName ?? '',
            header: t('app.settingsApiDatevConnectionCustomField'),
          },
          {
            value: item.defaultValue ?? '',
            header: t('app.settingsApiDatevConnectionDefaultValue'),
          },
          // {
          //   value: item.userDefinedFieldName,
          //   header: t('app.settingsApiDatevConnectionRegex'),
          // },
        ],
        contextMenu: <ContextMenu items={contextMenuItems} stopPropagation />,
        className: 'bg-white',
      };
    });
  }, [mappings, handleEdit, t]);

  return (
    <div>
      <SlideOverSortableList
        data={listItems}
        handleSelect={(i: number) => handleEdit(mappings[i])}
        sortHeader={sortHeader ?? []}
        onHandleSort={onHandleSort}
        headline={
          sortHeader ? (
            <span className="text-[13px] text-gray-400">{t('app.settingsApiDatevConnectionObjectsyncronisation')}</span>
          ) : undefined
        }
        bgColor=" "
        noItemsMessage={t('app.settingsApiDatevNoConnections')}
        gridCols="grid-cols-4"
        contextMenu
        additionalTopChildren={
          <div className="w-full h-[60px] bg-slate-200 flex justify-between items-center text-slate-500">
            <div className="flex items-center justify-center">
              <div className="w-20 flex items-center justify-center">
                <EditListIcon className="h-6" />
              </div>
              <span className="text-lg font-bold mr-2">{title}</span>
              <SortingArrowsIcon className="rotate-90 h-6" />
            </div>
          </div>
        }
      />

      {children}
      <Modal isOpen={isDeleteModalOpen} onClose={setIsDeleteModalOpen}>
        <DatevMappingDeleteModal onClose={setIsDeleteModalOpen} item={selectedItem} apiAccessId={apiAccessId} />
      </Modal>
    </div>
  );
};
