import { FinancingPaymentPlanElementReadModel } from '@client/shared/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddButton, Button, Modal } from '@client/shared/toolkit';
import { RenderFinancingPayoutElement } from './RenderFinancingPayoutElement';
import { formatDateOnly } from '@client/shared/utilities';
import { FormattedCurrency } from '@client/project/shared';

interface FinancingCustomPayoutModalProps {
  onClose: (plan?: FinancingPaymentPlanElementReadModel[]) => void;
  payoutPlan: FinancingPaymentPlanElementReadModel[];
  nominalAmount: number;
}

export const FinancingCustomPayoutModal = ({ nominalAmount, onClose, payoutPlan }: FinancingCustomPayoutModalProps) => {
  const { t } = useTranslation();

  const [plan, setPlan] = useState(payoutPlan);

  useEffect(() => {
    setPlan(payoutPlan);
  }, [payoutPlan]);

  const addNewElement = () => {
    const extendedPlan = [
      ...plan,
      {
        id: '',
        description: '',
        amount: 0,
        percentage: null,
        paymentOn: formatDateOnly(new Date()),
        order: plan.length + 1,
      },
    ];

    setPlan(updateOrder(extendedPlan));
  };

  const totalPayout =
    plan.length > 0
      ? plan
          .map((a) => a.amount)
          .reduce(function (a, b) {
            return (a ?? 0) + (b ?? 0);
          }) ?? 0
      : 0;

  const restAmount = nominalAmount - totalPayout;

  const updateOrder = (planToOrder: FinancingPaymentPlanElementReadModel[]): FinancingPaymentPlanElementReadModel[] => {
    const elements = [...planToOrder];

    return elements.map((v) => {
      v = { ...v, order: elements.indexOf(v) + 1 };
      return v;
    });
  };

  return (
    <>
      <Modal.Header
        title={t('projectCalculate.financingPayoutScheduleTitle')}
        description={t('projectCalculate.financingPayoutScheduleDescription')}
      />
      <Modal.Content>
        <div className="overflow-auto">
          <div className="divide-y">
            {plan.map((payoutElement) => {
              return (
                <RenderFinancingPayoutElement
                  key={payoutElement.order}
                  item={payoutElement}
                  onItemChange={(item) => {
                    const result = plan.map((c) => {
                      if (c.order === item.order) {
                        c = item;
                      }
                      return c;
                    });
                    setPlan(result);
                  }}
                  onItemDeleted={(item) => {
                    const ordered = updateOrder(plan.filter((x) => x.order !== item.order));
                    setPlan(ordered);
                  }}
                />
              );
            })}
          </div>

          <div className="sticky bottom-0 flex-row-reverse flex mr-2">
            <AddButton onClick={addNewElement} />
          </div>
        </div>

        {restAmount !== 0 && (
          <div className="flex items-center py-2 pl-2">
            <div className="font-medium text-gray-900 pr-2">
              {t('projectCalculate.financingElementLabelRestAmount')}:
            </div>
            <div className="flex flex-row items-center p-2 space-x-2">
              <div><FormattedCurrency amount={restAmount} /></div>
            </div>
          </div>
        )}
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={() => onClose(undefined)} className="mr-2" variant="secondary">
          {t('common.cancel')}
        </Button>
        <Button onClick={() => onClose(plan)}>{t('common.save')}</Button>
      </Modal.Controls>
    </>
  );
};
