export const monthDiff = (d1?: Date, d2?: Date): number => {
  if (!d1) return 0;

  if (!d2) return 0;

  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};
