import * as yup from 'yup';
import { InferType } from 'yup';

export const DealEditFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  code: yup.string().optional(),
  status: yup.string().optional(),
  start: yup.date(),
  end: yup.date(),
});

export type DealEditFormValidationValues = InferType<typeof DealEditFormValidationSchema>;
