import cn from 'classnames';

interface StatusBadgeProps {
  status?: 'success' | 'warning' | 'danger' | 'disabled' | 'custom'
  size?: 'default' | 'custom'
  customColor?: string
  customSize?: string
  className?: string
}
export const StatusBadge = (props: StatusBadgeProps) => {
  const {
    status = 'success',
    customColor = '',
    size = 'default',
    customSize = '',
    className
  } = props

  const bgColor = status === 'success' ? 'bg-green-500' : status === 'warning' ? 'bg-yellow-500' : status === 'danger' ? 'bg-red-500' : status === 'disabled' ? 'bg-gray-500' : customColor
  const sizeClass = size === 'default' ? 'w-2 h-2' : customSize

  return (
    <div className={cn('my-2 mr-2 rounded-full align-center flex-none', bgColor, sizeClass, className)} />
  )
}
