import { CalculationTarget } from '@client/shared/api';
import { i18n } from '@client/shared/utilities';

export const getCalculationTargetValueLabel = (calculation: CalculationTarget): string => {
  switch (calculation) {
    case 'Amount':
      return i18n.t('projectRent.calculationTargetValue.Amount');
    case 'RentalSpace':
      return i18n.t('projectRent.calculationTargetValue.RentalSpace');
    case 'CommonSpace':
      return i18n.t('projectRent.calculationTargetValue.CommonSpace');
    default:
      return i18n.t('projectRent.calculationTargetValue.Value');
  }
};
