import { PaymentPlanType } from '@client/shared/api';
import { BaseSelect } from '@client/shared/toolkit';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { i18n } from '@client/shared/utilities';
import { FinancingDistributionType } from './FinancingDistribution';

const getPayoutTypeOptions = () => [
  {
    label: i18n.t('projectCalculate.payoutTypes.AllInTheBeginning'),
    value: 'AllInTheBeginning' as PaymentPlanType,
  },
  {
    label: i18n.t('projectCalculate.payoutTypes.MonthlyLinear'),
    value: 'MonthlyLinear' as PaymentPlanType,
  },
  {
    label: i18n.t('projectCalculate.payoutTypes.QuarterlyLinear'),
    value: 'QuarterlyLinear' as PaymentPlanType,
  },
  {
    label: i18n.t('projectCalculate.payoutTypes.Variable'),
    value: 'Variable' as PaymentPlanType,
  }
];

const getPaybackTypeOptions = () => [
  {
    label: i18n.t('projectCalculate.paybackTypes.EndOfTheProject'),
    value: 'EndOfTheProject' as PaymentPlanType,
  },
  {
    label: i18n.t('projectCalculate.paybackTypes.AllInTheBeginning'),
    value: 'AllInTheBeginning' as PaymentPlanType,
  },
  {
    label: i18n.t('projectCalculate.paybackTypes.MonthlyLinear'),
    value: 'MonthlyLinear' as PaymentPlanType,
  },
  {
    label: i18n.t('projectCalculate.paybackTypes.QuarterlyLinear'),
    value: 'QuarterlyLinear' as PaymentPlanType,
  },
  {
    label: i18n.t('projectCalculate.paybackTypes.Variable'),
    value: 'Variable' as PaymentPlanType,
  }
];
interface FinancingPaymentTypeSelectProps {
  className?: string
  defaultPaymentPlanType: PaymentPlanType
  selectedValue: PaymentPlanType
  onChange: (type: PaymentPlanType) => void
  disabledTypes?: PaymentPlanType[]
  disabled?: boolean
  type: FinancingDistributionType
}

export const FinancingPaymentTypeSelect = (props: FinancingPaymentTypeSelectProps) => {
  const { t } = useTranslation();
  const {
    className,
    defaultPaymentPlanType,
    selectedValue,
    onChange,
    disabledTypes = [],
    disabled = false,
    type
  } = props;

  const handleChange = (type: string) => {
    triggerOnChange(type as PaymentPlanType);
  };

  const triggerOnChange = (type: PaymentPlanType) => {
    onChange(type);
  };

  const selectableTypes = useMemo(() => {
    const types = type === FinancingDistributionType.PAYOUT ? getPayoutTypeOptions() : getPaybackTypeOptions()
    if (disabledTypes && disabledTypes.length > 0) {
      return types.filter((type) => !disabledTypes.includes(type.value));
    }
    return types;
  }, [type, disabledTypes]);

  const label = useMemo(() => {
    return type === FinancingDistributionType.PAYOUT ? t('projectCalculate.financingElementLabelPayoutPlan') : t('projectCalculate.financingElementLabelPaybackPlan')
  }, [type, t])

  return (
    <BaseSelect
      className={className}
      disabled={disabled}
      options={selectableTypes}
      label={label}
      value={selectedValue ?? defaultPaymentPlanType}
      onChange={(value) => handleChange(value)}
    />
  );
};
