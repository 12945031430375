import { AuthGroupReadModel, useApiDeleteAuthGroupMutation } from '@client/shared/api';
import { ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation, Trans } from 'react-i18next';

interface GroupDeleteModalProps extends ModalOnCloseProps {
  group?: AuthGroupReadModel;
}

export const GroupDeleteModal = ({ group, onClose }: GroupDeleteModalProps) => {
  const { t } = useTranslation();

  const [postDelete, { isLoading: isDeleting }] = useApiDeleteAuthGroupMutation();

  const handleDelete = async () => {
    if (group) {
      try {
        await safeMutation(
          postDelete,
          {
            groupId: group.id,
          },
          isDeleting
        );

        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  return (
    <ConfirmModal
      title={`${t('common.delete')} ${group?.name ?? ''}`}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isDeleting}
    >
      {isDeleting && (
        <LoadingIndicator text={t('common.loading')} mode="overlay" />
      )}
      <Trans i18nKey="auth.groupDeleteConfirmation" values={{ entity: `${group?.name ?? ''}` }}>
        <div className="block">
          Do you want to delete
          <span className="font-bold">Role</span>?<span className="font-bold">Group</span>?
        </div>
        <div className="inline-block pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
