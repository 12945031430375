import { useLoadedProjectId } from '@client/project/store';
import { useApiGetTaxonomyWithCostItemsQuery } from '@client/shared/api';

export const useGetVariantTaxonomyCostItems = () => {
  const loadedProjectId = useLoadedProjectId();

  return useApiGetTaxonomyWithCostItemsQuery(
    { projectId: loadedProjectId ?? '' },
    { skip: loadedProjectId == null }
  );
};
