import {
  Badge,
  DecoratedCard,
  ProjectSetupIcon,
  SettingsListHeader,
  SettingsListItem,
  SettingsListItemTitle,
} from '@client/shared/toolkit';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { DatevClientLinkedProjectReadModel, DatevConnectionResponse } from '@client/shared/api';

interface DatevClientSlideOverProjectsTabProps {
  connectionData: DatevConnectionResponse | undefined;
}

export const DatevClientSlideOverProjectsTab = ({ connectionData }: DatevClientSlideOverProjectsTabProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-14">
      {connectionData?.clientDetail?.projects && (
        <DatevClientSlideOverProjectsList
          projects={connectionData?.clientDetail?.projects}
          title={t('app.settingsApiDatevActiveProjects')}
          active
        />
      )}
      {/* <DatevClientSlideOverProjectsList
        projects={archivedProjects}
        title={t('app.settingsApiDatevArchivedProjects')}
        handleEdit={handleEdit}
      /> */}
    </div>
  );
};

interface DatevClientSlideOverProjectsListProps extends PropsWithChildren {
  active?: boolean;
  projects: DatevClientLinkedProjectReadModel[];
  title: string;
}

const DatevClientSlideOverProjectsList = ({ projects, title, children }: DatevClientSlideOverProjectsListProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <DecoratedCard>
        <DecoratedCard.Header>{title}</DecoratedCard.Header>
        <DecoratedCard.Content className="p-8">
          <SettingsListHeader>
            <div className="flex justify-between w-full">
              <span className="">{t('common.project')}</span>
              <span className="">{t('app.settingsApiDatevSyncedElement')}</span>
            </div>
          </SettingsListHeader>
          {projects.map((project) => {
            return (
              <SettingsListItem key={project.projectId}>
                <div className="flex justify-between w-full">
                  <SettingsListItemTitle icon={<ProjectSetupIcon className="w-full" />} title={project.projectName} />
                  <span className="text-gray-400 flex items-center">
                    <Badge
                      variant="custom"
                      className="text-white bg-gray-400"
                      text={project.numberOfSyncedInvoices.toString()}
                    />
                  </span>
                </div>
              </SettingsListItem>
            );
          })}
        </DecoratedCard.Content>
        {children}
      </DecoratedCard>
    </div>
  );
};
