import { FilterGroup, FilterOption, SearchFilter } from '@client/shared/toolkit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getCostsFilters,
  CostsFilterOption,
  FINANCE_COST_FILTERS
} from '../utils';
import { useGetCurrency, useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { UserDefinedFieldsSearch } from '@client/project/shared';

export interface CalculateSearchFilterProps {
  handleSelectedCostFiltersChange: (filters: CostsFilterOption[]) => void
  showFinance?: boolean
  handleSearchValueChange: (value: string) => void
  updateSearchResults: (result: string[]) => void
}

export const CalculateSearchFilter = (props: CalculateSearchFilterProps) => {
  const {
    handleSelectedCostFiltersChange,
    showFinance = false,
    handleSearchValueChange,
    updateSearchResults
  } = props
  const { t } = useTranslation()

  const loadedVariantId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();

  const [searchValue, setSearchValue] = useState<string>('')

  const currentCurrency = useGetCurrency();
  const costFilters: CostsFilterOption[] = getCostsFilters(currentCurrency)

  const costFilterGroup: FilterGroup = useMemo(() => {
    return {
      options: costFilters as FilterOption[],
      visible: showFinance,
      title: t('projectCalculate.financeCostFiltersTitle')
    }
  }, [costFilters, showFinance, t])

  const handleSearch = useCallback((value: string, filters: FilterOption[]) => {
    setSearchValue(value)
    handleSearchValueChange(value)
    const financeFilters = filters.filter((filter) => {
      return FINANCE_COST_FILTERS.includes(filter.id)
    })
    handleSelectedCostFiltersChange(financeFilters as CostsFilterOption[])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSearchValueChange, handleSelectedCostFiltersChange])

  useEffect(() => {
    if (!showFinance) {
      handleSelectedCostFiltersChange([]);
    } else {
      setSearchValue('');
      handleSearchValueChange('');
    }
  }, [showFinance, handleSelectedCostFiltersChange, handleSearchValueChange]);

  // TODO we keep the old logic for finance view for now
  if (showFinance) {
    return (
      <SearchFilter
        filterGroups={[costFilterGroup]}
        handleSearch={handleSearch}
        updateSearchValue={setSearchValue}
        searchValue={searchValue}
        className="h-10 xl:w-2/3"
      />
    )
  }

  return (
    <UserDefinedFieldsSearch
      searchValue={searchValue}
      updateSearchResults={updateSearchResults}
      handleSearchValueUpdate={(val) => {
        setSearchValue(val);
        handleSearchValueChange(val);
      }}
      udfElementTypes={['Cost', 'Risk', 'Earning']}
      className="h-10 xl:w-2/3"
      filterGroups={[costFilterGroup]}
      searchDisabled={!loadedVariantId || !loadedProjectId}
      filterStore="Cost"
    />
  )
}
