import { useTranslation } from 'react-i18next';
import { IcsDeductionValueFormField, IcsDeductionValueFormFieldProps } from './IcsDeductionValueFormField';

interface IcsDeductionCumulatedValueFormFieldsProps extends IcsDeductionValueFormFieldProps {
  selectedValues: (string | null | undefined)[] // selectedPartialInvoiceValue, selectedPartialFinalInvoiceValue, selectedFinalInvoiceValue
}

export const IcsDeductionCumulatedValueFormFields = (props: IcsDeductionCumulatedValueFormFieldsProps) => {
  const {
    selectedValues,
    customAmount
  } = props
  const { t } = useTranslation()

  return (
    <>
      <IcsDeductionValueFormField
        {...props}
        name="selectedPartialInvoiceValue"
        label={t('ics.deductionPartialInvoiceValue')}
        selectedValue={selectedValues[0]}
        referenceTypeFormName="partialInvoiceReferenceType"
        calculationRuleReferenceFormName="partialInvoiceCalculationRuleReference"
        calculationRuleGroupReferenceFormName="partialInvoiceCalculationRuleGroupReference"
        customAmount={customAmount}
      />
      <IcsDeductionValueFormField
        {...props}
        name="selectedPartialFinalInvoiceValue"
        label={t('ics.deductionPartialFinalInvoiceValue')}
        selectedValue={selectedValues[1]}
        referenceTypeFormName="partialFinalInvoiceReferenceType"
        calculationRuleReferenceFormName="partialFinalInvoiceCalculationRuleReference"
        calculationRuleGroupReferenceFormName="partialFinalInvoiceCalculationRuleGroupReference"
        customAmount={customAmount}
      />
      <IcsDeductionValueFormField
        {...props}
        name="selectedFinalInvoiceValue"
        label={t('ics.deductionFinalInvoiceValue')}
        selectedValue={selectedValues[2]}
        referenceTypeFormName="finalInvoiceReferenceType"
        calculationRuleReferenceFormName="finalInvoiceCalculationRuleReference"
        calculationRuleGroupReferenceFormName="finalInvoiceCalculationRuleGroupReference"
        customAmount={customAmount}
      />
    </>
  )
}
