import { useTranslation } from 'react-i18next';
import { DocumentViewer } from '.';
import { Button } from '../Button';
import { SlideOver } from '../SlideOver';
import { DialogTitle } from '@headlessui/react';
import cn from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/solid';

interface DocumentViewerSlideOverProps {
  name: string;
  preTitle?: string
  subTitle?: string;
  pdfFile: string;
  onClose: () => void;
  documentViewerTitle?: string
}

export const DocumentViewerSlideOver = ({ name, pdfFile, onClose, preTitle, subTitle, documentViewerTitle }: DocumentViewerSlideOverProps) => {
    const { t } = useTranslation();

    return (
    <>
      <DialogTitle className={cn('w-full relative')}>
        <div className="flex h-[88px] items-center justify-between border-b">
          <div className="w-full flex-1 py-4 px-4">
            <div className="flex">
              {name && (
                <div className="w-full">
                  {preTitle && (
                    <div className="whitespace-nowrap text-[13px] leading-tight text-gray-500">
                      {preTitle}
                    </div>
                  )}
                  {name && <div className="text-2xl leading-tight font-bold truncate">{name}</div>}
                  {subTitle && (
                    <div className="whitespace-nowrap text-[13px] leading-tight text-gray-500 pt-1">
                      {subTitle}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {onClose && (
            <div className="absolute top-1/2 -translate-y-1/2 right-4" onClick={onClose}>
              <XMarkIcon className="w-8 h-8 text-gray-500 hover:text-gray-600 transition-color duration-200 cursor-pointer" />
            </div>
          )}
        </div>
      </DialogTitle>

      <SlideOver.Content>
        <div className="w-full flex-none py-4 px-8">
          <DocumentViewer
            menuOptions={{
              fullscreen: false,
            }}
            title={documentViewerTitle ?? name}
            pdfFile={pdfFile}
          />
        </div>
      </SlideOver.Content>

      <SlideOver.Controls>
        <div className="w-full flex justify-end">
          <Button variant="secondary" onClick={onClose}>
            {t('common.close')}
          </Button>
        </div>
      </SlideOver.Controls>
    </>
  );
};
