import { useState, useLayoutEffect } from 'react';

/**
 * The useComponentDimensions hook uses the ResizeObserver API to observe the dimensions of the passed in
 * containerRef. It returns an object with the width and height of the container whenever it changes.
 * @param containerRef The ref to the container element to observe the dimensions of.
 * @returns The dimensions of the containerRef.
 */
export const useComponentDimensions = (containerRef: React.RefObject<HTMLElement>) => {
  const [dimensions, setDimensions] = useState<{ width: number | undefined; height: number | undefined }>({
    width: undefined,
    height: undefined,
  });

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
      const newDimensions = {
        width: event[0].contentBoxSize[0].inlineSize,
        height: event[0].contentBoxSize[0].blockSize,
      };

      if (newDimensions.width !== dimensions.width || newDimensions.height !== dimensions.height) {
        setDimensions(newDimensions);
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef, dimensions]);

  return dimensions;
};
