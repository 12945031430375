import { UnitSystem, useApiGetProjectDashboardQuery } from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId, useUnitSystem } from '@client/project/store';
import { useMemo } from 'react';

export const useProjectDashboardData = () => {
  const unitSystem = useUnitSystem();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const getProjectDashboardQuery = useApiGetProjectDashboardQuery(
    {
      projectId: loadedProjectId || '',
      calculationModelId: loadedVariantId || '',
      unitSystem: unitSystem as UnitSystem,
    },
    { skip: !loadedProjectId || !loadedVariantId },
  );

  return useMemo(() => {
    return getProjectDashboardQuery;
  }, [getProjectDashboardQuery]);
};
