import { useLoadedProjectId } from '@client/project/store';
import { DealType, useApiGetRentDealAvailableTaxonomyQuery, useApiGetSellDealAvailableTaxonomyQuery } from '@client/shared/api';

export const useGetTaxonomyAvailableForDeal = (dealId: string, type: DealType) => {
  const loadedProjectId = useLoadedProjectId();

  const rentTaxonomy = useApiGetRentDealAvailableTaxonomyQuery(
    {
      projectId: loadedProjectId ?? '',
      dealId: dealId,
    },
    {
      skip: type === 'Sell' || loadedProjectId == null,
      refetchOnMountOrArgChange: true,
    }
  );

  const sellTaxonomy = useApiGetSellDealAvailableTaxonomyQuery(
    {
      projectId: loadedProjectId ?? '',
      dealId: dealId,
    },
    {
      skip: type === 'Rent' || loadedProjectId == null,
      refetchOnMountOrArgChange: true,
    }
  );

  switch (type){
    case 'Rent':
      return rentTaxonomy;
    case 'Sell':
      return sellTaxonomy;
  }
};
