import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

interface WizardContentProps extends PropsWithChildren {
  className?: string
}
export const WizardSlideContent = (props: WizardContentProps) => {
  const {
    className,
    children
  } = props
  return (
    <div className={cn('w-full px-12 divide-y divide-gray-100', className)}>
      {children}
    </div>
  )
}
