import { BaseSelectOption, ComboSelect, ContextMenu, TextInput, TrashIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

interface DocuwareProjectMappingListItemProps {
  id: number;
  options: BaseSelectOption[];
  probisProject?: string;
  docuWareProject?: string;
  onUpdate: (id: number, probisProject: string, docuWareProject: string) => void;
  onDelete: (id: number) => void;
}

export const DocuwareProjectMappingListItem = ({
  id,
  options,
  onUpdate,
  onDelete,
  probisProject,
  docuWareProject,
}: DocuwareProjectMappingListItemProps) => {
  const { t } = useTranslation();

  const contextMenuItems = [
    {
      label: t('common.delete'),
      subtitle: t('app.settingsApiDocuwareProjectsDelete'),
      icon: <TrashIcon />,
      onClick: () => {
        onDelete(id);
      },
    },
  ];

  return (
    <div className="flex bg-white divide-x w-full items-center">
      <div className="w-6/12">
        <ComboSelect
          className="w-full"
          label={t('app.settingsApiProjects')}
          options={options.sort((a, b) => a.label.localeCompare(b.label))}
          value={probisProject ?? ''}
          onChange={(value) => {
            onUpdate(id, value ?? '', docuWareProject ?? '');
          }}
        />
      </div>
      <div className="w-5/12">
        <TextInput
          className="w-full"
          label={t('app.settingsApiDocuwareProjects')}
          value={docuWareProject ?? ''}
          onChange={(value: string) => {
            onUpdate(id, probisProject ?? '', value);
          }}
        />
      </div>
      <div className="w-1/12 flex justify-center">
        <ContextMenu items={contextMenuItems} />
      </div>
    </div>
  );
};
