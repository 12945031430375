import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { getCustomFieldTypeIcon, getCustomFieldTypeSubtitle } from '../../utils';
import { BooleanInput, Tooltip } from '@client/shared/toolkit';
import {
  UserDefinedFieldDefinitionReadModel,
} from '@client/shared/api';

interface SelectUserDefinedFieldsListItemProps {
  field: UserDefinedFieldDefinitionReadModel
  selected: boolean
  onChange: (val: boolean) => void
}
export const SelectUserDefinedFieldsListItem = (props: SelectUserDefinedFieldsListItemProps) => {
  const {
    field,
    selected,
    onChange
  } = props

  const icon = useMemo(() => {
    const IconComponent = getCustomFieldTypeIcon(field.fieldType)
    return <IconComponent />
  }, [field.fieldType])

  const subtitle = useMemo(() => {
    return getCustomFieldTypeSubtitle(field)
  }, [field])

  const isDisabled = useMemo(() => {
    if (field.isVisible && field.visibilityType === 'AlwaysVisible') return true
    if (field.isVisible && field.visibilityType === 'VisibleOnAllComponentsAfterManualSelection' && field.isMarkedVisible) return true
    return false
  }, [field])

  return (
    <BooleanInput
      icon={icon}
      label={field.name}
      name={field.name}
      subLabel={subtitle}
      value={selected}
      onChange={onChange}
      disabled={isDisabled}
    />
  )
}

interface SelectUserDefinedFieldsListProps extends PropsWithChildren {
  updateSelectedCustomFields: (fields: UserDefinedFieldDefinitionReadModel[]) => void
  selectedCustomFields: UserDefinedFieldDefinitionReadModel[] | null
}
export const SelectUserDefinedFieldsList = (props: SelectUserDefinedFieldsListProps) => {
  const {
    updateSelectedCustomFields,
    selectedCustomFields
  } = props

  const handleUserDefinedFieldsChange = useCallback((field: UserDefinedFieldDefinitionReadModel) => {
    if (selectedCustomFields) {
      const updatedCustomFields = [...selectedCustomFields]
      const foundIndex = updatedCustomFields.findIndex((udf) => udf.id === field.id)
      if (foundIndex >= 0) {
        const updatedFoundField = {...updatedCustomFields[foundIndex]}
        updatedFoundField.isVisible = !updatedFoundField.isVisible
        updatedCustomFields[foundIndex] = updatedFoundField
      }
      updateSelectedCustomFields(updatedCustomFields)
    }
  }, [selectedCustomFields, updateSelectedCustomFields])

  return (
    <>
      {selectedCustomFields?.map((field, i) => {
        if (field.description) {
          return (
            <Tooltip
              key={`cost-element-setting-${field.name}-${i}`}
              placement="bottom"
              className="relative"
              label={
                <SelectUserDefinedFieldsListItem
                  field={field}
                  selected={field.isVisible}
                  onChange={() => handleUserDefinedFieldsChange(field)}
                />
              }
            >
              <div className="w-full">
                {field.description}
              </div>
            </Tooltip>
          )
        }
        return (
          <SelectUserDefinedFieldsListItem
            key={`cost-element-setting-${field.name}-${i}`}
            field={field}
            selected={field.isVisible}
            onChange={() => handleUserDefinedFieldsChange(field)}
          />
        )
      })}
    </>
  )
}
