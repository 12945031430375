import { parseISO, formatDistanceToNow } from 'date-fns';
import { isEmpty } from './isEmpty';

interface HumanizeJsonDateOptions {
  placeHolder?: string;
}

/**
 * Takes a JSON date string and returns easily readable date formats. (e.g. 2 days ago)
 * @param date - The JSON date string to be humanized.
 * @param options - The options to use when humanizing the date.
 * @return {string} The humanized date.
 */
export const humanizeJsonDate = (
  date: Date | string | undefined,
  { placeHolder = '' }: HumanizeJsonDateOptions = {}
) => {
  if (date == null || isEmpty(date)) return placeHolder;

  let parsedDate;

  if (typeof date === 'string') {
    parsedDate = parseISO(date);
  } else {
    parsedDate = date;
  }

  return formatDistanceToNow(parsedDate);
};
