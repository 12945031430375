import { DealContractItemReadModel, ContractItemCategory, DealReadModel } from '@client/shared/api';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useState } from 'react';
import { getCalculationTargetValueLabel } from '../../utils';
import { DealContractItemSlideOver } from './DealContractItemSlideOver';
import {
  HandGearIcon,
  HooverIcon,
  HouseMoneyIcon,
  HouseThermostatIcon,
  SlideOver,
  TaxonomyParkingIcon,
  ThunderboltIcon
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { formatUnit } from '@client/shared/utilities';
import { FormattedCurrency } from '@client/project/shared';
// import { useLoadedProjectUnitSystemSymbol } from '@client/project/store';

interface DealContractItemRowProps {
  deal: DealReadModel;
  contractItem: DealContractItemReadModel;
  isReadOnly:boolean;
}

const getBorderColor = (category: ContractItemCategory | undefined) => {
  if (!category) return;

  switch (category) {
    case 'Rent':
      return 'border-green-800';
    case 'Sell':
      return 'border-green-800';
    case 'Utility':
      return 'border-green-500';
    case 'Additional':
      return 'border-blue-800';
    case 'Special':
      return 'border-blue-800';
    default:
      return 'border-green-800';
  }
};

const getContractItemIcon = (item: DealContractItemReadModel) => {
  const itemName = item.name.toLowerCase();

  switch (item.category) {
    case 'Sell':
    case 'Rent':
      return item.calculation === 'Amount' ? <TaxonomyParkingIcon className="w-6" /> : <HouseMoneyIcon className="w-6" />
    case 'Utility':
      if (itemName.includes('heiz') || itemName.includes('heat')) return <HouseThermostatIcon className="w-6" />
      if (itemName.includes('strom') || itemName.includes('electricity')) return <ThunderboltIcon className="w-6" />
      return <HandGearIcon className="w-6" />
    case 'Additional':
      return <HooverIcon className="w-6" />
    case 'Special':
      return <HandGearIcon className="w-6" />
    default:
      return <HandGearIcon className="w-6" />
  }
};

export const DealContractItemRow = ({ contractItem, deal, isReadOnly }: DealContractItemRowProps) => {
  const { t } = useTranslation();

  // const unitSystem = useLoadedProjectUnitSystemSymbol();

  const isLocked = deal.status === 'Closed';
  const [showEditForm, setShowEditForm] = useState<boolean>(false);

  return (
    <div
      className={classNames(
        'flex w-full bg-white hover:bg-blue-100 shadow-sm h-14 border-l-8 rounded text-sm',
        getBorderColor(contractItem.category)
      )}
    >
      <div className="w-14 flex justify-center items-center">
        {getContractItemIcon(contractItem)}
      </div>

      <div className="w-4/12 flex items-center ml-2">
        <div>
          <div className="text-gray-400 text-xs font-bold">
            {getCalculationTargetValueLabel(contractItem.calculation)}
          </div>
          <div className="truncate font-bold">{contractItem.name}</div>
        </div>
      </div>

      <div className="border-l-2 border-slate-300 m-4" />

      <div className="w-2/12 flex items-center justify-end">
        {contractItem.calculation !== 'Value' && (
          <>
            {contractItem.calculation === 'Amount' && (
              <div>
                <div
                  className={classNames('truncate font-semibold text-end', {
                    'text-red-600': contractItem.hasSpaceConflict,
                  })}
                >
                  {contractItem.value.value}
                </div>
                <div className="text-gray-400 text-xs font-bold">{t('projectRent.dealContractItemPieces')}</div>
              </div>
            )}
            {contractItem.calculation === 'RentalSpace' && (
              <div>
                <div
                  className={classNames('truncate font-semibold text-end', {
                    'text-red-600': contractItem.hasSpaceConflict,
                  })}
                >
                  {formatUnit(contractItem.value.value, contractItem.value.unit)}
                </div>
                <div className="text-gray-400 text-xs font-bold">
                  {deal.type === 'Rent' && t('projectRent.dealContractItemRentalArea')}
                  {deal.type === 'Sell' && t('projectRent.dealContractItemArea')}
                </div>
              </div>
            )}
            {contractItem.calculation === 'CommonSpace' && (
              <div>
                <div
                  className={classNames('truncate font-semibold text-end', {
                    'text-red-600': contractItem.hasSpaceConflict,
                  })}
                >
                  {formatUnit(contractItem.value.value, contractItem.value.unit)}
                </div>
                <div className="text-gray-400 text-xs font-bold">{t('projectRent.dealContractItemCommonArea')}</div>
              </div>
            )}
          </>
        )}
      </div>

      <div className="w-2/12 flex items-center justify-end">
        {contractItem.calculation !== 'Value' && (
          <div>
            <div className="truncate font-semibold text-end"><FormattedCurrency amount={contractItem.factor.value} /></div>
            {contractItem.calculation === 'Amount' && (
              <div className="text-gray-400 text-xs font-bold">{t('projectRent.perUnit')}</div>
            )}
            {contractItem.calculation !== 'Amount' && (
              <div className="text-gray-400 text-xs font-bold">{t('projectRent.perM2')}</div>
            )}
          </div>
        )}
      </div>

      <div className="w-2/12 flex flex-col items-end justify-center">
        <div className="truncate font-semibold text-end">{contractItem.vat}%</div>
        <div className="text-gray-400 text-xs font-bold">{t('projectRent.dealContractItemTaxRate')}</div>
      </div>

      <div className="w-2/12 flex items-center justify-end">
        <div>
          <div
            className={classNames('truncate font-semibold text-end', { 'text-red-600': contractItem.hasSpaceConflict })}
          >
            <FormattedCurrency amount={(contractItem.value.value ?? 0) * (contractItem.factor.value ?? 0)} />
          </div>
          <div className="text-gray-400 text-xs font-bold">
            {deal.type === 'Rent' && t('projectRent.dealContractItemMonthly')}
            {deal.type === 'Sell' && t('projectRent.dealContractItemSum')}
          </div>
        </div>
      </div>

      <div className="w-20 flex items-center justify-end mr-3 text-gray-500 hover:text-gray-800">
        {!isLocked && (
          <button onClick={() => setShowEditForm(true)}>
            <EllipsisVerticalIcon className="w-6 h-6" />
          </button>
        )}
      </div>

      <SlideOver isOpen={showEditForm} onClose={() => setShowEditForm(false)}>
        <DealContractItemSlideOver
          isReadOnly={isReadOnly}
          deal={deal}
          dealId={deal.id}
          contractItem={contractItem}
          category={contractItem.category}
          isAddMode={false}
          onClose={() => setShowEditForm(false)}
        />
      </SlideOver>
    </div>
  );
};
