import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Portal, Transition } from '@headlessui/react';
import { BaseSelectOption, SelectedCustomIcon, SortDownIcon, UnselectedCustomIcon } from '@client/shared/toolkit';
import { Fragment, useEffect, useId, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { usePopper } from 'react-popper';
import { isEmpty } from '@client/shared/utilities';

interface DatevMappingHeaderMenuProps {
  label: string;
  value: string;
  options: BaseSelectOption[];
  onChange: (selected: string) => void;
  onBlur?: () => void;
  className?: string;
  disabled?: boolean;
  handlePopoverVisibility?: (isOpen: boolean) => void;
}

export const DatevMappingHeaderMenu = ({
  className,
  disabled,
  onChange,
  onBlur,
  options,
  value,
  handlePopoverVisibility,
  label,
}: DatevMappingHeaderMenuProps) => {
  const inputId = useId();
  const selectedOption = useMemo(() => {
    let foundOption: BaseSelectOption | undefined;
    options.forEach((option) => {
      if (!foundOption) {
        if (option.value === value) {
          foundOption = option;
        } else if (option.options?.length) {
          foundOption = option.options.find((childOption) => childOption.value === value);
        }
      }
    });
    return foundOption;
  }, [options, value]);
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  useEffect(() => {
    if (handlePopoverVisibility) {
      handlePopoverVisibility(!!popperElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperElement]);

  const listOptionsWidth = targetElement?.scrollWidth;

  return (
    <div className={className}>
      <div className="w-full relative h-14 bg-white">
        <Listbox value={value} onChange={(item) => onChange(item)} disabled={disabled} as="div" onBlur={onBlur}>
          {({ open }) => (
            <>
              <div ref={setTargetElement}>
                <ListboxButton
                  className={classNames('w-full relative h-14 px-3 flex flex-row bg-gray-200 outline-none peer')}
                >
                  <div className={classNames('relative flex-grow truncate h-full flex items-center')}>
                    <div
                      className={classNames(
                        'fake-mt w-full text-lg flex justify-start appearance-none focus:outline-none bg-transparent font-bold text-left truncate pr-4',
                        {
                          'text-gray-500 cursor-not-allowed pt-5': disabled,
                        },
                      )}
                      title={selectedOption?.label}
                    >
                      {selectedOption?.label}
                    </div>
                    {label && (
                      <label
                        htmlFor={inputId}
                        className={classNames(
                          'absolute top-0 left-0 right-0 text-lg duration-200 origin-0 text-gray-600 select-none transform truncate pr-4 text-left',
                          {
                            'pt-3 mt-[3px]': isEmpty(value),
                            'pt-4 -mt-px text-xs -translate-y-3': !isEmpty(value) ,
                          },
                        )}
                      >
                        {label}
                      </label>
                    )}
                  </div>
                  <div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SortDownIcon className="w-5 h-5 text-gray-800" />
                    </div>
                  </div>
                </ListboxButton>
                <div
                  className={classNames(
                    'absolute bottom-0 h-0.5 bg-black left-0 right-0 duration-200 transition-opacity peer-focus:opacity-100',
                    {
                      'opacity-0': !open,
                    },
                  )}
                />
              </div>

              <Portal>
                <div
                  ref={popperElRef}
                  style={{ ...styles.popper, width: listOptionsWidth }}
                  {...attributes.popper}
                  className="z-50"
                >
                  <Transition
                    show={open}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    beforeEnter={() => setPopperElement(popperElRef.current)}
                    afterLeave={() => setPopperElement(null)}
                  >
                    <ListboxOptions className="origin-top-right bg-white border border-t-0 border-gray-200 divide-y divide-gray-100 rounded-bl-lg rounded-br-lg shadow-lg outline-none max-h-72 overflow-y-auto">
                      {options.map((option, index) => (
                        <Fragment key={`select-option-${index}`}>
                          <ListboxOption
                            className={({ focus }) =>
                              classNames(
                                'select-none relative py-3 px-3 text-lg',
                                { 'cursor-pointer text-gray-900': !focus && !option.disabled },
                                { 'cursor-pointer text-gray-900 bg-sky-100': focus && !option.disabled },
                                { 'cursor-not-allowed text-gray-400 bg-gray-100/50': option.disabled },
                              )
                            }
                            value={option.value}
                            disabled={option.disabled}
                          >
                            {({ selected }) => (
                              <div className="flex gap-2 items-center justify-between">
                                <div className="flex gap-2 items-center relative truncate flex-1">
                                  {option.icon}
                                  <span
                                    className={classNames('block truncate', {
                                      'font-medium pr-5': selected,
                                    })}
                                    title={option.label}
                                  >
                                    {option.label}
                                  </span>
                                </div>
                                <div className="flex gap-2 items-center flex-none">
                                  {selected ? (
                                    <SelectedCustomIcon className="w-5 h-5" />
                                  ) : (
                                    <UnselectedCustomIcon className="w-5 h-5" />
                                  )}
                                  {option.info}
                                </div>
                              </div>
                            )}
                          </ListboxOption>
                        </Fragment>
                      ))}
                    </ListboxOptions>
                  </Transition>
                </div>
              </Portal>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
};
