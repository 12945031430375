import {
  TimelineValueReadModel,
  useApiPostCalculateRecurringPaymentDistributionMutation,
  PaymentFrequency,
} from '@client/shared/api';
import { useEffect, useRef, useState } from 'react';
import { formatDate, formatNullableDateOnly } from '@client/shared/utilities';
import { Button, InitiateMoneyTransferIcon, LoadingIndicator, Modal, NumberInput, PercentageIcon, TimeLimitIcon } from '@client/shared/toolkit';
import { useLoadedProjectId, useLoadedVariant } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FormattedCurrency } from '@client/project/shared';

interface RecurringPaymentDistributionModalProps {
  amount: number | null;
  unitPrice: number | null;
  index: number | null;
  indexDate: Date | undefined;
  start: Date | undefined;
  end: Date | undefined;
  frequency: PaymentFrequency;
  onClose: () => void;
  selectedDate?: string;
}

export const RecurringPaymentDistributionModal = ({
  amount,
  unitPrice,
  index,
  indexDate,
  start,
  end,
  frequency,
  onClose,
  selectedDate,
}: RecurringPaymentDistributionModalProps) => {
  const { t } = useTranslation();

  const [distributionValues, setDistributionValues] = useState<TimelineValueReadModel[]>([]);

  const loadedCalculationModel = useLoadedVariant();
  const loadedCalculationModelId = loadedCalculationModel.data?.calculationModel.modelMetadata.id ?? 'unset';
  const loadedProjectId = useLoadedProjectId() ?? 'unset';
  const startDate = formatNullableDateOnly(start) ?? '';
  const endDate =
    formatNullableDateOnly(end) ??
    loadedCalculationModel.data?.calculationModel.modelMetadata.calculationModelEndDate ??
    '';
  const distributedAmount = distributionValues.reduce((x, y) => (x = x + y.value), 0);
  const [getRecurringPaymentDistribution, {isLoading}] = useApiPostCalculateRecurringPaymentDistributionMutation();
  const rowsRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (!amount || !unitPrice || !start) {
      setDistributionValues([]);
      return;
    }

    const fetchRecurringPayment = async () => {
      try {
        const values = await getRecurringPaymentDistribution({
          projectId: loadedProjectId,
          calculationModelId: loadedCalculationModelId,
          body: {
            start: startDate,
            end: endDate,
            unitPrice: unitPrice,
            amount: amount,
            rentIndex: index,
            rentIndexDate: formatNullableDateOnly(indexDate),
            paymentFrequency: frequency,
          },
        }).unwrap();
        setDistributionValues(values ?? []);
      } catch (error) {
        setDistributionValues([]);
        console.log(error);
      }
    };

    fetchRecurringPayment();
  }, [
    getRecurringPaymentDistribution,
    loadedProjectId,
    loadedCalculationModelId,
    start,
    startDate,
    endDate,
    amount,
    unitPrice,
    index,
    indexDate,
    frequency,
  ]);

  const getTitle = () => {
    switch (frequency) {
      case 'Monthly':
        return t('projectCalculate.monthlyRecurringPaymentTitle');
      case 'Quarterly':
        return t('projectCalculate.quaterlyRecurringPaymentTitle');
      case 'Yearly':
        return t('projectCalculate.yearlyRecurringPaymentTitle');
      default:
        return t('projectCalculate.monthlyRecurringPaymentTitle');
    }
  };



  useEffect(() => {
    if (typeof selectedDate !== 'undefined') {
      setTimeout(() => {
        const selectedIdx = distributionValues.findIndex(
          (value) => new Date(selectedDate).getMonth() === new Date(value.periodStart).getMonth() && new Date(selectedDate).getFullYear() === new Date(value.periodStart).getFullYear(),
        );
        rowsRef.current[selectedIdx]?.scrollIntoView({block: 'center'})

      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, rowsRef.current, distributionValues]);

  return (
    <>
      <Modal.Header title={t('projectCalculate.ValueDistributionTitel')} description={getTitle()} />
      <Modal.Content className="pb-3">
        {isLoading && <LoadingIndicator text={t('common.loading')} mode='overlay' />}
        <div className="flex flex-col overflow-y-auto mb-2">
          {distributionValues.map((distributionValue, index) => (
            <div
              key={index}
              className={classNames('flex flex-row text-lg border-b', {
                'border-l-4 border-l-primary': selectedDate && new Date(selectedDate).getMonth() === new Date(distributionValue.periodStart).getMonth()  && new Date(selectedDate).getFullYear() === new Date(distributionValue.periodStart).getFullYear(),
              })}
              ref={el => rowsRef.current[index] = el}
            >
              <div className="flex flex-row w-5/12 border-r pl-3 bg-white items-center">
                <TimeLimitIcon className="w-5 h-5" />
                <div className="flex-col pl-2 justify-center">
                  <div className="text-xs">{t('projectCalculate.DistributionDateColumn')}</div>
                  {formatDate(distributionValue.periodStart, { includeDay: false })}
                </div>
              </div>
              <div className="flex w-7/12">
                <NumberInput
                  className="w-full border-r"
                  disabled={true}
                  label={t('projectCalculate.DistributionPercentage')}
                  icon={<PercentageIcon />}
                  value={distributionValue.percentage * 100}
                  onChange={() => {
                    /*Edit is currently disabled => ToDo: exchange with label that has editor layout*/
                  }}
                  decimalScale={3}
                />
                <NumberInput
                  className="w-full"
                  disabled={true}
                  label={t('projectCalculate.DistributionValueColumn')}
                  icon={<InitiateMoneyTransferIcon />}
                  value={distributionValue.value}
                  onChange={() => {
                    /*Edit is currently disabled => ToDo: exchange with label that has editor layout*/
                  }}
                  decimalScale={3}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-row mr-4 justify-end items-end">
          <div className="text-2xl font-bold"><FormattedCurrency amount={distributedAmount} options={{ maxDigits: 2 }} /></div>
        </div>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={onClose} variant="secondary">
          {t('common.cancel')}
        </Button>
      </Modal.Controls>
    </>
  );
};
