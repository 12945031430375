import { SearchInput, TitleBar, useDocumentTitle } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useLoadedProject } from '@client/project/store';
import { useState } from 'react';
import { TasksContainer } from '../components/TasksContainer';

export const TasksRoute = () => {
  const { t } = useTranslation();
  const { data: loadedProject } = useLoadedProject();

  useDocumentTitle({ title: t('projectTasks.routeTitle') });

  const [searchValue, setSearchValue] = useState('')

  return (
    <>
      <TitleBar
        title={loadedProject?.project?.payload?.name ?? t('projectTasks.routeTitle')}
        subTitle={t('projectTasks.routeSubTitle')}
      >
        <SearchInput
          value={searchValue}
          handleSearch={(val) => setSearchValue(val)}
          className="max-w-[428px] text-gray-400 bg-gray-100"
          bgColor="bg-gray-100"
          size='small'
        />
      </TitleBar>
      <div className="flex-grow flex flex-col overflow-hidden">
        <div className="px-4 py-8 md:px-8 flex flex-col flex-grow overflow-y-auto">
          <TasksContainer searchValue={searchValue} />
        </div>
      </div>
    </>
  )
}

