import {
  BudgetAssignmentReadModel, CostCatalogElementDto
} from '@client/shared/api';
import { PaidSearchIcon, SlideOverTitle } from '@client/shared/toolkit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CostElementSelect } from '../CostElementSelect';

interface InvoiceBudgetAssignmentProps {
  budgetAssignment?: BudgetAssignmentReadModel | null;
  canBeBudgeted?: boolean;
  disabled?: boolean;
  showTitle?: boolean;
  updateInvoiceBudgetAssignment: (costElementId: string | undefined) => void;
  setCostElement?: (costElement?: CostCatalogElementDto) => void
  handlePopoverVisibility?: (isOpen: boolean) => void
}

export const InvoiceBudgetAssignment = ({
  budgetAssignment,
  canBeBudgeted = true,
  disabled = false,
  updateInvoiceBudgetAssignment,
  showTitle,
  handlePopoverVisibility
}: InvoiceBudgetAssignmentProps) => {
  const { t } = useTranslation();

  const [costElementId, setCostElementId] = useState<string>(
    budgetAssignment ? budgetAssignment.costElementId : 'None',
  );

  useEffect(() => {
    updateInvoiceBudgetAssignment(costElementId !== 'None' ? costElementId : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costElementId]);

  return (
    <>
      {showTitle && (
        <SlideOverTitle title={t('projectContract.budgeting')} />
      )}
      <div className="bg-white">
        <div className="divide-gray-100 divide-y">
          <CostElementSelect
            label={t('projectContract.budget')}
            nonSelectedLabel={t('projectContract.noBugdetElementSelected')}
            icon={<PaidSearchIcon className="h-6 w-6" />}
            costElementId={costElementId}
            setCostElementId={(value) => setCostElementId(value)}
            disabled={disabled}
            handlePopoverVisibility={handlePopoverVisibility}
            addNoneSelectedOption={false}
          />
        </div>
        {!canBeBudgeted && (
          <div className="w-full flex justify-center text-red-400 italic text-xs">
            {t('projectContract.connectedElementBudgeted')}
          </div>
        )}
      </div>
    </>
  );
};
