import { useLoadedProjectId } from '@client/project/store';
import { DealContractItemReadModel, DealType, useApiDeleteRentDealContractItemMutation, useApiDeleteSellDealContractItemMutation } from '@client/shared/api';
import { ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { Trans, useTranslation } from 'react-i18next';

interface DealRentItemDeleteModalProps extends ModalOnCloseProps {
  dealId: string;
  type: DealType;
  item: DealContractItemReadModel;
}

export const DealContractItemDeleteModal = ({ dealId, type, item, onClose }: DealRentItemDeleteModalProps) => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();

  const [deleteRentContractItem, { isLoading : isLoadingRent }] = useApiDeleteRentDealContractItemMutation();
  const [deleteSellContractItem, { isLoading : isLoadingSell }] = useApiDeleteSellDealContractItemMutation();

  const handleDelete = async () => {
    switch (type){
      case 'Rent':
        try {
          await safeMutation(
            deleteRentContractItem,
            {
              projectId: projectId ?? 'unset',
              dealId: dealId,
              contractItemId: item.id,
            },
            isLoadingRent
          );
    
          onClose(true);
        } catch {
          /* left blank */
        }
        break;
      case 'Sell':
        try {
          await safeMutation(
            deleteSellContractItem,
            {
              projectId: projectId ?? 'unset',
              dealId: dealId,
              contractItemId: item.id,
            },
            isLoadingSell
          );
    
          onClose(true);
        } catch {
          /* left blank */
        }        
        break;
    }
  };

  return (
    <ConfirmModal
      title={`${t('common.delete')} ${item.name}`}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      {(isLoadingRent || isLoadingSell) && <LoadingIndicator text={t('projectRent.contractItemDeleteLoading') ?? ''} mode="overlay" />}
      <Trans i18nKey="projectRent.dealContractDeleteConfirmation" values={{ entity: item.name }}>
        <div>
          Möchten Sie <span className="font-bold">Entity</span> wirklich löschen?
        </div>
        <div className="pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
