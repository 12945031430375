import { Routes, Route, Navigate } from 'react-router-dom';
import { TaxonomyRoute } from '@client/project/taxonomy';
import { DashboardRoute } from '@client/project/dashboard';
import { VariantsRoute } from '@client/project/variants';
import { DealRoute } from '@client/project/rentsell';
import { CalculateRoute }  from '@client/project/calculate';
import { ReportingRoute } from '@client/project/reporting';
import { ContractRoute } from '@client/project/contract';
import { ControlRoute } from '@client/project/control';
import { ProtectedRoute, ROUTES_CONFIG } from '@client/shared/permissions';
import { UploadRoute } from '@client/project/upload';
import { TasksRoute } from '@client/project/tasks';
import { useLoadedProjectId } from '@client/project/store';

/**
 * ProjectRoute is a module entrypoint (we are talking about one specific project)
 * It tries to ensure valid data at a top point and keep sub routes from having to deal
 * with query parameters / path segments
 */
export const ProjectRoutes = () => {
  const loadedProjectId = useLoadedProjectId();
  return (
    <Routes>
      <Route
        path={`${ROUTES_CONFIG.PROJECT_DASHBOARD.name}/*`}
        element={
          <ProtectedRoute
            routeConfig={ROUTES_CONFIG.PROJECT_DASHBOARD}
            projectId={loadedProjectId}
          >
            <DashboardRoute />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${ROUTES_CONFIG.PROJECT_TAXONOMY.name}/*`}
        element={
          <ProtectedRoute
            routeConfig={ROUTES_CONFIG.PROJECT_TAXONOMY}
            projectId={loadedProjectId}
          >
            <TaxonomyRoute />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES_CONFIG.PROJECT_CALCULATE.name}
        element={
          <ProtectedRoute
            routeConfig={ROUTES_CONFIG.PROJECT_CALCULATE}
            projectId={loadedProjectId}
          >
            <CalculateRoute />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES_CONFIG.PROJECT_VARIANTS.name}
        element={
          <ProtectedRoute
            routeConfig={ROUTES_CONFIG.PROJECT_VARIANTS}
            projectId={loadedProjectId}
          >
            <VariantsRoute />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${ROUTES_CONFIG.PROJECT_RENT.name}/*`}
        element={
          <ProtectedRoute
            routeConfig={ROUTES_CONFIG.PROJECT_RENT}
            projectId={loadedProjectId}
          >
            <DealRoute type="Rent" />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${ROUTES_CONFIG.PROJECT_SELL.name}/*`}
        element={
          <ProtectedRoute
            routeConfig={ROUTES_CONFIG.PROJECT_SELL}
            projectId={loadedProjectId}
          >
            <DealRoute type="Sell" />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${ROUTES_CONFIG.PROJECT_REPORTING.name}/*`}
        element={
          <ProtectedRoute
            routeConfig={ROUTES_CONFIG.PROJECT_REPORTING}
            projectId={loadedProjectId}
          >
            <ReportingRoute />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${ROUTES_CONFIG.PROJECT_CONTRACT.name}/*`}
        element={
          <ProtectedRoute
            routeConfig={ROUTES_CONFIG.PROJECT_CONTRACT}
            projectId={loadedProjectId}
          >
            <ContractRoute />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${ROUTES_CONFIG.PROJECT_CONTROL.name}/*`}
        element={
          <ProtectedRoute
            routeConfig={ROUTES_CONFIG.PROJECT_CONTROL}
            projectId={loadedProjectId}
          >
            <ControlRoute />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES_CONFIG.PROJECT_TASKS.name}
        element={
          <ProtectedRoute
            routeConfig={ROUTES_CONFIG.PROJECT_TASKS}
            projectId={loadedProjectId}
          >
            <TasksRoute />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES_CONFIG.PROJECT_UPLOAD.name}
        element={
          <ProtectedRoute
            routeConfig={ROUTES_CONFIG.PROJECT_UPLOAD}
            projectId={loadedProjectId}
          >
            <UploadRoute />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to={ROUTES_CONFIG.PROJECT_DASHBOARD.name} replace />} />
    </Routes>
  );
};
