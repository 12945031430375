import { HintBox, ModalOnCloseProps, ConfirmModal } from '@client/shared/toolkit';
// import { useApiPostApplyCostElementForecastAsBudgetMutation } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
// import { safeMutation } from '@client/shared/utilities';
// import { useLoadedProjectId } from '@client/project/store';

interface CostElementDistributeObligoModalProps extends ModalOnCloseProps {
  elementId?: string;
  variantId?: string;
  children?: boolean
}

export const CostElementDistributeObligoModal = ({ elementId, variantId, onClose, children = false }: CostElementDistributeObligoModalProps) => {
  const { t } = useTranslation();

  // const projectId = useLoadedProjectId();
  // const [call, { isLoading }] = useApiPostApplyCostElementForecastAsBudgetMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleConfirm = async () => {
    if (elementId && variantId) {
      /* try {
        await safeMutation(
          call,
          {
            projectId: projectId ?? 'unset',
            calculationModelId: variantId,
            costElementId: elementId,
            body: {
              applyRecursively: children
            }
          },
          isLoading
        );

        onClose(true);
      } catch (e) {
        console.log(e)
      } */

      onClose(false);
    }
  };

  return (
    <ConfirmModal
      title={children ? t('projectCalculate.costElementDistributeAllObligoModalTitle') : t('projectCalculate.costElementDistributeObligoModalTitle')}
      onConfirm={handleConfirm}
      onClose={handleOnClose}
      confirmText={t('projectCalculate.rowMenu.distributeObligoTitle')}
      isLoading={false}
    >
      <HintBox hintType="warning">{t('projectCalculate.costElementDistributeObligoModalHint')}</HintBox>
    </ConfirmModal>
  );
};
