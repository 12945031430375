import {
  ApiDeleteEuriborRateProjectionApiArg,
  ApiDeleteEuriborRateProjectionApiResponse,
  ApiPostCreateEuriborRateProjectionApiArg,
  ApiPostCreateEuriborRateProjectionApiResponse,
  ApiPostUpdateEuriborRateProjectionApiArg,
  ApiPostUpdateEuriborRateProjectionApiResponse
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiEuriborRatesApiEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetEuriborRates: {
    providesTags: [ApiTagTypes.EuriborRates],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateEuriborRateProjection: {
    invalidatesTags: (
      _result: ApiPostCreateEuriborRateProjectionApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostCreateEuriborRateProjectionApiArg,
    ) => (error == null ? [ApiTagTypes.EuriborRates, ApiTagTypes.FinancingElement] : []),
  },
  apiPostUpdateEuriborRateProjection: {
    invalidatesTags: (
      _result: ApiPostUpdateEuriborRateProjectionApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostUpdateEuriborRateProjectionApiArg,
    ) => (error == null ? [ApiTagTypes.EuriborRates, ApiTagTypes.FinancingElement] : []),
  },
  apiDeleteEuriborRateProjection: {
    invalidatesTags: (
      _result: ApiDeleteEuriborRateProjectionApiResponse,
      error: FetchBaseQueryError,
      _args: ApiDeleteEuriborRateProjectionApiArg,
    ) => (error == null ? [ApiTagTypes.EuriborRates, ApiTagTypes.FinancingElement] : []),
  },
}
