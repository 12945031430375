import { DealReadModel } from '@client/shared/api';
import { getPaymentMethodValueLabel, getSecurityTypeValueLabel } from '../../utils';
import { getContractItemsColdRentSumWithoutVat } from '../DealCalculation';
import React, { useState } from 'react';
import {
  SlideOver,
  UserShieldIcon,
  DecoratedCard,
  Calendar7Icon,
  Deposit5Icon,
  TermsAndConditionsIcon,
  DateSpanIcon,
  PencilIcon
} from '@client/shared/toolkit';
import { DealRentConditionSlideOver } from './DealRentConditionSlideOver';
import { useTranslation } from 'react-i18next';
import { formatNumber, formatDate } from '@client/shared/utilities';
import { FormattedCurrency } from '@client/project/shared';

interface DealRentSecurityConditionProps {
  deal: DealReadModel;
  isReadOnly:boolean;
}

export const DealRentSecurityCondition = ({
  deal: { contractItems, dealCondition, id, status, type },
  isReadOnly
}: DealRentSecurityConditionProps) => {
  const { t } = useTranslation();

  const isLocked = status === 'Closed';
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const contractItemsColdRentWithoutVat = getContractItemsColdRentSumWithoutVat(contractItems);

  return (
    <div className="w-full">
      <DecoratedCard>
        <DecoratedCard.Header
          showActionButton={!isLocked}
          onActionClick={() => setShowEditForm(true)}
          icon={<PencilIcon />}
        >
          <Deposit5Icon className="w-6" />
          <div>
            {type === 'Rent' && t('projectRent.dealConditionLabelDeposit')}
            {type === 'Sell' && t('projectRent.dealConditionLabelPrices')}
          </div>
        </DecoratedCard.Header>
        <DecoratedCard.Content className="w-full flex items-center justify-center bg-white">
          <div className="flex w-full px-3 py-3">
            <div className="flex w-5/12 ml-4">
              <UserShieldIcon className="w-5" />
              <div className="pl-4">
                <div className="text-xs text-gray-400">{t('projectRent.dealConditionLabelSecurity')}</div>
                <div className="flex w-full justify-center">
                  <div className="inline-flex w-full">
                    <div className="text-sm font-bold"><FormattedCurrency amount={dealCondition?.securityAmount} /></div>
                    {contractItems &&
                      dealCondition &&
                      contractItemsColdRentWithoutVat !== 0 &&
                      dealCondition.securityAmount !== 0 && (
                        <div className="flex">
                          <div className="w-5 border-dotted border-b-2 border-slate-400 ml-1 mr-1 mb-1" />
                          <div className="text-sm">
                            {formatNumber(dealCondition.securityAmount / contractItemsColdRentWithoutVat, {
                              maxDigits: 1,
                            })}
                            x {t('projectRent.dealConditionLabelColdRent')}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-3/12 ml-2 mr-5">
              <div className="mt-2 mr-2 border-l-2 h-6 border-slate-300"></div>
              <DateSpanIcon className="w-5" />
              <div className="ml-2">
                <div className="text-xs text-gray-400">{t('projectRent.dealConditionLabelPaymentMethod')}</div>
                <div className="text-sm font-bold">{getPaymentMethodValueLabel(dealCondition?.paymentMethod)}</div>
              </div>
            </div>

            <div className="flex w-3/12 ml-2 mr-3">
              <div className="mt-2 mr-2 border-l-2 h-6 border-slate-300"></div>
              <Calendar7Icon className="w-5" />
              <div className="flex-col ml-2">
                <div className="text-xs text-gray-400">{t('projectRent.dealConditionLabelPaymentAppointment')}</div>

                {dealCondition && dealCondition.paymentAppointment && (
                  <div className="text-sm font-bold bg">{formatDate(dealCondition.paymentAppointment)}</div>
                )}
              </div>
            </div>

            <div className="flex w-3/12 ml-2">
              <div className="mt-2 mr-2 border-l-2 h-6 border-slate-300"></div>
              <TermsAndConditionsIcon className="w-5" />
              <div className="ml-2">
                <div className="text-xs text-gray-400">{t('projectRent.dealConditionLabelSecurityType')}</div>
                <div className="text-sm font-bold">{getSecurityTypeValueLabel(dealCondition?.securityType)}</div>
              </div>
            </div>
          </div>
        </DecoratedCard.Content>
      </DecoratedCard>

      <SlideOver isOpen={showEditForm} onClose={() => setShowEditForm(false)}>
        <DealRentConditionSlideOver
          isReadOnly={isReadOnly}
          rentCondition={dealCondition}
          type={type}
          dealId={id}
          isSecurityCondition={true}
          onClose={() => setShowEditForm(false)}
        />
      </SlideOver>
    </div>
  );
};
