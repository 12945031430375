import { DealReadModel, DealCostItemReadModel, TaxonomyType } from '@client/shared/api';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { EllipsisVerticalIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { DealCostItemSlideOver } from './DealCostItemSlideOver';
import { SlideOver } from '@client/shared/toolkit';
import { getTaxonomyIcon } from '@client/project/shared';
import { useTranslation } from 'react-i18next';
import { formatUnit } from '@client/shared/utilities';
import { useLoadedProjectUnitSystemSymbol } from '@client/project/store';

export interface DealCostItemRowProps {
  deal: DealReadModel;
  costItem: DealCostItemReadModel;
  isReadOnly:boolean;
}

export const DealCostItemRow = ({ deal, costItem, isReadOnly }: DealCostItemRowProps) => {
  const { t } = useTranslation();

  const unitSystem = useLoadedProjectUnitSystemSymbol();

  const [showEditForm, setShowEditForm] = useState<boolean>(false);

  const getBorderColor = (type: TaxonomyType | undefined) => {
    if (!type) return;

    switch (type) {
      case 'Project':
        return 'border-sky-400';
      case 'Area':
        return 'border-sky-500';
      case 'Quarter':
        return 'border-sky-600';
      case 'BuildingComplex':
        return 'border-sky-700';
      case 'Building':
        return 'border-sky-800';
      case 'Wing':
        return 'border-sky-900';
      case 'Floor':
        return 'border-blue-400';
      case 'Unit':
        return 'border-blue-500';
      case 'Room':
        return 'border-blue-600';
      case 'Zone':
        return 'border-blue-700';
      case 'Parking':
        return 'border-blue-800';
      default:
        return 'border-blue-500';
    }
  };

  const getHasCommonUsageConflict = (): boolean => {
    if (costItem.conflictingSpaceUsage.length === 0) return false;

    const usedByOthers = costItem.conflictingSpaceUsage.reduce((x, y) => (x = x + (y.partCommonSpace.value ?? 0)), 0);
    const usedByMe = costItem.partCommonSpace.value ?? 0;
    const available = costItem.taxonomyItem?.sizes.effectiveCommonSpaceValue?.value ?? 0;

    return usedByMe + usedByOthers > available;
  };

  const getHasRentUsageConflict = (): boolean => {
    if (costItem.conflictingSpaceUsage.length === 0) return false;

    const usedByOthers = costItem.conflictingSpaceUsage.reduce((x, y) => (x = x + (y.partRentalSpace.value ?? 0)), 0);
    const usedByMe = costItem.partRentalSpace.value ?? 0;
    const available = costItem.taxonomyItem?.sizes.effectiveRentalSpaceValue?.value ?? 0;

    return usedByMe + usedByOthers > available;
  };

  const hasSpaceUsageConflict = costItem.conflictingSpaceUsage.length > 0;
  const hasCommonUsageConflict = getHasCommonUsageConflict();
  const hasRentUsageConflict = getHasRentUsageConflict();
  const hasReservedConflict = costItem.conflictingReserved.length > 0;
  const hasSoldConflict = costItem.conflictingSold.length > 0;
  const hasRentConflict = costItem.conflictingRented.length > 0;
  const isBlocked = hasSoldConflict || hasRentConflict;

  const isLocked = deal.status === 'Closed';

  const icon = useMemo(() => {
    const IconComponent = getTaxonomyIcon(costItem.taxonomyItem?.itemType)
    return <IconComponent className="w-8 h-8" />
  }, [costItem.taxonomyItem?.itemType])

  return (
    <div
      className={classNames(
        'flex w-full bg-white hover:bg-blue-100 shadow-sm cursor-pointer h-14 border-l-8 rounded text-sm',
        getBorderColor(costItem.taxonomyItem?.itemType)
      )}
    >
      <div className="w-1/12 flex justify-center items-center">
        {icon}
      </div>

      <div className="w-6/12 flex items-center">
        <div>
          <div className="text-gray-400 text-xs font-bold">{costItem.taxonomyItem?.itemType}</div>
          <div className="truncate font-bold">{costItem.taxonomyItem?.itemName}</div>
        </div>
        {hasSpaceUsageConflict && (
          <span className="inline-flex items-center rounded-sm px-2 text-[12px] text-white bg-yellow-400/60 ml-3 align-bottom">
            <ExclamationTriangleIcon className="w-5 h-5 mr-1" />
            {unitSystem}
          </span>
        )}
        {hasReservedConflict && (
          <span className="inline-flex items-center rounded-sm px-2 text-[12px] text-white bg-yellow-400/60 ml-3 align-bottom">
            <ExclamationTriangleIcon className="w-5 h-5 mr-1" />
            {t('projectRent.dealCostItemReserved')}
          </span>
        )}
        {hasSoldConflict && (
          <span className="inline-flex items-center rounded-sm px-2 text-[12px] text-white bg-red-600/60 ml-3 align-bottom">
            <ExclamationTriangleIcon className="w-5 h-5 mr-1" />
            {t('projectRent.dealCostItemSold')}
          </span>
        )}
        {hasRentConflict && (
          <span className="inline-flex items-center rounded-sm px-2 text-[12px] text-white bg-red-600/60 ml-3 align-bottom">
            <ExclamationTriangleIcon className="w-5 h-5 mr-1" />
            {t('projectRent.dealCostItemRented')}
          </span>
        )}
      </div>

      <div className="border-l-2 border-slate-300 my-4 mx-4" />

      <div className="w-5/12 flex items-center ">
        {costItem.taxonomyItem?.itemType !== 'Parking' && (
          <div className="flex w-full flex-row">
            <div className="flex w-6/12">
              <div>
                <div className="flex">
                  <div
                    className={classNames(
                      'truncate font-semibold',
                      { 'text-yellow-400': !isBlocked && hasCommonUsageConflict },
                      { 'text-red-600 line-through': isBlocked }
                    )}
                  >
                    {costItem.partCommonSpace.value}
                  </div>
                  <div className="truncate font-semibold">
                    &nbsp;/&nbsp;{formatUnit(costItem.taxonomyItem?.sizes.commonSpace?.value, unitSystem)}
                  </div>
                </div>
                <div className="text-gray-400 text-xs font-bold">{t('projectRent.dealCostItemCommonArea')}</div>
              </div>
            </div>
            <div className="flex w-6/12">
              <div>
                <div className="flex">
                  <div
                    className={classNames(
                      'truncate font-semibold',
                      { 'text-yellow-400': !isBlocked && hasRentUsageConflict },
                      { 'text-red-600 line-through': isBlocked }
                    )}
                  >
                    {costItem.partRentalSpace.value}
                  </div>
                  <div className="truncate font-semibold">
                    &nbsp;/&nbsp;{formatUnit(costItem.taxonomyItem?.sizes.effectiveRentalSpaceValue?.value, unitSystem)}
                  </div>
                </div>
                <div className="text-gray-400 text-xs font-bold">
                  {deal.type === 'Rent' && t('projectRent.dealCostItemRentalArea')}
                  {deal.type === 'Sell' && t('projectRent.dealCostItemArea')}
                </div>
              </div>
            </div>
          </div>
        )}
        {costItem.taxonomyItem?.itemType === 'Parking' && (
          <div className="flex w-full flex-row">
            <div className="flex w-6/12" />
            <div className="flex w-6/12">
              <div>
                <div className="flex">
                  <div className={classNames('truncate font-semibold', { 'text-red-600 line-through': isBlocked })}>
                    {costItem.partParkingAmount}
                  </div>
                  <div className="truncate font-semibold">
                    &nbsp;/&nbsp;{costItem.taxonomyItem?.sizes.amount?.value} {t('projectTaxonomy.buildingPieces')}
                  </div>
                </div>
                <div className="text-gray-400 text-xs font-bold">{t('projectRent.dealCostItemParkingSpaces')}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex items-center justify-end mr-2 ml-2 text-gray-500 hover:text-gray-800">
        {!isLocked && (
          <button onClick={() => setShowEditForm(true)}>
            <EllipsisVerticalIcon className="w-6 h-6" />
          </button>
        )}
      </div>

      <SlideOver isOpen={showEditForm} onClose={() => setShowEditForm(false)}>
        <DealCostItemSlideOver deal={deal} costItem={costItem} onClose={() => setShowEditForm(false)} isReadOnly={isReadOnly} />
      </SlideOver>
    </div>
  );
};
