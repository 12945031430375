import React, { Ref, forwardRef, useImperativeHandle } from 'react';
import { CatalogEditingProps, SaveCatalogChanges } from './CatalogEditingModal';
import { EarningsCatalogElementReadModel, useApiGetEarningsCatalogQuery } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { CatalogEditingRows } from './CatalogEditingRows';
import { DepositIcon, LoadingIndicator } from '@client/shared/toolkit';

export const EarningsCatalogEditing = forwardRef(({ catalog }: CatalogEditingProps, ref: Ref<SaveCatalogChanges>) => {
  const { data, isFetching } = useApiGetEarningsCatalogQuery({ id: catalog.id });
  const elements: EarningsCatalogElementReadModel[] = data?.earningsCatalog.elements ?? [];
  const rootNodes = elements.filter((x) => !x.parentId);
  const { t } = useTranslation()
  useImperativeHandle(ref, () => ({ saveChanges }));

  const saveChanges = (): boolean => {
    console.log('saving');
    return true;
  };

  return (
    <>
      {isFetching && <LoadingIndicator text={t('app.settingsLoadingCatalogElements')} />}
      <CatalogEditingRows
        title={t('projectCalculate.calculateEarningsTitle')}
        icon={<DepositIcon />}
        bgColor="bg-earnings"
        catalog={catalog}
        elements={rootNodes}
        type="Earnings"
      />
    </>
  );
});
