import { PermissionScope, ProbisPermission } from "@client/shared/api";
import { i18n } from "@client/shared/utilities";

export type ProbisPermissionState = 
    'Granted' | 
    'New' | 
    'Deleted' | 
    'Unset';

export type ProbisPermissionStatus = {
    permission: ProbisPermission;
    state: ProbisPermissionState;
};

export type PermissionGroupDefinition = {
    group : string,
    readPermission? : ProbisPermission,
    writePermission? : ProbisPermission,
    deletePermission? : ProbisPermission
    executePermission? : ProbisPermission
}

export type PermissionCategoryDefinition = {
    category : string,
    groups : PermissionGroupDefinition[]
}

export const getNewState = (state: ProbisPermissionState): ProbisPermissionState => {
  switch (state) {
    case 'Granted':
      return 'Deleted';
    case 'New':
      return 'Unset';
    case 'Deleted':
      return 'Granted';
    case 'Unset':
      return 'New';
  }
};

export const getNewStateWithSelectAll = (selectedAll: boolean, state: ProbisPermissionState): ProbisPermissionState => {
  switch (state) {
    case 'Granted':
      return selectedAll
        ? 'Granted'
        : 'Deleted';
    case 'New':
      return selectedAll
        ? 'New'
        : 'Unset';
    case 'Deleted':
      return selectedAll
        ? 'Granted'
        : 'Deleted';
    case 'Unset':
      return selectedAll
        ? 'New'
        : 'Unset';
  }
};

export const getPermissionCategories = (scope : PermissionScope) : PermissionCategoryDefinition[] => {
    switch (scope){
        case "Project":
            return [
                {
                    category : i18n.t('auth.ProjectPermissionCategory'),
                    groups : [
                        {
                            group: i18n.t('auth.ProjectPermissionGroup'),
                            readPermission: 'PROJECT_READ',
                            writePermission: 'PROJECT_WRITE',
                            deletePermission: 'PROJECT_DELETE'
                        },
                        {
                          group: i18n.t('auth.PlotAndTaxonomyPermissionGroup'),
                          readPermission: 'PLOT_AND_TAXONOMY_READ',
                          writePermission: 'PLOT_AND_TAXONOMY_WRITE',
                          deletePermission: 'PLOT_AND_TAXONOMY_DELETE'
                      }                        
                    ]
                },
                {
                    category : i18n.t('auth.CalculatePermissionCategory'),
                    groups : [
                        {
                            group: i18n.t('auth.CostsPermissionGroup'),
                            readPermission: 'COSTS_READ',
                            writePermission: 'COSTS_WRITE',
                            deletePermission: 'COSTS_DELETE'
                        },
                        {
                            group: i18n.t('auth.EarningsPermissionGroup'),
                            readPermission: 'EARNINGS_READ',
                            writePermission: 'EARNINGS_WRITE',
                            deletePermission: 'EARNINGS_DELETE'
                        },
                        {
                            group: i18n.t('auth.RisksPermissionGroup'),
                            readPermission: 'RISKS_READ',
                            writePermission: 'RISKS_WRITE',
                            deletePermission: 'RISKS_DELETE'
                        },
                        {
                            group: i18n.t('auth.FinancingPermissionGroup'),
                            readPermission: 'FINANCING_READ',
                            writePermission: 'FINANCING_WRITE',
                            deletePermission: 'FINANCING_DELETE'
                        },                
                    ]
                },
                {
                  category : i18n.t('auth.RentAndSellPermissionCategory'),
                  groups : [
                      {
                        group: i18n.t('auth.RentPermissionGroup'),
                        readPermission: 'RENT_READ',
                        writePermission: 'RENT_WRITE',
                        deletePermission: 'RENT_DELETE'
                      },
                      {
                        group: i18n.t('auth.RentReopenPermissionGroup'),
                        executePermission: 'RENT_REOPEN'
                      },
                      {
                        group: i18n.t('auth.SellPermissionGroup'),
                        readPermission: 'SELL_READ',
                        writePermission: 'SELL_WRITE',
                        deletePermission: 'SELL_DELETE'
                      },
                      {
                        group: i18n.t('auth.SellReopenPermissionGroup'),
                        executePermission: 'SELL_REOPEN'
                      },                      
                  ]
              },
              {
                category : i18n.t('auth.ContractAndControlPermissionCategory'),
                groups : [
                    {
                      group: i18n.t('auth.ContractPermissionGroup'),
                      readPermission: 'CONTRACT_READ',
                      writePermission: 'CONTRACT_WRITE',
                      deletePermission: 'CONTRACT_DELETE'
                    },
                    {
                      group: i18n.t('auth.InvoicePermissionGroup'),
                      readPermission: 'INVOICE_READ',
                      writePermission: 'INVOICE_WRITE',
                      deletePermission: 'INVOICE_DELETE'
                    },
                    {
                      group: i18n.t('auth.InvoiceApprovePermissionGroup'),
                      executePermission: 'INVOICE_APPROVE'
                    },                      
                ]
            },              
            ];
        case "Tenant":
            return [ 
                {
                    category : i18n.t('auth.TenantPermissionCategory'),
                    groups : [
                        {
                            group: i18n.t('auth.TenantSettingsPermissionGroup'),
                            readPermission: 'TENANT_MANAGE',
                            writePermission: 'TENANT_MANAGE',
                            deletePermission: 'TENANT_MANAGE'
                        },
                        {
                            group: i18n.t('auth.InviteUserPermissionGroup'),
                            executePermission: 'TENANT_USER_CREATE'
                        },
                        {
                            group: i18n.t('auth.DeleteUserPermissionGroup'),
                            executePermission: 'TENANT_USER_DELETE'
                        },
                        {
                            group: i18n.t('auth.CopyProjectPermissionGroup'),
                            executePermission: 'TENANT_PROJECT_COPY'
                        },
                        {
                            group: i18n.t('auth.CreateProjectPermissionGroup'),
                            executePermission: 'TENANT_PROJECT_CREATE'
                        },
                        {
                            group: i18n.t('auth.DeleteProjectPermissionGroup'),
                            executePermission: 'TENANT_PROJECT_DELETE'
                        },
                    ]
                }
            ];
        default: return [];
    }
}

export function TranslatePermissionName(permission: ProbisPermission): string {
    switch (permission) {
      case 'PROJECT_READ':
        return i18n.t('permission.projectRead');
      case 'PROJECT_WRITE':
        return i18n.t('permission.projectWrite');
      case 'PROJECT_DELETE':
        return i18n.t('permission.ProjectDelete');
      case 'COSTS_READ':
        return i18n.t('permission.costsRead');
      case 'COSTS_WRITE':
        return i18n.t('permission.costsWrite');
      case 'COSTS_DELETE':
        return i18n.t('permission.costsDelete');
      case 'EARNINGS_READ':
        return i18n.t('permission.earningsRead');
      case 'EARNINGS_WRITE':
        return i18n.t('permission.earningsWrite');
      case 'EARNINGS_DELETE':
        return i18n.t('permission.earningsDelete');     
      case 'RISKS_READ':
        return i18n.t('permission.risksRead');
      case 'RISKS_WRITE':
        return i18n.t('permission.risksWrite');
      case 'RISKS_DELETE':
        return i18n.t('permission.risksDelete');
      case 'FINANCING_READ':
        return i18n.t('permission.financingRead');
      case 'FINANCING_WRITE':
        return i18n.t('permission.financingWrite');
      case 'FINANCING_DELETE':
        return i18n.t('permission.financingDelete');
      case 'RENT_READ':
        return i18n.t('permission.rentRead');
      case 'RENT_WRITE':
        return i18n.t('permission.rentWrite');
      case 'RENT_DELETE':
        return i18n.t('permission.rentDelete');        
      case 'RENT_REOPEN':
        return i18n.t('permission.rentReopen');
      case 'SELL_READ':
        return i18n.t('permission.sellRead');
      case 'SELL_WRITE':
        return i18n.t('permission.sellWrite');
      case 'SELL_DELETE':
        return i18n.t('permission.sellDelete');        
      case 'SELL_REOPEN':
        return i18n.t('permission.sellReopen');        
      case 'PLOT_AND_TAXONOMY_READ':
        return i18n.t('permission.plotAndTaxonomyRead');
      case 'PLOT_AND_TAXONOMY_WRITE':
        return i18n.t('permission.plotAndTaxonomyWrite');
      case 'PLOT_AND_TAXONOMY_DELETE':
        return i18n.t('permission.plotAndTaxonomyDelete');
      case 'CONTRACT_READ':
        return i18n.t('permission.contractRead');
      case 'CONTRACT_WRITE':
        return i18n.t('permission.contractWrite');
      case 'CONTRACT_DELETE':
        return i18n.t('permission.contractDelete');
      case 'INVOICE_READ':
        return i18n.t('permission.invoiceRead');
      case 'INVOICE_WRITE':
        return i18n.t('permission.invoiceWrite');
      case 'INVOICE_DELETE':
        return i18n.t('permission.invoiceDelete');
      case 'INVOICE_APPROVE':
        return i18n.t('permission.invoiceApprove');
      case 'TENANT_PROJECT_CREATE':
        return i18n.t('permission.tenantProjectCreate');
      case 'TENANT_PROJECT_COPY':
        return i18n.t('permission.tenantProjectCopy');
      case 'TENANT_PROJECT_DELETE':
        return i18n.t('permission.tenantProjectDelete');
      case 'TENANT_MANAGE':
        return i18n.t('permission.tenantManageData');      
      case 'TENANT_USER_CREATE':
        return i18n.t('permission.tenantUserCreate');
      case 'TENANT_USER_DELETE':
        return i18n.t('permission.tenantUserDelete');
      default:
        return permission;
    }
  }