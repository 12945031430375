import { useTranslation } from 'react-i18next';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  BaseSelect,
  Button,
  CodeIcon,
  OBJIcon,
  Form,
  FormField,
  FormWatch,
  StaticViewLevel2Icon,
  TopViewIcon,
  SlideOver,
  SlideOverOnCloseProps,
  SlideOverSteps,
  TextInput,
  SlideOverList,
  TrashIcon,
  SlideOverTitle,
  NumberInput,
  BaseSelectOption,
  LoadingIndicator,
  MultiSelect,
  LinkIcon,
  CheckBox, DatePicker,
} from '@client/shared/toolkit';
import {
  CreateCustomFieldFormValidationSchema,
  CreateCustomFieldFormValidationValues,
} from './CreateCustomFieldFormValidationSchema';
import cn from 'classnames';
import {
  useApiGetProjectsQuery,
  useApiPostCreateTenantUserDefinedFieldDefinitionMutation,
  useApiPostUpdateTenantUserDefinedFieldDefinitionMutation,
  UserDefinedFieldCalculateElementType,
  UserDefinedFieldDefinitionListItemPayload,
  UserDefinedFieldDefinitionReadModel,
  UserDefinedFieldVisibilityType,
} from '@client/shared/api';
import { Radio, RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { formatDateOnly, safeMutation } from '@client/shared/utilities';

export interface CreateCustomFieldSlideOverProps extends SlideOverOnCloseProps {
  item?: UserDefinedFieldDefinitionReadModel | null;
}

export const CreateCustomFieldSlideOver = ({ onClose, item }: CreateCustomFieldSlideOverProps) => {
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState(0);
  const [dropdownItems, setDropdownItems] = useState<UserDefinedFieldDefinitionListItemPayload[]>([
    { label: '', listItemId: null },
  ]);
  const [allowNextStep, setAllowNextStep] = useState(false);

  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [postCustomField, { isLoading: isCreatingCustomField }] =
    useApiPostCreateTenantUserDefinedFieldDefinitionMutation();
  const [updateCustomField, { isLoading: isUpdatingCustomField }] =
    useApiPostUpdateTenantUserDefinedFieldDefinitionMutation();

  const [projectSpecific, setProjectSpecific] = useState(
    item?.assignedProjects ? item?.assignedProjects?.length > 0 : false,
  );

  const handleOnStepChange = async (index: number) => {
    if (index > 0) {
      if (allowNextStep) {
        setCurrentStep(index);
      }
    } else {
      setCurrentStep(index);
    }
  };

  const customFieldListItemsRef = useRef<HTMLDivElement>(null);

  const { data: projectsData, isFetching } = useApiGetProjectsQuery();

  const [selectedProjects, setSelectedProjects] = useState<string[]>(
    item?.assignedProjects?.map((project) => project.id) ?? [],
  );
  const [projectsValid, setProjectsValid] = useState(true);

  const allProjects = useMemo(() => {
    const projects = (projectsData?.projects ?? []).slice();
    const groups = (projectsData?.projectGroups ?? []).slice();
    groups.forEach((group) => {
      group.projects.forEach((project) => {
        projects.push(project);
      });
    });
    return projects.map((project) => {
      return {
        label: project.name,
        value: project.id,
      } as BaseSelectOption;
    });
  }, [projectsData]);

  useEffect(() => {
    if (projectSpecific) {
      setProjectsValid(selectedProjects.length > 0);
    } else {
      setProjectsValid(true);
    }
  }, [projectSpecific, selectedProjects]);

  const handleSubmit = async (data: CreateCustomFieldFormValidationValues) => {
    if (data.id) {
      try {
        await safeMutation(
          updateCustomField,
          {
            userDefinedFieldDefinitionId: data.id,
            body: {
              name: data.name,
              fieldType: data.fieldType,
              elementType: data.elementType,
              visibilityType:
                data.visibilityType === 'AlwaysVisibleAndRequired'
                  ? ('AlwaysVisible' as UserDefinedFieldVisibilityType)
                  : (data.visibilityType as UserDefinedFieldVisibilityType),
              listItems: data.listItems?.length ? data.listItems : null,
              calculateElementType: data.calculateElementType ? data.calculateElementType : null,
              description: data.description ? data.description : null,
              isRequired: data.visibilityType === 'AlwaysVisibleAndRequired',
              defaultText: data.fieldType === 'Text' ? data.defaultText : null,
              defaultNumber: data.fieldType === 'Number' ? data.defaultNumber : null,
              defaultListItem:
                data.fieldType === 'List' && data.defaultListItem ? JSON.parse(data.defaultListItem) : null,
              defaultDate: data.fieldType === 'Date' && data.defaultDate ? formatDateOnly(new Date(data.defaultDate)) : null,
              assignedProjectIds: selectedProjects,
            },
          },
          isUpdatingCustomField,
        );
        onClose(true);
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        await safeMutation(
          postCustomField,
          {
            body: {
              name: data.name,
              fieldType: data.fieldType,
              elementType: data.elementType,
              visibilityType:
                data.visibilityType === 'AlwaysVisibleAndRequired'
                  ? ('AlwaysVisible' as UserDefinedFieldVisibilityType)
                  : (data.visibilityType as UserDefinedFieldVisibilityType),
              listItems: data.listItems?.length ? data.listItems : null,
              calculateElementType: data.calculateElementType ? data.calculateElementType : null,
              description: data.description ? data.description : null,
              isRequired: data.visibilityType === 'AlwaysVisibleAndRequired',
              defaultText: data.fieldType === 'Text' ? data.defaultText : null,
              defaultNumber: data.fieldType === 'Number' ? data.defaultNumber : null,
              defaultListItem:
                data.fieldType === 'List' && data.defaultListItem ? JSON.parse(data.defaultListItem) : null,
              defaultDate: data.fieldType === 'Date' && data.defaultDate ? formatDateOnly(new Date(data.defaultDate)) : null,
              assignedProjectIds: selectedProjects,
            },
          },
          isCreatingCustomField,
        );
        onClose(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const defaultFormValues = useMemo(() => {
    return {
      id: item?.id ?? '',
      name: item?.name ?? '',
      description: item?.description ?? '',
      fieldType: item?.fieldType ?? 'Text',
      listItems: item?.listItems ?? [],
      visibilityType: item?.isRequired ? 'AlwaysVisibleAndRequired' : item?.visibilityType ?? 'AlwaysVisible',
      elementType: item?.elementType ?? 'Project',
      calculateElementType: item?.calculateElementType
        ? (item?.calculateElementType as UserDefinedFieldCalculateElementType)
        : null,
      isRequired: item ? item.isRequired : false,
      defaultText: item?.defaultText ?? '',
      defaultNumber: item?.defaultNumber ?? null,
      defaultListItem: item?.defaultListItem ? JSON.stringify(item.defaultListItem) : null,
      defaultDate: item?.defaultDate ?? null
    };
  }, [item]);

  useEffect(() => {
    if (item?.listItems) {
      setDropdownItems(item.listItems);
    }
  }, [item?.listItems]);

  const fieldTypeOptions = useMemo(() => {
    return [
      {
        label: t('app.settingsCreateCustomFieldTypeText'),
        value: 'Text',
      },
      {
        label: t('app.settingsCreateCustomFieldTypeNumber'),
        value: 'Number',
      },
      {
        label: t('app.settingsCreateCustomFieldTypeList'),
        value: 'List',
      },
      {
        label: t('app.settingsCreateCustomFieldTypeDate'),
        value: 'Date',
      },
    ];
  }, [t]);

  const addNewDropdownLabel = useCallback(() => {
    const updatedItems = [...dropdownItems];
    updatedItems.push({
      label: '',
      listItemId: null,
    });
    setDropdownItems(updatedItems);
  }, [dropdownItems]);

  const listItemsDefaultOptions = useMemo(() => {
    const options: BaseSelectOption[] = [];
    dropdownItems.forEach((item, index) => {
      options.push({
        label: item.label,
        value: JSON.stringify(item),
      });
    });
    return options;
  }, [dropdownItems]);

  // Set the focus to the first input
  useEffect(() => {
    if (customFieldListItemsRef.current) {
      const textInput = customFieldListItemsRef.current.querySelector('li input');
      if (textInput) {
        (textInput as HTMLInputElement).focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFieldListItemsRef.current]);

  // Set the focus to the next input
  useEffect(() => {
    if (dropdownItems.length) {
      const textInputs = customFieldListItemsRef?.current?.querySelectorAll('li input');
      if (textInputs?.length) {
        (textInputs[textInputs.length - 1] as HTMLInputElement).focus();
      }
    }
  }, [dropdownItems.length])

  return (
    <Form<CreateCustomFieldFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={CreateCustomFieldFormValidationSchema}
      defaultValues={defaultFormValues}
      className="w-full flex flex-col justify-between h-full"
      ref={formRef}
    >
      {(isFetching || isCreatingCustomField || isUpdatingCustomField) && (
        <LoadingIndicator text={t('common.loading')} mode="overlay" />
      )}
      <SlideOver.Header
        title={t('app.settingsCreateCustomFieldSubtitle')}
        subTitle={t('app.settingsCreateCustomFieldDescription')}
        backgroundClassName="bg-gray-800"
        onClose={onClose}
      />
      <SlideOver.Content>
        <SlideOverSteps
          steps={[
            t('app.settingsCreateCustomFieldGeneral'),
            t('app.settingsCreateCustomFieldType'),
            t('app.settingsCreateCustomFieldRules'),
          ]}
          currentStep={currentStep}
          handleOnStepChange={handleOnStepChange}
          bgColor="bg-gray-800"
        />
        <div className="flex flex-col p-8">
          <div
            className={cn({
              hidden: currentStep !== 0,
            })}
          >
            <FormWatch<CreateCustomFieldFormValidationValues>
              onChange={() => {
                if (formRef.current) {
                  const { isValid } = formRef.current.getState();
                  setAllowNextStep(isValid);
                }
              }}
              fieldNames={['name', 'elementType']}
            >
              {({ elementType }) => (
                <>
                  <FormField name="elementType">
                    {(control) => (
                      <BaseSelect
                        label={t('app.settingsCreateCustomFieldObjectType')}
                        options={[
                          {
                            label: t('app.settingsCreateCustomFieldElementTypeProject'),
                            value: 'Project',
                          },
                          {
                            label: t('app.settingsCreateCustomFieldElementTypeCost'),
                            value: 'Cost',
                          },
                          {
                            label: t('app.settingsCreateCustomFieldElementTypeRisk'),
                            value: 'Risk',
                          },
                          {
                            label: t('app.settingsCreateCustomFieldElementTypeEarning'),
                            value: 'Earning',
                          },
                          {
                            label: t('app.settingsCreateCustomFieldElementTypeTaxonomy'),
                            value: 'Taxonomy',
                          },
                          {
                            label: t('app.settingsCreateCustomFieldElementTypeContract'),
                            value: 'Contract',
                          },
                          {
                            label: t('app.settingsCreateCustomFieldElementTypeContractTitle'),
                            value: 'ContractTitle',
                          },
                          {
                            label: t('app.settingsCreateCustomFieldElementTypeInvoice'),
                            value: 'Invoice',
                          },
                        ]}
                        icon={<TopViewIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                  {(elementType === 'Cost' || elementType === 'Risk' || elementType === 'Earning') && (
                    <FormField name="calculateElementType">
                      {(control) => (
                        <BaseSelect
                          label={t('app.settingsCreateCustomFieldCalculateType')}
                          options={[
                            {
                              label: t('app.settingsCreateCustomFieldCostTypeGroup'),
                              value: 'Group',
                            },
                            {
                              label: t('app.settingsCreateCustomFieldCostTypeElement'),
                              value: 'Element',
                            },
                          ]}
                          icon={<StaticViewLevel2Icon />}
                          {...control}
                        />
                      )}
                    </FormField>
                  )}
                  <FormField name="name">
                    {(control) => (
                      <TextInput icon={<CodeIcon />} label={t('app.settingsCreateCustomFieldLabel')} {...control} />
                    )}
                  </FormField>
                </>
              )}
            </FormWatch>
            <FormField name="description">
              {(control) => <TextInput label={t('common.description')} inputType="textarea" {...control} />}
            </FormField>

            <label className="w-full flex items-center justify-between bg-white p-3 cursor-pointer">
              <span>{t('app.settingsCreateCustomFieldSpecifyProjects')}</span>
              <CheckBox
                checked={projectSpecific}
                onChange={(checked) => {
                  setProjectSpecific(checked);
                  if (!checked) {
                    setSelectedProjects([]);
                  }
                }}
              />
            </label>
            {projectSpecific && (
              <MultiSelect
                label={t('app.settingsCreateCustomFieldProjects')}
                placeHolder={t('app.settingsCreateCustomFieldProjects')}
                options={allProjects}
                value={selectedProjects}
                onChange={(selected) => setSelectedProjects(selected)}
                icon={<LinkIcon />}
                disabled={!projectSpecific}
                nullable
              />
            )}
            {selectedProjects.length === 0 && (
              <div className="p-3 w-full justify-center h-10 flex items-center text-yellow-600">
                {t('app.settingsCreateCustomFieldProjectSpecificWarning')}
              </div>
            )}
          </div>

          <div
            className={cn({
              hidden: currentStep !== 1,
            })}
          >
            <FormField name="fieldType">
              {(control) => (
                <>
                  <BaseSelect
                    label={t('app.settingsCreateCustomFieldType')}
                    options={fieldTypeOptions}
                    icon={<OBJIcon />}
                    {...control}
                    onChange={(value) => {
                      if (value === 'Text') {
                        formRef.current?.setValue('defaultText', '');
                      }
                      control.onChange(value);
                    }}
                  />
                  {control.value === 'List' && (
                    <div className="flex flex-col" ref={customFieldListItemsRef}>
                      <span className="mt-5 mb-1 mx-2.5 text-[15px]">{t('app.settingsCreateCustomFieldElements')}</span>
                      <FormField name="listItems">
                        {(control) => (
                          <SlideOverList
                            items={dropdownItems.map(
                              (item: UserDefinedFieldDefinitionListItemPayload, index: number) => {
                                return {
                                  id: index.toString(),
                                  inputLabel: t('app.settingsCreateCustomFieldLabel'),
                                  inputValue: item?.label ?? '',
                                  inputType: 'text',
                                  onChange: (value: string | number | null) => {
                                    const updatedItems = [...dropdownItems];
                                    updatedItems[index] = {
                                      ...updatedItems[index],
                                      label: value?.toString() ?? '',
                                    };
                                    setDropdownItems(updatedItems);
                                    control.onChange(updatedItems);
                                  },
                                  contextMenuItems: [
                                    {
                                      label: t('common.delete'),
                                      icon: <TrashIcon />,
                                      onClick: () => {
                                        const updatedItems = [...dropdownItems];
                                        updatedItems.splice(index, 1);
                                        setDropdownItems(updatedItems);
                                        control.onChange(updatedItems);
                                      },
                                    },
                                  ],
                                };
                              },
                            )}
                            onAdd={addNewDropdownLabel}
                            isInput
                            rounded={false}
                          />
                        )}
                      </FormField>
                    </div>
                  )}

                  <div className="mt-4">
                    <SlideOverTitle title={t('app.settingsCreateCustomFieldDefaultValue')} />
                    {control.value === 'Text' && (
                      <FormField name="defaultText">
                        {(control) => <TextInput label={t('app.settingsCreateCustomFieldDefaultValue')} {...control} />}
                      </FormField>
                    )}
                    {control.value === 'List' && (
                      <FormField name="defaultListItem">
                        {(control) => (
                          <BaseSelect
                            label={t('app.settingsCreateCustomFieldDefaultValue')}
                            options={listItemsDefaultOptions}
                            {...control}
                          />
                        )}
                      </FormField>
                    )}
                    {control.value === 'Number' && (
                      <FormField name="defaultNumber">
                        {(control) => (
                          <NumberInput label={t('app.settingsCreateCustomFieldDefaultValue')} {...control} />
                        )}
                      </FormField>
                    )}
                    {control.value === 'Date' && (
                      <FormField name="defaultDate">
                        {(control) => (
                          <DatePicker label={t('app.settingsCreateCustomFieldDefaultValue')} {...control} />
                        )}
                      </FormField>
                    )}
                  </div>
                </>
              )}
            </FormField>
          </div>
          <div
            className={cn({
              hidden: currentStep !== 2,
            })}
          >
            <FormField name="visibilityType">
              {(control) => (
                <RadioGroup value={control.value} onChange={control.onChange} name="visibilityType">
                  <CustomFieldRadioCheckbox
                    label={t('app.settingsCreateCustomRuleAlwaysVisibleAndRequired')}
                    value="AlwaysVisibleAndRequired"
                  />
                  <CustomFieldRadioCheckbox
                    label={t('app.settingsCreateCustomRuleAlwaysVisible')}
                    value="AlwaysVisible"
                  />
                  <CustomFieldRadioCheckbox
                    label={t('app.settingsCreateCustomRuleVisibleOnComponentAfterManualSelection')}
                    value="VisibleOnComponentAfterManualSelection"
                  />
                  <CustomFieldRadioCheckbox
                    label={t('app.settingsCreateCustomRuleVisibleOnAllComponentsAfterManualSelection')}
                    value="VisibleOnAllComponentsAfterManualSelection"
                  />
                </RadioGroup>
              )}
            </FormField>
          </div>
        </div>
      </SlideOver.Content>
      <SlideOver.Controls>
        <div className="flex-grow flex justify-between">
          <div className="flex">
            {currentStep > 0 && (
              <Button variant="secondary" onClick={() => setCurrentStep(currentStep - 1)}>
                {t('common.back')}
              </Button>
            )}
          </div>
          <div className="flex">
            <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
              {t('common.cancel')}
            </Button>
            {currentStep < 2 && (
              <Button
                disabled={!allowNextStep || !projectsValid}
                variant="primary"
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.validateForm();
                    const { isValid } = formRef.current.getState();
                    setAllowNextStep(isValid);
                    if (isValid) {
                      setCurrentStep(currentStep + 1);
                    }
                  }
                }}
              >
                {t('common.next')}
              </Button>
            )}
            {currentStep === 2 && (
              <Button variant="primary" formSubmit={true} innerRef={submitRef}>
                {item ? t('common.save') : t('common.create')}
              </Button>
            )}
          </div>
        </div>
      </SlideOver.Controls>
    </Form>
  );
};

export const CustomFieldRadioCheckbox = ({ label, value }: { label: string; value: string }) => {
  return (
    <Radio value={value} as={Fragment} key={value}>
      {({ checked }) => (
        <div className="bg-white border-b flex pl-4 py-4 cursor-pointer">
          <div className="flex items-center w-full gap-4">
            <div className="h-4 w-4 border-2 rounded-full border-neutral-500 focus:ring-2 focus:ring-blue-300 flex items-center justify-center">
              <div className={classNames('w-2 h-2 rounded-full', { 'bg-black': checked })}></div>
            </div>
            {label}
          </div>
        </div>
      )}
    </Radio>
  );
};
