import {formatNumber} from '@client/shared/utilities';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DecoratedElement, EarningElement } from '../hooks';
import {getUnitLabel} from '../utils';
import { ContextMenuItem, PencilIcon, TrashIcon } from '@client/shared/toolkit';
import { ElementRowMenu } from './ElementRowMenu';
import { useLoadedProjectId } from '@client/project/store';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { TimeLineView } from './Timeline';
import { Column } from './CalculateContainer';
import { OptionalColumn } from './CalculateSectionHeader';
import {CalculateColumns} from "./CalculateColumns";
import {CalculateElementRowInformationColumn} from "./CalculateElementRowInformationColumn";
import {CalculateInformationColumnContent} from "./CalculateGroupElementInformationColumn";
import {CalculateElementRowFinanceColumn} from "./CalculateElementRowFinanceColumn";
import { FormattedCurrency } from '@client/project/shared';
import { CalculateSelectableColumn } from './CalculateSelectableColumn';

interface EarningElementProps {
  item: DecoratedElement<EarningElement>;
  selectedVersionId: string;
  onClick: () => void;
  onCodeClick?: () => void;
  onRemove?: () => void;
  optionalColumn: OptionalColumn;
  obligoColumn: OptionalColumn;
  view: TimeLineView;
  isReadOnly: boolean;
}

export const EarningsElementRow = ({
  item: {
    element: { earningElement, showError },
    level,
    parent,
  },
  onClick,
  onRemove,
  view,
  isReadOnly,
  optionalColumn,
  obligoColumn
}: EarningElementProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const canDeleteEarnings = useValidateProjectPermission(['EARNINGS_DELETE'], loadedProjectId ?? '');
  const canWriteEarnings = useValidateProjectPermission(['EARNINGS_WRITE'], loadedProjectId ?? '');

  const handleOnClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    onClick?.();
  };

  const elementMenuItems = useMemo<ContextMenuItem[]>(() => {
    const items: ContextMenuItem[] = [];

    items.push({
      isDisabled: !canWriteEarnings,
      label: t('projectCalculate.rowMenu.updateEarningElementTitle'),
      subtitle: t('projectCalculate.rowMenu.updateEarningElementSubTitle'),
      onClick: onClick,
      icon: <PencilIcon />,
    });

    if (onRemove) {
      items.push({
        isDisabled: !canDeleteEarnings,
        label: t('projectCalculate.rowMenu.removeEarningElementTitle'),
        subtitle: t('projectCalculate.rowMenu.removeEarningElementSubTitle'),
        onClick: onRemove,
        icon: <TrashIcon />,
      });
    }

    return items;
  }, [t, onClick, onRemove, canDeleteEarnings, canWriteEarnings]);

  return (
    <CalculateColumns
      view={view}
      informationColumnContent={(
        <CalculateElementRowInformationColumn
          level={level}
          view={view}
          handleOnClick={handleOnClick}
          contentType="row"
          leftColumn={(
            <CalculateInformationColumnContent
              showCode={!!parent?.element.group?.code}
              level={level}
              showError={showError}
              title={earningElement?.title ?? earningElement?.description}
              contentType="row"
              showExpanded
              >
              {earningElement?.hasFormula && <sup className="font-normal italic text-gray-400">&nbsp;fx</sup>}
            </CalculateInformationColumnContent>
          )}
          rightColumn={(
            <>
              <div className="hidden md:flex w-2/12 text-sm text-right flex-row justify-end items-center leading-tight">
                <div>
                  {earningElement?.unit === '€'
                    ? <FormattedCurrency amount={(earningElement?.amount)} />
                    : formatNumber(earningElement?.amount)}
                  {earningElement?.unit && earningElement?.unit !== '€' && (
                    <div className="text-xs font-light">{getUnitLabel(earningElement.unit)}</div>
                  )}
                </div>
              </div>
              <div className="hidden w-3/12 md:flex text-sm text-right flex-row justify-end items-center leading-tight">
                <div>
                  <FormattedCurrency amount={earningElement?.unitPrice} options={{ maxDigits: 2 }} />
                  {earningElement?.unit && (
                    <div className="text-xs font-light">
                      {t('projectCalculate.earningsElementLabelPer')} {getUnitLabel(earningElement.unit)}
                    </div>
                  )}
                </div>
              </div>
              <div className="hidden w-2/12 md:flex  text-sm text-right flex-row justify-end items-center leading-tight">
                {earningElement?.multiple ? <div>{formatNumber(earningElement?.multiple)}x</div> : '-'}
              </div>
              <div className="w-5/12 text-right flex flex-row justify-end items-center leading-tight">
                <div className="text-sm font-bold"><FormattedCurrency amount={earningElement?.totalValue} /></div>
              </div>
            </>
          )}
          menu={!isReadOnly && (
            <ElementRowMenu items={elementMenuItems} />
          )}
        />
      )}
      currentColumnContent={(
        <CalculateElementRowFinanceColumn
          level={level + 1}
          handleOnClick={handleOnClick}
          columnType={Column.CURRENT}
          view={view}
        >
          <FormattedCurrency amount={earningElement?.totalValue} />
        </CalculateElementRowFinanceColumn>
      )}
      obligoColumnContent={(
        <CalculateSelectableColumn
          column={obligoColumn}
          level={level + 1}
          handleOnClick={handleOnClick}
          columnType={Column.OBLIGO}
          view={view}
          values={earningElement}
          type="earningElement"
        />
      )}
      optionalColumnContent={(
        <CalculateSelectableColumn
          column={obligoColumn}
          level={level + 1}
          handleOnClick={handleOnClick}
          columnType={Column.OPTIONAL_COLUMN}
          view={view}
          values={earningElement}
          type="earningElement"
        />
      )}
    />
  )
};
