import classNames from 'classnames';
import React, {PropsWithChildren, RefObject, useMemo} from 'react';
import { parseISO } from 'date-fns/fp';
import { getPercentageBetweenDates } from '../../utils';

interface TimeLinePositionedContainerProps {
  date?: Date | string;
  endDate?: Date | string;
  timeLineStartDate?: Date;
  timeLineEndDate?: Date;
  className?: string;
  top?: string;
  id?: string
  wrapperRef?: RefObject<HTMLDivElement>
}

export const TimeLinePositionedContainer = ({
  children,
  className,
  date,
  endDate,
  timeLineEndDate,
  timeLineStartDate,
  top,
  id,
  wrapperRef
}: PropsWithChildren<TimeLinePositionedContainerProps>) => {
  const startPercent = useMemo(() => {
    let parsedDate
    if (typeof date === 'string') {
      parsedDate = parseISO(date)
    } else {
      parsedDate = date
    }
    if (parsedDate) {
      return getPercentageBetweenDates(timeLineStartDate, timeLineEndDate, parsedDate)
    }
    return undefined
  }, [date, timeLineStartDate, timeLineEndDate])

  const endPercent = useMemo(() => {
    let parsedDate
    if (typeof endDate === 'string') {
      parsedDate = parseISO(endDate);
    } else {
      parsedDate =  endDate
    }
    if (parsedDate) {
      return getPercentageBetweenDates(timeLineStartDate, timeLineEndDate, parsedDate)
    }
    return undefined
  }, [endDate, timeLineStartDate, timeLineEndDate])

  const left = useMemo(() => {
    return `${startPercent}%`
  }, [startPercent])

  const maxWidth = useMemo(() => {
    if (typeof startPercent !== 'undefined' && typeof endPercent !== 'undefined') {
      return `${endPercent - startPercent}%`
    }
    return undefined
  }, [endPercent, startPercent])

  if (!date || !timeLineStartDate || !timeLineEndDate) {
    return null;
  }

  return (
    <div className={classNames('absolute', className)} style={{ left, maxWidth, top }} id={id} ref={wrapperRef}>
      {children}
    </div>
  );
};
