import { AuthRoleCatalogTemplateReadModel, CatalogTemplateReadModel, useApiGetCatalogTemplatesQuery } from '@client/shared/api';
import { Button, LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import { RectangleGroupIcon } from '@heroicons/react/24/outline';
import { TemplateStatus } from './CatalogTemplateUtilities';

interface CatalogTemplateAddSlideOverProps {
  roleId: string;
  assignedCatalogTemplates: AuthRoleCatalogTemplateReadModel[];
  onClose: (newCatalogTemplates?: TemplateStatus[]) => void;
}

export const CatalogTemplateAddSlideOver = ({ roleId, assignedCatalogTemplates, onClose }: CatalogTemplateAddSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const { data: catalogTemplates, isFetching } = useApiGetCatalogTemplatesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [selectableCatalogTemplates, setSelectableCatalogTemplates] = useState<CatalogTemplateReadModel[]>([]);
  const [selectedCatalogTemplates, setSelectedCatalogTemplates] = useState<CatalogTemplateReadModel[]>([]);

  const toTemplateStatus = (catalogTemplate: CatalogTemplateReadModel): TemplateStatus => ({
    template : {
      id: '',
      roleId: roleId,
      catalogTemplate: catalogTemplate,
      catalogElementPermissions: []
    },
    elements: []
  });

  useEffect(() => {
    if (!catalogTemplates) {
        setSelectableCatalogTemplates([]);
    } else {
        setSelectableCatalogTemplates(
            catalogTemplates.filter((x) => !assignedCatalogTemplates.find((y) => y.catalogTemplate.catalogTemplateId === x.catalogTemplateId))
      );
    }
  }, [assignedCatalogTemplates, catalogTemplates]);

  const handleChange = (catalogTemplate: CatalogTemplateReadModel) => {
    if (selectedCatalogTemplates.includes(catalogTemplate)) {
      const newSelection = selectedCatalogTemplates.filter((x) => x.catalogTemplateId !== catalogTemplate.catalogTemplateId);
      setSelectedCatalogTemplates(newSelection);
    } else {
      const newSelection = selectedCatalogTemplates.concat(catalogTemplate);
      setSelectedCatalogTemplates(newSelection);
    }
  };

  return (
    <>
      <SlideOver.Header
        backgroundClassName="bg-gray-600"
        title={t('auth.templateAdd')}
        onClose={() => onClose(undefined)}
      />
      <SlideOver.Content
        className="p-8"
        onKeyEnter={() => submitRef.current?.click()}
      >
        {isFetching === true ? (
          <LoadingIndicator text={t('auth.catalogTemplateLoadingIndicator') ?? ''} />
        ) : (
          <div className="divide-y">
            {selectableCatalogTemplates.map((template, index) => (
              <label className="flex items-center bg-white p-3 cursor-pointer" key={index}>
                <input
                  type="checkbox"
                  checked={selectedCatalogTemplates.includes(template)}
                  onChange={() => handleChange(template)}
                  autoFocus={false}
                />
                <div className="w-6 ml-2">
                  <RectangleGroupIcon className="w-6"/>
                </div>
                <div>
                  <div className="ml-2">{template.name}</div>
                </div>
              </label>
            ))}
          </div>
        )}
      </SlideOver.Content>
      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(undefined)}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={() => onClose(selectedCatalogTemplates.map(toTemplateStatus))} innerRef={submitRef}>
          {t('common.add')}
        </Button>
      </SlideOver.Controls>
    </>
  );
};
