import { ShortContractReadModel, useApiDeleteContractMutation } from '@client/shared/api';
import { HintBox, ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';

interface ContractDeleteModalProps extends ModalOnCloseProps {
  contract: ShortContractReadModel;
}

export const ContractDeleteModal = ({ contract, onClose }: ContractDeleteModalProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const [deleteContract, { isLoading }] = useApiDeleteContractMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    try {
      await safeMutation(
        deleteContract,
        {
          contractId: contract.id,
          projectId: loadedProjectId ?? '',
          calculationModelId: loadedVariantId ?? ''
        },
        isLoading
      );
      onClose(true);
    } catch {
      /* left blank */
    }
  };

  return (
    <ConfirmModal
      title={t('projectContract.deleteTitle')}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      {isLoading && <LoadingIndicator text={t('common.deleteLoading') ?? ''} mode="overlay" />}
      <HintBox hintType="warning">{t('projectContract.deleteHint')}</HintBox>
    </ConfirmModal>
  );
};
