import { useLoadedProjectId } from '@client/project/store';
import { DealReadModel, useApiPostReopenRentDealMutation, useApiPostReopenSellDealMutation } from '@client/shared/api';
import { Button, LoadingIndicator, Modal, ModalOnCloseProps } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

interface DealReOpenModalProps extends ModalOnCloseProps {
  deal: DealReadModel;
}

export const DealReOpenModal = ({ deal, onClose }: DealReOpenModalProps) => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();
  const [reopenRentDeal, { isLoading: isLoadingRent }] = useApiPostReopenRentDealMutation();
  const [reopenSellDeal, { isLoading: isLoadingSell }] = useApiPostReopenSellDealMutation();

  const handleReopen = async () => {
    switch (deal.type){
      case 'Rent':
        try {
          await safeMutation(
            reopenRentDeal,
            {
              projectId: projectId ?? 'unset',
              dealId: deal.id ?? '',
            },
            isLoadingRent
          );
    
          onClose(true);
        } catch (e) {
          console.log(e);
        }
        break;
      case 'Sell':
        try {
          await safeMutation(
            reopenSellDeal,
            {
              projectId: projectId ?? 'unset',
              dealId: deal.id ?? '',
            },
            isLoadingSell
          );
    
          onClose(true);
        } catch (e) {
          console.log(e);
        }
        break;        

    }

  };

  return (
    <>
      <Modal.Header title={`${t('projectRent.dealReopen')} '${deal.name}'`} />
      <Modal.Content>{t('projectRent.dealReopenConfirmation')}</Modal.Content>
      <Modal.Controls className="bg-white">
      {(isLoadingRent || isLoadingSell) && <LoadingIndicator text={t('projectRent.dealReopenLoading') ?? ''} mode="overlay" />}
        <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
          {t('common.cancel')}
        </Button>
        <Button variant="warning" onClick={handleReopen}>
          {t('common.yes')}
        </Button>
      </Modal.Controls>
    </>
  );
};
