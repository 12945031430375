import { AuthGroupReadModel } from '@client/shared/api';
import { SettingsAddButton, SlideOver, TrashIcon } from '@client/shared/toolkit';
import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid';
import { FolderIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useState } from 'react';
import { GroupAddSlideOver } from './GroupAddSlideOver';
import { useTranslation } from 'react-i18next';

type GroupState = 'Assigned' | 'New' | 'Deleted';

interface GroupRowProps {
  state: GroupState;
  group: AuthGroupReadModel;
  onDelete: (role: AuthGroupReadModel) => void;
}

const GroupRow = ({ group, onDelete, state }: GroupRowProps) => (
  <div className="flex flex-col w-full py-2">
    <div className="flex items-center h-10 ">
      <div className="flex flex-row w-11/12 mx-4">
        <div>
          <FolderIcon
            className={classNames(
              'w-6 ',
              { 'text-green-600': state === 'New' },
              { 'text-red-600': state === 'Deleted' }
            )}
          />
        </div>
        <div className="ml-2">{group.name}</div>
      </div>

      <div className="w-1/12 text-gray-500 hover:text-black">
        {state === 'Assigned' && <TrashIcon className="w-6" onClick={() => onDelete(group)} />}
        {state !== 'Assigned' && <ArrowUturnLeftIcon className="w-6" onClick={() => onDelete(group)} />}
      </div>
    </div>
  </div>
);

interface GroupListProps {
  assignedGroups: AuthGroupReadModel[];
  newGroups: AuthGroupReadModel[];
  deletedGroups: AuthGroupReadModel[];
  groupSelectionChanged: (added: AuthGroupReadModel[], removed: AuthGroupReadModel[]) => void;
}

export const GroupList = ({ newGroups, assignedGroups, groupSelectionChanged, deletedGroups }: GroupListProps) => {
  const { t } = useTranslation();

  const [showAddGroup, setShowAddGroup] = useState<boolean>(false);

  const getGroupState = (role: AuthGroupReadModel): GroupState => {
    if (deletedGroups.includes(role)) {
      return 'Deleted';
    }
    if (!assignedGroups.includes(role)) {
      return 'New';
    }

    return 'Assigned';
  };

  const handleDeleteGroup = (role: AuthGroupReadModel) => {
    const state = getGroupState(role);
    if (state === 'Assigned') {
      const newRemoved = deletedGroups.concat(role);
      groupSelectionChanged(newGroups, newRemoved);
    }
    if (state === 'New') {
      const newAdded = newGroups.filter((x) => x.id !== role.id);
      groupSelectionChanged(newAdded, deletedGroups);
    }
    if (state === 'Deleted') {
      const newRemoved = deletedGroups.filter((x) => x.id !== role.id);
      groupSelectionChanged(newGroups, newRemoved);
    }
  };

  const handleAddGroups = (newRoles: AuthGroupReadModel[]) => {
    const allAdded = newGroups.concat(newRoles);

    groupSelectionChanged(allAdded, deletedGroups);
  };

  return (
    <div className="flex flex-col w-full ">
      <div className="flex flex-col w-full">
        {assignedGroups.map((group, idx) => (
          <GroupRow key={idx} group={group} state={getGroupState(group)} onDelete={handleDeleteGroup} />
        ))}
        {newGroups.map((group, idx) => (
          <GroupRow key={idx} group={group} state="New" onDelete={handleDeleteGroup} />
        ))}
        {newGroups.length === 0 && assignedGroups.length === 0 && (
          <div className="flex my-4 items-center justify-center w-full">
            <div className="text-gray-500">{t('auth.noGroupsAdded')}</div>
          </div>
        )}
      </div>
      <SettingsAddButton className="mr-2 mb-2" onClick={() => setShowAddGroup(true)} />

      <SlideOver isOpen={showAddGroup} onClose={() => setShowAddGroup(false)}>
        <GroupAddSlideOver
          assignedGroups={assignedGroups.concat(newGroups)}
          onClose={(newGroups) => {
            if (newGroups) {
              handleAddGroups(newGroups);
            }

            setShowAddGroup(false);
          }}
        />
      </SlideOver>
    </div>
  );
};
