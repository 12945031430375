import { BaseSelectOption, BillIcon, PriceTagUSDIcon } from '@client/shared/toolkit';
import { AmountFactorInput } from './AmountFactorInput';
import { EarningsElementReadModel } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { produce } from "immer";
import { useGetCurrencySymbol, useLoadedProject } from '@client/project/store';
import classNames from 'classnames';
import { UnitFactorInput } from './UnitFactorInput';
import { useEffect, useState } from 'react';

interface EarningsFormulaInputProps {
  value: EarningsElementReadModel;
  onChange: (value: EarningsElementReadModel, effectiveAmount: number, effectiveUnitPrice: number) => void;
  disabled?: boolean;
  catalogElementId?: string;
  unitOptions: BaseSelectOption[];
  setIsLoading: (isLoading: boolean) => void;
}

export const EarningsFormulaInput = ({ onChange, value, disabled, unitOptions, catalogElementId, setIsLoading }: EarningsFormulaInputProps) => {
  const { t } = useTranslation();
  const currentCurrencySymbol = useGetCurrencySymbol()

  const projectData = useLoadedProject()
  const phValuation = projectData.data?.project.payload.priceHubbleValuation;
  const showPriceHubbleValuation = phValuation
    && (value.earningsType === 'RentRevenue' || value.earningsType === 'SaleRevenue')
    && value.amount.staticValue?.unit === 'm²';
  const priceHubbleValue = value.earningsType === 'RentRevenue' ? phValuation?.avgRentUnitPrice.value : phValuation?.avgSaleUnitPrice.value;

  const onChangeDecorated = (model: EarningsElementReadModel, save = false) => {
    model = produce(model, (draft) => {
      draft.totalValue = calculateValue(model);
    });

    if (save) {
      onChange(model, getEffectiveAmount(model), getEffectiveUnitPrice(model));
    }
  };

  const [isLoadingAmountDataset, setIsLoadingAmountDataset] = useState(false);
  const [isLoadingUnitDataset, setIsLoadingUnitDataset] = useState(false);

  useEffect(() => {
    setIsLoading(isLoadingAmountDataset || isLoadingUnitDataset);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAmountDataset, isLoadingUnitDataset]);

  const calculateValue = (model: EarningsElementReadModel): number => {
    return getEffectiveAmount(model) * getEffectiveUnitPrice(model);
  };

  const getEffectiveAmount = (model: EarningsElementReadModel): number => {
    let amount = 0;

    switch (model.amount.type) {
      case 'Static':
        amount = model.amount.staticValue?.value ?? 0;
        break;
      case 'Formula':
        amount = model.amount.formulaValue?.value ?? 0;
        break;
    }
    return amount;
  }

  const getEffectiveUnitPrice = (model: EarningsElementReadModel): number => {
    let unitPrice = 0;
    switch (model.unitPrice.type) {
      case 'Static':
        unitPrice = model.unitPrice.staticValue?.value ?? 0;
        break;
      case 'Formula':
        unitPrice = model.unitPrice.formulaValue?.value ?? 0;
        break;
    }
    return unitPrice;
  }

  return (
    <div className="relative">
      <div className="bg-white">
        <div className="divide-gray-100 divide-y">
          <AmountFactorInput
            label={t('projectCalculate.formulaLabelAmount')}
            icon={<BillIcon className="fill-black" />}
            factor={value.amount}
            disabled={disabled}
            unitOptions={unitOptions}
            onChange={(amount) => onChangeDecorated({ ...value, amount }, true)}
            showFX
            catalogElementType="EarningsElement"
            catalogElementId={catalogElementId}
            setIsLoading={setIsLoadingAmountDataset}
          />
           <div className="flex divide-x w-full">
            <div className={classNames({
              'flex flex-row flex-nowrap items-center w-4/6': showPriceHubbleValuation,
              'w-full': !showPriceHubbleValuation
            })}>
              <UnitFactorInput
                label={t('projectCalculate.formulaLabelUnitPrice')}
                icon={<PriceTagUSDIcon />}
                factor={value.unitPrice}
                disabled={disabled}
                onChange={(unitPrice) => onChangeDecorated({ ...value, unitPrice }, true)}
                catalogElementType='EarningsElement'
                showFX
                catalogElementId={catalogElementId}
                setIsLoading={setIsLoadingUnitDataset}
              />
            </div>
            {showPriceHubbleValuation && (
              <div className={classNames(
                  'flex flex-col flex-nowrap items-center w-2/6 pt-2 text-sm text-gray-400 font-bold', {
                  'text-gray-900': (value.unitPrice.staticValue?.value === priceHubbleValue)
                })}
              >
                <div>
                  {priceHubbleValue}{currentCurrencySymbol}/m²
                </div>
                <div className="text-xs">
                  PriceHubble
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
