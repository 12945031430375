import { RefObject } from 'react';
import { DecoratedElement, EarningElement, FinancingElement, RiskElement } from '../hooks';

export const ELEMENT_ROW_HEIGHT = 38;
export const CALCULATE_SECTION_PADDING = 32;
export const getRenderElementIsVisibleAndTopStyle = (counter: number, containerRef: RefObject<HTMLDivElement>, top: number) => {
  counter = counter + 1;
  let isVisible = false;
  const topStyle = counter * ELEMENT_ROW_HEIGHT + top;
  if (containerRef.current) {
    const rect = containerRef.current.getBoundingClientRect();
    if (topStyle <= window.innerHeight - rect.top && topStyle >= rect.top * -1) {
      isVisible = true;
    }
  }
  return {
    isVisible: isVisible,
    topStyle: topStyle,
    counter: counter,
    top: top
  }
}

export const getCalculateSectionHeight = (elements: DecoratedElement<FinancingElement | RiskElement | EarningElement>[], h: number, level: number, expandedCatalogIds: string[]) => {
  elements?.forEach((element) => {
    const open = expandedCatalogIds.includes(element.categoryId);
    h += ELEMENT_ROW_HEIGHT + (level === 0 ? CALCULATE_SECTION_PADDING : 0);
    if (open) {
      // Children
      if (element.children.length) {
        h = getCalculateSectionHeight(element.children, h, level + 1, expandedCatalogIds);
      }
    }
  });
  return h;
};
