import { useMemo } from 'react';
import {
  CommitmentDto,
  ContractDto,
  InvoicingPartyDto,
  ContractBudgetErrors,
  CostCatalogElementDto,
  CostElementDto,
  TimelineErrors,
} from '@client/shared/api';
import {
  CostsFilterOption,
  filterElementsByCostsFilter,
  filterElementsById,
} from '../utils';

export type DecoratedElement<T> = {
  element: T;
  children: DecoratedElement<T>[];
  level: number;
  categoryId: string;
  parent?: DecoratedElement<T>;
  disableClick?: boolean;
  match?: boolean
};

export type CostCatalogElement = {
  key: string;
  group?: CostCatalogElementDto;
  showError: boolean;
  costElements?: CostElement[];
  commitments?: CommitmentDto[]; 
  contracts?: ContractDto[];
  invoicingParties?: InvoicingPartyDto[];  
  timelineErrors: TimelineErrors[];
  contractBudgetErrors: ContractBudgetErrors[];
  type: 'group' | 'element' | 'summary';
};

export type CostElement = {
  key: string;
  catalogElement: DecoratedElement<CostCatalogElement>;
  costElement?: CostElementDto;
  showError: boolean;
  commitments?: CommitmentDto[]; 
  contracts?: ContractDto[];
  invoicingParties?: InvoicingPartyDto[];
  timelineErrors: TimelineErrors[];
  contractBudgetErrors: ContractBudgetErrors[];
}

const unassignedGroupId = 'unassigned';

/**
 * Converts CostCatalogElementDto[] to DecoratedCostElement[]
 */
function decorateElements(
  elements?: CostCatalogElementDto[],
  level = 0,
  parent?: DecoratedElement<CostCatalogElement>
): DecoratedElement<CostCatalogElement>[] {
  if (elements == null || elements.length === 0) return [];

  return elements.map((element, i) => {

    const decorated: DecoratedElement<CostCatalogElement> = {
      element: {
        key: element.id,
        group: element ?? undefined,
        showError: element.modelValues.calculationState === 'Overflow' || element.timelineErrors.length > 0,
        type: 'group',
        timelineErrors: element.timelineErrors,
        contractBudgetErrors: element.contractBudgetErrors,
        costElements: [],
        commitments: element.commitments,
        contracts: element.contracts,
        invoicingParties: element.invoicingParties
      },
      categoryId: element.id,
      level: level,
      parent,
      children: [],
      disableClick: element.id === unassignedGroupId,
    };

    const costElements = element.costElements.map((ce, h) => {
      const decoratedCostElement: CostElement = {
          key:
            (ce.costElement?.elementId
              ? ce.costElement?.elementId
              : ce.costElement?.elementId) ?? i.toString(),
          // TODO if reference cost element exists, we need a different solution in the FE to show the user that an old version is shown
          costElement: ce.costElement || undefined,
          showError: (ce.costElement?.timelineErrors.length ?? 0) > 0
          || (ce.costElement?.contractBudgetErrors.length ?? 0) > 0 ,
          timelineErrors: ce.costElement?.timelineErrors ?? [],
          contractBudgetErrors: ce?.costElement?.contractBudgetErrors ?? [],
          commitments: ce.commitments,
          contracts: ce.contracts,
          invoicingParties: ce.invoicingParties,
          catalogElement: decorated
      }

      return decoratedCostElement;
    })

    const children = element.children ?? [];

    decorated.element.costElements?.push(...costElements);
    decorated.children = decorateElements(children, level + 1, decorated);
    return decorated;
  });
}

export const useCosts = (
  costs: CostCatalogElementDto[],
  costsFilters?: CostsFilterOption[],
  searchValue?: string,
  searchResults?: string[] | null,
) => {
  return useMemo(() => {

    // decorate all elements while preserving all items and structure
    let filteredElements = decorateElements(costs);

    if (searchValue && searchResults) {
      filteredElements = filterElementsById(filteredElements, searchResults);
    }

    if (costsFilters?.length) {
      filteredElements = filterElementsByCostsFilter(filteredElements, costsFilters);
    }

    return filteredElements;
  }, [costs, costsFilters, searchValue, searchResults]);
};
