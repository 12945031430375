import { getUsageTypes, useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import {
  TaxonomyReadModel,
  TaxonomyType,
  TaxonomyUsageType,
  useApiPostCreateTaxonomyMutation, UserDefinedFieldPayload,
} from '@client/shared/api';
import {
  BaseSelect,
  Button,
  Form,
  FormField,
  FormRefHandle,
  FormWatch,
  EditNodeIcon,
  Modal,
  ModalOnCloseProps,
  NumberInput,
  TextInput,
  TimeLimitIcon, LoadingIndicator,
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { BuildingOfficeIcon, CalculatorIcon, DocumentIcon, TagIcon } from '@heroicons/react/24/outline';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAllowedTaxonomyTypes, useMgfExclusiveTypes } from '../../hooks';
import {
  BuildingItemAddFormValidationSchema,
  BuildingItemAddFormValidationValues,
} from './BuildingItemAddFormValidationSchema';
import { EditUserDefinedFields } from '@client/project/shared';

interface BuildingItemAddParentModalProps extends ModalOnCloseProps {
  child: TaxonomyReadModel;
}

export const BuildingItemAddParentModal = ({ child, onClose }: BuildingItemAddParentModalProps) => {
  const { t } = useTranslation();
  const formRef = useRef<FormRefHandle<BuildingItemAddFormValidationValues>>();

  const loadedProjectId = useLoadedProjectId();

  const loadedVariantId = useLoadedVariantId();
  const { forCreatingParent: forCreation } = useAllowedTaxonomyTypes(child);
  const mgfExclusiveTypes = useMgfExclusiveTypes(child);

  // for custom fields
  const [udfUpdatePayload, setUdfUpdatePayload] = useState<UserDefinedFieldPayload[] | undefined>();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [customFieldsAreValid, setCustomFieldsAreValid] = useState(true)

  const allowedTypes = forCreation.map((taxonomyType) => ({
    label: t(`projectTaxonomy.taxonomyType.${taxonomyType}`),
    value: taxonomyType,
  }));

  const grossFloorRestValue: number | undefined =
    child?.parent?.sizes?.grossFloorRestValue?.value !== null &&
    child?.parent?.sizes?.grossFloorRestValue?.value !== undefined &&
    child.parent.sizes.grossFloorRestValue?.value > 0
      ? child.parent.sizes.grossFloorRestValue?.value
      : undefined;
  const netFloorRestValue: number | undefined =
    child?.parent?.sizes?.netFloorRestValue?.value &&
    child?.parent?.sizes?.netFloorRestValue?.value !== undefined &&
    child.parent.sizes.netFloorRestValue?.value > 0
      ? child.parent.sizes.netFloorRestValue?.value
      : undefined;
  const rentalSpaceRestValue: number | undefined =
    child?.parent?.sizes?.rentalSpaceRestValue?.value !== null &&
    child?.parent?.sizes?.rentalSpaceRestValue?.value !== undefined &&
    child.parent.sizes.rentalSpaceRestValue?.value > 0
      ? child.parent.sizes.rentalSpaceRestValue?.value
      : undefined;

  const [postItem, { isLoading }] = useApiPostCreateTaxonomyMutation();

  const handleSubmit = async (data: BuildingItemAddFormValidationValues) => {
    if (loadedVariantId == null || !customFieldsAreValid) return;

    try {
      await safeMutation(
        postItem,
        {
          calculationModelId: loadedVariantId,
          projectId: loadedProjectId ?? 'unset',
          body: {
            childId: child.itemId,
            name: data.name,
            customerName: data.customerName,
            bgf: mgfExclusiveTypes.includes(data.type as TaxonomyType) ? undefined : data.bgf,
            ngf: mgfExclusiveTypes.includes(data.type as TaxonomyType) ? undefined : data.ngf,
            rentalSpace: data.rentalSpace,
            type: data.type as TaxonomyType,
            usageType:
              data.usageType !== undefined && data.usageType !== ''
                ? (data.usageType as TaxonomyUsageType)
                : ('Undefined' as TaxonomyUsageType),
            amount: data.amount,
            userDefinedFieldsPayload: udfUpdatePayload?.length ? udfUpdatePayload : undefined
          },
        },
        isLoading
      );

      onClose(true);
    } catch (error) {
      console.log(error);
    }
  };

  const defaultFormValues = {
    name: '',
    customerName: '',
    bgf: grossFloorRestValue,
    ngf: netFloorRestValue,
    rentalSpace: rentalSpaceRestValue,
    type: '',
    usageType: child?.parent?.itemUsageType ?? '',
  };

  return (
    <Form<BuildingItemAddFormValidationValues>
      onSubmit={(data) => {
        setIsFormSubmitted(true)
        handleSubmit(data)
      }}
      validationSchema={BuildingItemAddFormValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
      ref={formRef}
    >
      {isLoading && <LoadingIndicator text={t('projectTaxonomy.creatingTaxonomyLoadingIndicator')} mode="overlay-window" />}
      <Modal.Header title={t('projectTaxonomy.addBuildingParentElementTitle', { element: child.itemName })} />
      <Modal.Content className="divide-gray-100 divide-y my-6">
        <FormField name="name">
          {(control) => (
            <TextInput
              label={t('projectTaxonomy.addBuildingElementLabelName')}
              icon={<EditNodeIcon />}
              {...control}
            />
          )}
        </FormField>
        <FormField name="customerName">
          {(control) => (
            <TextInput
              label={t('projectTaxonomy.addBuildingElementLabelCustomerName')}
              icon={<EditNodeIcon />}
              {...control}
            />
          )}
        </FormField>
        <FormField name="type">
          {(control) => (
            <BaseSelect
              label={t('projectTaxonomy.addBuildingElementLabelType')}
              options={allowedTypes}
              icon={<TimeLimitIcon />}
              {...control}
            />
          )}
        </FormField>

        <FormWatch<BuildingItemAddFormValidationValues>
          fieldNames={['type']}
          onChange={({ type }) => {
            if (type === 'Parking') {
              formRef.current?.setValue('usageType', 'Traffic');
            }
          }}
        >
          {({ type }) => (
            <>
              <FormField name="usageType">
                {(control) => (
                  <BaseSelect
                    label={t('projectTaxonomy.addBuildingElementLabelUsageType')}
                    options={getUsageTypes()}
                    icon={<TimeLimitIcon />}
                    disabled={type === 'Parking'}
                    {...control}
                  />
                )}
              </FormField>
              {!mgfExclusiveTypes.includes(type as TaxonomyType) && (
                <>
                  <FormField name="bgf">
                    {(control) => (
                      <NumberInput
                        label={`${t('projectTaxonomy.buildingBgf')} (${
                          child?.parent?.sizes?.grossFloorRestValue?.unit ?? child?.parent?.sizes?.bgf?.unit
                        })`}
                        icon={<TagIcon />}
                        {...control}
                      />
                    )}
                  </FormField>

                  <FormField name="ngf">
                    {(control) => (
                      <NumberInput
                        label={`${t('projectTaxonomy.buildingNgf')} (${
                          child?.parent?.sizes?.netFloorRestValue?.unit ?? child?.parent?.sizes?.ngf?.unit
                        })`}
                        icon={<BuildingOfficeIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                </>
              )}
            </>
          )}
        </FormWatch>
        <FormField name="rentalSpace">
          {(control) => (
            <NumberInput
              label={`${t('projectTaxonomy.buildingMfg')} (${child?.parent?.sizes?.rentalSpaceRestValue?.unit ?? child?.parent?.sizes?.rentalSpace?.unit})`}
              icon={<DocumentIcon />}
              {...control}
            />
          )}
        </FormField>
        <FormWatch<BuildingItemAddFormValidationValues> fieldNames={['type']}>
          {({ type }) => (
            <FormField name="amount">
              {(control) => (
                <NumberInput label={t('projectTaxonomy.buildingAmount')} icon={<CalculatorIcon />} {...control} />
              )}
            </FormField>
          )}
        </FormWatch>
        <EditUserDefinedFields
          type="Taxonomy"
          setUpdatePayload={setUdfUpdatePayload}
          isSubmitted={isFormSubmitted}
          updateIsValid={setCustomFieldsAreValid}
          hasPadding={false}
        />
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
          {t('common.cancel')}
        </Button>
        <Button variant="primary" formSubmit onClick={() => setIsFormSubmitted(true)}>
          {t('projectTaxonomy.addBuildingElementAction')}
        </Button>
      </Modal.Controls>
    </Form>
  );
};
