import { CostCatalogElement, DecoratedElement } from '../hooks';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function filterElementsById(elements: DecoratedElement<any>[],  searchResults: string[]): DecoratedElement<any>[] {
  if (!searchResults.length) return [];

  return elements.filter((element) => {
    let match =
      searchResults.includes(element.element?.group?.id) ||
      searchResults.includes(element.element?.group?.groupId) ||
      searchResults.includes(element.element?.key) ||
      searchResults.includes(element.element?.costElementId) ||
      searchResults.includes(element.element?.group?.riskElementId) ||
      searchResults.includes(element.element?.group?.earningsElementId) ||
      searchResults.includes(element.element?.earningsElement?.id)  ||
      searchResults.includes(element.element?.riskElement?.riskElementId);

    if (!match && element.element?.costElements) {
      const parsedElem = element as DecoratedElement<CostCatalogElement>;
      if (parsedElem.element?.group?.costElementId) {
        match = searchResults.includes(parsedElem.element.group.costElementId);
      }
      if (!match && parsedElem.element?.costElements?.length) {
        const costElementMatch = parsedElem.element.costElements.find((ce) => {
          return searchResults.includes(ce.key);
        });
        if (costElementMatch) {
          element.element.costElements = parsedElem.element.costElements.filter((ce) => searchResults.includes(ce.key));
        }
        match = !!costElementMatch;
      }
    }
    if (element.children.length) {
      const childrenMatch = filterElementsById(element.children, searchResults);
      element.children = [...childrenMatch];
      if (!match) {
        match = childrenMatch.length > 0;
      }
    }

    element.match = match;
    return match;
  });
}
