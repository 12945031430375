import { FinancingTransactionReadModel } from '@client/shared/api';
import { formatDate, formatPercentage } from '@client/shared/utilities';
import classNames from 'classnames';
import { Fragment, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedCurrency } from '@client/project/shared';

interface FinancingTransactionsRowProps {
  transaction: FinancingTransactionReadModel;
  fixedInterestRate?: boolean;
  showDateMark?: boolean;
  applyOpacity?: boolean;
}

export const FinancingTransactionsRow = ({
  transaction,
  showDateMark,
  applyOpacity,
  fixedInterestRate,
}: FinancingTransactionsRowProps) => {
  const { t } = useTranslation();

  const date = new Date(transaction.paymentOn);

  const interestSum = useMemo(() => {
    let sum = 0;

    if (transaction.euriborRate) sum += transaction.euriborRate;
    if (transaction.bankMarge) sum += transaction.bankMarge;
    if (transaction.liquidityPremium) sum += transaction.liquidityPremium;

    return sum / 100;
  }, [transaction]);

  const interestParts = useMemo(() => {
    const bottom: BottomValue[] = [];

    if (transaction.euriborRate != null) {
      bottom.push({
        label: t('projectCalculate.transactionsHeader.EuriborRate'),
        value: formatPercentage(transaction.euriborRate / 100),
      });
    }

    if (transaction.bankMarge != null) {
      bottom.push({
        label: t('projectCalculate.transactionsHeader.BankMarge'),
        value: formatPercentage(transaction.bankMarge / 100),
      });
    }
    if (transaction.liquidityPremium) {
      bottom.push({
        label: t('projectCalculate.transactionsHeader.LiquidityPremium'),
        value: formatPercentage(transaction.liquidityPremium / 100),
      });
    }

    return bottom;
  }, [t, transaction.bankMarge, transaction.euriborRate, transaction.liquidityPremium]);

  const endBalanceParts = useMemo(() => {
    return [
      {
        label: t('projectCalculate.transactionsHeader.Interest'),
        value: <FormattedCurrency amount={transaction.endingInterestBalance} />,
      },
      {
        label: t('projectCalculate.transactionsHeader.Principle'),
        value: <FormattedCurrency amount={transaction.endingPrincipleBalance} />,
      },
    ];
  }, [t, transaction.endingInterestBalance, transaction.endingPrincipleBalance]);

  const totalParts = useMemo(() => {
    return [
      {
        label: t('projectCalculate.transactionsHeader.InterestPart'),
        value: <FormattedCurrency amount={transaction.amountTowardsInterest} />,
      },
      {
        label: t('projectCalculate.transactionsHeader.PrinciplePart'),
        value: <FormattedCurrency amount={transaction.amountTowardsPrincipe} />,
      },
    ];
  }, [t, transaction.amountTowardsInterest, transaction.amountTowardsPrincipe]);

  const transactionTypes = {
    InterestCalculation: t('projectCalculate.transactionsLabel.Interest'),
    PaybackAnnuityPercentage: t('projectCalculate.transactionsLabel.Payback'),
    CommitmentInterestCalculation: t('projectCalculate.transactionsLabel.CommitmentInterest'),
    Payout: t('projectCalculate.transactionsLabel.Payout'),
    Payback: t('projectCalculate.transactionsLabel.Payback'),
    Grant: t('projectCalculate.transactionsLabel.Grant'),
  };

  return (
    <div className="relative flex w-full bg-white hover:bg-neutral-100 shadow-sm min-h-[52px] py-1 rounded text-sm items-center px-8">
      <div className="w-1/12 flex">
        <div className={classNames('text-center text-lg text-gray-800 font-bold', { 'opacity-50': applyOpacity })}>
          <div>{date.getDate()}</div>
          <div className="text-[11px] leading-[10px] font-bold">
            {`${formatDate(date, {
              includeDay: false,
              shortYear: true,
              shortMonth: true,
            })}`}
          </div>
        </div>

        {showDateMark && <DateMarker className="left-[26px] -bottom-[12px]" key={'date-marker'} />}
      </div>
      <div className={classNames('w-3/12 text-lg text-gray-800 font-bold', { 'opacity-50': applyOpacity })}>
        <div>{transactionTypes[transaction.type as keyof typeof transactionTypes] ?? ''}</div>
      </div>
      <div className="w-2/12">
        <FinancingTransactionsNumberColumn top={<FormattedCurrency amount={transaction.beginningTotalBalance} />} />
      </div>
      <div className="w-2/12">
        {fixedInterestRate ? (
          <FinancingTransactionsNumberColumn
            top={formatPercentage(transaction.interestRate ? transaction.interestRate / 100 : null)}
          />
        ) : (
          <FinancingTransactionsNumberColumn top={formatPercentage(interestSum)} bottom={interestParts} />
        )}
      </div>
      <div className="w-2/12">
        <FinancingTransactionsNumberColumn
          top={<FormattedCurrency amount={transaction.endingPrincipleBalance + transaction.endingInterestBalance} />}
          bottom={endBalanceParts}
        />
      </div>
      <div className="w-2/12">
        <FinancingTransactionsNumberColumn
          top={<FormattedCurrency amount={transaction.amount} />}
          topColor="text-gray-800"
          bottom={totalParts}
        />
      </div>
    </div>
  );
};

type BottomValue = {
  value: string | ReactNode;
  label: string;
};

interface FinancingTransactionsNumberColumnProps {
  top: string | ReactNode;
  topColor?: string;
  bottom?: BottomValue[];
}

const FinancingTransactionsNumberColumn = ({ top, bottom, topColor }: FinancingTransactionsNumberColumnProps) => {
  return (
    <div className="flex flex-column justify-end">
      <div className={classNames('text-end', topColor ? topColor : 'text-slate-500')}>
        <div className="text-lg font-bold">{top}</div>
        {bottom && (
          <div className="flex flex-col justify-end text-xxs text-slate-500">
            {bottom.map((value, index) => (
              <Fragment key={'column' + index}>
                <div>
                  <span className="font-bold">{value.value}</span>
                  &nbsp;{value.label}
                </div>
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

interface DateMarkerProps {
  className?: string;
}

const DateMarker = ({ className }: DateMarkerProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'absolute z-40 text-center text-[11px] font-bold px-2 text-white rounded-xl bg-orange-400',
        className
      )}
    >
      {t('projectCalculate.timeLineToday')}
    </div>
  );
};
