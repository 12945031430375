import { useState } from 'react';
import { VatReturnModal } from './VatReturnModal';
import { VatDistribution, useApiPostCreateVatCostElementMutation } from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { LoadingIndicator } from '@client/shared/toolkit';
import { t } from 'i18next';

interface VatCreateModalProps {
  catalogId?: string;
  onClose: () => void;
  onSave: (id: string) => void;
}

export const VatCreateModal = ({ catalogId, onClose, onSave }: VatCreateModalProps) => {
  const [vatReturnPercentage, setVatReturnPercentage] = useState<number>(0);
  const [returnDelayMonths, setReturnDelayMonths] = useState<number | null | undefined>(null);
  const [selectedCostElementIds, setSelectedCostElementIds] = useState<string[]>([]);
  const [selectedUDF, setSelectedUDF] = useState<string>('none');
  const [selectedUDFLabels, setSelectedUDFLabels] = useState<string[]>([]);

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const [createVatElement, { isLoading: isCreating }] = useApiPostCreateVatCostElementMutation();

  const handleSave = async (vatDistributions: VatDistribution[]) => {
    try {
      await safeMutation(
        createVatElement,
        {
          calculationModelId: loadedVariantId ?? 'unset',
          projectId: loadedProjectId ?? 'unset',
          body: {
            vatCostCatalogId: catalogId ?? '',
            vatDistributions: vatDistributions,
            costGroupIds: selectedCostElementIds,
            userDefinedFieldId: selectedUDF === 'none' ? null : selectedUDF,
            userDefinedFieldLabelIds: selectedUDFLabels.length === 0 ? null : selectedUDFLabels,
            vatReturnPercentage: vatReturnPercentage,
            delayMonths: returnDelayMonths ?? 0,
          },
        },
        isCreating,
      );

      onSave('');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {isCreating && <LoadingIndicator text={t('projectCalculate.vatReturnCreateLoading') ?? ''} mode="overlay" />}
      <VatReturnModal
        vatReturnPercentage={vatReturnPercentage}
        setVatReturnPercentage={setVatReturnPercentage}
        selectedCostElementIds={selectedCostElementIds}
        setSelectedCostElementIds={setSelectedCostElementIds}
        selectedUDF={selectedUDF}
        setSelectedUDF={setSelectedUDF}
        selectedUDFLabels={selectedUDFLabels}
        setSelectedUDFLabels={setSelectedUDFLabels}
        returnDelayMonths={returnDelayMonths}
        setReturnDelayMonths={setReturnDelayMonths}
        onClose={onClose}
        disabled={false}
        vatDistributions={undefined}
        onSave={handleSave}
      />
    </>
  );
};
