export interface FlattenedTaxonomyItem<T> {
  taxonomyItem: T;
  depth: number;
}

interface TaxonomyItemWithChildren<T> {
  orderWithinParent: number;
  children: T[];
}

export const flattenTaxonomyTree = <T extends TaxonomyItemWithChildren<T>>(
  taxonomyItem: T | undefined,
  depth = 0,
  childrenIds: string[] = []
): FlattenedTaxonomyItem<T>[] => {
  if (!taxonomyItem) return [];

  const children = [...taxonomyItem.children] || [];
  const flattenedChildren = children
    .sort((a, b) => a.orderWithinParent - b.orderWithinParent)
    .flatMap((child) => flattenTaxonomyTree(child, depth + 1));
  return [{ taxonomyItem, depth }, ...flattenedChildren];
};

export interface FlattenedTaxonomyPlotItem<T> {
  plotItem: T;
  depth: number;
}
interface TaxonomyPlotItemWithChildren<T> {
  plots: T[];
}
export const flattenTaxonomyPlotTree = <T extends TaxonomyPlotItemWithChildren<T>>(
  plotItem: T | undefined,
  depth = 0
): FlattenedTaxonomyPlotItem<T>[] => {
  if (!plotItem) return [];

  const children = [...plotItem.plots] || [];
  const flattenedChildren = children
    .flatMap((child) => flattenTaxonomyPlotTree(child, depth + 1));
  return [{ plotItem, depth }, ...flattenedChildren];
};
