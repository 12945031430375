import { 
    AuthCatalogElementReadModel,
    AuthEffectiveCatalogElementPermissionReadModel,
} from '@client/shared/api';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { getFontWeight } from './CatalogTemplateUtilities';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { CheckBox } from '@client/shared/toolkit';

interface EffectiveCatalogElementPermissionProps {
    level: number;
    borderColor: string;
    catalogElement: AuthCatalogElementReadModel
    catalogElementPermissions: AuthEffectiveCatalogElementPermissionReadModel[]
}

export const EffectiveCatalogElementPermission = ({
    level,
    borderColor,
    catalogElement,
    catalogElementPermissions
}: EffectiveCatalogElementPermissionProps) => {
    
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [elementPermission, setElementPermission] = useState<AuthEffectiveCatalogElementPermissionReadModel | undefined>();

    useEffect(() =>{
        setElementPermission(catalogElementPermissions.find(x => x.catalogElementId === catalogElement.id));
    }, [catalogElement, catalogElementPermissions]);

    return (
        <div>
            <div className="flex flex-col text-lg">
                <div className="flex flex-col text-lg">
                    <div className='border-b-2 border-gray-300 bg-white pr-2'>
                        <div className={classNames('flex flex-row items-center py-2 font-bold border-l-4', getFontWeight(level), borderColor)}>
                            <div className='w-1/12 pl-6' onClick={() => setIsExpanded(!isExpanded)}>
                                {catalogElement.children.length > 0 &&
                                    <>
                                        {isExpanded 
                                            ? (<ChevronDownIcon className='w-4 h-4' />) 
                                            : (<ChevronRightIcon className='w-4 h-4' />)}
                                    </>
                                }
                            </div>
                            <div className='w-1/12'> 
                                {catalogElement.code}
                            </div>
                            <div className='w-8/12'> 
                                {catalogElement.description}
                            </div>
                            <div className='flex items-center justify-center w-1/12'>
                                <div className='flex items-center justify-center w-6 h-6'>
                                    <CheckBox 
                                        disabled={true}
                                        checked={elementPermission?.canReadElement ?? false}
                                        onChange={() => {/*nothing to do*/}}/>
                                </div>
                            </div>
                            <div className='flex items-center justify-center w-1/12'>
                                <div className='flex items-center justify-center w-6 h-6'>
                                    <CheckBox 
                                        disabled={true}                                    
                                        checked={elementPermission?.canWriteElement ?? false}
                                        onChange={() => {/*nothing to do*/}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isExpanded &&
                    <>
                        {catalogElement.children.map((child, index) => (
                            <EffectiveCatalogElementPermission 
                                key={index} 
                                level={level+1}
                                catalogElement={child}
                                borderColor={borderColor}
                                catalogElementPermissions={catalogElementPermissions}
                            />
                        ))}
                    </>
                }                    
            </div>
        </div>
  );
};