import React, { useState } from 'react';
import { useDocumentTitle } from '@client/shared/toolkit';
import { AppLayout, AppLayoutTitle, AppLayoutMenu, AppLayoutContent } from '../components';
import { useTranslation } from 'react-i18next';
import { ProjectsContainer, ProjectsHeader } from '@client/main/projects';
import { useUi } from '@client/shared/store';

export const ProjectsRoute = () => {
  const { t } = useTranslation();

  useDocumentTitle({ title: t('app.menuProjects') });
  const ui = useUi();
  const tenant = ui.appUser.tenant;

  const [searchText, setSearchText] = useState('');
  const [showAllCheckboxes, setShowAllCheckboxes] = useState(false);

  const [searchResults, setSearchResults] = useState<string[]>([]);

  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false)

  const displayCheckboxes = () => {
    setShowAllCheckboxes(!showAllCheckboxes);
  };

  return (
    <AppLayout updateHeaderCollapsed={setIsHeaderCollapsed}>
      <AppLayoutTitle>{tenant?.name ?? t('app.project', { count: 3 })}</AppLayoutTitle>
      <AppLayoutMenu>
        <ProjectsHeader
          searchText={searchText}
          updateSearchResults={setSearchResults}
          handleSearchValueUpdate={setSearchText}
          displayCheckboxes={displayCheckboxes}
          showAllCheckboxes={showAllCheckboxes}
          inverse={isHeaderCollapsed}
        />
      </AppLayoutMenu>
      <AppLayoutContent>
        <ProjectsContainer
          searchText={searchText}
          showAllCheckboxes={showAllCheckboxes}
          searchResults={searchResults}
          setSearchText={setSearchText}
        />
      </AppLayoutContent>
    </AppLayout>
  );
};
