import { useApiGetDevelopmentCommandListQuery } from '@client/shared/api';
import classNames from 'classnames';
import { LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

export const DevelopmentCommandList = () => {
  const { data, isFetching } = useApiGetDevelopmentCommandListQuery();
  const { t } = useTranslation();

  const commands = data ?? [];

  return (
    <div>
      {isFetching && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
      <div className="text-lg font-bold my-2">Command List</div>

      <div className="">
        <div className="ml-4">
          <table className="divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className=" pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Name
                </th>
                <th scope="col" className="px-3  text-left text-sm font-semibold text-gray-900">
                  Email
                </th>
                <th scope="col" className="px-3  text-left text-sm font-semibold text-gray-900">
                  Role
                </th>
                <th scope="col" className="px-3  text-left text-sm font-semibold text-gray-900">
                  TenantOwnership
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {commands.map((command, idx) => (
                <tr key={command.fullName} className={classNames(idx % 2 === 0 ? ' bg-slate-200' : '')}>
                  <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    {command.fullName}
                  </td>
                  <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">{command.baseClass}</td>
                  <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
                    {command.permissions.join(', ')}
                  </td>
                  <td className="relative whitespace-nowrap py-1 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    {command.requiresOwnership ? 'YES' : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
