import * as yup from 'yup';
import { InferType } from 'yup';

export const ContractFormValidationSchema = yup.object({
  code: yup.string().required('validation.required'),
  name: yup.string().required('validation.required'),
  files: yup.mixed<FileList>().optional()
});

export type ContractFormValidationValues = InferType<typeof ContractFormValidationSchema>;
