import { DealReadModel, ContractItemCategory } from '@client/shared/api';
import React, { useState } from 'react';
import { DealContractItemsGroup } from './DealContractItemsGroup';
import { getContractItemsSumWithoutVat, getDealVat, getVatValue } from '../DealCalculation';
import { SlideOver, DecoratedCard, HouseMoneyIcon, SlideOverTitle } from '@client/shared/toolkit';
import { DealContractItemSlideOver } from './DealContractItemSlideOver';
import { useTranslation } from 'react-i18next';
import { ArrowUpTrayIcon } from '@heroicons/react/20/solid';
import { FormattedCurrency } from '@client/project/shared';

interface DealContractItemsProps {
  deal: DealReadModel;
  isReadOnly:boolean;
}

export const DealContractItems = ({ deal, isReadOnly }: DealContractItemsProps) => {
  const { t } = useTranslation();

  const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const [modalCategory, setModalCategory] = useState<ContractItemCategory>('None');

  const handleOnAddRentItem = (category: ContractItemCategory) => {
    setModalCategory(category);
    setShowAddForm(true);
  };

  const rentItemSum = getContractItemsSumWithoutVat(deal.contractItems);
  const vatValue = getVatValue(deal);
  const vat = getDealVat(deal);

  return (
    <div>
      <SlideOverTitle title={t('projectRent.dealContractItemPriceCalculationTitle')} />

      <DecoratedCard>
        <DecoratedCard.Header showActionButton={false} icon={<ArrowUpTrayIcon />}>
          <HouseMoneyIcon className="w-6" />
          <span>{t('projectRent.dealDocumentTitle')}</span>
        </DecoratedCard.Header>
        <DecoratedCard.Content className="px-1 py-3 bg-gray-100 w-full h-full flex items-center justify-center">
          <div className="w-full">
            {deal.type === 'Rent' && (
              <div>
                <DealContractItemsGroup category="Rent" deal={deal} onAdd={handleOnAddRentItem} isReadOnly={isReadOnly} />
                <DealContractItemsGroup category="Utility" deal={deal} onAdd={handleOnAddRentItem} isReadOnly={isReadOnly}/>
                <DealContractItemsGroup category="Additional" deal={deal} onAdd={handleOnAddRentItem} isReadOnly={isReadOnly}/>
              </div>
            )}
            {deal.type === 'Sell' && (
              <div>
                <DealContractItemsGroup category="Sell" deal={deal} onAdd={handleOnAddRentItem} isReadOnly={isReadOnly}/>
                <DealContractItemsGroup category="Special" deal={deal} onAdd={handleOnAddRentItem} isReadOnly={isReadOnly} />
              </div>
            )}
            <div className="border-b-2 border-slate-300 ml-20 mr-5 mt-5 mb-1" />
            <div className="border-b-2 border-slate-300 ml-20 mr-5 mb-5" />

            <div className="flex">
              <div className="w-6/12" />
              <div className="flex w-3/12 items-center text-gray-400 text-lg">
                {deal.type === 'Rent' && t('projectRent.dealContractRentNet')}
                {deal.type === 'Sell' && t('projectRent.dealContractSalePriceNet')}
              </div>
              <div className="flex-col w-2/12 mr-5 text-end">
                <div className="text-lg font-semibold"><FormattedCurrency amount={rentItemSum} /></div>
                <div className="text-gray-400 h-4 text-xs font-bold">
                  {deal.type === 'Rent' && t('projectRent.dealContractItemMonthly')}
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="w-6/12" />
              <div className="flex w-3/12 items-center text-gray-400 text-lg">
                {t('projectRent.dealContractTaxesPercent', {
                  percent: <FormattedCurrency amount={vat} options={{ formatStyle: 'percent', maxDigits: 2 }} />,
                })}
              </div>
              <div className="flex-col w-2/12 mr-5 text-end">
                <div className="text-lg font-semibold"><FormattedCurrency amount={vatValue} /></div>
                <div className="text-gray-400 h-4 text-xs font-bold">
                  {deal.type === 'Rent' && t('projectRent.dealContractItemMonthly')}
                </div>
              </div>
            </div>

            <div className="flex mb-5">
              <div className="w-6/12" />
              <div className="flex w-3/12 items-center font-bold text-gray-400 text-lg">
                {deal.type === 'Rent' && t('projectRent.dealContractRentGross')}
                {deal.type === 'Sell' && t('projectRent.dealContractSalePriceGross')}
              </div>
              <div className="flex-col w-2/12 mr-5 text-end">
                <div className="text-lg font-bold"><FormattedCurrency amount={vatValue + rentItemSum} /></div>
                <div className="text-gray-400 h-4 text-xs font-bold">
                  {deal.type === 'Rent' && t('projectRent.dealContractItemMonthly')}
                </div>
              </div>
            </div>
          </div>
        </DecoratedCard.Content>
      </DecoratedCard>

      <SlideOver isOpen={showAddForm} onClose={() => setShowAddForm(false)}>
        <DealContractItemSlideOver
          isReadOnly={isReadOnly}
          deal={deal}
          dealId={deal.id}
          category={modalCategory}
          isAddMode={true}
          onClose={() => setShowAddForm(false)}
        />
      </SlideOver>
    </div>
  );
};
