import {
  CalculationModelMetadata,
  useApiPostUpdateCalculationModelMetadataMutation,
  CalculationModelType,
} from '@client/shared/api';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SlideOver, SlideOverOnCloseProps, Button, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { CalculationModelInfo } from './CalculationModelInfo';
import { CalculationModelSavePoints } from './CalculationModelSavePoints';
import classNames from 'classnames';
import { InformationCircleIcon, Square3Stack3DIcon } from '@heroicons/react/24/outline';
import { TFunction } from 'i18next';

interface VariantSlideOverProps extends SlideOverOnCloseProps {
  variant?: CalculationModelMetadata;
}

type Subarea = 'Info' | 'History';

type VariantTypeStyle = {
  headerClassNames: string;
  text: string;
};

type VariantTypeStyles = {
  [Type in CalculationModelType]: VariantTypeStyle;
};

function variantTypeStyle(t: TFunction, versionNumber?: string): VariantTypeStyles {
  return {
    Version: {
      headerClassNames: 'bg-green-700',
      text: t('project.versionNumber', { version: versionNumber }),
    },
    Variant: {
      headerClassNames: 'bg-blue-700',
      text: t('project.variantNumber', { version: versionNumber }),
    },
    ArchivedVariant: {
      headerClassNames: 'bg-blue-400',
      text: t('project.archivedVariantNumberReadonly', { version: versionNumber }),
    },
    UserSnapshot: {
      headerClassNames: 'bg-gray-700',
      text: t('project.userSnapshotReadOnly'),
    },
    SystemSnapshot: {
      headerClassNames: 'bg-gray-700',
      text: t('project.systemSnapshotReadOnly'),
    },
    ArchivedVersion: {
      headerClassNames: 'bg-green-400',
      text: t('project.archivedVersionNumberReadonly', { version: versionNumber }),
    },
  };
}

export const VariantSlideOver = ({ onClose, variant }: VariantSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const [title, setTitle] = useState<string>(variant?.name ?? '');
  const [description, setDescription] = useState<string>(variant?.description ?? '');
  const [irr, setIrr] = useState<number>(variant?.plannedIrr.value ?? 0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [subarea, setSubarea] = useState<Subarea>('Info');
  const [postMetadata, { isLoading }] = useApiPostUpdateCalculationModelMetadataMutation();

  const handleOnClose = (wasDeleted: boolean) => {
    if (modalOpen === false) {
      onClose(false);
    }

    if (wasDeleted === true) {
      onClose(false);
    }
  };

  const handleOnSave = async () => {
    if (variant == null) {
      return;
    }

    try {
      await safeMutation(
        postMetadata,
        {
          projectId: variant.projectId,
          calculationModelId: variant.id,
          body: {
            title,
            description,
            plannedIrr: irr,
            startDate: variant?.calculationModelStartDate ?? '',
            endDate: variant?.calculationModelEndDate ?? ''
          },
        },
        isLoading
      );

      handleOnClose(false);
    } catch (e) {
      console.error(e);
    }
  };

  const type = variant?.type ?? '';
  const version = variant?.version ?? '';
  const style = type !== '' ? variantTypeStyle(t, version)[type] : null;

  return (
    <>
      {isLoading && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
      <SlideOver.Header
        title={variant?.name ?? ''}
        subTitle={style?.text ?? ''}
        onClose={onClose}
        backgroundClassName={style?.headerClassNames}
      />
      <SlideOver.Content
        onKeyEnter={() => submitRef.current?.click()}
      >
        <div className="flex flex-row flex-wrap pt-2 mb-1">
          <button
            type="button"
            className={classNames('px-4 pb-2 text text-gray-900 block ', {
              'font-bold border-b border-slate-200': subarea === 'Info',
            })}
            onClick={() => setSubarea('Info')}
          >
            <InformationCircleIcon className="w-5 h-5 mr-2 inline-block" />
            {t('projectVariants.variantBadgeVersion')}
          </button>
          <button
            type="button"
            className={classNames('px-4 pb-2 text text-gray-900 block', {
              'font-bold border-b border-slate-200': subarea === 'History',
            })}
            onClick={() => setSubarea('History')}
          >
            <Square3Stack3DIcon className="w-5 h-5 mr-2 inline-block" />
            {t('projectVariants.tabHistory')}
          </button>
        </div>
        {subarea === 'Info' && (
          <CalculationModelInfo
            model={variant}
            title={title}
            irr={irr}
            description={description}
            onTitleEdited={setTitle}
            onDescriptionEdited={setDescription}
            onIrrEdited={setIrr}
            onModalOpenChange={setModalOpen}
            onClose={handleOnClose}
          />
        )}
        {subarea === 'History' && variant && (
          <CalculationModelSavePoints model={variant} onClose={() => onClose(false)} />
        )}
      </SlideOver.Content>
      <SlideOver.Controls>
        <div className="flex justify-end">
          <Button variant="secondary" className="mr-2" onClick={() => handleOnClose(false)}>
            {t('common.close')}
          </Button>
          {variant?.type !== 'SystemSnapshot' &&
            <Button variant="primary" onClick={handleOnSave} innerRef={submitRef}>
              {t('common.save')}
            </Button>
          }
        </div>
      </SlideOver.Controls>
    </>
  );
};
