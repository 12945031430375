import { DealReadModel } from '@client/shared/api';
import { DealRentConditions } from './DealRentConditions';
import { DealRentSecurityCondition } from './DealRentSecurityCondition';
import { useTranslation } from 'react-i18next';
import { SlideOverTitle } from '@client/shared/toolkit';

interface DealConditionsProps {
  deal: DealReadModel;
  isReadOnly:boolean;
}

export const DealConditions = ({ deal, isReadOnly }: DealConditionsProps) => {
  const { t } = useTranslation();

  return (
    <div>
      {deal.type === 'Rent' && (
        <div className="w-full">
          <SlideOverTitle title={t('projectRent.dealConditionsTitle')} />
          <div className="flex flex-col space-y-6">
            <DealRentSecurityCondition deal={deal} isReadOnly={isReadOnly} />
            <DealRentConditions deal={deal} isReadOnly={isReadOnly} />
          </div>
        </div>
      )}
    </div>
  );
};
