import { DefaultValuesReadModel, useApiDeleteDefaultValuesMutation } from '@client/shared/api';
import { ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation, Trans } from 'react-i18next';

interface DefaultValuesDeleteModalProps {
  onClose: (success: boolean) => void;
  defaultValues : DefaultValuesReadModel;
}

export const DefaultValuesDeleteModal = ({ defaultValues, onClose }: DefaultValuesDeleteModalProps) => {
  const { t } = useTranslation();

  const [postDelete, { isLoading }] = useApiDeleteDefaultValuesMutation();

  const handleDelete = async () => {
    try {
      await safeMutation(postDelete, { defaultValuesId : defaultValues.id} , isLoading);

      onClose(true);
    } catch {
      /* left blank */
    }
  };

  return (
    <ConfirmModal
      title={`${t('common.delete')}`}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isLoading}
    >
      {isLoading && (
        <LoadingIndicator text={t('common.loading')} mode="overlay" />
      )}
      <Trans i18nKey="app.masterDataDefaultValues.deleteConfirmation">
        <div className="block">Do you want to delete</div>
        <div className="inline-block pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
