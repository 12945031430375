import { ReportCostBreakdownTableFixed } from './ReportCostBreakdownTableFixed';
import { ReportCostBreakdownTableYears } from './ReportCostBreakdownTableYears';

export const ReportCostBreakdownTable = () => {
  return (
    <div className="w-full flex overflow-x-auto">
      <div className="w-full max-w-[950px] flex-none">
        <ReportCostBreakdownTableFixed />
      </div>
      <div className="w-full max-w-[calc(100%-950px)]">
        <ReportCostBreakdownTableYears />
      </div>
    </div>
  )
}
