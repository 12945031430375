import { PropsWithChildren } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';

interface HeaderButtonProps extends PropsWithChildren {
  showToggle?: boolean;
  title?: string
  subtitle?: string;
  className?: string;
}
export const HeaderButton = (props: HeaderButtonProps) => {
  const {
    children,
    title,
    subtitle,
    showToggle = false,
    className
  } = props;

  return (
    <div className={cn('w-fit h-10 flex justify-between gap-2 py-1 pl-6 pr-4 font-medium text-gray-600 bg-gray-400 bg-opacity-10 rounded-full hover:bg-opacity-20 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75', className)}>
      <div className="flex flex-col h-full justify-between">
        {title && (<span className="text-xxs text-left text-gray-400">{title}</span>)}
        {subtitle && (
          <div className="text-left font-semibold text-sm leading-tight whitespace-nowrap text-gray-400 h-4">
            {subtitle}
          </div>
        )}
        {children}
      </div>
      {showToggle && (
        <ChevronDownIcon className="w-5 h-5 my-auto text-gray-600 flex-none" />
      )}
    </div>
  )
}
