import { useApiDeleteCalculationModelMutation, CalculationModelMetadata } from '@client/shared/api';
import { HintBox, ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useGenericDispatch } from '@client/shared/store';
import { setVariantId, useLoadedProjectVariants } from '@client/project/store';
import React from 'react';

interface VariantDeleteModalProps extends ModalOnCloseProps {
  variant: CalculationModelMetadata;
}

export const VariantDeleteModal = ({ variant, onClose }: VariantDeleteModalProps) => {
  const { t } = useTranslation();

  const dispatch = useGenericDispatch();
  const [call, { isLoading }] = useApiDeleteCalculationModelMutation();
  const calculationModels = useLoadedProjectVariants();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    try {
      await call({
        projectId: variant.projectId,
        calculationModelId: variant.id,
      }).unwrap();

      dispatch(
        setVariantId(
          calculationModels.currentData?.project.calculationModels.find((x) => x.type === 'Version')?.id ?? ''
        )
      );

      onClose(true);
    } catch {
      /* left blank */
    }
  };

  return (
    <ConfirmModal
      title={t('projectVariants.variantDeleteTitle')}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isLoading}
    >
      {isLoading && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
      <HintBox hintType="warning">{t('projectVariants.variantDeleteHint')}</HintBox>
    </ConfirmModal>
  );
};
