import { formatUnit, FormatUnitOptions } from '@client/shared/utilities';
import { useGetCurrencySymbol } from '@client/project/store';

export interface FormattedUnitProps {
  amount: number | null | undefined
  unit: string
  options?: FormatUnitOptions
}

export const FormattedUnit = (props: FormattedUnitProps) => {
  const currentCurrencySymbol = useGetCurrencySymbol()
  const formattedUnit = formatUnit(props.amount, props.unit, props.options)

  // this is just a temporary solution!
  return formattedUnit?.replace('€', currentCurrencySymbol) ?? ''
}
