import {
  DistributionReadModel,
  ElementTimelineReadModel,
  FinancePayoutTypeEnum,
  PaymentPlanType, RepaymentMethod
} from '@client/shared/api';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import {FinancingPaymentTypeSelect} from './FinancingPaymentTypeSelect';
import {FinancingDistributionType} from './FinancingDistribution';
import { BankBuildingIcon, BaseSelect, DateFromIcon, MastercardCreditCardIcon, NumberInput, PercentageIcon } from '@client/shared/toolkit';
import {FinancingPaymentSuggestionSelect} from './FinancingPaymentSuggestionSelect';
import { i18n } from '@client/shared/utilities';
import { TimeInput, TimeLineElementInput, updateTimeLine } from '@client/project/shared';

const getRepaymentMethods = () => {
  return [
    {
      label: i18n.t('projectCalculate.repaymentMethods.Annuity'),
      value: 'Annuity' as RepaymentMethod,
    },
    {
      label: i18n.t('projectCalculate.repaymentMethods.Amortization'),
      value: 'Amortization' as RepaymentMethod,
    },
    {
      label: i18n.t('projectCalculate.repaymentMethods.AnnuityByPercentage'),
      value: 'AnnuityByPercentage' as RepaymentMethod,
    },
  ];
};

interface FinancingDistributionModalHeaderProps {
  className?: string
  type: FinancingDistributionType
  selectedPaymentPlan: PaymentPlanType
  disabled: boolean
  variantId?: string;
  timing?: ElementTimelineReadModel | null | undefined
  setTimeLine: (value: ElementTimelineReadModel) => void
  setPaymentPlanType: (value: PaymentPlanType) => void
  isRecurringPayback?: boolean
  residualDebt?:  number | null
  setResidualDebt?: (value: number | null | undefined) => void
  suggestionType: FinancePayoutTypeEnum | 'None'
  setSuggestionType: (type: FinancePayoutTypeEnum | 'None') => void
  showAutoSuggestion: boolean
  isLoan?: boolean
  repaymentMethod?: RepaymentMethod
  setRepaymentMethod?: (method: RepaymentMethod) => void
  isLocked?: boolean
  annuityPercentage?: number | null;
  setAnnuityPercentage?: (value: number | null | undefined) => void;
}

export const FinancingDistributionModalHeader = (props: FinancingDistributionModalHeaderProps) => {
  const { t } = useTranslation();
  const {
    className,
    type,
    selectedPaymentPlan,
    disabled,
    variantId,
    timing,
    setTimeLine,
    setPaymentPlanType,
    isRecurringPayback = false,
    residualDebt= null,
    setResidualDebt,
    suggestionType,
    setSuggestionType,
    showAutoSuggestion,
    isLoan = false,
    repaymentMethod,
    setRepaymentMethod,
    isLocked = false,
    annuityPercentage,
    setAnnuityPercentage
  } = props
  const timeLineStart = useMemo(() => {
    return timing
      ? {
        variantDeliveryPhaseId: timing.startCalculationModelDeliveryPhaseId,
        variantMileStoneId: timing.startCalculationModelMileStoneId,
        offset: timing.startOffset,
        offsetPosition: timing.startOffsetPosition,
        offsetUnit: timing.startOffsetUnit,
        fixedDate: timing.startFixedStartDate,
        elementTimelineId: timing.startElementTimelineId,
        type: timing.startType,
        effectiveDate: timing.effectiveStartDate,
      }
      : undefined;
  }, [timing])

  const timeLineEnd = useMemo(() => {
    return timing
      ? {
        variantDeliveryPhaseId: timing.endCalculationModelDeliveryPhaseId,
        variantMileStoneId: timing.endCalculationModelMileStoneId,
        offset: timing.endOffset,
        offsetPosition: timing.endOffsetPosition,
        elementTimelineId: timing.endElementTimelineId,
        type: timing.endType,
        duration: timing.duration,
        durationUnit: timing.durationUnit,
        effectiveDate: timing.effectiveEndDate,
        fixedDate: timing.effectiveEndDate,
      }
      : undefined;
  }, [timing])

  const timelineDistribution = useMemo(() => {
    return timing ? timing.distribution : null;
  }, [timing])

  const hideEnd = useMemo(() => {
    return selectedPaymentPlan === 'AllInTheBeginning'
  }, [selectedPaymentPlan])

  const handleTimeLineChange = useCallback((data: {
    startElement?: TimeInput;
    endElement?: TimeInput;
    elementDistribution?: DistributionReadModel
  }) => {
    if (setTimeLine) {
      // if timeline got updated, save it
      const timeLineUpdated = updateTimeLine(data, timeLineStart, timeLineEnd, hideEnd, timing, timelineDistribution)
      if (timeLineUpdated) {
        setTimeLine(timeLineUpdated)
      }
    }
  }, [timeLineStart, timeLineEnd, setTimeLine, hideEnd, timing, timelineDistribution])

  const timeLineStartLabel = useMemo(() => {
    if (type === FinancingDistributionType.PAYOUT) {
      return selectedPaymentPlan === 'AllInTheBeginning'
        ? t('projectCalculate.financingElementLabelPayoutDate')
        : t('projectCalculate.financingElementLabelPayoutStart')
    }
    return selectedPaymentPlan === 'AllInTheBeginning'
      ? t('projectCalculate.financingElementLabelPaybackDate')
      : t('projectCalculate.financingElementLabelPaybackStart')
  }, [type, selectedPaymentPlan, t])

  const timeLineEndLabel = useMemo(() => {
    return type === FinancingDistributionType.PAYOUT ? t('projectCalculate.financingElementLabelPayoutEnd') : t('projectCalculate.financingElementLabelPaybackEnd')
  }, [type, t])

  const minEndDate = useMemo(() => {
    return timeLineStart?.effectiveDate ? new Date(timeLineStart?.effectiveDate) : undefined
  }, [timeLineStart?.effectiveDate])

  const isTimelineEndDisabled = useMemo(() => {
    return disabled || selectedPaymentPlan === 'AllInTheBeginning' || selectedPaymentPlan === 'Variable' ||
      selectedPaymentPlan === 'EndOfTheProject' || (isRecurringPayback && residualDebt != null && repaymentMethod === 'AnnuityByPercentage')
  }, [disabled, selectedPaymentPlan, isRecurringPayback, residualDebt, repaymentMethod])

  return (
    <div className={cn('flex flex-wrap divide-y pb-3', className)}>
      <div className="w-full flex flex-row divide-x">
        <FinancingPaymentTypeSelect
          className="w-1/4"
          defaultPaymentPlanType={type === FinancingDistributionType.PAYOUT ? 'AllInTheBeginning' : 'EndOfTheProject'}
          selectedValue={selectedPaymentPlan}
          onChange={setPaymentPlanType}
          type={type}
        />
        {variantId && (
          <>
            <TimeLineElementInput
              className={cn('w-1/4', {
                'opacity-70': disabled || selectedPaymentPlan === 'Variable'
              })}
              timeLineData={timeLineStart}
              label={timeLineStartLabel}
              variantId={variantId}
              disabled={disabled || selectedPaymentPlan === 'Variable'}
              showValue={selectedPaymentPlan !== 'Variable'}
              onChange={(data) => {
                if (data.effectiveDate) {
                  if (selectedPaymentPlan === 'AllInTheBeginning') {
                    handleTimeLineChange({
                      startElement: data,
                      endElement: { ...data, type: 'FixedDates', fixedDate: data.effectiveDate },
                    });
                  } else if (selectedPaymentPlan === 'EndOfTheProject') {
                    handleTimeLineChange({
                      startElement: data,
                      endElement: data
                    });
                  } else {
                    handleTimeLineChange({ startElement: data });
                  }
                }
              }}
              icon={<DateFromIcon />}
              showProjectEnd={selectedPaymentPlan === 'EndOfTheProject'}
            />
            <TimeLineElementInput
              className={cn('w-1/4', {
                'opacity-70': isTimelineEndDisabled
              })}
              showValue={(selectedPaymentPlan !== 'AllInTheBeginning' && selectedPaymentPlan !== 'Variable' && selectedPaymentPlan !== 'EndOfTheProject') && !(isRecurringPayback && residualDebt != null && repaymentMethod === 'AnnuityByPercentage')}
              timeLineData={timeLineEnd}
              label={timeLineEndLabel}
              variantId={variantId}
              showDuration={true}
              disabled={isTimelineEndDisabled}
              onChange={(data) => {
                handleTimeLineChange({ endElement: data })
                if (setResidualDebt) {
                  setResidualDebt(undefined)
                }
              }}
              icon={<DateFromIcon className='scale-x-[-1]' />}
              minDate={minEndDate}
              type="end"
            />
            {showAutoSuggestion && type === FinancingDistributionType.PAYOUT && (
              <FinancingPaymentSuggestionSelect
                className="w-1/4"
                defaultSuggestionType="None"
                selectedValue={suggestionType}
                onChange={(value: FinancePayoutTypeEnum) => setSuggestionType(value)}
              />
            )}
            {isRecurringPayback && type === FinancingDistributionType.PAYBACK && (
              <NumberInput
                className={cn('w-1/4', {
                  'opacity-70': disabled || (isRecurringPayback && repaymentMethod === 'AnnuityByPercentage' && !!timeLineEnd?.effectiveDate && residualDebt === null)
                })}
                label={t('projectCalculate.financingElementLabelResidualDebt')}
                value={residualDebt}
                onChange={(value) => {
                  if (setResidualDebt) {
                    handleTimeLineChange({
                      endElement: {
                        type: 'FixedDates',
                        duration: 0
                      }
                    });
                    setResidualDebt(value ?? undefined);
                  }
                }}
                icon={<MastercardCreditCardIcon />}
                disabled={disabled || (isRecurringPayback && repaymentMethod === 'AnnuityByPercentage' && !!timeLineEnd?.effectiveDate && residualDebt === null)}
              />
            )}
          </>
        )}
      </div>
      {((isLoan && isRecurringPayback && setRepaymentMethod) || (isRecurringPayback && repaymentMethod === 'AnnuityByPercentage' && type === FinancingDistributionType.PAYBACK)) && (
        <div className="w-full flex flex-row divide-x">
          {isLoan && isRecurringPayback && setRepaymentMethod && (
            <BaseSelect
              className="w-1/2"
              label={t('projectCalculate.financingElementLabelRepaymentMethod')}
              options={getRepaymentMethods()}
              value={repaymentMethod ?? ('Annuity' as RepaymentMethod)}
              onChange={(value) => setRepaymentMethod(value as RepaymentMethod)}
              icon={<BankBuildingIcon />}
              disabled={disabled || isLocked}
            />
          )}
          {isRecurringPayback && repaymentMethod === 'AnnuityByPercentage' && type === FinancingDistributionType.PAYBACK && (
            <NumberInput
              className="w-1/2"
              label={t('projectCalculate.financingElementLabelAnnuityPercentage')}
              icon={<PercentageIcon className="h-6 w-6" />}
              value={annuityPercentage}
              onChange={(value) => {
                if (setAnnuityPercentage) {
                  setAnnuityPercentage(value ?? undefined);
                }
              }}
              disabled={disabled}
            />
          )}
        </div>
      )}
    </div>
  );
};
