import { TaxonomyReadModel, TaxonomyType } from '@client/shared/api';
import { useMemo } from 'react';

export const useMgfExclusiveTypes = (item?: TaxonomyReadModel): TaxonomyType[] => {
  return useMemo(() => {
    if (item == null) return [];

    const result = item.types.slice(1);

    const indexOfSector = result.indexOf('Sector');
    const indexOfOther = result.indexOf('Other');

    if (indexOfSector < -1) {
      return ['Other'];
    }

    return result.filter((_, idx) => idx > indexOfSector && idx !== indexOfOther);
  }, [item]);
};
