import {
  EmailButtonIcon,
  PhoneButtonIcon,
  WhatsappButtonIcon
} from '../../icons';
import React, { ReactNode } from 'react';
import cn from 'classnames';

type ContactButtonsProps = {
  className?: string
  phone?: string | null
  email?: string | null
  whatsapp?: string | null
  color?: string
  width?: string
}
export const ContactButtons = (props: ContactButtonsProps) => {
  const {
    className,
    phone,
    email,
    whatsapp,
    color,
    width = 'aspect-square'
  } = props
  if (!phone && !email && !whatsapp) return
  return (
    <ul className={cn('flex gap-2 items-center', className)}>
      {phone && (
        <li className={width}>
          <ContactButton
            icon={<PhoneButtonIcon />}
            link={`tel:${phone}`}
            color={color}
          />
        </li>
      )}
      {email && (
        <li className={width}>
          <ContactButton
            icon={<EmailButtonIcon />}
            link={`mailTo:${email}`}
            color={color}
          />
        </li>
      )}
      {whatsapp && (
        <li className={width}>
          <ContactButton
            icon={<WhatsappButtonIcon />}
            link={`https://wa.me/1${whatsapp}`}
            color={color}
          />
        </li>
      )}
    </ul>
  )
}

type ContactButtonProps = {
  link: string
  icon: ReactNode
  color?: string
}
export const ContactButton = (props: ContactButtonProps) => {
  const {
    link,
    icon,
    color = 'text-gray-400 hover:text-gray-700'
  } = props
  return (
    <a
      href={link}
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={cn('block transition-colors duration-300 h-full w-full', color)}
    >
      {icon}
    </a>
  )
}
