import { BaseSelectOption, ComboSelect } from '@client/shared/toolkit';
import React, { useMemo } from 'react';
import { useFlattenCostElements } from '../hooks';
import { useTranslation } from 'react-i18next';


interface CostElementSelectProps {
  label: string
  icon?: React.ReactNode
  disabled?: boolean
  setCostElementId: (id: string) => void
  costElementId: string
  nonSelectedLabel?: string
  addNoneSelectedOption?: boolean
  handlePopoverVisibility?: (isOpen: boolean) => void
}
export const CostElementSelect = (props: CostElementSelectProps) => {
  const { t } = useTranslation();
  const {
    label,
    icon,
    disabled,
    setCostElementId,
    costElementId,
    nonSelectedLabel = '',
    addNoneSelectedOption = true,
    handlePopoverVisibility
  } = props

  const flattenedCostElements = useFlattenCostElements()

  const costElementOptions = useMemo(() => {
    const options: BaseSelectOption[] = []
    if (addNoneSelectedOption) {
      const noneSelectedOption: BaseSelectOption = { value: 'None', label: nonSelectedLabel }
      options.push(noneSelectedOption)
    }
    if (flattenedCostElements.length) {
      flattenedCostElements.forEach((costGroupElement) => {
        if (costGroupElement.costElementId) {
          options.push({
            value: costGroupElement.costElementId,
            label: `${costGroupElement.code} - ${costGroupElement.description ? costGroupElement.description : t('projectCalculate.unnamedElement')}`,
          });
        }
        if (costGroupElement.costElements.length > 0) {
          costGroupElement.costElements.forEach((costElement) => {
            if (costElement.costElement) {
              options.push({
                value: costElement.costElement.elementId,
                label: `${costElement.costElement.costCatalogCode} - ${costElement.costElement.description ? costElement.costElement.description : t('projectCalculate.unnamedElement')}`,
              });
            }
          });
        }
      });
    }
    return options;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flattenedCostElements, addNoneSelectedOption, nonSelectedLabel])

  return (
    <ComboSelect
      label={label}
      icon={icon}
      options={costElementOptions}
      value={costElementId}
      onChange={(value) => setCostElementId(value ?? 'None')}
      disabled={disabled}
      handlePopoverVisibility={handlePopoverVisibility}
      nullable
    />
  )
}
