import { CompanyElementData } from '@client/shared/api';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import cn from 'classnames';
import { CheckmarkIcon, LabeledValue, ToggleButton } from '@client/shared/toolkit';
import {
  AtSymbolIcon,
  BuildingOffice2Icon,
  DevicePhoneMobileIcon,
  ExclamationCircleIcon,
  GlobeAltIcon,
  PhoneIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

interface PartnerCompanyImportRecordProps {
  element: CompanyElementData;
  level?: number;
}

export const PartnerCompanyImportRecord = ({ element, level = 0 }: PartnerCompanyImportRecordProps) => {
  const { t } = useTranslation();
  const getBranchAddress = () => {
    const value =
      (element.data.branchAddressSupplement ?? '') +
      ' ' +
      (element.data.branchAddressStreet ?? '') +
      ' ' +
      (element.data.branchAddressNumber ?? '') +
      ', ' +
      (element.data.branchAddressPostalCode ?? '') +
      ' ' +
      (element.data.branchAddressCity ?? '') +
      ' ' +
      (element.data.branchAddressCountry ?? '');

    const trimmed = value.trim();

    if (trimmed === ',') return '-';

    if (trimmed.endsWith(',')) return trimmed.substring(0, trimmed.length - 1);

    if (trimmed.startsWith(',')) return trimmed.substring(1, trimmed.length);

    return trimmed;
  };

  return (
    <Disclosure as="div" className="w-full" defaultOpen={false}>
      {({ open }) => (
        <>
          <DisclosureButton as="div">
            <div
              className={cn('flex items-center h-[38px] hover:text-black hover:bg-gray-50 relative', {
                'border-b font-bold text-sm text-gray-900': level === 0,
                'text-xs text-gray-500': level > 0,
              })}
            >
              <ToggleButton className="absolute z-[5] -left-6 top-0 h-full" open={open} />
              <div className="pl-2 w-full h-full flex flex-row cursor-pointer items-center">
                <div className="flex flex-grow" style={level > 1 ? { marginLeft: `${level * 0.6}rem` } : undefined}>
                  {element.data.name}
                </div>
                {element.errors && element.errors.length > 0 && (
                  <div className="mr-2 flex justify-end text-red-600">
                    <ExclamationCircleIcon className="w-6" />
                  </div>
                )}
              </div>
            </div>
          </DisclosureButton>
          <DisclosurePanel>
            <div className="text-xs mb-2 ml-2">
              <div className="text-center">
                {element.errors.map((err, index) => (
                  <div key={index} className="text-red-500">
                    {t(err)}
                  </div>
                ))}
              </div>
              <div className="my-1">{t('app.masterDataCompany')}</div>
              <div className="flex flex-row">
                <div className="w-1/3">
                  <LabeledValue value={element.data.taxId ?? '-'}>{t('app.companiesTaxId')}</LabeledValue>
                </div>
                <div className="w-1/3">
                  <LabeledValue value={element.data.companyRegistrationNumber ?? '-'}>
                    {t('app.masterDataCompanyRegistrationNumber')}
                  </LabeledValue>
                </div>
                <div className="w-1/3">
                  <LabeledValue value={element.data.link ?? '-'}>
                    <GlobeAltIcon className="w-4" />
                  </LabeledValue>
                </div>
              </div>
              <div className="my-1 mt-2">{t('app.masterDataBranch')}</div>
              <div className="flex flex-row">
                {element.data.branchIsMain !== undefined && (
                  <div className="w-1/3">
                    <div className="flex flex-row text-slate-400">
                      <div className="flex flex-row text-slate-400">
                        <div>{t('app.masterDataMainBranch')}</div>
                        <div>:</div>
                      </div>
                      <div className="ml-2 text-primary">
                        {element.data.branchIsMain === true && <CheckmarkIcon className="w-4" />}
                        {element.data.branchIsMain === false && <XCircleIcon className="w-4" />}
                      </div>
                    </div>
                  </div>
                )}
                <div className="w-1/3">
                  <LabeledValue value={element.data.branchCode ?? '-'}>ext.Id</LabeledValue>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/3">
                  <LabeledValue value={element.data.branchName ?? '-'}>{t('common.code')}</LabeledValue>
                </div>
                <div className="w-1/3">
                  <LabeledValue value={element.data.branchLink ?? '-'}>
                    <GlobeAltIcon className="w-4" />
                  </LabeledValue>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/3">
                  <LabeledValue value={element.data.branchMobile ?? '-'}>
                    <DevicePhoneMobileIcon className="w-4" />
                  </LabeledValue>
                </div>
                <div className="w-1/3">
                  <LabeledValue value={element.data.branchPhone ?? '-'}>
                    <PhoneIcon className="w-4" />
                  </LabeledValue>
                </div>
                <div className="w-1/3">
                  <LabeledValue value={element.data.branchEmail ?? '-'}>
                    <AtSymbolIcon className="w-4" />
                  </LabeledValue>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/3">
                  <LabeledValue value={element.data.branchCreditorId ?? '-'}>
                  {t('app.masterDataBranchCreditor')}
                  </LabeledValue>
                </div>
                <div className="w-1/3">
                  <LabeledValue value={element.data.branchDebitorId ?? '-'}>
                  {t('app.masterDataBranchDebitor')}
                  </LabeledValue>
                </div>
                <div className="w-1/3">
                  <LabeledValue value={element.data.branchSpvId ?? '-'}>
                  {t('app.masterDataBranchSpecialPurposeVehicle')}
                  </LabeledValue>
                </div>
                </div>
              <div className="flex flex-row">
                <div className="">
                  <LabeledValue value={getBranchAddress()}>
                    <BuildingOffice2Icon className="w-4" />
                  </LabeledValue>
                </div>
              </div>
              <div className="my-1 mt-2">{t('app.companiesBankAccount')}</div>
              <div className="flex flex-row">
                <div className="w-1/3">
                  <LabeledValue value={element.data.branchAccountOwner ?? '-'}>
                    {t('app.settingsBadgeOwner')}
                  </LabeledValue>
                </div>
                <div className="w-1/3">
                  <LabeledValue value={element.data.branchAccountBankName ?? '-'}>
                    {t('app.companiesBank')}
                  </LabeledValue>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-1/3">
                  <LabeledValue value={element.data.branchAccountIban ?? '-'}>IBAN</LabeledValue>
                </div>
                <div className="w-1/3">
                  <LabeledValue value={element.data.branchAccountSwift ?? '-'}>SWIFT</LabeledValue>
                </div>
              </div>
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
};