import cn from 'classnames';
import React, { PropsWithChildren } from 'react';
import { CalculateTooltip } from '../CalculateTooltip';
interface TimeLineMarkerProps extends PropsWithChildren {
  className?: string;
  position?: 'left' | 'right' | 'top';
  active?: boolean;
}

export const TimeLineMarkerDot = ({ position = 'left', active = false, className }: TimeLineMarkerProps) => {
  return (
    <div
      className={cn(
        'absolute top-1/2 p-px rounded-full bg-white shadow border-2 z-30 -translate-x-1/2 -translate-y-1/2 cursor-pointer',
        {
          'left-0': position === 'left',
          'left-full': position === 'right',
          'w-[0.5625rem] h-[0.5625rem]': !active,
          'w-2.5 h-2.5': active,
        },
        className
      )}
    />
  );
};

export const TimeLineMarker = ({ position = 'left', active = false, className, children }: TimeLineMarkerProps) => {
  if (children) {
    return (
      <CalculateTooltip
        className={cn(
          'absolute top-1/2 p-px rounded-full bg-white shadow border-2 z-30 -translate-x-1/2 -translate-y-1/2 cursor-pointer',
          {
            'left-0': position === 'left',
            'left-full': position === 'right',
            'w-[0.5625rem] h-[0.5625rem]': !active,
            'w-2.5 h-2.5': active,
          },
          className
        )}
        placement={position}
      >
        <>{children}</>
      </CalculateTooltip>
    );
  }
  return <TimeLineMarkerDot position={position} active={active} className={className} />;
};

export const TimeLineTooltip = ({ position = 'top', className, children }: TimeLineMarkerProps) => {
  return (
    <CalculateTooltip
      className={cn('absolute p-px left-0 h-1 w-full z-30 cursor-pointer', className)}
      placement={position}
    >
      <>{children}</>
    </CalculateTooltip>
  );
};
