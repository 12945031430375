import { PaymentFrequency } from '@client/shared/api';
import { i18n } from '@client/shared/utilities';

export const getPaymentFrequencyValueLabel = (billingPeriod: PaymentFrequency) => {
  switch (billingPeriod) {
    case 'Monthly':
      return i18n.t('projectRent.paymentFrequency.Monthly');
    case 'Quarterly':
      return i18n.t('projectRent.paymentFrequency.Quarterly');
    case 'Yearly':
      return i18n.t('projectRent.paymentFrequency.Yearly');
    default:
      return i18n.t('projectRent.paymentFrequency.Monthly');
  }
};
