import * as yup from 'yup';
import { InferType } from 'yup';

// const optionalBgfNgfTypes = ['Parking', 'Zone', 'Unit', 'Room', 'Bed'];

export const BuildingItemAddFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  customerName: yup.string().optional(),
  type: yup.string().required('validation.required'),
  usageType: yup.string().optional(),
  bgf: yup.number().optional().integer().nullable().when('ngf', ([ngf], schema) => {
    if (ngf) {
      return schema.test({
        test: (bgf: undefined | null | number) => {
          if (bgf && !ngf || !ngf && bgf) return false
          if (ngf && bgf) {
            return ngf <= bgf
          }
          return true
        },
        message: 'validation.bgfMustBeGreaterOrEqualNgf',
      })
    }
    return schema
  })
  .test('bgf', 'error.general.amount_must_be_grater_than_zero', (bgf: number | null | undefined) => {
    if (typeof bgf === 'undefined' || bgf  === null) return true
    return bgf > 0
  }),
  ngf: yup
    .number()
    .optional()
    .integer()
    .nullable()
    .moreThan(0, 'error.general.amount_must_be_grater_than_zero'),
  mfgEfficiency: yup.number().typeError('validation.number').optional(),
  ngfEfficiency: yup.number().typeError('validation.number').optional(),
  rentalSpace: yup.number().optional().nullable().typeError('validation.number'),
  amount: yup.number().typeError('validation.number').optional().integer().moreThan(0, 'error.general.amount_must_be_grater_than_zero'),
  parkingLotNumber: yup.string().optional(),
  parkingLotType: yup.string().when('type', ([type]) => {
    if (type === 'Parking') {
      return yup.string().required('validation.required')
    }
    return yup.string().optional()
  }),
  parkingLotFeatures: yup.array().of(yup.string()).optional(),
  parkingLotDescription: yup.string().optional(),
  description: yup.string().optional(),
  cost: yup.number().typeError('validation.number').optional(),
});

export type BuildingItemAddFormValidationValues = InferType<typeof BuildingItemAddFormValidationSchema>;
