import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchIn } from '@client/shared/api';
import { toggleFilters } from './utils';
import { FilterOption } from '@client/shared/toolkit';

export interface FilterState {
  Cost: FilterOption[];
  Project: FilterOption[];
  Taxonomy: FilterOption[];
  Contract: FilterOption[];
  ContractTitle: FilterOption[];
  Invoice: FilterOption[];
  Risk: FilterOption[];
  Earning: FilterOption[];
}

const initialState: FilterState = {
  Cost: [],
  Project: [],
  Taxonomy: [],
  Contract: [],
  ContractTitle: [],
  Invoice: [],
  Risk: [],
  Earning: []
};

interface SetFiltersPayload {
  filters: FilterOption[]
  type: SearchIn
}
export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setToggledFilters: (state, action: PayloadAction<SetFiltersPayload>) => {
      state[action.payload.type] = action.payload.filters
      toggleFilters(action.payload.filters, action.payload.type)
    },
    setCostFilters: (state, action: PayloadAction<FilterOption[]>) => {
      state.Cost = action.payload
      toggleFilters(action.payload, 'Cost')
    },
    setProjectFilters: (state, action: PayloadAction<FilterOption[]>) => {
      state.Project = action.payload
      toggleFilters(action.payload, 'Project')
    },
    setTaxonomyFilters: (state, action: PayloadAction<FilterOption[]>) => {
      state.Taxonomy = action.payload
      toggleFilters(action.payload, 'Taxonomy')
    },
    setContractFilters: (state, action: PayloadAction<FilterOption[]>) => {
      state.Contract = action.payload
      toggleFilters(action.payload, 'Contract')
    },
    setContractTitleFilters: (state, action: PayloadAction<FilterOption[]>) => {
      state.ContractTitle = action.payload
      toggleFilters(action.payload, 'ContractTitle')
    },
    setInvoiceFilters: (state, action: PayloadAction<FilterOption[]>) => {
      state.Invoice = action.payload
      toggleFilters(action.payload, 'Invoice')
    }
  }
});

export const {
  setToggledFilters,
  setCostFilters,
  setProjectFilters,
  setTaxonomyFilters,
  setContractFilters,
  setContractTitleFilters,
  setInvoiceFilters
} = filterSlice.actions;
