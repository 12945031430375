import { isWithinInterval, parseISO } from 'date-fns';

/**
 * Checks if the given date is within the start and end date.
 * @param date The date to check
 * @param start The start date
 * @param end The end date
 */
export const isDateInBetween = (date: Date | number, start: string | Date, end: string | Date) => {
  if (typeof start === 'string') {
    start = parseISO(start);
  }

  if (typeof end === 'string') {
    end = parseISO(end);
  }

  return isWithinInterval(date, { start: start, end: end });
};
