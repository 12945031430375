import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { CalculationModelMilestoneReadModel, useApiDeleteCalculationModelMilestonesMutation } from '@client/shared/api';
import { ModalOnCloseProps, ConfirmModal } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation, Trans } from 'react-i18next';

interface DeleteCalculationModelMilestoneModalProps extends ModalOnCloseProps {
  milestone: CalculationModelMilestoneReadModel | undefined;
}

export const DeleteCalculationModelMilestoneModal = ({ milestone, onClose }: DeleteCalculationModelMilestoneModalProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId() ?? 'unset';
  const loadedCalculationModelId = useLoadedVariantId() ?? 'unset';
  const [deleteMilestone, { isLoading: isDeleting }] = useApiDeleteCalculationModelMilestonesMutation();

  const handleDelete = async () => {
    try {
    await safeMutation(
        deleteMilestone,
        {
            projectId: loadedProjectId,
            calculationModelId: loadedCalculationModelId,
            milestoneId: milestone?.id ?? 'unset'
        },
        isDeleting
    );

    onClose(true);
    } catch {
        /* left blank */
    }
  };

  return (
    <ConfirmModal
      title={`${t('common.delete')} ${milestone?.name ?? ''}`}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      <Trans i18nKey="projectCalculate.milestoneDeleteConfirmation" values={{ entity: `${milestone?.name ?? ''}` }}>
        <div className="block">
          Do you want to delete
          <span className="font-bold">Milestone</span>?<span className="font-bold">Milestone</span>?
        </div>
        <div className="inline-block pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
