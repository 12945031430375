import { TaxonomyReadModel, PlotReadModel } from '@client/shared/api';
import { isImage, isPdf } from '@client/shared/utilities';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  // ArrowDownCircleIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useState, useRef, useEffect } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useTranslation } from 'react-i18next';

interface DashboardModalProps {
  selectedFile: {
    id: string;
    name: string;
  } | null;
  loadedProjectId: string | undefined;
  item?: TaxonomyReadModel | undefined;
  plot?: PlotReadModel | undefined;
}

export const DashboardModal = ({ selectedFile, loadedProjectId, item, plot }: DashboardModalProps) => {
  const { t } = useTranslation()
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);
  const [scale, setScale] = useState(1);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = useRef<null | HTMLDivElement>(null);

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onDocumentLoadSuccess({ numPages: nextNumPages }: any): void {
    setNumPages(nextNumPages);
    setIsPdfLoaded(true);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  useEffect(() => {
    if (ref.current) {
      const getWidth = () => {
        return ref?.current?.offsetWidth ? ref?.current?.offsetWidth : 0;
      };
      const getHeight = () => {
        return ref?.current?.offsetHeight ? ref?.current?.offsetHeight : 0;
      };

      if (ref?.current?.offsetWidth !== undefined) setWidth(getWidth());
      if (ref?.current?.offsetHeight !== undefined) setHeight(getHeight());
    }
  }, [ref?.current?.offsetWidth, ref?.current?.offsetHeight, scale]);

  return (
    <div className="w-full h-full flex justify-center items-center p-10 flex-col">
      {selectedFile && isImage(selectedFile?.name) && (
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-fit h-fit" ref={ref}>
            <img
              src={
                item
                  ? `/api/projects/${loadedProjectId}/calculation-models/${item?.calculationModelId}/taxonomy/${item?.itemId}/file/${selectedFile?.id}`
                  : `/api/projects/${loadedProjectId}/calculation-models/${plot?.calculationModelId}/plots/${plot?.plotId}/file/${selectedFile?.id}`
              }
              alt={selectedFile?.name}
              className={classNames({
                'w-full h-fit': width > height,
                'w-fit h-full': width < height,
              })}
            />
          </div>
        </div>
      )}
      {selectedFile && isPdf(selectedFile?.name) && (
        <>
          <div className="w-full relative z-50">
            <MagnifyingGlassPlusIcon
              className={classNames('absolute top-2 left-2 w-5 h-5 cursor-pointer hover:opacity-50')}
              onClick={() => setScale(scale + 0.5)}
            />
            <MagnifyingGlassMinusIcon
              className={classNames('absolute top-2 left-10 w-5 h-5 hover:opacity-50', {
                'opacity-50 cursor-default': scale === 1,
                'cursor-pointer': scale > 1,
              })}
              onClick={() => {
                if (scale > 1) setScale(scale - 0.5);
              }}
            />
            {/* <a
              href={`/api/projects/${loadedProjectId}/calculation-models/${item?.calculationModelId}/taxonomy/${item?.itemId}/file/${selectedFile.id}`}
              target="_blank"
              rel="noreferrer"
              download
              className="absolute top-2 left-20"
            >
              <ArrowDownCircleIcon className="w-5 h-5 hover:opacity-50" />
            </a> */}
          </div>
          <div
            className={classNames('flex items-start justify-start h-full ', {
              'w-fit': width < 1000 && isPdfLoaded,
              'w-full': width >= 1000 && isPdfLoaded,
              'w-full h-full items-center justify-center': !isPdfLoaded,
              'overflow-scroll': scale > 1,
            })}
          >
            <Document
              file={
                item
                  ? `/api/projects/${loadedProjectId}/calculation-models/${item?.calculationModelId}/taxonomy/${item?.itemId}/file/${selectedFile?.id}`
                  : `/api/projects/${loadedProjectId}/calculation-models/${plot?.calculationModelId}/plots/${plot?.plotId}/file/${selectedFile?.id}`
              }
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <div ref={ref}>
                <Page pageNumber={pageNumber} renderTextLayer={false} scale={scale} height={545} />
              </div>
            </Document>
          </div>
        </>
      )}
      <div className="mt-3 flex flex-col justify-center items-center">
        <div className="mb-2 font-bold text-xl">{selectedFile?.name}</div>
        {selectedFile && isPdf(selectedFile?.name) && (
          <div className="font-bold text-lg flex justify-center items-center">
            {isPdfLoaded ? (
              <>
                <button type="button" className="mx-5" disabled={pageNumber <= 1} onClick={previousPage}>
                  <ArrowLeftIcon
                    className={classNames('w-5 h-5 fill-black', {
                      'opacity-25': pageNumber <= 1,
                      'hover:opacity-50': pageNumber > 1,
                    })}
                  />
                </button>
                <span className="cursor-default">
                  Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                </span>
                <button type="button" className="mx-5" disabled={pageNumber >= (numPages ?? 0)} onClick={nextPage}>
                  <ArrowRightIcon
                    className={classNames('w-5 h-5 fill-black', {
                      'opacity-25': pageNumber >= (numPages ?? 0),
                      'hover:opacity-50': pageNumber < (numPages ?? 0),
                    })}
                  />
                </button>
              </>
            ) : (
              <div>{t('projectTaxonomy.loadingPages')}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
