import React, { useCallback, useMemo, useState } from 'react';
import {
  AddIcon,
  Button,
  ContextMenuItem,
  FloatingActionButton,
  LoadingIndicator,
  Modal
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { EmptyProjectsCard } from '@client/shared/toolkit';
import { PortfolioOverviewCard } from './PortfolioOverviewCard';
import { PortfolioShortReadModel, useApiGetPortfoliosQuery } from '@client/shared/api';

interface PortfoliosContainerProps {
  searchText: string;
  searchResults: string[];
  setSearchText: (val: string) => void;
}

export const PortfoliosContainer = (props: PortfoliosContainerProps) => {
  const { searchText, setSearchText } = props;
  const { t } = useTranslation();

  const { data, isFetching } = useApiGetPortfoliosQuery();

  const [openModal, setOpenModal] = useState<'None' | 'New'>('None');

  const resetSearch = useCallback(() => {
    setSearchText('');
  }, [setSearchText]);

  const loadedPortfolios: PortfolioShortReadModel[] = useMemo(() => {
    return data?.portfolios ?? [];
  }, [data?.portfolios]);

  const portfolios = useMemo(() => {
    if (searchText) {
      return loadedPortfolios.filter((portfolio) => portfolio.name.toLowerCase().includes(searchText.toLowerCase()));
    }
    return loadedPortfolios;
  }, [searchText, loadedPortfolios]);

  const contextItems: ContextMenuItem[] = useMemo(() => {
    return [
      {
        label: t('portfolio.createNewTitle'),
        subtitle: t('portfolio.createNewSubtitle'),
        icon: <AddIcon />,
        onClick: () => {
          setOpenModal('New');
        },
      },
    ];
  }, [t]);

  const handleCloseModal = () => {
    setOpenModal('None');
  };

  return (
    <>
      {isFetching === true && <LoadingIndicator mode="overlay" text={t('app.loadingPortfolios')} />}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 relative grid-flow-dense">
        {!portfolios.length && searchText && (
          <div className="inline-block">
            {t('app.noPortfoliosFound')}
            &nbsp;
            <Button variant="text" hasPadding={false} onClick={resetSearch}>
              {t('common.resetSearch')}
            </Button>
          </div>
        )}
        {!portfolios.length && !searchText ? (
          <EmptyProjectsCard
            onClick={() => setOpenModal('New')}
            title={t('portfolio.createNewTitle')}
            subTitle={t('portfolio.createNewSubtitle')}
          />
        ) : (
          portfolios.map((portfolio) => (
            <PortfolioOverviewCard
              portfolio={portfolio}
              key={portfolio.id}
              searchText={searchText}
            />
          ))
        )}
      </div>

      <FloatingActionButton menuItems={contextItems} position="fixed right-20 bottom-16" />

      <Modal isOpen={openModal === 'New'} onClose={handleCloseModal} />
    </>
  );
};
