import { i18n } from '@client/shared/utilities';

export const getUsageTypes = () => {
  return [
    {
      label: i18n.t('projectTaxonomy.usageType.Production'),
      value: 'Production',
    },
    {
      label: i18n.t('projectTaxonomy.usageType.Traffic'),
      value: 'Traffic',
    },
    {
      label: i18n.t('projectTaxonomy.usageType.Technical'),
      value: 'Technical',
    },
    {
      label: i18n.t('projectTaxonomy.usageType.Residential'),
      value: 'Residential',
    },
    {
      label: i18n.t('projectTaxonomy.usageType.Office'),
      value: 'Office',
    },
    {
      label: i18n.t('projectTaxonomy.usageType.Retail'),
      value: 'Retail',
    },
    {
      label: i18n.t('projectTaxonomy.usageType.Storage'),
      value: 'Storage',
    },
    {
      label: i18n.t('projectTaxonomy.usageType.Hotel'),
      value: 'Hotel',
    },
    {
      label: i18n.t('projectTaxonomy.usageType.Mixed'),
      value: 'Mixed',
    },
  ];
};
