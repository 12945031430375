import { useLoadedProjectId } from '@client/project/store';
import { DealDocumentReadModel, DealType, useApiDeleteRentDealDocumentMutation, useApiDeleteSellDealDocumentMutation } from '@client/shared/api';
import { ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation, Trans } from 'react-i18next';

interface DealDocumentDeleteModalProps extends ModalOnCloseProps {
  dealId: string;
  type: DealType;
  document: DealDocumentReadModel;
}

export const DealDocumentDeleteModal = ({ dealId, type, document, onClose }: DealDocumentDeleteModalProps) => {
  const { t } = useTranslation();
  const projectId = useLoadedProjectId();
  const [deleteRentDocument, { isLoading : isLoadingRentDocument }] = useApiDeleteRentDealDocumentMutation();
  const [deleteSellDocument, { isLoading : isLoadingSellDocument }] = useApiDeleteSellDealDocumentMutation();

  const handleDelete = async () => {
    switch (type){
      case 'Rent':
        try {
          await safeMutation(
            deleteRentDocument,
            {
              projectId: projectId ?? 'unset',
              dealId: dealId,
              documentId: document.id,
            },
            isLoadingRentDocument
          );
    
          onClose(true);
        } catch (e) {
          console.log(e);
        }        
        break;
      case 'Sell':
        try {
          await safeMutation(
            deleteSellDocument,
            {
              projectId: projectId ?? 'unset',
              dealId: dealId,
              documentId: document.id,
            },
            isLoadingSellDocument
          );
    
          onClose(true);
        } catch (e) {
          console.log(e);
        }        
        break;        
    }
  };

  return (
    <ConfirmModal
      title={t('projectRent.dealDocumentRemoveDocument')}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      {(isLoadingRentDocument || isLoadingSellDocument) && <LoadingIndicator text={t('projectRent.documentDeleteLoading') ?? ''} mode="overlay" />}
      <Trans i18nKey="projectRent.dealDocumentDeleteConfirmation" values={{ entity: document.name }}>
        <div>
          Möchten Sie die Datei <span className="font-bold">Entity</span> wirklich löschen?
        </div>
        <div className="pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
