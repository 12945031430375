import { AuthSubjectReadModel } from '@client/shared/api';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { TrashIcon, UserAvatar } from '@client/shared/toolkit';

export type UserState = 'Present' | 'New' | 'Deleted';

interface RenderUserProps {
  state: UserState;
  user: AuthSubjectReadModel;
  allowRemove: boolean;
  className?: string;
  onRemove?: (user: AuthSubjectReadModel) => void;
}

export const RenderUser = ({ allowRemove, className, onRemove, state, user }: RenderUserProps) => (
  <div className={classNames('flex px-4 mt-2', className)}>
    <div className="flex flex-row w-full items-center">
      <div className={classNames(
          'flex items-center justify-center w-14 h-12 rounded-full',
          { 'bg-green-600': state === 'New' },
          { 'bg-red-600': state === 'Deleted' }
        )}>
        <UserAvatar userId={user.userId} userName={user.name} hasAvatar={user.hasAvatar} lastUpdate={user.lastUpdate} classNames="w-10 h-10" />
      </div>

      <div className="w-full truncate ml-2">
        <div className="font-bold truncate">{user.name !== ' ' ? user.name : user.firstName !== '' ? user.firstName : user.email}</div>
        <div className="text-sm text-gray-500 truncate">{user.email}</div>
      </div>

      <div className="text-gray-500 hover:text-black ml-2">
        {state === 'Present' && allowRemove && <TrashIcon className="w-6" onClick={() => onRemove?.(user)} />}
        {state !== 'Present' && allowRemove && <ArrowUturnLeftIcon className="w-6" onClick={() => onRemove?.(user)} />}
      </div>
    </div>
  </div>
);
