import * as yup from 'yup';
import { InferType } from 'yup';

export const DealRentConditionFormValidationSchema = yup.object({
  isSecurityCondition: yup.boolean(),
  securityAmount: yup.number().when('isSecurityCondition', ([isSecurityCondition], schema) => {
    if (isSecurityCondition) {
      return yup
        .number()
        .typeError('validation.number')
        .required('validation.required')
        .moreThan(0, 'validation.moreThan')
    }
    return schema
  }),
  securityType: yup.string(),
  paymentMethod: yup.string(),
  paymentAppointment: yup.date(),
  rentStart: yup.date(),
  rentEnd: yup.date(),
  contractTerminationNotice: yup.number().when('isSecurityCondition', ([isSecurityCondition], schema) => {
    if (!isSecurityCondition) {
      return yup
        .number()
        .typeError('validation.number')
        .required('validation.required')
        .moreThan(0, 'validation.moreThan')
    }
    return schema
  }),
  contractEarliestTerminationDate: yup.date(),
  contractPossibleExtensions: yup.number().when('isSecurityCondition', ([isSecurityCondition], schema) => {
    if (!isSecurityCondition) {
      return yup
        .number()
        .typeError('validation.number')
        .required('validation.required')
        .moreThan(0, 'validation.moreThan')
    }
    return schema
  }),
  contractExtensionDuration: yup.number().when('isSecurityCondition', ([isSecurityCondition], schema) => {
    if (!isSecurityCondition) {
      return yup
        .number()
        .typeError('validation.number')
        .required('validation.required')
        .moreThan(0, 'validation.moreThan')
    }
    return schema
  }),
  rentFreePeriodStart: yup.date(),
  rentFreePeriodEnd: yup.date(),
  billingPeriod: yup.string(),
  rentDueDays: yup.number().when('isSecurityCondition', ([isSecurityCondition], schema) => {
    if (!isSecurityCondition) {
      return yup
        .number()
        .typeError('validation.number')
        .required('validation.required')
        .moreThan(0, 'validation.moreThan')
    }
    return schema
  }),
  adjustmentType: yup.string(),
  adjustmentTypeActiveValue: yup.number().typeError('validation.number'),
  adjustmentInterval: yup.string(),
  adjustmentDeadline: yup.date(),
});

export type DealRentConditionFormValidationValues = InferType<typeof DealRentConditionFormValidationSchema>;
