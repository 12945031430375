import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import React, { useEffect, useState } from 'react';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import { InvoiceReportReadModel, useApiGetInvoiceReportQuery } from '@client/shared/api';
import { InvoiceCoverSheetViewSlideOver } from './InvoiceCoverSheetViewSlideOverProps';

export const InvoiceCoverSheetView = () =>{
  const { invoiceId } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const [isOpenICSViewSlideOver, setIsOpenICSViewSlideOver] = useState(false);
  const [icsData, setIcsData] = useState<InvoiceReportReadModel | null>(null);

  if (!invoiceId) {
    navigate(ROUTES_CONFIG.PROJECT_CONTROL.name);
  }

  const {
    data: fetchedIcsData,
    isFetching: isFetchingIcs,
    isError,
  } = useApiGetInvoiceReportQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      invoiceId: invoiceId ?? '',
      calculationModelId: loadedVariantId ?? ''
    },
    {
      skip: !loadedProjectId || !invoiceId || !loadedVariantId ||!!icsData
    },
  );

  useEffect(() => {
    if (typeof fetchedIcsData !== 'undefined' && fetchedIcsData !== null && fetchedIcsData.invoice) {
      setIcsData(fetchedIcsData.invoice)
      setIsOpenICSViewSlideOver(true);
    }
    if (isError) {
      navigate(ROUTES_CONFIG.PROJECT_CONTROL.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedIcsData, isError]);

  return (
    <SlideOver
      isOpen={isOpenICSViewSlideOver}
      onClose={() => setIsOpenICSViewSlideOver(false)}
      variant="2xl"
      confirmBeforeClose={false}
      onAfterLeave={() => {
        setIcsData(null)
        navigate(ROUTES_CONFIG.PROJECT_CONTROL.name);
      }}
    >
      {isFetchingIcs && (
        <LoadingIndicator text={t('projectControl.fetchingInvoiceLoadingIndicator')} mode="overlay-window" />
      )}
      {icsData && (
        <InvoiceCoverSheetViewSlideOver
          icsData={icsData}
          onClose={() => setIsOpenICSViewSlideOver(false)}
        />
      )}
    </SlideOver>
  );
}
