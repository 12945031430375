import {
  ApiDeleteProjectApiArg,
  ApiDeleteProjectApiResponse,
  ApiGetProjectApiArg,
  ApiGetProjectApiResponse,
  ApiGetProjectDashboardApiArg,
  ApiGetProjectDashboardApiResponse,
  ApiPostCreateProjectApiArg,
  ApiPostCreateProjectApiResponse,
  ApiPostProjectValuationApiArg,
  ApiPostProjectValuationApiResponse,
  ApiPostUpdateProjectMetadataApiArg,
  ApiPostUpdateProjectMetadataApiResponse,
  ApiPostUploadProjectCoverApiArg,
  ApiPostUploadProjectCoverApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiProjectEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetProject: {
    providesTags: (result: ApiGetProjectApiResponse, _error: FetchBaseQueryError, args: ApiGetProjectApiArg) => [
      { type: ApiTagTypes.Project, id: args.projectId },
      ...(result?.project?.calculationModels?.map((dto): { type: ApiTagTypes.CalculationModel; id: string } => ({
        type: ApiTagTypes.CalculationModel,
        id: dto.id,
      })) ?? []),
    ],
  },
  apiGetProjectDashboard: {
    providesTags: (
      _result: ApiGetProjectDashboardApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetProjectDashboardApiArg,
    ) => [
      { type: ApiTagTypes.Project, id: args.projectId },
      { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
    ],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostUploadProjectCover: {
    invalidatesTags: (
      _result: ApiPostUploadProjectCoverApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUploadProjectCoverApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Project, id: args.projectId },
            {
              type: ApiTagTypes.CompanyProjectImage,
              id: args.projectId,
            },
            ApiTagTypes.Projects,
          ]
        : [],
  },
  apiPostCreateProjectCopy: {
    invalidatesTags: [ApiTagTypes.Projects],
  },
  apiPostUpdateProjectMetadata: {
    invalidatesTags: (
      _result: ApiPostUpdateProjectMetadataApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateProjectMetadataApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.UserDefinedFields, id: args.projectId },
            ApiTagTypes.Projects,
          ]
        : [],
  },
  apiPostCreateProject: {
    invalidatesTags: (
      _result: ApiPostCreateProjectApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateProjectApiArg,
    ) => {
      if (error == null) {
        if ((args.body as FormData).get('projectGroupId')) {
          return [
            { type: ApiTagTypes.Project, id: args.body.ProjectGroupId },
            ApiTagTypes.Projects,
            ApiTagTypes.TenantCatalog,
          ];
        }
        return [ApiTagTypes.Projects, ApiTagTypes.TenantCatalog];
      }
      return [];
    },
  },
  apiDeleteProject: {
    invalidatesTags:(
      _result: ApiDeleteProjectApiResponse,
      error: FetchBaseQueryError,
      _args: ApiDeleteProjectApiArg,
    ) => 
      error == null
      ? [
          ApiTagTypes.Projects, 
          ApiTagTypes.TenantCatalog, 
          { type: ApiTagTypes.UserDefinedFieldDefinition, id: 'LIST' },
          ApiTagTypes.UserDefinedFields
        ]
      : [],
  },
  apiPostProjectValuation: {
    invalidatesTags: (
      _result: ApiPostProjectValuationApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostProjectValuationApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.CalculationModel, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId },
          ]
        : [],
  },
};
