import { useApiPostUpdateTenantNamesMutation } from '@client/shared/api';
import { useUi } from '@client/shared/store';
import {
  Button,
  Form,
  FormField,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
  TextInput
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

const NamesFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  name2: yup.string(),
});

type NamesFormValidationValues = InferType<typeof NamesFormValidationSchema>;

export const NamesEditSlideOver = ({ onClose }: SlideOverOnCloseProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const ui = useUi();
  const tenant = ui.appUser.tenant;
  const [postChangeName, { isLoading }] = useApiPostUpdateTenantNamesMutation();

  const handleSubmit = async (data: NamesFormValidationValues) => {
    try {
      await safeMutation(postChangeName, { body: { name: data.name, name2: data.name2 } }, isLoading);

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const defaultValues = {
    name: tenant?.name ?? '',
    name2: tenant?.name2 ?? '',
  };

  return (
    <>
      {isLoading && <LoadingIndicator mode="overlay-window" text={t('app.settingsTenantSavingLoadingIndicator')} />}
      <Form<NamesFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={NamesFormValidationSchema}
        defaultValues={defaultValues}
        className="flex flex-col flex-grow min-h-0"
      >
        <SlideOver.Header
          title={t('app.settingsTenantName')}
          subTitle={''}
          backgroundClassName="bg-blue-900"
          onClose={onClose}
        />
        <SlideOver.Content className="p-8">
          <div>
            <FormField name="name">{(control) => <TextInput label={t('common.name')} {...control} />}</FormField>
            <FormField name="name2">{(control) => <TextInput label={''} {...control} />}</FormField>
          </div>
        </SlideOver.Content>

        <SlideOver.Controls>
          <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
            {t('common.cancel')}
          </Button>
          <Button variant="primary" formSubmit={true} innerRef={submitRef}>
            {t('common.save')}
          </Button>
        </SlideOver.Controls>
      </Form>
    </>
  );
};
