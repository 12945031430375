import { useLoadedProjectId } from '@client/project/store';
import { DealType, useApiGetRentDealsQuery, useApiGetSellDealsQuery } from '@client/shared/api';

export const useGetProjectDeals = (type: DealType) => {
  const loadedProjectId = useLoadedProjectId();
  
  const rentDeals = useApiGetRentDealsQuery({ projectId: loadedProjectId ?? '' }, { skip: type === 'Sell' || loadedProjectId == null });
  const sellDeals = useApiGetSellDealsQuery({ projectId: loadedProjectId ?? '' }, { skip: type === 'Rent' || loadedProjectId == null });

  switch (type){
    case 'Rent':
      return rentDeals;
    case 'Sell':
      return sellDeals;
  }
};
