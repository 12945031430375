import {
  EmailIcon,
  GlobeIcon,
  PhoneIcon,
  SmartphoneIcon
} from '../../icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SlideOverTitle } from '../SlideOverTitle';
import { TextInput } from '../TextInput';
import { FormField } from '../FormField';

type ContactDetailsField = 'email' | 'phone' | 'mobile' | 'web'

interface ContactDetailsFormFieldsProps {
  fields?: ContactDetailsField[]
}

export const ContactDetailsFormFields = (props: ContactDetailsFormFieldsProps) => {
  const {
    fields = ['email', 'phone', 'mobile', 'web']
  } = props
  const { t } = useTranslation()
  return (
    <>
      <SlideOverTitle title={t('app.companiesContactDetails')} />
      {fields.includes('email') && (
        <FormField name="email">{(control) => <TextInput icon={<EmailIcon />} label={t('common.email')} {...control} />}</FormField>
      )}
      {fields.includes('phone') && (
        <FormField name="phone">{(control) => <TextInput icon={<PhoneIcon />} label={t('common.phoneNumber')} {...control} />}</FormField>
      )}
      {fields.includes('mobile') && (
        <FormField name="mobile">
          {(control) => <TextInput icon={<SmartphoneIcon />} label={t('common.mobileNumber')} {...control} />}
        </FormField>
      )}
      {fields.includes('web') && (
        <FormField name="web">
          {(control) => <TextInput icon={<GlobeIcon />} label={t('app.masterDataCompanyWebsite')} {...control} />}
        </FormField>
      )}
    </>
  )
}
