import { FinancingPaymentPlanElementReadModel } from '@client/shared/api';
import {
  ContextMenu,
  DatePicker,
  ContextMenuItem,
  NumberInput,
  TextInput,
  ReceiveCashIcon,
  TrashIcon
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { formatDateOnly } from '@client/shared/utilities';
import { useGetCurrency } from '@client/project/store';

interface RenderFinancingPayoutElementProps {
  item: FinancingPaymentPlanElementReadModel;
  onItemChange: (item: FinancingPaymentPlanElementReadModel) => void;
  onItemDeleted: (item: FinancingPaymentPlanElementReadModel) => void;
}

export const RenderFinancingPayoutElement = ({
  item,
  onItemChange,
  onItemDeleted,
}: RenderFinancingPayoutElementProps) => {
  const { t } = useTranslation();
  const currentCurrency = useGetCurrency();

  const isCurrency = item?.amount != null;

  const contextItems: ContextMenuItem[] = [
    {
      label: t('projectCalculate.financingDeleteTitle'),
      subtitle: t('projectCalculate.financingDeleteSubTitle'),
      icon: <TrashIcon />,
      onClick: () => onItemDeleted(item),
    },
  ];

  return (
    <div className="flex flex-row items-center bg-white divide-x">
      <div className="w-3/12">
        <DatePicker
          label={t('projectCalculate.financingLabelPaymentOn')}
          value={new Date(item.paymentOn)}
          onChange={(paymentOn) => {
            if (paymentOn && item.paymentOn !== formatDateOnly(paymentOn)) {
              onItemChange({...item, paymentOn: formatDateOnly(paymentOn) ?? ''});
            }
          }}
        />
      </div>
      <div className="w-6/12">
        <TextInput
          label={t('projectCalculate.financingLabelDescription')}
          value={item.description}
          onChange={(description) => {
            if (description !== item.description) {
              onItemChange({...item, description});
            }
          }}
        />
      </div>
      <div className="w-3/12 flex flex-row items-center">
        <div>
          {isCurrency && (
            <NumberInput
              label={t('projectCalculate.financingLabelAmount')}
              value={item.amount ?? 0}
              onChange={(amount) => {
                if (item.amount !== amount) {
                  onItemChange({ ...item, amount: amount ?? 0, percentage: null });
                }
              }}
              step={1}
              icon={<ReceiveCashIcon />}
            />
          )}
          {!isCurrency && (
            <NumberInput
              label={t('projectCalculate.financingLabelAmount')}
              value={item.amount ?? 0}
              onChange={(percentage) => {
                if (percentage !== item.percentage) {
                  onItemChange({ ...item, percentage: percentage ?? 0, amount: null });
                }
              }}
              step={1}
              icon={<ReceiveCashIcon />}
            />
          )}
        </div>
        <div className="text-xxs pr-1">{isCurrency ? currentCurrency : '%'}</div>
        <div className="px-1 text-gray-400 hover:text-gray-600">
          <ContextMenu items={contextItems} />
        </div>
      </div>
    </div>
  );
};
