import {
  CalculationRuleGroupReadModel,
  useApiDeleteProjectCalculationRuleGroupMutation,
  useApiDeleteTenantCalculationRuleGroupMutation
} from '@client/shared/api';
import { ConfirmModal, HintBox, LoadingIndicator, Modal } from '@client/shared/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IcsType } from '.';
import { safeMutation } from '@client/shared/utilities';

export const IcsGroupDeleteModal = ({
  group,
  isOpen,
  onClose,
  type,
  invoiceCalculationSchemeId,
  projectId
}: {
  group: CalculationRuleGroupReadModel | null;
  isOpen: boolean;
  onClose: () => void;
  type: IcsType;
  invoiceCalculationSchemeId: string
  projectId?: string
}) => {
  const { t } = useTranslation();

  const [deleteTenantIcsGroup, { isLoading: isDeletingTenantIcsGroup }] =
    useApiDeleteTenantCalculationRuleGroupMutation();
  const [deleteProjectIcsGroup, { isLoading: isDeletingProjectIcsGroup }] =
    useApiDeleteProjectCalculationRuleGroupMutation();

  const handleOnClose = () => {
    onClose();
  };

  const handleDelete = async () => {
    if (group && invoiceCalculationSchemeId) {
      if (type === 'tenant') {
        try {
          await safeMutation(
            deleteTenantIcsGroup,
            {
              invoiceCalculationSchemeId: invoiceCalculationSchemeId,
              calculationRuleGroupId: group.calculationRuleGroupId
            },
            isDeletingTenantIcsGroup,
          )
          onClose()
        } catch (e) {
          console.log(e);
        }
      } else if (projectId) {
        try {
          await safeMutation(
            deleteProjectIcsGroup,
            {
              invoiceCalculationSchemeId: invoiceCalculationSchemeId,
              calculationRuleGroupId: group.calculationRuleGroupId,
              projectId: projectId,
            },
            isDeletingProjectIcsGroup,
          );
          onClose()
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {(isDeletingTenantIcsGroup || isDeletingProjectIcsGroup) && (
        <LoadingIndicator text={t('app.settingsSavingInvoiceCoverSheet')} mode="overlay-window" />
      )}
      {group && (
        <ConfirmModal
          title={t('ics.deleteInvoiceCoverSheetGroupTitle', { name: group.name})}
          onConfirm={handleDelete}
          onClose={handleOnClose}
          confirmVariant="danger"
          confirmText={t('common.delete')}
        >
          <HintBox hintType="warning">{t('ics.deleteGroupHintMessage')}</HintBox>
        </ConfirmModal>
      )}
    </Modal>
  );
};
