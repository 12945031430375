import { CalculationState } from '@client/shared/api';
import { Column } from './CalculateContainer';
import classNames from 'classnames';
import { FormattedCurrency } from '@client/project/shared';

interface ElementSumProps {
  classNames?: string;
  calculationState: CalculationState;
  total?: number | null;
  calculatedValue?: number | null;
  effectiveValue?: number | null;
  difference?: number | null;
  overflow?: number | null;
  remainder?: number | null;
  forecast?: number | null;
  column?: Column;
}

export const getDifferenceElement = (difference: number): JSX.Element | null => {
  if (difference === 0) return null;
  return (
    <span
      className={classNames('text-xs pr-1 whitespace-nowrap', {
        'text-red-800': difference > 0,
        'text-green-800': difference < 0,
      })}
    >
      {difference > 0 ? '+' : '-'}
      <FormattedCurrency amount={Math.abs(difference)} options={{maxDigits: 0}} />
    </span>
  );
};

export const CalculateElementGroupSum = (props: ElementSumProps) => {
  let displayValue: number | undefined | null;
  let changeValue: number | undefined | null;

  if (props.calculationState === 'JustTotal') {
    displayValue = props.total;
    changeValue = props.forecast && props.total && props.total !== 0 ? props.forecast - props.total : undefined;
  } else if (props.calculationState === 'JustCalculated') {
    displayValue = props.calculatedValue;
    changeValue =
      props.forecast && props.calculatedValue && props.calculatedValue !== 0
        ? props.forecast - props.calculatedValue
        : undefined;
  } else {
    displayValue = props.effectiveValue;
    changeValue =
      props.forecast && props.effectiveValue && props.effectiveValue !== 0
        ? props.forecast - props.effectiveValue
        : undefined;
  }

  return props.column === Column.CURRENT ? (
    <div className={classNames(props.classNames, 'text-right flex flex-row justify-end items-center leading-tight')}>
      {props.calculationState === 'Empty' && <div>&nbsp;</div>}
      {props.calculationState !== 'Empty' && (
        <div className="font-bold">
          <div>
            {changeValue && getDifferenceElement(changeValue)}
            {!!displayValue && <FormattedCurrency amount={displayValue} />}
          </div>
          {!!props.forecast && <div className="text-xs text-slate-400"><FormattedCurrency amount={props.forecast} /></div>}
        </div>
      )}
    </div>
  ) : (
    <div className={classNames(props.classNames, 'text-right flex flex-row justify-end items-center leading-tight text-[15px]')}>
      {props.calculationState === 'Empty' && <div>&nbsp;</div>}
      {props.calculationState === 'JustTotal' && props.total !== 0 && (
        <div className="font-bold"><FormattedCurrency amount={props.total} /></div>
      )}
      {props.calculationState === 'JustCalculated' && props.calculatedValue !== 0 && (
        <div className="font-bold italic"><FormattedCurrency amount={props.calculatedValue} /></div>
      )}
      {props.calculationState === 'Equality' && props.effectiveValue !== 0 && (
        <div className="font-bold">
          <FormattedCurrency amount={props.effectiveValue} />
          <div className="text-xs font-light">+/- 0</div>
        </div>
      )}
      {props.calculationState === 'Remainder' && props.effectiveValue !== 0 && (
        <div className="font-bold">
          <FormattedCurrency amount={props.effectiveValue} />
          <div className="text-xs text-green-800 font-light">+ <FormattedCurrency amount={props.remainder} /></div>
        </div>
      )}
      {props.calculationState === 'Overflow' && props.effectiveValue !== 0 && (
        <div className="font-bold text-red-800">
          <FormattedCurrency amount={props.effectiveValue} />
          <div className="text-xs text-red-900 font-light">- <FormattedCurrency amount={props.overflow} /></div>
        </div>
      )}
    </div>
  );
};
