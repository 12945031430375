import { useApiPostCheckSessionMutation, useApiPostLoginMutation } from '@client/shared/api';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Button, TextInput, Form, FormField, FormHelperText } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { LoginFormValidationSchema, LoginFormValidationValues } from './LoginFormValidationSchema';
import { setPostLoginRedirect, settings } from '@client/shared/store';
import { useDispatch } from 'react-redux';
import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/solid';

export const LoginView = () => {
  const { t } = useTranslation();

  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [callLogin, callStateLogin] = useApiPostLoginMutation();
  const [callCheckSession, callCheckSessionState] = useApiPostCheckSessionMutation();

  const { isLoading } = callStateLogin || callCheckSessionState;

  const [searchParams] = useSearchParams();
  const redirectTarget = decodeURIComponent(searchParams.get('_r') || '/');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPostLoginRedirect(redirectTarget));
  }, [dispatch, redirectTarget]);

  const navigate = useNavigate();

  const doLogin = useCallback(
    async (email: string, password: string) => {
      try {
        await callLogin({
          body: {
            password,
            email,
          },
        }).unwrap();

        await callCheckSession();

        setFormError(undefined);
        navigate(redirectTarget);

        // FP: Catch can only be annotated with any or unknown so any is used here
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.status === 401) {
          setFormError(t('app.notificationInvalidUserCredentials'));
        }
      }
    },
    [callCheckSession, callLogin, navigate, redirectTarget, t],
  );

  const handleSubmit = async (data: LoginFormValidationValues) => {
    await doLogin(data.email, data.password);
  };

  const isDevMode = settings.devMode;

  const defaultFormValues = { email: '', password: '' };
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <div className="flex flex-row items-center justify-between mb-6">
        <h1 className="text-4xl font-medium">{t('auth.loginTitle')}</h1>
      </div>
      <Form<LoginFormValidationValues>
        className="space-y-6"
        onSubmit={handleSubmit}
        validationSchema={LoginFormValidationSchema}
        defaultValues={defaultFormValues}
      >
        <div className="divide-gray-100 divide-y">
          <FormField name="email">
            {(control) => (
              <TextInput passDataCy="input-email" label={t('common.email')} {...control} autoComplete="username" />
            )}
          </FormField>
          <FormField name="password">
            {(control) => (
              <div className="flex justify-between items-center bg-white" onBlur={() => setShowPassword(false)}>
                <TextInput
                  label={t('common.password')}
                  passDataCy="input-password"
                  inputType={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  className="w-full"
                  {...control}
                />
                <div
                  className="w-5 cursor-pointer mx-2"
                  onClick={() => setShowPassword(!showPassword)}
                  data-cy="button-show-password"
                >
                  {showPassword ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
                </div>
              </div>
            )}
          </FormField>
          {formError && <FormHelperText text={formError} error={true} passCyData="div-form-error" />}
        </div>

        <div className="flex items-center justify-end">
          <div className="text-sm">
            <button
              type="button"
              data-cy="button-forgot-password"
              onClick={() => navigate('/auth/forgot-password')}
              className="text-secondary hover:text-sky-700 transition-colors duration-200 cursor-pointer"
            >
              {t('auth.loginForgotPassword')}
            </button>
          </div>
        </div>

        {isDevMode && (
          <div>
            <div>
              <Button
                onClick={(evt) => {
                  evt.preventDefault();
                  doLogin('cb@prob.is', 'Pa$$w0rd');
                }}
                variant="primary"
              >
                Sign in as Armin
              </Button>
              <Button
                onClick={(evt) => {
                  evt.preventDefault();
                  doLogin('ma@prob.is', 'Pa$$w0rd');
                }}
                variant="primary"
              >
                Sign in as John
              </Button>
            </div>
            <div className="mt-1">
              <Button
                onClick={(evt) => {
                  evt.preventDefault();
                  doLogin('haraldLeer@prob.is', 'Pa$$w0rd');
                }}
                variant="primary"
              >
                Sign in as Harald Leer
              </Button>
            </div>
          </div>
        )}

        <div className="flex justify-end">
          <Button disabled={isLoading} variant="primary" formSubmit={true} passDataCy="button-login">
            {t('auth.loginAction')}
          </Button>
        </div>
      </Form>
    </>
  );
};
