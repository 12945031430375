import { AuthRoleReadModel, useApiPostCopyAuthRoleMutation } from '@client/shared/api';
import {
  BaseSelect,
  Button,
  Form,
  FormField,
  FormRefHandle,
  FormWatch, LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
  TagWindowIcon,
  TextInput
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { ShieldCheckIcon } from '@heroicons/react/24/outline';
import { CopyRoleFormValidationSchema, CopyRoleFormValidationValues } from './CopyRoleFormValidationSchema';
import { safeMutation } from '@client/shared/utilities';
import { TranslateRoleName } from './RoleTranslation';

interface RoleCopySlideOverProps extends SlideOverOnCloseProps  {
    availableRoles : AuthRoleReadModel[]
}

export const RoleCopySlideOver = ({ availableRoles, onClose }: RoleCopySlideOverProps) => {
    const { t } = useTranslation();
    const submitRef = useRef<HTMLButtonElement>(null);
    const formRef = useRef<FormRefHandle<CopyRoleFormValidationValues>>();

    const [postCopy, { isLoading: isCopying }] = useApiPostCopyAuthRoleMutation();

    const handleCopyRoleSubmit = async (data: CopyRoleFormValidationValues) => {
        try {
            await safeMutation(
                postCopy,
                {
                    roleId: data.role,
                    body: {
                        copyName: data.name,
                        copyComment: data.comment,
                    },
                },
                isCopying
            );

            onClose(true);
        } catch {
            /* left blank */
        }
    };

    const roleOptions = availableRoles.map((role) => ({
        label: TranslateRoleName(role.name, role.systemRoleType),
        value: role.id,
      }));

    const defaultFormValues = {
        role: availableRoles[0].id,
        name: t('auth.roleCopyOf') + ' ' + TranslateRoleName(availableRoles[0].name, availableRoles[0].systemRoleType),
        comment: '',
    };

    return (
        <Form<CopyRoleFormValidationValues>
            onSubmit={handleCopyRoleSubmit}
            validationSchema={CopyRoleFormValidationSchema}
            defaultValues={defaultFormValues}
            ref={formRef}
            className="w-full flex flex-col justify-between h-full"
        >
            {isCopying && (
              <LoadingIndicator text={t('common.loading')} mode="overlay" />
            )}
            <SlideOver.Header
                title={t('auth.roleAdd')}
                backgroundClassName="bg-gray-600"
                onClose={() => onClose(true)}
            />
            <SlideOver.Content>
                <div className="m-8 bg-white">
                    <div className="divide-gray-100 divide-y">
                        <FormWatch<CopyRoleFormValidationValues>
                            onChange={({ role }) => {
                                const newRole = availableRoles.find(x => x.id === role);
                                if (!newRole) {
                                    return;
                                }

                                const newName = t('auth.roleCopyOf') + ' ' + TranslateRoleName(newRole.name, newRole.systemRoleType);
                                formRef.current?.setValue('name', newName);
                            }}
                            fieldNames={['role']}
                        >
                            {() => (
                                <FormField name="role">
                                    {(control) => (
                                        <BaseSelect
                                            label={t('auth.role')}
                                            options={roleOptions}
                                            icon={<ShieldCheckIcon className="w-5 h-5" />}
                                            {...control}
                                        />
                                    )}
                                </FormField>
                            )}
                        </FormWatch>
                        <FormField name="name">
                            {(control) => (
                                <TextInput
                                    label={t('common.name')}
                                    icon={<TagWindowIcon />}
                                    {...control}
                                />
                            )}
                        </FormField>
                        <FormField name="comment">
                            {(control) => (
                                <TextInput
                                    label={t('auth.comment')}
                                    icon={<TagWindowIcon />}
                                    {...control}
                                />
                            )}
                        </FormField>
                    </div>
                </div>
            </SlideOver.Content>
            <SlideOver.Controls>
                <Button variant="secondary" className="mr-2" onClick={() => onClose(true)}>
                    {t('common.cancel')}
                </Button>
                <Button variant="primary" formSubmit={true} innerRef={submitRef}>
                    {t('common.copy')}
                </Button>
            </SlideOver.Controls>
        </Form>
    );
};
