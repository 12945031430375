import { CatalogTemplateElements } from './CatalogTemplateElements';
import { RectangleGroupIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { TemplateElementStatus, TemplateStatus } from './CatalogTemplateUtilities';
import { TrashIcon } from '@client/shared/toolkit';

interface CatalogTemplateProps {
  catalogTemplate: TemplateStatus,
  handleDeleteTemplate: (deletedTemplate: TemplateStatus) => void;
  handleChangeElement: (template: TemplateStatus) => void;
}

export const CatalogTemplate = ({
    catalogTemplate,
    handleDeleteTemplate,
    handleChangeElement
}: CatalogTemplateProps) => {

    const handleChange = (changedElements: TemplateElementStatus[], deletedElement?: TemplateElementStatus) => {
        const newElements = catalogTemplate.elements.slice();

        if (deletedElement){
            const current = getCurrentElementStatus(newElements, deletedElement);

            if (current){
                const index = newElements.indexOf(current);
                newElements.splice(index, 1);
            }
        }

        changedElements.forEach(element => {
            const current = getCurrentElementStatus(newElements, element);

            if (current){
                const index = newElements.indexOf(current);
                newElements.splice(index, 1, element);
            } else {
                newElements.push(element);
            }
        });

        handleChangeElement({
            template: catalogTemplate.template,
            elements: newElements
        });
    }

    const getCurrentElementStatus = (elementStatuses: TemplateElementStatus[], elementStatus: TemplateElementStatus) => {
        if (elementStatus.element.costCatalogElement){
            return elementStatuses.find(x => x.element.costCatalogElement?.id === elementStatus.element.costCatalogElement?.id);
        }

        if (elementStatus.element.riskCatalogElement){
            return elementStatuses.find(x => x.element.riskCatalogElement?.id === elementStatus.element.riskCatalogElement?.id);
        }

        if (elementStatus.element.earningsCatalogElement){
            return elementStatuses.find(x => x.element.earningsCatalogElement?.id === elementStatus.element.earningsCatalogElement?.id);
        }

        if (elementStatus.element.financingCatalogElement){
            return elementStatuses.find(x => x.element.financingCatalogElement?.id === elementStatus.element.financingCatalogElement?.id);
        }
    }

    return (
        <div className='flex flex-col bg-gray-200 pl-2'>
            <div className='flex flex-row py-4 font-medium text-lg text-black' >
                <div className='flex flex-row w-11/12'>
                    <RectangleGroupIcon className={classNames('w-6 mr-2', catalogTemplate.template.id === '' ? 'text-green-500' : 'text-black')} />
                    {catalogTemplate.template.catalogTemplate.name}
                </div>
                <div className="w-1/12 flex justify-center items-center mr-2 text-gray-500 hover:text-black">
                    <TrashIcon className="w-6" onClick={() => handleDeleteTemplate(catalogTemplate)} />
                </div>
            </div>
            {catalogTemplate.template.catalogTemplate.costCatalog.elements.map((element, index) => (
                <CatalogTemplateElements
                    key={index}
                    level={0}
                    roleCatalogTemplateId={catalogTemplate.template.id}
                    costCatalogElement={element}
                    catalogElementPermissions={catalogTemplate.elements}
                    handleChangeElement={handleChange}
                />
            ))}
            {catalogTemplate.template.catalogTemplate.riskCatalog?.elements.map((element, index) => (
                <CatalogTemplateElements
                    key={index}
                    level={0}
                    roleCatalogTemplateId={catalogTemplate.template.id}
                    riskCatalogElement={element}
                    catalogElementPermissions={catalogTemplate.elements}
                    handleChangeElement={handleChange}
                />
            ))}
            {catalogTemplate.template.catalogTemplate.earningsCatalog?.elements.map((element, index) => (
                <CatalogTemplateElements
                    key={index}
                    level={0}
                    roleCatalogTemplateId={catalogTemplate.template.id}
                    earningsCatalogElement={element}
                    catalogElementPermissions={catalogTemplate.elements}
                    handleChangeElement={handleChange}
                />
            ))}
            {catalogTemplate.template.catalogTemplate.financingCatalog?.elements.map((element, index) => (
                <CatalogTemplateElements
                    key={index}
                    level={0}
                    roleCatalogTemplateId={catalogTemplate.template.id}
                    financingCatalogElement={element}
                    catalogElementPermissions={catalogTemplate.elements}
                    handleChangeElement={handleChange}
                />
            ))}
        </div>
    );
};
