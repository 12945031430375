import {
  ImportReadModel,
  useApiGetExchangeDataImportsQuery,
  useApiPostExchangeDataProcessImportMutation,
} from '@client/shared/api';
import { Button, SlideOver, SlideOverOnCloseProps } from '@client/shared/toolkit';
import { formatDateTime } from '@client/shared/utilities';
import classNames from 'classnames';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface ImportSlideOverProps extends SlideOverOnCloseProps {
  projectId: string;
  calcModelId: string;
}

export const ImportForecastSlideOver = ({ projectId, calcModelId, onClose }: ImportSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const { data, isFetching } = useApiGetExchangeDataImportsQuery({ proId: projectId });
  const [doImportMutation, {isLoading: isImporting}] = useApiPostExchangeDataProcessImportMutation();
  const imports: ImportReadModel[] = data ?? [];

  const [selectedImport, setSelectedImport] = useState<ImportReadModel | undefined>();
  const [includeInvoice, setIncludeInvoice] = useState<boolean>(false);
  const disableImport: boolean =
    (selectedImport == null ? true : false) &&
    (selectedImport?.executedImports.some((i) => i.calcModelId === calcModelId) ? false : true);

  const handleSelect = (imp: ImportReadModel) => {
    if (!imp.executedImports.some((i) => i.calcModelId === calcModelId)) {
      setSelectedImport((prev) => (prev?.id !== imp.id ? imp : undefined));
    }
  };

  const handleImport = async () => {
    if (!selectedImport) return;

    try {
      await doImportMutation({
        proId: projectId,
        calcModelId: calcModelId,
        body: {
          importId: selectedImport.id,
          importInvoiceData: includeInvoice,
        },
      }).unwrap();
    } catch (e) {
      console.log(e);
    }
    setSelectedImport(undefined);
    setIncludeInvoice(false);
  };

  return (
    <>
      <SlideOver.Header
        onClose={onClose}
        title={t('projectCalculate.ForecastImport')}
        subTitle={t('projectCalculate.ForecastImportDescription')}
        backgroundClassName="bg-sky-900 w-full text-white p-8 "
      />
      <SlideOver.Content isLoading={isFetching || isImporting}>
        <div className="m-5">
          {!selectedImport ? (
            imports.map((imp, idx) => (
              <div
                key={`import-${idx}`}
                className="bg-white m-1 hover:bg-gray-200 cursor-pointer"
                onClick={() => handleSelect(imp)}
              >
                <div
                  className={classNames(
                    'border-l-4 p-2',
                    { 'border-slate-400': !imp.executedImports.some((i) => i.calcModelId === calcModelId) },
                    {
                      'border-green-400 cursor-default': imp.executedImports.some((i) => i.calcModelId === calcModelId),
                    }
                  )}
                >
                  <div
                    className={classNames('flex flex-row justify-between', {
                      'text-gray-500': imp.executedImports.some((i) => i.calcModelId === calcModelId),
                    })}
                  >
                    <div className="flex flex-col">
                      <div className="text-xs font-medium text-slate-400">
                        {t('projectCalculate.ForecastImportDataInventoryExported')}
                      </div>
                      <div className="font-bold">{formatDateTime(imp.staged)}</div>
                    </div>
                    {imp.executedImports.some((i) => i.calcModelId === calcModelId) && (
                      <div className="flex flex-col text-right">
                        <div className="text-xs font-medium text-slate-400">
                          {t('projectCalculate.ForecastImportDataInventoryImported')}
                        </div>
                        <div className="font-bold">
                          {formatDateTime(imp.executedImports.find((i) => i.calcModelId === calcModelId)?.imported)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>
              <div className="flex flex-row justify-between bg-white m-1 p-2 border-l-4 border-slate-400">
                <div className="flex flex-col">
                  <div className="text-xs font-medium text-slate-400">
                    {t('projectCalculate.ForecastImportDataInventoryExported')}
                  </div>
                  <div className="font-bold">{formatDateTime(selectedImport.staged)}</div>
                </div>
                <div className="flex flex-col text-right">
                  <div className="text-xs font-medium text-slate-400">
                    {t('projectCalculate.ForecastImportProjectState')}
                  </div>
                  <div className="font-bold">{formatDateTime(selectedImport.projectState)}</div>
                </div>
              </div>
              {selectedImport.includesInvoices && (
                <label className="flex justify-center items-center h-14 p-3 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={includeInvoice}
                    onChange={() => setIncludeInvoice(!includeInvoice)}
                    autoFocus={false}
                  />
                  <div className="ml-2 font-medium">{t('projectCalculate.ForecastImportIncludeInvoice')}</div>
                </label>
              )}
              <div className="mt-10 m-1">
                {selectedImport.executedImports.length > 0 ? (
                  <>
                    <div className="p-3 font-medium">{t('projectCalculate.ForecastImportAlsoImportedTo')}</div>
                    {selectedImport.executedImports.map((execImp, idx) => (
                      <div className="flex flex-row justify-between bg-white p-3 border-l-4" key={`execImport-${idx}`}>
                        <div className="text-lg font-medium ">{execImp.calculationModelName}</div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="flex justify-center items-center p-3 font-medium">
                    {t('projectCalculate.ForecastImportNotImported')}
                  </div>
                )}
              </div>
            </>
          )}
          {imports.length === 0 && (
            <div className="ml-2 flex justify-center items-center p-3 font-medium">
              {t('projectCalculate.ForecastImportNoImports')}
            </div>
          )}
        </div>
      </SlideOver.Content>

      <SlideOver.Controls disabled={isFetching}>
        <div className="w-full flex justify-end">
          <div className="flex">
            {selectedImport ? (
              <>
                <Button variant="secondary" onClick={() => setSelectedImport(undefined)} className="mr-2">
                  {t('projectCalculate.ForecastImportBack')}
                </Button>
                <Button variant="primary" onClick={handleImport} disabled={disableImport} innerRef={submitRef}>
                  {t('common.import')}
                </Button>
              </>
            ) : (
              <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
                {t('common.close')}
              </Button>
            )}
          </div>
        </div>
      </SlideOver.Controls>
    </>
  );
};
