import { BaseSelect } from '@client/shared/toolkit';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FinancePayoutTypeEnum } from '@client/shared/api';

interface FinancingPaymentSuggestionSelectProps {
  className?: string;
  defaultSuggestionType: FinancePayoutTypeEnum | 'None';
  selectedValue: FinancePayoutTypeEnum | 'None';
  onChange: (type: FinancePayoutTypeEnum) => void;
  disabledTypes?: FinancePayoutTypeEnum[];
  disabled?: boolean;
}

export const FinancingPaymentSuggestionSelect = (props: FinancingPaymentSuggestionSelectProps) => {
  const { t } = useTranslation();
  const { className, defaultSuggestionType, selectedValue, onChange, disabledTypes = [], disabled = false } = props;

  const handleChange = (type: string) => {
    onChange(type as FinancePayoutTypeEnum);
  };

  const selectableTypes = useMemo(() => {
    const types = [
      {
        label: t('common.none'),
        value: 'None',
      },
      {
        label: t('projectCalculate.financingPaymentSuggestionType.ProRataPayout'),
        value: 'ProRataPayout' as FinancePayoutTypeEnum,
      },
      {
        label: t('projectCalculate.financingPaymentSuggestionType.PayoutFirstPayout'),
        value: 'PayoutFirst' as FinancePayoutTypeEnum,
      },
      {
        label: t('projectCalculate.financingPaymentSuggestionType.PayoutLastPayout'),
        value: 'PayoutLast' as FinancePayoutTypeEnum,
      },
      // there will be coming more
    ];
    if (disabledTypes && disabledTypes.length > 0) {
      return types.filter(
        (type) => type.value !== 'None' && !disabledTypes.includes(type.value as FinancePayoutTypeEnum)
      );
    }
    return types;
  }, [t, disabledTypes]);

  return (
    <BaseSelect
      className={className}
      disabled={disabled}
      options={selectableTypes}
      label={t('projectCalculate.financingPaymentSuggestionType.label')}
      value={selectedValue ?? defaultSuggestionType}
      onChange={(value) => handleChange(value)}
    />
  );
};
