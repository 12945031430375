import { CalculateContext, TimeLineView } from '.';
import React, { PropsWithChildren, ReactNode, useContext } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { TextHighlighter } from '@client/shared/toolkit';

export interface CalculateGroupElementInformationColumnProps extends PropsWithChildren {
  className?: string;
  level: number;
  view: TimeLineView;
  handleOnClick?: (evt: React.MouseEvent<HTMLDivElement>) => void;
  rightColumn?: ReactNode;
  leftColumn?: ReactNode;
  codeWidth?: number;
  menu?: ReactNode;
  'data-cy'?: string;
}

/* Information Column for group elements */
export const CalculateGroupElementInformationColumn = (props: CalculateGroupElementInformationColumnProps) => {
  const { level, rightColumn, leftColumn, view, handleOnClick, codeWidth = 0, menu } = props;
  const { isListCollapsed } = useContext(CalculateContext);
  return (
    <div
      className={cn('flex w-full h-full', {
        'cursor-pointer': handleOnClick,
      })}
      onClick={handleOnClick}
      data-cy={props['data-cy']}
    >
      <div
        className={cn({
          'w-2/3 md:w-1/2': view === TimeLineView.TIMELINE && !isListCollapsed,
          'w-full flex-1': isListCollapsed || view === TimeLineView.FINANCE,
          'pr-6': isListCollapsed && view === TimeLineView.TIMELINE,
        })}
      >
        <div
          data-level={level}
          className={cn('group w-full flex h-full', {
            'text-[15px]': level === 0,
            'text-xs': level > 0,
            'pl-8': level < 2,
            'pl-[4.375rem]': level === 2 && codeWidth < 8,
            'pl-24': level === 2 && codeWidth >= 8,
            'pl-[8.75rem]': level === 3,
            // 'pl-24': level === 3,
            'pr-4': view === TimeLineView.FINANCE,
          })}
        >
          <div
            className={cn('flex w-full truncate', {
              'border-b': level === 0,
              //'ml-12': level === 2,
              //'ml-24': level === 3,
            })}
          >
            {leftColumn}
          </div>
        </div>
      </div>
      {view === TimeLineView.TIMELINE && !isListCollapsed && (
        <div className="w-1/3 md:w-1/2 pr-6 lg:pr-0">
          <div
            className={cn('w-full flex h-[38px] pr-4 truncate justify-end', {
              'border-b': level === 0,
              'text-sm': level === 0,
              'text-xs': level > 0,
            })}
          >
            {rightColumn}
          </div>
        </div>
      )}
      {menu}
    </div>
  );
};

export interface CalculateGroupElementInformationColumnContentProps extends PropsWithChildren {
  level: number;
  code?: string | null;
  handleOnCodeClick?: (evt: React.MouseEvent<HTMLDivElement>) => void;
  showExpanded?: boolean;
  showError?: boolean;
  title?: string | null;
  contentType?: 'group' | 'row';
  marker?: ReactNode;
  showCode?: boolean;
  searchValue?: string;
}
export const CalculateInformationColumnContent = (props: CalculateGroupElementInformationColumnContentProps) => {
  const {
    showCode = true,
    code,
    handleOnCodeClick,
    level,
    showExpanded,
    showError,
    title,
    children,
    contentType = 'group',
    marker,
    searchValue,
  } = props;
  return (
    <div className="w-full flex items-center gap-2">
      <div className={cn('flex items-center flex-none justify-between gap-2')}>
        {/* Code */}
        <span
          data-level={level}
          data-content-type={contentType}
          className={cn('shrink-0 text-right flex-1', {
            'truncate': code && code.length > 7,
            'font-bold': level === 0,
            'w-10': showCode && ((level <= 2 && contentType === 'row') || (level < 2 && contentType === 'group')),
            'w-12': (level > 2 && contentType === 'row') || (level >= 2 && contentType === 'group'),
          })}
          onClick={handleOnCodeClick}
        >
          {showCode && !searchValue && code}
          {showCode && searchValue && code && (
            <TextHighlighter
              text={code}
              highlighted={searchValue
                .split(',')
                .filter((x) => x !== '')
                .map((x) => x.trim())}
            />
          )}
        </span>

        {/* Marker */}
        {marker ? (
          marker
        ) : (
          <div className={cn('relative w-[0.5625rem] flex-none', { invisible: !showExpanded })}>
            {contentType === 'row' && <div className="w-px h-8 absolute ml-1 -mt-9 bg-slate-400 z-0" />}
            <div className="w-[0.5625rem] h-[0.5625rem] p-px absolute -mt-1 rounded-full bg-white shadow border-2 border-slate-400 z-[5]" />
          </div>
        )}
      </div>

      {/* Error + Title */}
      <div
        className={cn('flex flex-row items-center gap-2 truncate', {
          'font-bold': level < 2 && contentType !== 'row',
        })}
      >
        {showError && <ExclamationTriangleIcon className="h-6 w-6 text-red-900 flex-shrink-0" />}
        {title && (
          <span className="overflow-x-hidden text-ellipsis text-slate-600" title={title}>
            {searchValue ? (
              <TextHighlighter
                text={title}
                highlighted={searchValue
                  .split(',')
                  .filter((x) => x !== '')
                  .map((x) => x.trim())}
              />
            ) : (
              title
            )}
          </span>
        )}
        {children}
      </div>
    </div>
  );
};
