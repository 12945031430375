import { setVariantId, useGetCurrencySymbol } from '@client/project/store';
import { CalculationModelMetadata } from '@client/shared/api';
import { useGenericDispatch } from '@client/shared/store';
import { useValidateCatalogHasRestrictions } from '@client/shared/permissions'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VariantCreateCopyModal } from './VariantCreateCopyModal';
import { VariantCreateSnapshotModal } from './VariantCreateSnapshotModal';
import { VariantDeactivateModal } from './VariantDeactivateModal';
import { VariantDeleteModal } from './VariantDeleteModal';
import { VariantUpdateTitleModal } from './VariantUpdateTitleModal';
import {
  TextInput,
  Modal,
  NumberInput,
  TransactionIcon,
  TimesheetIcon,
  BasementIcon,
  FloorPlanIcon,
  TagWindowIcon,
  VariantCostIcon,
  VariantRevenueIcon,
  VariantProfitIcon,
  VariantBgfIcon,
  TrashIcon, DownloadingUpdatesIcon
} from '@client/shared/toolkit';
import {
  ArchiveBoxIcon,
  CloudArrowUpIcon,
  Square2StackIcon,
  ArrowPathIcon,
  CalendarIcon,
  ArrowRightIcon,
  HandRaisedIcon,
} from '@heroicons/react/24/outline';
import { formatPercentage, formatUnit, humanizeJsonDate } from '@client/shared/utilities';
import { format } from 'date-fns';
import { FormattedCurrency } from '@client/project/shared';

interface CalculationModelInfoProps {
  model?: CalculationModelMetadata;
  title?: string;
  description?: string;
  irr: number;
  onTitleEdited: (newTitle: string) => void;
  onDescriptionEdited: (newDescription: string) => void;
  onIrrEdited: (newIrr: number) => void;
  onModalOpenChange: (modalOpen: boolean) => void;
  onClose: (success: boolean) => void;
}

type OpenModal =
  | 'None'
  | 'ArchiveVariant'
  | 'Delete'
  | 'CreateCopy'
  | 'CreateSnapshot'
  | 'UpdateTitle'
  | 'SetAsCurrentVersion';

export const CalculationModelInfo = ({
  model,
  title = '',
  description = '',
  irr,
  onTitleEdited,
  onDescriptionEdited,
  onIrrEdited,
  onModalOpenChange,
  onClose,
}: CalculationModelInfoProps) => {
  const { t } = useTranslation();
  const currentCurrencySymbol = useGetCurrencySymbol();

  const dispatch = useGenericDispatch();

  const [openModal, setOpenModal] = useState<OpenModal>('None');

  const costCatalogHasRestrictions = useValidateCatalogHasRestrictions(model?.projectId ?? '', model?.costCatalogId);
  const riskCatalogHasRestrictions = useValidateCatalogHasRestrictions(model?.projectId ?? '', model?.riskCatalogId);
  const earningsCatalogHasRestrictions = useValidateCatalogHasRestrictions(
    model?.projectId ?? '',
    model?.earningsCatalogId
  );

  // make sure to reset the nested modal state on close
  // and update the cached variant on open
  useEffect(() => {
    if (model == null) {
      setOpenModal('None');
    }
  }, [model]);

  const handleCloseModal = (success?: boolean) => {
    setOpenModal('None');
    onModalOpenChange(false);

    if (success === true) {
      if (openModal === 'Delete') {
        onClose(true);
      } else {
        onClose(false);
      }
    }
  };

  const handleOpenModal = (modal: OpenModal) => {
    setOpenModal(modal);
    onModalOpenChange(true);
  };

  const onDownload = () => {
    window.open(`/api/projects/${model?.projectId.toString()}/calculation-models/${model?.id.toString()}/excel`, '_new');
    handleOnClose();
  };

  const handleOnClose = () => {
    handleCloseModal();

    onClose(false);
  };

  const isCurrentVersion = model?.type === 'Version';
  const isVariant = model?.type === 'Variant';

  const isReadonly = model?.type !== 'Version' && model?.type !== 'Variant';
  const isDeletable = model?.type === 'Variant' || model?.type === 'UserSnapshot';

  let duration = 0;
  const startDate = model?.calculationModelStartDate ? new Date(model.calculationModelStartDate) : undefined;
  const endDate = model?.calculationModelEndDate ? new Date(model.calculationModelEndDate) : undefined;

  if (startDate && endDate) {
    duration = Math.round(Math.abs(endDate.getTime() - startDate.getTime()) / (30 * 24 * 60 * 60 * 1000));
  }

  const loadVariant = () => {
    dispatch(setVariantId(model?.id ?? ''));
    handleOnClose();
  };

  const totalCosts = (model?.totalCost.value ?? 0) + (model?.totalRisks.value ?? 0);
  const totalRevenue = model?.totalEarnings.value ?? 0;
  const totalProfit = totalRevenue - totalCosts;
  const roi = model?.roi.value;

  return (
    <>
      {model && (
        <>
          <Modal isOpen={openModal === 'CreateCopy'} onClose={handleCloseModal}>
            <VariantCreateCopyModal variant={model} onClose={handleCloseModal} />
          </Modal>
          <Modal isOpen={openModal === 'CreateSnapshot'} onClose={handleCloseModal}>
            <VariantCreateSnapshotModal variant={model} onClose={handleCloseModal} />
          </Modal>
          <Modal isOpen={openModal === 'ArchiveVariant'} onClose={handleCloseModal}>
            <VariantDeactivateModal variant={model} onClose={handleCloseModal} />
          </Modal>
          <Modal isOpen={openModal === 'Delete'} onClose={handleCloseModal}>
            <VariantDeleteModal variant={model} onClose={handleCloseModal} />
          </Modal>
          <Modal isOpen={openModal === 'UpdateTitle'} onClose={handleCloseModal}>
            <VariantUpdateTitleModal variant={model} onClose={handleCloseModal} />
          </Modal>
        </>
      )}

      <div className="flex flex-col m-6 space-y-6">
        <div className="">
          <TextInput
            label={t('projectVariants.labelName')}
            value={title}
            icon={<TagWindowIcon className="h-6 w-6" />}
            onChange={(value) => onTitleEdited(value)}
          />
          <TextInput
            label={t('projectVariants.labelDescription')}
            value={description}
            icon={<TagWindowIcon className="h-6 w-6" />}
            onChange={(value) => onDescriptionEdited(value)}
            inputType="textarea"
          />
        </div>
        <div className="divide-gray-100 divide-y">
          <div className="flex w-full p-3 bg-white items-center">
            <div className="pr-3 flex-shrink-0">
              <VariantCostIcon className="w-8 h-8" />
            </div>
            <div className="text-lg font-bold truncate text-rose-700 flex-grow">{t('projectVariants.labelCost')}</div>
            {costCatalogHasRestrictions || riskCatalogHasRestrictions ? (
              <div className="flex items-end text-rose-700">
                <HandRaisedIcon className="w-8 h-8 " />
              </div>
            ) : (
              <div className="flex flex-col flex-shrink-0 text-right leading-none">
                <div className="text-lg font-bold"><FormattedCurrency amount={totalCosts} /></div>
                <div className="text-[9px] text-gray-500">
                  <span>{t('projectVariants.labelMgf')}</span>{' '}
                  <span className="font-bold">
                    {formatUnit(totalCosts / (model?.rentalSpaceValue.value ?? 0), `${currentCurrencySymbol}/${model?.rentalSpaceValue.unit ?? ''}`)}
                  </span>
                  &bull; <span>{t('projectVariants.labelBgf')}</span>{' '}
                  <span className="font-bold">
                    {formatUnit(totalCosts / (model?.grossFloorValue.value ?? 0), `${currentCurrencySymbol}/${model?.grossFloorValue.unit ?? ''}`)}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="flex w-full p-3 bg-white items-center">
            <div className=" pr-3 flex-shrink-0">
              <VariantRevenueIcon className="w-8 h-8" />
            </div>
            <div className="text-lg font-bold truncate text-green-900 flex-grow">
              {t('projectVariants.labelRevenue')}
            </div>
            {earningsCatalogHasRestrictions ? (
              <div className="flex items-end text-rose-700">
                <HandRaisedIcon className="w-8 h-8 " />
              </div>
            ) : (
              <div className="flex flex-col flex-shrink-0 text-right leading-none">
                <div className="text-lg font-bold"><FormattedCurrency amount={totalRevenue} /></div>
                <div className="text-[9px] text-gray-500">
                  <span>{t('projectVariants.labelMgf')}</span>{' '}
                  <span className="font-bold">
                    {formatUnit(
                      totalRevenue / (model?.rentalSpaceValue.value ?? 0),
                      `${currentCurrencySymbol}/${model?.rentalSpaceValue.unit ?? ''}`)}
                  </span>
                  &bull; <span>{t('projectVariants.labelBgf')}</span>{' '}
                  <span className="font-bold">
                    {formatUnit(totalRevenue / (model?.grossFloorValue.value ?? 0), `${currentCurrencySymbol}/${model?.grossFloorValue.unit ?? ''}`)}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="flex p-3 bg-white items-center">
            <div className=" pr-3 flex-shrink-0">
              <VariantProfitIcon className="w-8 h-8" />
            </div>
            <div className="text-lg font-bold truncate text-emerald-600 flex-grow">
              {t('projectVariants.labelProfit')}
            </div>
            {earningsCatalogHasRestrictions || riskCatalogHasRestrictions || costCatalogHasRestrictions ? (
              <div className="flex items-end text-rose-700">
                <HandRaisedIcon className="w-8 h-8 " />
              </div>
            ) : (
              <div className="flex flex-col flex-shrink-0 text-right leading-none">
                <div className="text-lg font-bold"><FormattedCurrency amount={totalProfit} /></div>
                <div className="text-[9px] text-gray-500">
                  <span>{t('projectVariants.labelMgf')}</span>{' '}
                  <span className="font-bold">
                    {formatUnit(
                      totalProfit / (model?.rentalSpaceValue.value ?? 0),
                      `${currentCurrencySymbol}/${model?.rentalSpaceValue.unit ?? ''}`)}
                  </span>
                  &bull; <span>{t('projectVariants.labelBgf')}</span>{' '}
                  <span className="font-bold">
                    {formatUnit(totalProfit / (model?.grossFloorValue.value ?? 0), `${currentCurrencySymbol}/${model?.grossFloorValue.unit ?? ''}`)}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="divide-gray-100 divide-y">
          <div className="flex p-3 bg-white items-center">
            <div className=" pr-3 flex-shrink-0">
              <div className="w-8 flex justify-center">
                <VariantBgfIcon className="w-5 h-5" />
              </div>
            </div>
            <div className="text-lg font-bold truncate text-gray-600 flex-grow">{t('projectVariants.labelBgf')}</div>
            <div className="flex flex-col flex-shrink-0 text-right leading-none">
              <div className="text-lg font-bold">
                {formatUnit(model?.grossFloorValue.value, model?.grossFloorValue.unit ?? '')}
              </div>
              <div className="text-[9px] text-gray-500">
                <span>{t('projectVariants.labelRemainder')}</span>{' '}
                <span className="font-bold">
                  {formatUnit(model?.grossFloorRemainder.value, model?.grossFloorRemainder.unit ?? '')}
                </span>
                &bull; <span>{t('projectVariants.labelGfz')}</span>{' '}
                <span className="font-bold">{model?.gfz ?? ''}</span>
              </div>
            </div>
          </div>
          <div className="flex p-3 bg-white items-center">
            <div className=" pr-3 flex-shrink-0">
              <div className="w-8 flex justify-center">
                <BasementIcon className="w-6 h-6 text-gray-600" />
              </div>{' '}
            </div>
            <div className="text-lg font-bold truncate text-gray-600 flex-grow">{t('projectVariants.labelNgf')}</div>
            <div className="flex flex-col flex-shrink-0 text-right leading-none">
              <div className="text-lg font-bold">
                {formatUnit(model?.netFloorValue.value, model?.netFloorValue.unit ?? '')}
              </div>
              <div className="text-[9px] text-gray-500">
                <span>{t('projectVariants.labelRemainder')}</span>{' '}
                <span className="font-bold">
                  {formatUnit(model?.netFloorRemainder.value, model?.netFloorRemainder.unit ?? '')}
                </span>
                &bull; <span>{t('projectVariants.labelEfficiency')}</span>{' '}
                <span className="font-bold">
                  {formatUnit(model?.netFloorEfficiency.value, model?.netFloorEfficiency.unit ?? '')}
                </span>
              </div>
            </div>
          </div>
          <div className="flex p-3 bg-white items-center">
            <div className=" pr-3 flex-shrink-0">
              <div className="w-8 flex justify-center">
                <FloorPlanIcon className="w-6 h-6 text-gray-600" />
              </div>{' '}
            </div>
            <div className="text-lg font-bold truncate text-gray-600 flex-grow">{t('projectVariants.labelMgf')}</div>
            <div className="flex flex-col flex-shrink-0 text-right leading-none">
              <div className="text-lg font-bold">
                {formatUnit(model?.rentalSpaceValue.value, model?.rentalSpaceValue.unit ?? '')}
              </div>
              <div className="text-[9px] text-gray-500">
                <span>{t('projectVariants.labelRemainder')}</span>{' '}
                <span className="font-bold">
                  {formatUnit(model?.rentalSpaceRemainder.value, model?.rentalSpaceRemainder.unit ?? '')}
                </span>
                &bull; <span>{t('projectVariants.labelEfficiency')}</span>{' '}
                <span className="font-bold">
                  {formatUnit(model?.rentalSpaceEfficiency.value, model?.rentalSpaceEfficiency.unit ?? '')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="rounded-md w-full h-24 flex space-x-4 items-center px-6 py-4 bg-white">
          <div>
            <TransactionIcon className="text-sky-500 w-6" />
          </div>
          <div className="flex flex-col">
            <div className="text-[13px] font-bold text-neutral-500">{t('projectVariants.labelRoi')}</div>
            <div className="flex space-x-2 leading-none">
              <div className="text-3xl font-bold text-gray-700">{formatPercentage(roi)}</div>
            </div>

            <div className="flex justify-between text-[13px] font-bold text-neutral-500">&nbsp;</div>
          </div>
        </div>
        <div className="rounded-md w-full h-24 flex space-x-4 items-center px-6 py-4 bg-white">
          <div>
            <TransactionIcon className="text-sky-500 w-6" />
          </div>
          <div className="flex flex-col">
            <NumberInput label={t('project.irr')} value={irr ?? ''} onChange={(value) => onIrrEdited(value ?? 0)} />

            <div className="flex justify-between text-[13px] font-bold text-neutral-500">&nbsp;</div>
          </div>
        </div>
        <div className="rounded-md w-full h-24 flex space-x-4 items-center px-6 py-4 bg-white">
          <div>
            <TimesheetIcon className="text-sky-500 w-6" />
          </div>
          <div className="flex flex-col">
            <div className="text-[13px] font-bold text-neutral-500">{t('project.runtime')}</div>
            <div className="flex space-x-2 leading-none">
              <div className="text-3xl font-bold text-gray-700">{duration}</div>
              <span className="text-base font-normal flex-shrink min-h-0">{t('common.month').substring(0, 3)}</span>
            </div>

            <div className="flex justify-between text-[13px] font-bold text-neutral-500">
              <div>
                {model?.deliveryPhasesCount ?? 0} {t('project.phases', { count: model?.deliveryPhasesCount ?? 0 })}
              </div>
            </div>
          </div>
          <div className="flex-grow" />
          <div>
            <div className="text-[13px] font-bold text-neutral-500">{t('project.start')}</div>
            <div className="text-[21px] font-bold text-gray-700">
              {startDate ? format(startDate, 'MMMM').substring(0, 3) : '-'}
            </div>
            <div className="flex justify-between text-[13px] font-bold text-neutral-500">
              <div className="m-auto">{startDate?.getFullYear()}</div>
            </div>
          </div>
          <div className="border-r h-6 border-gray-300" />
          <div>
            <div className="text-[13px] font-bold text-neutral-500">{t('project.end')}</div>
            <div className="text-[21px] font-bold text-gray-700">
              {endDate ? format(endDate, 'MMMM').substring(0, 3) : '-'}
            </div>
            <div className="flex justify-between text-[13px] font-bold text-neutral-500">
              <div className="m-auto">{endDate?.getFullYear()}</div>
            </div>
          </div>
        </div>
        <div className="divide-gray-100 divide-y mt-8">
          <TextInput
            label={t('projectVariants.labelEdited')}
            value={t('projectVariants.timeAgoByUser', {
              time: humanizeJsonDate(model?.lastEdit.changedAt),
              user: model?.lastEdit.changedBy,
            })}
            disabled={true}
            icon={<ArrowPathIcon className="h-6 w-6" />}
          />
          <TextInput
            label={t('projectVariants.labelCreated')}
            value={t('projectVariants.timeAgoByUser', {
              time: humanizeJsonDate(model?.created.changedAt),
              user: model?.created.changedBy,
            })}
            disabled={true}
            icon={<CalendarIcon className="h-6 w-6" />}
          />
        </div>
        <div>
          <div className="flex flex-col text-sm text-blue-900 space-y-4">
            {(isCurrentVersion || isVariant) && (
              <button className="inline-flex items-center cursor-pointer font-semibold" onClick={() => loadVariant()}>
                <ArrowRightIcon className="h-8 w-8 mr-2" />
                {t('projectVariants.actionLoad')}
              </button>
            )}

            {!isCurrentVersion && !isVariant && (
              <button className="inline-flex items-center cursor-pointer font-semibold" onClick={() => loadVariant()}>
                <ArrowRightIcon className="h-8 w-8 mr-2" />
                {t('projectVariants.actionLoadReadOnly')}
              </button>
            )}

            <button className="inline-flex items-center cursor-pointer font-semibold" onClick={onDownload}>
              <DownloadingUpdatesIcon className="h-8 w-8 mr-2" />
              {t('projectVariants.actionExportToExcel')}
            </button>

            {/* Note: this is still supported by the backend but won't be used for now -> ChangeCurrentVersionCommand
                  isVariant && (
                  <button
                    className="inline-flex items-center cursor-pointer font-semibold"
                    onClick={() => setNestedModal('SetAsCurrentVersion')}
                  >
                    <ArrowUpTrayIcon className="h-8 w-8 mr-2" />
                    {t('projectVariants.actionActivate')}
                  </button>
                )*/}

            {!isReadonly && (
              <button
                className="inline-flex items-center cursor-pointer font-semibold"
                onClick={() => handleOpenModal('CreateCopy')}
              >
                <Square2StackIcon className="h-8 w-8 mr-2" />
                {t('projectVariants.actionCreateCopy')}
              </button>
            )}

            {(isCurrentVersion || isVariant) && (
              <button
                className="inline-flex items-center cursor-pointer font-semibold"
                onClick={() => handleOpenModal('CreateSnapshot')}
              >
                <CloudArrowUpIcon className="h-8 w-8 mr-2" />
                {t('projectVariants.actionCreateSnapshot')}
              </button>
            )}

            {isVariant && (
              <button
                className="inline-flex items-center cursor-pointer font-semibold"
                onClick={() => handleOpenModal('ArchiveVariant')}
              >
                <ArchiveBoxIcon className="h-8 w-8 mr-2" />
                {t('projectVariants.actionDeactivate')}
              </button>
            )}

            {isDeletable && (
              <button
                className="inline-flex items-center cursor-pointer font-semibold"
                onClick={() => handleOpenModal('Delete')}
              >
                <TrashIcon className="h-8 w-8 mr-2" />
                {t('common.delete')}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
