import {
  DecoratedCard,
  ParkingDisabledIcon,
  ParkingWallboxIcon,
  ParkingXxlIcon,
  PencilIcon
} from '@client/shared/toolkit';
import { TaxonomyReadModel } from '@client/shared/api';
import { useTranslation } from 'react-i18next';

interface ParkingFeaturesCardProps {
  item?: TaxonomyReadModel | undefined;
  setSelectedField: (value: string) => void;
  setIsBuildingItemSlideOverOpen: (value: boolean) => void;
}

export const ParkingFeaturesCard = ({
  item,
  setSelectedField,
  setIsBuildingItemSlideOverOpen,
}: ParkingFeaturesCardProps) => {
  const { t } = useTranslation();

  const features = {
    Xxl: { name: 'XXL', icon: <ParkingXxlIcon className="w-5 h-5" /> },
    Disabled: {
      name: 'Disabled',
      icon: <ParkingDisabledIcon className="w-5 h-5" />
    },
    Wallbox11kW: { name: '11kW', icon: <ParkingWallboxIcon className="w-5 h-5" /> },
    Wallbox22kW: { name: '22kW', icon: <ParkingWallboxIcon className="w-5 h-5" /> },
  };

  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        setSelectedField('parkingFeatures');
        setIsBuildingItemSlideOverOpen(true);
      }}
    >
      <DecoratedCard>
        <DecoratedCard.Header
          showActionButton={true}
          icon={<PencilIcon />}
          onActionClick={() => {
            setSelectedField('parkingFeatures');
            setIsBuildingItemSlideOverOpen(true);
          }}
        >
          <div className="truncate">{t('projectTaxonomy.parkingLotFeatures')}</div>
        </DecoratedCard.Header>
        <DecoratedCard.Content className="p-5 w-full h-full bg-white">
          <div className="w-fit grid grid-cols-4 gap-2">
            {item?.parkingLot?.features.map((feature) => (
              <div
                key={features[feature].name}
                className="w-12 h-12 flex flex-col items-center justify-center border-gray-200 border rounded-lg gap-1"
              >
                {features[feature].icon}
                <div className="text-xxs text-gray-500">{features[feature].name}</div>
              </div>
            ))}
          </div>
        </DecoratedCard.Content>
      </DecoratedCard>
    </div>
  );
};
