import { BaseSelectOption } from '@client/shared/toolkit';

export const PORTFOLIO_ID = '39b91252-2c9c-46cf-b567-627527bb6604';

export type OrgaUnitUserType = {
  id: string;
  name: string;
};

export type OrgaUnitType = {
  name: string;
  users: OrgaUnitUserType[];
};

export type ClusterUserType = {
  id: string;
  name: string;
  teams: OrgaUnitType[];
  assetManagers: string[];
  portfolioId: string;
};

const TENANT_OWNER = {
  id: '65fdd76c-7881-4c5b-a6d1-c5f3345f3c29',
  name: 'Armin',
}; // cb@prob.is, Armin

/**
 * Each asset manager that exists
 */
const ASSET_MANAGER_1 = '2f7b02a5-1ca9-4bf6-844a-aef0549ad9d3'; // 'portfolioam1@prob.is', Asset Manager
const ASSET_MANAGER_2 = '74267e4c-c4dd-4f6d-966e-8e3210428fed'; // 'portfolioam2@prob.is', Manager Asset

/**
 * All asset managers in one array
 */
export const ASSET_MANAGERS: string[] = [ASSET_MANAGER_1, ASSET_MANAGER_2];

/**
 * Each reporter that exists
 */
export const REPORTER_1: OrgaUnitUserType = {
  id: 'a144651d-b883-4b4b-9338-32b9613753b2',
  name: 'Bedarfsmelder 1',
}; // portfolioorgaunit11@prob.is, Max Mustermann

export const REPORTER_2: OrgaUnitUserType = {
  id: '1eecd7a5-8386-4001-b317-aa474b81af40',
  name: 'Bedarfsmelder 2',
}; // portfolioorgaunit12@prob.is, Erika Mustermann

export const REPORTER_3: OrgaUnitUserType = {
  id: '79b805d0-3f98-4b57-8e4b-c2cab55109ac',
  name: 'Bedarfsmelder 3',
}; // portfolioorgaunit21@prob.is, Matthias Mustermann

export const REPORTER_4: OrgaUnitUserType = {
  id: 'dad588ca-e8c1-46c3-b773-ab25d436b119',
  name: 'Bedarfsmelder 4',
}; // portfolioorgaunit22@prob.is, Klaus Mustermann

export const REPORTER_5: OrgaUnitUserType = {
  id: '6b886efd-7d10-4e28-a59f-e00d0f5bf960',
  name: 'Bedarfsmelder 5',
}; // portfolioorgaunit31@prob.is, Tina Mustermann

export const REPORTER_6: OrgaUnitUserType = {
  id: '9c6d921e-c019-41d4-b132-00d14e0bb25d',
  name: 'Bedarfsmelder 6',
}; // portfolioorgaunit41@prob.is, Martina Mustermann

/**
 * All reporters in one array
 */
export const REQUIREMENT_REPORTERS: OrgaUnitUserType[] = [
  REPORTER_1,
  REPORTER_2,
  REPORTER_3,
  REPORTER_4,
  REPORTER_5,
  REPORTER_6,
];

/**
 * Each organization unit that exists
 */
export const ORGA_UNIT_1: OrgaUnitType = {
  name: 'Organisations-Einheit 1',
  users: [REPORTER_1, REPORTER_2],
};
export const ORGA_UNIT_2: OrgaUnitType = {
  name: 'Organisations-Einheit 2',
  users: [REPORTER_3, REPORTER_4],
};
export const ORGA_UNIT_3: OrgaUnitType = {
  name: 'Organisations-Einheit 3',
  users: [REPORTER_5, REPORTER_4],
};
export const ORGA_UNIT_4: OrgaUnitType = {
  name: 'Organisations-Einheit 4',
  users: [REPORTER_6],
};

/**
 * All organization units in one array
 */
export const ORGA_UNITS: OrgaUnitType[] = [ORGA_UNIT_1, ORGA_UNIT_2, ORGA_UNIT_3, ORGA_UNIT_4];

/**
 * The Cluster items with the reporter teams and asset managers that belong to the cluster item.
 */
export const CLUSTER_USERS: ClusterUserType[] = [
  {
    id: 'f2d32094-f06e-4bf3-b58c-b26880a543a0',
    name: 'Terminal 1 (A/A-Plus)',
    teams: [ORGA_UNIT_1, ORGA_UNIT_2, ORGA_UNIT_3],
    assetManagers: [ASSET_MANAGER_1],
    portfolioId: PORTFOLIO_ID,
  },
  {
    id: '5a6da31e-adc3-40c9-8cc8-99d6f5cb10f1',
    name: 'Terminal 1 (B, C, Hallen)',
    teams: [ORGA_UNIT_1, ORGA_UNIT_3],
    assetManagers: [ASSET_MANAGER_1],
    portfolioId: PORTFOLIO_ID,
  },
  {
    id: '9637fecd-6fe5-4997-88f4-74db885f19fb',
    name: 'Terminal 2',
    teams: [ORGA_UNIT_3, ORGA_UNIT_4],
    assetManagers: [ASSET_MANAGER_1],
    portfolioId: PORTFOLIO_ID,
  },
  {
    id: '018cbb83-fcfe-4d53-a90f-91c29976aea2',
    name: 'Terminal 3',
    teams: [ORGA_UNIT_1],
    assetManagers: [ASSET_MANAGER_1],
    portfolioId: PORTFOLIO_ID,
  },
  {
    id: '7094e5a6-70f1-41a0-a04f-9d7b4d29dadd',
    name: 'Core Gebäude S&V',
    teams: [],
    assetManagers: [ASSET_MANAGER_1],
    portfolioId: PORTFOLIO_ID,
  },
  {
    id: 'fa60de4d-d439-4fd1-9b26-684b2ce74068',
    name: 'Non-Core Gebäude S&V',
    teams: [],
    assetManagers: [ASSET_MANAGER_1],
    portfolioId: PORTFOLIO_ID,
  },
  {
    id: '62b6c0b4-6811-4ca2-9f32-eaad5e13f82e',
    name: 'Energieversorgung / Strom',
    teams: [],
    assetManagers: [ASSET_MANAGER_1],
    portfolioId: PORTFOLIO_ID,
  },
  {
    id: '276cf01e-3326-414b-a8de-a7a09f8de838',
    name: 'Entsorgungsnetze',
    teams: [],
    assetManagers: [ASSET_MANAGER_1],
    portfolioId: PORTFOLIO_ID,
  },
  {
    id: '01fad5ba-9bbc-4ec5-bda8-004fb865416c',
    name: 'Sonstiges',
    teams: [],
    assetManagers: [ASSET_MANAGER_1],
    portfolioId: PORTFOLIO_ID,
  },
  {
    id: 'f901c5f9-476c-4546-8d00-e138f3b736f2',
    name: 'Verkehrsanlagen',
    teams: [],
    assetManagers: [ASSET_MANAGER_1],
    portfolioId: PORTFOLIO_ID,
  },
  {
    id: 'ac1a2d00-d5c4-4ee4-82fc-297cfa56428d',
    name: 'Versorgungsnetze',
    teams: [],
    assetManagers: [ASSET_MANAGER_1],
    portfolioId: PORTFOLIO_ID,
  },
  {
    id: '4aab303c-ec23-4db4-8105-31ef4046ca00',
    name: 'Flugbetriebsflächen und Airfieldtechnik',
    teams: [],
    assetManagers: [ASSET_MANAGER_1],
    portfolioId: PORTFOLIO_ID,
  },
  {
    id: 'e776ca9b-0134-4ce0-8f27-ff1acba2356a',
    name: 'Zentrale Infrastruktur',
    teams: [],
    assetManagers: [ASSET_MANAGER_1],
    portfolioId: PORTFOLIO_ID,
  },
  {
    id: '14b93c77-3322-4520-8ea7-93f04fefa88c',
    name: 'Sonstiges',
    teams: [ORGA_UNIT_1],
    assetManagers: [ASSET_MANAGER_2],
    portfolioId: PORTFOLIO_ID,
  },
];

/**
 *  All editors, without teams
 */
export const EDITOR_OPTIONS: BaseSelectOption[] = [
  {
    value: '9e5dd097-96e2-40f0-81fd-0da063f0c0e4',
    label: 'Bedarfsmanager 1'
  },
  {
    value: '1b2d38bb-5ca4-4d47-aeb0-7c44b72bf86b',
    label: 'Bedarfsmanager 2'
  }
];

/**
 *  All editors, with teams
 */
export const EDITOR_DEPUTY_OPTIONS: BaseSelectOption[] = [
  {
    value: '9e5dd097-96e2-40f0-81fd-0da063f0c0e4',
    label: 'Bedarfsmanager 1'
  },
  {
    value: '1b2d38bb-5ca4-4d47-aeb0-7c44b72bf86b',
    label: 'Bedarfsmanager 2'
  },
  {
    value: '71f746a0-97e7-4b38-adb9-384221bf31ed',
    label: 'Team 1'
  },
  {
    value: 'e390d104-870a-438e-91fe-1bece22d6bb3',
    label: 'Team 2'
  },
];

/**
 * Returns the cluster elements to which a user is allowed to add requests.
 * @param userId The user
 */
export const getClusterItemIdsForUser = (userId: string) => {
  return CLUSTER_USERS.filter((clusterUsers) => {
    // check if asset manager
    if (clusterUsers.assetManagers.includes(userId) || userId === TENANT_OWNER.id) {
      return true;
    }
    // check if requirement reporter
    const teams = clusterUsers.teams.reduce((users, teamUsers) => {
      const userIds = teamUsers.users.reduce((ids, user) => [...ids, user.id], [] as string[]);
      return [...users, ...userIds];
    }, [] as string[]);

    const reporters = [...new Set([...teams])];
    return reporters.includes(userId);
  }).reduce((clusterUsers, clusterUser) => [...clusterUsers, clusterUser.id], [] as string[]);
};

/**
 * Gets the organization unit names for a given user.
 * If a clusterItemId is set, only the names of the teams are returned that belong to that cluster item.
 * If additionally a reporterId is provided, the names of only those teams are returned, where the reporter is also part of.
 *
 * @param userId The user for which the names of the teams shall be found
 * @param clusterItemId The cluster item to which the teams should belong to
 * @param reporterId The reporter must be part of the teams
 */
export const getOrgaUnitNamesForUser = (userId: string, clusterItemId?: string, reporterId?: string) => {
  return ORGA_UNITS.filter((unit) => {
    const userIds = unit.users.reduce((ids, user) => [...ids, user.id], [] as string[]);
    // the teams must include the reporter
    if (reporterId) {
      return userIds.includes(userId) && userIds.includes(reporterId);
    }
    return userIds.includes(userId);
  }).reduce((names, unit) => {
    if (clusterItemId) {
      const clusterUserConfig = CLUSTER_USERS.find((item) => item.id === clusterItemId);
      // the team must belong to the cluster item
      if (clusterUserConfig && clusterUserConfig.teams.find((team) => team.name === unit.name)) {
        return [...names, unit.name];
      } else {
        return [...names];
      }
    }
    return [...names, unit.name];
  }, [] as string[]);
};

/**
 * Finds all the teams including users that are allowed to see the cluster item and are in the same team as the reporter.
 * If no userId is passed, then all allowed teams and cluster items for the requirement are returned.
 *
 * @param clusterItemId The cluster item
 * @param userId The user which must be in the team(s)
 */
export const getClusterItemUsers = (clusterItemId: string, userId?: string) => {
  const clusterUserConfig = CLUSTER_USERS.find((item) => item.id === clusterItemId);
  if (clusterUserConfig) {
    if (userId) {
      return clusterUserConfig.teams.filter((team) => team.users.find((user) => user.id === userId));
    }
    return clusterUserConfig.teams;
  }
  return [];
};

/**
 * Looks up the name of a given user id
 *
 * @param id The user id
 */
export const getReporterNameById = (id: string) => {
  return REQUIREMENT_REPORTERS.find((user) => user.id === id)?.name ?? '';
};

/**
 * A reporter is either the deputy or the reporter him/herself
 *
 * @param reporterId The reporter of the requirement
 * @param reporterDeputyId The deputy reporter of the requirement
 * @param userId The user id we want to check if he / she is the reporter
 */
export const checkIsReporter = (reporterId: string, reporterDeputyId?: string | null, userId?: string | null) => {
  if (userId) {
    return reporterId === userId || reporterDeputyId === userId || userId === TENANT_OWNER.id;
  }
  return false;
}

/**
 * Check if the user is an asset manager
 *
 * @param userId The user to check if he / she is an asset manager
 */
export const isAssetManager = (userId?: string) => {
  return userId ? ASSET_MANAGERS.includes(userId) || userId === TENANT_OWNER.id : false;
}

/**
 * Checks if a reporter is in the cluster item
 * @param reportedById The id of the reporter to check
 * @param clusterItem The cluster item id
 */
export const isReporterInCluster = (reportedById: string, clusterItem?: string | null) => {
  if (clusterItem) {
    const clusterItemsForUser = getClusterItemIdsForUser(reportedById);
    return clusterItemsForUser.includes(clusterItem);
  }
  return false;
}
