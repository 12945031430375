import { apiBase, ApiTagTypes, ShortProjectReadModel, useApiDeleteProjectMutation } from '@client/shared/api';
import { HintBox, ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface ProjectDeleteModalProps extends ModalOnCloseProps {
  project: ShortProjectReadModel;
}

export const ProjectDeleteModal = ({ project, onClose }: ProjectDeleteModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [call, { isLoading }] = useApiDeleteProjectMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    try {
      await safeMutation(
        call,
        {
          projectId: project.id,
        },
        isLoading
      );
      if (project.groupId) {
        // @ts-expect-error need to invalidate the project group also
        dispatch(apiBase.util.invalidateTags([{ type: ApiTagTypes.Project, id: project.groupId }]));
      }
      onClose(true);
    } catch {
      /* left blank */
    }
  };

  return (
    <ConfirmModal
      title={t('app.deleteTitle', { name: project.name })}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isLoading}
    >
      {isLoading && <LoadingIndicator text={t('project.deletingProjectLoadingIndicator')} mode="overlay" />}
      <HintBox hintType="warning">{t('app.deleteHint')}</HintBox>
    </ConfirmModal>
  );
};
