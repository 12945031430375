import { ElementTimelineReadModel } from '@client/shared/api';
import { useCallback } from 'react';
import { TimelineManualDistributionRow } from './TimelineManualDistributionRow';
import { ExtendedDistributionValueReadModel } from '.';

interface TimeLineMoneyDistributionBodyProps {
  totalValue?: number;
  distributionValues: ExtendedDistributionValueReadModel[];
  timeline: ElementTimelineReadModel;
  setDistributionValues: (values: ExtendedDistributionValueReadModel[]) => void;
  selectedDate?: string;
  statusMonth?: string;
  cashFlowOverride: boolean;
}

export const TimeLineMoneyDistributionBody = (props: TimeLineMoneyDistributionBodyProps) => {
  const {
    totalValue,
    distributionValues,
    timeline,
    setDistributionValues,
    selectedDate,
    statusMonth,
    cashFlowOverride,
  } = props;

  const updateAtIndex = useCallback(
    (updated: Partial<ExtendedDistributionValueReadModel>, itemIndex: number): ExtendedDistributionValueReadModel[] => {
      return distributionValues.map((item, index) => {
        if (index === itemIndex) {
          return {
            ...item,
            ...updated,
          };
        }
        return item;
      });
    },
    [distributionValues],
  );

  const getAmountFromPercentage = useCallback(
    (percentage: number) => {
      if (!totalValue) {
        return 0;
      }
      let value = (totalValue * percentage) / 100;
      value = Math.round((value + Number.EPSILON) * 100) / 100;
      return value;
    },
    [totalValue],
  );

  const getPercentageFromAmount = useCallback(
    (amount: number) => {
      if (!totalValue) {
        return 0;
      }
      return (amount / totalValue) * 100;
    },
    [totalValue],
  );

  return (
    <div className="divide-y">
      {distributionValues
        .sort((a, b) => {
          if (a.date && b.date && a.date === b.date) {
            return a.description.toLowerCase() === 'vat' ? -1 : 1;
          } else {
            return 0;
          }
        })
        .map((item, index) => {
            const statusDate: Date = statusMonth ? new Date(statusMonth) : new Date();
          return (
            <TimelineManualDistributionRow
              active={Boolean(
                selectedDate &&
                  item.date &&
                  new Date(selectedDate).getMonth() === new Date(item.date).getMonth() &&
                  new Date(selectedDate).getFullYear() === new Date(item.date).getFullYear(),
              )}
              key={`${index}-${item.date}-${item.id}`}
              item={item}
              minDate={
                cashFlowOverride && statusMonth
                  ? new Date(statusDate.setDate(statusDate.getDate() - 1))
                  : timeline.effectiveStartDate
                    ? new Date(timeline.effectiveStartDate)
                    : undefined
              }
              maxDate={timeline.effectiveEndDate ? new Date(timeline.effectiveEndDate) : undefined}
              onDateChange={(date) => {
                if (date !== item.date) {
                  const values = updateAtIndex({ date }, index);
                  setDistributionValues(values);
                }
              }}
              onDescriptionChange={(description) => {
                if (description !== item.description) {
                  const values = updateAtIndex({ description }, index);
                  setDistributionValues(values);
                }
              }}
              onPercentChange={(percentage) => {
                if (
                  (percentage !== item.percentage || getAmountFromPercentage(percentage) !== item.value) &&
                  !isNaN(percentage)
                ) {
                  const values = updateAtIndex(
                    {
                      percentage: percentage,
                      value: getAmountFromPercentage(percentage),
                    },
                    index,
                  );
                  setDistributionValues(values);
                }
              }}
              onAmountChange={(amount) => {
                if (amount !== item.value) {
                  const values = updateAtIndex(
                    {
                      percentage: getPercentageFromAmount(amount),
                      value: amount,
                    },
                    index,
                  );
                  setDistributionValues(values);
                }
              }}
              onItemDeleted={() => {
                const values = [...distributionValues];
                values.splice(index, 1);
                setDistributionValues(values);
              }}
            />
          );
        })}
    </div>
  );
};
