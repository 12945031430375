import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { DatevConnectionResponse, useApiGetDatevApiAccessQuery } from '@client/shared/api';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import { DatevClientSlideOver } from './DatevClientSlideOver';

export const DatevConnectionEditView = ({ apiId }: { apiId: string; }) => {
  const { id } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isOpenSlideOver, setIsOpenSlideOver] = useState(false);
  const [isChildSlideOverIsOpen] = useState(false);
  const [datevConnection, setDatevConnection] = useState<DatevConnectionResponse | null>(null);
  const [deleting, setDeleting] = useState(false);

  const { data, isFetching, isError } = useApiGetDatevApiAccessQuery(
    {
      apiAccessId: id ?? '',
    },
    {
      skip: !id || deleting,
    },
  );

  if (!id) {
    navigate(ROUTES_CONFIG.SETTINGS_CONNECT.name);
  }

  useEffect(() => {
    if (typeof data !== 'undefined' && data !== null && data) {
      setDatevConnection(data);
      if (!datevConnection) {
        setIsOpenSlideOver(true);
      }
    }
    if (isError) {
      navigate(ROUTES_CONFIG.SETTINGS_CONNECT.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isError]);

  return (
    <>
      {!isOpenSlideOver && isFetching && (
        <LoadingIndicator text={t('common.loading')} mode="overlay-window" />
      )}
      <SlideOver
        isOpen={isOpenSlideOver}
        onClose={() => setIsOpenSlideOver(false)}
        variant="2xl"
        confirmBeforeClose={false}
        preventClickOutsideClose={isChildSlideOverIsOpen}
        onAfterLeave={() => {
          navigate(ROUTES_CONFIG.SETTINGS_CONNECT.name);
        }}
      >
        {isOpenSlideOver && isFetching && (
          <LoadingIndicator text={t('common.loading')} mode="overlay" />
        )}
        {datevConnection && id && (
          <DatevClientSlideOver
            apiId={apiId}
            apiAccessId={id}
            isOpen={isOpenSlideOver}
            setDeleting={setDeleting}
            onClose={() => setIsOpenSlideOver(false)}
          />
        )}
      </SlideOver>
    </>
  );
}
