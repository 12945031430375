import {
  CostCatalogElementReadModel,
  EarningsCatalogElementReadModel,
  FinancingCatalogElementReadModel,
  RiskCatalogElementReadModel,
  TenantCatalogReadModel,
  TenantCatalogType,
} from '@client/shared/api';
import { CatalogElementDeleteModal, CatalogElementEditSlideOver } from '.';
import { CatalogHeader, CatalogSection, CatalogSlideOverRow } from '..';
import { Modal, SlideOver } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { ReactNode, useState } from 'react';
import { CatalogElementCreateSlideOver } from './CatalogElementCreateSlideOver';

export type CatalogEditingRowsProps = {
  catalog: TenantCatalogReadModel;
  elements:
    | CostCatalogElementReadModel[]
    | FinancingCatalogElementReadModel[]
    | RiskCatalogElementReadModel[]
    | EarningsCatalogElementReadModel[];
  title: string;
  icon: ReactNode;
  bgColor: string;
  type: TenantCatalogType;
};
export const CatalogEditingRows = (props: CatalogEditingRowsProps) => {
  const { title, icon, bgColor, catalog, elements, type } = props;
  const { t } = useTranslation();
  const [selectedElement, setSelectedElement] = useState<
    | CostCatalogElementReadModel
    | FinancingCatalogElementReadModel
    | RiskCatalogElementReadModel
    | EarningsCatalogElementReadModel
    | null
  >(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [parentElement, setParentElement] = useState<
    | CostCatalogElementReadModel
    | FinancingCatalogElementReadModel
    | RiskCatalogElementReadModel
    | EarningsCatalogElementReadModel
    | null
  >(null);
  return (
    <>
      <CatalogHeader title={title} icon={icon} />
      <CatalogSection bgColor={bgColor}>
        {!elements.length && <span className="py-2 px-3">{t('app.settingsNoCatalogItems')}</span>}
        {elements.map((catalogElement, i) => (
          <div className="ml-5" key={catalogElement.id}>
            <CatalogSlideOverRow
              catalog={catalog}
              catalogElement={catalogElement}
              handleEdit={(element) => {
                setSelectedElement(element);
                setShowEdit(true);
              }}
              handleDelete={(element) => {
                setSelectedElement(element);
                setShowDelete(true);
              }}
              key={catalogElement.id}
              level={1}
              addChild={(element) => {
                setParentElement(element);
                setShowAdd(true);
              }}
              idx={i}
              canMoveUp={i > 0}
              canMoveDown={i < elements.length - 1}
            />
          </div>
        ))}
      </CatalogSection>
      <SlideOver
        isOpen={showEdit}
        onClose={() => {
          setSelectedElement(null);
          setParentElement(null);
          setShowEdit(false);
        }}
      >
        <CatalogElementEditSlideOver
          catalog={catalog}
          parentElement={parentElement}
          catalogElement={selectedElement}
          onClose={() => {
            setSelectedElement(null);
            setParentElement(null);
            setShowEdit(false);
          }}
          type={type}
        />
      </SlideOver>
      <SlideOver isOpen={showAdd}
        onClose={() =>  setShowAdd(false)}
        onAfterLeave={() => {
          setSelectedElement(null);
          setParentElement(null);
        }}
        >
          <CatalogElementCreateSlideOver
            catalogId={catalog.id}
            parentElement={parentElement}
            type={type}
            onClose={() => setShowAdd(false) }/>
      </SlideOver>
      <Modal isOpen={showDelete} onClose={() => setShowDelete(false)}>
        {selectedElement && (
          <CatalogElementDeleteModal
            catalogId={catalog.id}
            type={type}
            catalogElement={selectedElement}
            onClose={() => setShowDelete(false)}
          />
        )}
      </Modal>
    </>
  );
};
