import { DecoratedCard, LegendArrow, LoadingIndicator } from '@client/shared/toolkit';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartDataset,
  ChartOptions,
  ChartTypeRegistry,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import annotationPlugin, { LineAnnotationOptions } from 'chartjs-plugin-annotation';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getLanguageAndLocale } from '@client/shared/utilities';
import { Context } from 'chartjs-plugin-datalabels';
import { BmwWaterfallChartDataset, useApiGetBmwWaterfallReportQuery } from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';

const DUMMY_FORECAST_SUM_VALUE = 2480000; // 1010000 // 1210000

// const DIFFERENCE_COLOR = '#38bdf8'; // sky-400
const SECONDARY_COLOR = '#0A5A85'; // secondary
const PRIMARY_COLOR = '#334155'; // primary // Construction
const PROF_FEES_COLOR = '#64748b'; // slate-500 // Prof. Fees
const CONTINGENCY_COLOR = '#94a3b8'; // slate-400 // Contingency
const BORDER_COLOR = '#6b7280'; // gray-500
const LIGHT_LABEL_COLOR = '#cbd5e1'; // slate-300
const WHITE_COLOR = '#ffffff'; // white

const BARS_LENGTH = 14;

const mapResponseToChartDatasets = (responseDataSet: BmwWaterfallChartDataset | undefined) => {
  return [
    responseDataSet?.initial ?? null, // Initial
    responseDataSet?.diff1_Tech ?? null, // Technology
    responseDataSet?.diff1_Constr ?? null, // Construction
    responseDataSet?.diff1_Market ?? null, // Market
    responseDataSet?.diff1_Contin ?? null, // Contingency
    responseDataSet?.current ?? null, // Current
    responseDataSet?.diff2_Tech ?? null, // Technology
    responseDataSet?.diff2_Constr ?? null, // Construction
    responseDataSet?.diff2_Market ?? null, // Market
    responseDataSet?.diff2_Contin ?? null, // Contingency
    responseDataSet?.forecast ?? null, // Forecast
    null, // Risk Offset
    null, // Update
    null, // Difference
    null, // Target
  ];
};

const valueFormatter = (value: number, context: Context, locale: string) => {
  const { datasetIndex, dataIndex } = context;
  const datasets = context.chart.data.datasets;
  if (datasetIndex === context.chart.data.datasets.length - 1) {
    let sum = 0;

    if (dataIndex === 4 || dataIndex === 9) {
      sum = value;
    } else {
      datasets.forEach((set, counter) => {
        // do not add transparent bar value
        if (counter > 0) {
          sum += set.data[dataIndex] != null ? (set.data[dataIndex] as number) : 0;
        }
      });
    }
    return new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
      signDisplay: 'auto',
      notation: 'compact',
      compactDisplay: 'short',
    }).format(Number(sum));
  }
  if (value) {
    return new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
      signDisplay: 'auto',
      notation: 'compact',
      compactDisplay: 'short',
    }).format(Number(value));
  }

  return '';
};

const nameFormatter = (value: number, ctx: Context, label: string) => {
  const showNameIndexes = [0, 5, 10, 12, 13, 14];
  if (showNameIndexes.includes(ctx.dataIndex)) {
    return label;
  }
  return '';
};

export const ReportWaterfall = () => {
  ChartJS.register(
    annotationPlugin,
    Filler,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    Title,
    ChartDataLabels,
  );
  const { t } = useTranslation();
  const locale = getLanguageAndLocale().locale;

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: responseData, isFetching } = useApiGetBmwWaterfallReportQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId,
    },
  );

  // Maps the response data to the datasets for the chart
  const reportData = useMemo(() => {
    // Test dummy data
    const sums: (number | null)[] = [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      DUMMY_FORECAST_SUM_VALUE,
      2480000,
      2580000,
      2580000,
      2600000,
    ];
    let transparentBarData: (number | null)[] = [
      0, 1580000, 2260000, 2360000, 2410000, 0, 2380000, 2380000, 2380000, 2380000, 0, 2480000, 0, 2580000, 0,
    ];
    let contingencyRealData: (number | null)[] = [
      1080000,
      null,
      null,
      null,
      -100000,
      1080000,
      null,
      null,
      null,
      0,
      1080000,
      null,
      null,
      null,
      null,
    ];
    let constructionData: (number | null)[] = [
      500000,
      500000,
      120000,
      90000,
      null,
      600000,
      0,
      0,
      0,
      null,
      700000,
      null,
      null,
      null,
      null,
    ];
    let profFeesData: (number | null)[] = [
      null,
      200000,
      200000,
      null,
      null,
      700000,
      0,
      0,
      0,
      null,
      700000,
      null,
      null,
      null,
      null,
    ];
    const riskOffsetData: (number | null)[] = [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      100000,
      null,
      null,
      null,
    ];
    const updateData: (number | null)[] = [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      2580000,
      null,
      null,
    ];
    const diffData: (number | null)[] = [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      20000,
      null,
    ];
    const targetData: (number | null)[] = [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      2600000,
    ];

    const sumValues: (number | null)[] = [...Array(BARS_LENGTH).keys()].map(() => 0);
    if (responseData) {
      // console.log(responseData, 'waterfall data');
      const datasets = responseData.datasets;
      datasets.forEach((dataset) => {
        const mappedData = mapResponseToChartDatasets(dataset);
        switch (dataset.content) {
          case 'pseudo bars':
            transparentBarData = mappedData;
            break;
          case 'Contingency':
            contingencyRealData = mappedData;
            break;
          case 'Construction':
            constructionData = mappedData;
            break;
          case 'Prof. Fees':
            profFeesData = mappedData;
            break;
          default:
            break;
        }
        mappedData.forEach((data, index) => {
          sumValues[index] = (sumValues[index] ?? 0) + (data ?? 0);
        });
      });

      // TODO remove again after all data is available
      transparentBarData[10] = null;
      transparentBarData[11] = sums[10];
      transparentBarData[13] = updateData[12];
    }

    // TODO remove again after all data is available
    sumValues[10] = sums[10];
    sumValues[11] = sums[11];
    sumValues[12] = sums[12];
    sumValues[13] = sums[13];
    sumValues[14] = sums[14];

    return {
      sums: sumValues,
      transparentBarData: transparentBarData,
      contingencyRealData: contingencyRealData,
      constructionData: constructionData,
      profFeesData: profFeesData,
      riskOffset: riskOffsetData, // TODO
      updateData: updateData, // TODO
      diffData: diffData, // TODO
      targetData: targetData, // TODO
    };
  }, [responseData]);

  const annotations = useMemo(() => {
    const lines: { [key: string]: LineAnnotationOptions } = {};
    const { transparentBarData, sums } = reportData;
    transparentBarData.forEach((value, i) => {
      if (i > 0 && i !== 12) {
        const sumCurrent = sums[i];
        const sumPrev = sums[i - 1];
        const yMin =
          sumCurrent != null && sumPrev != null && sumCurrent < sumPrev
            ? sumPrev ?? 0
            : value != null && value > 0
              ? value ?? 0
              : sumCurrent ?? 0;
        lines['line' + i] = {
          type: 'line',
          yMin: yMin,
          yMax: yMin,
          xMin: 0.3 + (i - 1),
          xMax: 0.7 + (i - 1),
          borderWidth: 2,
          borderDash: [2],
        } as LineAnnotationOptions;
      }
    });
    return lines;
  }, [reportData]);

  /* const xScalePadding: Plugin = {
    id: 'xScalePadding',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    beforeDatasetsDraw: (chart: any): boolean | void => {
      const {
        scales: { x, xSubLabel, xSubLabel2 },
      } = chart;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      x._labelItems?.forEach((label: any) => {
        label.textBaseline = 'bottom';
        label.textOffset = 20;
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      xSubLabel._labelItems?.forEach((label: any) => {
        label.textBaseline = 'bottom';
        label.textOffset = 0;
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      xSubLabel2._labelItems?.forEach((label: any) => {
        label.textBaseline = 'bottom';
        label.textOffset = 0;
      });
    }
  }; */

  const forecastUpdateDiff = useMemo(() => {
    return (reportData.sums[10] ?? 0) - (reportData.sums[12] ?? 0);
  }, [reportData.sums]);

  const forecastUpdateDiffParsed = useMemo(() => {
    return new Intl.NumberFormat(locale, {
      maximumFractionDigits: 0,
      signDisplay: 'auto',
      notation: 'compact',
      compactDisplay: 'short',
    }).format(Number(forecastUpdateDiff));
  }, [forecastUpdateDiff, locale]);

  // @ts-expect-error config issues
  const options: ChartOptions = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      categoryPercentage: 1.0,
      // barPercentage: 0.6,
      maxBarThickness: 55,
      plugins: {
        title: {
          display: false,
        },
        htmlLegend: {
          // ID of the container to put the legend in
          containerID: 'legend-container',
        },
        legend: {
          display: false,
        },
        annotation: {
          annotations: {
            ...annotations,
            label1: {
              type: 'label',
              xValue: 11,
              yValue: (forecastUpdateDiff > 0 ? reportData.sums[10] ?? 0 : reportData.sums[12] ?? 0) * 1.138, // border width needs to be calculated in
              //forecastUpdateDiff >= 0 ? reportData.sums[10] ?? 0 : reportData.sums[12] ?? 0,
              backgroundColor: WHITE_COLOR,
              borderColor: BORDER_COLOR,
              borderWidth: 2,
              borderRadius: 50,
              content: [`  ${forecastUpdateDiff > 0 ? '+' : ''}${forecastUpdateDiffParsed}  `],
              position: {
                x: 'center',
                y: 'center',
              },
              color: BORDER_COLOR,
              font: {
                family: 'Roboto',
                size: 14,
                weight: 'bold',
              },
              z: 2,
              // yAdjust: -100, // TODO
            },
            arrowLineTop: {
              type: 'line',
              xMin: 10,
              xMax: 12,
              yMin: (forecastUpdateDiff > 0 ? reportData.sums[10] ?? 0 : reportData.sums[12] ?? 0) * 1.14,
              yMax: (forecastUpdateDiff > 0 ? reportData.sums[10] ?? 0 : reportData.sums[12] ?? 0) * 1.14,
              borderColor: BORDER_COLOR, // 'green'
              borderWidth: 2,
              z: 1,
            },
            arrowLineStart: {
              type: 'line',
              xMin: forecastUpdateDiff > 0 ? 12 : 10,
              xMax: forecastUpdateDiff > 0 ? 12 : 10,
              yMin: (forecastUpdateDiff > 0 ? reportData.sums[12] ?? 0 : reportData.sums[10] ?? 0) * 1.08,
              yMax: (forecastUpdateDiff > 0 ? reportData.sums[10] ?? 0 : reportData.sums[12] ?? 0) * 1.14,
              borderColor: BORDER_COLOR, // 'red'
              borderWidth: 2,
              z: 1,
            },
            arrowLineEnd: {
              type: 'line',
              xMin: forecastUpdateDiff > 0 ? 10 : 12,
              xMax: forecastUpdateDiff > 0 ? 10 : 12,
              yMin: (forecastUpdateDiff > 0 ? reportData.sums[10] ?? 0 : reportData.sums[12] ?? 0) * 1.08,
              yMax: (forecastUpdateDiff > 0 ? reportData.sums[10] ?? 0 : reportData.sums[12] ?? 0) * 1.14,
              borderColor: BORDER_COLOR, // 'orange'
              borderWidth: 2,
              z: 1,
              arrowHeads: {
                length: 10,
                start: {
                  display: forecastUpdateDiff !== 0,
                  fill: false,
                  borderColor: BORDER_COLOR,
                  width: 9,
                },
              },
            },
          },
        },
        tooltip: {
          filter: (data: TooltipItem<keyof ChartTypeRegistry>) => {
            // Hide the transparent dataset tooltip
            return data.datasetIndex > 0;
          },
        },
        datalabels: {
          // do not show the label in the bar, if the bar height is too small
          display: (context: Context) => {
            const { datasetIndex, dataIndex, chart } = context;
            if (datasetIndex === chart.data.datasets.length - 1) return true;
            const meta = chart.getDatasetMeta(datasetIndex);
            const metaData = meta.data[dataIndex];
            // bar height is always 0 when animation not completed, yet
            const barHeight = metaData.$animations.height._to as 'NaN' | number; // metaData?.height && metaData?.height !== 'NaN' ? metaData?.height : metaData.$animations.height._to;
            return barHeight === 'NaN' || barHeight > 28; // Only display label when it fits
          },
        },
      },
      datasets: {
        bar: {
          borderColor: WHITE_COLOR,
          // do not render a border top on last bar
          borderWidth: (ctx) => {
            if (ctx.datasetIndex === 0 || ctx.dataIndex === 4 || ctx.dataIndex === 9) {
              return { top: 0, bottom: 0, left: 0, right: 0 };
            }
            if (ctx.raw && (ctx.raw as number) > 0 && ctx.datasetIndex !== 3) {
              return { top: 2, bottom: 0, left: 0, right: 0 };
            } else {
              return { top: 0, bottom: 0, left: 0, right: 0 };
            }
          },
        },
      },
      scales: {
        x: {
          type: 'category',
          stacked: true,
          beginAtZero: true,
          ticks: {
            font: {
              family: 'Roboto',
              size: [18, 14, 14, 14, 14, 18, 14, 14, 14, 14, 18, 14, 18, 14, 18],
              weight: 'bold',
            },
            color: [
              PRIMARY_COLOR,
              BORDER_COLOR,
              BORDER_COLOR,
              BORDER_COLOR,
              BORDER_COLOR,
              PRIMARY_COLOR,
              BORDER_COLOR,
              BORDER_COLOR,
              BORDER_COLOR,
              BORDER_COLOR,
              PRIMARY_COLOR,
              BORDER_COLOR,
              PRIMARY_COLOR,
              BORDER_COLOR,
              PRIMARY_COLOR,
            ],
            showLabelBackdrop: false,
          },
          border: {
            width: 2,
            color: BORDER_COLOR,
            padding: 3,
          },
          grid: {
            display: false,
          },
          //afterFit: (axis: Scale) => {
          // axis.height += 12;
          // },
        },
        y: {
          stacked: true,
          beginAtZero: true,
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        xSubLabel: {
          type: 'category',
          position: 'bottom',
          labels: [['Version 4.0', '28.02.23'], [], [], [], [], [], [], [], [], [], [], [], [], [], []],
          ticks: {
            font: {
              family: 'Roboto',
              size: 11,
              weight: 'bold',
            },
            color: BORDER_COLOR,
            showLabelBackdrop: false,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        xSubLabel2: {
          type: 'category',
          position: 'bottom',
          labels: [
            ['18% IRR', 'Revenue 10mio €', 'Costs 150mio €'],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
          ],
          ticks: {
            font: {
              family: 'Roboto',
              size: 11,
              weight: 'bold',
            },
            color: LIGHT_LABEL_COLOR,
            showLabelBackdrop: false,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
      },
      layout: {
        padding: {
          top: 0,
          left: 50,
          bottom: 10,
          right: 50,
        },
      },
    };
  }, [annotations, reportData.sums, forecastUpdateDiff, forecastUpdateDiffParsed]);

  const contingencyBarData = useMemo(() => {
    return reportData.contingencyRealData.map((val) => (val != null && val < 0 ? val * -1 : val));
  }, [reportData.contingencyRealData]);

  const defaultValueConfig = useMemo(() => {
    return {
      font: {
        family: 'Roboto',
        weight: 'bold',
      },
      color: WHITE_COLOR,
      anchor: 'start',
      align: 'top',
      formatter: (value: number, context: Context) => {
        return valueFormatter(value, context, locale);
      },
    };
  }, [locale]);

  const defaultNameConfig = useMemo(() => {
    return {
      font: {
        family: 'Roboto',
        size: 9,
      },
      color: BORDER_COLOR,
      align: -14,
      anchor: 'start',
      offset: 28,
      formatter: (value: number, context: Context) => {
        return nameFormatter(value, context, '');
      },
    };
  }, []);

  const data: ChartData = useMemo(() => {
    const data = [
      {
        label: '',
        data: reportData.transparentBarData,
        backgroundColor: 'transparent',
        pointHitRadius: 0,
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
      {
        label: t('reporting.reportWaterfall.label.construction'),
        data: reportData.constructionData,
        backgroundColor: PRIMARY_COLOR,
        datalabels: {
          labels: {
            value: { ...defaultValueConfig },
            name: {
              ...defaultNameConfig,
              formatter: (value: number, ctx: Context) => {
                return nameFormatter(value, ctx, t('reporting.reportWaterfall.label.construction'));
              },
              display: (context: Context) => {
                return context.dataset.data[context.dataIndex] !== null;
              },
            },
          },
        },
      },
      {
        label: t('reporting.reportWaterfall.label.professionalFees'),
        data: reportData.profFeesData,
        backgroundColor: PROF_FEES_COLOR,
        datalabels: {
          labels: {
            value: { ...defaultValueConfig },
            name: {
              ...defaultNameConfig,
              align: -11,
              formatter: (value: number, ctx: Context) => {
                return nameFormatter(value, ctx, t('reporting.reportWaterfall.label.profFeesInline'));
              },
              display: (context: Context) => {
                return context.dataset.data[context.dataIndex] !== null;
              },
            },
          },
        },
        minBarLength: 5,
      },
      {
        label: t('reporting.reportWaterfall.label.contingency'),
        data: contingencyBarData,
        backgroundColor: CONTINGENCY_COLOR,
        datalabels: {
          labels: {
            value: {
              ...defaultValueConfig,
              formatter: (value: number, context: Context) => {
                const { dataIndex } = context;
                const realValue = reportData.contingencyRealData[dataIndex] as number;
                return valueFormatter(realValue, context, locale);
              },
            },
            name: {
              ...defaultNameConfig,
              formatter: (value: number, context: Context) => {
                return nameFormatter(value, context, t('reporting.reportWaterfall.label.contingency'));
              },
              display: (context: Context) => {
                return context.dataset.data[context.dataIndex] !== null;
              },
            },
          },
        },
        minBarLength: 5,
      },
      {
        label: t('reporting.reportWaterfall.label.update'),
        data: reportData.updateData,
        backgroundColor: SECONDARY_COLOR,
        datalabels: {
          labels: {
            value: { ...defaultValueConfig },
            name: {
              ...defaultNameConfig,
              align: -16,
              formatter: (value: number, ctx: Context) => {
                return nameFormatter(value, ctx, t('reporting.reportWaterfall.label.indexPrice'));
              },
            },
          },
        },
      },
      {
        label: t('reporting.reportWaterfall.label.riskOffset'),
        data: reportData.riskOffset,
        backgroundColor: LIGHT_LABEL_COLOR,
        datalabels: {
          labels: {
            value: { ...defaultValueConfig },
          },
        },
        minBarLength: 5,
      },
      {
        label: t('reporting.reportWaterfall.label.difference'),
        data: reportData.diffData,
        backgroundColor: SECONDARY_COLOR,
        datalabels: {
          labels: {
            value: { ...defaultValueConfig },
          },
        },
        minBarLength: 5,
      },
      {
        label: t('reporting.reportWaterfall.label.target'),
        data: reportData.targetData,
        backgroundColor: SECONDARY_COLOR,
        datalabels: {
          labels: {
            value: { ...defaultValueConfig },
            name: {
              ...defaultNameConfig,
              align: -16,
              formatter: (value: number, ctx: Context) => {
                return nameFormatter(value, ctx, t('reporting.reportWaterfall.label.indexPrice'));
              },
            },
          },
        },
      },
      // Numbers on top of bar
      {
        label: '',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        datalabels: {
          labels: {
            value: {
              ...defaultValueConfig,
              font: {
                family: 'Roboto',
                weight: 'bold',
                size: 16,
              },
              formatter: (value: number, context: Context) => {
                const { dataIndex } = context;
                if (dataIndex === 4 || dataIndex === 9) {
                  value = reportData.contingencyRealData[dataIndex] as number;
                }
                return valueFormatter(value, context, locale);
              },
              color: (ctx: Context) => {
                if (ctx.dataIndex === 12 || ctx.dataIndex === 13 || ctx.dataIndex === 14) {
                  return SECONDARY_COLOR;
                } else if (ctx.dataIndex === 0 || ctx.dataIndex === 5 || ctx.dataIndex === 10) {
                  return PROF_FEES_COLOR;
                } else if (ctx.dataIndex === 4 || ctx.dataIndex === 9) {
                  return CONTINGENCY_COLOR;
                } else {
                  if ((!ctx.chart.data.datasets[1].data[ctx.dataIndex] && !ctx.chart.data.datasets[2].data[ctx.dataIndex]) || ctx.chart.data.datasets[2].data[ctx.dataIndex]) {
                    return PROF_FEES_COLOR;
                  }
                  return PRIMARY_COLOR;
                }
              },
              align: 'top',
            },
          },
        },
      },
    ] as ChartDataset[];

    return {
      datasets: data,
      labels: [
        t('reporting.reportWaterfall.label.initial') ?? 'Initial',
        t('reporting.reportWaterfall.label.technology') ?? 'Technology',
        t('reporting.reportWaterfall.label.construction') ?? 'Construction',
        t('reporting.reportWaterfall.label.market') ?? 'Market',
        t('reporting.reportWaterfall.label.contingency') ?? 'Contingency',
        t('reporting.reportWaterfall.label.current') ?? 'Current',
        t('reporting.reportWaterfall.label.technology') ?? 'Technology',
        t('reporting.reportWaterfall.label.construction') ?? 'Construction',
        t('reporting.reportWaterfall.label.market') ?? 'Market',
        t('reporting.reportWaterfall.label.contingency') ?? 'Contingency',
        t('reporting.reportWaterfall.label.forecast') ?? 'Forecast',
        t('reporting.reportWaterfall.label.riskOffset') ?? 'Risk offset', //t('reporting.reportWaterfall.label.offset') ?? 'offset'],
        t('reporting.reportWaterfall.label.update') ?? 'Update',
        t('reporting.reportWaterfall.label.difference') ?? 'Difference',
        t('reporting.reportWaterfall.label.target') ?? 'Target',
      ],
    };
  }, [locale, reportData, contingencyBarData, defaultNameConfig, defaultValueConfig, t]);

  return (
    <DecoratedCard className="min-w-[1383px]">
      <DecoratedCard.Header showActionButton={false}>
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex">
            <div className="truncate">{t('reporting.reportWaterfall.fullTitle')}</div>
          </div>
          {/*
          <div className="flex items-center">
            <div className="flex items-center mr-6">
              <div className="font-bold text-[15px] pr-6">{t('reporting.filterBy')}</div>
              <div className="flex space-x-0.5 items-center"></div>
            </div>
            <div className="font-bold text-[15px] pr-6">{t('reporting.dataSource')}</div>
            <div className="flex space-x-0.5 items-center"></div>
          </div>
          */}
        </div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="w-full h-full flex relative">
        {isFetching && <LoadingIndicator mode="overlay-window" />}
        <div className="w-full max-w-[2000px] mx-auto">
          <div className="py-6 flex flex-col gap-6 text-gray-500 text-sm" id="legend-container">
            <ul className="flex gap-4 px-6">
              <li className="flex gap-2 items-center">
                <div className="w-2 h-4 bg-primary rounded-full" />
                {t('reporting.reportWaterfall.label.construction')}
              </li>
              <li className="flex gap-2 items-center">
                <div className="w-2 h-4 bg-slate-500 rounded-full" />
                {t('reporting.reportWaterfall.label.professionalFees')}
              </li>
              <li className="flex gap-2 items-center">
                <div className="w-2 h-4 bg-slate-400 rounded-full" />
                {t('reporting.reportWaterfall.label.contingency')}
              </li>
            </ul>
            <ul className="flex items-center gap-2 w-full px-[50px]">
              {/* we need to add the classes before otherwise the classes will be rendered */}
              <span className="opacity-0 after:border-l-gray-300 after:border-l-gray-100 bg-gray-300 bg-gray-100 before:border-l-white" />
              <LegendArrow
                as="li"
                title={t('reporting.reportWaterfall.label.validation')}
                className="flex-none w-[calc((100%/15*5.5)-42px)] ml-8"
                arrowColor="gray-300"
                textColor="text-gray-500"
              />
              <LegendArrow
                as="li"
                title={t('reporting.reportWaterfall.label.forecast')}
                className="flex-none w-[calc((100%/15*5)-10px)]"
                arrowColor="gray-300"
                textColor="text-gray-500"
              />
              <LegendArrow
                as="li"
                title={t('reporting.reportWaterfall.label.changeRequest')}
                className="flex-none w-[calc((100%/15*2)-10px)]"
                arrowColor="gray-100"
                textColor="text-gray-500"
              />
              <LegendArrow
                as="li"
                title={t('reporting.reportWaterfall.label.targets')}
                direction="left"
                className="ml-7 flex-1 w-[calc((100%/15*2)-42px)] mr-8"
                arrowColor="gray-300"
                textColor="text-gray-500"
              />
            </ul>
          </div>
          <div className="my-6">
            {/* export-chart id important for exporting chart as PDF */}
            <div className="h-[67vh] min-h-[750px]" id="export-chart">
              <Chart options={options} type="bar" data={data} />
            </div>
          </div>
        </div>
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};
