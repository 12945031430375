import {
  DragScrollContainerTranslateProps,
  // DragScrollContainerTranslate,
  DragScrollContainer,
} from '@client/shared/toolkit';
import cn from 'classnames';

export interface TimeLineDragScrollContainerProps extends DragScrollContainerTranslateProps {
  showFinance?: boolean
}

export const TimeLineDragScrollContainer = ({
  children,
  containerRef,
  syncRefs = [],
}: TimeLineDragScrollContainerProps) => {

  return (
    <DragScrollContainer
      innerRef={containerRef}
      horizontal
      className={cn('hide-scrollbars h-full z-0 relative')}
      syncRefs={syncRefs}
    >
      {children}
    </DragScrollContainer>
  )

  /* return (
    <DragScrollContainerTranslate
      containerRef={containerRef}
      syncRefs={syncRefs}
      updateWidth={updateWidth}
      updateIsDragging={updateIsDragging}
    >
      {children}
    </DragScrollContainerTranslate>
  ); */
};
