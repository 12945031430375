import {
  DecoratedElement,
  RiskElement,
} from '../../hooks';
import {
  addOrRemoveDecoratedElementFromExpandedIds,
  getRenderElementIsVisibleAndTopStyle
} from '../../utils';
import { CalculateProps } from '../CalculateContainer';
import {
  RiskElementRow, RiskGroupElement,
} from '.';
import React, { Fragment, RefObject, useState } from 'react';
import { ToggleButton } from '@client/shared/toolkit';
import classNames from 'classnames';

interface CalculateRisksRenderElementsProps extends CalculateProps {
  containerRef: RefObject<HTMLDivElement>;
  elements: DecoratedElement<RiskElement>[];
  selectedElement?: DecoratedElement<RiskElement>;
  handleOpenSlideOver: (
    element: DecoratedElement<RiskElement> | undefined,
    preselectedGroupId: string | undefined,
  ) => void;
  preselectedGroupId?: string;
  handleOpenDeleteElement: (deleteElementId?: string | null) => void;
  isReadOnly: boolean;
}

export const CalculateRisksRenderElements = (props: CalculateRisksRenderElementsProps) => {
  const {
    containerRef,
    view,
    elements,
    expandedCatalogIds = [],
    onExpand,
    selectedVersionId,
    optionalColumn,
    obligoColumn,
    selectedElement,
    handleOpenSlideOver,
    preselectedGroupId,
    handleOpenDeleteElement,
    isReadOnly,
  } = props;

  let counter = -1;
  let top = 0;
  const [hoveredElementId, setHoveredElementId] = useState<string | undefined>();

  const renderElements = (elements: DecoratedElement<RiskElement>[]) => {
    return elements.map((element) => {
      const open = expandedCatalogIds.includes(element.categoryId);
      top = top + (element.level === 0 ? 32 : 0);
      const { isVisible, topStyle, counter: newCounter } = getRenderElementIsVisibleAndTopStyle(counter, containerRef, top);
      counter = newCounter;

      return (
        <Fragment key={element.element.key}>
          {isVisible && (
            <div
              className={classNames(
                'w-full flex group bg-transparent hover:bg-neutral-100 transition-colors duration-200 h-[38px] absolute',
              )}
              data-level={element.level}
              style={{ top: topStyle }}
            >
              <ToggleButton
                open={open}
                className={classNames('absolute h-full z-[5]', {
                  invisible: element.children.length === 0,
                })}
                onClick={() => {
                  onExpand(
                    addOrRemoveDecoratedElementFromExpandedIds(expandedCatalogIds, element, open ? 'remove' : 'add'),
                  );
                }}
              />
              {element.element.type === 'group' ? (
                <RiskGroupElement
                  view={view}
                  key={`group_${element.element.key}`}
                  item={element}
                  selectedVersionId={selectedVersionId}
                  expanded={open}
                  isSelected={element.categoryId === selectedElement?.categoryId}
                  optionalColumn={optionalColumn}
                  obligoColumn={obligoColumn}
                  onCodeClick={() => {
                    onExpand(
                      addOrRemoveDecoratedElementFromExpandedIds(
                        expandedCatalogIds,
                        element,
                        open ? 'remove' : 'add',
                      ),
                    );
                  }}
                  onNewElement={() => handleOpenSlideOver(undefined, element.element.group?.groupId ?? undefined)}
                  onClick={element.disableClick ? undefined : () => handleOpenSlideOver(element, preselectedGroupId)}
                  onRemoveBudget={
                    element.element.group?.riskElementId
                      ? () => handleOpenDeleteElement(element.element.group?.riskElementId)
                      : undefined
                  }
                  hoveredElementId={hoveredElementId}
                  setHoveredElementId={setHoveredElementId}
                  isReadOnly={isReadOnly}
                />
              ) : (
                <RiskElementRow
                  view={view}
                  key={`risk_element_${element.element.key}`}
                  selectedVersionId={selectedVersionId}
                  item={element}
                  isSelected={element.categoryId === selectedElement?.categoryId}
                  optionalColumn={optionalColumn}
                  obligoColumn={obligoColumn}
                  onClick={() => handleOpenSlideOver(element, preselectedGroupId)}
                  onRemove={
                    element.element.riskElement?.riskElementId
                      ? () => handleOpenDeleteElement(element.element.riskElement?.riskElementId)
                      : undefined
                  }
                  hoveredElementId={hoveredElementId}
                  setHoveredElementId={setHoveredElementId}
                  isReadOnly={isReadOnly}
                />
              )}
            </div>
          )}
          {open && renderElements(element.children)}
        </Fragment>
      );
    });
  };

  return renderElements(elements);
};
