import { ModalOnCloseProps, ConfirmModal, HintBox, LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import {
  useApiDeleteTenantUserDefinedFieldDefinitionMutation,
  UserDefinedFieldDefinitionReadModel
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';

interface CustomFieldDeleteModalProps extends ModalOnCloseProps {
  item: UserDefinedFieldDefinitionReadModel;
}

export const CustomFieldDeleteModal = ({ item, onClose }: CustomFieldDeleteModalProps) => {
  const { t } = useTranslation();
  const [deleteCustomField, { isLoading: isDeleting }] = useApiDeleteTenantUserDefinedFieldDefinitionMutation();

  const handleDelete = async () => {
    try {
      await safeMutation(
        deleteCustomField,
        {
          userDefinedFieldDefinitionId: item.id
        },
        isDeleting
      );

      onClose(true);
    } catch (e) {
      console.log(e)
    }
  };
  const handleOnClose = () => {
    onClose(false);
  };
  return (
    <ConfirmModal
      title={t('app.settingsDeleteCustomFieldTitle', { name: item.name })}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isDeleting}
    >
      {isDeleting && (
        <LoadingIndicator text={t('common.loading')} mode="overlay" />
      )}
      <HintBox hintType="warning">{t('app.settingsDeleteCustomFieldHint', { name: item.name })}</HintBox>
      {item.isUsed && (<HintBox hintType="danger">{t('app.settingsDeleteUsedCustomFieldHint', { name: item.name })}</HintBox>)}
    </ConfirmModal>
  );
};
