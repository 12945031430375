import { t } from "i18next";

export const getContractStateLabel = (contractState : string) => {
    switch (contractState){
        case 'Pending':
            return t('projectContract.pending');
        case 'Settled':
            return t('projectContract.settled');
        case 'Paid':
            return t('projectContract.paid');
        default:
            return '';
    }
}