import { Badge, DecoratedCard, PhoneIcon } from '@client/shared/toolkit';
import { TaxonomyReadModel } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { AtSignIcon } from '@client/shared/toolkit';
import { getInitials } from '@client/shared/utilities';

interface RentersContactsCardProps {
  item?: TaxonomyReadModel | undefined;
}

export const RentersContactsCard = ({ item }: RentersContactsCardProps) => {
  const { t } = useTranslation();

  const flattenedContacts = item?.dealCosts
    ?.filter(
      (deal) =>
        deal.type === 'Rent' && deal.costItems.some((costItem) => costItem.taxonomyItemId === item.itemLogicalId)
    )
    .map((deal) => deal.contacts)
    .flat();

  return (
    <DecoratedCard>
      <DecoratedCard.Header showActionButton={false}>
        <div className="truncate">{t('projectTaxonomy.dashboardRenterContacts')}</div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="p-5 w-full h-full bg-white">
        <div className="flex flex-row overflow-y-auto">
          {flattenedContacts?.map((contact, i) => (
            <div key={i} className="mr-5 mb-2 bg-white shadow-md rounded-md p-4">
              <div className="flex">
                <div className="w-14">
                  <div className="flex items-center justify-center bg-slate-200 text-slate-600 text-2xl font-bold rounded-full h-14 w-14">
                    {getInitials(contact.name)}
                  </div>
                </div>

                <div className="ml-2 w-48">
                  <div className="font-bold text-xl">{contact.company}</div>
                  <div>{contact.name}</div>
                  {contact.role && <Badge variant="secondary" text={contact.role} />}
                </div>
              </div>

              <div className="flex mt-2">
                <div className="flex w-14 items-center justify-end">
                  <AtSignIcon className="w-6 h-6" />
                </div>
                <div className="ml-2">
                  <div className="text-xs">{t('common.email')}</div>
                  <div className="font-semibold">{contact.email}</div>
                </div>
              </div>

              <div className="flex">
                <div className="flex w-14 items-center justify-end">
                  <PhoneIcon className="w-6 h-6" />
                </div>
                <div className="ml-2">
                  <div className="text-xs">{t('common.phoneNumber')}</div>
                  <div className="font-semibold">{contact.mobile}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};
