import {
  // ContextMenu,
  LoadingIndicator,
  // Modal,
  // PencilIcon,
  // SettingsAddButton,
  // SlideOver,
  // SortDownIcon,
  // TextHighlighter,
  // TrashIcon,
  // WorkflowIconLargeIcon,
} from '@client/shared/toolkit';
import React, {
  // useCallback, useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
// import cn from 'classnames';
// import { WorkflowDeleteModal } from './WorkflowDeleteModal';
// import { includesSearchValue } from '@client/shared/utilities';
// import { WorkflowSlideOver } from './WorkflowSlideOver';
import { WorkflowNodeReadModel } from './WorkflowNodeEditSlideOver';
// import { WorkflowCreateModal } from './WorkflowCreateModal';
import { SettingsHeader } from '../SettingsHeader';
import { useApiGetWorkflowsQuery } from '@client/shared/api';
import { WorkflowsList } from '@client/project/shared';

export type WorkflowTestReadModel = {
  id?: string;
  name: string;
  description: string;
  nodes?: WorkflowNodeReadModel[];
  type: 'invoice' | 'contract'; // ...
};

export const Workflows = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  // const [isSortedByNameAsc, setIsSortedByNameAsc] = useState(true);
  // const [selectedItem, setSelectedItem] = useState<WorkflowTestReadModel | null>(null);
  // const [openModal, setOpenModal] = useState<'delete' | 'edit' | 'create' | null>(null);

  // const [isChildSlideOverIsOpen, setIsChildSlideOverOpen] = useState(false);

  const { data: availableWorkflows, isFetching: isLoadingWorkflows } = useApiGetWorkflowsQuery();

  // const workflows: WorkflowTestReadModel[] = useMemo(() => {
  //   return [
  //     {
  //       id: '0000',
  //       name: 'New Workflow',
  //       description: 'Not configured, yet',
  //       type: 'invoice',
  //       nodes: [
  //         {
  //           id: 'start',
  //           name: 'Start Trigger',
  //           actionUsers: [
  //             {
  //               id: '1',
  //               name: 'Harald Ostermeier',
  //               description: 'Active - Manager',
  //               type: 'user',
  //             },
  //             {
  //               id: '2',
  //               name: 'Accounting',
  //               description: 'Gruppe',
  //               type: 'group',
  //             },
  //           ],
  //           triggers: [
  //             {
  //               id: '1',
  //               name: 'Email Eingang',
  //               description: 'Unter: alte_akademie@signa.prob.is',
  //             },
  //             {
  //               id: '2',
  //               name: 'Upload Rechnung',
  //               description: 'Übergabe durch Upload-Portal',
  //             },
  //             {
  //               id: '3',
  //               name: 'Webhook',
  //               description: 'SAP: SIGNA/alte_akademie',
  //             },
  //             {
  //               id: '4',
  //               name: 'Manueller Start',
  //               description: 'Rechnung aus Control ausgewählt und gestartet',
  //             },
  //           ],
  //           type: 'start',
  //           target: 'end',
  //           order: 0,
  //         },
  //         {
  //           id: 'end',
  //           name: 'End Trigger',
  //           actionUsers: [
  //             {
  //               id: '1',
  //               name: 'Harald Ostermeier',
  //               description: 'Active - Manager',
  //               type: 'user',
  //             },
  //             {
  //               id: '2',
  //               name: 'Accounting',
  //               description: 'Gruppe',
  //               type: 'group',
  //             },
  //           ],
  //           triggers: [
  //             {
  //               id: '1',
  //               name: 'Email Eingang',
  //               description: 'Unter: alte_akademie@signa.prob.is',
  //             },
  //             {
  //               id: '2',
  //               name: 'Upload Rechnung',
  //               description: 'Übergabe durch Upload-Portal',
  //             },
  //             {
  //               id: '3',
  //               name: 'Webhook',
  //               description: 'SAP: SIGNA/alte_akademie',
  //             },
  //             {
  //               id: '4',
  //               name: 'Manueller Start',
  //               description: 'Rechnung aus Control ausgewählt und gestartet',
  //             },
  //           ],
  //           type: 'end',
  //           order: 1,
  //         },
  //       ],
  //     },
  //     {
  //       id: '1111',
  //       name: 'Rechnungsfreigabe',
  //       description: 'Erfassen, prüfen & freigeben zur Zahlung',
  //       type: 'invoice',
  //       nodes: [
  //         {
  //           id: 'start',
  //           name: 'Start Trigger',
  //           actionUsers: [
  //             {
  //               id: '1',
  //               name: 'Harald Ostermeier',
  //               description: 'Active - Manager',
  //               type: 'user',
  //             },
  //             {
  //               id: '2',
  //               name: 'Accounting',
  //               description: 'Gruppe',
  //               type: 'group',
  //             },
  //           ],
  //           triggers: [
  //             {
  //               id: '1',
  //               name: 'Email Eingang',
  //               description: 'Unter: alte_akademie@signa.prob.is',
  //             },
  //             {
  //               id: '2',
  //               name: 'Upload Rechnung',
  //               description: 'Übergabe durch Upload-Portal',
  //             },
  //             {
  //               id: '3',
  //               name: 'Webhook',
  //               description: 'SAP: SIGNA/alte_akademie',
  //             },
  //             {
  //               id: '4',
  //               name: 'Manueller Start',
  //               description: 'Rechnung aus Control ausgewählt und gestartet',
  //             },
  //           ],
  //           type: 'start',
  //           target: 'decision-1',
  //           order: 0,
  //         },
  //         {
  //           id: 'decision-1',
  //           name: 'Vorprüfung 1',
  //           description: 'Prüfen der Rechnung auf Vollständigkeit und richtiger Zuordnung.',
  //           actionUsers: [
  //             {
  //               id: '1',
  //               name: 'Harald Ostermeier',
  //               description: 'Active - Manager',
  //               type: 'user',
  //             },
  //             {
  //               id: '2',
  //               name: 'Accounting',
  //               description: 'Gruppe',
  //               type: 'group',
  //             },
  //           ],
  //           triggers: [
  //             {
  //               id: '1',
  //               name: 'Email Eingang',
  //               description: 'Unter: alte_akademie@signa.prob.is',
  //             },
  //             {
  //               id: '2',
  //               name: 'Upload Rechnung',
  //               description: 'Übergabe durch Upload-Portal',
  //             },
  //             {
  //               id: '3',
  //               name: 'Webhook',
  //               description: 'SAP: SIGNA/alte_akademie',
  //             },
  //             {
  //               id: '4',
  //               name: 'Manueller Start',
  //               description: 'Rechnung aus Control ausgewählt und gestartet',
  //             },
  //           ],
  //           type: 'decision',
  //           source: 'start',
  //           ifTarget: 'decision-2',
  //           elseTarget: 'end-2',
  //           order: 1,
  //         },
  //         {
  //           id: 'decision-2',
  //           name: 'Vorprüfung 2',
  //           description: 'Prüfen der Rechnung auf Vollständigkeit und richtiger Zuordnung.',
  //           actionUsers: [
  //             {
  //               id: '1',
  //               name: 'Harald Ostermeier',
  //               description: 'Active - Manager',
  //               type: 'user',
  //             },
  //             {
  //               id: '2',
  //               name: 'Accounting',
  //               description: 'Gruppe',
  //               type: 'group',
  //             },
  //           ],
  //           triggers: [
  //             {
  //               id: '1',
  //               name: 'Email Eingang',
  //               description: 'Unter: alte_akademie@signa.prob.is',
  //             },
  //             {
  //               id: '2',
  //               name: 'Upload Rechnung',
  //               description: 'Übergabe durch Upload-Portal',
  //             },
  //             {
  //               id: '3',
  //               name: 'Webhook',
  //               description: 'SAP: SIGNA/alte_akademie',
  //             },
  //             {
  //               id: '4',
  //               name: 'Manueller Start',
  //               description: 'Rechnung aus Control ausgewählt und gestartet',
  //             },
  //           ],
  //           type: 'decision',
  //           source: 'decision-1',
  //           ifTarget: 'end-1',
  //           elseTarget: 'decision-1',
  //           order: 2,
  //         },
  //         {
  //           id: 'end-2',
  //           name: 'Abgelehnt',
  //           triggers: [],
  //           type: 'end',
  //           status: 'failed',
  //           order: 3,
  //         },
  //         {
  //           id: 'end-1',
  //           name: 'End trigger - Angenommen',
  //           triggers: [],
  //           type: 'end',
  //           order: 4,
  //         },
  //       ],
  //     },
  //   ];
  // }, []);

  // const sortedWorkflows = useMemo(() => {
  //   let sorted = [...workflows];
  //   if (sorted.length) {
  //     sorted.sort(function (a, b) {
  //       const nameA = a.name.toUpperCase();
  //       const nameB = b.name.toUpperCase();
  //       if (isSortedByNameAsc) {
  //         return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  //       } else {
  //         return nameB < nameA ? -1 : nameB > nameA ? 1 : 0;
  //       }
  //     });
  //   }
  //   if (searchText) {
  //     sorted = sorted.filter((workflow) => {
  //       return includesSearchValue(workflow.name, searchText) || includesSearchValue(workflow.description, searchText);
  //     });
  //   }
  //   return sorted;
  // }, [isSortedByNameAsc, searchText, workflows]);

  // const sortByFieldName = useCallback(() => {
  //   setIsSortedByNameAsc((prev) => !prev);
  // }, []);

  return (
    <div className="w-full">
      <SettingsHeader searchText={searchText} setSearchText={setSearchText}>
        {t('app.settingsWorkflows')}
      </SettingsHeader>
      {isLoadingWorkflows ? (
        <LoadingIndicator text={t('app.settingsLoadingWorkflows')} />
      ) : (
        <div className="mt-8">
          {availableWorkflows?.workflows && availableWorkflows.workflows.length > 0 ? (
            <WorkflowsList
              workflows={availableWorkflows.workflows}
              searchText={searchText}
            />
          ) : (
            <div>{t('app.settingsNoWorkflows')}</div>
          )}
        </div>
      )}

      {/* FOR DEMO ONLY */}
      {/* {sortedWorkflows.length > 0 ? (
        <>
          <div
            className="flex w-full items-center py-1 px-5 gap-8 leading-6 justify-between text-[13px] text-slate-400 mt-8">
            <div className="flex-1 flex items-center gap-8">
              <div className="flex gap-4 items-center w-6/12">
                <div className="w-8"/>
                <div className="flex-1 flex justify-between items-center cursor-pointer" onClick={sortByFieldName}>
                  {t('app.settingsSortByNameLabel')}
                  <SortDownIcon
                    className={cn('text-slate-400 opacity-60 transition-transform duration-100 w-7', {
                      '-rotate-180': !isSortedByNameAsc,
                    })}
                  />
                </div>
              </div>
              <div className="w-2/12 truncate">{t('app.settingsWorkflowType')}</div>
              <div className="w-2/12 truncate">{t('app.settingsWorkflowUsedInProjects')}</div>
            </div>
            <div className="px-1 w-6"/>
          </div>
          <ul>
            {sortedWorkflows.map((workflow) => (
              <li
                key={`workflow-${workflow.id}`}
                className="border-b flex w-full items-center py-2 px-5 gap-8 justify-between hover:bg-gray-100 transition-colors duration-300"
              >
                <div
                  className="flex-1 flex items-center gap-8 cursor-pointer"
                  onClick={() => {
                    setSelectedItem(workflow);
                    setOpenModal('edit');
                  }}
                >
                  <div className="flex gap-4 items-center w-6/12">
                    <div className="w-8">
                      <WorkflowIconLargeIcon/>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-lg font-bold leading-6">
                        {searchText ? (
                          <TextHighlighter text={workflow.name} highlighted={[searchText]}/>
                        ) : (
                          workflow.name
                        )}
                      </span>
                      {workflow.description && (
                        <span className="text-[15px] text-slate-400 leading-5">
                          {searchText ? (
                            <TextHighlighter text={workflow.description} highlighted={[searchText]}/>
                          ) : (
                            workflow.description
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="w-2/12 truncate">{workflow.type}</div>
                  <div className="w-2/12"/>
                </div>
                <div>
                  <ContextMenu
                    items={[
                      {
                        label: t('common.edit'),
                        subtitle: t('common.editEntity', {entity: t('app.settingsWorkflow')}),
                        icon: <PencilIcon/>,
                        onClick: () => {
                          setSelectedItem(workflow);
                          setOpenModal('edit');
                        },
                      },
                      {
                        label: t('common.delete'),
                        subtitle: t('common.deleteElement'),
                        icon: <TrashIcon/>,
                        onClick: () => {
                          setSelectedItem(workflow);
                          setOpenModal('delete');
                        },
                      },
                    ]}
                  />
                </div>
              </li>
            ))}
          </ul>
          <SettingsAddButton onClick={() => setOpenModal('create')}/>
          <Modal isOpen={openModal === 'delete'} onClose={() => setOpenModal(null)} variant="medium">
            <WorkflowDeleteModal
              item={selectedItem}
              onClose={() => {
                setSelectedItem(null);
                setOpenModal(null);
              }}
            />
          </Modal>
          <SlideOver
            isOpen={openModal === 'edit'}
            onClose={() => setOpenModal(null)}
            variant="2xl"
            preventClickOutsideClose={isChildSlideOverIsOpen}
          >
            {selectedItem && (
              <WorkflowSlideOver
                workflow={selectedItem}
                setIsChildSlideOverOpen={setIsChildSlideOverOpen}
                onClose={() => {
                  setSelectedItem(null);
                  setOpenModal(null);
                }}
              />
            )}
          </SlideOver>
          <Modal isOpen={openModal === 'create'} onClose={() => setOpenModal(null)}>
            <WorkflowCreateModal
              onClose={() => {
                setSelectedItem(null);
                setOpenModal(null);
              }}
            />
          </Modal>
        </>
      ) : (
        <div className="mt-4">{t('app.settingsNoWorkflows')}</div>
      )} */}
    </div>
  );
};
