import * as yup from 'yup';
import { InferType } from 'yup';

export const DocuwareWizardFormValidationSchema = yup.object({
  isActive: yup.boolean().required('validation.required'),
  apiUrl: yup.string().required('validation.required'),
  userName: yup.string().required('validation.required'),
  password: yup.string().required('validation.required'),
  organization: yup.string().required('validation.required'),
  dialog: yup.string().required('validation.required'),
  projectIdentifier: yup.string().required('validation.required'),
});

export type DocuwareWizardFormValidationValues = InferType<typeof DocuwareWizardFormValidationSchema>;
