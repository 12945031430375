import {
  CostCatalogElementDto,
  EarningsElementShortReadDto,
  PlotReadModel,
  TaxonomyReadModel,
  useApiGetCalculationModelCostsQuery,
  useApiGetCalculationModelEarningsQuery,
  useApiGetCalculationModelQuery,
  useApiGetPlotQuery,
  useApiGetTaxonomyQuery,
} from '@client/shared/api';
import { useMemo } from 'react';

type UseFormulaEditorDatasetArgs = {
  projectId: string;
  calculationModelId: string | null;
};

export type UseFormulaEditorDatasetReturnValues = {
  taxonomy: TaxonomyReadModel | undefined;
  costElements: CostCatalogElementDto[];
  flatCatalogElements: CostCatalogElementDto[];
  earningsElements: EarningsElementShortReadDto[];
  plot: PlotReadModel | undefined;
  flatPlots: PlotReadModel[];
};

export type UseFormulaEditorDatasetReturnObject = {
  values: UseFormulaEditorDatasetReturnValues;
  isLoading: boolean;
};

export type SizeType = {
  label: string;
  value: string;
};

const Size: SizeType[] = [
  { label: 'BGF', value: 'bgf' },
  { label: 'NGF', value: 'ngf' },
  { label: 'MFG', value: 'rentalSpace' },
  { label: 'Amount', value: 'amount' },
  { label: 'GRZ', value: 'grz' },
  { label: 'GFZ', value: 'gfz' },
  { label: 'Size', value: 'size' },
];

export const getSizes = (type?: 'building' | 'plot'): SizeType[] => {
  switch (type) {
    case 'building':
      return Size.slice(0, 4);
    case 'plot':
      return Size.slice(4);
    default:
      return Size;
  }
};

export const useFormulaEditorDataset = (options: UseFormulaEditorDatasetArgs): UseFormulaEditorDatasetReturnObject => {
  const { data: calculateModelData, isFetching: isLoadingCalculateData } = useApiGetCalculationModelQuery(
    {
      projectId: options.projectId ?? 'unset',
      calculationModelId: options.calculationModelId ?? '',
    },
    { skip: options.calculationModelId == null },
  );

  const { data: costsData, isFetching: isLoadingCostData } = useApiGetCalculationModelCostsQuery(
    {
      projectId: options.projectId ?? 'unset',
      calculationModelId: options.calculationModelId ?? '',
    },
    { skip: options.calculationModelId == null },
  );

  const { data: earningsData, isFetching: isLoadingEarningsData } = useApiGetCalculationModelEarningsQuery(
    {
      projectId: options.projectId ?? 'unset',
      calculationModelId: options.calculationModelId ?? '',
    },
    { skip: options.calculationModelId == null },
  );

  const { data: taxonomyData, isFetching: isLoadingTaxonomyData } = useApiGetTaxonomyQuery(
    {
      projectId: options.projectId ?? 'unset',
      calculationModelId: options.calculationModelId ?? '',
    },
    { skip: options.calculationModelId == null },
  );

  const { data: plotData, isFetching: isLoadingPlotData } = useApiGetPlotQuery(
    {
      projectId: options.projectId ?? 'unset',
      calculationModelId: options.calculationModelId ?? '',
      plotId: '',
    },
    { skip: options.calculationModelId == null },
  );

  return useMemo(() => {
    return {
      values: {
        taxonomy: taxonomyData,
        costElements: costsData?.payload.catalogElements ?? [], // calculateModelData?.calculationModel.payload.costElements ?? [],
        flatCatalogElements: calculateModelData?.calculationModel.payload.flatCatalogElements ?? [],
        earningsElements: earningsData?.payload.earnings ?? [],
        plot: plotData,
        flatPlots: calculateModelData?.calculationModel.payload.plots ?? [],
      },
      isLoading:
        isLoadingCalculateData ||
        isLoadingCostData ||
        isLoadingEarningsData ||
        isLoadingTaxonomyData ||
        isLoadingPlotData,
    };
  }, [
    taxonomyData,
    costsData?.payload.catalogElements,
    calculateModelData?.calculationModel.payload.flatCatalogElements,
    calculateModelData?.calculationModel.payload.plots,
    earningsData?.payload.earnings,
    plotData,
    isLoadingCalculateData,
    isLoadingCostData,
    isLoadingEarningsData,
    isLoadingTaxonomyData,
    isLoadingPlotData,
  ]);
};
