import { useApiGetProjectsQuery } from '@client/shared/api';
import { useMemo } from 'react';

export const useProjects = () => {
  const query = useApiGetProjectsQuery();

  return useMemo(() => {
    const projects = (query.data?.projects ?? []).slice();
    const groups = (query.data?.projectGroups ?? []).slice();
    const trustedProjects = (query.data?.trustedProjects ?? []).slice();
    return { ...query, projects, groups, trustedProjects };
  }, [query]);
};
