import { PropsWithChildren } from 'react';
import cn from 'classnames';

export interface DocumentViewerPreviewHeaderGroupProps extends PropsWithChildren {
  className?: string
}
export const DocumentViewerPreviewHeaderGroup = (props: DocumentViewerPreviewHeaderGroupProps) => {
  const {
    className,
    children
  } = props

  return (
    <div className={cn('flex items-center text-slate-500 gap-6', className)}>
      {children}
    </div>
  )
}
