import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PortfolioState } from './portfolioSlice';

type RootState = {
  portfolio: PortfolioState;
};

export const usePortfolioState = () => {
  const portfolio = useSelector<RootState, PortfolioState>((x) => x.portfolio);
  return useMemo(() => portfolio, [portfolio]);
};
