import {
  Button,
  FinishFlagIcon,
  HintBox,
  SlideOver,
  SlideOverOnCloseProps,
  SlideOverTitle,
  StartTriggerIcon,
  WorkflowInfoText,
  WorkflowStep,
  WorkflowTaskInfo,
} from '@client/shared/toolkit';
import {
  WorkflowAssignableActivityReadModel,
  AssignableActivityReadModel,
  WorkflowAssignableActivityConfigurationOptions,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { translateTaskName } from '@client/shared/utilities';
import React, { useState } from 'react';
import { WorkflowEditVariablesSlideOver } from './WorkflowEditVariablesSlideOver';
import { WorkflowEditConfigurationsSlideOver } from './WorkflowEditConfigurationSlideOver';

interface WorkflowTasksSlideOverProps extends SlideOverOnCloseProps {
  workflow: WorkflowAssignableActivityReadModel | null;
  configurationOptions: WorkflowAssignableActivityConfigurationOptions[];
  projectId?: string;
}

export const WorkflowTasksSlideOver = (props: WorkflowTasksSlideOverProps) => {
  const { onClose, workflow, projectId, configurationOptions } = props;

  const { t } = useTranslation();

  const [selectedTask, setSelectedTask] = useState<AssignableActivityReadModel | null>(null);
  const [isOpenEditTaskSlideOver, setIsOpenEditTaskSlideOver] = useState(false);
  const [isOpenEditConfigurationSlideOver, setIsOpenEditConfigurationSlideOver] = useState(false);

  const hasAssignedWorkflows = workflow?.activities.every((a) => a.authorizedGroups?.length);

  return (
    <>
      <SlideOver.Header
        title={workflow?.name ?? ''}
        subTitle={t('common.editEntity', { entity: t('app.settingsWorkflow') })}
        backgroundClassName="bg-slate-900"
        onClose={onClose}
      />
      <SlideOver.Content className="p-8">
        {!hasAssignedWorkflows && (
          <HintBox hintType="warning" className="mb-4">
            {t('app.settingsWorkflowAutomaticTriggerInfo')}
          </HintBox>
        )}
        <div className="flex flex-col gap-0">
          <SlideOverTitle title={t('app.settings.workflows.editNodeTypeStartLabel')} marginTop={false} />
          <div className="relative">
            <div className="w-7 h-24 absolute top-full left-0 before:w-px before:h-full before:absolute before:top-0 before:left-1/2 before:-translate-x-1/2 z-0 before:bg-slate-400" />
            <WorkflowTaskInfo
              isLast
              variant="card"
              customIcon={<StartTriggerIcon className="w-6" />}
              title={workflow?.name ?? ''}
              borderColor="border-slate-600"
              onClick={() => {
                setIsOpenEditConfigurationSlideOver(true);
              }}
            />
          </div>
          <SlideOverTitle title={t('app.settingsWorkflow')} className="mb-9 ml-7" />
          {workflow?.activities.map((task, i) => (
            <WorkflowStep status="disabled" variant="cards" key={`workflow-task-${i}`}>
              <WorkflowTaskInfo
                variant="card"
                titleIcon
                isLast={i === workflow.activities.length - 1}
                title={translateTaskName(task.name)}
                onClick={() => {
                  setSelectedTask(task);
                  setIsOpenEditTaskSlideOver(true);
                }}
              >
                <WorkflowInfoText
                  label={`${t('app.settings.workflows.workflowType')}:`}
                  text={translateTaskName(task.type)}
                />

                <WorkflowInfoText
                  label={t('app.settings.workflows.whoLabel')}
                  text={
                    (task.authorizedGroups?.map((group) => group.groupName).join(', ') ?? '') +
                    (task.authorizedGroups?.length && task.authorizedUsers?.length ? ', ' : '') +
                    (task.authorizedUsers?.map((user) => user.userName).join(', ') ?? '')
                  }
                >
                  {!task.authorizedGroups?.length ? (
                    <span className="italic text-red-700">{t('workflow.requiredAssignment')}</span>
                  ) : (
                    ''
                  )}
                </WorkflowInfoText>
              </WorkflowTaskInfo>
            </WorkflowStep>
          ))}
          <div className="relative mt-12 z-10">
            <div className="w-7 h-full absolute -top-12 left-0 before:w-px before:h-full before:absolute before:top-0 before:left-1/2 before:-translate-x-1/2 z-0 before:bg-slate-400" />
            <WorkflowTaskInfo
              isLast
              variant="card"
              customIcon={<FinishFlagIcon className="w-6" />}
              title={t('app.settings.workflows.finished')}
              borderColor="border-emerald-400"
            />
          </div>
        </div>
      </SlideOver.Content>
      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.close')}
        </Button>
      </SlideOver.Controls>
      {selectedTask && workflow && (
        <WorkflowEditVariablesSlideOver
          isOpen={isOpenEditTaskSlideOver}
          onClose={() => setIsOpenEditTaskSlideOver(false)}
          onAfterLeave={() => setSelectedTask(null)}
          task={selectedTask}
          workflow={workflow}
          projectId={projectId}
          configurationOptions={configurationOptions}
        />
      )}
      {workflow && (
        <WorkflowEditConfigurationsSlideOver
          isOpen={isOpenEditConfigurationSlideOver}
          onClose={() => setIsOpenEditConfigurationSlideOver(false)}
          onAfterLeave={() => setSelectedTask(null)}
          workflow={workflow}
          projectId={projectId}
        />
      )}
    </>
  );
};
