import {
  DealReadModel,
  DealStatus,
  DealType,
  useApiPostCreateRentDealMutation,
  useApiPostUpdateRentDealMutation,
  useApiPostCreateSellDealMutation,
  useApiPostUpdateSellDealMutation,
} from '@client/shared/api';
import classNames from 'classnames';
import { useState, useRef } from 'react';
import {
  Button,
  SlideOver,
  ResumeIcon,
  TextInput,
  BaseSelect,
  DatePicker,
  Form,
  FormField,
  Modal,
  SlideOverOnCloseProps,
  TagWindowIcon,
  TimeLimitIcon,
  LoadingIndicator,
} from '@client/shared/toolkit';
import { formatNullableDateOnly, safeMutation } from '@client/shared/utilities';
import { DealDeleteModal } from './DealDeleteModal';
import { useTranslation } from 'react-i18next';
import { DealEditFormValidationSchema, DealEditFormValidationValues } from './DealEditFormValidationSchema';
import { useCanDeleteDeal, useCanWriteDeal } from '../../hooks/useDealPermissions';

interface DealEditSlideOverProps extends SlideOverOnCloseProps {
  isAddMode: boolean;
  projectId: string;
  createType: DealType;
  deal?: DealReadModel;
  isReadOnlyVersion: boolean;
}

export const DealEditSlideOver = ({
  createType,
  isAddMode,
  deal,
  onClose,
  projectId,
  isReadOnlyVersion,
}: DealEditSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const canDelete = useCanDeleteDeal(createType);
  const readOnly = !useCanWriteDeal(createType) || isReadOnlyVersion;

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const [createRentDeal, { isLoading: isCreatingRent }] = useApiPostCreateRentDealMutation();
  const [updateRentDeal, { isLoading: isUpdatingRent }] = useApiPostUpdateRentDealMutation();
  const [createSellDeal, { isLoading: isCreatingSell }] = useApiPostCreateSellDealMutation();
  const [updateSellDeal, { isLoading: isUpdatingSell }] = useApiPostUpdateSellDealMutation();

  const availableStatus = () => {
    const status: { label: string; value: DealStatus; disabled?: boolean }[] = [
      { label: t('projectRent.dealStatusInterested'), value: 'Interested' },
      { label: t('projectRent.dealStatusDraft'), value: 'Draft' },
    ];

    if (isAddMode) {
      status.push({ label: t('projectRent.dealStatusReserved'), value: 'Reserved', disabled: true });
      status.push({ label: t('projectRent.dealStatusClosed'), value: 'Closed', disabled: true });
    }

    if (deal) {
      const hasSpaceUsageConflict = deal.contractItems.filter((x) => x.hasSpaceConflict).length > 0;
      const hasReservedConflict = deal.costItems.filter((x) => x.conflictingReserved.length > 0).length > 0;
      const hasSoldConflict = deal.costItems.filter((x) => x.conflictingSold.length > 0).length > 0;
      const hasRentConflict = deal.costItems.filter((x) => x.conflictingRented.length > 0).length > 0;
      const blockedConflict = hasSpaceUsageConflict || hasReservedConflict || hasSoldConflict || hasRentConflict;

      status.push({ label: t('projectRent.dealStatusReserved'), value: 'Reserved', disabled: blockedConflict });
      status.push({ label: t('projectRent.dealStatusClosed'), value: 'Closed', disabled: blockedConflict });
    }

    return status;
  };

  const handleSubmit = async (data: DealEditFormValidationValues) => {
    switch (createType) {
      case 'Rent':
        handleRentSubmit(data);
        break;
      case 'Sell':
        handleSellSubmit(data);
        break;
    }
  };

  const handleSellSubmit = async (data: DealEditFormValidationValues) => {
    try {
      if (isAddMode) {
        await safeMutation(
          createSellDeal,
          {
            projectId: projectId,
            body: {
              name: data.name,
              code: data.code ?? '',
              status: (data.status ?? 'Interested') as DealStatus,
              start: formatNullableDateOnly(data.start),
              end: formatNullableDateOnly(data.end),
            },
          },
          isCreatingSell,
        );
      } else {
        await safeMutation(
          updateSellDeal,
          {
            projectId: projectId,
            dealId: deal?.id ?? '',
            body: {
              name: data.name,
              code: data.code ?? '',
              status: (data.status ?? 'Interested') as DealStatus,
              start: formatNullableDateOnly(data.start),
              end: formatNullableDateOnly(data.end),
            },
          },
          isUpdatingSell,
        );
      }

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRentSubmit = async (data: DealEditFormValidationValues) => {
    try {
      if (isAddMode) {
        await safeMutation(
          createRentDeal,
          {
            projectId: projectId,
            body: {
              name: data.name,
              code: data.code ?? '',
              status: (data.status ?? 'Interested') as DealStatus,
              start: formatNullableDateOnly(data.start),
              end: formatNullableDateOnly(data.end),
            },
          },
          isCreatingRent,
        );
      } else {
        await safeMutation(
          updateRentDeal,
          {
            projectId: projectId,
            dealId: deal?.id ?? '',
            body: {
              name: data.name,
              code: data.code ?? '',
              status: (data.status ?? 'Interested') as DealStatus,
              start: formatNullableDateOnly(data.start),
              end: formatNullableDateOnly(data.end),
            },
          },
          isUpdatingRent,
        );
      }

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const defaultFormValues = {
    name: deal?.name ?? '',
    code: deal?.code,
    status: deal?.status ?? 'Interested',
    start: deal?.start ? new Date(deal.start) : undefined,
    end: deal?.end ? new Date(deal.end) : undefined,
  };

  return (
    <>
      <SlideOver.Header
        onClose={() => onClose(false)}
        title={isAddMode ? t('projectRent.dealNewDeal') : deal?.name ?? ''}
        subTitle={isAddMode ? '' : deal?.code ?? ''}
        backgroundClassName="bg-sky-900"
      />

      <Form<DealEditFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={DealEditFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
      >
        <SlideOver.Content>
          {(isCreatingRent || isUpdatingRent || isCreatingSell || isUpdatingSell) && (
            <LoadingIndicator text={t('projectRent.dealEditLoading') ?? ''} mode="overlay" />
          )}
          <div className="m-8 bg-white">
            <div className="divide-gray-100 divide-y">
              <FormField name="name">
                {(control) => (
                  <TextInput
                    disabled={readOnly}
                    label={t('projectRent.dealLabelName')}
                    icon={<TagWindowIcon />}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="code">
                {(control) => (
                  <TextInput
                    disabled={readOnly}
                    label={t('projectRent.dealLabelCode')}
                    icon={<TagWindowIcon />}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="status">
                {(control) => (
                  <BaseSelect
                    disabled={readOnly}
                    label={t('projectRent.dealLabelStatus')}
                    options={availableStatus()}
                    icon={<ResumeIcon />}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="start">
                {(control) => (
                  <DatePicker
                    disabled={readOnly}
                    label={t('projectRent.dealLabelStart')}
                    icon={<TimeLimitIcon />}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="end">
                {(control) => (
                  <DatePicker
                    disabled={readOnly}
                    label={t('projectRent.dealLabelEnd')}
                    icon={<TimeLimitIcon />}
                    {...control}
                  />
                )}
              </FormField>
            </div>
          </div>
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className={classNames('flex w-full', deal ? 'justify-between' : 'justify-end')}>
            {!isAddMode && canDelete && !isReadOnlyVersion && (
              <Button variant="warning" onClick={() => setIsOpenDeleteModal(true)}>
                {t('common.delete')}
              </Button>
            )}
            <div className="flex">
              <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
                {t('common.cancel')}
              </Button>
              {!readOnly && (
                <Button variant="primary" formSubmit={true} innerRef={submitRef}>
                  {t('common.save')}
                </Button>
              )}
            </div>
          </div>
        </SlideOver.Controls>
      </Form>

      {!isAddMode && deal && (
        <Modal isOpen={isOpenDeleteModal} onClose={() => setIsOpenDeleteModal(false)} variant="small">
          <DealDeleteModal deal={deal} onClose={() => setIsOpenDeleteModal(false)} />
        </Modal>
      )}
    </>
  );
};
