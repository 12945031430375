import { Modal } from '@client/shared/toolkit';
import React, { useMemo, useState } from 'react';
import { FinancingDistributionModal } from './FinancingDistributionModal';
import { FinancingPaymentTypeSelect } from './FinancingPaymentTypeSelect';
import {
  ElementTimelineReadModel,
  FinancingPaybackPlanElementReadModel,
  FinancingPaymentPlanElementReadModel,
  PaymentPlanType, RepaymentMethod,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export enum FinancingDistributionType {
  PAYBACK = 'payback',
  PAYOUT = 'payout',
}

interface FinancingDistributionProps {
  type: FinancingDistributionType;
  selectedPaymentPlan: PaymentPlanType;
  disabled?: boolean;
  variantId?: string;
  timing?: ElementTimelineReadModel;
  setTimeline: (value: ElementTimelineReadModel | null) => void;
  setPaymentPlanType: (value: PaymentPlanType) => void;
  isRecurringPayback?: boolean;
  residualDebt?: number | null;
  setResidualDebt?: (value: number | null | undefined) => void;
  paymentPlan: FinancingPaymentPlanElementReadModel[] | FinancingPaybackPlanElementReadModel[];
  nominalAmount?: number;
  savePaymentPlan: (plan: FinancingPaymentPlanElementReadModel[] | FinancingPaybackPlanElementReadModel[]) => void;
  ltc: number | null;
  costCatalogElementIds: string[];
  isLoan?: boolean
  repaymentMethod?: RepaymentMethod
  setRepaymentMethod?: (method: RepaymentMethod) => void
  isLocked?: boolean
  annuityPercentage?: number | null;
  setAnnuityPercentage?: (value: number | null | undefined) => void;
}
export const FinancingDistribution = (props: FinancingDistributionProps) => {
  const {
    type,
    selectedPaymentPlan,
    disabled = false,
    variantId,
    timing,
    setTimeline,
    setPaymentPlanType,
    isRecurringPayback = false,
    residualDebt,
    setResidualDebt,
    paymentPlan,
    nominalAmount,
    savePaymentPlan,
    ltc,
    costCatalogElementIds,
    isLoan = false,
    repaymentMethod,
    setRepaymentMethod,
    isLocked,
    annuityPercentage,
    setAnnuityPercentage
  } = props;
  const { t }  = useTranslation()
  const [showDistribution, setShowDistribution] = useState(false);

  const repaymentMethodLabel = useMemo(() => {
    let label = ''
    if (repaymentMethod && isLoan && isRecurringPayback) {
      switch (repaymentMethod) {
        case 'Annuity':
          label = t('projectCalculate.repaymentMethods.Annuity')
          break;
        case 'Amortization':
          label = t('projectCalculate.repaymentMethods.Amortization')
          break;
        case 'AnnuityByPercentage':
          label = t('projectCalculate.repaymentMethods.AnnuityByPercentage')
          break;
        default:
          label = ''
      }
    }
    return label
  }, [repaymentMethod, isLoan, isRecurringPayback, t])

  return (
    <>
      <div
        onClick={() => {
          setShowDistribution(true);
        }}
        tabIndex={0}
        role="button"
        className={cn('relative', {
          'pb-4': repaymentMethodLabel
        })}
      >
        <FinancingPaymentTypeSelect
          className="pointer-events-none"
          defaultPaymentPlanType="AllInTheBeginning"
          selectedValue={selectedPaymentPlan}
          onChange={setPaymentPlanType}
          type={type}
          disabled={disabled}
        />
        {repaymentMethodLabel && (
          <div className="absolute bottom-2 truncate px-4 text-xs">{repaymentMethodLabel}</div>
        )}
      </div>
      <Modal
        isOpen={showDistribution}
        onClose={() => setShowDistribution(false)}
        variant="max"
      >
        <FinancingDistributionModal
          type={type}
          selectedPaymentPlan={selectedPaymentPlan}
          disabled={disabled}
          variantId={variantId}
          timing={timing}
          setTimeLine={setTimeline}
          setPaymentPlanType={setPaymentPlanType}
          isRecurringPayback={isRecurringPayback}
          residualDebt={residualDebt}
          setResidualDebt={setResidualDebt}
          paymentPlan={paymentPlan}
          savePaymentPlan={(plan) => {
            savePaymentPlan(plan);
          }}
          onClose={() => {
            setShowDistribution(false);
          }}
          nominalAmount={nominalAmount}
          ltc={ltc}
          costCatalogElementIds={costCatalogElementIds}
          isLoan={isLoan}
          repaymentMethod={repaymentMethod}
          setRepaymentMethod={setRepaymentMethod}
          isLocked={isLocked}
          annuityPercentage={annuityPercentage}
          setAnnuityPercentage={setAnnuityPercentage}
        />
      </Modal>
    </>
  );
};
