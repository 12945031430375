import { CalculationModelMetadata } from '@client/shared/api';
import { Badge } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { humanizeJsonDate, formatPercentage, formatUnit } from '@client/shared/utilities';
import { FormattedCurrency, versionTextFromType } from '@client/project/shared';
import { useValidateCatalogHasRestrictions } from '@client/shared/permissions';
import { HandRaisedIcon } from '@heroicons/react/24/outline';

interface VariantListItemProps {
  variant: CalculationModelMetadata;
  isSelected?: boolean;
  onClick?: () => void;
}

export const VariantListItem = ({ isSelected = false, onClick, variant }: VariantListItemProps) => {
  const { t } = useTranslation();

  const handleOnClick = () => {
    onClick?.();
  };

  const isSnapshot = variant.type === 'UserSnapshot';

  const costCatalogHasRestrictions = useValidateCatalogHasRestrictions(variant.projectId, variant.costCatalogId);
  const riskCatalogHasRestrictions = useValidateCatalogHasRestrictions(variant.projectId, variant.riskCatalogId);
  const earningsCatalogHasRestrictions = useValidateCatalogHasRestrictions(
    variant.projectId,
    variant.earningsCatalogId
  );

  const totalCost = variant.totalCost.value ? variant.totalCost.value : 0;
  const totalRisks = variant.totalRisks.value ? variant.totalRisks.value : 0;
  const totalEarnings = variant.totalEarnings.value ? variant.totalEarnings.value : 0;

  const costsAndRisks = totalCost + totalRisks;

  const versionText = versionTextFromType(t, variant.type, variant.version);

  return (
    <div className="flex w-full p-2 px-4 border-b cursor-pointer hover:bg-blue-100 text-sm" onClick={handleOnClick}>
      <div className="w-1/3 items-center flex">
        {isSnapshot ? (
          <div className="flex flex-col">
            <div className="font-bold">
              {variant.snapshotComment}{' '}
              {isSelected === true && <Badge variant="success" text="Loaded" className="flex-shrink-0" />}
            </div>
            <div className="text-xs text-gray-500">{variant.name}</div>
          </div>
        ) : (
          <div className="flex flex-col truncate">
            <div className="flex flex-row">
              <div className="font-bold">
                {variant.name}
                <span className="font-normal">{versionText ? ` \u2022 ${versionText}` : ''}</span>
              </div>
              {isSelected === true && <Badge variant="success" text="Loaded" className="ml-2 flex-shrink-0" />}
            </div>

            {variant.description && <div className="text-xs text-gray-500 truncate">{variant.description}</div>}
          </div>
        )}
      </div>
      <div className="w-2/3 flex text-gray-500">
        <div className="w-2/12 flex flex-col justify-center">
          {costCatalogHasRestrictions || riskCatalogHasRestrictions ? (
            <HandRaisedIcon className="text-red-500 h-6 w-6" />
          ) : (
            <>
              <div><FormattedCurrency amount={costsAndRisks} /></div>
              <div className="">{formatUnit(variant.grossFloorValue.value, variant.grossFloorValue.unit)} {t('projectVariants.labelBgf')}</div>
            </>
          )}
        </div>

        <div className="w-2/12 flex flex-col justify-center">
          {earningsCatalogHasRestrictions ? (
            <HandRaisedIcon className="text-red-500 h-6 w-6" />
          ) : (
            <div><FormattedCurrency amount={totalEarnings} /></div>
          )}
        </div>

        <div className="w-2/12 flex flex-col justify-center">
          {earningsCatalogHasRestrictions || riskCatalogHasRestrictions || costCatalogHasRestrictions ? (
            <HandRaisedIcon className="text-red-500 h-6 w-6" />
          ) : (
            <div>{formatPercentage(costsAndRisks !== 0 ? (totalEarnings - costsAndRisks) / costsAndRisks : 0)}</div>
          )}
        </div>

        <div className="w-3/12 flex flex-col">
          <div>{variant.lastEdit?.changedBy}</div>
          {t('projectVariants.lastEdited', { time: humanizeJsonDate(variant.lastEdit?.changedAt) })}
        </div>

        <div className="w-3/12 flex flex-col items-end">
          <div>
            <div>{variant.created?.changedBy}</div>
            {t('projectVariants.created', { time: humanizeJsonDate(variant.created?.changedAt) })}
          </div>
        </div>
      </div>
    </div>
  );
};
