import { useLoadedProjectId } from "@client/project/store";
import {
  AiEvalDocumentReadModel,
  useApiDeleteAiEvalDocumentMutation,
} from "@client/shared/api";
import { ConfirmModal, LoadingIndicator, ModalOnCloseProps } from "@client/shared/toolkit";
import { Trans, useTranslation } from "react-i18next";
import { safeMutation } from '@client/shared/utilities';

interface DeleteInvoiceAiEvalDocumentDeleteModalProps extends ModalOnCloseProps {
  document: AiEvalDocumentReadModel;
}

export const DeleteInvoiceAiEvalDocumentModal = ({ document, onClose }: DeleteInvoiceAiEvalDocumentDeleteModalProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();

  const [deleteDocument, {isLoading}] = useApiDeleteAiEvalDocumentMutation();

  const handleDelete = async () => {
    if (loadedProjectId) {
      try {
        await safeMutation(
          deleteDocument,
          {
            projectId: loadedProjectId,
            documentId: document.id
          },
          isLoading
        );

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <ConfirmModal
      title={t('projectCalculate.DeleteDocument')}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      {isLoading && (<LoadingIndicator mode="overlay" text={t('projectCalculate.DeleteDocumentLoadingIndicator')} />)}
      <Trans i18nKey="projectCalculate.DocumentDeleteConfirmation" values={{ entity: document.filename }}>
        <div>
          Möchten Sie die Datei <span className="font-bold">Entity</span> wirklich löschen?
        </div>
        <div className="pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
