import { ApiGetProjectsApiResponse } from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiProjectsEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetProjects: {
    providesTags: (result: ApiGetProjectsApiResponse) => [
      ...(result?.projects?.map((dto): { type: ApiTagTypes.Project, id: string } => ({
        type: ApiTagTypes.Project,
        id: dto.id,
      })) ?? []),
      ...(result?.projectGroups?.map((dto): { type: ApiTagTypes.Project, id: string } => ({
        type: ApiTagTypes.Project,
        id: dto.id,
      })) ?? []),
      ApiTagTypes.Projects,
    ],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
}
