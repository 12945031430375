import {
  CompanyAssignmentReadModel,
  CompanyBranchReadModel,
  CompanyPersonReadModel,
  CompanyReadModel,
} from '@client/shared/api';
import {
  Avatar,
  Badge,
  BadgeGroup,
  ContactButtons,
  ContactList,
  ContactListProps,
  ContextMenu,
  ContextMenuItem,
  EyeIcon,
  HomeCustomIcon,
  PencilIcon,
  TextHighlighter,
  TrashIcon,
  UserIcon,
  AddIcon,
  UploadBadge,
} from '@client/shared/toolkit';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { setExpandedCompanyIds } from '@client/project/store';
import {
  getTenantTrustBadgeByStatus,
  getTenantTrustBadgeLabelByStatus
} from '@client/project/shared';

type PartnerCompanyListProps = {
  searchText?: string;
  company: CompanyReadModel;
  className?: string;
  onAdd: (company: CompanyReadModel) => void;
  onEdit: (company: CompanyReadModel) => void;
  onDelete: (company: CompanyReadModel) => void;
  onShow: (company: CompanyReadModel) => void;
  onEditContact: (contact: CompanyPersonReadModel) => void;
  onEditBranch: (branch: CompanyBranchReadModel) => void;
  onShowTenantTrust: (project: CompanyAssignmentReadModel, branch: CompanyBranchReadModel, company: CompanyReadModel) => void;
  expandedIds: string[];
};

export const PartnerCompanyList = (props: PartnerCompanyListProps) => {
  const {
    className,
    searchText,
    company,
    onShow,
    onAdd,
    onEdit,
    onDelete,
    onEditContact,
    onEditBranch,
    onShowTenantTrust,
    expandedIds
  } =
    props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isExpanded = useMemo(() => {
    return expandedIds.includes(company.id);
  }, [expandedIds, company.id]);

  const updateExpandedIds = useCallback(
    (id: string) => {
      const expanded = expandedIds ? [...expandedIds] : [];
      const index = expanded.indexOf(id);

      if (index > -1) {
        expanded.splice(index, 1);
      } else {
        expanded.push(id);
      }
      dispatch(setExpandedCompanyIds(expanded));
    },
    [expandedIds, dispatch],
  );

  const contextMenu = useMemo(() => {
    if (company.type === 'Company') {
      return [
        {
          label: t('app.masterDataCompaniesShowCompany'),
          icon: <EyeIcon />,
          onClick: () => onShow(company),
          isDisabled: false,
        },
        {
          label: t('app.masterDataCompaniesAddBranch'),
          icon: <AddIcon />,
          onClick: () => onAdd(company),
          isDisabled: false,
        },
        {
          label: t('app.masterDataCompaniesEditCompany'),
          icon: <PencilIcon />,
          onClick: () => onEdit(company),
          isDisabled: false,
        },
        {
          label: t('app.masterDataCompaniesDeleteCompany'),
          icon: <TrashIcon />,
          onClick: () => onDelete(company),
          isDisabled: false,
        },
      ];
    }
    return [
      {
        label: t('app.masterDataCompaniesEditPrivatePerson'),
        icon: <PencilIcon />,
        onClick: () => onEdit(company),
        isDisabled: false,
      },
      {
        label: t('app.masterDataCompaniesDeletePrivatePerson'),
        icon: <TrashIcon />,
        onClick: () => onDelete(company),
        isDisabled: false,
      },
    ];
  }, [t, onShow, company, onAdd, onEdit, onDelete]);

  const projectsSubTitle = useCallback((branch: CompanyBranchReadModel) => {
    if (branch.projectAssignments.length) {
      const sortedProjects = [...branch.projectAssignments].sort((a, b) => a.project.name.localeCompare(b.project.name, undefined, {sensitivity: 'case'}));

      return (
        <ul className="flex gap-x-3 gap-y-1 flex-wrap underline mb-1">
          {sortedProjects.map((project, i) => (
            <li
              key={`company-project-${project.project.projectId}-${i}`}
              className="font-light text-[15px] flex gap-1.5 hover:text-gray-300 transition-colors duration-300 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onShowTenantTrust(project, branch, company);
              }}
            >
              {project.project.name}
              {project.tenantTrust && (
                <UploadBadge
                  variant={getTenantTrustBadgeByStatus(
                    project.tenantTrust.trustState,
                    project.tenantTrust.trustIsActive,
                  )}
                  tooltip={getTenantTrustBadgeLabelByStatus(
                    project.tenantTrust.trustState,
                    project.tenantTrust.trustIsActive,
                  )}
                  size="xs"
                />
              )}
            </li>
          ))}
        </ul>
      );
    }
    return undefined;
  }, [onShowTenantTrust, company])

  const tenantCompanyListItems: ContactListProps[] = useMemo(() => {
    const items: ContactListProps[] = [];
    if (company.branches.length && company.type === 'Company') {
      const branches = [...company.branches].sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'case'}));
      branches.forEach((branch) => {
        let branchPersons = [];
        const persons: ContactListProps[] = [];

        // branch persons
        if (company.persons.length) {
          branchPersons = [...company.persons].filter(
            (person) => person.branches.length > 0 && person.branches.find((pb) => pb.branchName === branch.name),
          ).sort((a, b) => a.fullname.localeCompare(b.fullname, undefined, {sensitivity: 'case'}));
          branchPersons.forEach((person) => {
            const contactContextMenu = [
              {
                label: t('app.masterDataCompaniesEditContact'),
                icon: <PencilIcon />,
                onClick: () => onEditContact(person),
                isDisabled: false,
                stopPropagation: true,
              },
            ];
            persons.push({
              icon: <UserIcon />,
              subtitle: person.function,
              title: searchText ? (
                <TextHighlighter text={person.fullname} highlighted={[searchText]} />
              ) : (
                person.fullname
              ),
              onTitleClick: () => onEditContact(person),
              children: (
                <PartnerCompanyListContent company={company} person={person} contextMenu={contactContextMenu} />
              ),
            });
          });
        }

        // Add branch
        const branchContextMenu = [
          {
            label: t('app.masterDataCompaniesEditBranch'),
            icon: <PencilIcon />,
            onClick: () => onEditBranch(branch),
            isDisabled: false,
            stopPropagation: true,
          },
        ];
        items.push({
          icon: <HomeCustomIcon />,
          subtitle: projectsSubTitle(branch),
          title: searchText ? <TextHighlighter text={branch.name} highlighted={[searchText]} /> : branch.name,
          listItems: persons,
          children: (
            <PartnerCompanyListContent
              company={company}
              numberOfPersons={branchPersons.length}
              contextMenu={branchContextMenu}
            />
          ),
          defaultOpen: expandedIds.includes(branch.id),
          onToggle: () => updateExpandedIds(branch.id),
          onTitleClick: () => onEditBranch(branch)
        });
      });
    }

    // Private person
    if (company.type === 'Company') {
      const peopleWithOutBranch = [...company.persons].filter((person) => !person.branches.length).sort((a, b) => a.fullname.localeCompare(b.fullname, undefined, {sensitivity: 'case'}));
      peopleWithOutBranch.forEach((person) => {
        const contactContextMenu = [
          {
            label: t('app.masterDataCompaniesEditContact'),
            icon: <PencilIcon />,
            onClick: () => onEditContact(person),
            isDisabled: false,
            stopPropagation: true,
          },
        ];
        items.push({
          icon: <UserIcon />,
          subtitle: person.function,
          title: searchText ? <TextHighlighter text={person.fullname} highlighted={[searchText]} /> : person.fullname,
          customColor: 'bg-white',
          children: <PartnerCompanyListContent company={company} person={person} contextMenu={contactContextMenu} />,
        });
      });
    }
    return items;
  }, [company, t, onEditBranch, onEditContact, searchText, expandedIds, updateExpandedIds, projectsSubTitle]);

  const listImage = useMemo(() => {
    if (company.type === 'Company') {
      return (
        <Avatar
          name={company.name}
          className="w-10 h-10"
          rounded={false}
          randomBgColor={false}
          imagePath={company.logoFileId ? `/api/companies/${company.id}/logo/${company.logoFileId}` : undefined}
        />
      );
    }
    return <UserIcon />;
  }, [company.type, company.name, company.logoFileId, company.id]);

  return (
    <ContactList
      className={className}
      listItems={[
        {
          image: listImage,
          listItems: tenantCompanyListItems,
          title: searchText ? <TextHighlighter text={company.name} highlighted={[searchText]} /> : company.name,
          children: (
            <PartnerCompanyListContent
              company={company}
              numberOfPersons={company.persons.length}
              contextMenu={contextMenu}
              person={company.type === 'PrivatePerson' ? company.persons[0] : undefined}
            />
          ),
          onClick: company.type === 'PrivatePerson' ? () => onEdit(company) : undefined,
          onTitleClick: company.type === 'Company' ? () => onShow(company) : undefined,
        },
      ]}
      customColor={company.type === 'PrivatePerson' ? 'bg-white' : undefined}
      defaultOpen={isExpanded}
      onToggle={() => updateExpandedIds(company.id)}
    />
  );
};

type PartnerCompanyListContentProps = {
  numberOfPersons?: number;
  company: CompanyReadModel;
  person?: CompanyPersonReadModel;
  contextMenu?: ContextMenuItem[];
};
export const PartnerCompanyListContent = (props: PartnerCompanyListContentProps) => {
  const { numberOfPersons, company, person, contextMenu } = props;

  if (!numberOfPersons && !person && !contextMenu) return null;

  return (
    <div className="flex items-center">
      {typeof numberOfPersons !== 'undefined' &&
        numberOfPersons > 0 &&
        company.type === 'Company' &&
        company.persons.length > 0 && (
          <BadgeGroup>
            <Badge
              className="w-[38px] text-center bg-white text-slate-600 pointer-events-none mr-2"
              variant="custom"
              text={numberOfPersons.toString()}
            />
          </BadgeGroup>
        )}
      {person && (
        <ContactButtons
          className="mr-2"
          // color={person ? 'text-white hover:text-slate-300' : undefined}
          email={person.email}
          phone={person.phone}
          whatsapp={person.mobile}
        />
      )}
      {contextMenu && contextMenu.length > 0 && (
        <div className="flex justify-end mx-2 z-70 cursor-pointer">
          {contextMenu.length > 1 ? (
            <ContextMenu items={contextMenu} stopPropagation />
          ) : (
            <div
              className={cn(
                'w-6 h-6 duration-200 transition-colors hover:text-gray-700',
                person ? 'text-gray-400' : '',
              )}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                contextMenu[0].onClick();
                return false;
              }}
            >
              <ChevronRightIcon />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
