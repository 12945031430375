import {
  FileManagementSlideOver,
  FileUploadCard,
  TaxonomyPlotDashboardProps
} from '.';
import React, { useState } from 'react';
import { SlideOver } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { TaxonomyItemFileTypeEnum } from '@client/shared/api';

export const TaxonomyPlotDocuments = (props: TaxonomyPlotDashboardProps) => {
  const {
    plot,
    isReadOnly
  } = props
  const { t } = useTranslation()
  const [selectedField, setSelectedField] = useState('');
  const [isFileManagementSlideOverOpen, setIsFileManagementSlideOverOpen] = useState(false);
  const handleUploadCardClick = (type: TaxonomyItemFileTypeEnum) => {
    setSelectedField(type)
    setIsFileManagementSlideOverOpen(true)
  }
  const showforItemTypes = (itemTypes: (string | undefined)[]) => {
    return itemTypes.includes(plot?.type);
  };
  const leftColumnComponents = [
    {
      component: (
        <FileUploadCard
          plot={plot}
          type="Image"
          handleClick={handleUploadCardClick}
          title={t('projectTaxonomy.dashboardImages')}
        />
      ),
      show: showforItemTypes(['Plot', 'Infrastructure', 'SpecialItem']) || plot?.isProjectPlot,
    },
    {
      component: (
        <FileUploadCard
          plot={plot}
          type="Sketch"
          handleClick={handleUploadCardClick}
          title={t('projectTaxonomy.dashboardSketches')}
        />
      ),
      show: showforItemTypes(['Plot', 'Infrastructure', 'SpecialItem']),
    },
    {
      component: (
        <FileUploadCard
          plot={plot}
          type="SitePlan"
          handleClick={handleUploadCardClick}
          title={t('projectTaxonomy.dashboardSitePlans')}
        />
      ),
      show: showforItemTypes(['Plot', 'Infrastructure', 'SpecialItem']) || plot?.isProjectPlot,
    },
    {
      component: (
        <FileUploadCard
          plot={plot}
          type="Document"
          handleClick={handleUploadCardClick}
          title={t('projectTaxonomy.dashboardDocuments')}
          showThumbnail={false}
        />
      ),
      show: showforItemTypes(['Plot', 'Infrastructure', 'SpecialItem']) || plot?.isProjectPlot,
    },
  ];
  return (
    <div className="p-6 flex flex-col gap-6">
      {leftColumnComponents
        .filter((element) => element.show)
        .map((element, i) => {
          return <div key={i}>{element.component}</div>;
        })}
      <SlideOver
        isOpen={isFileManagementSlideOverOpen}
        onClose={() => setIsFileManagementSlideOverOpen(false)}
        confirmBeforeClose={false}
      >
        <FileManagementSlideOver
          plot={plot}
          isReadOnly={isReadOnly}
          onClose={() => setIsFileManagementSlideOverOpen(false)}
          field={selectedField}
          canDelete={true}
        />
      </SlideOver>
    </div>
  )
}
