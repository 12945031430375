import { useContext } from 'react';
import { ReportCostBreakdownContext } from './ReportCostBreakdownContext';
import { Collapsible, ReceiptAndChangeIcon, ToggleButton } from '@client/shared/toolkit';
import { FormattedCurrency } from '@client/project/shared';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// import { formatPercentage } from '@client/shared/utilities';
import { CashFlowReportReadModel } from '@client/shared/api';
import { useDispatch } from 'react-redux';
import { setExpandedCostBreakdownReportIds, useExpandedReportingIds } from '@client/project/store';

export const ReportCostBreakdownTableFixed = () => {
  const { t } = useTranslation();
  const { elements, metadata } = useContext(ReportCostBreakdownContext);

  const columns = [
    {
      value: 'Budget',
      label: t('reporting.tableBudget'),
      subTitle: t('reporting.tableCostBreakdownSublabel'),
      total: metadata?.budgetAvailable,
    },
    {
      value: 'ToBe',
      label: t('reporting.tableToBe'),
      subTitle: t('reporting.tableCostBreakdownSublabel'),
      total: metadata?.forecast,
    },
    {
      value: 'Last',
      label: t('reporting.tableLast'),
      subTitle: t('reporting.tableCostBreakdownSublabel'),
      total: undefined,
    },
    {
      value: 'Invoiced',
      label: t('reporting.tableInvoiced'),
      subTitle: t('reporting.tableCostBreakdownSublabel'),
      total: metadata?.budgetAssigned,
    },
  ];
  const { CostBreakdown: expandedElements } = useExpandedReportingIds();

  return (
    <>
      <div className="border-b border-b-slate-300 h-20">
        <HeaderFooter columns={columns} />
      </div>
      {elements.map((element, i) => (
        <ReportCostBreakdownCollapsingRow
          rowData={element}
          key={`report-cost-${element.code}-${i}`}
          expandedElements={expandedElements}
          last={i === elements.length - 1}
        />
      ))}
      <div className="bg-white">
        <HeaderFooter columns={columns} isFooter />
      </div>
    </>
  );
};

interface Column {
  value: string;
  label: string;
  subTitle: string;
  total: number | undefined | null;
}

interface HeaderFooterProps {
  columns: Column[];
  isFooter?: boolean;
}

const HeaderFooter = ({ columns, isFooter = false }: HeaderFooterProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames('flex pr-0.5 w-full h-full', {
        'pl-12': !isFooter,
      })}
    >
      <div className="flex items-end flex-grow">
        <div
          className={classNames('h-full flex text-[11px] text-slate-500', {
            'items-start flex-grow ': isFooter,
            'items-end': !isFooter,
          })}
        >
          {!isFooter && <div className="w-[80px] text-right px-2 truncate">{t('reporting.tableColumnId')}</div>}
          {isFooter ? (
            <div className="flex-grow">
              <div className="pl-[128px] flex items-center h-11 text-[18px] font-bold text-red-700 truncate border-b border-slate-200 border-dotted">
                {t('reporting.reportCostBreakdown.totalExclVat')}
              </div>
              <div className="pl-[128px] flex items-center h-9 text-[14px] truncate border-b border-slate-200 border-dotted">
                {t('reporting.reportCostBreakdown.vatPaid')}
              </div>
              <div className="pl-[128px] flex items-center h-9 text-[14px] truncate border-b border-slate-200 border-dotted">
                {t('reporting.reportCostBreakdown.vatReceived')}
              </div>
              <div className="pl-[128px] flex items-center h-11 text-[18px] font-bold text-red-700 truncate border-b border-slate-200 border-dotted">
                {t('reporting.reportCostBreakdown.totalInclVat')}
              </div>
              <div className="pl-[128px] w-[350px] flex items-center h-11 text-[18px] font-bold text-red-700 truncate">
                {t('reporting.reportCostBreakdown.capitalCalls')}
              </div>
            </div>
          ) : (
            <div className="w-[350px] truncate">{t('reporting.tableColumnDescription')}</div>
          )}
        </div>
      </div>
      <div
        className={classNames('h-full', {
          'pt-8': !isFooter,
        })}
      >
        <div className="flex items-start justify-end pb-1 h-full">
          {columns?.map((column, index) => (
            <div
              className={classNames(
                'w-[117px] flex justify-end text-right font-bold text-[15px] truncate border-r border-slate-300',
                {
                  'items-end border-dashed': isFooter,
                  'h-full items-start px-4 ': !isFooter,
                },
              )}
              key={`report-detail-column-${isFooter ? 'footer' : 'header'}-${index}`}
            >
              {isFooter ? (
                <div className="flex-grow">
                  <div className="flex items-center justify-end px-4 h-11 text-[14px] font-bold truncate border-b border-slate-200 border-dotted">
                    <FormattedCurrency amount={column.total} />
                  </div>
                  <div className="flex items-center justify-end px-4 h-9 text-[13px] truncate border-b border-slate-200 border-dotted">
                    {<FormattedCurrency amount={undefined} />}
                  </div>
                  <div className="flex items-center justify-end px-4 h-9 text-[13px] truncate border-b border-slate-200 border-dotted">
                    <FormattedCurrency amount={undefined} />
                  </div>
                  <div className="flex items-center justify-end px-4 h-11 text-[14px] font-bold truncate border-b border-slate-200 border-dotted">
                    <FormattedCurrency amount={undefined} />
                  </div>
                  <div className="flex items-center justify-end px-4 h-11 text-[14px] font-bold truncate">
                    <FormattedCurrency amount={undefined} />
                  </div>
                </div>
              ) : (
                <div>
                  <div>{column.label}</div>
                  <div className="text-xxs font-light">{column.subTitle}</div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

interface ReportCostBreakdownCollapsingRowProps {
  rowData: CashFlowReportReadModel;
  level?: number;
  expandedElements: string[];
  last: boolean;
  committment?: boolean;
}

const ReportCostBreakdownCollapsingRow = ({
  level = 0,
  rowData,
  expandedElements,
  last = false,
  committment,
}: ReportCostBreakdownCollapsingRowProps) => {
  const hasChildren = rowData?.children && rowData.children.length > 0;
  const expanded = expandedElements.includes(rowData?.uid ?? '');

  const dispatch = useDispatch();
  return (
    <>
      <div
        className={classNames('flex items-center pl-4 hover:bg-slate-50 transition-colors', {
          'bg-gray-50 font-semibold border-b border-slate-300 text-[14px]': level === 0,
          'bg-white border-b border-dotted border-slate-300 text-[12px]': level > 0,
        })}
      >
        {hasChildren ? (
          <ToggleButton
            open={expanded}
            onClick={() =>
              dispatch(
                setExpandedCostBreakdownReportIds(
                  expanded ? expandedElements.filter((x) => x !== rowData?.uid) : [...expandedElements, rowData?.uid],
                ),
              )
            }
          />
        ) : (
          <div className="w-8">&nbsp;</div>
        )}
        <div className="flex flex-grow h-10 justify-center items-center">
          <div className="h-full flex items-center justify-end py-2 w-[80px] text-[12px] text-right px-2">
            {rowData.code}
          </div>
          <div
            className={classNames('h-full flex items-center justify-start py-2 w-[350px] text-[12px] truncate bg', {
              'pl-3': committment,
            })}
          >
            {rowData.itemType === 'Contract' && <ReceiptAndChangeIcon className="h-5 pl-0.5 pr-1" />}
            {rowData.itemType === 'Commitment' && <ReceiptAndChangeIcon className="h-5 pl-0.5 pr-1 text-slate-600" />}
            {rowData.name}
          </div>
          <div className="h-full flex items-center justify-end py-2 w-[117px] text-right px-4 border-r border-dashed border-slate-300">
            <FormattedCurrency amount={rowData.budgetAvailable} />
          </div>
          <div className="h-full flex items-center justify-end py-2 w-[117px] text-right px-4 border-r border-dashed border-slate-300">
            <FormattedCurrency amount={rowData.forecast} />
          </div>
          <div className="h-full flex items-center justify-end py-2 w-[117px] text-right px-4 border-r border-dashed border-slate-300">
            <FormattedCurrency amount={undefined} />
          </div>
          <div className="h-full flex items-center justify-end py-2 w-[117px] text-right px-4 border-r border-dashed border-slate-300">
            <FormattedCurrency amount={rowData.budgetAssigned} />
          </div>
        </div>
      </div>

      {hasChildren && (
        <Collapsible open={expanded}>
          {rowData.children?.map((child, index) => (
            <ReportCostBreakdownCollapsingRow
              key={index}
              rowData={child}
              level={level + 1}
              expandedElements={expandedElements}
              last={rowData.children ? index === rowData.children.length - 1 : false}
              committment={rowData.itemType === 'Commitment'}
            />
          ))}
        </Collapsible>
      )}
    </>
  );
};
