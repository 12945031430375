import { api } from '@client/shared/api';
import toast from 'react-hot-toast';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { i18n, wait } from '@client/shared/utilities';
import { logout } from '@client/shared/store';

type TApiAction<TPayload> = {
  meta: {
    baseQueryMeta?: TMetadata;
  };
  payload: TPayload;
};

type TMetadata = {
  request?: unknown;
  response?: {
    status?: number;
    url: string;
  };
};

export function* greetUser() {
  yield takeEvery([api.endpoints.apiPostCheckSession.matchFulfilled], function* (action) {
    const user = action.payload.user;
    const fullName = user != null ? `${user.firstName} ${user.lastName}` : null;

    if (fullName != null) {
      toast.success(i18n.t('app.notificationWelcome', { name: fullName }));
    } else {
      toast.success(i18n.t('app.notificationWelcomeUser'));
    }

    yield;
  });
}

export function* httpErrorHandler() {
  yield takeEvery(['api/executeQuery/rejected', 'api/executeMutation/rejected'], function* (action) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const typedAction = action as any as TApiAction<unknown>;
    const response = typedAction.meta.baseQueryMeta?.response;

    // sometimes we don't have the underlying response (or baseQueryMeta in general)
    // this might happen if calls are interrupted
    // if so, there is nothing to do here
    if (response == null) return;

    switch (response.status) {
      case 404: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const payload = typedAction.payload as any;

        if (payload?.data) {
          let message = i18n.t('app.notificationServerErrorNotFound') + '\n\n';

          let translationkey = payload?.data?.message;

          let parameters = {};
          if (payload?.data?.messageParameters && payload?.data?.messageParameters.length > 0) {
            parameters = payload?.data?.messageParameters.reduce(
              (acc: Record<string, string>, curr: string, index: number) => {
                acc[index.toString()] = curr;
                return acc;
              },
              {}
            );
          }
          translationkey = i18n.t(`${payload?.data?.code}`.toLowerCase(), parameters);

          message += '\u2022 ' + translationkey + '\n';
          yield toast.error(message);
        } else yield toast.error(i18n.t('app.notificationServerErrorNotFound'));

        break;
      }
      case 500: {
        toast.error(i18n.t('app.notificationServerError'));
        break;
      }
      case 503: {
        toast.error(i18n.t('app.notificationServerNotAvailableError'));
        break;
      }
      case 401: {
        if (response.url.includes('/api/auth/login')) {
          toast.error(i18n.t('app.notificationInvalidUserCredentials'));
        } else {
          toast.error(i18n.t('app.notificationUnauthorized'));
          yield wait(2000);
          if (!response.url.includes('/api/auth/check-session')) {
            yield put(logout());
          }
        }
        break;
      }
      case 403: {
        if (response.url.includes('/api/auth/change-password')) return; // DO NOT SHOW NOTIFICATION FOR THIS API CALL

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const payload = typedAction.payload as any;

        if (payload?.data) {
          let message = i18n.t('app.notificationOperationForbidden') + '\n\n';

          let translationkey = payload?.data?.message;

          let parameters = {};
          if (payload?.data?.messageParameters && payload?.data?.messageParameters.length > 0) {
            parameters = payload?.data?.messageParameters.reduce(
              (acc: Record<string, string>, curr: string, index: number) => {
                acc[index.toString()] = curr;
                return acc;
              },
              {}
            );
          }
          translationkey = i18n.t(`${payload?.data?.code}`.toLowerCase(), parameters);

          message += '\u2022 ' + translationkey + '\n';
          yield toast.error(message);
        } else yield toast.error(i18n.t('app.notificationOperationForbidden'));
        break;
      }
      case 409:
      case 400: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const payload = typedAction.payload as any;

        if (payload?.data?.errorsList) {
          let message = i18n.t('error.general.invalid-request') + '\n\n';

          payload?.data?.errorsList.forEach(
            (error: { errorMessage: string; propertyName: string; errorCode: string; errorType: string }) => {
              const translationKey = i18n.t(`${error.errorCode}`.toLowerCase());
              message += '\u2022 ' + translationKey + '\n';
            }
          );

          yield toast.error(message);
        }

        if (payload?.data && !payload?.data?.errorsList) {
          let message = i18n.t('error.general.invalid-request') + '\n\n';
          let translationkey = payload?.data?.message;
          let parameters = { };

          if (payload?.data?.messageParameters && payload?.data?.messageParameters.length > 0) {
            parameters = payload?.data?.messageParameters.reduce(
              (acc: Record<string, string>, curr: string, index: number) => {
                acc[index.toString()] = curr;
                return acc;
              },
              {}
            );
          }

          translationkey = i18n.t(`${payload?.data?.code}`.toLowerCase(), parameters);
          message += '\u2022 ' + translationkey + '\n';
          yield toast.error(message);
        }

        break;
      }
      default: {
        toast.error(i18n.t('app.notificationUnexpectedError'));
        break;
      }
    }

    yield;
  });
}

export function* notificationSaga() {
  yield fork(httpErrorHandler);
  yield fork(greetUser);
}
