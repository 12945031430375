import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IdListState } from './idListSlice';

type RootState = {
  idList: IdListState;
};

export const useIdListState = () => {
  const idList = useSelector<RootState, IdListState>((x) => x.idList);
  return useMemo(() => idList, [idList]);
};

export const useExpandedTaxonomyIds = () => {
  return useIdListState().Taxonomy;
};

export const useExpandedCalculateIds = () => {
  return useIdListState().Calculate;
};

export const useExpandedCompanyIds = () => {
  return useIdListState().Company;
}

export const useExpandedReportingIds = () => {
  return useIdListState().Reporting;
}