import { ClusterType } from '@client/shared/api';
import { i18n } from '@client/shared/utilities';

export const getClusterItemType = (type: ClusterType) => {
  switch (type) {
    case 'SpecialBuilding':
      return i18n.t('portoflio.property.cluster.typeSpecialBuilding');
    case 'Building':
      return i18n.t('portoflio.property.cluster.typeBuilding');
    case 'BuildingComplex':
      return i18n.t('portoflio.property.cluster.typeBuildingComplex');
    default:
      return '';
  }
};
