import cn from 'classnames';
import {TimeLineBarChartDataProps} from '.'
import React, {useMemo} from 'react';
import { useGetCurrencySymbol } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import { FormattedCurrency } from '@client/project/shared';
import { CalculateTooltip } from '../CalculateTooltip';

interface TimeLineBarChartProps {
  className?: string;
  barChartData: TimeLineBarChartDataProps[]
  first?: boolean
  groupName?: string
  isToggled?: boolean
  months: Date[]
}

export const TimeLineBarChart = (props: TimeLineBarChartProps) => {
  const {
    className,
    barChartData,
    first = false,
    groupName,
    isToggled = false
  } = props
  const colors = useMemo(() => {
    let isColor = ''
    let shouldColor = ''
    switch (groupName) {
      case ('costs'):
        isColor = 'bg-costs' // TODO correct color needed here
        shouldColor = 'bg-[#F7EDEE]'
        break
      case ('risks'):
        isColor = 'bg-risks' // TODO correct color needed here
        shouldColor = 'bg-[#E2E8F0]'
        break
      case ('earnings'):
        isColor = 'bg-[#CDDDB7]'
        shouldColor = 'bg-[#F2F7EC]'
        break
      default:
        isColor = ''
        shouldColor = ''
    }
    return {
      isColor: isColor,
      shouldColor: shouldColor
    }
  }, [groupName])

  const currentCurrency = useGetCurrencySymbol()

  return (
    <div className={cn('flex flex-col justify-end h-28', className)}>
      {barChartData.length > 0 && (
        <div className="flex w-full relative h-20">
          {barChartData.map((data, i) => (
            <TimeLineBarChartBarIsShould
              key={`bar-chart-${i}`}
              data={data}
              colorIs={colors.isColor}
              colorShould={colors.shouldColor}
              width={isToggled ? 'w-full' : undefined}
            />
          ))}
        </div>
      )}
      <div className="absolute bottom-0 left-0 w-full h-20 text-slate-400">
        {/* TODO current currency + translation ? */}
        <div className="w-full before:content-[''] before:w-full before:h-px before:bg-gray-200 before:absolute before:top-0 before:left-0">
          {first && (
            <span className="w-8 text-right absolute -top-3 left-1 text-[8px]">10 Mio {currentCurrency}</span>
          )}
        </div>
        <div className="absolute top-1/2 left-0 w-full after:content-[''] after:w-full after:h-px after:bg-gray-200 after:absolute after:top-0 after:left-0">
          {first && (
            <span className="w-8 text-right absolute -top-3 left-1 text-[8px]">5 Mio {currentCurrency}</span>
          )}
        </div>
      </div>
    </div>
  );
};

interface TimeLineBarChartBarIsShouldProps {
  colorIs?: string
  colorShould?: string
  data: TimeLineBarChartDataProps
  width?: string
}

export const TimeLineBarChartBarIsShould = (props: TimeLineBarChartBarIsShouldProps) => {
  const {
    colorIs = 'bg-lime-300',
    colorShould = 'bg-lime-100',
    data,
    width = 'w-[44px]'
  } = props

  const { t } = useTranslation()

  // max height is 100%, the rest ist cut off
  const isHeight = useMemo(() => {
    return `${Math.min(100, 100 / 10000000 * data.isValue)}%`
  }, [data.isValue])

  const shouldHeight = useMemo(() => {
    return `${Math.min(100, 100 / 10000000 * data.shouldValue)}%`
  }, [data.shouldValue])

  return (
    <div className={cn(
      'relative flex items-end h-full',
      width
    )}>
      {data.isValue === 0 && data.shouldValue === 0 ? (
        <>
          <TimeLineBarChartBar
            color={colorShould}
            height={shouldHeight}
          />
          <TimeLineBarChartBar
            color={colorIs}
            width="w-3/4"
            className="absolute left-0 bottom-0"
            height={isHeight}
          />
        </>
      ) : (
        <CalculateTooltip
          className="h-full w-full pointer-events-auto flex items-end cursor-pointer"
          label={
            <>
              <TimeLineBarChartBar
                color={colorShould}
                height={shouldHeight}
              />
              <TimeLineBarChartBar
                color={colorIs}
                width="w-3/4"
                className="absolute left-0 bottom-0"
                height={isHeight}
              />
            </>
          }
          placement="top"
        >
          <div className="text-xs whitespace-nowrap">
            <span className="font-bold">{t('projectCalculate.timeLine.isLabel')}:</span> <FormattedCurrency amount={data.isValue} /><br />
            <span className="font-bold">{t('projectCalculate.timeLine.shouldLabel')}:</span> <FormattedCurrency amount={data.shouldValue} />
          </div>
        </CalculateTooltip>
      )}
    </div>
  )
}

interface TimeLineBarChartBarProps {
  className?: string
  color?: string
  height?: string
  width?: string
}

export const TimeLineBarChartBar = (props: TimeLineBarChartBarProps) => {
  const {
    className,
    color = 'bg-slate-200',
    height = '100%',
    width = 'w-11/12'
  } = props

  return (
    <div
      className={cn('h-full', width, color, className)}
      style={{
        height: height
      }}
    />
  )
}
