import { DealCostItemReadModel, DealReadModel } from '@client/shared/api';
import { useApiPostUpdateRentDealCostItemMutation, useApiPostUpdateSellDealCostItemMutation } from '@client/shared/api';
import classNames from 'classnames';
import React, { useState, useRef, useMemo } from 'react';
import {
  Button,
  NumberInput,
  Form,
  FormField,
  Modal,
  SlideOverOnCloseProps,
  SlideOver, ExpandIcon, TaxonomyParkingIcon,
  LoadingIndicator,
} from '@client/shared/toolkit';
import { formatUnit, safeMutation } from '@client/shared/utilities';
import { getTaxonomyIcon } from '@client/project/shared';
import { DealCostItemDeleteModal } from './DealCostItemDeleteModal';
import { useTranslation } from 'react-i18next';
import { DealCostItemFormValidationValues, DealCostItemFormValidationSchema } from './DealCostItemFormValidationSchema';
import { useLoadedProjectId, useLoadedProjectUnitSystemSymbol } from '@client/project/store';
import { useCanDeleteDeal, useCanWriteDeal } from '../../hooks';

interface DealCostItemSlideOverProps extends SlideOverOnCloseProps {
  deal: DealReadModel;
  costItem: DealCostItemReadModel;
  isReadOnly:boolean;
}

export const DealCostItemSlideOver = ({ deal, costItem, onClose, isReadOnly }: DealCostItemSlideOverProps) => {
  const { t } = useTranslation();

  const unitSystem = useLoadedProjectUnitSystemSymbol();

  const submitRef = useRef<HTMLButtonElement>(null);

  const projectId = useLoadedProjectId();
  const readOnly = !useCanWriteDeal(deal.type) || isReadOnly;
  const canDelete = useCanDeleteDeal(deal.type) && !isReadOnly;

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [updateRentCostItem, { isLoading: isLoadingRent }] = useApiPostUpdateRentDealCostItemMutation();
  const [updateSellCostItem, { isLoading: isLoadingSell }] = useApiPostUpdateSellDealCostItemMutation();

  const handleSubmit = async (data: DealCostItemFormValidationValues) => {
    switch (deal.type) {
      case 'Rent':
        try {
          await safeMutation(
            updateRentCostItem,
            {
              projectId: projectId ?? 'unset',
              dealId: deal.id ?? '',
              costItemId: costItem.id,
              body: {
                partCommonSpace: data.partCommon ?? 0,
                partRentSpace: data.partRent ?? 0,
                partParkingAmount: data.partParkingAmount ?? 0,
              },
            },
            isLoadingRent
          );

          onClose(true);
        } catch (e) {
          console.log(e);
        }
        break;
      case 'Sell':
        try {
          await safeMutation(
            updateSellCostItem,
            {
              projectId: projectId ?? 'unset',
              dealId: deal.id ?? '',
              costItemId: costItem.id,
              body: {
                partCommonSpace: data.partCommon ?? 0,
                partRentSpace: data.partRent ?? 0,
                partParkingAmount: data.partParkingAmount ?? 0,
              },
            },
            isLoadingSell
          );

          onClose(true);
        } catch (e) {
          console.log(e);
        }
        break;
    }
  };

  const defaultFormValues = {
    partCommon: costItem.partCommonSpace.value ?? 0,
    partRent: costItem.partRentalSpace.value ?? 0,
    partParkingAmount: costItem.partParkingAmount ?? 0,
  };

  const icon = useMemo(() => {
    const IconComponent = getTaxonomyIcon(costItem.taxonomyItem?.itemType)
    return <IconComponent className="w-8 h-8" />
  }, [costItem.taxonomyItem?.itemType])

  return (
    <>
      <SlideOver.Header
        title={t('projectRent.dealCostItemEditTitle')}
        backgroundClassName="bg-sky-900"
        onClose={() => onClose(false)}
      />

      <Form<DealCostItemFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={DealCostItemFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
      >
        <SlideOver.Content>
        {(isLoadingRent  || isLoadingSell )  && <LoadingIndicator text={t('projectRent.dealCostItemSaving') ?? ''} mode="overlay" />}
          <div className="m-8">
            <div className="flex flex-row mb-4">
              <div className="w-8 mr-2 flex justify-center">
                {icon}
              </div>
              <div>
                <div>
                  <div className="text-gray-400 text-xs font-bold">{costItem.taxonomyItem.itemType}</div>
                  <div className="truncate font-bold">{costItem.taxonomyItem.itemName}</div>
                </div>
              </div>
            </div>
            <div className="bg-white divide-gray-100 divide-y">
              {costItem.taxonomyItem.itemType !== 'Parking' && (
                <div className="flex w-full">
                  <FormField name="partCommon">
                    {(control) => (
                      <NumberInput
                        disabled={readOnly}
                        label={t('projectRent.dealCostItemLabelCommonSpace')}
                        icon={<ExpandIcon />}
                        className="flex-grow"
                        {...control}
                      />
                    )}
                  </FormField>

                  <div className="flex w-2/12 items-center font-bold">
                    <div>&nbsp;/&nbsp;{formatUnit(costItem.taxonomyItem.sizes.effectiveCommonSpaceValue?.value, unitSystem)}</div>
                  </div>
                </div>
              )}
              {costItem.taxonomyItem?.itemType !== 'Parking' && (
                <div className="flex w-full">
                  <FormField name="partRent">
                    {(control) => (
                      <NumberInput
                        disabled={readOnly}
                        label={t('projectRent.dealCostItemLabelRentSellSpace')}
                        icon={<ExpandIcon />}
                        className="flex-grow"
                        {...control}
                      />
                    )}
                  </FormField>
                  <div className="flex w-2/12 items-center font-bold">
                    <div>&nbsp;/&nbsp;{formatUnit(costItem.taxonomyItem.sizes.effectiveRentalSpaceValue?.value, unitSystem)}</div>
                  </div>
                </div>
              )}
              {costItem.taxonomyItem?.itemType === 'Parking' && (
                <div className="flex w-full">
                  <FormField name="partParkingAmount">
                    {(control) => (
                      <NumberInput
                        disabled={readOnly}
                        label={t('projectRent.dealCostItemLabelParking')}
                        icon={<TaxonomyParkingIcon />}
                        className="flex-grow"
                        {...control}
                      />
                    )}
                  </FormField>
                  <div className="flex w-2/12 items-center font-bold">
                    <div>
                      &nbsp;/&nbsp;
                      {formatUnit(costItem.taxonomyItem.sizes.amount?.value, t('projectTaxonomy.buildingPieces'))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className={classNames('flex-grow flex', costItem ? 'justify-between' : 'justify-end')}>
            {canDelete && (
              <Button variant="warning" onClick={() => setIsOpenDeleteModal(true)}>
                {t('common.delete')}
              </Button>
            )}
            <div className="flex">
              <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
                {t('common.cancel')}
              </Button>
              {!readOnly && (
                <Button variant="primary" formSubmit={true} innerRef={submitRef}>
                  {t('common.save')}
                </Button>
              )}
            </div>
          </div>
        </SlideOver.Controls>
      </Form>

      <Modal isOpen={isOpenDeleteModal} onClose={() => setIsOpenDeleteModal(false)} variant="small">
        <DealCostItemDeleteModal deal={deal} costItem={costItem} onClose={() => setIsOpenDeleteModal(false)} />
      </Modal>
    </>
  );
};
