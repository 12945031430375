import classNames from 'classnames';
import React, { KeyboardEvent, ChangeEvent, useId } from 'react';
import { isEmpty } from '@client/shared/utilities';
import { FormHelperText } from './FormHelperText';

interface TextSpellCheck {
  spellCheck?: boolean;
}


interface TextInputProps extends TextSpellCheck {
  label: string;
  placeHolder?: string;
  value: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  icon?: React.ReactNode;
  className?: string;
  inputType?: 'text' | 'password' | 'textarea';
  passDataCy?: string,
  disabled?: boolean;
  inputClassName?: string;
  maxLength?: number;
  name?: string;
  autoComplete?: string;
  showValidation?: boolean;
  isValidationValid?: boolean;
  helperText?: string;
  helperTextTruncate?: boolean
  iconRight?: React.ReactNode;
  onRightIconClick?: () => void;
  inputRef?: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: (event: KeyboardEvent) => void;
  helperTextClassName?: string
}

export const TextInput = ({
  autoComplete,
  className,
  disabled,
  icon,
  iconRight,
  inputClassName,
  inputType = 'text',
  passDataCy,
  isValidationValid,
  label,
  maxLength,
  name,
  onChange,
  onFocus,
  onBlur,
  placeHolder,
  showValidation,
  value,
  helperText,
  helperTextTruncate = true,
  onRightIconClick,
  spellCheck = false,
  inputRef,
  onKeyDown,
  helperTextClassName
}: TextInputProps) => {
  const inputId = useId();
  const [isFocused, setIsFocused] = React.useState(false);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleFocusChange = (focus: boolean) => {
    if (focus && onFocus) {
      onFocus();
    }
    if (!focus && onBlur) {
      onBlur();
    }
    setIsFocused(focus);
  };

  return (
    <div className={className}>
      <div
        className={classNames('w-full relative px-3 flex flex-row bg-white', {
          'shadow-[inset_0px_0px_0px_1px] shadow-red-500':
            showValidation && isValidationValid != null && !isValidationValid,
          'shadow-[inset_0px_0px_0px_1px] shadow-green-500': showValidation && isValidationValid,
          'h-14': inputType !== 'textarea',
          'min-h-[3.5rem] pb-3 h-full': inputType === 'textarea',
        })}
      >
        {icon && (
          <div className="h-14 flex items-center">
            <div className="h-5 w-5 flex items-center justify-center">{icon}</div>
          </div>
        )}
        <div
          className={classNames('relative flex-grow flex-shrink', {
            'ml-2': icon,
            'mx-1': !icon,
            'pt-5': inputType === 'textarea' && label,
            'flex items-center': !label
          })}
        >
          {inputType !== 'textarea' ? (
            <input
              spellCheck={spellCheck}
              id={inputId}
              className={classNames(
                'fake-mt block w-full text-lg appearance-none focus:outline-none bg-transparent font-medium transition-opacity truncate',
                {
                  'text-gray-800': !disabled,
                  'text-gray-500 cursor-not-allowed': disabled,
                  'opacity-0': placeHolder && isEmpty(value) && !isFocused,
                  'pt-5': label
                },
                inputClassName
              )}
              data-cy={passDataCy}
              value={value}
              type={inputType}
              placeholder={placeHolder}
              disabled={disabled}
              autoComplete={autoComplete}
              name={name}
              maxLength={maxLength}
              onChange={handleOnChange}
              onFocus={() => handleFocusChange(true)}
              onBlur={() => handleFocusChange(false)}
              ref={inputRef as React.RefObject<HTMLInputElement>}
              onKeyDown={onKeyDown}
            />
          ) : (
            <textarea
              id={inputId}
              spellCheck={spellCheck}
              className={classNames(
                'fake-mt block w-full text-lg appearance-none focus:outline-none bg-transparent font-medium',
                {
                  'text-gray-800': !disabled,
                  'text-gray-500 cursor-not-allowed': disabled,
                  'opacity-0': placeHolder && isEmpty(value) && !isFocused,
                  'h-full': inputType === 'textarea',
                },
                inputClassName
              )}
              value={value}
              data-cy={passDataCy}
              placeholder={placeHolder}
              disabled={disabled}
              autoComplete={autoComplete}
              name={name}
              maxLength={maxLength}
              onChange={handleOnChange}
              onFocus={() => handleFocusChange(true)}
              onBlur={() => handleFocusChange(false)}
              ref={inputRef as React.RefObject<HTMLTextAreaElement>}
              onKeyDown={onKeyDown}
            />
          )}
          {label && (
            <label
              htmlFor={inputId}
              className={classNames(
                'absolute top-0 left-0 right-0 text-lg duration-200 origin-0 text-gray-600 select-none transform truncate pr-4 text-left',
                {
                  'pt-3 mt-[3px]': isEmpty(value) && !isFocused,
                  'pt-5 -mt-px': !isEmpty(value) || isFocused,
                }
              )}
            >
              {label}
            </label>
          )}
        </div>
        {iconRight && (
          <div
            className={`w-20 flex items-center justify-center ${onRightIconClick ? 'cursor-pointer' : ''}`}
            onClick={onRightIconClick}
          >
            {iconRight}
          </div>
        )}
        <div
          className={classNames('absolute bottom-0 h-0.5 bg-black left-0 right-0 duration-300 transition-opacity', {
            'opacity-0': !isFocused,
          })}
        />
      </div>
      {helperText && (
        <FormHelperText text={helperText} error={!isValidationValid} className={classNames('w-full', helperTextClassName ?? 'bg-white')} truncate={helperTextTruncate} />
      )}
    </div>
  );
};
