import { PropsWithChildren } from 'react';
import cn from 'classnames';

interface WidgetContainerProps extends PropsWithChildren {
  className?: string;
}
export const WidgetContainer = ({ children, className }: WidgetContainerProps) => {
  return (
    <div className={cn('bg-white rounded-md shadow-md break-inside-avoid-column relative h-full', className)}>
      {children}
    </div>
  )
}
