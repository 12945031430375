import { BillIcon, NumberInput, PercentageIcon, PriceTagUSDIcon } from '@client/shared/toolkit';
import { AmountFactorInput } from '../AmountFactorInput';
import { RiskElementReadModel } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { UnitFactorInput } from '../UnitFactorInput';
import { produce } from 'immer';
import { useEffect, useState } from 'react';

interface RiskFormulaInputProps {
  value: RiskElementReadModel;
  onChange: (value: RiskElementReadModel) => void;
  disabled?: boolean;
  catalogElementId?: string;
  setIsLoading: (isLoading: boolean) => void;
}

export const RiskFormulaInput = ({ onChange, value, disabled, catalogElementId, setIsLoading }: RiskFormulaInputProps) => {
  const { t } = useTranslation();

  const [isLoadingAmountDataset, setIsLoadingAmountDataset] = useState(false);
  const [isLoadingUnitDataset, setIsLoadingUnitDataset] = useState(false);


  useEffect(() => {
    setIsLoading(isLoadingAmountDataset || isLoadingUnitDataset);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAmountDataset, isLoadingUnitDataset]);

  const onChangeDecorated = (model: RiskElementReadModel) => {
    onChange(
      produce(model, (draft) => {
        draft.total = calculateValue(draft);
      })
    );
  };

  const calculateValue = (model: RiskElementReadModel): number => {
    const percentage = model.amount.type === 'Static' && model.amount.staticValue?.unit === '%';
    let amount = 0;
    switch (model.amount.type) {
      case 'Static':
        amount = model.amount.staticValue?.value ?? 0;
        break;
      case 'Formula':
        amount = model.amount?.formulaValue?.value ?? 0;
        break;
    }

    let unitPrice = 0;

    switch (model.unitPrice.type) {
      case 'Static':
        unitPrice = model.unitPrice.staticValue?.value ?? 0;
        break;
      case 'Formula':
        unitPrice = model.unitPrice.formulaValue?.value ?? 0;
        break;
    }

    return (model.probability / 100) * amount * unitPrice * (percentage ? 0.01 : 1);
  };

  return (
    <div className="relative">
      <div className="bg-white">
        <div className="divide-gray-100 divide-y">
          <AmountFactorInput
            label={t('projectCalculate.formulaLabelAmount')}
            icon={<BillIcon className="fill-black" />}
            factor={value.amount}
            disabled={disabled}
            showFX
            catalogElementId={catalogElementId}
            catalogElementType='RiskElement'
            onChange={(amount) => onChangeDecorated({ ...value, amount })}
            setIsLoading={setIsLoadingAmountDataset}
          />
          <UnitFactorInput
            label={t('projectCalculate.formulaLabelUnitPrice')}
            icon={<PriceTagUSDIcon />}
            factor={value.unitPrice}
            disabled={disabled}
            onChange={(unitPrice) => onChangeDecorated({ ...value, unitPrice })}
            showFX
            catalogElementId={catalogElementId}
            catalogElementType='RiskElement'
            setIsLoading={setIsLoadingUnitDataset}
          />
          <NumberInput
            label={t('projectCalculate.riskElementLabelProbability')}
            value={value.probability ?? 0}
            onChange={(probability) => onChangeDecorated({ ...value, probability: Number(probability) })}
            icon={<PercentageIcon />}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};
