import { PlusIcon } from '../icons';
import React, { ReactElement, ReactNode } from 'react';

interface ComboSelectAdditionalOptionProps {
  label: string
  onClick?: () => void
  icon?: string | ReactNode
}
export const ComboSelectAdditionalOption = (props: ComboSelectAdditionalOptionProps) => {
  const {
    label,
    onClick,
    icon = <PlusIcon />
  } = props
  return (
    <div className="w-full h-full flex justify-between items-center" onClick={onClick}>
      <span className="italic text-gray-400 text-sm">{label}</span>
      {React.cloneElement(icon as ReactElement, { className: 'ml-auto h-3 w-3' })}
    </div>
  )
}
