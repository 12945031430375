import { useApiGetPasswordResetTokenQuery, useApiPostResetPasswordMutation } from '@client/shared/api';
import { XMarkIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, TextInput, FormField, Form, LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import {
  ResetPasswordFormValidationValues,
  ResetPasswordFormValidationSchema,
} from './ResetPasswordFormValidationSchema';
import { safeMutation } from '@client/shared/utilities';
import toast from 'react-hot-toast';
import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/solid';

export const ResetPasswordView = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { token } = useParams<{ token: string }>();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const { isError, isFetching } = useApiGetPasswordResetTokenQuery(
    {
      tokenId: token ?? '',
    },
    {
      skip: !token,
    },
  );

  if (isError) {
    toast.error(t('auth.resetPasswordTokenExpired'));
    navigate('/auth/login');
  }

  const [call, callState] = useApiPostResetPasswordMutation();
  const { isLoading } = callState;

  const handleSubmit = async (data: ResetPasswordFormValidationValues) => {
    if (token == null) return;
    try {
      await safeMutation(
        call,
        {
          body: {
            resetToken: token,
            password: data.password,
          },
        },
        isLoading,
      );
      navigate('/auth/password-reset-successful');
    } catch (e) {
      // left blank intentionally
      console.log(e);
    }
  };

  const defaultFormValues = { password: '', passwordConfirmation: '' };

  return (
    <Form<ResetPasswordFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={ResetPasswordFormValidationSchema}
      defaultValues={defaultFormValues}
    >
      {(isFetching || isLoading) && <LoadingIndicator mode="overlay-window" />}
      <div
        className="flex cursor-pointer"
        onClick={() => {
          navigate('/auth/login');
        }}
      >
        <XMarkIcon className="absolute -mt-6 ml-[23rem] w-8 h-8" fill="#6B7280" />
      </div>
      <div className="flex flex-row items-center mt-3 mb-6">
        <h1 className="text-4xl font-semibold text-primary">{t('auth.newPasswordTitle')}</h1>
      </div>
      <div className="divide-gray-100 divide-y">
        <FormField name="password">
          {(control) => (
            <div className="flex justify-between items-center bg-white" onBlur={() => setShowPassword(false)}>
              <TextInput
                label={t('common.password')}
                inputType={showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                helperTextTruncate={false}
                className="w-full"
                {...control}
              />
              <div
                className="w-5 cursor-pointer mx-2"
                onClick={() => setShowPassword(!showPassword)}
                data-cy="button-show-password"
              >
                {showPassword ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
              </div>
            </div>
          )}
        </FormField>
        <FormField name="passwordConfirmation">
          {(control) => (
            <div
              className="flex justify-between items-center bg-white"
              onBlur={() => setShowPasswordConfirmation(false)}
            >
              <TextInput
                label={t('auth.newPasswordConfirmPassword')}
                inputType={showPasswordConfirmation ? 'text' : 'password'}
                autoComplete="new-password"
                className="w-full"
                {...control}
              />
              <div
                className="w-5 cursor-pointer mx-2"
                onClick={() => setShowPassword(!showPasswordConfirmation)}
                data-cy="button-show-password"
              >
                {showPasswordConfirmation ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
              </div>
            </div>
          )}
        </FormField>
      </div>

      <div className="pt-36 flex place-content-end">
        <Button
          variant="secondary"
          onClick={() => {
            navigate('/auth/login');
          }}
        >
          {t('common.cancel')}
        </Button>
        <Button variant="primary" formSubmit={true} disabled={isLoading}>
          {t('auth.newPasswordAction')}
        </Button>
      </div>
    </Form>
  );
};
