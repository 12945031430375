import { PlotReadModel } from '@client/shared/api';

export const getPlotLabel = (item: PlotReadModel | undefined) => {
  if (item === undefined) return '';
  const plotType = item.type;

  switch (plotType) {
    case 'Plot':
      return 'projectTaxonomy.plotType.projectRoom';
    case 'SpecialItem':
      return 'projectTaxonomy.plotType.Plot';
    case 'Infrastructure':
      return 'projectTaxonomy.plotType.Infrastructure';
  }
};
