import { FormEvent, UIEvent, useCallback, useEffect, useRef } from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-excel-formula';
import { useTranslation } from 'react-i18next';

/**
 * https://css-tricks.com/creating-an-editable-textarea-that-supports-syntax-highlighted-code/
 * https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
 */

type FormulaInputProps = {
  value: string;
  onChange: (arg: string) => void;
  onUpdateOffset: (arg: number) => void;
  disabled?: boolean;
};

Prism.languages.insertBefore('excel-formula', 'string', {
  string: {
    pattern: /('[^']*')(\.\w+)?/g,
  },
});

const FormulaInput = (props: FormulaInputProps) => {
  const { t } = useTranslation();
  const { onChange } = props;

  const hRef = useRef<HTMLPreElement>(null);
  const highlightRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const growRef = useRef<HTMLDivElement>(null);

  const syncScroll = ({ top, left }: { top: number; left: number }) => {
    if (hRef.current) hRef.current.scrollLeft = left;
    if (hRef.current) hRef.current.scrollTop = top;
  };

  const handleTextAreaInput = (e: FormEvent<HTMLTextAreaElement>) => {
    if (growRef.current) growRef.current.dataset.replicatedValue = '' + e.currentTarget.value;
    updateHighlighting(e);
    const { target } = e;
    const { scrollTop, scrollLeft } = target as HTMLElement;
    syncScroll({ top: scrollTop, left: scrollLeft });
  };

  const update = useCallback(
    (value: string) => {
      if (value[value.length - 1] === '\n') {
        // If the last character is a newline character
        value += ' ';
      }
      value = value
        .replace(new RegExp('BGF', 'g'), t('project.bgfRoi'))
        .replace(new RegExp('NGF', 'g'), t('project.ngf'))
        .replace(new RegExp('MFG', 'g'), t('project.mf'))
        .replace(new RegExp('Amount', 'g'), t('project.amount'))
        .replace(new RegExp('GRZ', 'g'), t('project.grz'))
        .replace(new RegExp('GFZ', 'g'), t('project.gfz'));

      onChange(value);
      if (highlightRef.current) {
        highlightRef.current.innerHTML = value
          .replace(new RegExp(',', 'g'), '.')
          .replace(new RegExp('&', 'g'), '&')
          .replace(new RegExp('<', 'g'), '<'); /* Global RegExp */
      }
    },
    [onChange, t],
  );

  const updateHighlighting = (e: FormEvent<HTMLTextAreaElement>) => {
    const { target } = e;
    const { value } = target as HTMLTextAreaElement;
    update(value);
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.selectionStart = textAreaRef.current.value.length - 1;
    }
  }, []);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.value = props.value;
    }
    update(props.value);
    Prism.highlightAll();
  }, [props.value, textAreaRef, update]);

  const handleMouseMove = (event: UIEvent<HTMLTextAreaElement>) => {
    const { target } = event;
    const { selectionStart } = target as HTMLInputElement;
    if (selectionStart) {
      props.onUpdateOffset(selectionStart);
    }
  };

  const handleScroll = (event: UIEvent<HTMLTextAreaElement>) => {
    const { target } = event;
    const { scrollTop, scrollLeft } = target as HTMLElement;
    syncScroll({ top: scrollTop, left: scrollLeft });
  };

  return (
    <div className="grid grow-wrap w-full h-full" ref={growRef}>
      {!props.disabled && (
        <textarea
          id="editing"
          onInput={(e) => handleTextAreaInput(e)}
          onMouseMove={handleMouseMove}
          onScroll={handleScroll}
          spellCheck="false"
          ref={textAreaRef}
          style={{
            fontFamily: 'Roboto',
          }}
        />
      )}
      <pre id="highlighting" ref={hRef} className="bg-white ">
        <code
          className="language-excel-formula"
          id="highlighting-content"
          style={{
            fontFamily: 'Roboto',
          }}
          ref={highlightRef}
        />
      </pre>
    </div>
  );
};

export default FormulaInput;
