import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { PlotReadModel, useApiPostUpdateCalculationModelKpisMutation } from '@client/shared/api';
import {
  NumberInput,
  SlideOver,
  Button,
  Form,
  FormField,
  SlideOverOnCloseProps,
  YardSaleIcon, GrzIcon, LoadingIndicator,
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { PlotHeaderFormValidationValues, PlotHeaderFormValidationSchema } from './PlotHeaderFormValidationSchema';
import { useRef } from 'react';

interface PlotHeaderSlideOverProps extends SlideOverOnCloseProps {
  item?: PlotReadModel;
  isReadOnly: boolean;
}

export const PlotHeaderSlideOver = ({ item, isReadOnly, onClose }: PlotHeaderSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const loadedProjectId = useLoadedProjectId();

  const loadedVariantId = useLoadedVariantId();
  const [updatePlotKpis, { isLoading }] = useApiPostUpdateCalculationModelKpisMutation();

  const handleSubmit = async (data: PlotHeaderFormValidationValues) => {
    if (loadedVariantId == null) return;
    try {
      await safeMutation(
        updatePlotKpis,
        {
          projectId: loadedProjectId ?? 'unset',
          calculationModelId: loadedVariantId,
          body: {
            gfz: data.gfz ?? 0,
            grz: data.grz ?? 0,
          },
        },
        isLoading
      );

      onClose(true);
    } catch (error) {
      console.log(error);
    }
  };

  const defaultFormValues = {
    gfz: item?.calculationModelGfz ?? 0,
    grz: item?.calculationModelGrz ?? 0,
  };

  return (
    <>
      {isLoading && (
        <LoadingIndicator text={t('projectTaxonomy.updatingTaxonomyLoadingIndicator')} mode="overlay-window" />
      )}
      <SlideOver.Header
        onClose={onClose}
        title={item?.plotName ?? ''}
        subTitle={t('projectTaxonomy.plotSlideOverHeaderSubtitle')}
        backgroundClassName="bg-gray-900"
      />

      <Form<PlotHeaderFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={PlotHeaderFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
      >
        <SlideOver.Content>
          <div className="overflow-y-auto">
            <div className="m-8 bg-white">
              <div className="divide-gray-100 divide-y">
                <FormField name="gfz">
                  {(control) => (
                    <NumberInput
                      label={t('projectTaxonomy.plotGfz')}
                      icon={<YardSaleIcon />}
                      disabled={isReadOnly}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="grz">
                  {(control) => (
                    <NumberInput
                      label={t('projectTaxonomy.plotGrz')}
                      icon={<GrzIcon />}
                      disabled={isReadOnly}
                      {...control}
                    />
                  )}
                </FormField>
              </div>
            </div>
          </div>
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className="flex">
            <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
              {t('common.cancel')}
            </Button>
            {!isReadOnly && (
              <Button variant="primary" formSubmit={true} innerRef={submitRef}>
                {t('common.save')}
              </Button>
            )}
          </div>
        </SlideOver.Controls>
      </Form>
    </>
  );
};
