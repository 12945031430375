
/**
 * Describes just the expected part of the Mutation Triggers
 */
type TMutation<T, R> = (arg: T) => {
  unwrap: () => Promise<R>
}

/**
 * Does only execute mutations if they are not loading
 */
export const safeMutation = <T, R>(
  mutation: TMutation<T, R>,
  arg: T,
  isLoading: boolean
) : Promise<R> | undefined => {

  if (isLoading) return;
  return mutation(arg).unwrap();
}
