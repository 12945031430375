import {
  CompanyReadModel,
  CompanyBranchReadModel,
  CompanyPersonReadModel,
  CompanyAssignmentReadModel,
} from '@client/shared/api';
import {
  BgImageCard,
  Button,
  CardsList,
  CompanyCard,
  DecoratedCard, DecoratedCardAddButton,
  DecoratedCardContent,
  DecoratedCardHeader,
  Modal, PencilIcon,
  SlideOver,
  SlideOverList,
  SlideOverListItemValueProps,
  SlideOverTabOptions, SlideOverTitle,
  SlideOverWithTabs,
  TrashIcon, UploadBadge,
  UserIcon,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { EditPartnerBranchSlideOver } from './EditPartnerBranchSlideOver';
import { ContactDetailsSlideOver } from './ContactDetailsSlideOver';
import { ContactDeleteModal } from './ContactDeleteModal';
import cn from 'classnames';
import { PartnerCompanyEditSlideOver } from './PartnerCompanyEditSlideOver';
import { getProjectThumbnailByCoverId, getTenantTrustBadgeByStatus, getTenantTrustBadgeLabelByStatus, } from '@client/project/shared';
import {
  PartnerCompaniesProjectEditSlideOver
} from './PartnerCompaniesProjectEditSlideOver';

interface PartnerCompanyDetailsSlideOverProps {
  company?: CompanyReadModel | undefined;
  onClose: () => void;
}

export const PartnerCompanyDetailsSlideOver = ({ company, onClose }: PartnerCompanyDetailsSlideOverProps) => {
  const { t } = useTranslation();

  const [isAdd, setIsAdd] = useState<boolean>(false);

  const [showEditBranch, setShowEditBranch] = useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState<CompanyBranchReadModel | undefined>();

  const [showEditContact, setShowEditContact] = useState<boolean>(false);
  const [showDeleteContact, setShowDeleteContact] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<CompanyPersonReadModel | undefined>();

  const [showEditCompany, setShowEditCompany] = useState(false);

  const [showEditProject, setShowEditProject] = useState(false);
  const [selectedProject, setSelectedProject] = useState<CompanyAssignmentReadModel | null>(null);

  const hasMainBranch = company?.branches.find((branch) => branch.isMainBranch === true);

  const onEditBranch = (branch: CompanyBranchReadModel) => {
    setSelectedBranch(branch);
    setShowEditBranch(true);
    setIsAdd(false);
  };

  const onAddBranch = () => {
    setShowEditBranch(true);
    setIsAdd(true);
  };

  const onEditContact = (contact: CompanyPersonReadModel) => {
    setSelectedContact(contact);
    setShowEditContact(true);
    setIsAdd(false);
  };

  const onAddContact = () => {
    setShowEditContact(true);
    setIsAdd(true);
  };

  const onDeleteContact = (contact: CompanyPersonReadModel) => {
    setSelectedContact(contact);
    setShowDeleteContact(true);
  };

  useEffect(() => {
    if (company?.branches && selectedBranch) {
      const foundBranch = company.branches.find((branch) => branch.id === selectedBranch.id)
      if (foundBranch) {
        setSelectedBranch(foundBranch)

        if (selectedProject && foundBranch.projectAssignments.length) {
          const foundProject = foundBranch.projectAssignments.find((project) => project.project.projectId === selectedProject.project.projectId)
          if (foundProject) {
            setSelectedProject(foundProject)
          }
        }
      }
    }
    if (company?.persons && selectedContact) {
      const foundContact = company.persons.find((person) => person.id === selectedContact.id)
      if (foundContact) {
        setSelectedContact(foundContact)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const companyPersons = useMemo(() => {
    const persons: SlideOverListItemValueProps[] = [];
    if (company?.persons.length) {
      const sortedPersons = [...company.persons].sort((a, b) => a.fullname.localeCompare(b.fullname, undefined, {sensitivity: 'case'}));
      sortedPersons.forEach((contact) => {
        persons.push({
          icon: <UserIcon />,
          id: contact.id,
          leftTop: contact.branches[0]?.branchName,
          leftCenter: contact.fullname,
          onClick: () => {
            onEditContact(contact);
          },
          leftBottom: contact.function ?? '',
          contextMenuItems: [
            {
              label: t('common.edit'),
              icon: <PencilIcon />,
              onClick: () => {
                onEditContact(contact);
              },
            },
            {
              label: t('common.delete'),
              icon: <TrashIcon />,
              onClick: () => {
                onDeleteContact(contact);
              },
            },
          ],
        });
      });
    }
    return persons;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.persons]);

  const contactPartner = false; // TODO

  const companyBranchCards = useMemo(() => {
    const cards: ReactNode[] = [];
    if (company?.branches.length) {
      const sortedBranches = [...company.branches].filter((branch) => branch.isMainBranch === false).sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'case'}));
      sortedBranches.forEach((branch) => {
        cards.push(
          <CompanyCard address={branch.address ?? undefined} name={branch.name} onEdit={() => onEditBranch(branch)} />,
        );
      });
    }
    return cards;
  }, [company?.branches]);

  const companyBranchProjectCards = useMemo(() => {
    const cards: ReactNode[] = [];
    company?.branches.forEach((branch) => {
      const projects = [...branch.projectAssignments].sort((a, b) => a.project.name.localeCompare(b.project.name, undefined, {sensitivity: 'case'}));
      projects.forEach((project) => {
        const coverImage = getProjectThumbnailByCoverId(
          project.project.projectId,
          project.project.name,
          'Small',
          project.project.projectCoverId
        );
        cards.push(
          <BgImageCard
            linkText={t('common.open')}
            onClick={() => {
              setSelectedProject(project);
              setSelectedBranch(branch);
              setShowEditProject(true);
            }}
            preTitle={branch.name}
            subTitle={project.project.city}
            thumbnail={coverImage}
            thumbnailAlt={project.project.name}
            title={project.project.name}
            icon={
              project.tenantTrust ? (
                <UploadBadge
                  variant={getTenantTrustBadgeByStatus(
                    project.tenantTrust.trustState,
                    project.tenantTrust.trustIsActive,
                  )}
                  tooltip={getTenantTrustBadgeLabelByStatus(
                    project.tenantTrust.trustState,
                    project.tenantTrust.trustIsActive,
                  )}
                />
              ) : undefined
            }
          />,
        );
      });
    });
    return cards;
  }, [company?.branches, t]);

  const logo: string | undefined = useMemo(() => {
    return company?.logoFileId ? `/api/companies/${company.id}/logo/${company.logoFileId}` : undefined;
  }, [company?.logoFileId, company?.id]);

  const tabOptions: SlideOverTabOptions[] = [
    {
      header: t('app.companiesContact'),
      name: 'contact',
      // TODO move this into a new component if more tabs get added!
      panel: (
        <>
          {/* ----- CONTACT DETAILS AND CONTACT PARTNER ------ */}
          <div className="flex gap-9">
            <DecoratedCard
              shadowVariant="normal"
              className={cn('flex-none', {
                'w-7/12': contactPartner,
                'w-full': !contactPartner,
              })}
            >
              <DecoratedCardHeader
                showActionButton
                onActionClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowEditCompany(true);
                }}
                icon={<PencilIcon className="hover:opacity-70 cursor-pointer w-5" />}
              >
                {t('app.companiesContactDetails')}
              </DecoratedCardHeader>
              <DecoratedCardContent className="w-full h-full p-5">
                <div className="flex">
                  <CompanyCard
                    address={hasMainBranch?.address ?? undefined}
                    className=""
                    contactTypes={[]} // TODO
                    logo={logo}
                    name={company?.name ?? ''}
                    // onEdit={() => {}} // TODO
                    size="large"
                    label={t('app.companiesName')}
                    box={false}
                  />
                </div>
              </DecoratedCardContent>
            </DecoratedCard>
            {/* ----- CONTACT PARTNER ------ */}
            {contactPartner && (
              <CompanyCard
                className=""
                contactTypes={['Mieter']}
                email="gabriella.hailbert@brainlab.com"
                firstName="Mrs. Gabriella"
                name="Hailbert"
                phone="+49 (171) 231 123 12"
                size="large"
                subtitle="Assistenz"
              />
            )}
          </div>
          {/* ----- PROJECTS ------ */}
          <DecoratedCard className="mt-6" shadowVariant="normal">
            <DecoratedCardHeader>{t('app.projects')}</DecoratedCardHeader>
            <DecoratedCardContent className="w-full h-full p-5">
              <CardsList cards={companyBranchProjectCards} className="w-full" />
            </DecoratedCardContent>
          </DecoratedCard>

          {/* ----- BRANCHES ------ */}
          <DecoratedCard className="mt-10 relative" shadowVariant="normal">
            <DecoratedCardHeader>{t('app.masterDataBranches')}</DecoratedCardHeader>
            <DecoratedCardContent className="w-full h-full p-5">
              {hasMainBranch && (
                <>
                  <SlideOverTitle title={t('app.masterDataMainBranch')} marginTop={false} />
                  <CompanyCard
                    address={hasMainBranch.address ?? undefined}
                    name={hasMainBranch.name}
                    onEdit={() => onEditBranch(hasMainBranch)}
                  />
                </>
              )}
              <SlideOverTitle title={hasMainBranch ? t('app.masterDataOtherBranches') : t('app.masterDataBranches')} />
              <CardsList cards={companyBranchCards} className="w-full" />
              <DecoratedCardAddButton onClick={onAddBranch} />
            </DecoratedCardContent>
          </DecoratedCard>

          {/* ----- EMPLOYEES ------ */}
          <DecoratedCard className="mt-6" shadowVariant="normal" padding="pt-1.5">
            <DecoratedCardHeader>{t('app.masterDataEmployees')}</DecoratedCardHeader>
            <DecoratedCardContent className="w-full h-full p-5 bg-gray-100 relative">
              <SlideOverList
                className="gap-1"
                items={companyPersons}
                noItemsMessage={t('app.companiesNoEmployeesMessage')}
                rounded={false}
                showShadow={false}
                margin={false}
                customAddButton={<div />}
              />
              <DecoratedCardAddButton onClick={onAddContact} />
            </DecoratedCardContent>
          </DecoratedCard>
        </>
      ),
    },
  ];

  return (
    <>
      <SlideOverWithTabs
        tabOptions={tabOptions}
        title={company?.name ?? ''}
        subtitle={t('app.masterDataPartnerCompany')}
        onClose={onClose}
      >
        <SlideOver.Controls className="w-full flex justify-end">
          <Button variant="secondary" className="mr-2" onClick={() => onClose()}>
            {t('common.close')}
          </Button>
        </SlideOver.Controls>
      </SlideOverWithTabs>

      <SlideOver
        isOpen={showEditBranch}
        onClose={() => setShowEditBranch(false)}
        onAfterLeave={() => setSelectedBranch(undefined)}
      >
        <EditPartnerBranchSlideOver
          isAddMode={isAdd}
          branch={selectedBranch}
          company={company}
          onClose={() => setShowEditBranch(false)}
        />
      </SlideOver>
      <SlideOver
        isOpen={showEditContact}
        onClose={() => setShowEditContact(false)}
        onAfterLeave={() => setSelectedContact(undefined)}
      >
        <ContactDetailsSlideOver
          isAdd={isAdd}
          company={company}
          contact={selectedContact}
          onClose={() => setShowEditContact(false)}
        />
      </SlideOver>
      <Modal isOpen={showDeleteContact} onClose={() => setShowDeleteContact(false)}>
        <ContactDeleteModal contact={selectedContact} onClose={() => setShowDeleteContact(false)} />
      </Modal>
      <SlideOver isOpen={showEditCompany} onClose={() => setShowEditCompany(false)}>
        <PartnerCompanyEditSlideOver
          company={company}
          onClose={(close) => {
            setShowEditCompany(false);
            if (close) {
              onClose();
            }
          }}
        />
      </SlideOver>
      <PartnerCompaniesProjectEditSlideOver
        isOpen={showEditProject}
        onClose={() => setShowEditProject(false)}
        company={company}
        project={selectedProject}
        branch={selectedBranch}
        resetProject={() => {
          setSelectedProject(null);
          setSelectedBranch(undefined);
        }}
      />
    </>
  );
};
