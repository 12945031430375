import { useApiDeleteTenantAvatarMutation } from '@client/shared/api';
import { ConfirmModal, HintBox, LoadingIndicator, ModalOnCloseProps } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

export const RemoveImageModal = ({ onClose }: ModalOnCloseProps) => {
  const { t } = useTranslation();
  const [postImage, { isLoading }] = useApiDeleteTenantAvatarMutation();

  const handleSubmit = async () => {
    try {
      await safeMutation(postImage, undefined, isLoading);
      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ConfirmModal
      title={t('common.delete')}
      onConfirm={handleSubmit}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isLoading}
    >
      {isLoading && (
        <LoadingIndicator text={t('app.tenantRemoveImageLoadingIndicator')} mode="overlay" />
      )}
      <HintBox hintType="warning">{t('app.tenantRemoveImage')}</HintBox>
    </ConfirmModal>
  );
};
