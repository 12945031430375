import { useLoadedProject } from './useLoadedProject';

export const useLoadedProjectUnitSystem = () => {
  const loadedProject = useLoadedProject();
  return loadedProject?.data?.project.payload.unitSystem ?? 'Metric';
};

export const useLoadedProjectUnitSystemSymbol = () => {
  const unitSystem = useLoadedProjectUnitSystem();
  return unitSystem === 'Imperial' ? 'ft²' : 'm²';
}