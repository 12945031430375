import { AuthGroupReadModel, useApiGetAuthGroupsQuery } from '@client/shared/api';
import { LoadingIndicator, SettingsAddButton, SlideOver } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { GroupDetailsSlideOver } from './GroupDetailsSlideOver';
import { GroupRow } from './GroupRow';
import { SettingsHeader } from '../../SettingsHeader';

export const Groups = () => {
  const { t } = useTranslation();

  const { data: groupData, isFetching } = useApiGetAuthGroupsQuery();

  const [searchText, setSearchText] = useState<string>('');
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<AuthGroupReadModel | undefined>();

  const groups: AuthGroupReadModel[] = groupData ?? [];

  const closeSlideOver = () => {
    setShowAdd(false);
    setShowDetails(false);
  };

  const handleEdit = (group: AuthGroupReadModel) => {
    setSelectedGroup(group);
    setShowDetails(true);
  };

  const handleAdd = () => {
    setShowAdd(true);
    setShowDetails(true);
  };

  return (
    <div className="w-full">
      <SettingsHeader searchText={searchText} setSearchText={setSearchText}>
        {t('auth.groupPageTitle')}
      </SettingsHeader>
      <div className="relative h-full">
        <div className="flex w-full text-xs text-gray-500 pl-12 mt-4 mb-2">
          <div className="w-5/12">{t('auth.groupGroupColumnTitle')}</div>
          <div className="w-5/12">{t('auth.groupUsersColumnTitle')}</div>
          <div className="w-2/12">{t('auth.groupProjectsColumnTitle')}</div>
        </div>

        {isFetching === true && <LoadingIndicator text={t('auth.groupLoadingIndicator')} />}

        <div className="border-t border-gray-200">
          {groups
            .filter((x) => x.name.toLowerCase().includes(searchText?.toLowerCase() ?? ''))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((group, idx) => (
              <div key={idx} className="border-b border-gray-200">
                <GroupRow group={group} handleEdit={handleEdit} />
              </div>
            ))}
        </div>
        <SettingsAddButton onClick={handleAdd} />
      </div>

      <SlideOver isOpen={showDetails} variant='large' onClose={() => closeSlideOver()} onAfterLeave={() => setSelectedGroup(undefined)}>
        <GroupDetailsSlideOver isAddMode={showAdd} group={selectedGroup} onClose={() => closeSlideOver()} />
      </SlideOver>
    </div>
  );
};
