import { InvoiceCoverSheets } from '@client/project/shared';
import { SettingsHeader } from './SettingsHeader';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useApiGetTenantInvoiceCalculationSchemesQuery
} from '@client/shared/api';
import { LoadingIndicator } from '@client/shared/toolkit';

export const SettingsInvoiceCoverSheets = () => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState<string | undefined>();
  const { data: sheets, isFetching } = useApiGetTenantInvoiceCalculationSchemesQuery();

  return (
    <div className="w-full">
      <SettingsHeader searchText={searchText} setSearchText={setSearchText}>
        {t('app.settingsInvoiceCoverSheets')}
      </SettingsHeader>
      <div className="relative h-full">
        {isFetching && <LoadingIndicator text={t('app.settingsLoadingInvoiceCoverSheets')} />}
        <InvoiceCoverSheets
          searchText={searchText}
          sheets={sheets}
          type="tenant"
        />
      </div>
    </div>
  )
}
