import { DealType, DealReadModel } from '@client/shared/api';
import { DealRow } from '../Deals';
import { useTranslation } from 'react-i18next';
import { DecoratedCard, TaxonomyBuildingComplexIcon } from '@client/shared/toolkit';

interface DealTypeGroupProps {
  type: DealType;
  items: DealReadModel[];
  onSelect: (deal: DealReadModel) => void;
  isReadOnly:boolean;
}

export const DealTypeGroup = ({ items, onSelect, type, isReadOnly }: DealTypeGroupProps) => {
  const { t } = useTranslation();

  const elements = items.filter((x) => x.type === type);

  return (
    <DecoratedCard>
      <DecoratedCard.Header showActionButton={false}>
        <TaxonomyBuildingComplexIcon className="w-6" />
        <span>
          {type === 'Rent' && t('projectRent.dealTypeRentalContract')}
          {type === 'Sell' && t('projectRent.dealTypeSaleContract')}
        </span>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="p-1 w-full h-full flex items-center justify-center space-y-1">
        {elements.map((i) => (
          <DealRow key={i.id} item={i} onSelect={onSelect} isReadOnly={isReadOnly} />
        ))}
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};
