import { CalculationModelMetadata } from '@client/shared/api';
import { PlanCostsIcon } from '@client/shared/toolkit';
import { formatUnit } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

interface RoiCardProps {
  project?: CalculationModelMetadata | undefined;
}

export const RoiCard = ({ project }: RoiCardProps) => {
  const { t } = useTranslation();

  return (
    <div className="rounded shadow p-4 w-full h-full flex flex-row bg-white ">
      <div className="w-full flex flex-row">
        <div className="flex justify-center items-center mr-4">
          <PlanCostsIcon className="h-8 w-8" />
        </div>
        <div className="w-full">
          <div className="text-xxs font-bold text-slate-500">{t('projectTaxonomy.dashboardRoi')}</div>
          <div className="h-7 text-base font-extrabold">{project?.roi && formatUnit(project?.roi.value || 0, '%')}</div>
        </div>
      </div>
      <div className="w-2/6 text-right justify-end">
        <div className="text-xxs font-bold text-slate-500">{t('projectTaxonomy.dashboardIrr')}</div>
        <div className="h-7 text-xs font-extrabold flex items-end justify-end">{project?.calculatedIrr && formatUnit(project?.calculatedIrr.value || 0, '%')}</div>
      </div>
    </div>
  );
};
