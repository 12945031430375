import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { countryCodes } from '@client/shared/utilities';
import { Badge } from './Badges';
import { ContactInfos } from './ContactInfos';
import { PencilIcon } from '../icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export interface CompanyCard {
  className?: string
  onEdit?: () => void
  firstName?: string
  name: string
  contactName?: string
  address?: {
    supplement?: string | null;
    street?: string | null;
    number?: string | null;
    postalCode?: string | null;
    city?: string | null;
    country?: string | null;
  }
  size?: 'default' | 'large'
  subtitle?: string
  label?: string
  contactTypes?: string[]
  logo?: string
  box?: boolean
  email?: string
  phone?: string
}
export const CompanyCard = (props: CompanyCard) => {
  const {
    className,
    onEdit,
    firstName,
    name,
    contactName,
    address,
    size = 'default',
    subtitle,
    label,
    contactTypes,
    box = true,
    email,
    phone,
    logo
  } = props
  const { t } = useTranslation()
  const isEmptyAddress = address ? Object.values(address).every(x => x === null || x === '') : true
  return (
    <div
      className={cn('relative w-full overflow-hidden',
        {
          'drop-shadow rounded bg-white p-5 max-w-[404px]': box
        },
        className
      )}
    >
      {(onEdit || logo) && (
        <div className="right-5 absolute">
          {onEdit && (
            <PencilIcon
              className="hover:opacity-70 cursor-pointer w-5"
              onClick={onEdit}
            />
          )}
          {logo && (
            <LazyLoadImage
              width={44}
              height={44}
              alt="Logo"
              src={logo}
              className="w-11 h-auto aspect-square object-cover border border-gray-200 rounded"
            />
          )}
        </div>
      )}
      {size === 'large' ? (
        <div className="leading-9 flex-col flex">
          {firstName && (<span className="text-4xl leading-9">{firstName}</span>)}
          <span className="text-4xl leading-9 font-bold">{name}</span>
          {contactName && <span className="text-lg">{contactName}</span>}
          {label && <span className="text-xs leading-6 text-gray-500">{label}</span>}
          {subtitle && <span className="text-sm font-black">{subtitle}</span>}
        </div>
      ) : (
        <div className="leading-6 flex-col flex">
          <span className="text-[22px] font-bold">{name}</span>
          {contactName && <span className="text-lg">{contactName}</span>}
          {label && <span className="text-xs leading-6 text-gray-500">{label}</span>}
          {subtitle && <span className="text-sm font-black">{subtitle}</span>}
        </div>
      )}
      {contactTypes && contactTypes.length > 0 && (
        <div className="mt-4">
          <span className="text-xs text-gray-500">{t('common.contactType')}</span>
          <div className="flex gap-1 mt-1">
            {contactTypes.map((contactType) => (
              <Badge
                variant="success"
                className=""
                text={contactType}
              />
            ))}
          </div>
        </div>
      )}
      {!isEmptyAddress && (
        <div
          className={cn('flex flex-col w-full justify-between',
            contactTypes && contactTypes.length ? 'mt-6' : 'mt-8'
          )}
        >
          <span className="text-gray-500 text-[12px]">{t('common.address')}</span>
          <div className="font-bold text-lg leading-6">
            {address?.supplement && (
              <>
                {address?.supplement} <br />
              </>
            )}
            {(address?.street || address?.number) && (
              <>
                {address?.street} {address?.number} <br />
              </>
            )}
            {(address?.postalCode || address?.city) && (
              <>
                {address?.postalCode} {address?.city} <br />
              </>
            )}
            {countryCodes.getCountryName(address?.country ?? '')}
          </div>
        </div>
      )}
      {(email || phone) && (
        <ContactInfos
          email={email}
          phone={phone}
          className="w-full mt-6"
        />
      )}
    </div>
  )
};
