export const getPercentageBetweenDates = (startDate?: Date, endDate?: Date, currentDate?: Date) => {
  if (!startDate || !endDate || !currentDate) {
    return undefined;
  }

  const start = startDate.valueOf();
  const end = endDate.valueOf();
  const current = currentDate.valueOf();

  return Math.min(Math.max(((current - start) / (end - start)) * 100, 0), 100);
};
