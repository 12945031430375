import { HintBox, ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { useApiDeleteCostElementMutation } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import { useLoadedProjectId } from '@client/project/store';

interface CostElementDeleteModalProps extends ModalOnCloseProps {
  elementId?: string;
  variantId?: string;
  setIsDeleting?: (deleting: boolean) => void;
}

export const CostElementDeleteModal = ({ elementId, variantId, onClose, setIsDeleting }: CostElementDeleteModalProps) => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();
  const [call, { isLoading: isDeleting }] = useApiDeleteCostElementMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    if (elementId && variantId) {
      if (setIsDeleting) {
        setIsDeleting(true)
      }
      try {
        await safeMutation(
          call,
          {
            id: elementId,
            projectId: projectId ?? 'unset',
            calculationModelId: variantId,
          },
          isDeleting
        );

        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  return (
    <ConfirmModal
      title={t('projectCalculate.costElementDeleteTitle')}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isDeleting}
    >
      {isDeleting && <LoadingIndicator text={t('projectCalculate.elementDeleteLoading') ?? ''} mode="overlay" />}
      <HintBox hintType="warning">{t('projectCalculate.costElementDeleteHint')}</HintBox>
    </ConfirmModal>
  );
};
