import { useLoadedProjectId } from "@client/project/store";
import { DealType } from "@client/shared/api";
import { useValidateProjectPermission } from "@client/shared/permissions";

export const useCanDeleteDeal = (type: DealType) => {
    const loadedProjectId = useLoadedProjectId() ?? '';
    const canDeleteRent = useValidateProjectPermission(['RENT_DELETE'], loadedProjectId);
    const canDeleteSell = useValidateProjectPermission(['SELL_DELETE'], loadedProjectId);
    return type === 'Rent' ? canDeleteRent : canDeleteSell;
};

export const useCanWriteDeal = (type: DealType) => {
    const loadedProjectId = useLoadedProjectId() ?? '';
    const canWriteRent = useValidateProjectPermission(['RENT_WRITE'], loadedProjectId);
    const canWriteSell = useValidateProjectPermission(['SELL_WRITE'], loadedProjectId);
    return type === 'Rent' ? canWriteRent : canWriteSell;
};

export const useCanReopenDeal = (type: DealType) => {
    const loadedProjectId = useLoadedProjectId() ?? '';
    const canReopenRent = useValidateProjectPermission(['RENT_REOPEN'], loadedProjectId);
    const canReopenSell = useValidateProjectPermission(['SELL_REOPEN'], loadedProjectId);
    return type === 'Rent' ? canReopenRent : canReopenSell;
};
