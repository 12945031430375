import { Square2StackIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { OpenModal, ProjectCopyModal, ProjectDeleteModal, ProjectMoveModal } from '.';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ShortProjectGroupReadModel, ShortProjectReadModel } from '@client/shared/api';
import { getProjectThumbnail } from '@client/project/shared';
import {
  ROUTES_CONFIG,
  useValidateTenantPermission
} from '@client/shared/permissions';
import {
  ContextMenuItem,
  DashboardCard,
  Modal, MoveNodeUpIcon,
  PencilIcon,
  TextHighlighter,
  TrashIcon,
} from '@client/shared/toolkit';

interface ProjectOverviewCardProps {
  project: ShortProjectReadModel;
  showCheckbox: boolean;
  isToggleable?: boolean;
  isProjectSelected: boolean;
  onSelectProject?: (arg: string) => void;
  enableHover?: boolean;
  searchText?: string;
  multiProjects?: ShortProjectGroupReadModel[]
}

export const ProjectOverviewCard = ({ project, multiProjects, ...props }: ProjectOverviewCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const thumbnail = getProjectThumbnail('Small', project, false);
  const [openModal, setOpenModal] = useState<OpenModal>('None');

  const contextItems: ContextMenuItem[] = [
    {
      label: t('project.EditProjectSetting'),
      subtitle: t('common.editEntity', { entity: t('project.projectSettingsAction') }),
      icon: <PencilIcon />,
      onClick: () => {
        navigate(ROUTES_CONFIG.PROJECTS_PROJECT_SETTINGS.path.replace(':id', project.id ?? ''));
      }
    },
    {
      label: t('common.copy'),
      subtitle: t('common.copyEntity', { entity: t('app.project') }),
      isDisabled: !useValidateTenantPermission(['TENANT_PROJECT_COPY']),
      icon: <Square2StackIcon />,
      onClick: () => setOpenModal('Copy'),
    },
    {
      label: t('project.Linking'),
      subtitle: t('project.moveProjectTitle'),
      icon: <MoveNodeUpIcon />,
      onClick: () => setOpenModal('Move'),
    },
    {
      label: t('common.delete'),
      subtitle: t('common.deleteEntity', { entity: t('app.project') }),
      isDisabled: !useValidateTenantPermission(['TENANT_PROJECT_DELETE']),
      icon: <TrashIcon />,
      onClick: () => setOpenModal('Delete'),
    },
  ]

  const handleCloseModal = () => {
    setOpenModal('None');
  };

  const onProjectCardClick = () => {
    navigate(ROUTES_CONFIG.PROJECT_DASHBOARD.path.replace(':id', project?.id));
  };

  return (
    <>
      <DashboardCard
        showStack={false}
        thumbnail={thumbnail}
        thumbnailAlt={project.name}
        showCheckbox={props.showCheckbox}
        isSelected={props.isProjectSelected}
        toggleSelect={() => props.onSelectProject && props.onSelectProject(project.id)}
        title={
          props.searchText ? (
            <TextHighlighter
              text={project.name}
              highlighted={props.searchText
                .split(',')
                .filter((x) => x !== '')
                .map((x) => x.trim())}
            />
          ) : (
            project.name
          )
        }
        subTitle={
          project.city && props.searchText ? (
            <TextHighlighter
              text={project.city}
              highlighted={props.searchText
                .split(',')
                .filter((x) => x !== '')
                .map((x) => x.trim())}
            />
          ) : (
            project.city ?? '-'
          )
        }
        contextItems={contextItems}
        onClick={onProjectCardClick}
        passDataCy={`project-${project.name}`}
      />

      <Modal isOpen={openModal === 'Copy'} onClose={handleCloseModal}>
        <ProjectCopyModal onClose={handleCloseModal} selectedProject={project} />
      </Modal>

      <Modal isOpen={openModal === 'Delete'} onClose={handleCloseModal}>
        <ProjectDeleteModal project={project} onClose={handleCloseModal} />
      </Modal>

      <Modal isOpen={openModal === 'Move'} onClose={handleCloseModal}>
        <ProjectMoveModal groupId={project.groupId} selectedProjectIds={[project.id]} multiProjects={multiProjects} onClose={handleCloseModal} />
      </Modal>
    </>
  );
};
