// TODO: I'm guessing this some kind of deep copy..
// this really needs a better comment, or some tests.
// Object Equality is a hard topic and always comes with restrictions

// compare objects, return true if they are equal
export const objectsEqual = (a: object, b: object) => {
  const s = (o: object) =>
    Object.entries(o)
      .sort()
      .map((i) => {
        if (i[1] instanceof Object) i[1] = s(i[1]);
        return i;
      });

  return JSON.stringify(s(a)) === JSON.stringify(s(b));
};
