import { ReactNode } from 'react';

export interface SlideOverTotalSectionProps {
  label: string
  value: string | ReactNode
}
export const SlideOverTotalSection = (props: SlideOverTotalSectionProps) => {
  const {
    label,
    value
  } = props
  return (
    <div className="pb-2 mx-8 mb-2 mt-4">
      <div className="border-t-2 flex items-end justify-end w-full gap-2 pt-4">
        <div className="text-right text-sm text-gray-600 leading-none mb-0.5">
          {label}
        </div>
        <div className="text-xl font-bold text-right text-sky-900 leading-none">
          {value}
        </div>
      </div>
    </div>
  )
}
