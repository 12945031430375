import {
  InitiateMoneyTransferIcon,
  Modal,
  Button,
  ModalOnCloseProps,
  Form,
  FormField,
  Wizard,
  FormRefHandle,
  PercentageIcon,
  Co2Icon,
  WizardSlideHeader,
  WizardSlideContent,
  wizardHandleOnSlideChangeFormValidation,
} from '@client/shared/toolkit';
import { Radio, RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { useEffect, useRef, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { InferType } from 'yup';

export const NewElementFormValidationSchema = yup.object({
  regulation: yup.string().required('validation.required'),
});

type NewElementFormValidationValues = InferType<typeof NewElementFormValidationSchema>;

interface ElementButtonProps {
  title: string;
  subtitle: string;
  icon: React.ReactNode;
  onClick: () => void;
  disabled: boolean;
}

const ElementButton = ({ title, subtitle, icon, onClick, disabled }: ElementButtonProps) => {
  return (
    <div
      className={classNames('w-40', {
        'opacity-50': disabled,
      })}
    >
      <div
        className={classNames(
          'h-40 w-40 mb-3 bg-white rounded-xl border-2 border-slate-200 flex justify-center items-center',
          {
            'opacity-50': disabled,
            'hover:opacity-70 cursor-pointer': !disabled,
          }
        )}
        onClick={onClick}
      >
        {icon}
      </div>
      <div className="mb-1 text-medium font-medium text-gray-900 text-center">{title}</div>
      <div className="text-xs font-base text-gray-700 text-center">{subtitle}</div>
    </div>
  );
};

interface NewElementModalProps extends ModalOnCloseProps {
  catalogElementId?: string;
  onClose: (clearSelection?: boolean) => void;
  onOpenSlideOver: (regulation: string) => void;
}

export const NewElementModal = ({ catalogElementId, onClose, onOpenSlideOver }: NewElementModalProps) => {
  const { t } = useTranslation();

  const hasParent = catalogElementId != null;

  const [currentStep, setCurrentStep] = useState(1);
  const formRef = useRef<FormRefHandle<NewElementFormValidationValues>>();

  useEffect(() => {
    if (!hasParent) {
      setCurrentStep(0);
    }
  }, [hasParent]);

  const regulationTypes = [
    {
      label: 'Local Law 97',
      region: 'New York',
      value: 'localLaw97',
      icon: <Co2Icon className="w-14 h-14 bg-slate-900 p-3 rounded-full text-white" />,
      disabled: false,
    },
    // {
    //   label: 'General ESG',
    //   region: 'EU Law',
    //   value: 'generalEsg',
    //   icon: <Co2Icon className="w-14 h-14 bg-slate-900 p-3 rounded-full text-white" />,
    //   disabled: true,
    // },
    // {
    //   label: 'Labour Settlements',
    //   region: 'New York',
    //   value: 'labourSettlements',
    //   icon: <Co2Icon className="w-14 h-14 bg-slate-900 p-3 rounded-full text-white" />,
    //   disabled: true,
    // },
    {
      label: t('projectCalculate.vatReturnModal.vat'),
      region: t('projectCalculate.vatReturnModal.vatElement'),
      value: 'vat',
      icon: <PercentageIcon className="w-14 h-14 p-3 rounded-full" />,
      disabled: false,
    },
  ];

  const defaultFormValues = { regulation: 'localLaw97' };

  return (
    <Form<NewElementFormValidationValues>
      validationSchema={NewElementFormValidationSchema}
      defaultValues={defaultFormValues}
      ref={formRef}
      className="h-full"
    >
      <Wizard
        currentStep={currentStep}
        onSlideChange={(step) =>
          wizardHandleOnSlideChangeFormValidation(step, formRef, setCurrentStep)
        }
        className="w-[740px] h-[560px]"
      >
        <Wizard.Slides>
          <Wizard.Slide>
            <WizardSlideHeader
              title={t('projectCalculate.localLaw.newElement')}
              subTitle={t('projectCalculate.localLaw.newElementSubtitle')}
            />
            <WizardSlideContent>
              <div className="flex justify-evenly">
                <ElementButton
                  title={t('projectCalculate.costElement')}
                  subtitle={t('projectCalculate.costElementSubtitle')}
                  icon={<InitiateMoneyTransferIcon className="h-8 w-8" />}
                  onClick={() => undefined}
                  disabled={true}
                />
                <ElementButton
                  title={t('projectCalculate.prebuildElement')}
                  subtitle={t('projectCalculate.prebuildElementSubtitle')}
                  icon={<InitiateMoneyTransferIcon className="h-8 w-8" />}
                  onClick={() => undefined}
                  disabled={true}
                />
                <ElementButton
                  title={t('projectCalculate.featureElement')}
                  subtitle={t('projectCalculate.createFeatureElement')}
                  icon={<InitiateMoneyTransferIcon className="h-8 w-8" />}
                  onClick={() => setCurrentStep(1)}
                  disabled={false}
                />
              </div>
            </WizardSlideContent>
          </Wizard.Slide>
          <Wizard.Slide>
            <WizardSlideHeader
              title={t('projectCalculate.featureElement')}
              subTitle={t('projectCalculate.createFeatureElement')}
            />
            <WizardSlideContent>
              <FormField name="regulation">
                {(control) => (
                  <RadioGroup value={control.value} onChange={control.onChange} name="regulation">
                    {regulationTypes.map((regulationType) => (
                      <Radio
                        value={regulationType.value}
                        as={Fragment}
                        key={regulationType.value}
                        disabled={regulationType.disabled}
                      >
                        {({ checked }) => (
                          <div
                            className={classNames('w-full bg-white border-b flex pl-4 py-4', {
                              'opacity-50': regulationType.disabled,
                            })}
                          >
                            <div className="mr-5">{regulationType.icon}</div>
                            <div className="w-full flex flex-col">
                              <div className="text-medium font-medium text-gray-900">{regulationType.label}</div>
                              <div className="font-xs text-slate-700">{regulationType.region}</div>
                            </div>
                            <div className="flex flex-col items-center justify-center pr-3">
                              <CustomRadioCircle checked={checked} />
                            </div>
                          </div>
                        )}
                      </Radio>
                    ))}
                  </RadioGroup>
                )}
              </FormField>
            </WizardSlideContent>
          </Wizard.Slide>
        </Wizard.Slides>
        <Wizard.Navigation>
          {({ previous, next }) => (
            <Modal.Controls
              className={classNames({
                'bg-slate-100': currentStep === 0,
                'bg-white': currentStep !== 0,
              })}
            >
              {(() => {
                if (currentStep === 0) {
                  return (
                    <Button variant="text" onClick={() => onClose(false)}>
                      {t('common.cancel')}
                    </Button>
                  );
                } else if (!hasParent && currentStep === 1) {
                  return (
                    <Button variant="text" onClick={previous}>
                      {t('common.back')}
                    </Button>
                  );
                }
                if (currentStep === 1) {
                  return (
                    <Button
                      variant="primary"
                      onClick={() => onOpenSlideOver(formRef.current?.getValues().regulation ?? '')}
                    >
                      {t('common.next')}
                    </Button>
                  );
                }
              })()}
            </Modal.Controls>
          )}
        </Wizard.Navigation>
      </Wizard>
    </Form>
  );
};

const CustomRadioCircle = ({ ...props }) => (
  <div className="h-4 w-4 border-2 rounded-full border-neutral-500 focus:ring-2 focus:ring-blue-300 flex items-center justify-center">
    <div className={classNames('w-2 h-2 rounded-full', { 'bg-black': props.checked })}></div>
  </div>
);
