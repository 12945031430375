import { useParams, Navigate } from 'react-router-dom';
import { ProjectLayout } from './components';
import { MultiProjectRoutes } from './MultiProjectRoutes';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from '@client/shared/toolkit';
import { useDispatch } from 'react-redux';
import { setProjectId } from '@client/project/store';
import { useEffect } from 'react';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useApiGetProjectGroupQuery } from '@client/shared/api';

export const MultiProjectModule = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  if (id == null) throw new Error('Can only render if project id (:id) is set');

  const { data : groupData, isFetching : isLoading, isError, isSuccess } = useApiGetProjectGroupQuery(
    { groupId: id },
    { skip: !id, },
  );

  const firstProjectId = groupData?.projectGroup.projects?.length ? groupData?.projectGroup.projects[0].payload.id : undefined;

  const activeVariantId = firstProjectId
    ? groupData?.projectGroup.projects[0].calculationModels?.find((calculationModel) => calculationModel.type === 'Version')?.id
    : undefined;

  useEffect(() => {
    if (firstProjectId && activeVariantId) {
      dispatch(
        setProjectId({
          projectId: firstProjectId,
          variantId: activeVariantId,
        })
      );
    }
  }, [dispatch, firstProjectId, activeVariantId]);

  return (
    <ProjectLayout>
      {isLoading === true && <LoadingIndicator mode="overlay" text={t('project.loadingProjectDetails')} />}

      {isLoading === false && (
        <>
          {(isError === true || !groupData) && <Navigate to={{ pathname: ROUTES_CONFIG.PROJECTS.path }} />}
          {isSuccess === true && <MultiProjectRoutes />}
        </>
      )}
    </ProjectLayout>
  );
};
