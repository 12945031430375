import React, { createContext, ReactNode, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { CalculationModelDeliveryPhaseReadModelExt, TimeLineView } from './TimeLineHeader';
import { CalculationModelMilestoneReadModel } from '@client/shared/api';
import { parseISO } from 'date-fns/fp';
import { TimeLineElementsProps } from './TimeLineBody';
import { usePopper } from 'react-popper';
import { Portal } from '@headlessui/react';
import cn from 'classnames';
import { Placement } from '@popperjs/core';

export const MIN_TIMELINE_HEADER_HEIGHT = 64;

type TimeLineDataContextType = {
  activePhase: string | null;
  toggledYears: number[];
  timeLineHeaderRef?: RefObject<HTMLDivElement>;
  dragScrollContainerRef?: RefObject<HTMLDivElement>;
  containerRef?: RefObject<HTMLDivElement>;
  isHeaderCollapsed: boolean;
  headerHeight: number;
  activeTitle?: string;
  activeMilestone?: string | null;
  view?: TimeLineView;
  phases?: CalculationModelDeliveryPhaseReadModelExt[];
  milestones?: CalculationModelMilestoneReadModel[];
  isDragging?: boolean;
  selectedTimelineElement?: { elem: TimeLineElementsProps | null; date: string | undefined } | null;
  setActivePhase: (phase: string | null) => void;
  setToggledYears: (years: number[]) => void;
  addToggledYear: (year: number) => void;
  removeToggledYear: (year: number) => void;
  updateToggledYears: (year: number) => void;
  setIsHeaderCollapsed: (collapsed: boolean) => void;
  setHeaderHeight: (height: number) => void;
  setActiveMilestone: (code: string | null) => void;
  setIsDragging?: (isDragging: boolean) => void;
  setView?: (view: TimeLineView) => void;
  setSelectedTimelineElement?: (data: { elem: TimeLineElementsProps | null; date: string | undefined } | null) => void;
  setFinanceTargetElement: (elem: HTMLDivElement | null) => void;
  setFinanceElementDetails: (details: string | ReactNode) => void;
  setTooltipTarget: (elem: HTMLDivElement | null) => void;
  setTooltipContent: (details: string | ReactNode) => void;
  setTooltipOptions: (options: {placement: Placement, modifiers: {name: string, options: {offset: number[]}}[]}) => void
};

interface TimeLineDataContextProviderProps {
  children?: ReactNode;
  dragScrollContainerRef?: RefObject<HTMLDivElement>;
  timeLineHeaderRef?: RefObject<HTMLDivElement>;
  loadedProjectId?: string;
  containerRef?: RefObject<HTMLDivElement>;
  phases?: CalculationModelDeliveryPhaseReadModelExt[];
  milestones?: CalculationModelMilestoneReadModel[];
  timeLineView?: TimeLineView;
}

export const TimeLineDataContext = createContext<TimeLineDataContextType>({
  activePhase: null,
  toggledYears: [],
  timeLineHeaderRef: undefined,
  dragScrollContainerRef: undefined,
  containerRef: undefined,
  isHeaderCollapsed: false,
  headerHeight: MIN_TIMELINE_HEADER_HEIGHT,
  activeTitle: 'costs',
  activeMilestone: null,
  isDragging: false,
  view: TimeLineView.TIMELINE,
  selectedTimelineElement: null,
  // eslint-disable-next-lines @typescript-eslint/no-empty-function
  setActivePhase: () => {
    //
  },
  setToggledYears: () => {
    //
  },
  addToggledYear: () => {
    //
  },
  removeToggledYear: () => {
    //
  },
  updateToggledYears: () => {
    //
  },
  setIsHeaderCollapsed: () => {
    //
  },
  setHeaderHeight: () => {
    //
  },
  setActiveMilestone: () => {
    //
  },
  setIsDragging: () => {
    //
  },
  setView: () => {
    //
  },
  setSelectedTimelineElement: () => {
    //
  },
  setFinanceTargetElement: () => {
    //
  },
  setFinanceElementDetails: () => {
    //
  },
  setTooltipTarget: () => {
    //
  },
  setTooltipContent: () => {
    //
  },
  setTooltipOptions: () => {
    //
  }
});

export const TOGGLED_YEARS_STORAGE = 'devcal_years';

export const updateToggledYearsCookie = (years: number[], projectId?: string) => {
  if (!projectId) return;
  const savedToggledYears = localStorage.getItem(TOGGLED_YEARS_STORAGE);
  let json: { [key: string]: number[] } = {};
  if (savedToggledYears) {
    json = JSON.parse(savedToggledYears);
  }
  json[projectId] = years;
  const updatedJson = JSON.stringify(json);
  localStorage.setItem(TOGGLED_YEARS_STORAGE, updatedJson);
};

export const getMilestonesForYear = (year: number, milestones: CalculationModelMilestoneReadModel[]) => {
  let milestonesForYear: CalculationModelMilestoneReadModel[] = [];
  if (milestones.length) {
    milestonesForYear = milestones.filter((milestone) => {
      const date = parseISO(milestone.date);
      return date.getFullYear() === year;
    });
  }
  return milestonesForYear;
};
export const getPhasesForYear = (year: number, phases: CalculationModelDeliveryPhaseReadModelExt[]) => {
  const phasesForYear: CalculationModelDeliveryPhaseReadModelExt[] = [];
  if (phases.length) {
    phases.forEach((phase) => {
      if (
        phase.startDate &&
        phase.endDate &&
        (phase.startDate.getFullYear() === year ||
          phase.endDate.getFullYear() === year ||
          (phase.startDate.getFullYear() <= year && phase.endDate.getFullYear() >= year))
      ) {
        phase.yearEnd = phase.endDate?.getFullYear() > year ? new Date(year, 11, 31) : phase.endDate;
        phase.yearStart = phase.startDate?.getFullYear() < year ? new Date(year, 0, 1) : phase.startDate;
        phasesForYear.push({ ...phase });
      }
    });
  }
  return phasesForYear as CalculationModelDeliveryPhaseReadModelExt[];
};

export const getPhasesWithPositions = (phases: CalculationModelDeliveryPhaseReadModelExt[]) => {
  if (phases.length === 0) {
    return [];
  }
  phases.sort((a, b) => (a.startDate?.valueOf() || 0) - (b.startDate?.valueOf() || 0));
  phases.forEach((phase, pIdx) => {
    if (phase.startDate && phase.endDate) {
      phase.position = 0;
      let prevPos = 0; // if multiple overlaps, we need to store the latest previous position

      // check all previous phases, if they overlap
      for (let i = 0; i < pIdx; i++) {
        const prev = phases[i];
        if (
          phase.startDate &&
          prev.endDate &&
          // overlap
          phase.startDate < prev.endDate
        ) {
          if (prev.position === phase.position) {
            phase.position = (prev.position ?? 0) + 1 + prevPos;
          } else {
            prevPos = prev.position ?? 0;
          }
        }
      }
    }
  });
  return phases;
};

export const isTimelineElementInYear = (year: number, start?: string | Date, end?: string | Date) => {
  if (start && end && year) {
    const startDate = typeof start === 'string' ? parseISO(start) : start;
    const endDate = typeof end === 'string' ? parseISO(end) : end;
    if (
      startDate &&
      endDate &&
      (startDate.getFullYear() === year ||
        endDate.getFullYear() === year ||
        (startDate.getFullYear() <= year && endDate.getFullYear() >= year))
    ) {
      return true;
    }
  }
  return false;
};

export default function TimeLineDataContextProvider({
  children,
  dragScrollContainerRef,
  timeLineHeaderRef,
  loadedProjectId,
  timeLineView,
  containerRef,
}: TimeLineDataContextProviderProps) {
  const [activePhase, setActivePhase] = useState<string | null>(null);
  const [toggledYears, setToggledYears] = useState<number[]>([]);
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [activeMilestone, setActiveMilestone] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [view, setView] = useState(timeLineView ? timeLineView : TimeLineView.TIMELINE);
  const [selectedTimelineElement, setSelectedTimelineElement] = useState<{
    elem: TimeLineElementsProps | null;
    date: string | undefined;
  } | null>(null);

  // Finance Details Popover
  const [financeTargetElement, setFinanceTargetElement] = useState<HTMLElement | null>(null);
  const [financePopperElement, setFinancePopperElement] = useState<HTMLElement | null>(null);
  const [financeElementDetails, setFinanceElementDetails] = useState<string | ReactNode>('');

  const { styles, attributes } = usePopper(financeTargetElement, financePopperElement, {
    placement: 'bottom',
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
  });

  // Tooltips
  const [tooltipTarget, setTooltipTarget] = useState<HTMLElement | null>(null);
  const [tooltipPopperElement, setTooltipPopperElement] = useState<HTMLElement | null>(null);
  const [tooltipContent, setTooltipContent] = useState<string | ReactNode>('');
  const defaultTooltipOptions = {
    placement: 'bottom' as Placement,
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
  };
  const [tooltipOptions, setTooltipOptions] = useState(defaultTooltipOptions);

  const { styles: tooltipStyles, attributes: tooltipAttributes } = usePopper(
    tooltipTarget,
    tooltipPopperElement,
    tooltipOptions ?? defaultTooltipOptions,
  );

  useEffect(() => {
    if (toggledYears.length || !loadedProjectId) return;
    const savedToggledYears = localStorage.getItem(TOGGLED_YEARS_STORAGE);
    if (savedToggledYears) {
      const savedYearsByProject = JSON.parse(savedToggledYears);
      if (savedYearsByProject && savedYearsByProject[loadedProjectId]?.length) {
        const nums = savedYearsByProject[loadedProjectId];
        setToggledYears(nums);
      }
    }
  }, [loadedProjectId, toggledYears.length]);

  const addToggledYear = useCallback(
    (year: number) => {
      const newToggledYears = [...toggledYears];
      if (!newToggledYears.includes(year)) {
        newToggledYears.push(year);
        setToggledYears(newToggledYears);
        updateToggledYearsCookie(newToggledYears, loadedProjectId);
      }
    },
    [toggledYears, loadedProjectId],
  );

  const removeToggledYear = useCallback(
    (year: number) => {
      const foundYearIndex = toggledYears.indexOf(year);
      if (foundYearIndex >= 0) {
        const newToggledYears = [...toggledYears];
        newToggledYears.splice(foundYearIndex, 1);
        setToggledYears(newToggledYears);
        updateToggledYearsCookie(newToggledYears, loadedProjectId);
      }
    },
    [toggledYears, loadedProjectId],
  );

  const updateToggledYears = useCallback(
    (year: number) => {
      const newToggledYears = [...toggledYears];
      const foundYearIndex = toggledYears.indexOf(year);
      if (foundYearIndex >= 0) {
        newToggledYears.splice(foundYearIndex, 1);
      } else {
        newToggledYears.push(year);
      }
      setToggledYears(newToggledYears);
      updateToggledYearsCookie(newToggledYears, loadedProjectId);
    },
    [toggledYears, loadedProjectId],
  );

  useEffect(() => {
    if (timeLineView) {
      setView(timeLineView);
    }
  }, [timeLineView]);

  const value = useMemo(
    () => ({
      setToggledYears,
      setActivePhase,
      activePhase,
      toggledYears,
      addToggledYear,
      removeToggledYear,
      updateToggledYears,
      timeLineHeaderRef,
      isHeaderCollapsed,
      setIsHeaderCollapsed,
      headerHeight,
      setHeaderHeight,
      activeMilestone,
      setActiveMilestone,
      dragScrollContainerRef,
      isDragging,
      setIsDragging,
      view,
      setView,
      containerRef,
      selectedTimelineElement,
      setSelectedTimelineElement,
      setFinanceTargetElement,
      setFinanceElementDetails,
      setTooltipTarget,
      setTooltipContent,
      setTooltipOptions
    }),
    [
      activePhase,
      toggledYears,
      addToggledYear,
      removeToggledYear,
      updateToggledYears,
      timeLineHeaderRef,
      isHeaderCollapsed,
      headerHeight,
      activeMilestone,
      dragScrollContainerRef,
      isDragging,
      view,
      containerRef,
      selectedTimelineElement,
    ],
  );

  return (
    <TimeLineDataContext.Provider value={value}>
      {children}
      <Portal>
        <div
          ref={setFinancePopperElement}
          style={{ ...styles.popper }}
          {...attributes.popper}
          className={cn('bg-white shadow-lg p-2 z-20 transition-opacity', {
            'opacity-100': financeTargetElement && financeElementDetails,
            'opacity-0 pointer-events-none': !financeTargetElement || !financeElementDetails,
          })}
        >
          <div className="flex flex-col text-sm text-center text-gray-600 p-4">{financeElementDetails}</div>
        </div>
      </Portal>
      <Portal>
        <div
          ref={setTooltipPopperElement}
          style={tooltipStyles.popper}
          {...tooltipAttributes.popper}
          className={cn('bg-white shadow-lg p-2 z-20 transition-opacity', {
            'opacity-100': tooltipTarget && tooltipContent,
            'opacity-0 pointer-events-none': !tooltipTarget || !tooltipContent,
          })}
        >
          {tooltipContent}
        </div>
      </Portal>
    </TimeLineDataContext.Provider>
  );
}
