import { ConfirmModal, LoadingIndicator, ModalOnCloseProps } from "@client/shared/toolkit";
import { Trans, useTranslation } from "react-i18next";
import { RequirementDocumentReadModel, useApiDeleteClusterItemRequirementDocumentMutation } from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { useLoadedPortfolioId } from '@client/portfolio/store';

interface DocumentDeleteModalProps extends ModalOnCloseProps {
  requirementId: string;
  clusterItemId: string;
  document: RequirementDocumentReadModel;
}

export const RequirementDocumentDeleteModal = ({ document, requirementId, clusterItemId, onClose }: DocumentDeleteModalProps) => {
  const { t } = useTranslation();
  const loadedPortfolioId = useLoadedPortfolioId();
  const [deleteDocument, { isLoading }] = useApiDeleteClusterItemRequirementDocumentMutation();

  const handleDelete = async () => {
    if (loadedPortfolioId) {
      try {
        await safeMutation(
          deleteDocument,
          {
            portfolioId: loadedPortfolioId,
            clusterItemId: clusterItemId,
            requirementId: requirementId,
            documentId: document.id
          },
          isLoading
        );

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <ConfirmModal
      title={t('projectCalculate.DeleteDocument')}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isLoading}
    >
      {isLoading && <LoadingIndicator text={t('projectCalculate.DeleteDocumentLoadingIndicator')} />}
      <Trans i18nKey="projectCalculate.DocumentDeleteConfirmation" values={{ entity: document.fileName }}>
        <div>
          Möchten Sie die Datei <span className="font-bold">Entity</span> wirklich löschen?
        </div>
        <div className="pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
