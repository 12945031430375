import cn from 'classnames';
import {endOfMonth, format, parseISO, startOfMonth} from 'date-fns';
import React, {memo, useCallback, useContext, useMemo, useState} from 'react';
import {CalculationModelDeliveryPhaseReadModelExt, TimeLineView} from './TimeLineHeader';
import {useTranslation} from 'react-i18next';
import {TimeLinePositionedContainer} from './TimeLinePositionedContainer';
import {MinusIcon, PlusIcon} from '@heroicons/react/24/outline';
import {
  CalculationModelMilestoneReadModel,
  useApiPostUpdateCalculationModelMilestonesMutation
} from '@client/shared/api';
import {formatDate, safeMutation} from '@client/shared/utilities';
import {useLoadedProjectId, useLoadedVariantId} from '@client/project/store';
import { LoadingIndicator, MilestoneCheckIcon, Tooltip } from '@client/shared/toolkit';
import {TimelineHeaderPhase} from './TimeLineHeaderPhase';
import {TimeLineDataContext} from './TimeLineDataContext';

interface TimeLineHeaderYearProps {
  months: Date[]
  view: TimeLineView
  milestones: CalculationModelMilestoneReadModel[]
  showMilestones?: boolean
  phases: CalculationModelDeliveryPhaseReadModelExt[]
  scrollPos?: number
  dueDate?: string | null
}
export const MIN_MONTH_SIZE_TIMELINE = 44
export const MIN_MONTH_SIZE_FINANCE  = 128

export const getMinMonthSize = (view: TimeLineView, monthsLength: number, isToggled: boolean) => {
  const minMonthSize = view === TimeLineView.TIMELINE ? MIN_MONTH_SIZE_TIMELINE : MIN_MONTH_SIZE_FINANCE
  const size = view === TimeLineView.TIMELINE ? `${minMonthSize * 2}px` : `${minMonthSize}px`
  return {
    width: isToggled ? size : undefined,
    minWidth: !isToggled ? `${monthsLength * minMonthSize}px` : undefined
  }
}

export const TimeLineHeaderYear = ({
  months,
  view,
  milestones,
  showMilestones = false,
  phases,
  scrollPos = 0,
  dueDate
}: TimeLineHeaderYearProps) => {
  const {
    toggledYears,
    updateToggledYears,
    isHeaderCollapsed,
    activeMilestone,
    setActiveMilestone
  } = useContext(TimeLineDataContext);
  const [isLoading, setIsLoading] = useState(false)
  const [postUpdate, { isLoading: isUpdating }] = useApiPostUpdateCalculationModelMilestonesMutation();
  const loadedProjectId = useLoadedProjectId() ?? 'unset';
  const loadedCalculationModelId = useLoadedVariantId() ?? 'unset';
  const { t } = useTranslation();
  const today = new Date()
  const year = months.length ? months[0].getFullYear() : today.getFullYear()
  const todayYear = today.getFullYear()
  const isToggled = useMemo(() => {
    return toggledYears.includes(year)
  }, [toggledYears, year])
  const style = useMemo(() => {
    return getMinMonthSize(view, months.length, isToggled)
  }, [isToggled, months.length, view])
  const toggleYear = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    updateToggledYears(year)
  }, [updateToggledYears, year])
  const toggleMilestoneStatus = useCallback((e: React.MouseEvent<HTMLDivElement>, milestone: CalculationModelMilestoneReadModel) => {
    e.preventDefault()
    e.stopPropagation()
    setIsLoading(true)
    handleMilestoneSubmit(milestone).then(() => {
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleMilestoneSubmit = async (milestone: CalculationModelMilestoneReadModel) => {
    try {
      await safeMutation(
        postUpdate,
        {
          projectId: loadedProjectId,
          calculationModelId: loadedCalculationModelId,
          milestoneId: milestone?.id ?? 'unset',
          body: {
            name : milestone.name,
            code : milestone.code,
            date: milestone.date,
            order : milestone.order,
            isAchieved: !milestone.isAchieved
          },
        },
        isUpdating
      );
    } catch (e) {
      console.log(e);
      setIsLoading(false)
    }
  }
  const timelineStartDate = useMemo(() => {
    const start = startOfMonth(months[0])
    return new Date(start.toDateString()); // get rid of time
  }, [months])

  const timelineEndDate = useMemo(() => {
    const end = endOfMonth(months[months.length - 1])
    return new Date(end.toDateString()); // get rid of time
  }, [months])

  const phaseMarginTop = useMemo(() => {
    if (phases.length === 0) return '0px';

    const maxPositionPhase = phases.reduce((prev, current) => {
      const prevPos = prev.position ?? 0
      const currentPos = current.position ?? 0
      return (prevPos > currentPos) ? prev : current
    })
    const maxPosition = maxPositionPhase.position ? maxPositionPhase.position + 1 : 1
    return `${maxPosition * 30}px`
  }, [phases])

  const dueDateParsed = useMemo(() => {
    if (dueDate) {
      const date = parseISO(dueDate)
      if (date.getFullYear() === year) {
        return date
      }
    }
    return null
  }, [dueDate, year])

  return (
    <div
      data-year={year}
      className={cn(
        'relative w-auto z-0 min-h-[64px]',
        'before:content-[""] before:absolute before:w-[2px] before:h-11 before:bottom-0 before:left-0 before:bg-gray-200',
        {
          // 'flex-auto': !isToggled,
          'flex-none': isToggled || months.length <= 2
        }
      )}
      style={style}
    >
      {isLoading && <LoadingIndicator mode={'overlay-window'} />}
      {/* show button only if months of year are larger than 2 */}
      {months.length > 2 && (
        <button
          className="w-5 h-5 absolute left-0.5 bottom-6 top-auto bg-gray-100 center flex justify-center items-center z-50 rounded shadow-lg hover:bg-gray-200 transition-colors duration-300"
          onClick={toggleYear}
        >
          {isToggled ? <PlusIcon className='w-4 h-4' /> : <MinusIcon className='w-4 h-4' />}
        </button>
      )}
      <div className="flex flex-col text-[11px] justify-end h-full relative z-0">
        {/* Due Date Marker */}
        {dueDateParsed && (
          <TimeLinePositionedContainer
            className="bottom-0"
            timeLineStartDate={timelineStartDate}
            timeLineEndDate={timelineEndDate}
            date={dueDateParsed}
            id="due-date-marker"
          >
            <div className="-translate-x-1/2">
              <div className={cn('text-[11px] font-bold leading-none text-white bg-lime-500 py-0', {
                'px-2 rounded-xl py-0.5': !isToggled,
                'w-2 h-2 rounded-full': isToggled
              })}>
                {!isToggled && t('projectCalculate.timeLineDueDate')}
              </div>
              <div className="translate-x-1/2 border-l border-l-lime-500 h-6 border-dashed" />
            </div>
          </TimeLinePositionedContainer>
        )}
        {/* Today Marker */}
        {year === todayYear && (
          <TimeLinePositionedContainer
              className="bottom-0"
              timeLineStartDate={timelineStartDate}
              timeLineEndDate={timelineEndDate}
              date={today}
              id="today-marker"
          >
            <div className="-translate-x-1/2">
              <div className={cn('text-[11px] font-bold leading-none text-white bg-amber-400 py-0', {
                'px-2 rounded-xl py-0.5': !isToggled,
                'w-2 h-2 rounded-full': isToggled
              })}>
                {!isToggled && t('projectCalculate.timeLineToday')}
              </div>
              <div className="translate-x-1/2 border-l border-l-amber-400 h-6 border-dashed" />
            </div>
          </TimeLinePositionedContainer>
        )}
        {/* Phases */}
        {!isHeaderCollapsed && (
          <div
            className={cn('w-full relative', {
              'my-2.5': showMilestones,
              'mt-2.5 mb-7': !showMilestones
            })}
            style={{ marginTop: phaseMarginTop }}
          >
            {phases.map((label, index) => (
              <TimelineHeaderPhase
                timelineStartDate={timelineStartDate}
                timelineEndDate={timelineEndDate}
                phase={label}
                isToggled={isToggled}
                key={`timeline-header-phase-${label.code}`}
                index={phases.length - index}
                scrollPos={scrollPos}
              />
            ))}
          </div>
        )}
        {/* Milestones */}
        {!isHeaderCollapsed && showMilestones && (
          <div className="w-full border-t-4 relative my-2.5">
            {milestones.map((milestone, index) => (
              <TimeLinePositionedContainer
                key={index}
                timeLineStartDate={timelineStartDate}
                timeLineEndDate={timelineEndDate}
                date={milestone.date}
                className={cn('z-30', {
                  'z-40': activeMilestone === milestone.code
                })}
              >
                <Tooltip
                  className="relative"
                  label={
                    <div
                      onMouseEnter={() => setActiveMilestone(milestone.code)}
                      onMouseLeave={() => setActiveMilestone(null)}
                    >
                      <div
                        className={cn(
                          'absolute rounded-sm border-2 transition-colors duration-300 border-black hover:border-secondary z-[5] rotate-45 text-black hover:text-secondary', {
                            'border-secondary': activeMilestone === milestone.code,
                            'h-3 w-3 flex justify-center items-center -top-2 -left-[5px] ': !isToggled,
                            'h-2 w-2 -translate-y-1/2 -translate-x-1/2 -top-0.5 -left-0.5': isToggled,
                            'bg-white': !milestone.isAchieved,
                            'bg-black hover:bg-blue': milestone.isAchieved && isToggled
                          })}
                        aria-haspopup={true}
                        tabIndex={0}
                        role="button"
                        onClick={(e) => toggleMilestoneStatus(e, milestone)}
                      >
                        {milestone.isAchieved && !isToggled ? <MilestoneCheckIcon className="-rotate-45 w-full h-full" /> : ''}
                      </div>
                      {!isToggled && (
                        <div
                          className={cn('absolute top-0 -translate-x-1/2 border-l h-[50px] border-dashed transition-colors duration-300', {
                            'border-l-secondary': activeMilestone === milestone.code,
                            'border-l-gray-300': activeMilestone !== milestone.code
                          })}
                        />
                      )}
                    </div>
                  }
                  show={activeMilestone === milestone.code}
                >
                  <div className="min-w-fit whitespace-nowrap text-gray-400 text-xs">
                    <span className="text-secondary">{milestone.code ? <strong>{milestone.code}</strong> : ''} {milestone.name}</span>
                    <br />
                    {formatDate(milestone.date)}
                  </div>
                </Tooltip>
              </TimeLinePositionedContainer>
            ))}
          </div>
        )}
        {/* Year Label */}
        <span className="text-center text-slate-500 font-bold">{year}</span>
        {/* Months */}
        <TimeLineHeaderMonths
          months={months}
          view={view}
          isToggled={isToggled}
          year={year}
        />
      </div>
    </div>
  )
}
export interface TimeLineHeaderMonthProps {
  months: Date[]
  view: TimeLineView
  isToggled?: boolean
  year: number
}

const TimeLineHeaderMonths = memo((props: TimeLineHeaderMonthProps) => {
  const {
    months,
    year,
    view,
    isToggled
  } = props
  const shownMonths = useMemo(() => {
    const currentMonths = [...months]
    if (isToggled) {
      return currentMonths.splice(0, 2)
    }
    return currentMonths
  }, [isToggled, months])
  if (!months.length) return null
  return (
    <div className="flex">
      {shownMonths.map((month, index) => (
        <div
          key={`${year}-${index}`}
          className={cn('flex-auto flex-col relative text-center', {
            'min-w-[44px]': view === TimeLineView.TIMELINE, // MIN_MONTH_SIZE_TIMELINE
            'min-w-[128px]': view === TimeLineView.FINANCE // MIN_MONTH_SIZE_FINANCE
          })}>
            <span className={cn({
              'opacity-0': isToggled
            })}>
              {format(month, 'LLL')}
            </span>
          <div
            className={cn(
              'w-full h-2 relative',
              'before:content-[""] before:absolute before:w-px before:h-1/2 before:left-1/2 before:bg-neutral-300 before:bottom-0',
              {
                // 'border-l border-l-transparent': index !== 0,
                // border right, not for finance view if toggled (as we show only one month)
                'border-r': isToggled ? (view === TimeLineView.TIMELINE ? index !== 1 : false) : index !== months.length - 1
              }
            )}
          />
        </div>
      ))}
    </div>
  )
})
