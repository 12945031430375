import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { InfoIcon } from '../icons';
import cn from 'classnames';

interface InfoTextProps extends  PropsWithChildren {
  className?: string
  textStyle?: string
  icon?: ReactNode
}
export const InfoText = (props: InfoTextProps) => {
  const {
    className,
    icon = <InfoIcon />,
    children,
    textStyle = 'text-[13px] text-gray-500 italic'
  } = props

  return (
    <div className={cn('flex gap-2', textStyle, className)}>
      {icon && React.cloneElement(icon as ReactElement, { className: 'w-4 h-5 flex-none'})}
      {children}
    </div>
  )
}
