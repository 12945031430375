import { DealReadModel } from '@client/shared/api';
import React, { useState } from 'react';
import { FolderIcon, DecoratedCard, Modal, SlideOverTitle } from '@client/shared/toolkit';
import { DealDocumentRow } from './DealDocumentRow';
import { DealDocumentUploadModal } from './DealDocumentUploadModal';
import { useTranslation } from 'react-i18next';
import { ArrowUpTrayIcon } from '@heroicons/react/20/solid';
import { useCanWriteDeal } from '../../hooks';
import classNames from 'classnames';
import { useReadOnly } from '@client/project/store';
interface DealDocumentsProps {
  className?: string
  deal: DealReadModel;
  allowUpload: boolean;
}

export const DealDocuments = ({ allowUpload, deal: { documents, id, type }, className }: DealDocumentsProps) => {
  const { t } = useTranslation();

  const isReadOnly = useReadOnly();
  const readOnly = !useCanWriteDeal(type) || isReadOnly;

  const [showUpload, setShowUpload] = useState<boolean>(false);

  const handleUploadClick = () => {
    if (readOnly){
      return;
    }

    setShowUpload(true);
  }

  return (
    <div className={className}>
      <SlideOverTitle title={t('projectRent.dealDocumentTitle')} />
      <DecoratedCard>
        <DecoratedCard.Header
          showActionButton={allowUpload && !isReadOnly}
          onActionClick={handleUploadClick}
          icon={<ArrowUpTrayIcon />}
        >
          <FolderIcon className={classNames('w-6', readOnly ? 'text-gray-100' : 'text-black')} />
          <span>{t('projectRent.dealDocumentTitle')}</span>
        </DecoratedCard.Header>
        <DecoratedCard.Content className="p-1 w-full h-full flex items-center justify-center">
          {documents.map((document) => (
            <DealDocumentRow dealId={id} type={type} dealDocument={document} key={document.id} isReadOnly={isReadOnly} />
          ))}
          {!documents.length && (
            <div className="flex flex-col items-center justify-center p-5 text-gray-500">
              {t('projectRent.dealDocumentsEmpty')}
            </div>
          )}
        </DecoratedCard.Content>
      </DecoratedCard>

      <Modal isOpen={showUpload} onClose={() => setShowUpload(false)}>
        <DealDocumentUploadModal dealId={id} type={type} onDone={() => setShowUpload(false)} />
      </Modal>
    </div>
  );
};
