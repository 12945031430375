import {
  CalculationRuleGroupReadModel,
  CalculationRuleReadModel, CalculationRuleType,
  InvoiceCalculationSchemeReadModel,
  ReferenceType
} from '@client/shared/api';
import { BaseSelectOption } from '@client/shared/toolkit';
import { getIcsDeductionName, getInvoiceCoverSheetDeductionGroupLabel } from './getInvoiceCoverSheetLabels';

export const getInvoiceCoverSheetDeductionNames = (
  sheet: InvoiceCalculationSchemeReadModel,
  excludeGroups: string[],
  excludeDeductions: string[]
) => {
  const names: string[] = []
  if (sheet.calculationRuleGroups) {
    sheet.calculationRuleGroups.forEach((group) => {
      if (!excludeGroups.includes(group.calculationRuleGroupId)) {
        names.push(group.name)
      }
      if (group.calculationRules) {
        group.calculationRules.forEach((calculationRule) => {
          if (!excludeDeductions.includes(calculationRule.calculationRuleId)) {
            names.push(calculationRule.name)
          }
        })
      }
    })
  }
  return names
}

export const getReferenceOptionsByType = (
  type: ReferenceType | null,
  currentDeductionType: CalculationRuleType,
  sheet?: InvoiceCalculationSchemeReadModel | null,
  group?: CalculationRuleGroupReadModel | null,
  deduction?: CalculationRuleReadModel | null
) => {
  // all groups and deductions with lower order number than the current deduction can be referenced
  if (sheet?.calculationRuleGroups && group) {
    if (type === 'CalculationRule' || type === 'CalculationRuleGroup') {
      // get groups before the current
      const previousGroups = sheet?.calculationRuleGroups.filter((currentGroup) => {
        return currentGroup.orderNumber < group.orderNumber
      })

      const options: BaseSelectOption[] = []
      if (type === 'CalculationRule') {
        const previousGroupDeductions = previousGroups.reduce((result, currentObject) => {
          result = result.concat(currentObject.calculationRules)
          return result;
        }, [] as CalculationRuleReadModel[]);

        // return all deductions except the current one
        previousGroupDeductions.forEach((ded) => {
          const name = getIcsDeductionName(ded?.name)
          if (deduction?.calculationRuleId) {
            if (ded.calculationRuleId !== deduction.calculationRuleId) {
              options.push({
                label: name,
                value: ded.calculationRuleId
              })
            }
          } else {
            options.push({
              label: name,
              value: ded.calculationRuleId
            })
          }
        })
      } else {
        previousGroups.forEach((prevGroup) => {
          options.push({
            label: getInvoiceCoverSheetDeductionGroupLabel(prevGroup.name),
            value: prevGroup.calculationRuleGroupId
          })
        })
      }

      return options
    }
  }
  return []
}
