import { AuthEffectivePermissionReadModel } from '@client/shared/api';
import { Badge, SearchIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { PropsWithChildren, useState } from 'react';
import { TranslatePermissionName } from './PermissionUtilities';

interface EffectivePermissionListProps extends PropsWithChildren {
  showGroupOrigin: boolean;
  tenantPermissions: AuthEffectivePermissionReadModel[];
  projectPermissions: AuthEffectivePermissionReadModel[];
}

export const EffectivePermissionList = ({
  projectPermissions,
  showGroupOrigin,
  tenantPermissions,
  children
}: EffectivePermissionListProps) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState<string>('');

  return (
    <div className="p-4">
      {tenantPermissions.length > 0 && (
        <div className="mb-20">
          <div className="text-2xl text-gray-400 mb-2">{t('auth.tenantImpactLevelTitle')}</div>
          <div className="mx-2">
            <div className="flex flex-row border-b">
              <div className="w-8/12 text-gray-400">{t('auth.permission')}</div>
              <div className="w-4/12 text-gray-400">{t('auth.origin')}</div>
            </div>
            {tenantPermissions.map((permission, index) => (
              <div key={index} className="flex justify-center border-b">
                <div className="w-8/12">{TranslatePermissionName(permission.permission.name)}</div>
                <div className="w-4/12">
                  {permission.roleAssignments.map((role, index) => (
                    <div key={index} className="flex items-start my-1">
                      <Badge variant="info" text={role.name} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {projectPermissions.length > 0 && (
        <div>
          <div className="flex flex-row items-center">
            <div className="text-2xl text-gray-400 ">{t('auth.projectImpactLevelTitle')}</div>
          </div>
          <div className="relative text-gray-600 my-2">
            <SearchIcon className="absolute left-0 top-0 mt-3 ml-4 h-5 w-5" />
            <input
              type="search"
              placeholder={t('common.search')}
              className="bg-gray-100 h-10 px-5 pl-10 rounded-full text-sm focus:outline-none border-none"
              onChange={(e) => setSearchText(e.target.value.toLowerCase())}
            />
          </div>
          <div className="mx-2">
            <div className="flex flex-row border-b">
              <div className="w-1/3 text-gray-400">{t('auth.permission')}</div>
              <div className="w-1/3 text-gray-400">{t('auth.project')}</div>
              <div className="w-1/3 text-gray-400">{t('auth.origin')}</div>
            </div>
            {projectPermissions
              .filter((x) =>
                x.roleAssignments.some((y) =>
                  y.assignments.some((z) => z.project && z.project.name.toLowerCase().includes(searchText))
                )
              )
              .map((permission, index) => (
                <div key={index} className="flex justify-center border-b">
                  <div className="w-4/12">{TranslatePermissionName(permission.permission.name)}</div>
                  <div className="w-8/12 mb-1">
                    {permission.roleAssignments.map((role, index) => (
                      <div key={index} className="flex flex-col">
                        {role.assignments
                          .filter((x) => x.project && x.project.name.toLowerCase().includes(searchText))
                          .map((assignment, index) => (
                            <div key={index} className="flex flex-row items-center">
                              <div className="w-6/12">{assignment.project?.name}</div>
                              <div className="w-6/12">
                                <Badge variant="info" text={assignment.role.name} />
                                {showGroupOrigin && assignment.group && (
                                  <Badge variant="secondary" text={assignment.group.name} />
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};
