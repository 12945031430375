import { useApiDeleteTenantInviteMutation } from '@client/shared/api';
import { ConfirmModal, HintBox, LoadingIndicator, ModalOnCloseProps } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

interface InviteDeleteModalProps extends ModalOnCloseProps {
  inviteId?: string;
}

export const InviteDeleteModal = ({ inviteId, onClose }: InviteDeleteModalProps) => {
  const { t } = useTranslation();

  const [call, { isLoading }] = useApiDeleteTenantInviteMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    if (inviteId) {
      try {
        await safeMutation(
          call,
          {
            inviteId: inviteId,
          },
          isLoading,
        );
        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  return (
    <ConfirmModal
      title={t('app.setttingsTenantTrustDelete')}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isLoading}
    >
      {isLoading && (
        <LoadingIndicator text={t('common.loading')} mode="overlay" />
      )}
      <HintBox hintType="warning">{t('app.settingsTenantTrustDeleteInviteHint')}</HintBox>
    </ConfirmModal>
  );
};
