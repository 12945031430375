import {
  ContextMenu,
  DecoratedCard, DocumentViewerSlideOver,
  DownloadIcon, EyeIcon,
  ImageDocumentIcon, LoadingIndicator,
  Modal,
  PDFIcon,
  PencilIcon, SlideOver, TrashIcon
} from '@client/shared/toolkit';
import {
  TaxonomyReadModel,
  TaxonomyItemFileReadModel,
  PlotReadModel,
  TaxonomyItemFileTypeEnum, useApiDeleteTaxonomyItemFileMutation, useApiDeletePlotItemFileMutation,
} from '@client/shared/api';
import React, { useRef, useState } from 'react';
import { useLoadedProjectId } from '@client/project/store';
import { DashboardModal } from './DashboardModal';
import { PDFThumbnail } from './PDFThumbnail';
import { isImage, safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

interface FileUploadCardProps {
  item?: TaxonomyReadModel | undefined;
  plot?: PlotReadModel | undefined;
  type: TaxonomyItemFileTypeEnum;
  handleClick: (type: TaxonomyItemFileTypeEnum) => void;
  title: string;
  showThumbnail?: boolean;
}

export const FileUploadCard = ({ item, plot, type, handleClick, title, showThumbnail = true }: FileUploadCardProps) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDocumentViewerOpen, setIsDocumentViewerOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<{
    id: string;
    name: string;
    path?: string
  } | null>(null);
  const loadedProjectId = useLoadedProjectId();
  const [postTaxonomyDelete, { isLoading: isDeletingTaxonomyImage }] = useApiDeleteTaxonomyItemFileMutation();
  const [postPlotDelete, { isLoading: isDeletingPlotImage }] = useApiDeletePlotItemFileMutation();

  const openModal = (file: TaxonomyItemFileReadModel) => {
    setSelectedFile({
      id: file.id,
      name: file.name,
    });
    setIsModalOpen(true);
  };

  const openDocumentViewer = (file: TaxonomyItemFileReadModel) => {
    const href =  item
      ? `/api/projects/${loadedProjectId}/calculation-models/${item?.calculationModelId}/taxonomy/${item?.itemId}/file/${file.id}`
      : `/api/projects/${loadedProjectId}/calculation-models/${plot?.calculationModelId}/plots/${plot?.plotId}/file/${file.id}`

    setSelectedFile({
      id: file.id,
      name: file.name,
      path: href
    });
    setIsDocumentViewerOpen(true);
  }

  const files = item ? item.files : plot?.files;

  const downloadRef = useRef<HTMLAnchorElement>(null)

  const getContextMenuItems = (file: TaxonomyItemFileReadModel) => {
    return [
      {
        label: t('common.view'),
        icon: <EyeIcon />,
        onClick: () => {
          if (isImage(file.name)) {
            openModal(file)
          } else {
            openDocumentViewer(file)
          }
        },
        isDisabled: false,
        stopPropagation: true
      },
      {
        label: t('common.download'),
        icon: <DownloadIcon />,
        onClick: () => {
          if (downloadRef?.current) {
            const href =  item
              ? `/api/projects/${loadedProjectId}/calculation-models/${item?.calculationModelId}/taxonomy/${item?.itemId}/file/${file.id}`
              : `/api/projects/${loadedProjectId}/calculation-models/${plot?.calculationModelId}/plots/${plot?.plotId}/file/${file.id}`

            downloadRef.current.setAttribute('href', href)
            downloadRef?.current?.click()
          }
        },
        isDisabled: false,
        stopPropagation: true
      },
      {
        label: t('common.delete'),
        icon: <TrashIcon />,
        onClick: () => {
          handleDelete(file.id)
        },
        isDisabled: false,
        stopPropagation: true
      },
    ]
  }

  const handleDelete = async (id: string) => {
    if (item && loadedProjectId) {
      try {
        await safeMutation(
          postTaxonomyDelete,
          {
            projectId: loadedProjectId,
            calculationModelId: item?.calculationModelId ?? '',
            taxonomyItemId: item?.itemId ?? '',
            fileId: id,
          },
          isDeletingTaxonomyImage
        );
      } catch (e) {
        console.log(e);
      }
    }

    if (plot && loadedProjectId) {
      try {
        await safeMutation(
          postPlotDelete,
          {
            projectId: loadedProjectId,
            calculationModelId: plot?.calculationModelId ?? '',
            plotItemId: plot?.plotId ?? '',
            fileId: id,
          },
          isDeletingPlotImage
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      {(isDeletingTaxonomyImage || isDeletingPlotImage) && (
        <LoadingIndicator text={t('projectTaxonomy.removingTaxonomyImageLoadingIndicator')} mode="overlay-window" />
      )}
      <DecoratedCard>
        <DecoratedCard.Header showActionButton={true} icon={<PencilIcon />} onActionClick={() => handleClick(type)}>
          <div className="truncate">{title}</div>
        </DecoratedCard.Header>
        <DecoratedCard.Content className="p-5 w-full h-full container">
          <div className="flex flex-wrap gap-2">
            {files
              ?.filter((file) => file.fileType === type)
              .map((file) =>
                showThumbnail ? (
                  <div key={file.id} className="flex w-1/3 flex-wrap">
                    <div
                      className="w-full p-2 cursor-pointer hover:opacity-75"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (isImage(file.name)) {
                          openModal(file)
                        } else {
                          openDocumentViewer(file)
                        }
                        return false;
                      }}
                    >
                      {isImage(file.name) ? (
                        <img
                          src={
                            item
                              ? `/api/projects/${loadedProjectId}/calculation-models/${item?.calculationModelId}/taxonomy/${item?.itemId}/file/${file.id}`
                              : `/api/projects/${loadedProjectId}/calculation-models/${plot?.calculationModelId}/plots/${plot?.plotId}/file/${file.id}`
                          }
                          alt={file.name}
                          className="block h-full w-full rounded-lg object-cover object-center"
                        />
                      ) : (
                        <PDFThumbnail
                          file={file}
                          loadedProjectId={loadedProjectId}
                          item={item}
                          plot={plot}
                          width={150}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div key={file.id} className="w-full flex items-center">
                    <div
                      className="w-full flex p-2 border border-slate-200 rounded-md cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (isImage(file.name)) {
                          openModal(file)
                        } else {
                          openDocumentViewer(file)
                        }
                        return false
                      }}
                    >
                      {isImage(file.name) ? <ImageDocumentIcon className="w-5 h-5 mr-2" /> : <PDFIcon className="w-5 h-5 mr-2" />}
                      <span className="text-sm font-medium text-gray-900 truncate">{file.name}</span>
                    </div>
                    <ContextMenu items={getContextMenuItems(file)} />
                  </div>
                ),
              )}
          </div>
          <a
            href="/"
            target="_blank"
            rel="noreferrer"
            download
            ref={downloadRef}
            className="hidden"
          >
            <DownloadIcon className="w-6 h-6 ml-2 cursor-pointer" />
          </a>
        </DecoratedCard.Content>
      </DecoratedCard>
      <Modal
        isOpen={isModalOpen}
        onClose={(e) => setIsModalOpen(false)}
        variant="full"
        onAfterLeave={() => setSelectedFile(null)}
      >
        <DashboardModal selectedFile={selectedFile} loadedProjectId={loadedProjectId} item={item} plot={plot} />
      </Modal>
      <SlideOver
        isOpen={isDocumentViewerOpen}
        onClose={() => setIsDocumentViewerOpen(false)}
        variant="large"
        onAfterLeave={() => setSelectedFile(null)}
      >
        <DocumentViewerSlideOver
          name={selectedFile?.name ?? ''}
          pdfFile={selectedFile?.path ?? ''}
          onClose={() => setIsDocumentViewerOpen(false)}
        />
      </SlideOver>
    </>
  );
};
