import { DecoratedCard, Button, LoadingIndicator } from '@client/shared/toolkit';
import {
  useApiPostProjectValuationMutation,
  useApiGetExternalApisQuery,
  ExternalApiReadModel
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import React, { useEffect, useState } from 'react';
import { useLoadedProjectId, useLoadedProject } from '@client/project/store';
import {
  ProtectedNavLink,
  ROUTES_CONFIG,
  useValidatePermissionsAndLicenses,
} from '@client/shared/permissions';
import {PriceHubble} from '@client/project/shared';

export const MarketPricesCard = () => {
  const { t } = useTranslation();
  const projectId = useLoadedProjectId();
  const project = useLoadedProject();
  const [triggerProjectValuation, { isLoading }] = useApiPostProjectValuationMutation();
  const [isActive, setIsActive] = useState<boolean>(false);

  const { hasPermissionsAndLicense } = useValidatePermissionsAndLicenses(ROUTES_CONFIG.SETTINGS_CONNECT, projectId)
  const { data: externalApis, isFetching: isLoadingExternalApis } = useApiGetExternalApisQuery();

  useEffect(() => {
    if (externalApis)
      setIsActive(!!externalApis?.find((x: ExternalApiReadModel) => x.api.name === 'PriceHubble')?.api.isActive);
  }, [externalApis]);

  const handleProjectValuation = async () => {
    try {
      await safeMutation(
        triggerProjectValuation,
        {
          projectId: projectId,
        },
        isLoading
      );
    } catch (e) {
      console.log(e);
    }
  };

  const priceHubbledata = project.currentData?.project.payload.priceHubbleValuation;

  return (
    <>
      {(isLoading || isLoadingExternalApis) && <LoadingIndicator text={t('common.loading')} mode="overlay" />}
      {!priceHubbledata ? (
        isActive ? (
          <Button className="w-full" variant="primary" onClick={() => handleProjectValuation()} disabled={isLoading}>
            {t('projectTaxonomy.dashboardGetPricehubble')}
          </Button>
        ) : (
          <ProtectedNavLink
            routeConfig={ROUTES_CONFIG.SETTINGS_CONNECT}
            className="w-full"
            disabledClassName="w-full"
            projectId={projectId}
          >
            <Button className="w-full" variant="primary" disabled={!hasPermissionsAndLicense}>
              {t('projectTaxonomy.dashboardGetPricehubbleCredentials')}
            </Button>
          </ProtectedNavLink>
        )
      ) : (
        <DecoratedCard>
          <DecoratedCard.Header showActionButton={false}>
            <div className="truncate">{t('projectTaxonomy.dashboardMarketPrices')}</div>
          </DecoratedCard.Header>
          <DecoratedCard.Content>
            <PriceHubble priceHubbledata={priceHubbledata} handleProjectValuation={handleProjectValuation} />
          </DecoratedCard.Content>
        </DecoratedCard>
      )}
    </>
  )
};
