import { useApiPostCreateCalculationModelArchiveMutation, CalculationModelMetadata } from '@client/shared/api';
import { Button, HintBox, LoadingIndicator, Modal, ModalOnCloseProps } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface VariantDeactivateModalProps extends ModalOnCloseProps {
  variant: CalculationModelMetadata;
}

export const VariantDeactivateModal = ({ onClose, variant }: VariantDeactivateModalProps) => {
  const { t } = useTranslation();

  const [call, { isLoading }] = useApiPostCreateCalculationModelArchiveMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDeactivate = async () => {
    try {
      await safeMutation(
        call,
        {
          projectId: variant.projectId,
          calculationModelId: variant.id,
        },
        isLoading
      );

      onClose(true);
    } catch {
      /* left blank */
    }
  };

  return (
    <>
      {isLoading && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
      <Modal.Header title={t('projectVariants.variantDeactivateTitle')} />
      <Modal.Content>
        <HintBox>{t('projectVariants.variantDeactivateHint')}</HintBox>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={handleOnClose} className="mr-2" variant="secondary">
          {t('common.cancel')}
        </Button>
        <Button onClick={handleDeactivate}>{t('projectVariants.variantDeactivateAction')}</Button>
      </Modal.Controls>
    </>
  );
};
