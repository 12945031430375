type ParsedDomain = {
  tld?: string;
  domain?: string;
  subdomain?: string;
  depth: number;
};

export const parseDomain = (hostname: string) => {
  if (hostname == null || hostname === '') return { depth: 0 };

  const hostnames = hostname.split('.');

  const result: ParsedDomain = {
    depth: hostnames.length,
  };

  if (hostnames.length > 0) result.tld = hostnames[hostnames.length - 1];
  if (hostnames.length > 1) result.domain = hostnames[hostnames.length - 2];
  if (hostnames.length > 2) result.subdomain = hostnames[hostnames.length - 3];

  return result;
};
