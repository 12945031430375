import {
  AuthAssignmentReadModel,
  AuthRoleAssignmentReadModel,
  ShortProjectReadModel,
  useApiGetProjectsQuery,
} from '@client/shared/api';
import { Button, LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import { TranslatePermissionName } from '../permissions';
import { TranslateRoleName } from './RoleTranslation';

interface RoleImpactEditSlideOverProps {
  roleAssignment: AuthRoleAssignmentReadModel;
  onClose: (selectedAssignments?: AuthRoleAssignmentReadModel) => void;
}

export const RoleImpactEditSlideOver = ({ onClose, roleAssignment }: RoleImpactEditSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  /*Refetch if user removes himself from a project*/
  const { data: projects, isFetching } = useApiGetProjectsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [selectableAssignments, setSelectableAssignments] = useState<AuthAssignmentReadModel[]>([]);
  const [selectedAssignments, setSelectedAssignments] = useState<AuthAssignmentReadModel[]>([]);

  const hasProjectAssignments = roleAssignment.assignments.some((x) => x.project);
  const [isTenantImpactLevel, setIsTenantImpactLevel] = useState<boolean>(!hasProjectAssignments);

  useEffect(() => {
    if (!projects) {
      setSelectedAssignments([]);
    } else {
      const toAssignment = (project: ShortProjectReadModel): AuthAssignmentReadModel => ({
        id: '',
        role: { id: roleAssignment.roleId, name: roleAssignment.name },
        project: { id: project.id, name: project.name },
      });

      const notAssignedProjects = projects.projects
        .filter((x) => !roleAssignment.assignments.find((y) => y.project?.id === x.id))
        .map(toAssignment);

      const notAssignedProjectGroups = projects.projectGroups
        .map(x => x.projects.filter(x => !roleAssignment.assignments.find((y) => y.project?.id === x.id)))
        .reduce((a, b) => a.concat(b), [])
        .map(toAssignment);

      const assignedProjects = roleAssignment.assignments.filter((x) => x.project);

      setSelectableAssignments(notAssignedProjects
        .concat(notAssignedProjectGroups)
        .concat(assignedProjects));
      setSelectedAssignments(assignedProjects);
    }
  }, [roleAssignment, projects]);

  const handleChange = (assignment: AuthAssignmentReadModel) => {
    if (selectedAssignments.includes(assignment)) {
      setSelectedAssignments(selectedAssignments.filter((x) => x.project?.id !== assignment.project?.id));
    } else {
      setSelectedAssignments(selectedAssignments.concat(assignment));
    }
  };

  const handleSave = () => {
    let assignments: AuthAssignmentReadModel[];

    if (isTenantImpactLevel) {
      assignments = [{ id: '', role: { id: roleAssignment.roleId, name: roleAssignment.name } }];
    } else {
      assignments = selectedAssignments;
    }

    const newRoleAssignment: AuthRoleAssignmentReadModel = {
      roleId: roleAssignment.roleId,
      name: roleAssignment.name,
      scope: roleAssignment.scope,
      isSystemRole: roleAssignment.isSystemRole,
      systemRoleType: roleAssignment.systemRoleType,
      permissions: roleAssignment.permissions,
      assignments: assignments,
    };

    onClose(newRoleAssignment);
  };

  return (
    <>
      <SlideOver.Header
        title={t('auth.roleEditAssignmentTitle')}
        subTitle={TranslateRoleName(roleAssignment.name, roleAssignment.systemRoleType)}
        backgroundClassName="bg-blue-900"
        onClose={() => onClose(undefined)}
      />
      <SlideOver.Content className="p-8">
        {isFetching === true ? (
          <LoadingIndicator text={t('auth.projectLoadingIndicator') ?? ''} />
        ) : (
          <div className="h-full">
            <div className="text-2xl text-gray-400 mb-2">{t('auth.roleAssignments')}</div>
            <div className="divide-y">
              <label className="flex items-center bg-white h-14 p-3 cursor-pointer">
                <input
                  type="checkbox"
                  checked={isTenantImpactLevel}
                  onChange={() => setIsTenantImpactLevel(!isTenantImpactLevel)}
                  autoFocus={false}
                />
                <div className="ml-2 w-full">{t('auth.tenantImpactLevelTitle')}</div>
              </label>
              <label className="flex items-center bg-white h-14 p-3 cursor-pointer">
                <input
                  type="checkbox"
                  checked={!isTenantImpactLevel}
                  onChange={() => setIsTenantImpactLevel(!isTenantImpactLevel)}
                  autoFocus={false}
                />
                <div className="ml-2 w-full">{t('auth.projectImpactLevelTitle')}</div>
              </label>
              {!isTenantImpactLevel && (
                <>
                  {selectableAssignments.map((assignment, index) => (
                    <label className="pl-10 flex items-center bg-white h-14 p-3 cursor-pointer" key={index}>
                      <input
                        type="checkbox"
                        checked={selectedAssignments.includes(assignment)}
                        onChange={() => handleChange(assignment)}
                        autoFocus={false}
                      />
                      <div className="ml-2 w-full">{assignment.project?.name ?? ''}</div>
                    </label>
                  ))}
                </>
              )}
            </div>

            <div className="text-2xl text-gray-400 mt-4 mb-2">{t('auth.rolePermissions')}</div>
            <div className="flex flex-wrap ml-2">
              {roleAssignment.permissions.map((permission, index) => (
                <div key={index} className="flex items-center">
                  {TranslatePermissionName(permission.name)}
                  {index !== roleAssignment.permissions.length - 1 && <div className="mx-1">{'|'}</div>}
                </div>
              ))}
            </div>
          </div>
        )}
      </SlideOver.Content>
      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(undefined)}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={handleSave} innerRef={submitRef}>
          {t('common.save')}
        </Button>
      </SlideOver.Controls>
    </>
  );
};
