import { DealReadModel } from '@client/shared/api';
import { useState } from 'react';
import { DealCostItemRow } from './DealCostItemRow';
import { getSumOfCommonAndRentalSpace, getSumOfParkingSpace } from '../DealCalculation';
import { DealCostItemAddSelectSlideOver } from './DealCostItemAddSelectSlideOver';
import {
  SlideOver,
  DecoratedCard,
  TaxonomyBuildingComplexIcon,
  AddButton,
  SlideOverTitle
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { formatUnit } from '@client/shared/utilities';
import classNames from 'classnames';
import { useCanWriteDeal } from '../../hooks';
import { useLoadedProjectUnitSystemSymbol } from '@client/project/store';

export interface DealCostItemsProps {
  deal: DealReadModel;
  isReadOnly: boolean;
}

export const DealCostItems = ({ deal, isReadOnly }: DealCostItemsProps) => {
  const { t } = useTranslation();
  const unitSystem = useLoadedProjectUnitSystemSymbol();

  const isLocked = deal.status === 'Closed';
  const readOnly = !useCanWriteDeal(deal.type) || isReadOnly;
  const [showAddForm, setShowAddForm] = useState<boolean>(false);

  return (
    <div>
      <SlideOverTitle title={deal.type === 'Rent' ? t('projectRent.dealCostItemRentalUnits') : t('projectRent.dealCostItemUnits')} />

      <DecoratedCard>
        <DecoratedCard.Header showActionButton={false}>
          <TaxonomyBuildingComplexIcon className="w-6" />
          <div className="flex items-center">
            <div className="text-2xl font-bold">
              {deal.type === 'Rent' && t('projectRent.dealCostItemRentalUnits')}
              {deal.type === 'Sell' && t('projectRent.dealCostItemUnits')}
            </div>
            {deal.costItems && (
              <div className="text-sm ml-6 font-light">
                {deal.costItems?.length} {t('projectRent.dealCostItemUnits')}
              </div>
            )}
          </div>
        </DecoratedCard.Header>
        <DecoratedCard.Content className="p-1 pb-6 w-full h-full flex items-center justify-center">
          <div className="w-full">
            {deal.costItems?.map((x) => (
              <div key={x.id} className="mb-2">
                <DealCostItemRow deal={deal} costItem={x} isReadOnly={isReadOnly} />
              </div>
            ))}

            <div className={classNames('flex justify-end', { '-mt-6': deal.costItems?.length > 0 })}>
              {!isLocked && !readOnly && (
                <AddButton onClick={() => setShowAddForm(true)} />
              )}
            </div>
          </div>

          <div className="flex-col w-11/12 mt-5 mr-5 text-end">
            <div className="text-xl font-bold">
              {getSumOfParkingSpace(deal.costItems)} {t('projectRent.dealCostItemPieces')}
            </div>
            <div className="text-gray-400 text-xs font-bold">{t('projectRent.dealCostItemParkingSpaces')}</div>
          </div>

          <div className="flex-col w-11/12 mt-5 mr-5 text-end">
            <div className="text-xl font-bold">
              {formatUnit(getSumOfCommonAndRentalSpace(deal.costItems), unitSystem)}
            </div>
            <div className="text-gray-400 text-xs font-bold">
              {deal.type === 'Rent' && t('projectRent.dealCostItemRentableAreaTotal')}
              {deal.type === 'Sell' && t('projectRent.dealCostItemAreaTotal')}
            </div>
          </div>
        </DecoratedCard.Content>
      </DecoratedCard>

      <SlideOver isOpen={showAddForm} onClose={() => setShowAddForm(false)}>
        <DealCostItemAddSelectSlideOver dealId={deal.id} type={deal.type} onClose={() => setShowAddForm(false)} />
      </SlideOver>
    </div>
  );
};
