import {
  ElementTimelineReadModel,
  DistributionReadModel,
  DistributionType, DistributionFrequency
} from '@client/shared/api';
import {
  BaseSelect,
  BaseSelectOption, BellcurveDistributionIcon, DegressiveDistributionIcon,
  LinearDistributionIcon, ManualDistributionIcon,
  NoneDistributionIcon, ProgressiveDistributionIcon
} from '@client/shared/toolkit';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

interface TimeLineDistributionSelectProps {
  timeline: ElementTimelineReadModel | null | undefined;
  onChange: (distribution: DistributionReadModel | undefined) => void;
  disabled?: boolean;
  disabledTypes?: DistributionType[];
  className?: string
}

export const TimeLineDistributionSelect = (props: TimeLineDistributionSelectProps) => {
  const { t } = useTranslation();

  const {
    className,
    timeline,
    disabled,
    disabledTypes,
    onChange
  } = props;

  const [distributionType, setDistributionType] = useState<string>('None');
  useEffect(() => {
    setDistributionType(timeline?.distribution?.type ?? 'None');
  }, [timeline]);

  const handleChange = (newDistributionType: string) => {
    setDistributionType(newDistributionType);
    triggerOnChange(newDistributionType as DistributionType);
  };

  const triggerOnChange = (newDistributionType: DistributionType) => {
    let grain = timeline?.distribution?.frequency ?? 'Month'
    if (newDistributionType === 'Manual') {
      grain = 'None'
    } else if (timeline?.distribution?.frequency === 'None') {
      grain = 'Month'
    }
    const distribution: DistributionReadModel = {
      id: timeline?.distribution?.id ?? '',
      type: newDistributionType,
      frequency: grain as DistributionFrequency,
      distributionValues: [],
      manualDistributionValues: [],
    }
    onChange(distribution);
  };

  const selectableDistributionTypes: BaseSelectOption[] = useMemo(() => {
    const types: { value: DistributionType; label: string, icon?: ReactNode }[] = [
      { value: 'None', label: t('common.none'), icon: <NoneDistributionIcon /> },
      // { value: 'AllAtTheStart', label: t('projectCalculate.DistributionAllAtStart') },
      // { value: 'AllAtTheEnd', label: 'All at the end' },
      { value: 'Constant', label: t('projectCalculate.DistributionConstant'), icon: <LinearDistributionIcon /> },
      // { value: 'ReverseCurve', label: t('projectCalculate.DistributionReverseCurve') },
      { value: 'Progressive', label: t('projectCalculate.DistributionProgressive'), icon: <ProgressiveDistributionIcon /> },
      { value: 'Degressive', label: t('projectCalculate.DistributionDegressive'), icon: <DegressiveDistributionIcon /> },
      { value: 'Manual', label: t('projectCalculate.DistributionManual'), icon: <ManualDistributionIcon /> },
      { value: 'BellCurve', label: t('projectCalculate.DistributionBellCurve'), icon: <BellcurveDistributionIcon /> },
      { value: 'Effective', label: t('projectCalculate.DistributionEffective') }
    ];
    if (disabledTypes && disabledTypes.length > 0) {
      return types.filter((type) => !disabledTypes.includes(type.value));
    }
    return types;
  }, [disabledTypes, t]);

  const selectedIcon = useMemo(() => {
    if (distributionType) {
      const foundType = selectableDistributionTypes.find((type) => {
        return type.value === distributionType
      })
      if (foundType && foundType.icon) {
        return foundType.icon
      }
    }
    return null
  }, [distributionType, selectableDistributionTypes])

  return (
    <BaseSelect
      className={className}
      disabled={disabled || timeline === null || timeline === undefined}
      options={selectableDistributionTypes}
      label={t('projectCalculate.DistributionTypeLabel')}
      icon={selectedIcon ? selectedIcon : <ChartBarIcon />}
      value={distributionType}
      onChange={(value) => handleChange(value)}
    />
  );
};
