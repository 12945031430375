import { TitleBar, useDocumentTitle } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import {
  ControlContainer
} from '../components';
import { useLoadedProject } from '@client/project/store';
import { useState } from 'react';
import { UserDefinedFieldsSearch } from '@client/project/shared';

export const ControlRoute = () => {
  const { t } = useTranslation();

  const { data: loadedProject } = useLoadedProject();

  useDocumentTitle({ title: t('projectControl.routeTitle') });

  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState<string[]>([]);

  return (
    <>
      <TitleBar
        title={loadedProject?.project?.payload?.name ?? t('projectCntrol.routeTitle')}
        subTitle={t('projectControl.routeSubTitle')}
      >
        <UserDefinedFieldsSearch
          searchValue={searchValue}
          updateSearchResults={(results) => setSearchResults(results)}
          handleSearchValueUpdate={(value) => setSearchValue(value)}
          udfElementTypes={['Invoice']}
          filterStore="Invoice"
          className="max-w-[428px] text-gray-400 bg-gray-100"
        />
      </TitleBar>
      <div className="flex-grow flex flex-col overflow-hidden">
        <div className="px-4 py-8 md:px-8 flex flex-col flex-grow overflow-y-auto">
          <ControlContainer searchValue={searchValue} searchResults={searchResults}  />
        </div>
      </div>
    </>
  );
};
