import { CalculationRuleGroupReadModel, InvoiceCalculationRuleGroupReadModel } from '@client/shared/api';

export const getIcsVatRenderedInfo = (
  groups?: InvoiceCalculationRuleGroupReadModel[] | CalculationRuleGroupReadModel[],
) => {
  const info = {
    groupIndex: 0,
    ruleIndex: 0,
  };
  groups?.forEach((group, groupIndex) => {
    group.calculationRules.forEach((rule, ruleIndex) => {
      if (rule.type === 'Vat') {
        info.groupIndex = groupIndex;
        info.ruleIndex = ruleIndex;
      }
    });
  });
  return info;
};
