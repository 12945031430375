import { TaxonomyReadModel, TaxonomyType } from '@client/shared/api';

export interface AllowedTaxonomyTypesResponse {
  forCreatingChild: TaxonomyType[];
  forModifying: TaxonomyType[];
  forCreatingParent: TaxonomyType[];
}

// * Project -> Area -> Quarter -> BuildingComplex -> Building -> Wing -> Floor -> Room -> Zone
export const useAllowedTaxonomyTypes = (item?: TaxonomyReadModel): AllowedTaxonomyTypesResponse => {
  if (item == null) return { forCreatingChild: [], forModifying: [], forCreatingParent: [] };

  const result = item.types.slice(1);

  const childIdxs = item.children.map((c) => result.indexOf(c.itemType) - 1);
  const max = Math.min(...childIdxs);
  const forModifying = result.filter((_, idx) => idx <= max);

  const forCreatingChild = item.types.filter((_, idx) => idx > result.indexOf(item.itemType) + 1);
  const forCreatingParent = result.filter((_, idx) => idx < result.indexOf(item.itemType));

  return {
    forCreatingChild: forCreatingChild,
    forModifying: forModifying,
    forCreatingParent: forCreatingParent,
  };
};
