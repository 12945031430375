import { AuthCatalogElementPermissionReadModel, AuthRoleCatalogTemplateReadModel, CostCatalogElementReadModel, EarningsCatalogElementReadModel, FinancingCatalogElementReadModel, RiskCatalogElementReadModel } from "@client/shared/api";
import { ProbisPermissionState } from "../permissions";

export type TemplateElementState = 'unchanged' | 'changed' | 'new' | 'deleted';

export type TemplateElementStatus = {
    elementState: TemplateElementState,
    writePermissionState: ProbisPermissionState,
    readPermissionState: ProbisPermissionState,
    element: AuthCatalogElementPermissionReadModel
};

export type TemplateStatus = {
    template: AuthRoleCatalogTemplateReadModel,
    elements: TemplateElementStatus[]
};

export const getFontWeight = (level: number) => {
    switch (level){
        case 0:
            return 'font-bold';
        case 1:
            return 'font-medium';
        case 2:
            return 'font-light';
        default:
            return 'font-thin'
    }
}    

export const toTemplateStatus = (template: AuthRoleCatalogTemplateReadModel) : TemplateStatus => {
    return {
        template: template,
        elements: template.catalogElementPermissions.map(toTemplateElementStatus)
    }
}

export const toTemplateElementStatus = (templateElement: AuthCatalogElementPermissionReadModel) : TemplateElementStatus => {
    return {
        element: templateElement,
        elementState: "unchanged",
        readPermissionState: templateElement.canReadElement ? 'Granted' : 'Unset',
        writePermissionState: templateElement.canWriteElement ? 'Granted' : 'Unset'
    }
}

export const getCostCatalogElementChildsRecursivly = (costCatalogElement: CostCatalogElementReadModel) => {
    let childs = costCatalogElement.children;

    costCatalogElement.children.forEach( x => {
        childs = childs.concat(getCostCatalogElementChildsRecursivly(x))
    })

    return childs;
}

export const getRiskCatalogElementChildsRecursivly = (riskCatalogElement: RiskCatalogElementReadModel) => {
    let childs = riskCatalogElement.children;
    
    riskCatalogElement.children.forEach( x => {
        childs = childs.concat(getRiskCatalogElementChildsRecursivly(x))
    })

    return childs;
}

export const getEarningsCatalogElementChildsRecursivly = (earningsCatalogElement: EarningsCatalogElementReadModel) => {
    let childs = earningsCatalogElement.children;
    
    earningsCatalogElement.children.forEach( x => {
        childs = childs.concat(getEarningsCatalogElementChildsRecursivly(x))
    })

    return childs;
}

export const getFinancingCatalogElementChildsRecursivly = (financingCatalogElement: FinancingCatalogElementReadModel) => {
    let childs = financingCatalogElement.children;
    
    financingCatalogElement.children.forEach( x => {
        childs = childs.concat(getFinancingCatalogElementChildsRecursivly(x))
    })

    return childs;
}