import {
  InvoiceCalculationSchemeReadModel, useApiDeleteProjectInvoiceCalculationSchemeMutation,
  useApiDeleteTenantInvoiceCalculationSchemeMutation
} from '@client/shared/api';
import { ConfirmModal, HintBox, LoadingIndicator, Modal } from '@client/shared/toolkit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IcsType } from '.';
import { safeMutation } from '@client/shared/utilities';

export const InvoiceCoverSheetDeleteModal = ({
  sheet,
  isOpen,
  onClose,
  type,
  onAfterLeave,
  projectId
}: {
  sheet: InvoiceCalculationSchemeReadModel | null;
  isOpen: boolean;
  onClose: () => void
  type: IcsType
  onAfterLeave?: () => void;
  projectId?: string
}) => {
  const { t } = useTranslation()

  const [deleteTenantIcs, { isLoading: isDeletingTenantIcs }] = useApiDeleteTenantInvoiceCalculationSchemeMutation();
  const [deleteProjectIcs, { isLoading: isDeletingProjectIcs }] = useApiDeleteProjectInvoiceCalculationSchemeMutation();
  const [isDeleted, setIsDeleted] = useState(false)

  const handleDelete = async () => {
    setIsDeleted(false)
    if (sheet?.invoiceCalculationSchemeId) {
      if (type === 'tenant') {
        try {
          await safeMutation(
            deleteTenantIcs,
            {
              invoiceCalculationSchemeId: sheet.invoiceCalculationSchemeId
            },
            isDeletingTenantIcs
          );
          setIsDeleted(true)
          onClose();
        } catch {
          // left blank
        }
      } else if (projectId) {
        try {
          await safeMutation(
            deleteProjectIcs,
            {
              invoiceCalculationSchemeId: sheet.invoiceCalculationSchemeId,
              projectId: projectId
            },
            isDeletingProjectIcs
          );
          setIsDeleted(true)
          onClose();
        } catch {
          // left blank
        }
      }
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onAfterLeave={() => {
        if (isDeleted && onAfterLeave) {
          onAfterLeave()
        }
      }}
    >
      {(isDeletingTenantIcs || isDeletingProjectIcs) && (
        <LoadingIndicator text={t('app.settingsLoadingInvoiceCoverSheets')} mode="overlay-window" />
      )}
      {sheet && (
        <ConfirmModal
          title={t('ics.deleteInvoiceCoverSheetTitle', { name: sheet.name})}
          onConfirm={handleDelete}
          onClose={onClose}
          confirmVariant="danger"
          confirmText={t('common.delete')}
        >
          <HintBox hintType="warning">{t('ics.deleteHintMessage')}</HintBox>
        </ConfirmModal>
      )}
    </Modal>
  );
};
