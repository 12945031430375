import React, { useEffect, useState } from 'react';
import {
  ContextMenuItem,
  ContextMenu,
  LoadingIndicator,
  SlideOver,
  Modal,
  MapMarkerDottedIcon,
  PencilIcon
} from '@client/shared/toolkit';
import { PlotReadModel, useApiGetPlotsByCalculationModelQuery } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { formatUnit, stopPropagation } from '@client/shared/utilities';
import { PlotRow } from './PlotRow';
import { PlotDeleteModal } from './PlotDeleteModal';
import { PlotAddChildModal } from './PlotAddChildModal';
import { PlotHeaderSlideOver } from './PlotHeaderSlideOver';
import { PlotElementSlideOver } from './PlotElementSlideOver';
import { useLoadedProjectId } from '@client/project/store';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';

interface PlotTableProps {
  loadedVariantId?: string;
  isReadOnly: boolean;
  canDelete: boolean;
  openDashboard: string
  setIsLoading?: (loading: boolean) => void;
}

export const PlotTable = ({ loadedVariantId, isReadOnly, canDelete, openDashboard, setIsLoading }: PlotTableProps) => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();
  const navigate = useNavigate()

  const { data: plotData, isFetching } = useApiGetPlotsByCalculationModelQuery(
    {
      projectId: projectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: loadedVariantId == null },
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditPlotsModalOpen, setIsEditPlotsModalOpen] = useState(false);
  const [isEditPlotModalOpen, setIsEditPlotModalOpen] = useState(false);
  const [selectedPlot, setSelectedPlot] = useState<PlotReadModel>();

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(isFetching);
    }
  }, [isFetching, setIsLoading]);

  const handleOnDelete = (plot: PlotReadModel) => {
    setSelectedPlot(plot);
    setIsDeleteModalOpen(true);
  };

  const handleOnEdit = (plot: PlotReadModel) => {
    navigate(ROUTES_CONFIG.PROJECT_TAXONOMY_PLOT_VIEW.path.replace(':id', projectId ?? '').replace(':itemId', plot?.plotId ?? ''))
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setSelectedPlot(undefined);
  };

  const handleOnAddChild = (plot: PlotReadModel) => {
    setSelectedPlot(plot);
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
    setSelectedPlot(undefined);
  };

  useEffect(() => {
    if (plotData?.plots?.find((p) => p.plotId === selectedPlot?.plotId) !== selectedPlot) {
      setSelectedPlot(plotData?.plots?.find((p) => p.plotId === selectedPlot?.plotId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotData]);

  const contextItems: ContextMenuItem[] = [
    {
      label: t('common.update'),
      subtitle: t('projectTaxonomy.plotUpdateMenuDescription'),
      icon: <PencilIcon />,
      onClick: () => plotData && handleOnEdit(plotData),
    },
  ];

  return (
    <>
      {isFetching && !setIsLoading && <LoadingIndicator text={t('projectTaxonomy.loadingPlots')} />}
      {!isFetching && plotData && (
        <>
          <div className="bg-gray-900 shadow-lg rounded cursor-pointer">
            <div
              className="border-l-8 border-transparent cursor-pointer flex h-16 items-center justify-between ml-2 relative text-gray-100"
              onClick={() => handleOnEdit(plotData)}
            >
              <div className="flex flex-none pl-1 pr-2 w-1/3">
                <div className="pr-4 flex-none flex">
                  <MapMarkerDottedIcon className="w-8 h-8 tex-white" />
                </div>
                <div className="flex flex-col flex-grow truncate">
                  <div className="font-bold text-lg leading-none truncate">{t('projectTaxonomy.basePlotTitle')}</div>
                  <div className="text-[15px] leading-tight">
                    <span>{t('common.project')}</span>
                  </div>
                </div>
              </div>
              <div className="flex-none w-2/3 place-content-end flex gap-4 h-full pl-2">
                <div className="flex-none flex flex-col text-right justify-center w-[75px] overflow-hidden" />
                <div className="flex-1 grid grid-cols-3 gap-6 max-w-[600px]">
                  <div />
                  <div className="flex items-center justify-end text-right">
                    <div>
                      {formatUnit(plotData.maxBgf.value, plotData.maxBgf.unit)}
                      <div className="text-[10px] leading-tight text-right">
                        {t('projectTaxonomy.plotBgf')} • {t('projectTaxonomy.plotGfz')}{' '}
                        {plotData.calculationModelGfz}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-end text-right">
                    <div>
                      {formatUnit(plotData.totalPlotSize.value, plotData.totalPlotSize.unit)}
                      <div className="text-[10px] leading-tight text-right">
                        {t('projectTaxonomy.plot')} • {t('projectTaxonomy.plotGrz')} {plotData.calculationModelGrz}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center text-right flex w-[104px] flex-none" onClick={stopPropagation}>
                  <div className="flex justify-center items-center w-8 ml-6" />
                  <div className="px-3 flex items-center text-white justify-center">
                    <ContextMenu items={contextItems} className="w-6 h-6" />
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-2 divide-white divide-y text-slate-500 bg-slate-100">
              {plotData.plots?.map((subPlot) => (
                <PlotRow
                  key={subPlot.plotId}
                  plot={subPlot}
                  isReadOnly={isReadOnly}
                  canDelete={canDelete}
                  depth={0}
                  onClick={handleOnEdit}
                  onDelete={(item) => handleOnDelete(item)}
                  onAddChild={handleOnAddChild}
                  onEdit={(item) => handleOnEdit(item)}
                  openDashboard={openDashboard}
                  selectedPlot={selectedPlot}
                />
              ))}
            </div>
          </div>

          <SlideOver isOpen={isEditPlotsModalOpen} onClose={() => setIsEditPlotsModalOpen(false)}>
            <PlotHeaderSlideOver
              item={plotData}
              isReadOnly={isReadOnly}
              onClose={() => setIsEditPlotsModalOpen(false)}
            />
          </SlideOver>
        </>
      )}
      {selectedPlot && (
        <>
          <Modal isOpen={isDeleteModalOpen} onClose={handleDeleteModalClose}>
            <PlotDeleteModal onClose={handleDeleteModalClose} item={selectedPlot} />
          </Modal>

          <Modal isOpen={isAddModalOpen} onClose={handleAddModalClose}>
            <PlotAddChildModal onClose={handleAddModalClose} parent={selectedPlot} />
          </Modal>

          <SlideOver isOpen={isEditPlotModalOpen} onClose={() => setIsEditPlotModalOpen(false)}>
            <PlotElementSlideOver
              item={selectedPlot}
              isReadOnly={isReadOnly}
              canDelete={canDelete}
              onClose={() => setIsEditPlotModalOpen(false)}
            />
          </SlideOver>
        </>
      )}
    </>
  );
};
