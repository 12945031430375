import { WidgetContainer } from './WidgetContainer';
import { WidgetContainerTitle } from './WidgetContainerTitle';
import { WidgetContainerContent } from './WidgetContainerContent';
import { LoadingIndicator } from '../LoadingIndicator';
import cn from 'classnames';

export interface WidgetTableRow {
  cols: string[];
}

export interface WidgetTableProps {
  title?: string;
  tableTitles: string[];
  rows: WidgetTableRow[];
  isLoading?: boolean;
  noRowsMessage?: string;
  gridCols?: string;
}

export const WidgetTable = (props: WidgetTableProps) => {
  const {
    title,
    tableTitles,
    rows,
    isLoading = false,
    noRowsMessage,
    gridCols = 'grid-cols-4'
  } = props;
  return (
    <WidgetContainer>
      {isLoading ? (
        <LoadingIndicator className="p-4" />
      ) : (
        <>
          {title && <WidgetContainerTitle>{title}</WidgetContainerTitle>}
          <WidgetContainerContent>
            {tableTitles.length > 0 && (
              <div className={cn('grid text-[11px] text-slate-500 pb-2 border-b', gridCols)}>
                {tableTitles.map((tableTitle, index) => {
                  if (index === 0) {
                    return (
                      <div className="col-span-2" key={`widget-table-title-${index}`}>
                        {tableTitle}
                      </div>
                    );
                  }
                  return (
                    <div className="col-span-1 text-right" key={`widget-table-title-${index}`}>
                      {tableTitle}
                    </div>
                  );
                })}
              </div>
            )}
            {rows.length > 0
              ? rows.map((row, rowIndex) => (
                  <div
                    key={`widget-table-row-${rowIndex}`}
                    className={cn('grid gap-1 text-[13px] xl:text-[10px] 2xl:text-[13px] mt-2 [&:not(:last-child)]:border-b [&:not(:last-child)]:border-dotted [&:not(:last-child)]:pb-2', gridCols)}
                  >
                    {row.cols.length > 0 &&
                      row.cols.map((col, colIndex) => {
                        if (colIndex === 0) {
                          return (
                            <div className="col-span-2 font-bold" key={`widget-table-row-${rowIndex}-col-${colIndex}`}>
                              {col}
                            </div>
                          );
                        }
                        return (
                          <div className="col-span-1 text-right" key={`widget-table-row-${rowIndex}-col-${colIndex}`}>
                            {col}
                          </div>
                        );
                      })}
                  </div>
                ))
              : noRowsMessage ? (
                <div className="mt-2 italic text-slate-500 text-xs">{noRowsMessage}</div>
              ) : ''}
          </WidgetContainerContent>
        </>
      )}
    </WidgetContainer>
  );
};
