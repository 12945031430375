import { DealType, TaxonomyAvailableForDealReadModel, useApiPostCreateRentDealCostItemMutation, useApiPostCreateSellDealCostItemMutation } from '@client/shared/api';
import { useState } from 'react';
import {
  Button,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
  TodoCustomIcon
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useGetTaxonomyAvailableForDeal } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId } from '@client/project/store';

interface DealCostItemAddSelectSlideOverProps extends SlideOverOnCloseProps {
  dealId: string;
  type: DealType;
}

export const DealCostItemAddSelectSlideOver = ({ dealId, type, onClose }: DealCostItemAddSelectSlideOverProps) => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();

  const { data: taxonomy, isFetching, isSuccess } = useGetTaxonomyAvailableForDeal(dealId, type);

  const taxonomyAvailable = taxonomy?.taxonomyAvailableForDeal;

  const [createRentDeal, { isLoading: isLoadingRentDeal }] = useApiPostCreateRentDealCostItemMutation();
  const [createSellDeal, { isLoading: isLoadingSellDeal }] = useApiPostCreateSellDealCostItemMutation();

  const handleItemSelect = async (item: TaxonomyAvailableForDealReadModel) => {
    switch (type){
      case 'Rent':
        try {
          await safeMutation(
            createRentDeal,
            {
              projectId: projectId ?? 'unset',
              dealId: dealId,
              taxonomyItemId: item.taxonomyItem.itemId,
            },
            isLoadingRentDeal
          );

          onClose(true);
        } catch {
          /* left blank */
        }
        break;
      case 'Sell':
        try {
          await safeMutation(
            createSellDeal,
            {
              projectId: projectId ?? 'unset',
              dealId: dealId,
              taxonomyItemId: item.taxonomyItem.itemId,
            },
            isLoadingSellDeal
          );

          onClose(true);
        } catch {
          /* left blank */
        }
        break;
    }


  };

  return (
    <>
      <SlideOver.Header
        title={t('projectRent.dealCostItemTaxonomyTitle')}
        subTitle={t('projectRent.dealCostItemTaxonomySubTitle')}
        backgroundClassName="bg-sky-900"
        onClose={() => onClose(false)}
      />

     <SlideOver.Content>
        {(isLoadingRentDeal  || isLoadingSellDeal )  && <LoadingIndicator text={t('projectRent.dealTaxonomyItemSaving') ?? ''} mode="overlay" />}
        <div className="m-8">
          <div className="space-y-2">
            {isFetching && <LoadingIndicator />}
            {!isFetching && isSuccess && taxonomyAvailable && (
              <div className="container">
                <SelectableTaxRoot item={taxonomyAvailable} depth={0} onItemSelect={handleItemSelect} />
              </div>
            )}
            {!isFetching && isSuccess && !taxonomyAvailable && (
              <div className="container">
                <div>{t('projectRent.dealCostItemNoTaxonomyHint')}</div>
              </div>
            )}
          </div>
        </div>
      </SlideOver.Content>
      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.cancel')}
        </Button>
      </SlideOver.Controls>
    </>
  );
};

interface SelectableTaxRootProps {
  item: TaxonomyAvailableForDealReadModel;
  depth: number;
  onItemSelect: (item: TaxonomyAvailableForDealReadModel) => void;
}

const SelectableTaxRoot = ({ depth, item: { childItems, taxonomyItem }, onItemSelect }: SelectableTaxRootProps) => {
  return (
    <div className="bg-sky-900 shadow-lg rounded">
      <div className="text-gray-100 indent-6 p-6">{taxonomyItem.itemName}</div>
      <div className="flex flex-col ml-2 divide-white divide-y-2">
        {childItems != null &&
          childItems.length > 0 &&
          childItems.map((c) => (
            <SelectableTaxItem depth={depth + 1} item={c} key={c.taxonomyItem.itemId} onItemSelect={onItemSelect} />
          ))}
      </div>
    </div>
  );
};

const SelectableTaxItem = ({ depth, item, onItemSelect }: SelectableTaxRootProps) => {
  const { t } = useTranslation();

  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <div className=" bg-slate-100 font-semibold pl-0 divide-white divide-y-2">
      <div
        className="relative h-fit p-3 flex hover:bg-slate-200"
        onMouseOver={() => setIsHovering(true)}
        onMouseOut={() => setIsHovering(false)}
      >
        <div className="flex w-6/12">
          {
            <div
              style={{
                paddingRight: 25 * depth + 'px',
              }}
            ></div>
          }
          <div>
            {item.isSelectable && !item.isActivelyUsedInDeal && (
              <div className="text-sky-900/60">
                <p className="font-bol">{item.taxonomyItem.itemName}</p>
                <p className="text-sm font-extralight">{item.taxonomyItem.itemType}</p>
              </div>
            )}
            {(!item.isSelectable || item.isActivelyUsedInDeal) && (
              <div className="text-gray-500/60">
                <p className="font-bol">{item.taxonomyItem.itemName}</p>
                <p className="text-sm font-extralight">{item.taxonomyItem.itemType}</p>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row-reverse w-6/12">
          {item.isActivelyUsedInDeal && (
            <span className="inline-flex mr-2">
              <TodoCustomIcon className="w-4" />
            </span>
          )}
          {item.isSelectable && !item.isActivelyUsedInDeal && isHovering && (
            <Button variant="primary" className="mr-2" onClick={() => onItemSelect(item)}>
              {t('common.select')}
            </Button>
          )}
          {item.isSelectable && !item.isActivelyUsedInDeal && !isHovering && (
            <Button variant="secondary" className="mr-2" onClick={() => onItemSelect(item)}>
              {t('common.select')}
            </Button>
          )}
        </div>
      </div>

      {item.childItems != null &&
        item.childItems.length > 0 &&
        item.childItems.map((c) => (
          <SelectableTaxItem depth={depth + 1} item={c} key={c.taxonomyItem.itemId} onItemSelect={onItemSelect} />
        ))}
    </div>
  );
};
