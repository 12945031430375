import cn from 'classnames';
import React, { Fragment, ReactNode } from 'react';

export interface SlideOverSortableListTotalListItemProps {
  nrOfItems: number;
  gridCols: string;
  totals?: (string | ReactNode)[];
  textColor?: string;
  className?: string;
  totalLabel?: string;
  size?: 'default' | 'lg' | 'xl';
  totalSumLabel?: string;
  totalHeaders: string[];
  borderTop?: boolean;
  contextMenu?: boolean;
  showSumOnColumns?: number[];
  earlyCompact?: boolean;
}

export const SlideOverSortableListTotalListItem = ({
  nrOfItems,
  gridCols,
  totals,
  textColor = 'text-gray-400',
  className,
  totalLabel,
  size = 'default',
  totalSumLabel,
  totalHeaders,
  borderTop = true,
  contextMenu = false,
  showSumOnColumns,
  earlyCompact,
}: SlideOverSortableListTotalListItemProps) => {
  return (
    <div className={cn('flex px-6 pr-6 py-3', nrOfItems > 0 ? textColor : '', { 'border-t': borderTop }, className)}>
      {nrOfItems > 0 && totals && (
        <>
          <div
            className={cn(
              'hidden w-1/3 flex-none gap-6 items-center pr-3 font-bold leading-none',
              size !== 'default' ? 'text-lg' : '',
              {
                'md:flex': !earlyCompact,
                'lg:flex': earlyCompact,
              },
            )}
          >
            <div
              className={cn('hidden w-8 flex-none', {
                'md:block': !earlyCompact,
                'lg:block': earlyCompact,
              })}
            />
            {totalLabel}
          </div>
          <div
            className={cn(
              'flex-1 grid justify-between gap-3',
              {
                'md:pl-3': !earlyCompact,
                'lg:pl-3': earlyCompact,
              },
              gridCols,
            )}
          >
            {totals.map((total, i) => (
              <Fragment key={`sub-total-${i}`}>
                {total && (
                  <div
                    className={cn(
                      'font-bold flex flex-col gap-0.5 justify-center leading-tight whitespace-break-spaces',
                      { 'md:hidden': !earlyCompact, 'lg:hidden': earlyCompact },
                      textColor,
                      size !== 'default' ? 'text-[15px]' : 'text-[13px]',
                    )}
                  >
                    {totalHeaders && totalHeaders[i] && (showSumOnColumns ? showSumOnColumns.includes(i) : true)
                      ? totalHeaders[i]
                      : ''}
                  </div>
                )}
                <div
                  className={cn('flex flex-col justify-center gap-0.5 leading-none text-right', {
                    'hidden md:flex': !total && !earlyCompact,
                    'hidden lg:flex': !total && earlyCompact,
                    flex: total,
                  })}
                >
                  {total && (showSumOnColumns ? showSumOnColumns.includes(i) : true) && (
                    <>
                      <span
                        className={cn('font-bold leading-none', textColor, {
                          'text-lg': size === 'lg',
                          'text-[21px]': size === 'xl',
                        })}
                      >
                        {total}
                      </span>
                      <span className="text-[10px] font-light text-gray-400">{totalSumLabel}</span>
                    </>
                  )}
                </div>
              </Fragment>
            ))}
          </div>
        </>
      )}
      {contextMenu && (
        <div
          className={cn('hidden w-10 flex-none', {
            'md:block': !earlyCompact,
            'lg:block': earlyCompact,
          })}
        />
      )}
    </div>
  );
};
