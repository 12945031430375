import { 
    CostCatalogElementReadModel, 
    EarningsCatalogElementReadModel, 
    FinancingCatalogElementReadModel, 
    RiskCatalogElementReadModel 
} from '@client/shared/api';
import { useEffect, useState } from 'react';
import { RenderCatalogTemplateElement } from './RenderCatalogTemplateElement';
import { 
    TemplateElementState, 
    TemplateElementStatus, 
    getCostCatalogElementChildsRecursivly, 
    getEarningsCatalogElementChildsRecursivly, 
    getFinancingCatalogElementChildsRecursivly, 
    getRiskCatalogElementChildsRecursivly 
} from './CatalogTemplateUtilities';
import { ProbisPermissionState, getNewState } from '../permissions';

interface CatalogTemplateElementsProps {
    level: number;
    roleCatalogTemplateId: string;
    costCatalogElement?: CostCatalogElementReadModel;
    riskCatalogElement?: RiskCatalogElementReadModel;
    earningsCatalogElement?: EarningsCatalogElementReadModel;
    financingCatalogElement?: FinancingCatalogElementReadModel;
    catalogElementPermissions: TemplateElementStatus[];
    handleChangeElement: (changedElements: TemplateElementStatus[], deletedElement?: TemplateElementStatus) => void;
}

export const CatalogTemplateElements = ({
    level,
    roleCatalogTemplateId,
    costCatalogElement,
    riskCatalogElement,
    earningsCatalogElement,
    financingCatalogElement,
    catalogElementPermissions,
    handleChangeElement,
}: CatalogTemplateElementsProps) => {
    
    const [catalogElementPermission, setCatalogElementPermission] = useState<TemplateElementStatus | undefined>(undefined)
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    useEffect(() => {
        let currentPermission : TemplateElementStatus |undefined; 
        if (costCatalogElement){
            currentPermission = catalogElementPermissions.find(x => x.element.costCatalogElement?.id === costCatalogElement.id)
        }
        if (riskCatalogElement){
            currentPermission = catalogElementPermissions.find(x => x.element.riskCatalogElement?.id === riskCatalogElement.id)
        }
        if (earningsCatalogElement){
            currentPermission = catalogElementPermissions.find(x => x.element.earningsCatalogElement?.id === earningsCatalogElement.id)
        }
        if (financingCatalogElement){
            currentPermission = catalogElementPermissions.find(x => x.element.financingCatalogElement?.id === financingCatalogElement.id)
        }        
        setCatalogElementPermission(currentPermission);
    }, [costCatalogElement, riskCatalogElement, earningsCatalogElement, financingCatalogElement, catalogElementPermissions])

    const hasReadPermissionChanged = (readPermission : boolean) => {
        return readPermission !== catalogElementPermission?.element.canReadElement;
    }

    const hasWritePermissionChanged = (writePermission : boolean) => {
        return writePermission !== catalogElementPermission?.element.canWriteElement;
    }

    const handlePermissionChange = (readPermissionState : ProbisPermissionState, writePermissionState : ProbisPermissionState) => {
        const readPermission = readPermissionState === 'New' || readPermissionState === 'Granted';
        const writePermission = writePermissionState === 'New' || writePermissionState === 'Granted';
        const readHasChanged = hasReadPermissionChanged(readPermission);
        const writeHasChanged = hasWritePermissionChanged(writePermission);

        let effectedStatuses = getNewParentStatuses(!readPermission && readHasChanged, !writePermission && writeHasChanged, 
            costCatalogElement, riskCatalogElement, earningsCatalogElement, financingCatalogElement);

        if (catalogElementPermission?.elementState === 'new' && !readPermission && !writePermission){
            handleChangeElement(effectedStatuses, catalogElementPermission);
            return;
        }
        
        const elementStatus = getNewElementStatus(readPermissionState, writePermissionState);
        effectedStatuses.push(elementStatus);

        const childStatuses = getNewChildStatuses(readPermission && readHasChanged, writePermission && writeHasChanged,
            costCatalogElement, riskCatalogElement, earningsCatalogElement, financingCatalogElement) ?? [];
        effectedStatuses = effectedStatuses.concat(childStatuses);

        handleChangeElement(effectedStatuses);
    }

    const getNewElementStatus = (readPermissionState : ProbisPermissionState, writePermissionState : ProbisPermissionState) => {
        const readPermission = readPermissionState === 'New' || readPermissionState === 'Granted';
        const writePermission = writePermissionState === 'New' || writePermissionState === 'Granted';
        
        return {
            element : {
                id: catalogElementPermission?.element.id ?? '',
                roleCatalogTemplateId: roleCatalogTemplateId,
                costCatalogElement: costCatalogElement,
                riskCatalogElement: riskCatalogElement,
                earningsCatalogElement: earningsCatalogElement,
                financingCatalogElement: financingCatalogElement,
                canReadElement: readPermission,
                canWriteElement: writePermission},
            readPermissionState : readPermissionState,
            writePermissionState : writePermissionState,
            elementState : getNewElementState(readPermission, writePermission, catalogElementPermission)
        };  
    }

    const getNewElementState = (readPermission : boolean, writePermission : boolean, element : TemplateElementStatus | undefined) : TemplateElementState => {
        if (!element){
            return 'new';
        }
        
        switch (element?.elementState){
            case 'changed':
            case 'unchanged':
            case 'deleted':
                return !readPermission && !writePermission
                    ? 'deleted'
                    : 'changed';
            default:
                return 'new';
        }
    }

    const getNewParentStatuses = (updateReadPermission: boolean, updateWritePermission: boolean, costCatalogElement?: CostCatalogElementReadModel, riskCatalogElement?: RiskCatalogElementReadModel, 
        earningsCatalogElement?: EarningsCatalogElementReadModel, financingCatalogElement?: FinancingCatalogElementReadModel) => {
        if (!updateReadPermission && !updateWritePermission){
            return [];
        }
        
        const parents = getParentStatusesRecursivly(costCatalogElement, riskCatalogElement, earningsCatalogElement, financingCatalogElement);        
        return parents.map(parent => updateParentElementStatus(parent, updateReadPermission, updateWritePermission));
    }

    const getParentStatusesRecursivly = (costCatalogElement?: CostCatalogElementReadModel, riskCatalogElement?: RiskCatalogElementReadModel, 
        earningsCatalogElement?: EarningsCatalogElementReadModel, financingCatalogElement?: FinancingCatalogElementReadModel) : TemplateElementStatus[] => {
        let parent : TemplateElementStatus | undefined;
        
        if (costCatalogElement?.parentId){
            parent = catalogElementPermissions.find(x => x.element.costCatalogElement?.id === costCatalogElement?.parentId);
        }
        if (riskCatalogElement?.parentId){
            parent = catalogElementPermissions.find(x => x.element.riskCatalogElement?.id === riskCatalogElement?.parentId);
        }
        if (earningsCatalogElement?.parentId){
            parent = catalogElementPermissions.find(x => x.element.earningsCatalogElement?.id === earningsCatalogElement?.parentId);
        }
        if (financingCatalogElement?.parentId){
            parent = catalogElementPermissions.find(x => x.element.financingCatalogElement?.id === financingCatalogElement?.parentId);
        }

        if (parent){
            const parents = getParentStatusesRecursivly(parent.element.costCatalogElement ?? undefined, parent.element.riskCatalogElement ?? undefined, 
                parent.element.earningsCatalogElement ?? undefined, parent.element.financingCatalogElement ?? undefined);
            parents.push(parent);
            return parents;
        }

        return [];
    }

    const getNewChildStatuses = (updateReadPermission: boolean, updateWritePermission: boolean, costCatalogElement?: CostCatalogElementReadModel, riskCatalogElement?: RiskCatalogElementReadModel, 
        earningsCatalogElement?: EarningsCatalogElementReadModel, financingCatalogElement?: FinancingCatalogElementReadModel) => {
        if (!updateReadPermission && !updateWritePermission){
            return [];
        }
        
        if (costCatalogElement){
            const childs = getCostCatalogElementChildsRecursivly(costCatalogElement);
            return childs.map(x => getStatusByCostCatalogElement(x, updateReadPermission, updateWritePermission));
        }
        if (riskCatalogElement){
            const childs = getRiskCatalogElementChildsRecursivly(riskCatalogElement);
            return childs.map(x => getStatusByRiskCatalogElement(x, updateReadPermission, updateWritePermission));
        }
        if (earningsCatalogElement){
            const childs = getEarningsCatalogElementChildsRecursivly(earningsCatalogElement);
            return childs.map(x => getStatusByEarningsCatalogElement(x, updateReadPermission, updateWritePermission));
        }
        if (financingCatalogElement){
            const childs = getFinancingCatalogElementChildsRecursivly(financingCatalogElement);
            return childs.map(x => getStatusByFinancingCatalogElement(x, updateReadPermission, updateWritePermission));
        }
    }

    const getStatusByCostCatalogElement = (costCatalogElement: CostCatalogElementReadModel, updateReadPermission: boolean, updateWritePermission: boolean) : TemplateElementStatus => {
        const status = catalogElementPermissions.find(x => x.element.costCatalogElement?.id === costCatalogElement.id);

        if (status){
            return updateChildElementStatus(status, updateReadPermission, updateWritePermission);
        } else {
            return createNewChildElementStatus(updateReadPermission, updateWritePermission, costCatalogElement);
        }
    }

    const getStatusByRiskCatalogElement = (riskCatalogElement: RiskCatalogElementReadModel, updateReadPermission: boolean, updateWritePermission: boolean) => {
        const status = catalogElementPermissions.find(x => x.element.riskCatalogElement?.id === riskCatalogElement.id);

        if (status){
            return updateChildElementStatus(status, updateReadPermission, updateWritePermission);
        } else {
            return createNewChildElementStatus(updateReadPermission, updateWritePermission, undefined, riskCatalogElement);
        }
    }

    const getStatusByEarningsCatalogElement = (earningsCatalogElement: EarningsCatalogElementReadModel, updateReadPermission: boolean, updateWritePermission: boolean) => {
        const status = catalogElementPermissions.find(x => x.element.earningsCatalogElement?.id === earningsCatalogElement.id)

        if (status){
            return updateChildElementStatus(status, updateReadPermission, updateWritePermission);
        } else {
            return createNewChildElementStatus(updateReadPermission, updateWritePermission, undefined, undefined, earningsCatalogElement);
        }
    }

    const getStatusByFinancingCatalogElement = (financingCatalogElement: FinancingCatalogElementReadModel, updateReadPermission: boolean, updateWritePermission: boolean) => {
        const status = catalogElementPermissions.find(x => x.element.financingCatalogElement?.id === financingCatalogElement.id)

        if (status){
            return updateChildElementStatus(status, updateReadPermission, updateWritePermission);
        } else {
            return createNewChildElementStatus(updateReadPermission, updateWritePermission, undefined, undefined, undefined, financingCatalogElement)
        }        
    }    

    const createNewChildElementStatus = (updateReadPermission: boolean, updateWritePermission: boolean, 
        costCatalogElement? : CostCatalogElementReadModel, riskCatalogElement? : RiskCatalogElementReadModel, 
        earningsCatalogElement? : EarningsCatalogElementReadModel, financingCatalogElement? : FinancingCatalogElementReadModel) : TemplateElementStatus => {
        return {
            element: {
                id: '',
                roleCatalogTemplateId: roleCatalogTemplateId,
                costCatalogElement: costCatalogElement,
                riskCatalogElement: riskCatalogElement,
                earningsCatalogElement: earningsCatalogElement,
                financingCatalogElement: financingCatalogElement,
                canReadElement: updateReadPermission,
                canWriteElement: updateWritePermission 
            },
            elementState: 'new',
            readPermissionState: updateReadPermission ? 'New' : 'Unset',
            writePermissionState: updateWritePermission ? 'New' : 'Unset',
        }
    }

    const updateChildElementStatus = (status: TemplateElementStatus, updateReadPermission: boolean, updateWritePermission: boolean) : TemplateElementStatus => {
        const readPermission = updateReadPermission && !status.element.canReadElement ? true : status.element.canReadElement;
        const writePermission = updateWritePermission && !status.element.canWriteElement ? true : status.element.canWriteElement
        const readState = updateReadPermission && !status.element.canReadElement ? getNewState(status.readPermissionState) : status.readPermissionState;
        const writeState = updateWritePermission && !status.element.canWriteElement ? getNewState(status.writePermissionState) : status.writePermissionState;
        const elementState = getNewElementState(updateReadPermission ? true : status.element.canReadElement, updateWritePermission ? true : status.element.canWriteElement, status);

        return {
            element: {
                id: status.element.id,
                roleCatalogTemplateId: status.element.roleCatalogTemplateId,
                costCatalogElement: status.element.costCatalogElement,
                riskCatalogElement: status.element.riskCatalogElement,
                earningsCatalogElement: status.element.earningsCatalogElement,
                financingCatalogElement: status.element.financingCatalogElement,
                canReadElement: readPermission,
                canWriteElement: writePermission 
            },
            elementState: elementState,
            readPermissionState: readState,
            writePermissionState: writeState,
        }
    }

    const updateParentElementStatus = (status: TemplateElementStatus, updateReadPermission: boolean, updateWritePermission: boolean) : TemplateElementStatus => {
        return {
            element: {
                id: status.element.id,
                roleCatalogTemplateId: status.element.roleCatalogTemplateId,
                costCatalogElement: status.element.costCatalogElement,
                riskCatalogElement: status.element.riskCatalogElement,
                earningsCatalogElement: status.element.earningsCatalogElement,
                financingCatalogElement: status.element.financingCatalogElement,
                canReadElement: updateReadPermission && status.element.canReadElement ? false : status.element.canReadElement,
                canWriteElement: updateWritePermission && status.element.canWriteElement ? false : status.element.canWriteElement 
            },
            elementState: getNewElementState(updateReadPermission ? false : status.element.canReadElement, updateWritePermission ? false : status.element.canWriteElement, status),
            readPermissionState: updateReadPermission && status.element.canReadElement ? getNewState(status.readPermissionState) : status.readPermissionState,
            writePermissionState: updateWritePermission && status.element.canWriteElement ? getNewState(status.writePermissionState) : status.writePermissionState,
        }
    }    

    const handleExpand = () : boolean => {
        const newValue = !isExpanded
        
        setIsExpanded(newValue);
        return newValue;
    }

    return (
        <div>
            <div className="flex flex-col text-lg">
                {costCatalogElement &&
                    <>
                        <RenderCatalogTemplateElement 
                            borderColor='border-red-500'
                            code={costCatalogElement.code}
                            description={costCatalogElement.description}
                            level={level}
                            readPermission={catalogElementPermission?.readPermissionState ?? 'Unset'}
                            writePermission={catalogElementPermission?.writePermissionState ?? 'Unset'}
                            hasChildren={costCatalogElement.children.length !== 0}
                            handleChange={handlePermissionChange}
                            handleExpand={handleExpand}
                        />
                        {isExpanded &&
                            <>
                                {costCatalogElement.children.map((costCatalogChild, index) => (
                                    <CatalogTemplateElements 
                                        key={index} 
                                        level={level+1}
                                        roleCatalogTemplateId={roleCatalogTemplateId}
                                        costCatalogElement={costCatalogChild}
                                        catalogElementPermissions={catalogElementPermissions}
                                        handleChangeElement={handleChangeElement}
                                    />
                                ))}
                            </>
                        }                    
                    </>
                }
                {riskCatalogElement &&
                    <>
                        <RenderCatalogTemplateElement 
                            borderColor='border-sky-800'
                            code={riskCatalogElement.code}
                            description={riskCatalogElement.description}
                            level={level}
                            readPermission={catalogElementPermission?.readPermissionState ?? 'Unset'}
                            writePermission={catalogElementPermission?.writePermissionState ?? 'Unset'}
                            hasChildren={riskCatalogElement.children.length !== 0}
                            handleChange={handlePermissionChange}
                            handleExpand={handleExpand}
                        />
                        {isExpanded &&
                            <>
                                {riskCatalogElement.children.map((riskCatalogChild, index) => (
                                    <CatalogTemplateElements
                                        key={index} 
                                        level={level+1}
                                        roleCatalogTemplateId={roleCatalogTemplateId}
                                        riskCatalogElement={riskCatalogChild}
                                        catalogElementPermissions={catalogElementPermissions}
                                        handleChangeElement={handleChangeElement}
                                    />
                                ))}
                            </>
                        }                    
                    </>
                }
                {earningsCatalogElement &&
                    <>
                        <RenderCatalogTemplateElement 
                            borderColor='border-green-500'
                            code={earningsCatalogElement.code}
                            description={earningsCatalogElement.description}
                            level={level}
                            readPermission={catalogElementPermission?.readPermissionState ?? 'Unset'}
                            writePermission={catalogElementPermission?.writePermissionState ?? 'Unset'}
                            hasChildren={earningsCatalogElement.children.length !== 0}
                            handleChange={handlePermissionChange}
                            handleExpand={handleExpand}
                        />
                        {isExpanded &&
                            <>
                                {earningsCatalogElement.children.map((earningsCatalogChild, index) => (
                                    <CatalogTemplateElements 
                                        key={index} 
                                        level={level+1}
                                        roleCatalogTemplateId={roleCatalogTemplateId}
                                        earningsCatalogElement={earningsCatalogChild}
                                        catalogElementPermissions={catalogElementPermissions}
                                        handleChangeElement={handleChangeElement}
                                    />
                                ))}
                            </>
                        }
                    </>
                }
                {financingCatalogElement &&
                    <>  
                        <RenderCatalogTemplateElement 
                            borderColor='border-gray-700'
                            code={financingCatalogElement.code}
                            description={financingCatalogElement.description}
                            level={level}
                            readPermission={catalogElementPermission?.readPermissionState ?? 'Unset'}
                            writePermission={catalogElementPermission?.writePermissionState ?? 'Unset'}
                            hasChildren={financingCatalogElement.children.length !== 0}
                            handleChange={handlePermissionChange}
                            handleExpand={handleExpand}
                        />
                        {isExpanded &&
                            <>
                                {financingCatalogElement.children.map((financingCatalogChild, index) => (
                                    <CatalogTemplateElements 
                                        key={index}
                                        level={level+1}
                                        roleCatalogTemplateId={roleCatalogTemplateId}
                                        financingCatalogElement={financingCatalogChild}
                                        catalogElementPermissions={catalogElementPermissions}
                                        handleChangeElement={handleChangeElement}
                                    />
                                ))}
                            </>
                        }
                    </>
                }                
            </div>
        </div>
  );
};