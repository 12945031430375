import { TenantCatalogReadModel, useApiGetTenantCatalogsQuery } from '@client/shared/api';
import {
  ContextMenu, ContextMenuItem,
  LoadingIndicator,
  Modal, SettingsAddButton,
  SlideOver,
  AddIcon
} from '@client/shared/toolkit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CatalogRow } from './CatalogRow';
import { NewCatalogWizard } from './NewCatalogWizard';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { CatalogImportSlideOver } from './import';
import { SettingsHeader } from '../SettingsHeader';

export const Catalogs = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [showNewModal, setShowNewModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  const { data, isFetching } = useApiGetTenantCatalogsQuery();

  const catalogs: TenantCatalogReadModel[] = data?.tenantCatalogs ?? [];

  const contextItems: ContextMenuItem[] = [
    {
      label: t('common.create'),
      subtitle: t('app.newCatalog'),
      icon:  <AddIcon />,
      onClick: () => setShowNewModal(true),
    },
    {
      label: t('common.upload'),
      subtitle: t('app.newCatalog'),
      icon: <CloudArrowUpIcon />,
      onClick: () => setShowImportModal(true),
    }
  ];


  return (
    <div className="w-full">
      <SettingsHeader searchText={searchText} setSearchText={setSearchText}>
        {t('app.settingsCatalogs')}
      </SettingsHeader>
      <div className="relative h-full">
        <div className="flex w-full text-xs text-gray-500 pl-12 mt-4 mb-2 items-center">
          <div className="w-7/12">{t('common.name')}</div>
          <div className="w-2/12">{t('app.settingsColType')}</div>
          <div className="w-2/12">{t('app.menuProjects')}</div>
          <div className="w-1/12"></div>
        </div>

        {isFetching === true && <LoadingIndicator text={t('templates.catalogTemplatesLoadingIndicator')} />}

        <div className="border-t border-gray-200">
          {catalogs
            .filter((x) => x.name.toLowerCase().includes(searchText?.toLowerCase() ?? ''))
            .map((catalog, idx) => (
              <div key={idx} className="border-b border-gray-200">
                <CatalogRow catalog={catalog} searchText={searchText} />
              </div>
            ))}
        </div>

        <div className="flex w-full justify-end items-center">
          <ContextMenu
            items={contextItems}
            button={
              <SettingsAddButton />
            }
          />
        </div>
        <Modal isOpen={showNewModal} onClose={() => setShowNewModal(false)}>
          <NewCatalogWizard
            onClose={() => setShowNewModal(false)}
          />
        </Modal>
        <SlideOver 
          variant='large'
          isOpen={showImportModal} 
          onClose={() => setShowImportModal(false)}
        >
            <CatalogImportSlideOver onClose={() => setShowImportModal(false)}/>
        </SlideOver>
      </div>
    </div>
  );
};
