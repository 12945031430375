import { api, ApiPostCreateProjectApiResponse } from '@client/shared/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { fork, takeEvery, put } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import { i18n } from '@client/shared/utilities';
import {
  setExpandedCostCatalogIds,
  setExpandedEarningCatalogIds,
  setExpandedFinancingCatalogIds,
  setExpandedRiskCatalogIds, setToggledFilters,
  setUnitSystems,
  setVariantId,
  TOGGLED_EXPANDED_IDS, TOGGLED_FILTERS,
  TOGGLED_UNIT_SYSTEM
} from '@client/project/store';

function* onProjectCreated() {
  yield takeEvery(
    [api.endpoints.apiPostCreateProject.matchFulfilled],
    function* (_: PayloadAction<ApiPostCreateProjectApiResponse>) {
      // Todo: route to project detail screen?
      toast.success(i18n.t('project.notificationNewProjectCreated'));
      yield;
    }
  );
}

function* adjustVariantQueryParameter() {
  yield takeEvery([setVariantId], function* (action: PayloadAction<string>) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('vid', action.payload);
    const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    window.history.pushState(null, '', newRelativePathQuery);
    yield;
  });
}

function* loadVariantQueryParameterOnce() {
  const searchParams = new URLSearchParams(window.location.search);
  const vid = searchParams.get('vid');
  if (vid != null) yield put(setVariantId(vid));
  yield;
}

function* loadProjectsUnitSystem() {
  // set selected unit system
  const serializedUnitSystemState = localStorage.getItem(TOGGLED_UNIT_SYSTEM);
  if (serializedUnitSystemState) {
    const preselectedUnitSystems = JSON.parse(serializedUnitSystemState)
    yield put(setUnitSystems(preselectedUnitSystems))
  }
}

function* loadCalculateExpandedIds() {
  const serializedExpandedIds = localStorage.getItem(TOGGLED_EXPANDED_IDS);
  if (serializedExpandedIds) {
    const preselectedExpandedIds = JSON.parse(serializedExpandedIds)
    if (preselectedExpandedIds.Calculate && typeof preselectedExpandedIds.Calculate !== 'undefined') {
      if (preselectedExpandedIds.Calculate.Cost) {
        yield put(setExpandedCostCatalogIds(preselectedExpandedIds.Calculate.Cost))
      }
      if (preselectedExpandedIds.Calculate.Risk) {
        yield put(setExpandedRiskCatalogIds(preselectedExpandedIds.Calculate.Risk))
      }
      if (preselectedExpandedIds.Calculate.Earning) {
        yield put(setExpandedEarningCatalogIds(preselectedExpandedIds.Calculate.Earning))
      }
      if (preselectedExpandedIds.Calculate.Financing) {
        yield put(setExpandedFinancingCatalogIds(preselectedExpandedIds.Calculate.Financing))
      }
    }
  }
}

function* loadProjectFilter() {
  const serializedProjectFilters = localStorage.getItem(TOGGLED_FILTERS);
  if (serializedProjectFilters) {
    const preselectedToggledIds = JSON.parse(serializedProjectFilters)
    if (preselectedToggledIds.Cost && typeof preselectedToggledIds.Cost !== 'undefined') {
      yield put(setToggledFilters({ filters: preselectedToggledIds.Cost, type: 'Cost'}))
    }
    if (preselectedToggledIds.Project && typeof preselectedToggledIds.Project !== 'undefined') {
      yield put(setToggledFilters({ filters: preselectedToggledIds.Project, type: 'Project'}))
    }
    if (preselectedToggledIds.Taxonomy && typeof preselectedToggledIds.Taxonomy !== 'undefined') {
      yield put(setToggledFilters({ filters: preselectedToggledIds.Taxonomy, type: 'Taxonomy'}))
    }
  }
}

export const projectSaga = function* () {
  yield fork(onProjectCreated);
  yield fork(loadVariantQueryParameterOnce);
  yield fork(adjustVariantQueryParameter);
  yield fork(loadProjectsUnitSystem);
  yield fork(loadCalculateExpandedIds);
  yield fork(loadProjectFilter);
};
