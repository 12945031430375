import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiGetAuditLogsByKeyApiArg,
  ApiGetAuditLogsByKeyApiResponse,
  ApiGetAuditLogsByTargetApiArg,
  ApiGetAuditLogsByTargetApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiAuditLogEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetAuditLogsByKey: {
    providesTags: (_result: ApiGetAuditLogsByKeyApiResponse, _error: FetchBaseQueryError, args: ApiGetAuditLogsByKeyApiArg) => [
      { type: ApiTagTypes.AuditLogs, id: args.projectId },
      { type: ApiTagTypes.AuditLogs, id: args.key },
    ],
  },
  apiGetAuditLogsByTarget: {
    providesTags: (_result: ApiGetAuditLogsByTargetApiResponse, _error: FetchBaseQueryError, args: ApiGetAuditLogsByTargetApiArg) => [
      { type: ApiTagTypes.AuditLogs, id: args.projectId },
    ],
  },  
};
