import cn from 'classnames';
import React, { PropsWithChildren, ReactNode } from 'react';
import { CheckmarkIcon } from '../icons';

interface WorkflowStepProps extends PropsWithChildren {
  borderClassName?: string;
  className?: string;
  status?: 'active' | 'disabled' | 'success' | 'custom' | 'success-disabled';
  variant?: 'default' | 'cards';
}

export const WorkflowStep = (props: WorkflowStepProps) => {
  const { children, variant = 'default' } = props;
  return (
    <div className={cn('flex flex-grow h-full', variant === 'default' ? 'gap-4' : '')}>
      <div className="w-7 flex-none">
        <WorkflowStepNode {...props} />
      </div>
      {children && (
        <div
          className={cn('flex-1', {
            '-mt-2': variant === 'default',
            '-mt-6': variant === 'cards',
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export const WorkflowStepNode = (props: WorkflowStepProps) => {
  const { borderClassName, className, status = 'active' } = props;

  return (
    <div
      className={cn(
        'flex flex-col items-center relative h-full',
        'before:content-[""] before:min-h-[0.625rem] before:flex-none before:w-px before:h-full before:absolute before:top-0 before:left-1/2 before:-translate-x-1/2 z-0',
        {
          'before:bg-emerald-400': status === 'active' || status === 'success',
          'before:bg-slate-400': status !== 'active' && status !== 'success',
        },
        className,
      )}
    >
      {status === 'success' || status === 'success-disabled' ? (
        <CheckmarkIcon className={cn('w-5 bg-white z-10', {
          'text-emerald-400': status === 'success',
          'text-slate-400': status === 'success-disabled'
        })} />
      ) : (
        <div
          className={cn(
            'flex-none rounded-full w-2.5 h-2.5 border-2 bg-white z-10',
            {
              'border-emerald-400': status === 'active',
              'border-slate-400': status === 'disabled',
            },
            borderClassName,
          )}
        />
      )}
    </div>
  );
};

export const WorkflowInfoText = ({ label, text, children }: { label: string; text: string, children?: ReactNode }) => (
  <div className="text-[12px] text-slate-400 w-full flex">
    <div className="w-20 flex-none">{label}&nbsp;</div>
    <div className={`font-medium ${text.split(/\s+/).some((w) => w.length > 20) ? 'break-all' : 'break-words'}`}>
      {text}
    </div>
    {children}
  </div>
);
