import * as yup from 'yup';
import { InferType } from 'yup';

export const DealContactFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  role: yup.string(),
  company: yup.string(),
  email: yup.string().email('validation.email'),
  mobile: yup.string(),
});

export type DealContactFormValidationValues = InferType<typeof DealContactFormValidationSchema>;
