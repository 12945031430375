import { fork, put, take, takeEvery } from 'redux-saga/effects';
import { appError, startAppInit, finishAppInit, createAppError, setSession } from '@client/shared/store';
import { api } from '@client/shared/api';
import toast from 'react-hot-toast';
import { getCookie } from 'typescript-cookie';
import { i18n } from '@client/shared/utilities';

/**
 * The main method coordinating the application "startup".
 * If you need to execute any logic before the user can actually do something,
 * this is the right place.
 */
function* initializeApp() {
  yield take(startAppInit);

  try {
    // try to hydrate a session cookie (if exists)
    const sessionId = getCookie('session_id');

    if (sessionId != null) {
      // change app state to reflect the possible session id
      yield put(setSession(sessionId));

      // // check if the session is valid on the server (ignore cache!)
      yield put(
        api.endpoints.apiPostCheckSession.initiate(undefined, {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any
      );

      // wait for the result of the call.
      // no need to distinguish between the ok/error here, this is done in another saga
      yield take([api.endpoints.apiPostCheckSession.matchFulfilled, api.endpoints.apiPostCheckSession.matchRejected]);
    }

    // notify that the app is ready
    yield put(finishAppInit());
  } catch (err) {
    yield put(appError(createAppError(err)));
  }
}

function* onPasswordChange() {
  yield takeEvery([api.endpoints.apiPostUpdatePassword.matchFulfilled], function* () {
    toast.success(i18n.t('app.notificationPasswordChanged'));
    yield;
  });

  yield takeEvery([api.endpoints.apiPostUpdatePassword.matchRejected], function* () {
    toast.error(i18n.t('app.notificationPasswordChangeFailed'));
    yield;
  });
}

export function* appSaga() {
  yield fork(initializeApp);
  yield fork(onPasswordChange);
}
