import cn from 'classnames';
import React, { CSSProperties, PropsWithChildren, ReactElement, ReactNode, useMemo } from 'react';

export interface LegendArrowProps extends PropsWithChildren {
  title: string;
  as?: 'div' | 'li';
  icon?: ReactNode;
  onClick?: () => void;
  className?: string;
  bgColor?: string;
  direction?: 'left' | 'right';
  arrowColor?: string;
  textColor?: string;
  style?: CSSProperties;
}

export const LegendArrow = (props: LegendArrowProps) => {
  const {
    as = 'li',
    onClick,
    className,
    bgColor = 'white',
    direction = 'right',
    arrowColor = 'gray-400',
    textColor = 'text-white',
    style,
    children
  } = props;

  const directionClassNames = useMemo(() => {
    let classes = cn(
      'pr-4 pl-8',
      'after:content-[""] after:w-0 after:h-0 after:z-5 after:block',
      'after:absolute after:-right-[15px] after:top-0 after:z-[2]',
      'after:border-t-[20px] after:border-r-0 after:border-b-[20px] after:border-l-[15px]',
      'after:border-t-transparent after:border-r-transparent after:border-b-transparent',
      'before:content-[""] before:w-0 before:h-0 before:block',
      'before:absolute before:-left-0.5 before:-top-[3px] before:z-[1]',
      'before:border-t-[23px] before:border-r-0 before:border-b-[23px] before:border-l-[18px]',
      'before:border-t-transparent before:border-r-transparent before:border-b-transparent',
      `before:border-l-${bgColor}`,
    );

    if (direction === 'left') {
      classes = cn(
        'pl-4 pr-8',
        'after:content-[""] after:w-0 after:h-0 after:z-5 after:block',
        'after:absolute after:-left-[15px] after:top-0 after:z-[2]',
        'after:border-t-[20px] after:border-r-0 after:border-b-[20px] after:border-l-[15px]',
        'after:border-t-transparent after:border-r-transparent after:border-b-transparent after:rotate-180',
        'before:content-[""] before:w-0 before:h-0 before:block',
        'before:absolute before:-right-0.5 before:-top-[3px] before:z-[1] before:rotate-180',
        'before:border-t-[23px] before:border-r-0 before:border-b-[23px] before:border-l-[18px]',
        'before:border-t-transparent before:border-r-transparent before:border-b-transparent',
        `before:border-l-${bgColor}`,
      );
    }
    return classes;
  }, [direction, bgColor]);

  const classNames = useMemo(() => {
    return cn(
      'float-left flex items-center text-[15px] gap-2 relative h-10 py-2',
      directionClassNames,
      `bg-${arrowColor} after:border-l-${arrowColor}`,
      textColor,
      className,
    );
  }, [className, directionClassNames, arrowColor, textColor]);

  if (as === 'li') {
    return (
      <li className={classNames} onClick={onClick} style={style}>
        <LegendArrowContent {...props} />
        {children}
      </li>
    );
  }
  return (
    <div className={classNames} onClick={onClick} style={style}>
      <LegendArrowContent {...props} />
      {children}
    </div>
  );
};

export const LegendArrowContent = (props: LegendArrowProps) => {
  const { title, icon, textColor } = props;

  return (
    <div className={cn('flex items-center justify-center text-[15px] gap-2 relative h-10 w-full', textColor)}>
      {icon ? React.cloneElement(icon as ReactElement, { className: 'w-[18px] flex-none' }) : ''}
      <span className="truncate">{title}</span>
    </div>
  );
};
