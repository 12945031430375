import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiDeleteSellDealApiArg,
  ApiDeleteSellDealApiResponse,
  ApiDeleteSellDealContactApiArg,
  ApiDeleteSellDealContactApiResponse,
  ApiDeleteSellDealContractItemApiArg,
  ApiDeleteSellDealContractItemApiResponse,
  ApiDeleteSellDealCostItemApiArg,
  ApiDeleteSellDealCostItemApiResponse,
  ApiDeleteSellDealDocumentApiArg,
  ApiDeleteSellDealDocumentApiResponse,
  ApiGetSellDealsApiArg,
  ApiGetSellDealsApiResponse,
  ApiPostCreateSellDealApiArg,
  ApiPostCreateSellDealApiResponse,
  ApiPostCreateSellDealContactApiArg,
  ApiPostCreateSellDealContactApiResponse,
  ApiPostCreateSellDealContractItemApiArg,
  ApiPostCreateSellDealContractItemApiResponse,
  ApiPostCreateSellDealCostItemApiArg,
  ApiPostCreateSellDealCostItemApiResponse,
  ApiPostCreateSellDealDocumentApiArg,
  ApiPostCreateSellDealDocumentApiResponse,
  ApiPostReopenSellDealApiArg,
  ApiPostReopenSellDealApiResponse,
  ApiPostUpdateSellDealApiArg,
  ApiPostUpdateSellDealApiResponse,
  ApiPostUpdateSellDealContactApiArg,
  ApiPostUpdateSellDealContactApiResponse,
  ApiPostUpdateSellDealContractItemApiArg,
  ApiPostUpdateSellDealContractItemApiResponse,
  ApiPostUpdateSellDealCostItemApiArg,
  ApiPostUpdateSellDealCostItemApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiSellEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetSellDeals: {
    providesTags: (_result: ApiGetSellDealsApiResponse, error: FetchBaseQueryError, args: ApiGetSellDealsApiArg) =>
      error == null ? [{ type: ApiTagTypes.SellDeal, id: args.projectId }] : [],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateSellDeal: {
    invalidatesTags: (
      _result: ApiPostCreateSellDealApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateSellDealApiArg,
    ) => {
      return error == null
        ? [
          { type: ApiTagTypes.SellDeal, id: args.projectId },
          { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
        ]
        : []
    },
  },
  apiPostCreateSellDealContact: {
    invalidatesTags: (
      _result: ApiPostCreateSellDealContactApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateSellDealContactApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.SellDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
          ]
        : [],
  },
  apiPostCreateSellDealContractItem: {
    invalidatesTags: (
      _result: ApiPostCreateSellDealContractItemApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateSellDealContractItemApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.SellDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
          ]
        : [],
  },
  apiPostCreateSellDealCostItem: {
    invalidatesTags: (
      _result: ApiPostCreateSellDealCostItemApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateSellDealCostItemApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.SellDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
          ]
        : [],
  },
  apiPostCreateSellDealDocument: {
    invalidatesTags: (
      _result: ApiPostCreateSellDealDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateSellDealDocumentApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.SellDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
          ]
        : [],
  },
  apiDeleteSellDeal: {
    invalidatesTags: (
      _result: ApiDeleteSellDealApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteSellDealApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.SellDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
          ]
        : [],
  },
  apiDeleteSellDealContact: {
    invalidatesTags: (
      _result: ApiDeleteSellDealContactApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteSellDealContactApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.SellDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
          ]
        : [],
  },
  apiDeleteSellDealContractItem: {
    invalidatesTags: (
      _result: ApiDeleteSellDealContractItemApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteSellDealContractItemApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.SellDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
          ]
        : [],
  },
  apiDeleteSellDealCostItem: {
    invalidatesTags: (
      _result: ApiDeleteSellDealCostItemApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteSellDealCostItemApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.SellDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
          ]
        : [],
  },
  apiDeleteSellDealDocument: {
    invalidatesTags: (
      _result: ApiDeleteSellDealDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteSellDealDocumentApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.SellDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
          ]
        : [],
  },
  apiPostReopenSellDeal: {
    invalidatesTags: (
      _result: ApiPostReopenSellDealApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostReopenSellDealApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.SellDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiPostUpdateSellDeal: {
    invalidatesTags: (
      _result: ApiPostUpdateSellDealApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateSellDealApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.SellDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiPostUpdateSellDealContact: {
    invalidatesTags: (
      _result: ApiPostUpdateSellDealContactApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateSellDealContactApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.SellDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
          ]
        : [],
  },
  apiPostUpdateSellDealContractItem: {
    invalidatesTags: (
      _result: ApiPostUpdateSellDealContractItemApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateSellDealContractItemApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.SellDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
          ]
        : [],
  },
  apiPostUpdateSellDealCostItem: {
    invalidatesTags: (
      _result: ApiPostUpdateSellDealCostItemApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateSellDealCostItemApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.SellDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
          ]
        : [],
  },
};
