import { DownloadIcon, PrintIcon, AddIcon, MinusSignIcon } from '../../icons'
import { useTranslation } from 'react-i18next';
import { PropsWithChildren, ReactNode, useCallback, useMemo } from 'react';
import { formatPercentage } from '@client/shared/utilities';
import { DocumentViewerPreviewHeaderGroup } from './DocumentViewerPreviewHeaderGroup';
import { DocumentViewerPreviewHeaderButton } from './DocumentViewerPreviewHeaderButton';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

export type DocumentViewerPreviewHeaderMenuOptions = {
  zoom?: boolean
  rotate?: boolean
  fullscreen?: boolean
  pages?: boolean
  print?: boolean
  download?: boolean
}

export interface DocumentViewerPreviewHeaderProps extends PropsWithChildren {
  title?: string
  pdfFile: string
  selectedPage: number
  numPages: number
  zoomOut: () => void
  zoomIn: () => void
  goToPreviousPage: () => void
  goToNextPage: () => void
  rotate: () => void
  toggleFullscreen: () => void
  zoomLevel: number
  name: string
  customMenu?: ReactNode
  additionalMenu?: ReactNode
  menuOptions?: DocumentViewerPreviewHeaderMenuOptions
  blob?: Blob | null
}
export const DocumentViewerPreviewHeader = (props: DocumentViewerPreviewHeaderProps) => {
  const { t } = useTranslation();

  const {
    title,
    zoomOut,
    zoomIn,
    goToPreviousPage,
    goToNextPage,
    rotate,
    toggleFullscreen,
    pdfFile,
    selectedPage,
    numPages,
    zoomLevel,
    name,
    children,
    customMenu,
    additionalMenu,
    menuOptions = {},
    blob
  } = props

  const mergedMenuOptions = useMemo(() => {
    const defaultMenuOptions = {
      zoom: true,
      rotate: true,
      fullscreen: true,
      pages: true,
      print: true,
      download: true
    }
    return {...defaultMenuOptions, ...menuOptions}
  }, [menuOptions])

  const downloadFile = useCallback(() => {
    if (pdfFile) {
      const link = document.createElement("a");
      link.setAttribute('download', name);
      if (blob) {
        link.href = window.URL.createObjectURL(blob)
      } else {
        link.href = pdfFile;
      }
      document.body.appendChild(link);
      link.style.visibility = 'hidden';
      link.click();
      link.remove();
    }
  }, [pdfFile, name, blob])

  const printFile = useCallback(() => {
    if (pdfFile) {
      if (blob) {
        window.open(window.URL.createObjectURL(blob));
      } else {
        window.open(pdfFile);
      }

    }
  }, [pdfFile, blob])

  return (
    <div className="w-full flex flex-wrap bg-white py-4 px-8 items-center justify-between relative rounded-t-md">
      <div className="absolute -left-1 top-1/2 h-2/3 w-2 bg-primary rounded -translate-y-1/2" />
      {title && (
        <h4 className="text-[22px] font-bold w-1/3 truncate">{title}</h4>
      )}
      {pdfFile && customMenu}
      {pdfFile && !customMenu && (
        <>
          {mergedMenuOptions?.pages && (
            <DocumentViewerPreviewHeaderGroup>
              {numPages > 1 && (
                <DocumentViewerPreviewHeaderButton
                  buttonText={<ChevronLeftIcon className="w-5 h-5" />}
                  onClick={goToPreviousPage}
                  disabled={selectedPage === 1}
                />
              )}
              {t('projectControl.selectedPageOfTotalPages', { current: selectedPage, total: numPages })}
              {numPages > 1 && (
                <DocumentViewerPreviewHeaderButton
                  buttonText={<ChevronRightIcon className="w-5 h-5" />}
                  onClick={goToNextPage}
                  disabled={selectedPage === numPages}
                />
              )}
            </DocumentViewerPreviewHeaderGroup>
          )}
          {mergedMenuOptions?.zoom && (
            <DocumentViewerPreviewHeaderGroup>
              <DocumentViewerPreviewHeaderButton
                buttonText={<AddIcon className="h-5 w-5" title={t('projectControl.documentUploadZoomIn')} />}
                onClick={zoomIn}
              />
              {formatPercentage(zoomLevel, { maxDigits: 0 })}
              <DocumentViewerPreviewHeaderButton
                buttonText={<MinusSignIcon className="h-5 w-5"  title={t('projectControl.documentUploadZoomOut')} />}
                onClick={zoomOut}
              />
            </DocumentViewerPreviewHeaderGroup>
          )}
          {(mergedMenuOptions?.rotate || mergedMenuOptions?.fullscreen) && (
            <DocumentViewerPreviewHeaderGroup>
              {mergedMenuOptions?.rotate && (
                <DocumentViewerPreviewHeaderButton
                  buttonText={<ArrowUturnLeftIcon className="h-5 w-5" title={t('projectControl.documentRotateLeft')} />}
                  onClick={rotate}
                />
              )}
              {mergedMenuOptions?.fullscreen && (
                <DocumentViewerPreviewHeaderButton
                  buttonText={t('projectControl.documentFullscreen')}
                  onClick={toggleFullscreen}
                />
              )}
            </DocumentViewerPreviewHeaderGroup>
          )}
          {(additionalMenu || mergedMenuOptions?.rotate || mergedMenuOptions?.fullscreen) && (
            <DocumentViewerPreviewHeaderGroup>
              {additionalMenu}
              {(mergedMenuOptions?.rotate || mergedMenuOptions?.fullscreen) && (
                <>
                  {mergedMenuOptions?.print && printFile && (
                    <DocumentViewerPreviewHeaderButton
                      buttonText={<PrintIcon className="h-5 w-5"   title={t('projectControl.documentPrint')} />}
                      onClick={printFile}
                    />
                  )}
                  {mergedMenuOptions?.download && (
                    <DocumentViewerPreviewHeaderButton
                      buttonText={ <DownloadIcon className="h-5 w-5"   title={t('projectControl.documentDownload')} />}
                      onClick={downloadFile}
                    />
                  )}
                </>
              )}
            </DocumentViewerPreviewHeaderGroup>
          )}
        </>
      )}
      {children}
    </div>
  )
}
