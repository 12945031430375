import { ApiTagTypes } from '../apiTagTypes';
import {
  ApiDeleteProjectCalculationRuleApiArg,
  ApiDeleteProjectCalculationRuleApiResponse,
  ApiDeleteProjectCalculationRuleGroupApiArg,
  ApiDeleteProjectCalculationRuleGroupApiResponse,
  ApiDeleteProjectInvoiceCalculationSchemeApiArg,
  ApiDeleteProjectInvoiceCalculationSchemeApiResponse,
  ApiGetProjectInvoiceCalculationSchemesApiArg,
  ApiGetProjectInvoiceCalculationSchemesApiResponse,
  ApiPostCopyProjectInvoiceCalculationSchemeApiArg,
  ApiPostCopyProjectInvoiceCalculationSchemeApiResponse,
  ApiPostCreateProjectCalculationRuleApiArg,
  ApiPostCreateProjectCalculationRuleApiResponse,
  ApiPostCreateProjectCalculationRuleGroupApiArg,
  ApiPostCreateProjectCalculationRuleGroupApiResponse,
  ApiPostCreateProjectInvoiceCalculationSchemeApiArg,
  ApiPostCreateProjectInvoiceCalculationSchemeApiResponse,
  ApiPostMoveProjectCalculationRuleApiArg,
  ApiPostMoveProjectCalculationRuleApiResponse,
  ApiPostMoveProjectCalculationRuleGroupApiArg,
  ApiPostMoveProjectCalculationRuleGroupApiResponse,
  ApiPostUpdateProjectCalculationRuleApiArg,
  ApiPostUpdateProjectCalculationRuleApiResponse,
  ApiPostUpdateProjectCalculationRuleGroupApiArg,
  ApiPostUpdateProjectCalculationRuleGroupApiResponse,
  ApiPostUpdateProjectInvoiceCalculationSchemeApiArg,
  ApiPostUpdateProjectInvoiceCalculationSchemeApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

export const enhancedApiProjectInvoiceCalculationEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetProjectInvoiceCalculationSchemes: {
    providesTags: (
      _result: ApiGetProjectInvoiceCalculationSchemesApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetProjectInvoiceCalculationSchemesApiArg,
    ) => [
      { type: ApiTagTypes.ProjectInvoiceCoverSheets, id: args.projectId },
      { type: ApiTagTypes.ProjectInvoiceCoverSheets, id: 'LIST' },
    ],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateProjectInvoiceCalculationScheme: {
    invalidatesTags: (
      _result: ApiPostCreateProjectInvoiceCalculationSchemeApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateProjectInvoiceCalculationSchemeApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.ProjectInvoiceCoverSheets, id: args.projectId }] : []),
  },
  apiDeleteProjectInvoiceCalculationScheme: {
    invalidatesTags: (
      _result: ApiDeleteProjectInvoiceCalculationSchemeApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteProjectInvoiceCalculationSchemeApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.ProjectInvoiceCoverSheets, id: args.projectId }] : []),
  },
  apiPostUpdateProjectInvoiceCalculationScheme: {
    invalidatesTags: (
      _result: ApiPostUpdateProjectInvoiceCalculationSchemeApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateProjectInvoiceCalculationSchemeApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.ProjectInvoiceCoverSheets, id: args.projectId },
            { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId },
          ]
        : [],
  },
  apiPostCopyProjectInvoiceCalculationScheme: {
    invalidatesTags: (
      _result: ApiPostCopyProjectInvoiceCalculationSchemeApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCopyProjectInvoiceCalculationSchemeApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.ProjectInvoiceCoverSheets, id: args.projectId }] : []),
  },
  apiPostCreateProjectCalculationRuleGroup: {
    invalidatesTags: (
      _result: ApiPostCreateProjectCalculationRuleGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateProjectCalculationRuleGroupApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.ProjectInvoiceCoverSheets, id: args.projectId },
            { type: ApiTagTypes.CalculationSchemeInvoice, id: args.invoiceCalculationSchemeId }, // invalidates contract and invoice
            { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId },
            { type: ApiTagTypes.Invoices, id: args.projectId },
          ]
        : [],
  },
  apiPostUpdateProjectCalculationRuleGroup: {
    invalidatesTags: (
      _result: ApiPostUpdateProjectCalculationRuleGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateProjectCalculationRuleGroupApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.ProjectInvoiceCoverSheets, id: args.projectId },
            { type: ApiTagTypes.CalculationSchemeInvoice, id: args.invoiceCalculationSchemeId },
            { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId },
            { type: ApiTagTypes.Invoices, id: args.projectId },
          ]
        : [],
  },
  apiDeleteProjectCalculationRuleGroup: {
    invalidatesTags: (
      _result: ApiDeleteProjectCalculationRuleGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteProjectCalculationRuleGroupApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.ProjectInvoiceCoverSheets, id: args.projectId },
            { type: ApiTagTypes.CalculationSchemeInvoice, id: args.invoiceCalculationSchemeId },
            { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId },
            { type: ApiTagTypes.Invoices, id: args.projectId },
          ]
        : [],
  },
  apiPostCreateProjectCalculationRule: {
    invalidatesTags: (
      _result: ApiPostCreateProjectCalculationRuleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateProjectCalculationRuleApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.ProjectInvoiceCoverSheets, id: args.projectId },
            { type: ApiTagTypes.CalculationSchemeInvoice, id: args.invoiceCalculationSchemeId },
            { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId },
            { type: ApiTagTypes.Invoices, id: args.projectId },
          ]
        : [],
  },
  apiPostUpdateProjectCalculationRule: {
    invalidatesTags: (
      _result: ApiPostUpdateProjectCalculationRuleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateProjectCalculationRuleApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.ProjectInvoiceCoverSheets, id: args.projectId },
            { type: ApiTagTypes.CalculationSchemeInvoice, id: args.invoiceCalculationSchemeId },
            { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId },
            { type: ApiTagTypes.Invoices, id: args.projectId },
          ]
        : [],
  },
  apiDeleteProjectCalculationRule: {
    invalidatesTags: (
      _result: ApiDeleteProjectCalculationRuleApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteProjectCalculationRuleApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.ProjectInvoiceCoverSheets, id: args.projectId },
            { type: ApiTagTypes.CalculationSchemeInvoice, id: args.invoiceCalculationSchemeId },
            { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId },
            { type: ApiTagTypes.Invoices, id: args.projectId },
          ]
        : [],
  },
  apiPostMoveProjectCalculationRuleGroup: {
    invalidatesTags: (
      _result: ApiPostMoveProjectCalculationRuleGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostMoveProjectCalculationRuleGroupApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.ProjectInvoiceCoverSheets, id: args.projectId },
            { type: ApiTagTypes.CalculationSchemeInvoice, id: args.invoiceCalculationSchemeId },
            { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId },
            { type: ApiTagTypes.Invoices, id: args.projectId },
          ]
        : [],
  },
  apiPostMoveProjectCalculationRule: {
    invalidatesTags: (
      _result: ApiPostMoveProjectCalculationRuleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostMoveProjectCalculationRuleApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.ProjectInvoiceCoverSheets, id: args.projectId },
            { type: ApiTagTypes.CalculationSchemeInvoice, id: args.invoiceCalculationSchemeId },
            { type: ApiTagTypes.InvoiceCalculationSample, id: args.invoiceCalculationSchemeId },
            { type: ApiTagTypes.Invoices, id: args.projectId },
          ]
        : [],
  },
};
