import { useApiGetCalculationModelEarningsQuery } from '@client/shared/api';
import { useLoadedVariantId } from './useLoadedVariantId';
import { useLoadedProjectId } from './useLoadedProjectId';

export const useLoadedVariantEarnings = () => {
  const loadedVariantId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();

  return useApiGetCalculationModelEarningsQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? 'unset'
    },
    {
      skip: loadedVariantId == null,
    }
  );
};
