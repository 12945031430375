import {
  Button,
  Form,
  FormField,
  FormRefHandle,
  GasIndustryIcon,
  LoadingIndicator,
  Modal,
  SlideOver,
  SlideOverList,
  SlideOverOnCloseProps,
  TextInput,
  TagWindowIcon,
  ElectricityIcon, PencilIcon, TrashIcon
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { formatUnit, safeMutation } from '@client/shared/utilities';
import { useGetCurrencySymbol, useLoadedProjectId } from '@client/project/store';
import { CostElementDeleteModal, RegulatoryElementUtilitySlideOver } from '.';
import * as yup from 'yup';
import { InferType } from 'yup';
import {
  ConsumptionElementReadModel,
  useApiGetRegionEmissionFactorsByRegionQuery,
  useApiGetCostElementQuery,
  useApiPostCreateLocalLawCostElementMutation,
  useApiPostUpdateLocalLawCostElementMutation,
  QuantityReadModel,
  UtilityType,
  useApiPostCalculateConsumptionCostMutation,
  useApiPostCalculateAllConsumptionsCostMutation,
  CalculateConsumptionOverviewResponse,
} from '@client/shared/api';

export const RegulatoryElementFormValidationSchema = yup.object({
  description: yup.string().required('validation.required'),
});

type RegulatoryElementFormValidationValues = InferType<typeof RegulatoryElementFormValidationSchema>;

type NestedModal = 'None' | 'Delete';

export interface ReconstructedConsumptionUsage {
  usage: QuantityReadModel;
  year: number | null | undefined;
}

export interface RestructuredCunsumptionElement {
  consumptionElementId?: string | undefined;
  consumption: {
    value: number | null | undefined;
    unit: string;
  };
  consumptionUsages: ReconstructedConsumptionUsage[];
  taxonomies: string[];
  propertyType: {
    propertyType: string;
  };
  utilityType: UtilityType;
}

interface RegulatoryElementSlideOverProps extends SlideOverOnCloseProps {
  variantId?: string;
  regulation?: string;
  catalogElementId?: string;
  elementId?: string | null;
  disabled: boolean;
}

export const RegulatoryElementSlideOver = ({
  variantId,
  regulation,
  catalogElementId,
  elementId,
  disabled,
  onClose,
}: RegulatoryElementSlideOverProps) => {
  const { t } = useTranslation();

  const isEditing = !!elementId;

  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<FormRefHandle<RegulatoryElementFormValidationValues>>();

  const [isOpenUtilitySlideOver, setIsOpenUtilitySlideOver] = useState(false);
  const [nestedModal, setNestedModal] = useState<NestedModal>('None');
  const [selectedConsumptionElement, setSelectedConsumptionElement] = useState<
    RestructuredCunsumptionElement | undefined
  >(undefined);
  const [consumptionElements, setConsumptionElements] = useState<RestructuredCunsumptionElement[]>([]);
  const [calculatedConsumption, setCalculatedConsumption] = useState<CalculateConsumptionOverviewResponse | undefined>(
    undefined,
  );

  const currency = useGetCurrencySymbol();
  const [createLocalLawElement, { isLoading: isCreating }] = useApiPostCreateLocalLawCostElementMutation();
  const [updateLocalLawElement, { isLoading: isUpdating }] = useApiPostUpdateLocalLawCostElementMutation();
  const [calculateSingleConsumptionCost, { isLoading: isCalculatingSingle }] =
    useApiPostCalculateConsumptionCostMutation();
  const [calculateAllConsumptionCost, { isLoading: isCalculatingAll }] =
    useApiPostCalculateAllConsumptionsCostMutation();

  const projectId = useLoadedProjectId();

  const { data: regionEmissionFactors, isFetching: isFetchingEmissionFactors } = useApiGetRegionEmissionFactorsByRegionQuery({
    region: 'US',
    state: 'NY',
  });

  const {
    data: dataCostElement,
    isFetching,
    isSuccess,
  } = useApiGetCostElementQuery(
    {
      id: elementId ?? '',
      projectId: projectId ?? 'unset',
      calculationModelId: variantId ?? 'unset',
    },
    { skip: !isEditing },
  );

  const canWriteCosts = useValidateProjectPermission(['COSTS_WRITE'], projectId ?? '');

  const readOnly = !canWriteCosts || disabled;

  useEffect(() => {
    if (dataCostElement && dataCostElement.readModel.localLawElement) {
      const RestructuredCunsumptionElement = dataCostElement.readModel.localLawElement.consumptions.map(
        (element: ConsumptionElementReadModel) => ({
          consumptionElementId: element.consumptionElementId,
          consumption: {
            value: element.consumption.value,
            unit: element.consumption.unit,
          },
          consumptionUsages: element.consumptionUsages.map((usage) => ({
            usage: {
              value: usage.usage.value,
              unit: usage.usage.unit,
            },
            year: usage.year,
          })),
          taxonomies: element.taxonomies.map((taxonomy) => taxonomy.taxonomyItemId),
          propertyType: {
            propertyType: element.propertyType.propertyType,
          },
          utilityType: element.utilityType,
        }),
      );

      setConsumptionElements(RestructuredCunsumptionElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCostElement]);

  useEffect(() => {
    if (consumptionElements.length > 0) {
      handleCalculateAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumptionElements]);

  const buildPayload = (data: RegulatoryElementFormValidationValues) => {
    return {
      catalogElementId: catalogElementId !== '00000000-0000-0000-0000-000000000000' ? catalogElementId : undefined,
      description: data.description ?? '',
      consumptionPayloads: consumptionElements.map((element) => ({
        utilityType: element.utilityType as UtilityType,
        taxonomyItemsId: element.taxonomies,
        consumptionUsages: element.consumptionUsages.map((usage) => ({
          usage: usage.usage.value ?? 0,
          year: usage.year ?? 0,
        })),
        propertyTypeFactorId:
          regionEmissionFactors?.regionEmissionFactors.find(
            (factor) => factor.propertyType === element.propertyType.propertyType,
          )?.id ?? '',
      })),
    };
  };

  const handleSubmit = async (data: RegulatoryElementFormValidationValues) => {
    if (data) {
      if (isEditing) {
        try {
          await safeMutation(
            updateLocalLawElement,
            {
              id: elementId,
              projectId: projectId ?? '',
              calculationModelId: variantId ?? '',
              body: buildPayload(data),
            },
            isUpdating,
          );
          onClose(true);
        } catch (e) {
          console.error(e);
        }
        return;
      } else {
        try {
          await safeMutation(
            createLocalLawElement,
            {
              projectId: projectId ?? '',
              calculationModelId: variantId ?? '',
              body: buildPayload(data),
            },
            isCreating,
          );
          onClose(true);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const buildCalculatePayload = (element: RestructuredCunsumptionElement) => {
    return {
      utilityType: element.utilityType as UtilityType,
      taxonomyItemsId: element.taxonomies,
      consumptionUsages: element.consumptionUsages.map((usage) => ({
        usage: usage.usage.value ?? 0,
        year: usage.year ?? 0,
      })),
      propertyTypeFactorId:
        regionEmissionFactors?.regionEmissionFactors.find(
          (factor) => factor.propertyType === element.propertyType.propertyType,
        )?.id ?? '',
    };
  };

  const handleCalculateAll = async () => {
    try {
      const result = await safeMutation(
        calculateAllConsumptionCost,
        {
          projectId: projectId ?? '',
          calculationModelId: variantId ?? '',
          body: consumptionElements.map((element) => buildCalculatePayload(element)),
        },
        isCalculatingAll,
      );
      setCalculatedConsumption(result);
    } catch (e) {
      console.error(e);
    }
  };

  const handleCalculateSingle = async (element: RestructuredCunsumptionElement, index?: number) => {
    try {
      const result = await safeMutation(
        calculateSingleConsumptionCost,
        {
          projectId: projectId ?? '',
          calculationModelId: variantId ?? '',
          body: buildCalculatePayload(element),
        },
        isCalculatingSingle,
      );
      const newElement = {
        ...element,
        consumption: {
          value: result?.totalConsumption,
          unit: 'tCO2e',
        },
      };
      if (index !== undefined) {
        setConsumptionElements(consumptionElements.map((item, i) => (i === index ? newElement : item)));
      } else {
        setConsumptionElements([...consumptionElements, newElement]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onCloseNestedModal = (deleted: boolean) => {
    setNestedModal('None');

    if (deleted) {
      onClose(false);
    }
  };

  const defaultValues = {
    description: dataCostElement?.readModel.description ?? '',
  };

  const utilityTypes: {
    unit: string;
    value: string;
    label: string;
    icon: React.ReactNode;
  }[] = [
    {
      unit: 'kWh',
      value: 'Electricity',
      label: t('projectCalculate.localLaw.utilityElectricity'),
      icon: <ElectricityIcon className="w-8 h-8" />,
    },
    {
      unit: 'ft³',
      value: 'NaturalGas',
      label: t('projectCalculate.localLaw.utilityGas'),
      icon: <GasIndustryIcon className="w-8 h-8" />,
    },
    {
      unit: 'gal',
      value: 'Propane',
      label: t('projectCalculate.localLaw.utilityPropane'),
      icon: <GasIndustryIcon className="w-8 h-8" />,
    },
    {
      unit: 'gal',
      value: 'FuelOilNo2',
      label: t('projectCalculate.localLaw.utilityOilNo2'),
      icon: <GasIndustryIcon className="w-8 h-8" />,
    },
    {
      unit: 'gal',
      value: 'FuelOilNo4',
      label: t('projectCalculate.localLaw.utilityOilNo4'),
      icon: <GasIndustryIcon className="w-8 h-8" />,
    },
    {
      unit: 'lb',
      value: 'DistrictSteam',
      label: t('projectCalculate.localLaw.utilitySteam'),
      icon: <GasIndustryIcon className="w-8 h-8" />,
    },
  ];

  return (
    <>
      {isEditing && isFetching && !isSuccess ? (
        <LoadingIndicator mode="overlay-window" text={t('projectCalculate.localLaw.loadingElement')} />
      ) : (
        <>
          {(isFetchingEmissionFactors || isCreating || isUpdating || isCalculatingSingle || isCalculatingAll) && (
            <LoadingIndicator text={t('projectCalculate.localLaw.loadingElement')} mode="overlay" />
          )}
          <SlideOver.Header
            onClose={onClose}
            title={
              (isEditing ? dataCostElement?.readModel.description : t('projectCalculate.regulatory.newElement')) ?? ''
            }
            subTitle={t('projectCalculate.regulatoryElement')}
            backgroundClassName="bg-gray-700"
          />
          <Form<RegulatoryElementFormValidationValues>
            defaultValues={defaultValues}
            ref={formRef}
            className="w-full flex flex-col flex-grow min-h-0"
            onSubmit={handleSubmit}
            validationSchema={RegulatoryElementFormValidationSchema}
          >
            <SlideOver.Content className="p-8 overflow-y-scroll">
              <div className="flex flex-grow flex-col">
                <div>
                  <FormField name="description">
                    {(control) => (
                      <TextInput label={t('projectCalculate.localLaw.title')} icon={<TagWindowIcon />} {...control} />
                    )}
                  </FormField>
                </div>
                <div>
                  <SlideOverList
                    items={consumptionElements.map((element, index) => ({
                      id: index.toString(),
                      leftCenter: t('projectCalculate.localLaw.property' + element.propertyType.propertyType),
                      rightTop: utilityTypes.find((type) => type.value === element.utilityType)?.label ?? '',
                      rightCenter: formatUnit(element.consumption.value, element.consumption.unit),
                      rightBottom: t('projectCalculate.localLaw.yearlyConsumption'),
                      icon: utilityTypes.find((type) => type.value === element.utilityType)?.icon,
                      onClick: () => {
                        setSelectedConsumptionElement(consumptionElements[index]);
                        setIsOpenUtilitySlideOver(true);
                      },
                      contextMenuItems: [
                        {
                          label: t('common.edit'),
                          icon: <PencilIcon />,
                          onClick: () => {
                            setSelectedConsumptionElement(consumptionElements[index]);
                            setIsOpenUtilitySlideOver(true);
                          },
                        },
                        {
                          label: t('common.delete'),
                          icon: <TrashIcon />,
                          onClick: () => setConsumptionElements(consumptionElements.filter((_, i) => i !== index)),
                        },
                      ],
                    }))}
                    onAdd={() => {
                      setSelectedConsumptionElement(undefined);
                      setIsOpenUtilitySlideOver(true);
                    }}
                    noItemsMessage={t('projectCalculate.localLaw.addUtilityElements')}
                    disabled={readOnly}
                    children={
                      <>
                        {isCalculatingAll ? (
                          <div className="flex justify-center items-center pt-6">
                            <LoadingIndicator text={t('projectCalculate.localLaw.calculating')} />
                          </div>
                        ) : (
                          consumptionElements.length > 0 && (
                            <div className="flex justify-between px-6">
                              <div>
                                <div className="text-xs text-slate-400 text-left">
                                  {t('projectCalculate.localLaw.co2PerYear')}
                                </div>
                                <div className="text-base font-bold text-slate-400 text-right">
                                  {formatUnit(calculatedConsumption?.totalConsumption ?? 0, 'tCO2e')}
                                </div>
                              </div>
                              <div>
                                <div className="text-xs text-slate-400 text-right">
                                  {t('projectCalculate.localLaw.damagePerYear')}
                                </div>
                                <div className="text-base font-bold text-slate-400 text-right">
                                  {formatUnit(calculatedConsumption?.overAllPenaltyCost ?? 0, currency)}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </>
                    }
                  />
                </div>
              </div>
            </SlideOver.Content>
            <SlideOver.Controls>
              <div className={classNames('w-full flex justify-end', { 'justify-between': isEditing && !disabled })}>
                {isEditing && !disabled && (
                  <Button variant="warning" onClick={() => setNestedModal('Delete')}>
                    {t('common.delete')}
                  </Button>
                )}
                <div className="flex">
                  <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
                    {t('common.close')}
                  </Button>
                  <Button
                    variant="primary"
                    className="mr-2"
                    formSubmit={true}
                    innerRef={submitRef}
                    disabled={readOnly || consumptionElements.length === 0}
                  >
                    {t('common.save')}
                  </Button>
                </div>
              </div>
            </SlideOver.Controls>
          </Form>
        </>
      )}
      <SlideOver
        isOpen={isOpenUtilitySlideOver}
        onClose={() => setIsOpenUtilitySlideOver(false)}
        onAfterLeave={() => undefined}
      >
        <RegulatoryElementUtilitySlideOver
          element={selectedConsumptionElement}
          index={consumptionElements.findIndex((element) => element === selectedConsumptionElement)}
          disabled={readOnly}
          addElement={(element: RestructuredCunsumptionElement) => {
            handleCalculateSingle(element);
          }}
          updateElement={(element: RestructuredCunsumptionElement, index: number) => {
            handleCalculateSingle(element, index);
          }}
          removeElement={(index: number) => {
            setConsumptionElements(consumptionElements.filter((element, i) => i !== index));
          }}
          onClose={() => setIsOpenUtilitySlideOver(false)}
          regionEmissionFactors={regionEmissionFactors}
        />
      </SlideOver>

      <Modal isOpen={nestedModal === 'Delete'} onClose={onCloseNestedModal}>
        <CostElementDeleteModal elementId={elementId ?? ''} variantId={variantId} onClose={onCloseNestedModal} />
      </Modal>
    </>
  );
};
