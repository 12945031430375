import { ContractElementData, ContractTitleState, ContractTitleType } from '@client/shared/api';
import { Disclosure } from '@headlessui/react';
import cn from 'classnames';
import { LabeledValue, ToggleButton } from '@client/shared/toolkit';
import { ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate, formatNumber, isEmpty, partitionArray } from '@client/shared/utilities';

interface ContractImportRecordProps {
  element: ContractElementData;
  level?: number;
}

export const ContractImportRecord = ({ element, level = 0 }: ContractImportRecordProps) => {
  const { t } = useTranslation();

  const allContractUserDefinedFieldKeys = Object.keys(element.data.contractUserDefinedFields);
  const contractUserDefinedFieldKeySegments = partitionArray(allContractUserDefinedFieldKeys, 3);
  const allTitleUserDefinedFieldKeys = Object.keys(element.data.titleUserDefinedFields);
  const titleUserDefinedFieldKeySegments = partitionArray(allTitleUserDefinedFieldKeys, 3);

  const translateTitleType = (titleType : ContractTitleType | undefined | null) => {
    switch (titleType){
      case 'MainContract':
        return t('projectContract.mainContractTitle');
      case 'Supplement':
        return t('projectContract.supplementContractTitle');
      case 'Reserve':
        return t('projectContract.reserveContractTitle');
    }

    return '-';
  }

  const translateTitleState = (titleState : ContractTitleState | undefined | null) => {
    switch (titleState){
      case 'Expected':
        return t('projectContract.contractTitleStateExpected');
      case 'Received':
        return t('projectContract.contractTitleStateReceived');
      case 'Commissioned':
        return t('projectContract.contractTitleStateCommissioned');
      case 'Optional':
        return t('projectContract.contractTitleStateOptional');
      case 'Announced':
        return t('projectContract.contractTitleStateAnnounced');
      case 'Approved':
        return t('projectContract.contractTitleStateApproved');
      case 'Budgeted':
        return t('projectContract.contractTitleStateBudgeted');
      case 'Reserve':
        return t('projectContract.contractTitleStateReserve');
      case 'Canceled':
        return t('projectContract.contractTitleStateCanceled');
      case 'Rejected':
        return t('projectContract.contractTitleStateRejected');        
    }

    return '-';
  }  

  return (
    <Disclosure as="div" className="w-full" defaultOpen={false}>
      {({ open }) => (
        <>
          <Disclosure.Button as="div">
            <div
              className={cn('flex items-center h-[38px] hover:text-black hover:bg-gray-50 relative', {
                'border-b font-bold text-sm text-gray-900': level === 0,
                'text-xs text-gray-500': level > 0,
              })}
            >
              <ToggleButton className="absolute z-[5] -left-6 top-0 h-full" open={open} />
              <div className="pl-2 w-full h-full flex flex-row cursor-pointer items-center">
                <div className="flex flex-grow" style={level > 1 ? { marginLeft: `${level * 0.6}rem` } : undefined}>
                  {element.data.code} - {element.data.name}
                </div>
                {element.errors && element.errors.length > 0 && (
                  <div className="mr-2 flex justify-end text-red-600">
                    <ExclamationCircleIcon className="w-6" />
                  </div>
                )}
              </div>
            </div>
          </Disclosure.Button>
          <Disclosure.Panel>
            <div className="text-xs mb-2 ml-2">
              <div className="text-center mb-2">
                {element.errors.map((err, index) => (
                  <div key={index} className="text-red-500">
                    {t(err)}
                  </div>
                ))}
              </div>
              <div className="my-1">{t('projectContract.contract')}</div>
              <div className='ml-2'>
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <LabeledValue value={element.data.code ?? '-'}>{t('projectContract.contractCode')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={element.data.name ?? '-'}>{t('common.name')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={element.data.description ?? '-'}>{t('common.description')}</LabeledValue>
                  </div>                
                </div>
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <LabeledValue value={formatDate(element.data.contractDate)}>{t('projectContract.contractDate')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={isEmpty(element.data.contractDiscount) ? '-' : `${formatNumber(element.data.contractDiscount)} %`}>{t('projectContract.discount')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={isEmpty(element.data.contractVat) ? '-' : `${formatNumber(element.data.contractVat)} %`}>{t('common.vat')}</LabeledValue>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <LabeledValue value={formatNumber(element.data.dueDateDeadline)}>{t('projectContract.dueDateDeadline')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={formatNumber(element.data.cashDiscountDeadline)}>{t('projectContract.cashDiscountDeadline')}</LabeledValue>
                  </div>
                </div>              
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <LabeledValue value={isEmpty(element.data.commitmentCode) ? '-' : element.data.commitmentCode ?? ''}>{t('projectContract.commitment')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={isEmpty(element.data.clientCode) ? '-' : element.data.clientCode ?? ''}>{t('projectContract.contractClient')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={isEmpty(element.data.contractorCode) ? '-' : element.data.contractorCode ?? ''}>{t('projectContract.contractor')}</LabeledValue>
                  </div>
                </div>
                {allContractUserDefinedFieldKeys.length > 0 && <div className="my-1">{t('common.userDefinedFields')}</div>}
                {contractUserDefinedFieldKeySegments.map((keys, segmentIndex) => (
                  <div key={segmentIndex} className="flex flex-row">
                    {keys.map((key, userDefinedFieldIndex) =>(
                      <div key={`contract-user-defined-field${userDefinedFieldIndex}`} className="w-1/3">
                        <LabeledValue value={element.data.contractUserDefinedFields[key] ?? '-'}>{key}</LabeledValue>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="my-1">{t('projectContract.contractTitle')}</div>
              <div className='ml-2'>
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <LabeledValue value={element.data.titleCode ?? '-'}>{t('projectContract.titleCode')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={element.data.titleName ?? '-'}>{t('common.name')}</LabeledValue>
                  </div>                
                  <div className="w-1/3">
                    <LabeledValue value={element.data.titleDescription ?? '-'}>{t('common.description')}</LabeledValue>
                  </div>
                </div>  
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <LabeledValue value={translateTitleType(element.data.titleType)}>{t('projectContract.contractTitleType')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={translateTitleState(element.data.titleState)}>{t('projectContract.contractTitleState')}</LabeledValue>
                  </div>                
                </div>  
                <div className="flex flex-row">
                <div className="w-1/3">
                    <LabeledValue value={isEmpty(element.data.titleDiscount) ? '-' : `${formatNumber(element.data.titleDiscount)} %`}>{t('projectContract.discount')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={isEmpty(element.data.titleVat) ? '-' : `${formatNumber(element.data.titleVat)} %`}>{t('common.vat')}</LabeledValue>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <LabeledValue value={formatCurrency(element.data.titleOffer)}>{t('projectContract.offer')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={formatCurrency(element.data.titleApprovedOffer)}>{t('projectContract.approvedOffer')}</LabeledValue>
                  </div>                
                  <div className="w-1/3">
                    <LabeledValue value={formatCurrency(element.data.titleAdjustment)}>{t('projectContract.adjustmentValue')}</LabeledValue>
                  </div>
                </div>
                {allTitleUserDefinedFieldKeys.length > 0 && <div className="my-1">{t('common.userDefinedFields')}</div>}
                {titleUserDefinedFieldKeySegments.map((keys, segmentIndex) => (
                  <div key={segmentIndex} className="flex flex-row">
                    {keys.map((key, userDefinedFieldIndex) =>(
                      <div  key={`contract-title-user-defined-field${userDefinedFieldIndex}`} className="w-1/3">
                        <LabeledValue value={element.data.titleUserDefinedFields[key] ?? '-'}>{key}</LabeledValue>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};