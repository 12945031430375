import { useTranslation } from 'react-i18next';
import { BaseSelect, BaseSelectOption, Button, HintBox, LoadingIndicator, Modal } from '@client/shared/toolkit';
import React, { useState } from 'react';
import {
  BudgetLevel,
  CommitmentReadModel,
  ContractReadModel,
  useApiPostMoveBudgetLevelMutation,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';

interface BudgetLevelModalProps {
  contract?: ContractReadModel | null;
  commitment?: CommitmentReadModel | null;
  sourceLevel: BudgetLevel;
  hasCommitment: boolean
  onClose: () => void;
}
export const BudgetLevelModal = ({ contract, commitment, sourceLevel, hasCommitment, onClose }: BudgetLevelModalProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const [postChangeLevel, { isLoading: isChangingLevel }] = useApiPostMoveBudgetLevelMutation();
  const [selectedLevel, setSelecetedLevel] = useState<BudgetLevel | null>();

  const handleSubmit = async () => {
    if (loadedVariantId && loadedProjectId && selectedLevel) {
      try {
        await safeMutation(
          postChangeLevel,
          {
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: {
              contractId: contract?.id,
              commitmentId: commitment?.id,
              targetLevel: selectedLevel,
            },
          },
          isChangingLevel,
        );
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const levelOptions: BaseSelectOption[] = [
    {
      label: t('projectCalculate.commitment'),
      value: "Commitment",
      disabled: (sourceLevel === 'Commitment' || !hasCommitment)
    },
    {
      label: t('projectCalculate.contract'),
      value: "Contract",
      disabled: (sourceLevel === 'Contract')
    },
    {
      label: t('projectCalculate.costElementLabelTitle'),
      value: "Title",
      disabled: (sourceLevel === 'Title')
    }
  ]

  return (
    <>
      <Modal.Header title={t('projectContract.budgeting')} description={t('projectContract.budgetinglevel.description')} />
      <Modal.Content>
        {isChangingLevel && <LoadingIndicator text={t('projectContract.budgetingSaving') ?? ''} mode="overlay" />}
        {selectedLevel === 'Commitment' &&
          <HintBox hintType="warning">{t('projectContract.budgetinglevel.warning')}</HintBox>
        }
        <BaseSelect className='mt-2'
              label={t("projectContract.budgetinglevel")}
              value={selectedLevel ?? ''}
              options={levelOptions}
              onChange={(selected: string) => {
                setSelecetedLevel(selected as BudgetLevel);
              }}
            />
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" onClick={() => onClose()} className="mr-2">
          {t('common.cancel')}
        </Button>
        <Button variant="primary" disabled={!selectedLevel} onClick={() => handleSubmit()}>
          {t('common.save')}
        </Button>
      </Modal.Controls>
    </>
  );
};
