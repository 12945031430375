import * as yup from 'yup';
import { InferType } from 'yup';

export const InviteTrustedTenantFormValidationSchema = yup.object({
  companyId: yup.string().required('validation.required'),
  companyBranchId: yup.string().required('validation.required'),
  companyPersonId: yup.string().required('validation.required'),
});

export type InviteTrustedTenantFormValidationValues = InferType<typeof InviteTrustedTenantFormValidationSchema>;
