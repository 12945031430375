import { Button, LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useApiGetAssemblyVersionQuery } from '@client/shared/api';
import { SettingsHeader } from './SettingsHeader';

export const Settings = () => {
  const { t } = useTranslation();

  const { data, isFetching } = useApiGetAssemblyVersionQuery();

  return (
    <div>
      <SettingsHeader>
        {t('app.settingsTitle')}
      </SettingsHeader>
      {isFetching === true ? (
        <LoadingIndicator text={t('app.settingsLoadingAppVersion') ?? ''} />
      ) : (
        <div className="border-b pt-2 pb-6 flex">
          <div className="grow">
            <div className="text text-gray-900 mb-3 font-bold">{t('app.settingsVersion')}</div>
            <div className="text-xl font-bold text-gray-700">
              {data?.assemblyVersion} - <span className="text-green-300">{t('app.settingsLatestVersion')}</span>
            </div>
          </div>
          <div className="mt-auto">
            <Button variant="secondary" disabled={true}>
              {t('app.settingsUpdateVersion')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
