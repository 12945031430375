import {
  ContextMenu,
  ContextMenuItem,
  DatePicker,
  NumberInput,
  PercentageIcon,
  ReceiveCashIcon,
  TextInput,
  Tooltip,
  TrashIcon,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { formatDateOnly, formatNullableDateOnly } from '@client/shared/utilities';
import classNames from 'classnames';
import { ExtendedDistributionValueReadModel } from '.';

interface TimelineManualDistributionRowProps {
  item: ExtendedDistributionValueReadModel;
  minDate?: Date;
  maxDate?: Date;
  onAmountChange: (amount: number) => void;
  onPercentChange: (percentage: number) => void;
  onDescriptionChange: (description: string) => void;
  onDateChange: (date: string) => void;
  onItemDeleted: () => void;
  active?: boolean;
}

export const TimelineManualDistributionRow = ({
  item,
  minDate,
  maxDate,
  onAmountChange,
  onPercentChange,
  onDescriptionChange,
  onDateChange,
  onItemDeleted,
  active = false,
}: TimelineManualDistributionRowProps) => {
  const { t } = useTranslation();
  const [editPercentage, setEditPercentage] = useState(false);
  const [percentage, setPercentage] = useState<number | null>(0);
  const [amount, setAmount] = useState<number | null>(null);
  const [wasAlreadySelected, setWasAlreadySelected] = useState(false);
  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setAmount(item.value);
    setPercentage(item.percentage);
  }, [item]);

  const contextItems: ContextMenuItem[] = [
    {
      label: t('common.delete'),
      subtitle: t('common.deleteElement'),
      icon: <TrashIcon />,
      onClick: () => onItemDeleted(),
      isDisabled: item.isActual,
    },
  ];

  const itemDate = useMemo(() => {
    if (item?.date) {
      const parsedDate = new Date(item.date);
      if (minDate && parsedDate < minDate) {
        return minDate;
      }
      if (maxDate && parsedDate > maxDate) {
        return maxDate;
      }
    }
    return item?.date;
  }, [item.date, minDate, maxDate]);

  const roundedValue = useMemo(() => {
    return item.percentage ? item.percentage.toFixed(2).replace(/[.,]00$/, '') : 0;
  }, [item.percentage]);

  const showRoundedValue = useMemo(() => {
    return item.percentage ? roundedValue !== item.percentage.toString() : false;
  }, [roundedValue, item.percentage]);

  useEffect(() => {
    if (active && !wasAlreadySelected && rowRef?.current) {
      rowRef.current.scrollIntoView({ block: 'center' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, rowRef.current, wasAlreadySelected]);

  return (
    <div className="flex flex-row items-center">
      <div
        className={classNames('flex flex-row items-center bg-white divide-x ', {
          'border-l-4 border-l-primary': active && !wasAlreadySelected,
        })}
        onClick={() => setWasAlreadySelected(true)}
        ref={rowRef}
      >
        <div className="w-[160px] flex-none">
          <DatePicker
            required
            disabled={item.rest || item.isActual}
            minDate={minDate}
            maxDate={maxDate}
            label={t('projectCalculate.DistributionDateColumn')}
            value={itemDate}
            onChange={(date) => {
              // only update if date really changes // TODO this is just a workaround
              if (date && itemDate) {
                const d = new Date(Date.parse(date.toString()));
                const d2 = new Date(Date.parse(itemDate.toString()));
                if (d.toDateString() !== d2.toDateString()) {
                  onDateChange(formatDateOnly(date));
                }
              } else {
                onDateChange(formatNullableDateOnly(date) ?? '');
              }
            }}
          />
        </div>
        <div className="w-2/12 flex-1">
          <TextInput
            disabled={item.rest || item.isActual}
            label={t('projectCalculate.DistributionDescription')}
            value={item.description ?? ''}
            onChange={(description) => onDescriptionChange(description)}
          />
        </div>
        <div className="w-2/12 relative">
          <NumberInput
            disabled={item.isActual}
            className="w-full"
            label={t('projectCalculate.DistributionPercentage')}
            value={isNaN(item.percentage) ? 0 : item.percentage}
            onChange={(value) => {
              setPercentage(value);
              // onPercentChange(value ?? 0)
            }}
            step={1}
            allowNegative={true}
            icon={<PercentageIcon />}
            onBlur={() => {
              onPercentChange(percentage ?? 0);
              setEditPercentage(false);
            }}
            onFocus={() => setEditPercentage(true)}
          />
          {showRoundedValue && !editPercentage && (
            <div className="absolute bg-transparent pl-10 pt-5 top-0 w-full z-10 pointer-events-none">
              <span className="whitespace-nowrap font-medium text-gray-800 text-lg bg-white w-full block pr-4 truncate">
                &#126; {roundedValue}
              </span>
            </div>
          )}
        </div>
        <NumberInput
          disabled={item.isActual}
          className="w-3/12"
          inputClassName={item.description === 'VAT return' ? 'text-red-500' : ''}
          label={t('projectCalculate.DistributionValueColumn')}
          value={item.value ?? 0}
          onChange={(value) => {
            setAmount(value);
            // onAmountChange(newAmount ?? 0)
          }}
          step={1}
          decimalScale={2}
          allowNegative={true}
          icon={<ReceiveCashIcon />}
          onBlur={() => {
            // only update value when focus is lost
            onAmountChange(amount ?? 0);
          }}
        />

        <div className="text-gray-400 hover:text-gray-600 px-1">
          {!item.isActual ? (
            <ContextMenu items={contextItems} />
          ) : (
            <Tooltip
              placement="right"
              className="relative w-6 flex justify-center h-full items-center"
              label={<span>i</span>}
            >
              <span>{t('projectCalculate.ist')}</span>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};
