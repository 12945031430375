import { PlotReadModel } from '@client/shared/api';
import classNames from 'classnames';
import { Arrow, RenderNode, SizeElement, Tree, TreeNodeProps } from './Tree';
import { ReactNode, useCallback, useMemo } from 'react';
import { getSizes } from '../hooks';
import { MapMarkerDottedIcon } from '@client/shared/toolkit';

type RenderPlotNameProps = {
  plot: PlotReadModel;
  disabled?: boolean;
  icon: ReactNode;
  headerClass?: string;
  onClick?: () => void;
};

const RenderPlotName = (props: RenderPlotNameProps) => {
  return (
    <div
      className={classNames('bg-white w-full flex flex-row items-center p-1 gap-x-2 cursor-pointer', {
        'cursor-pointer': !props.disabled,
      })}
      onClick={props.onClick}
    >
      <div className="w-5 h-full">{props.icon}</div>
      <div className={classNames('text-base', props.headerClass ?? '')}>{props.plot.plotName}</div>
    </div>
  );
};

type RenderPlotProps = TreeNodeProps & {
  plot: PlotReadModel | undefined;
  plotTree: PlotReadModel[] | undefined;
  onSelectPlot: (arg: string) => void;
  disabled?: boolean;
};

export const RenderPlot = (props: RenderPlotProps) => {
  const sizes = useMemo(() => {
    return getSizes('plot').filter((s) => (props.plot?.isProjectPlot ? true : s.value === 'size'));
  }, [props]);

  const onSelectPlotWithSize = useCallback(
    (size: string) => {
      const elementsWithThisName = props.plotTree?.filter((p) => p.plotName === props.plot?.plotName);
      const elementIndex = elementsWithThisName?.findIndex((p) => p.plotId === props.plot?.plotId);
      const duplicateNameDifferentiation =
        (elementsWithThisName?.length ?? 0) <= 1 ? '' : `[${1 + (elementIndex ?? 0)}]`;

      props.onSelectPlot(`'${props.plot?.plotName}'${duplicateNameDifferentiation}.${size}`);
    },
    [props],
  );

  if (props.plot === undefined) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <RenderNode>
        <>
          <Arrow expanded={props.expanded ?? false} onClick={props.toggleExpansion} />
          <RenderPlotName
            plot={props.plot}
            icon={<MapMarkerDottedIcon />}
            onClick={props.toggleExpansion}
          />
        </>
      </RenderNode>
      {props.expanded && (
        <>
          <div className="ml-5">
            {sizes.map((size) => (
              <SizeElement
                key={size.label}
                size={size}
                onClick={() => onSelectPlotWithSize(size.label)}
                disabled={props.disabled}
              />
            ))}
          </div>
          <div className="">
            {props.plot?.plots.map((p) => (
              <Tree
                key={p.plotId}
                nodeData={p}
                renderNode={(plot, isExpanded, toggleExpansion) => (
                  <RenderPlot
                    toggleExpansion={toggleExpansion}
                    expanded={isExpanded}
                    plot={plot}
                    plotTree={props.plotTree}
                    onSelectPlot={props.onSelectPlot}
                    disabled={props.disabled}
                  />
                )}
                className="ml-5"
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
