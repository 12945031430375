import { CheckBox } from '@client/shared/toolkit';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { ProbisPermissionState, getNewState } from '../permissions';
import { getFontWeight } from './CatalogTemplateUtilities';

interface RenderCatalogTemplateElementProps {
    level: number;    
    code: string;
    description: string;
    readPermission: ProbisPermissionState;
    writePermission: ProbisPermissionState;
    borderColor: string;
    hasChildren: boolean;
    handleChange: (readPermission : ProbisPermissionState, writePermission : ProbisPermissionState) => void;
    handleExpand: () => boolean;
}

export const RenderCatalogTemplateElement = ({
    level,
    code,
    description,
    readPermission,
    writePermission,
    borderColor,
    hasChildren,
    handleChange,
    handleExpand
}: RenderCatalogTemplateElementProps) => {
    
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [canRead, setCanRead] = useState<ProbisPermissionState>(readPermission);
    const [canWrite, setCanWrite] = useState<ProbisPermissionState>(writePermission);

    useEffect(() => {
        setCanRead(readPermission);
        setCanWrite(writePermission);
    }, [readPermission, writePermission]);

    const handleCanReadChange = () => {
        const newCanRead = getNewState(canRead);
        setCanRead(newCanRead);
        
        let newCanWrite = canWrite;
        if ((newCanRead === 'Deleted' || newCanRead === 'Unset') && (canWrite === 'Granted' || canWrite === 'New')){
            setCanWrite(newCanRead);
            newCanWrite = newCanRead;
        }

        handleChange(newCanRead, newCanWrite)
    }

    const handleCanWriteChange = () => {
        const newCanWrite = getNewState(canWrite); 
        setCanWrite(newCanWrite);
        
        let newCanRead = canRead;
        if ((newCanWrite === 'Granted' || newCanWrite === 'New') && (canRead === 'Deleted' || canRead === 'Unset')){
            setCanRead(newCanWrite);
            newCanRead = newCanWrite;
        }

        handleChange(newCanRead, newCanWrite)
    }

    const getBackgroundColor = (state : ProbisPermissionState) => {
        switch (state){
            case 'Unset':
            case 'Granted':
                return 'bg-white';
            case 'New':
                return 'bg-green-500';
            case 'Deleted':
                return 'bg-red-500';
        }
    }

    return (
        <div>
            <div className="flex flex-col text-lg">
                <div className='border-b-2 border-gray-300 bg-white pr-2'>
                    <div className={classNames('flex flex-row items-center py-2 font-bold border-l-4', getFontWeight(level), borderColor)}>
                        <div className='w-1/12 pl-6' onClick={() => setIsExpanded(handleExpand())}>
                            {hasChildren &&
                                <>
                                    {isExpanded 
                                        ? (<ChevronDownIcon className='w-4 h-4' />) 
                                        : (<ChevronRightIcon className='w-4 h-4' />)}
                                </>
                            }
                        </div>
                        <div className='w-1/12'> 
                            {code}
                        </div>
                        <div className='w-8/12'> 
                            {description}
                        </div>
                        <div className='flex items-center justify-center w-1/12'>
                            <div className={classNames('flex items-center justify-center w-6 h-6', getBackgroundColor(canRead))}>
                                <CheckBox 
                                    checked={canRead === 'New' || canRead === 'Granted'}
                                    onChange={handleCanReadChange}/>
                            </div>
                        </div>
                        <div className='flex items-center justify-center w-1/12'>
                            <div className={classNames('flex items-center justify-center w-6 h-6', getBackgroundColor(canWrite))}>
                                <CheckBox 
                                    checked={canWrite === 'New' || canWrite === 'Granted'}
                                    onChange={handleCanWriteChange}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
};