import { 
    AuditLogReadModel, 
    AuditLogTarget, 
    AuditType, 
    ContractState, 
    ContractTitleState, 
    ContractTitleType, 
    InvoiceState, 
    InvoiceType 
} from "@client/shared/api";
import { UserAvatar } from "@client/shared/toolkit";
import { Trans } from "react-i18next";
import { t } from "i18next";
import classNames from "classnames";
import { formatCurrency, isEmpty } from "@client/shared/utilities";
import { 
    getInvoiceTypeLabel, 
    getContractTitleTypeLabel,
    getContractTitleStateLabel,
    getInvoiceStateLabel,
    getContractStateLabel,
} from "../../utils";

interface AuditLogListItemProps {
    auditLogEntry: AuditLogReadModel;
}

export const AuditLogListItem = ({ auditLogEntry }: AuditLogListItemProps) => {
    const valueKeys = Object.keys(auditLogEntry.newValues);

    const getUserBorderColor = (type : AuditType) => {
        switch (type){
            case "Delete":
                return "border-red-700";
            case "Create":
                return "border-green-700";
            default:
                return "border-gray-700";
        }
    }

    const formatValue = (target : AuditLogTarget, key : string, value : string) => {
        if (isEmpty(value)){
            return t('common.empty');
        }

        if (key === 'State'){
            switch (target){
                case 'Contract':
                    return getContractStateLabel(value as ContractState);
                case 'ContractTitle':
                    return getContractTitleStateLabel(value as ContractTitleState);
                case 'Invoice':
                    return getInvoiceStateLabel(value as InvoiceState);
            }

            return value;
        }

        if (key === 'Type'){
            switch (target){
                case 'ContractTitle':
                    return getContractTitleTypeLabel(value as ContractTitleType);
                case 'Invoice':
                    return getInvoiceTypeLabel(value as InvoiceType);
            }
        }

        if (key.endsWith('_Net') || key.endsWith('_Gross') || key.includes('Price') || key.includes('Total') || key === 'PenaltyCostPerYear' || key === 'Claim'){
            const parsedNumber = parseFloat(value);
            if (parsedNumber.toString() !== 'NaN'){
                return formatCurrency(parsedNumber);
            }
        }

        if (key.includes('Date')){
            const parsedDate = new Date(value);
            if (parsedDate.toString() !== "Invalid Date" && parsedDate.toString() !== "NaN"){
                return parsedDate.toLocaleDateString([], { dateStyle : 'short'});
            }
        }

        if (key === 'Vat' || key === 'Discount'){
            return `${value} %`
        }

        return value;
    }

    return (
        <div className={classNames("flex flex-col rounded-md drop-shadow-md border-l-8 bg-white my-2", getUserBorderColor(auditLogEntry.type))}>
            <div className="flex flex-row m-4">
                <UserAvatar 
                    hasAvatar={auditLogEntry.hasAvatar}
                    userId={auditLogEntry.userId}
                    userName={auditLogEntry.name}
                    lastUpdate={auditLogEntry.lastUpdate}
                />
                
                <div className="flex flex-col pl-4 text-[14px] text-slate-500">
                    <div className="flex flex-row items-center">
                        <div className="text-[16px] font-semibold text-black">
                            {auditLogEntry.name}
                        </div>
                        <div className="ml-3">
                            {new Date(auditLogEntry.dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} {t('auditLog.oClock')}
                        </div>
                    </div>
                    {auditLogEntry.type === "Create" &&
                        <>
                            {valueKeys.map((key, index) => (
                                <Trans key={index} tOptions={{ interpolation: { escapeValue: false } }} i18nKey="auditLog.setValue" values={{ name: t(`auditLog.property${key}`), value: formatValue(auditLogEntry.target, key, auditLogEntry.newValues[key] ?? '') }}>
                                    <div className="flex flex-row flex-wrap">and has set <div className="mx-1 font-bold">name</div><div className="mx-1 font-bold">value</div></div>
                                </Trans>
                            ))}
                        </>
                    }
                    {auditLogEntry.type === "Update" &&
                        <>
                            {valueKeys.map((key, index) => (
                                <Trans key={index} tOptions={{ interpolation: { escapeValue: false } }} i18nKey="auditLog.updatedValue" values={{ name: t(`auditLog.property${key}`), oldValue: formatValue(auditLogEntry.target, key, auditLogEntry.oldValues[key] ?? ''), newValue: formatValue(auditLogEntry.target, key, auditLogEntry.newValues[key] ?? '') }}>
                                    <div className="flex flex-row flex-wrap">and has updated <div className="mx-1 font-bold">name</div><div className="mx-1 font-bold">oldValue</div><div className="mx-1 font-bold">newValue</div></div>
                                </Trans>
                            ))}
                        </>
                    }                    
                </div>
            </div>
        </div>
    );
}