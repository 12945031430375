import {
  DocumentViewerFileDataGroup,
  Form,
  LoadingIndicator,
  PaidBillDottedIcon,
  SlideOverTitle,
} from '@client/shared/toolkit';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  ContractReadModel,
  InvoiceReadModel,
  InvoiceType,
  InvoiceValueDistributionType,
  ProbisErrorDataType,
  UserDefinedFieldPayload,
  useApiGetProjectSelectCompanyBranchesQuery,
  useApiPostUpdateInvoiceByContractMutation,
  useApiPostUpdateInvoiceMutation, SelectContractInvoiceCalculationSchemeReadModel,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import {
  InvoiceCreateFormValidationSchema,
  InvoiceCreateFormValidationValues,
} from '../InvoiceCreateFormValidationValues';
import { InvoiceContractorAndClientEdit } from './InvoiceContractorAndClientEdit';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { InvoiceDataEdit } from './InvoiceDataEdit';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { InvoiceEditContext } from '../InvoiceEditContextProvider';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import cn from 'classnames';
import { formatDateOnly, safeMutation } from '@client/shared/utilities';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { InvoiceContractInvoiceList } from './InvoiceContractInvoiceList';
import { InvoiceTitlesList } from './InvoiceTitlesList';
import { InvoiceCoverSheetEdit } from './InvoiceCoverSheetEdit';
import { InlineEditUserDefinedFields } from '../../UserDefinedFields';

interface InvoiceDataProps {
  invoice: InvoiceReadModel;
  authorizedToReview: boolean;
  contract?: ContractReadModel;
  setIsLoading: (isLoading: boolean) => void;
}

export const InvoiceData = (props: InvoiceDataProps) => {
  const { invoice, authorizedToReview, contract, setIsLoading } = props;

  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');

  const [updateInvoice, { isLoading: isUpdating }] = useApiPostUpdateInvoiceMutation();
  const [updateInvoiceByContract, { isLoading: isUpdatingByContract }] = useApiPostUpdateInvoiceByContractMutation();

  const { data: branches, isFetching: isLoadingBranches } = useApiGetProjectSelectCompanyBranchesQuery(
    {
      projectId: loadedProjectId ?? '',
    },
    {
      skip: !loadedProjectId,
    },
  );

  const { setIsEditMode, formRef, updateUnsavedData, setUnsavedData, setIsSaving, setIsValid, setIsUpdated } =
    useContext(InvoiceEditContext);

  const [codeError, setCodeError] = useState(false);
  const [allowChangeMode, setAllowChangeMode] = useState(true);
  const [budgetAssignment, setBudgetAssignment] = useState<string | undefined>(undefined);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [customFieldsAreValid, setCustomFieldsAreValid] = useState(true);
  const [udfUpdatePayload, setUdfUpdatePayload] = useState<UserDefinedFieldPayload[] | undefined>(undefined);

  const canEdit = useMemo(() => {
    return (
      canWrite &&
      !invoice.isPxInvoice &&
      (invoice.hasWorkflow ? authorizedToReview : true) &&
      !invoice.isWaitingForNextTask
    );
  }, [canWrite, invoice.isPxInvoice, invoice.hasWorkflow, authorizedToReview, invoice.isWaitingForNextTask]);

  const handleError = (e: unknown) => {
    const error = e as FetchBaseQueryError;
    const data = error.data as ProbisErrorDataType;
    if (data?.code === 'error.invoice.code_already_exists') {
      setCodeError(true);
    }
    setIsSaving(false);
    setIsValid(false);
  };

  const reset = () => {
    setUnsavedData([]);
    setIsSaving(false);
    setIsUpdated(true);
    setIsValid(true);
  };

  const handleSubmit = async (data: InvoiceCreateFormValidationValues) => {
    setIsFormSubmitted(true);
    setCodeError(false);
    if (customFieldsAreValid) setIsValid(true);
    if (loadedProjectId && loadedVariantId && customFieldsAreValid) {
      if (data.contractId) {
        try {
          await safeMutation(
            updateInvoiceByContract,
            {
              invoiceId: invoice.id,
              contractId: data.contractId,
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              body: {
                calculationSchemeId: data.calculationSchemeId,
                sequentialNumber: invoice.sequentialNumber ?? 1,
                code: data.code,
                externalCode: data.externalCode,
                type: data.type,
                distributionType:
                  (data.distributionType as InvoiceValueDistributionType) ?? invoice.distributionType ?? 'Manual',
                vat: data.vat,
                claim: data.claim,
                invoiceValue: data.invoiceValue ?? invoice.invoiceValueNet ?? 0,
                dueDate: data.dueDate ? formatDateOnly(new Date(data.dueDate)) : null,
                cashDiscountDate: data.cashDiscountDate ? formatDateOnly(new Date(data.cashDiscountDate)) : null,
                invoiceDate: data.invoiceDate ? formatDateOnly(new Date(data.invoiceDate)) : formatDateOnly(new Date()),
                dateOfReceipt: data.dateOfReceipt
                  ? formatDateOnly(new Date(data.dateOfReceipt))
                  : formatDateOnly(new Date()),
                dateOfAudit: data.dateOfAudit ? formatDateOnly(new Date(data.dateOfAudit)) : undefined,
                dateOfApproval: data.dateOfApproval ? formatDateOnly(new Date(data.dateOfApproval)) : undefined,
                paymentDate:
                  invoice.state === 'Paid' && data.paymentDate ? formatDateOnly(new Date(data.paymentDate)) : null,
                addedDocuments: [],
                deletedDocuments: [],
                comment: data.comment,
                userDefinedFieldsPayload: udfUpdatePayload,
              },
            },
            isUpdatingByContract,
          );
          reset();
        } catch (e) {
          handleError(e);
        }
      } else {
        try {
          await safeMutation(
            updateInvoice,
            {
              invoiceId: invoice.id,
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              body: {
                invoicingPartyId: data.invoicingPartyId,
                invoiceRecipientId: data.invoiceRecipientId,
                calculationSchemeId: data.calculationSchemeId,
                sequentialNumber: invoice.sequentialNumber ?? 1,
                code: data.code,
                externalCode: data.externalCode,
                type: data.type,
                distributionType:
                  (data.distributionType as InvoiceValueDistributionType) ?? invoice.distributionType ?? 'Manual',
                vat: data.vat,
                claim: data.claim,
                invoiceValue: data.invoiceValue ?? invoice.invoiceValueNet ?? 0,
                dueDate: data.dueDate ? formatDateOnly(new Date(data.dueDate)) : null,
                cashDiscountDate: data.cashDiscountDate ? formatDateOnly(new Date(data.cashDiscountDate)) : null,
                invoiceDate: data.invoiceDate ? formatDateOnly(new Date(data.invoiceDate)) : formatDateOnly(new Date()),
                dateOfReceipt: data.dateOfReceipt
                  ? formatDateOnly(new Date(data.dateOfReceipt))
                  : formatDateOnly(new Date()),
                dateOfAudit: data.dateOfAudit ? formatDateOnly(new Date(data.dateOfAudit)) : undefined,
                dateOfApproval: data.dateOfApproval ? formatDateOnly(new Date(data.dateOfApproval)) : undefined,
                paymentDate:
                  invoice.state === 'Paid' && data.paymentDate ? formatDateOnly(new Date(data.paymentDate)) : null,
                addedDocuments: [],
                deletedDocuments: [],
                costElementId: budgetAssignment,
                comment: data.comment,
                userDefinedFieldsPayload: udfUpdatePayload,
              },
            },
            isUpdating,
          );
          reset();
        } catch (e) {
          handleError(e);
        }
      }
    }
  };

  const defaultFormValues = {
    invoicingPartyId: invoice.invoicingParty?.id ?? null,
    invoiceRecipientId: invoice.invoiceRecipient?.id ?? null,
    calculationSchemeId: invoice.calculationSchemeId ?? null,
    contractId: invoice.contractId ?? null,
    code: invoice.code ?? '',
    externalCode: invoice.externalCode ?? '',
    type: invoice.type ?? ('Single' as InvoiceType),
    dateOfReceipt: invoice.dateOfReceipt,
    invoiceDate: invoice.invoiceDate,
    dateOfAudit: invoice.dateOfAudit,
    dateOfApproval: invoice.dateOfApproval,
    paymentDate: invoice.paymentDate,
    vat: invoice.vat,
    claim: invoice.claim,
    dueDate: invoice.dueDate,
    cashDiscountDate: invoice.cashDiscountDate,
    comment: invoice.comment,
    invoiceValue: invoice.valueNet,
    distributionType: invoice.distributionType,
  };

  const contractor = useMemo(() => {
    return invoice.invoicingParty 
      ? branches?.find((branch) => branch.id === invoice.invoicingParty?.id) ?? null 
      : null;
  }, [invoice?.invoicingParty, branches]);

  const client = useMemo(() => {
    return invoice.invoiceRecipient
      ? branches?.find((branch) => branch.id === invoice.invoiceRecipient?.id) ?? null
      : null;
  }, [invoice?.invoiceRecipient, branches]);

  useEffect(() => {
    setBudgetAssignment(invoice?.budgetAssignment?.costElementId);
  }, [invoice?.budgetAssignment?.costElementId]);

  const invoiceCalculationSchemes: SelectContractInvoiceCalculationSchemeReadModel[] = useMemo(() => {
    if (contract?.invoiceCalculationSchemes.length) {
      return contract.invoiceCalculationSchemes.map((scheme) => {
        return {
          id: scheme.invoiceCalculationScheme.invoiceCalculationSchemeId,
          name: scheme.invoiceCalculationScheme.name,
          type: scheme.invoiceCalculationScheme.type,
        };
      }) as SelectContractInvoiceCalculationSchemeReadModel[];
    }
    return [];
  }, [contract?.invoiceCalculationSchemes]);

  useEffect(() => {
    setIsLoading(isLoadingBranches);
  }, [isLoadingBranches, setIsLoading]);

  return (
    <Form<InvoiceCreateFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={InvoiceCreateFormValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col gap-7"
      ref={formRef}
    >
      {(isUpdating || isUpdatingByContract) && (
        <LoadingIndicator text={t('projectControl.savingInvoiceLoadingIndicator')} mode="overlay" />
      )}
      {/* CONTRACTOR & CLIENT (for contract invoice see InvoiceContract) */}
      {!invoice.contractId && (
        <InvoiceContractorAndClientEdit
          formRef={formRef}
          labelContractor={t('projectControl.invoicingParty')}
          labelClient={t('projectControl.invoiceRecipient')}
          originalContractor={contractor}
          originalClient={client}
          branches={branches}
          canEdit={canEdit}
          updateEditMode={setIsEditMode}
          updateUnsavedData={updateUnsavedData}
        />
      )}

      {/* CALCULATION SCHEME */}
      <DocumentViewerFileDataGroup className="relative">
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <DisclosureButton>
                <div className={cn('flex gap-2 cursor-pointer', open ? 'pb-2' : '')}>
                  <PaidBillDottedIcon className="w-9 flex-none" />
                  <SlideOverTitle
                    marginTop={false}
                    title={t('projectControl.createWizard.slideInvoiceBillingTitle')}
                    className="mt-4 flex-1 flex justify-between hover:text-gray-800 duration-300 transition-colors"
                  >
                    <ChevronDownIcon
                      className={classNames(
                        'transition-transform will-change-transform duration-100 transform -rotate-90 h5 w-5 flex-none',
                        {
                          'rotate-0': open,
                        },
                      )}
                    />
                  </SlideOverTitle>
                </div>
              </DisclosureButton>
              <DisclosurePanel as="div" className="flex flex-col divide-y-2">
                <InvoiceCoverSheetEdit
                  invoice={invoice}
                  contractId={contract?.id ?? invoice?.contractId}
                  contractHasFinalInvoice={contract ? contract?.invoices.some((x) => x.type === 'Final') : undefined}
                  invoiceCalculationSchemes={invoiceCalculationSchemes}
                  defaultFormValues={defaultFormValues}
                  allowChangeMode={allowChangeMode}
                  setAllowChangeMode={setAllowChangeMode}
                  canEdit={canEdit}
                  setIsEditMode={setIsEditMode}
                  updateUnsavedData={updateUnsavedData}
                  formRef={formRef}
                />
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      </DocumentViewerFileDataGroup>

      {/* INVOICE DATA */}
      <DocumentViewerFileDataGroup className="relative">
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <DisclosureButton>
                <div className={cn('flex gap-2 cursor-pointer', open ? 'pb-2' : '')}>
                  <PaidBillDottedIcon className="w-9 flex-none" />
                  <SlideOverTitle
                    marginTop={false}
                    title={t('projectControl.invoice')}
                    className="mt-4 flex-1 flex justify-between hover:text-gray-800 duration-300 transition-colors"
                  >
                    <ChevronDownIcon
                      className={classNames(
                        'transition-transform will-change-transform duration-100 transform -rotate-90 h5 w-5 flex-none',
                        {
                          'rotate-0': open,
                        },
                      )}
                    />
                  </SlideOverTitle>
                </div>
              </DisclosureButton>
              <DisclosurePanel as="div" className="flex flex-col divide-y-2">
                <InvoiceDataEdit
                  invoice={invoice}
                  contract={contract}
                  codeError={codeError}
                  defaultFormValues={defaultFormValues}
                  allowChangeMode={allowChangeMode}
                  setAllowChangeMode={setAllowChangeMode}
                  canEdit={canEdit}
                  setIsEditMode={setIsEditMode}
                  updateUnsavedData={updateUnsavedData}
                  budgetAssignment={budgetAssignment}
                  setBudgetAssignment={setBudgetAssignment}
                />
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      </DocumentViewerFileDataGroup>

      {/* INVOICE TITLES */}
      <DocumentViewerFileDataGroup className="relative">
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <DisclosureButton>
                <div className={cn('flex gap-2 cursor-pointer', open ? 'pb-2' : '')}>
                  <PaidBillDottedIcon className="w-9 flex-none" />
                  <SlideOverTitle
                    marginTop={false}
                    title={t('projectControl.invoiceTitles')}
                    className="mt-4 flex-1 flex justify-between hover:text-gray-800 duration-300 transition-colors"
                  >
                    <ChevronDownIcon
                      className={classNames(
                        'transition-transform will-change-transform duration-100 transform -rotate-90 h5 w-5 flex-none',
                        {
                          'rotate-0': open,
                        },
                      )}
                    />
                  </SlideOverTitle>
                </div>
              </DisclosureButton>
              <DisclosurePanel as="div" className="flex flex-col divide-y-2">
                {contract && contract.invoices.length > 1 ? (
                  <InvoiceContractInvoiceList
                    contract={contract}
                    currentInvoice={invoice}
                    saveDistribution={() => formRef?.current?.submitForm()}
                  />
                ) : (
                  <InvoiceTitlesList
                    invoice={invoice}
                    contract={contract}
                    authorizedToReview={authorizedToReview}
                    saveDistribution={() => formRef?.current?.submitForm()}
                  />
                )}
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      </DocumentViewerFileDataGroup>

      {/* UDF */}
      <InlineEditUserDefinedFields
        elementId={invoice.id}
        type="Invoice"
        setUpdatePayload={(payload) => {
          setUdfUpdatePayload(payload);
        }}
        isSubmitted={isFormSubmitted}
        updateIsValid={setCustomFieldsAreValid}
        allowChangeMode={allowChangeMode}
        setAllowChangeMode={setAllowChangeMode}
        setIsEditMode={setIsEditMode}
        setUnsavedData={(unsaved) => {
          updateUnsavedData('userDefinedFields', !unsaved);
        }}
        canEdit={canEdit}
      />
    </Form>
  );
};
