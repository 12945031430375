import React, { PropsWithChildren } from 'react';
import { Transition } from '@headlessui/react';

interface ElementTransitionProps extends PropsWithChildren {
  show?: boolean;
}

export const ElementTransition = ({ children, show }: ElementTransitionProps) => (
  <Transition
    show={show}
    enter="transition duration-75 ease-out"
    enterFrom="transform scale-95 opacity-0"
    enterTo="transform scale-100 opacity-100"
    leave="transition duration-75 ease-out"
    leaveFrom="transform scale-100 opacity-100"
    leaveTo="transform scale-95 opacity-0"
    as={React.Fragment}
  >
    {children}
  </Transition>
);
