import { HintBox, ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { useApiDeleteFinancingElementMutation } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';

interface FinancingElementDeleteModalProps extends ModalOnCloseProps {
  elementId?: string;
  variantId?: string;
  setIsDeleting?: (isDeleting: boolean) => void;
}

export const FinancingElementDeleteModal = ({
  elementId,
  onClose,
  variantId,
  setIsDeleting,
}: FinancingElementDeleteModalProps) => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();

  const [call, {isLoading: isDeleting}] = useApiDeleteFinancingElementMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    if (elementId && variantId) {
      if (setIsDeleting) {
        setIsDeleting(true);
      }
      try {
        await safeMutation(
          call,
          {
            id: elementId,
            calculationModelId: variantId,
            projectId: projectId ?? 'unset',
          },
          isDeleting,
        );

        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  return (
    <ConfirmModal
      title={t('projectCalculate.financingElementDeleteTitle')}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      {isDeleting && <LoadingIndicator text={t('projectCalculate.elementDeleteLoading') ?? ''} mode="overlay" />}
      <HintBox hintType="warning">{t('projectCalculate.financingElementDeleteHint')}</HintBox>
    </ConfirmModal>
  );
};
