import * as yup from 'yup';
import { InferType } from 'yup';
import { EarningsType, TenantCatalogType } from '@client/shared/api';

export const UpdateCatalogElementFormValidationSchema = yup.object({
  parentId: yup.string().optional().nullable(),
  codeMask: yup.string().optional().nullable(),
  code: yup.string().nullable()
    .when('codeMask', {
      is: (codeMask: string) => (codeMask !== '' || codeMask !== null),
      then: (schema) => schema.required('validation.required'),
      otherwise: (schema) => schema.optional().nullable()
    }),
  order: yup.number().nullable()
    .when('codeMask', {
      is: (codeMask: string) => (codeMask !== '' || codeMask !== null),
      then: (schema) => schema.required('validation.required'),
      otherwise: (schema) => schema.optional().nullable()
    }),
  description: yup.string().required('validation.required'),
  longDescription: yup.string().optional().nullable(),
  type: yup.mixed<TenantCatalogType>().required(),
  earningsType: yup.mixed<EarningsType>()
    .when('type', {
      is: (type: TenantCatalogType) => (type === 'Earnings'),
      then: (schema) => schema.required('validation.required'),
      otherwise: (schema) => schema.optional().nullable()
    }),
  assignable: yup.boolean()
    .when('type', {
      is: (type: TenantCatalogType) => (type === 'Finance'),
      then: (schema) => schema.required('validation.required'),
      otherwise: (schema) => schema.optional().nullable()
    })
});

export type UpdateCatalogElementFormValidationValues = InferType<typeof UpdateCatalogElementFormValidationSchema>;
