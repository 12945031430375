import { FinancingPaybackPlanElementReadModel } from '@client/shared/api';
import {
  ContextMenu,
  DatePicker,
  ContextMenuItem,
  NumberInput,
  TextInput,
  ReceiveCashIcon,
  TrashIcon
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { formatDateOnly } from '@client/shared/utilities';
import { useGetCurrency } from '@client/project/store';

interface RenderFinancingPaybackElementProps {
  item: FinancingPaybackPlanElementReadModel;
  onItemChange: (item: FinancingPaybackPlanElementReadModel) => void;
  onItemDeleted: (item: FinancingPaybackPlanElementReadModel) => void;
}

export const RenderFinancingPaybackElement = ({
  item,
  onItemChange,
  onItemDeleted,
}: RenderFinancingPaybackElementProps) => {
  const { t } = useTranslation();
  const currentCurrency = useGetCurrency();

  const contextItems: ContextMenuItem[] = [
    {
      label: t('projectCalculate.financingDeleteTitle'),
      subtitle: t('projectCalculate.financingDeleteSubTitle'),
      icon: <TrashIcon />,
      onClick: () => onItemDeleted(item),
    },
  ];

  return (
    <div className="flex flex-row items-center bg-white divide-x">
      <div className="w-3/12">
        <DatePicker
          label={t('projectCalculate.financingLabelPaymentOn')}
          value={new Date(item.paymentOn)}
          onChange={(paymentOn) => {
            if (paymentOn && formatDateOnly(paymentOn) !== item.paymentOn) {
              onItemChange({ ...item, paymentOn: formatDateOnly(paymentOn) })
            }
          }}
        />
      </div>
      <div className="w-6/12">
        <TextInput
          label={t('projectCalculate.financingLabelDescription')}
          value={item.description}
          onChange={(description) => {
            if (description !== item.description) {
              onItemChange({ ...item, description })
            }
          }}
        />
      </div>
      <div className="w-3/12 flex flex-row items-center">
        <div className="w-5/6">
          {!item.rest && (
            <NumberInput
              label={t('projectCalculate.financingLabelAmount')}
              value={item.amount ?? 0}
              onChange={(amount) => {
                if (item.amount !== amount) {
                  onItemChange({ ...item, amount: amount ?? 0 })
                }
              }}
              step={1}
              icon={<ReceiveCashIcon />}
            />
          )}
          {item.rest && (
            <TextInput
              label={t('projectCalculate.financingLabelAmount')}
              value={t('projectCalculate.financingElementLabelRest')}
              disabled={true}
              icon={<ReceiveCashIcon />}
            />
          )}
        </div>
        <div className={classNames('pr-1 text-xxs', { 'mr-8': item.rest })}>{currentCurrency}</div>
        {!item.rest && (
          <div className="text-gray-400 hover:text-gray-600 px-1">
            <ContextMenu items={contextItems} />
          </div>
        )}
      </div>
    </div>
  );
};
