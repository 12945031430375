import { CalculationState } from '@client/shared/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useElementErrors = (calculationState: CalculationState | undefined, timelineErrors: string[], contractBudgetErrors: string[]) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const translatedErrors: string[] = [];
    if (calculationState === 'Overflow') {
      translatedErrors.push(t('projectCalculate.CostElementOverflowError'));
    }
    if (timelineErrors && timelineErrors.length > 0) {
      timelineErrors.forEach((timelineError) => {
        translatedErrors.push(t(`projectCalculate.DistributionError${timelineError}`));
      });
    }
    if (contractBudgetErrors && contractBudgetErrors.length > 0)
      contractBudgetErrors.forEach((contractBudgetError) => {
        translatedErrors.push(t(`projectCalculate.ContractBudgetError${contractBudgetError}`));
    });
    return translatedErrors;
  }, [calculationState, timelineErrors, contractBudgetErrors, t]);
};
