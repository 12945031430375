import { FinancingError } from '@client/shared/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useFinancingElementErrors = (financingErrors: FinancingError[]) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const translatedErrors: string[] = [];
    if (financingErrors && financingErrors.length > 0) {
      financingErrors.forEach((financingError) => {
        translatedErrors.push(t(`projectCalculate.FinancingError${financingError}`));
      });
    }
    return translatedErrors;
  }, [financingErrors, t]);
};
