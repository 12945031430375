import { AuthRoleAssignmentReadModel } from '@client/shared/api';
import { SettingsAddButton, SlideOver } from '@client/shared/toolkit';
import { useState } from 'react';
import { RoleAddSlideOver } from './RoleAddSlideOver';
import { RoleRow, RoleState } from './RoleRow';
import { useTranslation } from 'react-i18next';

interface RoleListProps {
  embeddedSlideOverBackground: string | undefined;
  assignedRoles: AuthRoleAssignmentReadModel[];
  newRoles: AuthRoleAssignmentReadModel[];
  deletedRoles: AuthRoleAssignmentReadModel[];
  onEditRoleImpact: (role: AuthRoleAssignmentReadModel) => void;
  onRoleSelectionChanged: (added: AuthRoleAssignmentReadModel[], removed: AuthRoleAssignmentReadModel[]) => void;
}

export const RoleList = ({
  newRoles,
  assignedRoles,
  embeddedSlideOverBackground,
  onEditRoleImpact,
  onRoleSelectionChanged,
  deletedRoles,
}: RoleListProps) => {
  const { t } = useTranslation();

  const slideOverBackground = embeddedSlideOverBackground ?? 'bg-gray-600';

  const [showAddRole, setShowAddRole] = useState<boolean>(false);

  const getRoleState = (role: AuthRoleAssignmentReadModel): RoleState => {
    if (deletedRoles.includes(role)) {
      return 'Deleted';
    }
    if (!assignedRoles.includes(role)) {
      return 'New';
    }

    return 'Assigned';
  };

  const handleRemoveRole = (role: AuthRoleAssignmentReadModel) => {
    const state = getRoleState(role);
    if (state === 'Assigned') {
      const newRemoved = deletedRoles.concat(role);
      onRoleSelectionChanged(newRoles, newRemoved);
    }
    if (state === 'New') {
      const newAdded = newRoles.filter((x) => x.roleId !== role.roleId);
      onRoleSelectionChanged(newAdded, deletedRoles);
    }
    if (state === 'Deleted') {
      const newRemoved = deletedRoles.filter((x) => x.roleId !== role.roleId);
      onRoleSelectionChanged(newRoles, newRemoved);
    }
  };

  const handleAddRoles = (roles: AuthRoleAssignmentReadModel[]) => {
    const allAdded = newRoles.concat(roles);

    onRoleSelectionChanged(allAdded, deletedRoles);
  };

  return (
    <div className="flex flex-col w-full ">
      <div className="flex flex-col w-full">
        {assignedRoles.map((role, idx) => (
          <RoleRow
            key={idx}
            role={role}
            state={getRoleState(role)}
            onDelete={handleRemoveRole}
            onEdit={onEditRoleImpact}
          />
        ))}
        {newRoles.map((role, idx) => (
          <RoleRow key={idx} role={role} state="New" onDelete={handleRemoveRole} onEdit={onEditRoleImpact} />
        ))}
        {assignedRoles.length === 0 && newRoles.length === 0 && (
          <div className="flex w-full my-4 items-center justify-center">
            <div className="text-gray-500">{t('auth.noRolesAdded')}</div>
          </div>
        )}
      </div>

      <SettingsAddButton className="mr-2 mb-2" onClick={() => setShowAddRole(true)} />

      <SlideOver
        isOpen={showAddRole}
        onClose={() => setShowAddRole(false)}
      >
        <RoleAddSlideOver
          slideOverBackground={slideOverBackground}
          assignedRoles={assignedRoles.concat(newRoles)}
          onClose={(newRoles) => {
            if (newRoles) {
              handleAddRoles(newRoles);
            }

            setShowAddRole(false);
          }}
        />
      </SlideOver>
    </div>
  );
};
