import { MouseEventHandler, ReactElement, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { FullScreenIcon, TriangleIcon } from '@client/shared/toolkit';
import { SizeType } from '../hooks';
import { useTranslation } from 'react-i18next';

type PropsWithChildren<P> = P & {
  children?: ReactNode;
};

type PropsWithClassName<P> = P & {
  className?: string;
};

type TreeProps<T> = {
  nodeData: T;
  expandedByDefault?: boolean;
  className?: string;
  renderNode?: (props: T, isExpanded: boolean, toggleExpanded: () => void) => ReactElement;
};

export type TreeNodeProps = {
  toggleExpansion: () => void;
  expanded?: boolean;
};

type TreeContainerProps = PropsWithChildren<PropsWithClassName<{ onMouseEnter?: MouseEventHandler }>>;

export const SectionLabel = (
  props: PropsWithChildren<{
    onMouseOver?: MouseEventHandler;
  }>,
) => (
  <div className="flex flex-col text-lg pl-1 mb-1" onMouseOver={props.onMouseOver}>
    {props.children}
  </div>
);

export const TreeContainer = (props: TreeContainerProps) => (
  <div className="my-2 select-none" onMouseOver={props.onMouseEnter}>
    {props.children}
  </div>
);

export const Arrow = (props: { expanded: boolean; onClick?: () => void }) => {
  return (
    <div className="flex flex-row items-center justify-center mr-1  w-3 h-full" onClick={props.onClick}>
      <TriangleIcon className={classNames('w-4 h-4 text-gray-500 ', props.expanded ? 'rotate-90' : '')} />
    </div>
  );
};

export const RenderSection = (props: {
  label: string;
  children: ReactNode;
  onMouseEnter?: MouseEventHandler;
  onMouseOverHeader?: MouseEventHandler;
}) => {
  return (
    <TreeContainer onMouseEnter={props.onMouseEnter}>
      <SectionLabel onMouseOver={props.onMouseOverHeader}>{props.label}</SectionLabel>
      {props.children}
    </TreeContainer>
  );
};

export const SizeElement = (props: { size: SizeType; disabled?: boolean; onClick: (size: string) => void }) => {
  const { t } = useTranslation();
  const getLabel = () => {
    switch (props.size.label) {
      case 'BGF':
        return t('project.bgfRoi');
      case 'NGF':
        return t('project.ngf');
      case 'MFG':
        return t('project.mf');
      case 'Amount':
          return t('project.amount');
      case 'GRZ': 
        return t('project.grz');
      case 'GFZ':
        return t('project.gfz');
      default:
        return props.size.label;
    }
  };

  return (
    <div
      className={classNames('ml-5 p-2 bg-white text-xs flex flex-auto flex-row gap-x-2 cursor-pointer my-1', {
        'cursor-pointer': !props.disabled,
      })}
      onClick={(e) => {
        e.stopPropagation();
        props.onClick(props.size.label);
      }}
    >
      <div className="w-5">
        <FullScreenIcon />
      </div>
      <div>{getLabel()}</div>
    </div>
  );
};

export const RenderNode = (props: { children: ReactNode }) => {
  return <div className="text-sm flex flex-row w-full h-8">{props.children}</div>;
};

export const RenderChildren = (props: { children: ReactNode }) => {
  return <div className="mb-2">{props.children}</div>;
};

export const Tree = <T,>(props: TreeProps<T>) => {
  const [isExpanded, setIsExpanded] = useState(() => {
    return props.expandedByDefault ?? false;
  });
  const toggleExpansion = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className={classNames('flex flex-col my-1', props.className)}>
      {props.renderNode && props.renderNode(props.nodeData, isExpanded, toggleExpansion)}
    </div>
  );
};
