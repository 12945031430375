import * as yup from 'yup';
import { InferType } from 'yup';

export const ChangePasswordFormValidationSchema = yup.object({
  currentPassword: yup.string().required('validation.required'),
  newPassword: yup.string().required('validation.required').matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{8,}$/,
    'validation.passwordStrength'
  ),
  newPasswordConfirmation: yup.string().test('passwords-match', 'validation.passwordMatch', function (value) {
    return this.parent.newPassword === value;
  }),
});

export type ChangePasswordFormValidationValues = InferType<typeof ChangePasswordFormValidationSchema>;
