import { ProjectUnitSystem } from '../unitSystemSlice';

export const TOGGLED_UNIT_SYSTEM = 'unit_system'

export const toggleUnitSystem = (unitSystem: string, projectId: string) => {
  const unitSystemCookie = localStorage.getItem(TOGGLED_UNIT_SYSTEM)
  const storageExists = unitSystemCookie && unitSystemCookie !== 'Imperial' && unitSystemCookie !== 'Metric';
  const parsedStorage = storageExists ? JSON.parse(unitSystemCookie) : [];

  const newUnitSystem = unitSystem === 'Metric' ? 'Imperial' : 'Metric';

  localStorage.setItem(TOGGLED_UNIT_SYSTEM, JSON.stringify([
    ...parsedStorage.filter((unitSystem: ProjectUnitSystem) => unitSystem.id !== projectId),
    {
      id: projectId,
      unitSystem: newUnitSystem,
    },
  ]));

  return newUnitSystem;
};
