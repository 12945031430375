import { InvoiceReadModel, ShortInvoiceReadModel } from '@client/shared/api';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { CircledPlayIcon, InfoIcon } from '@client/shared/toolkit';

interface StartWorkflowDisabledMessageProps {
  invoice: InvoiceReadModel | ShortInvoiceReadModel;
  projectId: string
}

export const StartWorkflowDisabledMessage = (props: StartWorkflowDisabledMessageProps) => {
  const {
    invoice,
    projectId
  } = props;
  const { t } = useTranslation();

  const disabledWorkflowStartMessage = useMemo(() => {
    if (!invoice.startWorkflowStatus.canStartWorkflow && !invoice.startWorkflowStatus.canRestartWorkflow) {
      const contractSettingsLink = <NavLink className="font-bold underline" to={`${ROUTES_CONFIG.PROJECT_CONTRACT_EDIT.path.replace(':id', projectId ?? '').replace(':contractId', invoice.contractId ?? '')}`}>{t('projectControl.contractSettings')}</NavLink>;
      const projectSettingsLink = <NavLink className="font-bold underline" to={`${ROUTES_CONFIG.PROJECT_DASHBOARD_SETTINGS.path.replace(':id', projectId ?? '')}?tab=workflows`}>{t('workflow.projectSettings')}</NavLink>;

      switch (invoice.startWorkflowStatus.reasonStatus) {
        case 'NoWorkflowAvailable': // no workflow at all
          return t('error.workflow.not_available');
        case 'InvoiceMustBePendingState':
          return t('projectControl.notPendingInvoiceWorkflowCannotBeStarted');
        case 'InProgress': // wf already started
          return t('projectControl.workflowAlreadyStarted');
        case 'NoDefaultWorkflow': // no default workflow for invoices without contract, go to project settings
          return (
            <Trans i18nKey="projectControl.noDefaultWorkflowSelected">
              No invoice default workflow. Go to {projectSettingsLink} to create a workflow.
            </Trans>
          );
        case 'NoContractInvoiceWorkflow': // contract has no wf selected, go to contract edit slide over
          return invoice.contractId ? (
            <Trans i18nKey="projectControl.noContractInvoiceWorkflowSelected">
              No contract workflow selected. Go to the {contractSettingsLink} to set one.
            </Trans>
          ) : '';
        case 'NoDefaultAvailableContractWorkflowMustBeSelected': // contract does not have wf selected, and there is no default wf => select wf in contract edit slide over
          return invoice.contractId ? (
            <Trans i18nKey="projectControl.noContractInvoiceWorkflowSelected">
              No contract workflow selected. Go to the {contractSettingsLink} to set one.
            </Trans>
          ) : '';
        case 'SomeContractActivitiesNotAssigned':
          return (
            <Trans
              i18nKey="workflow.unassignedTasksContractWorkflow"
              values={{
                name: invoice.startWorkflowStatus.workflowName ?? ''
              }}
              components={{
                1: <span className="font-bold">{invoice.startWorkflowStatus.workflowName ?? ''}</span>,
                2: projectSettingsLink
              }}
            >
              There are unassigned tasks for the <span className="font-bold">{invoice.startWorkflowStatus.workflowName ?? ''}</span>. Go to {projectSettingsLink} to set them.
            </Trans>
          );
        case 'SomeDefaultActivitiesNotAssigned':
          return (
            <Trans i18nKey="workflow.unassignedTasks">
              There are unassigned tasks. Go to {projectSettingsLink} to set them.
            </Trans>
          )
        default:
          return null;
      }
    }
  }, [invoice, projectId, t])

  if (invoice.startWorkflowStatus.canStartWorkflow || invoice.startWorkflowStatus.canRestartWorkflow) return null;

   return disabledWorkflowStartMessage ? (
    <div className="group cursor-default">
      <div className={classNames('flex px-4 py-1')}>
        <div className="flex items-center justify-center">
          <div className="w-5 h-5 ">
            <CircledPlayIcon />
          </div>
        </div>
        <div className="text-left flex-grow">
          <div className="font-bold ml-3 text-left">{invoice.startWorkflowStatus.canRestartWorkflow
            ? invoice.startWorkflowStatus.workflowName ? t('projectControl.restartWorkflowName', { name: invoice.startWorkflowStatus.workflowName }) : t('projectControl.restartWorkflow')
            : invoice.startWorkflowStatus.workflowName ? t('projectControl.startWorkflowName', { name: invoice.startWorkflowStatus.workflowName }) : t('projectControl.startWorkflow')}</div>
          <div className="text-xs ml-3 text-left">{t('projectControl.startWorkflowForInvoice')}</div>
        </div>
      </div>
      <div className="hidden group-hover:flex text-gray-600 italic text-xs gap-2 py-2 px-4">
        <InfoIcon className="h-4 w-4 flex-none" />
        <div>{disabledWorkflowStartMessage}</div>
      </div>
    </div>
  ) : null;
}
