import { ShortProjectReadModel, ShortProjectGroupReadModel } from '@client/shared/api';
import { getProjectThumbnail } from '@client/project/shared';

export const getSettingsProjectThumbnail = (
  projectId: string,
  projects: ShortProjectReadModel[],
  groups: ShortProjectGroupReadModel[]
) => {
  let project;
  if (projects.find((project) => project.id === projectId)) {
    project = projects.find((project) => project.id === projectId);
  } else if (groups.find((group) => group.projects.find((project) => project.id === projectId))) {
    const group = groups.find((group) => group.projects.find((project) => project.id === projectId));
    project = group?.projects.find((project) => project.id === projectId);
  }

  return getProjectThumbnail('Small', project, false);
};
