import classNames from 'classnames';
import React, {memo, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import { CalculationModelDeliveryPhaseReadModelExt } from './TimeLineHeader';
import { TimeLinePositionedContainer } from '../index';
import { Portal } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { TimeLineDataContext } from './TimeLineDataContext';
import cn from 'classnames';

interface TimelineHeaderPhaseProps {
  timelineStartDate: Date
  timelineEndDate: Date
  isToggled: boolean
  phase: CalculationModelDeliveryPhaseReadModelExt
  index: number
  scrollPos?: number
}

const PHASE_LABEL_HEIGHT = 30
const PHASE_LABEL_MAX_WIDTH = 25

export const TimelineHeaderPhase = memo(({
  timelineStartDate,
  timelineEndDate,
  phase,
  isToggled,
  index,
  scrollPos = 0
}: TimelineHeaderPhaseProps) => {
  const {
    view,
    toggledYears,
    activePhase,
    setActivePhase,
    dragScrollContainerRef,
    isHeaderCollapsed,
    headerHeight
  } = useContext(TimeLineDataContext);
  const monthLabelPopupRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null)
  const phaseLabelTriggerRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null)
  const [phaseLabelMaxWidth, setPhaseLabelMaxWidth] = useState(0)
  const [phaseLength, setPhaseLength] = useState<string | number>('auto')
  const {
    styles: monthLabelStyles,
    attributes: monthLabelAttributes,
    forceUpdate
  } = usePopper(phaseLabelTriggerRef.current, monthLabelPopupRef.current, {
    placement: 'top-start',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          boundary: dragScrollContainerRef?.current ? dragScrollContainerRef.current : undefined
        }
      },
      {
        name: 'offset',
        options: {
          offset: [-5, 0]
        }
      }
    ]
  })
  const isActive = useMemo(() => {
    return activePhase === phase.code
  }, [activePhase, phase.code])
  const toggleActivePhase = useCallback((phase: string | null) => {
    setActivePhase(phase)
    setTimeout(() => {
      if (forceUpdate) {
        forceUpdate()
      }
    })
  }, [setActivePhase, forceUpdate])
  const phaseHeight = useMemo(() => {
    // return isToggled ? '20px' : '30px'
    return isToggled ? '20px' : `${(phase.position ?? 1) * PHASE_LABEL_HEIGHT + 73}px`
  }, [isToggled, phase.position])
  // if toggled years changed, update the label positions
  useEffect(() => {
    if (!isActive) {
      calculatePhaseLabelMaxWidth()
      setTimeout(() => {
        if (forceUpdate) {
          forceUpdate()
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggledYears, scrollPos, view, headerHeight, phase])
  const calculatePhaseLabelMaxWidth = () => {
    const wrapperDivs = document.querySelectorAll(`.timeline-phase[data-phase="${phase.code}"]`)
    let maxWidth = phaseLabelTriggerRef?.current?.scrollWidth ?? 0
    if (wrapperDivs.length) {
      maxWidth = 0
      wrapperDivs.forEach((wrapperDiv) => {
        maxWidth += wrapperDiv.scrollWidth
      })
    }
    setPhaseLength(maxWidth ? `${maxWidth}px` : 'auto')
    if (phaseLabelTriggerRef.current && dragScrollContainerRef?.current) {
      const triggerPosLeft = phaseLabelTriggerRef.current?.getBoundingClientRect().left
      const triggerPosRight = triggerPosLeft + maxWidth
      const timeLineRefPosLeft = dragScrollContainerRef.current.getBoundingClientRect().left
      const timeLineRefPosRight = dragScrollContainerRef.current.getBoundingClientRect().right
      // trigger start scrolled out of timeline view (end still in view) start---|----end  |
      if (triggerPosLeft < timeLineRefPosLeft && triggerPosRight > timeLineRefPosLeft) {
        maxWidth = triggerPosRight - timeLineRefPosLeft
      } // trigger start scrolled into timeline view (end out of view)  | start---|---end
      else if (triggerPosLeft < timeLineRefPosRight && triggerPosRight > timeLineRefPosRight) {
        maxWidth = timeLineRefPosRight - triggerPosLeft
      }
      // show not more than the actual defined max width
      maxWidth = maxWidth > PHASE_LABEL_MAX_WIDTH ? maxWidth : 0
    }
    setPhaseLabelMaxWidth(maxWidth)
  }
  useEffect(() => {
    if (!isHeaderCollapsed && forceUpdate && monthLabelPopupRef.current) {
      setTimeout(() => {
        if (forceUpdate) {
          forceUpdate()
        }
      }, 300) // duration of header collapsing transition
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHeaderCollapsed, forceUpdate, monthLabelPopupRef.current]);
  return useMemo(() => {
    return (
      <TimeLinePositionedContainer
        timeLineStartDate={timelineStartDate}
        timeLineEndDate={timelineEndDate}
        date={phase.yearStart}
        endDate={phase.yearEnd}
        className={
          cn('w-full transition-all duration-300',
            // 'before:content-[""] before:absolute before:left-0 before:w-full before:h-1 before:-translate-y-1/2 before:top-0 before:transition-color before:duration-300',
            {
              'z-30': isActive,
              // 'before:bg-secondary': isActive,
              // 'before:bg-slate-300 z-[1]': !isActive && phase.startDate && phase.startDate < timelineStartDate, // top line here works...
              // ... but for this case, issue with borders (weird random gap), so just add transparent border here and use the actual phase size label as top line
              // 'before:bg-transparent z-10': !isActive && phase.startDate && phase.startDate >= timelineStartDate
            })
        }
        top={phase.position ? `-${phase.position * PHASE_LABEL_HEIGHT}px` : '0px'}
      >
        {/* this one is the actual phase size (needed to keep the label visible the whole phase) and used as the top line */}
        {phase.startDate && phase.startDate >= timelineStartDate && (
          <div
            className={
              cn('absolute -top-0.5 left-0 h-1 transition-colors duration-300', {
                'bg-slate-300': !isActive,
                'bg-secondary': isActive
              })
            }
            ref={phaseLabelTriggerRef}
            style={{ width: phaseLength }}
          />
        )}
        <div
          data-phase={phase.code}
          className={classNames(
            'timeline-phase cursor-pointer leading-tight',
            {
              // Marker dots
              'before:absolute before:left-0 before:top-0 before:p-px before:rounded-full before:bg-white before:shadow before:border-2 before:z-20 before:-translate-x-1/2 before:-translate-y-1/2 before:transition-all before:duration-300': phase.startDate && phase.startDate >= timelineStartDate,
              'after:absolute after:left-full after:top-0 after:p-px after:rounded-full after:bg-white after:shadow after:border-2 after:z-20 after:-translate-x-1/2 after:-translate-y-1/2 after:transition-all after:duration-300': phase.endDate && phase.endDate < timelineEndDate,
              'before:w-[0.5625rem] before:h-[0.5625rem] before:border-current after:w-[0.5625rem] after:h-[0.5625rem] after:border-current': !isActive,
              'before:w-2.5 before:h-2.5 before:border-secondary after:w-2.5 after:h-2.5 after:border-secondary': isActive
            }
          )}
          onMouseEnter={() => toggleActivePhase(phase.code)}
          onMouseLeave={() => toggleActivePhase(null)}
          style={{ height: PHASE_LABEL_HEIGHT }}
        >
          {/* <span className="font-bold">{phase.code}</span> <span className="font-light">{phase.name}</span> */}
          {phase.startDate && phase.startDate >= timelineStartDate && (
            <Portal>
              <div
                className={classNames(
                  'text-xs ml-0.5 mb-1 hover:text-secondary cursor-pointer popper-ref-hidden transition-opacity duration-200',
                  `z-${index * 10}`, {
                    'opacity-0 w-full': isToggled && !isActive,
                    'text-primary truncate': !isActive,
                    'z-[100] bg-slate-50 text-secondary': isActive,
                    'pl-1 pr-2': phaseLabelMaxWidth > PHASE_LABEL_MAX_WIDTH || isActive
                  })}
                title={`${phase.code} ${phase.name}`}
                ref={monthLabelPopupRef}
                style={{ ...monthLabelStyles.popper, maxWidth: !isActive ? phaseLabelMaxWidth : undefined }}
                {...monthLabelAttributes.popper}
              >
                <span className="font-bold">{phase.code}</span> <span className="font-light">{phase.name}</span>
              </div>
            </Portal>
          )}
          {/*
        {phase.startDate && phase.startDate >= timelineStartDate && (
          <TimeLineMarker active={isActive} />
        )}
        */}
          <div
            className={classNames(
              'transition-colors duration-300 w-full h-full pointer-events-none absolute top-0 left-0',
              {
                // borders position is not correct, so use before and after instead
                // 'border-l-2': !isToggled && phase.startDate && phase.startDate >= timelineStartDate,
                // 'border-r-2': !isToggled && phase.endDate && phase.endDate <= timelineEndDate,
                // Marker lines
                'border-secondary before:bg-secondary after:bg-secondary': isActive,
                'border-slate-300 before:bg-slate-300 after:bg-slate-300': !isActive,
                'before:content-[""] before:absolute before:w-px before:h-full before:top-0 before:-left-px before:z-10 before:mx-2.5 before:-translate-x-2.5 before:transition-colors before:duration-300 before:pointer-events-auto': phase.startDate && phase.startDate >= timelineStartDate,
                'after:content-[""] after:absolute after:w-px after:h-full after:top-0 after:-right-px after:z-10 after:mx-2.5 after:translate-x-2.5 after:transition-colors after:duration-300 after:pointer-events-auto': phase.endDate && phase.endDate <= timelineEndDate
              }
            )}
            style={{ height: phaseHeight }}
          />
          {/*
        {phase.endDate && phase.endDate <= timelineEndDate && (
          <TimeLineMarker position="right" active={isActive}/>
        )}
        */}
        </div>
      </TimeLinePositionedContainer>
    )
  }, [timelineStartDate, timelineEndDate, phase.yearStart, phase.yearEnd, isActive, phase.startDate, phase.position, phaseLength, phase.code, phase.endDate, toggleActivePhase, index, isToggled, phaseLabelMaxWidth, phase.name, monthLabelStyles.popper, monthLabelAttributes.popper, phaseHeight])
})
