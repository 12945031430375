import * as yup from 'yup';
import { InferType } from 'yup';

export const PlotElementFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  description: yup.string().optional(),
  street: yup.string().optional(),
  parcelNumber: yup.string().optional(),
  usageType: yup.string().optional(),
  size: yup.number().typeError('validation.number').required('validation.required'),
  constructionSize: yup.number().typeError('validation.number').required('validation.required'),
  grossVolume: yup.number().typeError('validation.number').optional(),
  cost: yup.number().typeError('validation.number').optional(),
  type: yup.string().required('validation.required'),
});

export type PlotElementFormValidationValues = InferType<typeof PlotElementFormValidationSchema>;
