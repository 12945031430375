import { fork } from 'redux-saga/effects';
import { taxonomySaga } from '@client/project/taxonomy';
import { reportingSaga } from '@client/project/reporting';
import { projectSaga } from '@client/project/shell';
import { authSaga } from '@client/main/auth';
import { settingsSaga } from '@client/main/settings';
import { appSaga, notificationSaga } from '@client/main/shell';
import { controlSaga } from '@client/project/control';

export const rootSaga = function* () {
  yield fork(appSaga);
  yield fork(notificationSaga);
  yield fork(authSaga);
  yield fork(projectSaga);
  yield fork(taxonomySaga);
  yield fork(reportingSaga);
  yield fork(settingsSaga);
  yield fork(controlSaga);
};
