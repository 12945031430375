import { EarningsType, RiskAmountTarget } from '@client/shared/api';
import { i18n } from '@client/shared/utilities';

export const getRiskDescription = (
  type: EarningsType | null | undefined,
  riskAmountTarget: RiskAmountTarget | null | undefined,
  referencedItemCode: string | null | undefined
) => {
  //} element: VariantRiskElementReadModel) {
  const getEarningsGroupDescription = (type: EarningsType | null | undefined) => {
    if (!type) return '';
    switch (type) {
      case 'SaleRevenue':
        return i18n.t('projectCalculate.riskSaleRevenue');
      case 'RentRevenue':
        return i18n.t('projectCalculate.riskRentRevenue');
      case 'OperatingRevenue':
        return i18n.t('projectCalculate.riskOperatingRevenue');
      case 'ServiceRevenue':
        return i18n.t('projectCalculate.riskServiceRevenue');
    }
  };

  switch (riskAmountTarget) {
    case 'CatalogCostElement':
      return i18n.t('projectCalculate.riskCatalogCostElement') + `: ${referencedItemCode}`;
    case 'EarningsElement':
      return i18n.t('projectCalculate.riskEarningsElement') + `: ${referencedItemCode}`;
    case 'EarningsGroups':
      return `${getEarningsGroupDescription(type)}`;
    case 'FixedValue':
      return i18n.t('projectCalculate.riskFixedValue');
    case 'TotalCost':
      return i18n.t('projectCalculate.riskTotalCosts');
    case 'TotalEarning':
      return i18n.t('projectCalculate.riskTotalEarnings');
    default:
      return '';
  }
};
