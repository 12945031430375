import classNames from 'classnames';
import React, {PropsWithChildren, useCallback, useEffect, useRef, useState} from 'react';

export interface DragScrollContainerTranslateProps extends PropsWithChildren {
  containerRef: React.RefObject<HTMLDivElement>;
  className?: string;
  draggingClassName?: string;
  syncRefs?: React.RefObject<HTMLDivElement>[];
  updateWidth?: boolean
  paddingRight?: number
  updateIsDragging?: (isDragging: boolean) => void
}

export const DragScrollContainerTranslate = ({
  children,
  containerRef,
  className,
  syncRefs = [],
  updateWidth,
  paddingRight = 32,
  updateIsDragging
}: DragScrollContainerTranslateProps) => {
  const innerContainerRef = useRef<HTMLDivElement>(null)
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragX, setDragX] = useState(0);
  const [width, setWidth] = useState(800)

  const updateSyncRefs = (newDragX: number) => {
    if (syncRefs.length) {
      syncRefs.forEach((syncRef) => {
        if (syncRef.current) {
          syncRef.current.style.transform = `translate(${newDragX}px, 0px)`;
        }
      })
    }
  }

  useEffect(() => {
    if (containerRef.current && innerContainerRef.current && width !== containerRef.current.scrollWidth && !isDragging) {
      const widthDifference = width - innerContainerRef.current.scrollWidth;
      if (widthDifference > 0) {
        const newDragX = Math.max(
          Math.min(dragX + widthDifference, 0),
          -innerContainerRef.current.scrollWidth + containerRef.current.clientWidth
        );
        setDragX(newDragX);
        updateSyncRefs(newDragX)
      }
      setWidth(innerContainerRef.current.scrollWidth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateWidth]);

  const handleMouseDown = ({ clientX }: React.MouseEvent) => {
    setIsDragging(true);
    if (updateIsDragging) {
      updateIsDragging(true)
    }
    setDragStartX(clientX);
  };

  useEffect(() => {
    if (innerContainerRef.current) {
      setWidth(innerContainerRef.current.scrollWidth)
    }
  }, []);

  const handleMouseMove = useCallback(({ clientX } : MouseEvent) => {
    if (!isDragging) return
    if (innerContainerRef.current && containerRef.current) {
      const deltaX = clientX - dragStartX;
      const newDragX = Math.min(0, Math.max(-innerContainerRef.current.scrollWidth + containerRef.current.clientWidth, dragX + deltaX));
      if (newDragX !== dragX) {
        requestAnimationFrame(() => {
          setDragX(newDragX);
          setDragStartX(clientX);
          updateSyncRefs(newDragX)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging, containerRef.current, dragStartX, dragX])

  const handleMouseUp = () => {
    setIsDragging(false);
    if (updateIsDragging) {
      updateIsDragging(false)
    }
  }

  useEffect(() => {
    const innerContainerRefCurrent = innerContainerRef.current
    const handleDocumentMouseMove = (event: MouseEvent) => {
      handleMouseMove(event);
    };

    if (isDragging) {
      innerContainerRefCurrent?.addEventListener('mousemove', handleDocumentMouseMove);
    }

    return () => {
      innerContainerRefCurrent?.removeEventListener('mousemove', handleDocumentMouseMove);
    };
  }, [isDragging, handleMouseMove])

  return (
    <div
      className={classNames(
        'cursor-grab hide-scrollbars h-full',
        className
      )}
      ref={containerRef}
      style={{
        maxWidth: `calc(100% - ${paddingRight}px)`
      }}
    >
      <div
        className="will-change-transform h-full select-none"
        ref={innerContainerRef}
        style={{
          transform: `translateX(${dragX}px)`
        }}
        onMouseDown={handleMouseDown}
        // onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {children}
      </div>
    </div>
  );
};
