import { ReactNode } from 'react';
import { Button } from '../Button';

export interface DocumentViewerPreviewHeaderButtonProps {
  className?: string
  onClick: () => void
  buttonText: ReactNode | string
  disabled?: boolean
}
export const DocumentViewerPreviewHeaderButton = (props: DocumentViewerPreviewHeaderButtonProps) => {
  const {
    className,
    buttonText,
    onClick,
    disabled = false
  } = props

  return (
    <Button
      variant="custom"
      onClick={onClick}
      disabled={disabled}
      className={className}
      hasPadding={false}
    >
      {buttonText}
    </Button>
  )
}
