import { Arrow, RenderChildren, RenderNode, Tree, TreeNodeProps } from './Tree';
import {
  // CalculationModelEarningsElement,
  CalculationModelEarningsGroupElement,
  EarningsElementShortReadDto,
} from '@client/shared/api';
import { useMemo } from 'react';
import classNames from 'classnames';

type RenderEarningsProps = TreeNodeProps & {
  earningElement: EarningsElementShortReadDto;
  onSelectEarningElement: (token: string) => void;
  disabled?: boolean;
};

export const RenderEarnings = (props: RenderEarningsProps) => {
  return (
    <div>
      {props.earningElement.group && (
        <RenderEarningGroupElement
          toggleExpansion={props.toggleExpansion}
          expanded={props.expanded}
          earningElement={props.earningElement.group}
          onSelectEarningElement={props.onSelectEarningElement}
          disabled={props.disabled}
        />
      )}
    </div>
  );
};

type RenderEarningGroupElementProps = TreeNodeProps & {
  earningElement?: CalculationModelEarningsGroupElement;
  onSelectEarningElement: (token: string) => void;
  disabled?: boolean;
};

export const RenderEarningGroupElement = (props: RenderEarningGroupElementProps) => {
  const children = props.earningElement?.children.filter((c) => !!c.group) ?? [];
  const hasChildren = children.length > 0 || false;
  const hasGroupId = !!props.earningElement?.groupId;

  const earningGroupName = useMemo(() => {
    return props.earningElement?.description ?? props.earningElement?.title ?? '';
  }, [props]);

  const elementName = `'${props.earningElement?.code} ${earningGroupName}'`;

  return (
    <div className="flex flex-col">
      <RenderNode>
        {hasChildren ? (
          <Arrow expanded={props.expanded ?? false} onClick={props.toggleExpansion} />
        ) : (
          <div className="mr-1 w-2" />
        )}
        <div
          className={classNames('border-l-4 pl-1 border-l-green-600 flex flex-row bg-white flex-grow', {
            'cursor-pointer': !props.disabled && hasGroupId,
            'opacity-80': !hasGroupId,
          })}
          onClick={() => (hasGroupId ? props.onSelectEarningElement(elementName) : null)}
        >
          <div className="flex flex-row items-center">{props.earningElement?.code}</div>
          <div className="pl-2 w-auto flex flex-row items-center">{props.earningElement?.description ?? props.earningElement?.title ??''}</div>
        </div>
      </RenderNode>
      {props.expanded && (
        <RenderChildren>
          {children.map((c, idx) => (
            <Tree
              key={idx}
              nodeData={c}
              renderNode={(earningElement, isExpanded, toggleExpansion) => (
                <RenderEarnings
                  toggleExpansion={toggleExpansion}
                  expanded={isExpanded}
                  earningElement={c}
                  onSelectEarningElement={props.onSelectEarningElement}
                  disabled={props.disabled}
                />
              )}
              className="ml-5"
            />
          ))}
        </RenderChildren>
      )}
    </div>
  );
};
