import * as yup from 'yup';
import { InferType } from 'yup';

export const CommitmentFormValidationSchema = yup.object({
  id: yup.string().optional(),
  code: yup.string().required('validation.required'),
  name: yup.string().required('validation.required'),
  description: yup.string().optional(),
});

export type CommitmentFormValidationValues = InferType<typeof CommitmentFormValidationSchema>;
