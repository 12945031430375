import { AuthEffectiveCatalogReadModel } from '@client/shared/api';
import { Badge, EditNodeIcon, EditRowIcon, EyeIcon } from '@client/shared/toolkit';
import { EffectiveCatalogElementPermission } from './EffectiveCatalogElementPermission';
import { useState } from 'react';

interface EffectiveCatalogProps {
  effectiveCatalog: AuthEffectiveCatalogReadModel,
}

export const EffectiveCatalog = ({
    effectiveCatalog,
}: EffectiveCatalogProps) => {
    
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const getBorderColor = () => {
        switch (effectiveCatalog.catalog.category){
            case 'Cost': return 'border-red-500';
            case 'Risk': return 'border-sky-800';
            case 'Earnings': return 'border-green-500';
            case 'Financing':return 'border-gray-700';
        }
    }

    return (
        <div className='flex flex-col'>
            <div className='flex flex-row w-full py-4 font-medium text-lg items-center text-black hover:bg-gray-100 cursor-pointer'
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <div className='flex flex-row w-10/12 items-center'>
                    <EditNodeIcon className='mx-3 w-6 h-6' />
                    {effectiveCatalog.catalog.name}
                    {effectiveCatalog.project &&
                        <Badge className='ml-4' text={effectiveCatalog.project.name}/>
                    }
                </div>                 
                <div className='w-1/12'>
                    {isExpanded &&
                        <EyeIcon className="ml-5 w-6 h-6" />
                    }   
                </div>
                <div className='w-1/12'>
                    {isExpanded &&
                        <EditRowIcon className="ml-5 w-6 h-6" />
                    }
                </div>                
            </div>
            {isExpanded && 
                <div className='border-t-2 border-gray-300'>            
                    {effectiveCatalog.catalog.elements.map((element, index) => (
                        <EffectiveCatalogElementPermission 
                            key={index}
                            level={0}
                            borderColor={getBorderColor()}
                            catalogElement={element}
                            catalogElementPermissions={effectiveCatalog.elementPermissions}/>
                    ))}
                </div>
            }
        </div>
    );
};
