import { lazy } from 'react';
import { LazyComponent } from '@client/shared/toolkit';
import {
  useApiGetDefaultInvoiceCoverSheetReportQuery,
  useApiGetReportInvoiceCoverSheetDataSourceQuery,
  useApiGetReportQuery,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useUi } from '@client/shared/store';

export interface IcsViewerProps {
  invoiceId: string;
  reportId?: string | null;
}

const ReportViewerComponent = lazy(() => import('@client/project/lazy/src/components/ReportViewer').catch((e) => {
  console.error('Component Failed Loading:', e);
  return { default: () => <div>Error loading the component.</div> };
}));

const LazyIcsViewer = (props: IcsViewerProps) => {
  const { invoiceId, reportId } = props;

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const userData = useUi();

  // if reportId is set, the invoice has own report
  const { data: reportData, isFetching } = useApiGetReportQuery(
    {
      reportId: reportId ?? '',
    },
    {
      skip: !reportId,
    },
  );

  // otherwise the default ics report should be used
  const {
    data: icsDefaultReportData,
    isFetching: isFetchingDefault,
  } = useApiGetDefaultInvoiceCoverSheetReportQuery(undefined, {
    skip: !!reportId,
  });

  // We need to load the datasource with the correct data, because in report response there are only dummy ids
  const { data: dataSource, isFetching: isFetchingIcsDataSource } = useApiGetReportInvoiceCoverSheetDataSourceQuery(
    {
      dataSourceId: reportData?.dataSourceModel?.id ?? icsDefaultReportData?.dataSourceModel?.id ?? '',
      invoiceId: invoiceId,
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || (!reportData?.dataSourceModel?.id && !icsDefaultReportData?.dataSourceModel?.id),
    },
  );

  return (
    <LazyComponent>
      <ReportViewerComponent
        className="ics-report-viewer"
        isLoading={isFetching || isFetchingIcsDataSource || isFetchingDefault}
        reportData={reportData || icsDefaultReportData}
        dataSource={dataSource}
        sessionId={userData?.sessionId}
        smallIcons
        hideToolbar
      />
    </LazyComponent>
  );
};

export default LazyIcsViewer;
