import { useApiGetUserTenantsQuery } from '@client/shared/api';
import { LoadingIndicator } from '@client/shared/toolkit';
import React, { useState } from 'react';
import { CompanyRow } from './CompanyRow';
import { useTranslation } from 'react-i18next';
import { SettingsHeader } from '../SettingsHeader';

export const UserCompanies = () => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState<string | undefined>();

  const { data, isFetching } = useApiGetUserTenantsQuery();
  const userTenants = data ?? [];

  return (
    <div className="w-full">
      <SettingsHeader searchText={searchText} setSearchText={setSearchText}>
        {t('app.settingsCompanies')}
      </SettingsHeader>
      <div className="relative h-full">
        <div className="flex w-full text-xs text-gray-500 px-0 mt-4 mb-2">
          <div className="w-6/12 ml-16"></div>
          <div className="w-1/12"></div>
          <div className="w-2/12">{t('app.menuTeams')}</div>
          <div className="w-2/12">{t('app.menuProjects')}</div>
          <div className="w-1/12"></div>
        </div>
      </div>

      {isFetching === true && <LoadingIndicator text={t('app.settingsUsersLoading') ?? ''} />}

      <div className="border-t border-gray-200">
        {userTenants
          .filter((x) => x.tenantName.toLowerCase().includes(searchText?.toLowerCase() ?? ''))
          .map((tenant, idx) => (
            <div key={idx} className="border-b border-gray-200">
              <CompanyRow tenant={tenant} />
            </div>
          ))}
      </div>
    </div>
  );
};
