import { useState } from 'react';
import { BuildingItemSlideOver } from './BuildingItemSlideOver';
import { useTranslation } from 'react-i18next';
import { SkyscrapersDottedIcon, SlideOver } from '@client/shared/toolkit';
import { useLoadedProjectId } from '@client/project/store';
import { useValidateProjectPermission } from '@client/shared/permissions';

interface EmptyBuildingsTableProps {
  isReadOnly: boolean;
  canDelete: boolean;
}

export const EmptyBuildingsTable = ({ isReadOnly, canDelete }: EmptyBuildingsTableProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const canWrite = useValidateProjectPermission(['PLOT_AND_TAXONOMY_WRITE'], loadedProjectId ?? '');

  const [isBuildingItemModalOpen, setIsBuildingItemModalOpen] = useState(false);

  return (
    <>
      <button
        disabled={!canWrite}
        type="button"
        className="relative block w-full border-2 border-dashed border-gray-300 shadow-lg rounded p-12 text-center hover:border-gray-400 focus:outline-none"
        onClick={() => setIsBuildingItemModalOpen(true)}
      >
        <SkyscrapersDottedIcon className="mx-auto w-12 h-12" />
        <span className="mt-2 block text-sm font-medium text-gray-900">
          {t('projectTaxonomy.emptyBuildingTableHint')}
        </span>
      </button>

      <SlideOver isOpen={isBuildingItemModalOpen} onClose={() => setIsBuildingItemModalOpen(false)}>
        <BuildingItemSlideOver isReadOnly={isReadOnly} canDelete={canDelete} onClose={() => setIsBuildingItemModalOpen(false)} />
      </SlideOver>
    </>
  );
};
