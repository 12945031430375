import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import React, { PropsWithChildren, MouseEvent } from 'react';
import classNames from 'classnames';
import { AddButton } from './Buttons';
import { ContextMenu, ContextMenuItem } from './ContextMenu';
import cn from 'classnames';

interface DecoratedCardProps extends PropsWithChildren {
  className?: string;
  shadowVariant?: 'normal' | 'none' | 'large'
  padding?: string
}

export const DecoratedCardComponent = ({ children, className, shadowVariant = 'large', padding = 'py-1.5' }: DecoratedCardProps) => (
  <div className={classNames(
    'divide-y flex flex-col relative',
    'before:content-[""] before:w-full before:h-full before:z-0 before:absolute before:left-0 before:top-0 before:rounded-md before:border-gray-200 before:overflow-hidden before:bg-white',
    shadowVariant === 'large' ? 'before:shadow-lg' : (shadowVariant === 'normal' ? 'before:shadow' : ''),
    padding,
    className
  )}
  >
    {children}
  </div>
);

interface DecoratedCardHeaderProps extends PropsWithChildren {
  showActionButton?: boolean;
  onActionClick?: (e: MouseEvent) => void;
  icon?: React.ReactElement;
  variant?: 'default' | 'plain';
  className?: string
}

export const DecoratedCardHeader = ({
  className,
  children,
  showActionButton,
  onActionClick,
  icon = <EllipsisVerticalIcon />,
  variant = 'default',
}: DecoratedCardHeaderProps) => (
  <div className={cn('flex h-16 items-center relative flex-shrink-0 z-5', className)}>
    {variant === 'default' && <div className="w-2 h-8 bg-cyan-700 rounded -ml-1 left-0 absolute" />}
    <div className="flex items-center justify-between pl-6 pr-4 w-full">
      <div className="flex-grow text-[22px] font-bold flex space-x-3 truncate">{children}</div>
      <div className="text-gray-500 hover:text-gray-800">
        {showActionButton && (
          <button onClick={onActionClick} className="flex flex-col">
            <div className="w-5 h-5">{icon}</div>
          </button>
        )}
      </div>
    </div>
  </div>
);

interface DecoratedCardContentProps extends PropsWithChildren {
  className?: string;
}

export const DecoratedCardContent = ({ children, className }: DecoratedCardContentProps) => (
  <div className={classNames('flex-grow flex flex-col z-5 relative rounded-b-md', className)}>{children}</div>
);

export const DecoratedCardAddButton = ({ onClick, menuItems }: {onClick?: () => void, menuItems?: ContextMenuItem[]}) => (
  <div className="absolute right-3 -bottom-5">
    {onClick && (
      <AddButton onClick={onClick} />
    )}
    {menuItems && menuItems.length > 0 && (
      <ContextMenu items={menuItems} button={<AddButton />} />
    )}
  </div>
);

export const DecoratedCard = Object.assign(DecoratedCardComponent, {
  Header: DecoratedCardHeader,
  Content: DecoratedCardContent,
});
