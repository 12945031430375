/**
 * This function converts a string into a color code.
 * Based on https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
 * @param inString - The string to convert to a color.
 * @returns A string representing the html color.
 */
export const stringToColor = (inString: string) => {
  let hash = 0;

  for (let i = 0; i < inString.length; i++) {
    hash = inString.charCodeAt(i) + ((hash << 5) - hash);
  }

  let returnColor = '#';

  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;

    returnColor += ('00' + value.toString(16)).slice(-2);
  }

  return returnColor;
};
