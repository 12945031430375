import { Button, HintBox, LoadingIndicator, Modal, ModalOnCloseProps } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

interface DocuwareClientDeleteModalProps extends ModalOnCloseProps {
  clientName: string;
  isOpen: boolean;
  handleDelete: () => void;
  isDeleting: boolean;
}

export const DocuwareClientDeleteModal = ({
  clientName,
  onClose,
  isOpen,
  handleDelete,
  isDeleting,
}: DocuwareClientDeleteModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title={t('app.settingsApiDocuwareClientTitleDelete', { name: clientName })} />
      <Modal.Content className="text-center">
        {isDeleting && <LoadingIndicator text={t('common.deleteLoading')} mode="overlay" />}
        <HintBox hintType="warning">{t('app.settingsApiDocuwareDeleteClientWarning')}</HintBox>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
          {t('common.cancel')}
        </Button>
        <Button variant="danger" onClick={handleDelete} disabled={isDeleting}>
          {t('common.delete')}
        </Button>
      </Modal.Controls>
    </Modal>
  );
};
