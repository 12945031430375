import { i18n } from '@client/shared/utilities';

export const getDaysBetweenDates = (startDate: Date, endDate: Date) => {
  if (startDate && endDate) {
    const timeDifference = endDate.getTime() - startDate.getTime();
    if (timeDifference) {
      return Math.round(timeDifference / (1000 * 3600 * 24));
    }
  }
  return 0;
};

export const getDueDateMessage = (
  dueDate: string
): string => {
  let days = 0;
  if (dueDate) {
    days = getDaysBetweenDates(new Date(dueDate), new Date());
  }
  if (days === -1) {
    return i18n.t('projectControl.auditYesterday');
  } else if (days > 0) {
    return i18n.t('projectControl.auditDateInDays', {
      count: days,
    });
  } else if (days < 0) {
    return i18n.t('projectControl.auditDateDaysAgo', {
      count: days * -1,
    });
  }
  return i18n.t('projectControl.auditToday');
};
