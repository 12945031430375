import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiDeleteProjectGroupApiArg,
  ApiDeleteProjectGroupApiResponse,
  ApiDeleteProjectsFromGroupApiArg,
  ApiDeleteProjectsFromGroupApiResponse,
  ApiGetProjectGroupApiArg,
  ApiGetProjectGroupApiResponse,
  ApiPatchProjectGroupApiArg,
  ApiPatchProjectGroupApiResponse,
  ApiPostCreateProjectGroupApiArg,
  ApiPostCreateProjectGroupApiResponse,
  ApiPostGroupProjectsApiArg,
  ApiPostGroupProjectsApiResponse,
  ApiPostProjectGroupCoverApiArg,
  ApiPostProjectGroupCoverApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiProjectGroupEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetProjectGroup: {
    providesTags: (
      _result: ApiGetProjectGroupApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetProjectGroupApiArg,
    ) => [
      {
        type: ApiTagTypes.Project,
        id: args.groupId,
      },
    ],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateProjectGroup: {
    invalidatesTags: (
      _result: ApiPostCreateProjectGroupApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostCreateProjectGroupApiArg,
    ) => (error == null ? [ApiTagTypes.Projects] : []),
  },
  apiDeleteProjectGroup: {
    invalidatesTags: (
      _result: ApiDeleteProjectGroupApiResponse,
      error: FetchBaseQueryError,
      _args: ApiDeleteProjectGroupApiArg,
    ) => (error == null ? [ApiTagTypes.Projects] : []),
  },
  apiPatchProjectGroup: {
    invalidatesTags: (
      _result: ApiPatchProjectGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiPatchProjectGroupApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Project, id: args.body.id }] : []),
  },
  apiPostProjectGroupCover: {
    invalidatesTags: (
      _result: ApiPostProjectGroupCoverApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostProjectGroupCoverApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Project, id: args.groupId }] : []),
  },
  apiPostGroupProjects: {
    invalidatesTags: (
      _result: ApiPostGroupProjectsApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostGroupProjectsApiArg,
    ) => (error == null ? [ApiTagTypes.Projects, { type: ApiTagTypes.Project, id: args.body.groupId }] : [])
  },
  apiDeleteProjectsFromGroup: {
    invalidatesTags: (
      _result: ApiDeleteProjectsFromGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteProjectsFromGroupApiArg,
    ) => (error == null ? [ApiTagTypes.Projects, { type: ApiTagTypes.Project, id: args.body.groupId }] : [])
  },
};
