import { getUsageTypes, useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import {
  TaxonomyReadModel,
  TaxonomyType,
  TaxonomyUsageType,
  ParkingLotType,
  ParkingLotFeatures,
  useApiPostCreateTaxonomyMutation, UserDefinedFieldPayload,
} from '@client/shared/api';
import {
  MultiSelect,
  BaseSelect,
  Button,
  Form,
  FormField,
  FormRefHandle,
  FormWatch,
  EditNodeIcon,
  Modal,
  ModalOnCloseProps,
  NumberInput,
  TextInput,
  TimeLimitIcon, LoadingIndicator,
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { BuildingOfficeIcon, CalculatorIcon, DocumentIcon, TagIcon } from '@heroicons/react/24/outline';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAllowedTaxonomyTypes, useMgfExclusiveTypes } from '../../hooks';
import {
  BuildingItemAddFormValidationSchema,
  BuildingItemAddFormValidationValues,
} from './BuildingItemAddFormValidationSchema';
import { EditUserDefinedFields } from '@client/project/shared';

interface BuildingItemAddChildModalProps extends ModalOnCloseProps {
  parent: TaxonomyReadModel;
}

export const BuildingItemAddChildModal = ({ onClose, parent }: BuildingItemAddChildModalProps) => {
  const { t } = useTranslation();
  const formRef = useRef<FormRefHandle<BuildingItemAddFormValidationValues>>();

  const [postItem, { isLoading }] = useApiPostCreateTaxonomyMutation();

  const loadedProjectId = useLoadedProjectId();

  const loadedVariantId = useLoadedVariantId();
  const { forCreatingChild: forCreation } = useAllowedTaxonomyTypes(parent);
  const mgfExclusiveTypes = useMgfExclusiveTypes(parent);

  // for custom fields
  const [udfUpdatePayload, setUdfUpdatePayload] = useState<UserDefinedFieldPayload[] | undefined>();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [customFieldsAreValid, setCustomFieldsAreValid] = useState(true)

  const allowedTypes = forCreation.map((taxonomyType) => ({
    label: t(`projectTaxonomy.taxonomyType.${taxonomyType}`),
    value: taxonomyType,
  }));

  const parkingLotTypes = ['Undefined', 'Garage', 'Outdoor', 'Duplex', 'Carport', 'Other'];
  const allowedParkingLotTypes = parkingLotTypes.slice(1).map((parkingType) => ({
    label: t(`projectTaxonomy.parkingLotType.${parkingType}`),
    value: parkingType,
  }));

  const parkingLotFeatures: Record<string, string> = {
    Wallbox11kW: '11kW Wallbox',
    Wallbox22kW: '22kW Wallbox',
    Xxl: 'XXL',
    Disabled: 'Disabled',
  };

  const allowedParkingLotFeatures = Object.keys(parkingLotFeatures).map((parkingType) => ({
    label: parkingLotFeatures[parkingType],
    value: parkingType,
  }));

  let grossFloorRestValue: number | undefined;

  if (parent.sizes?.grossFloorRestValue?.value == null) {
    grossFloorRestValue = parent.sizes.bgf?.value ?? undefined;
  } else {
    grossFloorRestValue =
      parent.sizes?.grossFloorRestValue?.value > 0 ? parent.sizes?.grossFloorRestValue?.value : undefined;
  }

  let netFloorRestValue: number | undefined;

  if (parent.sizes?.netFloorRestValue?.value == null) {
    netFloorRestValue = parent.sizes.ngf?.value ?? undefined;
  } else {
    netFloorRestValue = parent.sizes?.netFloorRestValue?.value > 0 ? parent.sizes?.netFloorRestValue?.value : undefined;
  }

  let rentalSpaceRestValue: number | undefined;

  if (parent.sizes?.rentalSpaceRestValue?.value == null) {
    rentalSpaceRestValue = parent.sizes.rentalSpace?.value ?? undefined;
  } else {
    rentalSpaceRestValue =
      parent.sizes?.rentalSpaceRestValue?.value > 0 ? parent.sizes?.rentalSpaceRestValue?.value : undefined;
  }

  const handleSubmit = async (data: BuildingItemAddFormValidationValues) => {
    if (loadedVariantId == null || !customFieldsAreValid) return;
    try {
      await safeMutation(
        postItem,
        {
          calculationModelId: loadedVariantId,
          projectId: loadedProjectId ?? 'unset',
          body: {
            parentId: parent.itemId,
            name: data.name,
            customerName: data.customerName,
            bgf: mgfExclusiveTypes.includes(data.type as TaxonomyType) ? undefined : data.bgf,
            ngf: mgfExclusiveTypes.includes(data.type as TaxonomyType) ? undefined : data.ngf,
            rentalSpace: data.rentalSpace,
            type: data.type as TaxonomyType,
            usageType:
              data.usageType !== undefined && data.usageType !== ''
                ? (data.usageType as TaxonomyUsageType)
                : ('Undefined' as TaxonomyUsageType),
            amount: data.amount,
            parkingLot:
              data.type === 'Parking'
                ? {
                    number: data.parkingLotNumber,
                    type: data.parkingLotType as ParkingLotType,
                    features: data.parkingLotFeatures as ParkingLotFeatures[],
                    description: data.parkingLotDescription,
                  }
                : null,
            userDefinedFieldsPayload: udfUpdatePayload?.length ? udfUpdatePayload : undefined,
          },
        },
        isLoading
      );
      onClose(true);
    } catch (error) {
      console.log(error);
    }
  };

  const defaultFormValues = {
    name: '',
    customerName: '',
    bgf: grossFloorRestValue,
    ngf: netFloorRestValue,
    rentalSpace: rentalSpaceRestValue,
    type: '',
    usageType: parent?.itemUsageType ?? '',
    parkingLotDescription: '',
    parkingLotNumber: '',
    parkingLotType: '',
    parkingLotFeatures: [],
  };

  return (
    <Form<BuildingItemAddFormValidationValues>
      onSubmit={(data) => {
        setIsFormSubmitted(true)
        handleSubmit(data)
      }}
      validationSchema={BuildingItemAddFormValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
      ref={formRef}
    >
      {isLoading && <LoadingIndicator text={t('projectTaxonomy.creatingTaxonomyLoadingIndicator')} mode="overlay-window" />}
      <Modal.Header title={t('projectTaxonomy.addBuildingElementTitle', { element: parent.itemName })} />
      <Modal.Content className="overflow-y-auto divide-gray-100 divide-y my-6">
        <FormField name="name">
          {(control) => (
            <TextInput
              label={t('projectTaxonomy.addBuildingElementLabelName')}
              icon={<EditNodeIcon />}
              {...control}
            />
          )}
        </FormField>
        <FormField name="customerName">
          {(control) => (
            <TextInput
              label={t('projectTaxonomy.addBuildingElementLabelCustomerName')}
              icon={<EditNodeIcon/>}
              {...control}
            />
          )}
        </FormField>
        <FormField name="type">
          {(control) => (
            <BaseSelect
              label={t('projectTaxonomy.addBuildingElementLabelType')}
              options={allowedTypes}
              icon={<TimeLimitIcon />}
              {...control}
            />
          )}
        </FormField>

        <FormWatch<BuildingItemAddFormValidationValues>
          onChange={({ type }) => {
            if (type === 'Parking') {
              formRef.current?.setValue('usageType', 'Traffic');
            }
          }}
          fieldNames={['type']}
        >
          {({ type }) => (
            <>
              <FormField name="usageType">
                {(control) => (
                  <BaseSelect
                    label={t('projectTaxonomy.addBuildingElementLabelUsageType')}
                    options={getUsageTypes()}
                    icon={<TimeLimitIcon />}
                    disabled={type === 'Parking'}
                    {...control}
                  />
                )}
              </FormField>
              {!mgfExclusiveTypes.includes(type as TaxonomyType) && (
                <>
                  <FormField name="bgf">
                    {(control) => (
                      <NumberInput
                        label={`${t('projectTaxonomy.buildingBgf')} (${
                          parent?.sizes?.grossFloorRestValue?.unit ?? parent?.sizes?.bgf?.unit
                        })`}
                        icon={<TagIcon />}
                        {...control}
                      />
                    )}
                  </FormField>

                  <FormField name="ngf">
                    {(control) => (
                      <NumberInput
                        label={`${t('projectTaxonomy.buildingNgf')} (${
                          parent?.sizes?.netFloorRestValue?.unit ?? parent?.sizes?.ngf?.unit
                        })`}
                        icon={<BuildingOfficeIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                </>
              )}
              <FormField name="rentalSpace">
                {(control) => (
                  <NumberInput
                    label={`${t('projectTaxonomy.buildingMfg')} (${
                      parent?.sizes?.rentalSpaceRestValue?.unit ?? parent?.sizes?.rentalSpace?.unit
                    })`}
                    icon={<DocumentIcon />}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="amount">
                {(control) => (
                  <NumberInput label={t('projectTaxonomy.buildingAmount')} icon={<CalculatorIcon />} {...control} />
                )}
              </FormField>
              {type === 'Parking' && (
                <>
                  <FormField name="parkingLotType">
                    {(control) => (
                      <BaseSelect
                        label={t('projectTaxonomy.parkingLot.type')}
                        options={allowedParkingLotTypes}
                        icon={<TimeLimitIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="parkingLotDescription">
                    {(control) => (
                      <TextInput
                        label={t('projectTaxonomy.parkingLotDescription')}
                        icon={<DocumentIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="parkingLotNumber">
                    {(control) => (
                      <TextInput label={t('projectTaxonomy.parkingLotNumber')} icon={<DocumentIcon />} {...control} />
                    )}
                  </FormField>

                  <FormField name="parkingLotFeatures">
                    {(control) => (
                      <MultiSelect
                        label={t('projectTaxonomy.parkingLotFeatures')}
                        options={allowedParkingLotFeatures}
                        icon={<TimeLimitIcon />}
                        {...control}
                        value={control.value}
                        onChange={(value) => {
                          if (control.value.includes('Wallbox11kW') && value.includes('Wallbox22kW')) {
                            control.onChange(value.filter((feature) => feature !== 'Wallbox11kW'));
                          } else if (control.value.includes('Wallbox22kW') && value.includes('Wallbox11kW')) {
                            control.onChange(value.filter((feature) => feature !== 'Wallbox22kW'));
                          } else {
                            control.onChange(value);
                          }
                        }}
                        className="w-full"
                      />
                    )}
                  </FormField>
                </>
              )}
            </>
          )}
        </FormWatch>
        <EditUserDefinedFields
          type="Taxonomy"
          setUpdatePayload={setUdfUpdatePayload}
          isSubmitted={isFormSubmitted}
          updateIsValid={setCustomFieldsAreValid}
          hasPadding={false}
        />
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
          {t('common.cancel')}
        </Button>
        <Button variant="primary" formSubmit onClick={() => setIsFormSubmitted(true)}>
          {t('projectTaxonomy.addBuildingElementAction')}
        </Button>
      </Modal.Controls>
    </Form>
  );
};
