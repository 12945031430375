import {
  useApiPostCreateCatalogTemplateMutation,
  useApiPostUpdateCatalogTemplateMutation,
  CatalogTemplateReadModel,
  useApiGetCatalogsQuery,
  useApiGetRiskCatalogsQuery,
  useApiGetEarningsCatalogsQuery,
  useApiGetFinancingCatalogsQuery,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import {
  Button,
  Form,
  FormField,
  EditNodeIcon,
  SlideOver,
  TextInput,
  Modal,
  SlideOverOnCloseProps,
  LoadingIndicator,
  BaseSelect, TagWindowIcon
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { CatalogTemplateFormValidationSchema, CatalogTemplateFormValidationValues } from './CatalogTemplateFormValidationSchema';
import { CatalogTemplateDeleteModal } from './CatalogTemplateDeleteModal';

interface CatalogTemplateDetailsSlideOverProps extends SlideOverOnCloseProps {
  isAddMode: boolean;
  catalogTemplate: CatalogTemplateReadModel | undefined;
}

export const CatalogTemplateDetailsSlideOver = ({ catalogTemplate, isAddMode, onClose }: CatalogTemplateDetailsSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const canSelectCostCatalog = isAddMode;
  const canSelectRiskCatalog = catalogTemplate?.riskCatalog === undefined || isAddMode;
  const canSelectEarningsCatalog = catalogTemplate?.earningsCatalog === undefined || isAddMode;
  const canSelectFinancingCatalog = catalogTemplate?.financingCatalog === undefined || isAddMode;

  const [postUpdate, { isLoading: isUpdating }] = useApiPostUpdateCatalogTemplateMutation();
  const [postCreate, { isLoading: isCreating }] = useApiPostCreateCatalogTemplateMutation();
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const {data : costCatalogData, isFetching: isFetchingCostCatalog } = useApiGetCatalogsQuery();
  const {data : riskCatalogData, isFetching: isFetchingRiskCatalog } = useApiGetRiskCatalogsQuery();
  const {data : earningsCatalogData, isFetching: isFetchingEarningsCatalog } = useApiGetEarningsCatalogsQuery();
  const {data : financingCatalogData, isFetching: isFetchingFinancingCatalog } = useApiGetFinancingCatalogsQuery();

  const costCatalogOptions = costCatalogData?.catalogs.map((catalog: { name: string; id: string }) => ({
      label: catalog.name,
      value: catalog.id,
    })) ?? [];

  const riskCatalogOptions = riskCatalogData?.riskCatalogs.map((catalog: { name: string; id: string }) => ({
    label: catalog.name,
    value: catalog.id,
  })) ?? [];

  const earningsCatalogOptions = earningsCatalogData?.earningsCatalogs.map((catalog: { name: string; id: string }) => ({
    label: catalog.name,
    value: catalog.id,
  })) ?? [];

  const financingCatalogOptions = financingCatalogData?.financingCatalogs.map((catalog: { name: string; id: string }) => ({
    label: catalog.name,
    value: catalog.id,
  })) ?? [];

  const defaultFormValues = {
    name: catalogTemplate?.name ?? '',
    comment: catalogTemplate?.comment,
    costCatalogId: catalogTemplate?.costCatalog.id,
    riskCatalogId: catalogTemplate?.riskCatalog?.id,
    earningsCatalogId: catalogTemplate?.earningsCatalog?.id,
    financingCatalogId: catalogTemplate?.financingCatalog?.id,
  };

  const handleCatalogTemplateSubmit = async (data: CatalogTemplateFormValidationValues) => {
    if (isAddMode) {
      try {
        await safeMutation(
          postCreate,
          {
            body: {
              name: data.name,
              comment: data.comment,
              costCatalogId: data.costCatalogId,
              riskCatalogId: data.riskCatalogId,
              earningsCatalogId: data.earningsCatalogId,
              financingCatalogId: data.financingCatalogId
            },
          },
          isCreating
        );

        onClose(true);
      } catch {
        /* left blank */
      }
    } else {
      try {
        await safeMutation(
          postUpdate,
          {
            catalogTemplateId: catalogTemplate?.catalogTemplateId ?? '',
            body: {
              name: data.name,
              comment: data.comment,
              riskCatalogId: data.riskCatalogId,
              earningsCatalogId: data.earningsCatalogId,
              financingCatalogId: data.financingCatalogId
            },
          },
          isUpdating
        );

        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  return (
    <>
      <Form<CatalogTemplateFormValidationValues>
        onSubmit={handleCatalogTemplateSubmit}
        validationSchema={CatalogTemplateFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
      >
        <SlideOver.Header
          title={catalogTemplate?.name ?? t('templates.NewCatalogTemplateTitle')}
          backgroundClassName="bg-gray-600"
          onClose={() => onClose(false)}
        />

        <SlideOver.Content>
          {(isUpdating || isCreating || isFetchingCostCatalog || isFetchingRiskCatalog || isFetchingEarningsCatalog || isFetchingFinancingCatalog) ? (
            <div className="m-2">
              <LoadingIndicator text={t('templates.catalogTemplatesLoadingIndicator') ?? ''} />
            </div>
          ) : (
            <>
              <div className="m-8 bg-white">
                <div className="divide-gray-100 divide-y">
                  <FormField name="name">
                    {(control) => (
                      <TextInput
                        label={t('common.name')}
                        icon={<TagWindowIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="comment">
                    {(control) => (
                      <TextInput
                        label={t('common.comment')}
                        icon={<TagWindowIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                </div>
              </div>

              <div className="mx-8 mb-8 bg-white">
                <div className="divide-gray-100 divide-y">
                  <FormField name="costCatalogId">
                    {(control) => (
                      <BaseSelect
                        disabled={!canSelectCostCatalog}
                        label={t('templates.costCatalog')}
                        icon={<EditNodeIcon />}
                        options={costCatalogOptions}
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="riskCatalogId">
                    {(control) => (
                      <BaseSelect
                        disabled={!canSelectRiskCatalog}
                        label={t('templates.riskCatalog')}
                        icon={<EditNodeIcon />}
                        options={riskCatalogOptions}
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="earningsCatalogId">
                    {(control) => (
                      <BaseSelect
                        disabled={!canSelectEarningsCatalog}
                        label={t('templates.earningsCatalog')}
                        icon={<EditNodeIcon />}
                        options={earningsCatalogOptions}
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="financingCatalogId">
                    {(control) => (
                      <BaseSelect
                        disabled={!canSelectFinancingCatalog}
                        label={t('templates.financingCatalog')}
                        icon={<EditNodeIcon />}
                        options={financingCatalogOptions}
                        {...control}
                      />
                    )}
                  </FormField>
                </div>
              </div>
            </>
          )}
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className="w-full flex justify-between">
            {!isAddMode && (
              <Button variant="warning" onClick={() => setShowDelete(true)}>
                {t('common.delete')}
              </Button>
            )}
            <div className="flex ml-auto">
              <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
                {t('common.cancel')}
              </Button>
              <Button variant="primary" formSubmit={true} innerRef={submitRef}>
                {t('common.save')}
              </Button>
            </div>
          </div>
        </SlideOver.Controls>
      </Form>

      <Modal isOpen={showDelete} onClose={onClose} variant="small">
        <CatalogTemplateDeleteModal
          catalogTemplate={catalogTemplate}
          onClose={(success: boolean) => {
            setShowDelete(false);

            if (success) {
              onClose(true);
            }
          }}
        />
      </Modal>
    </>
  );
};
