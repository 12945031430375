import React, {useContext, useMemo, useRef} from "react";
import {
  CalculateColumns, CalculateContext,
  CalculateSectionHeader,
  Column, MIN_TIMELINE_HEADER_HEIGHT, OptionalColumn,
  TimeLineDataContext,
  TimeLineView
} from ".";
import {CalculationModelMetadata} from "@client/shared/api";
import {DecoratedElement} from "../hooks";
import { Button } from '@client/shared/toolkit';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';

export interface CalculateSectionHeadersProps {
  title: string
  icon: React.ReactNode;
  view: TimeLineView
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedVersionElements?: DecoratedElement<any>[];
  archivedVersions?: CalculationModelMetadata[];
  selectedVersionId: string;
  setSelectedVersionId: (VersionId: string) => void;
  optionalColumn: OptionalColumn;
  setOptionalColumn: (column: OptionalColumn) => void;
  obligoColumn: OptionalColumn;
  setObligoColumn: (column: OptionalColumn) => void;
  onCollapse: (level: number) => void;
  onCollapseContainer: (state: boolean) => void;
  level?: number
  showLevelToggles?: boolean
  showToggleButton?: boolean
}

export const CalculateSectionHeaders = (props: CalculateSectionHeadersProps) => {
  const {
    icon,
    title,
    view,
    archivedVersions,
    selectedVersionId,
    setSelectedVersionId,
    optionalColumn,
    setOptionalColumn,
    obligoColumn,
    setObligoColumn,
    onCollapse,
    onCollapseContainer,
    level,
    showLevelToggles = true,
    showToggleButton = false
  } = props
  const sectionHeaderRef = useRef<HTMLDivElement>(null)
  const { headerHeight } = useContext(TimeLineDataContext);
  const { isListCollapsed, setIsListCollapsed } = useContext(CalculateContext);
  const headerStyle = useMemo(() => {
    return { top: headerHeight ? headerHeight - MIN_TIMELINE_HEADER_HEIGHT : undefined }
  }, [headerHeight])

  return useMemo(() => {
    return (
      <div
        className="flex sticky top-0 z-10 transition-[top] duration-300 w-full pl-2"
        ref={sectionHeaderRef}
        style={headerStyle}
      >
        {showToggleButton && (
          <Button
            variant="secondary"
            className="aspect-square shadow-lg absolute right-0 top-[50px] z-10 hidden xl:flex justify-center items-center bottom-auto w-8 h-8"
            onClick={() => {
              setIsListCollapsed(!isListCollapsed)
            }}
            hasPadding={false}
          >
            <ChevronLeftIcon
              className={cn('w-4 h-4 transition-transform duration-300', {
                '-rotate-180': isListCollapsed,
              })}
            />
          </Button>
        )}
        <CalculateColumns
          header
          view={view}
          informationColumnContent={(
            <CalculateSectionHeader
              archivedVersions={archivedVersions}
              selectedVersionId={selectedVersionId}
              setSelectedVersionId={setSelectedVersionId}
              optionalColumn={optionalColumn}
              setOptionalColumn={setOptionalColumn}
              obligoColumn={obligoColumn}
              setObligoColumn={setObligoColumn}
              title={title}
              icon={icon}
              onCollapse={onCollapse}
              onLevelToggle={onCollapseContainer}
              level={level}
              showLevelToggles={showLevelToggles}
            />
          )}
          currentColumnContent={(
            <CalculateSectionHeader
              archivedVersions={archivedVersions}
              selectedVersionId={selectedVersionId}
              setSelectedVersionId={setSelectedVersionId}
              optionalColumn={optionalColumn}
              setOptionalColumn={setOptionalColumn}
              obligoColumn={obligoColumn}
              setObligoColumn={setObligoColumn}
              title={Column.CURRENT}
              icon={icon}
              onCollapse={onCollapse}
              onLevelToggle={onCollapseContainer}
            />
          )}
          obligoColumnContent={(
            <CalculateSectionHeader
              archivedVersions={archivedVersions}
              selectedVersionId={selectedVersionId}
              setSelectedVersionId={setSelectedVersionId}
              optionalColumn={optionalColumn}
              setOptionalColumn={setOptionalColumn}
              obligoColumn={obligoColumn}
              setObligoColumn={setObligoColumn}
              title={Column.OBLIGO}
              icon={icon}
              onCollapse={onCollapse}
              onLevelToggle={onCollapseContainer}
            />
          )}
          optionalColumnContent={(
            <CalculateSectionHeader
              archivedVersions={archivedVersions}
              selectedVersionId={selectedVersionId}
              setSelectedVersionId={setSelectedVersionId}
              optionalColumn={optionalColumn}
              setOptionalColumn={setOptionalColumn}
              obligoColumn={obligoColumn}
              setObligoColumn={setObligoColumn}
              title={Column.OPTIONAL_COLUMN}
              icon={icon}
              onCollapse={onCollapse}
              onLevelToggle={onCollapseContainer}
            />
          )}
        />
      </div>
    )
  }, [
    headerStyle,
    showToggleButton,
    setIsListCollapsed,
    isListCollapsed,
    view,
    archivedVersions,
    selectedVersionId,
    setSelectedVersionId,
    optionalColumn,
    setOptionalColumn,
    obligoColumn,
    setObligoColumn,
    title,
    icon,
    onCollapse,
    level,
    showLevelToggles,
    onCollapseContainer
  ])
}
