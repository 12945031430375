import { DealReadModel } from '@client/shared/api';
import React, { useState } from 'react';
import { getPaymentFrequencyValueLabel, getAdjustmentTypeValueLabel } from '../../utils';
import {
  SlideOver,
  DecoratedCard,
  EstimateIcon,
  PurchaseOrderIcon,
  Calendar7Icon,
  TransactionIcon,
  PeriodSliderIcon,
  CancelIcon,
  LoveTimeIcon,
  DealAdjustmentTypeIcon, PencilIcon
} from '@client/shared/toolkit';
import { DealRentConditionSlideOver } from './DealRentConditionSlideOver';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@client/shared/utilities';

interface DealRentConditionsProps {
  deal: DealReadModel;
  isReadOnly:boolean;
}

export const DealRentConditions = ({ deal: { dealCondition, id, status, type }, isReadOnly }: DealRentConditionsProps) => {
  const { t } = useTranslation();

  const isLocked = status === 'Closed';

  const [showEditForm, setShowEditForm] = useState<boolean>(false);

  return (
    <div>
      <DecoratedCard>
        <DecoratedCard.Header
          showActionButton={!isLocked}
          onActionClick={() => setShowEditForm(true)}
          icon={<PencilIcon />}
        >
          {t('projectRent.dealConditionSectionTitleConditions')}
        </DecoratedCard.Header>
        <DecoratedCard.Content className="w-full h-full flex items-center justify-center bg-white">
          <div className="flex w-full divide-x-2 divide-black my-4">
            <div className="ml-6" />

            <div className="w-6/12 pl-2 pr-8 flex flex-col justify-start space-y-3">
              {dealCondition && dealCondition.rentStart && dealCondition.rentEnd && (
                <div className="flex">
                  <Calendar7Icon className="w-5" />
                  <div className="w-full ml-2">
                    <div className="text-xs text-gray-400">{t('projectRent.dealConditionLabelRentDuration')}</div>
                    <div className="flex">
                      <div className="w-3/12 text-sm font-bold">{formatDate(dealCondition.rentStart)}</div>
                      <div className="w-6/12 border-dotted border-b-2 border-slate-400 mb-1 mx-2" />
                      <div className="w-3/12 text-end text-sm font-bold">{formatDate(dealCondition.rentEnd)}</div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex">
                <CancelIcon className="w-5" />
                <div className="w-full ml-2">
                  <div className="text-xs text-gray-400">
                    {t('projectRent.dealConditionLabelContractPeriodOfNotice')}
                  </div>
                  <div className="flex">
                    {dealCondition &&
                      dealCondition.contractTerminationNotice === 0 &&
                      dealCondition.contractEarliestTerminationDate === null && (
                        <div className="text-sm font-bold">
                          {t('common.countMonth', { count: dealCondition.contractTerminationNotice })}
                        </div>
                      )}
                    {dealCondition &&
                      dealCondition.contractTerminationNotice === 0 &&
                      dealCondition.contractEarliestTerminationDate && (
                        <div className="text-end text-sm font-bold">
                          {formatDate(dealCondition.contractEarliestTerminationDate)}
                        </div>
                      )}
                    {dealCondition && dealCondition.contractTerminationNotice !== 0 && (
                      <div className="flex w-full">
                        <div className="w-3/12 text-sm font-bold">
                          {t('common.countMonth', { count: dealCondition.contractTerminationNotice })}
                        </div>

                        {dealCondition.contractEarliestTerminationDate && (
                          <div className="w-9/12 flex">
                            <div className="w-7/12 border-dotted border-b-2 border-slate-400 mb-1 mx-2"></div>
                            <div className="w-5/12 text-end text-sm font-bold">
                              {formatDate(dealCondition.contractEarliestTerminationDate)}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {dealCondition && dealCondition.contractPossibleExtensions !== 0 && (
                <div className="flex">
                  <PeriodSliderIcon className="w-5" />
                  <div className="w-full ml-2">
                    <div className="text-xs text-gray-400">{t('projectRent.dealConditionLabelPossibleExtensions')}</div>
                    <div className="flex">
                      <div className="w-5/12 text-sm font-bold">{dealCondition?.contractPossibleExtensions}</div>

                      {dealCondition.contractExtensionDuration !== 0 && (
                        <div className="flex w-7/12">
                          <div className="w-2/12 border-dotted border-b-2 border-slate-400 mb-1 mx-1"></div>
                          <div className="w-10/12 text-end text-sm font-bold">
                            {t('common.countMonth', { count: dealCondition.contractExtensionDuration })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {dealCondition && dealCondition.rentFreePeriodStart && dealCondition.rentFreePeriodEnd && (
                <div className="flex">
                  <LoveTimeIcon className="w-5" />
                  <div className="w-full ml-2">
                    <div className="text-xs text-gray-400">{t('projectRent.dealConditionLabelRentFreePeriod')}</div>
                    <div className="flex">
                      <div className="w-3/12 text-sm font-bold">{formatDate(dealCondition.rentFreePeriodStart)}</div>
                      <div className="w-5/12 border-dotted border-b-2 border-slate-400 mb-1 mx-1"></div>
                      <div className="w-5/12 text-end text-sm font-bold">
                        {formatDate(dealCondition.rentFreePeriodEnd)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="w-6/12 pl-2 pr-6 flex flex-col justify-start space-y-3">
              <div className="flex">
                <PurchaseOrderIcon className="w-5" />
                <div className="w-full ml-2">
                  <div className="text-xs text-gray-400">{t('projectRent.dealConditionLabelBillingPeriod')}</div>
                  <div className="text-sm font-bold">{getPaymentFrequencyValueLabel(dealCondition?.billingPeriod)}</div>
                </div>
              </div>

              <div className="flex">
                <TransactionIcon className="w-5" />
                <div className="w-full ml-2">
                  <div className="text-xs text-gray-400">{t('projectRent.dealConditionLabelRentDueDay')}</div>
                  <div className="text-sm font-bold">{t('common.countDay', { count: dealCondition?.rentDueDays })}</div>
                </div>
              </div>

              <div className="flex">
                <DealAdjustmentTypeIcon className="w-5" />
                <div className="w-full ml-2">
                  <div className="text-xs text-gray-400">{t('projectRent.dealConditionLabelAdjustmentType')}</div>
                  <div className="flex">
                    <div className="w-6/12 text-sm font-bold">
                      {getAdjustmentTypeValueLabel(dealCondition.adjustmentType)}
                    </div>
                    <div className="w-2/12 border-dotted border-b-2 border-slate-400 mb-1 mx-1"></div>
                    <div className="w-4/12 text-end text-sm font-bold">{dealCondition?.adjustmentTypeActiveValue}%</div>
                  </div>
                </div>
              </div>

              <div className="flex">
                <EstimateIcon className="w-5" />
                <div className="w-full ml-2">
                  <div className="text-xs text-gray-400">{t('projectRent.dealConditionLabelAdjustmentInterval')}</div>
                  <div className="flex">
                    <div className="w-3/12 text-sm font-bold">
                      {getPaymentFrequencyValueLabel(dealCondition?.adjustmentInterval)}
                    </div>

                    {dealCondition && dealCondition.adjustmentDeadline && (
                      <div className="flex w-9/12">
                        <div className="w-6/12 border-dotted border-b-2 border-slate-400 mb-1 mx-1"></div>
                        <div className="w-6/12 text-end text-sm font-bold">
                          {formatDate(dealCondition.adjustmentDeadline)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DecoratedCard.Content>
      </DecoratedCard>

      <SlideOver isOpen={showEditForm} onClose={() => setShowEditForm(false)}>
        <DealRentConditionSlideOver
          isReadOnly={isReadOnly}
          rentCondition={dealCondition}
          dealId={id}
          type={type}
          isSecurityCondition={false}
          onClose={() => setShowEditForm(false)}
        />
      </SlideOver>
    </div>
  );
};
