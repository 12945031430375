import { ConfirmModal, HintBox, LoadingIndicator } from '@client/shared/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  RequirementReadModel,
  RequirementShortReadModel,
  useApiDeleteClusterItemRequirementMutation,
} from '@client/shared/api';
import { useLoadedPortfolioId } from '@client/portfolio/store';
import { safeMutation } from '@client/shared/utilities';

export const RequirementDeleteModal = ({
  onClose,
  requirement,
  clusterItemId
}: {
  onClose: () => void;
  requirement: RequirementReadModel | RequirementShortReadModel;
  clusterItemId: string;
}) => {
  const { t } = useTranslation();
  const loadedPortfolioId = useLoadedPortfolioId();

  const [deleteRequirement, { isLoading }] = useApiDeleteClusterItemRequirementMutation();

  const handleOnClose = () => {
    onClose();
  };

  const handleDelete = async () => {
    if (loadedPortfolioId) {
      try {
        await safeMutation(
          deleteRequirement,
          {
            clusterItemId: clusterItemId,
            requirementId: requirement.id,
            portfolioId: loadedPortfolioId,
          },
          isLoading,
        );
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  };
  return (
    <ConfirmModal
      title={t('portfolio.projecting.requirementDeleteModal.title')}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isLoading}
    >
      {isLoading && (
        <LoadingIndicator
          text={t('portfolio.projecting.requirementDeleteModal.deletingLoadingIndicator') ?? ''}
          mode="overlay"
        />
      )}
      <HintBox hintType="warning">{t('portfolio.projecting.requirementDeleteModal.deleteHint')}</HintBox>
    </ConfirmModal>
  );
};
