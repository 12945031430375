import { CompanyReadModel, useApiDeleteCompanyMutation } from "@client/shared/api";
import { ConfirmModal, HintBox, LoadingIndicator, ModalOnCloseProps } from "@client/shared/toolkit";
import { safeMutation } from "@client/shared/utilities";
import { useTranslation } from "react-i18next";
import React from 'react';

interface CompanyDeleteModalProps extends ModalOnCloseProps {
    company?: CompanyReadModel;
  }

  export const CompanyDeleteModal = ({ company, onClose }: CompanyDeleteModalProps) => {
    const { t } = useTranslation();

    const [call, { isLoading }] = useApiDeleteCompanyMutation();

    const handleOnClose = () => {
      onClose(false);
    };

    const handleDelete = async () => {
      try {
        await safeMutation(
          call,
          {
            companyId: company?.id ?? '',
          },
          isLoading
        );
        onClose(true);
      } catch {
        /* left blank */
      }
    };

    const deletePrompt = company?.type === 'Company'
      ? t('app.masterDataCompaniesDeleteCompanyPrompt')
      : t('app.masterDataCompaniesDeletePrivatePersonPrompt');

    return (
      <ConfirmModal
        title={deletePrompt}
        onConfirm={handleDelete}
        onClose={handleOnClose}
        confirmVariant="danger"
        confirmText={t('common.delete')}
        isLoading={isLoading}
      >
        {isLoading && (
          <LoadingIndicator text={t('app.settingsCompaniesLoading') ?? ''} mode="overlay" />
        )}
        {company?.usedInContractsOrInvoices && (
          <HintBox hintType="danger">{t('app.companyIsActivlyUsed')}</HintBox>
        )}
        <HintBox hintType="warning">{t('projectTaxonomy.deleteBuildingElementHint')}</HintBox>
      </ConfirmModal>
    );
  };
