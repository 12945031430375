import { apiBase as api } from './apiBase';
import {
  ApiAdminExportReportApiArg,
  ApiGetAiEvalDocumentFileApiArg,
  ApiGetAiEvalDocumentFileApiResponse,
  ApiGetJobRouterPdfApiArg,
  ApiGetJobRouterPdfApiResponse
} from './apiGenerated';

export type ApiAdminExportReportFileApiResponse = {
  fileName: string;
  data: string;
  statusText: string;
}

const getFileNameFromResponseHeaders = (response: Response) => {
  const contentDisposition = response.headers?.get('content-disposition');
  let fileName = '';
  if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }
  }
  return fileName;
}

const getBlobFile = async (response: Response, fallbackName = '') => {
  const fileName = getFileNameFromResponseHeaders(response) ?? fallbackName;
  const blobFile = await response.blob();
  const file = new File([blobFile], fileName, { type: blobFile.type })
  return URL.createObjectURL(file)
}

export const injectedRtkCustomApi = api.injectEndpoints({
  endpoints: (build) => ({
    apiGetAiEvalDocumentPdfFile: build.query<ApiGetAiEvalDocumentFileApiResponse, ApiGetAiEvalDocumentFileApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/projects/${queryArg.projectId}/aievaldocument/${queryArg.documentId}/file`,
          method: 'GET',
          responseHandler: async (response) => {
            if (response.ok) {
              return getBlobFile(response, 'invoice_document.pdf');
            } else {
              return response.statusText;
            }
          },
          cache: 'no-cache'
        }
      },
    }),
    apiGetJobRouterPdfFile: build.query<ApiGetJobRouterPdfApiResponse, ApiGetJobRouterPdfApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/job_router/${queryArg.invoiceId}`,
          method: 'GET',
          responseHandler: async (response) => {
            if (response.ok) {
              return getBlobFile(response, 'invoice_document.pdf');
            } else {
              return null; // response.statusText;
            }
          },
          cache: 'no-cache'
        }
      },
    }),
    apiAdminExportReportAsFile: build.query<ApiAdminExportReportFileApiResponse, ApiAdminExportReportApiArg>({
      query: (queryArg) => ({
        url: `/api/admin/reports/${queryArg.reportId}/export`,
        method: 'GET',
        responseHandler: async (response) => {
          let fileName = '';
          let data = '';
          if (response.ok) {
            fileName = getFileNameFromResponseHeaders(response) ?? 'report.json';
            data = await getBlobFile(response, fileName);
          }
          return {
            fileName: fileName,
            data: data,
            statusText: response.statusText
          } as ApiAdminExportReportFileApiResponse
        }
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useApiGetAiEvalDocumentPdfFileQuery,
  useApiGetJobRouterPdfFileQuery,
  useApiAdminExportReportAsFileQuery
} = injectedRtkCustomApi
