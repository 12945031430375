import { TaxonomyReadModel } from '@client/shared/api';

export const getChildIds = (item: TaxonomyReadModel | undefined) => {
  const childIds: string[] = [];
  if (item && item.children.length > 0) {
    item.children.forEach((child) => {
      childIds.push(child.itemId);
      childIds.push(...getChildIds(child));
    });
  }
  return childIds;
};
