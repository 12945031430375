import { ChaseBankIcon } from '../../icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from '../FormField';
import { TextInput } from '../TextInput';
import { isValidSWIFT } from '@client/shared/utilities';

export const SwiftCodeFormField = () => {
  const { t } = useTranslation()
  return (
    <FormField name="swift">
      {(control) => (
        <TextInput
          icon={<ChaseBankIcon />}
          label={t('app.companiesSwift')}
          {...control}
          showValidation={(control.value && !isValidSWIFT(control.value)) || control.showValidation}
          isValidationValid={
            (!control.value || (control.value && isValidSWIFT(control.value))) && control.isValidationValid
          }
          helperText={control.value && !isValidSWIFT(control.value) ? t('app.companiesInvalidSwift') : undefined}
        />
      )}
    </FormField>
  )
}
