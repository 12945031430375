import { useLoadedVariant, useLoadedProjectUnitSystemSymbol } from '@client/project/store';
import { TaxonomyReadModel, CalculationModelMetadata, PlotReadModel } from '@client/shared/api';
import { formatNumber, formatUnit } from '@client/shared/utilities';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FormattedCurrency } from '@client/project/shared';
import { FormattedUnit } from '@client/project/shared';

interface HeaderInfoProps {
  item?: TaxonomyReadModel;
  project?: CalculationModelMetadata;
  plot?: PlotReadModel;
}

const showInfo = (
  item: TaxonomyReadModel | undefined,
  project: CalculationModelMetadata | undefined,
  plot: PlotReadModel | undefined
) => {
  return item?.itemType === 'Project' || item?.itemType === 'Room' || item?.itemType === 'Floor' || !!plot;
};

export const HeaderInfo = ({ item, project, plot }: HeaderInfoProps) => {
  const { t } = useTranslation();
  const { data: variantData } = useLoadedVariant();
  const activeVariant = variantData?.calculationModel.modelMetadata;
  
  const unitSystem = useLoadedProjectUnitSystemSymbol();

  return (
    <div>
      {showInfo(item, project, plot) && (
        <div
          className={classNames(
            'absolute bottom-2 h-[90px] right-0 w-1/3 mx-5 flex flex-col flex-grow flex-shrink z-0 bg-gray-50 rounded-t shadow-sm p-2',
            {
              'w-[500px]': item?.itemType === 'Project',
              'h-[160px]': item?.itemType === 'Room',
            }
          )}
        >
          <div className="text-lg px-2">
            {/* {item?.itemType === 'Project' && t(`projectTaxonomy.taxonomyType.${item?.itemType}`)} */}
            {item && item?.itemName}
            {plot && t('projectTaxonomy.plotDetailsAreaTitle')}
          </div>
          <div className="flex flex-row w-full">
            {project && activeVariant && item?.itemType === 'Project' && (
              <>
                <div className="w-4/12 border-r border-slate-400 pr-4 pl-2">
                  <div className="text-2xl font-bold text-center">
                    {formatUnit(activeVariant.grossFloorValue.value, activeVariant.grossFloorValue.unit)}
                  </div>
                  <div className="flex flex-row w-full justify-between">
                    <div className="text-xs text-neutral-500">{t('project.bgfRoi')}</div>
                    <div className="text-xs font-bold text-neutral-500">
                      {t('project.grz')} {formatNumber(activeVariant.grz)}
                    </div>
                  </div>
                </div>
                <div className="w-4/12 border-r border-slate-400 px-2">
                  <div className="text-2xl font-bold text-center">
                    {formatUnit(activeVariant.plot.value, activeVariant.plot.unit)}
                  </div>
                  <div className="flex flex-row w-full justify-between">
                    <div className="text-xs text-neutral-500">{t('project.plot')}</div>
                    <div className="text-xs font-bold text-neutral-500">
                      {t('project.gfz')} {formatNumber(activeVariant.gfz)}
                    </div>
                  </div>
                </div>
                <div className="w-3/12 border-r border-slate-400 px-2">
                  <div className="text-2xl font-bold text-center">
                    <FormattedUnit amount={activeVariant.averageRentUnitPrice.value} unit={activeVariant.averageRentUnitPrice.unit} />
                  </div>
                  <div className="text-xs text-neutral-500 text-center">
                    {t('project.rentPer') + (unitSystem)}
                  </div>
                </div>
                <div className="w-fit px-2">
                  <div className="text-2xl font-bold text-center">{formatNumber(activeVariant.multiplier)}</div>
                  <div className="text-xs text-neutral-500 text-center">{t('project.multiplier')}</div>
                </div>
              </>
            )}
            {item && item?.itemType === 'Room' && (
              <div className="w-full h-full">
                <div className="w-full px-2">
                  <div className="text-2xl font-bold">
                    {formatUnit(item.sizes.grossVolume?.value, item.sizes.grossVolume?.unit ?? '')}
                  </div>
                  <div className="flex flex-row w-full justify-between">
                    <div className="text-xs text-neutral-500">{t('projectTaxonomy.roomSize')}</div>
                    <div className="text-xs font-bold text-neutral-500"></div>
                  </div>
                </div>
                <div className="w-full flex flex-row p-2 justify-between">
                  {/* {features.map((feature) => (
                    <div className="flex flex-col">
                      <div className="w-12 h-12 m-[3px] p-2 bg-white border rounded shadow-sm"></div>
                      <div className="text-center text-xxs">{feature}</div>
                    </div>
                  ))} */}
                </div>
              </div>
            )}
            {item && item?.itemType === 'Floor' && (
              <>
                <div className="w-1/2 border-r border-slate-400 px-2">
                  <div className="text-2xl font-bold">
                    {formatUnit(
                      item?.sizes.effectiveGrossFloorValue?.value,
                      item?.sizes.effectiveGrossFloorValue?.unit ?? ''
                    )}
                  </div>
                  <div className="flex flex-row w-full justify-between">
                    <div className="text-xs text-neutral-500">{t('projectTaxonomy.buildingBgf')}</div>
                    <div className="text-xs font-bold text-neutral-500"></div>
                  </div>
                </div>
                <div className="w-1/2 px-2">
                  <div className="text-2xl font-bold"><FormattedCurrency amount={undefined} /></div>
                  <div className="flex flex-row w-full justify-between">
                    <div className="text-xs text-neutral-500">
                      {t('project.rentPer') + (unitSystem)}
                    </div>
                    <div className="text-xs font-bold text-neutral-500"></div>
                  </div>
                </div>
              </>
            )}
            {plot && (
              <>
                <div className="w-1/2 border-r border-slate-400 pr-4 pl-2">
                  <div className="text-2xl font-bold">{formatUnit(plot.totalPlotSize.value, plot.totalPlotSize.unit)}</div>
                  <div className="flex flex-row w-full justify-between">
                    <div className="text-xs text-neutral-500">{t('projectTaxonomy.plotBgf')}</div>
                    <div className="text-xs font-bold text-neutral-500">
                      {t('projectTaxonomy.plotGrz')} {formatNumber(plot.calculationModelGrz)}
                    </div>
                  </div>
                </div>
                <div className="w-1/2 px-4">
                  <div className="text-2xl font-bold  text-center">{formatNumber(plot.calculationModelGfz)}</div>
                  <div className="text-xs font-bold text-neutral-500 text-center">{t('projectTaxonomy.plotGfz')}</div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
