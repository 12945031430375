import * as yup from 'yup';
import { InferType } from 'yup';

export const EditMultiProjectFormValidationSchema = yup.object({
  id: yup.string().required('validation.required'),
  name: yup.string().required('validation.required'),
  description: yup.string().optional()
});

export type EditMultiProjectFormValidationValues = InferType<typeof EditMultiProjectFormValidationSchema>;
