import { DealStatus } from '@client/shared/api';

export const getDealStatusBackgroundColor = (status: DealStatus): string => {
  switch (status) {
    case 'Interested':
      return 'bg-green-300';
    case 'Draft':
      return 'bg-green-500';
    case 'Reserved':
      return 'bg-green-700';
    case 'Closed':
      return 'bg-green-900';
    default:
      return 'bg-red-500';
  }
};
