import { AuthSubjectReadModel, useApiGetAuthSubjectsQuery } from '@client/shared/api';
import { Button, LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import { useEffect, useState } from 'react';
import { OwnerEditSlideOver } from './OwnerEditSlideOver';
import { useTranslation } from 'react-i18next';

export const TenantOwner = () => {
  const { t } = useTranslation();

  const [showEditOwner, setShowEditOwner] = useState<boolean>(false);
  const { data, isFetching } = useApiGetAuthSubjectsQuery();
  const [current, setCurrent] = useState<AuthSubjectReadModel | undefined>(undefined);

  useEffect(() => {
    if (data) {
      const owners = data.filter((x) => x.isTenantOwner);
      if (owners.length > 0) {
        setCurrent(owners[0]);
      }
    }
  }, [data]);

  return (
    <>
      {isFetching && <LoadingIndicator mode="overlay-window" text={t('common.loading')} />}
      <div className="grow text-sm">
        <p className="text-gray-600 mb-3">{t('auth.owner')}</p>
        <div className="flex flex-col">
          <div className="font-bold">{current?.name}</div>
          <div>{current?.email}</div>
        </div>
      </div>
      <Button onClick={() => setShowEditOwner(true)} className="my-auto">
        {t('app.settingsTransferOwner')}
      </Button>
      <SlideOver isOpen={showEditOwner} onClose={() => setShowEditOwner(false)}>
        <OwnerEditSlideOver onClose={() => setShowEditOwner(false)} />
      </SlideOver>
    </>
  );
};
