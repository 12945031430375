import {
  CalculationModelEarningsElement,
  CalculationModelEarningsGroupElement,
  EarningsElementShortReadDto,
  TimelineErrors,
} from '@client/shared/api';
import { useMemo } from 'react';
import { DecoratedElement } from './useCosts';
import {filterElementsByCodes, filterElementsById } from '../utils';

export type EarningElement = {
  key: string;
  group?: CalculationModelEarningsGroupElement;
  earningElement?: CalculationModelEarningsElement;
  showError: boolean;
  errors: TimelineErrors[];
  type: 'group' | 'element' | 'summary';
};

function decorateElements(
  elements?: EarningsElementShortReadDto[],
  level = 0,
  parent?: DecoratedElement<EarningElement>
): DecoratedElement<EarningElement>[] {
  if (elements === undefined || elements.length === 0) return [];

  return elements.map((element, index) => {
    if (element.earningsElement) {
      const decoratedEarningElement: DecoratedElement<EarningElement> = {
        element: {
          key: element.earningsElement.id ?? 'id',
          type: 'element',
          errors: element.earningsElement.timelineErrors,
          earningElement: element.earningsElement,
          showError: element.earningsElement.timelineErrors.length > 0,
        },
        categoryId: element.earningsElement.code ?? '',
        level,
        parent,
        children: [],
      };
      return decoratedEarningElement;
    }

    if (element.group == null) throw new Error('not a earning element, not a group');

    const group = { ...element.group };
    const key = group.groupId ? group.groupId : `${element.group}-${index}`;

    const decorated: DecoratedElement<EarningElement> = {
      element: {
        key: key,
        group: group,
        showError:
          element.group.calculationState === 'Overflow' ||
          (group.timelineElement && group.timelineElement?.errors.length > 0) ||
          false,
        type: 'group',
        errors: group.timelineElement?.errors ?? [],
      },
      categoryId: key,
      children: [],
      level: level,
      disableClick: !element.group.isCatalog,
      parent,
    };

    const children = group.children ?? [];

    decorated.children = decorateElements(children, level + 1, decorated);
    return decorated;
  });
}

export const useEarnings = (earnings: EarningsElementShortReadDto[], filteredCodes?: string[], searchValue?: string, searchResults?: string[] | null,) => {
  return useMemo(() => {
    // decorate all elements while preserving all items and structure
    const decoratedElements = decorateElements(earnings);

    // filter out elements
    let filteredElements = filterElementsByCodes<EarningElement>(decoratedElements, filteredCodes);

    if (searchValue && searchResults) {
      filteredElements = filterElementsById(filteredElements, searchResults);
    }

    return filteredElements;
  }, [earnings, filteredCodes, searchValue, searchResults]);
};
