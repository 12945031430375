import { DealReadModel, DealType, DealStatus } from '@client/shared/api';
import { DealRow } from './DealRow';
import { getDealContractItemsSum } from '../DealCalculation';
import { useTranslation } from 'react-i18next';
import { TodoCustomIcon } from '@client/shared/toolkit';
import { FormattedCurrency } from '@client/project/shared';

interface DealsGroupProps {
  header: string;
  type: DealType;
  status: DealStatus;
  elements: DealReadModel[];
  onSelect: (deal: DealReadModel) => void;
  isReadOnly:boolean;
}

export const DealsGroup = ({ elements, header, onSelect, status, type, isReadOnly }: DealsGroupProps) => {
  const { t } = useTranslation();

  const groupElements = elements.filter((x) => x.status === status);
  const groupSum = groupElements.reduce((x, y) => (x = x + (getDealContractItemsSum(y) ?? 0)), 0);

  return (
    <div>
      {groupElements.length >= 1 && (
        <div>
          <div className="flex font-bold mb-3">
            <span className="inline-flex mr-2">
              <TodoCustomIcon className="w-4" />
            </span>
            <span>{header}</span>
          </div>

          <div className="flex flex-col space-y-2">
            {groupElements.map((x) => (
              <DealRow item={x} key={x.id} onSelect={onSelect} isReadOnly={isReadOnly} />
            ))}
          </div>

          <div className="flex w-full justify-end text-gray-400 font-bold mt-1">
            <div className="mr-16 text-right">
              <div className="text-xl"> <FormattedCurrency amount={groupSum} /></div>
              <div className="text-[10px]">
                {type === 'Rent' && t('projectRent.dealRent')}
                {type === 'Sell' && t('projectRent.dealPrice')}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
