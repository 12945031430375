import { createContext, PropsWithChildren, useEffect, useMemo, useState } from 'react';

export type CalculateContextType = {
  isLoading: boolean
  setIsLoading: (loading: boolean) => void
  isListCollapsed: boolean
  setIsListCollapsed: (collapsed: boolean) => void
}
export const CalculateContext = createContext<CalculateContextType>({
  isLoading: false,
  setIsLoading: () => {
    //
  },
  isListCollapsed: false,
  setIsListCollapsed: () => {
    //
  },
})

interface CalculateContextProviderProps extends PropsWithChildren {
  loading?: boolean
}
export const CalculateContextProvider = ({ children, loading = true }: CalculateContextProviderProps) => {
  const [isLoading, setIsLoading] = useState(loading)
  const [isListCollapsed, setIsListCollapsed] = useState(loading)

  useEffect(() => {
    setIsLoading(loading)
  }, [loading]);

  const value = useMemo(() => ({
    isLoading,
    setIsLoading,
    isListCollapsed,
    setIsListCollapsed
  }), [isLoading, isListCollapsed])

  return (
    <CalculateContext.Provider value={value}>
      {children}
    </CalculateContext.Provider>
  )
}
