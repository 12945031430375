import {
  ShortProjectGroupReadModel,
  useApiPatchProjectGroupMutation
} from '@client/shared/api';
import { TagIcon } from '@heroicons/react/24/outline';
import { useState, useRef } from 'react';
import {
  Button,
  Form,
  TextInput,
  FormField,
  SkyscrapersDottedIcon,
  Modal,
  SlideOver,
  SlideOverOnCloseProps,
  LoadingIndicator
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import {
  ProjectImageUploadModal
} from '@client/project/edit';
import {
  EditMultiProjectFormValidationSchema,
  EditMultiProjectFormValidationValues
} from '.'

interface EditMultiProjectSlideOverProps extends SlideOverOnCloseProps {
  project?: ShortProjectGroupReadModel;
  readOnly: boolean;
  thumbnail?: string;
}

type NestedModal = 'None' | 'Edit';

export const EditMultiProjectSlideOver = ({ onClose, project, readOnly, thumbnail }: EditMultiProjectSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);
  const [nestedModal, setNestedModal] = useState<NestedModal>('None');
  const [postProjectMetadata, { isLoading }] = useApiPatchProjectGroupMutation();

  const handleCloseNestedModal = () => {
    setNestedModal('None');
  };

  const handleSubmit = async (data: EditMultiProjectFormValidationValues) => {
    if (project?.id) {
      try {
        await safeMutation(
          postProjectMetadata,
          {
            body: {
              id: data.id,
              name: data.name,
              description: data.description
            },
          },
          isLoading
        );
        onClose(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const defaultFormValues = {
    id: project?.id ?? '',
    name: project?.name ?? '',
    description: project?.description ?? ''
  };

  return (
    <>
      <SlideOver.Header
        title={project?.name ?? ''}
        subTitle={t('app.editProjectTitle')}
        onClose={onClose}
        backgroundClassName="bg-gray-900"
      />

      <Form<EditMultiProjectFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={EditMultiProjectFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
      >
        <SlideOver.Content
          onKeyEnter={() => {
            submitRef.current?.click()
          }}
        >
          {isLoading && <LoadingIndicator text={t('app.savingProjectMedatata') ?? ''} />}
          <div className="m-8 bg-white">
            <div className="relative group overflow-hidden">
              <div
                style={{ backgroundImage: `url(${thumbnail})` }}
                className="aspect-video bg-cover bg-center rounded-none w-full h-52 bg-gray-500 transition-transform duration-300 transform group-hover:scale-105"
              />
              <div className="absolute top-0 bg-white rounded-full h-12 w-12 flex items-center justify-center mt-4 ml-4">
                <SkyscrapersDottedIcon className="h-7" />
              </div>
              <div className="absolute inset-0 bg-black bg-opacity-75 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                <Button variant="primary" onClick={() => setNestedModal('Edit')}>
                  {t('app.editUploadImage')}
                </Button>
              </div>
            </div>

            <div className="divide-gray-100 divide-y">
              <FormField name="name">
                {(control) => (
                  <TextInput
                    label={t('app.editProjectName')}
                    icon={<TagIcon className="h-6 w-6" />}
                    {...control}
                    disabled={readOnly}
                  />
                )}
              </FormField>
              <FormField name="description">
                {(control) => (
                  <TextInput
                    label={t('app.editProjectDescription')}
                    icon={<TagIcon className="h-6 w-6" />}
                    {...control}
                    disabled={readOnly}
                  />
                )}
              </FormField>
            </div>
          </div>
        </SlideOver.Content>
        <SlideOver.Controls>
          <Button onClick={() => onClose(false)} className="mr-2" variant="secondary">
            {t('common.cancel')}
          </Button>
          {!readOnly && (
            <Button variant="primary" formSubmit={true} innerRef={submitRef}>
              {t('common.save')}
            </Button>
          )}
        </SlideOver.Controls>
      </Form>

      {project && project.id && (
        <Modal isOpen={nestedModal === 'Edit'} onClose={handleCloseNestedModal} zIndex="z-50">
          <ProjectImageUploadModal
            projectId={project?.id}
            onClose={handleCloseNestedModal}
            group
          />
        </Modal>
      )}
    </>
  );
};
