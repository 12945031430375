import { useEffect, useState } from 'react';
import { PermissionGroupDefinition, ProbisPermissionState, ProbisPermissionStatus, getNewState } from './PermissionUtilities';
import { CheckBox } from '@client/shared/toolkit';
import classNames from 'classnames';

interface PermissionGroupProps {
  permissionGroup: PermissionGroupDefinition,
  permissionStatuses: ProbisPermissionStatus[] | undefined;
  allowEditing: boolean;
  changeState: (newStatus: ProbisPermissionStatus[]) => void;
}

export const PermissionGroupRow = ({ allowEditing, changeState, permissionStatuses, permissionGroup }: PermissionGroupProps) => {

  const [currentReadStatus, setCurrentReadStatus] = useState<ProbisPermissionStatus | undefined>();
  const [currentWriteStatus, setCurrentWriteStatus] = useState<ProbisPermissionStatus | undefined>();
  const [currentDeleteStatus, setCurrentDeleteStatus] = useState<ProbisPermissionStatus | undefined>();
  const [currentExecuteStatus, setCurrentExecuteStatus] = useState<ProbisPermissionStatus | undefined>();

  useEffect(() => {
    setCurrentReadStatus(permissionStatuses?.find(x => x.permission === permissionGroup.readPermission));
    setCurrentWriteStatus(permissionStatuses?.find(x => x.permission === permissionGroup.writePermission));
    setCurrentDeleteStatus(permissionStatuses?.find(x => x.permission === permissionGroup.deletePermission));
    setCurrentExecuteStatus(permissionStatuses?.find(x => x.permission === permissionGroup.executePermission));
  }, [permissionStatuses, permissionGroup]);

  const handleReadStateChange = () => {
    if (!allowEditing || !currentReadStatus){
      return;
    }

    const newPermissionStatus = getNewPermissionStatus(currentReadStatus);
    changeState([newPermissionStatus]);
    setCurrentReadStatus(newPermissionStatus);
  }

  const handleWriteStateChange = () => {
    if (!allowEditing || !currentWriteStatus){
      return;
    }

    const newPermissionStatus = getNewPermissionStatus(currentWriteStatus);
    changeState([newPermissionStatus]);
    setCurrentWriteStatus(newPermissionStatus);
  }

  const handleDeleteStateChange = () => {
    if (!allowEditing || !currentDeleteStatus){
      return;
    }

    const newPermissionStatus = getNewPermissionStatus(currentDeleteStatus);
    changeState([newPermissionStatus]);
    setCurrentDeleteStatus(newPermissionStatus);
  }

  const handleExecuteStateChange = () => {
    if (!allowEditing || !currentExecuteStatus){
      return;
    }

    const newPermissionStatus = getNewPermissionStatus(currentExecuteStatus);
    changeState([newPermissionStatus]);
    setCurrentExecuteStatus(newPermissionStatus);
  }  

  const getNewPermissionStatus = (permissionStatus : ProbisPermissionStatus) : ProbisPermissionStatus => {
    return {
      permission: permissionStatus.permission,
      state: getNewState(permissionStatus.state),
    };
  }

  const isChecked = (state: ProbisPermissionState): boolean =>{
    switch (state){
      case 'Granted':
        return true;
      case 'New':
        return true;
      case 'Deleted':
        return false;
      case 'Unset':
        return false;      
    }
  }

  const getBackgroundColor = (state: ProbisPermissionState): string => {
    switch (state) {
      case 'Granted':
        return '';
      case 'New':
        return 'bg-green-600';
      case 'Deleted':
        return 'bg-red-600';
      case 'Unset':
        return '';
    }
  };  

  return (
    <div className='flex flex-row items-center py-4 border-b border-gray-100 pr-1'>
      <div className='w-8/12 ml-8 flex-wrap'>
        {permissionGroup.group}
      </div>
      <div className='flex justify-center w-1/12 pr-3'>
        { currentReadStatus && 
          <div className={classNames('flex items-center justify-center w-6 h-6', getBackgroundColor(currentReadStatus.state))}>
            <CheckBox 
              checked={isChecked(currentReadStatus.state)}
              onChange={handleReadStateChange}/>
          </div>
        }
      </div>
      <div className='flex justify-center w-1/12 pr-2'>
        { currentWriteStatus && 
          <div className={classNames('flex items-center justify-center w-6 h-6', getBackgroundColor(currentWriteStatus.state))}>
            <CheckBox 
              checked={isChecked(currentWriteStatus.state)}
              onChange={handleWriteStateChange}/>
          </div>
        }
      </div>
      <div className='flex justify-center w-1/12'>
        { currentDeleteStatus && 
          <div className={classNames('flex items-center justify-center w-6 h-6', getBackgroundColor(currentDeleteStatus.state))}>
            <CheckBox 
              checked={isChecked(currentDeleteStatus.state)}
              onChange={handleDeleteStateChange}/>
          </div>
        }
      </div>
      <div className='flex justify-center w-1/12'>
        { currentExecuteStatus && 
          <div className={classNames('flex items-center justify-center w-6 h-6', getBackgroundColor(currentExecuteStatus.state))}>                  
            <CheckBox 
              checked={isChecked(currentExecuteStatus.state)}
              onChange={handleExecuteStateChange}/>
          </div>
        }        
      </div>
    </div>
  );
};
