import { InvoiceReportReadModel } from '@client/shared/api';
import {
  Button,
  SlideOver,
  SlideOverTabOptions,
  SlideOverWithTabs
} from '@client/shared/toolkit';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceCoverSheetPdf } from '@client/project/shared';

interface InvoiceCoverSheetViewSlideOverProps {
  onClose: () => void;
  icsData: InvoiceReportReadModel;
}
export const InvoiceCoverSheetViewSlideOver = (props: InvoiceCoverSheetViewSlideOverProps) => {
  const {
    onClose,
    icsData
  } = props;
  const { t } = useTranslation();
  // const [currentTab, setCurrentTab] = useState(0);
  const tabOptions: SlideOverTabOptions[] = useMemo(() => {
    return [{
      header: t('ics.invoiceCoverSheet'),
      name: 'ics',
      panel: (
        <InvoiceCoverSheetPdf invoiceId={icsData.id} invoiceCoverSheetData={icsData} />
      ),
    }]
  }, [t, icsData]);

  return (
    <SlideOverWithTabs
      title={
        <>
          {t('projectControl.invoice')} {icsData?.code}
        </>
      }
      subtitle={
        <>
          {icsData?.externalCode && <span className="font-bold">{icsData?.externalCode}&nbsp;</span>}
          {typeof icsData?.externalCode !== 'undefined' && icsData?.externalCode && icsData?.sequentialNumber ? '• ' : ''}
          {icsData?.sequentialNumber ? `${t('projectContract.numberLabel')} ${icsData?.sequentialNumber}` : ''}
        </>
      }
      onClose={onClose}
      tabOptions={tabOptions}
      tabsClassName="h-full"
      // onChange={(tabIndex) => setCurrentTab(tabIndex)}
    >
      <SlideOver.Controls>
        <Button variant="secondary" onClick={() => onClose()}>
          {t('common.close')}
        </Button>
      </SlideOver.Controls>
    </SlideOverWithTabs>
  );
}
