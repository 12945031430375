import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiDeleteTenantUserDefinedFieldDefinitionApiArg,
  ApiDeleteTenantUserDefinedFieldDefinitionApiResponse,
  ApiGetUserDefinedFieldsDefinitionByElementApiArg,
  ApiGetUserDefinedFieldsDefinitionByElementApiResponse,
  ApiPostCreateTenantUserDefinedFieldDefinitionApiArg,
  ApiPostCreateTenantUserDefinedFieldDefinitionApiResponse,
  ApiPostUpdateTenantUserDefinedFieldDefinitionApiArg,
  ApiPostUpdateTenantUserDefinedFieldDefinitionApiResponse,
  ApiProjectGetUserDefinedFieldsDefinitionByElementApiArg,
  ApiProjectGetUserDefinedFieldsDefinitionByElementApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiUDFEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetUserDefinedFieldsDefinition: {
    providesTags: [{ type: ApiTagTypes.UserDefinedFieldDefinition, id: 'LIST' }],
  },
  apiProjectGetUserDefinedFieldsDefinition: {
    providesTags: [{ type: ApiTagTypes.UserDefinedFieldDefinition, id: 'LIST' }],
  },
  apiGetUserDefinedFieldsDefinitionByElement: {
    providesTags: (
      _result: ApiGetUserDefinedFieldsDefinitionByElementApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetUserDefinedFieldsDefinitionByElementApiArg,
    ) => [
      { type: ApiTagTypes.UserDefinedFields, id: args.elementId },
      { type: ApiTagTypes.UserDefinedFields, id: args.elementType },
      ApiTagTypes.UserDefinedFields,
    ],
  },
  apiProjectGetUserDefinedFieldsDefinitionByElement: {
    providesTags: (
      _result: ApiProjectGetUserDefinedFieldsDefinitionByElementApiResponse,
      _error: FetchBaseQueryError,
      args: ApiProjectGetUserDefinedFieldsDefinitionByElementApiArg,
    ) => [
      { type: ApiTagTypes.UserDefinedFields, id: args.elementId },
      { type: ApiTagTypes.UserDefinedFields, id: args.elementType },
      ApiTagTypes.UserDefinedFields,
    ],
  },
  /*------------------ PROVIDES TAGS --------------------*/
  apiPostCreateTenantUserDefinedFieldDefinition: {
    invalidatesTags: (
      _result: ApiPostCreateTenantUserDefinedFieldDefinitionApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateTenantUserDefinedFieldDefinitionApiArg,
    ) => {
      if (error == null) {
        const invalidate: { type: ApiTagTypes; id?: string }[] = [
          { type: ApiTagTypes.UserDefinedFieldDefinition, id: 'LIST' },
          { type: ApiTagTypes.UserDefinedFields, id: args.body.elementType },
        ];
        // for mandatory udfs the udf is shown in the overview (e.g. taxonomy dashboard)
        // so we need to invalidate the all elements of the used elementType
        if (args.body.isRequired) {
          switch (args.body.elementType) {
            case 'Cost':
              invalidate.push({ type: ApiTagTypes.CostElement });
              break;
            case 'Project':
              invalidate.push({ type: ApiTagTypes.Project });
              break;
            case 'Taxonomy':
              invalidate.push({ type: ApiTagTypes.Taxonomy });
              break;
            case 'ContractTitle':
            case 'Contract':
              invalidate.push({ type: ApiTagTypes.Contract });
              break;
            case 'Invoice':
              invalidate.push({ type: ApiTagTypes.Invoice });
              break;
            default:
              break;
          }
        }
        return invalidate;
      }
      return [];
    },
  },
  apiPostUpdateTenantUserDefinedFieldDefinition: {
    invalidatesTags: (
      _result: ApiPostUpdateTenantUserDefinedFieldDefinitionApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateTenantUserDefinedFieldDefinitionApiArg,
    ) => {
      if (error == null) {
        const invalidate: { type: ApiTagTypes; id?: string }[] = [
          { type: ApiTagTypes.UserDefinedFieldDefinition, id: 'LIST' },
          { type: ApiTagTypes.UserDefinedFields, id: args.body.elementType },
        ];
        // for update, we don't know if element already uses udf in overview (e.g. taxonomy dashboard)
        // so we need to invalidate the all elements of the used elementType
        switch (args.body.elementType) {
          case 'Cost':
            invalidate.push({ type: ApiTagTypes.CostElement });
            break;
          case 'Project':
            invalidate.push({ type: ApiTagTypes.Project });
            break;
          case 'Taxonomy':
            invalidate.push({ type: ApiTagTypes.Taxonomy });
            break;
          case 'ContractTitle':
          case 'Contract':
            invalidate.push({ type: ApiTagTypes.Contract });
            break;
          case 'Invoice':
            invalidate.push({ type: ApiTagTypes.Invoice });
            break;
          default:
            break;
        }
        return invalidate;
      }
      return [];
    },
  },
  apiDeleteTenantUserDefinedFieldDefinition: {
    invalidatesTags: (
      _result: ApiDeleteTenantUserDefinedFieldDefinitionApiResponse,
      error: FetchBaseQueryError,
      _args: ApiDeleteTenantUserDefinedFieldDefinitionApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.UserDefinedFieldDefinition, id: 'LIST' },
            ApiTagTypes.UserDefinedFields,
            // on delete we don't know which type (not in args TODO)
            // invalidate all
            { type: ApiTagTypes.Taxonomy },
            { type: ApiTagTypes.Project },
            { type: ApiTagTypes.CostElement },
            { type: ApiTagTypes.Contract },
            { type: ApiTagTypes.ContractInvoice },
            { type: ApiTagTypes.Invoice },
            ApiTagTypes.Invoices,
          ]
        : [],
  },
};
