import React, { useMemo } from 'react';
import { CheckIcon, TrashIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import {
  BillCustomIcon,
  ContextMenu,
  ContextMenuItem,
  Highlighted,
  PencilIcon,
  SettingsListItem,
  SettingsListItemTitle
} from '@client/shared/toolkit';
import {
  InvoiceCalculationSchemeReadModel,
} from '@client/shared/api';
import { getInvoiceCoverSheetTypeLabel } from '../../utils';
import { Square2StackIcon } from '@heroicons/react/24/outline';

type InvoiceCoverSheetsListItemProps = {
  searchText?: string
  handleAction: (action: 'delete' | 'edit' | 'copy') => void
  sheet: InvoiceCalculationSchemeReadModel
}

export const InvoiceCoverSheetsListItem = (props: InvoiceCoverSheetsListItemProps) => {
  const {
    searchText,
    handleAction,
    sheet
  } = props
  const { t } = useTranslation()
  const contextMenuItems: ContextMenuItem[] = useMemo(() => {
    return [
      {
        label: t('common.edit'),
        subtitle: t('common.editElement'),
        icon: <PencilIcon />,
        onClick: () => handleAction('edit')
      },
      {
        label: t('common.copy'),
        subtitle: t('ics.copyInvoiceCoverSheet'),
        icon: <Square2StackIcon />,
        onClick: () => handleAction('copy')
      },
      {
        label: t('common.delete'),
        subtitle: t('common.deleteElement'),
        icon: <TrashIcon />,
        onClick: () => handleAction('delete')
      },
    ]
  }, [t, handleAction])
  return (
    <SettingsListItem
     contextMenu={<ContextMenu items={contextMenuItems} />}
     onClick={() => handleAction('edit')}
    >
      <div className='w-6/12'>
        <SettingsListItemTitle
          icon={ <BillCustomIcon className="w-full" />}
          title={searchText ? <Highlighted text={sheet.name} highlight={searchText} /> : sheet.name}
          subtitle={getInvoiceCoverSheetTypeLabel(sheet.type)}
        />
      </div>
      <div className='w-6/12'>
        <SettingsListItemTitle
          title={sheet.isDefault ? <CheckIcon className='ml-3 h-6 w-6 text-green-500'/> : null}
        />
      </div>
    </SettingsListItem>
  )
}
