import {
  BedDottedIcon,
  FloorPlanDottedIcon,
  InteriorDottedIcon,
  PageSizeDottedIcon, ParkingDottedIcon,
  SkyscrapersDottedIcon,
  TaxonomyBuildingComplexIcon,
  TaxonomyBuildingSingleIcon,
  TaxonomyProjectIcon,
  TaxonomyQuarterIcon,
  TaxonomyUnitIcon,
  TaxonomyWingIcon,
  TaxonomyAreaIcon,
} from '@client/shared/toolkit';
import { TaxonomyType } from '@client/shared/api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const taxonomyIcons: { [key: string]: any } = {
  Project: TaxonomyProjectIcon,
  Area: TaxonomyAreaIcon,
  Quarter: TaxonomyQuarterIcon,
  BuildingComplex: TaxonomyBuildingComplexIcon,
  Building: TaxonomyBuildingSingleIcon,
  Wing: TaxonomyWingIcon,
  Floor: FloorPlanDottedIcon,
  Unit: TaxonomyUnitIcon,
  Sector: TaxonomyAreaIcon,
  Room: InteriorDottedIcon,
  Zone: PageSizeDottedIcon,
  Parking: ParkingDottedIcon,
  Bed: BedDottedIcon,
  Other: SkyscrapersDottedIcon
}
export const getTaxonomyIcon = (type: TaxonomyType | undefined) => {
  if (!type) return;
  const DynamicComponent = taxonomyIcons[type as string]
  return DynamicComponent ?? SkyscrapersDottedIcon
}
