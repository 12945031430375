import { PropsWithChildren, ReactNode, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';

export interface DocumentViewerFileDataSetProps extends PropsWithChildren {
  className?: string
  label?: string | ReactNode
  title?: string | ReactNode
  subtitle?: string | ReactNode
  text?: string | ReactNode
  toggledContent?: string | ReactNode
  toggledContentClassName?: string
  isOpen?: boolean
}

export const DocumentViewerFileDataSet = (props: DocumentViewerFileDataSetProps) => {
  const {
    className,
    children,
    label,
    title,
    subtitle,
    text,
    isOpen = false,
    toggledContent,
    toggledContentClassName
  } = props

  const buttonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (buttonRef.current) {
      if ((isOpen && buttonRef.current.dataset.headlessuiState !== 'open')
        || (!isOpen && buttonRef.current.dataset.headlessuiState === 'open')) {
        buttonRef.current.click()
      }
    }
  }, [isOpen]);

  const content = useMemo(() => {
    return (
      <>
        {label && (
          <span className="text-[11px] text-gray-500">{label}</span>
        )}
        {title && (
          <span className="text-lg font-bold">{title}</span>
        )}
        {subtitle && (
          <span className="text-[15px] inline-flex items-center">{subtitle}</span>
        )}
        {text && (
          <span className="text-xs text-gray-500">{text}</span>
        )}
        {children}
      </>
    )
  }, [label, title, subtitle, text, children])

  if (toggledContent) {
    return (
      <Disclosure as="div" defaultOpen={isOpen}>
        <div
          className={cn('flex flex-col justify-center', className)}
        >
          <DisclosureButton
            as="div"
            className={cn('flex flex-col justify-center cursor-pointer bg-white hover:bg-gray-100 transition-colors duration-300', className)}
            ref={buttonRef}
          >
            {content}
          </DisclosureButton>
          <DisclosurePanel className={cn('bg-gray-100 -mx-6 px-6 py-4 mt-4 shadow-inner', toggledContentClassName)}>
            {toggledContent}
          </DisclosurePanel>
        </div>
      </Disclosure>
    )
  }

  return (
    <div className={cn('flex flex-col justify-center', className)}>
      {content}
    </div>
  )
}
