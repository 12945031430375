import {
  AddToClipboardIcon,
  CheckmarkIcon,
  ClusterListItemContent,
  ClusterListTaskListItemContent,
  List, ListItem,
  ListItemProps, ListSortHeaderItem,
  LoadingIndicator,
} from '@client/shared/toolkit';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RequirementShortReadModel,
  useApiGetClusterItemsQuery
} from '@client/shared/api';
import { useLoadedPortfolioId } from '@client/portfolio/store';
import { formatDateOnly, SortHeaderType } from '@client/shared/utilities';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import {
  checkIsReporter,
  getClusterItemIdsForUser,
  getOrgaUnitNamesForUser,
  getRequirementStatusBadge,
  isAssetManager
} from '@client/portfolio/shared';
import { useNavigate } from 'react-router-dom';
import { sortByProperty } from '@client/shared/utilities';
import { useUi } from '@client/shared/store';

interface RequirementListItem extends RequirementShortReadModel {
  clusterItemId: string;
}

export const PortfolioTasksContainer = () => {
  const { t } = useTranslation();
  const loadedPortfolioId = useLoadedPortfolioId();
  const navigate = useNavigate();
  const ui = useUi();

  const { data, isFetching } = useApiGetClusterItemsQuery(
    {
      portfolioId: loadedPortfolioId ?? '',
    },
    {
      skip: !loadedPortfolioId,
    },
  );

  const defaultSortHeader: SortHeaderType[] = useMemo(() => {
    return [
      {
        asc: null,
        label: t('projectTasks.sortHeaderTask'),
        type: 'string',
        value: 'name',
      },
      {
        asc: null,
        label: t('projectTasks.sortHeaderAssignedTo'),
        type: 'custom',
        value: 'assignee',
      },
      {
        asc: null,
        label: t('projectTasks.sortHeaderStatus'),
        type: 'string',
        value: 'state',
      },
      {
        asc: null,
        label: t('projectTasks.sortHeaderDate'),
        type: 'date',
        value: 'createdAt',
      },
    ];
  }, [t]);
  const [sortHeader, setSortHeader] = useState<SortHeaderType[]>(defaultSortHeader);

  const getAssignedAssetManager = useCallback((requirement: RequirementListItem) => {
    if (requirement.state === 'Created') {
      return {
        name: requirement.reportedByFullName,
        id: requirement.reportedById,
        email: requirement.reportedByEmail,
        groupName: getOrgaUnitNamesForUser(requirement.reportedById, requirement.clusterItemId, requirement.reportedById).join(', '),
        hasAvatar: requirement.reportedByHasAvatar
      };
    }
    return {
      name: ui.appUser.fullName ?? '',
      id: ui.appUser.userId ?? '',
      email: ui.appUser.email ?? '',
      groupName: t('portfolio.tasks.assetManager'),
      hasAvatar: ui.appUser.hasAvatar ?? false
    };
  }, [ui.appUser.fullName, ui.appUser.userId, ui.appUser.email, ui.appUser.hasAvatar, t]);

  const openRequirement = useCallback(
    (clusterItemId: string, requirementId: string) => {
      if (loadedPortfolioId) {
        navigate(
          ROUTES_CONFIG.PORTFOLIO_PROJECTING_REQUIREMENT_VIEW.path
            .replace(':id', loadedPortfolioId)
            .replace(':clusterItemId', clusterItemId)
            .replace(':requirementId', requirementId),
        );
      }
    },
    [navigate, loadedPortfolioId],
  );

  const taskItems: ListItemProps[] = useMemo(() => {
    const listItems: ListItemProps[] = [];
    if (data?.clusterItems && ui.appUser.userId) {
      const userClusterItemIds = getClusterItemIdsForUser(ui.appUser.userId);

      let requirements = data.clusterItems.reduce((requirements, clusterItem) => {
        if (userClusterItemIds.includes(clusterItem.id)) {
          const reqs = clusterItem.requirements.map((req) => {
            return { ...req, clusterItemId: clusterItem.id } as RequirementListItem;
          });
          return [
            ...requirements,
            ...reqs.filter(
              (req) =>
                // only the requirements the asset manager is allowed to see
                (isAssetManager(ui.appUser.userId) && req.state === 'Submitted') ||
                // only the requirements the requirement reporter is allowed to see
                (checkIsReporter(req.reportedById, req.reportedByDeputyId, ui.appUser.userId) && req.state === 'Rejected')
            ),
          ];
        }
        return requirements;
      }, [] as RequirementListItem[]);

      const sortBy = sortHeader.find((item) => item.asc !== null);
      if (sortBy) {
        if (sortBy.type === 'custom') {
          if (sortBy.value === 'assignee') {
            requirements = requirements.sort((a, b) => {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            });
          } else {
            requirements = requirements.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
          }
        } else {
          requirements = sortByProperty(requirements, sortBy) as RequirementListItem[];
        }
      } else {
        requirements = requirements.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      }

      requirements.forEach((requirement) => {
        listItems.push({
          borderColor: 'bg-secondary',
          onClick: () => openRequirement(requirement.clusterItemId, requirement.id),
          children: (
            <ClusterListItemContent
              icon={<CheckmarkIcon />}
              title={requirement.name}
              subtitle={requirement.code}
              theme="requirement"
            >
              <ClusterListTaskListItemContent
                date={requirement.createdAt ? formatDateOnly(new Date(requirement.createdAt)) : undefined}
                status={getRequirementStatusBadge(requirement.state)}
                assignee={getAssignedAssetManager(requirement)}
              />
            </ClusterListItemContent>
          ),
        });
      });
    }
    return listItems;
  }, [data?.clusterItems, sortHeader, openRequirement, ui.appUser.userId, getAssignedAssetManager]);

  const onHandleSort = useCallback(
    (index: number) => {
      const currentSortValues = [...sortHeader];
      const update = currentSortValues[index];
      update.asc = update.asc === null ? true : !update.asc;
      currentSortValues.forEach((val, i) => {
        if (i !== index) {
          val.asc = null;
        }
      });
      currentSortValues[index] = update;
      setSortHeader(currentSortValues);
    },
    [sortHeader],
  );

  return (
    <>
      {isFetching && (
        <LoadingIndicator text={t('portfolio.projecting.fetchingRequirementsLoadingIndicator')} mode="overlay" />
      )}
      <List
        icon={<AddToClipboardIcon className="w-5 h-5" />}
        listTitle={t('portfolio.tasks.myTasks')}
        items={taskItems}
        emptyMessage={t('portfolio.tasks.noTasks')}
        collapsible={false}
        showPagination={true}
        amountPerPage={15}
        sortHeader={
          taskItems.length > 0 ? (
            <PortfolioTasksSortHeader onHandleSort={onHandleSort} sortHeader={sortHeader} />
          ) : null
        }
      />
    </>
  );
};

interface PortfolioTasksSortHeaderProps {
  onHandleSort: (index: number) => void
  sortHeader: SortHeaderType[]
}
export const PortfolioTasksSortHeader = (props: PortfolioTasksSortHeaderProps) => {
  const { onHandleSort, sortHeader } = props;
  const { t } = useTranslation()
  return (
    <ListItem
      className="hidden sm:flex"
      borderColor="bg-transparent"
      bgColor=""
      shadow={false}
      padding={false}
      as="div"
    >
      <div className="flex w-full gap-3.5 pt-2 text-[13px] text-gray-500 justify-between">
        <div className="flex items-center gap-4 overflow-hidden w-full lg:w-[300px]">
          <div className="w-8" />
          <div>
            <ListSortHeaderItem
              label={sortHeader[0].label}
              asc={sortHeader[0].asc}
              onClick={() => onHandleSort(0)}
              alignRight={false}
            />
          </div>
        </div>
        <div className="flex-1 flex lg:pl-3.5 gap-2 justify-end items-center">
          <div className="w-1/3 text-gray-500">
            {t('projectTasks.sortHeaderAssignedTo')}
          </div>
          <div className="w-[90px] flex-none flex items-center justify-end">
            <ListSortHeaderItem
              label={sortHeader[2].label}
              asc={sortHeader[2].asc}
              onClick={() => onHandleSort(2)}
              alignRight
            />
          </div>
          <div className="w-[130px] flex-none hidden lg:block">
            <ListSortHeaderItem
              label={sortHeader[3].label}
              asc={sortHeader[3].asc}
              onClick={() => onHandleSort(3)}
              alignRight
            />
          </div>
          <div className="flex-none w-6" />
        </div>
      </div>
    </ListItem>
  );
}
