import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Form,
  FormDottedIcon,
  FormField, FormRefHandle, LoadingIndicator,
  Modal,
  TaskCompletedDottedIcon,
  TextInput,
  Wizard,
  WizardSlideContent,
  WizardSlideHeader,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useNavigate } from 'react-router-dom';
import {
  RequirementCreateFormValidationValues,
  RequirementCreateFormValidationSchema,
} from './RequirementFormValidationSchemas';
import { safeMutation } from '@client/shared/utilities';
import {
  useApiGetPortfolioNextRequirementCodeQuery,
  useApiPostCreateClusterItemRequirementMutation
} from '@client/shared/api';
import { useLoadedPortfolioId } from '@client/portfolio/store';
import { ClusterItemSelect } from './ClusterItemSelect';

interface RequirementCreateWizardProps {
  onClose: () => void;
}

export const RequirementCreateWizard = (props: RequirementCreateWizardProps) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loadedPortfolioId = useLoadedPortfolioId();

  const [createRequirement, { isLoading }] = useApiPostCreateClusterItemRequirementMutation();

  const { data: nextCodeResponse, isFetching } = useApiGetPortfolioNextRequirementCodeQuery(
    {
      portfolioId: loadedPortfolioId ?? '',
    },
    {
      skip: !loadedPortfolioId,
    },
  );

  const formRef = useRef<FormRefHandle<RequirementCreateFormValidationValues>>(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [createdRequirementId, setCreatedRequirementId] = useState<string | undefined | null>(null);
  const [clusterItemId, setClusterItemId] = useState('');

  useEffect(() => {
    if (nextCodeResponse?.code && formRef.current) {
      formRef.current.setValue('code', nextCodeResponse.code);
    }
  }, [nextCodeResponse]);

  const handleSubmit = async (data: RequirementCreateFormValidationValues) => {
    if (loadedPortfolioId) {
      setClusterItemId(data.clusterItemId);
      try {
        const response = await safeMutation(
          createRequirement,
          {
            clusterItemId: data.clusterItemId,
            portfolioId: loadedPortfolioId,
            body: {
              name: data.name,
              code: data.code,
            },
          },
          isLoading,
        );
        setCreatedRequirementId(response?.requirementId ?? '');
        setCurrentStep(currentStep + 1);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const defaultFormValues = {
    name: '',
    code: '',
    clusterItemId: '',
  };

  return (
    <Form<RequirementCreateFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={RequirementCreateFormValidationSchema}
      defaultValues={defaultFormValues}
      className="h-full"
      ref={formRef}
    >
      <Wizard currentStep={currentStep} onSlideChange={(step) => setCurrentStep(step)}>
        <Wizard.Slides>
          <Wizard.Slide>
            <WizardSlideHeader icon={<FormDottedIcon />} title={t('portfolio.projecting.createWizard.title')} />
            <WizardSlideContent>
              <div className="flex gap-0.5">
                <FormField name="name">
                  {(control) => (
                    <TextInput
                      label={t('portfolio.projecting.requirementEdit.title')}
                      {...control}
                      className="flex-1"
                      helperTextClassName="bg-transparent"
                    />
                  )}
                </FormField>
                <div className="relative">
                  {isFetching && <LoadingIndicator mode="inline" className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10" svgClassName="w-8" />}
                  <FormField name="code">
                    {(control) => (
                      <TextInput
                        label={t('portfolio.projecting.requirementEdit.number')}
                        {...control}
                        className="w-[150px] flex-none"
                        helperTextClassName="bg-transparent"
                        disabled
                      />
                    )}
                  </FormField>
                </div>
              </div>
              <ClusterItemSelect />
            </WizardSlideContent>
          </Wizard.Slide>
          <Wizard.Slide>
            <WizardSlideContent>
              <WizardSlideHeader
                icon={<TaskCompletedDottedIcon />}
                title={t('portfolio.projecting.createWizard.successfullyCreated')}
                subTitle={t('portfolio.projecting.createWizard.successfullyCreatedSubTitle')}
                fullHeight
              />
            </WizardSlideContent>
          </Wizard.Slide>
        </Wizard.Slides>
        <Wizard.Navigation>
          {({ isFirst, isLast }) => (
            <Modal.Controls>
              {(() => {
                if (isFirst) {
                  return (
                    <>
                      <Button variant="text" onClick={onClose}>
                        {t('common.cancel')}
                      </Button>
                      <Button variant="primary" formSubmit disabled={isFetching}>
                        {t('common.create')}
                      </Button>
                    </>
                  );
                } else if (isLast) {
                  return (
                    <>
                      <Button variant="text" onClick={onClose}>
                        {t('common.close')}
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          onClose();
                          if (createdRequirementId && clusterItemId && loadedPortfolioId) {
                            navigate(
                              ROUTES_CONFIG.PORTFOLIO_PROJECTING_REQUIREMENT_VIEW.path
                                .replace(':id', loadedPortfolioId)
                                .replace(':clusterItemId', clusterItemId)
                                .replace(':requirementId', createdRequirementId),
                            );
                          }
                        }}
                      >
                        {t('portfolio.projecting.createWizard.goToRequirement')}
                      </Button>
                    </>
                  );
                }
              })()}
            </Modal.Controls>
          )}
        </Wizard.Navigation>
      </Wizard>
    </Form>
  );
};
