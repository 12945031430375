import { useTranslation } from 'react-i18next';
import React, { useMemo, useRef, useState } from 'react';
import {
  Button,
  ComboSelect,
  DatePicker, ErrorIcon,
  Form,
  FormField, FormWatch,
  Modal, MultiSelect,
  TaskCompletedDottedIcon,
  TextInput,
  Wizard,
  WizardSlideContent,
  WizardSlideHeader,
} from '@client/shared/toolkit';
import {
  RequirementReleaseForReviewValidationValues,
  RequirementReleaseForReviewFormValidationSchema,
  ClusterItemSelect
} from '.';
import { RequirementReadModel, useApiPostUpdateClusterItemRequirementStatusMutation } from '@client/shared/api';
import { useLoadedPortfolioId } from '@client/portfolio/store';
import { formatDateOnly, safeMutation } from '@client/shared/utilities';
import {
  EDITOR_DEPUTY_OPTIONS,
  EDITOR_OPTIONS
} from '@client/portfolio/shared';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useNavigate } from 'react-router-dom';
import { addBusinessDays } from 'date-fns';

export const RequirementReleaseForReviewWizard = ({ onClose, requirement, setClusterItemChanged }: { onClose: () => void, requirement: RequirementReadModel, setClusterItemChanged: (changed: boolean) => void }) => {
  const { t } = useTranslation();
  const loadedPortfolioId = useLoadedPortfolioId();
  const navigate = useNavigate();

  const formRef = useRef<HTMLFormElement>(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [updateState, { isLoading }] = useApiPostUpdateClusterItemRequirementStatusMutation();

  const [editorId, setEditorId] = useState<string | null>(null);
  const [editorDeputyIds, setEditorDeputyIds] = useState<string[]>([]);

  const handleSubmit = async (data: RequirementReleaseForReviewValidationValues) => {
    if (loadedPortfolioId) {
      try {
        await safeMutation(
          updateState,
          {
            portfolioId: loadedPortfolioId,
            clusterItemId: requirement.clusterItem.id,
            requirementId: requirement.id,
            body: {
              name: data.name,
              clusterItemId: data.clusterItemId,
              state: 'Released',
              reportedById: data.reportedById,
              reportedByDeputyId: data.reportedByDeputyId,
              editorId: data.editorId,
              editorDeputyIds: data.editorDeputyIds,
              deadline: formatDateOnly(new Date(data.deadline)),
              comment: data.clusterChanged ? data.reason : data.comment
            },
          },
          isLoading,
        );
        if (data.clusterChanged) {
          navigate(
            ROUTES_CONFIG.PORTFOLIO_PROJECTING_REQUIREMENT_VIEW.path
              .replace(':id', loadedPortfolioId ?? '')
              .replace(':clusterItemId', data.clusterItemId)
              .replace(':requirementId', requirement.id),
          );
        }
        setCurrentStep(currentStep + 1);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const editorOptions = useMemo(() => {
    return EDITOR_OPTIONS.filter((option) => !editorDeputyIds.includes(option.value));
  }, [editorDeputyIds])

  const editorDeputyOptions = useMemo(() => {
    return EDITOR_DEPUTY_OPTIONS.filter((option) => option.value !== editorId);
  }, [editorId])

  const deadlineDefault = addBusinessDays(new Date(), 20);
  const defaultFormValues = {
    name: requirement.name,
    clusterChanged: false,
    code: requirement.code,
    clusterItemId: requirement.clusterItem.id,
    reportedById: requirement.reportedById ?? '',
    reportedByDeputyId: requirement.reportedByDeputyId ?? '',
    editorId: '',
    editorDeputyIds: [],
    deadline: formatDateOnly(deadlineDefault),
    comment: ''
  };

  return (
    <Form<RequirementReleaseForReviewValidationValues>
      onSubmit={handleSubmit}
      validationSchema={RequirementReleaseForReviewFormValidationSchema}
      defaultValues={defaultFormValues}
      className="h-full"
      ref={formRef}
    >
      <FormWatch fieldNames={['clusterChanged']}>
        {({ clusterChanged }) => (
          <Wizard currentStep={currentStep} onSlideChange={(step) => setCurrentStep(step)}>
            <Wizard.Slides>
              <Wizard.Slide centered={false}>
                {clusterChanged && (
                  <div className="w-full relative">
                    <div className="absolute -top-6 left-0 w-full border-b-2 border-red-700 pb-3 flex gap-2 text-red-700 italic text-sm items-center justify-center">
                      <ErrorIcon className="w-6" />
                      {t('portfolio.projecting.releaseForReviewWizard.clusterChangedHint')}
                    </div>
                  </div>
                )}
                <WizardSlideHeader
                  title={t('portfolio.projecting.releaseForReviewWizard.title')}
                />
                <WizardSlideContent>
                  <div className="flex gap-0.5">
                    <FormField name="name">
                      {(control) => (
                        <TextInput
                          label={t('portfolio.projecting.requirementEdit.title')}
                          {...control}
                          className="flex-1"
                          helperTextClassName="bg-transparent"
                          disabled
                        />
                      )}
                    </FormField>
                    <FormField name="code">
                      {(control) => (
                        <TextInput
                          label={t('portfolio.projecting.requirementEdit.number')}
                          {...control}
                          disabled
                          className="w-[150px] flex-none"
                          helperTextClassName="bg-transparent"
                        />
                      )}
                    </FormField>
                  </div>
                  <ClusterItemSelect
                    formRef={formRef}
                    requirement={requirement}
                    onChange={(value) => {
                      formRef.current?.setValue('clusterChanged', value !== defaultFormValues.clusterItemId);
                      setClusterItemChanged(value !== defaultFormValues.clusterItemId);
                    }}
                    clusterChanged={clusterChanged}
                  />
                  <FormField name="editorId">
                    {(control) => (
                      <ComboSelect
                        nullable={false}
                        label={t('portfolio.projecting.submitNotificationWizard.editor')}
                        options={editorOptions}
                        {...control}
                        onChange={(val) => {
                          setEditorId(val);
                          control.onChange(val);
                        }}
                        helperTextClassName="bg-transparent"
                      />
                    )}
                  </FormField>
                  <FormField name="editorDeputyIds">
                    {(control) => (
                      <MultiSelect
                        // nullable={false}
                        label={t('portfolio.projecting.submitNotificationWizard.deputy')}
                        options={editorDeputyOptions}
                        {...control}
                        onChange={(val) => {
                          setEditorDeputyIds(val);
                          control.onChange(val);
                        }}
                      />
                    )}
                  </FormField>
                  <FormField name="deadline">
                    {(control) => (
                      <DatePicker
                        label={t('portfolio.projecting.submitNotificationWizard.deadline')}
                        minDate={new Date()}
                        {...control}
                      />
                    )}
                  </FormField>
                  {clusterChanged ? (
                    <FormField name="reason">
                      {(control) => (
                        <TextInput
                          label={t('portfolio.projecting.submitNotificationWizard.reason')}
                          inputType="textarea"
                          {...control}
                          helperTextClassName="bg-transparent"
                        />
                      )}
                    </FormField>
                  ) : (
                    <FormField name="comment">
                      {(control) => (
                        <TextInput
                          label={t('portfolio.projecting.submitNotificationWizard.comment')}
                          inputType="textarea"
                          {...control}
                          helperTextClassName="bg-transparent"
                        />
                      )}
                    </FormField>
                  )}
                </WizardSlideContent>
              </Wizard.Slide>
              <Wizard.Slide>
                <WizardSlideContent>
                  <WizardSlideHeader
                    icon={<TaskCompletedDottedIcon />}
                    title={t('portfolio.projecting.releaseForReviewWizard.successfullyReleased')}
                    subTitle={
                      clusterChanged
                        ? t('portfolio.projecting.releaseForReviewWizard.successfullyChangedClusterSubtitle')
                        : t('portfolio.projecting.releaseForReviewWizard.successfullyReleasedSubTitle')
                    }
                    fullHeight
                  />
                </WizardSlideContent>
              </Wizard.Slide>
            </Wizard.Slides>
            <Wizard.Navigation>
              {({ canGoNext, isFirst, isLast }) => (
                <Modal.Controls>
                  {(() => {
                    if (isFirst) {
                      return (
                        <>
                          <Button variant="text" onClick={onClose}>
                            {t('common.cancel')}
                          </Button>
                          <Button variant="primary" disabled={!canGoNext} formSubmit>
                            {t('portfolio.projecting.requirementSlideOver.release')}
                          </Button>
                        </>
                      );
                    } else if (isLast) {
                      return (
                        <Button variant="primary" onClick={onClose}>
                          {t('portfolio.projecting.submitNotificationWizard.gotIt')}
                        </Button>
                      );
                    }
                  })()}
                </Modal.Controls>
              )}
            </Wizard.Navigation>
          </Wizard>
        )}
      </FormWatch>
    </Form>
  );
};
