import cn from 'classnames';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Portal } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core/lib/enums';

export interface TooltipProps {
  className?: string;
  label?: React.ReactElement
  children?: React.ReactElement
  tooltipPanelClasses?: string
  placement?: Placement
  enterDelay?: number
  leaveDelay?: number
  show?: boolean
}
export const Tooltip = ({
  className,
  label,
  children,
  tooltipPanelClasses,
  placement = 'left',
  enterDelay = 250,
  leaveDelay = 150,
  show = false
}: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(show)
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        if (forceUpdate) {
          forceUpdate()
        }
      })
    }
    setIsOpen(show)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])
  const triggerRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null)
  const popupRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null)
  const {
    styles,
    attributes,
    forceUpdate
  } = usePopper(triggerRef.current, popupRef.current, {
    placement,
    modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
  })
  const enterTimeout = useRef<NodeJS.Timeout>()
  const leaveTimeout = useRef<NodeJS.Timeout>()
  const handleMouseEnter = useCallback(() => {
    // after toggling year, milestone popup is no longer correct, so force updating the position
    if (forceUpdate) {
      forceUpdate()
    }
    leaveTimeout.current && clearTimeout(leaveTimeout.current)
    enterTimeout.current = setTimeout(() => setIsOpen(true), enterDelay)
  }, [forceUpdate, enterDelay])
  const handleMouseLeave = useCallback(() => {
    enterTimeout.current && clearTimeout(enterTimeout.current)
    leaveTimeout.current = setTimeout(() => setIsOpen(false), leaveDelay)
  }, [leaveDelay])
  return (
    <>
      <div
        ref={triggerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={className}
      >
        {label}
      </div>

      <Portal>
        <div
          ref={popupRef}
          style={styles.popper}
          {...attributes.popper}
          className={
            cn('transition-opacity',
              tooltipPanelClasses ?? 'bg-white p-2 z-20 shadow-lg',
              {
                'opacity-100': isOpen,
                'opacity-0 pointer-events-none': !isOpen
              }
            )
          }
        >
          {children}
        </div>
      </Portal>
    </>
  )
}
