import { LicenseGrantReadModel } from '@client/shared/api';
import { Badge, BadgeGroup, SlideOver } from '@client/shared/toolkit';
import { ArrowPathIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { formatDate, isEmpty, monthDiff } from '@client/shared/utilities';
import { useState } from 'react';
import { LicenseDetailSlideOver } from './LicenseDetailSlideOver';
import { useTranslation } from 'react-i18next';
import { FormattedCurrency } from '@client/project/shared';

interface LicenseRowProps {
  license: LicenseGrantReadModel;
}

export const LicenseRow = ({ license }: LicenseRowProps) => {
  const { t } = useTranslation();

  const [showDetail, setShowDetail] = useState<boolean>(false);

  const expires = !isEmpty(license.activeTo);
  const licType = expires ? t('license.typeExp') : t('license.typePerp');

  const getBilling = (): string => {
   
    switch(license.durationInMonths){
      case undefined:
      case null:
      case 1:
      case 0:
        return t('license.paymentM');
      case 4:
        return t('license.paymentQ');
      case 12:
        return t('license.paymentY');
      default:
        return t('common.other');
    }
  };

  return (
    <div className="flex w-full border-b border-gray-100 hover:bg-slate-100 cursor-pointer items-center py-2">
      <div className="flex w-full items-center" onClick={() => setShowDetail(true)}>
        <div className="w-5/12">
          <div className="font-bold">{license.name}</div>
          <div className="text-sm text-gray-500">{license.description}</div>
        </div>
        <div className="w-3/12">
          {expires && (
            <div className="flex flex-row">
              <CalendarIcon className="w-6 mr-2" />
              <div>
                <div className="flex flex-row">
                  <div>{monthDiff(new Date(license.activeFrom ?? ''), new Date(license.activeTo ?? ''))}</div>
                  <div>-{licType}</div>
                </div>
                <div className="flex flex-row text-xs text-slate-400">
                  <div>{formatDate(license.activeFrom)}</div>
                  <div className="mx-1">-</div>
                  <div>{formatDate(license.activeTo)}</div>
                </div>
              </div>
            </div>
          )}
          {!expires && (
            <div className="flex flex-row">
              <ArrowPathIcon className="w-6 mr-2" />
              <div>
                <div>{licType}</div>
                <div className="flex flex-row text-xs text-slate-400">
                  <div className="mr-1">{t('license.since')}</div>
                  <div>{formatDate(license.activated)}</div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-1/12 flex">
          <div className="flex flex-row font-bold">
            <div>{license.assignees.length}</div>
            <div> / </div>
            <div>{license.seats ? license.seats : '∞'}</div>
          </div>
        </div>
        <div className="w-1/12 flex items-center">
          <BadgeGroup>
            <Badge variant="secondary" text={getBilling()} />
          </BadgeGroup>
        </div>
        <div className="w-1/12">
          <BadgeGroup>
            <Badge variant={license.isActive ? 'success' : 'danger'} text={license.isActive ? t('app.settingsBadgeActive') : t('app.inactive')} />
          </BadgeGroup>
        </div>
        <div className="w-1/12">
          {license.isActive && (
            <div>
              <div className="flex flex-row border-b text-xs text-slate-400 ">
                <div className="ml-3">{license.seats}x</div>
                <div className="flex-grow text-right mr-2">  <FormattedCurrency amount={license.seatPricePerMonth} /></div>
              </div>
              <div className=" text-sm text-right mr-2 mt-1">
                <FormattedCurrency amount={license.seats ?? license.assignees.length * license.seatPricePerMonth} />
              </div>
            </div>
          )}
        </div>
      </div>
      <SlideOver isOpen={showDetail} onClose={() => setShowDetail(false)}>
        <LicenseDetailSlideOver license={license} onClose={() => setShowDetail(false)} />
      </SlideOver>
    </div>
  );
};
