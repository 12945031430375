import { PropsWithChildren } from 'react';
import cn from 'classnames';
import classNames from 'classnames';

interface TitleBarProps extends PropsWithChildren {
  title: string;
  subTitle?: string;
  longTitle?: boolean;
}

export const TitleBar = ({ title, subTitle, longTitle, children }: TitleBarProps) => {
  return (
    <div
      className={cn(
        'bg-white xl:h-16 relative shadow-lg flex flex-col xl:flex-row xl:items-center justify-between gap-4 xl:space-y-0 xl:space-x-4 z-20 flex-shrink-0',
        {
          'py-4 lg:py-2 px-4': children,
          'py-4 lg:py-2 lg:px-4': !children,
        },
      )}
    >
      <div
        className={classNames(`hidden lg:block flex-shrink truncate`, {
          'w-auto xl:max-w-[300px]': !longTitle,
          'w-auto': longTitle,
        })}
      >
        <h1 className="text-xl font-bold truncate">{title}</h1>
        <h2 className="text-xs text-gray-500">{subTitle ?? ''}</h2>
      </div>
      {children && (
        <div
          className={classNames(`flex-grow`, {
            'xl:max-w-[calc(100%-300px)]': !longTitle,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};
