import { CatalogTemplateReadModel } from '@client/shared/api';
import { Badge, BadgeGroup } from '@client/shared/toolkit';
import { RectangleGroupIcon } from '@heroicons/react/24/outline';

interface CatalogTemplateRowProps {
  catalogTemplate: CatalogTemplateReadModel;
  handleEdit: (catalogTemplate: CatalogTemplateReadModel) => void;
}

export const CatalogTemplateRow = ({ catalogTemplate, handleEdit }: CatalogTemplateRowProps) => {
  return (
    <div>
      <div
        className="w-full flex flex-row py-2 text-gray-500 hover:text-black hover:bg-gray-100 cursor-pointer"
        onClick={() => handleEdit(catalogTemplate)}
      >
        <div className="flex mx-3">
          <RectangleGroupIcon className="w-6 " />
        </div>

        <div className="w-4/12">
          <div className="font-bold text-base text-gray-900">
            {catalogTemplate.name}
          </div>
          <div className="text-gray-500">
            {catalogTemplate.comment}
          </div>
        </div>

        <div className="flex w-8/12 text-black items-center">
          <BadgeGroup>
            <Badge 
                text={catalogTemplate.costCatalog.name} 
                variant='danger'/>
            <>
              {catalogTemplate.riskCatalog && 
                <Badge 
                  text={catalogTemplate.riskCatalog.name} 
                  variant='info'/>
              }
            </>
            <>
              {catalogTemplate.earningsCatalog && 
                <Badge 
                  text={catalogTemplate.earningsCatalog.name} 
                  variant='success'/>
              }
            </>
            <>
              {catalogTemplate.financingCatalog && 
                <Badge 
                  text={catalogTemplate.financingCatalog.name} 
                  variant='primary'/>
              }
            </>              
          </BadgeGroup>
        </div>        
      </div>
    </div>
  );
};
