import * as yup from 'yup';
import { InferType } from 'yup';

export const DatevMappingAddFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  description: yup.string().required('validation.required'),
  objectType: yup.string().required('validation.required'),
  datevPropertyName: yup.string().required('validation.required'),
  selectedInput: yup.string().required('validation.required'),
  probisPropertyName: yup.string().when('selectedInput', ([selectedInput], schema) => {
    if (selectedInput === 'probisPropertyName') {
      return yup.string().required('validation.required');
    }
    return yup.string().optional();
  }),
  userDefinedFieldName: yup.string().when('selectedInput', ([selectedInput], schema) => {
    if (selectedInput === 'userDefinedFieldName') {
      return yup.string().required('validation.required');
    }
    return yup.string().optional();
  }),
  defaultValue: yup.string().when('selectedInput', ([selectedInput], schema) => {
    if (selectedInput === 'defaultValue') {
      return yup.string().required('validation.required');
    }
    return yup.string().optional();
  }),
});

export type DatevMappingAddFormValidationValues = InferType<typeof DatevMappingAddFormValidationSchema>;
