import { DealReadModel } from '@client/shared/api';
import { DealSummary, DealStatusProgressBar } from '../Deals';
import { DealContractItems } from '../DealContractItems';
import { DealContacts } from '../DealContacts';
import { DealCostItems } from '../DealCostItems';
import { getDealContractItemsSum } from '../DealCalculation';
import { DealConditions } from '../DealConditions';
import { DealDocuments } from '../DealDocuments';
import { useTranslation } from 'react-i18next';
import { DocumentCustomIcon, HouseMoneyIcon, TimeLimitIcon } from '@client/shared/toolkit';
import { formatDate } from '@client/shared/utilities';
import { useReadOnly } from '@client/project/store';
import { FormattedCurrency } from '@client/project/shared';

interface DealTabPanelContentProps {
  deal: DealReadModel;
}

export const DealTabPanelContent = ({ deal }: DealTabPanelContentProps) => {
  const { t } = useTranslation();

  const isReadOnly = useReadOnly();
  const dealStartDate =
    deal.type === 'Rent'
      ? deal.dealCondition.rentStart != null
        ? new Date(deal.dealCondition.rentStart.toString())
        : undefined
      : deal.start != null
      ? new Date(deal.start.toString())
      : undefined;

  const dealEndDate =
    deal.type === 'Rent'
      ? deal.dealCondition.rentEnd != null
        ? new Date(deal.dealCondition.rentEnd.toString())
        : undefined
      : deal.end != null
      ? new Date(deal.end.toString())
      : undefined;

  return (
    <div className="w-full">
      <div className="pt-2 bg-white pl-10 pr-5">
        <DealSummary deal={deal} />
      </div>
      <div className="border-gray-300 border-t-2 p-6 flex flex-col space-y-6">
        <div className="flex w-full">
          <div className="w-4/12 mr-4 pr-2 pt-2 items-center bg-white shadow-md rounded-md">
            <div className="flex items-center">
              <div className="w-7/12 flex items-center justify-start">
                <DocumentCustomIcon className="w-6 ml-5" />
                <div className="ml-5">
                  <div className="text-[12px]">{t('projectRent.dealStatus')}</div>
                  <div className="font-bold text-xl">{deal.status}</div>
                </div>
              </div>

              <div className="w-5/12 flex ml-6 mr-2 items-center justify-end">
                {dealEndDate && (
                  <div className="ml-5 text-right">
                    <div className="text-[10px] font-bold">{t('projectRent.dealEndDate')}</div>
                    <div className="font-bold text-sm">
                      {dealEndDate.getDate() +
                        '.' +
                        dealEndDate.toLocaleString('default', { month: 'short' }) +
                        '.' +
                        dealEndDate.getFullYear()}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full ml-0.5 mr-1 mt-4 mb-1">
              {deal.status && <DealStatusProgressBar status={deal.status} />}
            </div>
          </div>

          {deal.type === 'Rent' && (
            <div className="w-4/12 mr-4 pr-2 pt-2 bg-white shadow-md rounded-md">
              <div className="flex items-center">
                <div className="w-7/12 flex items-center justify-start">
                  <TimeLimitIcon className="w-6 ml-5" />
                  <div className="ml-5">
                    <div className="text-[12px]">{t('projectRent.dealDuration')}</div>
                    {dealStartDate && dealEndDate && (
                      <span className="flex">
                        <div className="font-bold text-xl">
                          {dealEndDate.getFullYear() - dealStartDate.getFullYear()}
                        </div>
                        <span className="pl-2 pt-4 text-[11px] text-center">
                          {t('common.year', { count: dealEndDate.getFullYear() - dealStartDate.getFullYear() })}
                        </span>
                      </span>
                    )}
                  </div>
                </div>

                <div className="w-5/12 flex ml-6 mr-2 items-center justify-end">
                  {dealStartDate && (
                    <div className="ml-5 text-right">
                      <div className="text-[10px] font-bold">{t('projectRent.dealRentStart')}</div>
                      <div className="font-bold text-sm">{formatDate(dealStartDate)}</div>
                      <div className="text-[10px] text-gray-400">{formatDate(dealEndDate)}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="w-4/12 pt-2 items-center bg-white shadow-md rounded-md">
            <div className="flex items-center">
              <div className="w-7/12 flex justify-start">
                <HouseMoneyIcon className="w-6 ml-5" />
                <div className="ml-5">
                  <div className="text-[12px]">
                    {deal.type === 'Rent' && 'Mietvertrag'}
                    {deal.type === 'Sell' && 'Kaufvertrag'}
                  </div>
                  <span className="flex">
                    <div className="font-bold text-xl"><FormattedCurrency amount={getDealContractItemsSum(deal)} /></div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DealContacts deal={deal} isReadOnly={isReadOnly}/>

        <div className="border-b-2 border-neutral-300"></div>

        <DealCostItems deal={deal} isReadOnly={isReadOnly} />

        <DealContractItems deal={deal} isReadOnly={isReadOnly} />

        <DealConditions deal={deal} isReadOnly={isReadOnly} />

        <DealDocuments deal={deal} allowUpload={false} />
      </div>
    </div>
  );
};
