import { BankCardEuroIcon } from '../../icons';
import { safeMutation } from '@client/shared/utilities';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from '../FormField';
import { TextInput } from '../TextInput';
import { useApiPostValidateIbanMutation } from '@client/shared/api';
import toast from 'react-hot-toast';

interface IbanFormFieldProps {
  setIsValid?: (isValid: boolean) => void;
}

export const IbanFormField = ({ setIsValid }: IbanFormFieldProps) => {
  const [postIbanMutation, { isLoading: isLoadingIbanValidation }] = useApiPostValidateIbanMutation();

  const [invalidIban, setInvalidIban] = useState<boolean>(false);

  const checkIban = async (iban: string) => {
    try {
      await safeMutation(
        postIbanMutation,
        {
          body: {
            iban: iban,
          },
        },
        isLoadingIbanValidation,
      );
      setInvalidIban(false);
      setIsValid && setIsValid(true);
    } catch (e) {
      toast.dismiss();
      setInvalidIban(true);
      setIsValid && setIsValid(false);
    }
  };

  const { t } = useTranslation();
  return (
    <FormField name="iban">
      {(control) => (
        <TextInput
          icon={<BankCardEuroIcon />}
          label={t('app.companiesIban')}
          {...control}
          onChange={(value) => {
            if (value) {
              checkIban(value);
            }
            control.onChange(value);
          }}
          showValidation={(control.value && invalidIban) || control.showValidation}
          isValidationValid={(!control.value || (control.value && !invalidIban)) && control.isValidationValid}
          helperText={control.value && invalidIban ? t('app.companiesInvalidIban') : control.helperText ?? undefined}
        />
      )}
    </FormField>
  );
};

interface IbanInputFieldProps {
  iban: string;
  onChange: (iban: string) => void;
  setIsValid?: (isValid: boolean) => void;
}

export const IbanInputField = ({ iban, onChange, setIsValid }: IbanInputFieldProps) => {
  const [postIbanMutation, { isLoading: isLoadingIbanValidation }] = useApiPostValidateIbanMutation();

  const [invalidIban, setInvalidIban] = useState<boolean>(false);

  const checkIban = async (iban: string) => {
    try {
      await safeMutation(
        postIbanMutation,
        {
          body: {
            iban: iban,
          },
        },
        isLoadingIbanValidation,
      );
      setInvalidIban(false);
      setIsValid && setIsValid(true);
    } catch (e) {
      toast.dismiss();
      setInvalidIban(true);
      setIsValid && setIsValid(false);
    }
  };

  const { t } = useTranslation();
  return (
    <TextInput
      icon={<BankCardEuroIcon />}
      label={t('app.companiesIban')}
      value={iban ?? ''}
      showValidation={invalidIban}
      isValidationValid={!invalidIban}
      helperText={invalidIban ? t('app.companiesInvalidIban') : undefined}
      onChange={(value) => {
        checkIban(value);
        onChange(value);
      }}
    />
  );
};
