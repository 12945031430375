import { TaxonomyReadModel, PlotReadModel } from '@client/shared/api';
import { PlanCostsIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

interface MarketRentCardProps {
  item?: TaxonomyReadModel | undefined;
  plot?: PlotReadModel | undefined;
}

export const MarketRentCard = ({ item }: MarketRentCardProps) => {
  const { t } = useTranslation();

  return (
    <div className="rounded shadow p-4 w-full h-full flex flex-row bg-white ">
      <div className="w-full flex flex-row">
        <div className="flex justify-center items-center mr-4">
          <PlanCostsIcon className="h-8 w-8" />
        </div>
        <div className="w-full">
          <div className="my-1 text-xxs font-bold text-slate-500">{t('projectTaxonomy.dashboardMarketRent')}</div>
          {/* <div className="text-xxs">
            <span className="font-extrabold">17,20€ </span>
            <span className=""> ... Miete p.m</span>
          </div>
          <div className="my-1 w-full bg-slate-300 h-1">
            <div className="my-1 w-1/2 bg-blue-900 h-1" />
          </div>
          <div className="text-xxs">
            <span className="">Aktuelle Miete p.m ... </span>
            <span className="font-extrabold">17,20€ </span>
            <span className="font-extrabold text-slate-400 ">19,00 €</span>
          </div>
          <div className="text-slate-400 text-xxs w-full text-end">Ziel Miete p.m</div> */}
        </div>
      </div>
    </div>
  );
};
