import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Scale,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { isDateInBetween } from '@client/shared/utilities';
import React, { useMemo, useState } from 'react';
import { DecoratedCard, ToggleSlider } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { eachMonthOfInterval } from 'date-fns/fp';
import { format, getMonth, getYear } from 'date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';

// ------- CONSTANTS START -------
const MONTH_X_AXIS_WIDTH = 30;
const CHART_PADDING_X = 20;

const BLUE_BAR_COLOR = '#334155'; // primary
const BLUE_LINE_COLOR = '#0A5A85'; // secondary
const LIGHT_BAR_COLOR = '#e2e8f0'; // slate-200
const WHITE_COLOR = '#ffffff'; // white
const TODAY_MARKER_COLOR = '#fbbf24'; // amber-400
const GREEN_LINE_COLOR = '#34d399'; // emerald-400
const GRAY_COLOR = '#6b7280'; // gray-500
const BORDER_COLOR = '#d1d5db'; // gray-300
// ------- CONSTANTS END -------

export const ReportCashOut = ({ title }: { title?: string }) => {
  ChartJS.register(
    annotationPlugin,
    BarController,
    BarElement,
    LinearScale,
    PointElement,
    Tooltip,
    TimeScale,
    LinearScale,
    CategoryScale,
    LineElement,
    LineController,
    BarController,
    Title,
    ChartDataLabels,
  );
  const { t } = useTranslation();
  const [showNet, setShowNet] = useState(true);

  const start = useMemo(() => {
    return new Date('2024-01-01');
  }, []);

  const end = useMemo(() => {
    return new Date('2025-12-31');
  }, []);

  const labels = useMemo(() => {
    const monthLabels: string[] = [];
    const yearLabels: string[] = [];
    const timelineMonths = eachMonthOfInterval({ start: start, end: end });
    timelineMonths.forEach((month) => {
      const monthName = format(month, 'LLL');
      const yearName = format(month, 'yyyy');
      monthLabels.push(monthName);
      if (!yearLabels.includes(yearName)) {
        yearLabels.push(yearName);
      }
    });

    return {
      monthLabels: monthLabels,
      yearLabels: yearLabels,
    };
  }, [start, end]);

  const todayMarkerPositionX = useMemo(() => {
    const today = new Date();
    const nowIsInBetween = isDateInBetween(today, start, end);
    if (nowIsInBetween) {
      const month = getMonth(today);
      const startYear = getYear(start);
      const todayYear = getYear(today);
      const yearDiff = todayYear - startYear;
      return yearDiff * 12 + month;
    }
    return -1;
  }, [start, end]);

  // TODO percentage based on size of line y axis values
  const yBarScaleMax = useMemo(() => {
    return 200;
  }, []);

  const todayMarkerPositionY = useMemo(() => {
    return yBarScaleMax - 5;
  }, [yBarScaleMax]);

  // TODO from API
  const datasetValues = useMemo(() => {
    return {
      isBar: [50, 50, 0, 50, 0, 20, 60, 10, 20, 20, null, null],
      shouldBar: [50, 50, 50, 50, 10, 20, 60, 10, 20, 20, 20, 20, 20, 20, 20, 24, 20, 20, 20, 20, 20, 20],
      committed: [250, 250, 235, 250, 233, 220, 260, 210, 203, 240],
      planned: [220, 230, 235, 250, 263, 210, 250, 245, 213, 250],
      plannedFuture: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        250,
        230,
        235,
        250,
        233,
        220,
        260,
        210,
        203,
        240,
        240,
        240,
        240,
        240,
        240,
      ],
      actuals: [220, 250, 245, 250, 233, 180, 250, 210, 203, 240],
    };
  }, []);

  // ------- OPTIONS START -------
  const options: ChartOptions = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: false,
        },
        htmlLegend: {},
        legend: {
          display: false,
        },
        annotation: {
          annotations: {
            todayMarker: {
              type: 'label',
              xValue: todayMarkerPositionX,
              yValue: todayMarkerPositionY,
              padding: {
                top: 3,
                left: 10,
                right: 10,
                bottom: 1
              },
              backgroundColor: TODAY_MARKER_COLOR,
              borderRadius: 50,
              content: [`  Today  `],
              position: {
                x: 'center',
                y: 'center',
              },
              color: WHITE_COLOR,
              font: {
                family: 'Roboto',
                size: 9,
                weight: 'bold',
              },
              z: 2,
              display: todayMarkerPositionX >= 0,
            },
            todayMarkerLine: {
              type: 'line',
              yMin: 0,
              yMax: todayMarkerPositionY,
              xMin: todayMarkerPositionX,
              xMax: todayMarkerPositionX,
              borderWidth: 1,
              borderDash: [1.5],
              borderColor: TODAY_MARKER_COLOR,
              display: todayMarkerPositionX >= 0,
            },
            commitment: {
              type: 'label',
              xValue: todayMarkerPositionX,
              yValue: datasetValues.committed[datasetValues.committed.length - 1],
              // TODO
              yAdjust: (context) => {
                return -25;
              },
              // xValue: (context) => context.chart.scales.x.max,
              content: 'Committed | 12M',
              color: WHITE_COLOR,
              backgroundColor: BLUE_LINE_COLOR,
              borderRadius: 50,
              padding: {
                top: 3,
                left: 10,
                right: 10,
                bottom: 1
              },
              position: {
                x: 'start',
                y: 'end',
              },
              yScaleID: 'yLineChart',
              font: {
                family: 'Roboto',
                size: 9,
                weight: 'bold',
              },
            },
            planned: {
              type: 'label',
              xValue: todayMarkerPositionX,
              yValue: datasetValues.plannedFuture[datasetValues.plannedFuture.length - 1] ?? 0,
              content: 'Planned | 12M',
              color: WHITE_COLOR,
              backgroundColor: GREEN_LINE_COLOR,
              borderRadius: 50,
              padding: {
                top: 3,
                left: 10,
                right: 10,
                bottom: 1
              },
              position: {
                x: 'start',
                y: 'end',
              },
              yScaleID: 'yLineChart',
              font: {
                family: 'Roboto',
                size: 9,
                weight: 'bold',
              },
            },
            actuals: {
              type: 'label',
              xValue: todayMarkerPositionX,
              yValue: datasetValues.actuals[datasetValues.actuals.length - 1] ?? 0,
              // TODO
              yAdjust: 25,
              content: 'Actuals | 12M',
              color: WHITE_COLOR,
              backgroundColor: BORDER_COLOR,
              borderRadius: 50,
              padding: {
                top: 3,
                left: 10,
                right: 10,
                bottom: 1
              },
              position: {
                x: 'start',
                y: 'end',
              },
              yScaleID: 'yLineChart',
              font: {
                family: 'Roboto',
                size: 9,
                weight: 'bold',
              },
            },
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          labels: labels.monthLabels,
          beginAtZero: true,
          grid: {
            drawOnChartArea: false,
            tickLength: -10,
            lineWidth: 2,
          },
          border: {
            width: 2,
            color: BORDER_COLOR,
          },
          afterFit(axis: Scale) {
            axis.height = 20;
          },
          ticks: {
            padding: 15,
            font: {
              family: 'Roboto',
              size: 9,
            },
          },
        },
        xYear: {
          labels: labels.yearLabels,
          beginAtZero: true,
          border: {
            display: false,
            color: BORDER_COLOR,
          },
          grid: {
            drawOnChartArea: false,
            tickLength: -20,
            lineWidth: 2,
          },
          afterFit(axis: Scale) {
            axis.height = 25;
          },
          ticks: {
            padding: 20,
            font: {
              family: 'Roboto',
              size: 9,
              weight: 'bold',
            },
          },
        },
        yBarChart: {
          beginAtZero: true,
          id: 'yBarChart',
          type: 'linear',
          position: 'left',
          border: {
            width: 2,
            color: BORDER_COLOR,
          },
          grid: {
            drawOnChartArea: false,
          },
          min: 0,
          max: yBarScaleMax,
          ticks: {
            font: {
              family: 'Roboto',
              size: 9,
              weight: 'bold',
            },
            color: GRAY_COLOR,
          },
        },
        yLineChart: {
          beginAtZero: true,
          id: 'yLineChart',
          type: 'linear',
          position: 'right',
          border: {
            width: 2,
          },
          grid: {
            drawOnChartArea: false,
          },
          ticks: {
            font: {
              family: 'Roboto',
              size: 9,
              weight: 'bold',
            },
            color: GRAY_COLOR,
          },
        },
      },
      layout: {
        padding: {
          top: 10,
          left: CHART_PADDING_X,
          bottom: 10,
          right: CHART_PADDING_X,
        },
      },
    };
  }, [todayMarkerPositionX, labels.monthLabels, labels.yearLabels, yBarScaleMax, todayMarkerPositionY, datasetValues]);
  // ------- OPTIONS END -------

  // ------- CHART DATA START -------
  const chartData: ChartData = useMemo(() => {
    return {
      labels: labels.monthLabels,
      datasets: [
        {
          label: 'Is',
          data: datasetValues.isBar,
          backgroundColor: BLUE_BAR_COLOR,
          type: 'bar' as const,
          xAxisID: 'x',
          yAxisID: 'yBarChart',
          stack: 'Stack 0',
          datalabels: {
            labels: {
              title: null,
            },
          },
          barPercentage: 0.75,
        },
        {
          label: 'Should',
          data: datasetValues.shouldBar,
          datalabels: {
            labels: {
              title: null,
            },
          },
          type: 'bar' as const,
          backgroundColor: LIGHT_BAR_COLOR,
          xAxisID: 'x',
          yAxisID: 'yBarChart',
          stack: 'Stack 0',
          barPercentage: 0.75,
        },
        {
          label: 'Committed',
          data: datasetValues.committed,
          type: 'line' as const,
          xAxisID: 'x',
          yAxisID: 'yLineChart',
          fill: false,
          borderWidth: 2,
          borderDash: [2, 2.5],
          borderColor: BLUE_LINE_COLOR,
          datalabels: {
            labels: {
              title: null,
            },
          },
        },
        {
          label: 'PlannedUntilNow',
          data: datasetValues.planned,
          type: 'line' as const,
          xAxisID: 'x',
          yAxisID: 'yLineChart',
          fill: false,
          borderColor: GREEN_LINE_COLOR,
          datalabels: {
            labels: {
              title: null,
            },
          },
        },
        {
          label: 'PlannedFuture',
          data: datasetValues.plannedFuture,
          type: 'line' as const,
          xAxisID: 'x',
          yAxisID: 'yLineChart',
          fill: false,
          borderColor: GREEN_LINE_COLOR,
          borderDash: [2, 2.5],
          borderWidth: 2,
          datalabels: {
            labels: {
              title: null,
            },
          },
        },
        {
          label: 'Actuals',
          data: datasetValues.actuals,
          type: 'line' as const,
          xAxisID: 'x',
          yAxisID: 'yLineChart',
          fill: false,
          borderColor: BORDER_COLOR,
          datalabels: {
            labels: {
              title: null,
            },
          },
        },
      ],
    };
  }, [labels.monthLabels, datasetValues]);
  // ------- CHART DATA END -------

  const chartMinWidth = useMemo(() => {
    const minWidth = labels.monthLabels.length * MONTH_X_AXIS_WIDTH + CHART_PADDING_X * 2;
    return minWidth >= 1200 ? minWidth : 1200;
  }, [labels.monthLabels.length]);

  return (
    <div className="min-w-[1200px]" style={{ minWidth: chartMinWidth }}>
      <DecoratedCard className="w-full">
        <DecoratedCard.Header showActionButton={false}>
          <div className="flex flex-row justify-between items-center w-full">
            <div className="flex justify-between w-full gap-3">
              <div className="truncate">{title ? title : t('reporting.reportCashOut.fullTitle')}</div>
              <ToggleSlider
                headerLabel={t('reporting.netGrossSwitchLabel')}
                left={showNet}
                onClick={() => setShowNet((prev) => !prev)}
                labelLeft={t('projectControl.net')}
                labelRight={t('projectControl.gross')}
              />
            </div>
          </div>
        </DecoratedCard.Header>
        <DecoratedCard.Content className="w-full h-full flex relative">
          <div className="my-6">
            {/* export-chart id important for exporting chart as PDF */}
            <div className="h-[67vh]" id="export-chart">
              <Chart options={options} data={chartData} type="bar" />
            </div>
          </div>
        </DecoratedCard.Content>
      </DecoratedCard>
    </div>
  );
};
