import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {
  ArchiveIcon,
  CopyToClipboardIcon,
  ExportIcon,
  FloatingMenu,
  MoveNodeUpIcon,
  MultiProjectIcon,
  ReportFileDottedIcon,
  TrashCanCustomIcon,
} from '@client/shared/toolkit';
import { useValidateTenantPermission } from '@client/shared/permissions';
import { settings, useUi } from '@client/shared/store';
import { OpenModal } from '@client/project/edit';

type ProjectsFloatingMenuProps = {
  selectedItemsCount: number;
  clearSelection: () => void;
  onClickMultiProject: () => void;
  onClickReporting: (direction: 'project' | 'multiProject') => void;
  openModal: (type: OpenModal) => void;
  isMultiProjectSelected?: boolean;
};
export const ProjectsFloatingMenu = (props: ProjectsFloatingMenuProps) => {
  const { t } = useTranslation();
  const {
    openModal,
    selectedItemsCount,
    onClickMultiProject,
    onClickReporting,
    isMultiProjectSelected = false,
  } = props;

  const canCreateProject = useValidateTenantPermission(['TENANT_PROJECT_CREATE']);
  const canDeleteProject = useValidateTenantPermission(['TENANT_PROJECT_DELETE']);

  const ui = useUi();
  const user = ui.appUser;

  const canViewReporting =
    user.tenant?.tenantId === '3239f945-4fce-4ebb-931f-89700f4c7631' ||
    settings.mainDomain === 'probis.qa' ||
    settings.mainDomain === 'probis.test' ||
    settings.mainDomain === 'probis.dev';

  const menuOptions = useMemo(() => {
    return [
      {
        label: t('project.Duplicate'),
        icon: <CopyToClipboardIcon />,
        show: selectedItemsCount === 1 && !isMultiProjectSelected && canCreateProject,
        onClick: () => openModal('Copy'),
        disabled: !canCreateProject,
      },
      {
        label: t('project.Export'),
        icon: <ExportIcon />,
        show: true,
        disabled: true,
      },
      {
        label: t('project.Archive'),
        icon: <ArchiveIcon />,
        show: true,
        disabled: true,
      },
      {
        label: t('project.Delete'),
        icon: <TrashCanCustomIcon />,
        show: selectedItemsCount === 1 && canDeleteProject,
        onClick: () => openModal('Delete'),
        iconClassName: 'w-4 pb-1',
      },
      {
        label: t('project.Reporting'),
        icon: <ReportFileDottedIcon />,
        onClick: () => onClickReporting(isMultiProjectSelected ? 'multiProject' : 'project'),
        show: selectedItemsCount === 1,
        disabled: isMultiProjectSelected ? !canViewReporting : false,
      },
      {
        label: t('project.Linking'),
        icon: <MoveNodeUpIcon />,
        show: !isMultiProjectSelected,
        onClick: () => openModal('Move'),
      },
      {
        label: t('project.Multiproject'),
        icon: <MultiProjectIcon />,
        onClick: onClickMultiProject,
        show: !isMultiProjectSelected,
        disabled: !canCreateProject,
      },
    ];
  }, [
    t,
    selectedItemsCount,
    isMultiProjectSelected,
    canCreateProject,
    openModal,
    canDeleteProject,
    onClickMultiProject,
    onClickReporting,
    canViewReporting,
  ]);

  const shownMenuOptions = useMemo(() => {
    return menuOptions.filter((option) => option.show);
  }, [menuOptions]);

  return (
    <FloatingMenu
      selectedItemsCount={selectedItemsCount}
      clearSelection={props.clearSelection}
      menuOptions={shownMenuOptions}
    />
  );
};
