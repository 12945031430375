import {ValuationPoint} from '@client/shared/api';
import {useTranslation} from 'react-i18next';
import {Fragment} from 'react';
import {formatUnit} from '@client/shared/utilities';

export interface PriceHubbleLineChartProps {
  data: ValuationPoint[];
  increase: number;
  label: string;
}

export const PriceHubbleLineChart = ({ data, increase, label }: PriceHubbleLineChartProps) => {
  const { t } = useTranslation();
  const highestRentValue = data.reduce((a, b) => (a.value > b.value ? a : b)).value;
  const lowestRentValue = data.reduce((a, b) => (a.value < b.value ? a : b)).value;

  return (
    <div className="my-5 w-full flex flex-row items-center">
      <div className="flex justify-center items-center mr-4 h-5 w-5">
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
          <path
            id="icons8-rent"
            d="M18.494,0a.717.717,0,0,0-.333.085L2.378,8.55A.717.717,0,0,0,2,9.183v3.228a.717.717,0,0,0,1.059.631l.376-.2V32.283A.717.717,0,0,0,4.152,33h28.7a.717.717,0,0,0,.717-.717V12.839l.376.2A.717.717,0,0,0,35,12.411V9.183a.717.717,0,0,0-.378-.633L18.839.085A.717.717,0,0,0,18.494,0ZM18.5,1.531l15.065,8.08v1.595L18.842,3.242a.717.717,0,0,0-.684,0L3.435,11.206V9.612Zm0,3.158,13.63,7.373v19.5H4.87v-19.5Zm-.717,6.789v1.506c-2.47.26-4,1.723-4,3.846,0,5.409,8.043,2.868,8.043,6.6,0,1.442-1.352,2.4-3.325,2.4s-3.276-1.1-3.384-2.413H13.439c.01,2.153,1.716,3.684,4.344,3.9V28.7h1.435V27.3c2.72-.245,4.3-1.707,4.3-4.01,0-5.329-8.052-3.189-8.052-6.562,0-1.226,1.088-2.246,3.031-2.246s3.06,1.1,3.1,2.325h1.667c-.068-2.107-1.658-3.6-4.049-3.834v-1.5Z"
            transform="translate(-2)"
            fill="white"
          />
        </svg>
      </div>
      <div className="w-full">
        <div className="w-full h-full relative">
          <svg viewBox="0 0 100 20">
            {data.map(
              (rent, i) =>
                i < data.length - 1 && (
                  <Fragment key={`lines-${i}`}>
                    <line
                      x1={(i / (data.length - 1)) * 100 + '%'}
                      y1={(1 - (rent.value - lowestRentValue) / (highestRentValue - lowestRentValue)) * 100 + '%'}
                      x2={((i + 1) / (data.length - 1)) * 100 + '%'}
                      y2={
                        (1 - (data[i + 1].value - lowestRentValue) / (highestRentValue - lowestRentValue)) * 100 + '%'
                      }
                      stroke="white"
                      strokeWidth="0.5"
                    />
                    <line
                      x1={(i / (data.length - 1)) * 100 + '%'}
                      y1="0%"
                      x2={(i / (data.length - 1)) * 100 + '%'}
                      y2="100%"
                      stroke="white"
                      strokeWidth="0.5"
                      opacity="0.1"
                    />
                  </Fragment>
                )
            )}

            {/* {data.map((rent, i) => (
              <text
                key={`text-${i}`}
                x={(i / (data.length - 1)) * 100 + '%'}
                y={(1 - (rent.value - lowestRentValue) / (highestRentValue - lowestRentValue)) * 100 + '%'}
                dx="0.5em"
                dy="-0.5em"
                fill="white"
                fontSize="0.25em"
                textAnchor="middle"
              >
                {rent.value}
              </text>
            ))} */}

            {data.map((rent, i) => (
              <circle
                key={`circle-${i}`}
                cx={(i / (data.length - 1)) * 100 + '%'}
                cy={(1 - (rent.value - lowestRentValue) / (highestRentValue - lowestRentValue)) * 100 + '%'}
                r="1"
                fill="black"
                stroke="white"
                strokeWidth="0.5"
              />
            ))}
          </svg>
        </div>

        <div className="mt-5 text-xxs w-full flex justify-between">
          <span className="text-xs">{t(label)}</span>
          <span className="flex-grow border-b border-white border-dotted" />
          <span className="font-extrabold text-right text-xs">
            {increase > 0 && '+ '}
            {formatUnit(increase, '%')}
          </span>
        </div>
      </div>
    </div>
  );
};
