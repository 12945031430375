import { useNavigate, useParams } from 'react-router-dom';
import { LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import React, { useContext, useEffect, useState } from 'react';
import { InvoiceReadModel, useApiGetInvoiceQuery } from '@client/shared/api';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import { InvoiceSlideOver, InvoiceEditContext } from '@client/project/shared';

interface InvoiceViewProps {
  isLoading: boolean;
}

export const InvoiceView = ({isLoading}: InvoiceViewProps) => {
  const { invoiceId } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const [isOpenSlideOver, setIsOpenSlideOver] = useState(false);
  const [isChildSlideOverIsOpen, setIsChildSlideOverOpen] = useState(false);
  const [invoice, setInvoice] = useState<InvoiceReadModel | null>(null);

  if (!invoiceId) {
    navigate(ROUTES_CONFIG.PROJECT_CONTROL.name);
  }

  const {
    data: loadedInvoice,
    isFetching: isLoadingInvoice,
    isError,
  } = useApiGetInvoiceQuery(
    {
      invoiceId: invoiceId ?? '',
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !invoiceId,
    },
  );

  useEffect(() => {
    if (typeof loadedInvoice !== 'undefined' && loadedInvoice !== null && loadedInvoice.invoice) {
      setInvoice(loadedInvoice.invoice);
      if (!invoice) {
        setIsOpenSlideOver(true);
      }
    }
    if (isError) {
      navigate(ROUTES_CONFIG.PROJECT_CONTROL.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedInvoice, isError]);

  const { isEditMode, unsavedData } = useContext(InvoiceEditContext);

  return (
    <>
      {!isOpenSlideOver && !isLoading && isLoadingInvoice && (
        <LoadingIndicator text={t('projectControl.fetchingInvoiceLoadingIndicator')} mode="overlay-window" />
      )}
      <SlideOver
        isOpen={isOpenSlideOver}
        onClose={() => setIsOpenSlideOver(false)}
        variant="2xl"
        confirmBeforeClose={unsavedData.length > 0}
        cancelConfirmTitle={t('portfolio.projecting.cancelEditingConfirmationTitle')}
        cancelConfirmDescription={t('portfolio.projecting.cancelEditingConfirmationDescription')}
        cancelButtonText={t('portfolio.projecting.continueEditing')}
        confirmButtonText={t('portfolio.projecting.cancelEditingWithoutSaving')}
        preventClickOutsideClose={isChildSlideOverIsOpen || isEditMode}
        onAfterLeave={() => {
          navigate(ROUTES_CONFIG.PROJECT_CONTROL.name);
        }}
      >
        {isOpenSlideOver && !isLoading && isLoadingInvoice && (
          <LoadingIndicator text={t('projectControl.fetchingInvoiceLoadingIndicator')} mode="overlay" />
        )}
        {invoice && (
          <InvoiceSlideOver
            onClose={() => setIsOpenSlideOver(false)}
            selectedInvoiceId={invoice.id}
            selectedInvoice={invoice}
            setChildSlideOverIsOpen={setIsChildSlideOverOpen}
          />
        )}
      </SlideOver>
    </>
  );
};
