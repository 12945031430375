import * as yup from 'yup';
import {InferType} from 'yup';

export const MultiProjectNewFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  description: yup.string().optional(),
  externalId: yup.string().optional(),
  projectIds: yup.array(yup.string()).required('validation.required'),
  coverImage: yup.mixed<FileList>().optional(),
});
export type MultiProjectNewFormValidationValues = InferType<typeof MultiProjectNewFormValidationSchema>;
