import classNames from 'classnames';
import {
  ContextMenu,
  ContextMenuItem,
  DocumentViewerSlideOver,
  DownloadingUpdatesIcon,
  EyeIcon,
  SlideOver,
  TrashIcon
} from '..';
import { formatFileSize, humanizeJsonDate, isPdf } from '@client/shared/utilities';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const getFileExt = (filename: string): string => {
  if (!filename.includes('.')) return '.*';
  const fileExt = filename.split('.').pop();
  return fileExt?.toUpperCase() ?? '.*';
};

interface DocumentListItemProps {
  fileName: string;
  fileSize: number;
  created: string;
  title?: string;
  contextMenu?: boolean;
  downloadUrl?: string
  setIsDeleteModalOpen?: (open: boolean) => void;
  contextMenuItems?: ContextMenuItem[];
  documentViewerTitle?: string;
}
export const DocumentListItem = (props: DocumentListItemProps) => {
  const {
    title,
    fileName,
    fileSize,
    created,
    contextMenu,
    downloadUrl,
    setIsDeleteModalOpen,
    contextMenuItems,
    documentViewerTitle
  } = props;
  const { t } = useTranslation();
  const [isDocumentViewerOpen, setIsDocumentViewerOpen] = useState<boolean>(false);
  const documentIsPdf = isPdf(fileName);

  const defaultContextMenuItems: ContextMenuItem[] = useMemo(() => {
    const items: ContextMenuItem[] = [
      {
        label: t('common.view'),
        isDisabled: !documentIsPdf,
        icon: <EyeIcon />,
        onClick: () => setIsDocumentViewerOpen(true),
      }
    ]
    if (downloadUrl) {
      items.push({
        label: t('common.download'),
        icon: <DownloadingUpdatesIcon />,
        onClick: () => {
          const link = document.createElement('a');

          link.setAttribute('target', '_new');
          link.href = downloadUrl;
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        },
      });
    }
    if (setIsDeleteModalOpen) {
      items.push({
        label: t('common.delete'),
        icon: <TrashIcon />,
        onClick: () => setIsDeleteModalOpen(true),
      });
    }
    return items;
  }, [t, documentIsPdf, downloadUrl, setIsDeleteModalOpen]);

  return (
    <div className="flex flex-row w-full bg-white hover:bg-gray-100 border-b border-gray-100 shadow-sm h-14 rounded text-sm gap-2">
      <div className="w-14 flex justify-center mt-2">
        <div className="flex w-8 h-10 border-2 rounded-tr-2xl rounded border-gray-500">
          <div className="truncate text-gray-500 font-semibold mt-4 text-[10px] ml-[3px]">
            {getFileExt(fileName)}
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full font-bold items-start justify-center max-w-[calc(100%-100px)]">
        <div
          className={classNames('flex w-full', {
            'hover:cursor-pointer': documentIsPdf,
          })}
          onClick={() => documentIsPdf && setIsDocumentViewerOpen(true)}
        >
          {documentIsPdf && <EyeIcon className="w-5 h-5 flex-none mr-2" />}
          <span className="truncate">{title ?? fileName}&nbsp;</span>
        </div>
        <div className="flex gap-2">
          <div className="text-gray-400 text-xs">
            {t('projectRent.dealDocumentLabelSize')}: {formatFileSize(fileSize)}
          </div>
          <div className="text-gray-400 text-xs">
            {t('projectRent.dealDocumentLabelUploaded')}: {humanizeJsonDate(created)}
          </div>
        </div>
      </div>
      {contextMenu && (
        <div className="flex items-center justify-end text-gray-500 hover:text-gray-800 pr-3">
          <ContextMenu items={contextMenuItems ?? defaultContextMenuItems} />
        </div>
      )}
      <SlideOver isOpen={isDocumentViewerOpen} onClose={() => setIsDocumentViewerOpen(false)} variant="large">
        <DocumentViewerSlideOver
          name={fileName}
          preTitle="PDF"
          subTitle=""
          pdfFile={downloadUrl ?? ''}
          onClose={() => setIsDocumentViewerOpen(false)}
          documentViewerTitle={documentViewerTitle}
        />
      </SlideOver>
    </div>
  )
}
