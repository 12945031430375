import { formatCurrency, FormatCurrencyOptions } from '@client/shared/utilities';
import { useGetCurrency } from '@client/project/store';

export interface FormattedCurrencyProps {
  amount: number | null | undefined,
  options?: FormatCurrencyOptions
}

export const FormattedCurrency = (props: FormattedCurrencyProps) => {
  const currentCurrency = useGetCurrency()
  const options = {...props.options, currency: currentCurrency}
  return formatCurrency(props.amount, options)
}
