import { formatNumber } from '@client/shared/utilities';
import { useEffect, useMemo, useRef, useState } from 'react';

type GaugeProps = {
  width: string;
  value: number;
  label: string;
  strokeColor: string;
};

export const Gauge = (props: GaugeProps) => {
  const gaugeRef = useRef<HTMLDivElement>(null)
  const [viewportWidth, setViewportWidth] = useState(0)


  useEffect(() => {
    const resizeHandler = () => {
      setViewportWidth(window.innerWidth)
    }
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, []);

  const svgData = useMemo(() => {
    if (gaugeRef.current) {
      const strokeWidth = gaugeRef.current?.clientWidth * 0.08;
      const innerRadius = (gaugeRef.current?.clientWidth - strokeWidth) / 2;
      const circumference = 2 * Math.PI * innerRadius;
      const arc = circumference * (270 / 360);
      const dashArray = `${arc} ${circumference}`;
      const percentNormalized = Math.min(Math.max(props.value, 0), 100);
      const offset = arc - (percentNormalized / 100) * arc;
      return {
        strokeWidth: strokeWidth,
        innerRadius: innerRadius,
        circumference: circumference,
        arc: arc,
        dashArray: dashArray,
        percentNormalized: percentNormalized,
        offset: offset
      }
    }

    return {
      strokeWidth: 0,
      innerRadius: 0,
      circumference: 0,
      arc: 0,
      dashArray: 0,
      percentNormalized: 0,
      offset: 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaugeRef.current?.clientWidth, props.value])

  return (
    <div className={props.width}>
      <div className={`relative w-full aspect-square rounded-full`} ref={gaugeRef}>
        <svg className="relative w-full h-full rotate-[135deg]">
          <circle
            className="fill-transparent stroke-slate-700"
            cx="50%"
            cy="50%"
            r={svgData.innerRadius}
            fill="transparent"
            strokeWidth={svgData.strokeWidth}
            strokeLinecap="square"
            strokeDasharray={svgData.dashArray}
          />
          <circle
            className={`fill-transparent ${props.strokeColor}`}
            cx="50%"
            cy="50%"
            r={svgData.innerRadius}
            fill="transparent"
            strokeWidth={svgData.strokeWidth}
            strokeLinecap="square"
            strokeDasharray={svgData.dashArray}
            strokeDashoffset={svgData.offset}
          />
        </svg>

        <div className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 font-bold ${viewportWidth > 1700 ? 'text-lg' : 'text-sm'}`} >
          {formatNumber((props.value * 5) / 100, { maxDigits: 1 })}
        </div>
      </div>
      <div className="w-full relative text-center text-xs">{props.label}</div>
    </div>
  );
};
