import {
  ContractNumberIcon,
  CreateContractIcon,
  RenameIcon,
  TextInput,
  WizardSlideContent,
  WizardSlideHeader,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

interface InvoiceCreateWithNewContractSlidesProps {
  contractCode: string;
  setContractCode: (value: string) => void;
  contractName: string;
  setContractName: (value: string) => void;
  codeError: boolean;
}

export const InvoiceCreateWithNewContractSlides = (props: InvoiceCreateWithNewContractSlidesProps) => {
  const { contractCode, contractName, setContractCode, setContractName, codeError } = props;
  const { t } = useTranslation();

  return (
    <>
      <WizardSlideHeader icon={<CreateContractIcon />} title={t('projectContract.wizardNewContract')} />
      <WizardSlideContent>
        <div className="flex gap-1">
          <TextInput
            className="w-1/2"
            label={t('projectContract.contractCode')}
            icon={<ContractNumberIcon />}
            value={contractCode}
            onChange={setContractCode}
            isValidationValid={!codeError}
            showValidation={codeError}
            helperText={codeError ? t('error.contract.code_already_exists') : undefined}
            helperTextClassName="bg-transparent"
          />
          <TextInput
            className="w-1/2 flex-1"
            label={t('projectContract.contractName')}
            icon={<RenameIcon />}
            value={contractName}
            onChange={setContractName}
          />
        </div>
      </WizardSlideContent>
    </>
  );
};
