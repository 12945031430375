import { Button, HintBox, LoadingIndicator, Modal, ModalOnCloseProps } from '@client/shared/toolkit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DatevClientResetModalProps extends ModalOnCloseProps {
  clientName: string;
  isOpen: boolean;
  handleReset: () => void;
  handleDelete: () => void;
  modalType: 'delete' | 'reset';
  isDeleting: boolean;
}

export const DatevClientResetModal = ({
  clientName,
  onClose,
  isOpen,
  handleReset,
  handleDelete,
  modalType,
  isDeleting,
}: DatevClientResetModalProps) => {
  const { t } = useTranslation();

  const [resetConfirmed, setResetConfirmed] = useState(false);

  const handleClose = () => {
    onClose(false);
    setResetConfirmed(false);
  };

  const isDelete = modalType === 'delete';

  return (
    <Modal isOpen={isOpen} onClose={onClose} onAfterLeave={() => setResetConfirmed(false)}>
      <Modal.Header
        title={t(
          isDelete ? 'app.settingsApiDatevResetClientTitleDelete' : 'app.settingsApiDatevResetClientTitleReset',
          { name: clientName },
        )}
      />
      <Modal.Content className="text-center">
        {isDeleting && <LoadingIndicator text={t('apiAccessId') ?? ''} mode="overlay" />}
        <HintBox hintType="warning">
          {t(isDelete ? 'app.settingsApiDatevDeleteClientWarning' : 'app.settingsApiDatevResetClientWarning')}
        </HintBox>
        {!isDelete && (
          <div className="w-full items-center pt-5">
            {resetConfirmed ? (
              <HintBox hintType="danger">{t('app.settingsApiDatevResetClientFinalWarning')}</HintBox>
            ) : (
              <Button variant="danger" onClick={() => setResetConfirmed(true)}>
                {t('common.reset')}
              </Button>
            )}
          </div>
        )}
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" onClick={() => handleClose()} className="mr-2">
          {t('common.cancel')}
        </Button>
        {isDelete ? (
          <Button variant="danger" onClick={handleDelete} disabled={isDeleting}>
            {t('common.delete')}
          </Button>
        ) : (
          resetConfirmed && (
            <Button variant="danger" onClick={handleReset}>
              {t('app.settingsDeleteUsedCustomFieldReset')}
            </Button>
          )
        )}
      </Modal.Controls>
    </Modal>
  );
};
