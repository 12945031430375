export class CoordinateSystem {
  private reserved: string[] = [];
  public getNextPossibleIndex(row: number): number {
    let index = -1;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      index++;
      const key = `${row}-${index}`;
      if (this.reserved.indexOf(key) > -1) continue;

      this.reserved.push(key);

      return index;
    }
  }
}
