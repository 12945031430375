import {InformationCircleIcon} from '@heroicons/react/24/outline';
import React from 'react';
import {useTranslation} from 'react-i18next';
import { FormattedCurrency } from '@client/project/shared';

export interface CostsFinanceDetailsPopoverProps {
  detailValues: {
    // Vorheriges Budget // TODO
    previousBudget?: number | null;
    // Hauptauftrag
    mainContract?: number | null;
    // Nachtrag
    totalSupplement?: number | null;
    // Gesamtauftrag
    totalContract?: number | null;
    // Externe Prognose
    effectiveForecast?: number | null;
    // Aktuelles Budget
    totalBudget?: number | null;
  } | null;
}

export const CostsFinanceDetailsPopover = (props: CostsFinanceDetailsPopoverProps) => {
  const { t } = useTranslation()
  const {
    detailValues
  } = props
  return (
    <div className="bg-white w-72 font-light border border-gray-200 rounded-md rounded-tl-none shadow-lg overflow-hidden z-10 cursor-default">
      <div className="text-lg border-b-2 p-3 flex gap-2 items-center font-bold leading-none">
        <InformationCircleIcon className="h-5 w-5 -mt-0.5" />
        {t('projectCalculate.FinanceViewDetails.title')}
      </div>
      <div className="flex flex-col gap-1 pt-3 pb-4 pr-6 pl-10 text-xs w-full">
        {detailValues?.previousBudget && (
          <div className="flex gap-2 justify-between w-full">
            <span>{t('projectCalculate.FinanceViewPreviousBudget.label')}</span>
            <span className="font-bold"><FormattedCurrency amount={detailValues?.previousBudget} /></span>
          </div>
        )}
        <div className="flex gap-2 justify-between w-full">
          <span>{t('projectCalculate.FinanceViewMainContract.label')}</span>
          <span className="font-bold"><FormattedCurrency amount={detailValues?.mainContract} /></span>
        </div>
        <div className="flex gap-2 justify-between w-full">
          <span>{t('projectCalculate.headerTitleSupplement')}</span>
          <span className="font-bold"><FormattedCurrency amount={detailValues?.totalSupplement} /></span>
        </div>
        <div className="flex gap-2 justify-between w-full">
          <span>{t('projectCalculate.headerTitleTotalContract')}</span>
          <span className="font-bold"><FormattedCurrency amount={detailValues?.totalContract} /></span>
        </div>
        <div className="flex gap-2 justify-between w-full">
          <span>{t('projectCalculate.headerTitleExternalForecast')}</span>
          <span className="font-bold"><FormattedCurrency amount={detailValues?.effectiveForecast} /></span>
        </div>
        <div className="flex gap-2 justify-between w-full">
          <span>{t('projectCalculate.headerTitleCurrentBudget')}</span>
          <span className="font-bold"><FormattedCurrency amount={detailValues?.totalBudget} /></span>
        </div>
      </div>
    </div>
  )
}
