import React, { useMemo } from 'react';
import { ProjectCard, ProjectCardProps } from './ProjectCard';
import { Badge } from './Badges';
import { UploadBadge } from './UploadBadge';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface UploadProjectCardProps extends ProjectCardProps {
  logo?: string;
  logoAltText?: string;
  badgeLabel?: string;
  initials?: string;
}

export const UploadProjectCard = (props: UploadProjectCardProps) => {
  const { logo, logoAltText, badgeLabel, initials, ...projectCardProps } = props;

  const overlayImage = useMemo(() => {
    return (
      <div className="bg-white rounded p-2 overflow-hidden text-lg font-medium leading-none w-full h-full flex items-center justify-center">
        {logo ? <LazyLoadImage className="w-full h-full object-cover" src={logo} alt={logoAltText} /> : initials ?? ''}
      </div>
    );
  }, [logo, logoAltText, initials]);

  const overlayInfo = badgeLabel ? (
    <Badge text={badgeLabel} variant="custom" className="bg-emerald-500 text-white" />
  ) : (
    ''
  );

  return (
    <ProjectCard
      overlayImages={[overlayImage]}
      overlayInfo={overlayInfo}
      actionButton={<UploadBadge className="flex-none" />}
      {...projectCardProps}
    />
  );
};
