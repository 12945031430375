import {
  CostCatalogElementReadModel,
  EarningsCatalogElementReadModel,
  FinancingCatalogElementReadModel,
  RiskCatalogElementReadModel,
  TenantCatalogType,
  useApiDeleteCatalogElementMutation,
} from '@client/shared/api';
import { ConfirmModal, HintBox, LoadingIndicator, ModalOnCloseProps } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';

interface CatalogElementDeleteModalProps extends ModalOnCloseProps {
  catalogId: string,
  type: TenantCatalogType,
  catalogElement:
    | CostCatalogElementReadModel
    | FinancingCatalogElementReadModel
    | RiskCatalogElementReadModel
    | EarningsCatalogElementReadModel;
}

export const CatalogElementDeleteModal = ({ catalogId, type, catalogElement, onClose }: CatalogElementDeleteModalProps) => {
  const { t } = useTranslation();

  const [call, { isLoading }] = useApiDeleteCatalogElementMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    try {
      await safeMutation(
        call,
        {
          body: {
            catalogId: catalogId,
            elementId: catalogElement.id,
            type: type
          },
        },
        isLoading,
      );
      onClose(true);
    } catch {
      // left empty
    }
  };

  return (
    <ConfirmModal
      title={t('app.deleteTenantCatalogElement', { name: catalogElement.description })}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isLoading}
    >
      {isLoading && (
        <LoadingIndicator text={t('common.loading')} mode="overlay" />
      )}
      <HintBox hintType="warning">{t('app.deleteCatalogElementHint')}</HintBox>
    </ConfirmModal>
  );
};
