import { LedgerCustomIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { DoubleDataFeed } from './ProjectMetadata';

interface ProjectPlannedFeedProps {
  plannedCostsAndRisks: string;
  plannedEarnings: string;
  plannedCostsUnit: string;
}

export const ProjectPlannedFeed = (props: ProjectPlannedFeedProps) => {
  const { t } = useTranslation();
  return (
    <DoubleDataFeed
      icon={<LedgerCustomIcon />}
      leftTitle={t('project.plannedCostAndRisk')}
      leftValue={props.plannedCostsAndRisks}
      leftFooter={
        <>
          {props.plannedCostsUnit} <span className="font-bold">{' ' + t('project.bgfRoi')}</span>
        </>
      }
      rightTitle={t('project.plannedEarnings')}
      rightValue={props.plannedEarnings}
      rightFooter={<>&nbsp;</>}
    />
  );
};
