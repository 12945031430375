import { PlotReadModel } from '@client/shared/api';
import { DiggerIcon, ContextMenu, ContextMenuItem, RoadDottedIcon, MapMarkerDottedIcon, PencilIcon, TrashIcon, AddIcon } from '@client/shared/toolkit';
import { formatUnit, stopPropagation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { getPlotLabel } from '../../utils';

interface PlotRowProps {
  plot: PlotReadModel;
  depth: number;
  isReadOnly: boolean;
  canDelete: boolean;
  onClick: (plot: PlotReadModel) => void;
  onDelete: (plot: PlotReadModel) => void;
  onAddChild: (plot: PlotReadModel) => void;
  onEdit: (plot: PlotReadModel) => void;
  selectedPlot: PlotReadModel | undefined;
  openDashboard: string
}

export const PlotRow = ({
  plot,
  isReadOnly,
  canDelete,
  depth,
  onClick,
  onAddChild,
  onEdit,
  onDelete,
  openDashboard,
  selectedPlot,
}: PlotRowProps) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);

  const contextItems: ContextMenuItem[] = [
    {
      label: t('common.update'),
      subtitle: t('projectTaxonomy.plotUpdateMenuDescription'),
      icon: <PencilIcon />,
      onClick: () => onEdit(plot),
    },
    {
      label: t('projectTaxonomy.buildingAddChildAction'),
      subtitle: t('projectTaxonomy.buildingAddChildActionDescription'),
      icon: <AddIcon />,
      isDisabled: isReadOnly,
      onClick: () => onAddChild(plot),
    },
    {
      label: t('common.delete'),
      subtitle: t('projectTaxonomy.buildingDeleteActionDescription'),
      icon: <TrashIcon />,
      isDisabled: !canDelete,
      onClick: () => onDelete(plot),
    },
  ];

  return (
    <div
      className={classNames('text-slate-500 border-t border-t-white', {
        'bg-slate-100': depth === 0,
        'bg-slate-50': depth === 1,
        'bg-white text-zinc-600': depth > 1
      })}
    >
      <div
        className={classNames('relative h-16 flex cursor-pointer items-center transition-all w-full justify-between', {
          'border-l-8 border-l-green-900 border-opacity-70': openDashboard === plot.plotId,
          'border-l-8 border-transparent': openDashboard !== plot.plotId,
        })}
        onClick={() => onClick(plot)}
      >
        <div className="pl-2 flex w-1/3 flex-none pr-2">
          <div style={{ paddingRight: 2 * depth + 'rem' }} />
          <div className="w-6 flex justify-center items-center">
            {plot.plots.length > 0 && (
              <ChevronDownIcon
                className={classNames('w-6 h-6', { '-rotate-90 transform': !isExpanded })}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setIsExpanded(!isExpanded)
                }}
              />
            )}
          </div>
          <div className="px-4 flex-none flex">
            {plot.type === 'Plot' && <MapMarkerDottedIcon className="w-8 h-8" />}
            {plot.type === 'Infrastructure' && <RoadDottedIcon className="w-8 h-8" />}
            {plot.type === 'SpecialItem' && <DiggerIcon className="w-8 h-8" />}
          </div>
          <div className="flex flex-1 justify-between">
            <div className="flex flex-col flex-grow truncate">
              <div className="font-bold text-lg leading-none">{plot.plotName}</div>
              <div className="text-[15px] leading-tight">
                {t(getPlotLabel(plot))} • {formatUnit(plot.totalPlotSize.value, plot.totalPlotSize.unit)}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-none w-2/3 place-content-end flex gap-4 h-full pl-2 font-semibold">
          <div className="flex-none flex flex-col text-right justify-center w-[75px] overflow-hidden" />
          <div className="flex-1 grid grid-cols-3 gap-6 max-w-[600px]">
            <div className="flex items-center justify-end text-gray-900" />
            <div className="flex items-center justify-end text-gray-900">
              {!!plot.constructionSize && (
                <div className="leading-tight text-right">
                  {formatUnit(plot.constructionSize.value, plot.constructionSize.unit)}
                  <div className="text-[10px] font-normal text-right text-gray-500">
                    {t('projectTaxonomy.newPlotLabelConstructionSize')}
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center justify-end text-gray-900">
              {!!plot.totalPlotSize && (
                <div className="leading-tight text-right">
                  {formatUnit(plot.totalPlotSize.value, plot.totalPlotSize.unit)}
                  <div className="text-[10px] font-normal text-right text-gray-500">
                    {t('projectTaxonomy.newPlotLabelPlotSize')}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex w-[104px] flex-none">
            <div className="flex justify-center items-center w-8 ml-6" />
            <div
              className="px-3 flex items-center justify-center text-gray-500 hover:text-gray-800"
              onClick={stopPropagation}
            >
              <ContextMenu items={contextItems} className="w-6 h-6" />
            </div>
          </div>
        </div>
      </div>
      {isExpanded &&
        plot.plots?.map((subPlot) => (
          <PlotRow
            key={subPlot.plotId}
            depth={depth + 1}
            plot={subPlot}
            isReadOnly={isReadOnly}
            canDelete={canDelete}
            onClick={onClick}
            onAddChild={onAddChild}
            onDelete={onDelete}
            onEdit={onEdit}
            openDashboard={openDashboard}
            selectedPlot={selectedPlot}
          />
        ))}
    </div>
  );
};
