import { FinancingAnalysis, GeneralKeyPerformanceIndicators, SensitivityAnalysis } from '@client/project/shared';
import { useLoadedVariant, useLoadedVariantCosts, useLoadedVariantEarnings, useLoadedVariantFinance } from '@client/project/store';

export const ReportKpi = () => {
  const { data: variantData } = useLoadedVariant();
  const { data: variantCostsData } = useLoadedVariantCosts();
  const { data: variantEarningsData } = useLoadedVariantEarnings();
  const { data: variantFinanceData } = useLoadedVariantFinance();
  
  const calculationModel = variantData?.calculationModel;
  const costs = variantCostsData?.payload;
  const earnings = variantEarningsData?.payload;

  const totalEquity = variantFinanceData?.payload.totalEquity ?? 0;
  const totalProfit = calculationModel?.modelMetadata.totalProfit.value ?? 0;
  const netReturn = totalEquity === 0 ? 0 : totalProfit + totalEquity;
  const equityMultiplier = totalEquity === 0 ? 0 : (netReturn / totalEquity) * 100;

  const mainRentEarningsGroups = earnings?.earnings.find(x => x.group?.earningsType === 'RentRevenue')?.group;
  const averageMultiple = mainRentEarningsGroups?.effectiveMultiple ?? 0;
  const totalRent = averageMultiple === 0 ? 0 : (mainRentEarningsGroups?.effectiveValue ?? 0) / averageMultiple;

  const operationalResult = costs?.catalogElements
    .filter(x => x.code === '11')
    .reduce((previous, current) => previous = previous + (current.modelValues.effectiveValue ?? 0), 0) ?? 0;
  const totalInvestmentCosts = (calculationModel?.modelMetadata.totalCost.value ?? 0) - (operationalResult ?? 0);
  const mezzanineElements = variantFinanceData?.payload.financingGroups.filter(x => x.code === '32') ?? [];
  const financingElements = variantFinanceData?.payload.financingGroups.filter(x => x.code !== '32') ?? [];

  const irr = totalInvestmentCosts === 0 ? 0 : totalEquity / totalInvestmentCosts * 100;

  return (
    <>
      <GeneralKeyPerformanceIndicators
        className="order-2 lg2:order-1 xl:order-3 col-span-full lg2:col-span-1 xl:col-span-2 2xl:col-span-2 3xl:col-span-1"
        irr={irr}
        roi={equityMultiplier}
        totalEquity={totalEquity}
        totalProfit={totalProfit}
      />

      <div className="order-3 col-span-full xl:col-span-6 2xl:col-span-5 3xl:col-span-2 flex flex-col lg:flex-row gap-3 w-full">
        <FinancingAnalysis
          className="w-full lg:w-1/2"
          totalInvestmentCosts={totalInvestmentCosts}
          totalOperationalResult={totalInvestmentCosts + operationalResult}
          financingElements={financingElements}
          mezzanineElements={mezzanineElements}
        />

        <SensitivityAnalysis
          className="w-full lg:w-1/2"
          multiple={averageMultiple}
          totalRentEarnings={totalRent}
        />
      </div>
    </>
  );
}
