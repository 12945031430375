import { LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import React, { useEffect, useState } from 'react';
import {
  PlotReadModel,
  useApiGetPlotsByCalculationModelQuery
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { TaxonomyPlotDashboard } from './TaxonomyPlotDashboard';
import { flattenTaxonomyPlotTree } from '@client/project/shared';

interface TaxonomyPlotViewProps {
  isReadOnly: boolean;
  canDelete: boolean;
  setOpenDashboard: (id: string) => void
}

export const TaxonomyPlotView = (props: TaxonomyPlotViewProps) => {
  const { isReadOnly, canDelete, setOpenDashboard } = props;
  const { itemId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [plot, setPlot] = useState<PlotReadModel | undefined>(undefined);
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: plotData, isFetching, isError } = useApiGetPlotsByCalculationModelQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: loadedVariantId == null },
  );

  if (!itemId) {
    navigate(ROUTES_CONFIG.PROJECT_TAXONOMY.name);
  }

  useEffect(() => {
    if (itemId && typeof plotData !== 'undefined' && plotData !== null) {
      if (plotData.plotId === itemId) {
        setPlot(plotData);
      } else {
        const flattenedTree = flattenTaxonomyPlotTree(plotData)
        const foundItem = flattenedTree.find((child) => child.plotItem.plotId === itemId);
        if (!foundItem) {
          navigate(ROUTES_CONFIG.PROJECT_TAXONOMY.name);
          return
        }
        setPlot(foundItem.plotItem)
      }
      setOpenDashboard(itemId)
      setIsOpen(true);
    }

    if (isError) {
      navigate(ROUTES_CONFIG.PROJECT_TAXONOMY.name);
      setOpenDashboard('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotData, isError]);
  const handleCloseSlideOver = () => {
    setIsOpen(false);
  }

  return (
    <SlideOver
      isOpen={isOpen}
      onClose={handleCloseSlideOver}
      variant="x-large"
      confirmBeforeClose={false}
      onAfterLeave={() => {
        navigate(ROUTES_CONFIG.PROJECT_TAXONOMY.name);
        setOpenDashboard('');
      }}
    >
      {isFetching && <LoadingIndicator text={t('projectTaxonomy.loadingPlots')} />}
      <TaxonomyPlotDashboard
        plot={plot}
        isReadOnly={isReadOnly}
        canDelete={canDelete}
        onClose={handleCloseSlideOver}
      />
    </SlideOver>
  );
};
