import { CalculationTarget } from '@client/shared/api';
import { i18n } from '@client/shared/utilities';

export const getCalculationTargetFactorLabel = (
  calculation: CalculationTarget,
  unitSystem: string
): string => {
  switch (calculation) {
    case 'Amount':
      return i18n.t('projectRent.calculationTargetFactor.Amount');
    case 'RentalSpace':
    case 'CommonSpace':
      return `${i18n.t('projectRent.calculationTargetFactor.CommonSpace')} ${unitSystem === 'Metric' ? 'm²' : 'ft²'}`;
    case 'Value':
      return i18n.t('projectRent.calculationTargetFactor.Value');
    default:
      return '';
  }
};
