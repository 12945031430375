import { HintBox, ModalOnCloseProps, ConfirmModal } from '@client/shared/toolkit';
import { useApiPostApplyCostElementForecastAsBudgetMutation } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import { useLoadedProjectId } from '@client/project/store';

interface CostElementTakeOverForecastModalProps extends ModalOnCloseProps {
  elementId?: string;
  variantId?: string;
  children?: boolean
}

export const CostElementTakeOverForecastModal = ({ elementId, variantId, onClose, children = false }: CostElementTakeOverForecastModalProps) => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();
  const [call, { isLoading }] = useApiPostApplyCostElementForecastAsBudgetMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleConfirm = async () => {
    if (elementId && variantId) {
      try {
        await safeMutation(
          call,
          {
            projectId: projectId ?? 'unset',
            calculationModelId: variantId,
            costElementId: elementId,
            body: {
              applyRecursivly: children
            }
          },
          isLoading
        );

        onClose(true);
      } catch (e) {
        console.log(e)
      }

      onClose(false);
    }
  };

  return (
    <ConfirmModal
      title={children ? t('projectCalculate.costElementTakerOverAllForecastModalTitle') : t('projectCalculate.costElementTakerOverForecastModalTitle')}
      onConfirm={handleConfirm}
      onClose={handleOnClose}
      confirmText={t('projectCalculate.rowMenu.takeoverForecastTitle')}
      isLoading={false}
    >
      <HintBox hintType="warning">{t('projectCalculate.costElementTakeOverForecastModalHint')}</HintBox>
    </ConfirmModal>
  );
};
