import { DecoratedElement } from '../hooks';
/**
 * Hierarchically filters an array of DecoratedCostElement[]
 * => If "codes" is null, no filter is applied.
 * Otherwise, the result list will only contain cost elements that are in the filter
 */
export function filterElementsByCodes<T>(elements: DecoratedElement<T>[], codes?: string[]): DecoratedElement<T>[] {
  if (codes == null || !codes.length) return elements;

  return elements.filter((element) => {
    if (!codes.includes(element.categoryId)) return false;
    element.children = filterElementsByCodes(element.children, codes);
    return element;
  });
}
