import {
  Button,
  DocumentPositionBoundingBoxReadModel,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
  SlideOverTabOptions,
  SlideOverWithTabs
} from '@client/shared/toolkit';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InvoiceDocumentFileDataRef,
  InvoiceDocumentViewer
} from '..';
import {
  AiEvalValuesReadModel,
  ContractReadModel,
  InvoiceReadModel,
  useApiGetAiEvalDocumentPdfFileQuery,
  useApiGetAiEvalResultQuery
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';

export interface InvoiceDocumentReviewProps extends SlideOverOnCloseProps {
  contract?: ContractReadModel | null
  invoice?: InvoiceReadModel,
}

export const InvoiceDocumentReview = (props: InvoiceDocumentReviewProps) => {
  const {
    onClose,
    contract,
    invoice
  } = props
  const { t } = useTranslation();
  const { documentId } = useParams()
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [pdfFile, setPdfFile] = useState<string | null>(null);
  const [fileData, setFileData] = useState<AiEvalValuesReadModel | null | undefined>(null);
  const [boxes] = useState<(DocumentPositionBoundingBoxReadModel | null)[]>([]);
  const [imageWidth] = useState(0);
  const [imageHeight] = useState(0);
  const [allData] = useState<{
    boxes: number[][][]
    pages: number[]
    texts: string[][]
  } | null>(null)

  const invoiceDocumentFileDataRef = useRef<InvoiceDocumentFileDataRef>(null);
  const [isFormValid, setIsFormValid] = useState(true)

  const createInvoice = () => {
    setIsFormValid(false)
    invoiceDocumentFileDataRef.current?.createInvoice();
  };

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  if (!documentId) {
    navigate(ROUTES_CONFIG.PROJECT_CONTROL.name)
  }

  const { data: fetchedFile, isFetching: isFetchingFile } = useApiGetAiEvalDocumentPdfFileQuery(
    {
      documentId: documentId ?? '',
      projectId: loadedProjectId ?? 'unset',
    },
    {
      skip: !documentId || !loadedProjectId
    })

  const { data: processedData, isFetching: isFetchingProcessedData, isError } = useApiGetAiEvalResultQuery({
    documentId: documentId ?? '',
    projectId: loadedProjectId ?? 'unset',
    calculationModelId: loadedVariantId ?? '',
  }, {
    skip: !documentId || !loadedProjectId
  })

  useEffect(() => {
    if (fetchedFile) {
      setPdfFile(fetchedFile)
    }
  }, [fetchedFile]);

  useEffect(() => {
    if (isError) {
      navigate(ROUTES_CONFIG.PROJECT_CONTROL.name);
    }
    if (processedData?.result) {
      setFileData(processedData.result)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedData, isError]);

  const tabOptions: SlideOverTabOptions[] = useMemo(() => {
    return [{
      header: documentId ? t('projectControl.invoiceTabAudit') : t('common.upload'),
      name: 'audit',
      panel: (
        <InvoiceDocumentViewer
          invoiceDocument={processedData}
          fileData={fileData}
          file={file}
          pdfFile={pdfFile}
          setFile={setFile}
          boxes={boxes}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
          allBoundingBoxes={allData}
          contract={contract}
          onUpload={() => onClose(false)}
          invoice={invoice}
          loadedProjectId={loadedProjectId ?? ''}
          invoiceDocumentFileDataRef={invoiceDocumentFileDataRef}
          setIsFormValid={setIsFormValid}
          onClose={() => onClose(true)}
        />
      ),
    }]
  }, [documentId, t, file, pdfFile, boxes, imageWidth, imageHeight, allData, contract, onClose, invoice, loadedProjectId, fileData, processedData]);

  const slideOverTitle = useMemo(() => {
    if (!fileData) {
      return t('projectControl.uploadInvoiceSubTitle')
    }
    return (
      <>
        <div className="flex text-2xl">
          {fileData.contractorName && (
            <span className="whitespace-nowrap truncate">
              {fileData.contractorName}
            </span>
          )}
        </div>
        {(fileData.invoiceDetails.number || fileData.contractorName) && (
          <div className="text-gray-500 text-[13px]">
            {fileData.invoiceDetails.number && <span className="font-bold">{fileData.invoiceDetails.number}&nbsp;</span>}
            {fileData.customerName && (
              <span className="whitespace-nowrap truncate">
                &nbsp;•&nbsp;{fileData.customerName}
              </span>
            )}
          </div>
        )}
      </>
    );
  }, [fileData, t])

  return (
    <SlideOverWithTabs
      tabOptions={tabOptions}
      onClose={onClose}
      title={slideOverTitle}
      subtitle={!documentId ? t('projectControl.createNewInvoiceDocument') : ''}
    >
      {(isFetchingFile || isFetchingProcessedData) && (<LoadingIndicator text={t('projectControl.fetchingInvoiceLoadingIndicator')} mode="overlay-window" />)}
      <SlideOver.Controls className="items-center">
        {!isFormValid && (
          <span className="text-sm italic mt-2 text-red-700 mr-4">{t('projectControl.createInvoiceInvalidFormMessage')}</span>
        )}
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={createInvoice}>
          {t('projectControl.createInvoice')}
        </Button>
      </SlideOver.Controls>
    </SlideOverWithTabs>
  );
};
