import { LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import { TaxonomyDashboard } from './TaxonomyDashboard';
import React, { useEffect, useState } from 'react';
import { TaxonomyReadModel, useApiGetTaxonomyQuery } from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { flattenTaxonomyTree } from '@client/project/shared';

interface TaxonomyViewProps {
  isReadOnly: boolean;
  setOpenDashboard: (id: string) => void
}

export const TaxonomyView = (props: TaxonomyViewProps) => {
  const { isReadOnly, setOpenDashboard } = props;
  const { itemId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [taxonomyData, setTaxonomyData] = useState<TaxonomyReadModel | undefined>(undefined);
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const {
    data: buildingsData,
    isFetching,
    isError,
  } = useApiGetTaxonomyQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: loadedVariantId == null },
  );

  useEffect(() => {
    if (!itemId) {
      navigate(ROUTES_CONFIG.PROJECT_TAXONOMY.name);
    }
    if (itemId && typeof buildingsData !== 'undefined' && buildingsData !== null) {
      if (buildingsData.itemId === itemId) {
        setTaxonomyData(buildingsData);
      } else {
        const flattenedTree = flattenTaxonomyTree(buildingsData)
        // search for item id in children
        const foundItem = flattenedTree.find((child) => child.taxonomyItem.itemId === itemId);
        if (!foundItem) {
          navigate(ROUTES_CONFIG.PROJECT_TAXONOMY.name);
          return
        }
        setTaxonomyData(foundItem.taxonomyItem);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingsData, itemId]);

  useEffect(() => {
    if (taxonomyData && itemId) {
      setOpenDashboard(itemId)
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxonomyData]);

  useEffect(() => {
    if (isError) {
      navigate(ROUTES_CONFIG.PROJECT_TAXONOMY.name);
      setOpenDashboard('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const handleCloseSlideOver = () => {
    setIsOpen(false);
  }

  return (
    <SlideOver
      isOpen={isOpen}
      onClose={handleCloseSlideOver}
      variant="x-large"
      confirmBeforeClose={false}
      onAfterLeave={() => {
        navigate(ROUTES_CONFIG.PROJECT_TAXONOMY.name);
        setOpenDashboard('');
      }}
    >
      {isFetching && <LoadingIndicator text={t('projectTaxonomy.loadBuilding')} />}
      <TaxonomyDashboard
        item={taxonomyData}
        onClose={handleCloseSlideOver}
        isReadOnly={isReadOnly}
      />
    </SlideOver>
  );
};
