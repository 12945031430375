import {
  AddressIcon,
  CountryIcon,
  HighwaySignIcon,
  MapMarkerIcon,
  PostCodeIcon,
  RoadIcon
} from '../../icons';
import { countryCodes } from '@client/shared/utilities';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SlideOverTitle } from '../SlideOverTitle';
import { FormField } from '../FormField';
import { TextInput } from '../TextInput';
import { ComboSelect } from '../ComboSelect';

type AddressFormField = 'street' | 'number' | 'supplement' | 'postalCode' | 'city' | 'state' | 'country' | 'suffix'
interface AddressFormFieldsProps {
  fields?: AddressFormField[]
}
export const AddressFormFields = (props: AddressFormFieldsProps) => {
  const {
    fields = ['street', 'number' , 'supplement' , 'postalCode' , 'city' , 'country']
  } = props
  const { t } = useTranslation()
  return (
    <>
      <SlideOverTitle title={t('common.address')} />
      {fields.includes('street') && (
        <FormField name="street">
          {(control) => <TextInput icon={<RoadIcon />} label={t('common.addressStreet')} {...control} />}
        </FormField>
      )}
      {(fields.includes('number') || fields.includes('suffix') || fields.includes('supplement')) && (
        <div className="flex">
          {fields.includes('number') && (
            <FormField name="number">
              {(control) => <TextInput className="w-1/3 flex-none" label={t('common.addressHouseNumber')} {...control} />}
            </FormField>
          )}
          {fields.includes('suffix') && (
            <FormField name="suffix">
              {(control) => <TextInput icon={<AddressIcon />} className="w-2/3 flex-none" label={t('common.addressSuffix')} {...control} />}
            </FormField>
          )}
          {fields.includes('supplement') && (
            <FormField name="supplement">
              {(control) => <TextInput className="w-2/3 flex-none" icon={<AddressIcon />} label={t('common.addressSuffix')} {...control} />}
            </FormField>
          )}
        </div>
      )}
      {(fields.includes('postalCode') || fields.includes('city')) && (
        <div className="flex">
          {fields.includes('postalCode') && (
            <FormField name="postalCode">
              {(control) => <TextInput className="flex-none w-1/3" icon={<PostCodeIcon />} label={t('common.addressPostalCode')} {...control} />}
            </FormField>
          )}
          {fields.includes('city') && (
            <FormField name="city">{(control) => <TextInput className="flex-none w-2/3" icon={<MapMarkerIcon />} label={t('common.addressCity')} {...control} />}</FormField>
          )}
        </div>
      )}
      {fields.includes('state') && (
        <FormField name="state">
          {(control) => <TextInput icon={<HighwaySignIcon />} label={t('common.addressState')} {...control} />}
        </FormField>
      )}
      {fields.includes('country') && (
        <FormField name="country">
          {(control) => (
            <ComboSelect icon={<CountryIcon />} label={t('common.addressCountry')} options={countryCodes.getCountries()} {...control} />
          )}
        </FormField>
      )}
    </>
  )
}
