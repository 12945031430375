import { ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface HintBoxProps {
  title?: string
  hintType?: 'info' | 'warning' | 'danger';
  className?: string;
}

const hintConfiguration = {
  info: {
    classes: 'bg-blue-100',
    title: 'common.hint',
    icon: <InformationCircleIcon className="h-6 w-6" />,
  },
  warning: {
    classes: 'bg-yellow-100',
    title: 'common.warning',
    icon: <ExclamationCircleIcon className="h-6 w-6" />,
  },
  danger: {
    classes: 'bg-red-100',
    title: 'common.danger',
    icon: <ExclamationTriangleIcon className="h-6 w-6" />,
  },
};

export const HintBox = ({ children, className, hintType = 'info', title }: PropsWithChildren<HintBoxProps>) => {
  const { t } = useTranslation();
  const config = hintConfiguration[hintType];

  return (
    <div
      className={classNames(
        'w-full border-b-2 p-4 shadow-sm flex flex-col whitespace-pre-wrap',
        config.classes,
        className
      )}
    >
      <div className="flex items-center">
        {config.icon}
        <span className="font-bold ml-2">{title ? title : t(config.title)}</span>
      </div>
      {children && (<div className="mt-2 text-sm text-left">{children}</div>)}
    </div>
  );
};
