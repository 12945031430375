import { PropsWithChildren, Suspense } from 'react';
import { LoadingIndicator } from './LoadingIndicator';

export const LazyComponent = ({ children }: PropsWithChildren) => {
  return (
    <Suspense fallback={<LoadingIndicator className="p-4" />}>
      {children}
    </Suspense>
  );
};
