import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ProtectedRoute, ROUTES_CONFIG } from '@client/shared/permissions';
import React, { useCallback, useMemo, useState } from 'react';
import {
  AddIcon,
  BoxIcon,
  CheckAllIcon,
  CheckmarkIcon,
  ClusterListInnerListItemContent,
  ClusterListItemContent,
  ContextMenu,
  ContextMenuItem,
  EyeIcon,
  FloatingActionButton,
  List,
  ListItem,
  ListItemProps,
  ListSortHeaderItem,
  LoadingIndicator,
  Modal,
  TrashIcon,
} from '@client/shared/toolkit';
import { RequirementCreateWizard } from './RequirementCreateWizard';
import { useTranslation } from 'react-i18next';
import { RequirementView } from './RequirementView';
import { RequirementDeleteModal } from './RequirementDeleteModal';
import { RequirementShortReadModel, useApiGetClusterItemsQuery } from '@client/shared/api';
import { useLoadedPortfolioId } from '@client/portfolio/store';
import { formatDateOnly, sortByProperty, SortHeaderType } from '@client/shared/utilities';
import {
  REQUIREMENT_REPORTERS,
  getClusterItemType,
  getRequirementStatusBadge,
  getClusterItemIdsForUser, checkIsReporter,
} from '@client/portfolio/shared';
import { useUi } from '@client/shared/store';

export const RequirementsContainer = ({ searchValue }: { searchValue?: string; }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loadedPortfolioId = useLoadedPortfolioId();
  const ui = useUi();

  const [openModal, setOpenModal] = useState<null | 'New' | 'Delete'>(null);
  const [selectedRequirement, setSelectedRequirement] = useState<RequirementShortReadModel | null>(null);
  const [selectedClusterItemId, setSelectedClusterItemId] = useState<string>('');

  const { data, isFetching } = useApiGetClusterItemsQuery(
    {
      portfolioId: loadedPortfolioId ?? '',
    },
    {
      skip: !loadedPortfolioId,
    },
  );

  const defaultSortHeader: SortHeaderType[] = useMemo(() => {
    return [
      {
        asc: null,
        label: t('portfolio.projecting.requirementEdit.title'),
        type: 'string',
        value: 'name',
      },
      {
        asc: null,
        label: t('projectTasks.sortHeaderStatus'),
        type: 'string',
        value: 'state',
      },
      {
        asc: null,
        label: t('portfolio.projecting.submitNotificationWizard.deadline'),
        type: 'date',
        value: 'deadline',
      },
    ];
  }, [t]);
  const [sortHeader, setSortHeader] = useState<SortHeaderType[]>(defaultSortHeader);

  const onHandleSort = useCallback(
    (index: number) => {
      const currentSortValues = [...sortHeader];
      const update = currentSortValues[index];
      update.asc = update.asc === null ? true : !update.asc;
      currentSortValues.forEach((val, i) => {
        if (i !== index) {
          val.asc = null;
        }
      });
      currentSortValues[index] = update;
      setSortHeader(currentSortValues);
    },
    [sortHeader],
  );

  const contextItems: ContextMenuItem[] = [
    {
      label: t('portfolio.projecting.requirementNew.title'),
      subtitle: t('portfolio.projecting.requirementNew.subtitle'),
      isDisabled: false,
      icon: <AddIcon />,
      onClick: () => {
        setOpenModal('New');
      },
    },
  ];

  const openRequirement = useCallback(
    (clusterItemId: string, requirementId: string) => {
      if (loadedPortfolioId) {
        navigate(
          ROUTES_CONFIG.PORTFOLIO_PROJECTING_REQUIREMENT_VIEW.path
            .replace(':id', loadedPortfolioId)
            .replace(':clusterItemId', clusterItemId)
            .replace(':requirementId', requirementId),
        );
      }
    },
    [navigate, loadedPortfolioId],
  );

  const currentUserClusterItems = useMemo(() => {
    if (data?.clusterItems && ui.appUser.userId) {
      const userClusterItemIds = getClusterItemIdsForUser(ui.appUser.userId);
      return data.clusterItems.filter((clusterItem) => {
        return userClusterItemIds.includes(clusterItem.id);
      });
    }
    return [];
  }, [data?.clusterItems, ui.appUser.userId]);

  const requirementItems: ListItemProps[] = useMemo(() => {
    const listItems: ListItemProps[] = [];
    if (currentUserClusterItems) {
      currentUserClusterItems.forEach((clusterItem) => {
        const listChildren: ListItemProps[] = [];

        let clusterItemInSearch = true;
        const searchFor = searchValue?.toLowerCase();
        if (searchFor) {
          clusterItemInSearch = clusterItem.name.toLowerCase().includes(searchFor) || clusterItem.type.toLowerCase().includes(searchFor);
        }

        if (clusterItem.requirements.length) {
          let sortedRequirements = [...clusterItem.requirements];
          const sortBy = sortHeader.find((item) => item.asc !== null);
          if (sortBy) {
            if (sortBy.type !== 'custom') {
              sortedRequirements = sortByProperty(sortedRequirements, sortBy);
            }
          }
          if (searchFor && !clusterItemInSearch) {
            sortedRequirements = sortedRequirements.filter((req) => {
              return req.code.toLowerCase().includes(searchFor) || req.name.toLowerCase().includes(searchFor);
            });
            if (sortedRequirements.length) {
              clusterItemInSearch = true;
            }
          }

          sortedRequirements.forEach((requirement) => {
            const contextMenuItems = [
              {
                label: t('portfolio.property.viewRequirement'),
                subtitle: t('portfolio.property.viewRequirementSubtitle'),
                icon: <EyeIcon />,
                onClick: () => openRequirement(clusterItem.id, requirement.id),
              },
            ];
            if (
              requirement.state === 'Created' && checkIsReporter(requirement.reportedById, requirement.reportedByDeputyId, ui.appUser.userId)
            ) {
              contextMenuItems.push({
                label: t('portfolio.property.deleteRequirement'),
                subtitle: t('portfolio.property.deleteRequirementSubtitle'),
                icon: <TrashIcon />,
                onClick: () => {
                  setSelectedClusterItemId(clusterItem.id);
                  setSelectedRequirement(requirement);
                  setOpenModal('Delete');
                },
              });
            }

            listChildren.push({
              borderColor: 'bg-secondary',
              onClick: () => openRequirement(clusterItem.id, requirement.id),
              children: (
                <ClusterListItemContent
                  icon={<CheckmarkIcon />}
                  title={requirement.name}
                  subtitle={requirement.code}
                  theme="requirement"
                  searchValue={searchValue}
                >
                  <ClusterListInnerListItemContent
                    date={requirement.deadline ? formatDateOnly(new Date(requirement.deadline)) : undefined}
                    status={getRequirementStatusBadge(requirement.state)}
                  />
                </ClusterListItemContent>
              ),
              additionalContent: (
                <div className="hidden md:flex flex-col justify-center py-2 px-4">
                  <ContextMenu items={contextMenuItems} />
                </div>
              ),
            });
          });
        }

        if (clusterItemInSearch) {
          listItems.push({
            id: clusterItem.id,
            borderColor: 'bg-secondary',
            children: (
              <ClusterListItemContent
                icon={<CheckAllIcon />}
                title={clusterItem.name}
                subtitle={getClusterItemType(clusterItem.type)}
                parent={true}
                theme="requirement"
                searchValue={searchValue}
              />
            ),
            listChildren: listChildren,
            defaultOpen: true,
          });
        }
      });
    }
    return listItems;
  }, [currentUserClusterItems, t, openRequirement, sortHeader, ui.appUser.userId, searchValue]);

  const requirementItemsLength = useMemo(() => {
    let length = 0;
    requirementItems.forEach((item) => {
      length += item.listChildren?.length ?? 0;
    });
    return length.toString();
  }, [requirementItems]);

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  const isReporter = useMemo(() => {
    return REQUIREMENT_REPORTERS.find((user) => user.id === ui.appUser.userId);
  }, [ui.appUser.userId]);

  return (
    <>
      {isFetching && (
        <LoadingIndicator text={t('portfolio.projecting.fetchingRequirementsLoadingIndicator')} mode="overlay" />
      )}
      <List
        icon={<BoxIcon className="w-6" />}
        listTitle={t('portfolio.projecting.requirement')}
        items={requirementItems}
        showPagination={false}
        showIndentMarker
        totalNumber={requirementItemsLength}
        sortHeader={
          requirementItems.length > 0 ? (
            <RequirementsSortHeader onHandleSort={onHandleSort} sortHeader={sortHeader} />
          ) : null
        }
        emptyMessage={t('portfolio.projecting.noRequirementsMessage')}
      />
      <List
        className="mt-10"
        icon={<BoxIcon className="w-6" />}
        listTitle={t('portfolio.projecting.conception')}
        items={[]}
        showPagination={false}
        emptyMessage={t('portfolio.projecting.noConceptsMessage')}
      />
      <List
        className="mt-10"
        icon={<BoxIcon className="w-6" />}
        listTitle={t('portfolio.projecting.guidingDocuments')}
        items={[]}
        showPagination={false}
        emptyMessage={t('portfolio.projecting.noDocumentsMessage')}
      />
      <List
        className="mt-10"
        icon={<BoxIcon className="w-6" />}
        listTitle={t('portfolio.projecting.projects')}
        items={[]}
        showPagination={false}
        emptyMessage={t('portfolio.projecting.noProjectsMessage')}
      />
      <List
        className="mt-10"
        icon={<BoxIcon className="w-6" />}
        listTitle={t('portfolio.projecting.completed')}
        items={[]}
        showPagination={false}
        emptyMessage={t('portfolio.projecting.noCompletedProjectsMessage')}
      />
      {isReporter && <FloatingActionButton menuItems={contextItems} />}

      <Modal isOpen={openModal === 'New'} onClose={handleCloseModal}>
        <RequirementCreateWizard onClose={handleCloseModal} />
      </Modal>
      <Modal
        isOpen={openModal === 'Delete'}
        onClose={handleCloseModal}
        onAfterLeave={() => {
          setSelectedRequirement(null);
          setSelectedClusterItemId('');
        }}
      >
        {selectedRequirement && (
          <RequirementDeleteModal
            onClose={handleCloseModal}
            requirement={selectedRequirement}
            clusterItemId={selectedClusterItemId}
          />
        )}
      </Modal>

      <Routes>
        <Route
          path={ROUTES_CONFIG.PORTFOLIO_PROJECTING_REQUIREMENT_VIEW.name}
          element={
            <ProtectedRoute routeConfig={ROUTES_CONFIG.PORTFOLIO_PROJECTING_REQUIREMENT_VIEW}>
              <RequirementView />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </>
  );
};

interface RequirementsSortHeaderProps {
  onHandleSort: (index: number) => void;
  sortHeader: SortHeaderType[];
}

export const RequirementsSortHeader = (props: RequirementsSortHeaderProps) => {
  const { onHandleSort, sortHeader } = props;
  return (
    <ListItem
      className="hidden sm:flex"
      borderColor="bg-transparent"
      bgColor=""
      shadow={false}
      padding={false}
      as="div"
    >
      <div className="flex-1 flex gap-2 justify-between flex-wrap lg:flex-nowrap lg:justify-end items-center w-full lg:w-auto">
        <div className="flex items-center gap-4 overflow-hidden w-full lg:w-[300px] pl-9">
          <div className="w-8" />
          <div>
            <ListSortHeaderItem
              label={sortHeader[0].label}
              asc={sortHeader[0].asc}
              onClick={() => onHandleSort(0)}
              alignRight={false}
            />
          </div>
        </div>
        <div className="flex justify-between items-center flex-1 min-w-[240px] pl-10">
          <div className="flex-none flex items-center">
            <ListSortHeaderItem
              label={sortHeader[1].label}
              asc={sortHeader[1].asc}
              onClick={() => onHandleSort(1)}
              alignRight
            />
          </div>
          <div className="flex-1 items-end flex pl-3.5 gap-2 justify-end">
            <ListSortHeaderItem
              label={sortHeader[2].label}
              asc={sortHeader[2].asc}
              onClick={() => onHandleSort(2)}
              alignRight
            />
          </div>
          <div className="flex-none w-10" />
        </div>
      </div>
    </ListItem>
  );
};
