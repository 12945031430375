import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import cn from 'classnames';
import { ContextMenu, ContextMenuItem } from '.';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export interface ProjectCardProps extends PropsWithChildren {
  className?: string;
  thumbnail?: string;
  thumbnailAlt?: string;
  passDataCy?: string;
  title?: string | ReactNode;
  subTitle?: string | ReactNode;
  contextItems?: ContextMenuItem[];
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  overlayImages?: (ReactNode | string)[];
  overlayInfo?: ReactNode | string;
  actionButton?: ReactNode | string;
  overlayIcons?: ReactElement[];
}
export const ProjectCard = (props: ProjectCardProps) => {
  const {
    className,
    thumbnail,
    thumbnailAlt,
    passDataCy,
    title,
    subTitle,
    contextItems,
    onClick,
    onMouseLeave,
    onMouseOver,
    children,
    overlayImages,
    overlayInfo,
    actionButton,
    overlayIcons,
  } = props;

  return (
    <div className={cn('relative px-5 pt-5 pb-7', className)}>
      <div
        className={cn('relative h-full w-full')}
        onMouseOver={() => {
          if (onMouseOver) {
            onMouseOver();
          }
        }}
        onMouseLeave={() => {
          if (onMouseLeave) {
            onMouseLeave();
          }
        }}
        onClick={onClick}
        data-cy={passDataCy}
      >
        {children}
        <div className="w-full h-full flex flex-col relative z-10 rounded-md shadow cursor-pointer bg-white overflow-hidden">
          <div className="relative w-full h-60 overflow-hidden scale-100">
            <div className="rounded-none w-full h-full bg-white transition-transform duration-300 transform hover:scale-105">
              <LazyLoadImage className="w-full h-full object-cover" alt={thumbnailAlt} src={thumbnail} />
            </div>
            {overlayImages && overlayImages.length > 0 && (
              <div className="absolute top-5 left-5 flex w-full gap-2">
                {overlayImages.map((image, i) => (
                <div className="bg-white rounded-full h-12 w-12 flex items-center justify-center" key={`project-card-overlay-image-${i}`}>
                  {image}
                </div>
                ))}
              </div>
            )}
            {overlayInfo && (
              <div className="absolute top-5 right-5 flex items-center justify-center">{overlayInfo}</div>
            )}
          </div>
          <div className="p-4 h-20 flex items-center justify-between relative">
            {overlayIcons && overlayIcons.length > 0 && (
              <div className="absolute -top-8 right-5 flex items-center justify-center z-10 gap-2">
                {overlayIcons.map((icon, i) => (
                  <div
                    key={`project-card-overlay-icon-${i}`}
                    className="w-11 h-11 bg-slate-200 flex items-center justify-center rounded-lg"
                  >
                    {React.cloneElement(icon, { className: 'w-4 text-white' })}
                  </div>
                ))}
              </div>
            )}
            <div className="min-w-0 flex w-full items-center">
              <div className="flex-grow truncate">
                <div className="font-medium text-2xl truncate mr-2 relative" title={title as string}>
                  <span aria-hidden="true" className="absolute top-0 right-0 left-0 bottom-20" />
                  {title}
                  <span aria-hidden="true" className="absolute top-0 right-12 left-0 bottom-0 fill-slate-500" />
                </div>
                <div className="mt-1 truncate mr-8 text-[15px]">{subTitle ?? '-'}</div>
              </div>
              {contextItems && contextItems.length > 0 && (
                <div
                  className="flex items-center justify-center text-gray-500 hover:text-gray-800"
                  onClick={(event) => {
                    event.stopPropagation();
                    return false;
                  }}
                >
                  <ContextMenu items={contextItems} className="w-6 h-6" />
                </div>
              )}
              {actionButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
