import { DatevAuthState } from '../datevAuthSlice';

export const LOCAL_STORAGE_KEY_DATEV_AUTH_DATA = 'datev_auth';
export const updateDatevAuthData = (data: DatevAuthState) => {
  const storedData = localStorage.getItem(LOCAL_STORAGE_KEY_DATEV_AUTH_DATA);
  const parsedStorage = storedData ? JSON.parse(storedData) : [];

  const newData: DatevAuthState = {
    datevTenantAuthData: data.datevTenantAuthData ?? parsedStorage?.datevTenantAuthData,
    datevClientId: data.datevClientId ?? parsedStorage?.datevClientId,
    datevClientAuthData: data.datevClientAuthData ?? parsedStorage?.datevClientAuthData,
    datevUpdateData: data.datevUpdateData ?? parsedStorage?.datevUpdateData,
    datevWizardType: data.datevWizardType ?? parsedStorage?.datevWizardType,
    datevApiAccessId: data.datevApiAccessId ?? parsedStorage?.datevApiAccessId,
  };

  localStorage.setItem(LOCAL_STORAGE_KEY_DATEV_AUTH_DATA, JSON.stringify(newData));
};
