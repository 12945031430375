import { useTranslation } from 'react-i18next';
import {
  BuildingIcon,
  ContextMenuItem,
  FloatingActionButton,
  LoadingIndicator,
  Modal,
  SlideOver,
  SortDownIcon,
  AddIcon,
  ProtectIcon,
  InitiateMoneyTransferIcon,
  DepositIcon,
  SkyscrapersDottedIcon,
  ContractMenuIcon,
  ControlMenuIcon
} from '@client/shared/toolkit';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { CreateCustomFieldSlideOver } from './CreateCustomFieldSlideOver';
import { useApiGetUserDefinedFieldsDefinitionQuery, UserDefinedFieldDefinitionReadModel } from '@client/shared/api';
import { CustomFieldsListGroup } from './CustomFieldsListGroup';
import { CustomFieldDeleteModal } from './CustomFieldDeleteModal';
import cn from 'classnames';
import { includesSearchValue } from '@client/shared/utilities';
import { SettingsHeader } from '../SettingsHeader';

export type UserDefinedFieldDefinitionGroup = {
  items: UserDefinedFieldDefinitionReadModel[];
  title: string;
  icon?: ReactNode;
};

type UserDefinedFieldDefinitionGroups = {
  [key: string]: UserDefinedFieldDefinitionGroup;
};

export const CustomFields = () => {
  const { t } = useTranslation();
  const [isCreateCustomFieldSlideOverOpen, setIsCreateCustomFieldSlideOverOpen] = useState<boolean>(false);
  const { data: responseData, isFetching } = useApiGetUserDefinedFieldsDefinitionQuery();

  const [customFields, setCustomFields] = useState<UserDefinedFieldDefinitionReadModel[]>([]);
  const [selectedItem, setSelectedItem] = useState<UserDefinedFieldDefinitionReadModel | null>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isSortedByFieldNameAsc, setIsSortedByFieldNameAsc] = useState(true);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (responseData?.userDefinedFieldsDefinition) {
      setCustomFields(responseData.userDefinedFieldsDefinition);
    }
  }, [responseData]);

  const contextItems: ContextMenuItem[] = [
    {
      label: t('app.settingsCreateCustomField'),
      subtitle: t('app.settingsCreateCustomFieldSubtitle'),
      icon: <AddIcon />,
      onClick: () => {
        setSelectedItem(null);
        setIsCreateCustomFieldSlideOverOpen(true);
      },
    },
  ];

  const sortedFields = useMemo(() => {
    let sortedFields = [...customFields];
    if (sortedFields.length) {
      sortedFields.sort(function (a, b) {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (isSortedByFieldNameAsc) {
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        } else {
          return nameB < nameA ? -1 : nameB > nameA ? 1 : 0;
        }
      });
    }
    if (searchText) {
      sortedFields = sortedFields.filter((field) => {
        return includesSearchValue(field.name, searchText) || includesSearchValue(field.elementType, searchText);
      });
    }
    return sortedFields;
  }, [customFields, isSortedByFieldNameAsc, searchText]);

  const groupedFields = useMemo(() => {
    return {
      cost: {
        items: sortedFields.filter((field) => {
          return field.elementType === 'Cost';
        }),
        title: t('app.settingsCreateCustomFieldElementTypeCost'),
        icon: <InitiateMoneyTransferIcon />,
      },
      risk: {
        items: sortedFields.filter((field) => {
          return field.elementType === 'Risk';
        }),
        title: t('app.settingsCreateCustomFieldElementTypeRisk'),
        icon: <ProtectIcon />,
      },
      earnings: {
        items: sortedFields.filter((field) => {
          return field.elementType === 'Earning';
        }),
        title: t('app.settingsCreateCustomFieldElementTypeEarning'),
        icon: <DepositIcon />,
      },
      project: {
        items: sortedFields.filter((field) => {
          return field.elementType === 'Project';
        }),
        title: t('app.settingsCreateCustomFieldElementTypeProject'),
        icon: <SkyscrapersDottedIcon />,
      },
      taxonomy: {
        items: sortedFields.filter((field) => {
          return field.elementType === 'Taxonomy';
        }),
        title: t('app.settingsCreateCustomFieldElementTypeTaxonomy'),
        icon: <BuildingIcon />,
      },
      contract: {
        items: sortedFields.filter((field) => {
          return field.elementType === 'Contract';
        }),
        title: t('app.settingsCreateCustomFieldElementTypeContract'),
        icon: <ContractMenuIcon />,
      },
      contractTitle: {
        items: sortedFields.filter((field) => {
          return field.elementType === 'ContractTitle';
        }),
        title: t('app.settingsCreateCustomFieldElementTypeContractTitle'),
        icon: <ContractMenuIcon />,
      },
      Invoice: {
        items: sortedFields.filter((field) => {
          return field.elementType === 'Invoice';
        }),
        title: t('app.settingsCreateCustomFieldElementTypeInvoice'),
        icon: <ControlMenuIcon />,
      },
    } as UserDefinedFieldDefinitionGroups;
  }, [sortedFields, t]);

  const sortByFieldName = useCallback(() => {
    setIsSortedByFieldNameAsc((prev) => !prev);
  }, []);

  return (
    <div className="w-full">
      <SettingsHeader searchText={searchText} setSearchText={setSearchText}>{t('app.settingsUserDefinedFields')}</SettingsHeader>
      {isFetching && <LoadingIndicator text={t('app.settingsLoadingUserDefinedFields')} />}
      {sortedFields.length > 0 ? (
        <>
          <div className="flex w-full items-center py-1 px-5 gap-8 leading-6 justify-between text-[13px] text-slate-400 mt-8">
            <div className="flex-1 flex items-center gap-8">
              <div className="flex gap-4 items-center w-8/12">
                <div className="w-8" />
                <div className="flex-1 flex justify-between items-center cursor-pointer" onClick={sortByFieldName}>
                  {t('app.settingsSortByFieldNameLabel')}
                  <SortDownIcon
                    className={cn('text-slate-400 opacity-60 transition-transform duration-100 w-7', {
                      '-rotate-180': !isSortedByFieldNameAsc,
                    })}
                  />
                </div>
              </div>
              <div className="w-2/12">{t('app.settingsCustomFieldExtensionFrom')}</div>
              <div className="w-2/12">{t('app.settingsCustomFieldUsedInProjects')}</div>
            </div>
            <div className="text-gray-400 hover:text-gray-600 px-1 w-6" />
          </div>
          {Object.keys(groupedFields).map((group) => {
            if (groupedFields[group].items.length) {
              return (
                <CustomFieldsListGroup
                  key={groupedFields[group].title}
                  group={groupedFields[group]}
                  handleOnClickItem={(item) => {
                    setSelectedItem(item);
                    setIsCreateCustomFieldSlideOverOpen(true);
                  }}
                  handleOnDeleteItem={(item) => {
                    setSelectedItem(item);
                    setIsOpenDeleteModal(true);
                  }}
                  searchText={searchText}
                />
              );
            }
            return null;
          })}
        </>
      ) : (
        <div className="mt-4">{t('app.settingsNoCustomFields')}</div>
      )}

      <FloatingActionButton menuItems={contextItems} position="fixed bottom-16 right-24" />

      <SlideOver
        isOpen={isCreateCustomFieldSlideOverOpen}
        onClose={() => setIsCreateCustomFieldSlideOverOpen(false)}
        onAfterLeave={() => setSelectedItem(null)}
      >
        <CreateCustomFieldSlideOver
          onClose={() => {
            setIsCreateCustomFieldSlideOverOpen(false);
          }}
          item={selectedItem}
        />
      </SlideOver>

      {selectedItem && (
        <Modal isOpen={isOpenDeleteModal} onClose={() => setIsOpenDeleteModal(false)} variant="medium">
          <CustomFieldDeleteModal
            item={selectedItem}
            onClose={() => {
              setSelectedItem(null);
              setIsOpenDeleteModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
};
