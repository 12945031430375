import {
  Button,
  Form,
  FormField,
  Modal,
  ModalOnCloseProps,
  TextInput,
  BooleanInput,
  LoadingIndicator
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import {
  useApiPostApiAccessMutation,
  useApiUpdateApiAccessMutation,
  ApiAccessReadModel,
  ApiReadModel,
  useApiRemoveApiAccessMutation,
} from '@client/shared/api';
import { AccessItemFormValidationSchema } from './AccessItemFormValidationSchema';
import { safeMutation } from '@client/shared/utilities';
import classNames from 'classnames';

interface AddAccessItemModalProps extends ModalOnCloseProps {
  api: ApiReadModel | undefined;
  apiAccessItem?: ApiAccessReadModel | undefined;
}

export const AddAccessItemModal = ({ api, apiAccessItem, onClose }: AddAccessItemModalProps) => {
  const { t } = useTranslation();
  const [postAccessItem, { isLoading: isPosting }] = useApiPostApiAccessMutation();
  const [updateAccessItem, { isLoading: isUpdating }] = useApiUpdateApiAccessMutation();

  const [removeAcessItem, { isLoading: isRemoving }] = useApiRemoveApiAccessMutation();

  const isAdd = !apiAccessItem;

  const handleSubmit = async (data: AccessItemFormValidationSchema) => {
    try {
      if (isAdd) {
        await safeMutation(
          postAccessItem,
          {
            apiId: api?.id ?? '',
            body: {
              isActive: data.isActive,
              apiUrl: api?.needsApiUrl && data.apiUrl !== '' ? data.apiUrl : undefined,
              apiKey: api?.needsApiKey && data.apiKey !== '' ? data.apiKey : undefined,
              userName: api?.needsUserName && data.userName !== '' ? data.userName : undefined,
              password: api?.needsPassword && data.password !== '' ? data.password : undefined,
              customConfig: api?.needsCustomConfig && data.customConfig !== '' ? data.customConfig : undefined,
            },
          },
          isPosting,
        );
      } else {
        await safeMutation(
          updateAccessItem,
          {
            apiAccessId: apiAccessItem.id ?? '',
            body: {
              isActive: data.isActive,
              apiUrl: api?.needsApiUrl && data.apiUrl !== '' ? data.apiUrl : undefined,
              apiKey: api?.needsApiKey && data.apiKey !== '' ? data.apiKey : undefined,
              userName: api?.needsUserName && data.userName !== '' ? data.userName : undefined,
              password: api?.needsPassword && data.password !== '' ? data.password : undefined,
              customConfig: api?.needsCustomConfig && data.customConfig !== '' ? data.customConfig : undefined,
            },
          },
          isUpdating,
        );
      }

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteAcessItem = async (apiAccessItem: ApiAccessReadModel) => {
    try {
      await safeMutation(
        removeAcessItem,
        {
          apiAccessId: apiAccessItem.id ?? '',
        },
        isRemoving,
      );

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const defaultValues = {
    isActive: apiAccessItem?.isActive ?? true,
    apiUrl: apiAccessItem?.apiUrl ?? '',
    apiKey: apiAccessItem?.apiKey ?? '',
    userName: apiAccessItem?.userName ?? '',
    customConfig: apiAccessItem?.customConfig ?? '',
    password: '',
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={AccessItemFormValidationSchema}
      defaultValues={defaultValues}
      className="flex flex-col flex-grow min-h-0"
    >
      {(isPosting || isUpdating || isRemoving) && (
        <LoadingIndicator text={t('common.loading')} mode="overlay" />
      )}
      <Modal.Header title={t(isAdd ? 'app.ConnectAddItem' : 'app.ConnectEditItem')} />
      <Modal.Content className="divide-y divide-gray-200">
        {api?.needsApiUrl && (
          <FormField name="apiUrl">{(control) => <TextInput label={t('app.ConnectApiUrl')} {...control} />}</FormField>
        )}
        {api?.needsApiKey && (
          <FormField name="apiKey">{(control) => <TextInput label={t('app.ConnectApiKey')} {...control} />}</FormField>
        )}
        {api?.needsUserName && (
          <FormField name="userName">
            {(control) => <TextInput label={t('app.ConnectUserName')} {...control} />}
          </FormField>
        )}
        {api?.needsPassword && (
          <FormField name="password">
            {(control) => <TextInput label={t('app.ConnectPassword')} inputType="password" {...control} />}
          </FormField>
        )}
        {api?.needsCustomConfig && (
          <FormField name="customConfig">{(control) => <TextInput label={'customConfig'} {...control} />}</FormField>
        )}
        <div className="w-full px-20 pt-5">
          <FormField name="isActive">
            {(control) => <BooleanInput label={t('app.ConnectActive')} {...control} />}
          </FormField>
        </div>
      </Modal.Content>
      <Modal.Controls
        className={classNames('bg-white', {
          'flex justify-between': !isAdd,
        })}
      >
        <div>
          {!isAdd && (
            <Button variant="danger" onClick={() => deleteAcessItem(apiAccessItem)} className="mr-2">
              {t('app.ConnectDeleteItem')}
            </Button>
          )}
        </div>
        <div>
          <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
            {t('common.cancel')}
          </Button>
          <Button variant="primary" formSubmit={true}>
            {t('common.save')}
          </Button>
        </div>
      </Modal.Controls>
    </Form>
  );
};
