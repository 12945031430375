import classNames from 'classnames';
import { DeepPartial, FieldValues, FormProvider, SubmitHandler, useForm, KeepStateOptions } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { emptyFunction } from '@client/shared/utilities';
import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { FieldPath } from 'react-hook-form/dist/types/path';
import { SubmitErrorHandler, FormState, SetValueConfig } from 'react-hook-form/dist/types/form';

export interface FormProps<TFieldValues extends FieldValues = FieldValues> {
  className?: string;
  defaultValues?: DeepPartial<TFieldValues>;
  onSubmit?: SubmitHandler<TFieldValues>;
  onSubmitError?: SubmitErrorHandler<TFieldValues>;
  children?: React.ReactNode;
  validationSchema?: AnyObjectSchema;
  serverError?: DeepPartial<TFieldValues>;
}

export type FormRefHandle<TFieldValues extends FieldValues> = {
  resetForm: (values: DeepPartial<TFieldValues>, keepStateOptions?: KeepStateOptions) => void;
  submitForm: () => void;
  validateForm: () => Promise<void>;
  getState: () => FormState<TFieldValues>;
  getValues: () => TFieldValues;
  setValue: (name: FieldPath<TFieldValues>, value: unknown, options?: SetValueConfig) => void;
  setError: (name: FieldPath<TFieldValues>, error: string) => void;
};

export const InternalForm = <TFieldValues extends FieldValues>(
  {
    children,
    className,
    defaultValues,
    onSubmit,
    onSubmitError,
    serverError,
    validationSchema,
  }: FormProps<TFieldValues>,
  ref?: React.Ref<FormRefHandle<TFieldValues>>
) => {
  const methods = useForm<TFieldValues>({
    defaultValues,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });

  const onValid = onSubmit ?? emptyFunction;
  const onInvalid = onSubmitError ?? emptyFunction;

  if (ref && Object.prototype.hasOwnProperty.call(ref, 'current')) {
    // FP: This is a hack to get around the fact that we only want to pass a ref if we want to use the reset function on the form.
    // This should be the rare case where actually having a hook inside a conditional should be fine.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useImperativeHandle(ref, () => ({
      resetForm: (values: DeepPartial<TFieldValues>, options?: KeepStateOptions) => {
        methods.reset(values, options);
      },
      submitForm: async () => {
        await methods.handleSubmit(onValid, onInvalid)();
      },
      validateForm: async () => {
        // https://github.com/react-hook-form/react-hook-form/issues/2755#issuecomment-1371650635:
        // reading the formState errors before performing the trigger() executes it correctly and isValid
        // is now correctly set...
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // const errors = methods.formState.errors
        await methods.trigger();
      },
      getState: () => {
        return methods.formState;
      },
      getValues: () => {
        return methods.getValues();
      },
      // FP: This any is needed because the react-hook-form types are possibly not correct for this function.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setValue: (name: FieldPath<TFieldValues>, value: any, options?: SetValueConfig) => {
        methods.setValue(name, value, options);
      },

      // Muhammad: This is needed when an explicit error is needed to be set as part of form validation like in create project
      setError: (name: FieldPath<TFieldValues>, error: string) => {
        methods.setError(name, { message: error });
      },
    }));
  }

  useEffect(() => {
    if (serverError) {
      serverError.forEach((error: string, key: FieldPath<TFieldValues>) => {
        methods.setError(key, { message: error });
      });
    }
  }, [serverError, methods]);

  return (
    <FormProvider {...methods}>
      <form className={classNames(className)} onSubmit={methods.handleSubmit(onValid, onInvalid)}>
        {children}
      </form>
    </FormProvider>
  );
};

// FP: This wrapper is needed to keep the generic typing on the Form component because forwardRef has a different type
// signature that does not allow generics on the component level.
export const Form = forwardRef(InternalForm) as <TFieldValues extends FieldValues = FieldValues>(
  p: FormProps<TFieldValues> & { ref?: unknown }
) => React.ReactElement;
