import { DecoratedCard, DocumentCustomIcon, HouseMoneyIcon } from '@client/shared/toolkit';
import { TaxonomyReadModel } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { formatUnit } from '@client/shared/utilities';
import { DealStatus } from '@client/shared/api';
import classNames from 'classnames';
import { FormattedCurrency } from '@client/project/shared';

interface RentersCardProps {
  item?: TaxonomyReadModel | undefined;
}

export const RentersCard = ({ item }: RentersCardProps) => {
  const { t } = useTranslation();

  const getBorderColor = (status: DealStatus) => {
    switch (status) {
      case 'Interested':
        return 'border-green-300';
      case 'Draft':
        return 'border-green-500';
      case 'Reserved':
        return 'border-green-700';
      case 'Closed':
        return 'border-green-900';
      default:
        return 'border-red-500';
    }
  };

  return (
    <DecoratedCard>
      <DecoratedCard.Header showActionButton={false}>
        <div className="truncate">{t('projectTaxonomy.dashboardRenters')}</div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="p-5 w-full h-full bg-white">
        {item?.dealCosts
          ?.filter(
            (deal) =>
              deal.type === 'Rent' && deal.costItems.some((costItem) => costItem.taxonomyItemId === item.itemLogicalId)
          )
          .map((deal, i) => (
            <div
              className={classNames(
                'flex w-full bg-white shadow-sm cursor-default h-14 border-l-4 rounded text-sm mb-1',
                getBorderColor(deal.status)
              )}
              key={i}
            >
              <div className="flex w-full">
                <div className="w-14 flex justify-center items-center">
                  <DocumentCustomIcon className="w-4" />
                </div>

                <div className="w-7/12 flex items-center ml-2">
                  <div>
                    <div className="truncate">
                      <span className="mr-2 font-bold">{deal.name}</span>
                      <span className="mr-2 ">&bull;</span>
                      <span className="mr-2 ">{deal.code}</span>
                    </div>
                    <div className="text-gray-400 text-xs font-bold">{t(`projectRent.dealStatus${deal.status}`)}</div>
                  </div>
                </div>
                {/* <div className="w-4/12">
                <div className="text-xxs font-extrabold text-green-600">+17,20€</div>
                <div className="my-1 w-full bg-slate-300 h-1">
                  <div className="my-1 w-1/2 bg-green-900 h-1" />
                </div>
                <div className="text-slate-400 text-xxs w-full text-end">Markt Miete p.m</div>
              </div> */}

                <div className="w-5/12 flex items-center">
                  <div className="w-3/12 text-start"></div>

                  <div className="border-l-2 h-6 border-slate-300 m-4" />
                  <HouseMoneyIcon className="h-8 w-8 mr-4" />

                  <div className="w-3/12 leading-3 text-start mr-2">
                    <div className="text-sm font-bold">
                      {formatUnit(deal.totalRentedSpace.value, deal.totalRentedSpace.unit)}
                    </div>
                    <div className="text-[10px] text-gray-400">{t('projectRent.dealArea')}</div>
                  </div>
                  {/*
                  <div className="w-2/12 leading-3 text-start mr-2">
                    <div className="text-sm font-bold">
                      {getNumberOfRooms(deal.costItems)}
                    </div>
                    <div className="text-[10px] text-gray-400">
                      {t('projectRent.dealRoom', { count: getNumberOfRooms(deal.costItems) })}
                    </div>
                  </div> */}
                  <div className="w-4/12 leading-3 text-end mr-2">
                    <div className="text-sm font-bold"><FormattedCurrency amount={deal.totalDealValue.value} /></div>

                    <div className="text-[10px] text-gray-400">
                      {deal.type === 'Rent' && t('projectRent.dealRent')}
                      {deal.type === 'Sell' && t('projectRent.dealPrice')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};
