import { useTranslation } from 'react-i18next';
import { useApiPostUpdateUserNameMutation } from '@client/shared/api';
import { Modal, Button, FormField, TextInput, Form, ModalOnCloseProps, LoadingIndicator } from '@client/shared/toolkit';
import { ChangeNameFormValidationValues, ChangeNameFormValidationSchema } from './ChangeNameFormValidationSchema';
import { useUi } from '@client/shared/store';

export const ChangeNameModal = ({ onClose }: ModalOnCloseProps) => {
  const { t } = useTranslation();

  const ui = useUi();
  const user = ui.appUser;
  const [postChangeName, { isLoading }] = useApiPostUpdateUserNameMutation();

  const handleSubmit = async (data: ChangeNameFormValidationValues) => {
    try {
      await postChangeName({
        body: {
          firstName: data.firstName,
          lastName: data.lastName,
        },
      }).unwrap();

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const defaultFormValues = { firstName: user.firstName ?? '', lastName: user.lastName ?? '' };

  return (
    <Form<ChangeNameFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={ChangeNameFormValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
    >
      {isLoading && (
        <LoadingIndicator text={t('common.loading')} mode="overlay" />
      )}
      <Modal.Header title={t('app.settingsYourAccountChangeNameTitle')} />
      <Modal.Content className="flex flex-col divide-gray-100 divide-y">
        <FormField name="firstName">{(control) => <TextInput label={t('common.firstName')} {...control} />}</FormField>
        <FormField name="lastName">{(control) => <TextInput label={t('common.lastName')} {...control} />}</FormField>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)} disabled={isLoading}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" disabled={isLoading} formSubmit={true}>
          {t('common.save')}
        </Button>
      </Modal.Controls>
    </Form>
  );
};
