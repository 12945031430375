import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useDragScroll, useScrollSyncRef } from '../hooks';

interface DragScrollContainerProps extends PropsWithChildren {
  innerRef: React.RefObject<HTMLDivElement>;
  horizontal?: boolean;
  vertical?: boolean;
  className?: string;
  draggingClassName?: string;
  syncRefs?: React.RefObject<HTMLDivElement>[];
}

export const DragScrollContainer = ({
  children,
  innerRef,
  horizontal,
  vertical,
  draggingClassName = 'cursor-grabbing select-none',
  className,
  syncRefs = [],
}: DragScrollContainerProps) => {

  const { onMouseDown, isDragging } = useDragScroll(innerRef, { horizontal, vertical });
  useScrollSyncRef(syncRefs, { horizontal }, innerRef, true);

  return (
    <div
      className={classNames(
        'cursor-grab overflow-hidden overflow-x-auto',
        isDragging ? draggingClassName : '',
        className
      )}
      ref={innerRef}
      onMouseDown={onMouseDown}
    >
      {children}
    </div>
  );
};
