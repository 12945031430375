import {
  BooleanInput,
  Button, CheckmarkIcon,
  ContractNumberIcon,
  EstimateIcon,
  Form,
  FormField,
  FormRefHandle,
  LoadingIndicator, Modal, NumberInput,
  SlideOver,
  SlideOverOnCloseProps,
  TextInput
} from '@client/shared/toolkit';
import {
  InvoiceReadModel,
  InvoiceTitleReadModel,
  useApiPostUpdateInvoiceTitleByContractTitleMutation,
  useApiPostUpdateInvoiceTitleMutation
} from '@client/shared/api';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import {
  InvoiceTitleEditFormValidationSchema,
  InvoiceTitleEditFormValidationValues
} from './InvoiceTitleEditFormValidationValues';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { InvoiceTitleDeleteModal } from './InvoiceTitleDeleteModal';
import { useValidateProjectPermission } from '@client/shared/permissions';
import cn from 'classnames';
import { ProjectTaxPickerInput } from '../ProjectTaxPickerInput';
import classNames from 'classnames';
import { AuditLog } from '../AuditLog';

type SubArea = 'Values' | 'History';

interface InvoiceEditTitleSlideOverProps extends SlideOverOnCloseProps {
  invoice: InvoiceReadModel
  title: InvoiceTitleReadModel
}

export const InvoiceEditTitleSlideOver = ({ onClose, invoice, title }: InvoiceEditTitleSlideOverProps) => {
  const { t } = useTranslation();

  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<FormRefHandle<InvoiceTitleEditFormValidationValues>>();

  const [subarea, setSubarea] = useState<SubArea>('Values');
  const [update, { isLoading: isUpdating }] = useApiPostUpdateInvoiceTitleMutation()
  const [updateContractInvoiceTitle, { isLoading: isUpdatingContractInvoiceTitle }] = useApiPostUpdateInvoiceTitleByContractTitleMutation()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '')
  const canDelete = useValidateProjectPermission(['INVOICE_DELETE'], loadedProjectId ?? '')

  const handleSubmit = async (data: InvoiceTitleEditFormValidationValues) => {
    if (invoice?.id && loadedProjectId && loadedVariantId && data.id) {
      if (title.contractTitleId && invoice.contractId) {
        await safeMutation(
          updateContractInvoiceTitle,
      {
            contractId: invoice.contractId,
            invoiceId: invoice.id,
            contractTitleId: title.contractTitleId,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body:
            {
              vat: data.vat,
              invoiceValue: data.invoiceValue,
              checked: data.checked ?? false
            }
          },
          isUpdatingContractInvoiceTitle
        )
      } else {
        await safeMutation(
          update,
          {
            invoiceId: invoice.id,
            invoiceTitleId: data.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body:
              {
                code: data.code,
                vat: data.vat,
                invoiceValue: data.invoiceValue,
                checked: data.checked ?? false
              }
          },
          isUpdating
        )
      }
      onClose(true);
    }
  }

  const defaultFormValues = {
    id: title.id ?? '',
    code: title.code ?? '',
    vat: title.vat ?? 0,
    invoiceValue: title.invoiceValueNet ?? 0,
    checked: title.checked ?? false
  }

  const handleDelete = () => {
    setIsDeleteModalOpen(true)
  }

  return (
    <>
      <SlideOver.Header
        title={title?.contractTitleName ?? title?.code ?? t('projectContract.createNewTitle')}
        subTitle={title.contractTitleId ? t('projectControl.invoiceTitle') : (title ? t('projectControl.editInvoiceTitle') : t('projectContract.createNewInvoiceTitleDescription'))}
        onClose={onClose}
        backgroundClassName="bg-gray-900"
      >
        <div className="flex flex-row pt-2 pl-8 bg-gray-900 text-white">
          <button
            type="button"
            className={classNames('px-4 pb-1 text block border-l border-r', {
              'font-bold border-b-2 border-white': subarea === 'Values',
            })}
            onClick={() => setSubarea('Values')}
          >
            <div className="flex felx-row items-center">{t('common.values')}</div>
          </button>
          {title && (
            <button
              type="button"
              className={classNames('px-4 pb-1 text block border-r', {
                'font-bold border-b-2 border-white': subarea === 'History',
              })}
              onClick={() => setSubarea('History')}
            >
              <div className="flex felx-row items-center">{t('common.history')}</div>
            </button>
          )}
        </div>        
      </SlideOver.Header>

      <Form<InvoiceTitleEditFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={InvoiceTitleEditFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col flex-grow min-h-0"
        ref={formRef}
      >
        <SlideOver.Content>
          <div className="m-8">
            {subarea === 'History' && (
              <AuditLog
                id={title.logicalId}
                targetType='InvoiceTitle'
              />
            )}

            {subarea === 'Values' && (
              <div className="bg-white">
                {isUpdating && (
                  <LoadingIndicator text={t('projectContract.saveContractTitleLoadingIndicator')} mode="overlay-window" />
                )}

                <FormField name="code">
                  {(control) => (
                    <TextInput
                      label={t('projectControl.invoiceTitleCode')}
                      icon={<ContractNumberIcon className="h-6 w-6" />}
                      disabled={!canWrite || !!title.contractTitleId || invoice.isPxInvoice || invoice.state !== 'Pending'}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="vat">
                  {(control) => (
                    <ProjectTaxPickerInput
                      disabled={!canWrite || invoice.isPxInvoice || invoice.state !== 'Pending'}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="invoiceValue">
                  {(control) => (
                    <NumberInput
                      label={t('projectControl.invoiceValue')}
                      icon={<EstimateIcon className="h-6 w-6" />}
                      disabled={!canWrite || invoice.isPxInvoice || invoice.state !== 'Pending'}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="checked">
                  {(control) => (
                    <BooleanInput
                      label={t('projectControl.invoiceTitleChecked')}
                      icon={<CheckmarkIcon className="h-6 w-6" />}
                      disabled={!canWrite || invoice.isPxInvoice || invoice.state !== 'Pending'}
                      {...control}
                    />
                  )}
                </FormField>
              </div>              
            )}
          </div>
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className={cn('w-full flex', canDelete && title.contractTitleId && !invoice.isPxInvoice ? 'justify-end' : 'justify-between')}>
            {invoice.state === 'Pending' ? (
              <>
                {canDelete && !title.contractTitleId && !invoice.isPxInvoice &&
                  <Button onClick={handleDelete} className="mr-2" variant="warning">
                    {t('common.delete')}
                  </Button>
                }
                <div className="flex">
                  <Button onClick={() => onClose(false)} className="mr-2" variant="secondary">
                    {t('common.cancel')}
                  </Button>
                  {canWrite && !invoice.isPxInvoice &&
                    <Button variant="primary" formSubmit={true} innerRef={submitRef}>
                      {t('common.save')}
                    </Button>
                  }
                </div>
              </>
            ) : (
              <Button onClick={() => onClose(false)} className="ml-auto" variant="secondary">
                {t('common.close')}
              </Button>
            )}
          </div>
        </SlideOver.Controls>
      </Form>

      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <InvoiceTitleDeleteModal
          onClose={(close) => {
            setIsDeleteModalOpen(false);
            if (close) {
              onClose(close);
            }
          }}
          title={title}
        />
      </Modal>
    </>
  );
}
