import { FilterGroup, LevelToggle, SearchFilter, SearchIcon } from '@client/shared/toolkit';
import React, { RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface SettingsSearchHeaderRef {
  handleOnCollapse: (level: number) => void;
  setSearchText: (searchText: string) => void;
  getSearchText: () => string;
  getFilters: () => string[];
  setFilters: (filters: string[]) => void;
  getFilterGroups: () => FilterGroup[];
}

export const SettingsSearchHeader = ({ routeRef }: { routeRef: RefObject<SettingsSearchHeaderRef> }) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState<string[]>([]);

  useEffect(() => {
    setSearchText(routeRef.current?.getSearchText() ?? '');
    setFilters(routeRef.current?.getFilters?.() ?? []);
  }, [routeRef]);

  if (!routeRef.current) return null;

  return (
    <div className="flex items-center">
      <LevelToggle
        buttonClassName="bg-white hover:bg-slate-500 text-slate-500 hover:text-white focus:ring w-10 flex items-center justify-center"
        handleOnCollapse={routeRef.current.handleOnCollapse}
        variant="custom"
      />
      {setSearchText && !routeRef.current?.getFilterGroups().length && (
        <div className="relative text-gray-600">
          <SearchIcon className="absolute left-0 top-0 mt-3.5 ml-3.5 h-5 w-5" />
          <input
            value={searchText}
            type="search"
            placeholder={t('common.search') ?? ''}
            className="bg-gray-100 h-12 px-5 pl-10 rounded-full text-sm focus:outline-none border-none"
            onChange={(e) => {
              setSearchText(e.target.value);
              routeRef.current?.setSearchText(e.target.value);
            }}
          />
        </div>
      )}
      {setSearchText && routeRef.current?.getFilterGroups().length > 0 && (
        <SearchFilter
          filterGroups={routeRef.current?.getFilterGroups()}
          handleSearch={(value, filters) => {
            const filterIds = filters.map((filter) => filter.id);
            routeRef.current?.setFilters?.(filterIds)
            routeRef.current?.setSearchText(value);
            setFilters(filters.map((filter) => filter.id));
            setSearchText(value);
          }}
          updateSearchValue={setSearchText}
          searchValue={searchText ?? ''}
          className="h-12"
          toggledFilters={filters}
        />
      )}
    </div>
  );
};
