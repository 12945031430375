import {
  ApiDeleteEarningsElementApiArg,
  ApiDeleteEarningsElementApiResponse,
  ApiDeleteEarningsElementDocumentApiArg,
  ApiDeleteEarningsElementDocumentApiResponse,
  ApiGetEarningsElementApiArg,
  ApiGetEarningsElementApiResponse,
  ApiPostCreateEarningsElementApiArg,
  ApiPostCreateEarningsElementApiResponse,
  ApiPostCreateEarningsElementDocumentApiArg,
  ApiPostCreateEarningsElementDocumentApiResponse,
  ApiPostImportEarningsElementsFileApiArg,
  ApiPostImportEarningsElementsFileApiResponse,
  ApiPostUpdateEarningsElementApiArg,
  ApiPostUpdateEarningsElementApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiCalculateEarningEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetEarningsElement: {
    providesTags: (
      _result: ApiGetEarningsElementApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetEarningsElementApiArg,
    ) => [
      {
        type: ApiTagTypes.EarningsElement,
        id: args.id,
      },
      {
        type: ApiTagTypes.EarningsElement,
        id: args.calculationModelId,
      },      
      { // TODO only that have formula
        type: ApiTagTypes.FormulaElements,
        id: args.calculationModelId,
      },
      {
        type: ApiTagTypes.CalculateElements,
        id: args.calculationModelId,
      },
    ],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiDeleteEarningsElementDocument: {
    invalidatesTags: (
      _result: ApiDeleteEarningsElementDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteEarningsElementDocumentApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.EarningsElement, id: args.earningsElementId }] : []),
  },
  apiDeleteEarningsElement: {
    invalidatesTags: (
      _result: ApiDeleteEarningsElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteEarningsElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.EarningsCalculationModel, id: args.calculationModelId },
            // TODO only invalidate the elements that have a reference in formula to the cost element
            { type: ApiTagTypes.FormulaElements, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostCreateEarningsElementDocument: {
    invalidatesTags: (
      _result: ApiPostCreateEarningsElementDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateEarningsElementDocumentApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.EarningsElement, id: args.earningsElementId }] : []),
  },
  apiPostCreateEarningsElement: {
    invalidatesTags: (
      _result: ApiPostCreateEarningsElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateEarningsElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.EarningsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.RisksCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostUpdateEarningsElement: {
    invalidatesTags: (
      _result: ApiPostUpdateEarningsElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateEarningsElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.EarningsElement, id: args.id },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.UserDefinedFields, id: args.id },
            { type: ApiTagTypes.EarningsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.RisksCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            // TODO only invalidate the elements that have a reference in formula to the earnings element
            { type: ApiTagTypes.FormulaElements, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostImportEarningsElementsFile: {
    invalidatesTags: (
      _result: ApiPostImportEarningsElementsFileApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostImportEarningsElementsFileApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.EarningsElement, id: args.calculationModelId },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.EarningsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.RisksCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            // TODO only invalidate the elements that have a reference in formula to the earnings element
            { type: ApiTagTypes.FormulaElements, id: args.calculationModelId },
          ]
        : [],
  },  
};
