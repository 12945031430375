import { useApiGetDefaultValuesQuery } from '@client/shared/api';
import { useState } from 'react';
import { EditButton, LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { DefaultValuesSlideOver } from './DefaultValuesEditSlideOver';
import { SettingsHeader } from '../SettingsHeader';

export const DefaultValues = () => {
  const { t } = useTranslation();
  const { data: response, isFetching } = useApiGetDefaultValuesQuery();

  const [isSlideOverOpen, setIsSlideOverOpen] = useState<boolean>(false);

  return (
    <div>
        <SettingsHeader>
          {t('app.masterDataDefaultValues.title')}
        </SettingsHeader>

        <div className="relative h-full">
            {isFetching === true && <LoadingIndicator text={t('app.masterDataDefaultValues.Loading')} />}
        </div>

        <div className="mt-8 px-4">
          <div className="absolute top-24 right-32">
            <EditButton
              className="ml-auto"
              onClick={() => setIsSlideOverOpen(true)}
            />
          </div>

          <div className="text-xl mt-8 pb-2">{t('app.masterDataDefaultValues.contractAndControl')}</div>
          <div className="flex flex-row items-center py-1 px-10">
              <div className="w-1/2 text-left">{t('app.masterDataDefaultValues.dueDateDeadline')}</div>
              <div className="w-1/2 text-right">{response?.dueDateDeadline ? t('common.countDay', { count: response.dueDateDeadline}) : '-'}</div>
          </div>
          <div className="flex flex-row items-center py-1 px-10">
              <div className="w-1/2 text-left">{t('app.masterDataDefaultValues.cashDiscountDeadline')}</div>
              <div className="w-1/2 text-right">{response?.cashDiscountDeadline ? t('common.countDay', { count: response.cashDiscountDeadline }) : '-'}</div>
          </div>
        </div>

        <SlideOver isOpen={isSlideOverOpen} onClose={() => setIsSlideOverOpen(false)}>
          <DefaultValuesSlideOver defaultValues={response} onClose={() => setIsSlideOverOpen(false)}/>
        </SlideOver>
    </div>
  );
};
