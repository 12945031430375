import * as yup from 'yup';
import { InferType } from 'yup';

export const DealContractItemFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  calculation: yup.string().required('validation.required'),
  vat: yup.number().typeError('validation.number'),
  factor: yup.number().typeError('validation.number').min(0),
  value: yup.number().typeError('validation.number').min(0),
});

export type DealContractItemFormValidationValues = InferType<typeof DealContractItemFormValidationSchema>;
