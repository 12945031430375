import {
  Button,
  Form,
  FormField,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
  TextInput,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import {
  WorkflowAssignableActivityReadModel,
  useApiPostUpdateWorkflowConfigurationsMutation,
} from '@client/shared/api';
import { WorkflowConfigurationEditValidationSchema, WorkflowConfigurationEditValidationValues } from './WorkflowConfigurationEditValidationSchema';
import { safeMutation } from '@client/shared/utilities';

interface WorkflowEditVariablesSlideOverProps extends SlideOverOnCloseProps {
  isOpen: boolean;
  workflow: WorkflowAssignableActivityReadModel;
  projectId?: string;
  onAfterLeave: () => void;
}

export const WorkflowEditConfigurationsSlideOver = (props: WorkflowEditVariablesSlideOverProps) => {
  const { isOpen, onClose, onAfterLeave, projectId, workflow } = props;

  const { t } = useTranslation();

  const [postUpdate, { isLoading: isUpdating }] = useApiPostUpdateWorkflowConfigurationsMutation();

  const handleSubmit = async (data: WorkflowConfigurationEditValidationValues) => {
    try {
      await safeMutation(
        postUpdate,
        {
          projectId: projectId ?? 'unset',
          body: {
            definitionId: workflow.definitionId,
            displayName: data.displayName,
          },
        },
        isUpdating,
      );

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const defaultFormValues = {
    displayName: workflow.name,
  };

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} onAfterLeave={onAfterLeave}>
      {(isUpdating) && (
        <LoadingIndicator text={t('app.settings.workflows.loadingWorkflowLoadingIndicator')} mode="overlay-window" />
      )}
      <Form<WorkflowConfigurationEditValidationValues>
        validationSchema={WorkflowConfigurationEditValidationSchema}
        defaultValues={defaultFormValues}
        onSubmit={handleSubmit}
        className="w-full flex flex-col justify-between h-full"
      >
        <SlideOver.Header
          title={workflow.name}
          subTitle={t('app.settings.workflows.editTask')}
          backgroundClassName="bg-slate-900"
          onClose={onClose}
        />
        <SlideOver.Content className="p-8">
          <FormField name="displayName">
            {(control) => (
              <TextInput
                label={t('common.displayName')}
                {...control}
              />
            )}
          </FormField>
        </SlideOver.Content>
        <SlideOver.Controls>
          <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
            {t('common.cancel')}
          </Button>
          <Button variant="primary" formSubmit>
            {t('common.save')}
          </Button>
        </SlideOver.Controls>
      </Form>
    </SlideOver>
  );
};
