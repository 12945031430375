import React, { useState, useRef, PropsWithChildren, MutableRefObject } from 'react';
import { Transition } from '@headlessui/react';
import { useComponentDimensions } from '../../hooks';
import classNames from 'classnames';
import cn from 'classnames';
import { FormRefHandle } from '../Form';

type Direction = 'left' | 'right';

interface WizardComponentProps extends PropsWithChildren {
  className?: string;
  currentStep: number;
  onSlideChange?: (step: number, direction: Direction) => void;
}

const WizardComponent = ({ children, className, currentStep = 0, onSlideChange }: WizardComponentProps) => {
  const [direction, setDirection] = useState<Direction>('right');

  const childComponents = React.Children.toArray(children);

  const slides = childComponents.find((child) => {
    if (!React.isValidElement(child)) {
      return false;
    }

    return child?.type === WizardSlides;
  }) as React.ReactElement<WizardSlidesProps> | undefined;

  const navigation = childComponents.find((child) => {
    if (!React.isValidElement(child)) {
      return false;
    }

    return child?.type === WizardNavigation;
  }) as React.ReactElement<WizardNavigationProps> | undefined;

  const slidesChildren = React.Children.toArray(slides?.props.children);
  //const slideCount = React.Children.count(slides?.props.children); // counts also null or false
  const slideCount = slidesChildren.length;

  const slideCanGoNext = currentStep < slideCount - 1;
  const slideCanGoPrevious = currentStep > 0;
  const isLastSlide = currentStep === slideCount - 1;
  const isFirstSlide = currentStep === 0;

  const prevStep = async () => {
    setDirection('left');

    onSlideChange?.(currentStep - 1, 'left');
  };

  const nextStep = async () => {
    setDirection('right');

    onSlideChange?.(currentStep + 1, 'right');
  };

  return (
    <div className={classNames('flex flex-col h-full', className)}>
      <WizardSlidePanel currentStep={currentStep} direction={direction} className={slides?.props.className}>
        {slides}
      </WizardSlidePanel>
      {navigation &&
        navigation.props.children({
          count: slideCount,
          next: nextStep,
          previous: prevStep,
          canGoNext: slideCanGoNext,
          canGoPrevious: slideCanGoPrevious,
          isFirst: isFirstSlide,
          isLast: isLastSlide,
        })}
    </div>
  );
};

interface WizardSlidePanelProps extends PropsWithChildren {
  currentStep: number;
  direction?: Direction;
  className?: string;
}

const WizardSlidePanel = ({ children, className, currentStep, direction }: WizardSlidePanelProps) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const dimensions = useComponentDimensions(wrapper);

  if (!React.isValidElement(children) || !children.props.children) {
    return null;
  }
  const slidesChildren = React.Children.toArray(children?.props.children);
  slidesChildren.filter((child) => React.isValidElement(child));

  return (
    <div className={classNames('flex items-start overflow-hidden w-full flex-grow', className)} ref={wrapper}>
      {slidesChildren.map((child, i) => {
        let childProps = {
          show: i === currentStep,
          width: dimensions.width,
          direction,
          appear: i > 0,
          index: i
        };
        childProps = {...childProps, ...(child as React.ReactElement<WizardSlideProps>).props ?? {}};
        return React.cloneElement(child as React.ReactElement<WizardSlideProps>, childProps);
      })}
    </div>
  );
};

interface WizardSlideProps extends PropsWithChildren {
  show?: boolean;
  direction?: Direction;
  width?: number;
  centered?: boolean
  hasPadding?: boolean
  appear?: boolean
}

const WizardSlide = ({ children, show = false, direction, width, centered = true, hasPadding = true, appear = true }: WizardSlideProps) => {
  // needed when enter and leave transitions overlap (fast navigating)
  const [isEnterAnimating, setIsEnterAnimating] = useState(false);
  const [isLeaveAnimating, setIsLeaveAnimation] = useState(false);
  return (
    <Transition
      appear={appear}
      unmount={false}
      show={show}
      enterFrom={!isLeaveAnimating ? (direction === 'right' ? `translate-x-96 opacity-0` : `-translate-x-96 opacity-0`) : 'opacity-0'}
      enterTo={!isLeaveAnimating ? `translate-x-0 opacity-100`: 'opacity-100'}
      leaveFrom={!isEnterAnimating ? `translate-x-0 opacity-100` : 'opacity-100'}
      leaveTo={!isEnterAnimating ? (direction === 'right' ? `-translate-x-96 opacity-0` : `translate-x-96 opacity-0`) : 'opacity-0'}
      className="w-0 h-full transform transition ease-in-out duration-500"
      beforeEnter={() => setIsEnterAnimating(true)}
      afterEnter={() => setIsEnterAnimating(false)}
      beforeLeave={() => setIsLeaveAnimation(true)}
      afterLeave={() => setIsLeaveAnimation(false)}
      as="div"
    >
      <div
        className={cn('w-full flex items-center flex-col overflow-auto h-full', {
          'justify-center': centered,
          'py-12': hasPadding
        })}
         style={{ width: `${width}px` }}
      >
        {children}
      </div>
    </Transition>
  );
};

interface WizardSlidesProps extends PropsWithChildren {
  className?: string;
}

const WizardSlides = ({ children }: WizardSlidesProps) => {
  return <>{children}</>;
};

interface WizardNavigationProps {
  children: ({
    count,
    canGoNext,
    canGoPrevious,
    previous,
    next,
  }: {
    count: number;
    canGoNext?: boolean;
    canGoPrevious?: boolean;
    previous: () => void;
    next: () => void;
    isLast?: boolean;
    isFirst?: boolean;
  }) => React.ReactElement<React.ReactFragment>;
}

const WizardNavigation = ({ children }: WizardNavigationProps) => {
  return <>{children}</>;
};

export const Wizard = Object.assign(WizardComponent, {
  Slide: WizardSlide,
  Slides: WizardSlides,
  Navigation: WizardNavigation,
});


export const wizardHandleOnSlideChangeFormValidation = async (
  index: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formRef: MutableRefObject<FormRefHandle<any> | undefined>,
  setCurrentStep: (index: number) => void
) => {
  if (formRef.current) {
    await formRef.current.validateForm();

    const { isValid } = formRef.current.getState();

    // FP: A bit hacky, but it is the only way to make validation not just run but show the errors
    if (!isValid) {
      formRef.current.submitForm();
      await formRef.current.validateForm();

      const { isValid } = formRef.current.getState();

      if (isValid) {
        setCurrentStep(index);
      }
    }

    if (isValid) {
      setCurrentStep(index);
    }
  }
};
