import { useApiGetContractReportQuery } from '@client/shared/api';
import { LoadingIndicator } from '@client/shared/toolkit';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useEffect } from 'react';

export const ReportingContract = () => {
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: contractReportData, isFetching } = useApiGetContractReportQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId,
    },
  );

  useEffect(() => {
    console.log(contractReportData, 'contractReportData');
  }, [contractReportData]);

  return <div>{isFetching && <LoadingIndicator mode="overlay-window" text="Loading contract report data..." />}</div>;
};
