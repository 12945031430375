import { formatNumber } from '@client/shared/utilities';
import { CalculationState } from '@client/shared/api';
import React from 'react';
import classNames from 'classnames';

interface CalculateEarningsMultipleProps {
  classNames?: string;
  calculationState?: CalculationState | null;
  multiple?: number | null;
  calculatedMultiple?: number | null;
  effectiveMultiple?: number | null;
  multipleDifference?: number | null;
}

export const CalculateEarningsMultiple = (props: CalculateEarningsMultipleProps) => {
  return (
    <div className={classNames(props.classNames, 'text-right flex flex-row justify-end items-center leading-tight"')}>
      {props.calculationState === 'Empty' && <div>-</div>}
      {props.calculationState === 'JustTotal' && props.multiple !== 0 && (
        <div className="font-base">{formatNumber(props.multiple, {maxDigits: 1})}x</div>
      )}
      {props.calculationState === 'JustCalculated' && props.calculatedMultiple !== 0 && (
        <div className="font-base italic">ø{formatNumber(props.calculatedMultiple, {maxDigits: 1})}x</div>
      )}
      {props.calculationState === 'Equality' && props.effectiveMultiple !== 0 && (
        <div className="font-base">
          {formatNumber(props.effectiveMultiple, {maxDigits: 1})}x
        </div>
      )}
      {props.calculationState === 'Remainder' && props.effectiveMultiple !== 0 && (
        <div className="font-base">
          {formatNumber(props.effectiveMultiple, {maxDigits: 1})}x
          <div className="text-[8px] text-green-800 font-base">+ {formatNumber(props.multipleDifference, {maxDigits: 1})}x</div>
        </div>
      )}
      {props.calculationState === 'Overflow' && props.effectiveMultiple !== 0 && (
        <div className="font-base">
          {formatNumber(props.effectiveMultiple, {maxDigits: 1})}x
          <div className="text-[8px] text-red-900 font-base">{formatNumber(props.multipleDifference, {maxDigits: 1})}x</div>
        </div>
      )}
    </div>
  );
};
