import { AuthRoleReadModel, useApiGetAuthRolesQuery } from '@client/shared/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowSignIcon,
  Badge,
  BadgeGroup,
  ContextMenu,
  ContextMenuItem,
  LoadingIndicator,
  SettingsAddButton,
  SlideOver
} from '@client/shared/toolkit';
import { TranslateRoleComment, TranslateRoleName } from './RoleTranslation';
import { RoleDetailsSlideOver } from './RoleDetailsSlideOver';
import { ShieldCheckIcon, Square2StackIcon } from '@heroicons/react/24/outline';
import { RoleCopySlideOver } from './RoleCopySlideOver';
import { SettingsHeader } from '../../SettingsHeader';

export const Roles = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string | undefined>();

  const { data, isFetching } = useApiGetAuthRolesQuery();
  const roles: AuthRoleReadModel[] = data ?? [];

  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [showCopy, setShowCopy] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<AuthRoleReadModel>();

  const handleEditRole = (role: AuthRoleReadModel) => {
    setSelectedRole(role);
    setShowDetails(true);
  };

  const handleAddRole = () => {
    setShowAdd(true);
    setShowDetails(true);
  };

  const handleCopyRole = () => {
    setShowCopy(true);
  };

  const closeSlideOver = () => {
    setShowAdd(false);
    setShowDetails(false);
  };

  const contextItems: ContextMenuItem[] = [
    {
      label: t('auth.newRoleMenuTitle'),
      subtitle: t('auth.newRoleMenuSubtitle'),
      icon: <ShieldCheckIcon/>,
      onClick: () => handleAddRole(),
    },
    {
      label: t('auth.copyRoleMenuTitle'),
      subtitle: t('auth.copyRoleMenuSubtitle'),
      icon: <Square2StackIcon/>,
      onClick: () => handleCopyRole(),
    }
  ];

  return (
    <div className="w-full">
      <SettingsHeader searchText={searchText} setSearchText={setSearchText}>
        {t('app.settingsPermissions')}
      </SettingsHeader>
      <div className="relative h-full">
        <div className="flex w-full text-xs text-gray-500 pl-12 ml-2 mt-4 mb-2">
          <div className="w-4/12">{t('auth.role')}</div>
          <div className="w-2/12">{t('auth.roleType')}</div>
          <div className="w-6/12">{t('auth.teamsAndUsers')}</div>
        </div>
        {isFetching === true && <LoadingIndicator text={t('auth.roleLoadingIndicator')} />}
        <div className="border-t border-gray-200">
          {roles
            .filter((x) => x.name.toLowerCase().includes(searchText?.toLowerCase() ?? ''))
            .map((role, idx) => (
              <div
                key={idx}
                className="flex flex-row items-center border-b hover:bg-gray-100 cursor-pointer py-2"
                onClick={() => handleEditRole(role)}
              >
                <div className="w-8 h-8 mx-3">
                  <ArrowSignIcon />
                </div>

                <div className="w-4/12">
                  <div className="font-bold text-base text-gray-900">
                    {TranslateRoleName(role.name, role.systemRoleType)}
                  </div>
                  <div className="text-gray-500">{TranslateRoleComment(role.comment, role.systemRoleType)}</div>
                </div>

                <div className="w-2/12">
                    <BadgeGroup>
                      <Badge
                        text={role.scope === 'Project' ? t('auth.ProjectPermissionScope') : t('auth.TenantPermissionScope')}
                        variant={role.scope === 'Project' ? 'success' : 'info'}/>
                      <>
                        {role.isSystemRole &&
                          <Badge
                            text='System'
                            variant='lightInfo'/>
                        }
                      </>
                    </BadgeGroup>
                </div>

                <div className="w-6/12 flex flex-wrap items-center">
                  {role.groups
                    .filter((item, index, groups) => groups.findIndex((g) => g.id === item.id) === index)
                    .map((group, index) => (
                      <div key={index} className="flex font-bold ">
                        {index !== 0 && <div className="mx-1">{'|'}</div>}
                        {group.name}
                      </div>
                    ))}
                  {role.groups.length > 0 && role.subjects.length > 0 && <div className="w-1" />}
                  {role.subjects
                    .filter((item, index, subjects) => subjects.findIndex((s) => s.userId === item.userId) === index)
                    .map((user, index) => (
                      <div key={index} className="flex text-gray-500">
                        {index !== 0 && <div className="mx-1">{'|'}</div>}
                        {user.name}
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
        <div className="flex w-full justify-end items-center">
          <ContextMenu
            items={contextItems}
            button={
              <SettingsAddButton />
            }
          />
        </div>
      </div>

      <SlideOver isOpen={showCopy} onClose={() => setShowCopy(false)}>
        <RoleCopySlideOver availableRoles={roles} onClose={() => setShowCopy(false)} />
      </SlideOver>

      <SlideOver isOpen={showDetails} variant='large' onClose={() => closeSlideOver()} onAfterLeave={() => setSelectedRole(undefined)}>
        <RoleDetailsSlideOver isAddMode={showAdd} role={selectedRole} onClose={() => closeSlideOver()} />
      </SlideOver>
    </div>
  );
};
