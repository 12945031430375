import { DealReadModel, DealType } from '@client/shared/api';
import { useState } from 'react';
import { useGetProjectDeals } from '../../hooks';
import { DealsGroup } from './DealsGroup';
import { LoadingIndicator, SlideOver, FloatingActionButton } from '@client/shared/toolkit';
import { DealEditSlideOver } from './DealEditSlideOver';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useReadOnly } from '@client/project/store';
import { useCanWriteDeal } from '../../hooks';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useNavigate } from 'react-router-dom';

interface DealsProps {
  type: DealType;
}

export const Deals = ({ type }: DealsProps) => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();
  const isReadOnly = useReadOnly();
  const navigate = useNavigate();
  const canWrite = useCanWriteDeal(type) && !isReadOnly;

  const [showAddForm, setShowAddForm] = useState<boolean>(false);

  const { data: response, isFetching } = useGetProjectDeals(type);
  const deals = response?.deals === undefined ? [] : response.deals;

  const handleRowClick = (deal: DealReadModel) => {
    if (type === 'Rent') {
      navigate(ROUTES_CONFIG.PROJECT_RENT_DEAL_VIEW.path.replace(':id', projectId ?? '').replace(':dealId', deal.id))
    } else {
      navigate(ROUTES_CONFIG.PROJECT_SELL_DEAL_VIEW.path.replace(':id', projectId ?? '').replace(':dealId', deal.id))
    }
  }

  return (
    <div>
      {isFetching === true && <LoadingIndicator mode="overlay" text={t('projectRent.loadingDealDetails')} />}

      <DealsGroup
        header={t('projectRent.dealStatusInterested')}
        status="Interested"
        type={type}
        elements={deals}
        onSelect={handleRowClick}
        isReadOnly={isReadOnly}
      />
      <DealsGroup
        header={t('projectRent.dealStatusDraft')}
        status="Draft"
        type={type}
        elements={deals}
        onSelect={handleRowClick}
        isReadOnly={isReadOnly}
      />
      <DealsGroup
        header={t('projectRent.dealStatusReserved')}
        status="Reserved"
        type={type}
        elements={deals}
        onSelect={handleRowClick}
        isReadOnly={isReadOnly}
      />
      <DealsGroup
        header={t('projectRent.dealStatusClosed')}
        status="Closed"
        type={type}
        elements={deals}
        onSelect={handleRowClick}
        isReadOnly={isReadOnly}
      />

      {canWrite &&
        <FloatingActionButton
          onClick={() => setShowAddForm(true)} />
      }

      <SlideOver isOpen={showAddForm} onClose={() => setShowAddForm(false)}>
        <DealEditSlideOver
          isReadOnlyVersion={isReadOnly}
          isAddMode={true}
          createType={type}
          onClose={() => setShowAddForm(false)}
          projectId={projectId ?? ''}
        />
      </SlideOver>
    </div>
  );
};
