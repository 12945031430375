import { ContractNumberIcon, DatePicker, FormField, FormRefHandle, FormWatch, TextInput } from '@client/shared/toolkit';
import {  InvoiceBudgetAssignment, ProjectTaxPickerInput } from '../..';
import { getMinDate } from '../../../utils';
import { InvoiceCreateFormValidationValues } from '..';
import { addDays } from 'date-fns';
import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { BudgetAssignmentReadModel, ContractReadModel, SelectContractReadModel } from '@client/shared/api';

interface InvoiceCreateAdditionalFieldsProps {
  setBudgetAssignment: (budget: string | undefined) => void;
  budgetAssignment?: BudgetAssignmentReadModel | null;
  formRef: RefObject<FormRefHandle<InvoiceCreateFormValidationValues>> | null;
  selectedContract?: SelectContractReadModel | ContractReadModel;
}

export const InvoiceCreateAdditionalFields = (props: InvoiceCreateAdditionalFieldsProps) => {
  const { setBudgetAssignment, formRef, selectedContract } = props;
  const { t } = useTranslation();

  return (
    <>
      <FormField name="externalCode">
        {(control) => (
          <TextInput
            label={t('projectControl.invoiceExternalCode')}
            icon={<ContractNumberIcon className="h-6 w-6" />}
            className="w-full flex-none"
            {...control}
          />
        )}
      </FormField>
      <FormField name="comment">
        {(control) => (
          <TextInput
            label={t('projectControl.invoiceComment')}
            inputType="textarea"
            className="font-normal w-full flex-none"
            {...control}
          />
        )}
      </FormField>

      <FormField name="vat">{(control) => <ProjectTaxPickerInput {...control} />}</FormField>

      <div className="flex flex-wrap">
        <FormWatch<InvoiceCreateFormValidationValues>
          fieldNames={['invoiceDate', 'dateOfReceipt']}
          onChange={({ dateOfReceipt, invoiceDate }) => {
            if (
              invoiceDate &&
              dateOfReceipt &&
              new Date(invoiceDate).setHours(0, 0, 0, 0) > new Date(dateOfReceipt).setHours(0, 0, 0, 0)
            ) {
              formRef?.current?.setValue('dateOfReceipt', invoiceDate);
            }
          }}
        >
          {({ invoiceDate, dateOfReceipt }) => (
            <>
              <FormField name="invoiceDate">
                {(control) => <DatePicker label={t('projectControl.invoiceDate')} className="w-1/2" {...control} />}
              </FormField>
              <FormField name="dateOfReceipt">
                {(control) => (
                  <DatePicker
                    label={t('projectControl.dateOfReceipt')}
                    className="w-1/2"
                    minDate={getMinDate(invoiceDate)}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="dueDate">
                {(control) => (
                  <DatePicker
                    label={t('projectControl.dueDate')}
                    className="w-1/2"
                    minDate={getMinDate(dateOfReceipt)}
                    {...control}
                  />
                )}
              </FormField>
            </>
          )}
        </FormWatch>
        <FormWatch<InvoiceCreateFormValidationValues>
          fieldNames={['dateOfReceipt', 'dueDate', 'cashDiscountDate', 'contractId']}
          onChange={({ dateOfReceipt, dueDate, cashDiscountDate, contractId }) => {
            const currentDateOfReceipt = dateOfReceipt ? new Date(dateOfReceipt) : undefined;

            if (
              dueDate &&
              currentDateOfReceipt &&
              currentDateOfReceipt.setHours(0, 0, 0, 0) > new Date(dueDate).setHours(0, 0, 0, 0)
            ) {
              const newDueDate = selectedContract?.dueDateDeadline
                ? addDays(currentDateOfReceipt, selectedContract.dueDateDeadline)
                : null;

              formRef?.current?.setValue('dueDate', newDueDate);
            }
            if (
              cashDiscountDate &&
              currentDateOfReceipt &&
              currentDateOfReceipt.setHours(0, 0, 0, 0) > new Date(cashDiscountDate).setHours(0, 0, 0, 0)
            ) {
              const newCashDiscountDate = selectedContract?.cashDiscountDeadline
                ? addDays(currentDateOfReceipt, selectedContract.cashDiscountDeadline)
                : null;

              formRef?.current?.setValue('cashDiscountDate', newCashDiscountDate);
            }
          }}
        >
          {({ dateOfReceipt }) => (
            <FormField name="cashDiscountDate">
              {(control) => (
                <DatePicker
                  label={t('projectControl.cashDiscountDate')}
                  className="w-1/2"
                  minDate={getMinDate(dateOfReceipt)}
                  {...control}
                />
              )}
            </FormField>
          )}
        </FormWatch>
      </div>

      {!selectedContract && <InvoiceBudgetAssignment updateInvoiceBudgetAssignment={setBudgetAssignment} />}
    </>
  );
};
