import {
  IcsGroupNewFormValidationSchema,
  IcsGroupNewFormValidationValues,
  IcsType
} from '.';
import {
  Button,
  Form,
  FormField,
  FormRefHandle, LoadingIndicator, RenameIcon, SlideOver, TextInput
} from '@client/shared/toolkit';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CalculationRuleGroupReadModel,
  CalculationRuleGroupType,
  InvoiceCalculationSchemeReadModel,
  useApiPostCreateProjectCalculationRuleGroupMutation,
  useApiPostCreateTenantCalculationRuleGroupMutation,
  useApiPostUpdateProjectCalculationRuleGroupMutation,
  useApiPostUpdateTenantCalculationRuleGroupMutation
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { getInvoiceCoverSheetDeductionNames } from '../../utils';

type IcsGroupCreateSlideOverProps = {
  isOpen: boolean
  type: IcsType
  onClose: () => void
  sheet: InvoiceCalculationSchemeReadModel
  group?: CalculationRuleGroupReadModel | null
  onAfterLeave: () => void
  projectId?: string
}

export const IcsGroupCreateSlideOver = (props: IcsGroupCreateSlideOverProps) => {
  const {
    isOpen,
    onClose,
    type,
    sheet,
    group,
    onAfterLeave,
    projectId
  } = props

  const { t } = useTranslation()

  const formRef = useRef<FormRefHandle<IcsGroupNewFormValidationValues>>();

  const [createTenantDeductionGroup, { isLoading: isCreatingTenantIcsGroup }] =
    useApiPostCreateTenantCalculationRuleGroupMutation();
  const [createProjectDeductionGroup, { isLoading: isCreatingProjectIcsGroup }] =
    useApiPostCreateProjectCalculationRuleGroupMutation();

  const [updateTenantDeductionGroup, { isLoading: isUpdatingTenantIcsGroup }] =
    useApiPostUpdateTenantCalculationRuleGroupMutation();
  const [updateProjectDeductionGroup, { isLoading: isUpdatingProjectIcsGroup }] =
    useApiPostUpdateProjectCalculationRuleGroupMutation();

  const [nameIsNotUnique, setNameIsNotUnique] = useState(false)

  const handleSubmit = async (data: IcsGroupNewFormValidationValues) => {
    if (formRef.current && sheet?.invoiceCalculationSchemeId) {
      if (!nameIsNotUnique) {
        if (type === 'tenant') {
          if (group?.calculationRuleGroupId) { // update
            try {
              await safeMutation(
                updateTenantDeductionGroup,
                {
                  invoiceCalculationSchemeId: sheet.invoiceCalculationSchemeId,
                  calculationRuleGroupId: group?.calculationRuleGroupId,
                  body: {
                    name: data.name,
                    type: data.type
                  }
                },
                isUpdatingTenantIcsGroup,
              );
              onClose();
            } catch (e) {
              console.log(e);
            }
          } else { // create
            try {
              await safeMutation(
                createTenantDeductionGroup,
                {
                  invoiceCalculationSchemeId: sheet.invoiceCalculationSchemeId,
                  body: {
                    name: data.name,
                    type: data.type
                  }
                },
                isCreatingTenantIcsGroup,
              );
              onClose();
            } catch (e) {
              console.log(e);
            }
          }
        } else if (projectId) {
          if (group?.calculationRuleGroupId) { // update
            try {
              await safeMutation(
                updateProjectDeductionGroup,
                {
                  invoiceCalculationSchemeId: sheet.invoiceCalculationSchemeId,
                  calculationRuleGroupId: group?.calculationRuleGroupId,
                  projectId: projectId,
                  body: {
                    name: data.name,
                    type: data.type
                  }
                },
                isUpdatingProjectIcsGroup,
              );
              onClose();
            } catch (e) {
              console.log(e);
            }
          } else { // create
            try {
              await safeMutation(
                createProjectDeductionGroup,
                {
                  invoiceCalculationSchemeId: sheet.invoiceCalculationSchemeId,
                  projectId: projectId,
                  body: {
                    name: data.name,
                    type: data.type
                  },
                },
                isCreatingProjectIcsGroup,
              );
              onClose();
            } catch (e) {
              console.log(e);
            }
          }
        }
      }
    }
  }

  const allExistingNames = useMemo(() => {
    if (sheet) {
      return getInvoiceCoverSheetDeductionNames(sheet, group?.calculationRuleGroupId ? [group.calculationRuleGroupId] : [], [])
    }
    return []
  }, [sheet, group?.calculationRuleGroupId])

  const checkIfNameIsUnique = useCallback((name?: string) => {
    if (name && allExistingNames?.length) {
      setNameIsNotUnique(allExistingNames.includes(name))
    } else {
      setNameIsNotUnique(false)
    }
  }, [allExistingNames])


  if (!sheet?.invoiceCalculationSchemeId) return null

  // The group "Payment" can only be added once
  const defaultFormValues = {
    name: group?.name ?? '',
    type: group?.type ?? 'Custom' as CalculationRuleGroupType
  }

  return (
    <SlideOver
      isOpen={isOpen}
      onClose={onClose}
      onAfterLeave={onAfterLeave}
    >
      {(isCreatingTenantIcsGroup || isCreatingProjectIcsGroup || isUpdatingTenantIcsGroup || isUpdatingProjectIcsGroup) && (
        <LoadingIndicator text={t('app.settingsSavingInvoiceCoverSheet')} mode="overlay-window" />
      )}
      <SlideOver.Header
        title={t('ics.invoiceCoverSheetGroup')}
        subTitle={t('ics.newInvoiceCoverSheetGroup')}
        backgroundClassName="bg-slate-900"
        onClose={onClose}
      />
      <Form<IcsGroupNewFormValidationValues>
        validationSchema={IcsGroupNewFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <SlideOver.Content className="p-8">
          <FormField name="name">
            {(control) =>
              <TextInput
                label={t('app.settingsInvoiceCoverSheetName')}
                icon={<RenameIcon />}
                {...control}
                onChange={(val) => {
                  checkIfNameIsUnique(val)
                  control.onChange(val)
                }}
                showValidation={nameIsNotUnique || control.showValidation}
                helperText={nameIsNotUnique ? t('ics.deductionWithNameAlreadyExists') : control.helperText}
                isValidationValid={!nameIsNotUnique && control.isValidationValid}
                helperTextTruncate={false}
              />
            }
          </FormField>
        </SlideOver.Content>
        <SlideOver.Controls>
          <Button variant="secondary" className="mr-2" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button variant="primary" formSubmit>
            {t('common.save')}
          </Button>
        </SlideOver.Controls>
      </Form>
    </SlideOver>
  )
}
