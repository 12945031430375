import { getLanguageAndLocale } from './i18n';

/**
 * Returns the decimal or group separator for the given locale.
 * @param locale The locale code.
 * @param separatorType The type of the separator to return. Can be either 'decimal' or 'group'.
 * @returns {string} The decimal or group separator for the given locale.
 */
const getNumberSeparator = (locale: string, separatorType: 'decimal' | 'group') => {
  const numberWithGroupAndDecimalSeparator = 10000.1;

  return Intl.NumberFormat(locale)
    .formatToParts(numberWithGroupAndDecimalSeparator)
    .find((part) => part.type === separatorType)?.value;
};

/**
 * Returns the decimal separator and group separator for the given locale.
 * @param locale The locale code. Defaults to the current locale.
 * @returns The decimal and group separators
 */
export const getNumberSeparators = (locale: string = getLanguageAndLocale().locale) => {
  const decimalSeparator = getNumberSeparator(locale, 'decimal');
  const groupSeparator = getNumberSeparator(locale, 'group');
  return { decimalSeparator, groupSeparator };
};
