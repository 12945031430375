import { settings } from '@client/shared/store';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useDevModeGuard = (fallbackRoute = ROUTES_CONFIG.PROJECTS.path) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!settings.devMode) {
      navigate(fallbackRoute);
    }
  });
};
