import React, { useCallback, useContext, useRef } from 'react';
import { TooltipProps } from '@client/shared/toolkit';
import { TimeLineDataContext } from './Timeline';

export const CalculateTooltip = ({
  className,
  label,
  placement = 'left',
  enterDelay = 250,
  leaveDelay = 150,
  children,
}: TooltipProps) => {
  const { setTooltipContent, setTooltipTarget, setTooltipOptions } = useContext(TimeLineDataContext);

  const triggerRef = useRef<HTMLDivElement>(null);
  const enterTimeout = useRef<NodeJS.Timeout>();
  const leaveTimeout = useRef<NodeJS.Timeout>();
  const handleMouseEnter = useCallback(
    (e: React.MouseEvent) => {
      leaveTimeout.current && clearTimeout(leaveTimeout.current);
      enterTimeout.current = setTimeout(() => {
        setTooltipOptions({
          placement: placement,
          modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
        });
        setTooltipContent(children);
        setTooltipTarget(e.target as HTMLDivElement);
      }, enterDelay);
    },
    [setTooltipOptions, placement, setTooltipContent, children, setTooltipTarget, enterDelay],
  );
  const handleMouseLeave = useCallback(() => {
    enterTimeout.current && clearTimeout(enterTimeout.current);
    leaveTimeout.current = setTimeout(() => {
      setTooltipTarget(null);
      setTooltipContent('');
    }, leaveDelay);
  }, [setTooltipTarget, setTooltipContent, leaveDelay]);
  return (
    <div ref={triggerRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={className}>
      {label}
    </div>
  );
};
