import React, { PropsWithChildren } from 'react';
import { RouteConfig, ROUTES_CONFIG } from '../routes';
import { useValidatePermissionsAndLicenses } from '../hooks';
import { Navigate, useNavigate } from 'react-router-dom';
import { isEmpty } from '@client/shared/utilities';
import { LoadingIndicator } from '@client/shared/toolkit';

export interface ProtectedRouteProps extends PropsWithChildren {
  routeConfig: RouteConfig
  fallbackRoute?: string
  projectId?: string
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const {
    routeConfig,
    children,
    projectId
  } = props

  const navigate = useNavigate();

  const { hasPermissionsAndLicense, permissionsData, isLoading } = useValidatePermissionsAndLicenses(routeConfig, projectId);

  const fallBackTarget = isEmpty(routeConfig.fallbackRoute) ? ROUTES_CONFIG.PROJECTS.path : routeConfig.fallbackRoute ?? '';

  // route is disabled in route settings
  if (routeConfig.disabled) {
    navigate(fallBackTarget)
  }

  if (isLoading) {
    return <LoadingIndicator className="absolute top-0 left-0 w-screen h-screen" mode="overlay-window" />
  }

  if (hasPermissionsAndLicense) {
    return <>{children}</>;
  } else if (!permissionsData) {
    return null
  } else {
    return <Navigate to={fallBackTarget} />;
  }
}
