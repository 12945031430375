import {
  Collapsible,
  DecoratedCard,
  LevelToggle,
  LoadingIndicator,
  useComponentDimensions,
} from '@client/shared/toolkit';
import React, { PropsWithChildren, useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  useApiGetForecastReportQuery,
  ForecastInvestmentRowReadModel,
  useApiGetCalculationModelFinancingQuery,
} from '@client/shared/api';
import { setExpandedForecastReportIds, useExpandedReportingIds, useLoadedProjectId } from '@client/project/store';
import { formatDate, formatNumber, formatPercentage, formatUnit, isEmpty } from '@client/shared/utilities';
import { CalculationModelSelect } from './CalculationModelSelect';
import { ColumnRowStyle, ReportCollapsingRow } from './ReportCollapsingRow';
import { ToggleButton } from '@client/shared/toolkit';
import {
  FinancingAnalysis,
  FormattedCurrency,
  GeneralKeyPerformanceIndicators,
  SensitivityAnalysis,
} from '@client/project/shared';
import { ReportingContext } from './ReportingContextProvider';
import { useDispatch } from 'react-redux';
import { getLevelledElements } from '../utils';

export const ReportForecast = () => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();

  const { calculationModelId, setCalculationModelId } = useContext(ReportingContext);

  const { data, isFetching } = useApiGetForecastReportQuery(
    {
      projectId: projectId ?? '',
      calculationModelId: calculationModelId ?? '',
    },
    { skip: calculationModelId === undefined },
  );

  const { data: financeData, isFetching: isFinanceFetching } = useApiGetCalculationModelFinancingQuery(
    {
      projectId: projectId ?? '',
      calculationModelId: calculationModelId ?? '',
    },
    { skip: calculationModelId === undefined },
  );

  const investmentCost = data?.report.investment;
  const rentAndMarketValue = data?.report.rentAndMarketValue;

  const irr = data?.report.calculationModel.modelMetadata.plannedIrr.value ?? 0;
  const totalEquity = financeData?.payload.totalEquity;
  const totalProfit = data?.report.calculationModel.modelMetadata.totalProfit.value ?? 0;
  const roi = investmentCost?.gik ? (rentAndMarketValue?.total ?? 0) / investmentCost.gik : 0;

  const multiple = data?.report.rentAndMarketValue.rentalValueRows[0]?.children
    ? Math.round(data?.report.rentAndMarketValue.rentalValueRows[0].children[0]?.multiple ?? 0)
    : 0;
  const totalRentEarnings = data?.report.rentAndMarketValue.rentalIncomeRows[0]?.total ?? 0;

  const financingElements = financeData?.payload.financingGroups.filter((x) => x.code !== '32') ?? [];
  const mezzanineElements = financeData?.payload.financingGroups.filter((x) => x.code === '32') ?? [];

  const levelledElements = useMemo(() => {
    const allElements = [
      ...(investmentCost?.gekRows ?? []),
      ...(investmentCost?.gikRows ?? []),
      ...(investmentCost?.operationRows ?? []),
      ...(rentAndMarketValue?.rentalIncomeRows ?? []),
      ...(rentAndMarketValue?.rentalValueRows ?? []),
      ...(rentAndMarketValue?.propertyValueRows ?? []),
    ];
    return getLevelledElements(
      allElements,
      ['children'],
      'rowIdentifier',
    );
  }, [investmentCost, rentAndMarketValue]);

  const dispatch = useDispatch();

  const { Forecast: expandedElements } = useExpandedReportingIds();

  const handleOnCollapse = (level: number) => {
    const elements = levelledElements.filter((x) => x.level <= level - 2);
    const expanded = elements.map((x) => x.elementId);
    dispatch(setExpandedForecastReportIds(expanded));
  };

  return (
    <DecoratedCard>
      <DecoratedCard.Header showActionButton={false}>
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex">
            <div className="truncate">{t('reporting.reportForecast.title')}</div>
            <div className="pdf-export-hidden">
              <LevelToggle handleOnCollapse={handleOnCollapse} />
            </div>
          </div>
          <div className="flex items-center">
            <div className="font-bold text-[15px] pr-6">{t('reporting.dataSource')}</div>
            <div className="flex space-x-0.5 items-center">
              <CalculationModelSelect
                selectedCalculationModelId={calculationModelId}
                className="w-52"
                onChange={(id) => setCalculationModelId(id)}
              />
            </div>
          </div>
        </div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="w-full h-full flex relative bg-white">
        {(isFetching || isFinanceFetching) && <LoadingIndicator mode="overlay" />}
        <Grouping
          title={t('reporting.reportForecast.investmentCostsTitle')}
          accentColor="bg-red-700"
          titleColor="text-red-700"
          columns={[t('reporting.tableUnitPrice'), t('reporting.tableAmount'), t('reporting.tableSum')]}
        >
          {investmentCost?.gekRows?.map((cost) => (
            <BudgetGekRow rowData={cost} key={`Gek-${cost.code}`} expandedElements={expandedElements} />
          ))}

          <div className="h-[70px] bg-white pb-6">
            <div className="flex pl-4 pr-4 w-full h-full items-center">
              <div className="flex-grow flex items-end">
                <div>
                  <div className="font-bold flex truncate">
                    <div className="h-6 w-8" />
                    <div className="w-10">&nbsp;</div>
                    <div className="text-red-700">{t('reporting.reportForecast.sumGek')}</div>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-48 text-right font-bold px-4">
                  <FormattedCurrency amount={investmentCost?.gek} />
                </div>
              </div>
            </div>
          </div>

          {investmentCost?.gikRows && (
            <>
              {investmentCost?.gikRows?.map((cost) => (
                <BudgetGikRow rowData={cost} key={`Gik-${cost.code}`} expandedElements={expandedElements} />
              ))}

              <div className="h-[70px] bg-white pb-6">
                <div className="flex pl-4 pr-4 w-full h-full items-center">
                  <div className="flex-grow flex items-end">
                    <div>
                      <div className="font-bold flex truncate">
                        <div className="h-6 w-8" />
                        <div className="w-10">&nbsp;</div>
                        <div className="text-red-700">{t('reporting.reportForecast.sumGik')}</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="w-48 text-right font-bold px-4">
                      <FormattedCurrency amount={investmentCost?.gik} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {investmentCost?.operationRows && (
            <>
              {investmentCost?.operationRows?.map((cost, i) => (
                <BudgetGikWithOperationsRow
                  rowData={cost}
                  key={`GikWithOperations-${cost.code}-${i}`}
                  expandedElements={expandedElements}
                />
              ))}

              <div className="h-[70px] bg-white pb-6">
                <div className="flex pl-4 pr-4 w-full h-full items-center">
                  <div className="flex-grow flex items-end">
                    <div>
                      <div className="font-bold flex truncate">
                        <div className="h-6 w-8" />
                        <div className="w-10">&nbsp;</div>
                        <div className="text-red-700">{t('reporting.reportForecast.sumGikWithOperations')}</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="w-48 text-right font-bold px-4 border-b-4 border-double">
                      <FormattedCurrency amount={investmentCost?.gikWithOperation} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Grouping>

        <>
          <RentGrouping
            title={t('reporting.reportForecast.marketValueTitle')}
            accentColor="bg-lime-600"
            titleColor="text-lime-600"
            columns={[
              t('reporting.reportForecast.amount'),
              t('reporting.reportForecast.erv1'),
              t('reporting.reportForecast.erv2'),
              t('reporting.reportForecast.rentIndex'),
              t('reporting.tableSum'),
            ]}
          >
            {rentAndMarketValue?.rentalIncomeRows?.map((earnings) => (
              <div className="italic" key={`RentalIncome-${earnings.code}`}>
                <BudgetEarningsRow rowData={earnings} expandedElements={expandedElements} />
              </div>
            ))}

            {rentAndMarketValue?.rentalValueRows?.map((earnings) => (
              <BudgetRentValueRow
                rowData={earnings}
                key={`RentalValue-${earnings.code}`}
                expandedElements={expandedElements}
              />
            ))}

            {rentAndMarketValue?.propertyValueRows?.map((earnings) => (
              <BudgetEarningsRow
                rowData={earnings}
                key={`PropertyValue-${earnings.code}`}
                expandedElements={expandedElements}
              />
            ))}

            <div className="h-[70px] bg-white">
              <div className="flex pl-4 pr-4 w-full h-full items-center">
                <div className="flex-grow flex items-end">
                  <div>
                    <div className="font-bold flex truncate">
                      <div className="h-6 w-8" />
                      <div className="w-10">&nbsp;</div>
                      <div className="text-lime-600">{t('reporting.reportForecast.sumMarketValue')}</div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="w-48 text-right font-bold px-4">
                    <FormattedCurrency amount={rentAndMarketValue?.total} />
                  </div>
                </div>
              </div>
            </div>

            <div className="h-[38px] bg-white pb-6">
              <div className="flex pl-4 pr-4 w-full h-full items-center">
                <div className="flex-grow flex items-end">
                  <div>
                    <div className="flex truncate">
                      <div className="h-6 w-8" />
                      <div className="w-10">&nbsp;</div>
                      <div className="flex flex-col gap-0">
                        <div className="text-lime-600 font-bold">{t('reporting.reportForecast.labelTotal')}</div>
                        <div className="text-gray-500 text-sm">{t('reporting.reportForecast.roi')}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center border-b-4 border-double">
                  <div className="w-48 text-right font-bold px-4 text-[15px]">
                    <FormattedCurrency
                      amount={(rentAndMarketValue?.total ?? 0) - (investmentCost?.gikWithOperation ?? 0)}
                    />
                  </div>
                  <div className="w-48 text-right px-4 text-sm text-gray-500 ">{formatNumber(roi) + '%'}</div>
                </div>
              </div>
            </div>
          </RentGrouping>

          <Grouping title={t('reporting.reportForecast.kpiTitle')} accentColor="bg-sky-700" titleColor="text-sky-700">
            <div className="px-6 pt-3 pb-6 grid grid-cols-3 gap-6 bg-white">
              <GeneralKeyPerformanceIndicators
                totalEquity={totalEquity ?? 0}
                totalProfit={totalProfit}
                irr={irr}
                roi={roi}
              />
              <FinancingAnalysis
                financingElements={financingElements}
                mezzanineElements={mezzanineElements}
                totalInvestmentCosts={data?.report.investment.gik ?? 0}
                totalOperationalResult={data?.report.investment.gikWithOperation ?? 0}
              />
              <SensitivityAnalysis multiple={multiple} totalRentEarnings={totalRentEarnings} />
            </div>
          </Grouping>
        </>
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};

interface GroupingProps extends PropsWithChildren {
  accentColor?: string;
  titleColor?: string;
  title?: string;
  totals?: string[];
  columns?: string[];
}

const Grouping = ({ accentColor, children, title, titleColor, totals, columns = [] }: GroupingProps) => {
  const { t } = useTranslation();

  const wrapper = useRef<HTMLDivElement>(null);
  const dimensions = useComponentDimensions(wrapper);

  const [open, setOpen] = React.useState(true);

  return (
    <div className="relative w-full" ref={wrapper}>
      <div
        className={classNames('w-2 my-4 bg-cyan-700 rounded -ml-1 left-0 absolute', accentColor)}
        style={{ height: (dimensions.height ?? 15) - 32 }}
      />
      <div className="h-[70px] bg-white border-b border-slate-300">
        <div className="flex pl-4 pr-4 w-full h-full">
          <div className="flex-grow flex items-end">
            <div
              className={classNames({
                'pb-3': columns?.length === 0,
              })}
            >
              <div className="text-[22px] font-bold flex truncate">
                {children && React.Children.count(children) > 0 ? (
                  <ToggleButton open={open} onClick={() => setOpen(!open)} />
                ) : (
                  <div className="w-8">&nbsp;</div>
                )}
                <div className="w-10">&nbsp;</div>
                <div className={titleColor}>{title}</div>
              </div>
              {columns?.length > 0 && (
                <div className="flex text-[11px] text-slate-500">
                  <div className="w-6">&nbsp;</div>
                  <div className="w-20 text-right px-2 truncate">{t('reporting.tableColumnId')}</div>
                  <div className="w-[342px] truncate">{t('reporting.tableColumnDescription')}</div>
                  <div className="w-[80px] truncate text-right pr-8">{t('reporting.tableColumnGikPercentage')}</div>
                  <div className="truncate">{t('reporting.tableColumnDetails')}</div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-end">
            {columns?.map((column, index) => (
              <div className="w-48 text-right text-[11px] text-slate-500 px-4 truncate" key={`GroupingColumn-${index}`}>
                {column}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Collapsible open={open}>{children}</Collapsible>
      {totals && totals.length > 0 && (
        <div className="h-[70px] bg-white">
          <div className="flex pl-4 pr-4 w-full h-full items-center">
            <div className="flex-grow flex items-end">
              <div>
                <div className="text-[22px] font-bold flex truncate">
                  <div className="h-6 w-8" />
                  <div className="w-10">&nbsp;</div>
                  <div className={titleColor}>Total Sum</div>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              {totals?.map((total, index) => (
                <div className="w-48 text-right font-bold px-4 border-b-4 border-double" key={`GroupingTotal-${index}`}>
                  {total}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const RentGrouping = ({ accentColor, children, title, titleColor, totals, columns = [] }: GroupingProps) => {
  const { t } = useTranslation();

  const wrapper = useRef<HTMLDivElement>(null);
  const dimensions = useComponentDimensions(wrapper);

  const [open, setOpen] = React.useState(true);

  return (
    <div className="relative w-full" ref={wrapper}>
      <div
        className={classNames('w-2 my-4 bg-cyan-700 rounded -ml-1 left-0 absolute', accentColor)}
        style={{ height: (dimensions.height ?? 15) - 32 }}
      />
      <div className="h-[70px] bg-white border-b border-slate-300">
        <div className="flex pl-4 pr-4 w-full h-full">
          <div className="flex-grow flex items-end">
            <div
              className={classNames({
                'pb-3': columns?.length === 0,
              })}
            >
              <div className="text-[22px] font-bold flex truncate">
                {children && React.Children.count(children) > 0 ? (
                  <ToggleButton open={open} onClick={() => setOpen(!open)} />
                ) : (
                  <div className="w-8">&nbsp;</div>
                )}
                <div className="w-10">&nbsp;</div>
                <div className={titleColor}>{title}</div>
              </div>
              {columns?.length > 0 && (
                <div className="flex text-[11px] text-slate-500">
                  <div className="w-6">&nbsp;</div>
                  <div className="w-20 text-right px-2 truncate">{t('reporting.tableColumnId')}</div>
                  <div className="w-[342px] truncate">{t('reporting.tableColumnDescription')}</div>
                  <div className="w-[80px] truncate text-right pr-8">{t('reporting.tableColumnGikPercentage')}</div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-end">
            {columns?.map((column, index) => (
              <div className="w-36 text-right text-[11px] text-slate-500 px-4 truncate" key={`RentColumn-${index}`}>
                {column}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Collapsible open={open}>{children}</Collapsible>
      {totals && totals.length > 0 && (
        <div className="h-[70px] bg-white">
          <div className="flex pl-4 pr-4 w-full h-full items-center">
            <div className="flex-grow flex items-end">
              <div>
                <div className="text-[22px] font-bold flex truncate">
                  <div className="h-6 w-8" />
                  <div className="w-10">&nbsp;</div>
                  <div className={classNames('text-[15px]', titleColor)}>{t('reporting.reportForecast.totalSum')}</div>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              {totals?.map((total, index) => (
                <div className="w-48 text-right font-bold px-4 border-b-4 border-double" key={`RentTotal-${index}`}>
                  {total}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface BudgetGEKRowProps {
  rowData?: ForecastInvestmentRowReadModel;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
}

const BudgetGekRow = ({ columns, level = 0, rowData, expandedElements }: BudgetGEKRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>{rowData.code}</>,
      <>{rowData.title}</>,
      <>{level === 0 ? (isEmpty(rowData.gikPercent) ? '-' : formatPercentage(rowData.gikPercent)) : ''}</>,
      <>{isEmpty(rowData.description) ? '-' : rowData.description}</>,
      <FormattedCurrency amount={rowData.unitPrice} />,
      <>{formatUnit(rowData.amount, rowData.unit ?? '')}</>,
      <FormattedCurrency amount={rowData.total} />,
    ];
  }

  const columnRowStyle: ColumnRowStyle = (index: number) => {
    return {
      'w-20 text-right px-2': index === 0,
      'w-[342px]': index === 1,
      'w-[80px] text-right pr-8': index === 2,
      'flex-grow': index === 3,
      'w-48 text-right px-4': index > 3,
      'pl-8': level > 1,
      '-mr-8': level > 1 && index === 1,
    };
  };

  return (
    <ReportCollapsingRow<ForecastInvestmentRowReadModel>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={BudgetGekRow}
      columnRowStyle={columnRowStyle}
      expandedElements={expandedElements}
      report="Forecast"
    />
  );
};

interface BudgetGikRowProps {
  rowData?: ForecastInvestmentRowReadModel;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
}

const BudgetGikRow = ({ columns, level = 0, rowData, expandedElements }: BudgetGikRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>{rowData.code}</>,
      <>{rowData.title}</>,
      <>{level === 0 ? (isEmpty(rowData.gikPercent) ? '-' : formatPercentage(rowData.gikPercent)) : ''}</>,
      <>{isEmpty(rowData.description) ? '-' : rowData.description}</>,
      <FormattedCurrency amount={rowData.unitPrice} />,
      <>-</>,
      <FormattedCurrency amount={rowData.total} />,
    ];
  }

  const columnRowStyle: ColumnRowStyle = (index: number) => {
    return {
      'w-20 text-right px-2': index === 0,
      'w-[342px]': index === 1,
      'w-[80px] text-right pr-8': index === 2,
      'flex-grow': index === 3,
      'w-48 text-right px-4': index > 3,
      'pl-8': level > 1,
      '-mr-8': level > 1 && index === 1,
    };
  };

  return (
    <ReportCollapsingRow<ForecastInvestmentRowReadModel>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={BudgetGikRow}
      columnRowStyle={columnRowStyle}
      expandedElements={expandedElements}
      report="Forecast"
    />
  );
};

interface BudgetGIKWithOperationsRowProps {
  rowData?: ForecastInvestmentRowReadModel;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
}

const BudgetGikWithOperationsRow = ({
  columns,
  level = 0,
  rowData,
  expandedElements,
}: BudgetGIKWithOperationsRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>{rowData.code}</>,
      <>{rowData.title}</>,
      <>{level === 0 ? (isEmpty(rowData.gikPercent) ? '-' : formatPercentage(rowData.gikPercent)) : ''}</>,
      <>-</>,
      <>-</>,
      <FormattedCurrency amount={rowData.total} />,
    ];
  }

  const columnRowStyle: ColumnRowStyle = (index: number) => {
    return {
      'w-20 text-right px-2': index === 0,
      'w-[342px]': index === 1,
      'w-[80px] text-right pr-8': index === 2,
      'flex-grow': index === 3,
      'w-48 text-right px-4': index > 3,
      'pl-8': level > 1,
      '-mr-8': level > 1 && index === 1,
    };
  };

  return (
    <ReportCollapsingRow<ForecastInvestmentRowReadModel>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={BudgetGikWithOperationsRow}
      columnRowStyle={columnRowStyle}
      expandedElements={expandedElements}
      report="Forecast"
    />
  );
};

interface BudgetRentValueRowProps {
  rowData?: ForecastInvestmentRowReadModel;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
}

const BudgetRentValueRow = ({ columns, level = 0, rowData, expandedElements }: BudgetRentValueRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>{rowData.code}</>,
      <>{rowData.title}</>,
      <>{level === 0 ? (isEmpty(rowData.gikPercent) ? '-' : formatPercentage(rowData.gikPercent)) : ''}</>,
      <>{rowData.multiple ? formatNumber(rowData.multiple) + 'x' : ''}</>,
      <>{rowData.percentage ? formatNumber(rowData.percentage) + '%' : ''}</>,
      <FormattedCurrency amount={rowData.total} />,
    ];
  }

  const columnRowStyle: ColumnRowStyle = (index: number) => {
    return {
      'w-20 text-right px-2': index === 0,
      'w-[342px]': index === 1,
      'w-[80px] text-right pr-8': index === 2,
      'flex-grow text-right px-4': index === 3,
      'w-36 text-right px-4': index > 3,
      'pl-8': level > 1,
      '-mr-8': level > 1 && index === 1,
    };
  };

  return (
    <ReportCollapsingRow<ForecastInvestmentRowReadModel>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={BudgetRentValueRow}
      columnRowStyle={columnRowStyle}
      expandedElements={expandedElements}
      report="Forecast"
    />
  );
};

interface BudgetEarningsRowProps {
  rowData?: ForecastInvestmentRowReadModel;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
}

const BudgetEarningsRow = ({ columns, level = 0, rowData, expandedElements }: BudgetEarningsRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>{rowData.code}</>,
      <>{rowData.title}</>,
      <>{level === 0 ? (isEmpty(rowData.gikPercent) ? '-' : formatPercentage(rowData.gikPercent)) : ''}</>,
      <>{`${rowData.amount ? rowData.amount : ''} ${rowData.unit ? rowData.unit : ''}`}</>,
      <>
        {rowData.unitPrice && (
          <>
            <div>
              <FormattedCurrency amount={rowData.unitPrice} />
            </div>
            <div className="text-xxs">{formatDate(rowData.unitPriceDate)}</div>
          </>
        )}
      </>,
      <>
        {rowData.indexUnitPrice && (
          <>
            <div>
              <FormattedCurrency amount={rowData.indexUnitPrice} />
            </div>
            <div className="text-xxs">{formatDate(rowData.indexUnitPriceDate)}</div>
          </>
        )}
      </>,
      <>
        {rowData.index}
        {rowData.index ? '%' : ''}
      </>,
      <>
        <div>
          <FormattedCurrency amount={rowData.total} />
        </div>
        {rowData.difference !== undefined && rowData.difference !== null && rowData.difference !== 0 && (
          <div className={classNames('text-xxs', rowData.difference > 0 ? 'text-red-500' : 'text-green-500')}>
            {rowData.difference > 0 ? '- ' : '+ '}
            <FormattedCurrency amount={rowData.difference} />
          </div>
        )}
      </>,
    ];
  }

  const columnRowStyle: ColumnRowStyle = (index: number) => {
    return {
      'w-20 text-right px-2': index === 0,
      'w-[342px]': index === 1,
      'w-[80px] text-right pr-8': index === 2,
      'flex-grow text-right px-4': index === 3,
      'w-36 text-right px-4': index > 3,
      'pl-8': level > 1,
      '-mr-8': level > 1 && index === 1,
    };
  };

  return (
    <ReportCollapsingRow<ForecastInvestmentRowReadModel>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={BudgetEarningsRow}
      columnRowStyle={columnRowStyle}
      expandedElements={expandedElements}
      report="Forecast"
    />
  );
};
