import { CalculationModelMetadata, CalculationModelReadModel, FinancingElementReadModel } from '@client/shared/api';
import { BankBuildingIcon, Modal, SlideOver } from '@client/shared/toolkit';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useLoadedProjectId, useLoadedVariantId, useReadOnly } from '@client/project/store';
import { FinancingElementSlideOver } from './FinancingElementSlideOver';
import { useTranslation } from 'react-i18next';
import { FinancingTransactionsSlideOver } from './FinancingTransactionsSlideOver';
import { DecoratedElement, FinancingElement } from '../hooks';
import { useValidateCatalogHasRestrictions, useValidateProjectPermission } from '@client/shared/permissions';
import { TimeLineView } from './Timeline';
import { OptionalColumn, SectionTitle } from './CalculateSectionHeader';
import { CalculateSectionHeaders } from './CalculateSectionHeaders';
import { CalculateSection } from './CalculateSection';
import { CalculateTotal } from './CalculateTotal';
import { FinancingElementDeleteModal } from './FinancingElementDeleteModal';
import { FinancingElementChangeLockModal } from './FinancingElementChangeLockModel';
import { CalculateContext } from './CalculateContext';
import { CalculateFinancingRenderElements } from './Financing';
import { getCalculateSectionHeight } from '../utils';

interface CalculateFinancingProps {
  view: TimeLineView;
  elements?: DecoratedElement<FinancingElement>[];
  selectedVersionElements?: DecoratedElement<FinancingElement>[];
  variant?: CalculationModelReadModel;
  archivedVersions?: CalculationModelMetadata[];
  selectedVersionId: string;
  setSelectedVersionId: (VersionId: string) => void;
  optionalColumn: OptionalColumn;
  setOptionalColumn: (column: OptionalColumn) => void;
  obligoColumn: OptionalColumn;
  setObligoColumn: (column: OptionalColumn) => void;
  open?: boolean;
  onExpand: (catalogIds: string[]) => void;
  onCollapse: (level: number) => void;
  onCollapseContainer: (state: boolean) => void;
  expandedCatalogIds?: string[];
  searchValue?: string
}

export const CalculateFinancing = (props: CalculateFinancingProps) => {
  const {
    view,
    elements = [],
    variant,
    archivedVersions,
    selectedVersionId,
    setSelectedVersionId,
    optionalColumn,
    setOptionalColumn,
    obligoColumn,
    setObligoColumn,
    open = true,
    onCollapse,
    onCollapseContainer,
    expandedCatalogIds = [],
    searchValue
  } = props;
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { isListCollapsed } = useContext(CalculateContext);

  const containerRef = useRef<HTMLDivElement>(null);

  const catalogHasRestrictions = useValidateCatalogHasRestrictions(
    loadedProjectId ?? '',
    variant?.modelMetadata.financingCatalogId
  );
  const canWriteFinancing = useValidateProjectPermission(['FINANCING_WRITE'], loadedProjectId ?? '');
  const isReadOnly = useReadOnly() || !canWriteFinancing;

  const [isOpenSlideOver, setIsOpenSlideOver] = useState(false);
  const [isOpenTransactionSlideOver, setIsOpenTransactionSlideOver] = useState(false);
  const [selectedElement, setSelectedElement] = useState<DecoratedElement<FinancingElement> | undefined>();
  const [element, setElement] = useState<FinancingElementReadModel | undefined>();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isChangeLockModal, setIsChangeLockModal] = useState(false);
  const [isLockingElement, setIsLockingElement] = useState(false);

  const [deleteElementId, setDeleteElementId] = useState<string | undefined>();
  const [lockElementId, setLockElementId] = useState<string | undefined>();
  const [preselectedGroupId, setPreselectedGroupId] = useState<string | undefined | null>();

  const handleOpenSlideOver = (
    elementId: DecoratedElement<FinancingElement> | undefined,
    preselectedGroupId: string | null | undefined
  ) => {
    if (elementId) {
      setSelectedElement(elementId);
    } else {
      setSelectedElement(undefined);
    }
    setPreselectedGroupId(preselectedGroupId);
    setIsOpenSlideOver(true);
  };

  const handleCloseSlideOver = () => {
    setIsOpenSlideOver(false);
  };

  const handleOpenTransactionSlideOver = (element?: FinancingElementReadModel) => {
    if (!element) {
      return;
    }

    setElement(element);
    setIsOpenTransactionSlideOver(true);
  };

  const handleCloseTransactionSlideOver = () => {
    setIsOpenTransactionSlideOver(false);
  };

  const handleCloseDeleteElement = () => {
    setIsOpenDeleteModal(false);
    setDeleteElementId(undefined);
  };

  const handleCloseChangeLockElement = () => {
    setIsChangeLockModal(false);
    setSelectedElement(undefined);
  };

  const handleOpenDeleteElement = (deleteElementId?: string | null) => {
    if (!deleteElementId) {
      return;
    }

    setDeleteElementId(deleteElementId);
    setIsOpenDeleteModal(true);
  };

  const handleLockElement = (lock: boolean, elementId?: string | null) => {
    setLockElementId(elementId ?? undefined);
    setIsLockingElement(lock);
    setIsChangeLockModal(true);
  };

  const totalSectionTimeline = useMemo(() => {
    return (
      <div className="w-full flex">
        <div className="w-2" />
        <div className="w-full flex justify-end self-end">
          <div className="w-2/3 md:w-1/2" />
          <div className="w-1/3 md:w-1/2 flex justify-end pr-4">
            {!isListCollapsed && (
              <>
                <div className="hidden md:block w-3/12">
                  <CalculateTotal
                    catalogHasRestrictions={catalogHasRestrictions}
                    totalText={t('projectCalculate.financingElementLabelTotalGrants')}
                    colorClass="text-gray-700 opacity-30"
                    totalCost={variant?.modelMetadata.totalGrants.value}
                    paddingClass=""
                  />
                </div>
                <div className="hidden md:block w-4/12">
                  <CalculateTotal
                    catalogHasRestrictions={catalogHasRestrictions}
                    totalText={t('projectCalculate.calculateFinancingTotalFinancingCosts')}
                    colorClass="text-gray-700 opacity-30"
                    totalCost={variant?.modelMetadata.totalFinancingCost.value}
                    paddingClass=""
                  />
                </div>
              </>
            )}
            <div className="w-5/12">
              <CalculateTotal
                catalogHasRestrictions={catalogHasRestrictions}
                totalText={t('projectCalculate.calculateFinancingTitle')}
                colorClass="text-financing"
                totalCost={variant?.modelMetadata.totalFinancing.value}
                paddingClass=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }, [
    catalogHasRestrictions,
    t,
    variant?.modelMetadata.totalFinancing,
    variant?.modelMetadata.totalFinancingCost,
    variant?.modelMetadata.totalGrants,
    isListCollapsed
  ]);

  const height = useMemo(() => {
    return getCalculateSectionHeight(elements, 0, 0, expandedCatalogIds);
  }, [expandedCatalogIds, elements]);

  return (
    <div className="relative flex cursor-default transition-[margin] duration-300">
      <div className="w-full" ref={containerRef}>
        <CalculateSectionHeaders
          view={view}
          archivedVersions={archivedVersions}
          selectedVersionId={selectedVersionId}
          setSelectedVersionId={setSelectedVersionId}
          optionalColumn={optionalColumn}
          setOptionalColumn={setOptionalColumn}
          obligoColumn={obligoColumn}
          setObligoColumn={setObligoColumn}
          title={SectionTitle.FINANCING}
          icon={<BankBuildingIcon className="w-7 h-7" />}
          onCollapse={onCollapse}
          onCollapseContainer={() => onCollapseContainer(!open)}
          showLevelToggles={elements.length > 0 && !searchValue}
        />
        <CalculateSection
          catalogHasRestrictions={catalogHasRestrictions}
          open={open}
          view={view}
          bgColor="bg-financing"
          totalSectionTimeline={totalSectionTimeline}
          totalText={t('projectCalculate.calculateFinancingTitle')}
          totalColor="text-financing"
          totalCost={variant?.modelMetadata.totalFinancing.value}
          height={elements.length > 0 ? height : undefined}
        >
          {elements.length > 0 ? (
            <CalculateFinancingRenderElements
              isReadOnly={false}
              containerRef={containerRef}
              elements={elements}
              handleOpenSlideOver={handleOpenSlideOver}
              handleOpenDeleteElement={handleOpenDeleteElement}
              handleLockElement={handleLockElement}
              {...props}
            />
          ) : (
            <div className="text-lg font-bold p-4 h-12">{t('projectCalculate.NoElements')}</div>
          )}
        </CalculateSection>
      </div>
      <SlideOver
        isOpen={isOpenSlideOver}
        onClose={handleCloseSlideOver}
        onAfterLeave={() => setSelectedElement(undefined)}
        preventClickOutsideClose={isOpenTransactionSlideOver}
      >
        <FinancingElementSlideOver
          onClose={handleCloseSlideOver}
          variantId={loadedVariantId}
          selectedElement={selectedElement}
          financingCatalogId={variant?.payload.financingCatalogId ?? undefined}
          disabled={isReadOnly}
          catalogId={variant?.payload.costCatalogId}
          onShowTransactions={handleOpenTransactionSlideOver}
          errors={selectedElement?.element.financingElement?.errors ?? []}
          preselectedGroupId={preselectedGroupId}
        />
      </SlideOver>

      <SlideOver
        isOpen={isOpenTransactionSlideOver}
        onClose={handleCloseTransactionSlideOver}
        variant="x-large"
        confirmBeforeClose={false}
      >
        <FinancingTransactionsSlideOver element={element} onClose={handleCloseTransactionSlideOver} />
      </SlideOver>

      <Modal isOpen={isOpenDeleteModal} onClose={handleCloseDeleteElement}>
        <FinancingElementDeleteModal
          elementId={deleteElementId}
          variantId={loadedVariantId}
          onClose={handleCloseDeleteElement}
        />
      </Modal>

      <Modal isOpen={isChangeLockModal} onClose={handleCloseDeleteElement}>
        <FinancingElementChangeLockModal
          elementId={lockElementId}
          variantId={loadedVariantId}
          onClose={handleCloseChangeLockElement}
          lock={isLockingElement}
        />
      </Modal>
    </div>
  );
};
