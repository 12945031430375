import { useTranslation } from 'react-i18next';
import React, { ReactElement, useMemo } from 'react';
import { RoundedCloseIcon } from '../icons';
import cn from 'classnames';

export interface FloatingMenuOptions {
  label: string;
  icon: ReactElement;
  show: boolean;
  onClick?: () => void;
  disabled?: boolean;
  iconClassName?: string;
}

type FloatingMenuProps = {
  selectedItemsCount: number;
  clearSelection: () => void;
  menuOptions: FloatingMenuOptions[];
  selectedElementsLabel?: string;
};
export const FloatingMenu = (props: FloatingMenuProps) => {
  const { t } = useTranslation();
  const { clearSelection, selectedItemsCount, menuOptions, selectedElementsLabel } = props;

  const shownMenuOptions = useMemo(() => {
    return menuOptions.filter((option) => option.show);
  }, [menuOptions]);

  return (
    <div className="floating-projects-menu rounded-lg flex bg-white flex-nowrap overflow-hidden">
      <div className="w-12 bg-sky-800 flex justify-center items-center text-white rounded-l border border-sky-800">
        {selectedItemsCount}
      </div>
      <div className="flex justify-between gap-x-7 border-y flex-nowrap pl-7">
        <div className="w-28 border-r-2 flex justify-center items-center">
          <div className="h-full flex flex-col flex-grow font-bold justify-center">
            {selectedElementsLabel
              ? selectedElementsLabel
              : t('app.projectFloatingMenuSelectedElementsLabel', { count: selectedItemsCount })}
          </div>
        </div>
        {shownMenuOptions.map((m) => (
          <button
            key={m.label}
            className={cn('flex flex-col justify-center items-center py-1 px-1 pt-2 text-inherit', {
              'text-slate-300 cursor-not-allowed': m.disabled,
              'cursor-pointer': !m.disabled,
            })}
            onClick={m.onClick}
            disabled={m.disabled}
          >
            {React.cloneElement(m.icon as ReactElement, { className: m.iconClassName ? m.iconClassName : 'w-6 h-6 pb-1' })}
            <span className="text-xs">{m.label}</span>
          </button>
        ))}
        <button
          className="w-10 flex items-center justify-center cursor-pointer border-r rounded-r-lg border-l-2"
          onClick={clearSelection}
        >
          <RoundedCloseIcon className="w-6" />
        </button>
      </div>
    </div>
  );
};
