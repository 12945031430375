import { useApiPostCreateCalculationModelCopyMutation, CalculationModelMetadata } from '@client/shared/api';
import React, { useState } from 'react';
import {
  Modal,
  Button,
  HintBox,
  TextInput,
  EditNodeIcon,
  ModalOnCloseProps,
  LoadingIndicator
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import { setVariantId } from '@client/project/store';
import { useGenericDispatch } from '@client/shared/store';

interface VariantCreateCopyModalProps extends ModalOnCloseProps {
  variant: CalculationModelMetadata;
}

export const VariantCreateCopyModal = ({ onClose, variant }: VariantCreateCopyModalProps) => {
  const { t } = useTranslation();

  const dispatch = useGenericDispatch();
  const [call, { isLoading }] = useApiPostCreateCalculationModelCopyMutation();
  const [title, setTitle] = useState(t('projectVariants.variantCopyOf', { name: variant.name }));

  const handleOnClose = () => {
    onClose(false);
  };

  const handleOnCopy = async () => {
    try {
      const result = await safeMutation(
        call,
        {
          projectId: variant.projectId,
          calculationModelId: variant.id,
          body: {
            name: title,
          },
        },
        isLoading
      );

      if (result) {
        dispatch(setVariantId(result.calculationModelId));
      }

      onClose(true);
    } catch {
      /* left blank */
    }
  };

  return (
    <>
      {isLoading && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
      <Modal.Header title={t('projectVariants.variantCopyVariantTitle')} />
      <Modal.Content className="space-y-6">
        <TextInput
          label={t('projectVariants.variantCopyLabelName')}
          value={title}
          onChange={(value) => setTitle(value)}
          icon={<EditNodeIcon />}
        />
        <HintBox>{t('projectVariants.variantCopyHint')}</HintBox>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={handleOnClose} className="mr-2" variant="secondary">
          {t('common.cancel')}
        </Button>
        <Button onClick={handleOnCopy} disabled={title === ''}>
          {t('projectVariants.variantCopyVariantTitle')}
        </Button>
      </Modal.Controls>
    </>
  );
};
