import {
  AirportCustomIcon,
  AirTrafficControlTowerDottedIcon,
  CheckmarkIcon,
  ClusterList,
  ClusterListInnerListItemContent,
  ClusterListProps,
  EyeIcon,
  HierarchyListProps,
  LoadingIndicator,
  WarehouseDottedIcon,
} from '@client/shared/toolkit';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useTranslation } from 'react-i18next';
import { ClusterItemTreeReadModel, ClusterType, useApiGetClusterItemsTreeQuery } from '@client/shared/api';
import { useLoadedPortfolioId } from '@client/portfolio/store';
import { i18n } from '@client/shared/utilities';
import { getClusterItemIdsForUser, getRequirementStatusBadge } from '@client/portfolio/shared';
import { useUi } from '@client/shared/store';

const getClusterItemIcon = (name: string, type: ClusterType) => {
  switch (name) {
    case 'Zentrale Infrastruktur':
    case 'Flugbetriebsflächen und Airfieldtechnik':
    case 'Technische Infrastruktur':
    case 'Service & Verwaltungsgebäude':
    case 'Terminal':
      return <AirTrafficControlTowerDottedIcon />;
    case 'Sonstiges': {
      if (type === 'Building') {
        return <WarehouseDottedIcon />;
      }
      return <AirTrafficControlTowerDottedIcon />;
    }
    default:
      return <WarehouseDottedIcon />;
  }
}

const getClusterItemType = (type: ClusterType) => {
  switch (type) {
    case 'SpecialBuilding':
      return i18n.t('portoflio.property.cluster.typeSpecialBuilding');
    case 'Building':
      return i18n.t('portoflio.property.cluster.typeBuilding');
    case 'BuildingComplex':
      return i18n.t('portoflio.property.cluster.typeBuildingComplex');
    default:
      return '';
  }
}

export const PropertyContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const loadedPortfolioId = useLoadedPortfolioId();
  const ui = useUi();

  const { data, isFetching } = useApiGetClusterItemsTreeQuery(
    {
      portfolioId: loadedPortfolioId ?? '',
    },
    {
      skip: !loadedPortfolioId,
    },
  );

  const openRequirement = useCallback((clusterItemId: string, requirementId: string) => {
    if (loadedPortfolioId) {
      navigate(
        ROUTES_CONFIG.PORTFOLIO_PROJECTING_REQUIREMENT_VIEW.path
          .replace(':id', loadedPortfolioId ?? '')
          .replace(':clusterItemId', clusterItemId)
          .replace(':requirementId', requirementId),
      );
    }
  }, [navigate, loadedPortfolioId])

  const listItems: ClusterListProps[] = useMemo(() => {
    const getClusterChildren = (children: ClusterItemTreeReadModel[], userClusterItemIds: string[]) => {
      const items: ClusterListProps[] = [];
      if (children.length) {
        children.forEach((clusterItem) => {
          let childrenListItems: HierarchyListProps[] = [];
          if (clusterItem.children.length) {
            childrenListItems = getClusterChildren(clusterItem.children, userClusterItemIds);
          } else if (clusterItem.requirements.length) {
            // only those which user is allowed to see
            if (userClusterItemIds.includes(clusterItem.id)) {
              childrenListItems = clusterItem.requirements.map((requirement) => {
                return {
                  icon: <CheckmarkIcon />,
                  title: requirement.name,
                  subtitle: requirement.code,
                  onClick: () => openRequirement(clusterItem.id, requirement.id),
                  children: (
                    <ClusterListInnerListItemContent
                      date={requirement.deadline ? requirement.deadline : undefined}
                      status={getRequirementStatusBadge(requirement.state)}
                      contextMenuItems={[
                        {
                          label: t('portfolio.property.viewRequirement'),
                          subtitle: t('portfolio.property.viewRequirementSubtitle'),
                          icon: <EyeIcon />,
                          onClick: () => openRequirement(clusterItem.id, requirement.id),
                        },
                      ]}
                    />
                  ),
                }
              })
            }
          }
          items.push({
            icon: getClusterItemIcon(clusterItem.name, clusterItem.type),
            title: clusterItem.name,
            subtitle: getClusterItemType(clusterItem.type),
            listItems: childrenListItems,
            theme: childrenListItems.length && clusterItem.requirements.length ? 'requirement' : undefined
          });
        })
      }
      return items;
    }

    const items: ClusterListProps[] = [];
    if (data?.clusterItemsTree && ui.appUser.userId) {
      const userClusterItemIds = getClusterItemIdsForUser(ui.appUser.userId);
      items.push({
        icon: <AirportCustomIcon />,
        title: data.clusterItemsTree.name,
        subtitle: data.clusterItemsTree.description,
        listItems: getClusterChildren(data.clusterItemsTree.children, userClusterItemIds)
      });
    }
    return items;
  }, [t, data?.clusterItemsTree, openRequirement, ui.appUser.userId]);

  return (
    <>
      {isFetching && <LoadingIndicator mode="overlay-window" text={t('portfolio.property.clusterLoadingIndicator')}/>}
      <ClusterList listItems={listItems} />
    </>
  )
};
