import { DealType } from '@client/shared/api';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import classNames from 'classnames';
import { Deals } from './Deals';
import { DealObjects } from './Objects';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { ProtectedRoute, ROUTES_CONFIG } from '@client/shared/permissions';
import { DealView } from './DealView';
import React, { useEffect, useState } from 'react';
import { useLoadedProjectId } from '@client/project/store';

const tabs = [
  {
    key: 'deals',
    title: 'projectRent.tabContracts',
  },
  {
    key: 'objects',
    title: 'projectRent.tabObjects',
  },
];

interface DealModuleProps {
  type: DealType;
}

export const DealModule = ({ type }: DealModuleProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDealSlideOverOpen, setIsDealSlideOverOpen] = useState(false)
  const [view, setView] = useState('')
  const handleTabChange = (key: string) => {
    searchParams.set('view', key);
    setSearchParams(searchParams, { replace: true });
    setView(key)
  }

  useEffect(() => {
    const searchParamsName = searchParams.get('view')
    if (searchParamsName && searchParamsName === 'objects') {
      setView(searchParamsName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      <TabGroup selectedIndex={view === 'objects' ? 1 : 0} manual>
        <div className="mb-4">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <TabList className="block w-full pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-sky-700 focus:border-sky-700 sm:text-sm rounded-md space-x-4">
              {tabs.map((tab) => (
                <Tab
                  key={tab.key}
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? 'border-b-sky-700 text-sky-700'
                        : 'border-transparent text-slate-500 hover:text-slate-700 hover:border-slate-300',
                      'whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm'
                    )
                  }
                  onClick={() => handleTabChange(tab.key)}
                >
                  {t(tab.title)}
                </Tab>
              ))}
            </TabList>
          </nav>
        </div>
        <TabPanels className="flex-grow flex-col flex">
          <TabPanel key="deals" className="flex-grow flex flex-col mb-8">
            <Deals type={type}/>
          </TabPanel>
          <TabPanel key="objects" className="flex-grow flex flex-col mb-8">
            <DealObjects isDealSlideOverOpen={isDealSlideOverOpen} type={type} />
          </TabPanel>
        </TabPanels>
      </TabGroup>
      {type === 'Rent' && (
        <Routes>
          <Route
            path={ROUTES_CONFIG.PROJECT_SELL_DEAL_VIEW.name}
            element={
              <ProtectedRoute
                routeConfig={ROUTES_CONFIG.PROJECT_SELL_DEAL_VIEW}
                projectId={loadedProjectId}
              >
                <DealView setIsDealSlideOverOpen={setIsDealSlideOverOpen} type="Rent" />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to={view ? `?view=${view}` : ''} />} />
        </Routes>
      )}
      {type === 'Sell' && (
        <Routes>
          <Route
            path={ROUTES_CONFIG.PROJECT_SELL_DEAL_VIEW.name}
            element={
              <ProtectedRoute
                routeConfig={ROUTES_CONFIG.PROJECT_SELL_DEAL_VIEW}
                projectId={loadedProjectId}
              >
                <DealView setIsDealSlideOverOpen={setIsDealSlideOverOpen} type="Sell" />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to={view ? `?view=${view}` : ''} />} />
        </Routes>
      )}
    </>
  );
};
