import {
  AreasCard,
  CostsCard,
  DescriptionCard,
  HeaderInfo,
  ImageUploadModal,
  LocationCard,
  MarketPricesCard,
  MarketRentCard,
  ParkingFeaturesCard,
  ProjectDataCard,
  RentalPeriodCard,
  RentersCard,
  RentersContactsCard,
  RoiCard,
  RuntimeCard,
  UsageTypeCard,
} from './dashboard';
import { Button, Modal, PencilIcon, SlideOver } from '@client/shared/toolkit';
import React, { useState } from 'react';
import { useLoadedProject } from '@client/project/store';
import { getThumbnail } from '../utils';
import { BuildingItemSlideOver } from './building';
import { TaxonomyDashboardProps } from './TaxonomyDashboard';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const TaxonomyBuildingData = (props: TaxonomyDashboardProps) => {
  const { item, isReadOnly } = props;

  const loadedProject = useLoadedProject();
  const project =
    item?.itemType === 'Project'
      ? loadedProject?.data?.project?.calculationModels.find((x) => x.type === 'Version')
      : undefined;
  const thumbnail = getThumbnail(item, loadedProject?.data?.project);

  const [isBuildingItemSlideOverOpen, setIsBuildingItemSlideOverOpen] = useState(false);
  const [isImageUploadModalOpen, setIsImageUploadModalOpen] = useState(false);
  const [selectedField, setSelectedField] = useState('');

  const showforItemTypes = (itemTypes: (string | undefined)[]) => {
    return itemTypes.includes(item?.itemType);
  };

  const TopRowComponents = [
    {
      component: <CostsCard item={item} project={project} />,
      show: showforItemTypes(['Project', 'Building', 'BuildingComplex', 'Other', 'Sector']),
    },
    {
      component: <MarketRentCard item={item} />,
      show: showforItemTypes([
        'Building',
        'Area',
        'Quarter',
        'BuildingComplex',
        'Wing',
        'Floor',
        'Unit',
        'Zone',
        'Sector',
        'Bed',
        'Other',
      ]),
    },
    {
      component: <RoiCard project={project} />,
      show: showforItemTypes(['Project']),
    },
    {
      component: <RuntimeCard project={loadedProject?.data?.project} />,
      show: showforItemTypes([
        'Project',
        'Building',
        'Area',
        'Quarter',
        'BuildingComplex',
        'Wing',
        'Floor',
        'Unit',
        'Zone',
        'Parking',
        'Sector',
        'Bed',
        'Other',
      ]),
    },
    {
      component: <RentalPeriodCard item={item} />,
      show: showforItemTypes([]),
    },
  ];

  const leftColumnComponents = [
    {
      component: <ProjectDataCard project={loadedProject?.data?.project.payload} />,
      show: showforItemTypes(['Project']),
    },
    {
      component: (
        <DescriptionCard
          item={item}
          setSelectedField={setSelectedField}
          setIsBuildingItemSlideOverOpen={setIsBuildingItemSlideOverOpen}
        />
      ),
      show: showforItemTypes([
        'Project',
        'Building',
        'Area',
        'Quarter',
        'BuildingComplex',
        'Wing',
        'Floor',
        'Unit',
        'Room',
        'Zone',
        'Parking',
        'Sector',
        'Bed',
        'Other',
      ]),
    },
    {
      component: <RentersCard item={item} />,
      show:
        item?.dealCosts &&
        item?.dealCosts?.filter(
          (deal) =>
            deal.type === 'Rent' && deal.costItems.some((costItem) => costItem.taxonomyItemId === item.itemLogicalId),
        ).length > 0,
    },
    {
      component: <RentersContactsCard item={item} />,
      show:
        item?.dealCosts &&
        item?.dealCosts?.filter(
          (deal) =>
            deal.type === 'Rent' &&
            deal.costItems.some((costItem) => costItem.taxonomyItemId === item.itemLogicalId) &&
            deal.contacts.length > 0,
        ).length > 0,
    },
    {
      component: <LocationCard project={loadedProject?.data?.project.payload} />,
      show: showforItemTypes(['Project']),
    },
  ]
  const rightColumnComponents = [
    {
      component: <MarketPricesCard />,
      show: showforItemTypes(['Project']),
    },
    {
      component: (
        <AreasCard
          item={item}
          setSelectedField={setSelectedField}
          setIsBuildingItemSlideOverOpen={setIsBuildingItemSlideOverOpen}
        />
      ),
      show: showforItemTypes([
        'Project',
        'Building',
        'Area',
        'Quarter',
        'BuildingComplex',
        'Wing',
        'Floor',
        'Unit',
        'Room',
        'Zone',
        'Parking',
        'Sector',
        'Bed',
        'Other',
      ]),
    },
    {
      component: (
        <UsageTypeCard
          item={item}
          setSelectedField={setSelectedField}
          setIsBuildingItemSlideOverOpen={setIsBuildingItemSlideOverOpen}
        />
      ),
      show: showforItemTypes([
        'Project',
        'Building',
        'Area',
        'Quarter',
        'BuildingComplex',
        'Wing',
        'Floor',
        'Unit',
        'Zone',
        'Sector',
        'Other',
      ]),
    },
    {
      component: (
        <ParkingFeaturesCard
          item={item}
          setSelectedField={setSelectedField}
          setIsBuildingItemSlideOverOpen={setIsBuildingItemSlideOverOpen}
        />
      ),
      show: showforItemTypes(['Parking']),
    }
  ];

  return (
    <>
      <div className="flex-grow overflow-y-auto">
        <div className="flex-shrink flex flex-col space-y-3">
          <div className="w-full flex flex-col flex-grow flex-shrink z-0 relative">
            <div className="px-2 pb-2 w-full overflow-hidden h-[250px] relative">
              <LazyLoadImage src={thumbnail} alt="Background" className="w-full h-full object-cover" />
              <Button
                className="absolute top-0 right-0 m-6 aspect-square shadow-lg h-14 w-14 bg-white hover:bg-slate-100"
                variant="custom"
                onClick={() => setIsImageUploadModalOpen(true)}
              >
                <PencilIcon />
              </Button>
              <HeaderInfo item={item} project={project} />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 p-6 gap-6">
          {TopRowComponents.filter((element) => element.show).length > 1 && (
            <div className="col-span-3 grid grid-cols-3 gap-6">
              {TopRowComponents.filter((element) => element.show).map((element, i) => {
                return <div key={i}>{element.component}</div>;
              })}
            </div>
          )}
          <div className="col-span-2 grid grid-cols-1 gap-6 h-min">
            {leftColumnComponents
              .filter((element) => element.show)
              .map((element, i) => {
                return <div key={i}>{element.component}</div>;
              })}
          </div>
          <div className="col-span-1 grid grid-cols-1 gap-6 h-min">
            {TopRowComponents.filter((element) => element.show).length === 1 &&
              TopRowComponents.filter((element) => element.show).map((element, i) => {
                return <div key={i}>{element.component}</div>;
              })}

            {rightColumnComponents
              .filter((element) => element.show)
              .map((element, i) => {
                return <div key={i}>{element.component}</div>;
              })}
          </div>
        </div>
      </div>
      <SlideOver isOpen={isBuildingItemSlideOverOpen} onClose={() => setIsBuildingItemSlideOverOpen(false)}>
        <BuildingItemSlideOver
          item={item}
          isReadOnly={isReadOnly}
          onClose={() => setIsBuildingItemSlideOverOpen(false)}
          field={selectedField}
          canDelete={true}
        />
      </SlideOver>
      <Modal isOpen={isImageUploadModalOpen} onClose={() => setIsImageUploadModalOpen(false)} zIndex="z-50">
        <ImageUploadModal item={item} onClose={() => setIsImageUploadModalOpen(false)} />
      </Modal>
    </>
  )
}
