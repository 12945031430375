import { Avatar, Card, CardActionButton, CardDataSet,  } from '.';
import { PencilIcon } from '../icons'
import cn from 'classnames';
import { ReactNode } from 'react';

interface AddressCardProps {
  className?: string
  name?: string
  logo?: string
  label?: string
  handleEditButtonClick?: () => void
  companyName?: string
  address?: {
    supplement?: string | null;
    street?: string | null;
    number?: string | null;
    postalCode?: string | null;
    city?: string | null;
    country?: string | null;
  }
  centered?: boolean
  customName?: string | ReactNode
}
export const AddressCard = (props: AddressCardProps) => {
  const {
    name,
    logo,
    className,
    label,
    handleEditButtonClick,
    companyName,
    address,
    centered = false,
    customName
  } = props

  return (
    <Card className={cn('w-ful', className)}>
      <div className="flex flex-wrap gap-5">
        {(name || logo) && (
          <Avatar
            name={name ?? ''}
            className={cn('w-12 h-12 flex-none', centered ? '' : 'mt-4')}
            rounded={false}
            randomBgColor={false}
            imagePath={logo}
          />
        )}
        <CardDataSet label={label} className="flex-1">
          {customName ? customName : (
            <span className="font-bold text-lg leading-tight mb-1.5">
              {name
                ? companyName && companyName !== name
                  ? `${companyName} - ${name}`
                  : name
                : '-'}
            </span>
          )}
          {address && (
            <>
              <span className="text-gray-500 text-[15px] leading-tight">
                {address.street}{address.street && address.number ? ` ${address.number}` : address.number }
              </span>
              <span className="text-gray-500 text-[15px] leading-tight">
                {address.postalCode}{address.postalCode && address.city ? ` ${address.city}` : address.city }
              </span>
              <span className="text-gray-500 text-[15px] leading-tight">{address.country}</span>
            </>
          )}
        </CardDataSet>
        {handleEditButtonClick && <CardActionButton icon={<PencilIcon />} onClick={handleEditButtonClick} />}
      </div>
    </Card>
  );
}
