import { t } from 'i18next';
import { DistributionModal } from '@client/project/shared';
import { VatReturnInput } from './VatReturnInput';
import { AddButton, Button, LoadingIndicator } from '@client/shared/toolkit';
import { VatReturnModalRow } from './VatReturnModalRow';
import { useCallback, useEffect, useState } from 'react';
import {
  GetSuggestedVatReturnDistributionQueryResult,
  useApiGetVatReturnDistributionSuggestionMutation,
  VatDistribution,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { FormattedCurrency } from '@client/project/shared';
import { formatDateOnly } from '@client/shared/utilities';

interface VatReturnModalProps {
  vatReturnPercentage: number;
  setVatReturnPercentage?: (value: number) => void;
  selectedCostElementIds: string[];
  setSelectedCostElementIds: (value: string[]) => void;
  selectedUDF: string;
  setSelectedUDF: (value: string) => void;
  selectedUDFLabels: string[];
  setSelectedUDFLabels: (value: string[]) => void;
  returnDelayMonths: number | null | undefined;
  setReturnDelayMonths?: (value: number | null | undefined) => void;
  onClose: () => void;
  disabled?: boolean;
  vatDistributions?: VatDistribution[];
  onSave: (value: VatDistribution[]) => void;
}

export const VatReturnModal = (props: VatReturnModalProps) => {
  const {
    vatReturnPercentage,
    setVatReturnPercentage,
    selectedCostElementIds,
    setSelectedCostElementIds,
    selectedUDF,
    setSelectedUDF,
    selectedUDFLabels,
    setSelectedUDFLabels,
    returnDelayMonths,
    setReturnDelayMonths,
    onClose,
    disabled,
    vatDistributions,
    onSave,
  } = props;

  const [vatReturnDistributionQuery, setVatReturnDistributionQuery] =
    useState<GetSuggestedVatReturnDistributionQueryResult | null>(null);
  const [vatDistribution, setVatDistribution] = useState<VatDistribution[]>(vatDistributions ?? []);

  const [restAmount, setRestAmount] = useState<number>(0);

  const [getSuggestion, { isLoading: isFetchingSuggestion }] = useApiGetVatReturnDistributionSuggestionMutation();

  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();

  const fetchPaymentDistribution = useCallback(() => {
    const fetchDistributionValues = async () => {
      try {
        return await getSuggestion({
          projectId: loadedProjectId ?? 'unset',
          calculationModelId: loadedCalculationModelId ?? 'unset',
          body: {
            costCatalogElementIds: selectedCostElementIds,
            monthsDelay: returnDelayMonths ?? 0,
            returnPercentage: vatReturnPercentage ?? 0,
            userDefinedFieldId: selectedUDF === 'none' ? null : selectedUDF,
            userDefinedFieldLabelIds: selectedUDFLabels.length === 0 || selectedUDF === 'none'  ? null : selectedUDFLabels,
          },
        }).unwrap();
      } catch (error) {
        console.log(error);
      }
    };
    fetchDistributionValues().then((result) => {
      if (result) {
        setVatReturnDistributionQuery(result);
        setVatDistribution(result.vatReturnSuggestion);
      }
    });
  }, [
    selectedCostElementIds,
    getSuggestion,
    loadedCalculationModelId,
    loadedProjectId,
    returnDelayMonths,
    vatReturnPercentage,
    selectedUDF,
    selectedUDFLabels,
  ]);

  const onAdd = () => {
    const lastDate =
      vatDistribution.length > 0 ? new Date(vatDistribution[vatDistribution.length - 1].date) : new Date();
    const extendedDistribution = [
      ...vatDistribution,
      {
        date: formatDateOnly(new Date(lastDate.getFullYear(), lastDate.getMonth(), 1)),
        amount: 0,
      },
    ];
    setVatDistribution(extendedDistribution);
  };

  const handleOnSave = () => {
    onSave(vatDistribution);
    onClose();
  };

  useEffect(() => {
    fetchPaymentDistribution();
  }, [returnDelayMonths, vatReturnPercentage, selectedCostElementIds, fetchPaymentDistribution]);

  useEffect(() => {
    const calculateRestReturn = () => {
      const vatReturnSuggestionSum = vatDistribution.reduce((a, b) => a + (b.amount ?? 0), 0);
      const readModelVatDistributionSum = vatDistributions?.reduce((a, b) => a + (b.amount ?? 0), 0) ?? 0;
      const totalVatReturn = vatReturnDistributionQuery?.totalVatReturn ?? readModelVatDistributionSum;
      let restAmount = totalVatReturn - vatReturnSuggestionSum;
      restAmount = Math.round(restAmount * 100) / 100;
      return restAmount;
    };
    setRestAmount(calculateRestReturn());
  }, [vatDistributions, vatDistribution, vatReturnDistributionQuery]);

  return (
    <DistributionModal
      title={t('projectCalculate.vatReturnTitle')}
      description={t('projectCalculate.vatReturnDescription')}
      header={
        <VatReturnInput
          vatReturnPercentage={vatReturnPercentage}
          setVatReturnPercentage={setVatReturnPercentage}
          returnDelayMonths={returnDelayMonths}
          setReturnDelayMonths={setReturnDelayMonths}
          selectedCostElementIds={selectedCostElementIds}
          setSelectedCostElementIds={setSelectedCostElementIds}
          selectedUDF={selectedUDF}
          setSelectedUDF={setSelectedUDF}
          selectedUDFLabels={selectedUDFLabels}
          setSelectedUDFLabels={setSelectedUDFLabels}
          type="create"
          className="w-full px-12 pb-3 border-b-2 shadow-lg z-10 relative"
        />
      }
      controls={
        <div className="w-full flex justify-between">
          <div className="flex space-x-2 w-full justify-end">
            <Button onClick={onClose} variant="secondary">
              {t('common.cancel')}
            </Button>
            <Button
              onClick={handleOnSave}
              variant="primary"
              disabled={
                disabled ||
                restAmount !== 0 ||
                isFetchingSuggestion ||
                selectedCostElementIds.length === 0
              }
            >
              {t('common.apply')}
            </Button>
          </div>
        </div>
      }
    >
      {isFetchingSuggestion ? (
        <div className="h-full flex justify-center items-center">
          <LoadingIndicator mode="inline" />
        </div>
      ) : (
        <div className="h-full flex flex-col justify-between">
          <div className="flex flex-col h-full overflow-auto">
            {vatDistribution.map((element, index) => (
              <VatReturnModalRow
                key={`vat-return-${index}`}
                vatReturnDistribution={element}
                onChange={(value: VatDistribution) => {
                  const result = vatDistribution.map((c, i) => {
                    if (i === index) c = value;
                    return c;
                  });
                  setVatDistribution(result);
                }}
                onItemDeleted={() => {
                  const result = vatDistribution.filter((c, i) => {
                    return i !== index;
                  });
                  setVatDistribution(result);
                }}
              />
            ))}
            <div className="sticky bottom-0 flex-row-reverse flex mr-2 pointer-events-none">
              <AddButton onClick={onAdd} className="pointer-events-auto" />
            </div>
          </div>
          {restAmount !== 0 && (
            <div className="flex items-center py-2 pl-2 justify-end">
              <div className="font-medium text-gray-900 pr-2">
                {t('projectCalculate.financingElementLabelRestAmount')}:
              </div>
              <div className="flex flex-row items-center p-2 space-x-2">
                <div>
                  <FormattedCurrency amount={restAmount} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </DistributionModal>
  );
};
