import {
  CalculationModelMetadata,
  useApiGetCalculationModelHistoryQuery,
  useApiPostUpdateCaluclationModelFavoriteMutation,
} from '@client/shared/api';
import { humanizeJsonDate, i18n } from '@client/shared/utilities';
import {
  CalculationModelIcon,
  ContextMenu,
  ContextMenuItem,
  LoadingIndicator, SaveCustomIcon
} from '@client/shared/toolkit';
import classNames from 'classnames';
import { ArrowRightIcon, StarIcon } from '@heroicons/react/24/outline';
import { StarIcon as FullStar, BookmarkIcon } from '@heroicons/react/24/solid';
import { useGenericDispatch } from '@client/shared/store';
import { setVariantId, useLoadedVariantId } from '@client/project/store';
import { useTranslation } from 'react-i18next';

interface CalculationModelSavePointsProps {
  model: CalculationModelMetadata;
  onClose: () => void;
}

interface SavePointProps {
  model: CalculationModelMetadata;
  onFavoriteChange: (model: CalculationModelMetadata) => void;
  onVariantLoad: (model: CalculationModelMetadata) => void;
}

interface SavePointListProps {
  points: CalculationModelMetadata[];
  onFavoriteChange: (model: CalculationModelMetadata) => void;
  onVariantLoad: (model: CalculationModelMetadata) => void;
}

const setupContextMenu = (
  currentModelId: string,
  model: CalculationModelMetadata,
  onFavoriteChange: (model: CalculationModelMetadata) => void,
  onVariantLoad: (model: CalculationModelMetadata) => void
): ContextMenuItem[] => {
  const isCurrentVersion = model.type === 'Version';
  const isVariant = model.type === 'Variant';

  const favLabel =
    model?.favorite === true
      ? i18n.t('projectVariants.actionUnmarkFavorite')
      : i18n.t('projectVariants.actionMarkFavorite');

  const loadLabel =
    isCurrentVersion || isVariant ? i18n.t('projectVariants.actionLoad') : i18n.t('projectVariants.actionLoadReadOnly');

  return [
    {
      label: favLabel,
      icon: <StarIcon />,
      onClick: () => onFavoriteChange(model),
    },
    {
      label: loadLabel,
      icon: <ArrowRightIcon />,
      isDisabled: currentModelId === model.id,
      onClick: () => onVariantLoad(model),
    },
  ];
};

const CalculationModelItem = ({ model, onFavoriteChange, onVariantLoad }: SavePointProps) => {
  const { t } = useTranslation();

  const currentModelId = useLoadedVariantId();
  let borderColor = 'border-red-400';

  switch (model?.type) {
    case 'Version':
      borderColor = 'border-emerald-500';
      break;
    case 'ArchivedVersion':
      borderColor = 'border-slate-700';
      break;
    case 'Variant':
      borderColor = 'border-emerald-800';
      break;
    case 'ArchivedVariant':
      borderColor = 'border-gray-200';
      break;
    case 'SystemSnapshot':
      borderColor = 'border-gray-200';
      break;
    case 'UserSnapshot':
      borderColor = 'border-gray-200';
      break;
    case undefined:
      break;
    default:
      throw new Error('Unknown type: ' + model?.type);
  }

  if (model.favorite) {
    borderColor = 'border-yellow-400';
  }

  const ctxItems = setupContextMenu(currentModelId ?? '', model, onFavoriteChange, onVariantLoad);

  return (
    <div className="border-t border-b border-r rounded-md border-slate-300 z-50">
      <div className={classNames('p-1 border-l-4 rounded-md bg-white', borderColor)}>
        <div className="flex flex-row">
          <div className="mx-3 my-3">
            <CalculationModelIcon className="h-6 w-6" />
          </div>

          <div className="flex flex-col flex-grow">
            <div>{model?.name}</div>
            <div className="text-sm text-gray-400">
              {t('common.timeAgo', { time: humanizeJsonDate(model?.created?.changedAt) })}
            </div>
          </div>

          <div className="flex items-center">
            <ContextMenu items={ctxItems} />
            <div className="absolute -mt-9 -ml-7">
              {model.favorite && <BookmarkIcon className="w-7 text-red-600" />}
            </div>
            <div className="absolute -mt-9 -ml-3">
              {model.favorite && <FullStar className="w-4 h-4 text-yellow-400" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SavePointItem = ({ model, onFavoriteChange, onVariantLoad }: SavePointProps) => {
  const { t } = useTranslation();

  const currentModelId = useLoadedVariantId();
  const ctxItems = setupContextMenu(currentModelId ?? '', model, onFavoriteChange, onVariantLoad);

  return (
    <div className="flex-grow border-t border-b border-r rounded-md border-slate-300 mb-1">
      <div
        className={classNames('p-1 border-l-4 rounded-md border-gray-300 bg-white', {
          'border-yellow-400': model.favorite,
        })}
      >
        <div className="flex flex-row">
          <div className="mx-3 my-3">
            <SaveCustomIcon className="w-6 h-6" />
          </div>

          <div className="flex flex-col flex-grow">
            <div className="truncate">{model?.name}</div>
            <div className="text-sm text-gray-400">
              {t('common.timeAgo', { time: humanizeJsonDate(model?.created?.changedAt) })}
            </div>
          </div>

          <div className="flex items-center">
            <ContextMenu items={ctxItems} />
            <div className="absolute -mt-9 -ml-7">
              {model.favorite && <BookmarkIcon className="w-7 text-red-600" />}
            </div>
            <div className="absolute -mt-9 -ml-3">
              {model.favorite && <FullStar className="w-4 h-4 text-yellow-400" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SavePointList = ({ points, onFavoriteChange, onVariantLoad }: SavePointListProps) => {
  const { t } = useTranslation();

  return (
    <div className="mt-2 mb-[1.6rem]">
      <div className="flex flex-row">
        <div className="mt-7 px-4">
          <div className="relative">
            <div className="w-px h-12 absolute ml-1 -mt-9 bg-slate-400 z-0"></div>
          </div>
        </div>

        <div className="mt-3 mb-1 text-lg text-slate-400">{t('projectVariants.variantSavePoints')}</div>
      </div>

      {points.map((x, idx) => (
        <div key={idx} className="flex flex-row">
          <div className="mt-7 px-4">
            <div className="relative">
              <div className="w-px h-7 absolute ml-1 -mt-8 bg-slate-400 z-0"></div>
              <div className="w-[0.5625rem] h-[0.5625rem] p-px absolute -mt-1 rounded-full  shadow border-2 border-slate-400 z-[5]"></div>
              <div className="w-px h-14 absolute ml-1 mt-1 bg-slate-400"></div>
            </div>
          </div>

          <SavePointItem model={x} onFavoriteChange={onFavoriteChange} onVariantLoad={onVariantLoad} />
        </div>
      ))}
    </div>
  );
};

export const CalculationModelSavePoints = ({ model, onClose }: CalculationModelSavePointsProps) => {
  const { t } = useTranslation();

  const dispatch = useGenericDispatch();
  const [updateFavorite, {isLoading: isLoadingFavorite}] = useApiPostUpdateCaluclationModelFavoriteMutation();
  const { data: history, isFetching } = useApiGetCalculationModelHistoryQuery({
    projectId: model.projectId,
    calculationModelId: model.id,
  });

  const onFavoriteChange = async (model: CalculationModelMetadata) => {
    try {
      await updateFavorite({
        projectId: model.projectId,
        calculationModelId: model.id,
        favorite: !model.favorite,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onLoad = (mod: CalculationModelMetadata) => {
    dispatch(setVariantId(mod.id ?? ''));
    onClose();
  };

  const currentVersion = history?.currentVersion;
  const parentVersion = history?.parentVersion;

  return (
    <div className="p-6">
      <div className="text-lg mb-2 text-slate-400">{t('projectVariants.variantHistory')}</div>
      {(isFetching || isLoadingFavorite) && (
        <div className="justify-items-center">
          <LoadingIndicator />
        </div>
      )}
      {!isFetching && (
        <div>
          {parentVersion && (
            <CalculationModelItem
              model={parentVersion}
              onFavoriteChange={() => onFavoriteChange(parentVersion)}
              onVariantLoad={() => onLoad(parentVersion)}
            />
          )}
          {history && history.savePoints.length > 0 && (
            <SavePointList points={history.savePoints} onFavoriteChange={onFavoriteChange} onVariantLoad={onLoad} />
          )}
          {history?.parentVersion && history?.savePoints.length === 0 && (
            <div className="mt-7 px-4">
              <div className="relative">
                <div className="w-px h-7 absolute ml-1 -mt-7 bg-slate-400 z-0"></div>
              </div>
            </div>
          )}
          {currentVersion && (
            <CalculationModelItem
              model={history?.currentVersion}
              onFavoriteChange={() => onFavoriteChange(currentVersion)}
              onVariantLoad={() => onLoad(currentVersion)}
            />
          )}
        </div>
      )}
    </div>
  );
};
