import { DragLayerMonitor, useDragLayer } from 'react-dnd';
import { PropsWithChildren } from 'react';

export const CustomDragLayer: React.FC<PropsWithChildren> = ({ children }) => {
  const { isDragging, currentOffset } = useDragLayer((monitor: DragLayerMonitor) => {
    return {
      isDragging: monitor.isDragging(),
      currentOffset: monitor.getClientOffset(),
    };
  });

  return isDragging && currentOffset ? (
    <div
      style={{ transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)` }}
      className="fixed top-0 left-0 pointer-events-none"
    >
      {children}
    </div>
  ) : null;
};
