import { Button, Modal, TextInput } from '@client/shared/toolkit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkflowTaskStatus } from '@client/shared/api';

interface InvoiceReviewCommentModalProps {
  reviewInvoice: (comment: string) => void;
  onClose: () => void;
  completionStatus: WorkflowTaskStatus | null
}

export const InvoiceReviewCommentModal = ({
  reviewInvoice,
  onClose,
  completionStatus
}: InvoiceReviewCommentModalProps) => {
  const { t } = useTranslation();

  const [comment, setComment] = useState('');

  const handleAddComment = () => {
    reviewInvoice(comment);
    onClose();
  };

  return (
    <>
      <Modal.Header
        title={completionStatus === 'Rejected' ? t('projectControl.addCommentRejection') : t('projectControl.addComment')}
        description={t('projectControl.addCommentDescription')}
      />
      <Modal.Content className="">
        <TextInput
          label={t('projectControl.comment')}
          value={comment}
          onChange={(value) => setComment(value)}
          inputType="textarea"
          className='min-h-40'
        />
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={onClose} variant="secondary">
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={handleAddComment}>
          {t('common.proceed')}
        </Button>
      </Modal.Controls>
    </>
  );
};
