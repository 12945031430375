import { useApiGetAuthSubjectsQuery, useApiPostUpdateTenantOwnerMutation } from '@client/shared/api';
import {
  BaseSelect,
  BaseSelectOption,
  Button,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const OwnerEditSlideOver = ({ onClose }: SlideOverOnCloseProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const { data, isFetching } = useApiGetAuthSubjectsQuery();
  const [postOwner, { isLoading: isUpdating }] = useApiPostUpdateTenantOwnerMutation();
  const [items, setItems] = useState<BaseSelectOption[]>([]);
  const [selected, setSelected] = useState<string | undefined>();

  useEffect(() => {
    if (data) {
      const nonOwners = data.filter((x) => !x.isTenantOwner && x.membership === 'Active');
      const ownerItems: BaseSelectOption[] = nonOwners.map((x) => {
        return { label: x.name, value: x.tenantUserId } as BaseSelectOption;
      });
      setItems(ownerItems);
      if (ownerItems.length > 0) setSelected(ownerItems[0].value);
    }
  }, [data]);

  const handleSubmit = async () => {
    try {
      await safeMutation(
        postOwner,
        {
          body: {
            tenantUserId: selected ?? '',
          },
        },
        isUpdating
      );

      onClose(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {isFetching && <LoadingIndicator mode="overlay-window" text={t('common.loading')} />}
      {isUpdating && <LoadingIndicator mode="overlay-window" text={t('app.settingsTenantSavingLoadingIndicator')} />}
      <SlideOver.Header title={t('auth.owner')} subTitle={''} backgroundClassName="bg-blue-900" onClose={onClose} />
      <SlideOver.Content className="p-8">
        <div className="mb-4">{t('app.settingsTransferOwnerHint')}</div>
        <BaseSelect label={t('app.settingsTransferOwnerSelectLabel')} value={selected ?? ''} options={items} onChange={setSelected} />
      </SlideOver.Content>

      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={handleSubmit} innerRef={submitRef}>
          {t('common.save')}
        </Button>
      </SlideOver.Controls>
    </>
  );
};
