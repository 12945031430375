import { useLoadedProjectId } from '@client/project/store';
import {
  DealContactReadModel,
  DealType,
  useApiDeleteRentDealContactMutation,
  useApiDeleteSellDealContactMutation,
} from '@client/shared/api';
import { ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation, Trans } from 'react-i18next';

interface DealContactDeleteModalProps extends ModalOnCloseProps {
  dealId: string;
  type: DealType;
  contact: DealContactReadModel;
}

export const DealContactDeleteModal = ({ dealId, type, contact, onClose }: DealContactDeleteModalProps) => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();

  const [deleteRentContact, { isLoading: isLoadingRent }] = useApiDeleteRentDealContactMutation();
  const [deleteSellContact, { isLoading: isLoadingSell }] = useApiDeleteSellDealContactMutation();

  const handleDelete = async () => {
    switch (type) {
      case 'Rent':
        try {
          await safeMutation(
            deleteRentContact,
            {
              projectId: projectId ?? 'unset',
              dealId: dealId,
              contactId: contact.id,
            },
            isLoadingRent,
          );

          onClose(true);
        } catch {
          /* left blank */
        }
        break;
      case 'Sell':
        try {
          await safeMutation(
            deleteSellContact,
            {
              projectId: projectId ?? 'unset',
              dealId: dealId,
              contactId: contact.id,
            },
            isLoadingSell,
          );

          onClose(true);
        } catch {
          /* left blank */
        }
        break;
    }
  };

  return (
    <ConfirmModal
      title={t('projectRent.dealContactDeleteTitle', { name: `${contact.company} - ${contact.name}` })}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      {(isLoadingRent || isLoadingSell) && <LoadingIndicator text={t('projectRent.contractDeleteLoading') ?? ''} mode="overlay" />}
      <Trans
        i18nKey="projectRent.dealContactDeleteConfirmation"
        values={{ entity: `${contact.company} - ${contact.name}` }}
      >
        <div className="block">
          Do you want to delete
          <span className="font-bold">Entity</span>?
        </div>
        <div className="inline-block pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
