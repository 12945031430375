import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'text'
  | 'custom'
  | 'disabled'
  | 'outlinePrimary';

interface ButtonProps {
  variant?: ButtonVariants;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
  passDataCy?: string;
  formSubmit?: boolean;
  innerRef?: React.Ref<HTMLButtonElement>;
  hasPadding?: boolean;
  disabledClass?: string
}

const variantStyles = {
  primary: 'bg-sky-700 hover:bg-sky-800 text-white focus:ring',
  secondary: 'bg-slate-100 hover:bg-slate-200 text-slate-500 focus:ring',
  success: 'bg-emerald-500 hover:bg-emerald-400 text-white focus:ring',
  warning: 'border border-red-500 hover:bg-red-500 text-red-500 hover:text-white focus:ring',
  danger: 'bg-red-500 hover:bg-red-600 text-white focus:ring',
  text: 'text-gray-500 hover:text-sky-700',
  custom: '', // intentionally left blank to allow custom styles to be applied via className
  disabled: 'bg-gray-200 text-gray-400',
  outlinePrimary: 'border border-sky-700 hover:border-sky-800 text-sky-700 hover:text-sky-800 focus:ring'
};

export const Button = ({
  children,
  className,
  disabled = false,
  formSubmit,
  onClick,
  passDataCy,
  variant = 'primary',
  innerRef,
  hasPadding = true,
  disabledClass
}: PropsWithChildren<ButtonProps>) => {
  const ringDisabled = variant === 'text' || variant === 'custom';
  variant = disabled && !disabledClass ? 'disabled' : variant;

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
  };

  return (
    <button
      className={classNames(
        'rounded-full font-medium transition-colors duration-200',
        {
          'focus:outline-none focus:ring-2 focus:ring-offset-2': !ringDisabled,
          'cursor-pointer': !disabled,
          'cursor-not-allowed': disabled,
          'px-4 py-2': hasPadding
        },
        variantStyles[variant],
        className
      )}
      ref={innerRef}
      onClick={handleOnClick}
      disabled={disabled}
      data-cy={passDataCy}
      type={formSubmit === true ? 'submit' : 'button'}
    >
      {children}
    </button>
  );
};
