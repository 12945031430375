import { useTranslation } from 'react-i18next';
import { Marketplace } from './';
import React, { useState } from 'react';
import { SettingsHeader } from '../SettingsHeader';
export const Connect = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string | undefined>();

  return (
    <div className="w-full">
      <SettingsHeader searchText={searchText} setSearchText={setSearchText}>
        {t('app.settingsConnectMarketplace')}
      </SettingsHeader>
      <Marketplace searchText={searchText} />
    </div>
  );
};
