import { TenantCatalogReadModel, useApiDeleteCatalogMutation } from "@client/shared/api";
import { ConfirmModal, HintBox, LoadingIndicator, ModalOnCloseProps } from "@client/shared/toolkit";
import { safeMutation } from "@client/shared/utilities";
import { useTranslation } from "react-i18next";
import React from 'react';

interface CatalogDeleteModalProps extends ModalOnCloseProps {
    catalog: TenantCatalogReadModel;
  }

  export const CatalogDeleteModal = ({ catalog, onClose }: CatalogDeleteModalProps) => {
    const { t } = useTranslation();

    const [call, { isLoading }] = useApiDeleteCatalogMutation();

    const handleOnClose = () => {
      onClose(false);
    };

    const handleDelete = async () => {
      try {
        await safeMutation(
          call,
          {
              body: {
                  catalogId: catalog.id,
                  type: catalog.type
              }
          },
          isLoading
        );
        onClose(true);
      } catch {
        /* left blank */
      }
    };

    return (
      <ConfirmModal
        title={t('app.deleteTenantCatalog', { name: catalog.name })}
        onConfirm={handleDelete}
        onClose={handleOnClose}
        confirmVariant="danger"
        confirmText={t('common.delete')}
        isLoading={isLoading}
      >
        {isLoading && (
          <LoadingIndicator text={t('common.loading')} mode="overlay" />
        )}
        <HintBox hintType="warning">{t('app.deleteCatalogHint')}</HintBox>
      </ConfirmModal>
    );
  };
