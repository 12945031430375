import React, { forwardRef, PropsWithChildren, useMemo } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import classNames from 'classnames';
import { useValidatePermissionsAndLicenses } from '../hooks';
import { RouteConfig } from '../routes';

export interface ProtectedNavLinkProps extends PropsWithChildren {
  path?: string;
  disabled?: boolean;
  passDataCy?: string;
  routeConfig: RouteConfig;
  additionalProps?: NavLinkProps;
  isActiveClassName?: string;
  activeClassName?: string;
  notActiveClassName?: string;
  className?: string;
  disabledClassName?: string;
  onClick?: () => void;
  projectId?: string;
  as?: 'li' | 'div';
}
export const ProtectedNavLink = (props: ProtectedNavLinkProps, ref?: React.Ref<HTMLElement>) => {
  const {
    path,
    children,
    passDataCy,
    additionalProps,
    disabled = false,
    routeConfig,
    activeClassName,
    notActiveClassName,
    className,
    disabledClassName,
    onClick,
    projectId,
    as
  } = props;

  const { hasPermissionsAndLicense, licenseTypes, isLoading } = useValidatePermissionsAndLicenses(routeConfig, projectId);

  const isDisabled = useMemo(() => {
    return disabled || routeConfig.disabled || !hasPermissionsAndLicense;
  }, [disabled, routeConfig.disabled, hasPermissionsAndLicense]);

  const navLink = useMemo(() => {
    return (
      <NavLink
        to={path ? path : routeConfig.path}
        data-cy={passDataCy}
        {...additionalProps}
        className={({ isActive }) => classNames(className, isActive ? activeClassName : notActiveClassName)}
        onClick={onClick}
      >
        {children}
      </NavLink>
    )
  }, [path, routeConfig.path, passDataCy, additionalProps, className, activeClassName, notActiveClassName, onClick, children])

  return licenseTypes.length > 0 && licenseTypes.every((license) => license === 'Custom') && isDisabled ? null : isDisabled || isLoading ? (
    <>
      {as === 'li' ? (
        <li className={disabledClassName}>{children}</li>
      ) : (
        <div className={disabledClassName}>{children}</div>
      )}
    </>
  ) : (
   <>
     {as === 'div' || as === 'li' ? (
        <>
          {as === 'div' ? (
            <div>{navLink}</div>
          ) : (
            <li>{navLink}</li>
          )}
        </>
     ) : navLink}
   </>
  );
};


export const ProtectedNavLinkWithRef = forwardRef(ProtectedNavLink);
