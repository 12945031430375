import { LicenseAssigneeReadModel } from '@client/shared/api';
import { PlusIcon, SlideOver } from '@client/shared/toolkit';
import classNames from 'classnames';
import { useState } from 'react';
import { AddSeatsSlideOver } from './AddSeatsSlideOver';
import { LicenseSeat, SeatState } from './LicenseSeat';

interface IProps {
  seats: number | undefined | null;
  present: LicenseAssigneeReadModel[];
  added: LicenseAssigneeReadModel[];
  removed: LicenseAssigneeReadModel[];
  seatsChanged: (add: LicenseAssigneeReadModel[], remove: LicenseAssigneeReadModel[]) => void;
}

export const LicenseSeatList = (props: IProps) => {
  const { seats, present, added, removed, seatsChanged } = props;

  const [showAdd, setShowAdd] = useState<boolean>(false);

  const getSeatState = (seat: LicenseAssigneeReadModel): SeatState => {
    if (removed.includes(seat)) {
      return 'Removed';
    }
    if (!present.includes(seat)) {
      return 'Added';
    }

    return 'Present';
  };

  const usedSeats = present.length - removed.length + added.length;

  const handleRemove = (seat: LicenseAssigneeReadModel) => {
    const state = getSeatState(seat);
    if (state === 'Present') {
      const newRemoved = removed.concat(seat);
      seatsChanged(added, newRemoved);
    }
    if (state === 'Added') {
      const newAdded = added.filter((x) => x.tenantUserId !== seat.tenantUserId);
      seatsChanged(newAdded, removed);
    }
    if (state === 'Removed') {
      if (seats && usedSeats >= seats) return;

      const newRemoved = removed.filter((x) => x.tenantUserId !== seat.tenantUserId);
      seatsChanged(added, newRemoved);
    }
  };

  const handleAddSeats = (newSeats: LicenseAssigneeReadModel[]) => {
    const allAdded = added.concat(newSeats);

    seatsChanged(allAdded, removed);
  };

  return (
    <div className="bg-white p-4 ">
      <div className="flex flex-row">
        <div>{usedSeats}</div>
        <div>/</div>
        <div>{seats ? seats : '∞'}</div>
      </div>
      <div className="border-t border-slate-300">
        {present.map((seat, idx) => (
          <LicenseSeat
            key={idx}
            seat={seat}
            freeSeats={!seats || usedSeats < seats}
            state={getSeatState(seat)}
            onRemove={handleRemove}
          />
        ))}
        {added.map((seat, idx) => (
          <LicenseSeat
            key={idx}
            seat={seat}
            freeSeats={!seats || usedSeats < seats}
            state={getSeatState(seat)}
            onRemove={handleRemove}
          />
        ))}
      </div>
      <div className="flex w-full justify-end items-center">
        <div
          className={classNames('h-10 w-10 mr-2 mt-2 p-3 rounded-full bg-gray-400', {
            'bg-sky-700 hover:bg-sky-500 transition-colors duration-200': !seats || usedSeats < seats,
          })}
          onClick={() => {
            if (!seats || usedSeats < seats) setShowAdd(true);
          }}
        >
          <PlusIcon className="text-white w-full h-full" />
        </div>
      </div>
      <SlideOver isOpen={showAdd} onClose={() => setShowAdd(false)}>
        <AddSeatsSlideOver
          allowed={seats ? seats - usedSeats : seats}
          assignedSeats={present.concat(added)}
          onClose={(newGroups) => {
            if (newGroups) {
              handleAddSeats(newGroups);
            }

            setShowAdd(false);
          }}
        />
      </SlideOver>
    </div>
  );
};
