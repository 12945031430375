import {
  CalculationRuleGroupReadModel, CalculationRuleReadModel,
  useApiDeleteProjectCalculationRuleMutation,
  useApiDeleteTenantCalculationRuleMutation
} from '@client/shared/api';
import { ConfirmModal, HintBox, LoadingIndicator, Modal } from '@client/shared/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IcsType } from '.';
import { safeMutation } from '@client/shared/utilities';

export const IcsDeductionDeleteModal = ({
  group,
  isOpen,
  onClose,
  type,
  invoiceCalculationSchemeId,
  calculationRule,
  projectId
}: {
  group: CalculationRuleGroupReadModel | null;
  isOpen: boolean;
  onClose: () => void;
  type: IcsType;
  invoiceCalculationSchemeId: string
  calculationRule: CalculationRuleReadModel | null
  projectId?: string
}) => {
  const { t } = useTranslation();

  const [deleteTenantIcsDeduction, { isLoading: isDeletingTenantIcsDeduction }] =
    useApiDeleteTenantCalculationRuleMutation();
  const [deleteProjectIcsDeduction, { isLoading: isDeletingProjectIcsDeduction }] =
    useApiDeleteProjectCalculationRuleMutation();
  const handleDelete = async () => {
    if (group && invoiceCalculationSchemeId && calculationRule) {
      if (type === 'tenant') {
        try {
          await safeMutation(
            deleteTenantIcsDeduction,
            {
              invoiceCalculationSchemeId: invoiceCalculationSchemeId,
              calculationRuleGroupId: group.calculationRuleGroupId,
              calculationRuleId: calculationRule.calculationRuleId
            },
            isDeletingTenantIcsDeduction,
          )
          onClose()
        } catch (e) {
          console.log(e);
        }
      } else if (projectId) {
        try {
          await safeMutation(
            deleteProjectIcsDeduction,
            {
              projectId: projectId,
              invoiceCalculationSchemeId: invoiceCalculationSchemeId,
              calculationRuleGroupId: group.calculationRuleGroupId,
              calculationRuleId: calculationRule.calculationRuleId
            },
            isDeletingProjectIcsDeduction,
          );
          onClose()
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  if (!calculationRule) return null

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {(isDeletingTenantIcsDeduction || isDeletingProjectIcsDeduction) && (
        <LoadingIndicator text={t('app.settingsSavingInvoiceCoverSheet')} mode="overlay-window" />
      )}
      {group && (
        <ConfirmModal
          title={t('ics.deleteInvoiceCoverSheetDeductionTitle', { name: calculationRule.name})}
          onConfirm={handleDelete}
          onClose={onClose}
          confirmVariant="danger"
          confirmText={t('common.delete')}
        >
          <HintBox hintType="warning">{t('ics.deleteDeductionHintMessage')}</HintBox>
        </ConfirmModal>
      )}
    </Modal>
  );
};
