import { CompanyPersonReadModel, useApiDeleteCompanyPersonMutation } from "@client/shared/api";
import { ConfirmModal, HintBox, LoadingIndicator, ModalOnCloseProps } from "@client/shared/toolkit";
import { safeMutation } from "@client/shared/utilities";
import { useTranslation } from "react-i18next";
import React from 'react';

interface ContactDeleteModalProps extends ModalOnCloseProps {
    contact?: CompanyPersonReadModel;
  }

export const ContactDeleteModal = ({ contact, onClose }: ContactDeleteModalProps) => {
  const { t } = useTranslation();

  const [call, { isLoading }] = useApiDeleteCompanyPersonMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    try {
      await safeMutation(
        call,
        {
          companyId: contact?.companyId ?? '',
          companyPersonId: contact?.id ?? ''
        },
        isLoading
      );
      onClose(true);
    } catch {
      /* left blank */
    }
  };

  return (
    <ConfirmModal
      title={t('app.companyContactDeleteConfirm')}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isLoading}
    >
      {isLoading && (
        <LoadingIndicator text={t('app.settingsCompaniesLoading') ?? ''} mode="overlay" />
      )}
      <HintBox hintType="warning">{t('projectTaxonomy.deleteBuildingElementHint')}</HintBox>
    </ConfirmModal>
  );
};
