import {
  FileManagementSlideOver,
  FileUploadCard,
  TaxonomyDashboardProps
} from '..';
import { SlideOver } from '@client/shared/toolkit';
import React, { useState } from 'react';
import { TaxonomyItemFileTypeEnum } from '@client/shared/api';
import { useTranslation } from 'react-i18next';

export const TaxonomyDocuments = (props: TaxonomyDashboardProps) => {
  const { item, isReadOnly } = props
  const { t } = useTranslation()

  const [isFileManagementSlideOverOpen, setIsFileManagementSlideOverOpen] = useState(false);
  const [selectedField, setSelectedField] = useState('');

  const handleUploadCardClick = (type: TaxonomyItemFileTypeEnum) => {
    setSelectedField(type)
    setIsFileManagementSlideOverOpen(true)
  }

  return (
    <div className="p-6 flex flex-col gap-6">
      <FileUploadCard
        item={item}
        type="Image"
        handleClick={handleUploadCardClick}
        title={t('projectTaxonomy.dashboardImages')}
      />
      <FileUploadCard
        item={item}
        type="Sketch"
        handleClick={handleUploadCardClick}
        title={t('projectTaxonomy.dashboardSketches')}
      />
      <FileUploadCard
        item={item}
        type="FloorPlan"
        handleClick={handleUploadCardClick}
        title={t('projectTaxonomy.dashboardFloorPlans')}
      />
      <FileUploadCard
        item={item}
        type="SitePlan"
        handleClick={handleUploadCardClick}
        title={t('projectTaxonomy.dashboardSitePlans')}
      />
      <FileUploadCard
        item={item}
        type="Document"
        handleClick={handleUploadCardClick}
        title={t('projectTaxonomy.dashboardDocuments')}
        showThumbnail={false}
      />
      <FileUploadCard
        item={item}
        type="Contract"
        handleClick={handleUploadCardClick}
        title={t('projectTaxonomy.dashboardContract')}
        showThumbnail={false}
      />
      {item?.itemType === 'Room' && (
        <FileUploadCard
          item={item}
          type="Defect"
          handleClick={handleUploadCardClick}
          title={t('projectTaxonomy.dashboardDefects')}
        />
      )}
      <SlideOver
        isOpen={isFileManagementSlideOverOpen}
        onClose={() => setIsFileManagementSlideOverOpen(false)}
        confirmBeforeClose={false}
      >
        <FileManagementSlideOver
          item={item}
          isReadOnly={isReadOnly}
          onClose={() => setIsFileManagementSlideOverOpen(false)}
          field={selectedField}
          canDelete={true}
        />
      </SlideOver>
    </div>
  )
}
