import { PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';
import { Card } from './Card';
import { TodayDottedIcon } from '../icons';

interface WorkflowTaskInfoProps extends PropsWithChildren {
  className?: string
  isLast?: boolean
  title?: string
  titleIcon?: boolean
  customIcon?: ReactNode
  variant?: 'default' | 'card'
  borderColor?: string
  onClick?: () => void
}
export const WorkflowTaskInfo = (props: WorkflowTaskInfoProps) => {
  const {
    className,
    isLast = false,
    variant = 'default',
    borderColor,
    onClick
  } = props

  return (
    <div className={cn(className, isLast ? '' : variant === 'default' ? 'pb-8' : 'pb-12')}>
      {variant === 'card' ? (
        <Card
          className={cn('relative border-l-[6px] py-4 px-3.5', borderColor ? borderColor : 'border-slate-400')}
          divider={false}
          padding={false}
          onClick={onClick}
        >
          <WorkflowTaskInfoContent {...props} />
        </Card>
      ) : <WorkflowTaskInfoContent {...props} />}
    </div>
  )
}

export const WorkflowTaskInfoContent = (props: WorkflowTaskInfoProps) => {
  const {
    children,
    title,
    titleIcon,
    customIcon,
    variant = 'default'
  } = props

  return (
    <>
      {(title || titleIcon) && (
        <div className={cn('flex items-center gap-2', {
          'before:w-3 before:h-0.5 before:bg-slate-400': variant === 'default',
          'mb-1': children
        })}>
          {titleIcon && !customIcon && (<TodayDottedIcon className="w-6 flex-none" />)}
          {customIcon}
          {title && <span className="font-bold">{title}</span>}
        </div>
      )}
      {children && (
        <div className={variant === 'default' ? 'ml-5' : 'ml-8'}>
          {children}
        </div>
      )}
    </>
  )
}
