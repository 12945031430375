import {
  ApiDeleteUserAvatarApiResponse,
  ApiPostCreateUserAvatarApiResponse,
  ApiPostUpdateUserNameApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiUserEndpoints = {
  apiGetUserAvatar: {
    providesTags: [{ type: ApiTagTypes.UserAvatar, id: 'LIST' }],
  },
  apiDeleteUserAvatar: {
    invalidatesTags: (_result: ApiDeleteUserAvatarApiResponse, error: FetchBaseQueryError) => {
      return error == null
        ? [
            { type: ApiTagTypes.UserAvatar, id: 'LIST' },
            { type: ApiTagTypes.AuthSubjects, id: 'LIST' },
            { type: ApiTagTypes.AuthGroups, id: 'LIST' },
          ]
        : [];
    },
  },
  apiPostCreateUserAvatar: {
    invalidatesTags: (_result: ApiPostCreateUserAvatarApiResponse, error: FetchBaseQueryError) => {
      return error == null
        ? [
            { type: ApiTagTypes.UserAvatar, id: 'LIST' },
            { type: ApiTagTypes.AuthSubjects, id: 'LIST' },
            { type: ApiTagTypes.AuthGroups, id: 'LIST' },
          ]
        : [];
    },
  },
  apiPostUpdateUserName: {
    invalidatesTags: (_result: ApiPostUpdateUserNameApiResponse, error: FetchBaseQueryError) =>
      error == null
        ? [
            {
              type: ApiTagTypes.AuthSubjects,
              id: 'LIST',
            },
          ]
        : [],
  },
  // getApiUserGetLoggedUser: {
  //   providesTags: [{ type: ApiTagTypes.User, id: 'User' }],
  // },
  // getApiUserGetUsers: {
  //   providesTags: ['Users'],
  // },
};
