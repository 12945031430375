import { useParams, Navigate } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { PortfolioLayout } from './components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPortfolioId, useLoadedPortfolio } from '@client/portfolio/store';
import { LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { PortfolioRoutes } from './PortfolioRoutes';

export const PortfolioModule = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  if (id == null) throw new Error('Can only render if portfolio id (:id) is set');

  const { isFetching, isLoading, isError, isSuccess } = useLoadedPortfolio();

  useEffect(() => {
    dispatch(
      setPortfolioId({
        portfolioId: id
      })
    );
  }, [dispatch, id]);

  return (
    <PortfolioLayout>
      {isFetching === true && <LoadingIndicator mode="overlay-window" text={t('project.loadingPortfolioDetails')} />}

      {isLoading === false && (
        <>
          {isError === true && <Navigate to={{ pathname: ROUTES_CONFIG.PORTFOLIOS.path }} />}
          {isSuccess === true && <PortfolioRoutes />}
        </>
      )}
    </PortfolioLayout>
  );
};
