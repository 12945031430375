import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { AppRoutes } from './AppRoutes';
import { AppState, settings, startAppInit, useUi } from '@client/shared/store';
import { useClarity } from './hooks';
import { useTranslation } from 'react-i18next';
import { AppError } from './AppError';
import { LoadingIndicator } from '@client/shared/toolkit';
import { PreviewBanner } from './components';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';

export const AppModule = () => {
  const { t } = useTranslation();

  const { appState } = useUi();

  const isDevMode = settings.devMode;

  const dispatch = useDispatch();

  useClarity(settings.clarityId);

  /**
   * On first mount send the signal to start application initialization.
   * Beware: since React 18 this happens twice during development mode:
   *   https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
   */
  useEffect(() => {
    dispatch(startAppInit());
  }, [dispatch]);

  //TODO: hopefully remove this again when we decide against userpilot
  const location = useLocation();

  useEffect(() => {
    Userpilot.reload();
  }, [location]);

  return (
    <div>
      <Toaster position="top-right" />

      <PreviewBanner />

      {/* calc function is necessary as long as DragonBanner takes up space inside the container */}
      <div className="flex flex-col" style={{ height: isDevMode ? 'calc(100vh - 2rem)' : '100vh' }}>
        {/* Only proceed with rendering if the AppState is READY */}
        {appState === AppState.INIT && (
          <div className="flex-grow flex items-center justify-center bg-gray-200 z-50 absolute top-0 left-0 w-screen h-screen">
            <LoadingIndicator text={t('app.loadingApplication')} />
          </div>
        )}
        {appState === AppState.READY && <AppRoutes />}
        {appState === AppState.ERROR && <AppError />}
      </div>
    </div>
  );
};
