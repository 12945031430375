import { FinancingPaybackPlanElementReadModel } from '@client/shared/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddButton, Button, Modal } from '@client/shared/toolkit';
import { RenderFinancingPaybackElement } from './RenderFinancingPaybackElement';
import { formatDateOnly } from '@client/shared/utilities';

interface FinancingCustomPaybackModalProps {
  onClose: (plan?: FinancingPaybackPlanElementReadModel[]) => void;
  paybackPlan: FinancingPaybackPlanElementReadModel[];
}

export const FinancingCustomPaybackModal = ({ onClose, paybackPlan }: FinancingCustomPaybackModalProps) => {
  const { t } = useTranslation();

  const [plan, setPlan] = useState(paybackPlan);

  useEffect(() => {
    if (paybackPlan.filter((x) => x.rest).length === 0) {
      const extendedPlan = [
        ...paybackPlan,
        {
          id: '',
          description: '',
          amount: 0,
          paymentOn: formatDateOnly(new Date()),
          order: 10000,
          rest: true,
        },
      ];
      setPlan(updateOrder(extendedPlan));
    } else {
      setPlan(updateOrder(paybackPlan));
    }
  }, [paybackPlan]);

  const addNewElement = () => {
    const extendedPlan = [
      ...plan,
      {
        id: '',
        description: '',
        amount: 0,
        paymentOn: formatDateOnly(new Date()),
        order: plan.length + 1,
        rest: false,
      },
    ];

    setPlan(updateOrder(extendedPlan));
  };

  const updateOrder = (planToOrder: FinancingPaybackPlanElementReadModel[]): FinancingPaybackPlanElementReadModel[] => {
    const elements = [...planToOrder];
    const result = elements.map((v) => {
      v = { ...v, order: v.rest ? 10000 : elements.indexOf(v) + 1 };
      return v;
    });
    result.sort((a, b) => a.order - b.order);
    return result;
  };

  return (
    <>
      <Modal.Header
        title={t('projectCalculate.financingPaybackScheduleTitle')}
        description={t('projectCalculate.financingPaybackScheduleDescription')}
      />
      <Modal.Content>
        <div className="overflow-auto">
          <div className="divide-y">
            {plan.map((paybackElement) => (
              <RenderFinancingPaybackElement
                key={paybackElement.order}
                item={paybackElement}
                onItemChange={(item) => {
                  const result = plan.map((c) => {
                    if (c.order === item.order) {
                      c = item;
                    }
                    return c;
                  });
                  setPlan(result);
                }}
                onItemDeleted={(item) => {
                  const ordered = updateOrder(plan.filter((x) => x.order !== item.order));
                  setPlan(ordered);
                }}
              />
            ))}
          </div>

          <div className="sticky bottom-0 flex-row-reverse flex mr-2">
            <AddButton onClick={addNewElement} scale={false} />
          </div>
        </div>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={() => onClose(undefined)} className="mr-2" variant="secondary">
          {t('common.cancel')}
        </Button>
        <Button onClick={() => onClose(plan)}>{t('common.save')}</Button>
      </Modal.Controls>
    </>
  );
};
