import {
  ContractReadModel,
  ContractTitleReadModel,
  ContractTitleState,
  ContractTitleType,
  useApiPostCreateContractTitleMutation,
  useApiPostGenerateNextProjectObjectCodeMutation,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import {
  CareerLedderIcon,
  ContextMenuItem,
  DecoratedCard,
  ListTitle,
  LoadingIndicator,
  DiplomaIcon,
  PiggyBankIcon,
  SlideOver,
  SlideOverSortableList,
  SlideOverSortableListItemData,
  SlideOverSortableListTotalListItem,
  SubmitDocumentIcon,
  WithdrawalIcon,
  AddIcon,
  DecoratedCardAddButton,
  ContextMenu,
  PencilIcon,
  TrashIcon,
  Modal,
  EyeIcon,
  ToggleSwitch,
  Highlighted,
} from '@client/shared/toolkit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLoadedProject, useLoadedVariantId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';
import { useValidateProjectPermission } from '@client/shared/permissions';
import {
  ContractEditTitleSlideOver,
  ContractTitleDeleteModal,
  FormattedCurrency,
  UserDefinedFieldsSearch
} from '@client/project/shared';
import { sortByProperty, SortHeaderType } from '@client/shared/utilities';
export interface ContractTitlesProps {
  contract: ContractReadModel;
  setChildSlideOverIsOpen: (val: boolean) => void;
}

export const ContractTitles = ({ contract, setChildSlideOverIsOpen }: ContractTitlesProps) => {
  const { t } = useTranslation();

  const [selectedTitle, setSelectedTitle] = useState<ContractTitleReadModel | null>(null);
  const [isOpenAddTitleSlideOver, setIsOpenAddTitleSlideOver] = useState(false);
  const [isOpenDeleteTitleModal, setIsOpenDeleteTitleModal] = useState(false);
  const [showNet, setShowNet] = useState(true);

  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<string[]>([]);

  const loadedProject = useLoadedProject().currentData?.project.payload;
  const defaultVat = contract.vat ?? loadedProject?.vat ?? 0;
  const defaultDiscount = contract.discount ?? 0;

  const loadedProjectId = loadedProject?.id;
  const loadedVariantId = useLoadedVariantId();

  const [getNextCode] = useApiPostGenerateNextProjectObjectCodeMutation();
  const getNextContractTitleCode = async (contractId : string, titleType : ContractTitleType) => {
    if (loadedProjectId && loadedVariantId) {
      const isGettingNextCode = false;
      const nextCodeResponse = await safeMutation(
        getNextCode,
        {
          projectId: loadedProjectId,
          calculationModelId: loadedVariantId,
          body: {
            ownerObjectId: contractId,
            contractTitleType: titleType,
            projectObjectType: 'Title',
          }
        },
        isGettingNextCode,
      );
      if (typeof nextCodeResponse !== 'undefined') {
        return nextCodeResponse?.code;
      }
    }

    return '';
  }

  const canWrite = useValidateProjectPermission(['CONTRACT_WRITE'], loadedProjectId ?? '') && !contract.isPxContract;

  const [create, { isLoading: isCreating }] = useApiPostCreateContractTitleMutation();

  const defaultSortHeader: SortHeaderType[] = useMemo(() => {
    return [
      {
        asc: null,
        label: t('projectContract.titleCode'),
        type: 'string',
        value: 'code',
      },
      {
        asc: null,
        label: t('projectContract.titleStatus'),
        type: 'string',
        value: 'state',
      },
      {
        asc: true,
        label: t('projectContract.contractValue'),
        type: 'number',
        value: 'contractValueNet',
      },
      {
        asc: null,
        label: t('projectContract.adjustmentValue'),
        type: 'number',
        value: 'adjustmentValueNet',
      },
      {
        asc: null,
        label: t('projectContract.forecast'),
        type: 'number',
        value: 'forecastValueNet',
      },
    ];
  }, [t]);
  const [sortHeader, setSortHeader] = useState<SortHeaderType[]>(defaultSortHeader);

  const [titles, setTitles] = useState<ContractTitleReadModel[]>([]);

  useEffect(() => {
    if (contract) {
      setTitles(contract.titles);
    }
  }, [contract]);

  const handleSelectTitle = useCallback(
    (title: ContractTitleReadModel) => {
      setSelectedTitle(title);
      setChildSlideOverIsOpen(true);
      setIsOpenAddTitleSlideOver(true);
    },
    [setChildSlideOverIsOpen],
  );

  const getContractTitleStateTranslation = (state: ContractTitleState) => {
    switch (state) {
      case 'Expected':
        return t('projectContract.contractTitleStateExpected');
      case 'Optional':
        return t('projectContract.contractTitleStateOptional');
      case 'Announced':
        return t('projectContract.contractTitleStateAnnounced');
      case 'Budgeted':
        return t('projectContract.contractTitleStateBudgeted');
      case 'Received':
        return t('projectContract.contractTitleStateReceived');
      case 'Approved':
        return t('projectContract.contractTitleStateApproved');
      case 'Commissioned':
        return t('projectContract.contractTitleStateCommissioned');
      case 'Rejected':
        return t('projectContract.contractTitleStateRejected');
      case 'Canceled':
        return t('projectContract.contractTitleStateCanceled');
      case 'Reserve':
        return t('projectContract.contractTitleStateReserve');
      case 'None':
        return t('projectContract.contractTitleStateNone');
      default:
        return state;
    }
  }

  const createListItemDataFromTitle = useCallback(
    (title: ContractTitleReadModel) => {
      const contextMenuItems: ContextMenuItem[] = [
        {
          label: contract.isPxContract ? t('common.view') : t('common.edit'),
          subtitle: contract.isPxContract
            ? t('projectContract.viewContractTitle')
            : t('projectContract.editContractTitle'),
          icon: contract.isPxContract ? <EyeIcon /> : <PencilIcon />,
          onClick: () => {
            handleSelectTitle(title);
          },
          stopPropagation: true,
        },
      ];

      if (!contract.isPxContract) {
        contextMenuItems.push({
          label: t('common.delete'),
          subtitle: t('projectContract.deleteContractTitle'),
          icon: <TrashIcon />,
          onClick: () => {
            setSelectedTitle(title);
            setIsOpenDeleteTitleModal(true);
          },
          stopPropagation: true,
        });
      }

      return {
        id: title.id,
        name: <Highlighted text={title.name} highlight={searchValue} />,
        description: (
          <>
            {title.description ? '•' : ''}
            <Highlighted text={title.description ?? ''} highlight={searchValue} />
          </>
        ),
        cols: [
          {
            value: title.code,
            header: t('projectContract.titleCode'),
          },
          {
            value: getContractTitleStateTranslation(title.state as ContractTitleState),
            header: t('projectContract.titleStatus'),
          },
          {
            value: <FormattedCurrency amount={showNet ? title.contractValueNet : title.contractValueGross} options={{ maxDigits: 2, minDigits: 2 }} />,
            title: showNet?  t('projectControl.net') : t('projectControl.gross'),
            header: t('projectContract.contractValue'),
          },
          {
            value: <FormattedCurrency amount={showNet ? title.adjustmentValueNet : title.adjustmentValueGross} options={{ maxDigits: 2, minDigits: 2 }} />,
            title: showNet?  t('projectControl.net') : t('projectControl.gross'),
            header: t('projectContract.adjustmentValue'),
          },
          {
            value: <FormattedCurrency amount={showNet ? title.forecastValueNet : title.forecastValueGross} options={{ maxDigits: 2, minDigits: 2 }} />,
            title: t('projectContract.billingAmount'),
            header: t('projectContract.forecast'),
          },
        ],
        contextMenu: <ContextMenu items={contextMenuItems} stopPropagation />,
      } as SlideOverSortableListItemData;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract.isPxContract, t, showNet, handleSelectTitle, searchValue],
  );

  const titlesByGroup = useMemo(() => {
    const mainContractItems: SlideOverSortableListItemData[] = [];
    const supplementItems: SlideOverSortableListItemData[] = [];
    let reserveItem: SlideOverSortableListItemData | undefined = undefined;
    const mainContractTitles: ContractTitleReadModel[] = [];
    const supplementTitles: ContractTitleReadModel[] = [];
    let reserveTitle: ContractTitleReadModel | undefined = undefined;
    const mainContractTitlesSubtotals: number[] = [0, 0, 0];
    const supplementTitlesSubtotals: number[] = [0, 0, 0];
    const totals: number[] = [0, 0, 0];

    let sortedTitles = [...titles];
    if (sortedTitles.length) {
      const sortBy = sortHeader.find((sort) => sort.asc !== null);
      if (sortBy) {
        sortedTitles = sortByProperty(sortedTitles, sortBy) as ContractTitleReadModel[];
      }
    }
    if (searchValue) {
      sortedTitles = sortedTitles.filter((title) => searchResults.includes(title.id));
    }

    sortedTitles.forEach((title) => {
      switch (title.type) {
        case 'MainContract':
          mainContractTitles.push(title);
          mainContractItems.push(createListItemDataFromTitle(title));
          mainContractTitlesSubtotals[0] += showNet ? title.contractValueNet : title.contractValueGross;
          mainContractTitlesSubtotals[1] += showNet ? title.adjustmentValueNet : title.adjustmentValueGross;
          mainContractTitlesSubtotals[2] += showNet ? title.forecastValueNet : title.forecastValueGross;
          break;
        case 'Supplement':
          supplementTitles.push(title);
          supplementItems.push(createListItemDataFromTitle(title));
          supplementTitlesSubtotals[0] += showNet ? title.contractValueNet : title.contractValueGross;
          supplementTitlesSubtotals[1] += showNet ? title.adjustmentValueNet : title.adjustmentValueGross;
          supplementTitlesSubtotals[2] += showNet ? title.forecastValueNet : title.forecastValueGross;
          break;
        case 'Reserve':
          reserveItem = createListItemDataFromTitle(title);
          reserveItem.icon = <PiggyBankIcon />;
          reserveItem.className = 'mb-4';
          reserveTitle = title;
          break;
      }

      totals[0] += showNet ? title.contractValueNet : title.contractValueGross;
      totals[1] += showNet ? title.adjustmentValueNet : title.adjustmentValueGross;
      totals[2] += showNet ? title.forecastValueNet : title.forecastValueGross;
    });

    return {
      mainContract: {
        items: mainContractItems,
        titles: mainContractTitles,
        subTotals: [
          <div />,
          <div />,
          <FormattedCurrency amount={mainContractTitlesSubtotals[0]} options={{ maxDigits: 2, minDigits: 2 }} />,
          <FormattedCurrency amount={mainContractTitlesSubtotals[1]} options={{ maxDigits: 2, minDigits: 2 }} />,
          <FormattedCurrency amount={mainContractTitlesSubtotals[2]} options={{ maxDigits: 2, minDigits: 2 }} />,
        ],
      },
      supplement: {
        items: supplementItems,
        titles: supplementTitles,
        subTotals: [
          <div />,
          <div />,
          <FormattedCurrency amount={supplementTitlesSubtotals[0]} options={{ maxDigits: 2, minDigits: 2 }} />,
          <FormattedCurrency amount={supplementTitlesSubtotals[1]} options={{ maxDigits: 2, minDigits: 2 }} />,
          <FormattedCurrency amount={supplementTitlesSubtotals[2]} options={{ maxDigits: 2, minDigits: 2 }} />,
        ],
      },
      additionalCost: {
        items: [],
        titles: [],
        subTotals: [],
      },
      reserve: {
        item: reserveItem,
        title: reserveTitle,
      },
      totals: [
        <div />,
        <div />,
        <FormattedCurrency amount={totals[0]} options={{ maxDigits: 2, minDigits: 2 }} />,
        <FormattedCurrency amount={totals[1]} options={{ maxDigits: 2, minDigits: 2 }} />,
        <FormattedCurrency amount={totals[2]} options={{ maxDigits: 2, minDigits: 2 }} />,
      ],
    };
  }, [titles, searchValue, sortHeader, searchResults, showNet, createListItemDataFromTitle]);

  const createContractTitle = async (type: ContractTitleType) => {
    if (contract?.id && loadedProjectId && loadedVariantId) {
      let typeDescription = '';

      const titleCode = await getNextContractTitleCode(contract.id, type);

      switch (type) {
        case 'MainContract':
          typeDescription = t('projectContract.newMainContractTitle');
          break;
        case 'Supplement':
          typeDescription = t('projectContract.newSupplementTitle');
          break;
        case 'Reserve':
          typeDescription = t('projectContract.newReserveTitle');
          break;
      }

      await safeMutation(
        create,
        {
          contractId: contract.id,
          projectId: loadedProjectId,
          calculationModelId: loadedVariantId,
          body: {
            code: titleCode,
            name: typeDescription,
            type: type,
            allowEarlySettlement: false,
            state: type === 'Reserve' ? 'Reserve' : 'Expected',
            vat: defaultVat,
            discount: defaultDiscount,
            offer: 0,
            approvedOffer: 0,
            adjustmentValue: 0,
            budgetAssignments: [],
          },
        },
        isCreating,
      );
    }
  };

  const menuItems: ContextMenuItem[] = [
    {
      label: t('projectContract.createMainContractTitle'),
      subtitle: t('projectContract.createNewTitle'),
      icon: <AddIcon />,
      isDisabled: !canWrite,
      onClick: () => {
        createContractTitle('MainContract');
      },
    },
    {
      label: t('projectContract.createSupplementTitle'),
      subtitle: t('projectContract.createNewTitle'),
      icon: <AddIcon />,
      isDisabled: !canWrite,
      onClick: () => {
        createContractTitle('Supplement');
      },
    },
    {
      label: t('projectContract.createReserveTitle'),
      subtitle: t('projectContract.createNewTitle'),
      icon: <AddIcon />,
      isDisabled: !canWrite || contract.titles.some((x) => x.type === 'Reserve'),
      onClick: () => {
        createContractTitle('Reserve');
      },
    },
  ];

  const onHandleSort = useCallback(
    (index: number) => {
      const currentSortValues = [...sortHeader];
      const update = currentSortValues[index];
      update.asc = update.asc === null ? true : !update.asc;
      currentSortValues.forEach((val, i) => {
        if (i !== index) {
          val.asc = null;
        }
      });
      currentSortValues[index] = update;
      setSortHeader(currentSortValues);
    },
    [sortHeader],
  );

  return (
    <div className="pt-6">
      <div className="relative">
        <DecoratedCard shadowVariant="normal">
          <DecoratedCard.Content>
            {isCreating && (
              <LoadingIndicator text={t('projectContract.saveContractTitleLoadingIndicator')} mode="overlay-window" />
            )}
            <ListTitle title={t('projectContract.contractPositions')} />
            {titles.length > 0 && (
               <div className="absolute right-2 top-4 mb-0 flex">
                <UserDefinedFieldsSearch
                  searchValue={searchValue}
                  updateSearchResults={(result: string[]) => setSearchResults(result)}
                  handleSearchValueUpdate={(value) => setSearchValue(value)}
                  udfElementTypes={['ContractTitle']}
                  filterStore="ContractTitle"
                  className="text-gray-400 bg-gray-100 mr-2"
                />
                <ToggleSwitch
                  id="netGrossToggle"
                  checked={showNet}
                  name="netGrossToggle"
                  onChange={() => setShowNet((prev) => !prev)}
                  offLabel={t('projectControl.gross')}
                  onLabel={t('projectControl.net')}
                  variant="medium"
                />
              </div>
            )}
            <SlideOverSortableList
              icon={<DiplomaIcon className="w-5 text-sky-800" />}
              data={titlesByGroup.mainContract.items}
              headline={t('projectContract.mainContracts')}
              handleSelect={(i: number) => handleSelectTitle(titlesByGroup.mainContract.titles[i])}
              subTotals={titlesByGroup.mainContract.subTotals}
              sortHeader={sortHeader}
              onHandleSort={onHandleSort}
              noItemsMessage={t('projectContract.noMainContracts')}
              gridCols="grid-cols-2 lg:grid-cols-5"
              color="bg-sky-800"
              textColor="text-sky-800"
              subTotalLabel={t('projectContract.subtotal')}
              subTotalSumLabel={t('projectContract.sum')}
              subTotalHeaders={[
                t('projectContract.titleCode'),
                t('projectContract.titleStatus'),
                t('projectContract.subTotalContractValue'),
                t('projectContract.subTotalAdjustmentValue'),
                t('projectContract.subTotalForecast'),
              ]}
              showSumOnColumns={[2, 3, 4]}
              contextMenu
              earlyCompact
            />

            <SlideOverSortableList
              icon={<SubmitDocumentIcon className="w-5 text-sky-500" />}
              data={titlesByGroup.supplement.items}
              headline={t('projectContract.supplements')}
              handleSelect={(i: number) => handleSelectTitle(titlesByGroup.supplement.titles[i])}
              subTotals={titlesByGroup.supplement.subTotals}
              sortHeader={[]}
              onHandleSort={onHandleSort}
              noItemsMessage={t('projectContract.noSupplements')}
              gridCols="grid-cols-2 lg:grid-cols-5"
              color="bg-sky-500"
              textColor="text-sky-500"
              subTotalLabel={t('projectContract.subtotal')}
              subTotalSumLabel={t('projectContract.sum')}
              subTotalHeaders={[
                t('projectContract.titleCode'),
                t('projectContract.titleStatus'),
                t('projectContract.subTotalContractValue'),
                t('projectContract.subTotalAdjustmentValue'),
                t('projectContract.subTotalForecast'),
              ]}
              showSumOnColumns={[2, 3, 4]}
              contextMenu
              earlyCompact
            >
              {titlesByGroup.additionalCost.items.length > 0 && (
                <>
                  <SlideOverSortableList
                    icon={<WithdrawalIcon className="w-8 text-slate-500" />}
                    data={titlesByGroup.supplement.items}
                    headline={t('projectContract.supplements')}
                    handleSelect={(i: number) => handleSelectTitle(titlesByGroup.supplement.titles[i])}
                    sortHeader={[]}
                    onHandleSort={onHandleSort}
                    gridCols="grid-cols-2 lg:grid-cols-5"
                    textColor="text-sky-500"
                    level={1}
                    contextMenu
                  />
                  {titlesByGroup.additionalCost.items.length > 0 && (
                    <SlideOverSortableList
                      icon={<CareerLedderIcon className="w-8 text-slate-500" />}
                      data={titlesByGroup.additionalCost.items}
                      headline={t('projectContract.additionalCosts')}
                      handleSelect={(i: number) => handleSelectTitle(titlesByGroup.additionalCost.titles[i])}
                      sortHeader={[]}
                      onHandleSort={onHandleSort}
                      gridCols="grid-cols-2 lg:grid-cols-5"
                      textColor="text-sky-500"
                      level={1}
                      contextMenu
                    />
                  )}
                </>
              )}
            </SlideOverSortableList>

            <SlideOverSortableList
              data={titlesByGroup.reserve.item ? [titlesByGroup.reserve.item] : []}
              headline={t('projectContract.reserve')}
              handleSelect={() => {
                if (titlesByGroup.reserve.title) {
                  handleSelectTitle(titlesByGroup.reserve.title);
                }
              }}
              sortHeader={[]}
              onHandleSort={onHandleSort}
              noItemsMessage={t('projectContract.noReserve')}
              gridCols="grid-cols-2 lg:grid-cols-5"
              color="bg-sky-200"
              textColor="text-sky-200"
              contextMenu
              earlyCompact
            />
            {!contract?.isPxContract && <DecoratedCardAddButton menuItems={menuItems} />}
          </DecoratedCard.Content>
        </DecoratedCard>
      </div>

      <SlideOverSortableListTotalListItem
        className="mt-2"
        size="lg"
        nrOfItems={titles.length}
        totals={titlesByGroup.totals}
        textColor=""
        totalHeaders={[
          t('projectContract.titleCode'),
          t('projectContract.titleStatus'),
          t('projectContract.totalContractValue'),
          t('projectContract.totalAdjustmentValue'),
          t('projectContract.totalForecast'),
        ]}
        showSumOnColumns={[2, 3, 4]}
        gridCols="grid-cols-2 lg:grid-cols-5"
        totalSumLabel={t('projectContract.sum')}
        totalLabel={t('projectContract.total')}
        borderTop={false}
        contextMenu
        earlyCompact
      />

      {/* CONTRACT TITLE EDIT */}
      <SlideOver
        isOpen={isOpenAddTitleSlideOver}
        onClose={() => {
          setIsOpenAddTitleSlideOver(false);
          setChildSlideOverIsOpen(false);
        }}
      >
        {selectedTitle && (
          <ContractEditTitleSlideOver
            title={selectedTitle}
            contract={contract}
            onClose={() => {
              setIsOpenAddTitleSlideOver(false);
              setChildSlideOverIsOpen(false);
            }}
          />
        )}
      </SlideOver>

      {/* CONTRACT TITLE DELETE */}
      <Modal
        isOpen={isOpenDeleteTitleModal}
        onClose={() => {
          setIsOpenDeleteTitleModal(false);
          setChildSlideOverIsOpen(false);
        }}
      >
        {selectedTitle && (
          <ContractTitleDeleteModal
            onClose={(close) => {
              setIsOpenDeleteTitleModal(false);
              setIsOpenDeleteTitleModal(false);
              setChildSlideOverIsOpen(false);
            }}
            title={selectedTitle}
          />
        )}
      </Modal>
    </div>
  );
};
