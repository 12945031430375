import { AuthSubjectReadModel, LicenseAssigneeReadModel, useApiGetPlainAuthSubjectsQuery } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import { Button, LoadingIndicator, SlideOver } from '@client/shared/toolkit';

interface AddSeatsSlideOverProps {
  allowed: number | undefined | null;
  assignedSeats: LicenseAssigneeReadModel[];
  onClose: (seats?: LicenseAssigneeReadModel[]) => void;
}

export const AddSeatsSlideOver = ({ assignedSeats, onClose, allowed }: AddSeatsSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const { data: users, isFetching } = useApiGetPlainAuthSubjectsQuery();

  const [selectableUsers, setSelectableUsers] = useState<AuthSubjectReadModel[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<AuthSubjectReadModel[]>([]);

  useEffect(() => {
    if (!users) {
      setSelectableUsers([]);
    } else {
      const availableUsers = users.filter(
        (x) => assignedSeats.find((y) => y.tenantUserId === x.tenantUserId) === undefined
      );
      setSelectableUsers(availableUsers);
    }
  }, [assignedSeats, users]);

  const convertSelection = (): LicenseAssigneeReadModel[] => {
    return selectedUsers.map<LicenseAssigneeReadModel>((x) => {
      return { 
        fullname: x.name, 
        mail: x.email, 
        tenantUserId: x.tenantUserId 
      };
    });
  };

  const handleChange = (user: AuthSubjectReadModel) => {
    if (selectedUsers.includes(user)) {
      const newSelection = selectedUsers.filter((x) => x.userId !== user.userId);
      setSelectedUsers(newSelection);
    } else {
      if (allowed && selectedUsers.length >= allowed) return;

      const newSelection = selectedUsers.concat(user);
      setSelectedUsers(newSelection);
    }
  };

  return (
    <>
      <SlideOver.Header
        title={t('auth.subjectAdd')}
        backgroundClassName="bg-gray-600"
        onClose={() => onClose(undefined)}
      />
      <SlideOver.Content className="p-8">
        {isFetching === true ? (
          <LoadingIndicator text={t('auth.roleLoadingIndicator')} />
        ) : (
          <div className="divide-y">
            {selectableUsers.map((user, index) => (
              <label className="flex items-center bg-white h-14 p-3 cursor-pointer" key={index}>
                <input
                  type="checkbox"
                  checked={selectedUsers.includes(user)}
                  onChange={() => handleChange(user)}
                  autoFocus={false}
                />
                <div className="ml-3">
                  <div>{user.name}</div>
                  <div className="text-sm text-gray-500">{user.email}</div>
                </div>
              </label>
            ))}
          </div>
        )}
      </SlideOver.Content>
      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(undefined)}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={() => onClose(convertSelection())} innerRef={submitRef}>
          {t('common.add')}
        </Button>
      </SlideOver.Controls>
    </>
  );
};
