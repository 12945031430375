import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiDeleteTenantInviteApiArg,
  ApiDeleteTenantInviteApiResponse,
  ApiDeleteTenantProjectInviteApiArg,
  ApiDeleteTenantProjectInviteApiResponse,
  ApiGetTenantTrustsApiArg,
  ApiGetTenantTrustsApiResponse,
  ApiPostInviteSetEnabledApiArg,
  ApiPostInviteSetEnabledApiResponse,
  ApiPostInviteTenantApiArg,
  ApiPostInviteTenantApiResponse,
  ApiPostReinviteTenantApiArg,
  ApiPostReinviteTenantApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiTenantTrustEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetTenantTrusts: {
    providesTags: (
      _result: ApiGetTenantTrustsApiResponse,
      _error: FetchBaseQueryError,
      _args: ApiGetTenantTrustsApiArg,
    ) => [{ type: ApiTagTypes.TenantTrusts, id: 'LIST' }],
  },
  /*------------------ INVALIDATE TAGS --------------------*/
  apiPostInviteTenant: {
    invalidatesTags: (
      _result: ApiPostInviteTenantApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostInviteTenantApiArg,
    ) =>
      error == null
        ? [
            {
              type: ApiTagTypes.TenantTrusts,
              id: 'LIST',
            },
            {
              type: ApiTagTypes.Company,
              id: args.body.companyId,
            },
            ApiTagTypes.Companies,
            ApiTagTypes.CompanyBranches,
          ]
        : [],
  },
  apiPostInviteSetEnabled: {
    invalidatesTags: (
      _result: ApiPostInviteSetEnabledApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostInviteSetEnabledApiArg,
    ) =>
      error == null
        ? [
            {
              type: ApiTagTypes.TenantTrusts,
              id: 'LIST',
            },
            ApiTagTypes.Companies,
            ApiTagTypes.CompanyBranches,
          ]
        : [],
  },
  apiPostReinviteTenant: {
    invalidatesTags: (
      _result: ApiPostReinviteTenantApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostReinviteTenantApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.TenantTrusts, id: 'LIST' }] : []),
  },
  apiDeleteTenantInvite: {
    invalidatesTags: (
      _result: ApiDeleteTenantInviteApiResponse,
      error: FetchBaseQueryError,
      _args: ApiDeleteTenantInviteApiArg,
    ) =>
      error == null
        ? [
            {
              type: ApiTagTypes.TenantTrusts,
              id: 'LIST',
            },
            ApiTagTypes.Companies,
            ApiTagTypes.CompanyBranches,
          ]
        : [],
  },
  apiDeleteTenantProjectInvite: {
    invalidatesTags: (
      _result: ApiDeleteTenantProjectInviteApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteTenantProjectInviteApiArg,
    ) =>
      error == null
        ? [
            {
              type: ApiTagTypes.TenantTrusts,
              id: 'LIST',
            },
            {
              type: ApiTagTypes.CompanyTenantTrust,
              id: args.body.trustId,
            },
            ApiTagTypes.Companies,
            ApiTagTypes.CompanyBranches,
          ]
        : [],
  },
};
