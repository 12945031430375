import { formatUnit } from './formatUnit';
import { FormatNumberOptions } from './formatNumber';

/**
 * Shorten number to thousands, millions, billions, etc.
 * http://en.wikipedia.org/wiki/Metric_prefix
 * @param {number} amount Number to shorten.
 * @param options formatting options.
 * @param options.maxDigits The maximum number of digits to display after the decimal point.
 * @param options.minDigits The minimum number of digits to display after the decimal point.
 * @param options.placeHolder The placeholder to use if the amount is null or undefined. Defaults to '-'.
 * @param options.signDisplay The sign display style to use. Defaults to 'auto'.
 * @param options.locale The locale to use. Defaults to the user's browser locale.
 * @returns {string}
 */
export const formatLargeNumber = (amount: number, options?: FormatNumberOptions) => {
  const units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

  for (let i = units.length - 1; i >= 0; i--) {
    const decimal = Math.pow(1000, i + 1);

    if (amount <= -decimal || amount >= decimal) {
      return formatUnit(amount / decimal, units[i], options);
    }
  }

  return amount;
};
