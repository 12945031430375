import { AuthSubjectReadModel, useApiGetAuthSubjectsQuery } from '@client/shared/api';
import { useState } from 'react';
import { LoadingIndicator, SlideOver, Modal, SettingsAddButton } from '@client/shared/toolkit';
import { InviteUserModal } from '../../InviteUserModal';
import { useTranslation } from 'react-i18next';
import { UserDetailsSlideOver } from './UserDetailsSlideOver';
import { UserRow } from './UserRow';
import { SettingsHeader } from '../../SettingsHeader';

export const Users = () => {
  const { t } = useTranslation();

  const [subject, selectSubject] = useState<AuthSubjectReadModel | undefined>();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const { data, isFetching } = useApiGetAuthSubjectsQuery();
  const people: AuthSubjectReadModel[] = data ?? [];

  const handleUserClick = (user: AuthSubjectReadModel) => {
    selectSubject(user);
    setShowDetails(true);
  };

  return (
    <div className="w-full">
      <SettingsHeader searchText={searchText} setSearchText={setSearchText}>
        {t('app.settingsUsers')}
      </SettingsHeader>
      <div className="relative h-full">
        <div className="flex w-full mt-4 mb-2">
          <div className="w-16 flex-none" />
          <div className="flex text-xs text-gray-500 w-[calc(100%_-_4rem_-_2rem)]">
            <div className="w-4/12 flex-none">{t('app.settingsColUser')}</div>
            <div className="w-3/12 flex-none">{t('app.settingsColRole')}</div>
            <div className="w-3/12 flex-none">{t('auth.groupPageTitle')}</div>
            <div className="w-2/12 flex-none pl-2">{t('app.settingsColStatus')}</div>
          </div>
          <div className="w-8 flex-1" />
        </div>

        {isFetching === true && <LoadingIndicator text={t('app.settingsUsersLoading') ?? ''} />}

        <div className="border-t border-gray-200">
          {people
            .filter((x) => x.name.toLowerCase().includes(searchText?.toLowerCase() ?? ''))
            .map((person, idx) => (
              <div key={idx} className="border-b border-gray-200 w-full">
                <UserRow person={person} onEdit={handleUserClick} />
              </div>
            ))}
        </div>
        <SettingsAddButton onClick={() => setIsInviteModalOpen(true)} />
      </div>

      <SlideOver isOpen={showDetails} variant='large' onClose={() => setShowDetails(false)}>
        {subject && <UserDetailsSlideOver user={subject} onClose={() => setShowDetails(false)} />}
      </SlideOver>

      <Modal isOpen={isInviteModalOpen} onClose={() => setIsInviteModalOpen(false)}>
        <InviteUserModal onClose={() => setIsInviteModalOpen(false)} />
      </Modal>
    </div>
  );
};
