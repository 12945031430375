import cn from 'classnames';
import React, { ReactElement, ReactNode, useMemo } from 'react';

export interface BreadcrumbItemProps {
  title: string;
  as?: 'div' | 'li';
  active?: boolean;
  icon?: ReactNode;
  onClick?: () => void
}

export const BreadcrumbItem = (props: BreadcrumbItemProps) => {
  const { active = false, as = 'li', onClick } = props;

  const classNames = useMemo(() => {
    return cn('float-left text-white font-medium flex items-center py-2 pr-4 pl-8 text-[15px] gap-2 relative h-10',
      'first:rounded-l-full last:rounded-r-full [&:not(:last-child)]:border-r',
      'after:content-[""] after:w-0 after:h-0 after:z-5 after:block',
      'after:absolute after:-right-[14px] after:top-0 after:z-[2]',
      'after:border-t-[20px] after:border-r-0 after:border-b-[20px] after:border-l-[15px]',
      'after:border-t-transparent after:border-r-transparent after:border-b-transparent',
      'last:after:content-none',
      'before:content-[""] before:w-0 before:h-0 before:block',
      'before:absolute before:-left-0.5 before:-top-[3px] before:z-[1]',
      'before:border-t-[23px] before:border-r-0 before:border-b-[23px] before:border-l-[18px]',
      'before:border-t-transparent before:border-r-transparent before:border-b-transparent',
      'before:border-l-gray-100',
      'last:after:content-none',
      'first:before:content-none',
      'first:pl-5 last:pr-5',
      {
        'bg-secondary after:border-l-secondary': active,
        'bg-gray-400 after:border-l-gray-400': !active,
        'cursor-pointer': onClick
      });
  }, [active, onClick]);

  if (as === 'li') {
    return (
      <li className={classNames} onClick={onClick}>
        <BreadcrumbItemContent {...props} />
      </li>
    );
  }
  return (
    <div className={classNames} onClick={onClick}>
      <BreadcrumbItemContent {...props} />
    </div>
  );
};

export const BreadcrumbItemContent = (props: BreadcrumbItemProps) => {
  const { title, icon } = props;

  return (
    <div className="text-white font-medium flex items-center text-[15px] gap-2 relative h-10">
      {icon ? React.cloneElement(icon as ReactElement, { className: 'w-[18px] flex-none' }): ''}
      <span className="truncate">{title}</span>
    </div>
  );
};

