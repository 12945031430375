import {
  ElementTimelineReadModel,
  useApiGetRiskElementQuery,
  useApiPostUpdateRiskElementMutation,
} from '@client/shared/api';
import React, { useEffect, useMemo, useState } from 'react';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import { EditTimeLineMoneyDistributionModalProps } from '../index';
import { TimeLineMoneyDistributionModal } from '@client/project/shared'
import { LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { timelineReadModelToPayload } from '@client/project/shared';
export const EditRisksTimeLineMoneyDistributionModal = (props: EditTimeLineMoneyDistributionModalProps) => {
  const { onClose, onSave, selectedElement, riskGroupId, disabled, selectedDate } = props;
  const { t } = useTranslation()
  const variantId = useLoadedVariantId();
  const projectId = useLoadedProjectId();
  const [putElement, { isLoading: isUpdating }] = useApiPostUpdateRiskElementMutation();

  const { data: dataRiskElement, isFetching } = useApiGetRiskElementQuery(
    {
      projectId: projectId ?? 'unset',
      calculationModelId: variantId ?? 'unset',
      id: selectedElement.elementId ?? '',
    },
    { skip: false }
  );

  const [timeline, setTimeline] = useState<ElementTimelineReadModel | null>(null);

  useEffect(() => {
    if (dataRiskElement?.readModel?.elementTimeline) {
      setTimeline(dataRiskElement.readModel.elementTimeline);
    }
  }, [dataRiskElement?.readModel.elementTimeline]);

  const totalValue = useMemo(() => {
    return dataRiskElement?.readModel.total
  }, [dataRiskElement?.readModel.total])

  const handleUpdate = async (timelineUpdated: ElementTimelineReadModel | null) => {
    if (selectedElement.elementId && dataRiskElement) {
      try {
        await safeMutation(
          putElement,
          {
            projectId: projectId ?? 'unset',
            calculationModelId: variantId ?? 'unset',
            id: selectedElement.elementId ?? 'unset',
            body: {
              isGroup: dataRiskElement.readModel.isGroup,
              description: dataRiskElement.readModel.description ?? '',
              riskCatalogElementId: dataRiskElement.readModel.riskCatalogElementId ?? null,
              probability: dataRiskElement?.readModel?.probability ?? 0,
              amount: {
                type: dataRiskElement?.readModel?.amount?.type ?? 'Static',
                staticFactor: dataRiskElement?.readModel?.amount?.staticValue
                  ? {
                    value: dataRiskElement?.readModel?.amount?.staticValue?.value ?? 0,
                    unit: dataRiskElement?.readModel?.amount.staticValue?.unit ?? '', // TODO: maybe nullable?
                  }
                  : null,
                formula: dataRiskElement?.readModel?.amount?.type === 'Formula' ? {
                  expression: dataRiskElement?.readModel?.amount.formulaValue?.expression ?? '',
                  expressionParameterPayloads:
                    dataRiskElement?.readModel?.amount.formulaValue?.expressionParameters?.map((p) => ({
                      position: p.position,
                      taxonomyElementId: p?.taxonomyItem?.id,
                      costCatalogElementId: p?.costCatalogElement?.id,
                      plotId: p?.plot?.id,
                    })) ?? [],
                  catalogElementId: dataRiskElement.readModel.riskCatalogElementId ?? null,
                  elementType: 'RiskElement',
                } : undefined,
              },
              unitPrice: {
                type: dataRiskElement?.readModel.unitPrice?.type ?? 'Static',
                staticFactor: dataRiskElement?.readModel?.unitPrice.staticValue
                  ? {
                    value: dataRiskElement?.readModel?.unitPrice.staticValue?.value ?? 0,
                    unit: dataRiskElement?.readModel?.unitPrice.staticValue?.unit ?? '€',
                  }
                  : null,
                formula: dataRiskElement?.readModel?.unitPrice?.type === 'Formula' ? {
                  expression: dataRiskElement?.readModel?.unitPrice?.formulaValue?.expression ?? '',
                  expressionParameterPayloads:
                    dataRiskElement?.readModel?.unitPrice?.formulaValue?.expressionParameters?.map((p) => ({
                      position: p.position,
                      taxonomyElementId: p?.taxonomyItem?.id,
                      costCatalogElementId: p?.costCatalogElement?.id,
                      plotId: p?.plot?.id,
                    })) ?? [],
                  catalogElementId: dataRiskElement.readModel.riskCatalogElementId ?? null,
                  elementType: 'RiskElement',
                } : undefined,
              },
              elementTimeline: timelineReadModelToPayload(timelineUpdated)
            },
          },
          isUpdating
        );

        onClose();
      } catch (e) {
        console.log(e);
      }
    }
  }

  return (
    <>
      {(isFetching || isUpdating) && <LoadingIndicator mode="overlay" />}
      <TimeLineMoneyDistributionModal
        disabled={disabled}
        timeline={timeline}
        variantId={variantId}
        totalValue={totalValue}
        riskGroupId={riskGroupId}
        disabledDistributionTypes={!(selectedElement.type === 'group') || !selectedElement.hasChildren ? ['Effective'] : []}
        onClose={() => {
          setTimeline(null);
          onClose();
        }}
        onChange={(timeline) => setTimeline(timeline)}
        onClear={() => setTimeline(null)}
        onSave={(timelineUpdated) => {
          handleUpdate(timelineUpdated).then(() => {
            onSave();
            setTimeline(null)
          })
        }}
        saveButtonText={t('common.save')}
        selectedDate={selectedDate}
      />
    </>
  );
};
