import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { ContractDocumentReadModel } from '@client/shared/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  DocumentListItem
} from '@client/shared/toolkit';
import { ContractDocumentDeleteModal } from './ContractDocumentDeleteModal';
interface ContractDocumentRowProps {
  contractId: string;
  contractDocument: ContractDocumentReadModel;
  canDelete: boolean;
}

export const ContractDocumentRow = ({ contractId, contractDocument, canDelete }: ContractDocumentRowProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const downloadUrl =`/api/projects/${loadedProjectId}/calculation-models/${loadedCalculationModelId}/contracts/${contractId}/documents/${contractDocument.id}`;

  return (
    <>
      <DocumentListItem
        fileName={contractDocument.name}
        fileSize={contractDocument.size}
        created={contractDocument.created}
        downloadUrl={downloadUrl}
        setIsDeleteModalOpen={canDelete ? setIsDeleteModalOpen : undefined}
        contextMenu
        documentViewerTitle={t('projectContract.contract')}
      />
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <ContractDocumentDeleteModal
          contractId={contractId}
          document={contractDocument}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
    </>
  );
};
