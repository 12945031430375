import React from 'react';
import { TaxonomyReadModel, PlotReadModel } from '@client/shared/api';
import { pdfjs, Document, Thumbnail } from 'react-pdf';

interface PDFThumbnailProps {
  file: {
    id: string;
    name: string;
  } | null;
  loadedProjectId: string | undefined;
  item?: TaxonomyReadModel | undefined;
  plot?: PlotReadModel | undefined;
  className?: string;
  width?: number;
}

export const PDFThumbnail = ({ file, loadedProjectId, item, plot, className, width = 150 }: PDFThumbnailProps) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
  return (
    <Document
      file={
        item
          ? `/api/projects/${loadedProjectId}/calculation-models/${item?.calculationModelId}/taxonomy/${item?.itemId}/file/${file?.id}`
          :`/api/projects/${loadedProjectId}/calculation-models/${plot?.calculationModelId}/plots/${plot?.plotId}/file/${file?.id}`
      }
      className={'rounded-lg flex justify-center items-center border border-slate-100 ' + className}
      onLoadError={(error) => console.log(error)}
    >
      <Thumbnail pageNumber={1} width={width} />
    </Document>
  );
};
