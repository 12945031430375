import {
  ApiDeleteRiskElementApiArg,
  ApiDeleteRiskElementApiResponse,
  ApiDeleteRiskElementDocumentApiArg,
  ApiDeleteRiskElementDocumentApiResponse,
  ApiGetRiskElementApiArg,
  ApiGetRiskElementApiResponse,
  ApiPostCreateRiskElementApiArg,
  ApiPostCreateRiskElementApiResponse,
  ApiPostCreateRiskElementDocumentApiArg,
  ApiPostCreateRiskElementDocumentApiResponse,
  ApiPostUpdateRiskElementApiArg,
  ApiPostUpdateRiskElementApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiCalculateRiskEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetRiskElement: {
    providesTags: (
      _result: ApiGetRiskElementApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetRiskElementApiArg,
    ) => [
      {
        type: ApiTagTypes.RiskElement,
        id: args.id,
      },
      { // TODO only that have formula
        type: ApiTagTypes.FormulaElements,
        id: args.calculationModelId,
      },
      {
        type: ApiTagTypes.CalculateElements,
        id: args.calculationModelId,
      },
    ],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiDeleteRiskElementDocument: {
    invalidatesTags: (
      _result: ApiDeleteRiskElementDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteRiskElementDocumentApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.RiskElement, id: args.riskElementId }] : []),
  },
  apiDeleteRiskElement: {
    invalidatesTags: (
      _result: ApiDeleteRiskElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteRiskElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.RisksCalculationModel, id: args.calculationModelId },
            // TODO only invalidate the elements that have a reference in formula to the cost element
            { type: ApiTagTypes.FormulaElements, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostCreateRiskElementDocument: {
    invalidatesTags: (
      _result: ApiPostCreateRiskElementDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateRiskElementDocumentApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.RiskElement, id: args.riskElementId }] : []),
  },
  apiPostCreateRiskElement: {
    invalidatesTags: (
      _result: ApiPostCreateRiskElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateRiskElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.RisksCalculationModel, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostUpdateRiskElement: {
    invalidatesTags: (
      _result: ApiPostUpdateRiskElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateRiskElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.RiskElement, id: args.id },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.UserDefinedFields, id: args.id },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.RisksCalculationModel, id: args.calculationModelId },
            // TODO only invalidate the elements that have a reference in formula to the cost element
            { type: ApiTagTypes.FormulaElements, id: args.calculationModelId },
          ]
        : [],
  },
};
