import { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import cn from 'classnames';

export interface DocumentViewerFileDataInlineEditProps extends PropsWithChildren {
  className?: string;
  defaultIsEditMode?: boolean;
  onClick?: () => void;
  toggleContent?: ReactNode;
  allowChangeMode?: boolean;
  updateEditMode?: (isEditMode: boolean) => void;
  closeOnBlur?: boolean;
  marginX?: string;
  marginY?: string;
  toggleContentClassName?: string
  noHover?: boolean
}

export const DocumentViewerFileDataInlineEdit = (props: DocumentViewerFileDataInlineEditProps) => {
  const {
    className,
    children,
    defaultIsEditMode = false,
    toggleContent,
    allowChangeMode = true,
    updateEditMode,
    closeOnBlur = true,
    marginX = '-mb-[18px] -mt-6',
    marginY = '-ml-6 -mr-6',
    toggleContentClassName = 'shadow-inner bg-gray-100 p-6',
    noHover = false
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [isEditMode, setIsEditMode] = useState(defaultIsEditMode);
  const [showSummary, setShowSummary] = useState(!defaultIsEditMode)
  const [isFocusable, setIsFocusable] = useState(false)

  const mountedStyle = { animation: 'inAnimation 200ms ease-in' };
  const unmountedStyle = {
    animation: 'outAnimation 200ms ease-out',
    animationFillMode: 'forwards',
  };

  useClickAway(ref, () => {
    if (allowChangeMode) {
      setIsEditMode(false);
    }
  });

  useEffect(() => {
    setIsEditMode(defaultIsEditMode);
  }, [defaultIsEditMode]);

  useEffect(() => {
    if (updateEditMode) {
      updateEditMode(isEditMode);
    }
  }, [isEditMode, updateEditMode]);

  useEffect(() => {
    if (isFocusable) {
      const firstElem = ref.current?.querySelector('input, button, textarea');
      if (firstElem) {
        setTimeout(() => {
          (firstElem as HTMLElement).focus();
          if (firstElem.tagName === 'BUTTON') {
            (firstElem as HTMLButtonElement).click();
          }
        }, 10)
      }
    }
  }, [isFocusable])

  if (!toggleContent) {
    return children;
  }

  return (
    <div className={cn('transition-height duration-400 ease-in', className)} ref={ref}>

      {!showSummary && (
        <div
          className={cn(toggleContentClassName, marginX, marginY)}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              setIsEditMode(false);
            }
          }}
          onBlur={
            closeOnBlur
              ? () => {
                setTimeout(() => {
                  // if there is an active child (like e.g. datepicker or select dropdown), do not close the edit mode on blur
                  const foundInputs = ref.current?.querySelectorAll('input, button');
                  let activeChild = false;
                  if (document.activeElement && foundInputs?.length) {
                    foundInputs.forEach((foundInput) => {
                      if (foundInput === document.activeElement) {
                        activeChild = true;
                      }
                    });
                  }
                  if (!ref.current?.querySelector('button[data-headlessui-state="open"]') && !activeChild) {
                    setIsEditMode(false);
                  }
                }, 100);
              }
              : undefined
          }
          style={isEditMode ? mountedStyle : unmountedStyle}
          onAnimationEnd={() => {
            if (!isEditMode) {
              setShowSummary(true)
              setIsFocusable(false)
            } else {
              setIsFocusable(true)
            }
          }}
        >
          {toggleContent}
        </div>
      )}

      {showSummary && (
        <div
          className="outline-secondary transition-colors duration-300"
          onClick={() => {
            setIsEditMode((prev) => !prev);
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              setIsEditMode((prev) => !prev);
              return false;
            }
          }}
          style={!isEditMode ? mountedStyle : unmountedStyle}
          onAnimationEnd={() => {
            if (isEditMode) {
              setShowSummary(false)
            }
          }}
        >
          <div className={cn("bg-white transition-colors duration-300 cursor-pointer",
            {
              'hover:bg-gray-100': noHover === false
            })}>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};
