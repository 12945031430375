import React, { PropsWithChildren, useMemo } from 'react';
import { DealReadModel } from '@client/shared/api';
import { DealTabPanelContent } from './DealSlideOverTabPanels';
import { DealDocuments } from './DealDocuments';
import { useTranslation } from 'react-i18next';
import {
  DealDealIcon,
  DealDocumentIcon,
  DocumentCustomIcon,
  SlideOverOnCloseProps, SlideOverTabOptions, SlideOverWithTabs
} from '@client/shared/toolkit';

interface DealDetailsSlideOverProps extends SlideOverOnCloseProps {
  deal: DealReadModel | undefined;
}

export const DealDetailsSlideOver = ({ deal, onClose }: PropsWithChildren<DealDetailsSlideOverProps>) => {
  const { t } = useTranslation();

  const tabOptions: SlideOverTabOptions[] = useMemo(() => {
    return [
      {
        header: <div className="text-xs -mb-2">{t('projectRent.tabDeals')}</div>,
        name: 'deals',
        panel: deal ? <DealTabPanelContent deal={deal} /> : '',
        icon: <DealDealIcon />
      },
      {
        header: <div className="text-xs -mb-2">{t('projectRent.tabDocuments')}</div>,
        name: 'documents',
        panel: deal ? <DealDocuments deal={deal} allowUpload={true} className="px-6 pb-6" /> : '',
        icon: <DealDocumentIcon />
      }
    ];
  }, [t, deal]);

  return (
    <SlideOverWithTabs
      hasPadding={false}
      icon={<DocumentCustomIcon />}
      title={deal?.name}
      subtitle={deal?.type === 'Rent' ? t('projectRent.dealRentContract') : t('projectRent.dealSaleContract')}
      tabOptions={tabOptions}
      onClose={onClose}
    />
  )
}
