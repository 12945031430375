import { useLoadedProject } from './useLoadedProject';

export const useLoadedProjectValueType = () => {
  const loadedProject = useLoadedProject();
  return loadedProject?.data?.project.payload.calculateValueType ?? 'Net';
};

export const useLoadedProjectValueTypeIsNet = () => {
  const valueType = useLoadedProjectValueType();
  return valueType === 'Net';
};