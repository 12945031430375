import { Routes, Route } from 'react-router-dom';
import { MultiDashboardRoute } from '@client/project/dashboard';
import { ProtectedRoute, ROUTES_CONFIG } from '@client/shared/permissions';
import { settings, useUi } from '@client/shared/store';
import { MultiReportingRoute } from '@client/project/reporting';

/**
 * MultiProjectRoute is a module entrypoint (we are talking about one specific project)
 * It tries to ensure valid data at a top point and keep sub routes from having to deal
 * with query parameters / path segments
 */
export const MultiProjectRoutes = () => {

  const ui = useUi();
  const user = ui.appUser;

  return (
    <Routes>
      <Route
        path={ROUTES_CONFIG.MULTI_PROJECT_DASHBOARD.name}
        element={
          <ProtectedRoute routeConfig={ROUTES_CONFIG.MULTI_PROJECT_DASHBOARD}>
            <MultiDashboardRoute />
          </ProtectedRoute>
        }
      />
      {/* TODO Only for Axa presentation, remove later again */}
      {((user.tenant?.tenantId === '3239f945-4fce-4ebb-931f-89700f4c7631') || settings.mainDomain === 'probis.qa' || settings.mainDomain === 'probis.test' || settings.mainDomain === 'probis.dev') && (
        <Route path={ROUTES_CONFIG.MULTI_PROJECT_REPORTING.name} element={(
          <MultiReportingRoute />
        )} />
      )}
      {/* TODO insert again after Panattoni presentation
         <Route
          path={ROUTES_CONFIG.MULTI_PROJECT_REPORTING.name}
          element={
            <ProtectedRoute routeConfig={ROUTES_CONFIG.MULTI_PROJECT_REPORTING}>
               <MultiReportingRoute />
            </ProtectedRoute>
          }
        />
      <Route path="*" element={<Navigate to={ROUTES_CONFIG.MULTI_PROJECT_DASHBOARD.name} replace />} />
      */}
    </Routes>
  );
};
