import { createContext, PropsWithChildren, useMemo } from 'react';
import { CashFlowReportReadModel, CashFlowReportResponse } from '@client/shared/api';

export type ReportCostBreakdownContextType = {
  elements: CashFlowReportReadModel[];
  metadata: CashFlowReportResponse | undefined;
};
export const ReportCostBreakdownContext = createContext<ReportCostBreakdownContextType>({
  elements: [],
  metadata: undefined,
});

interface ReportCostBreakdownContextProviderProps extends PropsWithChildren {
  elements: CashFlowReportReadModel[];
  metadata: CashFlowReportResponse | undefined;
}

export const ReportCostBreakdownContextProvider = (props: ReportCostBreakdownContextProviderProps) => {
  const { elements, metadata, children } = props;
  const value = useMemo(
    () => ({
      elements,
      metadata,
    }),
    [elements, metadata],
  );

  return <ReportCostBreakdownContext.Provider value={value}>{children}</ReportCostBreakdownContext.Provider>;
};
