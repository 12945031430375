import { t } from "i18next";

export const getContractTitleStateLabel = (titleStatus : string) => {
    switch (titleStatus){
        case 'Optional':
            return t('projectContract.contractTitleStateOptional');
        case 'Expected':
            return t('projectContract.contractTitleStateExpected');
        case 'Announced':
            return t('projectContract.contractTitleStateAnnounced');
        case 'Budgeted':
            return t('projectContract.contractTitleStateBudgeted');
        case 'Received':
            return t('projectContract.contractTitleStateReceived');
        case 'Approved':
            return t('projectContract.contractTitleStateApproved');
        case 'Commissioned':
            return t('projectContract.contractTitleStateCommissioned');
        case 'Rejected': 
            return t('projectContract.contractTitleStateRejected');
        case 'Canceled':
            return t('projectContract.contractTitleStateCanceled');
        case 'Reserve':
            return t('projectContract.contractTitleStateReserve')
        default:
            return '';
    }
}
