import { stopPropagation } from '@client/shared/utilities';
import { ContextMenu, ContextMenuItem } from '@client/shared/toolkit';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import React, { ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';

interface ElementRowMenuProps {
  items: ContextMenuItem[];
  details?: ReactNode;
}

export const ElementRowMenu = ({ items, details }: ElementRowMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'right-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-1, 1],
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['right-start', 'bottom'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });
  return (
    <div
      className="absolute -right-8 lg:right-0 bottom-0 top-0 w-[32px] flex lg:opacity-0 lg:pointer-events-none lg:group-hover:opacity-100 lg:group-hover:pointer-events-auto items-center justify-center lg:bg-white lg:shadow-sm lg:border-l lg:border-t-[0.5px] lg:border-b-[0.5px]"
      onClick={stopPropagation}
    >
      {details ? (
        <>
          <div ref={setTargetElement} className="w-full h-full">
            <ContextMenu
              className="w-full h-full flex items-center justify-center"
              items={items}
              button={<EllipsisVerticalIcon className="w-6 h-6 text-slate-700" />}
              placement="right-start"
              modifiers={[
                {
                  name: 'flip',
                  options: {
                    fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
                    rootBoundary: 'viewport',
                  },
                },
                { name: 'offset', options: { offset: [0, 3] } },
              ]}
              handlePopoverVisibility={setIsMenuOpen}
            />
          </div>
          {!isMenuOpen && (
            <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="z-20 hidden lg:block">
              {details}
            </div>
          )}
        </>
      ) : (
        <ContextMenu
          className="w-full h-full flex items-center justify-center"
          items={items}
          button={<EllipsisVerticalIcon className="w-6 h-6 text-slate-700" />}
          placement="right-start"
          modifiers={[
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
                rootBoundary: 'viewport',
              },
            },
            { name: 'offset', options: { offset: [0, 3] } },
          ]}
        />
      )}
    </div>
  );
};
