import { TenantCatalogType, useApiCreateCatalogMutation } from '@client/shared/api';
import { NewCatalogFormValidationSchema, NewCatalogFormValidationValues } from './NewCatalogFormValidationSchema';
import {
  FormRefHandle,
  Form,
  Wizard,
  FormField,
  TextInput,
  BaseSelect,
  Button,
  Modal,
  TagWindowIcon,
  NewProjectWizardIcon,
  WizardSlideHeader,
  WizardSlideContent,
  wizardHandleOnSlideChangeFormValidation, LoadingIndicator,
} from '@client/shared/toolkit';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { safeMutation } from '@client/shared/utilities';

interface NewCatalogWizardProps {
  onClose: (clearSelection?: boolean) => void;
}

export const NewCatalogWizard = ({ onClose }: NewCatalogWizardProps) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const formRef = useRef<FormRefHandle<NewCatalogFormValidationValues>>();
  const [call, { isLoading }] = useApiCreateCatalogMutation();

  const handleSubmit = async (data: NewCatalogFormValidationValues) => {
    try {
      await safeMutation(
        call,
        {
          body: {
            catalogName: data.name,
            type: data.type,
          },
        },
        isLoading,
      );
      onClose(true);
    } catch {
      /* left blank */
    }
  };

  const defaultFormValues = {
    name: '',
    type: 'Costs' as TenantCatalogType,
  };

  return (
    <>
      {isLoading && (
        <LoadingIndicator text={t('common.loading')} mode="overlay-window" />
      )}
      <Form<NewCatalogFormValidationValues>
        validationSchema={NewCatalogFormValidationSchema}
        defaultValues={defaultFormValues}
        ref={formRef}
        className="h-full"
        onSubmit={handleSubmit}
      >
        <Wizard
          currentStep={currentStep}
          onSlideChange={(step) =>
            wizardHandleOnSlideChangeFormValidation(step, formRef, setCurrentStep)
          }
          className="w-[740px] h-[560px]"
        >
          <Wizard.Slides>
            <Wizard.Slide>
              <WizardSlideHeader
                icon={<NewProjectWizardIcon />}
                title={t('app.newCatalog')}
              />
              <WizardSlideContent>
                <FormField name="name">
                  {(control) => (
                    <TextInput
                      label={t('common.name')}
                      icon={<TagWindowIcon />}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="type">
                  {(control) => (
                    <BaseSelect
                      label={t('templates.catalogType')}
                      options={[
                        { label: t('templates.costCatalog'), value: 'Costs' as TenantCatalogType },
                        { label: t('templates.riskCatalog'), value: 'Risks' as TenantCatalogType },
                        { label: t('templates.earningsCatalog'), value: 'Earnings' as TenantCatalogType },
                        { label: t('templates.financingCatalog'), value: 'Finance' as TenantCatalogType },
                      ]}
                      icon={<TagWindowIcon />}
                      {...control}
                    />
                  )}
                </FormField>
              </WizardSlideContent>
            </Wizard.Slide>
          </Wizard.Slides>
          <Wizard.Navigation>
            {() => (
              <Modal.Controls
                className={classNames({
                  'bg-slate-100': currentStep === 0,
                  'bg-white': currentStep !== 0,
                })}
              >
                {(() => {
                  return (
                    <>
                      <Button variant="text" onClick={() => onClose(true)}>
                        {t('common.cancel')}
                      </Button>
                      <Button variant="primary" formSubmit>
                        {t('common.create')}
                      </Button>
                    </>
                  );
                })()}
              </Modal.Controls>
            )}
          </Wizard.Navigation>
        </Wizard>
      </Form>
    </>
  );
};
