import { AppLayout, AppLayoutContent, AppLayoutTitle } from '../components';
import { PreviewRoute } from './PreviewRoute';
import { useDocumentTitle } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

export const HelpRoute = () => {
  const { t } = useTranslation();

  useDocumentTitle({ title: t('app.menuHelp') });

  return (
    <AppLayout>
      <AppLayoutTitle>Help</AppLayoutTitle>
      {/* <AppLayoutMenu>Menu</AppLayoutMenu> */}
      <AppLayoutContent>
        <PreviewRoute hideBackButton={true} />
      </AppLayoutContent>
    </AppLayout>
  );
};
