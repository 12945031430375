import { PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';

export interface CardProps extends PropsWithChildren {
  className?: string
  divider?: boolean
  padding?: boolean
  onClick?: () => void
}
export const Card = (props: CardProps) => {
  const {
    className,
    children,
    divider = true,
    padding = true,
    onClick
  } = props

  return (
    <div
      className={cn('rounded-md w-full shadow flex flex-col bg-white overflow-hidden border-1',
        {
          'divide-y-2': divider,
          'px-6 py-4': padding,
          'cursor-pointer': onClick
        },
        className)}
      onClick={onClick}
    >
      {children}
    </div>
  )
}


export interface CardDataSetProps extends PropsWithChildren {
  className?: string
  label?: string | ReactNode
  title?: string | ReactNode
  subtitle?: string | ReactNode
  text?: string | ReactNode
}
export const CardDataSet = (props: CardDataSetProps) => {
  const {
    className,
    children,
    label,
    title,
    subtitle,
    text
  } = props

  return (
    <div className={cn('flex flex-col', className)}>
      {label && (
        <span className="text-[11px] text-gray-500">{label}</span>
      )}
      {title && (
        <span className="text-lg font-bold">{title}</span>
      )}
      {subtitle && (
        <span className="text-[15px]">{subtitle}</span>
      )}
      {text && (
        <span className="text-xs text-gray-500">{text}</span>
      )}
      {children}
    </div>
  )
}
