import {
  AdjustmentType,
  DealRentConditionReadModel,
  DealType,
  PaymentFrequency,
  PaymentMethod,
  SecurityType,
  useApiPostUpdateRentDealConditionMutation,
} from '@client/shared/api';
import {
  Button,
  Form,
  NumberInput,
  BaseSelect,
  DatePicker,
  FormField,
  SlideOver,
  SlideOverOnCloseProps,
  EstimateIcon,
  PurchaseOrderIcon,
  Calendar7Icon,
  TermsAndConditionsIcon,
  TransactionIcon,
  PeriodSliderIcon,
  CancelIcon,
  DateSpanIcon,
  LoveTimeIcon,
  UserShieldIcon, DealAdjustmentTypeIcon,
  LoadingIndicator,
} from '@client/shared/toolkit';
import { formatNullableDateOnly, safeMutation } from '@client/shared/utilities';
import {
  getPaymentMethodValueLabel,
  getSecurityTypeValueLabel,
  getPaymentFrequencyValueLabel,
  getAdjustmentTypeValueLabel,
} from '../../utils';
import { useTranslation } from 'react-i18next';
import {
  DealRentConditionFormValidationSchema,
  DealRentConditionFormValidationValues,
} from './DealRentConditionFormValidationSchema';
import { useLoadedProjectId } from '@client/project/store';
import { useCanWriteDeal } from '../../hooks';
import { useRef } from 'react';

interface DealRentConditionSlideOverProps extends SlideOverOnCloseProps {
  isSecurityCondition: boolean;
  dealId: string;
  type: DealType;
  rentCondition?: DealRentConditionReadModel;
  isReadOnly:boolean;
}

const getDate = (date: string | null | undefined): Date | undefined => {
  return date ? new Date(date) : undefined;
};

export const DealRentConditionSlideOver = ({
  dealId,
  type,
  isSecurityCondition,
  rentCondition,
  onClose,
  isReadOnly
}: DealRentConditionSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const projectId = useLoadedProjectId();

  const readOnly = !useCanWriteDeal(type) || isReadOnly;

  const availablePaymentMethods: { label: string; value: PaymentMethod }[] = [
    { label: getPaymentMethodValueLabel('OneTime'), value: 'OneTime' },
    { label: getPaymentMethodValueLabel('PaymentPlan'), value: 'PaymentPlan' },
  ];

  const availableSecurityTypes: { label: string; value: SecurityType }[] = [
    { label: getSecurityTypeValueLabel('Surety'), value: 'Surety' },
    { label: getSecurityTypeValueLabel('RentalDeposit'), value: 'RentalDeposit' },
    { label: getSecurityTypeValueLabel('DepositInsurance'), value: 'DepositInsurance' },
  ];

  const availableBillingPeriods: { label: string; value: PaymentFrequency }[] = [
    { label: getPaymentFrequencyValueLabel('Monthly'), value: 'Monthly' },
    { label: getPaymentFrequencyValueLabel('Quarterly'), value: 'Quarterly' },
    { label: getPaymentFrequencyValueLabel('Yearly'), value: 'Yearly' },
  ];

  const availableAdjustmentTypes: { label: string; value: AdjustmentType }[] = [
    { label: getAdjustmentTypeValueLabel('ConsumerPriceIndex'), value: 'ConsumerPriceIndex' },
    { label: getAdjustmentTypeValueLabel('Fix'), value: 'Fix' },
  ];

  const [update, { isLoading }] = useApiPostUpdateRentDealConditionMutation();

  const handleSubmit = async (data: DealRentConditionFormValidationValues) => {
    try {
      await safeMutation(
        update,
        {
          projectId: projectId ?? 'unset',
          dealId: dealId ?? '',
          body: {
            securityAmount: data.securityAmount ?? 0,
            securityType: data.securityType as SecurityType,
            paymentMethod: data.paymentMethod as PaymentMethod,
            paymentAppointment: formatNullableDateOnly(data.paymentAppointment),
            rentStart: formatNullableDateOnly(data.rentStart),
            rentEnd: formatNullableDateOnly(data.rentEnd),
            contractTerminationNotice: data.contractTerminationNotice ?? 0,
            contractEarliestTerminationDate: formatNullableDateOnly(data.contractEarliestTerminationDate),
            contractExtensionDuration: data.contractExtensionDuration ?? 0,
            contractPossibleExtensions: data.contractPossibleExtensions ?? 0,
            rentFreePeriodStart: formatNullableDateOnly(data.rentFreePeriodStart),
            rentFreePeriodEnd: formatNullableDateOnly(data.rentFreePeriodEnd),
            billingPeriod: data.billingPeriod as PaymentFrequency,
            rentDueDays: data.rentDueDays ?? 0,
            adjustmentType: data.adjustmentType as AdjustmentType,
            adjustmentTypeActiveValue: data.adjustmentTypeActiveValue ?? 0,
            adjustmentInterval: data.adjustmentInterval as PaymentFrequency,
            adjustmentDeadline: formatNullableDateOnly(data.adjustmentDeadline),
          },
        },
        isLoading
      );

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const defaultFormValues = {
    isSecurityCondition: isSecurityCondition,
    securityAmount: rentCondition?.securityAmount ?? 0,
    securityType: rentCondition?.securityType ?? 'Surety',
    paymentMethod: rentCondition?.paymentMethod ?? 'OneTime',
    paymentAppointment: getDate(rentCondition?.paymentAppointment),
    rentStart: getDate(rentCondition?.rentStart),
    rentEnd: getDate(rentCondition?.rentEnd),
    contractTerminationNotice: rentCondition?.contractTerminationNotice ?? 0,
    contractEarliestTerminationDate: getDate(rentCondition?.contractEarliestTerminationDate),
    contractPossibleExtensions: rentCondition?.contractPossibleExtensions ?? 0,
    contractExtensionDuration: rentCondition?.contractExtensionDuration ?? 0,
    rentFreePeriodStart: getDate(rentCondition?.rentFreePeriodStart),
    rentFreePeriodEnd: getDate(rentCondition?.rentFreePeriodEnd),
    billingPeriod: rentCondition?.billingPeriod ?? 'Monthly',
    rentDueDays: rentCondition?.rentDueDays ?? 0,
    adjustmentType: rentCondition?.adjustmentType ?? 'ConsumerPriceIndex',
    adjustmentTypeActiveValue: rentCondition?.adjustmentTypeActiveValue ?? 0,
    adjustmentInterval: rentCondition?.adjustmentInterval ?? 'Monthly',
    adjustmentDeadline: getDate(rentCondition?.adjustmentDeadline),
  };

  return (
    <>
      <SlideOver.Header
        title={t('projectRent.dealConditionSectionTitleConditions')}
        backgroundClassName="bg-sky-900"
        onClose={onClose}
      />

      <Form<DealRentConditionFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={DealRentConditionFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
      >
        <SlideOver.Content>
          {isLoading && <LoadingIndicator text={t('projectRent.dealConditionUpdateLoading') ?? ''} mode="overlay" />}
          {isSecurityCondition ? (
            <div className="m-8 bg-white">
              <div className="divide-gray-100 divide-y">
                <FormField name="securityAmount">
                  {(control) => (
                    <NumberInput
                      disabled={readOnly}
                      label={t('projectRent.dealConditionLabelSecurityAmount')}
                      icon={<UserShieldIcon />}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="paymentMethod">
                  {(control) => (
                    <BaseSelect
                      disabled={readOnly}
                      label={t('projectRent.dealConditionLabelPaymentMethod')}
                      options={availablePaymentMethods}
                      icon={<DateSpanIcon />}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="paymentAppointment">
                  {(control) => (
                    <DatePicker
                      disabled={readOnly}
                      label={t('projectRent.dealConditionLabelPaymentAppointment')}
                      icon={<Calendar7Icon />}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="securityType">
                  {(control) => (
                    <BaseSelect
                      disabled={readOnly}
                      label={t('projectRent.dealConditionLabelSecurityType')}
                      options={availableSecurityTypes}
                      icon={<TermsAndConditionsIcon />}
                      {...control}
                    />
                  )}
                </FormField>
              </div>
            </div>
          ) : (
            <>
              <div className="m-8">
                <div className="text-md pl-2 py-1">{t('projectRent.dealConditionSectionTitleTimeLine')}</div>
                <div className="divide-gray-100 divide-y bg-white">
                  <div className="grid grid-cols-2 divide-x">
                    <FormField name="rentStart">
                      {(control) => (
                        <DatePicker
                          disabled={readOnly}
                          label={t('projectRent.dealConditionLabelRentStart')}
                          icon={<Calendar7Icon />}
                          {...control}
                        />
                      )}
                    </FormField>
                    <FormField name="rentEnd">
                      {(control) => (
                        <DatePicker
                          disabled={readOnly}
                          label={t('projectRent.dealConditionLabelRentEnd')}
                          {...control}
                        />
                      )}
                    </FormField>
                  </div>
                  <div className="grid grid-cols-2 divide-x">
                    <FormField name="contractTerminationNotice">
                      {(control) => (
                        <NumberInput
                          disabled={readOnly}
                          label={t('projectRent.dealConditionLabelContractPeriodOfNoticeInMonths')}
                          icon={<CancelIcon />}
                          {...control}
                        />
                      )}
                    </FormField>
                    <FormField name="contractEarliestTerminationDate">
                      {(control) => (
                        <DatePicker
                          disabled={readOnly}
                          label={t('projectRent.dealConditionLabelContractEarliestTerminationDate')}
                          {...control}
                        />
                      )}
                    </FormField>
                  </div>
                  <div className="grid grid-cols-2 divide-x">
                    <FormField name="contractPossibleExtensions">
                      {(control) => (
                        <NumberInput
                          disabled={readOnly}
                          label={t('projectRent.dealConditionLabelPossibleExtensions')}
                          icon={<PeriodSliderIcon />}
                          {...control}
                        />
                      )}
                    </FormField>
                    <FormField name="contractExtensionDuration">
                      {(control) => (
                        <NumberInput
                          disabled={readOnly}
                          label={t('projectRent.dealConditionLabelExtensionDuration')}
                          {...control}
                        />
                      )}
                    </FormField>
                  </div>
                  <div className="grid grid-cols-2 divide-x">
                    <FormField name="rentFreePeriodStart">
                      {(control) => (
                        <DatePicker
                          disabled={readOnly}
                          label={t('projectRent.dealConditionLabelRentFreePeriodStart')}
                          icon={<LoveTimeIcon />}
                          {...control}
                        />
                      )}
                    </FormField>
                    <FormField name="rentFreePeriodEnd">
                      {(control) => (
                        <DatePicker
                          disabled={readOnly}
                          label={t('projectRent.dealConditionLabelRentFreePeriodEnd')}
                          {...control}
                        />
                      )}
                    </FormField>
                  </div>
                </div>
              </div>
              <div className="m-8">
                <div className="text-md pl-2 py-1">{t('projectRent.dealConditionSectionTitlePaymentConditions')}</div>
                <div className="divide-gray-100 divide-y bg-white">
                  <FormField name="billingPeriod">
                    {(control) => (
                      <BaseSelect
                        disabled={readOnly}
                        label={t('projectRent.dealConditionLabelBillingPeriod')}
                        options={availableBillingPeriods}
                        icon={<PurchaseOrderIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="rentDueDays">
                    {(control) => (
                      <NumberInput
                        disabled={readOnly}
                        label={t('projectRent.dealConditionLabelRentDueDay')}
                        icon={<TransactionIcon />}
                        {...control}
                      />
                    )}
                  </FormField>
                  <div className="grid grid-cols-2 divide-x">
                    <FormField name="adjustmentType">
                      {(control) => (
                        <BaseSelect
                          disabled={readOnly}
                          label={t('projectRent.dealConditionLabelAdjustmentPeriod')}
                          options={availableAdjustmentTypes}
                          icon={<DealAdjustmentTypeIcon />}
                          {...control}
                        />
                      )}
                    </FormField>
                    <FormField name="adjustmentTypeActiveValue">
                      {(control) => (
                        <NumberInput
                          disabled={readOnly}
                          label={t('projectRent.dealConditionLabelAdjustmentValue')}
                          {...control}
                        />
                      )}
                    </FormField>
                  </div>
                  <div className="grid grid-cols-2 divide-x">
                    <FormField name="adjustmentInterval">
                      {(control) => (
                        <BaseSelect
                          disabled={readOnly}
                          label={t('projectRent.dealConditionLabelAdjustmentInterval')}
                          options={availableBillingPeriods}
                          icon={<EstimateIcon />}
                          {...control}
                        />
                      )}
                    </FormField>
                    <FormField name="adjustmentDeadline">
                      {(control) => (
                        <DatePicker
                          disabled={readOnly}
                          label={t('projectRent.dealConditionLabelAdjustmentDeadline')}
                          icon={<EstimateIcon />}
                          {...control}
                        />
                      )}
                    </FormField>
                  </div>
                </div>
              </div>
            </>
          )}
        </SlideOver.Content>

        <SlideOver.Controls>
          <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
            {t('common.cancel')}
          </Button>
          {!readOnly && (
            <Button variant="primary" formSubmit={true} innerRef={submitRef}>
              {t('common.save')}
            </Button>
          )}
        </SlideOver.Controls>
      </Form>
    </>
  );
};
