import { DecoratedCard, Modal, PencilIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { DatevClientMasterDataCard, DatevClientUpdateModal } from '@client/project/shared';
import { useState } from 'react';
import { DatevClientReadModel } from '@client/shared/api';

interface DatevClientSlideOverMasterDataTabProps {
  apiAccessId: string;
  clientData: DatevClientReadModel | null | undefined;
}

export const DatevClientSlideOverMasterDataTab = ({
  apiAccessId,
  clientData,
}: DatevClientSlideOverMasterDataTabProps) => {
  const { t } = useTranslation();

  const [isUpdateClientModalOpen, setIsUpdateClientModalOpen] = useState(false);

  return (
    <DecoratedCard>
      <DecoratedCard.Header
        showActionButton={true}
        icon={<PencilIcon />}
        onActionClick={() => setIsUpdateClientModalOpen(true)}
      >
        {t('app.settingsApiDatevMasterData')}
      </DecoratedCard.Header>
      <DecoratedCard.Content className="p-8">
        {clientData ? (
          <DatevClientMasterDataCard clientData={clientData} />
        ) : (
          t('app.settingsApiDatevNoMasterDataAvailable')
        )}
      </DecoratedCard.Content>
      <Modal isOpen={isUpdateClientModalOpen} onClose={() => setIsUpdateClientModalOpen(false)}>
        <DatevClientUpdateModal
          apiAccessId={apiAccessId}
          isOpen={isUpdateClientModalOpen}
          onClose={() => setIsUpdateClientModalOpen(false)}
          datevClientId={clientData?.id ?? ''}
        />
      </Modal>
    </DecoratedCard>
  );
};
