import {
  ApiPostUpdateInvoiceCalculationRuleApiArg,
  ApiPostUpdateInvoiceCalculationRuleApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiInvoiceCalculationEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostUpdateInvoiceCalculationRule: {
    invalidatesTags: (
      _result: ApiPostUpdateInvoiceCalculationRuleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateInvoiceCalculationRuleApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Invoice, id: args.invoiceId }] : []),
  }
}
