import {
  ContractReadModel,
  ContractTitleReadModel,
  InvoiceReadModel,
} from '@client/shared/api';
import React, { useState } from 'react';
import { FormattedCurrency } from '../../FormattedCurrency';
import { SlideOver } from '@client/shared/toolkit';
import { ContractEditTitleSlideOver } from '../../ContractTitle';
import { InvoiceTitle, InvoiceTitleSum } from './InvoiceTitlesList';

interface InvoiceContractTitlesListProps {
  contract: ContractReadModel
  invoice: InvoiceReadModel
}
export const InvoiceContractTitlesList = (props: InvoiceContractTitlesListProps) => {
  const {
    contract
  } = props

  const [selectedTitle, setSelectedTitle] = useState<ContractTitleReadModel | null>(null)
  const [openSlideOver, setOpenSlideOver] = useState<'edit' | null>(null)

  return (
    <>
      {contract.titles.length > 0 && (
        <div className="pt-2">
          {contract.titles.map((title) => (
            <InvoiceTitle
              key={`invoice-contract-title-${title.id}`}
              code={title.code}
              onClick={() => {
                setSelectedTitle(title)
                setOpenSlideOver('edit')
              }}
              title={title.name}
              value={<FormattedCurrency amount={title.contractValueNet} />}
            />
          ))}
          <InvoiceTitleSum
            net={contract.contractValueNet}
            gross={contract.contractValueGross}
          />
        </div>
      )}

      <SlideOver
        isOpen={openSlideOver === 'edit'}
        onClose={() => {
          setOpenSlideOver(null)
        }}
      >
        {selectedTitle && (
          <ContractEditTitleSlideOver
            title={selectedTitle}
            contract={contract}
            onClose={() => {
              setOpenSlideOver(null)
            }}
          />
        )}
      </SlideOver>
    </>
  )
}
