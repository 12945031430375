export const partitionArray = (keys : string[], partitionSize : number) =>{
    const segments : [string[]] = [[]];
    let sublist : string[] = [];

    keys.forEach((key, index) => {
        sublist.push(key)

        if (index === keys.length -1 || (index + 1) % partitionSize === 0){
        segments.push(sublist);
        sublist = [];
        }
    })

    return segments;
};