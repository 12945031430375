import { EarningsType, TenantCatalogType } from '@client/shared/api';
import * as yup from 'yup';
import { InferType } from 'yup';

export const NewCatalogElementFormValidationSchema = yup.object({
  code: yup.string().optional(),
  name: yup.string().required('validation.required'),
  earningsElementType: yup.mixed<EarningsType>().when('type', {
    is: (type: TenantCatalogType) => type === 'Earnings',
    then: (schema) => schema.required('validation.required'),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});
export type NewCatalogElementFormValidationValues = InferType<typeof NewCatalogElementFormValidationSchema>;
