import { CommitmentReadModel, ShortContractReadModel, useApiPostUpdateCommitmentMutation } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';

import { Button, SlideOver, SlideOverOnCloseProps, SearchInput, Highlighted, LoadingIndicator } from '@client/shared/toolkit';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';

interface CommitmentAddContractSlideOverProps extends SlideOverOnCloseProps {
  commitment: CommitmentReadModel;
  contracts: ShortContractReadModel[] | null;
}

export const CommitmentAddContractSlideOver = ({
  onClose,
  commitment,
  contracts,
}: CommitmentAddContractSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const [selectedContracts, setSelectedContracts] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [postUpdateCommitment, { isLoading: isUpdating }] = useApiPostUpdateCommitmentMutation();

  useEffect(() => {
    if (commitment.contracts) {
      setSelectedContracts(commitment.contracts.map((c) => c.id));
    }
  }, [commitment]);

  const handleSubmit = async () => {
    if (loadedVariantId && loadedProjectId) {
      try {
        await safeMutation(
          postUpdateCommitment,
          {
            commitmentId: commitment.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: {
              code: commitment.code,
              name: commitment.name,
              description: commitment.description,
              addedContracts: selectedContracts.filter((c) => !commitment.contracts.map((c) => c.id).includes(c)),
              deletedContracts: commitment.contracts.map((c) => c.id).filter((c) => !selectedContracts.includes(c)),
              addedBudgetAssignments: [],
              updatedBudgetAssignments: [],
              deletedBudgetAssignments: [],
            },
          },
          isUpdating,
        );
        onClose(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <>
      <SlideOver.Header
        title={t('projectContract.addContractCommitmentTitle')}
        onClose={onClose}
        backgroundClassName="bg-sky-900"
      />

      <SlideOver.Content className="p-8">
        {(isUpdating) && <LoadingIndicator text={t('common.loading')} mode="overlay" />}
        <div className="bg-white">
          <div className="p-5">
            <SearchInput
              value={searchValue}
              handleSearch={(val) => setSearchValue(val)}
              className="max-w-[428px] text-gray-400 bg-gray-100"
              bgColor="bg-gray-100"
            />
          </div>
          {contracts
            ?.filter(
              (contract) =>
                contract.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                contract.code.toLowerCase().includes(searchValue.toLowerCase()),
            )
            .map((contract) => {
              const isAdded = selectedContracts.includes(contract.id);
              return (
                <div
                  key={contract.id}
                  className="flex flex-row items-center justify-between px-5 py-3 border-b border-gray-200"
                >
                  <div className="flex flex-row items-center">
                    <div className="flex flex-col">
                      <span className="font-medium text-sm">
                        <Highlighted text={contract.code} highlight={searchValue} />
                      </span>
                      <span className="text-xs text-gray-500">
                        <Highlighted text={contract.name} highlight={searchValue} />
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                      checked={isAdded}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedContracts([...selectedContracts, contract.id]);
                        } else {
                          setSelectedContracts(selectedContracts.filter((id) => id !== contract.id));
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </SlideOver.Content>
      <SlideOver.Controls>
        <Button onClick={() => onClose(false)} className="mr-2" variant="secondary">
          {t('common.cancel')}
        </Button>

        <Button variant="primary" innerRef={submitRef} onClick={handleSubmit}>
          {t('common.add')}
        </Button>
      </SlideOver.Controls>
    </>
  );
};
