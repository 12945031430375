/**
 * A helper function to create a FileList object from a list of files
 * @param {File[]} args a list of files to add to the FileList
 * @returns {FileList} a FileList object containing the files
 */
export const createFileList = (...args: File[]) => {
  const dataTransfer = new DataTransfer();
  const files = [...args];

  for (const item of files) {
    if (item instanceof File) {
      dataTransfer.items.add(item);
    }
  }

  return dataTransfer.files;
};
