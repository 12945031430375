import { Fragment, PropsWithChildren } from 'react';
import { WidgetContainer } from '@client/shared/toolkit';
import { DashboardWidgetProps, DashboardWidgetVariant, getWidget } from '../Dashboards';

export interface DashboardWidgetMixedProps extends PropsWithChildren {
  widgets: DashboardWidgetProps[];
  variant?: DashboardWidgetVariant;
}

export const DashboardWidgetMixed = ({ widgets, children, variant = 'card' }: DashboardWidgetMixedProps) => {
  const Component = variant === 'card' ? WidgetContainer : Fragment;
  return (
    <Component>
      {widgets.map((widget, index) => (
        <Fragment key={`dashboard-widget-mixed-child-widget-${index}`}>
          {getWidget(widget, 'child')}
        </Fragment>
      ))}
      {children}
    </Component>
  );
};
