import { AuthRoleReadModel, useApiDeleteAuthRoleMutation } from '@client/shared/api';
import { ModalOnCloseProps, ConfirmModal, LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation, Trans } from 'react-i18next';

interface RoleDeleteModalProps extends ModalOnCloseProps {
  role?: AuthRoleReadModel;
}

export const RoleDeleteModal = ({ role, onClose }: RoleDeleteModalProps) => {
  const { t } = useTranslation();

  const [postDelete, { isLoading: isDeleting }] = useApiDeleteAuthRoleMutation();

  const handleDelete = async () => {
    if (role) {
      try {
        await safeMutation(
          postDelete,
          {
            roleId: role.id,
          },
          isDeleting
        );

        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  return (
    <ConfirmModal
      title={`${t('common.delete')} ${role?.name ?? ''}`}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isDeleting}
    >
      {isDeleting && (
        <LoadingIndicator text={t('common.loading')} mode="overlay" />
      )}
      <Trans i18nKey="auth.roleDeleteConfirmation" values={{ entity: `${role?.name ?? ''}` }}>
        <div className="block">
          Do you want to delete
          <span className="font-bold">Role</span>
        </div>
        <div className="inline-block pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
