import {
  ApiGetCalculationModelHistoryApiArg,
  ApiGetCalculationModelHistoryApiResponse,
  ApiPostCreateCalculationModelArchiveApiArg,
  ApiPostCreateCalculationModelArchiveApiResponse,
  ApiPostCreateCalculationModelCopyApiArg,
  ApiPostCreateCalculationModelCopyApiResponse,
  ApiPostCreateCalculationModelSnapshotApiArg,
  ApiPostCreateCalculationModelSnapshotApiResponse,
  ApiPostCreateProjectVersionApiArg,
  ApiPostCreateProjectVersionApiResponse,
  ApiPostUpdateCalculationModelKpisApiArg,
  ApiPostUpdateCalculationModelKpisApiResponse,
  ApiPostUpdateCalculationModelMetadataApiArg,
  ApiPostUpdateCalculationModelMetadataApiResponse,
  ApiPostUpdateCalculationModelVersionApiArg,
  ApiPostUpdateCalculationModelVersionApiResponse,
  ApiPostUpdateCaluclationModelFavoriteApiArg,
  ApiPostUpdateCaluclationModelFavoriteApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiVariantsEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetCalculationModelHistory: {
    providesTags: (
      _result: ApiGetCalculationModelHistoryApiResponse,
      error: FetchBaseQueryError,
      args: ApiGetCalculationModelHistoryApiArg,
    ) =>
      error == null
        ? [
            {
              type: ApiTagTypes.CalculationModelHistory,
              id: args.calculationModelId,
            },
            { type: ApiTagTypes.Project, id: args.projectId },
          ]
        : [],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostUpdateCaluclationModelFavorite: {
    invalidatesTags: (
      _result: ApiPostUpdateCaluclationModelFavoriteApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateCaluclationModelFavoriteApiArg,
    ) =>
      error == null
        ? [
            {
              type: ApiTagTypes.CalculationModelHistory,
              id: args.calculationModelId,
            },
            { type: ApiTagTypes.Project, id: args.projectId },
          ]
        : [],
  },
  apiPostUpdateCalculationModelVersion: {
    invalidatesTags: (
      _result: ApiPostUpdateCalculationModelVersionApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateCalculationModelVersionApiArg,
    ) =>
      error == null
        ? [
            {
              type: ApiTagTypes.CalculationModel,
              id: args.calculationModelId,
            },
            { type: ApiTagTypes.Project, id: args.projectId },
          ]
        : [],
  },
  apiPostCreateProjectVersion: {
    invalidatesTags: (
      result: ApiPostCreateProjectVersionApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateProjectVersionApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: result?.calculationModelId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.Project, id: args.projectId },
          ]
        : [],
  },
  apiPostCreateCalculationModelCopy: {
    invalidatesTags: (
      result: ApiPostCreateCalculationModelCopyApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateCalculationModelCopyApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: result?.calculationModelId },
            {
              type: ApiTagTypes.Project,
              id: args.projectId,
            },
          ]
        : [],
  },
  apiPostCreateCalculationModelArchive: {
    invalidatesTags: (
      _result: ApiPostCreateCalculationModelArchiveApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateCalculationModelArchiveApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
          ]
        : [],
  },
  apiPostCreateCalculationModelSnapshot: {
    invalidatesTags: (
      _result: ApiPostCreateCalculationModelSnapshotApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateCalculationModelSnapshotApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.CalculationModel, id: args.calculationModelId }] : []),
  },
  apiPostUpdateCalculationModelKpis: {
    invalidatesTags: (
      _result: ApiPostUpdateCalculationModelKpisApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateCalculationModelKpisApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.RisksCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.EarningsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId },
            { type: ApiTagTypes.Plots, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostUpdateCalculationModelMetadata: {
    invalidatesTags: (
      _result: ApiPostUpdateCalculationModelMetadataApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateCalculationModelMetadataApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.CalculationModelDeliveryPhases, id: args.calculationModelId },
          ]
        : [],
  },
};
