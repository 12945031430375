import { Button, FileInput, Modal } from '@client/shared/toolkit';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiPostUploadAiEvalDocumentApiArg, useApiPostUploadAiEvalDocumentMutation } from '@client/shared/api';
import toast from 'react-hot-toast';
import { safeMutation } from '@client/shared/utilities';
import { useLoadedProjectId } from '@client/project/store';

interface InvoiceDocumentUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const InvoiceDocumentUploadModal = (props: InvoiceDocumentUploadModalProps) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();

  const [uploadInvoiceDocument, { isLoading: isUploadingDocument }] = useApiPostUploadAiEvalDocumentMutation();
  const [file, setFile] = useState<File | null>(null);
  const [isFileError, setIsFileError] = useState(false);

  const handleFileChange = (files: FileList | null) => {
    if (files?.length) {
      setFile(files[0]);
    } else {
      setFile(null);
    }
  };

  const uploadFile = useCallback(async () => {
    if (!isUploadingDocument && file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const args = {
          invoiceId: null,
          projectId: loadedProjectId,
          body: formData,
        } as ApiPostUploadAiEvalDocumentApiArg;

        const response = await safeMutation(uploadInvoiceDocument, args, isUploadingDocument);

        if (
          response?.aiServiceError !== null &&
          response?.aiServiceError !== '' &&
          response?.aiServiceError !== undefined
        ) {
          toast.error(t(response.aiServiceError), { duration: 5000 });
        } else {
          toast.success(t('projectControl.notificationUploadingInvoiceDocument', { name: file.name }), {
            duration: 5000,
          });
        }

        onClose();
      } catch (e) {
        console.log(e);
      }
    }
  }, [isUploadingDocument, file, loadedProjectId, uploadInvoiceDocument, onClose, t]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header
        title={t('projectControl.uploadInvoiceTitle')}
        description={t('projectControl.uploadInvoiceSubTitle')}
      />
      <Modal.Content>
        <div className="bg-white p-2 mb-6 flex-1">
          <FileInput
            className="h-full flex-1"
            acceptedFileTypes={['application/pdf']}
            multiple={false}
            selectedFiles={null}
            onChange={handleFileChange}
            uploadDescription={t('projectControl.invoiceUploadMessage')}
            maxFileSize={15000000}
            setError={setIsFileError}
          />
        </div>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" className="mr-2" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={uploadFile} disabled={isFileError || !file}>
          {t('common.upload')}
        </Button>
      </Modal.Controls>
    </Modal>
  );
};
