import { SearchIn } from '@client/shared/api';
import { FilterOption } from '@client/shared/toolkit';

export const TOGGLED_FILTERS = 'filters'
export const toggleFilters = (toggledFilters: FilterOption[], type: SearchIn) => {
  const currentToggledIds = localStorage.getItem(TOGGLED_FILTERS)
  const parsedStorage = currentToggledIds ? JSON.parse(currentToggledIds) : null
  const newStorage = {
    Cost: type === 'Cost' ? toggledFilters : parsedStorage?.Cost,
    Project: type === 'Project' ? toggledFilters : parsedStorage?.Project,
    Taxonomy: type === 'Taxonomy' ? toggledFilters : parsedStorage?.Taxonomy,
  }
  localStorage.setItem(TOGGLED_FILTERS, JSON.stringify(newStorage))
};
