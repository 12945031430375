import { AuthPermissionDefinitionReadModel, ProbisPermission } from '@client/shared/api';
import { PermissionCategoryDefinition,ProbisPermissionStatus,ProbisPermissionState } from './PermissionUtilities';
import { PermissionCategoryRow } from './PermissionCategoryRow';
import { EditRowIcon, EnterMacKeyIcon, EyeIcon, TrashIcon } from '@client/shared/toolkit';

interface PermissionListProps {
  permissionDefinitions: AuthPermissionDefinitionReadModel[];
  permissionCategories: PermissionCategoryDefinition[];
  grantedPermissions: ProbisPermission[];
  newPermissions: ProbisPermission[];
  deletedPermissions: ProbisPermission[];
  allowEditing: boolean;
  selectedPermissionsChanged: (newPermissions: ProbisPermission[], deletedPermissions: ProbisPermission[]) => void;
}

export const PermissionList = ({
  allowEditing,
  permissionDefinitions,
  permissionCategories,
  grantedPermissions,
  newPermissions,
  deletedPermissions,
  selectedPermissionsChanged,
}: PermissionListProps) => {
  const toStatus = (definition: AuthPermissionDefinitionReadModel): ProbisPermissionStatus => {
    let state: ProbisPermissionState = 'Unset';
    if (grantedPermissions.includes(definition.name)) {
      state = 'Granted';
    }

    if (deletedPermissions.includes(definition.name)) {
      state = 'Deleted';
    }

    if (newPermissions.includes(definition.name)) {
      state = 'New';
    }

    return {
      permission: definition.name,
      state: state,
    };
  };

  const permissionStatuses = permissionDefinitions.map(toStatus);

  const handlePermissionChange = (newStatuses: ProbisPermissionStatus[]) => {
    let newPermissionStatuses = permissionStatuses?.slice();

    newStatuses.forEach(newStatus => {
      const oldStatus = newPermissionStatuses.find(x => x.permission === newStatus.permission);
      if (oldStatus){
        const index = newPermissionStatuses.indexOf(oldStatus);
        newPermissionStatuses.splice(index, 1, newStatus)

        const parentStatuses = getParentPermissionStatusesRecursive(newPermissionStatuses, newStatus.permission);
        if (parentStatuses.length > 0 &&
           (newStatus.state === 'New' || newStatus.state === 'Granted'))
        {
          parentStatuses.forEach(parentStatus => {
            if (parentStatus.state === 'Unset' || parentStatus.state === 'Deleted') {
              const parentIndex = newPermissionStatuses.indexOf(parentStatus);
              const newParentStatus : ProbisPermissionStatus ={
                permission : parentStatus.permission,
                state : parentStatus.state === 'Deleted' ? 'Granted' : 'New'
              }
              newPermissionStatuses.splice(parentIndex, 1, newParentStatus);
            }
          });
        }

        const childStatuses = getChildPermissionStatusesRecursive(newPermissionStatuses, newStatus.permission);
        if (childStatuses.length > 0 &&
           (newStatus.state === 'Deleted' || newStatus.state === 'Unset'))
        {
          childStatuses.forEach(childStatus => {
            if (childStatus.state === 'New' || childStatus.state === 'Granted') {
              const childIndex = newPermissionStatuses.indexOf(childStatus);
              const newChildStatus : ProbisPermissionStatus ={
                permission : childStatus.permission,
                state : childStatus.state === 'New' ? 'Unset' : 'Deleted'
              }
              newPermissionStatuses.splice(childIndex, 1, newChildStatus);
            }
          });
        }
      }
    });

    newPermissionStatuses = newPermissionStatuses.filter(onlyUnique);

    selectedPermissionsChanged(
      newPermissionStatuses
        .filter(x => x.state === 'New')
        .map(y => y.permission),
      newPermissionStatuses
        .filter(x => x.state === 'Deleted')
        .map(y => y.permission));
  };

  const onlyUnique = (value: ProbisPermissionStatus, index: number, array: ProbisPermissionStatus[]) => {
    return array.indexOf(value) === index;
  };

  const getParentPermissionStatusesRecursive = (statuses : ProbisPermissionStatus[], permission : ProbisPermission) => {
    let parentStatuses : ProbisPermissionStatus[] = [];

    const requiredPermissions = permissionDefinitions.find(x => x.name === permission)?.requiredPermissions;
    if (requiredPermissions){
      requiredPermissions.forEach((permission) => {
        if (!parentStatuses.some(x => x.permission === permission)){
          parentStatuses = parentStatuses.concat(getParentPermissionStatusesRecursive(statuses, permission));
        }
      })
    }

    const directParentStatus = statuses.filter(x => requiredPermissions?.some(y => y === x.permission));
    parentStatuses = parentStatuses.concat(directParentStatus);
    return parentStatuses.filter(onlyUnique);
  };

  const getChildPermissionStatusesRecursive = (statuses : ProbisPermissionStatus[], permission : ProbisPermission) => {
    let childStatuses : ProbisPermissionStatus[] = [];

    const childPermissions = permissionDefinitions.filter(x => x.requiredPermissions?.includes(permission));
    if (childPermissions.length > 0){
      childPermissions.forEach((permission) => {
        childStatuses = childStatuses.concat(getChildPermissionStatusesRecursive(statuses, permission.name));
      })
    }

    const directChildStatuses = statuses.filter(x => childPermissions.some(y => y.name === x.permission));
    childStatuses = childStatuses.concat(directChildStatuses);
    return childStatuses.filter(onlyUnique);
  };

  return (
    <div>
      <div className='flex flex-row my-2'>
        <div className='w-8/12'/>

        <div className='flex items-center justify-center w-1/12'>
          <EyeIcon className="w-6 h-6" />
        </div>
        <div className='flex items-center justify-center w-1/12'>
          <EditRowIcon className="w-6 h-6" />
        </div>
        <div className='flex items-center justify-center w-1/12'>
          <TrashIcon className="w-6 h-6 text-slate-500" />
        </div>
        <div className='flex items-center justify-center w-1/12'>
          <EnterMacKeyIcon className="w-6 h-6" />
        </div>
      </div>

      <div className="flex flex-col font-bold text-lg">
        {permissionCategories.map((category, index) => (
          <div className='mb-8' key={index}>
            <PermissionCategoryRow
              permissionCategory={category}
              permissionStatuses={permissionStatuses}
              changeState={handlePermissionChange}
              allowEditing={allowEditing}
            />
          </div>
        ))}
      </div>
    </div>


  );
};
