import { useEffect, RefObject } from 'react';

/**
 * Helper function to synchronize the scroll positions of two or more elements.
 * @param target - The target element that is being scrolled.
 * @param others - The other elements that need to have their scroll positions updated.
 * @param topOrLeft - Either 'Top' or 'Left' depending on whether the elements are horizontally or vertically scrollable.
 * @param widthOrHeight - Either 'Width' or 'Height' depending on whether the elements are horizontally or vertically scrollable.
 */
const syncScroll = (
  target: HTMLElement,
  others: HTMLElement[],
  topOrLeft: 'Top' | 'Left',
  widthOrHeight: 'Width' | 'Height'
) => {
  const percentage =
    target[`scroll${topOrLeft}`] / (target[`scroll${widthOrHeight}`] - target[`offset${widthOrHeight}`]);

  // Use requestAnimationFrame to update the other elements
  // This will make sure the scroll position is updated only once per frame
  window.requestAnimationFrame(() => {
    others.forEach((el) => {
      el[`scroll${topOrLeft}`] = Math.round(percentage * (el[`scroll${widthOrHeight}`] - el[`offset${widthOrHeight}`]));
    });
  });
};

interface ScrollSyncOptions {
  horizontal?: boolean;
  vertical?: boolean;
}

/**
 * This hook is used to sync the scroll position of a group of elements based on a reference element that was scroleld.
 * The function will only update the scroll position of the elements if the scroll event was triggered by a user.
 * @param refsRef - The array of refs to sync
 * @param vertical - Vertical scroll position should be synced
 * @param horizontal - Horizontal scroll position should be synced
 * @param scrollContainerRef - The element which was scrolled
 * @param translate - Whether translate instead of scroll position to be updated, default: false
 */
export const useScrollSyncRef = (refsRef: RefObject<HTMLElement>[], { vertical, horizontal }: ScrollSyncOptions, scrollContainerRef: RefObject<HTMLDivElement>, translate = false) => {
  useEffect(() => {
    if (refsRef && refsRef.length && refsRef.some((ref) => ref.current) && scrollContainerRef?.current) {
      const scrollContainerRefCurrent = scrollContainerRef.current
      const handleScroll = ({ target }: Event) => {
        // Get all the other refs that are not the current target
        const others = refsRef.reduce((result, ref) => {
          if (ref.current) {
            result.push(ref.current);
          }
          return result;
        }, [] as HTMLElement[]);
        if (target) {
          if (vertical) {
            if (translate) {
              const scrollTop = (target as HTMLElement).scrollTop;
              refsRef.forEach((syncRef) => {
                if (syncRef.current) {
                  syncRef.current.style.transform = `translate(0px, -${scrollTop}px)`;
                }
              })
            } else {
              syncScroll(target as HTMLElement, others, 'Top', 'Height');
            }
          }
          if (horizontal) {
            if (translate) {
              const scrollLeft = (target as HTMLElement).scrollLeft;
              refsRef.forEach((syncRef) => {
                if (syncRef.current) {
                  syncRef.current.style.transform = `translate(-${scrollLeft}px, 0px)`;
                }
              })
            } else {
              syncScroll(target as HTMLElement, others, 'Left', 'Width');
            }
          }
        }
      }

      // the reference element was scrolled, so update the others
      scrollContainerRefCurrent.addEventListener('scroll', handleScroll)

      return () => {
        scrollContainerRefCurrent?.removeEventListener('scroll', handleScroll)
      };
    }
  }, [refsRef, vertical, horizontal, scrollContainerRef, translate]);

  return;
};
