import * as yup from 'yup';
import { InferType } from 'yup';

export const CatalogTemplateFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  comment: yup.string().nullable(),
  costCatalogId: yup.string().required('validation.required'),
  riskCatalogId: yup.string().nullable(),
  earningsCatalogId: yup.string().nullable(),
  financingCatalogId: yup.string().nullable()
});

export type CatalogTemplateFormValidationValues = InferType<typeof CatalogTemplateFormValidationSchema>;
