import classNames from 'classnames';
import React from 'react';

interface FormHelperTextProps {
  text?: string;
  error?: boolean;
  className?: string;
  passCyData?: string;
  truncate?: boolean
}

export const FormHelperText = ({ text, error, className, passCyData, truncate = true }: FormHelperTextProps) => (
  <>
    {text && (
      <div className={className}>
        <div data-cy={passCyData}
          className={classNames('px-3 text-sm', {
            'text-gray-500': !error,
            'text-red-500': error,
            'truncate': truncate
          })}
        >
          {text}
        </div>
      </div>
    )}
  </>
);
