import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiCancelAiEvalProcessApiArg,
  ApiCancelAiEvalProcessApiResponse,
  ApiDeleteAiEvalDocumentApiArg,
  ApiDeleteAiEvalDocumentApiResponse,
  ApiGetAiEvalDocumentsApiArg,
  ApiGetAiEvalDocumentsApiResponse,
  ApiGetAiEvalResultApiArg,
  ApiGetAiEvalResultApiResponse,
  ApiPostUploadAiEvalDocumentApiArg,
  ApiPostUploadAiEvalDocumentApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiAiEvalEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetAiEvalDocuments: {
    providesTags: (
      _result: ApiGetAiEvalDocumentsApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetAiEvalDocumentsApiArg,
    ) => [{ type: ApiTagTypes.InvoiceDocuments, id: args.projectId }],
  },
  apiGetAiEvalResult: {
    providesTags: (
      _result: ApiGetAiEvalResultApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetAiEvalResultApiArg,
    ) => [{ type: ApiTagTypes.InvoiceDocumentResult, id: args.documentId }],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostUploadAiEvalDocument: {
    invalidatesTags: (
      _result: ApiPostUploadAiEvalDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUploadAiEvalDocumentApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.InvoiceDocuments, id: args.projectId }] : []),
  },
  apiDeleteAiEvalDocument: {
    invalidatesTags: (
      _result: ApiDeleteAiEvalDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteAiEvalDocumentApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.InvoiceDocuments, id: args.projectId }, { type: ApiTagTypes.InvoiceDocumentResult, id: args.documentId }] : []),
  },
  apiCancelAiEvalProcess: {
    invalidatesTags: (
      _result: ApiCancelAiEvalProcessApiResponse,
      error: FetchBaseQueryError,
      args: ApiCancelAiEvalProcessApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.InvoiceDocuments, id: args.projectId }] : []),
  }
};
