import { PlotType, PlotUsageType, useApiPostCreatePlotMutation } from '@client/shared/api';
import { TagIcon } from '@heroicons/react/24/outline';
import {
  TextInput,
  NumberInput,
  Modal,
  Button,
  BaseSelect,
  Form,
  FormField,
  EditNodeIcon,
  ModalOnCloseProps,
  FormWatch, MapMarkerDottedIcon, LoadingIndicator,
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { PlotElementFormValidationValues, PlotElementFormValidationSchema } from './PlotElementFormValidationSchema';
import { getUsageTypes, useLoadedProjectUnitSystemSymbol, useLoadedProjectId, useLoadedVariantId } from '@client/project/store';

export const PlotElementNewModal = ({ onClose }: ModalOnCloseProps) => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();

  const unitSystem = useLoadedProjectUnitSystemSymbol();

  const plotTypeOptions = [
    {
      label: t('projectTaxonomy.plotType.projectRoom'),
      value: 'Plot',
    },
    {
      label: t('projectTaxonomy.plotType.Plot'),
      value: 'SpecialItem',
    },
    {
      label: t('projectTaxonomy.plotType.Infrastructure'),
      value: 'Infrastructure',
    },
  ];
  const loadedVariantId = useLoadedVariantId();

  const [postPlotPiece, { isLoading: isCreating }] = useApiPostCreatePlotMutation();

  const handleSubmit = async (data: PlotElementFormValidationValues) => {
    if (loadedVariantId == null) return;

    try {
      await safeMutation(
        postPlotPiece,
        {
          projectId: projectId ?? 'unset',
          calculationModelId: loadedVariantId,
          body: {
            name: data.name,
            size: Number(data.size) ?? 0,
            constructionSize: data.constructionSize,
            type: data.type as PlotType,
            grossVolume: data.grossVolume,
            cost: data.cost,
            usageType: data.usageType as PlotUsageType,
            description: data.description,
            street: data.street,
            parcelNumber: data.parcelNumber,
          },
        },
        isCreating
      );

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const defaultFormValues = {
    name: '',
    size: 0,
    constructionSize: 0,
    type: 'Plot',
  };

  return (
    <Form<PlotElementFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={PlotElementFormValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
    >
      {isCreating && <LoadingIndicator text={t('projectTaxonomy.creatingTaxonomyLoadingIndicator')} mode="overlay-window" />}
      <Modal.Header title={t('projectTaxonomy.newPlot')} description={t('projectTaxonomy.newPlotDescription')} />

      <Modal.Content className="overflow-y-auto divide-gray-100 divide-y my-6">
        <FormField name="type">
          {(control) => (
            <BaseSelect
              label={t('projectTaxonomy.newPlotLabelPlotType')}
              options={plotTypeOptions}
              icon={<MapMarkerDottedIcon />}
              {...control}
            />
          )}
        </FormField>
        <FormField name="name">
          {(control) => (
            <TextInput
              label={t('projectTaxonomy.newPlotLabelName')}
              icon={<EditNodeIcon />}
              {...control}
            />
          )}
        </FormField>
        <FormWatch<PlotElementFormValidationValues> fieldNames={['type']}>
          {({ type }) => (
            <FormField name="usageType">
              {(control) => (
                <BaseSelect
                  label={t('projectTaxonomy.newPlotLabelUsageType')}
                  options={getUsageTypes()}
                  icon={<MapMarkerDottedIcon />}
                  disabled={type !== 'SpecialItem'}
                  {...control}
                />
              )}
            </FormField>
          )}
        </FormWatch>

        <FormField name="street">
          {(control) => <TextInput label={t('projectTaxonomy.newPlotLabelStreet')} icon={<TagIcon />} {...control} />}
        </FormField>
        <FormField name="parcelNumber">
          {(control) => <TextInput label={t('projectTaxonomy.newPlotLabelParcelNr')} icon={<TagIcon />} {...control} />}
        </FormField>
        <FormField name="description">
          {(control) => (
            <TextInput label={t('projectTaxonomy.newPlotLabelDescription')} icon={<TagIcon />} {...control} />
          )}
        </FormField>
        <FormField name="size">
          {(control) => (
            <NumberInput
              label={`${t('projectTaxonomy.newPlotLabelPlotSize')} (${unitSystem})`}
              icon={<TagIcon />}
              {...control}
            />
          )}
        </FormField>
        <FormField name="constructionSize">
          {(control) => (
            <NumberInput
              label={`${t('projectTaxonomy.newPlotLabelConstructionSize')} (${unitSystem})`}
              icon={<TagIcon />}
              {...control}
            />
          )}
        </FormField>
        <FormField name="grossVolume">
          {(control) => (
            <NumberInput
              label={`${t('projectTaxonomy.newPlotLabelGrossVolume')} (${unitSystem})`}
              icon={<TagIcon />}
              {...control}
            />
          )}
        </FormField>
        <FormField name="cost">
          {(control) => <NumberInput label={t('projectTaxonomy.newPlotLabelCost')} icon={<TagIcon />} {...control} />}
        </FormField>
      </Modal.Content>
      <Modal.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" formSubmit={true}>
          {t('common.create')}
        </Button>
      </Modal.Controls>
    </Form>
  );
};
