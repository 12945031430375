import { DealDocumentReadModel, DealType } from '@client/shared/api';
import { useState } from 'react';
import {
  Modal,
  DocumentListItem
} from '@client/shared/toolkit';
import { DealDocumentDeleteModal } from './DealDocumentDeleteModal';
import { useCanDeleteDeal } from '../../hooks';
import { useLoadedProjectId } from '@client/project/store';

interface DealDocumentRowProps {
  dealId: string;
  type: DealType;
  dealDocument: DealDocumentReadModel;
  isReadOnly: boolean;
}

export const DealDocumentRow = ({ dealId, type, dealDocument, isReadOnly }: DealDocumentRowProps) => {
  const loadedProjectId = useLoadedProjectId();
  const canDelete = useCanDeleteDeal(type);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const downloadUrl = `/api/projects/${loadedProjectId}/${type.toLowerCase()}/deals/${dealId}/documents/${dealDocument.id}`;

  return (
    <>
      <DocumentListItem
        fileName={dealDocument.name}
        fileSize={dealDocument.size}
        created={dealDocument.created}
        downloadUrl={downloadUrl}
        setIsDeleteModalOpen={canDelete ? setIsDeleteModalOpen : undefined}
        contextMenu={!isReadOnly}
      />
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} variant="small">
        <DealDocumentDeleteModal
          dealId={dealId}
          type={type}
          document={dealDocument}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
    </>
  );
};
