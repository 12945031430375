import { DecoratedElement } from '../hooks';

export const getFlattenedDecoratedElements = <T>(
  elements?: DecoratedElement<T>[],
  level = 0,
  parent?: DecoratedElement<T>
): DecoratedElement<T>[] => {
  if (elements == null) return [];

  return elements.flatMap((_element) => {
    const { element, categoryId, children } = _element;

    const current = { ..._element, parent, level, categoryId, element };

    if (children == null) {
      return [current];
    } else {
      const flatElements = [current, ...getFlattenedDecoratedElements(children, level + 1, _element)];

      // insert summary element after all children
      /* if (level === 0) {
        flatElements.push({ ...current, element: { ...current.element, type: 'summary' } });
      } */

      return flatElements;
    }
  });
};
