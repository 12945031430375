import { TenantCatalogReadModel, useApiUpdateCatalogMutation } from '@client/shared/api';
import {
  Button,
  Form,
  FormField,
  FormRefHandle, LoadingIndicator,
  Modal,
  SlideOver,
  SlideOverOnCloseProps,
  TagWindowIcon,
  TextInput
} from '@client/shared/toolkit';
import React, { useRef, useState } from 'react';
import { CatalogDeleteModal } from './CatalogDeleteModal';
import { useTranslation } from 'react-i18next';
import { InferType } from 'yup';
import * as yup from 'yup';
import { safeMutation } from '@client/shared/utilities';

interface CatalogEditProps extends SlideOverOnCloseProps {
  catalog: TenantCatalogReadModel;
}

const CatalogEditValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
});
type CatalogEditValidationValues = InferType<typeof CatalogEditValidationSchema>;

export const CatalogEditSlideOver = ({ catalog, onClose }: CatalogEditProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<FormRefHandle<CatalogEditValidationValues>>(null);
  const [sendUpdate, {isLoading: isUpdating}] = useApiUpdateCatalogMutation();

  const [showDelete, setShowDelete] = useState<boolean>(false);

  const defaultFormValues = {
    name: catalog.name
  };

  const handleCatalogSubmit = async (data: CatalogEditValidationValues) => {
    if (formRef.current) {
      const values = formRef.current.getValues();
      try {
        await safeMutation(
          sendUpdate,
          {
            body: {
              catalogId: catalog.id,
              catalogName: values.name,
              type: catalog.type
            },
          },
          isUpdating,
        );
        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  return (
    <Form<CatalogEditValidationValues>
      onSubmit={handleCatalogSubmit}
      validationSchema={CatalogEditValidationSchema}
      defaultValues={defaultFormValues}
      className="w-full flex flex-col justify-between h-full"
      ref={formRef}
    >
      {isUpdating && (
        <LoadingIndicator text={t('common.loading')} mode="overlay" />
      )}
      <SlideOver.Header
        title={catalog?.name ?? t('templates.NewCatalogTemplateTitle')}
        backgroundClassName="bg-gray-600"
        onClose={() => onClose(false)}
      />

      <SlideOver.Content className="p-8 h-full">
        <div className="bg-white">
          <div className="divide-gray-100 divide-y">
            <FormField name="name">
              {(control) => (
                <TextInput
                  label={t('common.name')}
                  icon={<TagWindowIcon />}
                  {...control}
                />
              )}
            </FormField>
          </div>
        </div>
      </SlideOver.Content>

      <SlideOver.Controls>
        <div className="flex flex-grow">
          <Button variant="warning" onClick={() => setShowDelete(true)}>
            {t('common.delete')}
          </Button>
        </div>
        <div className="flex justify-end">
          <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
            {t('common.cancel')}
          </Button>
          <Button variant="primary" formSubmit={true} innerRef={submitRef}>
            {t('common.save')}
          </Button>
        </div>
      </SlideOver.Controls>

      <Modal isOpen={showDelete} onClose={() => setShowDelete(false)} onAfterLeave={() => onClose(false)}>
        <CatalogDeleteModal catalog={catalog} onClose={() => setShowDelete(false)} />
      </Modal>
    </Form>
  )
};
