import React, { PropsWithChildren } from 'react';
import { ElementTransition } from './ElementTransition';

interface CollapsibleProps extends PropsWithChildren {
  collapsibleHeader?: React.ReactNode;
  open: boolean;
  disableAnimation?: boolean;
  className?: string;
}

export const Collapsible = ({
  children,
  collapsibleHeader,
  disableAnimation = true,
  open = false,
  className,
}: CollapsibleProps) => (
  <div className={className}>
    {collapsibleHeader && <>{collapsibleHeader}</>}

    <div className="flex flex-col">
      {!disableAnimation && React.Children.count(children) > 0 && (
        <ElementTransition show={open}>{children}</ElementTransition>
      )}
      {disableAnimation && React.Children.count(children) > 0 && open && children}
    </div>
  </div>
);
