import {
  Badge,
  Button,
  ChecklistCuteOutlineIcon,
  DecoratedCard,
  FolderOutlinedIcon,
  FormRefHandle,
  ListTitle,
  Modal,
  OrderHistoryOutlinedIcon,
  SlideOver,
  SlideOverOnCloseProps,
  SlideOverWithTabs,
  TalkPastelGlyphIcon, Tooltip,
} from '@client/shared/toolkit';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FRAPORT_REQUIRED_CUSTOM_FIELDS, RequirementDetails } from './RequirementDetails';
import { RequirementDocuments } from './RequirementDocuments';
import { RequirementSubmitWizard } from './RequirementSubmitWizard';
import { RequirementReleaseForReviewWizard } from './RequirementReleaseForReviewWizard';
import { RequirementRejectWizard } from './RequirementRejectWizard';
import { RequirementReadModel } from '@client/shared/api';
import { RequirementUpdateFormValidationValues } from './RequirementFormValidationSchemas';
import { RequirementDeleteModal } from './RequirementDeleteModal';
import { useUi } from '@client/shared/store';
import {
  checkIsReporter,
  getClusterItemIdsForUser,
  isAssetManager
} from '@client/portfolio/shared';

export interface RequirementSlideOverProps extends SlideOverOnCloseProps {
  requirementId: string;
  selectedRequirement?: RequirementReadModel;
  setIsChildSlideOverOpen: (val: boolean) => void;
  setClusterItemChanged: (changed: boolean) => void;
  updateUnsavedData: (unsaved: boolean) => void;
}

export const RequirementSlideOver = (props: RequirementSlideOverProps) => {
  const {
    onClose,
    selectedRequirement,
    setClusterItemChanged,
    updateUnsavedData
  } = props;
  const { t } = useTranslation();
  const ui = useUi();

  const formRef = useRef<FormRefHandle<RequirementUpdateFormValidationValues>>(null);

  const [openModal, setOpenModal] = useState<null | 'Submit' | 'ReviewRelease' | 'Reject' | 'Delete'>(null);
  const [requirement, setRequirement] = useState<RequirementReadModel | undefined>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [unsavedData, setUnsavedData] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    if (selectedRequirement) {
      setRequirement(selectedRequirement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRequirement]);

  useEffect(() => {
    updateUnsavedData(unsavedData);
  }, [unsavedData, updateUnsavedData]);

  /**
   * If the current user is the one who reported this requirement or is the deputy
   */
  const isReporter = useMemo(() => {
    return requirement && checkIsReporter(requirement.reportedById, requirement.reportedByDeputyId, ui.appUser.userId);
  }, [ui.appUser.userId, requirement]);

  /**
   * If current user is asset manager of the requirement's cluster item
   */
  const isClusterItemAssetManager = useMemo(() => {
    if (ui.appUser.userId && requirement?.clusterItem.id) {
      return getClusterItemIdsForUser(ui.appUser.userId).includes(requirement.clusterItem.id) && isAssetManager(ui.appUser.userId);
    }
    return false;
  }, [ui.appUser.userId, requirement?.clusterItem.id])

  const tabOptions = useMemo(() => {
    if (!requirement) return [];
    return [
      {
        header: t('portfolio.projecting.notification'),
        name: 'notification',
        icon: <ChecklistCuteOutlineIcon />,
        panel: (
          <div className="flex flex-col gap-9">
            <RequirementDetails
              requirement={requirement}
              formRef={formRef}
              setUnsavedData={setUnsavedData}
              titleError={titleError}
              setTitleError={setTitleError}
              setClusterItemChanged={setClusterItemChanged}
            />
          </div>
        ),
      },
      {
        header: t('projectContract.history'),
        name: 'history',
        icon: <OrderHistoryOutlinedIcon />,
        panel: (
          <DecoratedCard shadowVariant="normal">
            <DecoratedCard.Content>
              <ListTitle title={t('projectContract.history')} />
            </DecoratedCard.Content>
          </DecoratedCard>
        ),
        disabled: true,
      },
      {
        header: (
          <>
            {t('portfolio.projecting.documents')}{' '}
            {requirement.documents?.length > 0 && (
              <Badge variant="lightInfo" text={requirement.documents?.length.toString()} />
            )}
          </>
        ),
        name: 'documents',
        icon: <FolderOutlinedIcon />,
        panel: <RequirementDocuments requirement={requirement} />,
      },
      {
        header: t('portfolio.projecting.comments'),
        name: 'comments',
        icon: <TalkPastelGlyphIcon />,
        panel: (
          <DecoratedCard shadowVariant="normal">
            <DecoratedCard.Content>
              <ListTitle title={t('portfolio.projecting.comments')} />
            </DecoratedCard.Content>
          </DecoratedCard>
        ),
        disabled: true,
      },
    ];
  }, [requirement, t, titleError, setClusterItemChanged]);

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  const submitLabel = useMemo(() => {
    switch (requirement?.state) {
      case 'Created':
        return t('portfolio.projecting.requirementSlideOver.submit');
      case 'Rejected':
        return t('portfolio.projecting.requirementSlideOver.submitAgain');
      case 'Submitted':
        return t('portfolio.projecting.requirementSlideOver.release');
      default:
        return '';
    }
  }, [requirement?.state, t]);

  const deleteLabel = useMemo(() => {
    switch (requirement?.state) {
      case 'Created':
        return t('common.delete');
      case 'Submitted':
        return t('portfolio.projecting.requirementSlideOver.reject');
      default:
        return '';
    }
  }, [requirement?.state, t]);

  /**
   * Requirement reporter / deputy is allowed to edit the requirement as long it is not submitted, yet or it was rejected.
   * An asset manager can only edit, if the requirement was submitted by the reporter / deputy.
   */
  const canEdit = useMemo(() => {
    return (
      (isReporter && (requirement?.state === 'Created' || requirement?.state === 'Rejected'))
      || (isClusterItemAssetManager && requirement?.state === 'Submitted')
    );
  }, [isReporter, isClusterItemAssetManager, requirement?.state]);

  const missingFields = useMemo(() => {
    // check if required custom fields are all set
    let customFieldMissing = !!FRAPORT_REQUIRED_CUSTOM_FIELDS.length;
    if (requirement?.customFields && FRAPORT_REQUIRED_CUSTOM_FIELDS.length) {
      let allSet = true;
      FRAPORT_REQUIRED_CUSTOM_FIELDS.forEach((field) => {
        const missingField = requirement.customFields.find((cf) => cf.name === field.name && !cf.text && !cf.number && !cf.date);
        if (missingField) {
          allSet = false;
        }
      });
      customFieldMissing = !allSet;
    }

    return !requirement?.reportedByDeputyId || !requirement?.category || !requirement?.description || customFieldMissing
  }, [requirement?.reportedByDeputyId, requirement?.category, requirement?.description, requirement?.customFields])

  return (
    <SlideOverWithTabs
      tabOptions={tabOptions}
      title={requirement?.name}
      subtitle={<span className="font-bold">{requirement?.code}</span>}
      onClose={onClose}
      onChange={setSelectedTab}
    >
      <SlideOver.Controls>
        {selectedTab === 0 && canEdit ? (
          <div className="flex flex-grow justify-between">
            <div className="items-start">
              {requirement?.state !== 'Rejected' && (
                <Button
                  variant="danger"
                  onClick={() => setOpenModal(requirement?.state === 'Created' ? 'Delete' : 'Reject')}
                >
                  {`← ${deleteLabel}`}
                </Button>
              )}
            </div>
            <div className="flex items-center order-0 md:order-1">
              {titleError && (
                <span className="text-sm italic text-red-700 leading-none mr-3">
                  {t('portfolio.projecting.requirementEdit.titleIsRequired')}
                </span>
              )}
              {unsavedData && (
                <span className="text-sm italic text-secondary leading-none mr-3">
                  {t('projectControl.editInvoiceUnsavedDataMessage')}
                </span>
              )}
              {(requirement?.state === 'Created' || requirement?.state === 'Rejected') && (
                <Button
                  variant="text"
                  className="mr-2 flex-none"
                  onClick={() => formRef?.current?.submitForm()}
                  disabled={titleError || !unsavedData}
                >
                  {t('common.save')}
                </Button>
              )}
              <Button
                variant="success"
                className="flex-none"
                onClick={() =>
                  setOpenModal(
                    requirement?.state === 'Created' || requirement?.state === 'Rejected' ? 'Submit' : 'ReviewRelease',
                  )
                }
                disabled={titleError || unsavedData || missingFields}
              >
                {missingFields ? (
                  <Tooltip label={<>{submitLabel}&nbsp;→</>}>
                    <p className="text-sm italic text-red-700">
                      {t('portfolio.projecting.requirementEdit.requiredFieldsMissing')}
                    </p>
                  </Tooltip>
                ) : (
                  <>{submitLabel}&nbsp;→</>
                )}
              </Button>
            </div>
          </div>
        ) : (
          <Button variant="secondary" className="flex-none self-end" onClick={() => onClose(false)}>
            {t('common.close')}
          </Button>
        )}
      </SlideOver.Controls>
      <Modal
        isOpen={openModal === 'Delete'}
        onClose={handleCloseModal}
        onAfterLeave={() => {
          if (deleted) {
            onClose(false);
          }
        }}
      >
        {requirement && (
          <RequirementDeleteModal
            onClose={() => {
              handleCloseModal();
              setDeleted(true);
            }}
            requirement={requirement}
            clusterItemId={requirement.clusterItem.id}
          />
        )}
      </Modal>
      <Modal isOpen={openModal === 'Submit'} onClose={handleCloseModal}>
        {requirement && <RequirementSubmitWizard onClose={handleCloseModal} requirement={requirement} />}
      </Modal>
      <Modal isOpen={openModal === 'ReviewRelease'} onClose={handleCloseModal}>
        {requirement && (
          <RequirementReleaseForReviewWizard
            onClose={handleCloseModal}
            requirement={requirement}
            setClusterItemChanged={setClusterItemChanged}
          />
        )}
      </Modal>
      <Modal isOpen={openModal === 'Reject'} onClose={handleCloseModal}>
        {requirement && (
          <RequirementRejectWizard
            onClose={handleCloseModal}
            requirement={requirement}
            setClusterItemChanged={setClusterItemChanged}
          />
        )}
      </Modal>
    </SlideOverWithTabs>
  );
};
