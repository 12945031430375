import * as yup from 'yup';
import { InferType, StringSchema } from 'yup';
import { InvoiceType } from '@client/shared/api';
import { InvoiceCreateWizardVariant } from './InvoiceCreateWizard';

declare module 'yup' {
  interface StringSchema {
    greaterOrEqualDateOfReceipt(): StringSchema
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getValue = (object: any, keys: string) => keys.split('.').reduce((o, k) => (o || {})[k], object);
yup.addMethod<StringSchema>(yup.string, "greaterOrEqualDateOfReceipt", function (errorMessage) {
  return this.test(`test-date-greater-or-equal-date-of-receipt`, errorMessage, function (value) {
    const { path, createError, from } = this;
    if (from) {
      const dateOfReceipt = getValue(from[0].value, 'dateOfReceipt');

      if (typeof value !== 'undefined' && value !== null && typeof dateOfReceipt !== 'undefined' && dateOfReceipt !== null) {
        if (new Date(value).setHours(0, 0, 0, 0) < new Date(dateOfReceipt).setHours(0, 0, 0, 0)) {
          return createError({ path, message: 'projectControl.dueDateMustBeGreaterOrEqualDateOfReceipt' });
        }
      }
    }
    return true
  });
});

export const InvoiceCreateFormValidationSchema = yup.object({
  invoicingPartyId: yup.string().optional().nullable(),
  invoiceRecipientId: yup.string().optional().nullable(),
  contractId: yup.string().nullable().when('variant', {
    is: (variant: InvoiceCreateWizardVariant) => variant === 'withContract' || variant === 'newContract',
    then: (schema) => schema.required('validation.required'),
    otherwise: (schema) => schema.optional(),
  }),
  calculationSchemeId: yup.string().optional().nullable(),
  code: yup.string().required('validation.required'),
  externalCode: yup.string().optional().nullable(),
  type: yup
    .mixed<InvoiceType>()
    .oneOf(['Single', 'Partial', 'PartialFinal', 'Final', 'AdvancePayment', 'Warranty'])
    .required('validation.required'),
  dateOfReceipt: yup.string().optional().nullable(),
  invoiceDate: yup.string().optional().nullable(),
  dateOfAudit: yup.string().optional().nullable(),
  dateOfApproval: yup.string().optional().nullable(),
  paymentDate: yup.string().optional().nullable(),
  vat: yup.number().optional().nullable(),
  net: yup.number().optional().nullable(),
  claim: yup.number().required('validation.required'),
  dueDate: yup.string().greaterOrEqualDateOfReceipt().optional().nullable(),
  cashDiscountDate: yup.string().greaterOrEqualDateOfReceipt().optional().nullable(),
  comment: yup.string().optional().nullable(),
  invoiceValue: yup.number().optional().nullable(),
  distributionType: yup.string().optional().nullable(),
  files: yup.mixed<FileList>().optional().nullable(),
  variant: yup
    .mixed<InvoiceCreateWizardVariant>()
    .oneOf(['noContract', 'withContract', 'newContract'])
    .optional()
    .nullable(),
});

export type InvoiceCreateFormValidationValues = InferType<typeof InvoiceCreateFormValidationSchema>;
