import { DealStatus } from '@client/shared/api';
import classNames from 'classnames';
import { getDealStatusBackgroundColor } from '../../utils';

interface DealStatusProgressBarProps {
  status: DealStatus;
}

export const DealStatusProgressBar = ({ status }: DealStatusProgressBarProps) => (
  <div className="w-full">
    {status && status === 'Interested' && (
      <div className="flex">
        <span className={classNames('w-6/12 mr-1 border-2 h-1.5', getDealStatusBackgroundColor(status))} />
        <span className="w-6/12 mr-1 border-2 h-1.5 bg-white" />
        <span className="w-6/12 mr-1 border-2 h-1.5 bg-white" />
        <span className="w-6/12 border-2 h-1.5 bg-white" />
      </div>
    )}

    {status && status === 'Draft' && (
      <div className="flex">
        <span className={classNames('w-6/12 mr-1 border-2 h-1.5', getDealStatusBackgroundColor('Interested'))} />
        <span className={classNames('w-6/12 mr-1 border-2 h-1.5', getDealStatusBackgroundColor(status))} />
        <span className="border-2 border-white w-6/12 bg-white mr-1" />
        <span className="border-2 border-white w-6/12 bg-white" />
      </div>
    )}

    {status && status === 'Reserved' && (
      <div className="flex">
        <span className={classNames('w-6/12 mr-1 border-2 h-1.5', getDealStatusBackgroundColor('Interested'))} />
        <span className={classNames('w-6/12 mr-1 border-2 h-1.5', getDealStatusBackgroundColor('Draft'))} />
        <span className={classNames('w-6/12 mr-1 border-2 h-1.5', getDealStatusBackgroundColor(status))} />
        <span className="border-2 border-white w-6/12 bg-white"></span>
      </div>
    )}

    {status && status === 'Closed' && (
      <div className="flex">
        <span className={classNames('w-6/12 mr-1 border-2 h-1.5', getDealStatusBackgroundColor('Interested'))} />
        <span className={classNames('w-6/12 mr-1 border-2 h-1.5', getDealStatusBackgroundColor('Draft'))} />
        <span className={classNames('w-6/12 mr-1 border-2 h-1.5', getDealStatusBackgroundColor('Reserved'))} />
        <span className={classNames('w-6/12 mr-1 border-2 h-1.5', getDealStatusBackgroundColor(status))} />
      </div>
    )}
  </div>
);
