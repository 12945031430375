import { AddButton } from '@client/shared/toolkit';

export interface DistributionModalAddButtonProps {
  onClick: () => void
}

export const DistributionModalAddButton = (props: DistributionModalAddButtonProps) => {
  const {
    onClick
  } = props
  return (
    <div className="sticky bottom-0 flex-row-reverse flex mr-2 pointer-events-none">
      <AddButton onClick={onClick} className="pointer-events-auto" />
    </div>
  );
}
