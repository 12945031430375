import { Fragment } from 'react';

export type TextHighlighterProps = {
  text: string
  highlighted: string[]
  className?: string
}

export const TextHighlighter = (props: TextHighlighterProps) => {
  const {
    text,
    highlighted,
    className
  } = props

  if (!text || !highlighted.length) return text

  const toBeHighlighted: string[] = []
  highlighted.forEach((toHighlight) => {
    toHighlight = toHighlight.replace(/[[\]/\\|&;$%@"<>()+,*]/g, '');
    if (toHighlight.trim()) {
      toBeHighlighted.push(toHighlight.trim())
    }
  })
  return (
    <Highlighted text={text} highlight={toBeHighlighted.join('|')} className={className} />
  )
}

export const Highlighted = ({text = '', highlight = '', className}: { text: string, highlight: string, className?: string}) => {
  const toHighlight = highlight.replace(/[[\]&;$%@"<>()+,*]/g, '');
  if (!toHighlight.trim()) {
    return <span>{text}</span>
  }
  try {
    const regex = new RegExp(`(${toHighlight})`, 'gi')
    const parts = text.split(regex)
    return (
      <>
        {parts.filter(part => part).map((part, i) => (
          regex.test(part) ? <mark className={className} key={`highlighted-text-${i}`}>{part}</mark> : <Fragment key={`not-highlighted-text-${i}`}>{part}</Fragment>
        ))}
      </>
    )
  }
  catch(e) {
    return <span>{text}</span>
  }
}
