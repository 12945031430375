import cn from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import { ContextMenu, ContextMenuItem } from './ContextMenu';

interface DescriptionSectionProps extends PropsWithChildren {
  title?: string;
  subtitle?: string;
  variant?: 'row' | 'col';
  border?: boolean;
  contextMenuItems?: ContextMenuItem[];
}

export const DescriptionSection = (props: DescriptionSectionProps) => {
  const { title, subtitle, variant = 'row', children, border = false, contextMenuItems } = props;
  return (
    <div
      className={cn(
        'flex gap-5 w-full flex-col [&:not(:last-child)]:border-b md:[&:not(:last-child)]:border-b-0 [&:not(:last-child)]:pb-5 [&:not(:last-child)]:md:pb-0',
        {
          'md:flex-row': variant === 'row',
        },
      )}
    >
      {(title || subtitle) && (
        <div
          className={cn('font-light text-[15px] leading-5 text-gray-500 flex flex-col', {
            'flex-none md:w-1/3 md:text-right': variant === 'row',
            'md:border-b md:pb-5': border,
          })}
        >
          {title && <span className="text-[22px] leading-7">{title}</span>}
          {subtitle}
        </div>
      )}
      {(children || contextMenuItems) && (
        <div className={cn('flex gap-5 overflow-hidden', { 'flex-1': variant === 'row', 'md:border-b md:pb-5': border })}>
          {children && <div className="flex flex-col gap-3 flex-1 w-full">{children}</div>}
          {contextMenuItems && (
            <div className="mt-2 flex-none w-6 relative text-gray-400 hover:text-gray-600 transition-colors duration-300">
              <ContextMenu items={contextMenuItems} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

interface DescriptionSectionBlockProps extends PropsWithChildren {
  largeTitle?: ReactNode | string;
  title?: ReactNode | string;
  text?: ReactNode | string;
  changed?: boolean;
  disabled?: boolean;
  icon?: ReactNode | string;
  className?: string;
}

export const DescriptionSectionBlock = (props: DescriptionSectionBlockProps) => {
  const { largeTitle, title, text, children, changed = false, disabled = false, icon, className } = props;
  return (
    <div className={cn('flex flex-col', className)}>
      <div className="flex justify-between items-center text-lg">
        {largeTitle && (
          <span
            className={cn('font-bold leading-7', {
              'text-slate-400': disabled,
              'text-gray-800': !changed,
              'text-secondary': changed,
            })}
          >
            {largeTitle}
          </span>
        )}
        {title && (
          <span
            className={cn('font-bold leading-tight', {
              'text-gray-800': !changed,
              'text-secondary': changed,
              'text-slate-400': disabled,
            })}
          >
            {title}
          </span>
        )}
        {text && (
          <div
            className={cn({
              'text-gray-800': !changed,
              'text-secondary': changed,
              'text-slate-400': disabled,
            })}
          >
            {text}
          </div>
        )}
        {icon}
      </div>
      {children}
    </div>
  );
};
