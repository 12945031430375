import { i18n } from '@client/shared/utilities';

export const getUnitLabel = (unit: string): string => {
  switch (unit) {
    case 'piece':
      return i18n.t('projectCalculate.formulaUnitLabelPiece');
    case 'ton':
      return i18n.t('projectCalculate.formulaUnitLabelTon');
    case 'liter':
      return i18n.t('projectCalculate.formulaUnitLabelLiter');
    case 'hour':
      return i18n.t('projectCalculate.formulaUnitLabelHour');
    case 'container':
      return i18n.t('projectCalculate.formulaUnitLabelContainer');
    case 'lumpsum':
      return i18n.t('projectCalculate.formulaUnitLabelLumpsum');
    default:
      return unit;
  }
};
