import { ContractItemCategory, DealReadModel } from '@client/shared/api';
import { DealContractItemRow } from './DealContractItemRow';
import { useTranslation } from 'react-i18next';
import { AddButton, BoxIcon, MortgageIcon } from '@client/shared/toolkit';
import { useCanWriteDeal } from '../../hooks';
import classNames from 'classnames';
import { FormattedCurrency } from '@client/project/shared';

interface DealContractItemsGroupProps {
  deal: DealReadModel;
  category: ContractItemCategory;
  onAdd: (category: ContractItemCategory) => void;
  isReadOnly:boolean;
}

export const DealContractItemsGroup = ({ category, deal, onAdd, isReadOnly }: DealContractItemsGroupProps) => {
  const { t } = useTranslation();

  const isLocked = deal.status === 'Closed';
  const readOnly = !useCanWriteDeal(deal.type) || isReadOnly;

  const elements = deal.contractItems.filter((x) => x.category === category);
  const groupItemSum = elements.reduce(
    (x, y) => (x = x + (y.calculation === 'Value' ? y.value.value ?? 0 : (y.value.value ?? 0) * (y.factor.value ?? 0))),
    0
  );

  const getHeader = (category: ContractItemCategory) => {
    switch (category) {
      case 'Rent':
        return t('projectRent.dealContractRent');
      case 'Utility':
        return t('projectRent.dealContractUtility');
      case 'Additional':
        return t('projectRent.dealContractAdditionalServices');
      case 'Sell':
        return t('projectRent.dealContractPrice');
      case 'Special':
        return t('projectRent.dealContractSpecialAdditions');
      default:
        return '';
    }
  };

  const getIcon = (category: ContractItemCategory) => {
    switch (category) {
      case 'Rent':
        return <MortgageIcon className="w-6" />
      case 'Utility':
        return <MortgageIcon className="w-6" />
      case 'Additional':
        return <BoxIcon className="w-6" />
      case 'Sell':
        return <MortgageIcon className="w-6" />
      case 'Special':
        return <BoxIcon className="w-6" />
      default:
        return <BoxIcon className="w-6" />
    }
  };

  const header =
    deal.type === 'Rent'
      ? t('projectRent.dealContractItemMonthlyForCategory', { category: getHeader(category) })
      : t('projectRent.dealContractPrice');

  return (
    <>
      <div className="flex ml-5 mb-3">
        {getIcon(category)}
        <div className="text-lg font-bold ml-5">{getHeader(category)}</div>
      </div>

      <div className="flex flex-col space-y-2">
        {elements.map((x) => (
          <DealContractItemRow contractItem={x} key={x.id} deal={deal} isReadOnly={isReadOnly} />
        ))}
      </div>

      <div className="flex justify-end mr-5">
        {!isLocked && !readOnly && (
          <AddButton onClick={() => onAdd(category)} />
        )}
      </div>

      <div className={classNames("flex-col w-11/12 mr-5 text-end", readOnly ? 'mt-4' : '')}>
        <div className="text-xl font-bold"><FormattedCurrency amount={groupItemSum} /></div>
        <div className="text-gray-400 text-xs font-bold">{header}</div>
      </div>
    </>
  );
};
