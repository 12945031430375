import { api } from '@client/shared/api';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

export const LogoutSessionsRoute = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, AnyAction>>();

  useEffect(() => {
    dispatch(api.endpoints.apiPostLogoutSessions.initiate());
  }, [dispatch]);

  return <div>{t('auth.loggingOut')}</div>;
};
