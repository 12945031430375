import { PropsWithChildren } from "react";

interface LabeledValueProps extends PropsWithChildren {
    value?: string;
  }

export const LabeledValue = ({ children, value }: LabeledValueProps) => {
    return (
      <div className="flex flex-row text-slate-400">
        <div className="flex flex-row text-slate-400">
          <div className="">{children}</div>
          <div>:</div>
        </div>
        <div className="ml-2 text-primary">{value}</div>
      </div>
    );
  };