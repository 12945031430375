import { getLanguageAndLocale } from './i18n';

/**
 * This function returns the date separator used in the current locale.
 * @param locale The locale to use. Defaults to the user's browser locale.
 * @returns {string} The date separator.
 */
export const getLocaleDateSeparator = (locale: string = getLanguageAndLocale().locale) => {
  const date = new Date(1988, 2, 29);

  return Intl.DateTimeFormat(locale)
    .formatToParts(date)
    .find((part) => part.type === 'literal')?.value;
};
