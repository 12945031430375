import { UserDefinedFieldsSearch } from '@client/project/shared';
import cn from 'classnames';
import { TickBoxIcon } from '@client/shared/toolkit';
import React from 'react';

export type ProjectsHeaderProps = {
  updateSearchResults?: (searchResult: string[]) => void
  handleSearchValueUpdate: (search: string) => void
  displayCheckboxes: () => void
  showAllCheckboxes: boolean
  inverse?: boolean
  searchText: string
}
export const ProjectsHeader = (props: ProjectsHeaderProps) => {
  const {
    updateSearchResults,
    handleSearchValueUpdate,
    displayCheckboxes,
    showAllCheckboxes,
    inverse = false,
    searchText
  } = props
  return (
    <>
      <UserDefinedFieldsSearch
        searchValue={searchText}
        updateSearchResults={updateSearchResults}
        handleSearchValueUpdate={handleSearchValueUpdate}
        udfElementTypes={['Project']}
        searchBgColor={inverse ? 'bg-gray-400 bg-opacity-10' : 'bg-white'}
        className="h-12"
        filterStore="Project"
        projectSpecific={false}
      />
      <button
        className={cn('w-12 h-12 rounded-full flex justify-center items-center ml-2.5 transition-colors duration-200 hover:bg-slate-300 hover:text-slate-500', showAllCheckboxes ? 'bg-slate-300 text-slate-500' : 'bg-white text-slate-600')}
        onClick={displayCheckboxes}
      >
        <TickBoxIcon className="left-0 top-0 w-5 h-auto" />
      </button>
    </>
  )
}
