import { InvoiceReportReadModel, useApiGetInvoiceReportQuery } from '@client/shared/api';
import { Page, Text, View, Document, StyleSheet, Svg, Path, Rect, G, Font, usePDF } from '@react-pdf/renderer';
import { DocumentViewerPreview, LoadingIndicator } from '@client/shared/toolkit';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate, formatPercentage } from '@client/shared/utilities';
import { getIcsDeductionName, getInvoiceTypeLabel } from '../../utils';
import { useLoadedProject, useLoadedVariantId } from '@client/project/store';

Font.register({ family: 'Roboto', src: '/assets/fonts/Roboto-Regular.ttf' });
Font.register({ family: 'Roboto-Bold', src: '/assets/fonts/Roboto-Bold.ttf' });
Font.register({ family: 'Roboto-Italic', src: '/assets/fonts/Roboto-Italic.ttf' });

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto',
    color: '#0F172B',
    paddingTop: 60,
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 100,
  },
  header: {
    flexDirection: 'row',
  },
  logo: {
    margin: -15,
  },
  headerTitle: {
    marginBottom: 10,
    flexGrow: 1,
    fontFamily: 'Roboto-Bold',
  },
  metaData: {
    fontSize: 11,
    marginBottom: 8,
  },
  textBold: {
    marginTop: 10,
    fontFamily: 'Roboto-Bold',
    marginBottom: 4,
  },
  keyValue: {
    marginTop: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  keyValueBorder: {
    paddingTop: 2,
    marginTop: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: 1,
    borderTopStyle: 'solid',
  },
  keyValueKey: {
    width: '45%',
  },
  columnLeft: {
    width: '50%',
    paddingRight: 10,
  },
  columnRight: {
    width: '50%',
    paddingLeft: 10,
  },
  value: {
    textAlign: 'left',
    width: '45%',
    paddingLeft: 15,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  valueRight: {
    textAlign: 'right',
    width: '60%',
    paddingLeft: 15,
    alignSelf: 'flex-end',
  },
  invoiceTitlesFirst: {
    marginTop: 20,
    flexDirection: 'row',
    fontSize: 11,
  },
  invoiceTitles: {
    marginTop: 8,
    flexDirection: 'row',
    fontSize: 11,
  },
  invoiceTitlesTitleBold: {
    width: 240,
    fontFamily: 'Roboto-Bold',
  },
  invoiceTitlesTitle: {
    width: 240,
  },
  invoiceTitleColumn: {
    width: 200,
    paddingLeft: 20,
    textAlign: 'right',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  invoiceTitleColumnVat: {
    width: 250,
  },
  invoiceTitleColumnBold: {
    fontFamily: 'Roboto-Bold',
    width: 200,
    paddingLeft: 10,
    textAlign: 'right',
  },
  invoiceTitlesBorderDotted: {
    borderTop: 1,
    borderTopStyle: 'dotted',
    width: '100%',
    height: 2,
    marginTop: 8,
  },
  invoiceTitlesBorder: {
    borderTop: 1,
    borderTopStyle: 'solid',
    width: '100%',
    height: 3,
    marginTop: 8,
  },
  invoiceTitlesRight: {
    marginTop: 8,
    flexDirection: 'row',
    fontSize: 11,
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  bankAccount: {
    marginTop: 4,
    fontSize: 11,
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  bankAccountRow: {
    marginBottom: 4,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  bold: {
    fontFamily: 'Roboto-Bold',
  },
  vat: {
    fontSize: 8,
    width: 40,
    textAlign: 'left',
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 60,
    left: 50,
    right: 50,
    width: '100%',
    color: '#475569',
    fontSize: 11,
    fontFamily: 'Roboto',
  },
  footerRight: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  footerLogo: {
    marginBottom: 3,
    marginLeft: 9,
  },
});

export const InvoiceCoverSheetPdf = (props: {
  invoiceId: string;
  invoiceCoverSheetData?: InvoiceReportReadModel | null;
}) => {
  const { invoiceId, invoiceCoverSheetData } = props;

  const { t } = useTranslation();

  const loadedProject = useLoadedProject().currentData?.project.payload;
  const loadedProjectId = loadedProject?.id;
  const loadedVariantId = useLoadedVariantId();

  const [pdfString, setPdfString] = useState<string | null>(null);
  const [icsData, setIcsData] = useState<InvoiceReportReadModel | null>(null);

  const { data: fetchedIcsData, isFetching: isFetchingIcs } = useApiGetInvoiceReportQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      invoiceId: invoiceId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !!invoiceCoverSheetData,
    },
  );

  useEffect(() => {
    if (fetchedIcsData?.invoice) {
      setIcsData(fetchedIcsData.invoice);
    }
  }, [fetchedIcsData]);

  const documentViewer = useMemo(() => {
    return <InvoiceCoverSheetDocumentViewer icsData={icsData} projectName={loadedProject?.name} />;
  }, [icsData, loadedProject?.name]);

  const [instance, updateInstance] = usePDF({
    document: documentViewer,
  });

  useEffect(() => {
    updateInstance(documentViewer);
  }, [icsData, documentViewer, updateInstance]);

  useEffect(() => {
    if (invoiceCoverSheetData) {
      setIcsData(invoiceCoverSheetData);
    }
  }, [invoiceCoverSheetData]);

  useEffect(() => {
    if (instance.blob) {
      const reader = new FileReader();
      reader.readAsDataURL(instance.blob);
      reader.onloadend = function () {
        const base64String = reader.result;
        if (base64String) {
          setPdfString(base64String.toString());
        }
      };
    }
  }, [instance.blob]);

  return (
    <>
      {/* <PDFDownloadLink document={<InvoiceCoverSheetDocumentViewer icsData={icsData} />} fileName="MyFile.pdf"> Click me </PDFDownloadLink> */}
      {/* <PDFViewer style={{ width: '100%', height: viewerHeight }} height={viewerHeight} showToolbar={false}>
          <InvoiceCoverSheetDocumentViewer icsData={icsData} />
        </PDFViewer> */}
      {(isFetchingIcs || !pdfString) && <LoadingIndicator text={t('projectControl.fetchingInvoiceLoadingIndicator')} mode="overlay" />}
      <DocumentViewerPreview
        title={t('ics.invoiceCoverSheet')}
        pdfFile={pdfString}
        file={null}
        name={`invoice-cover-sheet-${formatDate(new Date())}.pdf`}
        menuOptions={{
          fullscreen: false,
        }}
        blob={instance.blob}
        initialZoomLevel={1.4}
      />
    </>
  );
};

const InvoiceCoverSheetDocumentViewer = ({
  icsData,
  projectName,
}: {
  icsData: InvoiceReportReadModel | null;
  projectName?: string;
}) => {
  const { t } = useTranslation();
  const debug = false; // set to true for debugging the positions

  const contract = useMemo(() => {
    return icsData?.contract;
  }, [icsData?.contract]);

  const bankAccount = useMemo(() => {
    if (icsData?.invoiceRecipient?.bankAccounts && icsData.invoiceRecipient.bankAccounts.length) {
      return icsData.invoiceRecipient.bankAccounts[0];
    } else if (icsData?.contract?.client?.bankAccounts && icsData?.contract.client.bankAccounts.length) {
      return icsData?.contract.client.bankAccounts[0];
    }
    return null;
  }, [icsData?.invoiceRecipient?.bankAccounts, icsData?.contract?.client?.bankAccounts]);

  const payoutStatus = useMemo(() => {
    const payedInvoices = contract?.invoices.filter((invoice) => invoice.state === 'Paid');
    let sumNet = 0;
    let sumGross = 0;
    if (payedInvoices?.length) {
      payedInvoices.forEach((payedInvoice) => {
        sumNet += payedInvoice.paymentValueNet ?? 0;
        sumGross += payedInvoice.paymentValueGross ?? 0;
      });
    }
    return {
      net: sumNet,
      gross: sumGross,
    };
  }, [contract?.invoices]);

  return (
    <Document title={t('ics.invoiceCoverSheet')}>
      <Page size="A4" style={styles.page} debug={debug}>
        {icsData && (
          <>
            <View style={styles.header} debug={debug}>
              <View style={styles.headerTitle} debug={debug}>
                <Text>{t('ics.invoiceCoverSheet')}</Text>
              </View>
              <View style={styles.logo} debug={debug}>
                <Svg width="112.172" height="33.233" viewBox="0 0 112.172 33.233">
                  <G transform="translate(-104.229 -223)">
                    <Path
                      data-name="Path 1"
                      d="M13.054,220.491H9.471v8.019H6.229V208.747h6.825c4.691,0,6.995,2.673,6.995,5.887,0,2.815-1.848,5.858-6.995,5.858m0-2.645c2.53,0,3.668-1.223,3.668-3.213,0-2.048-1.138-3.242-3.668-3.242H9.471v6.455Z"
                      transform="translate(98 27.522)"
                      fill="#475569"
                    />
                    <Path
                      data-name="Path 2"
                      d="M108.187,211.392h3.583c2.531,0,3.668,1.28,3.668,3.27,0,1.962-1.137,3.355-3.668,3.355l-2.529.016c.208.416,1.42,2.571,1.42,2.571l4.55,7.906h3.867l-4.891-8.19a5.626,5.626,0,0,0,4.578-5.659c0-3.214-2.3-5.915-7-5.915h-6.824v19.764h3.241"
                      transform="translate(16.914 27.522)"
                      fill="#475569"
                    />
                    <Path
                      data-name="Path 3"
                      d="M213.6,227.533a10.109,10.109,0,1,1,10.094-10.123A9.892,9.892,0,0,1,213.6,227.533m0-2.815c3.924,0,6.767-2.844,6.767-7.308s-2.843-7.251-6.767-7.251-6.768,2.787-6.768,7.251,2.844,7.308,6.768,7.308"
                      transform="translate(-64.041 28.699)"
                      fill="#475569"
                    />
                    <Path
                      data-name="Path 4"
                      d="M348.947,218.359a4.624,4.624,0,0,0,3.355-4.521c0-2.787-2.218-5.09-6.4-5.09h-7.536v19.764l3.209.032c.014-.883.033-8.848.033-8.848h4.266c2.161,0,3.64,1.195,3.64,3.157,0,1.905-1.337,3.014-3.526,3.014l-1.161.031c-1.436,2.5-.076.151-1.512,2.646l2.957-.032c4.038,0,6.484-2.3,6.484-5.318a4.915,4.915,0,0,0-3.81-4.834m-3.327-1.308h-4.01v-5.659h4.01c2.1,0,3.384,1.052,3.384,2.843,0,1.82-1.279,2.815-3.384,2.815"
                      transform="translate(-174.82 27.522)"
                      fill="#475569"
                    />
                    <Rect
                      data-name="Rectangle 1"
                      width="3.242"
                      height="19.764"
                      transform="translate(182.175 236.27)"
                      fill="#475569"
                    />
                    <Path
                      data-name="Path 5"
                      d="M492.96,227.533c-3.953,0-7-2.161-7-5.659h3.469c.142,1.621,1.28,2.929,3.526,2.929,2.275,0,3.555-1.223,3.555-2.9,0-4.777-10.494-1.678-10.494-8.958,0-3.469,2.759-5.63,6.8-5.63,3.782,0,6.455,1.99,6.74,5.375h-3.583c-.114-1.337-1.28-2.559-3.327-2.616-1.877-.057-3.327.853-3.327,2.758,0,4.465,10.465,1.678,10.465,8.9,0,2.957-2.417,5.8-6.825,5.8"
                      transform="translate(-296.056 28.699)"
                      fill="#475569"
                    />
                    <Path
                      data-name="Subtraction 4"
                      d="M6.385,12.77A6.381,6.381,0,0,1,0,6.385,6.417,6.417,0,0,1,.2,4.8,6.345,6.345,0,0,1,.765,3.353,6.433,6.433,0,0,1,2.794,1.1L3.714,2.5A4.712,4.712,0,0,0,1.67,6.385,4.718,4.718,0,1,0,6.385,1.669H6.278V0h.106A6.383,6.383,0,0,1,10.9,10.9a6.366,6.366,0,0,1-4.515,1.87Z"
                      transform="translate(203.131 223.5)"
                      fill="#0d69a0"
                      stroke="#0d69a0"
                      stroke-miterlimit="10"
                      stroke-width="1"
                    />
                  </G>
                </Svg>
              </View>
            </View>

            {/* Contract info */}
            <View style={styles.metaData} debug={debug} wrap={false}>
              {contract && (
                <>
                  <Text style={styles.textBold}>{t('projectContract.contract')}:</Text>
                  <View style={styles.keyValue} debug={debug}>
                    <View style={styles.columnLeft}>
                      <View style={styles.keyValue}>
                        {/* Vergabeeinheit */}
                        <Text>{t('projectControl.ics.commitment')}</Text>
                        <Text style={styles.value}>{contract?.commitment?.name ?? '-'}</Text>
                      </View>
                      <View style={styles.keyValue}>
                        {/* Auftraggeber */}
                        <Text>{t('projectContract.contractClient')}</Text>
                        <Text style={styles.value}>{contract?.client?.name ?? '-'}</Text>
                      </View>
                      <View style={styles.keyValue}>
                        {/* Immobilie */}
                        <Text>{t('projectControl.ics.property')}</Text>
                        <Text style={styles.value}>{'-'}</Text>
                      </View>
                      <View style={styles.keyValue}>
                        {/* Projekt */}
                        <Text>{t('app.project')}</Text>
                        <Text style={styles.value}>{projectName ?? '-'}</Text>
                      </View>
                      <View style={styles.keyValue}>
                        {/* Auftragnehmer */}
                        <Text>{t('projectContract.contractor')}</Text>
                        <Text style={styles.value}>{contract?.contractor?.name ?? '-'}</Text>
                      </View>
                      <View style={styles.keyValue}>
                        {/* Auftrag */}
                        <Text>{t('projectContract.contract')}</Text>
                        <Text style={styles.value}>{contract?.name}</Text>
                      </View>
                      <View style={styles.keyValue}>
                        {/* Auftragsnummer */}
                        <Text>{t('projectContract.contractCode')}</Text>
                        <Text style={styles.value}>{contract?.code}</Text>
                      </View>
                      <View style={styles.keyValue}>
                        {/* Auftragsdatum */}
                        <Text>{t('projectCalculate.earningsElementLabelContractDate')}</Text>
                        <Text style={styles.value}>{contract?.contractDate ? formatDate(contract.contractDate) : '-'}</Text>
                      </View>
                    </View>
                    <View style={styles.columnRight} debug={debug}>
                      <View style={styles.keyValue}>
                        <Text>&nbsp;</Text>
                        <Text style={{ ...styles.valueRight, color: '#64748b', fontSize: 8 }}>
                          {t('common.net')} / {t('common.gross')}
                        </Text>
                      </View>
                      <View style={styles.keyValue}>
                        {/* Hauptauftrag */}
                        <Text>{t('projectContract.mainContract')}</Text>
                        <Text style={styles.valueRight}>
                          {contract?.mainContractValueNet
                            ? formatCurrency(contract.mainContractValueNet, { maxDigits: 2, minDigits: 2 })
                            : '-'}{' '}
                          /{' '}
                          {contract?.mainContractValueGross
                            ? formatCurrency(contract.mainContractValueGross, { maxDigits: 2, minDigits: 2 })
                            : '-'}
                        </Text>
                      </View>
                      <View style={styles.keyValue}>
                        {/* Nachträge */}
                        <Text>{t('projectContract.supplements')}</Text>
                        <Text style={styles.valueRight}>
                          {contract?.supplementValueNet
                            ? formatCurrency(contract.supplementValueNet, { maxDigits: 2, minDigits: 2 })
                            : '-'}{' '}
                          /{' '}
                          {contract?.supplementValueGross
                            ? formatCurrency(contract.supplementValueGross, { maxDigits: 2, minDigits: 2 })
                            : '-'}
                        </Text>
                      </View>
                      <View style={styles.keyValueBorder}>
                        {/* Auftragsvolumen */}
                        <Text style={{ ...styles.keyValueKey, ...styles.bold }}>{t('projectContract.contractValue')}</Text>
                        <Text style={{ ...styles.valueRight, ...styles.bold }}>
                          {contract?.contractValueNet
                            ? formatCurrency(contract.contractValueNet, { maxDigits: 2, minDigits: 2 })
                            : '-'}{' '}
                          /{' '}
                          {contract?.contractValueGross
                            ? formatCurrency(contract.contractValueGross, { maxDigits: 2, minDigits: 2 })
                            : '-'}
                        </Text>
                      </View>
                      <View style={styles.keyValue}>
                        {/* Offene Risiken TBS */}
                        <Text>{t('projectControl.ics.openRisks')}</Text>
                        <Text style={styles.valueRight}>{'-'}</Text>
                      </View>
                      <View style={styles.keyValueBorder}>
                        {/* Vorraussichtl. Gesamtsumme */}
                        <Text style={{ ...styles.keyValueKey, ...styles.bold }}>
                          {t('projectControl.ics.forecastTotal')}
                        </Text>
                        <Text style={{ ...styles.valueRight, ...styles.bold }}>
                          {contract?.forecastValueNet
                            ? formatCurrency(contract.forecastValueNet, { maxDigits: 2, minDigits: 2 })
                            : '-'}{' '}
                          /{' '}
                          {contract?.forecastValueGross
                            ? formatCurrency(contract.forecastValueGross, { maxDigits: 2, minDigits: 2 })
                            : '-'}
                        </Text>
                      </View>
                      <View style={{ ...styles.keyValue, marginTop: 14 }}>
                        {/* Freigabestand (netto) */}
                        <Text>{t('projectControl.ics.releaseStatusTotal')}</Text>
                        <Text style={styles.valueRight}>
                          {contract?.paymentValueNet
                            ? formatCurrency(contract.paymentValueNet, { maxDigits: 2, minDigits: 2 })
                            : '-'}{' '}
                          /{' '}
                          {contract?.paymentValueGross
                            ? formatCurrency(contract.paymentValueGross, { maxDigits: 2, minDigits: 2 })
                            : '-'}
                        </Text>
                      </View>
                      <View style={styles.keyValue}>
                        {/* Auszahlungsstand (netto) */}
                        <Text>{t('projectControl.ics.paymentStatusNet')}</Text>
                        <Text style={styles.valueRight}>
                          {payoutStatus.net ? formatCurrency(payoutStatus.net, { maxDigits: 2, minDigits: 2 }) : '-'} /{' '}
                          {payoutStatus.gross ? formatCurrency(payoutStatus.gross, { maxDigits: 2, minDigits: 2 }) : '-'}
                        </Text>
                      </View>
                    </View>
                  </View>
                </>
              )}
            </View>

            {/* Invoice info */}
            <View style={styles.metaData} debug={debug} wrap={false}>
              <Text style={styles.textBold}>{t('projectContract.invoice')}:</Text>
              <View style={styles.keyValue} debug={debug}>
                <View style={styles.columnLeft}>
                  {!contract && (
                    <>
                      <View style={styles.keyValue}>
                        {/* Projekt */}
                        <Text>{t('app.project')}</Text>
                        <Text style={styles.value}>{projectName ?? '-'}</Text>
                      </View>
                      <View style={styles.keyValue}>
                        {/* Rechnungssteller */}
                        <Text>{t('projectControl.invoicingParty')}</Text>
                        <Text style={styles.value}>{icsData?.invoicingParty?.name ?? '-'}</Text>
                      </View>
                      <View style={styles.keyValue}>
                        {/* Rechnungsempfänger */}
                        <Text>{t('projectControl.invoiceRecipient')}</Text>
                        <Text style={styles.value}>{icsData?.invoiceRecipient?.name ?? '-'}</Text>
                      </View>
                    </>
                  )}
                  <View style={styles.keyValue}>
                    {/* RechnungsNr (extern) */}
                    <Text>{t('projectControl.ics.invoiceNumberExternal')}</Text>
                    <Text style={styles.value}>{icsData.externalCode ? icsData.externalCode : '-'}</Text>
                  </View>
                  <View style={styles.keyValue}>
                    {/* RechnungsNr (intern) */}
                    <Text>{t('projectControl.ics.invoiceNumberInternal')}</Text>
                    <Text style={styles.value}>{icsData.code}</Text>
                  </View>
                  <View style={styles.keyValue}>
                    {/* Rechnungstyp */}
                    <Text>{t('projectControl.ics.invoiceType')}</Text>
                    <Text style={styles.value}>{getInvoiceTypeLabel(icsData.type ?? 'Single')}</Text>
                  </View>
                  <View style={styles.keyValue}>
                    {/* Rechnungsdatum */}
                    <Text>{t('projectControl.invoiceDate')}</Text>
                    <Text style={styles.value}>
                      {icsData.invoiceDate ? formatDate(new Date(icsData.invoiceDate)) : '-'}
                    </Text>
                  </View>
                  <View style={styles.keyValue}>
                    {/* Eingangsdatum */}
                    <Text>{t('projectControl.dateOfReceipt')}</Text>
                    <Text style={styles.value}>
                      {icsData.dateOfReceipt ? formatDate(new Date(icsData.dateOfReceipt)) : '-'}
                    </Text>
                  </View>
                </View>
                <View style={styles.columnRight} debug={debug}>
                  <View style={styles.keyValue}>
                    {/* Skontodatum */}
                    <Text>{t('projectControl.cashDiscountDate')}</Text>
                    <Text style={styles.valueRight}>
                      {icsData.cashDiscountDate ? formatDate(new Date(icsData.cashDiscountDate)) : '-'}
                    </Text>
                  </View>
                  <View style={styles.keyValue}>
                    {/* Fälligkeitsdatum */}
                    <Text>{t('projectControl.dueDate')}</Text>
                    <Text style={styles.valueRight}>{icsData.dueDate ? formatDate(new Date(icsData.dueDate)) : '-'}</Text>
                  </View>
                  <View style={styles.keyValue}>
                    {/* Leistungszeitraum */}
                    <Text>{t('projectControl.ics.servicePeriod')}</Text>
                    <Text style={styles.valueRight}>{t('projectControl.ics.seeInvoice')}</Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.invoiceTitlesFirst} debug={debug}>
              {/* Aktuelle Rechnungen im Überblick */}
              <Text style={styles.invoiceTitlesTitleBold}>{t('projectControl.ics.currentInvoicesTitle')}</Text>
              <Text style={styles.invoiceTitleColumn}>{t('common.net')}</Text>
              <Text style={{ ...styles.invoiceTitleColumn, ...styles.invoiceTitleColumnVat }}>
                {t('projectCalculate.vatReturnModal.vat')}
              </Text>
              <Text style={styles.invoiceTitleColumn}>{t('common.gross')}</Text>
            </View>
            <View style={styles.invoiceTitles} debug={debug}>
              {/* Leistungssumme */}
              <Text style={styles.invoiceTitlesTitle}>{t('projectControl.sumOfPayments')}</Text>
              <Text style={styles.invoiceTitleColumn}>
                {icsData.valueNet ? formatCurrency(icsData.valueNet, { maxDigits: 2, minDigits: 2 }) : '-'}
              </Text>
              <View style={{ ...styles.invoiceTitleColumn, ...styles.invoiceTitleColumnVat }}>
                <Text style={styles.vat}>
                  {icsData.vat ? formatPercentage(icsData.vat / 100) : '-'}&nbsp;&nbsp;&bull;&nbsp;
                </Text>
                <Text>
                  {icsData.valueGross && icsData.valueNet
                    ? formatCurrency(icsData.valueGross - icsData.valueNet, { maxDigits: 2, minDigits: 2 })
                    : '-'}
                </Text>
              </View>
              <Text style={styles.invoiceTitleColumnBold}>
                {icsData.valueGross ? formatCurrency(icsData.valueGross, { maxDigits: 2, minDigits: 2 }) : '-'}
              </Text>
            </View>
            <Text style={styles.invoiceTitlesBorderDotted}>&nbsp;</Text>
            {icsData.invoiceCalculationRuleGroups &&
              icsData.invoiceCalculationRuleGroups.length > 0 &&
              icsData.invoiceCalculationRuleGroups.map((group) => (
                <Fragment key={`ics-group-${group.calculationRuleGroupId}`}>
                  {group.calculationRules &&
                    group.calculationRules.length > 0 &&
                    group.calculationRules.map((rule) => (
                      <View style={styles.invoiceTitles} debug={debug} key={`ics-group-rule-${rule.calculationRuleId}`}>
                        <Text style={styles.invoiceTitlesTitle}>{getIcsDeductionName(rule.name)}</Text>
                        <Text style={styles.invoiceTitleColumn}>
                          {rule.calculationResultNet
                            ? formatCurrency(rule.calculationResultNet, { maxDigits: 2, minDigits: 2 })
                            : '-'}
                        </Text>
                        <View style={{ ...styles.invoiceTitleColumn, ...styles.invoiceTitleColumnVat }}>
                          <Text style={styles.vat}>
                            {rule?.vat
                              ? formatPercentage(rule?.vat / 100)
                              : icsData.vat
                                ? formatPercentage(icsData.vat / 100)
                                : '-'}
                            &nbsp;&bull;&nbsp;
                          </Text>
                          <Text>
                            {formatCurrency(rule.calculationResultGross - rule.calculationResultNet, {
                              maxDigits: 2,
                              minDigits: 2,
                            })}
                          </Text>
                        </View>
                        <Text style={styles.invoiceTitleColumnBold}>
                          {rule.calculationResultGross
                            ? formatCurrency(rule.calculationResultGross, { maxDigits: 2, minDigits: 2 })
                            : '-'}
                        </Text>
                      </View>
                    ))}
                  <Text style={styles.invoiceTitlesBorderDotted}>&nbsp;</Text>
                  <View style={styles.invoiceTitles} debug={debug}>
                    <Text style={{ ...styles.invoiceTitlesTitle, fontFamily: 'Roboto-Italic' }}>
                      {t('ics.deductionGroupLabelSum')}
                    </Text>
                    <Text style={styles.invoiceTitleColumn}>
                      {group.calculationResultNet
                        ? formatCurrency(group.calculationResultNet, { maxDigits: 2, minDigits: 2 })
                        : '-'}
                    </Text>
                    <View style={{ ...styles.invoiceTitleColumn, ...styles.invoiceTitleColumnVat }}>
                      <Text style={styles.vat}>
                        {icsData.vat ? formatPercentage(icsData.vat / 100) : '-'}&nbsp;&bull;&nbsp;
                      </Text>
                      <Text>
                        {formatCurrency(group.calculationResultGross - group.calculationResultNet, {
                          maxDigits: 2,
                          minDigits: 2,
                        })}
                      </Text>
                    </View>
                    <Text style={styles.invoiceTitleColumnBold}>
                      {group.calculationResultGross
                        ? formatCurrency(group.calculationResultGross, { maxDigits: 2, minDigits: 2 })
                        : '-'}
                    </Text>
                  </View>
                </Fragment>
              ))}
            {/* <Text style={styles.invoiceTitlesBorderDotted}>&nbsp;</Text> */}
            <View wrap={false}>
              <Text style={styles.invoiceTitlesBorder}>&nbsp;</Text>
              <View style={styles.invoiceTitlesRight} debug={debug}>
                {/* Auszuzahlender Betrag */}
                <Text style={styles.invoiceTitlesTitleBold}>{t('projectControl.ics.amountToBePaid')}</Text>
                <Text style={styles.invoiceTitleColumnBold}>
                  {icsData.paymentValueGross ? formatCurrency(icsData.paymentValueGross, { maxDigits: 2, minDigits: 2 }) : '-'}
                </Text>
              </View>
            </View>
            <View wrap={false}>
              <View style={styles.invoiceTitlesRight} debug={debug}>
                {/* Zu überweisen auf das Konto */}
                <Text style={styles.invoiceTitlesTitle} debug={debug}>
                  {t('projectControl.ics.toBeTransferredToAccount')}:
                </Text>
                {bankAccount ? (
                  <Text style={styles.invoiceTitleColumn}>{bankAccount?.bankName ?? '-'}</Text>
                ) : (
                  <Text style={styles.invoiceTitleColumn}>-</Text>
                )}
              </View>
              {bankAccount && (
                <View style={styles.bankAccount} debug={debug} wrap={false}>
                  <View style={styles.bankAccountRow}>
                    <Text>{t('app.companiesIban')}</Text>
                    <Text style={styles.bold}>&nbsp;{bankAccount?.iban ?? '-'}</Text>
                  </View>
                  <Text>
                    {t('projectControl.auditBic')} {bankAccount?.iban ?? '-'}
                  </Text>
                </View>
              )}
            </View>

            <View style={styles.footer} fixed debug={debug}>
              <Text render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
              <View style={styles.footerRight} debug={debug}>
                <Text>{t('projectControl.ics.createdWith')}</Text>
                <Svg width="66.71" height="19.55" viewBox="0 0 112.172 33.233" style={styles.footerLogo}>
                  <G transform="translate(-104.229 -223)">
                    <Path
                      data-name="Path 1"
                      d="M13.054,220.491H9.471v8.019H6.229V208.747h6.825c4.691,0,6.995,2.673,6.995,5.887,0,2.815-1.848,5.858-6.995,5.858m0-2.645c2.53,0,3.668-1.223,3.668-3.213,0-2.048-1.138-3.242-3.668-3.242H9.471v6.455Z"
                      transform="translate(98 27.522)"
                      fill="#475569"
                    />
                    <Path
                      data-name="Path 2"
                      d="M108.187,211.392h3.583c2.531,0,3.668,1.28,3.668,3.27,0,1.962-1.137,3.355-3.668,3.355l-2.529.016c.208.416,1.42,2.571,1.42,2.571l4.55,7.906h3.867l-4.891-8.19a5.626,5.626,0,0,0,4.578-5.659c0-3.214-2.3-5.915-7-5.915h-6.824v19.764h3.241"
                      transform="translate(16.914 27.522)"
                      fill="#475569"
                    />
                    <Path
                      data-name="Path 3"
                      d="M213.6,227.533a10.109,10.109,0,1,1,10.094-10.123A9.892,9.892,0,0,1,213.6,227.533m0-2.815c3.924,0,6.767-2.844,6.767-7.308s-2.843-7.251-6.767-7.251-6.768,2.787-6.768,7.251,2.844,7.308,6.768,7.308"
                      transform="translate(-64.041 28.699)"
                      fill="#475569"
                    />
                    <Path
                      data-name="Path 4"
                      d="M348.947,218.359a4.624,4.624,0,0,0,3.355-4.521c0-2.787-2.218-5.09-6.4-5.09h-7.536v19.764l3.209.032c.014-.883.033-8.848.033-8.848h4.266c2.161,0,3.64,1.195,3.64,3.157,0,1.905-1.337,3.014-3.526,3.014l-1.161.031c-1.436,2.5-.076.151-1.512,2.646l2.957-.032c4.038,0,6.484-2.3,6.484-5.318a4.915,4.915,0,0,0-3.81-4.834m-3.327-1.308h-4.01v-5.659h4.01c2.1,0,3.384,1.052,3.384,2.843,0,1.82-1.279,2.815-3.384,2.815"
                      transform="translate(-174.82 27.522)"
                      fill="#475569"
                    />
                    <Rect
                      data-name="Rectangle 1"
                      width="3.242"
                      height="19.764"
                      transform="translate(182.175 236.27)"
                      fill="#475569"
                    />
                    <Path
                      data-name="Path 5"
                      d="M492.96,227.533c-3.953,0-7-2.161-7-5.659h3.469c.142,1.621,1.28,2.929,3.526,2.929,2.275,0,3.555-1.223,3.555-2.9,0-4.777-10.494-1.678-10.494-8.958,0-3.469,2.759-5.63,6.8-5.63,3.782,0,6.455,1.99,6.74,5.375h-3.583c-.114-1.337-1.28-2.559-3.327-2.616-1.877-.057-3.327.853-3.327,2.758,0,4.465,10.465,1.678,10.465,8.9,0,2.957-2.417,5.8-6.825,5.8"
                      transform="translate(-296.056 28.699)"
                      fill="#475569"
                    />
                    <Path
                      data-name="Subtraction 4"
                      d="M6.385,12.77A6.381,6.381,0,0,1,0,6.385,6.417,6.417,0,0,1,.2,4.8,6.345,6.345,0,0,1,.765,3.353,6.433,6.433,0,0,1,2.794,1.1L3.714,2.5A4.712,4.712,0,0,0,1.67,6.385,4.718,4.718,0,1,0,6.385,1.669H6.278V0h.106A6.383,6.383,0,0,1,10.9,10.9a6.366,6.366,0,0,1-4.515,1.87Z"
                      transform="translate(203.131 223.5)"
                      fill="#0d69a0"
                      stroke="#0d69a0"
                      stroke-miterlimit="10"
                      stroke-width="1"
                    />
                  </G>
                </Svg>
              </View>
            </View>
          </>
        )}
      </Page>
    </Document>
  );
};
