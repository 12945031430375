import { CatalogTemplateReadModel, useApiGetCatalogTemplatesQuery } from '@client/shared/api';
import { LoadingIndicator, SettingsAddButton, SlideOver } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { CatalogTemplateRow } from './CatalogTemplateRow';
import { CatalogTemplateDetailsSlideOver } from '.';
import { SettingsHeader } from '../SettingsHeader';

export const CatalogTemplates = () => {
  const { t } = useTranslation();

  const { data: templateData, isFetching } = useApiGetCatalogTemplatesQuery();

  const [searchText, setSearchText] = useState<string>('');
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [selectedCatalogTemplate, setSelectedCatalogTemplate] = useState<CatalogTemplateReadModel | undefined>();

  const catalogTemplates: CatalogTemplateReadModel[] = templateData ?? [];

  const closeSlideOver = () => {
    setShowAdd(false);
    setShowDetails(false);
  };

  const handleEdit = (catalogTemplate: CatalogTemplateReadModel) => {
    setSelectedCatalogTemplate(catalogTemplate);
    setShowDetails(true);
  };

  const handleAdd = () => {
    setShowAdd(true);
    setShowDetails(true);
  };

  return (
    <div className="w-full">
      <SettingsHeader searchText={searchText} setSearchText={setSearchText}>
        {t('templates.catalogTemplatePageTitle')}
      </SettingsHeader>
      <div className="relative h-full">
        <div className="flex w-full text-xs text-gray-500 pl-12 mt-4 mb-2">
          <div className="w-4/12">{t('templates.catalogTemplateNameColumnTitle')}</div>
          <div className="w-8/12">{t('templates.catalogTemplateCatalogColumnTitle')}</div>
        </div>

        {isFetching === true && <LoadingIndicator text={t('templates.catalogTemplatesLoadingIndicator')} />}

        <div className="border-t border-gray-200">
          {catalogTemplates
            .filter((x) => x.name.toLowerCase().includes(searchText?.toLowerCase() ?? ''))
            .map((template, idx) => (
              <div key={idx} className="border-b border-gray-200">
                <CatalogTemplateRow catalogTemplate={template} handleEdit={handleEdit} />
              </div>
            ))}
        </div>

        <SettingsAddButton onClick={handleAdd} />
      </div>

      <SlideOver isOpen={showDetails} onClose={closeSlideOver} onAfterLeave={() => setSelectedCatalogTemplate(undefined)} >
        <CatalogTemplateDetailsSlideOver
          catalogTemplate={selectedCatalogTemplate}
          isAddMode={showAdd}
          onClose={closeSlideOver}/>
      </SlideOver>
    </div>
  );
};
