import classNames from 'classnames';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import React, {PropsWithChildren} from 'react';
import cn from 'classnames';

type ToggleButtonVariants = 'chevron';

export interface ToggleButtonProps extends PropsWithChildren {
  className?: string;
  variant?: ToggleButtonVariants;
  side?: 'left' | 'right';
  onClick?: () => void
  open: boolean
  iconSize?: string
}

export const ToggleButton = (props: ToggleButtonProps) => {
  const {
    className,
    variant = 'chevron',
    iconSize = 'h5 w-5',
    onClick,
    open,
    children
  } = props
  return (
    <div
      className={cn(
        'w-8 flex items-center justify-center cursor-pointer font-normal',
        className
      )}
      onClick={onClick}
    >
      {variant === 'chevron' && (
        <ChevronDownIcon className={classNames(
          iconSize,
          'transition-transform will-change-transform duration-100 transform -rotate-90',
          {
            'rotate-0': open
          }
        )}
        />
      )}
      {children}
    </div>
  )
}
