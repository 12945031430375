import { t } from "i18next";

export const getContractTitleTypeLabel = (titleType : string) => {
    switch (titleType){
        case 'MainContract':
            return t('projectContract.mainContract');
        case 'Supplement':
            return t('projectContract.supplement');
        case 'Reserve':
            return t('projectContract.reserve');
        default:
            return '';
    }
}