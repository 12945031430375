import { AuthGroupReadModel, useApiGetPlainAuthGroupQuery } from '@client/shared/api';
import { Button, LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import { FolderIcon } from '@heroicons/react/24/outline';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface GroupAddSlideOverProps {
  assignedGroups: AuthGroupReadModel[];
  onClose: (newGroups?: AuthGroupReadModel[]) => void;
}

export const GroupAddSlideOver = ({ assignedGroups, onClose }: GroupAddSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const { data: groups, isFetching } = useApiGetPlainAuthGroupQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [selectableGroups, setSelectableGroups] = useState<AuthGroupReadModel[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<AuthGroupReadModel[]>([]);

  useEffect(() => {
    if (!groups) {
      setSelectableGroups([]);
    } else {
      const availableGroups = groups.filter((x) => assignedGroups.find((y) => y.id === x.id) === undefined);
      setSelectableGroups(availableGroups);
    }
  }, [assignedGroups, groups]);

  const handleChange = (role: AuthGroupReadModel) => {
    if (selectedGroups.includes(role)) {
      const newSelection = selectedGroups.filter((x) => x.id !== role.id);
      setSelectedGroups(newSelection);
    } else {
      const newSelection = selectedGroups.concat(role);
      setSelectedGroups(newSelection);
    }
  };

  return (
    <>
      <SlideOver.Header
        title={t('auth.groupAdd')}
        backgroundClassName="bg-blue-900"
        onClose={() => onClose(undefined)}
      />
      <SlideOver.Content className="p-8">
        {isFetching === true ? (
          <LoadingIndicator text={t('auth.groupLoadingIndicator') ?? ''} />
        ) : (
          <div className="divide-y">
            {selectableGroups.map((group, index) => (
              <label className="flex items-center bg-white h-14 p-3 cursor-pointer" key={index}>
                <input
                  type="checkbox"
                  checked={selectedGroups.includes(group)}
                  onChange={() => handleChange(group)}
                  autoFocus={false}
                />
                <FolderIcon className='w-6 mx-2'/>
                {group.name}
              </label>
            ))}
            {selectableGroups.length === 0 && (
              <div className="flex items-center justify-center h-full">
                <div className="text-gray-500">{t('auth.noGroupsAdded')}</div>
              </div>
            )}
          </div>
        )}
      </SlideOver.Content>
      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(undefined)}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={() => onClose(selectedGroups)} innerRef={submitRef}>
          {t('common.add')}
        </Button>
      </SlideOver.Controls>
    </>
  );
};
