import { BaseSelect, SlideOverTitle } from '@client/shared/toolkit';
import React, { ReactNode, useMemo } from 'react';
import {
  GetInvoiceCalculationSampleResponse,
  InvoiceCalculationSchemeReadModel,
  InvoiceType, SampleInvoiceReadModel,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { formatPercentage } from '@client/shared/utilities';
import cn from 'classnames';
import { FormattedCurrency } from '../FormattedCurrency';

interface InvoiceCoverSheetExampleInvoiceProps {
  sheet: InvoiceCalculationSchemeReadModel;
  selectedType: InvoiceType | null;
  setSelectedType: (type: InvoiceType) => void;
  exampleInvoiceData?: GetInvoiceCalculationSampleResponse;
  selectedExampleInvoice?: SampleInvoiceReadModel
}

export const InvoiceCoverSheetExampleInvoice = (props: InvoiceCoverSheetExampleInvoiceProps) => {
  const { sheet, selectedType, setSelectedType, exampleInvoiceData, selectedExampleInvoice } = props;
  const { t } = useTranslation();

  const typeOptions = useMemo(() => {
    return [
      {
        value: 'Partial',
        label: t('projectControl.invoiceTypePartial'),
      },
      {
        value: 'PartialFinal',
        label: t('projectControl.invoiceTypePartialFinal'),
      },
      {
        value: 'Final',
        label: t('projectControl.invoiceTypeFinal'),
      },
    ];
  }, [t]);

  const invoiceData = useMemo(() => {
    if (selectedExampleInvoice) {
      let partialPayment: ReactNode | string = '';
      if (sheet?.type === 'Cumulated' && selectedExampleInvoice && selectedExampleInvoice.partialPaymentGross) {
        partialPayment =
          <IcsEditNetGrossExampleData
            title={t('ics.deductionNamePartialPayment')}
            netValue={<FormattedCurrency amount={selectedExampleInvoice.partialPaymentNet} options={{ maxDigits: 2, minDigits: 2 }} />}
            grossValue={<FormattedCurrency amount={selectedExampleInvoice.partialPaymentGross} options={{ maxDigits: 2, minDigits: 2 }} />}
          />;
      }
      return (
        <>
          {partialPayment}
          <IcsEditNetGrossExampleData
            title={t('ics.referenceTypeInvoiceValue')}
            netValue={<FormattedCurrency amount={selectedExampleInvoice.invoiceValueNet} options={{ maxDigits: 2, minDigits: 2 }} />}
            grossValue={<FormattedCurrency amount={selectedExampleInvoice.invoiceValueGross} options={{ maxDigits: 2, minDigits: 2 }} />}
          />
          {typeof selectedExampleInvoice.vat !== 'undefined' && selectedExampleInvoice.vat !== null &&
            <IcsEditExampleData
              title={t('ics.exampleInvoice.invoiceTaxRate')}
              value={formatPercentage(selectedExampleInvoice.vat / 100)}
            />
          }
        </>
      )
    }
    return null;
  }, [sheet.type, selectedExampleInvoice, t])

  if (!exampleInvoiceData?.calculationSample) return null;

  return (
    <>
      <div className='flex flex-row w-full items-end justify-between pr-12'>
        <SlideOverTitle title={t('ics.exampleInvoice.title')} />
        <SlideOverTitle title={`${t('common.net')} / ${t('common.gross')}`}/>
      </div>
      {sheet?.type === 'Cumulated' && (
          <BaseSelect
            label={t('ics.exampleInvoice.typeSelectLabel')}
            value={selectedType ?? ''}
            options={typeOptions}
            onChange={(type) => setSelectedType(type as InvoiceType)}
            className="my-2 max-w-[300px]"
          />
      )}
      <div className="pl-5 pr-12">
        <IcsEditNetGrossExampleData
          title={t('ics.referenceTypeContractValue')}
          netValue={<FormattedCurrency amount={exampleInvoiceData.calculationSample.contractValueNet} options={{ maxDigits: 2, minDigits: 2 }} />}
          grossValue={<FormattedCurrency amount={exampleInvoiceData.calculationSample.contractValueGross} options={{ maxDigits: 2, minDigits: 2 }} />}
        />
        <IcsEditNetGrossExampleData
          title={t('ics.referenceTypeMainContractValue')}
          netValue={<FormattedCurrency amount={exampleInvoiceData.calculationSample.mainContractValueNet} options={{ maxDigits: 2, minDigits: 2 }} />}
          grossValue={<FormattedCurrency amount={exampleInvoiceData.calculationSample.mainContractValueGross} options={{ maxDigits: 2, minDigits: 2 }} />}
        />
        <IcsEditNetGrossExampleData
          title={t('ics.referenceTypeSupplementValue')}
          netValue={<FormattedCurrency amount={exampleInvoiceData.calculationSample.supplementValueNet} options={{ maxDigits: 2, minDigits: 2 }} />}
          grossValue={<FormattedCurrency amount={exampleInvoiceData.calculationSample.supplementValueGross} options={{ maxDigits: 2, minDigits: 2 }} />}
          className={!exampleInvoiceData.calculationSample.contractVat ? 'mb-3' : ''}
        />
        {typeof exampleInvoiceData.calculationSample.contractVat !== 'undefined' && exampleInvoiceData.calculationSample.contractVat !== null && (
          <IcsEditExampleData
            title={t('ics.exampleInvoice.contractTaxRate')}
            value={formatPercentage(exampleInvoiceData.calculationSample.contractVat / 100)}
            className="mb-3"
          />
        )}
        {invoiceData}
      </div>
    </>
  );
};

export const IcsEditNetGrossExampleData = ({
  title,
  netValue,
  grossValue,
  variant = 'default',
  className,
}: {
  title: string;
  netValue: ReactNode;
  grossValue: ReactNode;
  variant?: 'default' | 'large';
  className?: string;
}) => {
  return (
    <div className={cn('flex justify-between flex-wrap font-medium items-end', className)}>
      <span
        className={cn({
          'text-sm': variant === 'default',
          'font-bold': variant === 'large',
        })}
      >
        {title}
      </span>
      <span
        className={cn({
          'text-sm': variant === 'default',
          'font-bold': variant === 'large',
        })}
      >
        {netValue} / {grossValue}
      </span>
    </div>
  );
};

export const IcsEditExampleData = ({
  title,
  value,
  variant = 'default',
  className,
}: {
  title: string;
  value: ReactNode | string;
  variant?: 'default' | 'large';
  className?: string;
}) => {
  return (
    <div className={cn('flex justify-between flex-wrap font-medium items-end', className)}>
      <span
        className={cn({
          'text-sm': variant === 'default',
          'font-bold': variant === 'large',
        })}
      >
        {title}
      </span>
      <span
        className={cn({
          'text-sm': variant === 'default',
          'font-bold': variant === 'large',
        })}
      >
        {value}
      </span>
    </div>
  );
};
