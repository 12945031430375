import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MainNavigationUserMenu } from './MainNavigationUserMenu';
import { MainNavigationLanguageMenu } from './MainNavigationLanguageMenu';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { MainNavigationMenu } from './MainNavigationMenu';

type MainNavigationProps = {
  setIsMenuOpen: (open: boolean) => void
}

export const MainNavigation = (props: MainNavigationProps) => {
  const { t } = useTranslation();

  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 30]
        }
      },
    ]
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  useEffect(() => {
    setIsMenuOpen(popperElement !== null)
    props.setIsMenuOpen(popperElement !== null)
  }, [popperElement, props]);

  return (
    <div className="text-lg space-x-4 mx-4 lg:mx-10 2xl:mx-20 flex-shrink-0 flex items-center">
      <div className="border-b border-gray-600 py-4 menuLg:py-0 menuLg:my-4 flex flex-grow gap-6 lg:gap-12 3xl:gap-16 justify-between">
        <NavLink to="/" className="flex-none inline-block">
          <div className="flex hidden lg:inline-block">
            <img src="/assets/logo-text-with-icon.svg" alt={t('app.altLogo') ?? 'Logo'} />
          </div>
          <div className="flex lg:hidden h-14 m-0">
            <img src="/assets/logo-icon.svg" className="w-full h-auto" alt={t('app.altLogo') ?? 'Logo'} />
          </div>
        </NavLink>
        <MainNavigationMenu className="hidden menuLg:flex flex-none flex-grow gap-8" />
        <div className="flex flex-none w-auto items-center text-white lg:border-b-2 border-transparent">
          <MainNavigationLanguageMenu />
          <div className="mx-4">|</div>
          <div className="flex flex-row flex-wrap pr-6 lg:pr-0">
            <MainNavigationUserMenu />
          </div>
          <Popover>
            <div ref={setTargetElement} className="flex flex-col items-center ml-4">
              <PopoverButton>
                <div className="p-2 menuLg:hidden">
                  {isMenuOpen ? <XMarkIcon className="w-8 h-8 text-white" /> : <Bars3Icon className="w-8 h-8 text-white" />}
                </div>
              </PopoverButton>
            </div>
            <PopoverPanel
              portal
              ref={setPopperElement}
              style={{ ...styles.popper }}
              {...attributes.popper}
              className="z-20 flex bg-[#021A25] text-white outline-none w-screen px-4 lg:px-10 2xl:px-20 shadow-lg"
            >
              <MainNavigationMenu />
            </PopoverPanel>
          </Popover>
        </div>
      </div>
    </div>
  );
};
