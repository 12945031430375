import React, { useEffect } from 'react';
import { useFormContext, useWatch, FieldValues, DeepPartial } from 'react-hook-form';

interface FormWatchProps<TFieldValues extends FieldValues = FieldValues> {
  fieldNames: string[];
  children: (values: DeepPartial<TFieldValues>) => React.ReactElement;
  onChange?: (values: DeepPartial<TFieldValues>) => void;
}

export const FormWatch = <TFieldValues extends FieldValues>({
  children,
  fieldNames,
  onChange,
}: FormWatchProps<TFieldValues>) => {
  const { control } = useFormContext();
  const watchValue = useWatch({
    control,
    name: fieldNames,
  });

  /* FP: This explicit any is necessary because of the typing in the library */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const result: { [key: string]: unknown } = {};

  fieldNames.forEach((fieldName, index) => (result[fieldName] = watchValue[index]));

  useEffect(() => {
    onChange?.(result as DeepPartial<TFieldValues>);
  }, [onChange, result]);
  // onChange?.(result as DeepPartial<TFieldValues>);

  return children(result as DeepPartial<TFieldValues>);
};
