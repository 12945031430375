import {
  YourAccount,
  Groups,
  LoginAndSecurity,
  SettingsNavLink,
  Roles,
  Users,
  Licenses,
  UserCompanies,
  CompanyInfo,
  CompanyAvatar,
  Settings,
  CatalogTemplates,
  Connect,
  CustomFields,
  Catalogs,
  PartnerCompanies,
  Euribor,
  DefaultValues,
  Workflows,
  SettingsInvoiceCoverSheets,
  SettingsSearchHeader,
  SettingsSearchHeaderRef,
} from '@client/main/settings';
import {
  LockIcon,
  LoyaltyIcon,
  MaleUserIcon,
  useDocumentTitle,
  CityBuildingsIcon,
  UsersCheckCustomIcon,
  BillIcon,
  WorkflowIcon,
  UserAvatar,
  // WorkflowMenuIcon
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import {
  CheckBadgeIcon,
  Cog6ToothIcon,
  InformationCircleIcon,
  UserGroupIcon,
  UserIcon,
  ListBulletIcon,
  RectangleGroupIcon,
  Bars3CenterLeftIcon,
  BuildingOffice2Icon,
  ChartBarIcon,
  Bars3BottomLeftIcon,
} from '@heroicons/react/24/outline';
import { useUi } from '@client/shared/store';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppLayout, AppLayoutTitle, AppLayoutContent, AppLayoutMenu } from '../components';
import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { ProtectedRoute, ROUTES_CONFIG, RouteNavType } from '@client/shared/permissions';

const userSettingsNavItems: RouteNavType[] = [
  {
    label: 'app.settingsYourAccountTitle',
    icon: <MaleUserIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_ACCOUNT,
  },
  {
    label: 'app.settingsCompanies',
    icon: <CityBuildingsIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_COMPANIES,
  },
  {
    label: 'app.loginSecurityTitle',
    icon: <LockIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_LOGIN_SECURITY,
  },
];

const settingsNavItems: RouteNavType[] = [
  {
    label: 'projectRent.dealContactLabelCompany',
    icon: <InformationCircleIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_COMPANY,
  },
  {
    label: 'app.companiesContacts',
    icon: <BuildingOffice2Icon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_PARTNER_COMPANIES,
  },
  {
    label: 'app.settingsUsers',
    icon: <UserIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_COMPANY_USERS,
  },
  {
    label: 'app.settingsTeamsTitle',
    icon: <UserGroupIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_TEAMS,
  },
  {
    label: 'app.settingsPermissions',
    icon: <UsersCheckCustomIcon className="w-full h-full" />,
    routeConfig: ROUTES_CONFIG.SETTINGS_PERMISSIONS,
  },
  {
    label: 'license.licenses',
    icon: <CheckBadgeIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_LICENSES,
  },
  {
    label: 'app.settingsInvoiceCoverSheets',
    icon: <BillIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_ICS,
  },
  {
    label: 'app.settingsTemplates',
    icon: <RectangleGroupIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_TEMPLATES,
  },
  {
    label: 'app.settingsWorkflows',
    icon: <WorkflowIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_WORKFLOWS,
  },
  {
    label: 'app.settingsCatalogs',
    icon: <Bars3CenterLeftIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_CATALOGS,
  },
  {
    label: 'app.settingsUserDefinedFields',
    icon: <LoyaltyIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_USER_DEFINED_FIELDS,
  },
  {
    label: 'app.settingsEuriborRates',
    icon: <ChartBarIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_EURIBOR_RATES,
  },
  {
    label: 'app.masterDataDefaultValues.title',
    icon: <Cog6ToothIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_DEFAULT_VALUES,
  },
  {
    label: 'app.settingsConnectMarketplace',
    icon: <ListBulletIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_CONNECT,
  },
  {
    label: 'app.settingsSettings',
    icon: <Cog6ToothIcon />,
    routeConfig: ROUTES_CONFIG.SETTINGS_VERSIONS,
  },
];

export const SettingsRoute = () => {
  const { t } = useTranslation();

  const ui = useUi();
  const appUser = ui.appUser;

  useDocumentTitle({ title: t('app.settingsTitle') });

  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false);

  useEffect(() => {
    function toggleMenu() {
      if (window.innerWidth < 768) {
        setIsMenuOpen(false);
      } else {
        setIsMenuOpen(true);
      }
    }
    window.addEventListener('resize', toggleMenu);
    toggleMenu();
    return () => window.removeEventListener('resize', toggleMenu);
  }, []);

  const scrollToTop = () => {
    const scrollableContainer = document.getElementById('app-scroll-container');
    if (scrollableContainer) {
      scrollableContainer.scrollTo(0, 0);
    }
  };

  const settingsSearchHeaderRef = useRef<SettingsSearchHeaderRef>(null);

  return (
    <AppLayout updateHeaderCollapsed={setIsHeaderCollapsed} scrollOffset={75}>
      <AppLayoutTitle>{t('app.settingsTitle')}</AppLayoutTitle>
      <AppLayoutMenu>
        {isHeaderCollapsed && (
          <Routes>
            <Route
              path={ROUTES_CONFIG.SETTINGS_PARTNER_COMPANIES.name}
              element={<SettingsSearchHeader routeRef={settingsSearchHeaderRef} />}
            />
          </Routes>
        )}
      </AppLayoutMenu>
      <AppLayoutContent>
        <div className="flex min-h-full gap-6 md:gap-10 w-full">
          <div className="bg-white w-12 md:w-60" style={isMenuOpen ? { width: 240 } : undefined}>
            <button
              className="md:hidden w-full text-center px-3 pt-0 pb-2 border-b mb-4"
              onClick={() => setIsMenuOpen((prev) => !prev)}
            >
              <Bars3BottomLeftIcon className="w-6 h-6" />
            </button>
            <div className="flex mb-5 justify-center gap-3">
              <UserAvatar
                userId={appUser?.userId ?? ''}
                userName={appUser?.fullName ?? ''}
                lastUpdate={appUser?.lastUpdate ?? ''}
                hasAvatar={appUser?.hasAvatar ?? false}
                classNames="h-12 w-12 flex-none"
              />
              <div className={cn('flex flex-col flex-1 overflow-hidden', !isMenuOpen ? 'hidden' : '')}>
                <span className="text-lg text-gray-900 leading-tight">{appUser?.fullName}</span>
                <span className="text-sm text-gray-500 font-light truncate">{appUser?.email}</span>
              </div>
            </div>
            <div className="m-5 flex flex-col space-y-4">
              {/* USER SETTINGS */}
              {userSettingsNavItems.map((item, i) => (
                <SettingsNavLink
                  icon={item.icon}
                  iconPath={item.iconPath}
                  isMenuOpen={isMenuOpen}
                  routeConfig={item.routeConfig}
                  onClick={scrollToTop}
                  key={`user-settings-nav-link-${i}`}
                >
                  {t(item.label)}
                </SettingsNavLink>
              ))}
            </div>
            <div className="border-b border-t">
              <div className="flex mb-5 mt-5 justify-center gap-3">
                <CompanyAvatar
                  tenantId={appUser?.tenant?.tenantId ?? ''}
                  tenantName={appUser?.tenant?.name ?? ''}
                  hasAvatar={appUser?.tenant?.hasAvatar ?? false}
                  lastUpdate={appUser?.tenant?.avatarLastUpdate ?? ''}
                  classNames="h-12 w-12 flex-none"
                />
                <div className={cn('flex flex-col flex-1 overflow-hidden', !isMenuOpen ? 'hidden' : '')}>
                  <span className="text-lg text-gray-900 leading-tight">{appUser?.tenant?.name ?? ''}</span>
                  <span className="text-sm text-gray-500 font-light truncate">{appUser?.tenant?.name2 ?? ''}</span>
                </div>
              </div>
              <div className="m-5 flex flex-col space-y-4">
                {/* GENERAL SETTINGS */}
                {settingsNavItems.map((item, i) => (
                  <SettingsNavLink
                    icon={item.icon}
                    iconPath={item.iconPath}
                    isMenuOpen={isMenuOpen}
                    routeConfig={item.routeConfig}
                    onClick={scrollToTop}
                    key={`general-settings-nav-link-${i}`}
                  >
                    {t(item.label)}
                  </SettingsNavLink>
                ))}
              </div>
            </div>
          </div>
          <div className="flex-1 bg-white pr-5 lg:pr-10 overflow-y-auto">
            <Routes>
              {/* USER SETTINGS */}
              <Route
                path={ROUTES_CONFIG.SETTINGS_ACCOUNT.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_ACCOUNT}>
                    <YourAccount fullName={appUser?.fullName ?? ''} email={appUser?.email ?? ''} />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES_CONFIG.SETTINGS_COMPANIES.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_COMPANIES}>
                    <UserCompanies />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES_CONFIG.SETTINGS_LOGIN_SECURITY.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_LOGIN_SECURITY}>
                    <LoginAndSecurity />
                  </ProtectedRoute>
                }
              />

              {/* SETTINGS */}
              <Route
                path={ROUTES_CONFIG.SETTINGS_COMPANY.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_COMPANY}>
                    <CompanyInfo />
                  </ProtectedRoute>
                }
              />
              {/* CONTACTS */}
              <Route
                path={`${ROUTES_CONFIG.SETTINGS_PARTNER_COMPANIES.name}/*`}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_PARTNER_COMPANIES}>
                    <PartnerCompanies ref={settingsSearchHeaderRef} />
                  </ProtectedRoute>
                }
              />
              {/*
              <Route
                path={ROUTES_CONFIG.SETTINGS_TRUSTED_COMPANIES.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_TRUSTED_COMPANIES}>
                    <TrustedTenants />
                  </ProtectedRoute>
                }
              />
              */}
              <Route
                path={ROUTES_CONFIG.SETTINGS_COMPANY_USERS.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_COMPANY_USERS}>
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES_CONFIG.SETTINGS_TEAMS.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_TEAMS}>
                    <Groups />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES_CONFIG.SETTINGS_PERMISSIONS.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_PERMISSIONS}>
                    <Roles />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES_CONFIG.SETTINGS_LICENSES.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_LICENSES}>
                    <Licenses />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES_CONFIG.SETTINGS_ICS.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_ICS}>
                    <SettingsInvoiceCoverSheets />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES_CONFIG.SETTINGS_TEMPLATES.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_TEMPLATES}>
                    <CatalogTemplates />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES_CONFIG.SETTINGS_WORKFLOWS.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_WORKFLOWS}>
                    <Workflows />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES_CONFIG.SETTINGS_CATALOGS.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_CATALOGS}>
                    <Catalogs />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES_CONFIG.SETTINGS_USER_DEFINED_FIELDS.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_USER_DEFINED_FIELDS}>
                    <CustomFields />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES_CONFIG.SETTINGS_EURIBOR_RATES.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_EURIBOR_RATES}>
                    <Euribor />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES_CONFIG.SETTINGS_DEFAULT_VALUES.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_DEFAULT_VALUES}>
                    <DefaultValues />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES_CONFIG.SETTINGS_CONNECT.name}/*`}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_CONNECT}>
                    <Connect />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES_CONFIG.SETTINGS_VERSIONS.name}
                element={
                  <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_VERSIONS}>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<Navigate to="/settings" replace />} />
            </Routes>
          </div>
        </div>
      </AppLayoutContent>
    </AppLayout>
  );
};
