import { CalculateContext, TimeLineView } from '.';
import { ReactElement, useContext } from 'react';
import cn from 'classnames';

export interface CalculateColumnsProps {
  className?: string;
  view: TimeLineView;
  informationColumnContent?: ReactElement;
  currentColumnContent?: ReactElement;
  obligoColumnContent?: ReactElement;
  optionalColumnContent?: ReactElement;
  archivedColumnContent?: ReactElement;
  borders?: boolean;
  header?: boolean;
  isTotal?: boolean
}

export const CalculateColumns = (props: CalculateColumnsProps) => {
  const {
    view,
    informationColumnContent,
    currentColumnContent,
    obligoColumnContent,
    optionalColumnContent,
    borders = false,
    header = false,
    isTotal = false
  } = props
  const { isListCollapsed } = useContext(CalculateContext)
  return (
    <>
      {/* Information Column */}
      <div className={cn(
        'flex items-end',
        {
          'w-full': view === TimeLineView.TIMELINE || isListCollapsed,
          'w-2/5 flex-1': view === TimeLineView.FINANCE && !isListCollapsed
        }
      )}>
        {header ?
          <div className="-ml-2">{informationColumnContent}</div>
          : informationColumnContent
        }
      </div>
      {view === TimeLineView.FINANCE && !isListCollapsed && (
        <div className={cn(
          'flex w-3/5 flex-none',
          {
            'items-end': !borders
          }
        )}>
          {/* Current Column */}
          <div className={cn('w-4/12', {
            'relative before:content-[""] before:absolute before:left-0 before:top-0 before:bg-gray-200 before:w-px before:h-full': borders
          })}>
            {currentColumnContent}
          </div>
          {/* Obligo Column */}
          <div className={cn('w-4/12', {
            'relative before:content-[""] before:absolute before:left-0 before:top-0 before:bg-gray-200 before:w-px before:h-full': borders
          })}>
            {obligoColumnContent}
          </div>
          {/* Optional Column */}
          <div className={cn('w-4/12', {
            'relative before:content-[""] before:absolute before:left-0 before:top-0 before:bg-gray-200 before:w-px before:h-full': borders
          })}>
            {optionalColumnContent}
          </div>
        </div>
      )}
      {isListCollapsed && isTotal && (
        <>
          {currentColumnContent}
        </>
      )}
    </>
  );
}
