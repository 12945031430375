import { SecurityType } from '@client/shared/api';
import { i18n } from '@client/shared/utilities';

export const getSecurityTypeValueLabel = (securityType: SecurityType) => {
  switch (securityType) {
    case 'Surety':
      return i18n.t('projectRent.securityType.Surety');
    case 'RentalDeposit':
      return i18n.t('projectRent.securityType.RentalDeposit');
    case 'DepositInsurance':
      return i18n.t('projectRent.securityType.DepositInsurance');
    case 'None':
      return i18n.t('projectRent.securityType.None');
    default:
      return i18n.t('projectRent.securityType.DepositInsurance');
  }
};
