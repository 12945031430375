import { getRiskDescription } from '../../utils';
import { useTranslation } from 'react-i18next';
import { formatPercentage } from '@client/shared/utilities';
import { DecoratedElement, RiskElement } from '../../hooks';
import React, { useMemo } from 'react';
import { ContextMenuItem, PencilIcon, TrashIcon } from '@client/shared/toolkit';
import { ElementRowMenu } from '../ElementRowMenu';
import { useLoadedProjectId } from '@client/project/store';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { TimeLineView } from '../Timeline';
import { Column } from '../CalculateContainer';
import { OptionalColumn } from '../CalculateSectionHeader';
import { CalculateColumns } from '../CalculateColumns';
import { CalculateElementRowInformationColumn } from '../CalculateElementRowInformationColumn';
import { CalculateInformationColumnContent } from '../CalculateGroupElementInformationColumn';
import { CalculateElementRowFinanceColumn } from '../CalculateElementRowFinanceColumn';
import { FormattedCurrency } from '@client/project/shared';
import { CalculateSelectableColumn } from '../CalculateSelectableColumn';

interface RiskElementProps {
  item: DecoratedElement<RiskElement>;
  selectedVersionId: string;
  onClick: () => void;
  onRemove?: () => void;
  isSelected?: boolean;
  optionalColumn: OptionalColumn;
  obligoColumn: OptionalColumn;
  column?: Column;
  view: TimeLineView;
  hoveredElementId: string | undefined;
  setHoveredElementId: (id: string | undefined) => void;
  isReadOnly:boolean;
}

export const RiskElementRow = ({
  item: {
    element: { riskElement, showError },
    level,
    parent,
  },
  onClick,
  onRemove,
  view,
  isReadOnly,
  optionalColumn,
  obligoColumn
}: RiskElementProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const canDeleteRisks = useValidateProjectPermission(['RISKS_DELETE'], loadedProjectId ?? '');

  const elementMenuItems = useMemo<ContextMenuItem[]>(() => {
    const items: ContextMenuItem[] = [];

    items.push({
      label: t('projectCalculate.rowMenu.updateRiskElementTitle'),
      subtitle: t('projectCalculate.rowMenu.updateRiskElementSubTitle'),
      onClick: onClick,
      icon: <PencilIcon />,
    });

    if (onRemove) {
      items.push({
        isDisabled: !canDeleteRisks,
        label: t('projectCalculate.rowMenu.removeRiskElementTitle'),
        subtitle: t('projectCalculate.rowMenu.removeRiskElementSubTitle'),
        onClick: onRemove,
        icon: <TrashIcon />,
      });
    }

    return items;
  }, [t, onClick, onRemove, canDeleteRisks]);

  const handleOnClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    onClick?.();
  };

  return (
    <CalculateColumns
      view={view}
      informationColumnContent={
        <CalculateElementRowInformationColumn
          level={level}
          view={view}
          handleOnClick={handleOnClick}
          contentType="row"
          leftColumn={
            <CalculateInformationColumnContent
              showCode={!!parent?.element.group?.code}
              level={level}
              showError={showError}
              title={riskElement?.description ?? riskElement?.title}
              contentType="row"
              showExpanded
            >
              {riskElement?.hasFormula && <sup className="font-normal italic text-gray-400">&nbsp;fx</sup>}
            </CalculateInformationColumnContent>
          }
          rightColumn={
            <>
              <div className="pl-2 hidden w-2/12 md:flex text-sm text-right flex-row justify-end items-center leading-tight">
                {riskElement?.hasFormula && <sup className="font-normal italic text-gray-400">&nbsp;fx</sup>}
                {riskElement && riskElement?.riskTarget === 'FixedValue' && (
                  <div>
                    <FormattedCurrency amount={riskElement.amountAtRisk} />
                    <div className="text-xs font-light text-neutral-500">{t('projectCalculate.riskValue')}</div>
                  </div>
                )}
                {riskElement?.riskTarget !== 'FixedValue' && (
                  <div>
                    {formatPercentage(riskElement?.amountPercentage ?? 0 / 100)}
                    <div className="text-xs font-light text-neutral-500">
                      {getRiskDescription(
                        riskElement?.earningsType,
                        riskElement?.riskTarget,
                        riskElement?.referencedItemCode
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="hidden w-5/12 md:flex xl:w-5/12 text-sm text-right flex-row justify-end items-center leading-tight">
                <div>
                  {formatPercentage((riskElement?.probability ?? 0) / 100)}
                  <div className="text-xs font-light text-neutral-500">
                    {t('projectCalculate.riskElementLabelProbability')}
                  </div>
                </div>
              </div>{' '}
              <div className="w-5/12 text-right flex flex-row justify-end items-center leading-tight">
                <div className="text-sm font-bold">
                  <FormattedCurrency amount={riskElement?.total} />
                  <div className="text-xs font-light text-neutral-500">
                    {t('projectCalculate.riskElementLabelTotal')}
                  </div>
                </div>
              </div>
            </>
          }
          menu={!isReadOnly && (
            <ElementRowMenu items={elementMenuItems} />
          )}
        />
      }
      currentColumnContent={
        <CalculateElementRowFinanceColumn
          level={level + 1}
          handleOnClick={handleOnClick}
          columnType={Column.CURRENT}
          view={view}
        >
          <FormattedCurrency amount={riskElement?.total} />
        </CalculateElementRowFinanceColumn>
      }
      obligoColumnContent={
        <CalculateSelectableColumn
          column={obligoColumn}
          level={level + 1}
          handleOnClick={handleOnClick}
          columnType={Column.OBLIGO}
          view={view}
          values={riskElement}
          type="riskElement"
        />
      }
      optionalColumnContent={
        <CalculateSelectableColumn
          column={optionalColumn}
          level={level + 1}
          handleOnClick={handleOnClick}
          columnType={Column.OPTIONAL_COLUMN}
          view={view}
          values={riskElement}
          type="riskElement"
        />
      }
    />
  );
};
