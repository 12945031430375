import { useDocumentTitle, TitleBar, Button } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useProjects } from '@client/project/store';
import { settings } from '@client/shared/store';
import { useState } from 'react';
import classNames from 'classnames';

export const MultiReportingRoute = () => {
  const { t } = useTranslation();

  useDocumentTitle({ title: t('reporting.routeTitle') });

  const { id } = useParams<{ id: string }>();
  const { groups } = useProjects();
  const group = groups.find((g) => g.id === id);

  const [selectedReport, setSelectedReport] = useState<'graphic' | 'benchmark'>('graphic');

  return (
    <>
      <TitleBar
        title={group?.name ?? t('reporting.multiReportingTitle')}
        subTitle={t('reporting.multiReportingSubTitle')}
      >
        <div className="w-full flex justify-end">
          <Button
            variant="custom"
            onClick={() => {
              setSelectedReport('graphic');
            }}
            className={classNames(
              'mr-2',
              selectedReport === 'graphic'
                ? 'text-white bg-slate-500 hover:bg-slate-600'
                : 'text-gray-500 hover:bg-slate-100',
            )}
          >
            {t('reporting.multiReportingJllCashflow')}
          </Button>
          <Button
            variant="custom"
            onClick={() => {
              setSelectedReport('benchmark');
            }}
            className={classNames(
              selectedReport === 'benchmark'
                ? 'text-white bg-slate-500 hover:bg-slate-600'
                : 'text-gray-500 hover:bg-slate-100',
            )}
          >
            {t('reporting.multiReportingJllBenchmark')}
          </Button>
        </div>
      </TitleBar>
      <div className="flex-grow flex flex-col overflow-y-auto p-8">
        {/* TODO Only for JLL presentation, remove later again */}
        {(settings.mainDomain === 'probis.qa' ||
          settings.mainDomain === 'probis.test' ||
          settings.mainDomain === 'probis.dev') && (
          <img
            className="w-full h-auto"
            src={
              selectedReport === 'graphic'
                ? '/assets/multireport-jll-cashflow.svg'
                : '/assets/multireport-jll-benchmark.svg'
            }
            alt="Multi-Reporting"
          />
        )}
      </div>
    </>
  );
};
