import { PropsWithChildren, ReactNode, useRef } from 'react';
import { Modal, useComponentDimensions } from '@client/shared/toolkit';

interface DistributionModalProps extends PropsWithChildren {
  title?: string
  description?: string
  header?: ReactNode
  footer?: ReactNode
  controls?: ReactNode
}

export const DistributionModal = (props: DistributionModalProps) => {
  const {
    title,
    description,
    header,
    children,
    controls,
    footer
  } = props
  const headerRef = useRef<HTMLDivElement>(null)
  const dimensions = useComponentDimensions(headerRef);
  return (
    <>
      <Modal.Header
        title={title}
        description={description}
      />
      <Modal.Content className="pb-3 justify-between relative">
        {/* Modal Content Header */}
        {header && (
          <div className="absolute top-0 left-0 w-full z-10" ref={headerRef}>
            {header}
          </div>
        )}

        {/* Modal Content Body */}
        {children && (
          <div className="flex-1 overflow-auto relative z-0 mt-[70px] pt-3" style={{ marginTop: dimensions.height}}>
            {children}
          </div>
        )}

        {/* Modal Footer */}
        {footer}
      </Modal.Content>
      <Modal.Controls className="bg-white w-full">
        <div className="w-full flex justify-between">
          {controls}
        </div>
      </Modal.Controls>
    </>
  );
};
