import { CardPaymentIcon, GeneralLedgerIcon, TaskCompletedIcon } from '../../icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from '../FormField';
import { TextInput } from '../TextInput';

export const CreditDataFormFields = () => {
  const { t } = useTranslation()
  return (
    <div className="mt-2">
      <FormField name="creditorId">
        {(control) => <TextInput icon={<CardPaymentIcon />} label={t('app.masterDataBranchCreditor')} {...control} />}
      </FormField>
      <FormField name="debitorId">
        {(control) => <TextInput icon={<GeneralLedgerIcon />} label={t('app.masterDataBranchDebitor')} {...control} />}
      </FormField>
      <FormField name="spvId">
        {(control) => <TextInput icon={<TaskCompletedIcon />} label={t('app.masterDataBranchSpecialPurposeVehicle')} {...control} />}
      </FormField>
    </div>
  )
}
