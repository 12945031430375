/**
 * Formats a file size in bytes into a human-readable string.
 * @param bytes The file size in bytes.
 * @param base The base in binary or decimal
 * @returns {string} The human-readable string.
 */
export const formatFileSize = (bytes: number, base = 1024) => {
  let unit = 0;

  while (bytes >= base || -bytes >= base) {
    bytes /= base;
    unit++;
  }

  return `${unit ? `${bytes.toFixed(1)} ` : bytes}${` KMGTPEZY`[unit]}B`;
};
