import classNames from 'classnames';
import React from 'react';

interface CheckBoxProps {
  id?: string;
  checked?: boolean;
  className?: string;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  name?: string;
  autoComplete?: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const CheckBox = ({
  autoComplete,
  id,
  checked = false,
  className,
  name,
  disabled,
  onFocus,
  onChange,
  onBlur,
}: CheckBoxProps) => {
  return (
    <input
      id={id}
      type="checkbox"
      className={classNames(
        'h-[18px] w-[18px] rounded accent-gray-800',
        {
          'text-gray-800': !disabled,
          'text-gray-500 cursor-not-allowed': disabled,
        },
        className
      )}
      checked={checked}
      disabled={disabled}
      autoComplete={autoComplete}
      name={name}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={() => onChange(!checked)}
    />
  );
};
