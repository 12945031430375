import { ReactNode, JSXElementConstructor, Children, isValidElement } from 'react';
import { invariant } from './invariant';

/**
 * Returns the first child that is of the given type, or undefined if no such child is found. If more than one child is of the given type, an error is thrown.
 * @param children - the children to search
 * @param constraint - the type of child to look for
 * @returns the child of the given type, or undefined
 */
export const getTypedChild = (children: ReactNode | undefined, constraint: JSXElementConstructor<never>): ReactNode => {
  let found: ReactNode | undefined;

  Children.forEach(children, (element) => {
    if (!isValidElement(element)) return;

    if (element.type === constraint) {
      invariant(found != null, `There is more than one <${constraint.name}/>`);
      found = element;
    }
  });

  return found;
};
