import { AuthEffectiveCatalogReadModel } from '@client/shared/api';
import { SearchIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { PropsWithChildren, useState } from 'react';
import { EffectiveCatalog } from './EffectiveCatalog';

interface EffectiveCatalogListProps extends PropsWithChildren {
  effectiveTenantCatalogTemplates: AuthEffectiveCatalogReadModel[],
  effectiveProjectCatalogTemplates: AuthEffectiveCatalogReadModel[],
}

export const EffectiveCatalogList = ({
  effectiveTenantCatalogTemplates,
  effectiveProjectCatalogTemplates,
  children
}: EffectiveCatalogListProps) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState<string>('');

  return (
    <div className='p-4'>
      {(effectiveProjectCatalogTemplates.length === 0 && effectiveTenantCatalogTemplates.length === 0) ? (
        <div className="flex w-full items-center justify-center">
          <div className="text-gray-500">{t('auth.noTemplatesAssigned')}</div>
        </div>
      ) : (
        <>
          {effectiveTenantCatalogTemplates.length > 0 && (
            <>
              <div className="text-2xl text-gray-400">
                {t('auth.tenantImpactLevelTitle')}
              </div>
              <div className="mx-2">
                {effectiveTenantCatalogTemplates.map((catalog, index) => (
                  <EffectiveCatalog
                    key={index}
                    effectiveCatalog={catalog}/>
                ))}
              </div>
            </>
          )}

          {effectiveProjectCatalogTemplates.length > 0 && effectiveTenantCatalogTemplates.length > 0 &&
            <div className="mb-10"/>
          }

          {effectiveProjectCatalogTemplates.length > 0 && (
            <div className='ml-4'>
              <div className="flex flex-row items-center">
                <div className="text-2xl text-gray-400 ">{t('auth.projectImpactLevelTitle')}</div>
              </div>
              <div className="relative text-gray-600 my-2">
                <SearchIcon className="absolute left-0 top-0 mt-3 ml-4 h-5 w-5" />
                <input
                  type="search"
                  placeholder={t('common.search')}
                  className="bg-gray-100 h-10 px-5 pl-10 rounded-full text-sm focus:outline-none border-none"
                  onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                />
              </div>
              {effectiveProjectCatalogTemplates
                .filter((x) =>
                  x.project && x.project.name.toLowerCase().includes(searchText))
                .map((catalog, index) => (
                  <EffectiveCatalog
                    key={index}
                    effectiveCatalog={catalog}/>
              ))}
            </div>
          )}
        </>
      )}
      {children}
    </div>
  );
};
