import {
  BaseSelect,
  Button,
  FileInput,
  Form,
  FormField,
  FormRefHandle,
  FormWatch,
  HintBox, LoadingIndicator,
  Modal,
  TextInput,
  UploadToCloudDottedIcon,
  Wizard,
} from '@client/shared/toolkit';
import { Trans, useTranslation } from 'react-i18next';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { UploadPortalValidationSchema, UploadPortalValidationValues } from './UploadPortalValidationSchema';
import cn from 'classnames';
import { InvoiceDocumentViewer } from '../Invoice';
import {
  CrossTenantContractReadModel,
  TrustedProjectReadModel,
  useApiPostCrossTenantUploadAiEvalDocumentMutation
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';

export const UploadWizard = ({
  contracts = [],
  loadedProjectId,
  project,
  onClose,
  setModalSize,
}: {
  loadedProjectId: string;
  project?: TrustedProjectReadModel;
  contracts?: CrossTenantContractReadModel[];
  onClose?: () => void;
  setModalSize?: (size: string | undefined) => void;
}) => {
  const { t } = useTranslation();

  const [uploadInvoiceDocument, { isLoading }] = useApiPostCrossTenantUploadAiEvalDocumentMutation();

  const formRef = useRef<FormRefHandle<UploadPortalValidationValues>>();

  const [currentStep, setCurrentStep] = useState(0);
  const [allowNextStep, setAllowNextStep] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [pdfFile] = useState<string | null>(null);

  const [files, setFiles] = useState<FileList | null>(null);
  const [fileError, setFileError] = useState(false);

  const handleOnSlideChange = async (index: number) => {
    setAllowNextStep(false);
    if (setModalSize) {
      if (index === 2) {
        setModalSize('large');
      } else {
        setModalSize(undefined);
      }
    }
    setCurrentStep(index);
    validateSteps();
  };

  const uploadDocument = async (data: UploadPortalValidationValues) => {
    if (file && project && !fileError) {
      try {
        const formData = new FormData();
        formData.append('file', file);

        await safeMutation(
          uploadInvoiceDocument,
          {
            contractId: data.contract,
            crossTenantId: project.tenantId,
            crossTenantProjectId: data.projectId,
            body: formData as unknown as { file: Blob },
          },
          isLoading,
        );
        if (setModalSize) {
          setModalSize(undefined);
        }
        setCurrentStep((prev) => prev + 1);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const validateSteps = useCallback(() => {
    if (formRef.current) {
      const values = formRef?.current?.getValues();
      switch (currentStep) {
        case 0: {
          if (values.documentType && values.contract) {
            setAllowNextStep(true);
          } else {
            setAllowNextStep(false);
          }
          break;
        }
        case 1: {
          if (values.documentType && values.contract && file) {
            setAllowNextStep(true);
          } else {
            setAllowNextStep(false);
          }
          break;
        }
        case 2: {
          if (values.documentType && values.contract && file) {
            setAllowNextStep(true);
          } else {
            setAllowNextStep(false);
          }
          break;
        }
        default:
          break;
      }
    }
  }, [currentStep, formRef, file]);

  const handleFileChange = (fileList: FileList | null) => {
    if (fileList && fileList[0]) {
      setFile(fileList[0]);
      if (currentStep === 1 && fileList[0]) {
        setAllowNextStep(true);
      }
      setFiles(fileList);
    } else {
      setFile(null);
      setAllowNextStep(false);
      setFiles(null);
    }
  };

  const nextButtonLabel = useMemo(() => {
    switch (currentStep) {
      case 0:
        return t('common.next');
      case 1:
        return t('common.upload');
    }

    return t('common.next');
  }, [currentStep, t]);

  const documentTypeOptions = useMemo(() => {
    return [
      { label: t('projectControl.invoice'), value: 'invoice' },
      // { label: t('projectContract.contract'), value: 'contract' },
      // { label: t('projectContract.supplement'), value: 'supplement' },
    ];
  }, [t]);

  const contractOptions = useMemo(() => {
    return contracts.map((contract) => {
      return {
        label: `${contract.code} ${contract.name}`,
        value: contract.id,
      };
    });
  }, [contracts]);

  const defaultFormValues = {
    projectId: project?.id ?? '',
    documentType: 'invoice',
    contract: '',
  };

  const reset = () => {
    handleFileChange(null);
    setCurrentStep(0);
  };

  if (!project) {
    return null;
  }

  return (
    <>
      {isLoading && <LoadingIndicator text={t('projectControl.uploadInvoiceLoadingIndicator')} mode="overlay" />}
      <Form<UploadPortalValidationValues>
        onSubmit={uploadDocument}
        validationSchema={UploadPortalValidationSchema}
        defaultValues={defaultFormValues}
        ref={formRef}
        className="h-full"
      >
        <div className="w-full h-full flex items-center">
          <div className="w-[260px] min-h-full py-6 px-2 bg-primary text-white flex flex-col items-center bg-bottom bg-no-repeat bg-contain" />
          <div className="h-full flex-1">
            <FormWatch<UploadPortalValidationValues>
              onChange={() => {
                validateSteps();
              }}
              fieldNames={['projectId', 'documentType', 'contract']}
            >
              {() => (
                <Wizard
                  currentStep={currentStep}
                  onSlideChange={(step) => handleOnSlideChange(step)}
                  className="w-full h-full"
                >
                  <Wizard.Slides className="h-full overflow-auto">
                    <Wizard.Slide hasPadding={false}>
                      <div className="w-full px-4 sm:px-10">
                        <h2 className="text-4xl text-primary font-bold mb-8">{t('uploadPortal.uploadTitle')}</h2>
                        <TextInput
                          label={t('app.project')}
                          value={`${project?.tenantName} - ${project?.name}`}
                          disabled
                        />
                        <FormField name="documentType">
                          {(control) => (
                            <BaseSelect
                              options={documentTypeOptions}
                              label={t('uploadPortal.uploadTypeOfDocument')}
                              {...control}
                            />
                          )}
                        </FormField>
                        <FormField name="contract">
                          {(control) => (
                            <BaseSelect
                              disabled={!contracts.length}
                              options={contractOptions}
                              label={t('projectContract.contract')}
                              {...control}
                            />
                          )}
                        </FormField>
                        {!contracts.length && (
                          <HintBox hintType="warning" className="mt-2">
                            {t('uploadPortal.noContractsAvailable', { name: project?.tenantName })}
                          </HintBox>
                        )}
                      </div>
                    </Wizard.Slide>
                    <Wizard.Slide>
                      <div className="w-full px-4 sm:px-10">
                        <h2 className="text-4xl text-primary font-bold mb-8">{t('uploadPortal.uploadTitle')}</h2>
                        <FileInput
                          className="w-full rounded-md shadow bg-white hover:bg-slate-50"
                          icon={<UploadToCloudDottedIcon className="w-16 flex-1" />}
                          acceptedFileTypes={['application/pdf']}
                          multiple={false}
                          selectedFiles={files}
                          onChange={handleFileChange}
                          uploadDescription={
                            <Trans i18nKey="uploadPortal.uploadFileInputDescription">
                            <span className="text-[13px]">
                              Ziehen Sie Ihre Datei per <span className="font-bold">Drag & Drop</span> in diesen Bereich
                              oder <span className="font-bold">klicken</span> Sie hier.
                            </span>
                            </Trans>
                          }
                          setError={setFileError}
                        />
                      </div>
                    </Wizard.Slide>
                    <Wizard.Slide>
                      <div className="w-full px-4 sm:px-10 h-full">
                        <InvoiceDocumentViewer
                          fileData={null}
                          file={file}
                          pdfFile={pdfFile}
                          setFile={setFile}
                          imageWidth={0}
                          imageHeight={0}
                          onUpload={() => {
                            // empty
                          }}
                          loadedProjectId={loadedProjectId}
                          additionalMenu={false}
                          onClose={() => {
                            // empty
                          }}
                          className="h-full"
                          showForm={false}
                        />
                      </div>
                    </Wizard.Slide>
                    <Wizard.Slide>
                      <div className="max-w-[369px] px-4 sm:px-10">
                        <p className="text-sm text-slate-500">{t('uploadPortal.uploadFileUploadSuccessMessage')}</p>
                      </div>
                    </Wizard.Slide>
                  </Wizard.Slides>
                  <Wizard.Navigation>
                    {({ count, isFirst, isLast, canGoPrevious, canGoNext, previous, next }) => (
                      <Modal.Controls
                        hasPadding={false}
                        className={cn(
                          'py-6',
                          currentStep === 3 ? 'bg-white px-8 py-4' : 'px-4 sm:px-10',
                          isLast ? '' : 'justify-between',
                        )}
                      >
                        {(() => {
                          if (isLast && onClose) {
                            return (
                              <Button
                                variant="text"
                                onClick={onClose}
                                className="bg-slate-200 px-8 h-[48px] hover:bg-slate-300"
                              >
                                {t('common.close')}
                              </Button>
                            );
                          }
                          return (
                            <Button
                              variant="secondary"
                              disabled={!isFirst && !canGoPrevious}
                              onClick={isFirst && onClose ? onClose : previous}
                              className="bg-slate-200 px-8 h-[48px] hover:bg-slate-300"
                            >
                              {t('common.back')}
                            </Button>
                          );
                        })()}
                        {(() => {
                          if (isLast) {
                            return (
                              <div className="text-right">
                                <Button variant="primary" onClick={reset} className="px-8 h-[48px]">
                                  {t('uploadPortal.uploadAnotherFile')}
                                </Button>
                              </div>
                            );
                          } else if (currentStep === 2) {
                            return (
                              <Button variant="danger" onClick={() => formRef.current?.submitForm()} disabled={fileError}>
                                {t('uploadPortal.transmitData')}
                              </Button>
                            );
                          } else {
                            return (
                              <Button
                                variant="primary"
                                disabled={!canGoNext || !allowNextStep || fileError}
                                onClick={next}
                                className="px-8 h-[48px]"
                              >
                                {nextButtonLabel}
                              </Button>
                            );
                          }
                        })()}
                      </Modal.Controls>
                    )}
                  </Wizard.Navigation>
                </Wizard>
              )}
            </FormWatch>
          </div>
        </div>
      </Form>
    </>
  );
};
