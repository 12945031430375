import {
  ComboSelect,
  BaseSelectOption,
  EditFilledIcon,
  EditNodeIcon,
  TagWindowIcon,
  TextInput
} from "@client/shared/toolkit"
import { useTranslation } from "react-i18next"

const EarningElementMetadata = ({
    hasCatalog,
    isGroup,
    catalogOptions,
    selectedCatalogElementId,
    updateSelectedCatalogElement,
    code,
    title,
    setCode,
    setTitle,
    disabled,
    description,
    setDescription
}: {
    hasCatalog: boolean
    isGroup: boolean
    catalogOptions: BaseSelectOption[]
    selectedCatalogElementId: string | null;
    updateSelectedCatalogElement: (arg: string | null) => void
    code: string;
    title: string;
    setCode: (arg: string) => void;
    setTitle: (arg: string) => void;
    disabled: boolean | undefined;
    description: string;
    setDescription: (arg: string) => void;
}) => {

    const { t } =useTranslation();

    return (
        <div className="mx-8 mt-8 bg-white">
            <div className="divide-gray-100 divide-y">
                {hasCatalog ? (
                    !isGroup ? (
                        <ComboSelect
                            label={t('projectCalculate.groupLabelGroupSelector')}
                            options={catalogOptions}
                            value={selectedCatalogElementId ?? ''}
                            disabled={disabled}
                            onChange={(selected) => updateSelectedCatalogElement(selected)}
                            icon={<EditNodeIcon />}
                        />
                    ) : (
                        <TextInput
                            label={t('projectCalculate.costElementLabelCatalogElement')}
                            value={`${code} - ${title}`}
                            disabled={true}
                            icon={<TagWindowIcon />}
                        />
                    )
                ) : (
                    <>
                        <TextInput
                            label={t('projectCalculate.earningsElementLabelPosition')}
                            value={code}
                            onChange={(value) => setCode(value)}
                            icon={<EditNodeIcon />}
                            disabled={disabled}
                        />
                        <TextInput
                            label={t('projectCalculate.earningsElementLabelTitle')}
                            value={title}
                            onChange={(value) => setTitle(value)}
                            icon={<TagWindowIcon />}
                            disabled={disabled}
                        />
                    </>
                )}

                <TextInput
                    label={t('projectCalculate.earningsElementLabelDescription')}
                    value={description}
                    onChange={(value) => setDescription(value)}
                    icon={<EditFilledIcon />}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

export default EarningElementMetadata;
