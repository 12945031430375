import { isEmpty } from './isEmpty';
import { getLanguageAndLocale } from './i18n';
import { formatISO } from 'date-fns';

interface FormatDateOptions {
  locale?: string;
  placeHolder?: string;
  includeDay?: boolean;
  shortYear?: boolean;
  shortMonth?: boolean;
}

/**
 * Convert a date to a string representation.
 * @param date The date to convert.
 * @param options The options to use when converting the date.
 * @param options.locale The locale to use when converting the date.
 * @param options.placeHolder The placeholder to use if the date is null or undefined. Defaults to '-'.
 * @param options.includeDay Include day in string representation. Defaults to true.
 * @param options.shortYear Include short day in string representation. E.g. '22' instead of '2022'.
 * @param options.shortMonth Include short month in string representation. E.g. 'Feb' instead of '02'.
 * @returns {string} A string representation of the date.
 */
export const formatDate = (
  date: string | number | Date | undefined | null,
  {
    locale = getLanguageAndLocale().locale,
    placeHolder = '-',
    includeDay = true,
    shortYear = false,
    shortMonth = false,
  }: FormatDateOptions = {}
) => {
  if (date == null || isEmpty(date)) {
    return placeHolder;
  }

  if (isNaN(new Date(date).getTime())) {
    return date.toString();
  }

  const format = includeDay
    ? new Intl.DateTimeFormat(locale, {
        year: shortYear ? '2-digit' : 'numeric',
        month: shortMonth ? 'short' : '2-digit',
        day: '2-digit',
      })
    : new Intl.DateTimeFormat(locale, {
        year: shortYear ? '2-digit' : 'numeric',
        month: shortMonth ? 'short' : '2-digit',
      });

  return format.format(new Date(date));
};

export const formatDateTime = (
  date: string | number | Date | undefined | null,
  {
    locale = getLanguageAndLocale().locale,
    placeHolder = '-',
    shortYear = false,
    shortMonth = false,
  }: FormatDateOptions = {}
) => {
  if (date == null || isEmpty(date)) {
    return placeHolder;
  }

  if (isNaN(new Date(date).getTime())) {
    return date.toString();
  }

  const format = new Intl.DateTimeFormat(locale, {
    year: shortYear ? '2-digit' : 'numeric',
    month: shortMonth ? 'short' : '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });

  return format.format(new Date(date));
};

export const formatNullableDateOnly = (date: Date | undefined | null) : string | undefined => {
  if (!date){
    return undefined;
  }

  return formatISO(date, { representation : 'date'});
}

export const formatDateOnly = (date: Date) : string => {
  return formatISO(date, { representation : 'date'});
}
