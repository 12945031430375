import { FinancingGroupReadModel, FinancingType } from '@client/shared/api';
import { DecoratedCard } from '@client/shared/toolkit';
import { formatPercentage } from '@client/shared/utilities';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface FinancingAnalysisProps {
    className?: string
    totalInvestmentCosts: number;
    totalOperationalResult: number;
    financingElements: FinancingGroupReadModel[];
    mezzanineElements: FinancingGroupReadModel[];
}

type FinancingElement = {
    type : FinancingType,
    title : string,
    interest : number | undefined,
    origin: number,
    drawing: number
}

export const FinancingAnalysis = ({ className, totalInvestmentCosts, totalOperationalResult, financingElements, mezzanineElements}: FinancingAnalysisProps) => {
    const  { t } = useTranslation();

    const getElementsRecursivly = (groups :FinancingGroupReadModel[]) => {
        let elements : FinancingElement[] = [];

        groups.forEach(group => {
            elements = elements.concat(group.financingElements.map(element => (
                {
                    title : element.title ?? '',
                    type : element.type,
                    interest : element.interestRate ? element.interestRate / 100 : undefined,
                    origin : totalInvestmentCosts === 0 ? 0 : element.totalValue / totalInvestmentCosts,
                    drawing : totalOperationalResult === 0 ? 0 : element.totalValue / totalOperationalResult
                }
            )));
            elements = elements.concat(getElementsRecursivly(group.groups));
        });

        return elements;
    }

    const hasOperationalResult = totalOperationalResult !== 0;

    const elements = getElementsRecursivly(financingElements);
    const mezzanine = getElementsRecursivly(mezzanineElements);

    const totalOrigin = elements.filter(x => x.type !== 'OwnCapital').reduce((previous, current) => previous = previous + current.origin, 0);
    const totalDrawing = elements.filter(x => x.type !== 'OwnCapital').reduce((previous, current) => previous = previous + current.drawing, 0);

    const equity = elements.filter(x => x.type === 'OwnCapital');
    const other = elements.filter(x => x.type !== 'OwnCapital');

    return(
        <DecoratedCard className={className}>
            <DecoratedCard.Header variant="plain">
                {t('project.financingAnalysis.title')}
            </DecoratedCard.Header>
            <DecoratedCard.Content>
                <div className="px-6 py-3 h-full text-right bg-white"
                    style={{
                        backgroundImage : 'url(/assets/icon-clipboard-watermark.svg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPositionX: '95%',
                        backgroundPositionY: '90%'
                    }}
                >
                    <div className='flex flex-row border-b py-2'>
                        <div className={classNames('text-left', hasOperationalResult ? 'w-3/12' : 'w-6/12')}>{t('project.financingAnalysis.financing')}</div>
                        <div className='w-3/12'>{t('project.financingAnalysis.InterestRate')}</div>
                        <div className='w-3/12'>{t('project.financingAnalysis.Origin')}</div>
                        {hasOperationalResult && <div className='w-3/12'>{t('project.financingAnalysis.Drawing')}</div>}
                    </div>

                    <div className='overflow-y-auto h-40'>
                        {equity.map((element, index) => (
                            <div key={index} className='flex flex-row py-2'>
                                <div className={classNames('text-left', hasOperationalResult ? 'w-3/12' : 'w-6/12')}>{element.title}</div>
                                <div className='w-3/12'>{formatPercentage(element.interest)}</div>
                                <div className='w-3/12'>{formatPercentage(element.origin)}</div>
                                {hasOperationalResult && <div className='w-3/12'>{formatPercentage(element.drawing)}</div>}
                            </div>
                        ))}

                        {mezzanine.map((element, index) => (
                            <div key={index} className='flex flex-row py-2'>
                                <div className={classNames('text-left', hasOperationalResult ? 'w-3/12' : 'w-6/12')}>{element.title}</div>
                                <div className='w-3/12'>{formatPercentage(element.interest)}</div>
                                <div className='w-3/12'>{formatPercentage(element.origin)}</div>
                                {hasOperationalResult && <div className='w-3/12'>{formatPercentage(element.drawing)}</div>}
                            </div>
                        ))}

                        {other.map((element, index) => (
                            <div key={index} className='flex flex-row py-2'>
                                <div className={classNames('text-left', hasOperationalResult ? 'w-3/12' : 'w-6/12')}>{element.title}</div>
                                <div className='w-3/12'>{formatPercentage(element.interest)}</div>
                                <div className='w-3/12'>{formatPercentage(element.origin)}</div>
                                {hasOperationalResult && <div className='w-3/12'>{formatPercentage(element.drawing)}</div>}
                            </div>
                        ))}
                    </div>

                    <div className='flex flex-row border-t-2 pt-4 py-2'>
                        <div className={classNames('text-left', hasOperationalResult ? 'w-6/12' : 'w-9/12')}>{t('project.financingAnalysis.ltc')}</div>
                        <div className='w-3/12'>{formatPercentage(totalOrigin)}</div>
                        {hasOperationalResult && <div className='w-3/12'>{formatPercentage(totalDrawing)}</div>}
                    </div>
                </div>
            </DecoratedCard.Content>
        </DecoratedCard>
    );
};
