import { useLoadedProjectId, useLoadedVariantId } from "@client/project/store";
import { ContractDocumentReadModel, useApiDeleteContractDocumentMutation } from "@client/shared/api";
import { ConfirmModal, LoadingIndicator, ModalOnCloseProps } from "@client/shared/toolkit";
import { Trans, useTranslation } from "react-i18next";
import { safeMutation } from '@client/shared/utilities';

interface ContractDocumentDeleteModalProps extends ModalOnCloseProps {
    contractId: string;
    document: ContractDocumentReadModel;
  }
  
  export const ContractDocumentDeleteModal = ({ contractId, document, onClose }: ContractDocumentDeleteModalProps) => {
    const { t } = useTranslation();
    const loadedProjectId = useLoadedProjectId();
    const loadedCalculationModelId = useLoadedVariantId();
    
    const [deleteDocument, {isLoading}] = useApiDeleteContractDocumentMutation();
  
    const handleDelete = async () => {
        try {
            await safeMutation(
              deleteDocument,
              {
                projectId: loadedProjectId ?? 'unset',
                calculationModelId: loadedCalculationModelId ?? 'unset',
                contractId: contractId,
                documentId: document.id
              },
              isLoading
            );
      
            onClose(true);
          } catch (e) {
            console.log(e);
          }  
    };
  
    return (
      <ConfirmModal
        title={t('projectCalculate.DeleteDocument')}
        onConfirm={handleDelete}
        onClose={() => onClose(false)}
        confirmVariant="danger"
        confirmText={t('common.delete')}
      >
        {isLoading && <LoadingIndicator text={t('common.deleteLoading') ?? ''} mode="overlay" />}
        <Trans i18nKey="projectCalculate.DocumentDeleteConfirmation" values={{ entity: document.name }}>
          <div>
            Möchten Sie die Datei <span className="font-bold">Entity</span> wirklich löschen?
          </div>
          <div className="pt-2">Dies kann nicht rückgängig gemacht werden!</div>
        </Trans>
      </ConfirmModal>
    );
  };