import { DocumentReadModel } from '@client/shared/api';
import { useState } from 'react';
import {
  Modal,
  DocumentListItem
} from '@client/shared/toolkit';
import { CalculateElementDocumentDeleteModal } from './CalculateElementDocumentDeleteModal';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { DocumentOwnerType } from './CalculateElementDocuments';
import { useTranslation } from 'react-i18next';

interface CalculateElementDocumentRowProps {
  ownerId: string;
  type: DocumentOwnerType;
  elementDocument: DocumentReadModel;
  canDelete: boolean;
}

export const CalculateElementDocumentRow = ({
  ownerId,
  type,
  elementDocument,
  canDelete,
}: CalculateElementDocumentRowProps) => {
  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const getSubPathByType = () => {
    switch (type) {
      case 'CostElement':
        return 'cost-elements';
      case 'EarningsElement':
        return 'earnings-elements';
      case 'RiskElement':
        return 'risk-elements';
      case 'FinancingElement':
        return 'financing-elements';
      default:
        return '';
    }
  };

  const downloadUrl = `/api/projects/${loadedProjectId}/calculation-models/${loadedCalculationModelId}/${getSubPathByType()}/${ownerId}/documents/${
    elementDocument.id
  }`;

  return (
    <>
      <DocumentListItem
        fileName={elementDocument.name}
        fileSize={elementDocument.size}
        created={elementDocument.created}
        downloadUrl={downloadUrl}
        setIsDeleteModalOpen={canDelete ? setIsDeleteModalOpen : undefined}
        contextMenu
        documentViewerTitle={t('common.document')}
      />
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} variant="small">
        <CalculateElementDocumentDeleteModal
          ownerId={ownerId}
          type={type}
          document={elementDocument}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
    </>
  );
};
