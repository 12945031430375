import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import classNames from 'classnames';
import { supportedLanguages, setLanguageAndLocale, getLanguageAndLocale, safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useApiPostUpdateUserLanguageMutation } from '@client/shared/api';
import { setLanguage, useGenericDispatch } from '@client/shared/store';
import { LoadingIndicator } from '@client/shared/toolkit';

export const MainNavigationLanguageMenu = () => {
  const { t } = useTranslation();
  const dispatch = useGenericDispatch();
  const [postLanguage, { isLoading }] = useApiPostUpdateUserLanguageMutation();

  const currentLanguage = getLanguageAndLocale().language;
  const availableLanguages = supportedLanguages;

  const handleChangeTranslation = async (language: string) => {
    try {
      await safeMutation(
        postLanguage,
        {
          body: { language: language },
        },
        isLoading
      );

      dispatch(setLanguage(language));
      await setLanguageAndLocale(language);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {isLoading && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
      <Menu as="div" className="relative inline-block text-left z-20">
        <MenuButton>
          <span className="uppercase">{ currentLanguage === 'en' ? 'en-us': currentLanguage}</span>
        </MenuButton>
        <MenuItems className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
          {availableLanguages.map((lang) => (
            <MenuItem key={lang}>
              {({ focus }) => {
                const isActive = lang === currentLanguage;
                return (
                  <button
                    className={classNames(
                      'text-black w-full text-left px-4 py-2 h-14 text-base border-t flex items-center',
                      {
                        'font-bold': isActive,
                        'bg-blue-200': focus,
                      }
                    )}
                    onClick={() => handleChangeTranslation(lang)}
                  >
                    <div className="flex w-full justify-between items-center">
                      <div>
                        {t(`languages.${lang.includes('en') ? 'en': lang}`)} <span className="uppercase">({lang === 'en' ? 'en-us': lang})</span>
                      </div>
                      <div className="h-4 w-4 border-2 rounded-full border-neutral-500 focus:ring-2 focus:ring-blue-300 flex items-center justify-center">
                        <div className={classNames('w-2 h-2 rounded-full', { 'bg-black': isActive })}></div>
                      </div>
                    </div>
                  </button>
                );
              }}
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>
    </>
  );
};
