import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiGetEmissionYearlyDistributionsApiArg,
  ApiGetEmissionYearlyDistributionsApiResponse,
  ApiPostCreateLocalLawCostElementApiArg,
  ApiPostCreateLocalLawCostElementApiResponse,
  ApiPostUpdateLocalLawCostElementApiArg,
  ApiPostUpdateLocalLawCostElementApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiLocalLawEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetEmissionYearlyDistributions: {
    providesTags: (
      _result: ApiGetEmissionYearlyDistributionsApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetEmissionYearlyDistributionsApiArg,
    ) => [{ type: ApiTagTypes.EmissionYearlyDistributions, id: args.calculationModelId }],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateLocalLawCostElement: {
    invalidatesTags: (
      _result: ApiPostCreateLocalLawCostElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateLocalLawCostElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.FinancingPurposeOverview, id: args.calculationModelId },
            { type: ApiTagTypes.EmissionYearlyDistributions, id: args.calculationModelId }
          ]
        : [],
  },
  apiPostUpdateLocalLawCostElement: {
    invalidatesTags: (
      _result: ApiPostUpdateLocalLawCostElementApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateLocalLawCostElementApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CostElement, id: args.id },
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.BudgetReport, id: args.calculationModelId },
            { type: ApiTagTypes.ForecastReport, id: args.calculationModelId },
            { type: ApiTagTypes.ComparisonReport, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.FinancingPurposeOverview, id: args.calculationModelId },
            { type: ApiTagTypes.EmissionYearlyDistributions, id: args.calculationModelId }
          ]
        : [],
  },
};
