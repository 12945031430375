import { useLoadedProjectId } from '@client/project/store';
import { DealReadModel, useApiDeleteRentDealMutation, useApiDeleteSellDealMutation } from '@client/shared/api';
import { ConfirmModal, LoadingIndicator, ModalOnCloseProps } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { Trans, useTranslation } from 'react-i18next';

interface DealDeleteModalProps extends ModalOnCloseProps {
  deal: DealReadModel;
}

export const DealDeleteModal = ({ deal, onClose }: DealDeleteModalProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const [deleteRentDeal, { isLoading: isLoadingRent }] = useApiDeleteRentDealMutation();
  const [deleteSellDeal, { isLoading: isLoadingSell }] = useApiDeleteSellDealMutation();

  const handleDelete = async () => {
    switch (deal.type){
      case 'Rent':
        try {
          await safeMutation(
            deleteRentDeal,
            {
              projectId: loadedProjectId ?? 'unset',
              dealId: deal.id ?? '',
            },
            isLoadingRent
          );
    
          onClose(true);
        } catch (e) {
          console.log(e);
        }        
        break;
      case 'Sell':
        try {
          await safeMutation(
            deleteSellDeal,
            {
              projectId: loadedProjectId ?? 'unset',
              dealId: deal.id ?? '',
            },
            isLoadingSell
          );
    
          onClose(true);
        } catch (e) {
          console.log(e);
        }        
        break;
    }
  };

  return (
    <ConfirmModal
      title={t('common.deleteEntity', { entity: `${deal.name} - ${deal.code}` })}
      onConfirm={handleDelete}
      onClose={() => onClose(false)}
      confirmVariant="danger"
      confirmText={t('common.delete')}
    >
      {(isLoadingRent || isLoadingSell) && <LoadingIndicator text={t('projectRent.dealDeleteLoading') ?? ''} mode="overlay" />}
      <Trans i18nKey="projectRent.dealDeleteConfirmation" values={{ entity: deal.name }}>
        <div>
          Möchten Sie <span className="font-bold">Entity</span> wirklich löschen?
        </div>
        <div className="pt-2">Dies kann nicht rückgängig gemacht werden!</div>
      </Trans>
    </ConfirmModal>
  );
};
