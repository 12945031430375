import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiDeleteRentDealApiArg,
  ApiDeleteRentDealApiResponse, ApiDeleteRentDealContactApiArg,
  ApiDeleteRentDealContactApiResponse, ApiDeleteRentDealContractItemApiArg,
  ApiDeleteRentDealContractItemApiResponse, ApiDeleteRentDealCostItemApiArg,
  ApiDeleteRentDealCostItemApiResponse, ApiDeleteRentDealDocumentApiArg,
  ApiDeleteRentDealDocumentApiResponse, ApiGetRentDealsApiArg, ApiGetRentDealsApiResponse, ApiPostCreateRentDealApiArg,
  ApiPostCreateRentDealApiResponse, ApiPostCreateRentDealContactApiArg,
  ApiPostCreateRentDealContactApiResponse, ApiPostCreateRentDealContractItemApiArg,
  ApiPostCreateRentDealContractItemApiResponse, ApiPostCreateRentDealCostItemApiArg,
  ApiPostCreateRentDealCostItemApiResponse, ApiPostCreateRentDealDocumentApiArg,
  ApiPostCreateRentDealDocumentApiResponse, ApiPostReopenRentDealApiArg,
  ApiPostReopenRentDealApiResponse, ApiPostUpdateRentDealApiArg, ApiPostUpdateRentDealConditionApiArg,
  ApiPostUpdateRentDealConditionApiResponse, ApiPostUpdateRentDealContactApiArg,
  ApiPostUpdateRentDealContactApiResponse, ApiPostUpdateRentDealContractItemApiArg,
  ApiPostUpdateRentDealContractItemApiResponse, ApiPostUpdateRentDealCostItemApiArg,
  ApiPostUpdateRentDealCostItemApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiRentEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetRentDeals: {
    providesTags: (
      _result: ApiGetRentDealsApiResponse,
      error: FetchBaseQueryError,
      args: ApiGetRentDealsApiArg,
    ) =>
      error == null
        ? [{ type: ApiTagTypes.RentDeal, id: args.projectId }]
        : [],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateRentDeal: {
    invalidatesTags: (
      _result: ApiPostCreateRentDealApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateRentDealApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiPostCreateRentDealContact: {
    invalidatesTags: (_result: ApiPostCreateRentDealContactApiResponse, error: FetchBaseQueryError, args: ApiPostCreateRentDealContactApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiPostCreateRentDealContractItem: {
    invalidatesTags: (_result: ApiPostCreateRentDealContractItemApiResponse, error: FetchBaseQueryError, args: ApiPostCreateRentDealContractItemApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiPostCreateRentDealCostItem: {
    invalidatesTags: (_result: ApiPostCreateRentDealCostItemApiResponse, error: FetchBaseQueryError, args: ApiPostCreateRentDealCostItemApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiPostCreateRentDealDocument: {
    invalidatesTags: (_result: ApiPostCreateRentDealDocumentApiResponse, error: FetchBaseQueryError, args: ApiPostCreateRentDealDocumentApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiDeleteRentDeal: {
    invalidatesTags: (_result: ApiDeleteRentDealApiResponse, error: FetchBaseQueryError, args: ApiDeleteRentDealApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiDeleteRentDealContact: {
    invalidatesTags: (_result: ApiDeleteRentDealContactApiResponse, error: FetchBaseQueryError, args: ApiDeleteRentDealContactApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiDeleteRentDealContractItem: {
    invalidatesTags: (_result: ApiDeleteRentDealContractItemApiResponse, error: FetchBaseQueryError, args: ApiDeleteRentDealContractItemApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiDeleteRentDealCostItem: {
    invalidatesTags: (_result: ApiDeleteRentDealCostItemApiResponse, error: FetchBaseQueryError, args: ApiDeleteRentDealCostItemApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiDeleteRentDealDocument: {
    invalidatesTags: (_result: ApiDeleteRentDealDocumentApiResponse, error: FetchBaseQueryError, args: ApiDeleteRentDealDocumentApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiPostReopenRentDeal: {
    invalidatesTags: (_result: ApiPostReopenRentDealApiResponse, error: FetchBaseQueryError, args: ApiPostReopenRentDealApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiPostUpdateRentDeal: {
    invalidatesTags: (_result: ApiPostUpdateRentDealConditionApiResponse, error: FetchBaseQueryError, args: ApiPostUpdateRentDealApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiPostUpdateRentDealContact: {
    invalidatesTags: (_result: ApiPostUpdateRentDealContactApiResponse, error: FetchBaseQueryError, args: ApiPostUpdateRentDealContactApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiPostUpdateRentDealContractItem: {
    invalidatesTags: (_result: ApiPostUpdateRentDealContractItemApiResponse, error: FetchBaseQueryError, args: ApiPostUpdateRentDealContractItemApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiPostUpdateRentDealCostItem: {
    invalidatesTags: (_result: ApiPostUpdateRentDealCostItemApiResponse, error: FetchBaseQueryError, args: ApiPostUpdateRentDealCostItemApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
  apiPostUpdateRentDealCondition: {
    invalidatesTags: (_result: ApiPostUpdateRentDealConditionApiResponse, error: FetchBaseQueryError, args: ApiPostUpdateRentDealConditionApiArg) =>
      error == null
        ? [
            { type: ApiTagTypes.RentDeal, id: args.projectId },
            { type: ApiTagTypes.TaxonomyCostItems, id: args.projectId },
            { type: ApiTagTypes.Taxonomy, id: args.projectId }
          ]
        : [],
  },
};
