import { BreadcrumbItem, BreadcrumbItemProps } from './BreadcrumbItem';
import cn from 'classnames';

interface BreadcrumbProps {
  items: BreadcrumbItemProps[];
  className?: string
}

export const Breadcrumb = (props: BreadcrumbProps) => {
  const { items, className } = props;

  if (!items.length) return null;

  return (
    <ul className={cn('flex items-center flex-wrap gap-y-0.5 justify-center', className)}>
      {items.map((item, index) => (
        <BreadcrumbItem {...item} key={`breadcrumb-item-${index}`} />
      ))}
    </ul>
  );
};
