import { getLanguageAndLocale } from './i18n';

export interface FormatCurrencyOptions {
  maxDigits?: number;
  minDigits?: number;
  formatStyle?: 'currency' | 'decimal' | 'percent';
  currency?: string;
  placeHolder?: string;
  currencyDisplay?: 'code' | 'symbol' | 'narrowSymbol' | 'name';
  locale?: string;
}

/**
 * Formats a number as a currency string.
 * @param amount The amount to be formatted
 * @param options formatting options
 * @param options.maxDigits The maximum number of digits to display after the decimal point.
 * @param options.formatStyle The format style to use. Defaults to 'currency'.
 * @param options.currency The currency to use. Defaults to 'EUR'.
 * @param options.placeHolder The placeholder to use if the amount is null or undefined. Defaults to '-'.
 * @param options.currencyDisplay The currency display style to use. Defaults to 'symbol'.
 * @param options.locale The locale to use. Defaults to the user's browser locale.
 * @returns {string} The formatted currency string.
 */
export const formatCurrency = (
  amount: number | null | undefined,
  {
    maxDigits = 0,
    minDigits = 0,
    formatStyle = 'currency',
    currency = 'EUR',
    currencyDisplay = 'narrowSymbol',
    locale = getLanguageAndLocale().locale,
    placeHolder = '-',
  }: FormatCurrencyOptions = {}
): string => {
  if (amount == null) {
    return placeHolder;
  }

  return new Intl.NumberFormat(locale, {
    style: formatStyle,
    currency: currency,
    currencyDisplay: currencyDisplay,
    maximumFractionDigits: maxDigits,
    minimumFractionDigits: minDigits,
  }).format(amount);
};
