import { i18n } from '@client/shared/utilities';
import { InvoiceType } from '@client/shared/api';

export function getInvoiceTypeLabel(type: InvoiceType): string {
  switch (type) {
    case 'Single':
      return i18n.t('projectControl.invoiceTypeSingle')
    case 'Partial':
      return i18n.t('projectControl.invoiceTypePartial')
    case 'PartialFinal':
      return i18n.t('projectControl.invoiceTypePartialFinal')
    case 'Final':
      return i18n.t('projectControl.invoiceTypeFinal')
    case 'AdvancePayment':
      return i18n.t('projectControl.invoiceTypeAdvancePayment')
    case 'Warranty':
      return i18n.t('projectControl.invoiceTypeWarranty')
    default:
      return ''
  }
}
