import { VatElementReadModel } from '@client/shared/api';
import { VatReturnInput } from '.';

interface VatReturnEditProps {
  vatCostElement: VatElementReadModel;
  updateVatCostElement: (value: VatElementReadModel) => void;
  disabled?: boolean;
}

export const VatReturnEdit = ({ vatCostElement, updateVatCostElement, disabled }: VatReturnEditProps) => {

  return (
    <div className="bg-white">
      <VatReturnInput
        vatReturnPercentage={vatCostElement.vatReturnPercentage}
        setVatReturnPercentage={(value) => updateVatCostElement({ ...vatCostElement, vatReturnPercentage: value })}
        returnDelayMonths={vatCostElement.delayMonths}
        setReturnDelayMonths={(value) => updateVatCostElement({ ...vatCostElement, delayMonths: value ?? 0 })}
        selectedCostElementIds={vatCostElement.costGroupIds ?? []}
        setSelectedCostElementIds={(value) => updateVatCostElement({ ...vatCostElement, costGroupIds: value })}
        selectedUDF={vatCostElement.userDefinedFieldId ?? 'none'}
        setSelectedUDF={(value) => updateVatCostElement({ ...vatCostElement, userDefinedFieldId: value })}
        selectedUDFLabels={vatCostElement.userDefinedFieldLabelIds ?? []}
        setSelectedUDFLabels={(value) => updateVatCostElement({ ...vatCostElement, userDefinedFieldLabelIds: value })}
        type='edit'
        disabled={disabled}
        className='w-full'
      />
    </div>
  );
};
