import { TaxonomyReadModel, PlotReadModel, CalculationModelMetadata } from '@client/shared/api';
import { PlanCostsIcon } from '@client/shared/toolkit';
import { formatUnit, formatLargeNumber } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { FormattedCurrency } from '@client/project/shared';
import { useGetCurrencySymbol } from '@client/project/store';

interface CostsCardProps {
  item?: TaxonomyReadModel | undefined;
  plot?: PlotReadModel | undefined;
  project?: CalculationModelMetadata | undefined;
}

export const CostsCard = ({ plot, item, project }: CostsCardProps) => {
  const { t } = useTranslation();
  const currentCurrencySymbol = useGetCurrencySymbol();

  return (
    <div className="rounded shadow p-4 w-full h-full flex flex-row bg-white ">
      <div className="w-full flex flex-row">
        <div className="flex justify-center items-center mr-4">
          <PlanCostsIcon className="h-8 w-8" />
        </div>
        <div className="w-full">
          <div className="text-xxs font-bold text-slate-500">{t('projectTaxonomy.dashboardCost')}</div>
          <div className="h-7 text-base font-extrabold">
            {item && item?.cost && item?.itemType !== 'Project' && <FormattedCurrency amount={item?.cost} />}
            {project && project?.totalCost && item?.itemType === 'Project' && <FormattedCurrency amount={project?.totalCost.value} />}
            {plot && plot?.cost && <FormattedCurrency amount={plot?.cost.value} />}
          </div>
          <div className="text-xxs text-slate-500">
            {item &&
              item?.cost &&
              item?.sizes.bgf?.value &&
              item?.itemType !== 'Project' &&
              formatUnit(item?.cost / item?.sizes?.bgf?.value, `${currentCurrencySymbol} p.${item?.sizes?.bgf?.unit}`)}
            {project &&
              project?.totalCostForecasts &&
              item?.sizes.bgf?.value &&
              item?.itemType === 'Project' &&
              formatUnit(
                project?.totalCostForecasts.value ?? 0 / item?.sizes?.bgf?.value,
                `${currentCurrencySymbol} p.${item?.sizes?.bgf?.unit}`
              )}
            {plot &&
              plot?.cost &&
              plot?.totalPlotSize &&
              formatUnit((plot?.cost.value ?? 0) / (plot?.totalPlotSize.value ?? 0), `${currentCurrencySymbol} p.${plot?.totalPlotSize.unit}`)}
          </div>
        </div>
      </div>
      {item?.itemType === 'Project' && (
        <div className="w-2/6 text-right justify-end">
          <div className="text-xxs font-bold text-slate-500">{t('projectTaxonomy.dashboardDeviation')}</div>
          <div className="h-7 text-xs font-extrabold flex items-end justify-end">
            {formatLargeNumber((project?.totalCostForecasts.value ?? 0) - (project?.totalCost.value ?? 0))}
          </div>
          <div className="text-xxs text-slate-500">
            <span className="text-green-400"></span>
          </div>
        </div>
      )}
    </div>
  );
};
