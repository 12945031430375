import {
  DeliveryPhaseOverviewReadModel,
  ElementTimelineReadModel,
  TimelineDurationUnit,
  useApiPostCreateCalculationModelDeliveryPhasesMutation,
  useApiPostUpdateCalculationModelDeliveryPhasesMutation
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import {
  ContextMenu,
  ContextMenuItem,
  DateFromIcon,
  Form, FormField, FormRefHandle,
  HintBox,
  Modal,
  NumberInput,
  TextInput,
  TrashIcon
} from '@client/shared/toolkit';
import { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  calculateDuration,
  calculateEndDate,
  calculateStartDate
} from '../../utils';
import {
  DeleteCalculationModelDeliveryPhaseModal,
  DeliveryPhaseFormValidationSchema,
  DeliveryPhaseFormValidationValues
} from '.'
import {
  TimeInput,
  TimeLineElementInput,
  deleteEmptyProperties,
  objectsEqual,
  timelineReadModelToPayload
} from '@client/project/shared'
import { isEmpty, safeMutation } from '@client/shared/utilities';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';

const hasChanged = (timeline: ElementTimelineReadModel, oldTimeline: ElementTimelineReadModel): boolean => {
  // clone original input data for change detection
  const previousTimeLineElement = { ...oldTimeline };
  delete previousTimeLineElement.id;
  timeline = { ...previousTimeLineElement, ...timeline };
  // prevent infinite loop by checking if the new timeline element is different from an empty one or the previous values
  if (
    (isEmpty(timeline.duration) &&
      isEmpty(timeline.endElementTimelineId) &&
      timeline.endOffset === 0 &&
      isEmpty(timeline.endOffsetPosition) &&
      isEmpty(timeline.endCalculationModelDeliveryPhaseId) &&
      isEmpty(timeline.endDate) &&
      isEmpty(timeline.startElementTimelineId) &&
      isEmpty(timeline.startFixedStartDate) &&
      timeline.startOffset === 0 &&
      isEmpty(timeline.startOffsetPosition) &&
      isEmpty(timeline.startCalculationModelDeliveryPhaseId) &&
      isEmpty(timeline.distribution)) ||
    objectsEqual(deleteEmptyProperties(timeline), deleteEmptyProperties(previousTimeLineElement))
  ) {
    return false;
  }

  return true;
};

export const createTimeline = (
  id: string,
  start?: TimeInput | null,
  end?: TimeInput | null,
  durationUnit?: TimelineDurationUnit | null,
  duration?: number | null
) => {
  const newTimeLineElement: ElementTimelineReadModel = {
    id: id,
    startCalculationModelDeliveryPhaseId: start?.variantDeliveryPhaseId,
    startOffset: start?.offset ?? 0,
    startOffsetPosition: start?.offsetPosition,
    startOffsetUnit: start?.offsetUnit,
    startFixedStartDate: start?.fixedDate,
    startElementTimelineId: start?.elementTimelineId,
    startType: start?.type ?? 'FixedDates',
    endCalculationModelDeliveryPhaseId: end?.variantDeliveryPhaseId,
    endOffset: end?.offset ?? 0,
    endOffsetPosition: end?.offsetPosition,
    endElementTimelineId: end?.elementTimelineId,
    endDate: end?.fixedDate,
    endType: end?.type ?? 'FixedDates',
    duration: duration,
    durationUnit: durationUnit,
    effectiveStartDate: calculateStartDate(start, end, duration, durationUnit),
    effectiveEndDate: calculateEndDate(start, end, duration, durationUnit),
  };

  return newTimeLineElement;
};

interface EditCalculationModelDeliveryPhaseProps {
  calculationModelStartDate: Date;
  updateItem: (item: DeliveryPhaseOverviewReadModel) => void
  item: DeliveryPhaseOverviewReadModel
  readOnly?: boolean
  variantId: string;
  onItemDeleted: () => void
  setIsLoading: (isLoading: boolean) => void
  selectedDurationUnit?: TimelineDurationUnit
}

export const EditCalculationModelDeliveryPhase = ({
  calculationModelStartDate,
  updateItem,
  item,
  readOnly = false,
  variantId,
  onItemDeleted,
  setIsLoading,
  selectedDurationUnit = 'Days'
}: EditCalculationModelDeliveryPhaseProps) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId() ?? 'unset';
  const loadedCalculationModelId = useLoadedVariantId() ?? 'unset';
  const canDelete = useValidateProjectPermission(['PROJECT_DELETE'], loadedProjectId);
  const [oldDuration, setOldDuration] = useState<number | null | undefined>(item.timeLine.duration)
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [postUpdate, { isLoading: isUpdating }] = useApiPostUpdateCalculationModelDeliveryPhasesMutation();
  const [postCreate, { isLoading: isCreating }] = useApiPostCreateCalculationModelDeliveryPhasesMutation();
  const formRef = useRef<FormRefHandle<DeliveryPhaseFormValidationValues>>();
  const [startWarning, setStartWarning] = useState<string | null>(null);
  const [newItemTimeline, setNewItemTimeline] = useState<ElementTimelineReadModel | null>(null)

  const timeLineStart = useMemo(() => {
    return item.timeLine
      ? {
        variantDeliveryPhaseId: item.timeLine.startCalculationModelDeliveryPhaseId,
        variantMileStoneId: item.timeLine.startCalculationModelMileStoneId,
        offset: item.timeLine.startOffset,
        offsetPosition: item.timeLine.startOffsetPosition,
        offsetUnit: item.timeLine.startOffsetUnit,
        fixedDate: item.timeLine.startFixedStartDate,
        elementTimelineId: item.timeLine.startElementTimelineId,
        type: item.timeLine.startType,
        effectiveDate: item.timeLine.effectiveStartDate,
      }
      : undefined;
  }, [item.timeLine])

  const timeLineEnd = useMemo(() => {
    return item.timeLine && item.timeLine.effectiveEndDate
      ? {
        variantDeliveryPhaseId: item.timeLine.endCalculationModelDeliveryPhaseId,
        variantMileStoneId: item.timeLine.endCalculationModelMileStoneId,
        offset: item.timeLine.endOffset,
        offsetPosition: item.timeLine.endOffsetPosition,
        elementTimelineId: item.timeLine.endElementTimelineId,
        type: item.timeLine.endType,
        duration: item.timeLine.duration,
        durationUnit: item.timeLine.durationUnit,
        effectiveDate: item.timeLine.effectiveEndDate,
        fixedDate: item.timeLine.effectiveEndDate,
      }
      : undefined;
  }, [item.timeLine])

  const handleOnDurationChange = useCallback(
    (duration: number | null) => {
      // const durationUnit = 'Days' // item.timeLine?.durationUnit ?? 'Days'

      const newTimeLineElement = createTimeline(item.id, timeLineStart, null, selectedDurationUnit, duration);

      let durationInDays = duration;

      newTimeLineElement.durationUnit = 'Days';
      // if (!newTimeLineElement.endCalculationModelDeliveryPhaseId) {
      newTimeLineElement.endType = 'FixedDates';
      // }
      if (item.timeLine?.startFixedStartDate || item.timeLine?.startCalculationModelDeliveryPhaseId) {
        newTimeLineElement.endDate = null;
        newTimeLineElement.endCalculationModelDeliveryPhaseId = null;
        newTimeLineElement.endOffset = null;
        newTimeLineElement.endOffsetPosition = null;
        newTimeLineElement.endOffsetUnit = null;
        // newTimeLineElement.effectiveEndDate = calculateEndDate(timeLineStart, null, duration, durationUnit);
      } else {
        newTimeLineElement.startFixedStartDate = null;
        newTimeLineElement.startCalculationModelDeliveryPhaseId = null;
        newTimeLineElement.startOffset = null;
        newTimeLineElement.startOffsetPosition = null;
        newTimeLineElement.startOffsetUnit = null;
        // newTimeLineElement.effectiveStartDate = calculateStartDate(null, timeLineStart, duration, durationUnit);
      }

      if (selectedDurationUnit !== 'Days') {
        durationInDays =
          calculateDuration('Days', newTimeLineElement.effectiveStartDate, newTimeLineElement.effectiveEndDate) ??
          duration;
        newTimeLineElement.duration = durationInDays;
      }

      if (hasChanged(newTimeLineElement, item.timeLine)) {
        const updatedItem = { ...item };
        updatedItem.timeLine = newTimeLineElement;
        updateItem(updatedItem);
        setNewItemTimeline(newTimeLineElement);
      }
    },
    [
      timeLineStart,
      selectedDurationUnit,
      item,
      updateItem,
    ],
  );

  useEffect(() => {
    if (
      item.timeLine?.effectiveStartDate &&
      new Date(item.timeLine.effectiveStartDate).getTime() < calculationModelStartDate.getTime()
    ) {
      setStartWarning(t('projectCalculate.timelineStartOutsideProjectStartDateWarning'));
    } else {
      setStartWarning(null);
    }

    if (newItemTimeline && newItemTimeline.effectiveStartDate && newItemTimeline.effectiveEndDate) {
      const diff = new Date(newItemTimeline.effectiveEndDate).getTime() - new Date(newItemTimeline.effectiveStartDate).getTime();
      if (diff < 0) {
        setStartWarning(t('error.general.end_date_must_be_after_start_date'));
      }
    }
  }, [item.timeLine, calculationModelStartDate, t, newItemTimeline]);

  const handleTimeLineChange = (data: {
    startElement?: TimeInput;
    endElement?: TimeInput;
    durationUnit?: TimelineDurationUnit | null;
  }) => {
    let newTimeLineElement: ElementTimelineReadModel | null = null;
    // const end = item.timeLine?.endDate || item.timeLine?.endCalculationModelDeliveryPhaseId ? timeLineEnd : null;
    // const start = item.timeLine?.startFixedStartDate || item.timeLine?.startCalculationModelDeliveryPhaseId ? timeLineStart : null;
    // const durationUnit = data.durationUnit ? data.durationUnit : item.timeLine?.durationUnit ?? 'Days';

    if (data.startElement) {
      newTimeLineElement = createTimeline(item.id, data.startElement, timeLineEnd);
    }
    if (data.endElement) {
      newTimeLineElement = createTimeline(item.id, timeLineStart, data.endElement);
    }
    if (newTimeLineElement) {
      // update duration
      newTimeLineElement.durationUnit = 'Days';
      newTimeLineElement.duration =  calculateDuration('Days', newTimeLineElement.effectiveStartDate, newTimeLineElement.effectiveEndDate);

      /* if (newTimeLineElement) {
        if (
          (newTimeLineElement?.startCalculationModelDeliveryPhaseId || newTimeLineElement?.startFixedStartDate) &&
          (newTimeLineElement?.endCalculationModelDeliveryPhaseId || newTimeLineElement?.endDate) &&
          !data.durationUnit
        ) {
          newTimeLineElement.durationUnit = 'Days';
          newTimeLineElement.duration = calculateDuration(
            'Days',
            newTimeLineElement.effectiveStartDate,
            newTimeLineElement.effectiveEndDate
          );
        }
        if (data.durationUnit) {
          newTimeLineElement = createTimeline(item.id, timeLineStart, null, durationUnit, item.timeLine?.duration);
        }
      } */

      if (newTimeLineElement && hasChanged(newTimeLineElement, item.timeLine)) {
        const updatedItem = {...item}
        updatedItem.timeLine = newTimeLineElement
        updateItem(updatedItem)
        setNewItemTimeline(newTimeLineElement)
      }
    }
  };

  const contextItems: ContextMenuItem[] = useMemo(() => [
    {
      label: t('common.delete'),
      subtitle: t('common.deleteElement'),
      icon: <TrashIcon />,
      onClick: () => {
        if (item.id) {
          setShowDelete(true)
        } else {
          onItemDeleted()
        }
      }
    },
  ], [t, item.id, onItemDeleted, setShowDelete])

  const defaultFormValues = useMemo(() => {
    return {
      code: item?.code,
      name: item?.name,
    }
  }, [item]);

  const handleDeliveryPhaseSubmit = async (data : DeliveryPhaseFormValidationValues) => {
    const newTimeline = timelineReadModelToPayload(newItemTimeline ?? item.timeLine);
    // only if there is a new item timeline, otherwise we just use the original item timeline, which should always have effective end date
    if (!newTimeline || (newItemTimeline && (!newItemTimeline?.effectiveEndDate && !newTimeline.endFixedDate) || (!newItemTimeline?.effectiveStartDate && !newTimeline.startFixedStartDate))) {
      return;
    }
    // create new item
    if (!item.id) {
      try {
        await safeMutation(
          postCreate,
          {
            projectId: loadedProjectId,
            calculationModelId: loadedCalculationModelId,
            body: {
              code : data.code,
              name : data.name,
              order : item.order,
              deliveryPhaseTimeLinePayload: newTimeline
            }
          },
          isCreating
        );
      } catch (e) {
        console.log(e);
      }
    } else {
      // update item
      try {
        await safeMutation(
          postUpdate,
          {
            projectId: loadedProjectId,
            calculationModelId: loadedCalculationModelId,
            deliveryPhaseId: item.id,
            body: {
              code : data.code,
              name : data.name,
              order : item.order ?? 0,
              deliveryPhaseTimeLinePayload : newTimeline
            }
          },
          isUpdating
        );
      } catch (e) {
        console.log(e);
      }
    }

    setOldDuration(newTimeline.duration)
  }
  const saveItem = useCallback(() => {
    if (formRef?.current) {
      formRef.current.submitForm()
    }
  }, [])

  useEffect(() => {
    if (isUpdating || isCreating) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdating, isCreating]);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      saveItem()
    }
  }, [saveItem])

  const calculatedDuration = useMemo(() => {
    let convertedDuration = newItemTimeline ? newItemTimeline?.duration : item.timeLine.duration;
    if (selectedDurationUnit !== 'Days') {
      if (newItemTimeline) {
        convertedDuration = calculateDuration(
          selectedDurationUnit,
          newItemTimeline.effectiveStartDate,
          newItemTimeline.effectiveEndDate,
        );
      } else if (item.timeLine) {
        convertedDuration = calculateDuration(
          selectedDurationUnit,
          item.timeLine.effectiveStartDate,
          item.timeLine.effectiveEndDate,
        );
      }
    }
    return convertedDuration;
  }, [selectedDurationUnit, newItemTimeline, item.timeLine]);

  const minEndDate = useMemo(() => {
    return timeLineStart?.effectiveDate ? new Date(timeLineStart?.effectiveDate) : undefined
  }, [timeLineStart?.effectiveDate])

  return (
    <>
      <Form<DeliveryPhaseFormValidationValues>
        onSubmit={handleDeliveryPhaseSubmit}
        validationSchema={DeliveryPhaseFormValidationSchema}
        defaultValues={defaultFormValues}
        ref={formRef}
        className="flex border-b divide-x"
        key={`form-delivery-phase-${item.id}-${item.order}`} // otherwise the form fields of each row get mixed up
      >
        <FormField name="code">
          {(control) => (
            <TextInput
              className="w-[76px] flex-none"
              disabled={readOnly}
              label={t('projectCalculate.deliveryPhaseLabelId')}
              maxLength={5}
              {...control}
              onBlur={() => {
                if (control.value !== item.code) {
                  saveItem()
                }
              }}
              helperText=""
              showValidation={!control.isValidationValid}
            />
          )}
        </FormField>
        <FormField name="name">
          {(control) => (
            <TextInput
              className="w-auto flex-1"
              disabled={readOnly}
              label={t('projectCalculate.deliveryPhaseLabelDescription')}
              {...control}
              onBlur={() => {
                if (control.value !== item.name) {
                  saveItem()
                }
              }}
              helperText=""
              showValidation={!control.isValidationValid}
              onKeyDown={handleKeyDown}
            />
          )}
        </FormField>
        <TimeLineElementInput
          className="w-[155px] flex-none box-content overflow-hidden"
          timeLineData={timeLineStart}
          label={t('projectCalculate.deliveryPhaseLabelStartDate')}
          variantId={variantId}
          disabled={readOnly}
          onChange={(data) => {
            handleTimeLineChange({ startElement: data })
          }}
          icon={<DateFromIcon />}
          showChevronDown={false}
          onHidePopover={saveItem}
          inModal={true}
        />
        <TimeLineElementInput
          className="w-[155px] flex-none overflow-hidden"
          timeLineData={timeLineEnd}
          label={t('projectCalculate.deliveryPhaseLabelEndDate')}
          variantId={variantId}
          disabled={readOnly}
          onChange={(data) => {
            handleTimeLineChange({ endElement: data })
          }}
          icon={<DateFromIcon className='scale-x-[-1]' />}
          showChevronDown={false}
          onHidePopover={saveItem}
          minDate={minEndDate}
          inModal={true}
          type="end"
        />
        {/* Helper input to show the duration in the given selected unit */}
        <NumberInput
          className="w-[88px] flex-none"
          label={t('projectCalculate.deliveryPhaseLabelDuration')}
          value={calculatedDuration}
          disabled={readOnly}
          onChange={(duration) => {
            handleOnDurationChange(duration)
          }}
          onBlur={() => {
            if (oldDuration !== (newItemTimeline ? newItemTimeline.duration : item.timeLine.duration)) {
              saveItem()
            }
          }}
          step={1}
          onKeyDown={handleKeyDown}
        />
        {canDelete && (
          <div className="bg-white flex items-center justify-center text-gray-400 hover:text-gray-600 px-1">
            <ContextMenu items={contextItems} />
          </div>
        )}
        <Modal isOpen={showDelete} onClose={() => setShowDelete(false)}>
          <DeleteCalculationModelDeliveryPhaseModal
            deliveryPhase={item}
            onClose={() => setShowDelete(false)}
          />
        </Modal>
      </Form>
      {startWarning && (
        <div className="flex flex-row justify-start text-left">
          <HintBox hintType="warning" className="text-sm">
            {startWarning}
          </HintBox>
        </div>
      )}
    </>
  );
};
